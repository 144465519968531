import { NavLink } from 'react-router-dom';
import { getAuthTokenFromLocalStorage } from '../../../utils/localStorageHelper';
import EzyButton from '../button/EzyButton';
import errorImage from '../../../assets/images/Access Denied.svg';

function ForbiddenAccess() {
  const token = getAuthTokenFromLocalStorage();
  return (
    <div className="error-page-container">
      <img src={errorImage} alt="No Page Found" />
      <div className="title">Access Denied/Forbidden</div>
      <div className="sub-title">You do not have access to this Page.</div>
      <NavLink to="/" className="nav-link">
        <EzyButton label={`Back to ${token ? 'dashboard' : 'login'}`} />
      </NavLink>
    </div>
  );
}

export default ForbiddenAccess;
