import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNil, omitBy } from 'lodash';
import moment from 'moment';
import EzyDrawer from '../../../components/common/drawer/Drawer';
import EzyAutoComplete from '../../../components/common/autoComplete/EzyAutoComplete';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import {
  clearExportItemsDrawer,
  updateExportTimesheetDrawerDetails,
} from '../redux/exportTimesheetSlice';
// import EzyButton from '../../../components/common/button/EzyButton';
import {
  exportTimesheetDetailsByPersonAction,
  exportTimesheetsAction,
  getExportTypeDropdownListAction,
} from '../redux/exportTimesheetsActions';
import { requireValidate } from '../../../utils/validationHelper';

function ExportItemsDrawer({ selectedTimeSheets }) {
  const { exportItemsDrawer, sidePanelData } = useSelector(
    ({ exportTimesheet }) => exportTimesheet ?? {}
  );

  const { exportTimesheetsActionLoader } = useSelector(({ common }) => common.generalLoader ?? {});

  const { isOpen, exportTypeList, exportTypeId, fileName, errors } = useMemo(
    () => exportItemsDrawer,
    [exportItemsDrawer]
  );

  const { selectedLocation, weekDatesValue, selectedPersons } = useMemo(
    () => sidePanelData,
    [sidePanelData]
  );
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(updateExportTimesheetDrawerDetails({ fieldName: 'isOpen', value: false }));
  };

  const handleExport = () => {
    let error = {};
    error.exportTypeId = requireValidate('Export Type', exportTypeId?.id);
    if (
      exportTypeId?.label === 'CSV/Excel - All Fields' ||
      exportTypeId?.label === 'Reckon Payroll'
    ) {
      error.fileName = requireValidate('File Name', fileName);
    }

    error = omitBy(error, isNil);
    dispatch(updateExportTimesheetDrawerDetails({ fieldName: 'errors', value: error }));

    if (isEmpty(error)) {
      const data = {
        timesheetIds: selectedTimeSheets,
        fileName,
        exportTypeId: exportTypeId?.id,
        locationId: selectedLocation?.id,
        weekStartDate: moment(weekDatesValue?.startDate)?.toISOString(),
        weekEndDate: moment(weekDatesValue?.endDate)?.toISOString(),
        exportTypeName: exportTypeId?.label,
      };
      dispatch(
        exportTimesheetsAction(data, () => {
          dispatch(clearExportItemsDrawer());
          dispatch(
            exportTimesheetDetailsByPersonAction(
              {
                personIds: selectedPersons?.map((e) => e?.personDetailId),
                locationId: selectedLocation?.id,
                startDate: moment(weekDatesValue?.startDate)?.toISOString(),
                endDate: moment(weekDatesValue?.endDate)?.toISOString(),
                previousStartDate: moment(weekDatesValue?.startDate)
                  .subtract(7, 'days')
                  ?.toISOString(),
                previousEndDate: moment(weekDatesValue?.endDate).subtract(7, 'days')?.toISOString(),
              },
              () => {}
            )
          );
        })
      );
    }
  };

  const actionButton = [
    {
      label: 'Export',
      onClick: handleExport,
      loading: exportTimesheetsActionLoader,
    },
  ];

  // const action = <EzyButton label="Custom export" color="secondary" onClick={() => {}} />;

  const handleFieldChange = (fieldName, value) => {
    dispatch(updateExportTimesheetDrawerDetails({ fieldName, value }));
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(getExportTypeDropdownListAction());
    }
  }, [isOpen]);

  return (
    <EzyDrawer
      isOpen={isOpen}
      onClose={handleClose}
      heading="Export Selected Items"
      actionButtons={actionButton}
      // leftAction={action}
    >
      <div className="export-items-drawer">
        <div className="info-div primary-bg word-break">
          {`While PayrollEzy takes care to provide a quality pay rules library, it does not guarantee
          or accept responsibility for) the accuracy, completeness or up to date nature of the
          library, or that it will be suitable for the particular circumstances of your business.
          you're advised to consult with your legal and payroll advisers before relying on
          PayrollEzy's interpretation.`}
        </div>

        <EzyAutoComplete
          label="The payroll application to export to"
          placeholder="Select"
          options={exportTypeList}
          value={exportTypeId}
          onChange={(_, value) => handleFieldChange('exportTypeId', value)}
          error={errors?.exportTypeId}
        />

        {/* <EzyAccordionWrapper>
          <EzyAccordion>
            <EzyAccordionSummary>File preview</EzyAccordionSummary>
            <EzyAccordionDetails>demo</EzyAccordionDetails>
          </EzyAccordion>
        </EzyAccordionWrapper> */}

        {(exportTypeId?.label === 'CSV/Excel - All Fields' ||
          exportTypeId?.label === 'Reckon Payroll') && (
          <EzyInputField
            label="Export file name"
            placeholder="Enter export file name"
            value={fileName}
            onChange={(e) => handleFieldChange('fileName', e?.target?.value)}
            error={errors?.fileName}
          />
        )}
      </div>
    </EzyDrawer>
  );
}

ExportItemsDrawer.propTypes = {
  selectedTimeSheets: PropTypes.array.isRequired,
};

export default ExportItemsDrawer;
