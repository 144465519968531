import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#34A853', // $primary-color
    },
    secondary: {
      main: '#FBBC05', // $secondary-color
    },
    background: {
      default: '#F2F5F8', // $main-bg-color
    },
    text: {
      primary: '#000000', // $text-main-color
    },
    error: {
      main: '#ff6565', // $error-color
    },
  },
  typography: {
    fontFamily: 'reemkufi',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: ` 
    @font-face {
      font-family: 'reemkufi';
      src: local('reemkufi'), url(assets/fonts/ReemKufi-Regular.ttf) format('truetype');
      }
      `,
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          border: '1px solid #dfdfdf',
          boxShadow: `none`,
        },
      },
    },
  },
});

export default theme;
