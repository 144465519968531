import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { InputAdornment } from '@mui/material';
import EzyDialog from '../../components/common/dialog/EzyDialog';
import blackLogo from '../../assets/images/logo/PayrollEzy_BlackLogo.png';
import EzyInputField from '../../components/common/inputField/EzyInputField';
import EzyButton from '../../components/common/button/EzyButton';
import authServices from './authServices';
import validateLogin from './validations/validateLogin';
import { saveAuthTokenToLocalStorage } from '../../utils/localStorageHelper';
import ROUTE_CONSTANTS from '../../utils/constants';
import { errorNotification } from '../../utils/notifyHelper';
import useKeyPress from '../../hooks/useKeyPress';

function Login() {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const isEnterPressed = useKeyPress('Enter');

  const history = useHistory();

  const updateFormData = (e) => {
    const { name, value } = e.target;
    setFormData((data) => ({ ...data, [name]: value }));
  };

  const handleSubmit = async () => {
    const errorsData = validateLogin(formData);
    setErrors(errorsData);
    if (isEmpty(errorsData)) {
      try {
        setLoading(true);
        const res = await authServices.login({
          email: formData.email.toString().trim(),
          password: formData.password.toString().trim(),
        });
        if (res?.data?.success) {
          saveAuthTokenToLocalStorage(res?.data?.data?.token);
          history.push(ROUTE_CONSTANTS.HOME);
        }
      } catch (e) {
        errorNotification(e?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isEnterPressed) {
      handleSubmit();
    }
  }, [isEnterPressed]);

  return (
    <EzyDialog handleClose={() => {}} open className="auth-dialog">
      <div className="auth-wrapper">
        <img src={blackLogo} alt="logo" width={200} />
        <div className="info-text">
          <div className="primary-large-title">Login</div>
          <div className="subtitle-text">Enter your credentials.</div>
        </div>
        <div className="auth-form">
          <div className="field-wrapper">
            <EzyInputField
              label="Email"
              placeholder="Enter your email"
              name="email"
              onChange={updateFormData}
              value={formData.email}
              error={errors?.email}
              required
            />
            <EzyInputField
              type={showPassword ? 'text' : 'password'}
              label="Password"
              placeholder="Enter your password"
              name="password"
              onChange={updateFormData}
              value={formData.password}
              error={errors?.password}
              required
              endAdornment={
                <InputAdornment position="end">
                  <span
                    className="material-icons icon-small endAdornment-icon"
                    onClick={() => setShowPassword((e) => !e)}
                    role="presentation"
                  >
                    {showPassword ? 'visibility_off' : 'visibility'}
                  </span>
                </InputAdornment>
              }
            />
          </div>
          <Link to="/forgot-password" className="secondary-action-text forgot-password">
            Forgot Password?
          </Link>
          <div className="action-button">
            <EzyButton label="Login" onClick={handleSubmit} loading={loading} />
          </div>
          <div className="auth-redirect-text">
            {`Don't have an account? `}
            <Link to="/signup" className="secondary-action-text">
              Register
            </Link>
          </div>
        </div>
      </div>
    </EzyDialog>
  );
}

export default Login;
