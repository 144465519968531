import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EzyToggleButtonGroup from '../../../../components/common/toggleButton/EzyToggleButtonGroup';
import LeaveBalanceList from './LeaveBalanceList';
import LeaveRequestList from './LeaveRequestList';
import EzyButton from '../../../../components/common/button/EzyButton';
import { editLeaveDetails } from '../../redux/peopleSlice';
import AccessWrapper from '../../../../components/common/accessWrapper/AccessWrapper';

const leaveTypes = ['Leave Balances', 'Leave Requests'];

function LeaveDetails() {
  const [leaveType, setLeaveType] = useState(leaveTypes[1]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { viewLeave = false } = history?.location?.state ?? {};
  const { roleId: loggedInRole } = useSelector(({ user }) => user?.profileData ?? {});
  const { personDetail } = useSelector(({ people }) => people?.journalDetails ?? {});

  const onClickAction = () => {
    if (leaveType === leaveTypes[0]) {
      dispatch(editLeaveDetails({ fieldName: 'leaveEntitlementDrawer', value: true }));
    } else {
      dispatch(editLeaveDetails({ fieldName: 'leaveRequestDrawer', value: true }));
    }
  };

  useEffect(() => {
    if (viewLeave) {
      setLeaveType(leaveTypes[1]);
      dispatch(editLeaveDetails({ fieldName: 'viewLeaveRequestDrawer', value: true }));
      dispatch(editLeaveDetails({ fieldName: 'leaveRequestId', value: viewLeave }));
    }
  }, [viewLeave]);

  const hasAccessOfLeaveEntitlement = () => {
    if (loggedInRole === 3 && personDetail?.roleId === 1) {
      return false;
    }
    return true;
  };

  return (
    <div className="leave-detail-root-container">
      <div className="title-with-primary-bg">
        <span>{leaveType}</span>
        <div className="d-flex gap-10">
          {leaveType === 'Leave Requests' ? (
            <EzyButton label="Add leave requests" onClick={onClickAction} access="CREATELVMGMT" />
          ) : (
            <EzyButton
              label="Add leave entitlement"
              onClick={onClickAction}
              access={hasAccessOfLeaveEntitlement() ? 'CREATELVBAL' : ' '}
            />
          )}
          <AccessWrapper access="LISTLVBAL">
            <EzyToggleButtonGroup
              list={leaveTypes}
              selected={leaveType[0]}
              selectedToggleButton="new"
              name="LeaveDetailsType"
              handleChange={(e) => setLeaveType(e.target.value)}
              value={leaveType}
            />
          </AccessWrapper>
        </div>
      </div>
      <div className="p-24 table-wrapper">
        {leaveType === leaveTypes[0] ? <LeaveBalanceList /> : <LeaveRequestList />}
      </div>
    </div>
  );
}

export default LeaveDetails;
