import React, { useState } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { FormControl, TextField } from '@mui/material';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import EzyInputField from '../inputField/EzyInputField';
import { DATE_FORMAT } from '../../../utils/constants';
import { displayDate } from '../../../utils/timeHelper';
import EzyIconButton from '../iconButton/EzyIconButton';

function EzyDatePicker({
  className,
  variant,
  onPrevClick,
  onNextClick,
  value,
  onChange,
  timeZone = moment.tz.guess(),
  ...restProps
}) {
  const [open, setOpen] = useState(false);

  return (
    <FormControl variant="standard" className={`week-picker ${className}`}>
      <EzyIconButton
        className="left-button"
        iconName="navigate_before"
        onClick={() => {
          if (onPrevClick) {
            onPrevClick(moment.tz(value, timeZone).subtract(1, 'days'));
          } else {
            onChange(moment.tz(value, timeZone).subtract(1, 'days'));
          }
        }}
        variant={variant}
      />
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en">
        <DatePicker
          className="MuiButtonBase-root"
          format={DATE_FORMAT}
          value={value}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          slotProps={{
            textField: {
              onClick: () => setOpen(true),
            },
          }}
          slots={{
            openPickerButton: () => null,
          }}
          onChange={(newDate) => onChange(moment.tz(newDate, timeZone))}
          rifmFormatter={(str) => {
            const date = moment.tz(str, timeZone);

            return displayDate(date);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              notched={false}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              onClick={() => setOpen(true)}
              renderInput={(params2) => (
                <EzyInputField
                  placeholder="Select Date"
                  ref={params2.InputProps.ref}
                  inputProps={params2.inputProps}
                  disabled={restProps.disabled}
                  variant={variant}
                />
              )}
            />
          )}
        />
      </LocalizationProvider>
      <EzyIconButton
        className="right-button"
        iconName="navigate_next"
        onClick={() => {
          if (onNextClick) {
            onNextClick(moment.tz(value, timeZone).add(1, 'days'));
          } else {
            onChange(moment.tz(value, timeZone).add(1, 'days'));
          }
        }}
        variant={variant}
      />
    </FormControl>
  );
}

EzyDatePicker.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  onPrevClick: PropTypes.func,
  onNextClick: PropTypes.func,
  value: PropTypes.object,
  onChange: PropTypes.func,
  timeZone: PropTypes.string,
};

EzyDatePicker.defaultProps = {
  className: '',
  value: '',
  onChange: () => {},
  variant: 'outlined',
  onPrevClick: null,
  onNextClick: null,
  timeZone: moment.tz.guess(),
};

export default EzyDatePicker;
