import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty, isNull } from 'lodash';
import AddJournalDrawer from './AddJournalDrawer';
import {
  deleteJournalAction,
  getJournalListByPersonDetailIdAction,
} from '../../redux/peopleActions';
import { displayDate } from '../../../../utils/timeHelper';
import Loader from '../../../../components/common/loader/Loader';
import { clearJournalDetails, updateJournalDetails } from '../../redux/peopleSlice';
import ConfirmationModal from '../../../../components/common/modal/ConfirmationModal';

function JournalList() {
  const dispatch = useDispatch();
  const params = useParams();
  const [deleteModalData, setDeleteModalData] = useState(null);

  const { roleId: loggedInRole } = useSelector(({ user }) => user?.profileData ?? {});

  const { journalList } = useSelector(({ people }) => people?.journalDetails ?? {});

  const { docs, page, limit, hasMoreRecords } = useMemo(() => journalList, [journalList]);

  const { getJournalListByPersonDetailIdActionLoader } = useSelector(
    ({ common }) => common?.generalLoader ?? {}
  );

  const getJournalListByFilter = (param) => {
    dispatch(getJournalListByPersonDetailIdAction(param));
  };

  const handleScroll = useCallback(
    (e) => {
      if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight && hasMoreRecords) {
        dispatch(
          updateJournalDetails({
            fieldName: 'journalList',
            value: { ...journalList, page: page + 1 },
          })
        );
        getJournalListByFilter({ personDetailId: params?.peopleId, page: page + 1, limit });
      }
    },

    [docs?.length, hasMoreRecords]
  );

  const handleDeleteJournalAction = (id) => {
    dispatch(
      deleteJournalAction({ journalId: id }, () =>
        getJournalListByFilter({ personDetailId: params?.peopleId, page, limit })
      )
    );
  };

  const handleEditJournalAction = (data) => {
    dispatch(
      updateJournalDetails({
        fieldName: 'addJournalDrawer',
        value: {
          isOpen: true,
          ratePerformance: data?.ratePerformance,
          journalEntry: data?.journalEntry,
          id: data?.id,
        },
      })
    );
  };

  useEffect(() => {
    if (params?.peopleId) getJournalListByFilter({ personDetailId: params?.peopleId, page, limit });

    return () => dispatch(clearJournalDetails());
  }, []);

  return (
    <div className="journalList-root-container " onScroll={handleScroll}>
      {getJournalListByPersonDetailIdActionLoader && <Loader />}
      {docs?.length > 0 ? (
        docs?.map((data) => (
          <div className="journal-details" key={data?.id}>
            <div>
              <div>
                {data?.createdByName} - {displayDate(data?.createdDate, data?.timeZoneId?.label)}
              </div>
              <div className="flex-center gap-10">
                {data?.createdByRoleId >= loggedInRole && (
                  <>
                    <span
                      className="material-icons text-secondary-color"
                      onClick={() => setDeleteModalData(data?.id)}
                    >
                      delete
                    </span>
                    <span
                      className="material-icons text-secondary-color"
                      onClick={() => handleEditJournalAction(data)}
                    >
                      edit
                    </span>
                  </>
                )}

                {data?.ratePerformance?.id === 1 ? (
                  <span className="material-icons primary-color">sentiment_satisfied_alt</span>
                ) : (
                  <span
                    className={`material-icons error-color ${
                      !data?.ratePerformance && 'visibility-off'
                    }`}
                  >
                    sentiment_very_dissatisfied
                  </span>
                )}
              </div>
            </div>
            <div className="body-text text-secondary-color">{data?.journalEntry}</div>
          </div>
        ))
      ) : (
        <div className="no-data-found">No data found</div>
      )}
      <AddJournalDrawer />
      <ConfirmationModal
        title="Delete Journal"
        isOpen={!isEmpty(deleteModalData) || !isNull(deleteModalData)}
        handleClose={() => {
          setDeleteModalData(null);
        }}
        handleSubmit={async () => {
          await handleDeleteJournalAction(deleteModalData);
          setDeleteModalData(null);
        }}
        primaryLabel="Delete"
      >
        <div>Are you sure you want to delete?</div>
      </ConfirmationModal>
    </div>
  );
}

JournalList.propTypes = {};

export default JournalList;
