import { isNaN, isNil, omitBy } from 'lodash';
import { requireValidate } from '../../../utils/validationHelper';

const validateAccountAndBill = ({
  name,
  email,
  address,
  numberOfUser,
  planDuration,
  minimumUser,
  isFromSave,
}) => {
  const { city, countryId, line1, postalCode, state } = address ?? {};
  const error = {};
  error.name = requireValidate('Name', name);
  error.email = requireValidate('Email', email);
  error.country = requireValidate('Country', countryId);
  error.state = requireValidate('State', state);
  error.city = requireValidate('City', city);
  error.postCode = requireValidate('Postcode', postalCode);
  error.addressLine1 = requireValidate('Address', line1);
  if (!isFromSave && (!numberOfUser || numberOfUser <= 0 || isNaN(numberOfUser))) {
    error.numberOfUser = 'Enter valid number of users!';
  } else if (planDuration === 2 && numberOfUser && numberOfUser < minimumUser && !isFromSave) {
    error.numberOfUser = `Number of users can't be less than existing number of users!`;
  }
  return omitBy(error, isNil);
};

export default validateAccountAndBill;
