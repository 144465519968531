import { NavLink } from 'react-router-dom';
import { getAuthTokenFromLocalStorage } from '../../../utils/localStorageHelper';
import EzyButton from '../button/EzyButton';
import errorImage from '../../../assets/images/No results found.svg';

function NoPageFound() {
  const token = getAuthTokenFromLocalStorage();
  return (
    <div className="error-page-container">
      <img src={errorImage} alt="No Page Found" />
      <div className="title">The page you are looking for was not found.</div>
      <div className="sub-title">
        Please check your URL or return to {token ? 'dashboard' : 'login'}.
      </div>
      <NavLink to="/" className="nav-link">
        <EzyButton label={`Back to ${token ? 'dashboard' : 'login'}`} />
      </NavLink>
    </div>
  );
}

export default NoPageFound;
