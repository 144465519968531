import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import EzyDrawer from '../../../components/common/drawer/Drawer';
import { resetTimeSheetData } from '../redux/timeSheetsSlice';
import {
  changeTimeSheetStatusAction,
  getTimeSheetViewDetailsAction,
} from '../redux/timeSheetsAction';
import { timeSheetActionButtons, timeSheetActions } from '../../../utils/constants';
import { displayDate, displayTime } from '../../../utils/timeHelper';
import useAccess from '../../../hooks/useAccess';
import IconByName from '../../../components/common/iconByName/IconByName';

function ViewTimeSheetDrawer({ getTimeSheetsList, isFromProfile }) {
  const { viewTimeSheetDrawer, viewTimeSheetData } = useSelector(
    ({ timeSheets }) => timeSheets?.timeSheetData
  );
  const dispatch = useDispatch();

  const hasAccess = useAccess('APPROVETS');

  const {
    timeSheetStatusDISCARDLoader,
    // eslint-disable-next-line camelcase
    timeSheetStatusUN_APPROVE_TIMELoader,
    // eslint-disable-next-line camelcase
    timeSheetStatusUN_APPROVE_PAYLoader,
    // eslint-disable-next-line camelcase
    timeSheetStatusRE_INSTATELoader,
    timeSheetStatusAPPROVELoader,
    getTimeSheetViewDetailsActionLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});

  const getBreakDataString = (data, breakType) => {
    const output = data?.[`${breakType}`]?.map((e) => {
      if ((e?.duration || e?.duration >= 0) && (e?.breakStartTime || e?.breakEndTime)) {
        return `${displayTime(
          e?.breakStartTime,
          viewTimeSheetData?.timeZoneId?.label
        )} - ${displayTime(e?.breakEndTime, viewTimeSheetData?.timeZoneId?.label)} (${
          e?.duration
        } mins)`;
      }
      return `(${e?.duration} mins)`;
    });
    if (output?.length > 0) {
      return output?.join(', ');
    }
    return '(0 mins)';
  };

  const viewData = useMemo(() => {
    const output = viewTimeSheetData?.timeSheetBreakDetailEntities?.reduce((obj, el) => {
      const property = el?.breakType;
      if (!obj?.[property]) {
        obj[property] = [];
      }
      obj[property].push(el);
      return obj;
    }, {});

    return {
      timeSheetPhotoURL: viewTimeSheetData?.timeSheetPhotoURL || null,
      value: [
        {
          title: 'Date',
          value: displayDate(viewTimeSheetData?.date, viewTimeSheetData?.timeZoneId?.label),
        },
        {
          title: 'Area',
          value: viewTimeSheetData?.areaName || '-',
        },
        {
          title: 'Time',
          value:
            `${displayTime(
              viewTimeSheetData?.startTime,
              viewTimeSheetData?.timeZoneId?.label
            )} - ${displayTime(
              viewTimeSheetData?.endTime,
              viewTimeSheetData?.timeZoneId?.label
            )}` || '-',
        },
        {
          title: 'Break summary',
          value: `Meal break : ${viewTimeSheetData?.totalMealBreak || '0'} mins, Rest break : ${
            viewTimeSheetData?.totalRestBreak || '0'
          } mins`,
        },
        {
          title: 'Meal break',
          value: getBreakDataString(output, 1),
        },
        {
          title: 'Rest break',
          value: getBreakDataString(output, 2),
        },
        {
          title: 'Pay rates',
          value: viewTimeSheetData?.payRates || '-',
        },
      ],
    };
  }, [viewTimeSheetData]);

  const attachedShiftDetails = useMemo(() => {
    const output = viewTimeSheetData?.attachedShift?.shiftBreakDetails?.reduce((obj, el) => {
      const property = el?.breakType;
      if (!obj?.[property]) {
        obj[property] = [];
      }
      obj[property].push(el);
      return obj;
    }, {});

    return {
      value: [
        {
          title: 'Date',
          value: displayDate(
            viewTimeSheetData?.attachedShift?.date,
            viewTimeSheetData?.timeZoneId?.label
          ),
        },
        {
          title: 'Area',
          value: viewTimeSheetData?.attachedShift?.areaName || '-',
        },
        {
          title: 'Time',
          value:
            `${displayTime(
              viewTimeSheetData?.attachedShift?.startTime,
              viewTimeSheetData?.timeZoneId?.label
            )} - ${displayTime(
              viewTimeSheetData?.attachedShift?.endTime,
              viewTimeSheetData?.timeZoneId?.label
            )}` || '-',
        },
        {
          title: 'Break summary',
          value: `Meal break : ${
            viewTimeSheetData?.attachedShift?.totalMealBreak || '0'
          } mins, Rest break : ${viewTimeSheetData?.attachedShift?.totalRestBreak || '0'} mins`,
        },
        {
          title: 'Meal break',
          value: getBreakDataString(output, 1),
        },
        {
          title: 'Rest break',
          value: getBreakDataString(output, 2),
        },
      ],
    };
  }, [viewTimeSheetData]);

  const handleClose = () => {
    dispatch(resetTimeSheetData());
  };

  const handleStatusChange = (status) => {
    dispatch(
      changeTimeSheetStatusAction(
        { timeSheetAction: status, timeSheetIds: [viewTimeSheetData?.id] },
        () => {
          getTimeSheetsList();
          handleClose();
        }
      )
    );
  };

  const actionButton = useMemo(() => {
    const buttons = [];
    const { status, progressStatus } = viewTimeSheetData ?? {};

    if (hasAccess) {
      if (status === 2) {
        /* Approve && (Time Approve && Pay Approve) */
        if ([3, 5]?.includes(progressStatus)) {
          buttons.push({
            color: 'secondary',
            label: timeSheetActionButtons.unApproveTime,
            onClick: () => handleStatusChange(timeSheetActions.unApproveTime),
            // eslint-disable-next-line camelcase
            loading: timeSheetStatusUN_APPROVE_TIMELoader,
          });
        }
        /* Approve && (Pay Approve) */
        if ([3]?.includes(progressStatus)) {
          buttons.push({
            color: 'secondary',
            label: timeSheetActionButtons.unApprovePay,
            onClick: () => handleStatusChange(timeSheetActions.unApprovePay),
            // eslint-disable-next-line camelcase
            loading: timeSheetStatusUN_APPROVE_PAYLoader,
          });
        }
      }
      /* Pending && (Time Approve && Submitted) */
      if (status === 1) {
        if ([2, 5]?.includes(progressStatus)) {
          buttons.push({
            color: 'success',
            label: timeSheetActionButtons.approve,
            onClick: () => handleStatusChange(timeSheetActions.approve),
            loading: timeSheetStatusAPPROVELoader,
          });
        }
      }
      /* Discarded */
      if (status === 4) {
        buttons.push({
          color: 'success',
          label: timeSheetActionButtons.reInstate,
          onClick: () => handleStatusChange(timeSheetActions.reInstate),
          // eslint-disable-next-line camelcase
          loading: timeSheetStatusRE_INSTATELoader,
        });
      }
      /* Not Discarded */
      if (status !== 4) {
        buttons.push({
          label: timeSheetActionButtons.discard,
          color: 'error',
          onClick: () => handleStatusChange(timeSheetActions.discard),
          loading: timeSheetStatusDISCARDLoader,
        });
      }
    }

    return buttons;
  }, [
    viewTimeSheetData,
    timeSheetStatusDISCARDLoader,
    timeSheetStatusAPPROVELoader,
    // eslint-disable-next-line camelcase
    timeSheetStatusRE_INSTATELoader,
    // eslint-disable-next-line camelcase
    timeSheetStatusUN_APPROVE_PAYLoader,
    // eslint-disable-next-line camelcase
    timeSheetStatusUN_APPROVE_TIMELoader,
  ]);

  useEffect(() => {
    if (viewTimeSheetDrawer) {
      dispatch(getTimeSheetViewDetailsAction({ timesheetId: viewTimeSheetDrawer }));
    }
  }, [viewTimeSheetDrawer]);

  return (
    <EzyDrawer
      onClose={handleClose}
      isOpen={viewTimeSheetDrawer}
      heading="View Timesheet"
      actionButtons={isFromProfile ? [] : actionButton}
      isLoading={getTimeSheetViewDetailsActionLoader}
    >
      <div className="view-timesheet-drawer">
        {viewTimeSheetDrawer && viewTimeSheetData?.isAutomaticallyClosed && (
          <div className="add-timesheet-fields-grid">
            <div className="info-div info body-text">
              <IconByName name="error" />
              <div>Automatically closed because employee did not finish their shift.</div>
            </div>
          </div>
        )}

        {viewTimeSheetData?.status === 4 && (
          <div className="add-timesheet-fields-grid">
            <div className="info-div info">
              <IconByName name="error" />
              <div>Timesheet has been discarded, you may reinstate it</div>
            </div>
          </div>
        )}

        {viewTimeSheetData?.attachedShift && (
          <div className="view-timesheet-data-wrapper">
            <div className="title-text">Scheduled Details</div>
            {attachedShiftDetails?.value?.map(({ title, value }, index) => (
              <div className="data-wrapper" key={index}>
                <div className="title">{title} :&nbsp;</div>
                <div className="value">{value || ' - '}</div>
              </div>
            ))}
          </div>
        )}
        {viewTimeSheetData?.progressStatus &&
          [1, 2, 3, 5].includes(viewTimeSheetData?.progressStatus) && (
            <div className="timesheet-wrapper">
              <div className="view-timesheet-data-wrapper">
                {viewData?.value?.map(({ title, value }, index) => (
                  <div className="data-wrapper" key={index}>
                    <div className="title">{title} :&nbsp;</div>
                    <div className="value">{value || ' - '}</div>
                  </div>
                ))}
              </div>
              {viewData?.timeSheetPhotoURL && (
                // eslint-disable-next-line jsx-a11y/img-redundant-alt
                <img src={viewData?.timeSheetPhotoURL} alt="Timesheet photo" />
              )}
            </div>
          )}
      </div>
    </EzyDrawer>
  );
}

export default ViewTimeSheetDrawer;

ViewTimeSheetDrawer.propTypes = {
  getTimeSheetsList: PropTypes.func.isRequired,
  isFromProfile: PropTypes.bool,
};

ViewTimeSheetDrawer.defaultProps = {
  isFromProfile: false,
};
