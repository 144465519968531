import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import EzyTable from '../../../../components/common/table/EzyTable';
import EzyPagination from '../../../../components/common/pagination/EzyPagination';
import { receiptHeaders } from '../../globalProfileHeaders';
import useUrlParamsUpdate from '../../../../hooks/useURLParamsUpdate';
import useQueryParams from '../../../../hooks/useQueryParams';
import { getAllReceiptAction } from '../../redux/globalProfileActions';

function Receipts() {
  const { receiptList } = useSelector(({ globalProfile }) => globalProfile?.receipts || {});
  const { getAllReceiptsActionLoader } = useSelector(({ common }) => common.generalLoader ?? {});
  const dispatch = useDispatch();
  const { paramId } = useParams();

  const { page: paramPage, limit: paramLimit } = useQueryParams();
  const { data: docs, total, pages, page, limit } = useMemo(() => receiptList ?? {}, [receiptList]);

  useUrlParamsUpdate({
    page: page ?? 1,
    limit: limit ?? 15,
  });

  const getReceiptListByFilter = async (params = {}) => {
    const data = {
      page: page ?? 1,
      limit: limit ?? 15,
      businessId: paramId,
      ...params,
    };
    await dispatch(getAllReceiptAction(data));
  };

  const pageActionClick = async (newPage) => {
    await getReceiptListByFilter({ page: newPage, limit });
  };
  const onSelectLimit = async (newLimit) => {
    await getReceiptListByFilter({ page: 1, limit: newLimit });
  };

  useEffect(() => {
    (async () => {
      const params = {
        page: paramPage ?? page ?? 1,
        limit: paramLimit ?? limit ?? 15,
      };
      await getReceiptListByFilter({ ...params });
    })();
  }, []);

  return (
    <div className="invoice-root-container">
      <div className="p-x-24 invoice-table-wrapper">
        <Paper className="table-paper">
          <EzyTable
            headers={receiptHeaders}
            rows={docs}
            loading={getAllReceiptsActionLoader}
            accessForRow={['default']}
            accessForAction={['default']}
          />
          <EzyPagination
            total={total}
            pages={pages}
            pageActionClick={pageActionClick}
            limit={limit}
            page={page}
            onChangeLimit={onSelectLimit}
          />
        </Paper>
      </div>
    </div>
  );
}

export default Receipts;
