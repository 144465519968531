import { toLower } from 'lodash';
import { peopleServices } from '../peopleServices';
import {
  editLeaveDetails,
  editPeopleDetails,
  importPeopleFromIntegrationFrPeopleModule,
  resetAddPeopleFields,
  setAddLeaveDetails,
  setAreaListByPerson,
  setExtraLeaveDetails,
  setImportedPeopleData,
  setJournalList,
  setLeaveBalanceList,
  setLeaveEntitlementEnum,
  setLeaveRequestList,
  setPayRateData,
  setPeopleDetails,
  setPeopleForOfferShift,
  setPeopleList,
  setPeopleListFiltersByBusiness,
  setPreviousShiftsList,
  setUpComingShiftsList,
  setWorkPeriodsByBusinessId,
  updateAddPeopleFields,
  updateEditPeopleShiftDetails,
  updateEditPeopleShiftDetailsField,
  updateJournalDetails,
  updateJournalDocs,
  setUnavailabilityList,
  updateUnavailabilityDocs,
  updatePeoplePayDetailsFields,
} from './peopleSlice';
import { displayErrors } from '../../../utils/errorNotifyHelper';
import { successNotification } from '../../../utils/notifyHelper';
import { mapEnumToIds, mapPayRateId, mapPeopleDetailIds } from '../../../utils/enumMapping';
import { downloadAll } from '../../../utils/downloadHelper';
import {
  startGeneralLoader,
  stopGeneralLoader,
} from '../../../utils/backOfficeLists/commonDataSlice';
import { updateTimeSheetData } from '../../timeSheets/redux/timeSheetsSlice';
import { formatPhoneToSend } from '../../../utils/phoneNumberHelper';
import dashboardServices from '../../home/dashboardServices';
import { updateUserData } from '../../auth/redux/userSlice';

export const getPeopleList = (params = { page: 1, limit: 15 }) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const finalParams = {
        ...params,
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getPeopleListLoader' }));
      const res = await peopleServices.getPeopleList(finalParams);
      if (res?.data?.success) {
        const data = mapEnumToIds(
          [
            { fieldName: 'timeZoneId', listName: 'timeZonesList', isFromEnum: false },
            { fieldName: 'invitation', listName: 'invitationStatus', isFromEnum: true },
          ],
          res?.data,
          state?.common
        );
        dispatch(setPeopleList(data));
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getPeopleListLoader' }));
    }
  };
};

export const getPeopleFilterByBusinessIdAction = () => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const finalParams = {
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getPeopleFilterByBusinessIdActionLoader' }));
      const res = await peopleServices.getPeopleFilterByBusinessId(finalParams);
      if (res?.data?.success) {
        dispatch(setPeopleListFiltersByBusiness(res?.data?.data));
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getPeopleFilterByBusinessIdActionLoader' }));
    }
  };
};

export const addPeopleData = (data, isImporting = false) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'addPeopleDataLoader' }));
      const res = await peopleServices.addPeopleData(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        return true;
      }
      return false;
    } catch (e) {
      if (e?.response?.data?.data?.length > 0 && !isImporting) {
        const errors = data?.people?.map((people) =>
          e?.response?.data?.data?.includes(toLower(people?.email))
            ? { email: 'Email already exists!' }
            : null
        );
        dispatch(updateAddPeopleFields({ fieldName: 'errors', value: { people: errors } }));
      } else if (isImporting) throw e;
      else displayErrors(e);
      return false;
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'addPeopleDataLoader' }));
    }
  };
};

export const importPeopleCSVFile = (data, config) => {
  return async (dispatch, getState) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'importPeopleCSVFileLoader' }));
      const response = await peopleServices.importPeopleCSVFile(data, config);
      if (response?.data?.success) {
        const dataToUpdate = response?.data?.data
          .filter((e) => !e.errorMessage)
          .map((e) => ({
            name: e.name,
            email: e.email,
            phoneNumber: formatPhoneToSend(e.phoneNumber, e.countryCode),
            countryCode: e.countryCode,
            accessLevel: 5,
          }));
        if (dataToUpdate?.length > 0) {
          const businessId = getState()?.user?.profileData?.businessId;
          const locationId = getState()?.people?.addPeople?.location?.id;
          const finalData = {
            locationId,
            businessId,
            sendInvitationEmail: true,
            people: dataToUpdate,
          };
          const isSuccess = await dispatch(addPeopleData(finalData, true));
          if (isSuccess) {
            dispatch(resetAddPeopleFields());
          }
        }
        dispatch(setImportedPeopleData(response?.data?.data));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'importPeopleCSVFileLoader' }));
    }
  };
};

export const getWorkPeriodsByBusinessId = () => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const finalParams = {
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getWorkPeriodsByBusinessIdLoader' }));
      const res = await peopleServices.getWorkPeriodsByBusinessId(finalParams);
      if (res?.data?.success) {
        dispatch(setWorkPeriodsByBusinessId(res?.data?.data));
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getWorkPeriodsByBusinessIdLoader' }));
    }
  };
};

export const getPeopleDetailsAction = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getPeopleDetailsActionLoader' }));
      const res = await peopleServices.getPeopleDetails({ personDetailId: id });
      if (res?.data?.success) {
        const data = mapEnumToIds(mapPeopleDetailIds, res?.data?.data, state?.common);
        dispatch(setPeopleDetails(data));
        dispatch(
          updateJournalDetails({
            fieldName: 'personDetail',
            value: { personName: data?.preferredFullName, roleId: data?.roleId?.id },
          })
        );
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getPeopleDetailsActionLoader' }));
    }
  };
};

export const getPeoplePayDetailsAction = (id, awardList) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getPeoplePayDetailsActionLoader' }));
      const res = await peopleServices.GetPeoplePayDetails({ personDetailId: id });
      if (res?.data?.success) {
        const data = mapEnumToIds(mapPayRateId, res?.data?.data, state?.common);
        if (state?.user?.profileData?.countryId === 13) {
          const finalData = mapEnumToIds(
            [
              {
                fieldName: 'awardCode',
                listName: 'awardList',
                isFromEnum: false,
              },
            ],
            res?.data?.data,
            awardList
          );
          dispatch(setPayRateData(finalData));
        } else {
          dispatch(setPayRateData(data));
        }
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getPeoplePayDetailsActionLoader' }));
    }
  };
};
export const getAreaListByPersonAction = (id) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(startGeneralLoader({ loaderFor: 'getAreaListByPersonActionLoader' }));
      const res = await peopleServices.getAreaListByPerson({ personDetailId: id });
      if (res?.data?.success) {
        const data = mapEnumToIds(
          [{ fieldName: 'timeZoneId', listName: 'timeZonesList', isFromEnum: false }],
          res?.data?.data,
          state?.common
        );
        await dispatch(setAreaListByPerson(data));
        await dispatch(updateTimeSheetData({ fieldName: 'areaList', value: data }));
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getAreaListByPersonActionLoader' }));
    }
  };
};

export const addNewWorkPeriodAction = (data, cb) => {
  return async (dispatch, getState) => {
    const state = getState();
    const finalData = { ...data, businessId: state?.user?.profileData?.businessId };
    try {
      dispatch(startGeneralLoader({ loaderFor: 'addNewWorkPeriodActionLoader' }));
      const res = await peopleServices.addWorkPeriod(finalData);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb(res?.data?.data);
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'addNewWorkPeriodActionLoader' }));
    }
  };
};

export const editPeopleDetailsAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'editPeopleDetailsActionLoader' }));
      const res = await peopleServices.editPeopleDetails(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb(res?.data?.data);
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'editPeopleDetailsActionLoader' }));
    }
  };
};

export const editPeoplePayDetailsAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'editPeoplePayDetailsActionLoader' }));
      const res = await peopleServices.editPeoplePayDetails(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'editPeoplePayDetailsActionLoader' }));
    }
  };
};

export const addBulkPeopleLocation = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'addBulkPeopleLocationLoader' }));
      const response = await peopleServices.addBulkLocation(data);
      if (response?.data?.success) {
        successNotification(response?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'addBulkPeopleLocationLoader' }));
    }
  };
};

export const addBulkPeopleAccess = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'addBulkPeopleAccessLoader' }));
      const response = await peopleServices.addBulkAccess(data);
      if (response?.data?.success) {
        successNotification(response?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'addBulkPeopleAccessLoader' }));
    }
  };
};

export const bulkSendInvitationAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'bulkSendInvitationActionLoader' }));
      const response = await peopleServices.bulkSendInvitation(data);
      if (response?.data?.success) {
        successNotification(response?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'bulkSendInvitationActionLoader' }));
    }
  };
};

export const getLeaveBalanceByPersonId = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getLeaveBalanceByPersonIdLoader' }));
      const res = await peopleServices.getLeaveBalanceList(params);
      if (res?.data?.success) {
        dispatch(setLeaveBalanceList(res?.data));
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getLeaveBalanceByPersonIdLoader' }));
    }
  };
};

export const getLeaveRequestByPersonId = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getLeaveRequestByPersonIdLoader' }));
      const res = await peopleServices.getLeaveRequestList(params);
      if (res?.data?.success) {
        dispatch(setLeaveRequestList(res?.data));
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getLeaveRequestByPersonIdLoader' }));
    }
  };
};

export const getUpcomingShiftsAction = (params) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(startGeneralLoader({ loaderFor: 'getUpcomingShiftsActionLoader' }));
      const res = await peopleServices.getUpcomingShifts(params);
      if (res?.data?.success) {
        const data = mapEnumToIds(
          [{ fieldName: 'timeZoneId', listName: 'timeZonesList', isFromEnum: false }],
          res?.data,
          state?.common
        );

        dispatch(setUpComingShiftsList(data));
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getUpcomingShiftsActionLoader' }));
    }
  };
};
export const getPreviousShiftsAction = (params) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(startGeneralLoader({ loaderFor: 'getPreviousShiftsActionLoader' }));
      const res = await peopleServices.getPreviousShifts(params);
      if (res?.data?.success) {
        const data = mapEnumToIds(
          [{ fieldName: 'timeZoneId', listName: 'timeZonesList', isFromEnum: false }],
          res?.data,
          state?.common
        );
        dispatch(setPreviousShiftsList(data));
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getPreviousShiftsActionLoader' }));
    }
  };
};

export const getLeaveEntitlementEnumByPersonIdAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getLeaveEntitlementEnumByPersonIdActionLoader' }));
      const res = await peopleServices.getLeaveEntitlementEnumByPersonId(params);
      if (res?.data?.success) {
        dispatch(
          setLeaveEntitlementEnum({
            enumFor: 'leaveEntitlementEnumByPersonId',
            data: res?.data?.data,
          })
        );
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getLeaveEntitlementEnumByPersonIdActionLoader' }));
    }
  };
};

export const getLeaveEntitlementEnumByBusinessIdAction = (params) => {
  return async (dispatch, getState) => {
    const state = getState();
    const finalParams = {
      ...params,
      businessId: state?.user?.profileData?.businessId,
    };
    try {
      dispatch(
        startGeneralLoader({ loaderFor: 'getLeaveEntitlementEnumByBusinessIdActionLoader' })
      );
      const res = await peopleServices.getLeaveEntitlementEnumByBusinessId(finalParams);
      if (res?.data?.success) {
        dispatch(
          setLeaveEntitlementEnum({
            enumFor: 'leaveEntitlementEnumByBusinessId',
            data: res?.data?.data,
          })
        );
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getLeaveEntitlementEnumByBusinessIdActionLoader' }));
    }
  };
};

export const getPeopleForOfferShiftAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(
        startGeneralLoader({
          loaderFor: params?.searchText
            ? 'getPeopleForOfferShiftWithSearchTextActionLoader'
            : 'getPeopleForOfferShiftActionLoader',
        })
      );
      const res = await peopleServices.getPeopleForOfferShift(params);
      if (res?.data?.success) {
        dispatch(setPeopleForOfferShift(res?.data?.data));
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(
        stopGeneralLoader({
          loaderFor: params?.searchText
            ? 'getPeopleForOfferShiftWithSearchTextActionLoader'
            : 'getPeopleForOfferShiftActionLoader',
        })
      );
    }
  };
};

export const sendShiftOfferInvitationAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'sendShiftOfferInvitationActionLoader' }));
      const response = await peopleServices.sendShiftOfferInvitation(data);
      if (response?.data?.success) {
        successNotification(response?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'sendShiftOfferInvitationActionLoader' }));
    }
  };
};
export const cancelShiftOfferInvitationAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'cancelShiftOfferInvitationActionLoader' }));
      const response = await dashboardServices.cancelShiftOfferInvitation(data);
      if (response?.data?.success) {
        successNotification(response?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'cancelShiftOfferInvitationActionLoader' }));
    }
  };
};

export const addEditLeaveBalanceAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'addEditLeaveBalanceActionLoader' }));
      const response = await peopleServices.addEditLeaveBalance(data);
      if (response?.data?.success) {
        successNotification(response?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'addEditLeaveBalanceActionLoader' }));
    }
  };
};

export const addLeaveRequestAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'addLeaveRequestActionLoader' }));
      const response = await peopleServices.addLeaveRequest(data);
      if (response?.data?.success) {
        successNotification(response?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'addLeaveRequestActionLoader' }));
    }
  };
};

export const deletePeopleByIdAction = (params, cb) => {
  const finalParams = {
    personDetailId: params,
  };
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'deletePeopleByIdActionLoader' }));
      const res = await peopleServices.deletePeopleById(finalParams);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        if (cb && typeof cb === 'function') {
          cb();
        }
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deletePeopleByIdActionLoader' }));
    }
  };
};

export const deletePeopleLeave = (params, cb) => {
  const finalParams = {
    leaveEntitlementId: params,
  };
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'deletePeopleLeaveLoader' }));
      const res = await peopleServices.deletePeopleLeave(finalParams);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deletePeopleLeaveLoader' }));
    }
  };
};

export const getDetailsOnChangeLeaveDate = (params) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(startGeneralLoader({ loaderFor: 'getDetailsOnChangeLeaveDateLoader' }));
      const response = await peopleServices.getExtraDetailsOnChangeLeaveDate(params);

      if (response?.data?.success) {
        const data = mapEnumToIds(
          [{ fieldName: 'timeZoneId', listName: 'timeZonesList', isFromEnum: false }],
          response?.data?.data,
          state?.common
        );

        dispatch(setExtraLeaveDetails(data));
        successNotification(response?.data?.message);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getDetailsOnChangeLeaveDateLoader' }));
    }
  };
};

export const getLeaveDetailsByLeaveRequestIdAction = (params) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(startGeneralLoader({ loaderFor: 'getLeaveDetailsByLeaveRequestIdActionLoader' }));
      const response = await peopleServices.getLeaveDetailsByLeaveRequestId(params);

      const data = mapEnumToIds(
        [{ fieldName: 'timeZoneId', listName: 'timeZonesList', isFromEnum: false }],
        response?.data?.data,
        state?.common
      );

      if (response?.data?.success) {
        dispatch(setAddLeaveDetails(data));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getLeaveDetailsByLeaveRequestIdActionLoader' }));
    }
  };
};

export const editLeaveRequestAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'editLeaveRequestActionLoader' }));
      const response = await peopleServices.editLeaveRequest(data);
      if (response?.data?.success) {
        successNotification(response?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'editLeaveRequestActionLoader' }));
    }
  };
};

export const leaveRequestActionsAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: `leaveStatus${data?.leaveAction}Loader` }));
      const response = await peopleServices.leaveRequestActions(data);
      if (response?.data?.success) {
        successNotification(response?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: `leaveStatus${data?.leaveAction}Loader` }));
    }
  };
};
export const downloadCSV = (data) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'downloadCSVLoader' }));
      const res = await peopleServices.downloadBlukCsv(data);
      if (res?.data) {
        successNotification('File received successfully');
        downloadAll(res);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'downloadCSVLoader' }));
    }
  };
};

export const sendPasswordToMail = (email) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'sendPasswordToMailLoader' }));
      const response = await peopleServices.sendPasswordToMail(email);
      if (response?.data?.success) {
        dispatch(setAddLeaveDetails(response?.data?.data));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'sendPasswordToMailLoader' }));
    }
  };
};

export const archivePeopleAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'archivePeopleActionLoader' }));
      const response = await peopleServices.archivePeople(data);
      if (response?.data?.success) {
        successNotification(response?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'archivePeopleActionLoader' }));
    }
  };
};

export const resetLoginDetailsAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'resetLoginDetailsActionLoader' }));
      const response = await peopleServices.resetLoginDetails(data);
      if (response?.data?.success) {
        successNotification(response?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'resetLoginDetailsActionLoader' }));
    }
  };
};

export const getNotInvitedPeopleListAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getNotInvitedPeopleListLoader' }));
      const response = await peopleServices.getNotInvitedPeopleList(params);
      if (response?.data?.success) {
        return response.data.data;
      }
      return null;
    } catch (e) {
      displayErrors(e);
      return null;
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getNotInvitedPeopleListLoader' }));
    }
  };
};

export const uploadProfilePicture = (data, config) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'uploadProfilePictureLoader' }));
      const response = await peopleServices.uploadProfilePicture(data, config);
      if (response?.data?.success) {
        dispatch(editPeopleDetails({ fieldName: 'profilePhotoURL', value: response.data.data }));
        successNotification(response?.data?.message);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'uploadProfilePictureLoader' }));
    }
  };
};

export const deleteProfilePictureAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'deleteProfilePictureActionLoader' }));
      const response = await peopleServices.deleteProfilePicture(params);
      if (response?.data?.success) {
        dispatch(editPeopleDetails({ fieldName: 'profilePhotoURL', value: null }));
        successNotification(response?.data?.message);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deleteProfilePictureActionLoader' }));
    }
  };
};

export const sendVerificationEmailAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'sendVerificationEmailActionLoader' }));
      const response = await peopleServices.sendVerificationMail(data);
      if (response?.data?.success) {
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'sendVerificationEmailActionLoader' }));
    }
  };
};

export const getShiftDetailsByPersonID = (personID) => {
  const params = { personDetailId: personID };
  return async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getShiftDetails' }));
      const response = await peopleServices.getShiftDetails(params);
      if (response?.data?.success) {
        const data = mapEnumToIds(
          [
            { fieldName: 'breakType', listName: 'breakType', isFromEnum: true },
            { fieldName: 'timeZoneId', listName: 'timeZonesList', isFromEnum: false },
          ],
          response?.data?.data,
          state?.common
        );

        dispatch(updateEditPeopleShiftDetails(data));
        dispatch(editLeaveDetails({ fieldName: 'timeZoneId', value: data?.timeZoneId }));
        dispatch(updateJournalDetails({ fieldName: 'personName', value: data?.preferdFullName }));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getShiftDetails' }));
    }
  };
};

export const getShiftDetailsArea = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getShiftDetailsAreaLoader' }));
      const response = await peopleServices.startShift(data);
      if (response?.data?.success) {
        const list = response.data.data.areaList;
        if (response.data.data.areaList) {
          const areaList = list.map((res) => {
            return {
              ...res,
              label: res.areaName,
            };
          });
          dispatch(
            updateEditPeopleShiftDetails({
              ...response?.data?.data,
              areaList,
            })
          );
        } else {
          dispatch(updateEditPeopleShiftDetails(response?.data?.data));
          cb();
        }
      }
    } catch (e) {
      if (e?.response?.status === 406) {
        dispatch(
          updateEditPeopleShiftDetailsField({
            fieldName: 'earlyShiftStartWarning',
            value: e?.response?.data?.message,
          })
        );
      }
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getShiftDetailsAreaLoader' }));
    }
  };
};

export const getEndShiftDetailsByTimeSheetID = (params) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getEndShiftDetails' }));
      const response = await peopleServices.getEndShiftDetails(params);
      if (response?.data?.success) {
        const newRes = mapEnumToIds(
          [{ fieldName: 'breakType', listName: 'breakType', isFromEnum: true }],
          response?.data?.data,
          state?.common
        );
        const mapIsCustom = newRes?.breakDetails?.map((res) => {
          if (res.startTime) {
            return { ...res, isCustom: true };
          }
          return { ...res, isCustom: false };
        });
        dispatch(
          updateEditPeopleShiftDetailsField({
            fieldName: 'endShiftDetails',
            value: { ...newRes, breakDetails: mapIsCustom },
          })
        );
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getEndShiftDetails' }));
    }
  };
};
export const editPeopleEndShift = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'editPeopleEndShiftLoader' }));
      const response = await peopleServices.endShift(data);
      if (response?.data?.success) {
        dispatch(
          updateEditPeopleShiftDetailsField({
            fieldName: 'endShiftDetails',
            value: response?.data?.data,
          })
        );
        cb();
      }
    } catch (e) {
      if (e?.response?.status === 417) {
        dispatch(
          updateEditPeopleShiftDetailsField({
            fieldName: 'earlyEndShiftWarningModal',
            value: true,
          })
        );
        dispatch(
          updateEditPeopleShiftDetailsField({ fieldName: 'isEditShiftDrawerEnable', value: false })
        );
      }
      if (e?.response?.status === 406) {
        dispatch(
          updateEditPeopleShiftDetailsField({
            fieldName: 'lessTimeWarning',
            value: true,
          })
        );
      } else {
        dispatch(
          updateEditPeopleShiftDetailsField({
            fieldName: 'lessTimeWarning',
            value: false,
          })
        );
      }

      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'editPeopleEndShiftLoader' }));
    }
  };
};
export const editPeopleStartBreak = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'editPeopleStartBreakLoader' }));
      const response = await peopleServices.startShiftBreak(data);
      if (response?.data?.success) {
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'editPeopleStartBreakLoader' }));
    }
  };
};
export const editPeopleEndBreak = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'editPeopleEndBreak' }));
      const response = await peopleServices.endShiftBreak(data);
      if (response?.data?.success) {
        cb();
      }
    } catch (e) {
      if (e?.response?.status === 406) {
        dispatch(
          updateEditPeopleShiftDetailsField({
            fieldName: 'lessTimeBreakWarningModal',
            value: true,
          })
        );
      } else {
        displayErrors(e);
      }
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'editPeopleEndBreak' }));
    }
  };
};

export const importPeopleFromIntegrationAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'importPeopleFromIntegrationLoader' }));
      const res = await peopleServices.importPeopleFromIntegration(params);
      if (res.data?.success) {
        dispatch(importPeopleFromIntegrationFrPeopleModule(res?.data?.data));
      }
    } catch (e) {
      dispatch(
        updateAddPeopleFields({ fieldName: 'warningMessage', value: e?.response?.data?.message })
      );

      dispatch(
        updateAddPeopleFields({ fieldName: 'isWarningModal', value: params?.integrationId })
      );
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'importPeopleFromIntegrationLoader' }));
    }
  };
};

export const getJournalListByPersonDetailIdAction = (params) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(startGeneralLoader({ loaderFor: 'getJournalListByPersonDetailIdActionLoader' }));
      const res = await peopleServices.getJournalListByPersonDetailId(params);
      if (res?.data?.success) {
        const data = mapEnumToIds(
          [
            { fieldName: 'ratePerformance', listName: 'ratePerformance', isFromEnum: true },
            { fieldName: 'timeZoneId', listName: 'timeZonesList', isFromEnum: false },
          ],
          res?.data,
          state?.common
        );
        if (params?.page < 2) dispatch(setJournalList(data));
        else dispatch(updateJournalDocs(data));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getJournalListByPersonDetailIdActionLoader' }));
    }
  };
};

export const addJournalAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'addJournalActionLoader' }));
      const res = await peopleServices.addJournal(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'addJournalActionLoader' }));
    }
  };
};

export const editJournalAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'editJournalActionLoader' }));
      const res = await peopleServices.editJournal(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'editJournalActionLoader' }));
    }
  };
};

export const deleteJournalAction = (params, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'deleteJournalActionLoader' }));
      const res = await peopleServices.deleteJournal(params);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deleteJournalActionLoader' }));
    }
  };
};

export const addUnavailabilityAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'addUnavailabilityActionLoader' }));
      const response = await peopleServices.addUnavailability(data);
      if (response?.data?.success) {
        successNotification(response?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'addUnavailabilityActionLoader' }));
    }
  };
};

export const getUnavailabilityListAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getUnavailabilityListLoader' }));
      const res = await peopleServices.getUnavailabilityList(params);
      if (res?.data?.success) {
        if (params?.page < 2) dispatch(setUnavailabilityList(res?.data));
        else dispatch(updateUnavailabilityDocs(res?.data));
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getUnavailabilityListLoader' }));
    }
  };
};

export const deleteUnavailabilityByIdAction = (params, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'deleteUnavailabilityByIdActionLoader' }));
      const res = await peopleServices.deleteUnavailabilityById(params);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        if (cb && typeof cb === 'function') {
          cb();
        }
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deleteUnavailabilityByIdActionLoader' }));
    }
  };
};

export const syncWithXeroAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'syncWithXeroActionLoader' }));
      const res = await peopleServices.syncWithXero(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'syncWithXeroActionLoader' }));
    }
  };
};

export const getAllFairWorkAwardsAction = (cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getAllFairWorkAwardsActionLoader' }));
      const res = await peopleServices.getAllFairWorkAwards();
      if (res?.data?.success) {
        dispatch(
          updatePeoplePayDetailsFields({
            fieldName: 'awardsList',
            value: res?.data?.data,
          })
        );
        cb(res?.data?.data);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getAllFairWorkAwardsActionLoader' }));
    }
  };
};

export const getAllWageAllowancesAction = (params, index) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getAllWageAllowancesActionLoader' }));
      const res = await peopleServices.getAllWageAllowances(params);
      if (res?.data?.success) {
        dispatch(
          updatePeoplePayDetailsFields({
            fieldName: 'wagesList',
            value: res?.data?.data,
            payTypeField: 'libraryRateDetailEntity',
            index,
          })
        );
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getAllWageAllowancesActionLoader' }));
    }
  };
};

export const showTimeSheetingAction = () => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'showTimeSheetingActionLoader' }));
      const response = await peopleServices.showTimeSheeting();
      if (response?.data?.success) {
        dispatch(
          updateUserData({
            fieldName: 'showTimesheetToEmployee',
            value: response?.data?.data,
          })
        );
      }
    } catch (e) {
      /** */
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'showTimeSheetingActionLoader' }));
    }
  };
};
