import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// import moment from 'moment';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import {
  editLeaveDetails,
  resetLeaveRequestDetails,
  updateAddLeaveDetails,
} from '../../redux/peopleSlice';
import {
  getLeaveDetailsByLeaveRequestIdAction,
  getLeaveRequestByPersonId,
  leaveRequestActionsAction,
} from '../../redux/peopleActions';
import { displayDate, displayDateTime, displayTime } from '../../../../utils/timeHelper';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import useAccess from '../../../../hooks/useAccess';
import EzyAccordion from '../../../../components/common/accordion/EzyAccordion';
import EzyAccordionSummary from '../../../../components/common/accordion/EzyAccordionSummary';
import EzyAccordionDetails from '../../../../components/common/accordion/EzyAccordinDetails';
import EzyAccordionWrapper from '../../../../components/common/accordion/EzyAccordionWrapper';
import EzyAutoComplete from '../../../../components/common/autoComplete/EzyAutoComplete';

function ViewLeaveRequestDrawer({ isFromProfile }) {
  const dispatch = useDispatch();
  const { peopleId } = useParams();
  const { viewLeaveRequestDrawer, addLeaveDetails, leaveRequestId, timeZoneId } = useSelector(
    ({ people }) => people?.leaveDetails ?? {}
  );

  const { enums } = useSelector(({ common }) => common ?? {});

  const hasAccess = useAccess('APPROVELVMGMT');

  const { personId: loggedInPersonId } = useSelector(({ user }) => user?.profileData ?? {});

  const {
    getLeaveDetailsByLeaveRequestIdActionLoader,
    leaveStatusAPPROVELoader,
    leaveStatusDECLINELoader,
    // eslint-disable-next-line camelcase
    leaveStatusUN_APPROVELoader,
    // eslint-disable-next-line camelcase
    leaveStatusUN_ARCHIVLoader,
    leaveStatusCANCELLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});

  const { canCancel, canUnArchive, canUnApprove, canDecline, canApprove } = useMemo(
    () => addLeaveDetails?.actionButtons ?? {},
    [addLeaveDetails?.actionButtons]
  );

  const handleClose = () => {
    dispatch(editLeaveDetails({ fieldName: 'viewLeaveRequestDrawer', value: false }));
    dispatch(resetLeaveRequestDetails());
  };

  const onChangeFields = (fieldName, value) => {
    dispatch(updateAddLeaveDetails({ fieldName, value }));
  };

  // LeaveActions
  // {
  // APPROVE
  // DECLINE
  // UN_APPROVE
  // UN_ARCHIVE
  // CANCEL
  // }
  const changeLeaveStatus = (status) => {
    if (status === 'DECLINE' && !addLeaveDetails?.managerComment) {
      onChangeFields('errors', { managerComment: 'Note is required!' });
      return;
    }
    if (status === 'CANCEL' && !addLeaveDetails?.employeeReason) {
      onChangeFields('errors', { employeeReason: 'Note is required!' });
      return;
    }
    if (
      addLeaveDetails?.extraLeaveDetails?.shifts?.length > 0 &&
      !addLeaveDetails?.shiftType?.id &&
      !isFromProfile &&
      canApprove &&
      status === 'APPROVE'
    ) {
      onChangeFields('errors', { shiftType: 'ShiftType is required!' });
      return;
    }
    dispatch(
      leaveRequestActionsAction(
        {
          leaveRequestId,
          leaveAction: status,
          employeeReason: addLeaveDetails?.employeeReason,
          managerComment: addLeaveDetails?.managerComment,
          shiftIds: addLeaveDetails?.extraLeaveDetails?.shifts.map((shift) => shift?.shiftId),
          shiftType: addLeaveDetails?.shiftType?.id,
        },
        () => {
          dispatch(
            getLeaveRequestByPersonId({
              page: 1,
              limit: 15,
              personDetailId: isFromProfile ? loggedInPersonId : peopleId,
            })
          );
          handleClose();
        }
      )
    );
  };

  const leaveActionButtons = useMemo(() => {
    const buttons = [];
    if (canCancel) {
      buttons.push({
        label: 'Cancel',
        color: 'secondary',
        onClick: () => changeLeaveStatus('CANCEL'),
        loading: leaveStatusCANCELLoader,
      });
    }
    if (hasAccess) {
      if (canUnArchive) {
        buttons.push({
          label: 'Un archive',
          color: 'primary',
          onClick: () => changeLeaveStatus('UN_ARCHIVE'),
          // eslint-disable-next-line camelcase
          loading: leaveStatusUN_ARCHIVLoader,
        });
      }
      if (canUnApprove) {
        buttons.push({
          label: 'Un approve',
          color: 'secondary',
          onClick: () => changeLeaveStatus('UN_APPROVE'),
          // eslint-disable-next-line camelcase
          loading: leaveStatusUN_APPROVELoader,
        });
      }
      if (canDecline) {
        buttons.push({
          label: 'Decline',
          color: 'error',
          onClick: () => changeLeaveStatus('DECLINE'),
          loading: leaveStatusDECLINELoader,
        });
      }
      if (canApprove) {
        buttons.push({
          label: 'Approve',
          color: 'primary',
          onClick: () => changeLeaveStatus('APPROVE'),
          loading: leaveStatusAPPROVELoader,
        });
      }
    }

    return buttons;
  }, [
    canCancel,
    canUnArchive,
    canUnApprove,
    canDecline,
    canApprove,
    leaveStatusCANCELLoader,
    // eslint-disable-next-line camelcase
    leaveStatusUN_ARCHIVLoader,
    // eslint-disable-next-line camelcase
    leaveStatusUN_APPROVELoader,
    leaveStatusDECLINELoader,
    leaveStatusAPPROVELoader,
    changeLeaveStatus,
  ]);

  useEffect(() => {
    if (viewLeaveRequestDrawer && leaveRequestId) {
      dispatch(
        getLeaveDetailsByLeaveRequestIdAction({
          leaveRequestId,
          fromProfile: isFromProfile,
        })
      );
    }
  }, [viewLeaveRequestDrawer]);

  return (
    <EzyDrawer
      onClose={handleClose}
      isOpen={viewLeaveRequestDrawer}
      actionButtons={leaveActionButtons}
      heading="View Leave Request"
      isLoading={getLeaveDetailsByLeaveRequestIdActionLoader}
    >
      <div className="leave-request-drawer">
        <div className="leave-request-field-grid">
          <div className="view-request-fields">
            {addLeaveDetails?.extraLeaveDetails?.personOnLeave?.length > 0 && !isFromProfile && (
              <EzyAccordionWrapper>
                <EzyAccordion>
                  <EzyAccordionSummary className="accordion-summary">
                    Already On Leave
                  </EzyAccordionSummary>
                  <EzyAccordionDetails>
                    {addLeaveDetails?.extraLeaveDetails?.personOnLeave?.map((data) => {
                      return (
                        <div className="leave-details-accordion-field">
                          <span>{data?.name}</span>
                          <span className="accordion-date">
                            {displayDate(data?.fromDate, data?.timeZoneId?.label)} -{' '}
                            {displayDate(data?.toDate, data?.timeZoneId?.label)}
                          </span>
                        </div>
                      );
                    })}
                  </EzyAccordionDetails>
                </EzyAccordion>
              </EzyAccordionWrapper>
            )}
            <div className="leave-drawer-note ">
              <div className="view-leave-field">
                <span>Created : </span>
                <div className="name-date-field">
                  <span>{addLeaveDetails?.createdBy || '-'} </span>
                  <span>
                    {displayDateTime(addLeaveDetails?.createdDate, timeZoneId?.label) || '-'}
                  </span>
                </div>
              </div>
              <div className="view-leave-field">
                <span>Modified : </span>
                <div className="name-date-field">
                  <span>{addLeaveDetails?.modifiedBy || '-'}</span>
                  <span>
                    {displayDateTime(addLeaveDetails?.modifiedDate, timeZoneId?.label) || '-'}
                  </span>
                </div>
              </div>
            </div>

            <div className="leave-drawer-note">
              <div className="view-leave-field">
                <span>Date : </span>
                <span>
                  {addLeaveDetails?.isAllDay
                    ? displayDate(addLeaveDetails?.fromDate, timeZoneId?.label) || '-'
                    : displayDateTime(addLeaveDetails?.fromDate, timeZoneId?.label) || '-'}{' '}
                  -{' '}
                  {addLeaveDetails?.isAllDay
                    ? displayDate(addLeaveDetails?.toDate, timeZoneId?.label) || '-'
                    : displayDateTime(addLeaveDetails?.toDate, timeZoneId?.label) || '-'}
                </span>
              </div>
              <div className="view-leave-field">
                <span>Leave type : </span>
                <span>{addLeaveDetails?.leaveType?.label || '-'}</span>
              </div>
              <div className="view-leave-field">
                <span>Reason : </span>
                <span>{addLeaveDetails?.reason || '-'}</span>
              </div>
              <div className="view-leave-field">
                <span>Manager comment: </span>
                <span>{addLeaveDetails?.managerComment || '-'}</span>
              </div>
            </div>

            {addLeaveDetails?.extraLeaveDetails?.shifts?.length > 0 && !isFromProfile && (
              <div className="leave-drawer-note">
                <span>
                  {addLeaveDetails?.extraLeaveDetails?.name} is scheduled for shifts during this
                  period. Choose what you would like to do with this shift. If no selection is made,
                  Team member will be kept on shift.
                </span>
              </div>
            )}

            {addLeaveDetails?.extraLeaveDetails?.shifts?.length > 0 && !isFromProfile && (
              <EzyAccordionWrapper>
                <EzyAccordion>
                  <EzyAccordionSummary className="accordion-summary">Shifts</EzyAccordionSummary>
                  <EzyAccordionDetails>
                    {addLeaveDetails?.extraLeaveDetails?.shifts?.map((data) => {
                      return (
                        <div className="leave-details-accordion-field" key={data?.shiftId}>
                          <span>{displayDate(data?.startTime, data?.timeZOneId?.label)}</span>
                          <span className="accordion-date">
                            {displayTime(data?.startTime, data?.timeZOneId?.label)} -
                            {displayTime(data?.endTime, data?.timeZOneId?.label)} | {data?.areaName}{' '}
                            | {data?.locationName}
                          </span>
                        </div>
                      );
                    })}
                  </EzyAccordionDetails>
                </EzyAccordion>
              </EzyAccordionWrapper>
            )}

            {addLeaveDetails?.extraLeaveDetails?.shifts?.length > 0 &&
              !isFromProfile &&
              (canApprove || canDecline) && (
                <EzyAutoComplete
                  name="shiftType"
                  placeholder="Select shift"
                  label="What you would like to do with shift?"
                  options={enums?.shiftAction}
                  value={addLeaveDetails?.shiftType}
                  onChange={(_, value) => onChangeFields('shiftType', value)}
                  error={addLeaveDetails?.errors?.shiftType}
                  required
                />
              )}

            {(canDecline || canCancel) && (
              <EzyInputField
                label={canCancel ? 'Reason' : 'Manager comment'}
                placeholder="Enter note"
                multiline
                rows={3}
                max-rows={5}
                value={
                  canDecline ? addLeaveDetails?.managerComment : addLeaveDetails?.employeeReason
                }
                onChange={(event) =>
                  onChangeFields(
                    canDecline ? 'managerComment' : 'employeeReason',
                    event.target.value
                  )
                }
                error={
                  canDecline
                    ? addLeaveDetails?.errors?.managerComment
                    : addLeaveDetails?.errors?.employeeReason
                }
              />
            )}
          </div>
        </div>
      </div>
    </EzyDrawer>
  );
}

export default ViewLeaveRequestDrawer;

ViewLeaveRequestDrawer.propTypes = {
  isFromProfile: PropTypes.bool,
};

ViewLeaveRequestDrawer.defaultProps = {
  isFromProfile: false,
};
