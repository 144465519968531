import { isNil, omitBy } from 'lodash';
import { isEmail, requireValidate } from '../../../utils/validationHelper';

const validateSignup = (data) => {
  const error = {};
  error.username = requireValidate('username', data.username);
  if (!data.tnc) {
    error.tnc = 'Please agree to terms and conditions to continue';
  }
  error.email = isEmail('email', data.email);
  return omitBy(error, isNil);
};

export default validateSignup;
