import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { displayDate, displayTime } from '../../../../utils/timeHelper';
import AccessWrapper from '../../../../components/common/accessWrapper/AccessWrapper';

function Unavailability({ unavailability, setDeleteModalData, isFromProfile }) {
  const { timeZoneId: timeZoneNotFromProfile } = useSelector(
    ({ people }) => people?.leaveDetails ?? {}
  );
  const { timeZone: timeZoneFromProfile } = useSelector(({ user }) => user?.profileData ?? {});
  const timeZone = isFromProfile ? timeZoneFromProfile : timeZoneNotFromProfile;
  const x = unavailability?.repeatOnDays && unavailability.repeatOnDays.length - 1;
  return (
    <div className="unavailability">
      <div className="d-flex gap-10 just-bet w-full">
        <div className="d-flex-column">
          <div>
            {unavailability?.duration && (
              <>
                <span>
                  {' '}
                  {unavailability?.duration}
                  {'  '}|
                </span>
                <span>
                  {unavailability?.repeatOnDays.map((e, idx) => {
                    if (idx === x) {
                      return `${e}`;
                    }
                    return `${e},`;
                  })}
                  {'  '}|
                </span>
              </>
            )}
            <span>
              {displayDate(unavailability?.fromDate, timeZone?.label) !==
              displayDate(unavailability?.toDate, timeZone?.label)
                ? `${displayDate(unavailability?.fromDate, timeZone?.label)} - ${displayDate(
                    unavailability?.toDate,
                    timeZone?.label
                  )}`
                : displayDate(unavailability?.fromDate, timeZone?.label)}
            </span>
            {!unavailability?.isAllDay && (
              <span>
                {`| ${displayTime(unavailability?.fromTime, timeZone?.label)} - ${displayTime(
                  unavailability?.toTime,
                  timeZone?.label
                )} `}
              </span>
            )}
          </div>

          <span className="body-text text-secondary-color">{unavailability.note}</span>
        </div>
        <AccessWrapper access="DELETEUNAV">
          <div
            className="material-icons pointer-cursor text-secondary-color"
            onClick={() => setDeleteModalData(unavailability.id)}
          >
            delete
          </div>
        </AccessWrapper>
      </div>
    </div>
  );
}
Unavailability.propTypes = {
  unavailability: PropTypes.object.isRequired,
  setDeleteModalData: PropTypes.func.isRequired,
  isFromProfile: PropTypes.bool.isRequired,
};

export default Unavailability;
