export const businessLeaveRulesListHeaders = [
  {
    name: 'id',
    label: 'id',
    hidden: true,
  },
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'leaveTypeString',
    label: 'Type',
  },
  {
    name: 'isPaid',
    label: 'Paid Leaves',
    format: (value) => (value ? 'Yes' : 'No'),
  },
  {
    name: 'trackLeaveInString',
    label: 'Track Leaves',
  },
];
