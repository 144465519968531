import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import EzyDrawer from '../../../components/common/drawer/Drawer';
import {
  addDeleteTimeSheetBreakDetails,
  resetTimeSheetData,
  updateTimeSheetData,
} from '../redux/timeSheetsSlice';
// import EzyDate from '../../../components/common/dateAndTimePicker/EzyDate';
import EzyAutoComplete from '../../../components/common/autoComplete/EzyAutoComplete';
import EzyTime from '../../../components/common/dateAndTimePicker/EzyTime';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import EzyIconButton from '../../../components/common/iconButton/EzyIconButton';
import EzySwitch from '../../../components/common/switch/EzySwitch';
import {
  addTimeSheetDetailsAction,
  editTimeSheetDetailsAction,
  getJournalDetailsByDateAction,
  getPersonListByBusinessIdAction,
  getTimeSheetViewDetailsAction,
  roundTimesheetTimeAction,
} from '../redux/timeSheetsAction';
import { getAreaListByPersonAction } from '../../people/redux/peopleActions';
import { DATE_TIME_FORMAT, breakDurationList } from '../../../utils/constants';
import validateAddTimeSheet from '../validations/validateAddTimeSheet';
import { concatDateAndTime, displayDate, displayDateTime } from '../../../utils/timeHelper';
import ImportScheduledDetails from './ImportScheduledDetails';
import IconByName from '../../../components/common/iconByName/IconByName';
import EzyDateTime from '../../../components/common/dateAndTimePicker/EzyDateTime';
import EzyButton from '../../../components/common/button/EzyButton';
import EzyTooltip from '../../../components/common/tooltip/EzyTooltip';
import { getLocationSettingsForShiftAction } from '../../schedule/redux/scheduleActions';

function AddTimeSheetDrawer({ getTimeSheetsList, isFromProfile }) {
  const dispatch = useDispatch();
  const [isFromEdit, setIsFromEdit] = useState(false);

  const { timeSheetData, sidePanelData } = useSelector(({ timeSheets }) => timeSheets ?? {});
  const { breakType, ratePerformance: ratePerformanceEnum } = useSelector(
    ({ common }) => common?.enums ?? {}
  );

  const { roleId: loggedInRole } = useSelector(({ user }) => user?.profileData ?? {});

  const {
    getPersonListByBusinessId,
    // getAreaPersonDataBasedOnLocationLoader,
    getTimeSheetViewDetailsActionLoader,
    getAreaListByPersonActionLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});

  const {
    addTimeSheetDrawer,
    editTimeSheetDrawer,
    // date,
    personDetailId,
    personList,
    areaList,
    areaId,
    startTime,
    endTime,
    totalMealBreak = 0,
    totalRestBreak = 0,
    timeSheetBreakDetailEntities,
    isAutomaticallyClosed,
    errors,
    breakTypeRoundingType,
    startTimeRoundingType,
    endTimeRoundingType,
    journalDetails,
    journalEntry,
    ratePerformance,
  } = useMemo(() => timeSheetData, [timeSheetData]);

  const {
    personId: loggedInPersonId,
    personName: loggedInPersonName,
    timeZone: loggedInPersonTimeZone,
  } = useSelector(({ user }) => user?.profileData ?? {});

  const onChangeFields = (fieldName, value, index) => {
    if (fieldName === 'breakStartTime' || fieldName === 'breakEndTime') {
      const timeZone = areaId?.timeZoneId?.label ?? moment.tz.guess();

      const scheduleStartTime = moment.tz(startTime, timeZone).format('HH');

      const scheduleEndTime = moment.tz(endTime, timeZone).format('HH');

      if (scheduleStartTime < scheduleEndTime) {
        // For single day timesheet
        const breakTime = concatDateAndTime(startTime, value, timeZone);

        dispatch(updateTimeSheetData({ fieldName, value: breakTime, index }));
      } else {
        const breakTime = moment.tz(value, timeZone).format('HH');

        if (breakTime <= scheduleEndTime) {
          dispatch(
            updateTimeSheetData({
              fieldName,
              value: concatDateAndTime(endTime, value, timeZone),
              index,
            })
          );
        } else if (breakTime === scheduleEndTime && fieldName === 'breakStartTime') {
          dispatch(
            updateTimeSheetData({
              fieldName,
              value: concatDateAndTime(endTime, value, timeZone),
              index,
            })
          );
          dispatch(
            updateTimeSheetData({
              fieldName: 'breakEndTime',
              value: concatDateAndTime(
                endTime,
                timeSheetBreakDetailEntities?.[index]?.breakEndTime,
                timeZone
              ),
              index,
            })
          );
        } else {
          dispatch(
            updateTimeSheetData({
              fieldName,
              value: concatDateAndTime(startTime, value, timeZone),
              index,
            })
          );
        }
      }
    } else dispatch(updateTimeSheetData({ fieldName, value, index }));
  };

  const handleClose = () => {
    dispatch(resetTimeSheetData());
  };

  const handleSubmit = () => {
    const data = {
      date: startTime,
      personDetailId: isFromProfile ? loggedInPersonId : personDetailId?.id,
      areaId: areaId?.id,
      startTime,
      endTime,
      totalMealBreak: Number(totalMealBreak),
      totalRestBreak: Number(totalRestBreak),
      timeSheetBreakDetailEntities,
      id: editTimeSheetDrawer ?? undefined,
      isFromProfile,
      createdDate:
        journalEntry && moment.tz(new Date(), loggedInPersonTimeZone?.label)?.toISOString(),
      journalEntry,
      ratePerformance: ratePerformance?.id,
    };

    const error = validateAddTimeSheet(data, areaId?.timeZoneId?.label);
    onChangeFields('errors', error);

    if (isEmpty(error)) {
      const finalData = cloneDeep(data);
      finalData.timeSheetBreakDetailEntities = finalData.timeSheetBreakDetailEntities
        ?.filter((e) => e?.breakType?.id)
        ?.map((e) => ({
          breakType: e?.breakType?.id,
          breakStartTime: moment.tz(e?.breakStartTime, areaId?.timeZoneId?.label)?.toISOString(),
          breakEndTime: moment.tz(e?.breakEndTime, areaId?.timeZoneId?.label)?.toISOString(),
          duration: Number(e?.duration),
        }));
      if (editTimeSheetDrawer) {
        dispatch(
          editTimeSheetDetailsAction(finalData, () => {
            getTimeSheetsList();
            handleClose();
          })
        );
      } else
        dispatch(
          addTimeSheetDetailsAction(finalData, () => {
            getTimeSheetsList();
            handleClose();
          })
        );
    }
  };

  const actionButton = [
    {
      label: [2, 5].includes(loggedInRole) || isFromProfile ? 'Save' : 'Save & Approve',
      onClick: handleSubmit,
      disabled:
        isFromProfile &&
        areaList?.map((area) => area?.isAllowToAddTimeSheet)?.every((value) => value === false),
    },
  ];

  const getAreaList = async (value) => {
    if (value?.id) await dispatch(getAreaListByPersonAction(value?.id));
  };

  const handleRoundTime = () => {
    const data = {
      timesheetId: editTimeSheetDrawer ?? undefined,
      startTime,
      endTime,
      mealBreakDuration: Number(totalMealBreak),
      restBreakDuration: Number(totalRestBreak),
      areaId: areaId?.id,
    };

    dispatch(roundTimesheetTimeAction(data));
  };

  const label =
    breakTypeRoundingType?.label === startTimeRoundingType?.label &&
    breakTypeRoundingType?.label === endTimeRoundingType?.label ? (
      `Round time to ${breakTypeRoundingType?.label} minute block.`
    ) : (
      <div className="pay-rate-tooltip-data-wrapper">
        <div className="pay-rate-tooltip-data">
          <div className="data">
            Round start time to {startTimeRoundingType?.label} minute block.
          </div>
          <div className="data">Round end time to {endTimeRoundingType?.label} minute block.</div>
          <div className="data">Round break to {breakTypeRoundingType?.label} minute block.</div>
        </div>
      </div>
    );

  const output = timeSheetBreakDetailEntities?.reduce((obj, el) => {
    const property = el?.breakType?.id;
    obj[property] = (obj[property] || 0) + Number(el.duration);
    return obj;
  }, {});

  useEffect(() => {
    if (isFromEdit) {
      if (output?.['1'] !== totalMealBreak) {
        onChangeFields('totalMealBreak', output?.['1']);
      }
      if (output?.['2'] !== totalRestBreak) {
        onChangeFields('totalRestBreak', output?.['2']);
      }
    }

    return () => setIsFromEdit(false);
  }, [timeSheetBreakDetailEntities, isFromEdit]);

  useEffect(() => {
    if (personDetailId?.id && startTime && !isFromProfile && areaId) {
      dispatch(
        getJournalDetailsByDateAction({
          date: moment.tz(startTime, areaId?.timeZoneId?.label)?.toISOString(),
          personDetailId: personDetailId?.id,
          areaId: areaId?.id,
          timesheetId: editTimeSheetDrawer,
        })
      );
    }
  }, [personDetailId, startTime, isFromProfile, areaId]);

  useEffect(() => {
    if (!isFromProfile && addTimeSheetDrawer) {
      dispatch(getPersonListByBusinessIdAction());
    }
    if (!isFromProfile && editTimeSheetDrawer)
      dispatch(
        getPersonListByBusinessIdAction(() =>
          dispatch(getTimeSheetViewDetailsAction({ timesheetId: editTimeSheetDrawer }, true))
        )
      );
    if (isFromProfile && loggedInPersonId && addTimeSheetDrawer) {
      getAreaList({ id: loggedInPersonId });
    }
    if (isFromProfile && editTimeSheetDrawer) {
      dispatch(getTimeSheetViewDetailsAction({ timesheetId: editTimeSheetDrawer }, true));
    }
  }, [addTimeSheetDrawer, editTimeSheetDrawer, loggedInPersonId, isFromProfile]);

  return (
    <EzyDrawer
      onClose={handleClose}
      isOpen={addTimeSheetDrawer || editTimeSheetDrawer}
      actionButtons={actionButton}
      heading={editTimeSheetDrawer ? 'Edit Timesheet' : 'Add Timesheet'}
      isLoading={
        (editTimeSheetDrawer && getPersonListByBusinessId) ||
        // getAreaPersonDataBasedOnLocationLoader ||
        getTimeSheetViewDetailsActionLoader
      }
      leftAction={
        <EzyTooltip title={startTimeRoundingType?.id ? label : null}>
          <EzyButton
            label="Round time"
            color="secondary"
            onClick={() => handleRoundTime()}
            disabled={!(startTime && endTime)}
          />
        </EzyTooltip>
      }
    >
      <div className="add-timesheet-drawer">
        {editTimeSheetDrawer && isAutomaticallyClosed && (
          <div className="add-timesheet-fields-grid">
            <div className="info-div info body-text">
              <IconByName name="error" />
              <div>Automatically closed because employee did not finish their shift.</div>
            </div>
          </div>
        )}
        {editTimeSheetDrawer && !isEmpty(timeSheetData?.attachedShift) && (
          <ImportScheduledDetails />
        )}
        <div className="add-timesheet-fields-grid">
          {isFromProfile &&
            areaList
              ?.map((area) => area?.isAllowToAddTimeSheet)
              ?.some((value) => value === false) && (
              <div className="info-div success">
                <IconByName name="error" />
                <div>You are not allowed to add timesheet in disabled area.</div>
              </div>
            )}

          <div className="two-grid">
            <EzyAutoComplete
              placeholder="Select person"
              label="Select person"
              options={personList}
              value={
                isFromProfile ? { id: loggedInPersonId, label: loggedInPersonName } : personDetailId
              }
              name="personDetailId"
              onChange={async (_, value) => {
                await getAreaList(value);
                onChangeFields('personDetailId', value);
              }}
              error={errors?.personDetailId}
              loading={getPersonListByBusinessId}
              readOnly={isFromProfile}
              required
            />
            <EzyAutoComplete
              placeholder="Select area"
              label="Area"
              options={areaList}
              getOptionDisabled={(option) => {
                if (isFromProfile) {
                  return !option?.isAllowToAddTimeSheet;
                }
                return false;
              }}
              value={areaId}
              name="areaId"
              onChange={(_, value) => {
                dispatch(
                  getLocationSettingsForShiftAction({
                    areaId: value?.id,
                    locationId: sidePanelData?.selectedLocation?.id,
                    startDate: moment(sidePanelData?.weekDatesValue?.startDate)?.toISOString(),
                    endDate: moment(sidePanelData?.weekDatesValue?.endDate)?.toISOString(),
                    isFromTimesheet: true,
                  })
                );
                onChangeFields('areaId', value);
                const currentTimeZone = areaId?.timeZoneId?.label ?? moment.tz.guess();
                const updatedTimeZone = value?.timeZoneId?.label ?? moment.tz.guess();

                if (startTime && endTime) {
                  const currentStartTime = displayDateTime(startTime, currentTimeZone);
                  const currentEndTime = displayDateTime(endTime, currentTimeZone);

                  onChangeFields(
                    'startTime',
                    moment.tz(currentStartTime, DATE_TIME_FORMAT, updatedTimeZone)?.toISOString()
                  );
                  onChangeFields(
                    'endTime',
                    moment.tz(currentEndTime, DATE_TIME_FORMAT, updatedTimeZone)?.toISOString()
                  );
                }

                const newBreakDetails = timeSheetBreakDetailEntities.map((e) => {
                  if (e?.isCustom) {
                    const currentBreakStartTime = displayDateTime(
                      e?.breakStartTime,
                      currentTimeZone
                    );
                    const currentBreakEndTime = displayDateTime(e?.breakEndTime, currentTimeZone);

                    return {
                      ...e,
                      breakStartTime: e?.breakStartTime
                        ? moment
                            .tz(currentBreakStartTime, DATE_TIME_FORMAT, updatedTimeZone)
                            ?.toISOString()
                        : null,
                      breakEndTime: e?.breakEndTime
                        ? moment
                            .tz(currentBreakEndTime, DATE_TIME_FORMAT, updatedTimeZone)
                            ?.toISOString()
                        : null,
                    };
                  }
                  return e;
                });

                onChangeFields('timeSheetBreakDetailEntities', newBreakDetails);
              }}
              error={errors?.areaId}
              loading={getAreaListByPersonActionLoader}
              required
            />
          </div>
          <div className="two-grid">
            <EzyDateTime
              name="startTime"
              placeholder="Select start time"
              label="Start time"
              value={startTime}
              onChange={(value) => onChangeFields('startTime', value)}
              error={errors?.startTime}
              timeZone={areaId?.timeZoneId?.label}
              required
            />
            <EzyDateTime
              name="endTime"
              placeholder="Select end time"
              label="End time"
              value={endTime}
              onChange={(value) => onChangeFields('endTime', value)}
              error={errors?.endTime}
              timeZone={areaId?.timeZoneId?.label}
              required
            />
          </div>
        </div>
        <div className="title-with-primary-bg ">Break Summary</div>
        <div className="add-timesheet-fields-grid">
          <div className="two-grid">
            <EzyInputField
              name="totalMealBreak"
              placeholder="0"
              label="Total meal break"
              value={totalMealBreak}
              // onChange={(value) => onChangeFields('totalMealBreak', value)}
              error={errors?.totalMealBreak}
              disabled
            />
            <EzyInputField
              name="totalRestBreak"
              placeholder="0"
              label="Total rest break"
              value={totalRestBreak}
              // onChange={(value) => onChangeFields('totalMealBreak', value)}
              error={errors?.totalRestBreak}
              disabled
            />
          </div>
        </div>
        {((output?.['1'] ? output?.['1'] !== totalMealBreak : false) ||
          (output?.['2'] ? output?.['2'] !== totalRestBreak : false)) && (
          <div className="add-timesheet-fields-grid">
            <div className="info-div error ">
              <IconByName name="error" />
              Please note that total duration of break/s does not reconcile with break details.You
              may still approve the timesheet.
            </div>
          </div>
        )}

        <div className="title-with-primary-bg area-title">
          <span>Break Details</span>
          <span>
            <EzyIconButton
              iconName="add"
              onClick={() => dispatch(addDeleteTimeSheetBreakDetails({ type: 'ADD' }))}
              className="small"
            />
          </span>
        </div>

        {timeSheetBreakDetailEntities?.map((entity, index) => (
          <div className="add-timesheet-fields-grid">
            <div className="d-flex flex-align-center just-bet">
              <EzySwitch
                name="isCustom"
                label="Set break start and end time"
                checked={entity?.isCustom}
                handleChange={(e) => {
                  setIsFromEdit(true);
                  onChangeFields('isCustom', e?.target?.checked, index);
                }}
              />
              <span>
                <EzyIconButton
                  iconName="delete"
                  onClick={() =>
                    dispatch(addDeleteTimeSheetBreakDetails({ type: 'DELETE', index }))
                  }
                  className="small"
                />
              </span>
            </div>
            <div className="two-grid">
              <EzyAutoComplete
                placeholder="Select type"
                label="Type"
                options={breakType}
                value={entity?.breakType}
                name="breakType"
                onChange={(_, value) => {
                  setIsFromEdit(true);
                  onChangeFields('breakType', value, index);
                }}
                error={errors?.timeSheetBreakDetailEntities?.[index]?.breakType}
              />
              {!entity?.isCustom ? (
                <EzyAutoComplete
                  placeholder="Select duration"
                  label="Duration"
                  options={breakDurationList}
                  value={entity?.duration}
                  name="duration"
                  onChange={(_, value) => {
                    setIsFromEdit(true);
                    onChangeFields('duration', value, index);
                  }}
                  error={entity?.errors?.duration}
                />
              ) : (
                <EzyInputField
                  placeholder="0"
                  label="Duration"
                  value={entity?.duration}
                  name="duration"
                  onChange={(_, value) => {
                    onChangeFields('duration', value, index);
                  }}
                  error={entity?.errors?.duration}
                  disabled
                />
              )}
            </div>
            {entity?.isCustom && (
              <div className="two-grid">
                <EzyTime
                  name="startTime"
                  placeholder="Select time"
                  label="Start time"
                  value={entity?.breakStartTime}
                  onChange={(value) => {
                    setIsFromEdit(true);
                    onChangeFields('breakStartTime', value, index);
                  }}
                  error={errors?.timeSheetBreakDetailEntities?.[index]?.breakStartTime}
                  timeZone={areaId?.timeZoneId?.label}
                />
                <EzyTime
                  name="endTime"
                  placeholder="Select time"
                  label="End time"
                  value={entity?.breakEndTime}
                  onChange={(value) => {
                    setIsFromEdit(true);
                    onChangeFields('breakEndTime', value, index);
                  }}
                  error={errors?.timeSheetBreakDetailEntities?.[index]?.breakEndTime}
                  timeZone={areaId?.timeZoneId?.label}
                />
              </div>
            )}
          </div>
        ))}

        {personDetailId?.id && personDetailId?.id !== loggedInPersonId && (
          <>
            <div className="title-with-primary-bg ">Journal</div>
            <div className="add-timesheet-fields-grid">
              <div className="info-div primary-bg word-break">
                {!journalDetails ? (
                  <div>There are no journal entries for this team member on this day.</div>
                ) : (
                  <div className="w-full">
                    <div>
                      {journalDetails?.createdByName} |{' '}
                      {displayDate(journalDetails?.createdDate, journalDetails?.timeZoneId?.label)}
                    </div>
                    <div className="d-flex just-bet">
                      <span className="text-secondary-color body-text">
                        {journalDetails?.journalEntry}
                      </span>
                      <span
                        className={`body-text ${
                          journalDetails?.ratePerformance?.id === 1
                            ? 'primary-color'
                            : 'error-color'
                        }`}
                      >
                        #{journalDetails?.ratePerformance?.label}
                      </span>
                    </div>
                  </div>
                )}
              </div>

              <EzyInputField
                label="Journal"
                placeholder="Enter Journal"
                multiline
                minRows={2}
                value={journalEntry}
                onChange={(e) => onChangeFields('journalEntry', e?.target?.value)}
                error={errors?.journalEntry}
              />
              <EzyAutoComplete
                label="Category"
                placeholder="Select Category"
                value={ratePerformance}
                options={ratePerformanceEnum}
                onChange={(_, value) => onChangeFields('ratePerformance', value)}
              />
            </div>
          </>
        )}
      </div>
    </EzyDrawer>
  );
}

export default AddTimeSheetDrawer;

AddTimeSheetDrawer.propTypes = {
  getTimeSheetsList: PropTypes.func.isRequired,
  isFromProfile: PropTypes.bool,
};

AddTimeSheetDrawer.defaultProps = {
  isFromProfile: false,
};
