import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import EzyEditableTable from '../../../../components/common/table/EzyEditableTable';
import EzyButton from '../../../../components/common/button/EzyButton';
import WeekPicker from '../../../../components/common/dateAndTimePicker/WeekPicker';
import { updateBudgetFilters, updateBudgetSettingsDrawer } from '../../redux/insightSlice';
import BudgetSettingsDrawer from './BudgetSettingsDrawer';
import { getBudgetStatisticAction, updateBudgetStatisticAction } from '../../redux/insightAction';
import ROUTE_CONSTANTS from '../../../../utils/constants';
import { displayDate } from '../../../../utils/timeHelper';
import EzyMenu from '../../../../components/common/menu/EzyMenu';

function varianceCell(prop) {
  const [toggle, setToggle] = useState(false);

  return (
    <div
      onMouseEnter={(e) => {
        e?.stopPropagation();
        setToggle((prev) => !prev);
      }}
      onMouseLeave={(e) => {
        e?.stopPropagation();
        setToggle((prev) => !prev);
      }}
      className="primary-color variance-hover-cell"
    >
      {toggle ? prop?.row?.variancePercentage : prop?.row?.varianceValue}
    </div>
  );
}

function Budget() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { filters, budget } = useSelector(({ insight }) => insight ?? {});
  const { businessId } = useSelector(({ user }) => user?.profileData ?? {});
  const { selectedModule, selectedLocation } = useMemo(() => filters, [filters]);

  const { budgetList, filters: budgetFilter } = useMemo(() => budget, [budget]);

  const getBudgetListByFilters = (param) => {
    const params = {
      id: selectedModule?.id,
      locationId: selectedLocation?.id !== 'all' ? selectedLocation?.id : null,
      businessId: selectedLocation?.id === 'all' ? businessId : null,
      weekStartDate: moment(budgetFilter?.weekDatesValue?.startDate)?.toISOString(),
      weekEndDate: moment(budgetFilter?.weekDatesValue?.endDate)?.toISOString(),
      areaId: selectedModule?.showArea ? budgetFilter?.areaId?.id : null,
      ...param,
    };

    dispatch(getBudgetStatisticAction(params));
  };

  const onFieldUpdate = (row) => {
    const data = {
      budgetId: selectedModule?.id,
      areaId: selectedModule?.showArea ? budgetFilter?.areaId?.id : null,
      locationId: selectedLocation?.id,
      day: row?.date,
      value: row?.value,
    };
    if (!row?.day) {
      data.weekStartDate = moment(budgetFilter?.weekDatesValue?.startDate)?.toISOString();
      data.weekEndDate = moment(budgetFilter?.weekDatesValue?.endDate)?.toISOString();
    }
    // if (data?.value)
    dispatch(
      updateBudgetStatisticAction(data, () => {
        getBudgetListByFilters();
      })
    );
  };
  const columns = [
    {
      field: 'dayOfStatistics',
      headerName: 'Date',
      valueGetter: (e) => {
        if (e?.id === 'weeklyTotal') {
          return 'Weekly total';
        }
        return displayDate(e?.value, 'UTC');
      },
    },
    {
      field: 'budgetValue',
      headerName: 'Budgeted value',
      editable: selectedLocation?.id !== 'all',
      align: 'right',
      headerAlign: 'right',
      onFieldChange: onFieldUpdate,
      formatValue: (value) => {
        if (selectedModule?.label?.includes('Hour')) {
          const minutes = value * 60;

          const wholeHours = Math.floor(minutes / 60);
          const remainingMinutes = minutes % 60;
          return `${wholeHours}h ${remainingMinutes}m`;
        }
        return `$${value}`;
      },
    },
    {
      field: 'scheduledValue',
      headerName: 'Scheduled value',
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'varianceValue',
      headerName: 'Variance',
      renderCell: varianceCell,
      align: 'right',
      headerAlign: 'right',
    },
  ];

  const formattedRows = [
    ...budgetList.budgetResponseValue.map((e, index) => {
      if (!e?.id) {
        return {
          ...e,
          id: `${selectedModule?.id}-${index + 1}`,
          isTotalEditable: budget?.budgetList?.isTotalEditable,
        };
      }
      return e;
    }),
    {
      id: 'weeklyTotal',
      isTotalEditable: budget?.budgetList?.isTotalEditable,
      budgetValue: budget?.budgetList?.budgetTotalValue,
      scheduledValue: budget?.budgetList?.scheduleTotalValue,
      varianceValue: budget?.budgetList?.varianceTotalValue,
      variancePercentage: budget?.budgetList?.varianceTotalPercentage,
    },
  ];

  useEffect(() => {
    if (selectedModule?.id) {
      const dates = {
        startDate: moment.tz(new Date(), 'UTC').startOf('week'),
        endDate: moment.tz(new Date(), 'UTC').endOf('week'),
      };
      getBudgetListByFilters({
        weekStartDate: dates?.startDate?.toISOString(),
        weekEndDate: dates?.endDate?.toISOString(),
        areaId: selectedModule?.showArea ? selectedLocation?.areaList?.[0]?.id : null,
      });
      if (selectedModule?.showArea) {
        dispatch(
          updateBudgetFilters({ fieldName: 'areaId', value: selectedLocation?.areaList?.[0] })
        );
      }
      dispatch(updateBudgetFilters({ fieldName: 'weekDatesValue', value: dates }));
    }
  }, [selectedLocation, selectedModule]);

  return (
    <div className="budget-list-wrapper">
      <div className="budget-header">
        <div>
          <WeekPicker
            variant="outlined"
            value={budgetFilter?.weekDatesValue}
            onChange={(e) => {
              dispatch(
                updateBudgetFilters({
                  fieldName: 'weekDatesValue',
                  value: {
                    startDate: moment.tz(e?.startDate, 'UTC'),
                    endDate: moment.tz(e?.endDate, 'UTC'),
                  },
                })
              );
              getBudgetListByFilters({
                weekStartDate: moment.tz(e?.startDate, 'UTC').toISOString(),
                weekEndDate: moment.tz(e?.endDate, 'UTC').toISOString(),
              });
            }}
            timeZone="UTC"
            startWeekFrom={
              selectedLocation?.weekStartsOn === 7 ? 0 : selectedLocation?.weekStartsOn
            }
          />
          {selectedModule?.showArea && selectedLocation?.id !== 'all' && (
            <EzyMenu
              className="insight-area-menu"
              menuItems={selectedLocation?.areaList}
              label={budgetFilter?.areaId?.label || 'Area'}
              onItemClick={(e, item) => {
                dispatch(updateBudgetFilters({ fieldName: 'areaId', value: item }));
                getBudgetListByFilters({ areaId: item?.id });
              }}
              selectedItem={budgetFilter?.areaId}
            />
          )}
        </div>

        <div>
          <EzyButton
            className="budget-setting-button"
            label={`${selectedModule?.label?.substr(
              selectedModule.label.indexOf(' ') + 1
            )} settings`}
            onClick={() => {
              dispatch(updateBudgetSettingsDrawer({ fieldName: 'isOpen', value: true }));
            }}
          />

          <EzyButton
            label="Back"
            color="secondary"
            onClick={() => history.push(ROUTE_CONSTANTS.SCHEDULE)}
          />
        </div>
      </div>
      <EzyEditableTable rows={formattedRows} columns={columns} />
      <BudgetSettingsDrawer />
    </div>
  );
}

Budget.propTypes = {};

export default Budget;
