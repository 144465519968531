export const formatPhoneToSend = (input = null, code = null) => {
  if (input?.slice(0, code?.length) === code) {
    return input.slice(code?.length);
  }
  if (code && input?.slice(0, code.length + 1) === `+${code}`) {
    return input.slice(+code.length + 2);
  }
  return input;
};

export const formatPhoneWithCode = (number, code = '61') => {
  if (!code && !number) {
    return '-';
  }
  if (code && number) {
    return `+${code} ${number}`;
  }
  return `${code && number ? `+${code}` : ''} ${number || '-'}`;
};
