import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
// import EzySwitch from '../../../components/common/switch/EzySwitch';
import { getGeneralLocationSettingsAction } from '../redux/businessAction';
import {
  resetBusinessSettingDetails,
  updateBusinessSettingDetails,
  updateGeneralBusinessSettingDetails,
  // updatePeopleBusinessSettingDetails,
  // updateTimeSheetBusinessSettingDetails,
} from '../redux/businessSlice';
import useUrlParamsUpdate from '../../../hooks/useURLParamsUpdate';
import EzyAutoComplete from '../../../components/common/autoComplete/EzyAutoComplete';
import Loader from '../../../components/common/loader/Loader';
import EzySwitch from '../../../components/common/switch/EzySwitch';

function BusinessGeneralSettings() {
  const details = useSelector(({ business }) => business?.businessSettings ?? {});
  const { enums } = useSelector(({ common }) => common ?? {});

  const { getGeneralLocationSettingsActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const {
    businessName,
    countryId,
    generalSetting,
    // people,
    // timesheets,
    errors,
  } = useMemo(() => details, [details]);
  const dispatch = useDispatch();

  const handleFieldChange = (name, value) => {
    dispatch(updateBusinessSettingDetails({ fieldName: name, value }));
  };

  // const handlePeopleSettingChanges = (name, value) => {
  //   dispatch(updatePeopleBusinessSettingDetails({ fieldName: name, value }));
  // };

  // const handleTimeSheetSettingChanges = (name, value) => {
  //   dispatch(updateTimeSheetBusinessSettingDetails({ fieldName: name, value }));
  // };

  const handleGeneralSettingChanges = (name, value) => {
    dispatch(updateGeneralBusinessSettingDetails({ fieldName: name, value }));
  };

  useUrlParamsUpdate({});

  useEffect(() => {
    dispatch(getGeneralLocationSettingsAction());
    return () => {
      dispatch(resetBusinessSettingDetails());
    };
  }, []);

  return (
    <div className="business-settings-wrapper">
      {getGeneralLocationSettingsActionLoader ? (
        <Loader />
      ) : (
        <>
          <div className="title-with-primary-bg">General</div>
          <div className="two-grid p-x-24">
            <EzyInputField
              name="businessName"
              placeholder="Enter business name"
              label="Business name"
              value={businessName}
              onChange={(e) => handleFieldChange('businessName', e?.target?.value)}
              error={errors?.businessName}
              required
            />
            <div>
              <EzyAutoComplete
                name="countryId"
                label="Number / Locale format"
                placeholder="Select number format"
                value={countryId}
                options={enums?.country}
                onChange={(_, value) => handleFieldChange('countryId', value)}
              />
            </div>
          </div>
          {/* <div className="title-with-primary-bg">People</div>
          <div className="two-grid p-x-24">
            <EzySwitch
              name="displayGenderOnTeamMemberProfile"
              label="Display gender on team member profile"
              subLabel="If turned on, gender will be displayed on the team member profile."
              checked={people?.displayGenderOnTeamMemberProfile}
              handleChange={(e) =>
                handlePeopleSettingChanges('displayGenderOnTeamMemberProfile', e?.target?.checked)
              }
            />
          </div> */}
          <div className="title-with-primary-bg">Time Sheets</div>
          <div className="two-grid p-x-24">
            <EzySwitch
              name="showTimesheetToEmployee"
              label="Show timesheets to employee"
              checked={generalSetting?.showTimesheetToEmployee}
              handleChange={(e) =>
                handleGeneralSettingChanges('showTimesheetToEmployee', e?.target?.checked)
              }
            />
          </div>
          {/* <div className="two-grid p-x-24">
            <EzySwitch
              name="allowUnpaidLeaveTimesheetsToBeExported"
              label="Allow unpaid leave timesheets to be exported"
              checked={timesheets?.timeSallowUnpaidLeaveTimesheetsToBeExported}
              handleChange={(e) =>
                handleTimeSheetSettingChanges(
                  'allowUnpaidLeaveTimesheetsToBeExported',
                  e?.target?.checked
                )
              }
            />
            <EzySwitch
              name="allowEmployeesToClockInWithPhotoonKioskWhenTheyForgetTheirPin"
              label="Allow employees to clock in with photo on kiosk when they forget their PIN"
              checked={timesheets?.allowEmployeesToClockInWithPhotoonKioskWhenTheyForgetTheirPin}
              handleChange={(e) =>
                handleTimeSheetSettingChanges(
                  'allowEmployeesToClockInWithPhotoonKioskWhenTheyForgetTheirPin',
                  e?.target?.checked
                )
              }
            />
          </div>
          <div className="two-grid p-x-24">
            <EzySwitch
              name="allowEmployeesToSubmitTimesheetsInANonworkedArea"
              label="Allow employees to submit timesheets in a non-worked area"
              checked={timesheets?.allowEmployeesToSubmitTimesheetsInANonworkedArea}
              handleChange={(e) =>
                handleTimeSheetSettingChanges(
                  'allowEmployeesToSubmitTimesheetsInANonworkedArea',
                  e?.target?.checked
                )
              }
            />
          </div> */}
        </>
      )}
    </div>
  );
}

export default BusinessGeneralSettings;
