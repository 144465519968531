import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { isEmpty, isNil, omitBy } from 'lodash';
import EzyTab from '../../../components/common/tab/EzyTab';
import BusinessGeneralSettings from '../components/GeneralSettings';
import BusinessLeaveSettings from '../components/LeaveSettings';
import { requireValidate } from '../../../utils/validationHelper';
import { updateBusinessSettingDetails, updateLeaveSettingDetails } from '../redux/businessSlice';
import { saveEditedLeaveSettingAction, saveRegisterGeneralSettings } from '../redux/businessAction';

export function BusinessSettings() {
  const { businessSettings, leaveSettings } = useSelector(({ business }) => business ?? {});
  const { businessName, countryId, generalSetting, people, timesheets } = useMemo(
    () => businessSettings ?? {},
    [businessSettings]
  );

  const {
    allowTeamMembersToViewTheirBalance,
    allowTeamMembersToAmendOrCancelTheirApprovedLeaveRequest,
    whoCanApproveLeave,
  } = useMemo(() => leaveSettings, [leaveSettings]);

  const { saveEditedLeaveSettingActionLoader, saveRegisterGeneralSettingsLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const dispatch = useDispatch();

  const handleGeneralSettingsSubmit = () => {
    let errors = {};
    errors.businessName = requireValidate('Business name', businessName);
    errors = omitBy(errors, isNil);
    dispatch(updateBusinessSettingDetails({ fieldName: 'errors', value: errors }));
    if (isEmpty(errors)) {
      const data = {
        businessName,
        countryId: countryId?.id,
        generalSetting,
        people,
        timesheets,
      };
      dispatch(saveRegisterGeneralSettings(data));
    }
  };
  const handleLeaveSettingsSubmit = () => {
    let errors = {};
    errors.whoCanApproveLeave = requireValidate('Access level', whoCanApproveLeave?.id);
    errors = omitBy(errors, isNil);
    dispatch(updateLeaveSettingDetails({ fieldName: 'errors', value: errors }));
    if (isEmpty(errors)) {
      const data = {
        leave: {
          allowTeamMembersToViewTheirBalance,
          allowTeamMembersToAmendOrCancelTheirApprovedLeaveRequest,
          whoCanApproveLeave: whoCanApproveLeave?.id,
        },
      };
      dispatch(saveEditedLeaveSettingAction(data));
    }
  };

  const businessSettingsActions = [
    {
      label: 'Save',
      onClick: handleGeneralSettingsSubmit,
      isLoading: saveRegisterGeneralSettingsLoader,
    },
  ];

  const leaveSettingsActions = [
    {
      label: 'Save',
      onClick: handleLeaveSettingsSubmit,
      isLoading: saveEditedLeaveSettingActionLoader,
    },
  ];

  const settingTabs = [
    {
      label: 'Business Settings',
      component: <BusinessGeneralSettings />,
      actionButtons: businessSettingsActions,
      access: 'EDITBUS',
    },
    {
      label: 'Leave Settings',
      component: <BusinessLeaveSettings />,
      actionButtons: leaveSettingsActions,
      access: 'EDITBUS',
    },
  ];

  return <EzyTab list={settingTabs} />;
}

export default BusinessSettings;
