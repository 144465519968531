import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { Chip, InputAdornment } from '@mui/material';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import EzyAutoComplete from '../../../../components/common/autoComplete/EzyAutoComplete';
import EzyDate from '../../../../components/common/dateAndTimePicker/EzyDate';
import EzyPhoneNumber from '../../../../components/common/phoneNumberInput/ezyPhoneNumber';
import { editPeopleDetails, resetEditPeopleDetails } from '../../redux/peopleSlice';
import { getPeopleDetailsAction, getWorkPeriodsByBusinessId } from '../../redux/peopleActions';
import { getLocationsByBusinessId } from '../../../location/redux/locationActions';
import WorkPeriodDrawer from './WorkPeriodDrawer';
import HourlyPayGradeDrawer from './HourlyPayGradeDrawer';
import Loader from '../../../../components/common/loader/Loader';
import {
  startGeneralLoader,
  stopGeneralLoader,
} from '../../../../utils/backOfficeLists/commonDataSlice';
import PeopleProfile from './PeopleProfile';
import { DATE_FORMAT } from '../../../../utils/constants';
import useAccess from '../../../../hooks/useAccess';

function PeopleDetails() {
  const {
    details,
    locationsList,
    workPeriodList = [],
  } = useSelector(({ people }) => people?.editPeople ?? {});
  const { enums } = useSelector(({ common }) => common ?? {});

  const hasAccessToEdit = useAccess('EDITPPL');

  const disabledLocation = useMemo(
    () => locationsList?.filter((e) => !e?.hasAccess) ?? [],
    [locationsList]
  );

  const {
    // getLocationsByBusinessIdLoader,
    // getWorkPeriodsByBusinessIdLoader,
    // getPeopleDetailsActionLoader,
    getPeopleDetails,
  } = useSelector(({ common }) => common.generalLoader ?? {});

  const { roleId: loggedInRole } = useSelector(({ user }) => user?.profileData ?? {});

  const { peopleId } = useParams();

  const {
    pronoun: pronounList,
    roles: accessLevelList,
    employmentType: employmentTypeList,
    country: countryList,
    // payRateType: payRateTypeList,
  } = useMemo(() => enums ?? {}, [enums]);

  const dispatch = useDispatch();

  const {
    // personDetailId,
    roleId,
    primaryLocationId,
    workLocations,
    workLocationsToDisabled,
    joiningDate,
    payRateType,
    payRollId,
    employmentType,
    workPeriodId,
    // profilePhotoURL,
    preferredFullName,
    dateOfBirth,
    pronoun,
    address,
    postCode,
    city,
    state,
    countryId,
    // countyCode,
    phoneNumber,
    email,
    emergencyContactName,
    emergencyContactNumber,
    // emergencyContactCountryCode,
    invitationStatus,
    hours,
    minutes,
    errors,
  } = useMemo(() => details ?? {}, [details]);

  const handleFieldChanges = (fieldName, value) => {
    dispatch(editPeopleDetails({ fieldName, value }));
  };

  const handleTextChange = (e) => {
    handleFieldChanges(e?.target?.name, e?.target.value);
  };

  const isDisabled = useMemo(() => roleId?.id < loggedInRole, [roleId, loggedInRole]);

  useEffect(() => {
    (async () => {
      dispatch(startGeneralLoader({ loaderFor: 'getPeopleDetails' }));
      await dispatch(getLocationsByBusinessId({ isAllLocation: true }));
      await dispatch(getWorkPeriodsByBusinessId());
      await dispatch(getPeopleDetailsAction(peopleId));
      dispatch(stopGeneralLoader({ loaderFor: 'getPeopleDetails' }));
    })();

    return () => {
      dispatch(resetEditPeopleDetails());
    };
  }, [peopleId]);

  return (
    <div className="edit-people-wrapper">
      {getPeopleDetails ? (
        <Loader />
      ) : (
        <>
          <div className="title-with-primary-bg">Personal Information</div>
          <div className="people-profile-container">
            <PeopleProfile />
            <EzyInputField
              name="preferredFullName"
              placeholder="Enter name"
              label="Name"
              value={preferredFullName}
              onChange={handleTextChange}
              error={errors?.preferredFullName}
              disabled={!hasAccessToEdit}
              required
            />
            <EzyAutoComplete
              name="pronoun"
              placeholder="Select pronoun"
              label="Pronoun"
              value={pronoun}
              options={pronounList}
              // error={errors?.people?.[index]?.name}
              onChange={(_, value) => handleFieldChanges('pronoun', value)}
              disabled={!hasAccessToEdit}
            />
            <EzyDate
              name="dateOfBirth"
              placeholder="Select birth date"
              label="Birth date"
              value={dateOfBirth}
              // error={errors?.people?.[index]?.name}
              onChange={(value) => handleFieldChanges('dateOfBirth', value)}
              disabled={!hasAccessToEdit}
              timeZone="UTC"
              maxDate={moment.tz(new Date(), 'UTC').subtract(10, 'years')}
            />
            <EzyInputField
              name="email"
              placeholder="Enter email address"
              label="Email"
              type="email"
              value={email}
              error={errors?.email}
              onChange={handleTextChange}
              disabled={invitationStatus === 1 || isDisabled || !hasAccessToEdit}
              required
            />
            <EzyPhoneNumber
              country="au"
              placeholder="4 123 456 789"
              label="Mobile number"
              name="phoneNumber"
              value={phoneNumber}
              onChange={(value, data) => {
                handleFieldChanges('phoneNumber', value);
                handleFieldChanges('countryCode', data?.dialCode);
                handleFieldChanges('phoneNumberData', data);
              }}
              error={errors?.phoneNumber}
              disabled
            />
            <EzyInputField
              name="postCode"
              placeholder="Enter post code"
              label="Post code"
              value={postCode}
              error={errors?.postCode}
              onChange={handleTextChange}
              inputProps={{
                maxLength: 10,
              }}
              disabled={!hasAccessToEdit}
            />
            <EzyAutoComplete
              placeholder="Select country"
              label="Country"
              options={countryList}
              value={countryId}
              name="countryId"
              onChange={(_, value) => handleFieldChanges('countryId', value)}
              disabled={!hasAccessToEdit}
            />
            <EzyInputField
              name="state"
              placeholder="Enter state"
              label="State"
              value={state}
              onChange={handleTextChange}
              disabled={!hasAccessToEdit}
            />
            <EzyInputField
              name="city"
              placeholder="Enter city"
              label="City"
              value={city}
              onChange={handleTextChange}
              disabled={!hasAccessToEdit}
            />
            <EzyInputField
              name="address"
              placeholder="Enter address"
              label="Address"
              value={address}
              inputProps={{
                maxLength: 300,
              }}
              multiline
              maxRows={2}
              onChange={handleTextChange}
              disabled={!hasAccessToEdit}
            />
            <EzyInputField
              name="emergencyContactName"
              placeholder="Enter name"
              label="Emergency contact name"
              value={emergencyContactName}
              onChange={handleTextChange}
              disabled={!hasAccessToEdit}
            />
            <EzyPhoneNumber
              country="au"
              placeholder="4 123 456 789"
              label="Emergency mobile number"
              name="emergencyMobileNumber"
              value={emergencyContactNumber}
              onChange={(value, data) => {
                handleFieldChanges('emergencyContactNumber', value);
                handleFieldChanges('emergencyContactCountryCode', data?.dialCode);
                handleFieldChanges('emergencyContactNumberData', data);
              }}
              disabled={!hasAccessToEdit}
              error={errors?.emergencyContactNumber}
            />
          </div>
          <div className="title-with-primary-bg">Employee Information</div>
          <div className="people-employee-container">
            <EzyAutoComplete
              tooltip="A set of permission that control what a team member does in payrollEZY."
              placeholder="Select access level"
              label="Access level"
              options={accessLevelList}
              getOptionDisabled={(option) => option?.id < loggedInRole}
              value={roleId}
              name="roleId"
              onChange={(_, value) => handleFieldChanges('roleId', value)}
              error={errors?.roleId}
              disabled={isDisabled || !hasAccessToEdit}
              required
            />
            <EzyDate
              name="joiningDate"
              placeholder="Select joining date"
              label="Joining date"
              value={joiningDate}
              onChange={(value) => handleFieldChanges('joiningDate', value)}
              disabled={!hasAccessToEdit}
              timeZone="UTC"
              minDate={moment(new Date(dateOfBirth), DATE_FORMAT).add(1, 'day')}
              error={errors?.joiningDate}
            />
            <EzyAutoComplete
              tooltip="Main location of a team member."
              placeholder="Select primary location"
              label="Primary location"
              options={locationsList?.filter((e) => e?.hasAccess)}
              value={primaryLocationId}
              name="primaryLocationId"
              getOptionDisabled={(option) => {
                return disabledLocation?.map((e) => e?.id)?.includes(option?.id);
              }}
              readOnly={disabledLocation?.map((e) => e?.id)?.includes(primaryLocationId?.id)}
              onChange={(_, value) => {
                handleFieldChanges('primaryLocationId', value);
                if (value?.label === undefined) {
                  handleFieldChanges('workLocations', [...new Set([...workLocations])]);
                } else
                  handleFieldChanges('workLocations', [
                    ...new Set([value?.label, ...workLocations]),
                  ]);
                // }
              }}
              error={errors?.primaryLocationId}
              disabled={!hasAccessToEdit}
              required
            />
            <EzyAutoComplete
              tooltip="Locations where a team member can be scheduled. "
              id="tags-outlined"
              multiple
              className={`${workLocations?.length ? 'auto-complete-tags' : ''}`}
              placeholder="Select work locations"
              label="Work location"
              name="workLocation"
              value={workLocations}
              options={locationsList?.map((e) => e?.label)}
              getOptionDisabled={(option) => {
                return disabledLocation?.map((e) => e?.label)?.includes(option);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, chipIndex) => (
                  <Chip
                    variant="outlined"
                    color="primary"
                    label={option}
                    deleteIcon={<span className="material-icons">highlight_off</span>}
                    {...getTagProps({ chipIndex })}
                    disabled={disabledLocation?.map((e) => e?.label)?.includes(option)}
                  />
                ))
              }
              onChange={(_, value) => {
                if (primaryLocationId?.label !== undefined) {
                  handleFieldChanges('workLocations', [
                    ...new Set([
                      primaryLocationId?.label,
                      // eslint-disable-next-line no-unsafe-optional-chaining
                      ...disabledLocation
                        ?.filter((e) => workLocationsToDisabled?.includes(e?.label))
                        ?.map((e) => e?.label),
                      ...value,
                    ]),
                  ]);
                } else {
                  handleFieldChanges('workLocations', [
                    ...new Set([
                      // eslint-disable-next-line no-unsafe-optional-chaining
                      ...disabledLocation
                        ?.filter((e) => workLocationsToDisabled?.includes(e?.label))
                        ?.map((e) => e?.label),
                      ...value,
                    ]),
                  ]);
                }
              }}
              // error={errors?.locations?.[index]?.areas}
              disabled={!hasAccessToEdit}
            />
            <EzyAutoComplete
              placeholder="Select employee type"
              label="Employee type"
              options={employmentTypeList}
              value={employmentType}
              name="employmentType"
              onChange={(_, value) => handleFieldChanges('employmentType', value)}
              error={errors?.employmentType}
              disabled={!hasAccessToEdit}
              required
            />
            <EzyInputField
              tooltip="Payrates include designated payments for this team member."
              placeholder="Select pay rates"
              label="Pay rates"
              // options={payRateTypeList}
              value={payRateType?.label}
              name="payRateType"
              // onClose={() => setTimeout(() => handleFieldChanges('payGradeModal', payRateType), [500])}
              onClick={() => {
                handleFieldChanges('payGradeModal', true);
              }}
              disabled={!hasAccessToEdit}
            />
            <EzyAutoComplete
              tooltip="The period used to calculate overtime. This overrides the default location setting."
              placeholder="Select work period"
              label="Work period"
              options={[...workPeriodList, { id: 'ADD_NEW', label: 'Create new work period' }]}
              value={workPeriodId}
              name="workPeriodDetail"
              onChange={(_, value) => {
                if (value?.id === 'ADD_NEW') handleFieldChanges('addNewWorkPeriod', value);
                else handleFieldChanges('workPeriodId', value);
              }}
              disabled={!hasAccessToEdit}
            />
            {workPeriodId?.id && (
              <div className="two-grid">
                <EzyInputField
                  tooltip="How many hours they are expected to work in the work period."
                  name="hours"
                  placeholder="Enter hours"
                  label="Hours per work period"
                  value={hours}
                  onChange={handleTextChange}
                  error={errors?.hours}
                  endAdornment={<InputAdornment position="end">hrs</InputAdornment>}
                  disabled={!hasAccessToEdit}
                />
                <EzyInputField
                  name="minutes"
                  placeholder="Enter minutes"
                  label={'\xa0'}
                  value={minutes}
                  className="align-self-end"
                  onChange={handleTextChange}
                  endAdornment={<InputAdornment position="end">min</InputAdornment>}
                  error={errors?.minutes}
                  disabled={!hasAccessToEdit}
                />
              </div>
            )}
            <EzyInputField
              name="payRollId"
              placeholder="Enter payroll ID"
              label="Payroll ID"
              value={payRollId}
              // error={errors?.people?.[index]?.name}
              onChange={handleTextChange}
              disabled={!hasAccessToEdit}
            />
          </div>

          <HourlyPayGradeDrawer />
          <WorkPeriodDrawer />
        </>
      )}
    </div>
  );
}

export default PeopleDetails;
