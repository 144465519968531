import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Menu } from '@mui/material';
// import PropTypes from 'prop-types';
import moment from 'moment';
import EzyButton from '../../../components/common/button/EzyButton';
import { getGraphDataAction, updateGraphSettingsAction } from '../redux/scheduleActions';

export function graphDataList() {
  const { graphData } = useSelector(({ schedule }) => schedule?.statsPanelData ?? {});

  const list = graphData?.graphSettingList?.map((x) => {
    if (x?.statisticName === 'Staff') {
      return {
        ...x,
        subList: [
          {
            id: 11,
            label: 'Required staff',
            className: 'required-staff',
          },
          {
            id: 12,
            label: 'Filled shift',
            className: 'filled-shift',
          },
          {
            id: 13,
            label: 'All shift',
            className: 'all-shift',
          },
        ],
      };
    }
    if (x?.statisticName === 'Wages') {
      return {
        ...x,
        subList: [
          {
            id: 11,
            label: 'Scheduled wages',
            className: 'wages',
          },
        ],
      };
    }

    const subList = [
      {
        id: 11,
        label: 'Actual',
        className: 'sales',
      },
    ];
    if (x?.showPayrollForecast) {
      subList?.push({ id: 12, label: 'Forecast', className: 'sales-dashed' });
    }
    if (x?.showManagerForecast) {
      subList?.push({ id: 12, label: 'Manager forecast', className: 'sales-dotted' });
    }
    return {
      ...x,
      subList,
    };
  });

  return list;
}

function EditInsightGraphMenu() {
  // const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const menuRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);

  const { businessId } = useSelector(({ user }) => user?.profileData ?? {});

  const { weekDatesValue, selectedLocation } = useSelector(
    ({ schedule }) => schedule?.sidePanelData ?? {}
  );
  const list = graphDataList();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event?.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheck = (event, x) => {
    const data = {
      ...x,
      showStatistic: event?.target?.checked,
    };
    delete data?.subList;
    dispatch(
      updateGraphSettingsAction(data, () => {
        const paramsData = {
          startDate: moment(weekDatesValue?.startDate).toISOString(),
          endDate: moment(weekDatesValue?.endDate).toISOString(),
          businessId: selectedLocation?.id === 'all' ? businessId : null,
          locationId:
            selectedLocation?.id !== 'all' && !selectedLocation?.locationId
              ? selectedLocation?.id
              : selectedLocation?.locationId,
        };
        dispatch(getGraphDataAction(paramsData));
        handleClose();
      })
    );
  };

  return (
    <div>
      <EzyButton
        ref={menuRef}
        label="Edit graph"
        id="basic-button"
        className="edit-graph-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        className="ezy-menu-list edit-insight-graph-menu"
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <div className="edit-insight-graph-menu">
          {list?.map((x) => {
            return (
              <div key={x?.id} className="d-flex-column gap-5 w-full">
                <div className="label">
                  {x?.statisticName}
                  <Checkbox
                    onClick={(e) => {
                      handleCheck(e, x);
                    }}
                    checked={x?.showStatistic}
                  />
                </div>

                {x?.showStatistic &&
                  x?.subList?.map((y) => {
                    return (
                      <div key={y?.id} className="sub-list-label">
                        {y?.label}
                        <span
                          className={`label-director ${y?.className}`}
                          style={{
                            borderColor: x?.metricColour,
                            backgroundColor: y?.label === 'Actual' && x?.metricColour,
                          }}
                        />
                      </div>
                    );
                  })}
              </div>
            );
          })}
        </div>
      </Menu>
    </div>
  );
}

export default EditInsightGraphMenu;
