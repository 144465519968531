import { Badge, Divider, IconButton, Menu } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IconByName from '../../../../components/common/iconByName/IconByName';
import EzyTooltip from '../../../../components/common/tooltip/EzyTooltip';
import EzyButton from '../../../../components/common/button/EzyButton';
import { displayDateTime } from '../../../../utils/timeHelper';
import {
  deleteNotificationsAction,
  getNotificationsAction,
} from '../../redux/globalProfileActions';
import Loader from '../../../../components/common/loader/Loader';
import ROUTE_CONSTANTS from '../../../../utils/constants';

export function Notification() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const history = useHistory();

  const { notificationLoader } = useSelector(({ common }) => common.generalLoader ?? {});
  const { businessId, personId, timeZone } = useSelector(({ user }) => user?.profileData || {});
  const { notifications: list } = useSelector(({ globalProfile }) => globalProfile ?? {});
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRedirection = (data = {}) => {
    switch (data?.redirectionScreen) {
      case 1: {
        const { leaveRequestId, personDetailId } = data;
        if (leaveRequestId) {
          history.replace(`${ROUTE_CONSTANTS.PEOPLE}/edit/${personDetailId}`, {
            tab: 2,
            viewLeave: leaveRequestId,
          });
        } else {
          history.replace(`${ROUTE_CONSTANTS.PEOPLE}/edit/${personDetailId}`, {
            tab: 0,
          });
        }
        break;
      }
      case 2: {
        const { leaveRequestId, timeSheetId } = data;
        if (timeSheetId) {
          history.replace(`${ROUTE_CONSTANTS.GLOBAL_PROFILE}`, {
            tab: 1,
            viewShift: timeSheetId,
          });
        } else {
          history.replace(`${ROUTE_CONSTANTS.GLOBAL_PROFILE}`, {
            tab: 0,
            viewLeave: leaveRequestId,
          });
        }
        break;
      }
      case 3:
        history.push(`${ROUTE_CONSTANTS.HOME}/dashboard`);
        break;
      case 4: {
        const { date } = data;
        history.push(`${ROUTE_CONSTANTS.HOME}/calendar?date=${date}`);
        break;
      }
      // case 5: {
      //   const { date, locationId, shiftId } = data;
      //   history.push(`${ROUTE_CONSTANTS.SCHEDULE}?location=${locationId}&date=${date}`, {
      //     shiftIdToHighlight: shiftId,
      //   });
      //   break;
      // }
      // case 6: {
      //   const { date, locationId, areaId, personDetailId } = data;
      //   history.push(
      //     `${ROUTE_CONSTANTS.TIMESHEETS}?page=1&limit=15&location=${locationId}&area=${areaId}&person=${personDetailId}&date=${date}`
      //   );
      //   break;
      // }
      default:
        break;
    }
    handleClose();
  };

  useEffect(() => {
    if (businessId && personId) {
      (async () => {
        await dispatch(getNotificationsAction({ personId }));
      })();
    }
  }, [businessId, personId]);

  const getNotificationOnFocus = useCallback(() => {
    if (businessId && personId) {
      (async () => {
        await dispatch(getNotificationsAction({ personId }));
      })();
    }
  }, [businessId, personId]);

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        getNotificationOnFocus();
      }
    });
  }, []);

  return (
    <div className="notification">
      <EzyTooltip title="Notifications">
        <IconButton className="navBar-icon-buttons" onClick={handleClick}>
          <Badge
            badgeContent={list?.length}
            color="secondary"
            className={list?.length <= 0 && 'display-none'}
          >
            <IconByName name="notifications" />
          </Badge>
        </IconButton>
      </EzyTooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        className="notification"
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div className="notification-header">
          <div className="title">Notifications</div>
          {list?.length > 0 && (
            <EzyButton
              startIcon={<IconByName name="done_all" />}
              label="Clear All"
              variant="text"
              className="small"
              onClick={() => dispatch(deleteNotificationsAction())}
            />
          )}
        </div>
        <Divider />
        {notificationLoader ? (
          <Loader />
        ) : (
          <div className="notification-body-wrapper">
            {list?.length > 0 ? (
              list?.map(({ body, date, notificationRedirection }, index) => (
                <div key={index}>
                  <div
                    className="notification-body"
                    onClick={() => {
                      handleRedirection(notificationRedirection);
                    }}
                  >
                    <div className="d-flex flex-align-start gap-10">
                      <div className="dot" />
                      <div className="d-flex-column gap-5">
                        <div className="notification-message">{body}</div>
                        <div className="notification-date">
                          {displayDateTime(date, timeZone?.label)}
                        </div>
                      </div>
                    </div>
                  </div>
                  {index !== list.length - 1 && <Divider />}
                </div>
              ))
            ) : (
              <div className="no-data-found">{`You don't have any notifications!`}</div>
            )}
          </div>
        )}
      </Menu>
    </div>
  );
}

export default Notification;
