import apiService from '../../utils/apiService';
import API_URL from '../../utils/urlConstants';

const dashboardServices = {
  getDashboardShifts: (params) =>
    apiService.getData(API_URL.DASHBOARD.getDashboardShifts, { params }),
  getDashboardDetailsByBusinessId: (params) =>
    apiService.getData(API_URL.DASHBOARD.getDashboardDetailsByBusinessId, { params }),
  cancelShiftOfferInvitation: (data) =>
    apiService.postData(API_URL.DASHBOARD.cancelShiftOfferInvitation, data),
  acceptShiftOfferInvitation: (data) =>
    apiService.postData(API_URL.DASHBOARD.acceptShiftOfferInvitation, data),
};

export default dashboardServices;
