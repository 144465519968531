import { Chip } from '@mui/material';
import { formatPhoneWithCode } from '../../utils/phoneNumberHelper';
import EzyIconTooltip from '../../components/common/tooltip/EzyIconTooltip';
import { displayDate, displayDateTime, displayTime } from '../../utils/timeHelper';

export const headers = [
  {
    name: 'id',
    label: 'Id',
    hidden: true,
  },
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'access',
    label: 'Access',
  },
  {
    name: 'invitation',
    label: 'Status',
    format: (value) => <Chip variant="outlined" className={value?.label} label={value?.label} />,
  },
  {
    name: 'mainLocation',
    label: 'Main Location',
  },
  {
    name: 'email',
    label: 'Email',
  },
  {
    name: 'mobile',
    label: 'Mobile',
    format: (value, row) => formatPhoneWithCode(value, row?.countryCode),
  },
  {
    name: 'payRateDetails',
    label: 'Pay Rate',
    format: (rowValue, row) => {
      const generateData = (key, value) => (
        <div className="data">
          <span>{key}</span>
          <span>&nbsp;:&nbsp;{value}</span>
        </div>
      );

      return (
        <div className="pay-rate-tooltip">
          <span>{row?.payRates}</span>
          <EzyIconTooltip
            tooltip={
              <div className="pay-rate-tooltip-data-wrapper">
                {rowValue?.map((e, index) => (
                  <div className="pay-rate-tooltip-data" key={index}>
                    {e?.areaName && generateData('Area', e?.areaName)}
                    {e?.payData?.map((it2) => generateData(it2?.type, it2?.amount))}
                    {index + 1 !== rowValue.length && <hr />}
                  </div>
                ))}
              </div>
            }
            className="table-tooltip"
          />
        </div>
      );
    },
  },
  {
    name: 'isArchived',
    label: 'Status',
    format: (value) => (value ? 'Archived' : 'Employed'),
  },
];

export const leaveBalanceListHeaders = [
  {
    name: 'id',
    label: 'Id',
    hidden: true,
  },
  {
    name: 'businessLeaveRuleId',
    label: 'leaveId',
    hidden: true,
  },
  {
    name: 'businessLeaveRuleName',
    label: 'Leave Type',
  },
  {
    name: 'totalBalance',
    label: 'Total Balance',
    format: (e, row) => `${e} ${row.trackLeaveIn || ''}`,
  },
  {
    name: 'currentBalance',
    label: 'Current Balance',
    format: (e, row) => `${e} ${row.trackLeaveIn || ''}`,
  },
];

export const leaveRequestListHeaders = [
  {
    name: 'id',
    label: 'Id',
    hidden: true,
  },
  {
    name: 'businessLeaveRuleId',
    label: 'leaveId',
    hidden: true,
  },
  {
    name: 'reason',
    label: 'Reason',
    hidden: true,
  },
  {
    name: 'createdDate',
    label: 'Created Date',
    hidden: true,
  },
  {
    name: 'businessLeaveRuleName',
    label: 'Leave Title',
  },
  {
    name: 'fromDate',
    label: 'From',
    format: (val, row, timeZone) =>
      row?.isAllDay ? displayDate(val, timeZone) : displayDateTime(val, timeZone),
  },
  {
    name: 'toDate',
    label: 'To',
    format: (val, row, timeZone) =>
      row?.isAllDay ? displayDate(val, timeZone) : displayDateTime(val, timeZone),
  },
  {
    name: 'leaveStatusString',
    label: 'Status',
    format: (value) => {
      return (
        <Chip
          variant="outlined"
          className={value === 'Date Approved' ? 'Date-Approved' : value}
          label={value}
        />
      );
    },
  },
];

export const upComingShiftHeaders = [
  {
    name: 'shiftId',
    label: 'shiftId',
    hidden: true,
  },
  {
    name: 'areaName',
    label: 'Area',
    format: (val, row) => `${val} - ${row.locationName}`,
  },
  {
    name: 'startDate',
    label: 'Start Date',
    format: (value, row) => displayDate(value, row?.timeZoneId?.label),
  },
  {
    name: 'startTimeString',
    label: 'Time',
    format: (value, row) =>
      `${displayTime(row?.startTime, row?.timeZoneId?.label)} - ${displayTime(
        row?.endTime,
        row?.timeZoneId?.label
      )}`,
  },
  {
    name: 'isPublished',
    label: 'Status',
    format: (value) => (value !== true ? 'Not Published' : 'Published'),
  },
];

export const previousShiftHeaders = [
  {
    name: 'shiftId',
    label: 'shiftId',
    hidden: true,
  },
  {
    name: 'areaName',
    label: 'Area',
    format: (val, row) => `${val} - ${row.locationName}`,
  },
  {
    name: 'startDate',
    label: 'Start Date',
    format: (value, row) => displayDate(value, row?.timeZoneId?.label),
  },
  {
    name: 'startTimeString',
    label: 'Time',
    format: (value, row) =>
      `${displayTime(row?.startTime, row?.timeZoneId?.label)} - ${displayTime(
        row?.endTime,
        row?.timeZoneId?.label
      )}`,
  },
  {
    name: 'statusString',
    label: 'Status',
    // format: (value) => (value !== true ? 'Not Published' : 'Published'),
  },
];

export const profileTimeSheetHeaders = [
  {
    name: 'shiftId',
    label: 'Id',
    hidden: true,
  },
  {
    name: 'startDate',
    label: 'Date',
    format: (value, rowData) => displayDate(value, rowData?.timeZineID?.label),
  },
  {
    name: 'areaName',
    label: 'Area',
    hidden: false,
    format: (val, row) => `${val} - ${row.locationName}`,
  },
  {
    name: 'statusString',
    label: 'Status',
    hidden: false,
    format: (value) => {
      return <Chip variant="outlined" className={value} label={value} />;
    },
  },
  {
    name: 'progessStatusString',
    label: 'Progress',
    hidden: false,
  },
  {
    name: 'time',
    label: 'Time',
    format: (_, rowData) =>
      `${displayTime(rowData?.startTime, rowData?.timeZoneId?.label)} - ${displayTime(
        rowData?.endTime,
        rowData?.timeZoneId?.label
      )}`,
  },
];
