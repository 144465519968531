import { Checkbox, List, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import IconByName from '../../../../components/common/iconByName/IconByName';
import { displayTime } from '../../../../utils/timeHelper';
import { updateEditPeopleShiftDetailsField } from '../../redux/peopleSlice';
import { editPeopleStartBreak, getShiftDetailsByPersonID } from '../../redux/peopleActions';

function SelectBreakDrawer({ isFromDashboard }) {
  const dispatch = useDispatch();
  const peopleData = useSelector(({ people }) => people ?? {});
  const param = useParams();
  const { shiftData } = useMemo(() => peopleData?.editPeople?.details, [peopleData]);
  const { editPeopleStartBreakLoader } = useSelector(({ common }) => common.generalLoader ?? {});
  const { profileData } = useSelector(({ user }) => user || {});
  const userData = useMemo(() => profileData, [profileData]);
  const peopleId = isFromDashboard ? userData?.personId : param?.peopleId;
  const isFromProfile = !isFromDashboard ?? false;

  const handelFieldChange = (fieldName, value) => {
    dispatch(updateEditPeopleShiftDetailsField({ fieldName, value }));
  };
  const handelSelectBreak = (list) => {
    handelFieldChange('selectedShiftBreak', list);
  };
  const handelDrawerClose = () => {
    handelFieldChange('isSelectBreakDrawerEnable', false);
    handelFieldChange('selectedShiftBreak', undefined);
  };
  const handelStartBreak = () => {
    const data = {
      timeSheetId: shiftData?.timeSheetId,
      shiftBreakId: shiftData?.selectedShiftBreak?.shiftBreakId || undefined,
      breakType: shiftData?.selectedShiftBreak?.breakType?.id,
      isFromDashboard,
      isFromProfile,
    };
    if (shiftData?.isAllowToClockInFromWeb) {
      dispatch(
        editPeopleStartBreak(data, () => {
          dispatch(getShiftDetailsByPersonID(peopleId));
          handelFieldChange('isSelectBreakDrawerEnable', false);
          handelFieldChange('selectedShiftBreak', undefined);
        })
      );
    } else {
      dispatch(
        updateEditPeopleShiftDetailsField({ fieldName: 'shiftStartStopWarningModal', value: true })
      );
      handelDrawerClose();
    }
  };
  const actionButton = [
    {
      label: 'Start Break',
      onClick: handelStartBreak,
      disabled: !shiftData?.selectedShiftBreak,
      loading: editPeopleStartBreakLoader,
    },
  ];
  return (
    <EzyDrawer
      onClose={handelDrawerClose}
      isOpen={shiftData?.isSelectBreakDrawerEnable}
      heading="Select Break"
      actionButtons={actionButton}
    >
      <List className="edit-people-area-checks-lists">
        {shiftData?.breakDetails?.map((list) => (
          <ListItem
            key={`${list?.shiftBreakId}-${list?.breakType?.label}`}
            selected={
              `${list?.shiftBreakId}${list?.breakType?.label}` ===
              `${shiftData?.selectedShiftBreak?.shiftBreakId}${shiftData?.selectedShiftBreak?.breakType?.label}`
            }
            disablePadding
          >
            <ListItemButton dense disableRipple onClick={() => handelSelectBreak(list)}>
              <div>
                <ListItemIcon>
                  <div>
                    <Checkbox
                      edge="start"
                      checked={
                        `${list?.shiftBreakId}${list?.breakType?.label}` ===
                        `${shiftData?.selectedShiftBreak?.shiftBreakId}${shiftData?.selectedShiftBreak?.breakType?.label}`
                      }
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': 'labelId' }}
                      icon={<IconByName name="check_circle_outline" />}
                      checkedIcon={<IconByName name="check_circle" className="primary-color" />}
                    />
                    <span className="break-type">{list?.breakType?.label}</span>
                  </div>
                  <div>
                    <span className="payout">{list?.isBreakPaid ? 'Paid' : 'Un Paid'}</span>
                  </div>
                </ListItemIcon>
                <ListItemIcon>
                  <div>
                    <Checkbox
                      edge="start"
                      checked={
                        `${list?.shiftBreakId}${list?.breakType?.label}` ===
                        `${shiftData?.selectedShiftBreak?.shiftBreakId}${shiftData?.selectedShiftBreak?.breakType?.label}`
                      }
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': 'labelId' }}
                      icon={<IconByName name="watch_later" className="watch-later-icon" />}
                      checkedIcon={
                        <IconByName name="watch_later" className="primary-color watch-later-icon" />
                      }
                    />
                    <span className="time">
                      {`${displayTime(list?.breakStartTime) || '00'}-${
                        displayTime(list?.breakEndTime) || '00'
                      } |${list?.isBreakScheduled ? `${Math.floor(list.breakDuration)} m` : ' --'}`}
                    </span>
                  </div>
                  <div>
                    <span className="shift-type">
                      {list?.isBreakScheduled ? 'Scheduled' : 'Unscheduled'}
                    </span>
                  </div>
                </ListItemIcon>
              </div>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </EzyDrawer>
  );
}
export default SelectBreakDrawer;
SelectBreakDrawer.propTypes = {
  isFromDashboard: PropTypes.bool,
};

SelectBreakDrawer.defaultProps = {
  isFromDashboard: false,
};
