import { DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';
import IconByName from '../iconByName/IconByName';

function EzyDialogTitle({ title, isCloseIcon, className, onClose }) {
  return (
    <DialogTitle className={`dialog-title ${className}`}>
      <div className="title">{title}</div>
      {isCloseIcon && <IconByName name="close" className="pointer-cursor" onClick={onClose} />}
    </DialogTitle>
  );
}

export default EzyDialogTitle;

EzyDialogTitle.propTypes = {
  title: PropTypes.string.isRequired,
  isCloseIcon: PropTypes.bool,
  className: PropTypes.string,
  onClose: PropTypes.func,
};

EzyDialogTitle.defaultProps = {
  isCloseIcon: true,
  className: '',
  onClose: () => {},
};
