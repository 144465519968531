import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useMemo } from 'react';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import { resetBulkActionData } from '../redux/scheduleSlice';
import EzyDialog from '../../../components/common/dialog/EzyDialog';
import EzyDialogTitle from '../../../components/common/dialog/EzyDialogTitle';
import EzyDialogAction from '../../../components/common/dialog/EzyDialogAction';
import EzyTable from '../../../components/common/table/EzyTable';
import { templateErrorByAreaHeaders, templateErrorByPeopleHeaders } from './templateErrorHeaders';

function TemplateErrorModal({ reFetchEvents }) {
  const { loadTemplateErrors, isRepeatErrorModal, isLoadErrorModal } = useSelector(
    ({ schedule }) => schedule?.bulkActionData ?? {}
  );
  const { selectedLocation } = useSelector(({ schedule }) => schedule?.sidePanelData ?? {});

  const { errorReasons, incorrectAreas } = useMemo(
    () => loadTemplateErrors ?? {},
    [loadTemplateErrors]
  );

  const dispatch = useDispatch();

  const handleClose = () => {
    reFetchEvents();
    dispatch(resetBulkActionData());
  };

  const actionButtons = [
    {
      label: 'Close',
      onClick: handleClose,
      color: 'secondary',
    },
  ];

  return (
    <EzyDialog
      handleClose={handleClose}
      open={isLoadErrorModal || isRepeatErrorModal}
      className="common-dialog-with-body template-error-modal"
    >
      <EzyDialogTitle
        title={isRepeatErrorModal ? 'Repeat Shifts' : 'Copy Shifts'}
        onClose={handleClose}
      />
      <div className="dialog-body">
        <div className="primary-bg">
          {isRepeatErrorModal ? 'Shift repeat status' : 'Shift copy status'}
        </div>
        {incorrectAreas?.length > 0 && (
          <div className="table-wrapper">
            <Paper className="table-paper">
              <EzyTable
                headers={templateErrorByAreaHeaders}
                rows={incorrectAreas?.map((e) => ({ reason: e }))}
                className="people-import-table"
              />
            </Paper>
          </div>
        )}
        {errorReasons?.length > 0 && (
          <div className="table-wrapper">
            <Paper className="table-paper">
              <EzyTable
                headers={templateErrorByPeopleHeaders}
                rows={errorReasons?.map((e) => {
                  return { ...e, timeZoneId: selectedLocation?.timeZoneId?.label };
                })}
                className="people-import-table"
              />
            </Paper>
          </div>
        )}
      </div>
      <EzyDialogAction actionButtons={actionButtons} />
    </EzyDialog>
  );
}

export default TemplateErrorModal;

TemplateErrorModal.propTypes = {
  reFetchEvents: PropTypes.func.isRequired,
};
