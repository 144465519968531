import { Box, IconButton, Menu, MenuItem, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import whiteLogo from '../../../assets/images/logo/PayrollEzy_WhiteLogo.png';
import useRouteMatch from '../../../hooks/useRouteMatch';
import ROUTE_CONSTANTS, { NAVBAR_CONSTANTS } from '../../../utils/constants';
import IconByName from '../iconByName/IconByName';
import EzyTooltip from '../tooltip/EzyTooltip';
import { getCurrentUserDataAction } from '../../../pages/auth/redux/userAction';
import useAccess from '../../../hooks/useAccess';
import AccessWrapper from '../accessWrapper/AccessWrapper';
import GlobalProfile from '../../../pages/globalProfile/GlobalProfile';
import Notification from '../../../pages/globalProfile/components/notification/Notification';

function EzyNavBar({ isFormProfile, children }) {
  const routeMatch = useRouteMatch(NAVBAR_CONSTANTS.map((res) => res.tabValueAndLinkUrl));
  const { getCurrentUserDataLoader, verifyEmailFromLinkActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );
  const access = useAccess(NAVBAR_CONSTANTS?.map((e) => e.access));
  const hasAccessOfExportTS = useAccess('LISTETS');

  const { personId, businessId } = useSelector(({ user }) => user?.profileData ?? {});

  const tabData = useMemo(
    () => NAVBAR_CONSTANTS?.filter((e) => access?.includes(e?.access)),
    [access]
  );
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const history = useHistory();

  // For timeSheet menu

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(1);
  const open = Boolean(anchorEl);

  const timeSheetMenu = [
    {
      id: ROUTE_CONSTANTS.TIMESHEETS,
      label: 'Approve TimeSheets',
      icon: 'task',
      subtitle: 'Check that submitted timesheets are accurate, adjust or approve them',
      onClick: () => {
        history.push(ROUTE_CONSTANTS.TIMESHEETS);
      },
    },
    {
      id: ROUTE_CONSTANTS.EXPORT_TIMESHEETS,
      label: 'Export TimeSheets',
      icon: 'file_download',
      subtitle: 'Send approved timesheets to your payroll package',
      onClick: () => {
        if (hasAccessOfExportTS) history.push(ROUTE_CONSTANTS.EXPORT_TIMESHEETS);
      },
    },
  ];

  const handleClick = (event) => {
    event?.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!personId || !businessId) {
      (async () => {
        await dispatch(
          getCurrentUserDataAction(
            pathname === ROUTE_CONSTANTS.REGISTER_BUSINESS
              ? () => {
                  history.push(ROUTE_CONSTANTS.REGISTER_BUSINESS);
                }
              : () => {
                  history.push(ROUTE_CONSTANTS.ACCOUNT_OVERVIEW);
                }
          )
        );
      })();
    }
  }, [personId, businessId]);

  return (
    <Box className="navBarContainer">
      {!isFormProfile ? (
        <NavLink to={ROUTE_CONSTANTS.HOME}>
          <img src={whiteLogo} className="navBarLogo" alt="company Logo comes here" />
        </NavLink>
      ) : (
        <img src={whiteLogo} className="navBarLogo" alt="company Logo comes here" />
      )}
      {!getCurrentUserDataLoader && !verifyEmailFromLinkActionLoader && isFormProfile && children}
      {!getCurrentUserDataLoader && !verifyEmailFromLinkActionLoader && !isFormProfile && (
        <Fragment>
          <Tabs
            value={routeMatch}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            className="ezyTabs"
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{
              children: <div className={`MuiIndicator ${!routeMatch ? 'display-none' : ''}`} />,
            }}
          >
            {tabData?.map((res, index) => (
              <Tab
                key={index}
                value={res?.tabValueAndLinkUrl}
                icon={<span className="material-icons">{res?.iconName}</span>}
                iconPosition="start"
                component={NavLink}
                to={res?.tabValueAndLinkUrl}
                label={res?.tabLabel}
                className="ezyTab"
                onClick={(e) => {
                  if (res?.tabLabel === 'Timesheets') {
                    handleClick(e);
                  }
                }}
              />
            ))}
          </Tabs>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={hasAccessOfExportTS ? open : false}
            className="timesheet-menu ezy-menu-list "
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            PaperProps={{
              style: {
                width: '250px',
              },
            }}
          >
            {timeSheetMenu?.map((menu) => (
              <MenuItem
                onClick={(e) => {
                  handleClose();
                  if (menu?.onClick) {
                    setSelectedMenu(menu?.id);
                    menu?.onClick(e, menu);
                  }
                }}
                key={menu?.id}
                selected={selectedMenu && menu?.id === pathname}
                // disabled={item.disabled}
              >
                <div className="d-flex-column">
                  <div className="flex-center gap-5 subtitle-text">
                    <span className="material-icons">{menu?.icon}</span>
                    {menu?.label}
                  </div>
                  <span className="text-secondary-color">{menu?.subtitle}</span>
                </div>
              </MenuItem>
            ))}
          </Menu>

          <Box className="navBarUserinfoSection">
            <Notification />
            <AccessWrapper access="EDITBUS">
              <NavLink
                to={ROUTE_CONSTANTS.BUSINESS_SETTINGS}
                className="nav-link"
                activeClassName="activated"
              >
                <EzyTooltip title="Settings">
                  <IconButton className="navBar-icon-buttons">
                    <IconByName name="tune" />
                  </IconButton>
                </EzyTooltip>
              </NavLink>
            </AccessWrapper>
            <GlobalProfile />
          </Box>
        </Fragment>
      )}
    </Box>
  );
}

export default EzyNavBar;

EzyNavBar.propTypes = {
  isFormProfile: PropTypes.bool,
  children: PropTypes.element,
};

EzyNavBar.defaultProps = {
  isFormProfile: false,
  children: null,
};
