import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { InputAdornment } from '@mui/material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import EzyDialog from '../../components/common/dialog/EzyDialog';
import blackLogo from '../../assets/images/logo/PayrollEzy_BlackLogo.png';
import EzyInputField from '../../components/common/inputField/EzyInputField';
import EzyButton from '../../components/common/button/EzyButton';
import authServices from './authServices';
import ROUTE_CONSTANTS from '../../utils/constants';
import { displayErrors } from '../../utils/errorNotifyHelper';
import useKeyQueryParams from '../../hooks/useKeyQueryParams';
import InfoModal from '../../components/infoModal/InfoModal';
import linkNotFound from '../../assets/images/Link not found.png';
import EzyPhoneNumber from '../../components/common/phoneNumberInput/ezyPhoneNumber';
import validateInvite from './validations/validateInvite';
import { formatPhoneToSend, formatPhoneWithCode } from '../../utils/phoneNumberHelper';
import { getAllTimezonesAction } from '../../utils/backOfficeLists/backOfficeListActions';
import { mapLabelFromList } from '../../utils/enumMapping';
import useKeyPress from '../../hooks/useKeyPress';

function InviteSignup() {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [isSignUpModal, setIsSignUpModal] = useState(true);
  const [isExpireModal, setIsExpireModal] = useState(false);
  const [showPassword, setShowPassword] = useState({
    pass: false,
    cPass: false,
  });

  const history = useHistory();
  const { key } = useKeyQueryParams();
  const isEnterPressed = useKeyPress('Enter');

  const { timeZonesList } = useSelector(({ common }) => common ?? {});

  const updateFormData = (name, value) => {
    setFormData((data) => ({ ...data, [name]: value }));
  };

  const handlePasswordShow = (index) => {
    setShowPassword((e) => ({ ...e, [index]: !e[index] }));
  };

  const handleSubmit = async () => {
    const errorsData = validateInvite(formData);
    setErrors(errorsData);
    if (isEmpty(errorsData)) {
      const timezone = mapLabelFromList(moment.tz.guess(), timeZonesList);
      try {
        setLoading(true);
        const res = await authServices.userSignUp({
          name: formData.username.toString().trim(),
          personDetailsId: formData.personDetailsId,
          password: formData.password.toString().trim(),
          mobileNumber: formatPhoneToSend(formData?.phoneNumber, formData?.countryCode),
          countryCode: formData?.countryCode,
          timeZoneId: timezone?.id,
        });
        if (res?.data?.success) {
          setIsSignUpModal(false);
          history.push(ROUTE_CONSTANTS.LOGIN);
        }
      } catch (e) {
        displayErrors(e);
        /**/
      } finally {
        setLoading(false);
      }
    }
  };

  const validateLink = async () => {
    try {
      setLoading(true);
      const res = await authServices.getUserSignUpDetails({ key });
      if (res?.data?.success && res.data?.data !== null) {
        setFormData({
          ...res?.data?.data,
          username: res?.data?.data?.name,
          phoneNumber: formatPhoneWithCode(
            res?.data?.data?.mobileNumber,
            res?.data?.data?.countryCode
          ),
          name: null,
          password: null,
        });
      } else {
        setIsExpireModal(true);
      }
    } catch (e) {
      setIsExpireModal(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (key) await validateLink();
      dispatch(getAllTimezonesAction());
    })();
  }, [key]);

  useEffect(() => {
    if (isEnterPressed) handleSubmit();
  }, [isEnterPressed]);

  return (
    <div>
      <EzyDialog handleClose={() => {}} open={isSignUpModal} className="auth-dialog">
        <div className="auth-wrapper">
          <img src={blackLogo} alt="logo" width={200} />
          <div className="info-text">
            <div className="primary-large-title">Create an account</div>
            <div className="subtitle-text">
              Please provide below details to continue with invitation.
            </div>
          </div>
          <div className="auth-form">
            <div className="field-wrapper">
              <EzyInputField
                label="Username"
                placeholder="Enter your username"
                name="username"
                onChange={(e) => updateFormData(e.target.name, e.target.value)}
                value={formData.username}
                error={errors?.username}
                required
              />
              <EzyPhoneNumber
                country="au"
                placeholder="4 123 456 789"
                label="What is your mobile number?"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={(value, data) => {
                  updateFormData('phoneNumber', value);
                  updateFormData('countryCode', data?.dialCode);
                  updateFormData('phoneNumberData', data);
                }}
                error={errors?.phoneNumber}
                required
              />
              <EzyInputField
                type={showPassword.pass ? 'text' : 'password'}
                label="Password"
                placeholder="Enter your password"
                name="password"
                tooltip="Password should contain at least 8 letters, 1 uppercase and 1 special character."
                onChange={(e) => updateFormData(e.target.name, e.target.value)}
                value={formData.password}
                error={errors?.password}
                required
                endAdornment={
                  <InputAdornment position="end">
                    <span
                      className="material-icons icon-small endAdornment-icon"
                      onClick={() => handlePasswordShow('pass')}
                      role="presentation"
                    >
                      {showPassword.pass ? 'visibility_off' : 'visibility'}
                    </span>
                  </InputAdornment>
                }
              />
              <EzyInputField
                type={showPassword.cPass ? 'text' : 'password'}
                label="Confirm password"
                placeholder="Confirm your password"
                name="confirmPassword"
                onChange={(e) => updateFormData(e.target.name, e.target.value)}
                value={formData.confirmPassword}
                error={errors?.confirmPassword}
                required
                endAdornment={
                  <InputAdornment position="end">
                    <span
                      className="material-icons icon-small endAdornment-icon"
                      onClick={() => handlePasswordShow('cPass')}
                      role="presentation"
                    >
                      {showPassword.cPass ? 'visibility_off' : 'visibility'}
                    </span>
                  </InputAdornment>
                }
              />
            </div>
            <div className="action-button">
              <EzyButton label="Register" onClick={handleSubmit} loading={loading} />
            </div>
          </div>
        </div>
      </EzyDialog>
      <InfoModal
        img={linkNotFound}
        subTitle="Sorry!, Your link for accept invitation is no longer valid. Request new one."
        title="Invalid link!"
        action={
          <div className="subtitle-text ">
            {`Link expired? `}
            <span
              onClick={() => history.push(ROUTE_CONSTANTS.LOGIN)}
              className="secondary-action-text"
            >
              Request new
            </span>
          </div>
        }
        open={isExpireModal && !loading}
        onClose={() => history.push(ROUTE_CONSTANTS.LOGIN)}
      />
    </div>
  );
}

export default InviteSignup;
