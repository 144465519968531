import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import moment from 'moment';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import {
  resetAddBudgetDrawerData,
  updateAddBudgetDrawer,
  updateBudgetStatisticsEntityList,
} from '../../redux/insightSlice';
import EzyToggleButtonGroup from '../../../../components/common/toggleButton/EzyToggleButtonGroup';
import EzySwitch from '../../../../components/common/switch/EzySwitch';
import EzyAccordion from '../../../../components/common/accordion/EzyAccordion';
import EzyAccordionSummary from '../../../../components/common/accordion/EzyAccordionSummary';
import EzyAccordionWrapper from '../../../../components/common/accordion/EzyAccordionWrapper';
import EzyAccordionDetails from '../../../../components/common/accordion/EzyAccordinDetails';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import { addBudgetAction, getInsightListAction } from '../../redux/insightAction';
import ROUTE_CONSTANTS from '../../../../utils/constants';

function AddBudgetDrawer() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { sidePanelList, filters } = useSelector(({ insight }) => insight ?? {});

  const {
    isOpen,
    setBudgetForArea,
    budgetPeriod,
    budgetEntity = sidePanelList?.budgetList?.find((e) => e?.label?.includes('Hour'))?.id ? 2 : 1,
    budgetStatisticsEntityList,
  } = useSelector(({ insight }) => insight?.budget?.addBudgetDrawer ?? {});

  const { addBudgetActionLoader } = useSelector(({ common }) => common.generalLoader ?? {});

  const {
    budgetPeriod: budgetPeriodEnum,
    budgetedEntity: budgetedEntityEnum,
    weekDays: weekDaysEnum,
  } = useSelector(({ common }) => common?.enums ?? {});

  const handleClose = () => {
    dispatch(resetAddBudgetDrawerData());
  };

  const handleFieldChange = (fieldName, value, day, areaId = null) => {
    if (day || day === null) {
      dispatch(updateBudgetStatisticsEntityList({ areaId, value, day }));
    } else dispatch(updateAddBudgetDrawer({ fieldName, value }));
  };

  const budgetWrapper = (area = {}) => {
    return (
      <div className="budget-entry-wrapper">
        {budgetPeriod === 1 ? (
          weekDaysEnum?.map((e, index) => (
            <div key={`${e?.id}-${area?.id}-${index}`}>
              <EzyInputField
                type="number"
                placeholder={0}
                onChange={(event) => {
                  handleFieldChange('', event?.target?.value, e?.id, area?.id);
                }}
                value={
                  setBudgetForArea
                    ? budgetStatisticsEntityList?.find(
                        (list) => list?.day === e?.id && list?.areaId === area?.id
                      )?.value
                    : budgetStatisticsEntityList?.find((list) => list?.day === e?.id)?.value
                }
              />
            </div>
          ))
        ) : (
          <EzyInputField
            key={area?.id}
            type="number"
            placeholder={0}
            onChange={(event) => {
              handleFieldChange('', event?.target?.value, null, area?.id);
            }}
            value={
              setBudgetForArea
                ? budgetStatisticsEntityList?.find((list) => list?.areaId === area?.id)?.value
                : budgetStatisticsEntityList?.[0]?.value
            }
          />
        )}
      </div>
    );
  };

  const handleSubmit = () => {
    const data = {
      setBudgetForArea,
      budgetPeriod,
      budgetEntity,
      locationId: filters?.selectedLocation?.id,
      budgetStatisticsEntityList,
    };

    dispatch(
      addBudgetAction(data, () => {
        handleClose();
        dispatch(
          getInsightListAction(
            `${budgetedEntityEnum?.find((e) => e?.id === budgetEntity)?.label} budget`,
            (selectedModule) => {
              history.push(
                `${ROUTE_CONSTANTS.SCHEDULE}/insights/${selectedModule?.pathName}?location=${
                  filters?.selectedLocation?.id
                }&date=${moment
                  .tz(
                    filters?.weekDatesValue?.startDate,
                    filters?.selectedLocation?.timeZoneId?.label
                  )
                  .toISOString()}`
              );
            }
          )
        );
      })
    );
  };

  return (
    <EzyDrawer
      isOpen={isOpen}
      onClose={handleClose}
      heading="Add New Budget"
      actionButtons={[{ label: 'Save', onClick: handleSubmit, loading: addBudgetActionLoader }]}
    >
      <div className="add-budget-drawer">
        <EzyToggleButtonGroup
          className="small"
          label="What are you budgeting?"
          list={[
            {
              id: 1,
              label: 'Hour',
              subtitle: 'Set a budget for the number of hours rostered in a period',
              disabled: sidePanelList?.budgetList?.find((e) => e?.label?.includes('Hour'))?.id,
            },
            {
              id: 2,
              label: 'Wages',
              subtitle: 'Set a budget for the cost of wages in a period',
              disabled: sidePanelList?.budgetList?.find((e) => e?.label?.includes('Wages'))?.id,
            },
          ]}
          name="budgetEntity"
          handleChange={(event, res) => {
            if (res !== null) {
              const value = budgetedEntityEnum?.find((e) => e?.label === res)?.id;
              handleFieldChange('budgetEntity', value);
            }
          }}
          value={
            budgetEntity ? budgetedEntityEnum?.find((e) => e?.id === budgetEntity)?.label : null
          }
          required
        />

        <EzyToggleButtonGroup
          label="Budget period"
          list={budgetPeriodEnum?.map((e) => e?.label)}
          selected="Daily"
          name="budgetPeriod"
          handleChange={(event) => {
            const value = budgetPeriodEnum?.find((e) => e?.label === event.target.value)?.id;
            handleFieldChange('budgetPeriod', value);
            handleFieldChange('budgetStatisticsEntityList', []);
          }}
          value={budgetPeriodEnum?.find((e) => e?.id === budgetPeriod)?.label}
          className="small"
        />

        <div className="d-flex-column gap-5">
          <div className="title-text">
            {budgetPeriodEnum?.find((e) => e?.id === budgetPeriod)?.label}{' '}
            {budgetedEntityEnum?.find((e) => e?.id === budgetEntity)?.label} budget for{' '}
            {filters?.selectedLocation?.label ?? '-'}
          </div>
          <div className="d-flex gap-10">
            <EzySwitch
              name="setBudgetForArea"
              onChange={(e) => {
                handleFieldChange('setBudgetForArea', e?.target?.checked);
                handleFieldChange('budgetStatisticsEntityList', []);
              }}
              checked={setBudgetForArea}
            />
            <span className="body-text text-secondary-color">Set a budget for each area</span>
          </div>
        </div>

        <EzyAccordionWrapper className="budget-details-accordion">
          <EzyAccordion expanded>
            {budgetPeriod === 1 && (
              <EzyAccordionSummary className="accordion-summary icon-small add" expandIcon={null}>
                {weekDaysEnum?.map((e, index) => (
                  <div key={`${e?.id}-${index}`}>{e?.label?.substr(0, 3)}</div>
                ))}
              </EzyAccordionSummary>
            )}
            <EzyAccordionDetails>
              {setBudgetForArea
                ? filters?.selectedLocation?.areaList?.map((area) => (
                    <div key={area?.id}>
                      <span className="body-text text-secondary-color">{area?.label}</span>
                      {budgetWrapper(setBudgetForArea ? area : {})}
                    </div>
                  ))
                : budgetWrapper()}
            </EzyAccordionDetails>
          </EzyAccordion>
        </EzyAccordionWrapper>
      </div>
    </EzyDrawer>
  );
}

AddBudgetDrawer.propTypes = {};

export default AddBudgetDrawer;
