import {
  startGeneralLoader,
  stopGeneralLoader,
} from '../../../utils/backOfficeLists/commonDataSlice';
import { displayErrors } from '../../../utils/errorNotifyHelper';
import { tasksServices } from '../tasksServices';
import {
  setTasksList,
  setAddTaskDrawerDetails,
  setAddTaskForWholeAreaDetails,
  setAreaTasksList,
  updateTasksListDocs,
  // updateAddTaskForAreaDrawerDetails,
} from './tasksSlice';
import { successNotification } from '../../../utils/notifyHelper';
import { mapEnumToIds, mapIdsFromList } from '../../../utils/enumMapping';

export const getTasksAction = (params) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const finalParams = {
        ...params,
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getTasksActionLoader' }));
      const res = await tasksServices.getTasks(finalParams);
      const data = mapEnumToIds(
        [{ fieldName: 'timeZoneId', listName: 'timeZonesList', isFromEnum: false }],
        res?.data,
        state?.common
      );
      if (res?.data?.success) {
        if (params?.page < 2) dispatch(setTasksList(data));
        else dispatch(updateTasksListDocs(data));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getTasksActionLoader' }));
    }
  };
};

export const getTasksByAreaAction = (params) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const finalParams = {
        ...params,
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getTasksByAreaActionLoader' }));
      const res = await tasksServices.getTasksByArea(finalParams);

      if (res?.data?.success) {
        dispatch(setAreaTasksList(res?.data?.data));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getTasksByAreaActionLoader' }));
    }
  };
};

export const addTasksForPersonAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'addTasksForPersonActionLoader' }));
      const res = await tasksServices.addTasksForPerson(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb(res?.data);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'addTasksForPersonActionLoader' }));
    }
  };
};
export const getTaskDetailsByIdAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getTaskDetailsByIdActionLoader' }));
      const res = await tasksServices.getTaskDetailsById(params);
      if (res?.data?.success) {
        dispatch(setAddTaskDrawerDetails(res?.data?.data));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getTaskDetailsByIdActionLoader' }));
    }
  };
};

export const editTaskAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'editTaskActionLoader' }));
      const res = await tasksServices.editTask(data);
      if (res?.data?.success) {
        cb();
        successNotification(res?.data?.message);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'editTaskActionLoader' }));
    }
  };
};

export const addTasksForWholeAreaAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'addTasksForWholeAreaActionLoader' }));
      const res = await tasksServices.addTaskForWholeArea(data);

      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb(res?.data);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'addTasksForWholeAreaActionLoader' }));
    }
  };
};

export const changeSubTaskStatusAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'changeSubTaskStatusActionLoader' }));
      const res = await tasksServices.changeSubTaskStatus(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'changeSubTaskStatusActionLoader' }));
    }
  };
};

export const changeTaskStatusAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'changeTaskStatusActionLoader' }));
      const res = await tasksServices.changeTaskStatus(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'changeTaskStatusActionLoader' }));
    }
  };
};

export const deleteTaskAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'deleteTaskActionLoader' }));
      const res = await tasksServices.deleteTask(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deleteTaskActionLoader' }));
    }
  };
};

export const getTaskForWholeAreaByIdAction = (params) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { weekDays: weekDaysEnum, datesOfMonthList: datesOfMonthListEnum } =
      state?.common?.enums ?? {};
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getTaskForWholeAreaByIdAction' }));
      const res = await tasksServices.getTaskForWholeAreaById(params);
      let data = mapEnumToIds(
        [{ fieldName: 'taskRepeatationFrequency', listName: 'description', isFromEnum: true }],
        res?.data?.data,
        state?.common
      );
      const resData = res.data.data;
      if (res?.data?.success) {
        data = {
          ...res?.data?.data,
          weekDays: mapIdsFromList(resData?.weekDays, weekDaysEnum),
          dayOfMonth: mapIdsFromList(resData?.dayOfMonth, datesOfMonthListEnum),
        };

        dispatch(setAddTaskForWholeAreaDetails(data));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getTaskForWholeAreaByIdAction' }));
    }
  };
};

export const editTaskForWholeAreaAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'editTaskForWholeAreaActionLoader' }));
      const res = await tasksServices.editTaskForWholeArea(data);
      if (res?.data?.success) {
        cb();
        successNotification(res?.data?.message);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'editTaskForWholeAreaActionLoader' }));
    }
  };
};
