import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import * as React from 'react';
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox, IconButton, Menu, MenuItem, Table } from '@mui/material';
import { useSelector } from 'react-redux';
import IconByName from '../iconByName/IconByName';
import Loader from '../loader/Loader';
import AccessWrapper from '../accessWrapper/AccessWrapper';
import useAccess from '../../../hooks/useAccess';
import { compareArrays } from '../../../utils/validationHelper';

function EzyTable({
  headers,
  rows,
  className,
  selectableRow,
  selected,
  setSelected,
  actions,
  loading,
  onRowClick,
  accessForRow,
  accessForAction,
  timeZone: timeZoneId,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const checkedAccess = useAccess(accessForRow);
  const checkedActionsAccess = useAccess(accessForAction);

  const { timeZone } = useSelector(({ user }) => user?.profileData ?? {});

  // Checking for access arrays and bool
  const hasAccess = useMemo(() => {
    if (Array.isArray(checkedAccess) && !compareArrays(accessForRow, checkedAccess)) return false;
    return checkedAccess;
  }, [checkedAccess]);

  // Checking for access arrays and bool for actions
  const hasAccessForActions = useMemo(() => {
    if (Array.isArray(checkedActionsAccess) && checkedActionsAccess?.length <= 0) return false;
    return checkedActionsAccess;
  }, [checkedActionsAccess]);

  const handleClick = (event, row, index) => {
    setOpenMenu(index);
    setSelectedRowData(row);
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    setSelectedRowData(null);
    setOpenMenu(null);
    event.stopPropagation();
  };

  const handleAction = (event, cb) => {
    handleClose(event);
    if (typeof cb === 'function') {
      cb(selectedRowData);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleCheck = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    event.preventDefault();
    event.stopPropagation();
  };

  const hasAction = () => {
    if (actions?.length > 0) return true;
    const actionInRow = rows?.some((e) => e?.action?.length > 0);
    return !!actionInRow;
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <TableContainer className={className}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {selectableRow && (
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={selected.length > 0 && selected.length < rows.length}
                  checked={rows.length > 0 && selected.length === rows.length}
                  onChange={handleSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all',
                  }}
                  size="small"
                />
              </TableCell>
            )}
            {headers
              ?.filter((e) => !e?.hidden)
              ?.map((column, index) => (
                <TableCell
                  key={index}
                  align={column?.align || 'left'}
                  style={{ minWidth: column?.minWidth }}
                >
                  {column?.label}
                </TableCell>
              ))}
            {hasAction() && <TableCell align="right">Actions</TableCell>}
          </TableRow>
        </TableHead>
        {loading ? (
          <Loader />
        ) : (
          <TableBody>
            {rows?.length > 0 ? (
              rows?.map((row, index) => {
                // const action = actions?.filter((dt) =>
                //   dt?.condition && typeof dt?.condition === 'function' ? dt?.condition(row) : true
                // );
                const action = actions?.length > 0 ? actions : row?.action || [];
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row?.id || index}
                    onClick={() => {
                      if (hasAccess && typeof onRowClick === 'function' && onRowClick)
                        onRowClick(row);
                    }}
                    className={
                      onRowClick && typeof onRowClick === 'function' ? 'pointer-cursor' : ''
                    }
                  >
                    {selectableRow && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          // onChange={(e) => handleCheck(e, row.id)}
                          onClick={(e) => {
                            handleCheck(e, row.id);
                          }}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                          size="small"
                        />
                      </TableCell>
                    )}
                    {headers
                      ?.filter((e) => !e?.hidden)
                      ?.map((column) => {
                        const value = row[column?.name];
                        return (
                          <TableCell
                            key={column?.name}
                            align={column?.align || 'left'}
                            title={column?.tooltip ? value : undefined}
                          >
                            {(column?.format
                              ? column?.format(value, row, timeZoneId ?? timeZone?.label)
                              : value) || '-'}
                          </TableCell>
                        );
                      })}
                    {action?.length > 0 && (
                      <TableCell align="right">
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={openMenu ? 'long-menu' : undefined}
                          aria-expanded={openMenu ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={(e) => handleClick(e, row, index)}
                          disabled={
                            action?.[0] === 'DISABLED' ||
                            // (action?.length === 1 && !hasAccess) ||
                            !hasAccessForActions
                          }
                        >
                          <IconByName name="more_vert" />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            'aria-labelledby': 'long-button',
                          }}
                          anchorEl={anchorEl}
                          open={openMenu === index}
                          onClose={handleClose}
                        >
                          {action?.[0] !== 'DISABLED' &&
                            action?.map(({ label, access, onClick }, i) => (
                              <AccessWrapper access={access} byPass={!access}>
                                <MenuItem
                                  key={i}
                                  onClick={(e) => {
                                    handleAction(e, onClick);
                                  }}
                                >
                                  {label}
                                </MenuItem>
                              </AccessWrapper>
                            ))}
                        </Menu>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })
            ) : (
              <Box className="no-data-found error-color">No records found</Box>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}

export default EzyTable;

EzyTable.propTypes = {
  headers: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  className: PropTypes.string,
  selectableRow: PropTypes.bool,
  actions: PropTypes.array,
  loading: PropTypes.bool,
  onRowClick: PropTypes.func,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  accessForRow: PropTypes.any,
  accessForAction: PropTypes.any,
  timeZone: PropTypes.string,
};

EzyTable.defaultProps = {
  className: '',
  selectableRow: false,
  actions: null,
  loading: false,
  onRowClick: null,
  selected: [],
  setSelected: null,
  accessForRow: null,
  accessForAction: null,
  timeZone: null,
};
