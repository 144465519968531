import { Avatar, AvatarGroup, Tooltip, useMediaQuery } from '@mui/material';
import moment from 'moment-timezone';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import WeekPicker from '../../../../components/common/dateAndTimePicker/WeekPicker';
import IconByName from '../../../../components/common/iconByName/IconByName';
import Loader from '../../../../components/common/loader/Loader';
import { displayHoursAndMinutesFromHours, displayTime } from '../../../../utils/timeHelper';
import {
  acceptShiftOfferInvitationAction,
  addRequestForOpenShiftAction,
  getDashboardShiftsAction,
} from '../../redux/dashboardActions';
import {
  resetDashboardCalender,
  updateCalenderData,
  updateOfferShiftDrawerDetails,
  updateSwapShiftDrawerDetails,
  updateSwapShiftRequestsDetails,
} from '../../redux/dashboardSlice';
import unscheduled from '../../../../assets/images/Unscheduled.svg';
import onLeave from '../../../../assets/images/On Leave.svg';
import EzyButton from '../../../../components/common/button/EzyButton';
import EzyMenu from '../../../../components/common/menu/EzyMenu';
import SwapShiftDrawer from './SwapShiftDrawer';
import OfferShiftDrawer from './OfferShiftDrawer';
import useUrlParamsUpdate from '../../../../hooks/useURLParamsUpdate';
import SwapShiftRequestsDrawer from './SwapShiftRequestsDrawer';
import LeaveRequestDrawer from '../../../people/components/leaveDetails/LeaveRequestDrawer';
import ShiftDetailsDrawer from './ShiftDetailsDrawer';
import { mapIdsFromList } from '../../../../utils/enumMapping';
import SwapShiftRequestsDetailsDrawer from './SwapShiftRequestsDetailsDrawer';
import { updateScheduleSidePanelData } from '../../../schedule/redux/scheduleSlice';
import ROUTE_CONSTANTS from '../../../../utils/constants';
import useQueryParams from '../../../../hooks/useQueryParams';
import AccessWrapper from '../../../../components/common/accessWrapper/AccessWrapper';

function DashboardCalender() {
  const dispatch = useDispatch();
  const isTabletScreen = useMediaQuery('(max-width: 1024px)');
  const history = useHistory();
  const { personId, businessId, primaryLocationId, weekStartsOn, timeZone } = useSelector(
    ({ user }) => user?.profileData ?? {}
  );
  const { enums } = useSelector(({ common }) => common ?? {});
  const { weekDatesValue, calenderShiftDetails } = useSelector(
    ({ dashboard }) => dashboard?.calenderData ?? {}
  );

  const { date } = useQueryParams();

  const userTimeZone = timeZone?.label ?? moment.tz.guess();

  const { weeklyTotal, dashboardShiftResponseEntitiesList: shiftList } = useMemo(
    () => calenderShiftDetails,
    [calenderShiftDetails]
  );

  const { getDashboardShiftsActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );
  moment.updateLocale('en', {
    week: {
      dow: weekStartsOn === 7 ? 0 : weekStartsOn,
    },
  });

  useEffect(() => {
    if (weekStartsOn) {
      const value = {
        startDate: moment.tz(new Date(), userTimeZone).startOf('week'),
        endDate: moment.tz(new Date(), userTimeZone).endOf('week'),
      };
      dispatch(updateCalenderData({ fieldName: 'weekDatesValue', value }));
    }
    return () => {
      dispatch(resetDashboardCalender());
    };
  }, [weekStartsOn]);

  const getDashBoardDataByFilter = (params) => {
    dispatch(
      getDashboardShiftsAction({
        personDetailId: personId,
        businessId,
        filterStartDate: moment.tz(weekDatesValue?.startDate, userTimeZone).toISOString(),
        filterEndDate: moment.tz(weekDatesValue?.endDate, userTimeZone).toISOString(),
        ...params,
      })
    );
  };

  const onShiftClick = async (shift) => {
    await dispatch(updateCalenderData({ fieldName: 'displayShiftDrawer', value: shift }));
  };

  const onClickCardButton = (e, label, shift) => {
    e?.stopPropagation();
    if (label === 'Claim' || label === 'Request') {
      const finalData = {
        openShiftId: shift?.shiftId,
        personsId: personId,
      };

      dispatch(addRequestForOpenShiftAction(finalData, () => getDashBoardDataByFilter()));
    } else if (label === 'Accept') {
      const finalData = {
        shiftId: shift?.shiftId,
        offerAcceptorPersonDetailId: personId,
      };

      dispatch(acceptShiftOfferInvitationAction(finalData, () => getDashBoardDataByFilter()));
    } else if (label === 'Swap Requested' || label === 'Swap Offers' || label === 'Swap Received') {
      dispatch(updateSwapShiftRequestsDetails({ fieldName: 'currentShift', value: shift }));
    } else if (label === 'Offer Pending') {
      dispatch(
        updateOfferShiftDrawerDetails({
          fieldName: 'currentShift',
          value: shift,
        })
      );
    } else if (label === 'Pending Approval' && shift?.title === 'Open') {
      onShiftClick(shift);
    } else if (label === 'Pending Approval' && shift?.title === 'Scheduled') {
      dispatch(updateCalenderData({ fieldName: 'isPendingApprovalDrawer', value: shift?.shiftId }));
    }
  };

  useUrlParamsUpdate({
    date: moment.tz(weekDatesValue?.startDate ?? new Date(), userTimeZone).toISOString(),
  });

  useEffect(() => {
    const filterDate = date ?? weekDatesValue?.startDate ?? new Date();
    const dateParam = {
      startDate: moment.tz(filterDate, userTimeZone).startOf('week'),
      endDate: moment.tz(filterDate, userTimeZone).endOf('week'),
    };
    // const dateParam = weekRangeFromDate(date || weekDatesValue?.startDate);

    (async () => {
      await getDashBoardDataByFilter({
        filterStartDate: moment.tz(dateParam?.startDate, userTimeZone)?.toISOString(),
        filterEndDate: moment.tz(dateParam?.endDate, userTimeZone)?.toISOString(),
      });

      dispatch(updateCalenderData({ fieldName: 'weekDatesValue', value: dateParam }));
    })();
  }, []);

  return (
    <div className="calender-panel-wrapper">
      <div className="calender-panel-header">
        <div>Weekly Total : {displayHoursAndMinutesFromHours(weeklyTotal / 60)}</div>

        <WeekPicker
          variant="outlined"
          value={weekDatesValue}
          onChange={async (e) => {
            dispatch(updateCalenderData({ fieldName: 'weekDatesValue', value: e }));
            await getDashBoardDataByFilter({
              filterStartDate: moment.tz(e?.startDate, userTimeZone).toISOString(),
              filterEndDate: moment
                .tz(e?.endDate, userTimeZone)
                .subtract(1, 'second')
                .toISOString(),
            });
          }}
          startWeekFrom={weekStartsOn === 7 ? 0 : weekStartsOn}
          timeZone={userTimeZone}
        />
      </div>
      {getDashboardShiftsActionLoader ? (
        <Loader />
      ) : (
        <div className="calender-grid">
          <div className="calender-header-field">
            {shiftList
              ?.filter((_, index) => index <= 6)
              ?.map((dateWiseData) => {
                return (
                  <div
                    className={` ${
                      moment(new Date()).isSame(dateWiseData?.filterDates, 'day')
                        ? 'current-date'
                        : ''
                    } `}
                  >
                    {moment.tz(dateWiseData?.filterDates, userTimeZone).format('ddd, Do MMM')}
                  </div>
                );
              })}
          </div>

          <div className="cards-field">
            {shiftList
              ?.filter((_, index) => index <= 6)
              ?.map((dateWiseData) => {
                const leaveDetails = mapIdsFromList(dateWiseData?.requestLeave, enums?.leaveStatus);

                return (
                  <div>
                    {dateWiseData?.personSpecificShift?.length > 0 ? (
                      dateWiseData?.personSpecificShift?.map((shift) => {
                        const totalBreakDuration = shift?.shiftBreakDetailsList?.length
                          ? shift?.shiftBreakDetailsList
                              ?.map((prev) => prev.duration)
                              ?.reduce((prev, next) => prev + next)
                          : 0;

                        const menuItem = [];
                        if (shift?.canSwapShift) {
                          menuItem.push({
                            id: 1,
                            label: 'Swap shift',
                            onClick: (e) => {
                              e?.stopPropagation();
                              dispatch(
                                updateSwapShiftDrawerDetails({
                                  fieldName: 'currentShift',
                                  value: shift,
                                })
                              );
                            },
                          });
                        }
                        if (shift?.canOfferShift) {
                          menuItem.push({
                            id: 2,
                            label: 'Offer shift',
                            disabled: shift?.actionButton === 'Swap Received',
                            onClick: (e) => {
                              e?.stopPropagation();
                              dispatch(
                                updateOfferShiftDrawerDetails({
                                  fieldName: 'currentShift',
                                  value: shift,
                                })
                              );
                            },
                          });
                        }

                        return (
                          <div
                            className="schedule-card"
                            key={shift?.shiftId}
                            onClick={() => onShiftClick(shift)}
                          >
                            <div className={`schedule-card-title ${shift?.title}`}>
                              <span>{shift?.title}</span>
                              {shift?.title === 'Scheduled' &&
                                shift?.actionButton !== 'Swap Requested' &&
                                shift?.actionButton !== 'Swap Offers' &&
                                shift?.actionButton !== 'Pending Approval' &&
                                (shift?.canSwapShift || shift?.canOfferShift) && (
                                  <EzyMenu iconName="more_vert" menuItems={menuItem} />
                                )}
                            </div>

                            <div className="schedule-card-details">
                              <span className="detail-field">
                                <IconByName name="access_time_filled" />
                                <span
                                  className="detail"
                                  title={`${displayTime(
                                    shift?.startTime,
                                    shift?.timeZoneId?.label
                                  )} - ${displayTime(shift?.endTime, shift?.timeZoneId?.label)}`}
                                >
                                  {displayTime(shift?.startTime, shift?.timeZoneId?.label)} -{' '}
                                  {displayTime(shift?.endTime, shift?.timeZoneId?.label)}
                                </span>
                              </span>
                              <span className="detail-field">
                                <IconByName name="emoji_food_beverage" />
                                <span> {totalBreakDuration} mins</span>
                              </span>
                              <span className="detail-field">
                                <IconByName name="location_on" />
                                <span
                                  className="detail"
                                  title={` ${shift?.areaName} - ${shift?.locationName}`}
                                >
                                  {shift?.areaName} - {shift?.locationName}
                                </span>
                              </span>

                              {shift?.actionButton && (
                                <AccessWrapper access="APPROVEDASHCAL">
                                  <div className="card-action">
                                    <hr />

                                    {shift?.actionButton !== null && (
                                      <EzyButton
                                        label={shift?.actionButton}
                                        className="no-pad-button"
                                        onClick={(e) =>
                                          onClickCardButton(e, shift?.actionButton, shift)
                                        }
                                        color={
                                          shift?.actionButton === 'Claim' ||
                                          shift?.actionButton === 'Request' ||
                                          shift?.actionButton === 'Accept'
                                            ? 'primary'
                                            : 'secondary'
                                        }
                                      />
                                    )}
                                  </div>
                                </AccessWrapper>
                              )}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="schedule-card">
                        <div className="schedule-card-title unscheduled">Unscheduled</div>
                        <img src={unscheduled} alt="Unscheduled shift" />
                      </div>
                    )}

                    {dateWiseData?.requestLeave !== null && (
                      <div className="schedule-card">
                        <div className={`schedule-card-title ${leaveDetails?.label}`}>
                          Leave - {leaveDetails?.label}
                        </div>
                        <img src={onLeave} alt="On Leave" />
                      </div>
                    )}

                    {dateWiseData?.personSpecificTimeSheet?.length > 0 &&
                      dateWiseData?.personSpecificTimeSheet?.map((data) => {
                        const totalTimeSheetBreakDuration = data?.timeSheetBreakDetailEntities
                          ?.length
                          ? data?.timeSheetBreakDetailEntities
                              ?.map((prev) => prev.duration)
                              .reduce((prev, next) => prev + next)
                          : 0;

                        return (
                          <div
                            className="schedule-card"
                            key={data?.id}
                            onClick={() => onShiftClick(data)}
                          >
                            <div className={`schedule-card-title ${data?.title}`}>
                              {data?.title}
                            </div>
                            <div className="schedule-card-details">
                              <span className="detail-field">
                                <IconByName name="access_time_filled" />
                                <span
                                  className="detail"
                                  title={`${displayTime(
                                    data?.startTime,
                                    data?.timeZoneId?.label
                                  )} - ${displayTime(data?.endTime, data?.timeZoneId?.label)}`}
                                >
                                  {displayTime(data?.startTime, data?.timeZoneId?.label)} -{' '}
                                  {displayTime(data?.endTime, data?.timeZoneId?.label)}
                                </span>
                              </span>
                              <span className="detail-field">
                                <IconByName name="emoji_food_beverage" />
                                <span> {totalTimeSheetBreakDuration} mins</span>
                              </span>
                              <span className="detail-field">
                                <IconByName name="location_on" />
                                <span
                                  className="detail"
                                  title={` ${data?.areaName} - ${data?.locationName}`}
                                >
                                  {data?.areaName} - {data?.locationName}
                                </span>
                              </span>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                );
              })}
          </div>

          <div className="other-schedule-title">Scheduled this day</div>

          <div className="other-schedule-field">
            {shiftList
              ?.filter((_, index) => index <= 6)
              ?.map((data) => {
                return data?.anotherPersonScheduledShift?.length > 0 ? (
                  <div>
                    <AvatarGroup
                      total={data?.anotherPersonScheduledShift?.length}
                      onClick={() => {
                        dispatch(
                          updateScheduleSidePanelData({
                            fieldName: 'weekDatesValue',
                            value: {
                              startDate: moment.tz(weekDatesValue?.startDate, userTimeZone),
                              endDate: moment
                                .tz(weekDatesValue?.endDate, userTimeZone)
                                .subtract(1, 'second'),
                            },
                          })
                        );
                        history.push(
                          `${ROUTE_CONSTANTS.SCHEDULE}?location=${primaryLocationId}&date=${moment
                            .tz(weekDatesValue?.startDate, userTimeZone)
                            .toISOString()}`
                        );
                      }}
                      max={isTabletScreen ? 2 : 4}
                    >
                      {data?.anotherPersonScheduledShift?.map((anotherPersonList) => {
                        return anotherPersonList?.profilePhotoURL ? (
                          <Tooltip title={anotherPersonList?.preferredName}>
                            <Avatar src={anotherPersonList?.profilePhotoURL} />
                          </Tooltip>
                        ) : (
                          <Tooltip title={anotherPersonList?.preferredName}>
                            <Avatar>{anotherPersonList?.preferredName?.[0]}</Avatar>
                          </Tooltip>
                        );
                      })}
                    </AvatarGroup>
                  </div>
                ) : (
                  <div>{}</div>
                );
              })}
          </div>
        </div>
      )}
      <SwapShiftDrawer />
      <OfferShiftDrawer />
      <SwapShiftRequestsDrawer />
      <LeaveRequestDrawer isFromProfile listCallback={getDashBoardDataByFilter} />
      <ShiftDetailsDrawer />
      <SwapShiftRequestsDetailsDrawer />
    </div>
  );
}

export default DashboardCalender;
