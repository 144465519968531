import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Chip, InputAdornment } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isEmpty, isNull } from 'lodash';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import EzyIconButton from '../../../components/common/iconButton/EzyIconButton';
import { errorNotification } from '../../../utils/notifyHelper';
import {
  addLogAction,
  addLogCommentAction,
  deleteLogAction,
  deleteLogBookCategoryAction,
  deleteLogCommentAction,
  deleteUploadedAttachmentAction,
  getLogbookEntriesAction,
  uploadAttachmentAction,
} from '../redux/logbookActions';

import { updateAddCategoryDrawerDetails, updateLogsEntryDetails } from '../redux/logbookSlice';
import EzyMenu from '../../../components/common/menu/EzyMenu';
import ConfirmationModal from '../../../components/common/modal/ConfirmationModal';
import LogEntry from './LogEntry';
import { REGEX } from '../../../utils/validationHelper';

function LogCategory({ data }) {
  const hiddenFileInput = useRef(null);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const { filters, logsList } = useSelector(({ logbook }) => logbook ?? {});
  const [deleteModalData, setDeleteModalData] = useState(null);

  // const [comment, setComment] = useState();

  const [seeMoreEntries, setSeeMoreEntries] = useState(false);

  const [entryData, setEntryData] = useState({
    logData: '',
    fileData: {},
  });

  // const { uploadAttachmentActionLoader, addLogActionLoader } = useSelector(
  //   ({ common }) => common.generalLoader ?? {}
  // );

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleInputClick = () => {
    inputRef.current.focus();
  };

  const handleChange = useCallback(async (e) => {
    e.preventDefault();

    if (e.target.files && e.target.files.length > 0) {
      const checkFileSize = e.target.files[0].size > 4194304;

      if (checkFileSize) {
        errorNotification('File size should be less than 4 mb');
      } else {
        const formData = new FormData();
        formData.append('formFile', e.target.files[0]);

        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };

        e.target.value = null;

        try {
          await dispatch(
            uploadAttachmentAction(formData, config, (fileData) => {
              setEntryData((prev) => {
                return {
                  ...prev,
                  fileData,
                };
              });
            })
          );
        } catch (error) {
          /**/
        }
      }
    }
  }, []);

  const updateLogsEntry = (id, value) => {
    dispatch(updateLogsEntryDetails({ id, value }));
  };

  const handleAddLog = () => {
    const addLogData = {
      categoryId: data?.logBookCategoryId,
      locationId: filters?.selectedLocation?.id,
      logData: entryData?.logData,
      filePathUrl: entryData?.fileData?.fileUrl,
      fileName: entryData?.fileData?.fileName,
      date: moment(filters?.date).toISOString(),
    };

    dispatch(
      addLogAction(addLogData, (newEntry) => {
        updateLogsEntry(data?.logBookCategoryId, {
          logBookEntries: [...data.logBookEntries, newEntry?.data],
        });

        setEntryData({
          logData: '',
          fileData: {},
        });
      })
    );
  };

  const handleDeleteLog = (id) => {
    dispatch(
      deleteLogAction({ logBookEntryId: id }, () => {
        updateLogsEntry(data?.logBookCategoryId, {
          logBookEntries: data?.logBookEntries.filter((e) => e?.logBookEntryId !== id),
        });
      })
    );
  };

  const logCategoryActions = [
    {
      id: 1,
      label: 'Edit',
      onClick: () => {
        dispatch(
          updateAddCategoryDrawerDetails({
            fieldName: 'isEditCategory',
            value: data?.logBookCategoryId,
          })
        );
      },
    },
    {
      id: 2,
      label: 'Delete',
      onClick: (e) => {
        setDeleteModalData(e);
      },
    },
  ];

  const handleAddLogComment = (id, comment, setComment) => {
    dispatch(
      addLogCommentAction({ logBookEntryId: id, comment }, (cmt) => {
        setComment('');
        updateLogsEntry(data?.logBookCategoryId, {
          logBookEntries: data?.logBookEntries.map((e) => {
            if (e?.logBookEntryId === id) {
              return { ...e, logBookComments: [...e.logBookComments, cmt?.data] };
            }
            return e;
          }),
        });
      })
    );
  };

  const handleDeleteComment = (entryId, id) => {
    dispatch(
      deleteLogCommentAction({ commentId: id }, () => {
        updateLogsEntry(data?.logBookCategoryId, {
          logBookEntries: data?.logBookEntries.map((e) => {
            if (e?.logBookEntryId === entryId) {
              return {
                ...e,
                logBookComments: e?.logBookComments?.filter((cmt) => cmt?.commentId !== id),
              };
            }
            return e;
          }),
        });
      })
    );
  };

  const placeholder = () => {
    switch (data?.categoryType?.id) {
      case 2:
        return 'Enter number';

      case 3:
        return 'Enter amount';

      case 4:
        return 'Enter percentage';

      default:
        return 'Enter text';
    }
  };

  return (
    <div
      className={`log-wrapper ${
        logsList?.requiredLogCategory?.includes(data?.logBookCategoryId) && 'required-log'
      }`}
      key={data?.logBookCategoryId}
    >
      <div className="title-text">
        <span>
          {data?.categoryName}{' '}
          {logsList?.requiredLogCategory?.includes(data?.logBookCategoryId) && (
            <span className="body-text text-secondary-color">( required )</span>
          )}
        </span>
        <EzyMenu iconName="more_vert" isOnlyIcon menuItems={logCategoryActions} access="EDITLGB" />
      </div>

      {data?.logBookEntries?.length > 0 &&
        data?.logBookEntries.map(
          (entry, index) =>
            (!seeMoreEntries ? index < 3 : seeMoreEntries) && (
              <LogEntry
                data={entry}
                logCategory={data?.categoryType?.id}
                handleDeleteLog={(id) => handleDeleteLog(id)}
                handleAddLogComment={(id, comment, setComment) =>
                  handleAddLogComment(id, comment, setComment)
                }
                handleDeleteComment={(entryId, id) => handleDeleteComment(entryId, id)}
              />
            )
        )}

      {data?.logBookEntries?.length > 3 && !seeMoreEntries && (
        <div className="see-more-text p-10" onClick={() => setSeeMoreEntries(true)}>
          View all entries
        </div>
      )}

      {data?.logBookEntries?.length > 3 && seeMoreEntries && (
        <div className="see-more-text p-10" onClick={() => setSeeMoreEntries(false)}>
          Hide entries
        </div>
      )}

      <div className="input-wrapper">
        <div className="custom-input-field" onClick={() => handleInputClick()}>
          <EzyInputField
            innerRef={inputRef}
            placeholder={placeholder()}
            value={entryData?.logData}
            onChange={(e) => {
              if (
                [2, 3, 4].includes(data?.categoryType?.id) &&
                REGEX?.DECIMAL_REGEX.test(e.target.value)
              ) {
                setEntryData((prev) => {
                  return { ...prev, logData: e?.target?.value };
                });
              } else if (data?.categoryType?.id === 1) {
                setEntryData((prev) => {
                  return { ...prev, logData: e?.target?.value };
                });
              }
            }}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                await handleAddLog();
              }
            }}
            endAdornment={
              data?.categoryType?.id === 4 && (
                <InputAdornment position="end">
                  <span>%</span>
                </InputAdornment>
              )
            }
            startAdornment={
              data?.categoryType?.id === 3 && (
                <InputAdornment position="start">
                  <span>$</span>
                </InputAdornment>
              )
            }
          />
          <div className="actions">
            <div>
              {entryData?.fileData?.fileName && (
                <Chip
                  label={entryData?.fileData?.fileName}
                  variant="outlined"
                  onDelete={() => {
                    dispatch(
                      deleteUploadedAttachmentAction(
                        { filePath: entryData?.fileData?.fileUrl },
                        () => {
                          setEntryData((prev) => {
                            return { ...prev, fileData: {} };
                          });
                        }
                      )
                    );
                  }}
                />
              )}
            </div>
            <div className="d-flex gap-5">
              <EzyIconButton iconName="attach_file" onClick={handleClick} />
              <input
                type="file"
                className="input-file-type"
                ref={hiddenFileInput}
                onChange={handleChange}
              />

              <EzyIconButton
                iconName="send"
                onClick={handleAddLog}
                disabled={!entryData?.logData}
              />
            </div>
          </div>
        </div>
      </div>

      <ConfirmationModal
        title="Delete Log Category"
        isOpen={!isEmpty(deleteModalData) || !isNull(deleteModalData)}
        handleClose={() => {
          setDeleteModalData(null);
        }}
        handleSubmit={async () => {
          await dispatch(
            deleteLogBookCategoryAction({ logBookCategoryId: data?.logBookCategoryId }, () => {
              dispatch(
                getLogbookEntriesAction({
                  locationId: filters?.selectedLocation?.id,
                  date: moment(filters?.date)?.toISOString(),
                })
              );
            })
          );
          setDeleteModalData(null);
        }}
        primaryLabel="Delete"
      >
        <div>Are you sure you want to delete?</div>
      </ConfirmationModal>
    </div>
  );
}

LogCategory.propTypes = {
  data: PropTypes.object.isRequired,
};

export default LogCategory;
