import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EzyAccordionDetails from '../../../../components/common/accordion/EzyAccordinDetails';
import EzyAccordion from '../../../../components/common/accordion/EzyAccordion';
import EzyAccordionSummary from '../../../../components/common/accordion/EzyAccordionSummary';
import EzyAccordionWrapper from '../../../../components/common/accordion/EzyAccordionWrapper';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import IconByName from '../../../../components/common/iconByName/IconByName';
import { mapIdsFromList } from '../../../../utils/enumMapping';
import { displayDate, displayTime } from '../../../../utils/timeHelper';
import { getSwapShiftDetailsAction } from '../../../schedule/redux/scheduleActions';
import { updateCalenderData } from '../../redux/dashboardSlice';

function SwapShiftRequestsDetailsDrawer() {
  const dispatch = useDispatch();
  const { isPendingApprovalDrawer } = useSelector(({ dashboard }) => dashboard?.calenderData ?? {});

  const { enums } = useSelector(({ common }) => common ?? {});

  const { getSwapShiftDetailsActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const { swapDetails } = useSelector(({ schedule }) => schedule?.addEditShift ?? {});
  const { receiverShiftDetails, requestorShiftDetails } = useMemo(
    () => swapDetails ?? {},
    [swapDetails]
  );

  const shifts = [
    {
      heading: 'Shift details',
      shift: requestorShiftDetails?.currentPersonShiftDetails
        ? requestorShiftDetails
        : receiverShiftDetails,
    },
    {
      heading: 'Swap offer received',
      shift: requestorShiftDetails?.otherPersonShiftDetails
        ? requestorShiftDetails
        : receiverShiftDetails,
    },
  ];

  const handleClose = () => {
    dispatch(updateCalenderData({ fieldName: 'isPendingApprovalDrawer', value: null }));
  };

  useEffect(() => {
    if (isPendingApprovalDrawer)
      dispatch(getSwapShiftDetailsAction({ shiftId: isPendingApprovalDrawer }));
  }, [isPendingApprovalDrawer]);

  return (
    <EzyDrawer
      heading="Pending Approval"
      onClose={handleClose}
      isOpen={isPendingApprovalDrawer}
      isLoading={getSwapShiftDetailsActionLoader}
    >
      <div className="shift-drawer">
        <EzyAccordionWrapper className="shift-details-accordion">
          {shifts?.map((data) => {
            return (
              <EzyAccordion key={data?.shift?.shiftId} expanded>
                <EzyAccordionSummary className="accordion-summary" expandIcon={null}>
                  {data?.heading}
                </EzyAccordionSummary>
                <EzyAccordionDetails>
                  <div className="shift-details-drawer-field">
                    <div className="d-flex">
                      <span className="flex-center gap-5">
                        <IconByName name="person" />
                        <span>{data?.shift?.preferredName || '-'}</span>
                      </span>
                    </div>

                    <div className="d-flex">
                      <span className="flex-center gap-5">
                        <IconByName name="location_on" />
                        <span>
                          {data?.shift?.areaName} - {data?.shift?.locationName}
                        </span>
                      </span>
                    </div>

                    <div className="d-flex">
                      <span className="flex-center gap-5">
                        <IconByName name="event" />
                        <span>{displayDate(data?.shift?.startTime)}</span>
                      </span>
                    </div>

                    <div className="d-flex flex-align-start just-bet">
                      <span className="flex-center gap-5">
                        <IconByName name="access_time_filled" />
                        <span>
                          {displayTime(data?.shift?.startTime)} -{' '}
                          {displayTime(data?.shift?.endTime)}
                        </span>
                      </span>

                      {data?.shift?.shiftBreakDetails?.length !== 0 &&
                        data?.shift?.shiftBreakDetails?.length !== undefined && (
                          <div className="d-flex flex-align-start">
                            <span className="d-flex gap-5">
                              <IconByName name="emoji_food_beverage" />

                              <div className="d-flex-column">
                                {data?.shift?.shiftBreakDetails?.map((details) => {
                                  const breakDetails = mapIdsFromList(
                                    details?.breakType,
                                    enums?.breakType
                                  );

                                  return (
                                    <span>
                                      {breakDetails?.label} :{' '}
                                      {details?.breakStartTime
                                        ? `${displayTime(details?.breakStartTime)} -
                                ${displayTime(details?.breakEndTime)}`
                                        : ''}
                                      {` (  ${details?.duration} mins  ) `}
                                    </span>
                                  );
                                })}
                              </div>
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                </EzyAccordionDetails>
              </EzyAccordion>
            );
          })}
        </EzyAccordionWrapper>
      </div>
    </EzyDrawer>
  );
}

export default SwapShiftRequestsDetailsDrawer;
