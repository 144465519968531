import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import EzyButton from '../../../components/common/button/EzyButton';
import IconByName from '../../../components/common/iconByName/IconByName';
import LogBookSidebar from '../components/LogBookSidebar';
import {
  clearLogsList,
  updateAddCategoryDrawerDetails,
  updateFilteredEntriesDetails,
  updateLogsList,
} from '../redux/logbookSlice';
import AddLogDrawer from '../components/AddLogDrawer';
import SendEmailSummaryDrawer from '../components/SendEmailSummaryDrawer';
import LogCategory from '../components/LogCategory';
import {
  addLogCommentAction,
  getLogBookCategoriesByBusinessIdAction,
  getLogbookEntriesAction,
  getLogsEntryByFiltersAction,
} from '../redux/logbookActions';
import Loader from '../../../components/common/loader/Loader';
import useReplaceState from '../../../hooks/useReplaceState';
import LogEntry from '../components/LogEntry';

function LogbookList() {
  const dispatch = useDispatch();
  const { filters, logsList, filteredEntries } = useSelector(({ logbook }) => logbook ?? {});

  const { docs, page, limit, hasMoreRecords } = useMemo(() => filteredEntries, [filteredEntries]);

  const replaceState = useReplaceState();

  const {
    getLogbookEntriesActionLoader,
    getLocationsByBusinessIdLoader,
    getLogsEntryByFiltersActionLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});

  const { categoryList } = useMemo(() => logsList, [logsList]);

  const handleAddLogComment = (id, comment, setComment, index) => {
    dispatch(
      addLogCommentAction({ logBookEntryId: id, comment }, (cmt) => {
        setComment('');
        dispatch(
          updateFilteredEntriesDetails({
            index,
            value: cmt?.data,
          })
        );
      })
    );
  };

  const handleScroll = useCallback(
    (e) => {
      if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight && hasMoreRecords) {
        dispatch(updateFilteredEntriesDetails({ fieldName: 'page', value: page + 1 }));
        const params = {
          locationId: filters?.location?.id,
          categoryId: filters?.categoryType?.id,
          userId: filters?.personName?.id,
          startDate: moment(filters?.fromDate)?.toISOString(),
          endDate: filters?.toDate ? moment(filters?.toDate).endOf('day').toISOString() : null,
          message: filters?.message,
          page: page + 1,
          limit,
        };

        dispatch(getLogsEntryByFiltersAction(params));
      }
    },
    [hasMoreRecords]
  );

  replaceState({ date: moment(filters?.date)?.toISOString() });

  useEffect(() => {
    if (filters?.selectedLocation?.id && filters?.viewType === 'View') {
      dispatch(
        getLogbookEntriesAction({
          locationId: filters?.selectedLocation?.id,
          date: moment(filters?.date)?.toISOString(),
        })
      );
    }
    if (filters?.viewType === 'Search') {
      dispatch(getLogBookCategoriesByBusinessIdAction());
    }
    return () => dispatch(clearLogsList());
  }, [filters?.selectedLocation?.id, filters?.date, filters?.viewType]);

  return (
    <div className="logbook-root-container">
      <LogBookSidebar />

      <div
        className={` ${
          filters?.viewType === 'Search' ? 'logs-search-list-wrapper' : 'logbook-list-wrapper'
        }`}
        onScroll={(e) => {
          if (filters?.viewType === 'Search') {
            handleScroll(e);
          }
        }}
      >
        {getLogbookEntriesActionLoader && <Loader />}

        {filters?.viewType === 'View' && (
          <div className="logbook-list-header">
            <EzyButton
              label="Send email summary"
              variant="outlined"
              onClick={() =>
                dispatch(updateLogsList({ fieldName: 'sendEmailSummaryDrawer', value: true }))
              }
            />
            <EzyButton
              label="Add"
              startIcon={<IconByName name="add" />}
              className="no-pad-button"
              onClick={() =>
                dispatch(updateAddCategoryDrawerDetails({ fieldName: 'isOpenDrawer', value: true }))
              }
              access="EDITLGB"
            />
          </div>
        )}

        {filters?.viewType === 'View' && (
          <div className="logs-grid-wrapper">
            {categoryList?.length > 0 ? (
              <>
                <div className="flex-div">
                  {categoryList?.map(
                    (logCategory, index) => index % 2 === 0 && <LogCategory data={logCategory} />
                  )}
                </div>

                <div className="flex-div">
                  {categoryList?.map(
                    (logCategory, index) => index % 2 !== 0 && <LogCategory data={logCategory} />
                  )}
                </div>
              </>
            ) : (
              !getLogbookEntriesActionLoader &&
              !getLocationsByBusinessIdLoader && (
                <div className="no-data-found no-data-found-container">No categories found</div>
              )
            )}
          </div>
        )}

        {filters?.viewType === 'Search' && (
          <div
            className={`${docs?.length === 0 || getLogsEntryByFiltersActionLoader ? 'h-full' : ''}`}
          >
            {getLogsEntryByFiltersActionLoader && <Loader />}
            {docs?.length > 0
              ? docs?.map((entry, index) => (
                  <>
                    <LogEntry
                      data={entry}
                      handleAddLogComment={(id, comment, setComment) =>
                        handleAddLogComment(id, comment, setComment, index)
                      }
                    />
                    {index + 1 < docs?.length && (
                      <div className="p-x-10">
                        <hr />
                      </div>
                    )}
                  </>
                ))
              : !getLogsEntryByFiltersActionLoader && (
                  <div className="no-data-found">No entries found</div>
                )}
          </div>
        )}
      </div>

      <AddLogDrawer />
      <SendEmailSummaryDrawer />
    </div>
  );
}

export default LogbookList;
