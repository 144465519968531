import {
  startGeneralLoader,
  stopGeneralLoader,
} from '../../../utils/backOfficeLists/commonDataSlice';
import { mapEnumToIds } from '../../../utils/enumMapping';
import { displayErrors } from '../../../utils/errorNotifyHelper';
import { errorNotification, successNotification } from '../../../utils/notifyHelper';
import { scheduleServices } from '../../schedule/scheduleServices';
import dashboardServices from '../dashboardServices';
import {
  setCalenderShiftDetails,
  setDashboardData,
  updateSwapShiftDrawerDetails,
  updateSwapShiftRequestsDetails,
} from './dashboardSlice';

export const getDashboardShiftsAction = (params) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(startGeneralLoader({ loaderFor: 'getDashboardShiftsActionLoader' }));

      const res = await dashboardServices.getDashboardShifts(params);
      if (res?.data?.success) {
        const data = mapEnumToIds(
          [{ fieldName: 'timeZoneId', listName: 'timeZonesList', isFromEnum: false }],
          res?.data?.data,
          state?.common
        );
        dispatch(setCalenderShiftDetails(data));
        // successNotification(res?.data?.message);
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getDashboardShiftsActionLoader' }));
    }
  };
};

export const getPossibleSwapShiftAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(
        startGeneralLoader({
          loaderFor: params?.searchText
            ? 'getPossibleSwapShiftWithSearchTextActionLoader'
            : 'getPossibleSwapShiftActionLoader',
        })
      );

      const res = await scheduleServices.getPossibleSwapShift(params);
      if (res?.data?.success) {
        dispatch(
          updateSwapShiftDrawerDetails({ fieldName: 'possibleShifts', value: res?.data?.data })
        );
        successNotification(res?.data?.message);
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(
        stopGeneralLoader({
          loaderFor: params?.searchText
            ? 'getPossibleSwapShiftWithSearchTextActionLoader'
            : 'getPossibleSwapShiftActionLoader',
        })
      );
    }
  };
};

export const sendSwapRequestAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'sendSwapRequestActionLoader' }));

      const res = await scheduleServices.sendSwapRequest(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'sendSwapRequestActionLoader' }));
    }
  };
};

export const addRequestForOpenShiftAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'addRequestForOpenShiftActionLoader' }));

      const res = await scheduleServices.addRequestForOpenShift(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'addRequestForOpenShiftActionLoader' }));
    }
  };
};

export const getAllSwapRequestsAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getAllSwapRequestsActionLoader' }));

      const res = await scheduleServices.getAllSwapRequests(params);
      if (res?.data?.success) {
        dispatch(
          updateSwapShiftRequestsDetails({ fieldName: 'swapRequests', value: res?.data?.data })
        );
        if (res?.data?.data?.swapOffersSent?.length > 0) {
          dispatch(
            updateSwapShiftRequestsDetails({
              fieldName: 'checkedSwapRequests',
              value: res?.data?.data?.swapOffersSent
                ?.filter((shift) => shift?.isSwapAlreadySent)
                ?.map((shift) => shift?.toShiftId),
            })
          );
        }
        if (
          res?.data?.data?.swapOffersSent?.filter((request) => request?.isSwapAlreadySent)?.length >
            0 &&
          res?.data?.data?.swapOffersReceived?.length > 0
        ) {
          dispatch(
            updateSwapShiftRequestsDetails({
              fieldName: 'swapRequestsDisplay',
              value: { isToggle: true, listType: 'Swap Shift Received' },
            })
          );
        } else if (
          res?.data?.data?.swapOffersSent?.filter((request) => request?.isSwapAlreadySent)?.length >
            0 &&
          res?.data?.data?.swapOffersReceived?.length === 0
        ) {
          dispatch(
            updateSwapShiftRequestsDetails({
              fieldName: 'swapRequestsDisplay',
              value: { isToggle: false, listType: 'Swap Shift Sent' },
            })
          );
        } else {
          dispatch(
            updateSwapShiftRequestsDetails({
              fieldName: 'swapRequestsDisplay',
              value: { isToggle: false, listType: 'Swap Shift Received' },
            })
          );
        }
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getAllSwapRequestsActionLoader' }));
    }
  };
};

export const getDashboardDetailsByBusinessIdAction = (params) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const finalParams = {
        businessId: state?.user?.profileData?.businessId,
        ...params,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getDashboardDetailsByBusinessIdActionLoader' }));

      const res = await dashboardServices.getDashboardDetailsByBusinessId(finalParams);

      const data = mapEnumToIds(
        [{ fieldName: 'timeZoneId', listName: 'timeZonesList', isFromEnum: false }],
        res?.data?.data,
        state?.common
      );
      if (res?.data?.success) {
        dispatch(setDashboardData(data));
      }
    } catch (e) {
      errorNotification(e?.res?.data?.message);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getDashboardDetailsByBusinessIdActionLoader' }));
    }
  };
};

export const swapRequestUserAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'swapRequestUserActionLoader' }));

      const res = await scheduleServices.swapRequestUserAction(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'swapRequestUserActionLoader' }));
    }
  };
};

export const withdrawRequestForOpenShiftAction = (params, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'withdrawRequestForOpenShiftActionLoader' }));

      const res = await scheduleServices.withdrawRequestForOpenShift(params);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'withdrawRequestForOpenShiftActionLoader' }));
    }
  };
};

export const acceptShiftOfferInvitationAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'acceptShiftOfferInvitationActionLoader' }));

      const res = await dashboardServices.acceptShiftOfferInvitation(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'acceptShiftOfferInvitationActionLoader' }));
    }
  };
};
