import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { displayDate } from '../../../../utils/timeHelper';
import Loader from '../../../../components/common/loader/Loader';
import EzyIconTooltip from '../../../../components/common/tooltip/EzyIconTooltip';

function TotalCell({ statisticsKey, metricKey, mainList }) {
  const [toggle, setToggle] = useState(false);
  switch (metricKey) {
    case 'budgetedValues':
      return mainList?.totalOfBudget;

    case 'scheduledValues':
      return (
        <div className="d-flex-column">
          <span>{mainList?.totalOfScheduled}</span>
          {mainList?.totalScheduledVarianceInValue?.value && (
            <span
              className={
                mainList?.totalScheduledVarianceInValue?.isPositive
                  ? 'primary-color'
                  : 'error-color'
              }
            >
              {mainList?.totalScheduledVarianceInValue?.isPositive ? '+' : '-'}
              {mainList?.totalScheduledVarianceInValue?.value}
            </span>
          )}
        </div>
      );

    case 'actualsValues':
      return (
        <div
          className="d-flex-column"
          onMouseEnter={(e) => {
            e?.stopPropagation();
            setToggle((prev) => !prev);
          }}
          onMouseLeave={(e) => {
            e?.stopPropagation();
            setToggle((prev) => !prev);
          }}
          key={statisticsKey}
        >
          <span>{mainList?.totalOfActuals}</span>
          {!toggle ? (
            <span
              className={
                mainList?.totalActualsVarianceInValue?.isPositive ? 'primary-color' : 'error-color'
              }
            >
              {mainList?.totalActualsVarianceInValue?.isPositive ? '+' : '-'}
              {mainList?.totalActualsVarianceInValue?.value}
            </span>
          ) : (
            <span
              className={
                mainList?.totalActualsVarianceInPercentage?.isPositive
                  ? 'primary-color'
                  : 'error-color'
              }
            >
              {' '}
              {mainList?.totalActualsVarianceInPercentage?.isPositive ? '+' : '-'}
              {mainList?.totalActualsVarianceInPercentage?.value}
            </span>
          )}
        </div>
      );

    case 'forecastValues':
      return mainList?.totalOfForecasted;

    case 'managerForecastValues':
      return (
        <div className="d-flex-column">
          <span>{mainList?.totalOfManagerForecasted}</span>
          <span
            className={
              mainList?.totalManagerForecastVarianceInValue?.isPositive
                ? 'primary-color'
                : 'error-color'
            }
          >
            {mainList?.totalManagerForecastVarianceInValue?.isPositive ? '+' : '-'}
            {mainList?.totalManagerForecastVarianceInValue?.value}
          </span>
        </div>
      );

    default:
      return null;
  }
}

TotalCell.propTypes = {
  mainList: PropTypes.object.isRequired,
  statisticsKey: PropTypes.string.isRequired,
  metricKey: PropTypes.string.isRequired,
};

function SummaryTable({ loading }) {
  const { summaryStatisticData, filters } = useSelector(({ insight }) => insight ?? {});

  const headers = summaryStatisticData?.hourSummary?.actualsValues?.map((x) => {
    if (filters?.viewType === 5) {
      return {
        label: displayDate(x?.startDate, 'UTC'),
        key: x?.startDate,
      };
    }
    return {
      label: `${moment.tz(x?.startDate, 'UTC').format('DD MMM')} - ${moment
        .tz(x?.endDate, 'UTC')
        .format('DD MMM')}`,
      key: x?.startDate,
    };
  });

  const statistics = [
    {
      label: 'Hour',
      key: 'hourSummary',
      subFields: [
        { label: 'Budgeted', key: 'budgetedValues' },
        { label: 'Scheduled', key: 'scheduledValues' },
        { label: 'Actual', key: 'actualsValues' },
      ],
    },
    {
      label: 'Wages',
      key: 'wagesSummary',
      subFields: [
        { label: 'Budgeted', key: 'budgetedValues' },
        { label: 'Scheduled', key: 'scheduledValues' },
        { label: 'Actual', key: 'actualsValues' },
      ],
    },
    {
      label: 'Sales',
      key: 'salesSummary',
      subFields: [
        { label: 'Forecast', key: 'forecastValues' },
        { label: 'M.Forecast', key: 'managerForecastValues' },
        { label: 'Actual', key: 'actualsValues' },
      ],
    },
    {
      label: (
        <div className="d-flex">
          <span>Labor</span>
          <EzyIconTooltip
            tooltip={
              <div className="d-flex-column gap-10">
                <div>Labor % shows the wage costs divided by your sales.</div>
                <div>Forecast shows budgeted wages vs. PayrollEzy forecast sales.</div>
                <div>
                  Scheduled shows scheduled wages vs. Manager forecast sales. If there is no manager
                  forecast, the PayrollEzy forecast sales will be used.
                </div>
                <div>Actual shows actual wages vs actual sales.</div>
              </div>
            }
          />
        </div>
      ),
      key: 'laborSummary',
      subFields: [
        { label: 'Forecast', key: 'forecastValues' },
        { label: 'Scheduled', key: 'scheduledValues' },
        { label: 'Actual', key: 'actualsValues' },
      ],
    },
    {
      label: (
        <div className="d-flex">
          <span>Sales per hour</span>
          <EzyIconTooltip
            tooltip={
              <div className="d-flex-column gap-10">
                <div>Sales per hour shows your sales divided by employee hours.</div>

                <div>Forecast shows PayrollEzy forecast sales vs budgeted hours.</div>

                <div>
                  Scheduled shows manager forecast sales vs scheduled hours. If there is no manager
                  forecast, the PayrollEzy forecast sales will be used.
                </div>

                <div>Actual shows actual sales vs actual hours.</div>
              </div>
            }
          />
        </div>
      ),
      key: 'salesPerHourSummary',
      subFields: [
        { label: 'Forecast', key: 'forecastValues' },
        { label: 'Scheduled', key: 'scheduledValues' },
        { label: 'Actual', key: 'actualsValues' },
      ],
    },
  ];

  const renderData = (headerKey, statisticsKey, metricKey) => {
    const value = summaryStatisticData?.[statisticsKey]?.[metricKey]?.find(
      (x) => x?.startDate === headerKey
    )?.value;

    if (metricKey === 'actualsValues') {
      const variance = summaryStatisticData?.[statisticsKey]?.varianceValues?.find(
        (x) => x?.startDate === headerKey
      );
      return (
        <div className="d-flex-column">
          <span>{value || ' '}</span>
          {variance?.value && (
            <span className={variance?.isPositive ? 'primary-color ' : 'error-color'}>
              {variance?.isPositive ? '+' : '-'}
              {variance?.value}
            </span>
          )}
        </div>
      );
    }
    return value;
  };

  return (
    <Paper className="table-paper summary-table">
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell size="small" className="empty-cell" />
              <TableCell size="small" className="empty-cell" />
              {headers?.map((x) => (
                <TableCell>{x?.label}</TableCell>
              ))}
              <TableCell>Total </TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <Loader />
          ) : (
            <TableBody>
              {statistics?.map((statisticsItem) => {
                if (!summaryStatisticData[statisticsItem.key]) {
                  return null;
                }
                return statisticsItem.subFields.map((subFieldItem, index) => {
                  return (
                    <TableRow className={index === 2 ? 'last-row' : ''}>
                      {index === 1 ? (
                        <TableCell>{statisticsItem.label}</TableCell>
                      ) : (
                        <TableCell className="empty-cell" />
                      )}
                      <TableCell>{subFieldItem.label}</TableCell>
                      {headers.map((headersItem) => {
                        return (
                          <TableCell>
                            {renderData(headersItem?.key, statisticsItem?.key, subFieldItem?.key)}
                          </TableCell>
                        );
                      })}
                      <TableCell key={`${statisticsItem?.key}-${index}`}>
                        <TotalCell
                          statisticsKey={statisticsItem?.key}
                          metricKey={subFieldItem?.key}
                          mainList={summaryStatisticData?.[statisticsItem?.key]}
                        />
                      </TableCell>
                    </TableRow>
                  );
                });
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Paper>
  );
}

SummaryTable.propTypes = {
  loading: PropTypes.bool,
};

SummaryTable.defaultProps = {
  loading: false,
};

export default SummaryTable;
