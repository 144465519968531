import { omitBy, isNil } from 'lodash';
import moment from 'moment';
import { requireValidate } from '../../../utils/validationHelper';

const validateExportMetric = (data) => {
  const { metricId, locationId, areaId, startDate, endDate, statisticData, showArea } = data ?? {};

  const error = {};

  error.metricId = requireValidate('Metric ', metricId);
  error.locationId = requireValidate('Location', locationId);
  if (showArea) error.areaId = requireValidate('Area', areaId);
  error.startDate = requireValidate('Start date', startDate);
  error.endDate = requireValidate('End date', endDate);
  if (startDate && endDate && !moment(endDate).isSameOrAfter(startDate)) {
    error.endDate = `End date can't be smaller then start date`;
  }
  error.statisticData = requireValidate('Statistic Data', statisticData);

  return omitBy(error, isNil);
};

export default validateExportMetric;
