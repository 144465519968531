import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import PropTypes from 'prop-types';
import EzyIconTooltip from '../tooltip/EzyIconTooltip';

function EzyToggleButtonGroup({
  list,
  handleChange,
  className,
  selected,
  label,
  color,
  tooltip,
  ...restOther
}) {
  return (
    <div className={className}>
      {label && (
        <div className="toggleButtonGroupLabel">
          {label}
          {tooltip && <EzyIconTooltip tooltip={tooltip} className="input-field-tooltip" />}
        </div>
      )}
      <ToggleButtonGroup
        value={selected ?? list[0]}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
        className="toggleButtonGroup"
        fullWidth
        {...restOther}
      >
        {list?.map((res, index) => (
          <ToggleButton
            className={`${color}ToggleButton`}
            key={`${index}-${res}`}
            value={res?.label ?? res}
            disabled={res?.disabled}
          >
            {restOther?.isIconLabel && <span className="material-icons">{res}</span>}
            {!restOther?.isIconLabel && !res?.subtitle ? (
              res
            ) : (
              <div
                className="d-flex-column w-full"
                key={res?.id}
                onClick={(e) => e?.preventDefault()}
              >
                <div>{res?.label}</div>
                <div className="subtitle body-text">{res?.subtitle}</div>
              </div>
            )}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
}

export default EzyToggleButtonGroup;
EzyToggleButtonGroup.propTypes = {
  list: PropTypes.array.isRequired,
  handleChange: PropTypes.func,
  selected: PropTypes.string.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};
EzyToggleButtonGroup.defaultProps = {
  className: '',
  label: '',
  color: 'secondary',
  handleChange: () => {},
  tooltip: null,
};
