import FullCalendar from '@fullcalendar/react';
// import { renderToString } from 'react-dom/server';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'; // a plugin!
import { Chip, ThemeProvider, Tooltip, useMediaQuery } from '@mui/material';
import listPlugin from '@fullcalendar/list';
import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import momentPlugin from '@fullcalendar/moment';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { displayHoursAndMinutesFromHours, displayTime } from '../../../utils/timeHelper';
import Loader from '../../../components/common/loader/Loader';
import ROUTE_CONSTANTS, {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DurationFilterMenu,
} from '../../../utils/constants';
import WeekPicker from '../../../components/common/dateAndTimePicker/WeekPicker';
import EzyButton from '../../../components/common/button/EzyButton';
import EzyIconButton from '../../../components/common/iconButton/EzyIconButton';
import {
  updateAddEditShiftBulkFields,
  updateAddEditShiftFields,
  updateCalendarFooterData,
  updateScheduleSidePanelData,
} from '../redux/scheduleSlice';
import {
  dragAndDropShiftAction,
  getLocationSettingsForShiftAction,
  getShiftDropdownEntitiesAction,
  updateInsightPanelDisplaySettingsAction,
} from '../redux/scheduleActions';
import ScheduleBulkActions from './ScheduleBulkActions';
import IconByName from '../../../components/common/iconByName/IconByName';
import useQueryParams from '../../../hooks/useQueryParams';
import AccessWrapper from '../../../components/common/accessWrapper/AccessWrapper';
import useAccess from '../../../hooks/useAccess';
import EzyDatePicker from '../../../components/common/dateAndTimePicker/EzyDatePicker';
import EzyMenu from '../../../components/common/menu/EzyMenu';
import EzySwitch from '../../../components/common/switch/EzySwitch';
import EditCoverageDataModal from './EditCoverageDataModal';
import InsightStatsPanel from './InsightStatsPanel';
import store from '../../../redux/store';
import muiTheme from '../../../utils/muiTheme';

const renderEventContent = (
  event,
  hasAccess,
  loggedInPersonId,
  selectedLocation,
  peopleView,
  duration
) => {
  const timeTitle = `${displayTime(
    event.event.start,
    event?.event?.extendedProps?.timeZoneId?.label,
    true
  )} - ${displayTime(event.event.end, event?.event?.extendedProps?.timeZoneId?.label, true)}`;
  const data = event?.event?.extendedProps;
  const isTimeOff = data?.isTimeOffLeave || data?.isUnAvailability;

  if (event?.event?.title === 'coverage') {
    const coverageTitle =
      event?.event?.extendedProps?.requiredStaff !== null
        ? `${event?.event?.extendedProps?.scheduledStaff} / ${event?.event?.extendedProps?.requiredStaff}`
        : `${event?.event?.extendedProps?.scheduledStaff}`;

    const title =
      event?.event?.extendedProps?.requiredStaff !== null
        ? `ScheduledStaff : ${event?.event?.extendedProps?.scheduledStaff} RequiredStaff: ${event?.event?.extendedProps?.requiredStaff}`
        : `ScheduledStaff : ${event?.event?.extendedProps?.scheduledStaff}`;

    return (
      <Tooltip title={title} placement="top">
        <div className="event-wrapper" key={Math.random()}>
          <div className="coverage-event-title">{coverageTitle}</div>
        </div>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={
        !hasAccess &&
        loggedInPersonId === data?.personDetailId && (
          <Link
            to={`${ROUTE_CONSTANTS.HOME}?date=${moment(data?.startTime)?.toISOString()}`}
            className="secondary-action-text"
          >
            View in dashboard
          </Link>
        )
      }
    >
      <div
        className={`event-wrapper ${
          duration?.dayCount > 7 && duration?.dayCount < 27 ? 'two-week-view' : ''
        } ${duration?.dayCount > 27 ? 'four-week-view' : ''}`}
        key={Math.random()}
      >
        <div className="event-time" title={data?.isAllDay ? 'All day' : timeTitle}>
          {data?.isAllDay ? 'All day' : timeTitle}
        </div>
        {(data?.preferredFullName ||
          data?.isSwapShiftRequest ||
          data?.isOpenShiftRequest ||
          isTimeOff) && (
          <div className="data-wrapper">
            {peopleView ? (
              <div className="event-title word-break" title={data?.areaName}>
                {data?.areaName}
              </div>
            ) : (
              <div
                className="event-title word-break"
                title={
                  isTimeOff
                    ? data?.personName
                    : `${data?.preferredFullName} ${data?.isArchived ? '(Archived)' : ''}`
                }
              >
                {isTimeOff
                  ? data?.personName
                  : `${data?.preferredFullName} ${data?.isArchived ? '(Archived)' : ''}`}
              </div>
            )}
            {(data?.isSwapShiftRequest || data?.isOpenShiftRequest) && (
              <div className="event-actions">
                {data?.isSwapShiftRequest && (
                  <IconByName
                    name="swap_horizontal_circle"
                    className="primary-color"
                    title="Swap shift request"
                  />
                )}
                {data?.isOpenShiftRequest && (
                  <IconByName name="group" className="secondary-color" title="Open shift request" />
                )}
              </div>
            )}
          </div>
        )}
        {isTimeOff && data?.isTimeOffLeave && (
          <div className={`leave-data-wrapper ${duration?.dayCount > 7 ? 'two-line' : ''}`}>
            <div className="event-note" title={data?.leaveType}>
              {data?.leaveType}
            </div>
            <div className="p-y-5" title={data?.leaveStatus?.label}>
              <Chip variant="outlined" className="Pending p-y-5" label={data?.leaveStatus?.label} />
            </div>
          </div>
        )}
        {data?.note && (
          <div className="event-note" title={data?.note}>
            {data?.note}
          </div>
        )}
        {data.isPublished && (
          <div className="primary-color material-icon-small publish-flag">
            <IconByName name="check_circle" title="Published" />
          </div>
        )}
        {data?.isArchived && (
          <div className="text-secondary-color material-icon-small archived-flag">
            <IconByName name="archive" title="Archived" />
          </div>
        )}
      </div>
    </Tooltip>
  );
};

const renderResourceContent = (resourceData) => {
  const { preferredFullName, payRate, isArchived, openShiftTotalHours, emptyShiftTotalHours } =
    resourceData?.resource?.extendedProps ?? {};
  if (!preferredFullName) {
    return (
      <div className="resource-wrapper">
        <div>{resourceData?.fieldValue}</div>
        <div className="resource-info-wrapper">
          <div className="resource-info">
            <span>Total hours:&nbsp;</span>
            <div>{displayHoursAndMinutesFromHours(openShiftTotalHours + emptyShiftTotalHours)}</div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="resource-wrapper">
      <div className="d-flex gap-5">
        {resourceData?.fieldValue} {isArchived && '(Archived)'}
        <AccessWrapper access="VIEWTMCT">
          <div className="resource-info">
            <span>({payRate?.basePay || '0'})</span>
          </div>
        </AccessWrapper>
      </div>
      <div className="d-flex flex-align-center gap-5">
        <AccessWrapper access="VIEWTMCT">
          <div className="resource-info">
            <span>Total cost:&nbsp;</span> <span>{payRate?.totalCost || 0}</span>
          </div>
          |
        </AccessWrapper>

        <div className="resource-info">
          <span>Total hours:&nbsp;</span>
          <span>{displayHoursAndMinutesFromHours(payRate?.totalHours)}</span>
        </div>
      </div>
    </div>
  );
};

const Scheduler = React.forwardRef(({ data, eventHandler, loader, reFetchEvents }, ref) => {
  const [currentView, setCurrentView] = useState('resourceTimelineWeek');
  const { selectedLocation, weekDatesValue, locationDropdownList, duration } = useSelector(
    ({ schedule }) => schedule?.sidePanelData ?? {}
  );
  const { getLocationAreaDropdownActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );
  const {
    businessId,
    roleId: loggedInRole,
    personId: loggedInPersonId,
  } = useSelector(({ user }) => user?.profileData ?? {});

  const selectedLocationData = useMemo(() => selectedLocation, [selectedLocation]);
  const isTabletScreen = useMediaQuery('(max-width : 1024px)');
  const [openCoverageModal, setOpenCoverageModal] = useState(null);

  const {
    emptyShifts = 0,
    unpublishedShifts = 0,
    publishedShifts = 0,
    requireConfirmation = 0,
    openShifts = 0,
    warning = 0,
    leaveApproved = 0,
    leavePending = 0,
    peopleUnavailable = 0,
  } = useMemo(() => data?.calendarFooterData ?? {}, [data]);

  const hasAccess = useAccess('EDITEMPSCHED');

  const calendarRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const onDatesSet = (e) => {
    eventHandler(e);
  };

  const eventIds = useMemo(
    () =>
      data.events
        .filter((e) => e?.resourceId !== 'time_off' && e?.title !== 'coverage')
        ?.map((e) => e.id),
    [data.events]
  );

  const onAddShift = async ({ start = new Date(), end = new Date(), resource, ...rest }) => {
    if (resource?.extendedProps?.groupId !== 'time_off') {
      dispatch(updateAddEditShiftFields({ fieldName: 'addShiftDrawer', value: currentView }));
      (async () => {
        await dispatch(
          getShiftDropdownEntitiesAction(
            {
              locationId:
                selectedLocation?.id !== 'all' && !selectedLocation?.locationId
                  ? selectedLocation?.id
                  : selectedLocation?.locationId,
              businessId: selectedLocation?.id === 'all' ? businessId : null,
            },
            resource?.extendedProps?.groupId, // areaId
            resource?.extendedProps?.personDetailId,
            (area) => {
              dispatch(
                getLocationSettingsForShiftAction({
                  start: moment(rest?.date)?.startOf('day')?.toISOString() || start,
                  end: moment(rest?.date)?.endOf('day')?.toISOString() || end,
                  areaId: area?.id,
                  personDetailId: resource?.extendedProps?.personDetailId,
                  currentView,
                  timeZone: area?.timeZoneId?.label,
                })
              );
            }
          )
        );
      })();
    }
  };

  const generateBlock = (e) => {
    const cellHoverElementsContainer = document.createElement('div');
    cellHoverElementsContainer.className = 'cell-hover-elements-container';

    let count = 0;

    if (currentView === 'resourceTimelineWeek') {
      count = duration?.dayCount;
    } else if (currentView === 'resourceTimelineMonth') {
      count = moment.tz(data?.startDate, selectedLocation?.timeZoneId?.label).daysInMonth();
    } else {
      count = 24 * 4;
    }

    for (let i = 0; i < count; i += 1) {
      const elem = document.createElement('div');
      elem.innerHTML = '+';
      // elem.onClick = onAddShift;
      cellHoverElementsContainer.appendChild(elem);
    }
    e?.appendChild(cellHoverElementsContainer);
  };

  const renderBlockWithIcon = () => {
    document.querySelectorAll('.fc-timeline-lane-frame')?.forEach((e) => {
      if (e?.parentElement?.getAttribute('data-resource-id') !== 'time_off') {
        if (e.querySelector('.cell-hover-elements-container')) {
          const node = e?.querySelector('.cell-hover-elements-container');
          e.removeChild(node);
          generateBlock(e);
        } else if (e) {
          generateBlock(e);
        }
      }
    });
  };

  const changeCalendarView = (view) => {
    setCurrentView(view);
    setTimeout(() => {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView(view);

      // calendarApi.render();
    });
  };

  const handleDateChange = (event) => {
    setTimeout(() => {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(new Date(event.startDate || event));
      // calendarApi.render();
    });
  };

  useImperativeHandle(ref, () => ({ handleDateChange }));

  const onPublishShift = () => {
    dispatch(updateAddEditShiftFields({ fieldName: 'publishShiftDrawer', value: true }));
    dispatch(
      updateAddEditShiftFields({
        fieldName: 'shiftsToPublish',
        value: eventIds,
      })
    );
  };

  const onShiftClick = (e) => {
    if (hasAccess) {
      if (
        e?.event?.extendedProps?.isSwapShiftRequest ||
        e?.event?.extendedProps?.isOpenShiftRequest
      ) {
        dispatch(
          updateAddEditShiftFields({
            fieldName: e?.event?.extendedProps?.isSwapShiftRequest
              ? 'isSwapShiftRequest'
              : 'isOpenShiftRequest',
            value: e?.event?.extendedProps?.shiftId,
          })
        );
      } else if (e?.event?.extendedProps?.isOfferedShift) {
        dispatch(
          updateAddEditShiftFields({
            fieldName: 'offerShiftDrawer',
            value: e?.event?.extendedProps?.shiftId,
          })
        );
      } else if (
        e?.event?.extendedProps?.isTimeOffLeave ||
        e?.event?.extendedProps?.isUnAvailability
      ) {
        if (e?.event?.extendedProps?.isTimeOffLeave) {
          history.replace(
            `${ROUTE_CONSTANTS.PEOPLE}/edit/${e?.event?.extendedProps?.personDetailId}`,
            {
              tab: 2,
              viewLeave: e?.event?.extendedProps?.leaveRequestId,
            }
          );
          // eslint-disable-next-line no-useless-return
        } else console.log(e?.event);
      } else if (e?.event?.title === 'coverage') {
        setOpenCoverageModal(e);
      } else {
        dispatch(updateAddEditShiftFields({ fieldName: 'editShiftDrawer', value: true }));
        dispatch(updateAddEditShiftBulkFields(e?.event?.extendedProps));
      }
    }
  };

  const handleDragAndDrop = async (event, access) => {
    if (
      event?.event?.extendedProps?.shiftType === 4 ||
      !access ||
      event?.event?.extendedProps?.isTimeOffLeave ||
      event?.event?.extendedProps?.isUnAvailability
    ) {
      event.revert();
      return;
    }
    const newResourceId = event?.newResource?.id;
    const { id, start: startTime, end: endTime } = event.event;
    const req = { shiftId: id, startTime, endTime };
    if (!data?.filterByPeople && newResourceId) {
      req.areaId = newResourceId;
    } else if (newResourceId) {
      req.personDetailId = newResourceId;
    }
    dispatch(
      dragAndDropShiftAction(req, (isSuccess) => {
        if (!isSuccess) {
          event.revert();
        } else {
          event.event.setExtendedProp('isPublished', false);
          dispatch(updateCalendarFooterData({ fieldName: 'unpublishedShifts', value: 1 }));
          reFetchEvents();
        }
      })
    );
  };

  const handleFilterChange = (event, fieldName, value) => {
    event?.stopPropagation();
    const insightSettingsData = {
      businessId,
      showCoverageInAreas: data?.calendarFooterData?.showCoverageInAreas,
      showStatsPanel: data?.calendarFooterData?.showStatsPanel,
    };
    insightSettingsData[fieldName] = value;
    dispatch(
      updateInsightPanelDisplaySettingsAction(insightSettingsData, () => {
        reFetchEvents();
      })
    );
  };

  const insightMenuItemList = [
    {
      id: 1,
      label: 'Insights',
      onClick: () => {
        history.push(`${ROUTE_CONSTANTS.SCHEDULE}/insights/Summary`);
      },
    },
    {
      id: 2,
      label: (
        <div className="d-flex gap-5">
          <span>Show coverage in areas</span>
          <EzySwitch
            onChange={(e) => handleFilterChange(e, 'showCoverageInAreas', e?.target?.checked)}
            checked={data?.calendarFooterData?.showCoverageInAreas}
          />
        </div>
      ),
    },
    {
      id: 3,
      label: (
        <div className="d-flex gap-5">
          <span>Show stats panel</span>
          <EzySwitch
            onChange={(e) => handleFilterChange(e, 'showStatsPanel', e?.target?.checked)}
            checked={data?.calendarFooterData?.showStatsPanel}
          />
        </div>
      ),
    },
  ];

  const handleNavigation = (direction, isToday) => {
    if (direction === 'left') {
      setTimeout(() => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.prev();
        // calendarApi.render();
      });
    }
    if (direction === 'right') {
      setTimeout(() => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.next();
        // calendarApi.render();
      });
    }
    if (isToday) {
      setTimeout(() => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.today();
        // calendarApi.render();
      });
    }
  };
  const { date: dateParam } = useQueryParams();

  const fullCalendarProps = useMemo(() => {
    const props = {};

    if (currentView === 'resourceTimelineWeek' && duration?.dayCount === 7) {
      props.slotLabelFormat = [
        {
          weekday: 'short',
          day: '2-digit',
          omitCommas: false,
        },
      ];
      props.slotLabelInterval = { day: 1 };
    } else if (
      currentView === 'resourceTimelineMonth' ||
      (currentView === 'resourceTimelineWeek' && duration?.dayCount > 7)
    ) {
      props.slotLabelFormat = [
        {
          weekday: 'short',
          day: '2-digit',
          omitCommas: false,
        },
      ];
      props.slotLabelInterval = { day: 1 };
    } else {
      props.slotDuration = '00:15:00'; // set slot duration to 30 minutes
      props.slotLabelInterval = { hour: 1 }; // set slot label interval to 15 minutes
      props.slotLabelFormat = [
        {
          hour: 'numeric',
          minute: '2-digit',
          meridiem: 'short',
          omitZeroMinute: true,
          omitCommas: true,
        },
      ]; // set slot label format to display start and end times
      props.slotMinWidth = 30;
    }

    if (data?.filterByPeople) {
      props.resourceAreaColumns = [
        {
          field: 'title',
          headerContent: 'People',
        },
      ];
      props.resourceAreaWidth = '20%';
      props.resourceLabelContent = renderResourceContent;
    } else {
      props.resourceAreaColumns = [
        {
          field: 'title',
          headerContent: 'Areas',
        },
      ];
      props.resourceAreaWidth = data?.calendarFooterData?.showStatsPanel ? '15%' : '10%';
      props.resourceGroupField = 'groupTitle';
    }

    if (data?.startDate) {
      props.initialDate = new Date(
        moment.tz(data?.startDate, selectedLocation?.timeZoneId?.label).startOf('week')
      );
    }
    // props.resourceLaneDidMount = ({ el }) => {};

    return props;
  }, [
    data,
    currentView,
    renderResourceContent,
    dateParam,
    data?.calendarFooterData?.showStatsPanel,
  ]);

  const isTodayDisabled = useMemo(() => {
    if (currentView === 'resourceTimeline') {
      return moment().isSame(moment(data.startDate), 'date');
    }
    return moment().isBetween(moment(data.startDate), moment(data.endDate), 'date');
  }, [currentView, data.startDate, data.endDate]);

  useEffect(() => {
    if (hasAccess) {
      setTimeout(() => renderBlockWithIcon(), [1000]);
    }
  }, [
    currentView,
    data?.filterByPeople,
    moment.tz(data?.startDate, selectedLocation?.timeZoneId?.label).daysInMonth(),
    hasAccess,
    duration,
    selectedLocation,
  ]);

  useEffect(() => {
    const node = document.querySelector('.insight-stats-panel');
    if (calendarRef?.current && node) {
      if (
        !data?.calendarFooterData?.showStatsPanel ||
        duration?.id > 4 ||
        [4, 5]?.includes(loggedInRole)
      ) {
        node.classList?.add('display-none');
      } else {
        node.classList?.remove('display-none');
        const root = createRoot(node);
        root.render(
          <Provider store={store}>
            <ThemeProvider theme={muiTheme}>
              <InsightStatsPanel />
            </ThemeProvider>
          </Provider>
        );
      }
    }
  }, [calendarRef, data?.calendarFooterData, duration?.id]);

  return (
    <>
      {loader && <Loader />}
      <div className="calendar-header">
        <div className="left-items">
          <EzyMenu
            className="schedule-location-menu"
            menuItems={locationDropdownList}
            label={selectedLocation?.label || 'Location'}
            iconName="location_on"
            onItemClick={async (_, value) => {
              dispatch(updateScheduleSidePanelData({ fieldName: 'selectedLocation', value }));

              moment.updateLocale('en', {
                week: {
                  dow: value?.weekStartsOn === 7 ? 0 : value?.weekStartsOn,
                },
              });

              const start = moment
                .tz(weekDatesValue?.startDate, selectedLocation?.timeZoneId?.label)
                .format(DATE_TIME_FORMAT);

              const end = moment
                .tz(weekDatesValue?.endDate, selectedLocation?.timeZoneId?.label)
                .format(DATE_TIME_FORMAT);

              const params = {
                startDate: moment
                  .tz(start, DATE_TIME_FORMAT, value?.timeZoneId?.label)
                  .startOf('week')
                  .toISOString(),
                endDate: moment
                  .tz(end, DATE_TIME_FORMAT, value?.timeZoneId?.label)
                  .endOf('week')
                  .toISOString(),
              };
              if (value?.id && value?.id !== 'all' && !value?.locationId) {
                params.locationId = value?.id;
                params.businessId = null;
                params.areaIds = null;
              } else if (value?.locationId) {
                params.areaIds = value?.id;
                params.locationId = value?.locationId;
                params.businessId = null;
              } else {
                params.businessId = businessId;
                params.locationId = null;
                params.areaIds = null;
              }

              handleDateChange({
                startDate: moment
                  .tz(start, DATE_TIME_FORMAT, value?.timeZoneId?.label)
                  .startOf('week'),
                endDate: moment.tz(end, DATE_TIME_FORMAT, value?.timeZoneId?.label).endOf('week'),
              });

              dispatch(
                updateScheduleSidePanelData({
                  fieldName: 'weekDatesValue',
                  value: {
                    startDate: moment
                      .tz(start, DATE_TIME_FORMAT, value?.timeZoneId?.label)
                      .startOf('week'),
                    endDate: moment
                      .tz(end, DATE_TIME_FORMAT, value?.timeZoneId?.label)
                      .endOf('week'),
                  },
                })
              );

              await reFetchEvents(params);
            }}
            selectedItem={selectedLocation}
            loading={getLocationAreaDropdownActionLoader}
          />

          {currentView === 'resourceTimeline' ? (
            <EzyDatePicker
              value={moment.tz(data?.startDate, selectedLocation?.timeZoneId?.label)}
              onChange={(e) => handleDateChange(e)}
              onPrevClick={() => handleNavigation('left', false)}
              onNextClick={() => handleNavigation('right', false)}
            />
          ) : (
            <WeekPicker
              value={{
                startDate: moment.tz(data?.startDate, selectedLocation?.timeZoneId?.label),
                endDate: moment.tz(data?.endDate, selectedLocation?.timeZoneId?.label),
              }}
              onChange={(e) => handleDateChange(e)}
              variant="outlined"
              onPrevClick={() => handleNavigation('left', false)}
              onNextClick={() => handleNavigation('right', false)}
              OffWeekPicker={currentView === 'resourceTimeline'}
              startWeekFrom={
                selectedLocationData?.weekStartsOn === 7 ? 0 : selectedLocationData?.weekStartsOn
              }
              timeZone={selectedLocation?.timeZoneId?.label}
            />
          )}

          {!isTabletScreen ? (
            <EzyButton
              label={currentView === 'resourceTimeline' ? 'Today' : 'This week'}
              className="low-padding"
              onClick={() => handleNavigation('', true)}
              variant="outlined"
              disabled={isTodayDisabled}
            />
          ) : (
            <EzyIconButton
              iconName={currentView === 'resourceTimeline' ? 'calendar_today' : 'date_range'}
              onClick={() => handleNavigation('', true)}
              variant="outlined"
              disabled={isTodayDisabled}
            />
          )}
          <ScheduleBulkActions reFetchEvents={reFetchEvents} eventIds={eventIds} />

          {![4, 5]?.includes(loggedInRole) && (
            <EzyMenu iconName="bar_chart" menuItems={insightMenuItemList} color="primary" />
          )}
        </div>
        <div className="right-items">
          <EzyMenu
            className="duration-menu"
            menuItems={DurationFilterMenu}
            label={
              (duration?.id &&
                (duration?.id < 6
                  ? `${duration?.label} by area`
                  : `${duration?.label} by team member`)) ||
              'Duration'
            }
            onItemClick={(_, item) => {
              dispatch(
                updateScheduleSidePanelData({
                  fieldName: 'duration',
                  value: item,
                })
              );
              if (item?.label === 'Day') {
                changeCalendarView('resourceTimeline');
              } else if (item?.label === 'Month') {
                changeCalendarView('resourceTimelineMonth');
              } else if (item?.label !== 'Day' && currentView !== 'resourceTimelineWeek') {
                changeCalendarView('resourceTimelineWeek');
              }

              dispatch(updateScheduleSidePanelData({ fieldName: 'areaIds', value: null }));
              dispatch(
                updateScheduleSidePanelData({
                  fieldName: 'filterByPeople',
                  value: item?.id > 5,
                })
              );
              // reFetchEvents();
            }}
            selectedItem={duration}
          />

          {!isTabletScreen ? (
            <EzyButton
              label="Publish shifts"
              className="no-pad-button"
              access="CREATEEMPSCHED"
              onClick={onPublishShift}
              color="secondary"
              disabled={eventIds?.length <= 0 || data?.calendarFooterData?.unpublishedShifts === 0}
            />
          ) : (
            <EzyIconButton
              iconName="launch"
              access="CREATEEMPSCHED"
              onClick={onPublishShift}
              color="secondary"
              disabled={eventIds?.length <= 0 || data?.calendarFooterData?.unpublishedShifts === 0}
            />
          )}
        </div>
      </div>
      <div className="calendar-wrapper">
        <FullCalendar
          ref={calendarRef}
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            resourceTimelinePlugin,
            listPlugin,
            // scrollGridPlugin,
            momentPlugin,
            momentTimezonePlugin,
          ]}
          timeZone={selectedLocation?.timeZoneId?.label}
          // initialDate={
          //   dateParam ? new Date(dateParam) : moment(new Date()).startOf('week').toISOString()
          // }
          editable={!!hasAccess}
          selectable={!!hasAccess}
          scrollTime="00:00"
          eventDrop={(e) => handleDragAndDrop(e, hasAccess)}
          eventResize={(e) => handleDragAndDrop(e, hasAccess)}
          headerToolbar={false}
          footerToolbar={false}
          initialView="resourceTimelineWeek"
          height="100%"
          firstDay={
            selectedLocationData?.weekStartsOn === 7 ? 0 : selectedLocationData?.weekStartsOn
          }
          titleFormat={DATE_FORMAT}
          eventClick={onShiftClick}
          // select={hasAccess ? onAddShift : undefined}
          dateClick={hasAccess ? onAddShift : undefined}
          longPressDelay={1}
          weekends
          schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            meridiem: 'short',
          }}
          stickyFooterScrollbar
          stickyHeaderDates
          slotMinWidth={70}
          slotDuration="01:00"
          events={data.events}
          eventContent={(e) =>
            renderEventContent(
              e,
              hasAccess,
              loggedInPersonId,
              selectedLocation,
              data?.filterByPeople,
              duration
            )
          }
          resources={data.resourceList || []}
          datesSet={onDatesSet}
          views={{
            resourceTimelineWeek: {
              type: 'resourceTimelineWeek',
              duration: { days: duration?.dayCount ?? 7 },
            },
          }}
          {...fullCalendarProps}
          viewDidMount={({ el }) => {
            const mainBody = el.querySelectorAll('tbody')[1];
            const node = document.createElement('tr');

            node.className = 'insight-stats-panel';

            mainBody.insertBefore(node, mainBody.querySelector('.fc-scrollgrid-section-body'));
          }}
        />
        <div className="sticky-footer">
          <div className="color-ball empty" />
          <div>{emptyShifts} Empty</div>
          <div className="color-ball unpublished" />
          <div>{unpublishedShifts} Unpublished</div>
          <div className="color-ball published" />
          <div>{publishedShifts} Published</div>
          <div className="color-ball require-confirmation" />
          <div>{requireConfirmation} Require confirmation</div>
          <div className="color-ball open" />
          <div>{openShifts} Open</div>
          <div className="color-ball warning" />
          <div>{warning} Warning</div>
          <div className="color-ball leave-approved" />
          <div>{leaveApproved + leavePending} Leave </div>
          {/* <div className="color-ball leave-pending" />
        <div>{leavePending} Leave pending</div> */}
          <div className="color-ball unavilable" />
          <div>{peopleUnavailable} People unavailable</div>
        </div>
      </div>
      <EditCoverageDataModal
        open={openCoverageModal}
        handleClose={() => {
          setOpenCoverageModal(null);
        }}
        reFetchEvents={reFetchEvents}
      />
    </>
  );
});

export default Scheduler;

Scheduler.propTypes = {
  data: PropTypes.object.isRequired,
  eventHandler: PropTypes.func.isRequired,
  loader: PropTypes.bool.isRequired,
  reFetchEvents: PropTypes.func,
};

Scheduler.defaultProps = {
  reFetchEvents: null,
};
