import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import EzyDrawer from '../../../components/common/drawer/Drawer';
import IconByName from '../../../components/common/iconByName/IconByName';
import { getShiftHistoryByShiftId } from '../redux/scheduleActions';
import { updateAddEditShiftFields } from '../redux/scheduleSlice';
import { displayDateTime, displayTime } from '../../../utils/timeHelper';

function ShiftHistoryDrawer() {
  const dispatch = useDispatch();
  const [openIndex, setOpenIndex] = useState(null);
  const shiftData = useSelector(({ schedule }) => schedule?.addEditShift ?? {});
  const { scheduleShiftHistoryDrawer, shiftId, shiftHistory } = useMemo(
    () => shiftData,
    [shiftData]
  );

  const { timeZoneId } = useSelector(
    ({ schedule }) => schedule?.sidePanelData?.selectedLocation ?? {}
  );

  const { getShiftHistoryLoader } = useSelector(({ common }) => common.generalLoader ?? {});
  const onClickDetail = (e) => {
    setOpenIndex((prev) => (prev === e ? null : e));
  };

  const handelFieldChange = (fieldName, value) => {
    dispatch(updateAddEditShiftFields({ fieldName, value }));
  };
  const handleClose = () => {
    handelFieldChange('scheduleShiftHistoryDrawer', undefined);
    handelFieldChange('shiftHistory', {});
    setOpenIndex(null);
  };

  const actionButton = [
    {
      label: 'Close',
      color: 'secondary',
      onClick: () => handleClose(),
    },
  ];
  useEffect(() => {
    if (scheduleShiftHistoryDrawer) {
      dispatch(getShiftHistoryByShiftId({ shiftId }));
    }
  }, [scheduleShiftHistoryDrawer]);

  return (
    <EzyDrawer
      onClose={handleClose}
      isOpen={scheduleShiftHistoryDrawer}
      heading="Shift History"
      actionButtons={actionButton}
      isLoading={getShiftHistoryLoader}
    >
      <div className="schedule-shift-history-drawer">
        <div className="schedule-shift-history-data-wrapper">
          <div className="data-wrapper">
            <div className="title">Created by &nbsp;</div>
            <div className="value">
              <span>{shiftHistory?.createdBy}</span>
              <span>{displayDateTime(shiftHistory?.createdDate, timeZoneId?.label)}</span>
            </div>
          </div>
        </div>
        <Timeline>
          {shiftHistory?.shiftHistoryData?.map((e, index) => (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className="schedule-timeline-info" onClick={() => onClickDetail(index)}>
                  <div>{`${displayDateTime(e?.date, timeZoneId?.label)} | ${e?.user}`}</div>
                  <IconByName name={openIndex === index ? 'visibility_off' : 'visibility'} />
                </div>
                <div
                  className={
                    openIndex === index ? 'schedule-shift-history-data-wrapper' : 'wrap-schedule'
                  }
                >
                  <div className="data-wrapper">
                    <div className="title">User &nbsp;</div>
                    <div className="value">{e?.user}</div>
                  </div>
                  <div className="data-wrapper">
                    <div className="title">Field &nbsp;</div>
                    <div className="value">{e?.field}</div>
                  </div>
                  <div className="data-wrapper">
                    <div className="title">Old value &nbsp;</div>
                    <div className="value">
                      {e?.oldValue && e?.oldValue?.map((val) => <span>{val}</span>)}
                      {e?.oldDateTimeValue &&
                        displayDateTime(e?.oldDateTimeValue, timeZoneId?.label)}
                      {e?.oldBreakValue?.length > 0 &&
                        e?.oldBreakValue?.map((breakDetails) => {
                          return (
                            <>
                              <span>{breakDetails?.breakType?.label}</span>
                              <span>
                                {breakDetails?.duration} mins
                                {(breakDetails?.startTime || breakDetails?.endTime) && (
                                  <span>
                                    &nbsp;| &nbsp;
                                    {displayTime(breakDetails?.startTime, timeZoneId?.label)}
                                    &nbsp;-&nbsp;
                                    {displayTime(breakDetails?.endTime, timeZoneId?.label)}
                                  </span>
                                )}
                              </span>
                            </>
                          );
                        })}
                      {!e?.oldBreakValue > 0 && !e?.oldDateTimeValue && isEmpty(e?.oldValue) && (
                        <span>-</span>
                      )}
                    </div>
                  </div>
                  <div className="data-wrapper">
                    <div className="title">New value &nbsp;</div>
                    <div className="value">
                      {e?.newValue && e?.newValue?.map((val) => <span>{val}</span>)}
                      {e?.newDateTimeValue &&
                        displayDateTime(e?.newDateTimeValue, timeZoneId?.label)}
                      {e?.newBreakValue?.length > 0 &&
                        e?.newBreakValue?.map((breakDetails) => {
                          return (
                            <>
                              <span>{breakDetails?.breakType?.label}</span>
                              <span>
                                {breakDetails?.duration} mins
                                {(breakDetails?.startTime || breakDetails?.endTime) && (
                                  <span>
                                    &nbsp;| &nbsp;
                                    {displayTime(breakDetails?.startTime, timeZoneId?.label)}
                                    &nbsp;-&nbsp;
                                    {displayTime(breakDetails?.endTime, timeZoneId?.label)}
                                  </span>
                                )}
                              </span>
                            </>
                          );
                        })}
                      {!e?.newBreakValue > 0 && !e?.newDateTimeValue && isEmpty(e?.newValue) && (
                        <span>-</span>
                      )}
                    </div>
                  </div>
                  <div className="data-wrapper">
                    <div className="title">Date &nbsp;</div>
                    <div className="value">{displayDateTime(e?.date, timeZoneId?.label)}</div>
                  </div>
                </div>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </div>
    </EzyDrawer>
  );
}
export default ShiftHistoryDrawer;
