import PropTypes from 'prop-types';
import EzyButton from '../button/EzyButton';

function EzyDialogAction({ actionButtons, className }) {
  return (
    <div className={`action-buttons ${className}`}>
      {actionButtons?.map(({ label, color, onClick, loading, ...rest }, index) => (
        <EzyButton
          key={index}
          label={label}
          onClick={onClick}
          className={rest?.className}
          color={color}
          loading={loading}
          {...rest}
        />
      ))}
    </div>
  );
}

export default EzyDialogAction;

EzyDialogAction.propTypes = {
  actionButtons: PropTypes.array.isRequired,
  className: PropTypes.string,
};

EzyDialogAction.defaultProps = {
  className: '',
};
