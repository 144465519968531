import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../components/common/loader/Loader';
import ROUTE_CONSTANTS from '../../../utils/constants';
import { updateSidePanelData } from '../../timeSheets/redux/timeSheetsSlice';

function InnerTable({
  headers,
  row,
  index,
  selected,
  setSelected,
  tableView,
  setTableView,
  personDetailId,
}) {
  const [viewBreakdown, setViewBreakdown] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectedLocation } = useSelector(
    ({ exportTimesheet }) => exportTimesheet?.sidePanelData ?? {}
  );

  useEffect(() => {
    if (tableView === 'Detail all') {
      setViewBreakdown(true);
    } else if (tableView) {
      setViewBreakdown(false);
    }
  }, [tableView]);

  const isItemSelected = selected.indexOf(row?.timeSheetId) !== -1;

  const handleCheck = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    event.preventDefault();
    event.stopPropagation();
  };
  const labelId = `enhanced-table-checkbox-${index}`;

  return (
    <>
      <TableRow
        className={` ${(index + 1) % 2 === 0 ? 'white-row' : 'gray-row'}`}
        onClick={() => {
          setViewBreakdown((prev) => !prev);
          if (tableView) setTableView(null);
        }}
      >
        <TableCell className="d-flex just-bet ">
          <Checkbox
            checked={isItemSelected}
            onClick={(e) => {
              handleCheck(e, row?.timeSheetId);
            }}
            inputProps={{
              'aria-labelledby': labelId,
            }}
          />
          <span className="material-icons">
            {row?.isExported ? 'open_in_new' : 'open_in_new_off'}
          </span>
          <span className="material-icons">{row?.isPaid ? 'attach_money' : 'money_off'}</span>
        </TableCell>
        {headers
          ?.filter((e) => !e?.hidden)
          ?.map((column) => {
            const value = row[column?.name];
            return (
              <TableCell
                key={column?.name}
                align={column?.align || 'left'}
                title={column?.tooltip ? value : undefined}
                size={column?.size || 'medium'}
              >
                {(column?.innerFormat
                  ? column?.innerFormat(value, row, selectedLocation?.timeZoneId?.label)
                  : value) || '-'}
              </TableCell>
            );
          })}
      </TableRow>

      {viewBreakdown &&
        row?.payRates?.length > 0 &&
        row?.payRates?.map((breakdown, i) => (
          <TableRow className={` ${(index + 1) % 2 === 0 ? 'white-row' : 'gray-row'}`}>
            <TableCell>
              {i === 0 && (
                <div
                  className="secondary-action-text"
                  onClick={() => {
                    history.push(
                      `${ROUTE_CONSTANTS.TIMESHEETS}?location=${selectedLocation?.id}&date=${row?.timeSheetDate}&area=${row?.areaId}&person=${personDetailId}`
                    );
                    dispatch(
                      updateSidePanelData({
                        fieldName: 'selectedTimesheet',
                        value: { id: row?.timeSheetId },
                      })
                    );
                  }}
                >
                  Original item
                </div>
              )}
            </TableCell>
            {headers
              ?.filter((e) => !e?.hidden)
              ?.map((column) => {
                const value = breakdown[column?.name];
                return (
                  <TableCell
                    key={column?.name}
                    align={column?.align || 'left'}
                    title={column?.tooltip ? value : undefined}
                    size={column?.size || 'medium'}
                  >
                    {(column?.mostInnerFormat
                      ? column?.mostInnerFormat(value, breakdown)
                      : value) || '-'}
                  </TableCell>
                );
              })}
          </TableRow>
        ))}
    </>
  );
}

InnerTable.propTypes = {
  headers: PropTypes.array.isRequired,
  row: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  tableView: PropTypes.string.isRequired,
  setTableView: PropTypes.func.isRequired,
  personDetailId: PropTypes.string.isRequired,
};

function Row({ headers, row, index, selected, setSelected, tableView, setTableView }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (tableView === 'Expand all' || tableView === 'Detail all') {
      setOpen(true);
    } else if (tableView === 'Collapse all') {
      setOpen(false);
      // setTableView(null);
    }
  }, [tableView]);

  return (
    <TableRow
      hover
      tabIndex={-1}
      key={row?.personDetailId || index}
      className={`collapsible-row ${(index + 1) % 2 === 0 ? 'white-row' : 'gray-row'}`}
    >
      <TableCell colspan={headers?.length} className="collapsible-cell">
        <Table>
          <TableBody>
            <TableRow
              className={`collapsible-row ${(index + 1) % 2 === 0 ? 'white-row' : 'gray-row'}`}
              onClick={() => {
                setOpen((prev) => !prev);
                if (tableView) setTableView(null);
              }}
            >
              <TableCell size="small" className="empty-cell">
                <span
                  className="material-icons "
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen((prev) => !prev);
                    if (tableView) setTableView(null);
                  }}
                >
                  {open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                </span>
              </TableCell>
              {headers
                ?.filter((e) => !e?.hidden)
                ?.map((column) => {
                  const value = row[column?.name];
                  return (
                    <TableCell
                      key={column?.name}
                      align={column?.align || 'left'}
                      title={column?.tooltip ? value : undefined}
                      size={column?.size || 'medium'}
                    >
                      {(column?.format ? column?.format(value, row, open) : value) || '-'}
                    </TableCell>
                  );
                })}
            </TableRow>

            {open &&
              row?.timeSheetsForExport?.map((timesheet) => {
                return (
                  <InnerTable
                    row={timesheet}
                    index={index}
                    headers={headers}
                    selected={selected}
                    setSelected={setSelected}
                    tableView={tableView}
                    setTableView={setTableView}
                    personDetailId={row?.personDetailId}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableCell>
    </TableRow>
  );
}

Row.propTypes = {
  headers: PropTypes.array.isRequired,
  row: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  tableView: PropTypes.string.isRequired,
  setTableView: PropTypes.func.isRequired,
};

export default function EzyCollapsibleTable({
  className,
  headers,
  actions,
  rows,
  loading,
  selected,
  setSelected,
  tableView,
  setTableView,
}) {
  const hasAction = () => {
    if (actions?.length > 0) return true;
    const actionInRow = rows?.some((e) => e?.action?.length > 0);
    return !!actionInRow;
  };

  return (
    <Paper className="table-paper">
      <TableContainer className={`${className} collapsible-table`}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell size="small" className="empty-cell" />
              {headers
                ?.filter((e) => !e?.hidden)
                ?.map((column, index) => (
                  <TableCell
                    key={index}
                    align={column?.align || 'left'}
                    style={{ minWidth: column?.minWidth }}
                    size={column?.size || 'medium'}
                  >
                    {column?.label}
                  </TableCell>
                ))}
              {hasAction() && <TableCell align="right">Actions</TableCell>}
            </TableRow>
          </TableHead>
          {loading ? (
            <Loader />
          ) : (
            <TableBody>
              {rows?.length > 0 ? (
                rows?.map((row, index) => {
                  return (
                    <Row
                      row={row}
                      index={index}
                      headers={headers}
                      selected={selected}
                      setSelected={setSelected}
                      tableView={tableView}
                      setTableView={setTableView}
                    />
                  );
                })
              ) : (
                <Box className="no-data-found error-color">No records found</Box>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Paper>
  );
}

EzyCollapsibleTable.propTypes = {
  headers: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  className: PropTypes.string,
  actions: PropTypes.array,
  loading: PropTypes.bool,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  tableView: PropTypes.string,
  setTableView: PropTypes.func,
};

EzyCollapsibleTable.defaultProps = {
  className: '',
  actions: [],
  loading: false,
  selected: [],
  setSelected: null,
  tableView: null,
  setTableView: () => {},
};
