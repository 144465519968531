import authServices from '../authServices';
import { setUserData } from './userSlice';
import { displayErrors } from '../../../utils/errorNotifyHelper';
import {
  startGeneralLoader,
  stopGeneralLoader,
} from '../../../utils/backOfficeLists/commonDataSlice';
import {
  getAllTimezonesAction,
  getCountryList,
  getEnumsList,
  getIndustryTypeList,
} from '../../../utils/backOfficeLists/backOfficeListActions';

export const getCurrentUserDataAction = (cb, data) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getCurrentUserDataLoader' }));
      const res = await authServices.getCurrentUserData();
      if (res?.data?.success) {
        await dispatch(getEnumsList());
        await dispatch(getIndustryTypeList());
        await dispatch(getCountryList());
        await dispatch(getAllTimezonesAction());
        dispatch(setUserData(res?.data?.data));
        if (cb && typeof cb === 'function' && !res?.data?.data?.businessId) {
          cb();
        }
        if (
          cb &&
          typeof cb === 'function' &&
          (data?.isFromSwitchBusiness || data?.isFromAccountOverview)
        ) {
          cb();
        }
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getCurrentUserDataLoader' }));
    }
  };
};

export const verifyEmailFromLinkAction = (data) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'verifyEmailFromLinkActionLoader' }));
      const res = await authServices.verifyEmailFromLink(data);
      if (res?.data?.success) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'verifyEmailFromLinkActionLoader' }));
    }
  };
};
