import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { Chip, FormHelperText } from '@mui/material';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import {
  addDeleteAreaLibraryRate,
  addDeletePeopleHourlyPayGrade,
  editPeopleDetails,
  resetPayRateData,
  updatePeoplePayDetailsFields,
} from '../../redux/peopleSlice';
import EzyIconButton from '../../../../components/common/iconButton/EzyIconButton';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import EzyAutoComplete from '../../../../components/common/autoComplete/EzyAutoComplete';
import {
  editPeoplePayDetailsAction,
  getAllFairWorkAwardsAction,
  getAllWageAllowancesAction,
  getAreaListByPersonAction,
  getPeoplePayDetailsAction,
} from '../../redux/peopleActions';
import { validatePayRate } from '../../validations/addPayRateValidations';
import Loader from '../../../../components/common/loader/Loader';
import EzyDialog from '../../../../components/common/dialog/EzyDialog';
import EzyDialogTitle from '../../../../components/common/dialog/EzyDialogTitle';
import EzyDialogAction from '../../../../components/common/dialog/EzyDialogAction';
import EzyAccordionWrapper from '../../../../components/common/accordion/EzyAccordionWrapper';
import EzyAccordion from '../../../../components/common/accordion/EzyAccordion';
import EzyAccordionSummary from '../../../../components/common/accordion/EzyAccordionSummary';
import EzyAccordionDetails from '../../../../components/common/accordion/EzyAccordinDetails';

function HourlyPayGradeDrawer() {
  const { details, payDetails, areaListByPerson } = useSelector(
    ({ people }) => people?.editPeople ?? {}
  );
  const { countryId } = useSelector(({ user }) => user?.profileData ?? {});
  const { enums } = useSelector(({ common }) => common ?? {});
  const { payGradeModal } = useMemo(() => details ?? {}, [details]);
  const { payRateType: payRateTypeList, salaryPeriod } = useMemo(() => enums ?? {}, [enums]);

  const [openModal, setOpenModal] = useState(false);

  const {
    getAreaListByPersonActionLoader,
    getPeoplePayDetailsActionLoader,
    editPeoplePayDetailsActionLoader,
    getAllFairWorkAwardsActionLoader,
    getAllWageAllowancesActionLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});

  const {
    awardsList,
    payRateType,
    hourlyPayDetails,
    salaryPayDetail,
    libraryRateDetailEntity,
    errors: payRateErrors,
  } = useMemo(() => payDetails, [payDetails]);

  const payRatesList = [payRateTypeList[0], payRateTypeList[1], ...awardsList];

  const dispatch = useDispatch();
  const { peopleId } = useParams();

  const handleClose = () => {
    dispatch(editPeopleDetails({ fieldName: 'payGradeModal', value: false }));
  };

  const handleFieldChanges = (fieldName, value, payTypeField, index) => {
    dispatch(updatePeoplePayDetailsFields({ fieldName, value, payTypeField, index }));
  };

  const cb = () => {
    handleClose();
    dispatch(editPeopleDetails({ fieldName: 'payRateType', value: payRateType }));
  };

  const handleSubmit = () => {
    const data = {
      personDetailId: peopleId,
      payRateType: payRateType?.id,
    };
    if (payRateType?.id === 1) {
      data.hourlyPayDetails = hourlyPayDetails?.map((e) => ({
        ...e,
        areaIds: areaListByPerson
          ?.filter((area) => e.areaIds?.includes(area?.label))
          ?.map((area) => area?.id),
      }));
    } else if (payRateType?.id === 2) {
      data.salaryPayDetail = {
        ...salaryPayDetail,
        salaryPeriod: salaryPayDetail?.salaryPeriod?.id,
        salaryAmount: Number(salaryPayDetail?.salaryAmount),
      };
    } else {
      data.payRateType = 3;
      data.libraryRateDetailEntity = libraryRateDetailEntity?.map((e) => {
        return {
          awardCode: e?.awardCode?.id,
          baseRate: e?.baseRate,
          areaIds: areaListByPerson
            ?.filter((area) => e.areaIds?.includes(area?.label))
            ?.map((area) => area?.id),
        };
      });
    }
    const errors = validatePayRate(data);
    handleFieldChanges('errors', errors);
    if (isEmpty(errors)) dispatch(editPeoplePayDetailsAction(data, cb));
  };

  const actionButton = [
    {
      label: 'Save',
      onClick: handleSubmit,
      loading: editPeoplePayDetailsActionLoader,
    },
  ];

  useEffect(() => {
    if (payGradeModal) {
      (async () => {
        await dispatch(getAreaListByPersonAction(peopleId));
        if (countryId === 13) {
          await dispatch(
            getAllFairWorkAwardsAction((data) => {
              dispatch(getPeoplePayDetailsAction(peopleId, { awardList: data }));
            })
          );
        } else {
          await dispatch(getPeoplePayDetailsAction(peopleId));
        }
      })();
    }

    return () => {
      dispatch(resetPayRateData());
    };
  }, [payGradeModal, peopleId]);

  return (
    <EzyDrawer
      onClose={handleClose}
      isOpen={payGradeModal}
      actionButtons={actionButton}
      heading="Pay Rate"
    >
      {getPeoplePayDetailsActionLoader ? (
        <Loader />
      ) : (
        <div className="hourly-drawer">
          <div className="select-pay-rate">
            <EzyAutoComplete
              placeholder="Select pay rates"
              label="Select pay rates"
              options={payRatesList}
              value={payRateType}
              name="payRateType"
              onChange={(_, value) => {
                handleFieldChanges('payRateType', value);
                if (![1, 2]?.includes(value?.id) && value?.id) {
                  if ([1, 2]?.includes(payRateType?.id)) {
                    setOpenModal(true);
                  }
                  handleFieldChanges('awardCode', value, 'libraryRateDetailEntity', 0);
                  dispatch(getAllWageAllowancesAction({ awardCode: value?.id }, 0));
                }
              }}
              error={payRateErrors?.payRateType}
              groupBy={(option) => {
                if ([1, 2].includes(option?.id)) return ' ';
                return 'Library rates';
              }}
              required
              loading={getAllFairWorkAwardsActionLoader}
            />
          </div>
          {hourlyPayDetails?.length > 0 &&
            payRateType?.label === 'Hourly' &&
            hourlyPayDetails?.map((e, index) => (
              <div>
                <div className="title-with-primary-bg area-title">
                  <span>Area</span>
                  <span>
                    {index >= 1 ? (
                      <EzyIconButton
                        iconName="delete"
                        onClick={() =>
                          dispatch(addDeletePeopleHourlyPayGrade({ type: 'DELETE', index }))
                        }
                        className="small"
                      />
                    ) : (
                      <EzyIconButton
                        iconName="add"
                        onClick={() => dispatch(addDeletePeopleHourlyPayGrade({ type: 'ADD' }))}
                        className="small"
                      />
                    )}
                  </span>
                </div>
                <div className="hourly-field-grid">
                  {index >= 1 && (
                    <div className="areaIds">
                      <EzyAutoComplete
                        id="tags-outlined"
                        multiple
                        className={`${e?.areaIds?.length ? 'auto-complete-tags' : ''}`}
                        placeholder="Add area"
                        label="Enter location areas"
                        name="areaIds"
                        value={e?.areaIds}
                        options={areaListByPerson?.map((area) => area?.label)}
                        renderTags={(value, getTagProps) =>
                          value.map((option, chipIndex) => (
                            <Chip
                              variant="outlined"
                              color="primary"
                              label={option}
                              deleteIcon={<span className="material-icons">highlight_off</span>}
                              {...getTagProps({ chipIndex })}
                            />
                          ))
                        }
                        onChange={(_, value) =>
                          handleFieldChanges('areaIds', value, 'hourlyPayDetails', index)
                        }
                        error={payRateErrors?.hourlyPayDetails?.[index]?.areaIds}
                        loading={getAreaListByPersonActionLoader}
                        required
                      />
                    </div>
                  )}
                  <EzyInputField
                    name="weekDayRate"
                    placeholder="Enter weekDay rate"
                    label="Weekday rate"
                    value={e?.weekDayRate}
                    onChange={(val) => {
                      if (val.target.value.length <= 16)
                        handleFieldChanges(
                          'weekDayRate',
                          val.target.value,
                          'hourlyPayDetails',
                          index
                        );
                    }}
                    error={payRateErrors?.hourlyPayDetails?.[index]?.weekDayRate}
                  />
                  <EzyInputField
                    name="publicHolidayRate"
                    placeholder="Enter public holiday rate"
                    label="Public holiday rate"
                    value={e?.publicHolidayRate}
                    onChange={(val) => {
                      if (val.target.value.length <= 16)
                        handleFieldChanges(
                          'publicHolidayRate',
                          val.target.value,
                          'hourlyPayDetails',
                          index
                        );
                    }}
                    error={payRateErrors?.hourlyPayDetails?.[index]?.publicHolidayRate}
                  />
                  <EzyInputField
                    name="saturdayRate"
                    placeholder="Enter saturday rate"
                    label="Saturday rate"
                    value={e?.saturdayRate}
                    onChange={(val) => {
                      if (val.target.value.length <= 16)
                        handleFieldChanges(
                          'saturdayRate',
                          val.target.value,
                          'hourlyPayDetails',
                          index
                        );
                    }}
                    error={payRateErrors?.hourlyPayDetails?.[index]?.saturdayRate}
                  />
                  <EzyInputField
                    name="sundayRate"
                    placeholder="Enter sunday rate"
                    label="Sunday rate"
                    value={e?.sundayRate}
                    onChange={(val) => {
                      if (val.target.value.length <= 16)
                        handleFieldChanges(
                          'sundayRate',
                          val.target.value,
                          'hourlyPayDetails',
                          index
                        );
                    }}
                    error={payRateErrors?.hourlyPayDetails?.[index]?.sundayRate}
                  />
                </div>
              </div>
            ))}
          {payRateType?.label === 'Salary' && (
            <div className="hourly-field-grid">
              <EzyAutoComplete
                placeholder="Select salary period"
                label="Salary period"
                options={salaryPeriod}
                value={salaryPayDetail?.salaryPeriod}
                name="salaryPeriod"
                onChange={(_, value) =>
                  handleFieldChanges('salaryPeriod', value, 'salaryPayDetail')
                }
                error={payRateErrors?.salaryPeriod}
                required
              />
              <EzyInputField
                type="number"
                name="salaryAmount"
                placeholder="Enter salary amount"
                label="Salary amount"
                value={salaryPayDetail?.salaryAmount}
                onChange={(val) => {
                  if (val.target.value.length <= 16)
                    handleFieldChanges('salaryAmount', val.target.value, 'salaryPayDetail');
                }}
                error={payRateErrors?.salaryAmount}
                required
              />
            </div>
          )}
          {libraryRateDetailEntity?.length > 0 &&
            !['Hourly', 'Salary']?.includes(payRateType?.label) &&
            libraryRateDetailEntity?.map((e, index) => {
              return (
                <div>
                  <div className="title-with-primary-bg area-title">
                    <span>Area</span>
                    <span>
                      {index >= 1 ? (
                        <EzyIconButton
                          iconName="delete"
                          onClick={() =>
                            dispatch(addDeleteAreaLibraryRate({ type: 'DELETE', index }))
                          }
                          className="small"
                        />
                      ) : (
                        <EzyIconButton
                          iconName="add"
                          onClick={() => dispatch(addDeleteAreaLibraryRate({ type: 'ADD' }))}
                          className="small"
                        />
                      )}
                    </span>
                  </div>
                  <div className="library-field">
                    {index >= 1 && (
                      <>
                        <EzyAutoComplete
                          id="tags-outlined"
                          multiple
                          className={`${e?.areaIds?.length ? 'auto-complete-tags' : ''}`}
                          placeholder="Add area"
                          label="Enter location areas"
                          name="areaIds"
                          value={e?.areaIds}
                          options={areaListByPerson?.map((area) => area?.label)}
                          renderTags={(value, getTagProps) =>
                            value.map((option, chipIndex) => (
                              <Chip
                                variant="outlined"
                                color="primary"
                                label={option}
                                deleteIcon={<span className="material-icons">highlight_off</span>}
                                {...getTagProps({ chipIndex })}
                              />
                            ))
                          }
                          onChange={(_, value) =>
                            handleFieldChanges('areaIds', value, 'libraryRateDetailEntity', index)
                          }
                          error={payRateErrors?.libraryRateDetailEntity?.[index]?.areaIds}
                          loading={getAreaListByPersonActionLoader}
                          required
                        />

                        <EzyAutoComplete
                          placeholder="Select pay rates"
                          label="Select pay rates"
                          options={awardsList}
                          value={e?.awardCode}
                          name="awardCode"
                          onChange={(_, value) => {
                            handleFieldChanges(
                              'awardCode',
                              value,
                              'libraryRateDetailEntity',
                              index
                            );
                            dispatch(getAllWageAllowancesAction({ awardCode: value?.id }, index));
                          }}
                          error={payRateErrors?.libraryRateDetailEntity?.[index]?.awardCode}
                          required
                        />
                      </>
                    )}
                    <EzyInputField
                      type="number"
                      name="baseRate"
                      placeholder="Enter in ₹ per hour"
                      label="Base rate - fixes rate"
                      value={e?.baseRate}
                      onChange={(event) =>
                        handleFieldChanges(
                          'baseRate',
                          event?.target?.value,
                          'libraryRateDetailEntity',
                          index
                        )
                      }
                      error={payRateErrors?.libraryRateDetailEntity?.[index]?.baseRate}
                      required
                    />
                    {getAllWageAllowancesActionLoader && <Loader />}
                    {e?.wagesList?.length > 0 && (
                      <EzyAccordionWrapper>
                        <EzyAccordion>
                          <EzyAccordionSummary>View all rates and allowances</EzyAccordionSummary>
                          <EzyAccordionDetails>
                            <div className="d-flex-column gap-10">
                              {e?.wagesList?.map((wages) => (
                                <div>
                                  {' '}
                                  <EzyInputField
                                    label={wages?.displayName}
                                    value={wages?.allowanceAmount}
                                    disabled
                                  />
                                  <FormHelperText>{wages?.paymentFrequency}</FormHelperText>
                                </div>
                              ))}
                            </div>
                          </EzyAccordionDetails>
                        </EzyAccordion>
                      </EzyAccordionWrapper>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      )}

      <EzyDialog className="common-dialog-with-body " open={openModal}>
        <EzyDialogTitle
          title="Payrate"
          onClose={() => {
            setOpenModal(false);
            handleClose();
          }}
        />
        <div className="dialog-body title-small">
          <div className="info-div primary-bg word-break">
            {` While Payroll Ezy takes care to provide a quality pay rules library, it does not
            guarantee (or accept responsibility for) the accuracy, completeness or up to date nature
            of the library, or that it will be suitable for the particular circumstances of your
            business. You're advised to consult with your legal and payroll advisers before relying
            on Payroll Ezy's interpretation.`}
          </div>
        </div>
        <EzyDialogAction
          actionButtons={[
            {
              label: 'Add payrate',
              onClick: () => {
                setOpenModal(false);
              },
            },
          ]}
        />
      </EzyDialog>
    </EzyDrawer>
  );
}

export default HourlyPayGradeDrawer;
