import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import EzyDrawer from '../../../components/common/drawer/Drawer';
import { updateExportTimeSheetData } from '../redux/exportTimesheetSlice';

// import PropTypes from 'prop-types';

function EditExportCodeDrawer() {
  const { isOpenEditExportCodeDrawer } = useSelector(
    ({ exportTimesheet }) => exportTimesheet ?? {}
  );

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(updateExportTimeSheetData({ fieldName: 'isOpenEditExportCodeDrawer', value: false }));
  };

  const actionButton = [
    {
      label: 'Save',
      onClick: () => {},
      loading: false,
    },
  ];

  return (
    <EzyDrawer
      isOpen={isOpenEditExportCodeDrawer}
      onClose={handleClose}
      heading="Edit Export Code"
      actionButtons={actionButton}
    >
      <div className="export-items-drawer">
        <EzyInputField label="Export Code" placeholder="Enter export code" />
      </div>
    </EzyDrawer>
  );
}

EditExportCodeDrawer.propTypes = {};

export default EditExportCodeDrawer;
