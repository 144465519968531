import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNil, omitBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import { resetAddMetricDrawerData, updateAddMetricDrawer } from '../../redux/insightSlice';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import EzyToggleButtonGroup from '../../../../components/common/toggleButton/EzyToggleButtonGroup';
import { requireValidate } from '../../../../utils/validationHelper';
import { addMetricAction, getInsightListAction } from '../../redux/insightAction';
import ROUTE_CONSTANTS from '../../../../utils/constants';

function AddMetricDrawer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { businessId } = useSelector(({ user }) => user?.profileData ?? {});
  const [errors, setErrors] = useState({});

  const { filters } = useSelector(({ insight }) => insight ?? {});

  const handleClose = () => {
    dispatch(resetAddMetricDrawerData());
  };

  const { isOpen, metricType, metricForecastingType, metricName } = useSelector(
    ({ insight }) => insight?.metric?.addMetricDrawer ?? {}
  );

  const { metricType: metricTypeEnum, metricForecastingType: metricForecastingTypeEnum } =
    useSelector(({ common }) => common?.enums ?? {});

  const { addMetricActionLoader } = useSelector(({ common }) => common.generalLoader ?? {});

  const handleFieldChange = (fieldName, value) => {
    dispatch(updateAddMetricDrawer({ fieldName, value }));
  };

  const handleSubmit = () => {
    let error = {};
    error.metricName = requireValidate('Metric Name', metricName);

    error = omitBy(error, isNil);
    setErrors(error);

    if (isEmpty(error)) {
      const data = { businessId, metricType, metricForecastingType, metricName };
      dispatch(
        addMetricAction(data, () => {
          handleClose();
          dispatch(
            getInsightListAction(metricName, (selectedModule) => {
              history.push(
                `${ROUTE_CONSTANTS.SCHEDULE}/insights/${selectedModule?.pathName}?location=${
                  filters?.selectedLocation?.id
                }&date=${moment
                  .tz(
                    filters?.weekDatesValue?.startDate,
                    filters?.selectedLocation?.timeZoneId?.label
                  )
                  .toISOString()}`
              );
            })
          );
        })
      );
    }
  };

  return (
    <EzyDrawer
      isOpen={isOpen}
      onClose={handleClose}
      heading="Add Metric"
      actionButtons={[{ label: 'Save', onClick: handleSubmit, loading: addMetricActionLoader }]}
    >
      <div className="add-metric-drawer">
        <EzyInputField
          name="metricName"
          label="Metric name"
          placeholder="Enter new metric entry"
          onChange={(e) => handleFieldChange('metricName', e?.target?.value)}
          value={metricName}
          error={errors?.metricName}
          required
        />
        <EzyToggleButtonGroup
          label="Type of metric"
          list={metricTypeEnum?.map((e) => e?.label)}
          onChange={(event) => {
            const value = metricTypeEnum?.find((e) => e?.label === event.target.value)?.id;
            handleFieldChange('metricType', value);
          }}
          value={metricTypeEnum?.find((e) => e?.id === metricType)?.label}
        />
        <EzyToggleButtonGroup
          label="ForeCasting"
          list={[
            {
              id: 1,
              label: 'Automatic forecast',
              subtitle: 'Automatically forecast based on previous data.',
            },
            {
              id: 2,
              label: 'Manager forecast',
              subtitle: 'Manager can enter and display their own forecast for the future.',
            },
            {
              id: 3,
              label: 'Both',
              subtitle: 'Automatic and manual forecasting',
            },
          ]}
          onChange={(event, res) => {
            if (res !== null) {
              const value = metricForecastingTypeEnum?.find((e) => e?.label === res)?.id;
              handleFieldChange('metricForecastingType', value);
            }
          }}
          value={metricForecastingTypeEnum?.find((e) => e?.id === metricForecastingType)?.label}
        />
      </div>
    </EzyDrawer>
  );
}

export default AddMetricDrawer;
