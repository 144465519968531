import { formatPhoneWithCode } from '../../utils/phoneNumberHelper';
import EzyIconTooltip from '../../components/common/tooltip/EzyIconTooltip';

export const peopleImportHeaders = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'email',
    label: 'Email',
  },
  {
    name: 'phoneNumber',
    label: 'Phone Number',
    format: (value, row) => formatPhoneWithCode(value, row?.countryCode),
  },
  {
    name: 'errorMessage',
    label: 'Actions',
    align: 'right',
    format: (value) => (
      <EzyIconTooltip tooltip={value} iconClass="import-csv-error-icon" placement="top-end" />
    ),
  },
];
