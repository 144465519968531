import { Chip } from '@mui/material';
import { startCase } from 'lodash';
import { displayDate } from '../../utils/timeHelper';

export const InvoiceHeaders = [
  {
    name: 'id',
    label: 'Id',
    hidden: true,
  },
  {
    name: 'invoiceNumber',
    label: 'Invoice#',
    format: (value, data) => {
      return value ? (
        <a href={data.pdfURL} target="_blank" rel="noreferrer" className="href-link">
          {value}
        </a>
      ) : (
        '-'
      );
    },
  },
  {
    name: 'invoiceDate',
    label: 'Invoice Date',
    format: (value, _, timezone) => displayDate(value, timezone),
  },
  {
    name: 'dueDate',
    label: 'Due Date',
    format: (value, _, timezone) => displayDate(value, timezone),
  },
  {
    name: 'amount',
    label: 'Amount',
  },
  {
    name: 'invoiceStatus',
    label: 'Status',
    format: (value) => {
      return (
        <Chip
          variant="outlined"
          className={value === 'Date Approved' ? 'Date-Approved' : value}
          label={startCase(value)}
        />
      );
    },
  },
];

export const receiptHeaders = [
  {
    name: 'receiptEmail',
    label: 'Receiver Email',
  },
  {
    name: 'planName',
    label: 'Plan',
  },
  {
    name: 'quantity',
    label: 'Quantity',
  },
  {
    name: 'amount',
    label: 'Amount',
  },
  {
    name: 'purchaseDate',
    label: 'Purchase Date',
    format: (value, _, timezone) => displayDate(value, timezone),
  },
  {
    name: 'serviceEndDate',
    label: 'Service Period End Date',
    format: (value, _, timezone) => displayDate(value, timezone),
  },
];
