/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { Badge } from '@mui/material';
import IconByName from '../../../../components/common/iconByName/IconByName';
import EzyAvatar from '../../../../components/common/avatar/EzyAvatar';
import { errorNotification } from '../../../../utils/notifyHelper';
import { uploadProfilePicture, deleteProfilePictureAction } from '../../redux/peopleActions';
import EzyDialog from '../../../../components/common/dialog/EzyDialog';
import PeopleShift from './PeopleShift';
import ConfirmationModal from '../../../../components/common/modal/ConfirmationModal';

function PeopleProfile({ isFromDashboard }) {
  const dispatch = useDispatch();
  const [profileModal, setOpenProfileModal] = useState(false);
  const [hoverEffect, setHoverEffect] = useState('mouseout');
  const [deleteModal, setDeleteModal] = useState(null);
  const { profileData } = useSelector(({ user }) => user || {});
  const userData = useMemo(() => profileData, [profileData]);
  const peopleData = useSelector(({ people }) => people ?? {});
  const details = useMemo(() => peopleData?.editPeople?.details, [peopleData]);
  const hiddenFileInput = useRef(null);
  const param = useParams();
  const { peopleId, profilePhotoURL, preferredFullName } = useMemo(() => {
    if (isFromDashboard) {
      return {
        peopleId: userData?.personId,
        profilePhotoURL: userData?.profilePictureUrl,
        preferredFullName: userData?.personName,
      };
    }

    return {
      peopleId: param?.peopleId,
      profilePhotoURL: details?.profilePhotoURL,
      preferredFullName: details?.preferredFullName,
    };
  }, [userData, param, details, isFromDashboard]);

  const isDisabled = useMemo(
    () => details?.roleId?.id < userData?.roleId ?? true,
    [details, userData?.roleId]
  );
  const handleClick = () => {
    if (!isDisabled) hiddenFileInput?.current?.click();
  };

  const handleChange = useCallback(
    async (e) => {
      e.preventDefault();

      if (e.target.files && e.target.files.length > 0) {
        const fileExtension = ['jpeg', 'jpg', 'png'];
        const mimeType = ['image/jpeg', 'image/jpg', 'image/png'];

        const checkExtension =
          fileExtension.indexOf(e.target.files[0].name.split('.').splice(-1)[0]) !== -1;

        const checkMimeTypes = mimeType.indexOf(e.target.files[0].type) !== -1;

        const checkFileSize = e.target.files[0].size > 4194304;

        if (!(checkExtension || checkMimeTypes)) {
          errorNotification('Only image file allowed');
        } else if (checkFileSize) {
          errorNotification('File size should be less than 4 mb');
        } else {
          const formData = new FormData();
          formData.append('image', e.target.files[0]);
          formData.append('personDetailId', peopleId || details?.peopleId);

          const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };

          e.target.value = null;

          try {
            await dispatch(uploadProfilePicture(formData, config));
          } catch (error) {
            /**/
          }
        }
      }
    },
    [peopleId]
  );

  const deleteProfilePicture = async () => {
    await dispatch(deleteProfilePictureAction({ personDetailId: peopleId }));
  };

  const badgeStatus = useMemo(() => {
    if (
      !details?.shiftData?.isShiftStarted &&
      details?.shiftData?.isShiftScheduled &&
      !details?.shiftData?.isLateForShift
    ) {
      return 'blue';
    }
    if (details?.shiftData?.isBreakStartted) {
      return 'yellow';
    }
    if (details?.shiftData?.isShiftScheduled && details?.shiftData?.isLateForShift) {
      return 'red';
    }

    return 'green';
  }, [details?.shiftData]);
  const isBadgeInvisible = useMemo(() => {
    if (isFromDashboard) {
      if (
        details?.shiftData?.isShiftScheduled ||
        details?.shiftData?.isShiftStarted ||
        details?.shiftData?.isBreakStartted
      ) {
        return false;
      }

      return true;
    }
    return true;
  }, [details?.shiftData]);

  return (
    <div className="people-profile">
      <div
        className="profile-picture-wrapper"
        onMouseOver={(e) => setHoverEffect(e?.type)}
        onMouseOut={(e) => setHoverEffect(e?.type)}
      >
        <Badge
          color="secondary"
          variant="dot"
          invisible={isBadgeInvisible}
          className={`people-profile-badge ${badgeStatus}`}
        >
          <EzyAvatar
            className={`profile-picture ${isFromDashboard && 'default-cursor'}`}
            firstName={preferredFullName}
            imageLink={profilePhotoURL}
          />
        </Badge>
        <input
          type="file"
          className="input-file-type"
          ref={hiddenFileInput}
          onChange={handleChange}
          accept="image/png, image/jpg, image/jpeg"
        />
        {hoverEffect === 'mouseover' && profilePhotoURL && !isFromDashboard && (
          <div className="img__overlay">
            <IconByName name="visibility" onClick={() => setOpenProfileModal(true)} />
            <IconByName name="edit" onClick={handleClick} />

            <IconByName
              name="delete"
              onClick={(e) => {
                setDeleteModal(e);
              }}
            />
          </div>
        )}
        {hoverEffect === 'mouseover' && profilePhotoURL === null && !isFromDashboard && (
          <div className="img__overlay">
            <IconByName name="add_photo_alternate" onClick={handleClick} />
          </div>
        )}
      </div>
      {isFromDashboard && <div className="profile-name">{preferredFullName}</div>}
      <EzyDialog
        open={profileModal && profilePhotoURL}
        handleClose={() => setOpenProfileModal(false)}
        className="common-dialog-with-body profile-picture-dialog"
      >
        <IconByName
          name="close"
          className="pointer-cursor close-icon"
          onClick={() => setOpenProfileModal(false)}
        />
        <img src={profilePhotoURL} alt={preferredFullName} />
      </EzyDialog>
      <PeopleShift isFromDashboard={isFromDashboard} />
      <ConfirmationModal
        title="Delete Profile Picture"
        isOpen={!isEmpty(deleteModal) || !isNull(deleteModal)}
        handleClose={() => {
          setDeleteModal(null);
        }}
        handleSubmit={async () => {
          await deleteProfilePicture();
          setDeleteModal(null);
        }}
        primaryLabel="Delete"
      >
        <div>Are you sure you want to delete?</div>
      </ConfirmationModal>
    </div>
  );
}

export default PeopleProfile;
PeopleProfile.propTypes = {
  isFromDashboard: PropTypes.bool,
};

PeopleProfile.defaultProps = {
  isFromDashboard: false,
};
