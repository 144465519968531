import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { PaginationItem } from '@mui/material';
import { useMemo } from 'react';
import EzySelect from '../select/EzySelect';

const noPerPage = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '15', value: 15 },
  { label: '20', value: 20 },
  { label: '25', value: 25 },
  { label: '30', value: 30 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

function Prev() {
  return <div>Previous</div>;
}

function Next() {
  return <div>Next</div>;
}

function EzyPagination({ total, limit, page, pages, pageActionClick, onChangeLimit }) {
  const fromRecordCount = useMemo(
    () => total && page && limit && (page - 1) * limit + 1,
    [page, limit, total]
  );
  const toRecordCount = useMemo(
    () => total && page && limit && (total < page * limit ? total : page * limit),
    [page, limit, total]
  );

  const onSelectLimit = (event) => {
    onChangeLimit(event?.target?.value);
  };

  return (
    <div className="pagination-wrapper">
      <div className="record-details">
        <div>
          Showing {fromRecordCount} to {toRecordCount} of {total}
        </div>
        <EzySelect
          placeholder="Limit per page"
          value={limit}
          options={noPerPage}
          onChange={onSelectLimit}
          className="rows-per-page"
        />
        <div>Row per page</div>
      </div>
      <Stack spacing={2}>
        <Pagination
          count={pages}
          page={page}
          onChange={(_, value) => pageActionClick(value)}
          shape="rounded"
          boundaryCount={2}
          renderItem={(item) => (
            <PaginationItem components={{ previous: Prev, next: Next }} {...item} />
          )}
        />
      </Stack>
    </div>
  );
}

export default EzyPagination;

EzyPagination.propTypes = {
  total: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pageActionClick: PropTypes.func.isRequired,
  onChangeLimit: PropTypes.func.isRequired,
};
