export const headers = [
  {
    name: 'id',
    label: 'Id',
    hidden: true,
    minWidth: 170,
  },
  {
    name: 'locationName',
    label: 'Name',
    minWidth: 170,
  },
  {
    name: 'locationAddress',
    label: 'Address',
    minWidth: 170,
    tooltip: true,
  },
  {
    name: 'areas',
    label: 'Areas',
    format: (value) => value.map((e) => e?.areaName).join(', '),
    minWidth: 170,
  },
];
