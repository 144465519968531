import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Chip } from '@mui/material';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import { resetExportMetricDrawer, updateExportMetricDrawer } from '../../redux/insightSlice';
import EzyDate from '../../../../components/common/dateAndTimePicker/EzyDate';
import EzyAutoComplete from '../../../../components/common/autoComplete/EzyAutoComplete';
import validateExportMetric from '../../validations/validateExportMetric';
import { exportMetricDataAction } from '../../redux/insightAction';

function ExportMetricDrawer() {
  const dispatch = useDispatch();
  const { metric, sidePanelList, locationListDropdown } = useSelector(
    ({ insight }) => insight ?? {}
  );

  const { exportMetricDataActionLoader } = useSelector(({ common }) => common.generalLoader ?? {});

  const { isOpen, startDate, endDate, locationId, areaId, statisticData, metricId, errors } =
    useMemo(() => metric?.exportMetricDrawer, [metric?.exportMetricDrawer]);

  const handleClose = () => {
    dispatch(resetExportMetricDrawer());
  };

  const handleFieldChange = (fieldName, value) => {
    dispatch(updateExportMetricDrawer({ fieldName, value }));
  };

  const handleSubmit = () => {
    const params = {
      startDate: moment.tz(startDate, 'UTC').toISOString(),
      endDate: moment.tz(endDate, 'UTC').toISOString(),
      metricId: metricId?.id,
      locationId: locationId?.id,
      areaId: areaId?.id,
    };
    if (statisticData?.includes('Actuals')) {
      params.isActuals = true;
    }
    if (statisticData?.includes('Manager forecast')) {
      params.isManagerForecast = true;
    }
    if (statisticData?.includes('Payroll forecast')) {
      params.isPayrollForecast = true;
    }

    const error = validateExportMetric({ showArea: metricId?.showArea, statisticData, ...params });

    handleFieldChange('errors', error);

    if (isEmpty(error)) {
      dispatch(
        exportMetricDataAction(params, () => {
          handleClose();
        })
      );
    }
  };

  return (
    <EzyDrawer
      isOpen={isOpen}
      onClose={handleClose}
      heading="Download Metric Data"
      actionButtons={[
        { label: 'Download', onClick: handleSubmit, loading: exportMetricDataActionLoader },
      ]}
    >
      <div className="export-metric-drawer">
        <div className="two-grid">
          <EzyDate
            name="startDate"
            label="From"
            placeholder="Select from date"
            value={startDate}
            onChange={(value) => handleFieldChange('startDate', value)}
            error={errors?.startDate}
            timeZone="UTC"
            required
          />
          <EzyDate
            name="endDate"
            label="To"
            placeholder="Select to date"
            value={endDate}
            onChange={(value) => handleFieldChange('endDate', value)}
            error={errors?.endDate}
            timeZone="UTC"
            required
          />
        </div>

        <div className="info-div primary-bg word-break">
          Selecting a date range longer than 7 days will make your download longer to process.
        </div>

        <EzyAutoComplete
          name="metric"
          label="Metric"
          placeholder="Select metric"
          options={sidePanelList?.metricList?.filter((e) => e?.id)}
          value={metricId}
          onChange={(e, value) => handleFieldChange('metricId', value)}
          error={errors?.metricId}
          required
        />

        <EzyAutoComplete
          name="location"
          label="Location"
          placeholder="Select location"
          options={locationListDropdown?.filter((e) => e?.id !== 'all')}
          value={locationId}
          onChange={(e, value) => handleFieldChange('locationId', value)}
          error={errors?.locationId}
          required
        />

        {metricId?.showArea && (
          <EzyAutoComplete
            label="Area"
            name="area"
            placeholder="Select area"
            options={locationId?.areaList}
            value={areaId}
            onChange={(e, value) => handleFieldChange('areaId', value)}
            error={errors?.areaId}
            required
          />
        )}

        <EzyAutoComplete
          label="Statistic data"
          name="statisticData"
          placeholder="Select statistic data"
          options={['Actuals', 'Manager forecast', 'Payroll forecast']}
          value={statisticData}
          className={`${statisticData?.length ? 'auto-complete-tags' : ''}`}
          renderTags={(value, getTagProps) =>
            value.map((option, chipIndex) => (
              <Chip
                variant="outlined"
                color="primary"
                label={option}
                deleteIcon={<span className="material-icons">highlight_off</span>}
                {...getTagProps({ chipIndex })}
              />
            ))
          }
          onChange={(e, value) => handleFieldChange('statisticData', value)}
          multiple
          error={errors?.statisticData}
          required
        />
      </div>
    </EzyDrawer>
  );
}

export default ExportMetricDrawer;
