import PropTypes from 'prop-types';
import EzyDialog from '../dialog/EzyDialog';
import EzyDialogAction from '../dialog/EzyDialogAction';
import EzyDialogTitle from '../dialog/EzyDialogTitle';

function EzyWarningModal({ open, handleClose, warningMessage, title }) {
  return (
    <EzyDialog
      className="common-dialog-with-body delete-account-warning-modal"
      open={open}
      handleClose={handleClose}
    >
      <EzyDialogTitle title={title} isCloseIcon onClose={handleClose} />
      <div className="dialog-body">{warningMessage}</div>{' '}
      <EzyDialogAction actionButtons={[{ label: 'Ok', onClick: handleClose }]} />
    </EzyDialog>
  );
}
export default EzyWarningModal;
EzyWarningModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  warningMessage: PropTypes.string,
  title: PropTypes.string,
};

EzyWarningModal.defaultProps = {
  open: false,
  warningMessage: '',
  title: '',
};
