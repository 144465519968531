import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { getAuthTokenFromLocalStorage } from '../../utils/localStorageHelper';
import EzyNavBar from '../common/navBar/EzyNavBar';
import ROUTE_CONSTANTS, { AOSettingRoutes, AppIntegrationRoutes } from '../../utils/constants';
import Loader from '../common/loader/Loader';
import InfoModal from '../infoModal/InfoModal';
import devices from '../../assets/images/Devices.svg';

function Layout(props) {
  const isMobileScreen = useMediaQuery('(max-width: 1023px)');
  const [isResponsiveWarn, setIsResponsiveWarn] = useState(true);
  const { children } = props;
  const { pathname } = useLocation();
  const { businessId } = useSelector(({ user }) => user?.profileData || {});
  const { getCurrentUserDataLoader, verifyEmailFromLinkActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const authStatus = getAuthTokenFromLocalStorage();

  const isRegisterBusiness = pathname === ROUTE_CONSTANTS.REGISTER_BUSINESS;
  const isGlobalProfile = pathname === ROUTE_CONSTANTS.GLOBAL_PROFILE;
  const isSetPassword = pathname === ROUTE_CONSTANTS.SET_PASSWORD;
  const isAccountOverview =
    pathname === ROUTE_CONSTANTS.ACCOUNT_OVERVIEW ||
    AOSettingRoutes?.includes(pathname?.substring(0, pathname.lastIndexOf('/'))) ||
    pathname.includes(ROUTE_CONSTANTS.ACCOUNT_OVERVIEW);

  const isAppIntegration =
    pathname === ROUTE_CONSTANTS.APP_INTEGRATION ||
    AppIntegrationRoutes?.includes(pathname?.substring(0, pathname.lastIndexOf('/'))) ||
    pathname.includes(ROUTE_CONSTANTS.APP_INTEGRATION);

  const isPrintTimesheet = pathname === ROUTE_CONSTANTS.PRINT_EXPORT_TIMESHEETS;

  const isFromDocuments =
    pathname === ROUTE_CONSTANTS.PRIVACY_POLICY || pathname === ROUTE_CONSTANTS.TERMS_AND_CONDITION;

  if (!authStatus || isFromDocuments) {
    return <div className="panel-auth-layout">{children}</div>;
  }

  if (authStatus && isRegisterBusiness) {
    return <div className="panel-layout image-bg">{children}</div>;
  }

  if (authStatus && (isGlobalProfile || isAccountOverview || isAppIntegration)) {
    return <div className="panel-layout">{children}</div>;
  }

  if (authStatus && isSetPassword) {
    return <div className="panel-auth-layout">{children}</div>;
  }

  if (authStatus && isPrintTimesheet) {
    return children;
  }

  return (
    <div className="panel-layout">
      {(getCurrentUserDataLoader || verifyEmailFromLinkActionLoader) && <Loader />}
      <EzyNavBar />
      <div className="panel-child-wrapper">{businessId && children}</div>
      <InfoModal
        img={devices}
        subTitle="For better experience use desktop or mobile application."
        title=""
        action={
          <div className="subtitle-text ">
            {`Click here to download for `}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span
              onClick={() => window.open('https://play.google.com/', '_newtab')}
              className="secondary-action-text"
            >
              Android
            </span>
            ,&nbsp;
            <span
              onClick={() => window.open('https://apps.apple.com/', '_newtab')}
              className="secondary-action-text"
            >
              IPhone
            </span>
          </div>
        }
        onClose={() => setIsResponsiveWarn(false)}
        open={isMobileScreen && isResponsiveWarn}
      />
    </div>
  );
}

export default Layout;

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};
