import ROUTE_CONSTANTS from '../utils/constants';
import Home from '../pages/home/Home';
import Login from '../pages/auth/Login';
import Signup from '../pages/auth/Signup';
import ForgotPassword from '../pages/auth/ForgotPassword';
import ResetPassword from '../pages/auth/ResetPassword';
import RegisterNewBusiness from '../pages/business/RegisterNewBusiness';
import LocationList from '../pages/location/locationList/LocationList';
import AddEditLocation from '../pages/location/components/AddEditLocation';
import PeopleList from '../pages/people/peopleList/PeopleList';
import AddEditPeople from '../pages/people/components/AddEditPeople';
import BusinessSettings from '../pages/business/settings/BusinessSettings';
import TimeSheetsList from '../pages/timeSheets/timeSheetsList/TimeSheetsList';
import ScheduleList from '../pages/schedule/scheduleList/ScheduleList';
import NoPageFound from '../components/common/actionPages/NoPageFound';
import ForbiddenAccess from '../components/common/actionPages/ForbiddenAccess';
import InviteSignup from '../pages/auth/InviteSignup';
import ProfileSettings from '../pages/globalProfile/components/profileSettings/ProfileSettings';
import AccountOverview from '../pages/globalProfile/components/accountOverview/AccountOverview';
import VerifyEmail from '../pages/auth/VerifyEmail';
import AOBusinessSettings from '../pages/globalProfile/components/aoBusinessSettings/AOBusinessSettings';
import PaymentStatus from '../pages/globalProfile/components/aoBusinessSettings/PaymentStatus';
import DownloadInvoice from '../pages/globalProfile/components/aoBusinessSettings/DownloadInvoice';
import AppIntegration from '../pages/globalProfile/components/appIntegration/AppIntegration';
import AppIntegrationSettings from '../pages/globalProfile/components/appIntegration/AppIntegrationSettings';
import LogbookList from '../pages/logBook/logbookList/LogbookList';
import NewsFeedList from '../pages/newsFeed/newsFeedList/NewsFeedList';
import TasksList from '../pages/tasks/tasksList/TasksList';
import ExportTimeSheetsList from '../pages/exportTimesheet/exportTimeSheetsList/ExportTimeSheetsList';
import TimesheetsPrint from '../pages/exportTimesheet/components/TimesheetsPrint';
import Insight from '../pages/insights/Insight';
import PrivacyPolicy from '../pages/auth/PrivacyPolicy';
import TermsAndConditions from '../pages/auth/TermsAndConditions';
import EditMetric from '../pages/insights/components/metric/EditMetric';
import EditStaff from '../pages/insights/components/staff/EditStaff';

const EZY_ROUTES = [
  {
    path: '/',
    authenticated: true,
  },
  {
    path: ROUTE_CONSTANTS.HOME,
    component: Home,
    authenticated: true,
    access: 'LISTDASHCAL',
  },
  {
    path: ROUTE_CONSTANTS.HOME_WITH_ACTION,
    component: Home,
    authenticated: true,
    access: 'LISTDASHCAL',
  },
  {
    path: ROUTE_CONSTANTS.PAYMENT_STATUS,
    component: PaymentStatus,
    authenticated: true,
  },
  {
    path: ROUTE_CONSTANTS.LOGIN,
    component: Login,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS.SIGNUP,
    component: Signup,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS.FORGOT_PASSWORD,
    component: ForgotPassword,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS.SET_PASSWORD,
    component: ResetPassword,
    isEscapeRedirect: true,
  },
  {
    path: ROUTE_CONSTANTS.INVITE,
    component: InviteSignup,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS.REGISTER_BUSINESS,
    component: RegisterNewBusiness,
    authenticated: true,
    access: 'CREATEBUS',
  },
  {
    path: ROUTE_CONSTANTS.LOCATIONS,
    component: LocationList,
    authenticated: true,
    access: 'LISTLOC',
  },
  {
    path: ROUTE_CONSTANTS.PEOPLE,
    component: PeopleList,
    authenticated: true,
    access: 'LISTPPL',
  },
  {
    path: ROUTE_CONSTANTS.ADD_LOCATION,
    component: AddEditLocation,
    authenticated: true,
    access: 'CREATELOC',
  },
  {
    path: ROUTE_CONSTANTS.Edit_LOCATION,
    component: AddEditLocation,
    authenticated: true,
    access: 'EDITLOC',
  },
  {
    path: ROUTE_CONSTANTS.Edit_PEOPLE,
    component: AddEditPeople,
    authenticated: true,
    access: 'VIEWPPL',
  },
  {
    path: ROUTE_CONSTANTS.ADD_PEOPLE,
    component: AddEditPeople,
    authenticated: true,
    access: 'CREATEPPL',
  },
  {
    path: ROUTE_CONSTANTS.BUSINESS_SETTINGS,
    component: BusinessSettings,
    authenticated: true,
    access: 'EDITBUS',
  },
  {
    path: ROUTE_CONSTANTS.TIMESHEETS,
    component: TimeSheetsList,
    authenticated: true,
    access: 'LISTTS',
  },
  {
    path: ROUTE_CONSTANTS.SCHEDULE,
    component: ScheduleList,
    authenticated: true,
    access: 'LISTEMPSCHED',
  },
  {
    path: ROUTE_CONSTANTS.FORBIDDEN,
    component: ForbiddenAccess,
    isEscapeRedirect: true,
  },
  {
    path: ROUTE_CONSTANTS.GLOBAL_PROFILE,
    component: ProfileSettings,
    authenticated: true,
  },
  {
    path: ROUTE_CONSTANTS.ACCOUNT_OVERVIEW,
    component: AccountOverview,
    authenticated: true,
  },
  {
    path: ROUTE_CONSTANTS.ACCOUNT_OVERVIEW_BUSINESS_SETTINGS,
    component: AOBusinessSettings,
    authenticated: true,
  },
  {
    path: ROUTE_CONSTANTS.VERIFY_EMAIL,
    component: VerifyEmail,
    isEscapeRedirect: true,
  },
  {
    path: ROUTE_CONSTANTS.DOWNLOAD_INVOICE,
    component: DownloadInvoice,
    isEscapeRedirect: true,
  },
  {
    path: ROUTE_CONSTANTS.APP_INTEGRATION,
    component: AppIntegration,
    authenticated: true,
  },
  {
    path: ROUTE_CONSTANTS.APP_INTEGRATION_SETTINGS,
    component: AppIntegrationSettings,
    authenticated: true,
  },
  {
    path: ROUTE_CONSTANTS.NEWSFEED,
    component: NewsFeedList,
    authenticated: true,
  },
  {
    path: ROUTE_CONSTANTS.LOGBOOK,
    component: LogbookList,
    authenticated: true,
  },
  {
    path: ROUTE_CONSTANTS.TASKS,
    component: TasksList,
    authenticated: true,
  },
  {
    path: ROUTE_CONSTANTS.EXPORT_TIMESHEETS,
    component: ExportTimeSheetsList,
    authenticated: true,
  },
  {
    path: ROUTE_CONSTANTS.PRINT_EXPORT_TIMESHEETS,
    component: TimesheetsPrint,
    authenticated: true,
  },
  {
    path: ROUTE_CONSTANTS.PRIVACY_POLICY,
    component: PrivacyPolicy,
    isEscapeRedirect: true,
  },
  {
    path: ROUTE_CONSTANTS.TERMS_AND_CONDITION,
    component: TermsAndConditions,
    isEscapeRedirect: true,
  },
  {
    path: ROUTE_CONSTANTS.INSIGHT,
    component: Insight,
    authenticated: true,
  },
  {
    path: ROUTE_CONSTANTS.STAFF_EDIT,
    component: EditStaff,
    authenticated: true,
  },
  {
    path: ROUTE_CONSTANTS.METRIC_EDIT,
    component: EditMetric,
    authenticated: true,
  },
  {
    path: '*',
    component: NoPageFound,
    isEscapeRedirect: true,
  },
];

export default EZY_ROUTES;
