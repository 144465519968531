import { isNil, omitBy } from 'lodash';
import moment from 'moment/moment';
import { requireValidate } from '../../../utils/validationHelper';

const validateLeaveRequest = (data) => {
  const {
    toDate,
    fromDate,
    reason,
    businessLeaveRuleId,
    isFromProfile,
    managerComment,
    isAllDay,
    fromTime,
    toTime,
    shiftLength,
    shiftType,
  } = data ?? {};
  const error = {};
  error.toDate = requireValidate('To date', toDate);
  error.fromDate = requireValidate('From date', fromDate);
  if (toDate && fromDate && !moment(toDate).isSameOrAfter(fromDate)) {
    error.toDate = `To date can't be smaller then from date`;
  }
  if (isFromProfile) {
    error.reason = requireValidate('Note', reason);
  } else {
    error.managerComment = requireValidate('Note', managerComment);
  }
  if (!isAllDay) {
    error.toTime = requireValidate('To time', toTime);
    error.fromTime = requireValidate('From time', fromTime);
  }
  error.businessLeaveRuleId = requireValidate('Leave type', businessLeaveRuleId);
  if (shiftLength > 0) {
    error.shiftType = requireValidate('Shift type', shiftType);
  }
  return omitBy(error, isNil);
};

export default validateLeaveRequest;
