import { Dialog } from '@mui/material';
import PropTypes from 'prop-types';

function EzyDialog({ open, handleClose, className, children, ...rest }) {
  return (
    <Dialog className={`dialog ${className}`} onClose={handleClose} open={open} {...rest}>
      {children}
    </Dialog>
  );
}

export default EzyDialog;

EzyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
};

EzyDialog.defaultProps = {
  className: '',
};
