import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();
export const successNotification = (message) => {
  toast(message, {
    type: 'success',
    position: 'bottom-left',
    autoClose: 3000,
    progress: undefined,
    closeButton: false,
    className: 'toast-background',
    bodyClassName: 'toast-body',
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    theme: 'light',
    // toastId: `success${message}`,
  });
  return false;
};

export const errorNotification = (message) => {
  toast(message, {
    type: 'error',
    position: 'bottom-left',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    closeButton: false,
    className: 'toast-background',
    bodyClassName: 'toast-body',
    // toastId: `error${message}`,
  });
  return false;
};

export const warningNotification = (message) => {
  toast(message, {
    type: 'warning',
    position: 'bottom-left',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    closeButton: false,
    className: 'toast-background',
    bodyClassName: 'toast-body',
    // toastId: `warning${message}`,
  });
  return false;
};
