import IconByName from '../../../components/common/iconByName/IconByName';
import { dayCheck, displayDate, displayDateTime, displayTime } from '../../../utils/timeHelper';

export const renderDashboardCardContent = (cardFor, data, onClick) => {
  switch (cardFor) {
    case 'UNFILLED_OPEN_SHIFT':
    case 'UNPUBLISHED_SHIFT':
    case 'TIMESHEET':
    case 'OPEN_SHIFTS':
    case 'RUNNING_LATE':
      return data?.map((e, index) => (
        <div
          className={`card-info ${typeof onClick === 'function' ? 'card-hover' : ''}`}
          key={index}
          onClick={() => onClick(e)}
        >
          <div className="title">{e?.areaName}</div>
          <div className="description">
            <div className="d-flex flex-align-center just-bet text-secondary-color gap-5">
              <div className="d-flex flex-align-center text-secondary-color gap-5 w-50">
                {(e?.preferredFullName || e?.personCount) && (
                  <>
                    <IconByName name={e?.personCount ? 'pin' : 'person'} />
                    <span className="word-break">
                      {e?.preferredFullName ?? `${e?.personCount} request`}
                    </span>
                  </>
                )}
              </div>

              <div className="d-flex flex-align-start text-secondary-color gap-5 w-50">
                <IconByName name="calendar_month" />
                <span>{displayDate(e?.startTime, e?.timeZoneId?.label)}</span>
              </div>
            </div>
            <div className="d-flex flex-align-center just-bet text-secondary-color gap-5">
              <div className="d-flex flex-align-center text-secondary-color gap-5 w-50">
                <IconByName name="place" />
                <span>{e?.locationName}</span>
              </div>
              <div className="d-flex flex-align-start text-secondary-color gap-5 w-50">
                <IconByName name="watch_later" />
                <span>
                  {displayTime(e?.startTime, e?.timeZoneId?.label)} -{' '}
                  {displayTime(e?.endTime, e?.timeZoneId?.label)}
                </span>
              </div>
            </div>
          </div>
        </div>
      ));
    case 'OFFER_SHIFT':
      return data?.map((e, index) => (
        <div
          className={`card-info ${typeof onClick === 'function' ? 'card-hover' : ''}`}
          key={index}
          onClick={() => onClick(e)}
        >
          <div className="title">
            {displayDate(e?.startTime, e?.timeZoneId?.label)}|
            {displayTime(e?.startTime, e?.timeZoneId?.label)} -{' '}
            {displayTime(e?.endTime, e?.timeZoneId?.label)}
          </div>
          <div className="description">
            <div className="d-flex flex-align-center text-secondary-color gap-5">
              <IconByName name="place" />
              <span>
                {e?.areaName},&nbsp;{e?.locationName}
              </span>
            </div>
            <div className="d-flex flex-align-center just-bet text-secondary-color gap-5">
              <div className="d-flex flex-align-center text-secondary-color gap-5">
                <IconByName name="person" />
                <span className="word-break">{e?.preferredFullName}</span>
              </div>
              <IconByName name="arrow_circle_right" />
              <div className="d-flex flex-align-center text-secondary-color gap-5">
                <IconByName name="person" />
                <span
                  className="word-break"
                  title={e?.toPersonDetails?.map((person) => person?.preferredFullName)?.join(', ')}
                >
                  {e?.toPersonDetails?.[0]?.preferredFullName}&nbsp;
                  {/* eslint-disable-next-line no-unsafe-optional-chaining */}
                  {e?.toPersonDetails?.length > 1 && `+ ${e?.toPersonDetails?.length - 1}`}
                </span>
              </div>
            </div>
          </div>
        </div>
      ));
    case 'LEAVE_REQUESTS':
    case 'PEOPLE_ON_LEAVE':
      return data?.map((e, index) => (
        <div
          className={`card-info ${typeof onClick === 'function' ? 'card-hover' : ''}`}
          key={index}
          onClick={() => onClick(e)}
        >
          <div className="title">{e?.preferredFullName}</div>
          <div className="description">
            <div className="d-flex flex-align-center text-secondary-color gap-5">
              <IconByName name="label" />
              <span>{e?.businessLeaveRuleName}</span>
            </div>
            <div className="d-flex flex-align-start text-secondary-color gap-5">
              <IconByName name="calendar_month" />
              <span>
                {e?.isAllDay
                  ? `${displayDate(e?.fromDate, e?.timeZoneId?.label)} - ${displayDate(
                      e?.toDate,
                      e?.timeZoneId?.label
                    )} | All Day`
                  : `${displayDateTime(e?.fromDate, e?.timeZoneId?.label)} - ${displayDateTime(
                      e?.toDate,
                      e?.timeZoneId?.label
                    )}`}
              </span>
            </div>
          </div>
        </div>
      ));
    case 'BIRTHDAYS':
    case 'WORK_ANNIVERSARIES':
      return data?.map((e, index) => (
        <div
          className={`card-info ${typeof onClick === 'function' ? 'card-hover' : ''}`}
          key={index}
          onClick={() => onClick(e)}
        >
          <div className="title">{e?.preferredFullName}</div>
          <div className="description">
            <div className="d-flex flex-align-start text-secondary-color gap-5">
              <IconByName name={cardFor === 'BIRTHDAYS' ? 'cake' : 'celebration'} />
              <span>
                {cardFor === 'BIRTHDAYS'
                  ? `${dayCheck(e?.birthDate)} | ${displayDate(e?.birthDate, 'UTC')}`
                  : `${dayCheck(e?.workAnniversaryDate)} | ${displayDate(
                      e?.workAnniversaryDate,
                      'UTC'
                    )}`}
              </span>
            </div>
          </div>
        </div>
      ));
    case 'SWAP_SHIFTS':
      return data?.map((e, index) => (
        <div
          className={`card-info ${typeof onClick === 'function' ? 'card-hover' : ''}`}
          key={index}
          onClick={() => onClick(e)}
        >
          <div className="description">
            <div className="d-flex flex-align-center just-bet text-secondary-color gap-5">
              <div className="d-flex-column flex-align-start gap-5">
                <div className="d-flex flex-align-start text-secondary-color gap-5">
                  <IconByName name="place" />
                  <span>{e?.fromPersonDetails?.areaName}</span>,&nbsp;
                  <span>{e?.fromPersonDetails?.locationName}</span>
                </div>
                <div className="d-flex flex-align-start text-secondary-color gap-5">
                  <IconByName name="person" />
                  <span className="word-break">{e?.fromPersonDetails?.preferredFullName}</span>
                </div>
                <div className="d-flex flex-align-start text-secondary-color gap-5">
                  <IconByName name="calendar_month" />
                  <span>
                    {displayDate(e?.fromPersonDetails?.shiftStartTime, e?.timeZoneId?.label)}
                  </span>
                </div>
                <div className="d-flex flex-align-start text-secondary-color gap-5">
                  <IconByName name="watch_later" />
                  <span>
                    {displayTime(e?.fromPersonDetails?.shiftStartTime, e?.timeZoneId?.label)}
                    &nbsp;-&nbsp;
                    {displayTime(e?.fromPersonDetails?.shiftEndTime, e?.timeZoneId?.label)}
                  </span>
                </div>
              </div>
              <IconByName name="swap_horizontal_circle" />

              <div className="d-flex-column flex-align-start gap-5">
                <div className="d-flex flex-align-start text-secondary-color gap-5">
                  <IconByName name="place" />
                  <span>{e?.toPersonDetails?.areaName}</span>,&nbsp;
                  <span>{e?.toPersonDetails?.locationName}</span>
                </div>
                <div className="d-flex flex-align-start text-secondary-color gap-5">
                  <IconByName name="person" />
                  <span className="word-break">{e?.toPersonDetails?.preferredFullName}</span>
                </div>
                <div className="d-flex flex-align-start text-secondary-color gap-5">
                  <IconByName name="calendar_month" />
                  <span>
                    {displayDate(e?.toPersonDetails?.shiftStartTime, e?.timeZoneId?.label)}
                  </span>
                </div>
                <div className="d-flex flex-align-start text-secondary-color gap-5">
                  <IconByName name="watch_later" />
                  <span>
                    {displayTime(e?.toPersonDetails?.shiftStartTime, e?.timeZoneId?.label)}
                    &nbsp;-&nbsp;
                    {displayTime(e?.toPersonDetails?.shiftEndTime, e?.timeZoneId?.label)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
    case 'INCOMPLETE_TASK':
      return data?.map((e, index) => (
        <div
          className={`card-info ${typeof onClick === 'function' ? 'card-hover' : ''}`}
          key={index}
          onClick={() => onClick(e)}
        >
          <div className="description">
            <div className="d-flex flex-align-center text-secondary-color gap-5">
              <IconByName name="place" />
              <span>
                {e?.area} - {e?.location}
              </span>
            </div>
            <div className="d-flex flex-align-start text-secondary-color gap-5">
              <IconByName name="task" />
              <span>
                {e?.taskTitle} - {e?.completionCount} / {e?.totalCount} completed
              </span>
            </div>
          </div>
        </div>
      ));
    case 'UNAVAILABILITY':
      return data?.map((e, index) => (
        <div
          className={`card-info ${typeof onClick === 'function' ? 'card-hover' : ''}`}
          key={index}
          onClick={() => onClick(e)}
        >
          <div className="description">
            <div className="d-flex flex-align-center text-secondary-color gap-5">
              <IconByName name="person" />
              <span>{e?.personName}</span>
            </div>
            <div className="d-flex flex-align-start text-secondary-color gap-5">
              <IconByName name="calendar_month" />
              <span>
                {displayDate(e?.fromDate, e?.timeZoneId?.label)}{' '}
                {e?.repeatitionFrequency &&
                  (e?.repeatitionFrequency === 4 ? '(repeat monthly)' : '(repeat weekly)')}
              </span>
            </div>
          </div>
        </div>
      ));
    default:
      return null;
  }
};
