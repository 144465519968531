import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EzyAccordionDetails from '../../../../components/common/accordion/EzyAccordinDetails';
import EzyAccordion from '../../../../components/common/accordion/EzyAccordion';
import EzyAccordionSummary from '../../../../components/common/accordion/EzyAccordionSummary';
import EzyAccordionWrapper from '../../../../components/common/accordion/EzyAccordionWrapper';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import IconByName from '../../../../components/common/iconByName/IconByName';
import useQueryParams from '../../../../hooks/useQueryParams';
import { mapIdsFromList } from '../../../../utils/enumMapping';
import { displayDate, displayTime } from '../../../../utils/timeHelper';
import {
  getDashboardShiftsAction,
  withdrawRequestForOpenShiftAction,
} from '../../redux/dashboardActions';
import { updateCalenderData } from '../../redux/dashboardSlice';

function ShiftDetailsDrawer() {
  const dispatch = useDispatch();
  const { personId, businessId, timeZone } = useSelector(({ user }) => user?.profileData ?? {});
  const { displayShiftDrawer: shiftData } = useSelector(
    ({ dashboard }) => dashboard?.calenderData ?? {}
  );
  const { enums } = useSelector(({ common }) => common ?? {});

  const { date: dateParam } = useQueryParams();

  const handleClose = () => {
    dispatch(updateCalenderData({ fieldName: 'displayShiftDrawer', value: {} }));
  };

  const withdrawRequest = () => {
    const params = { shiftId: shiftData?.shiftId, personDetailId: personId };

    dispatch(
      withdrawRequestForOpenShiftAction(params, () => {
        handleClose();
        dispatch(
          getDashboardShiftsAction({
            personDetailId: personId,
            businessId,
            filterStartDate: moment
              .tz(dateParam, timeZone?.label ?? moment.tz?.guess())
              .startOf('week')
              ?.toISOString(),
            filterEndDate: moment
              .tz(dateParam, timeZone?.label ?? moment.tz?.guess())
              .endOf('week')
              ?.toISOString(),
            ...params,
          })
        );
      })
    );
  };

  const actionButton = [{ label: 'Withdraw', color: 'secondary', onClick: withdrawRequest }];

  return (
    <EzyDrawer
      isOpen={shiftData?.shiftId || shiftData?.id}
      onClose={handleClose}
      heading={displayDate(shiftData?.startTime, shiftData?.timeZoneId?.label)}
      actionButtons={
        shiftData?.title === 'Open' && shiftData?.actionButton === 'Pending Approval'
          ? actionButton
          : []
      }
    >
      <div className="shift-drawer">
        <EzyAccordionWrapper className="shift-details-accordion">
          <EzyAccordion expanded>
            <EzyAccordionSummary className="accordion-summary icon-small" expandIcon={null}>
              {shiftData?.preferredName || shiftData?.preferredFullName}
            </EzyAccordionSummary>
            <EzyAccordionDetails>
              <div className="shift-details-drawer-field">
                <div className="d-flex">
                  <span className="flex-center gap-5">
                    <IconByName name="event" />
                    <span>{displayDate(shiftData?.startTime, shiftData?.timeZoneId?.label)}</span>
                  </span>
                </div>

                <div className="d-flex">
                  <span className="flex-center gap-5">
                    <IconByName name="location_on" />
                    <span>
                      {shiftData?.areaName} - {shiftData?.locationName}
                    </span>
                  </span>
                </div>

                <div className="d-flex">
                  <span className="flex-center gap-5">
                    <IconByName name="access_time_filled" />
                    <span>
                      {displayTime(shiftData?.startTime, shiftData?.timeZoneId?.label)} -{' '}
                      {displayTime(shiftData?.endTime, shiftData?.timeZoneId?.label)}
                    </span>
                  </span>
                </div>

                {shiftData?.shiftBreakDetailsList?.length !== 0 &&
                  shiftData?.shiftBreakDetailsList?.length !== undefined && (
                    <div className="d-flex">
                      <span className="d-flex gap-5 ">
                        <IconByName name="emoji_food_beverage" />
                        <div className="d-flex-column">
                          {shiftData?.shiftBreakDetailsList?.map((data) => {
                            const breakDetails = mapIdsFromList(data?.breakType, enums?.breakType);
                            return (
                              <span>
                                {breakDetails?.label}
                                {data?.breakPaidUnpaid
                                  ? ` (  ${data?.breakPaidUnpaid} ) `
                                  : ''} :{' '}
                                {data?.startTime
                                  ? `${displayTime(data?.startTime, shiftData?.timeZoneId?.label)} -
                                ${displayTime(data?.endTime, shiftData?.timeZoneId?.label)}`
                                  : ''}
                                {` (  ${data?.duration} mins  ) `}
                              </span>
                            );
                          })}
                        </div>
                      </span>
                    </div>
                  )}

                {shiftData?.timeSheetBreakDetailEntities?.length !== 0 &&
                  shiftData?.timeSheetBreakDetailEntities?.length !== undefined && (
                    <div className="d-flex">
                      <span className="d-flex gap-5">
                        <IconByName name="emoji_food_beverage" />
                        <div className="d-flex-column">
                          {shiftData?.timeSheetBreakDetailEntities?.map((data) => {
                            const breakDetails = mapIdsFromList(data?.breakType, enums?.breakType);

                            return (
                              <span>
                                {breakDetails?.label} :{' '}
                                {data?.breakStartTime
                                  ? `${displayTime(
                                      data?.breakStartTime,
                                      shiftData?.timeZoneId?.label
                                    )} -
                                ${displayTime(data?.breakEndTime, shiftData?.timeZoneId?.label)}`
                                  : ''}
                                {` (  ${data?.duration} mins  ) `}
                              </span>
                            );
                          })}
                        </div>
                      </span>
                    </div>
                  )}
              </div>
            </EzyAccordionDetails>
          </EzyAccordion>
        </EzyAccordionWrapper>
      </div>
    </EzyDrawer>
  );
}

export default ShiftDetailsDrawer;
