import { createSlice } from '@reduxjs/toolkit';
import { isEmpty, omitBy } from 'lodash';
import { headers } from '../locationHeaders';

const locationSlice = createSlice({
  name: 'location',
  initialState: {
    locationList: {
      docs: [],
      headers,
      total: 1,
      limit: 15,
      page: 1,
      pages: 1,
      areas: [],
      people: [],
    },
    locationListEnum: [],
    addLocationData: {
      locationName: '',
      locationAddress: '',
      weekStartOn: '',
      timeZoneId: '',
      sendInvitationEmail: false,
      addAreaAddress: false,
      city: '',
      errors: {},
      areas: [
        {
          areaOfWork: '',
          address: undefined,
        },
      ],
      people: [
        {
          name: '',
          email: '',
          phoneNumber: null,
          countryCode: null,
        },
      ],
      importedPeople: [],
      warningMessage: '',
    },
    editLocationData: {
      locationName: '',
      locationAddress: '',
      locationCode: '',
      timeZoneId: '',
      errors: {},
      addAreaAddress: false,
      note: '',
      weekDay: [
        {
          day: '0',
          isActive: false,
          startTime: '',
          endTime: '',
        },
        {
          day: '1',
          isActive: false,
          startTime: '',
          endTime: '',
        },
        {
          day: '2',
          isActive: false,
          startTime: '',
          endTime: '',
        },
        {
          day: '3',
          isActive: false,
          startTime: '',
          endTime: '',
        },
        {
          day: '4',
          isActive: false,
          startTime: '',
          endTime: '',
        },
        {
          day: '5',
          isActive: false,
          startTime: '',
          endTime: '',
        },
        {
          day: '6',
          isActive: false,
          startTime: '',
          endTime: '',
        },
      ],
      areas: [
        {
          areaName: '',
          address: undefined,
        },
      ],
    },
    editDetailsLocationData: {
      basics: {
        weekStartsOn: '',
        defaultShiftDuration: '',
        defaultBreakDuration: '',
      },
      creatingAndPublishingShifts: {
        preventShiftChange: '',
        shiftNotificationRecipient: '',
        shiftRemovedNotification: '',
      },
      swapsAndOffers: {
        coWorkerScheduleVisibility: '',
        allowSwapShifts: false,
        isManagerApprovalRequiredToSwap: true,
        allowOfferShifts: false,
      },
      clockInAndOut: {
        isClockInOutViaWeb: false,
        isClockInOutViaMobile: false,
        allowGeoFencing: false,
        isPhotoRequiredInKiosk: false,
        minimumTimeSheetLengthInMinutes: '',
      },
      teamMemberPermissions: {
        isTeamMemberAllowedToEditTimeSheet: false,
        editTimeSheetDuringShifts: false,
        allowToStartShiftBeforeScheduledTime: false,
        earlyStartShiftBufferTime: undefined,
        earlyEndShiftBufferTime: '',
        allowToEndBreaksEarlier: false,
      },
      timesheetApproving: {
        autoApproveTimeSheetVariance: '',
        roundTimeSheets: true,
        isRoundToScheduledStartTime: false,
        startTimeRoundingType: '',
        isRoundToScheduledEndTime: false,
        endTimeRoundingType: '',
        isRoundToScheduledBreakForShorterDuration: false,
        breakTimeRoundingType: '',
      },
      UnscheduledShifts: {
        linkUnscheduledTimeSheetType: {
          label: 'Best Fit (default)',
          id: 1,
        },
        unscheduledShiftTimeVariance: '',
      },
      breaks: {
        isBreakSuggestionOn: false,
        isDisplayWarningForShorterBreaks: false,
        isBreakPaid: '',
      },
      errors: {},
    },
    copyLocation: {
      fromLocationId: '',
      toLocationIds: [],
      copyOperatingHours: true,
      copySchedulingSettings: true,
      copyTimeSheetSettings: true,
    },
    duplicateLocation: {
      parentLocationId: '',
      locationName: '',
      locationAddress: '',
      copyEmployees: true,
      copySettings: true,
      // copyPayroll: true,
      // copyShift: true,
    },
    archiveLocation: {
      isArchive: false,
      archiveDetails: {},
      newArchiveLocation: '',
    },
    deleteLocation: {
      newLocation: '',
    },
  },
  reducers: {
    setLocationList: (state, action) => {
      state.locationList = {
        ...state.locationList,
        docs: action.payload.data,
        total: action.payload.totalData,
        limit: action.payload.limit,
        page: action.payload.page,
        pages: action.payload.totalPages,
      };
    },
    setAddLocationList: (state, action) => {
      const { fieldName, value } = action.payload;
      state.addLocationData[fieldName] = value;
    },
    setEditGeneralLocationList: (state, action) => {
      const { fieldName, value } = action.payload;
      state.editLocationData[fieldName] = value;
    },
    setEditGeneralLocation: (state, action) => {
      state.editLocationData = {
        locationName: action.payload?.locationName,
        locationAddress: action.payload?.locationAddress,
        locationCode: action.payload?.locationCode,
        timeZoneId: action.payload?.timeZoneId,
        addAreaAddress: !!action.payload?.areas.map((res) =>
          res.areaAddress ? { areaAddress: res.areaAddress } : undefined
        )[0],
        weekDay: action.payload?.generalSettings?.operatingHours?.weekDay,
        note: action.payload?.generalSettings?.note,
        city: action.payload?.city,
        areas: isEmpty(action.payload?.areas)
          ? [
              {
                areaName: '',
                areaAddress: undefined,
              },
            ]
          : action.payload?.areas,
      };
    },
    setEditDetailsLocation: (state, action) => {
      const { schedulingSettings, timeSheetSettings } = action.payload;
      state.editDetailsLocationData = {
        basics: {
          weekStartsOn: schedulingSettings?.weekStartsOn,
          defaultShiftDuration: schedulingSettings?.defaultShiftDuration,
          defaultBreakDuration: schedulingSettings?.defaultBreakDuration,
        },
        creatingAndPublishingShifts: {
          preventShiftChange: schedulingSettings?.preventShiftChange,
          shiftNotificationRecipient: schedulingSettings?.shiftNotificationRecipient,
          shiftRemovedNotification: schedulingSettings?.shiftRemovedNotification,
        },
        swapsAndOffers: {
          coWorkerScheduleVisibility: schedulingSettings?.coWorkerScheduleVisibility,
          allowSwapShifts: schedulingSettings?.allowSwapShifts,
          isManagerApprovalRequiredToSwap: schedulingSettings?.isManagerApprovalRequiredToSwap,
          allowOfferShifts: schedulingSettings?.allowOfferShifts,
        },
        clockInAndOut: {
          isClockInOutViaWeb: timeSheetSettings?.isClockInOutViaWeb,
          isClockInOutViaMobile: timeSheetSettings?.isClockInOutViaMobile,
          allowGeoFencing: timeSheetSettings?.allowGeoFencing,
          isPhotoRequiredInKiosk: timeSheetSettings?.isPhotoRequiredInKiosk,
          minimumTimeSheetLengthInMinutes: timeSheetSettings?.minimumTimeSheetLengthInMinutes,
        },
        teamMemberPermissions: {
          isTeamMemberAllowedToEditTimeSheet: timeSheetSettings?.isTeamMemberAllowedToEditTimeSheet,
          editTimeSheetDuringShifts: timeSheetSettings?.editTimeSheetDuringShifts,
          allowToStartShiftBeforeScheduledTime:
            timeSheetSettings?.allowToStartShiftBeforeScheduledTime,
          earlyStartShiftBufferTime: timeSheetSettings?.earlyStartShiftBufferTime,
          earlyEndShiftBufferTime: timeSheetSettings?.earlyEndShiftBufferTime,
          allowToEndBreaksEarlier: timeSheetSettings?.allowToEndBreaksEarlier,
        },
        timesheetApproving: {
          autoApproveTimeSheetVariance: timeSheetSettings?.autoApproveTimeSheetVariance,
          roundTimeSheets: timeSheetSettings?.roundTimeSheets,
          isRoundToScheduledStartTime: timeSheetSettings?.isRoundToScheduledStartTime,
          startTimeRoundingType: timeSheetSettings?.startTimeRoundingType,
          isRoundToScheduledEndTime: timeSheetSettings?.isRoundToScheduledEndTime,
          endTimeRoundingType: timeSheetSettings?.endTimeRoundingType,
          isRoundToScheduledBreakForShorterDuration:
            timeSheetSettings?.isRoundToScheduledBreakForShorterDuration,
          breakTimeRoundingType: timeSheetSettings?.breakTimeRoundingType,
        },
        UnscheduledShifts: {
          linkUnscheduledTimeSheetType: timeSheetSettings?.linkUnscheduledTimeSheetType,
          unscheduledShiftTimeVariance: timeSheetSettings?.unscheduledShiftTimeVariance,
        },
        breaks: {
          isBreakSuggestionOn: timeSheetSettings?.isBreakSuggestionOn,
          isDisplayWarningForShorterBreaks: timeSheetSettings?.isDisplayWarningForShorterBreaks,
          isBreakPaid: timeSheetSettings?.isBreakPaid,
        },
        errors: {},
      };
    },
    addDeleteLocationPeople: (state, action) => {
      const { type, index } = action.payload;
      if (type === 'ADD') {
        state.addLocationData.people.push({
          name: '',
          email: '',
          phoneNumber: null,
        });
      }
      if (type === 'DELETE') {
        state.addLocationData.people.splice(index, 1);
      }
    },
    addDeleteLocationAreas: (state, action) => {
      const { type, index } = action.payload;
      if (type === 'ADD') {
        state.addLocationData.areas.push({
          areaOfWork: '',
          address: '',
        });
      }
      if (type === 'DELETE') {
        state.addLocationData.areas = state.addLocationData.areas.filter((e, i) => i !== index);
      }
    },
    editDeleteGeneralLocationAreas: (state, action) => {
      const { type, index } = action.payload;
      if (type === 'ADD') {
        state.editLocationData.areas.push({
          areaName: '',
          areaAddress: '',
        });
      }
      if (type === 'DELETE') {
        state.editLocationData.areas = state.editLocationData.areas.filter((e, i) => i !== index);
      }
    },
    updateLocationPeople: (state, action) => {
      const { index, fieldName, value } = action.payload;
      state.addLocationData.people = state.addLocationData.people.map((e, i) =>
        i === index ? { ...e, [fieldName]: value } : e
      );
    },
    updateLocationAreas: (state, action) => {
      const { index, fieldName, value } = action.payload;
      state.addLocationData.areas = state.addLocationData.areas.map((e, i) =>
        i === index ? { ...e, [fieldName]: value } : e
      );
    },
    updateEditGeneralLocationAreas: (state, action) => {
      const { index, fieldName, value } = action.payload;
      state.editLocationData.areas = state.editLocationData.areas.map((e, i) =>
        i === index ? { ...e, [fieldName]: value } : e
      );
    },
    updateEditGeneralLocationDays: (state, action) => {
      const { index, fieldName, value } = action.payload;
      state.editLocationData.weekDay = state.editLocationData.weekDay.map((e, i) =>
        i === index ? { ...e, [fieldName]: value } : e
      );
    },

    cleanAddressLocationAreas: (state) => {
      state.editLocationData.areas = state.editLocationData.areas.map((e) => ({
        ...e,
        areaAddress: '',
      }));
    },
    setEditDetailsLocationData: (state, action) => {
      const { sectionName, fieldName, fieldValue } = action.payload;
      state.editDetailsLocationData = {
        ...state.editDetailsLocationData,
        [sectionName]: { ...state.editDetailsLocationData?.[sectionName], [fieldName]: fieldValue },
      };
    },
    setEditDetailsLocationError: (state, action) => {
      state.editDetailsLocationData.errors = action.payload;
    },
    updateCopyLocationFields: (state, action) => {
      const { fieldName, value } = action.payload;
      state.copyLocation[fieldName] = value;
    },
    updateDuplicateLocationField: (state, action) => {
      const { fieldName, value } = action.payload;
      state.duplicateLocation[fieldName] = value;
    },
    setImportedPeopleData: (state, action) => {
      const data = action.payload.reduce(
        (acc, value) => {
          if (value.errorMessage) {
            acc.failed.push(value);
          } else acc.success.push(value);
          return acc;
        },
        { success: [], failed: [] }
      );
      state.addLocationData.importedPeople = action.payload;
      state.addLocationData.totalNoOfRecords = action.payload.length;
      state.addLocationData.successCount = data.success.length;
      state.addLocationData.failedCount = data.failed.length;
      state.addLocationData.failedRecords = data.failed;
      state.addLocationData.uploaderIndex = 1;
    },
    resetImportPeopleData: (state) => {
      state.addLocationData.importedPeople = [];
      state.addLocationData.totalNoOfRecords = 0;
      state.addLocationData.successCount = 0;
      state.addLocationData.failedCount = 0;
      state.addLocationData.failedRecords = [];
      state.addLocationData.uploaderIndex = 0;
      state.addLocationData.selectedUploader = '';
      state.addLocationData.fileName = '';
    },
    importPeopleToList: (state) => {
      const dataToUpdate = state.addLocationData.importedPeople
        .filter((e) => !e.errorMessage)
        .map((e) => ({
          name: e.name,
          email: e.email,
          phoneNumber: e.countryCode.includes('+')
            ? e.countryCode.split('+')[1] + e.phoneNumber
            : e.countryCode + e.phoneNumber,
          countryCode: e.countryCode.includes('+') ? e.countryCode.split('+')[1] : e.countryCode,
        }));
      state.addLocationData.people = isEmpty(omitBy(state.addLocationData.people[0], isEmpty))
        ? dataToUpdate
        : [...state.addLocationData.people, ...dataToUpdate];
      if (isEmpty(state.addLocationData.people)) {
        state.addLocationData.people = [
          {
            name: '',
            email: '',
            phoneNumber: null,
            countryCode: null,
          },
        ];
      }
    },
    updateArchiveLocationDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.archiveLocation[fieldName] = value;
    },
    setArchiveLocationDetails: (state, action) => {
      state.archiveLocation.archiveDetails = action.payload;
    },
    resetAddLocationDetail: (state) => {
      state.addLocationData.locationName = '';
      state.addLocationData.locationAddress = '';
      state.addLocationData.weekStartOn = '';
      state.addLocationData.sendInvitationEmail = false;
      state.addLocationData.addAreaAddress = false;
      state.addLocationData.timeZoneId = '';
      state.addLocationData.city = '';
      state.addLocationData.errors = {};
      state.addLocationData.areas = [
        {
          areaOfWork: '',
          address: undefined,
        },
      ];
      state.addLocationData.people = [
        {
          name: '',
          email: '',
          phoneNumber: null,
          countryCode: null,
        },
      ];
      state.addLocationData.importedPeople = [];
    },
    resetEditLocationData: (state) => {
      state.editLocationData.locationName = '';
      state.editLocationData.locationAddress = '';
      state.editLocationData.locationCode = '';
      state.editLocationData.errors = {};
      state.editLocationData.addAreaAddress = false;
      state.editLocationData.note = '';
      state.editLocationData.weekDay = [
        {
          day: '0',
          isActive: false,
          startTime: '',
          endTime: '',
        },
        {
          day: '1',
          isActive: false,
          startTime: '',
          endTime: '',
        },
        {
          day: '2',
          isActive: false,
          startTime: '',
          endTime: '',
        },
        {
          day: '3',
          isActive: false,
          startTime: '',
          endTime: '',
        },
        {
          day: '4',
          isActive: false,
          startTime: '',
          endTime: '',
        },
        {
          day: '5',
          isActive: false,
          startTime: '',
          endTime: '',
        },
        {
          day: '6',
          isActive: false,
          startTime: '',
          endTime: '',
        },
      ];
    },
    resetEditDetailsLocationData: (state) => {
      state.editDetailsLocationData.basics = {
        weekStartsOn: '',
        defaultShiftDuration: '',
        defaultBreakDuration: '',
      };
      state.editDetailsLocationData.creatingAndPublishingShifts = {
        preventShiftChange: '',
        shiftNotificationRecipient: '',
        shiftRemovedNotification: '',
      };
      state.editDetailsLocationData.swapsAndOffers = {
        coWorkerScheduleVisibility: '',
        allowSwapShifts: false,
        isManagerApprovalRequiredToSwap: true,
        allowOfferShifts: false,
      };
      state.editDetailsLocationData.clockInAndOut = {
        isClockInOutViaWeb: false,
        isClockInOutViaMobile: false,
        allowGeoFencing: false,
        isPhotoRequiredInKiosk: false,
        minimumTimeSheetLengthInMinutes: '',
      };
      state.editDetailsLocationData.teamMemberPermissions = {
        isTeamMemberAllowedToEditTimeSheet: false,
        editTimeSheetDuringShifts: false,
        allowToStartShiftBeforeScheduledTime: false,
        earlyStartShiftBufferTime: undefined,
        earlyEndShiftBufferTime: '',
        allowToEndBreaksEarlier: false,
      };
      state.editDetailsLocationData.timesheetApproving = {
        autoApproveTimeSheetVariance: '',
        roundTimeSheets: true,
        isRoundToScheduledStartTime: false,
        startTimeRoundingType: '',
        isRoundToScheduledEndTime: false,
        endTimeRoundingType: '',
        isRoundToScheduledBreakForShorterDuration: false,
        breakTimeRoundingType: '',
      };
      state.editDetailsLocationData.UnscheduledShifts = {
        linkUnscheduledTimeSheetType: {
          label: 'Best Fit (default)',
          id: 1,
        },
        unscheduledShiftTimeVariance: '',
      };
      state.editDetailsLocationData.breaks = {
        isBreakSuggestionOn: false,
        isDisplayWarningForShorterBreaks: false,
        isBreakPaid: '',
      };
      state.editDetailsLocationData.errors = {};
    },
    resetCopyLocationDetails: (state) => {
      state.copyLocation.fromLocationId = '';
      state.copyLocation.toLocationIds = [];
      state.copyLocation.copyOperatingHours = false;
      state.copyLocation.copySchedulingSettings = false;
      state.copyLocation.copyTimeSheetSettings = false;
    },
    resetDuplicateLocation: (state) => {
      state.duplicateLocation.parentLocationId = '';
      state.duplicateLocation.locationName = '';
      state.duplicateLocation.locationAddress = '';
      state.duplicateLocation.copyEmployees = false;
      state.duplicateLocation.copySettings = false;
      // state.duplicateLocation.copyPayroll = false;
      // state.duplicateLocation.copyShift = false;
    },
    resetArchiveLocationDetails: (state) => {
      state.archiveLocation.archiveDetails = {};
      state.archiveLocation.newArchiveLocation = '';
    },
    updateDeleteLocationDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.deleteLocation[fieldName] = value;
    },
    resetDeleteLocationDetails: (state) => {
      state.deleteLocation.newLocation = '';
    },
    setLocationListEnum: (state, action) => {
      state.locationListEnum = action.payload;
    },
    importPeopleFromIntegration: (state, action) => {
      state.addLocationData.people = action.payload.map((e) => {
        return {
          email: e?.email,
          phoneNumber: e?.phone,
          name: e?.name,
          xeroEmployeeId: e?.id,
          myobEmployeeId: e?.myobEmployeeId,
          quickBookEmployeeId: e?.quickBookEmployeeId,
        };
      });
    },
  },
});

const { reducer, actions } = locationSlice;

export const {
  setLocationList,
  setAddLocationList,
  addDeleteLocationPeople,
  addDeleteLocationAreas,
  setEditGeneralLocationList,
  updateEditGeneralLocationDays,
  editDeleteGeneralLocationAreas,
  setEditDetailsLocationData,
  updateLocationPeople,
  updateLocationAreas,
  updateEditGeneralLocationAreas,
  cleanAddressLocationAreas,
  updateCopyLocationFields,
  setEditDetailsLocationError,
  updateDuplicateLocationField,
  setImportedPeopleData,
  importPeopleToList,
  resetImportPeopleData,
  setEditGeneralLocation,
  setEditDetailsLocation,
  updateArchiveLocationDetails,
  setArchiveLocationDetails,
  resetAddLocationDetail,
  resetEditLocationData,
  resetEditDetailsLocationData,
  resetCopyLocationDetails,
  resetArchiveLocationDetails,
  resetDuplicateLocation,
  updateDeleteLocationDetails,
  resetDeleteLocationDetails,
  setLocationListEnum,
  importPeopleFromIntegration,
} = actions;

export default reducer;
