import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import { updateAppIntegrationData } from '../../redux/globalProfileSlice';
import IconByName from '../../../../components/common/iconByName/IconByName';
import { connectIntegrationAction, getLocationListAction } from '../../redux/globalProfileActions';

function AddLocationDrawer() {
  const dispatch = useDispatch();
  const { newLocationDrawer, locationList, specificIntegrationDetails } = useSelector(
    ({ globalProfile }) => globalProfile?.appIntegration || {}
  );
  const { getLocationListActionLoader, connectIntegrationActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );
  const { businessId } = useSelector(({ user }) => user?.profileData || {});
  const { id } = useParams();
  const [checked, setChecked] = useState(null);

  const handleCheck = (value) => {
    setChecked((e) => (e === value ? null : value));
  };

  const handleClose = () => {
    dispatch(updateAppIntegrationData({ fieldName: 'newLocationDrawer', value: false }));
  };

  const actionButton = [
    {
      label: 'Connect',
      onClick: () => {
        if (checked)
          dispatch(
            connectIntegrationAction({
              locationId: checked,
              integration:
                specificIntegrationDetails?.integrationEntity?.integrationName === 'Myob Business'
                  ? 'MyOb'
                  : specificIntegrationDetails?.integrationEntity?.integrationName,
            })
          );
      },
      disabled: !checked,
      loading: connectIntegrationActionLoader,
    },
  ];

  useEffect(() => {
    if (id && newLocationDrawer) dispatch(getLocationListAction({ integrationId: id, businessId }));
  }, [id, newLocationDrawer]);

  return (
    <EzyDrawer
      isOpen={newLocationDrawer}
      onClose={handleClose}
      heading={`Select a location to connect to ${specificIntegrationDetails?.integrationEntity?.integrationName}`}
      actionButtons={actionButton}
      isLoading={getLocationListActionLoader}
    >
      <div className="shift-replace-drawer">
        <div className="shift-replace-field-grid">
          <List>
            {locationList?.map((value) => {
              const labelId = `checkbox-list-label-${value?.locationId}`;
              return (
                <ListItem
                  disablePadding
                  selected={checked === value?.locationId || value?.alreadyConnected}
                >
                  <ListItemButton
                    role={undefined}
                    onClick={() => {
                      if (!value?.alreadyConnected) handleCheck(value?.locationId);
                    }}
                    dense
                    disabled={value?.alreadyConnected}
                    disableRipple
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked === value?.locationId || value?.alreadyConnected}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                        icon={<IconByName name="check_circle_outline" />}
                        checkedIcon={<IconByName name="check_circle" className="primary-color" />}
                      />
                    </ListItemIcon>
                    <ListItemText className="people-invite-list-view">
                      <span>{value?.locationName}</span>

                      {value?.alreadyConnected && (
                        <span className="primary-color">Already connected</span>
                      )}
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </div>
      </div>
    </EzyDrawer>
  );
}

export default AddLocationDrawer;
