import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { isEmpty, isNil, omitBy } from 'lodash';
import IconByName from '../../../components/common/iconByName/IconByName';
import Loader from '../../../components/common/loader/Loader';
import { getLocationDropDownListByBusinessId } from '../../location/redux/locationActions';
import { clearLogsList, updateLogListFilters } from '../redux/logbookSlice';
import EzyDatePicker from '../../../components/common/dateAndTimePicker/EzyDatePicker';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import EzyDate from '../../../components/common/dateAndTimePicker/EzyDate';
import EzyAutoComplete from '../../../components/common/autoComplete/EzyAutoComplete';
import EzyButton from '../../../components/common/button/EzyButton';
import { getPersonListByBusinessIdAction } from '../../timeSheets/redux/timeSheetsAction';
import { getLogsEntryByFiltersAction } from '../redux/logbookActions';
import { requireValidate } from '../../../utils/validationHelper';

function LogBookSidebar() {
  const dispatch = useDispatch();
  const { locationListEnum } = useSelector(({ location }) => location ?? []);
  const { personList } = useSelector(({ timeSheets }) => timeSheets?.timeSheetData ?? {});
  const { filters, logsList } = useSelector(({ logbook }) => logbook ?? {});
  // const { enums } = useSelector(({ common }) => common ?? {});

  const { getLocationsByBusinessIdLoader, getLogsEntryByFiltersActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const onChangeFilterFields = (fieldName, value) => {
    dispatch(updateLogListFilters({ fieldName, value }));
  };

  const logBookListViews = [
    {
      id: 1,
      onClick: () => {
        dispatch(clearLogsList());
        onChangeFilterFields('viewType', 'View');
      },
      label: 'View',
    },
    {
      id: 2,
      onClick: () => {
        dispatch(clearLogsList());
        onChangeFilterFields('viewType', 'Search');
      },
      label: 'Search',
    },
  ];

  const handleApplyFilter = () => {
    dispatch(clearLogsList());
    const params = {
      locationId: filters?.location?.id,
      categoryId: filters?.categoryType?.id,
      userId: filters?.personName?.id,
      startDate: moment(filters?.fromDate)?.toISOString(),
      endDate: filters?.toDate ? moment(filters?.toDate).endOf('day').toISOString() : null,
      message: filters?.message,
      page: 1,
      limit: 10,
    };

    let error = {};

    if (filters?.toDate) {
      error.fromDate = requireValidate('From Date', filters?.fromDate);
    }

    if (
      filters?.toDate &&
      filters?.fromDate &&
      !moment(filters?.toDate).isSameOrAfter(filters?.fromDate)
    ) {
      error.toDate = `To date can't be smaller then from date`;
    }

    error = omitBy(error, isNil);

    if (isEmpty(error)) {
      dispatch(updateLogListFilters({ fieldName: 'errors', value: {} }));
      dispatch(getLogsEntryByFiltersAction(params));
    } else {
      dispatch(updateLogListFilters({ fieldName: 'errors', value: error }));
    }
  };

  useEffect(() => {
    dispatch(
      getLocationDropDownListByBusinessId((data) => {
        dispatch(updateLogListFilters({ fieldName: 'selectedLocation', value: data?.[0] }));
      })
    );
    dispatch(getPersonListByBusinessIdAction());
  }, []);

  return (
    <div className="logbook-sidebar">
      <div className="logbook-filter-container">
        {logBookListViews.map((filter) => (
          <div
            key={filter?.id}
            onClick={filter?.onClick}
            className={`filters ${filter?.label === filters?.viewType ? 'selected-filter' : ''}`}
          >
            {filter?.label === filters?.viewType ? (
              <span className="material-icons">check_circle</span>
            ) : (
              <span className="material-icons">check_circle_outline</span>
            )}
            <span className="text-overflow-ellipsis">{filter?.label}</span>
          </div>
        ))}
      </div>

      {filters?.viewType === 'View' && (
        <EzyDatePicker
          className="logbook-date-picker"
          value={filters?.date}
          onChange={(date) => onChangeFilterFields('date', date)}
        />
      )}

      {filters?.viewType === 'View' && (
        <Paper className="logbook-side-card-wrapper">
          <div className="title-text">Locations</div>
          {getLocationsByBusinessIdLoader && <Loader />}
          <List className="area-check-list">
            {locationListEnum?.map((value) => {
              const labelId = `checkbox-list-label-${value?.id}`;

              return (
                <ListItem
                  key={value?.id}
                  disablePadding
                  selected={value?.id === filters?.selectedLocation?.id}
                >
                  <ListItemButton
                    role={undefined}
                    onClick={() => onChangeFilterFields('selectedLocation', value)}
                    dense
                    disableRipple
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={filters?.selectedLocation?.id === value?.id}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                        icon={<IconByName name="check_circle_outline" />}
                        checkedIcon={<IconByName name="check_circle" className="primary-color" />}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={value?.id}
                      className="publish-checks-view text-overflow-ellipsis"
                    >
                      <span className="text-overflow-ellipsis">{value?.label}</span>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Paper>
      )}

      {filters?.viewType === 'Search' && (
        <div className="filter-fields-container">
          <EzyAutoComplete
            label="Location"
            options={locationListEnum}
            placeholder="Select location"
            value={filters?.location}
            onChange={(_, value) => onChangeFilterFields('location', value)}
          />
          <EzyDate
            label="From"
            onChange={(e) => onChangeFilterFields('fromDate', e)}
            value={filters?.fromDate}
            error={filters?.errors?.fromDate}
          />
          <EzyDate
            label="To"
            onChange={(e) => onChangeFilterFields('toDate', e)}
            value={filters?.toDate}
            error={filters?.errors?.toDate}
          />
          <EzyAutoComplete
            label="Category"
            options={logsList?.categoryDropdownList}
            placeholder="Select category"
            value={filters?.categoryType}
            onChange={(_, value) => onChangeFilterFields('categoryType', value)}
          />
          <EzyAutoComplete
            label="Post by"
            placeholder="Select user"
            options={personList}
            value={filters?.personName}
            onChange={(_, value) => onChangeFilterFields('personName', value)}
          />
          <EzyInputField
            label="Message"
            placeholder="Enter search text"
            inputProps={{
              maxLength: 300,
            }}
            value={filters?.message}
            onChange={(e) => onChangeFilterFields('message', e?.target?.value)}
          />
          <div className="d-flex justify-content-end ">
            <EzyButton
              label="Apply"
              className="no-pad-button"
              disabled={!filters?.location?.id}
              onClick={handleApplyFilter}
              loading={getLogsEntryByFiltersActionLoader}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default LogBookSidebar;
