import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, InputAdornment, Paper } from '@mui/material';
import { isEmpty, isNull } from 'lodash';
import useQueryParams from '../../../hooks/useQueryParams';
import useUrlParamsUpdate from '../../../hooks/useURLParamsUpdate';
import {
  addBulkPeopleAccess,
  addBulkPeopleLocation,
  archivePeopleAction,
  bulkSendInvitationAction,
  deletePeopleByIdAction,
  downloadCSV,
  getNotInvitedPeopleListAction,
  getPeopleFilterByBusinessIdAction,
  getPeopleList,
  syncWithXeroAction,
} from '../redux/peopleActions';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import EzyButton from '../../../components/common/button/EzyButton';
import IconByName from '../../../components/common/iconByName/IconByName';
import ROUTE_CONSTANTS from '../../../utils/constants';
import EzyTable from '../../../components/common/table/EzyTable';
import EzyPagination from '../../../components/common/pagination/EzyPagination';
import EzyIconbutton from '../../../components/common/iconButton/EzyIconButton';
import FilterModal from '../../../components/common/filterModal/FilterModal';
import BulkAction from '../components/BulkAction';
import EzyAutoComplete from '../../../components/common/autoComplete/EzyAutoComplete';
import EzyMenu from '../../../components/common/menu/EzyMenu';
import { resetArchiveLocationDetails } from '../../location/redux/locationSlice';
import ArchivePeopleModal from '../components/ArchivePeopleModal';
import ConfirmationModal from '../../../components/common/modal/ConfirmationModal';
import useAccess from '../../../hooks/useAccess';
import Loader from '../../../components/common/loader/Loader';

function PeopleList() {
  const { peopleList, peopleListFiltersByBusiness } = useSelector(({ people }) => people ?? {});
  const { personId } = useSelector(({ user }) => user?.profileData || '');
  const { enums } = useSelector(({ common }) => common ?? {});
  const [searchText, setSearchText] = useState('');
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [addLocationAction, setAddLocationAction] = useState(null);
  const [accessAction, setAccessAction] = useState(null);
  const [bulkActionLocation, setBulkActionLocation] = useState(null);
  const [bulkActionAccess, setBulkActionAccess] = useState(null);
  const [invitationAction, setInvitationAction] = useState(null);
  const [selected, setSelected] = useState([]);
  const [filters, setFilters] = useState({ status: enums?.status?.[1] });
  const [archiveModalData, setArchiveModalData] = useState(null);
  const [deleteModalData, setDeleteModalData] = useState(null);
  const [validInvitations, setValidInvitation] = useState([]);
  const history = useHistory();
  const { roleId: loggedInRole } = useSelector(({ user }) => user?.profileData ?? {});
  const hasAccessToEdit = useAccess('EDITPPL');
  const [syncAction, setSyncAction] = useState(null);
  const filterFields = [
    {
      name: 'status',
      label: 'Status',
      placeholder: 'Select status',
      type: 'AutoComplete',
      options: enums?.status ?? [],
    },
    {
      name: 'location',
      label: 'Location',
      placeholder: 'Select location',
      type: 'AutoComplete',
      options: peopleListFiltersByBusiness?.locationDropDownEntity ?? [],
    },
    {
      name: 'access',
      label: 'Access',
      placeholder: 'Select access',
      type: 'AutoComplete',
      options: enums?.roles ?? [],
    },
    {
      name: 'payRate',
      label: 'Pay rates',
      placeholder: 'Select pay rates',
      type: 'AutoComplete',
      options: peopleListFiltersByBusiness?.payRatesEntities ?? [],
    },
  ];

  const { docs, headers, total, pages, page, limit } = useMemo(
    () => peopleList ?? {},
    [peopleList]
  );

  const {
    getLocationsByBusinessIdLoader,
    getPeopleListLoader,
    addBulkPeopleLocationLoader,
    addBulkPeopleAccessLoader,
    getNotInvitedPeopleListLoader,
    bulkSendInvitationActionLoader,
    syncWithXeroActionLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});

  const { page: paramPage, limit: paramLimit } = useQueryParams();
  const dispatch = useDispatch();

  useUrlParamsUpdate({
    page: page ?? 1,
    limit: limit ?? 15,
  });

  const getPeopleListByFilter = async (params = {}) => {
    const data = {
      page: page ?? 1,
      limit: limit ?? 15,
      searchText,
      status: enums?.status?.[1]?.id,
      ...params,
    };
    await dispatch(getPeopleList(data));
    setOpenFilterModal(false);
  };

  const handleApplyFilter = async (params) => {
    await getPeopleListByFilter({
      ...params,
      status: params?.status?.id,
      access: params?.access?.id,
      locationId: params?.location?.id,
      payRate: params?.payRate?.id,
    });
  };

  const pageActionClick = async (newPage) => {
    await getPeopleListByFilter({ page: newPage, limit });
  };
  const onSelectLimit = async (newLimit) => {
    await getPeopleListByFilter({ page: 1, limit: newLimit });
  };
  const handleSearch = async (e) => {
    if (e.target.value) setSearchText(e.target.value);
    else {
      setSearchText(null);
      await getPeopleListByFilter({ searchText: null, page: 1, limit });
    }
  };
  const handelDeletePeopleByID = async (e) => {
    await dispatch(
      deletePeopleByIdAction(e?.id, () => {
        handleApplyFilter(filters);
      })
    );
  };

  const handleUnArchive = (rowData) => {
    const data = {
      personDetailId: rowData?.id,
      isArchive: false,
    };

    dispatch(
      archivePeopleAction(data, async () => {
        await handleApplyFilter(filters);
      })
    );
  };

  const handleArchiveCallback = async () => {
    await handleApplyFilter(filters);
  };

  const checkInvitations = async () => {
    const emails = await dispatch(
      getNotInvitedPeopleListAction({
        personDetailsIds: selected?.join(','),
      })
    );
    if (emails.length > 0) setValidInvitation(docs.filter((e) => emails.includes(e.id)));
  };

  const bulkActions = [
    {
      label: 'Add location',
      id: 1,
      onClick: (e) => setAddLocationAction(e),
      access: 'EDITPPL',
    },
    {
      label: 'Set access',
      id: 2,
      onClick: (e) => setAccessAction(e),
      access: 'EDITPPL',
    },
    {
      label: 'Send invitation',
      id: 3,
      onClick: (e) => {
        setInvitationAction(e);
        checkInvitations();
      },
      access: 'EDITPPL',
    },
    {
      label: 'Download CSV',
      id: 4,
      onClick: () => dispatch(downloadCSV(selected)),
      access: 'EXPORTPPL',
    },
    {
      label: 'Sync with xero',
      id: 5,
      onClick: (e) => setSyncAction(e),
    },
  ];

  const onApplyBulkLocation = () => {
    const data = { personDetailIds: selected, id: bulkActionLocation?.id };
    dispatch(
      addBulkPeopleLocation(data, async () => {
        await handleApplyFilter(filters);
      })
    );
    if (!addBulkPeopleLocationLoader) {
      setAddLocationAction(null);
    }
  };

  const onApplyBulkAccess = () => {
    const data = { id: bulkActionAccess?.id, personDetailIds: selected };
    dispatch(
      addBulkPeopleAccess(data, async () => {
        await handleApplyFilter(filters);
        setBulkActionAccess(null);
        setSelected([]);
      })
    );
    if (!addBulkPeopleAccessLoader) {
      setAccessAction(null);
    }
  };
  const onApplyBulkInvitation = () => {
    const data = validInvitations?.map((e) => e?.id);
    dispatch(
      bulkSendInvitationAction(data, async () => {
        await handleApplyFilter(filters);
        setInvitationAction(null);
        setValidInvitation([]);
        setSelected([]);
      })
    );
  };
  const getActions = ({ isArchived, id, roleId }) => {
    const actions = [];
    if (hasAccessToEdit) {
      if (!isArchived) {
        actions.push({
          label: 'Edit',
          onClick: (e) => history.push(`${ROUTE_CONSTANTS.PEOPLE}/edit/${e.id}`),
          access: 'EDITPPL',
        });
      }
      if (!isArchived && id !== personId && loggedInRole <= roleId) {
        actions.push({
          label: 'Archive',
          onClick: (e) => setArchiveModalData(e),
          access: 'DELETEPPL',
        });
      }
      if (isArchived && id !== personId && loggedInRole <= roleId) {
        actions.push({
          label: 'Unarchive',
          onClick: handleUnArchive,
          access: 'DELETEPPL',
        });
      }
      if (isArchived && id !== personId && loggedInRole !== 3 && loggedInRole <= roleId) {
        actions.push({
          label: 'Delete',
          onClick: (e) => setDeleteModalData(e),
          access: 'DELETEPPL',
        });
      }
    } else {
      actions.push('DISABLED');
    }
    return actions;
  };

  const getRowData = () =>
    docs?.map((dt) => ({
      ...dt,
      action: getActions(dt),
    }));

  useEffect(() => {
    const params = {
      page: paramPage ?? page ?? 1,
      limit: paramLimit ?? limit ?? 15,
    };
    dispatch(getPeopleFilterByBusinessIdAction());
    getPeopleListByFilter({ ...params });
  }, []);

  return (
    <div className="people-wrapper">
      <div className="people-header">
        <div>
          <EzyInputField
            placeholder="Search by name, email"
            className="search-box"
            value={searchText}
            onChange={handleSearch}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                await getPeopleListByFilter({ page: 1, limit });
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconByName name="search" />
              </InputAdornment>
            }
          />
          <Badge
            color="secondary"
            variant="dot"
            invisible={Object.values(filters)?.every((element) => element === null)}
          >
            <EzyIconbutton iconName="filter_list" onClick={() => setOpenFilterModal(true)} />
          </Badge>

          <EzyMenu
            iconName="more_vert"
            menuItems={bulkActions}
            color="primary"
            disabled={selected?.length <= 0}
            access={['EXPORTPPL', 'EDITPPL', 'CREATELOC']}
          />
        </div>

        <EzyButton
          label="Add"
          className="no-pad-button"
          startIcon={<IconByName name="add" />}
          onClick={() => history.push(`${ROUTE_CONSTANTS.PEOPLE}/add`)}
          access="CREATEPPL"
        />
      </div>
      <div className="table-wrapper">
        <Paper className="table-paper">
          <EzyTable
            headers={headers}
            rows={getRowData()}
            selected={selected}
            setSelected={setSelected}
            selectableRow
            loading={getPeopleListLoader}
            onRowClick={(e) =>
              !e?.isArchived ? history.push(`${ROUTE_CONSTANTS.PEOPLE}/edit/${e.id}`) : null
            }
            accessForRow={['VIEWPPL']}
            accessForAction={['EDITPPL', 'VIEWPPL', 'DELETEPPL']}
          />
          <EzyPagination
            total={total}
            pages={pages}
            pageActionClick={pageActionClick}
            limit={limit}
            page={page}
            onChangeLimit={onSelectLimit}
          />
        </Paper>
      </div>
      <FilterModal
        open={openFilterModal}
        handleClose={() => setOpenFilterModal(false)}
        onApplyFilters={(filterParams) =>
          handleApplyFilter({
            ...filterParams,
            page: 1,
            limit,
          })
        }
        filterFields={filterFields}
        filters={filters}
        setFilters={setFilters}
      />
      <BulkAction
        key="Location"
        open={!isEmpty(addLocationAction) || !isNull(addLocationAction)}
        handleClose={() => setAddLocationAction(null)}
        rowData={addLocationAction}
        onApplyClick={() => onApplyBulkLocation()}
        selectedRowNumber={selected?.length ?? 0}
        title="Add Location"
        isLoading={addBulkPeopleLocationLoader}
      >
        <EzyAutoComplete
          placeholder="Select location"
          label="Location"
          options={peopleListFiltersByBusiness?.locationDropDownEntity}
          value={bulkActionLocation}
          onChange={(_, value) => setBulkActionLocation(value)}
          loading={getLocationsByBusinessIdLoader}
          required
        />
      </BulkAction>
      <BulkAction
        key="access"
        open={!isEmpty(accessAction) || !isNull(accessAction)}
        handleClose={() => setAccessAction(null)}
        rowData={accessAction}
        onApplyClick={() => onApplyBulkAccess()}
        selectedRowNumber={selected?.length ?? 0}
        title="Set Access Level"
        isLoading={addBulkPeopleAccessLoader}
      >
        <EzyAutoComplete
          placeholder="Select access"
          label="Access"
          options={enums?.roles}
          onChange={(_, value) => setBulkActionAccess(value)}
          required
        />
      </BulkAction>
      <BulkAction
        key="Invitation"
        open={!isEmpty(invitationAction) || !isNull(invitationAction)}
        handleClose={() => setInvitationAction(null)}
        rowData={invitationAction}
        onApplyClick={onApplyBulkInvitation}
        selectedRowNumber={selected?.length ?? 0}
        title="Send Invitation"
        isLoading={getNotInvitedPeopleListLoader || bulkSendInvitationActionLoader}
        disabledAction={validInvitations?.length === 0}
      >
        {/* <div className="d-flex-column"> */}
        {validInvitations.map((res, index) => (
          <div className="primary-bg invite-user-field" key={`Send-Invitation-list-${index}`}>
            <div>{res?.name}</div> <div>{res?.email}</div>
          </div>
        ))}
        {selected?.length > validInvitations?.length && (
          <div className="primary-bg">
            {validInvitations?.length} members to be invited, rest are already invited.
          </div>
        )}
        {/* </div> */}
      </BulkAction>

      <BulkAction
        key="syncWithXero"
        open={!isEmpty(syncAction) || !isNull(syncAction)}
        handleClose={() => setSyncAction(null)}
        rowData={syncAction}
        onApplyClick={() => {
          dispatch(syncWithXeroAction(selected, () => setSyncAction(null)));
        }}
        selectedRowNumber={selected?.length ?? 0}
        title="Sync With Xero"
        actionTitle="Sync team member"
      >
        {docs
          .filter((e) => selected.includes(e.id))
          .map((res, index) => (
            <div className="primary-bg invite-user-field" key={`Send-Invitation-list-${index}`}>
              <div>{res?.name}</div>
              <div>{syncWithXeroActionLoader && <Loader />}</div>
            </div>
          ))}
      </BulkAction>
      <ArchivePeopleModal
        isOpen={!isEmpty(archiveModalData) || !isNull(archiveModalData)}
        handleClose={async (isSubmit = false) => {
          setArchiveModalData(null);
          dispatch(resetArchiveLocationDetails());
          if (isSubmit) await handleArchiveCallback();
        }}
        rowData={archiveModalData}
      />
      <ConfirmationModal
        title="Delete People"
        isOpen={!isEmpty(deleteModalData) || !isNull(deleteModalData)}
        handleClose={() => {
          setDeleteModalData(null);
        }}
        handleSubmit={async () => {
          await handelDeletePeopleByID(deleteModalData);
          setDeleteModalData(null);
        }}
        primaryLabel="Delete"
      >
        <div>Are you sure you want to delete?</div>
      </ConfirmationModal>
    </div>
  );
}

export default PeopleList;
