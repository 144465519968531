import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import EzyButton from '../../../../components/common/button/EzyButton';
import WeekPicker from '../../../../components/common/dateAndTimePicker/WeekPicker';
import EzyToggleButtonGroup from '../../../../components/common/toggleButton/EzyToggleButtonGroup';
import ROUTE_CONSTANTS from '../../../../utils/constants';
import { getSummaryDataAction, getSummarySettingsAction } from '../../redux/insightAction';
import InsightMetricMenu from './InsightMetricMenu';
import InsightLocationMenu from './InsightLocationMenu';
import { updateInsightFilters, updateSummaryStatisticData } from '../../redux/insightSlice';
import SummaryTable from './SummaryTable';

function InsightSummary() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { businessId } = useSelector(({ user }) => user?.profileData || {});

  const viewTypeList = [
    { id: 5, icon: 'today' },
    { id: 1, icon: 'date_range' },
    { id: 4, icon: 'calendar_month' },
  ];
  const { filters, locationListDropdown } = useSelector(({ insight }) => insight ?? {});

  const { weekDatesValue, selectedLocation, viewType } = useMemo(() => filters, [filters]);

  const { getSummaryDataActionLoader } = useSelector(({ common }) => common.generalLoader ?? {});

  const getInsightSummaryByFilters = (params) => {
    const data = {
      businessId,
      repetitionFrequency: viewType,
      startDate: moment(weekDatesValue?.startDate).toISOString(),
      endDate: moment(weekDatesValue?.endDate).toISOString(),
      locationAreaEntities: locationListDropdown?.slice(1)?.map((x) => {
        return { locationId: x?.id, areaIds: x?.areaList?.map((y) => y?.id) };
      }),
      ...params,
    };
    dispatch(getSummaryDataAction(data));
  };

  const updateWeekDatesValueBasedOnDuration = (data, duration) => {
    const params = {};
    if (duration === 5) {
      // for 1 week (day view)
      params.startDate = moment.tz(data?.startDate, 'UTC').startOf('week');
      params.endDate = moment.tz(data?.startDate, 'UTC').endOf('week');
    } else if (duration === 1) {
      // for 6 week (week view)
      params.startDate = moment.tz(data?.startDate, 'UTC').startOf('week');
      params.endDate = moment.tz(data?.startDate, 'UTC').add(5, 'weeks').endOf('week');
    } else if (duration === 4) {
      // for 6 month (month view)
      params.startDate = moment.tz(data?.startDate, 'UTC').startOf('month');
      params.endDate = moment.tz(data?.startDate, 'UTC').add(5, 'months').endOf('month');
    }
    return params;
  };

  const handleNavigation = (side) => {
    const dates = {};
    if (side === 'prev') {
      if (viewType === 5) {
        dates.startDate = moment(weekDatesValue?.startDate).subtract(1, 'week').startOf('week');
        dates.endDate = moment(weekDatesValue?.endDate).subtract(1, 'week').endOf('week');
      } else if (viewType === 1) {
        dates.startDate = moment(weekDatesValue?.startDate).subtract(6, 'weeks').startOf('week');
        dates.endDate = moment(weekDatesValue?.endDate).subtract(6, 'weeks').endOf('week');
      } else if (viewType === 4) {
        dates.startDate = moment(weekDatesValue?.startDate).subtract(5, 'months').startOf('month');
        dates.endDate = moment(weekDatesValue?.endDate).subtract(5, 'months').endOf('month');
      }
    }
    if (side === 'next') {
      const data = updateWeekDatesValueBasedOnDuration(
        { startDate: moment(weekDatesValue?.endDate).add(1, 'day') },
        viewType
      );
      dates.startDate = data.startDate;
      dates.endDate = data.endDate;
    }

    dispatch(updateInsightFilters({ fieldName: 'weekDatesValue', value: dates }));
    getInsightSummaryByFilters({
      startDate: moment(dates?.startDate).toISOString(),
      endDate: moment(dates.endDate).toISOString(),
    });
  };

  useEffect(() => {
    (async () => {
      const dates = updateWeekDatesValueBasedOnDuration({}, viewType || 5);
      dispatch(updateInsightFilters({ fieldName: 'weekDatesValue', value: dates }));
      await dispatch(getSummarySettingsAction());
      if (selectedLocation?.id)
        await getInsightSummaryByFilters({
          startDate: moment(dates?.startDate).toISOString(),
          endDate: moment(dates?.endDate).toISOString(),
        });
    })();

    return () => {
      dispatch(updateSummaryStatisticData({}));
    };
  }, [selectedLocation]);

  return (
    <div className="insight-summary-wrapper">
      <div className="insight-summary-header">
        <div>
          <WeekPicker
            value={weekDatesValue}
            onChange={(e) => {
              const dates = updateWeekDatesValueBasedOnDuration(e, viewType);

              dispatch(updateInsightFilters({ fieldName: 'weekDatesValue', value: dates }));
              getInsightSummaryByFilters({
                startDate: moment(dates?.startDate).toISOString(),
                endDate: moment(dates.endDate).toISOString(),
              });
            }}
            variant="outlined"
            onPrevClick={() => handleNavigation('prev')}
            onNextClick={() => handleNavigation('next')}
            startWeekFrom={
              selectedLocation?.weekStartsOn === 7 ? 0 : selectedLocation?.weekStartsOn
            }
            timeZone="UTC"
          />
          <InsightMetricMenu refetchEvent={getInsightSummaryByFilters} />

          <InsightLocationMenu refetchEvent={getInsightSummaryByFilters} />
        </div>
        <div>
          <EzyToggleButtonGroup
            list={viewTypeList?.map((x) => x?.icon)}
            selected="today"
            selectedToggleButton="new"
            id="demo"
            name="viewType"
            handleChange={(e) => {
              const id = viewTypeList?.find((x) => x?.icon === e?.target?.textContent)?.id;
              dispatch(updateInsightFilters({ fieldName: 'viewType', value: id }));
              const dates = updateWeekDatesValueBasedOnDuration(weekDatesValue, id);
              dispatch(updateInsightFilters({ fieldName: 'weekDatesValue', value: dates }));
              getInsightSummaryByFilters({
                repetitionFrequency: id,
                startDate: moment(dates?.startDate).toISOString(),
                endDate: moment(dates.endDate).toISOString(),
              });
            }}
            value={viewTypeList?.find((x) => x?.id === viewType)?.icon}
            color="primary"
            isIconLabel
          />

          <EzyButton
            label="Back"
            color="secondary"
            onClick={() => history.push(ROUTE_CONSTANTS.SCHEDULE)}
          />
        </div>
      </div>

      <SummaryTable loading={getSummaryDataActionLoader} />
    </div>
  );
}

InsightSummary.propTypes = {};

export default InsightSummary;
