import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNil, omitBy } from 'lodash';
import moment from 'moment';
import EzyDrawer from '../../../components/common/drawer/Drawer';
import { clearAddCategoryDrawerData, updateAddCategoryDrawerDetails } from '../redux/logbookSlice';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import EzyAutoComplete from '../../../components/common/autoComplete/EzyAutoComplete';
import EzySwitch from '../../../components/common/switch/EzySwitch';
import { requireValidate } from '../../../utils/validationHelper';
import {
  addLogBookCategoryAction,
  editLogBookCategoryAction,
  getCategoryDetailsByIdAction,
  getLogbookEntriesAction,
} from '../redux/logbookActions';

function AddLogDrawer() {
  const dispatch = useDispatch();

  const { enums } = useSelector(({ common }) => common ?? {});

  const { addCategoryDrawer, filters } = useSelector(({ logbook }) => logbook ?? []);

  const { addLogBookCategoryActionLoader, editLogBookCategoryActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const {
    isOpenDrawer,
    name,
    categoryType,
    instantNotification,
    required,
    errors,
    isEditCategory,
  } = useMemo(() => addCategoryDrawer, [addCategoryDrawer]);

  const handleClose = () => {
    dispatch(clearAddCategoryDrawerData());
  };

  const onChangeField = (fieldName, value) => {
    dispatch(updateAddCategoryDrawerDetails({ fieldName, value }));
  };

  const handleSubmit = () => {
    let error = {};
    error.name = requireValidate('Name', name);
    error.categoryType = requireValidate('Category type', categoryType);
    error = omitBy(error, isNil);

    onChangeField('errors', error);
    if (isEmpty(error)) {
      const data = {
        name,
        categoryType: categoryType?.id,
        instantNotification,
        required,
      };
      if (!isEditCategory) {
        dispatch(
          addLogBookCategoryAction(data, () => {
            handleClose();
            dispatch(
              getLogbookEntriesAction({
                locationId: filters?.selectedLocation?.id,
                date: moment(filters?.date)?.toISOString(),
              })
            );
          })
        );
      } else {
        dispatch(
          editLogBookCategoryAction({ ...data, id: isEditCategory }, () => {
            handleClose();
            dispatch(
              getLogbookEntriesAction({
                locationId: filters?.selectedLocation?.id,
                date: moment(filters?.date)?.toISOString(),
              })
            );
          })
        );
      }
    }
  };

  const actionButton = [
    {
      label: 'Save',
      onClick: handleSubmit,
      loading: addLogBookCategoryActionLoader || editLogBookCategoryActionLoader,
    },
  ];

  useEffect(() => {
    if (isEditCategory) {
      dispatch(getCategoryDetailsByIdAction({ categoryId: isEditCategory }));
    }
  }, [isEditCategory]);

  return (
    <EzyDrawer
      isOpen={isOpenDrawer || isEditCategory}
      heading={isEditCategory ? 'Edit Category' : 'Add Category'}
      onClose={handleClose}
      actionButtons={actionButton}
    >
      <div className="add-log-drawer">
        <EzyInputField
          label="Name"
          placeholder="Enter category name"
          value={name}
          onChange={(event) => onChangeField('name', event?.target?.value)}
          error={errors?.name}
          required
        />

        <EzyAutoComplete
          label="Type"
          placeholder="Select type"
          options={enums?.categoryType}
          value={categoryType}
          onChange={(_, value) => onChangeField('categoryType', value)}
          error={errors?.categoryType}
          required
        />

        <EzySwitch
          label="Instant notification"
          name="instantNotification"
          checked={instantNotification}
          onChange={(event) => onChangeField('instantNotification', event?.target?.checked)}
        />

        <EzySwitch
          label="Required"
          name="required"
          checked={required}
          onChange={(event) => onChangeField('required', event?.target?.checked)}
        />
      </div>
    </EzyDrawer>
  );
}

export default AddLogDrawer;
