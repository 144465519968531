import apiService from '../../utils/apiService';
import API_URL from '../../utils/urlConstants';

export const exportTimeSheetServices = {
  getExportTimeSheetDetails: (params) =>
    apiService.getData(API_URL.TIME_SHEETS.getExportTimeSheetDetails, { params }),
  exportTimeSheetSummary: (data) =>
    apiService.postData(API_URL.TIME_SHEETS.exportTimeSheetSummary, data),
  exportTimesheetDetailsByPerson: (data) =>
    apiService.postData(API_URL.TIME_SHEETS.exportTimesheetDetailsByPerson, data),
  markTimesheetAsPaidOrUnpaid: (data) =>
    apiService.putData(API_URL.TIME_SHEETS.markTimesheetAsPaidOrUnpaid, data),
  getExportTypeDropdownList: (params) =>
    apiService.getData(API_URL.TIME_SHEETS.getExportTypeDropdownList, { params }),
  exportTimesheets: (data, config) =>
    apiService.postData(API_URL.TIME_SHEETS.exportTimesheets, data, {
      ...config,
      timeout: 3 * 60 * 1000,
    }),
  exportTimesheetDetailsByPayRates: (data) =>
    apiService.postData(API_URL.TIME_SHEETS.exportTimesheetDetailsByPayRates, data),
};
