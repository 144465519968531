import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import EzyTab from '../../components/common/tab/EzyTab';
import DashboardAdmin from './components/DashboardAdmin';
import DashboardCalender from './components/dashboardCalender/DashboardCalender';
import IconByName from '../../components/common/iconByName/IconByName';
import { getDashboardDetailsByBusinessIdAction } from './redux/dashboardActions';
import PeopleProfile from '../people/components/editPeople/PeopleProfile';
import { editLeaveDetails } from '../people/redux/peopleSlice';
import { resetDashboardData } from './redux/dashboardSlice';
import useAccess from '../../hooks/useAccess';
import ROUTE_CONSTANTS from '../../utils/constants';

const tabsAndIndex = {
  dashboard: 0,
  calendar: 1,
};

function Home() {
  const { cardFor = 1 } = useSelector(({ dashboard }) => dashboard ?? {});
  const { profileData } = useSelector(({ user }) => user || {});
  const { businessName, timeZone, weekStartsOn } = useMemo(() => profileData, [profileData]);
  const hasAccess = useAccess('LISTDASHAD');

  const dispatch = useDispatch();
  const history = useHistory();

  const { action = 'dashboard' } = useParams();
  if (!Object.keys(tabsAndIndex).includes(action)) return null;

  const handleAdminDashboardRefresh = async () => {
    if (cardFor) {
      moment.updateLocale('en', {
        week: {
          dow: weekStartsOn === 7 ? 0 : weekStartsOn,
        },
      });
      await dispatch(
        getDashboardDetailsByBusinessIdAction({
          display: cardFor,
          weekStartDate: moment.tz(new Date(), timeZone?.label).startOf('week').toISOString(),
          weekEndDate: moment.tz(new Date(), timeZone?.label).endOf('week').toISOString(),
        })
      );
    }
  };
  const dashboardActionButtons = [
    {
      label: 'Refresh',
      onClick: handleAdminDashboardRefresh,
      startIcon: <IconByName name="refresh" />,
    },
  ];
  const calenderActionButtons = [
    {
      label: 'Add leave request',
      startIcon: <IconByName name="add" />,
      onClick: () => {
        dispatch(editLeaveDetails({ fieldName: 'leaveRequestDrawer', value: true }));
      },
      access: 'CREATELVMGMT',
    },
  ];
  const tabs = [
    {
      label: 'Dashboard',
      component: <DashboardAdmin />,
      actionButtons: dashboardActionButtons,
      name: 'dashboard',
      access: 'LISTDASHAD',
    },
    {
      label: 'Calendar',
      component: <DashboardCalender />,
      actionButtons: calenderActionButtons,
      name: 'calendar',
      access: 'LISTDASHCAL',
    },
  ];

  const handleTabChange = (newTab) => {
    history.push(`${ROUTE_CONSTANTS.HOME}/${newTab}`);
  };

  useEffect(() => {
    return () => {
      dispatch(resetDashboardData());
    };
  }, []);

  return (
    <div className="dashboard-wrapper">
      <div className="dashboard-sidebar">
        <div className="dashboard-business-info">
          <IconByName name="business_center" />
          {businessName}
        </div>
        <PeopleProfile isFromDashboard />
      </div>
      <EzyTab
        list={tabs}
        defaultIndex={hasAccess ? tabsAndIndex[action] || 0 : 0}
        onTabChange={handleTabChange}
      />
    </div>
  );
}

export default Home;
