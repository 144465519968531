import { Checkbox } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isNull } from 'lodash';
import moment from 'moment';
import EzyIconButton from '../../../components/common/iconButton/EzyIconButton';
import EzyMenu from '../../../components/common/menu/EzyMenu';
import ShowCount from './ShowCount';
import {
  changeSubTaskStatusAction,
  deleteTaskAction,
  getTaskForWholeAreaByIdAction,
  getTasksByAreaAction,
} from '../redux/tasksActions';
import { updateAreaTaskListDetails, updateAddTaskForAreaDrawerDetails } from '../redux/tasksSlice';
import ConfirmationModal from '../../../components/common/modal/ConfirmationModal';

function TaskByArea({ task }) {
  const dispatch = useDispatch();
  const [subTaskNote, setSubTaskNote] = useState();
  const [deleteModalData, setDeleteModalData] = useState(null);
  const [showNote, setShowNote] = useState(false);
  const { filters, areaTasksList } = useSelector(({ tasks }) => tasks ?? {});
  const { weekDays: weekDaysEnum, datesOfMonthList: datesOfMonthListEnum } = useSelector(
    ({ common }) => common?.enums ?? {}
  );
  const { roleId } = useSelector(({ user }) => user?.profileData ?? {});
  const messages = [
    {
      id: 1,
      label: 'No Repeats',
    },
    {
      id: 2,
      label: 'Repeated Daily',
    },
    {
      id: 3,
      label: `Repeated on every ${
        task?.weekDays !== null &&
        weekDaysEnum.filter((e) => task?.weekDays?.includes(e?.id)).map((e) => e.label)
      }`,
    },
    {
      id: 4,
      label: `Repeated on ${
        task?.dayOfMonth !== null &&
        datesOfMonthListEnum.filter((e) => task?.dayOfMonth?.includes(e?.id)).map((e) => e.label)
      }`,
    },
  ];
  const handleDuplicateAndEdit = async (title) => {
    await dispatch(getTaskForWholeAreaByIdAction({ taskId: task.id }));
    dispatch(
      updateAddTaskForAreaDrawerDetails({
        fieldName: 'drawerTitle',
        value: title,
      })
    );
  };

  const handleDeleteTask = () => {
    dispatch(
      deleteTaskAction({ taskId: task?.id }, () =>
        dispatch(
          updateAreaTaskListDetails({
            fieldName: 'areaDocs',
            value: areaTasksList?.areaDocs.filter((e) => e?.id !== task?.id),
          })
        )
      )
    );
  };

  const bulkActions = [
    {
      label: 'Duplicate',
      id: 1,
      onClick: () => {
        handleDuplicateAndEdit('Duplicate Task');
      },
    },
    {
      label: 'Edit',
      id: 2,
      onClick: () => {
        handleDuplicateAndEdit('Edit Task');
      },
    },
    {
      label: 'Delete',
      id: 3,
      onClick: (e) => {
        setDeleteModalData(e);
      },
    },
  ];
  const handleChangeStatus = async (id) => {
    await dispatch(
      changeSubTaskStatusAction(id, () => {
        dispatch(
          getTasksByAreaAction({
            date: moment(filters?.date).toISOString(),
            locationId: filters?.selectedLocation,
          })
        );
      })
    );
  };
  return (
    <div className="task-area-wrapper" key={task?.id}>
      <div className="title-text ">
        {task?.assignedAreaName}
        <div className="d-flex gap-10 task-completion-text">
          {task?.completedCount}/{task?.taskCount} Completed
          {roleId < 3 && (
            <EzyMenu iconName="more_vert" isOnlyIcon menuItems={bulkActions} color="primary" />
          )}
        </div>
      </div>

      <div className="task-name">
        <span className="task-title">{task?.taskTitle}</span>
        <span className="text-secondary-color p-bottom-10">
          {roleId < 3 &&
            messages
              .filter((message) => task?.taskRepeatationFrequency === message.id)
              .map((message) => message.label)}
        </span>
        <hr />
      </div>

      {task?.subTasks?.map((subTask) => {
        return (
          <div className="task-row-note p-bottom-10 ">
            {!subTask?.canCompletedMultipleTimes ? (
              <Checkbox
                checked={subTask?.taskCompletionStatus === 2}
                onClick={() => handleChangeStatus(subTask?.subTaskId)}
              />
            ) : (
              <div className="count-wrapper">
                <ShowCount
                  count={subTask?.completedCount}
                  onClick={() => handleChangeStatus(subTask?.subTaskId)}
                />
              </div>
            )}
            <div className="task-row">
              <div className="text-secondary-color task">
                <div
                  className={
                    subTask?.taskCompletionStatus === 2 && !subTask?.canCompletedMultipleTimes
                      ? 'text-line-through'
                      : ''
                  }
                >
                  {subTask.subTaskTitle}
                </div>

                {subTask?.note && (
                  <EzyIconButton
                    className="note-icon"
                    iconName="text_snippet"
                    onClick={() => {
                      setSubTaskNote(subTask?.subTaskId);
                      setShowNote(!showNote);
                    }}
                  />
                )}
              </div>
              {subTask?.note && subTask?.subTaskId === subTaskNote && showNote ? (
                <div className="text-secondary-color task-note">{subTask?.note}</div>
              ) : (
                ''
              )}
            </div>
          </div>
        );
      })}
      <ConfirmationModal
        title="Delete Task"
        isOpen={!isEmpty(deleteModalData) || !isNull(deleteModalData)}
        handleClose={() => {
          setDeleteModalData(null);
        }}
        handleSubmit={async () => {
          await handleDeleteTask();
          setDeleteModalData(null);
        }}
        primaryLabel="Delete"
      >
        <div>Are you sure you want to delete?</div>
      </ConfirmationModal>
    </div>
  );
}

TaskByArea.propTypes = {
  task: PropTypes.object.isRequired,
};

export default TaskByArea;
