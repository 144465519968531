import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import EzyDialog from '../../../components/common/dialog/EzyDialog';
import EzyDialogTitle from '../../../components/common/dialog/EzyDialogTitle';
import EzySwitch from '../../../components/common/switch/EzySwitch';
import EzyDialogAction from '../../../components/common/dialog/EzyDialogAction';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import { updateHolidaySettingsAction } from '../redux/scheduleActions';
import { displayDate } from '../../../utils/timeHelper';

function WeatherModal({ open, handleClose, selectedDate, reFetchEvents }) {
  const { weatherData, sidePanelData } = useSelector(({ schedule }) => schedule ?? {});
  const dispatch = useDispatch();
  const [fieldData, setFieldData] = useState({
    isPublicHoliday: false,
    isTimesOffRequest: false,
    note: '',
  });
  const { updateHolidaySettingsActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const dataToDisplay = weatherData?.find(
    (x) =>
      moment
        .tz(x?.date, sidePanelData?.selectedLocation?.timeZoneId?.label)
        .format('YYYY-MM-DD') === selectedDate
  );

  const getIconName = (icon) => {
    switch (icon) {
      case 'partly-cloudy-day':
      case 'cloudy':
        return 'cloud';
      case 'rain':
        return 'water_drop';
      case 'snow':
        return 'ac_unit';
      default:
        return 'sunny';
    }
  };

  const handleSubmit = () => {
    const data = {
      date: dataToDisplay?.date,
      locationId: dataToDisplay?.locationId,
      ...dataToDisplay,
      ...fieldData,
    };
    dispatch(
      updateHolidaySettingsAction(data, () => {
        handleClose();
        reFetchEvents();
      })
    );
  };

  const actionButton = [
    { label: 'Save', onClick: handleSubmit, loading: updateHolidaySettingsActionLoader },
  ];

  useEffect(() => {
    setFieldData({
      isPublicHoliday: dataToDisplay?.isPublicHoliday,
      isTimesOffRequest: dataToDisplay?.isTimesOffRequest,
      note: dataToDisplay?.note,
    });
  }, [dataToDisplay]);

  return (
    <EzyDialog
      open={
        weatherData?.length > 0 &&
        open &&
        dataToDisplay?.date &&
        sidePanelData?.selectedLocation?.id !== 'all'
      }
      handleClose={handleClose}
      className={` common-dialog-with-body copy-dialog `}
    >
      <EzyDialogTitle
        title={displayDate(dataToDisplay?.date, sidePanelData?.selectedLocation?.timeZoneId?.label)}
        onClose={handleClose}
      />
      <div className="dialog-body ">
        <EzySwitch
          label="Block time off requests"
          checked={fieldData?.isTimesOffRequest}
          handleChange={(e) =>
            setFieldData((prev) => {
              return {
                ...prev,
                isTimesOffRequest: e?.target?.checked,
              };
            })
          }
        />
        <EzySwitch
          label="Public holiday"
          checked={fieldData?.isPublicHoliday}
          handleChange={(e) =>
            setFieldData((prev) => {
              return {
                ...prev,
                isPublicHoliday: e?.target?.checked,
              };
            })
          }
        />
        <div>
          {dataToDisplay?.weatherInfo ? (
            <div className="title-text d-flex gap-10">
              <span className="material-icons">
                {getIconName(dataToDisplay?.weatherInfo?.icon)}
              </span>
              <span>{dataToDisplay?.weatherInfo?.temperature} &#8451;</span>
              <span>{dataToDisplay?.weatherInfo?.description}</span>
            </div>
          ) : (
            <div className="title-text">No weather data available</div>
          )}
          <div className="text-secondary-color">{dataToDisplay?.address}</div>
        </div>

        <EzyInputField
          label="Note"
          inputProps={{
            maxLength: 300,
          }}
          placeholder="Add a not to help schedule your day"
          multiline
          maxRows={2}
          value={fieldData?.note}
          onChange={(e) =>
            setFieldData((prev) => {
              return { ...prev, note: e?.target?.value };
            })
          }
        />
      </div>
      <EzyDialogAction actionButtons={actionButton} />
    </EzyDialog>
  );
}

WeatherModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedDate: PropTypes.string.isRequired,
  reFetchEvents: PropTypes.func.isRequired,
};

export default WeatherModal;
