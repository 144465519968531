import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useState, useMemo } from 'react';
import { isEmpty, isNull } from 'lodash';
import moment from 'moment';
import EzyMenu from '../../../components/common/menu/EzyMenu';
import {
  downloadScheduleExcelByAreaAction,
  downloadScheduleExcelByTeamMemberAction,
  downloadShecduleCSVAction,
  shiftBulkActionsAction,
} from '../redux/scheduleActions';
import ConfirmationModal from '../../../components/common/modal/ConfirmationModal';
import { updateBulkActionData } from '../redux/scheduleSlice';
import SaveTemplateDrawer from './SaveTemplateDrawer';
import LoadTemplateDrawer from './LoadTemplateDrawer';
import TemplateErrorModal from './TemplateErrorModal';

function ScheduleBulkActions({ eventIds, reFetchEvents }) {
  const dispatch = useDispatch();
  const { shiftBulkActions } = useSelector(({ common }) => common?.enums ?? {});
  const { weekDatesValue, selectedLocation, duration } = useSelector(
    ({ schedule }) => schedule?.sidePanelData ?? {}
  );

  const { startDate, endDate } = useMemo(() => weekDatesValue, [weekDatesValue]);

  const { roleId: loggedInRole, businessId } = useSelector(({ user }) => user?.profileData ?? {});

  const [markFilledEmptyAction, setMarkFilledEmptyAction] = useState(null);
  const [removeEmptyAction, setRemoveEmptyAction] = useState(null);
  const [markEmptyAsOpenAction, setMarkEmptyAsOpenAction] = useState(null);
  const [deleteAllAction, setDeleteAllAction] = useState(null);

  const {
    shiftBulkActionsActionLoader,
    downloadShecduleCSVActionLoader,
    downloadScheduleExcelByAreaActionLoader,
    downloadScheduleExcelByTeamMemberActionLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});

  const { unpublishedShifts, emptyShifts, openShifts, publishedShifts } = useSelector(
    ({ schedule }) => schedule.calendarFooterData ?? {}
  );

  const submitCallBack = () => {
    setDeleteAllAction(null);
    setMarkEmptyAsOpenAction(null);
    setRemoveEmptyAction(null);
    setMarkFilledEmptyAction(null);
    reFetchEvents();
  };
  const handleBulkSubmit = (bulkActionId) => {
    const data = {
      shiftIds: eventIds,
      shiftBulkActions: bulkActionId,
    };
    dispatch(shiftBulkActionsAction(data, submitCallBack));
  };

  const donwloadScheduleCSV = () => {
    const params = {
      startDate: moment(startDate)?.toISOString(),
      endDate: moment(endDate)?.toISOString(),
    };
    if (selectedLocation?.id !== 'all' && !selectedLocation?.locationId) {
      params.locationId = selectedLocation?.id;
    } else if (selectedLocation?.id === 'all') {
      params.businessId = businessId;
    } else if (selectedLocation?.locationId) {
      params.areaIds = selectedLocation?.id;
      params.locationId = selectedLocation?.locationId;
    }
    dispatch(downloadShecduleCSVAction(params));
  };

  const downloadScheduleExcel = ({ downloadBy }) => {
    const params = {
      startDate: moment(startDate)?.toISOString(),
      endDate: moment(endDate)?.toISOString(),
      repetitionFrequency: duration?.id > 5 ? duration.id - 5 : duration?.id,
    };
    if (selectedLocation?.id !== 'all' && !selectedLocation?.locationId) {
      params.locationId = selectedLocation?.id;
    } else if (selectedLocation?.id === 'all') {
      params.businessId = businessId;
    } else if (selectedLocation?.locationId) {
      params.areaId = selectedLocation?.id;
    }
    if (downloadBy === 'area') {
      dispatch(downloadScheduleExcelByAreaAction(params));
    } else {
      dispatch(downloadScheduleExcelByTeamMemberAction(params));
    }
  };

  const bulkActions = [
    {
      label: 'Save template',
      id: Math.floor(Math.random() * 16),
      onClick: () =>
        dispatch(updateBulkActionData({ fieldName: 'isSaveTemplateDrawer', value: true })),
      loading: shiftBulkActionsActionLoader,
      disabled: eventIds?.length <= 0,
    },
    {
      label: 'Load template',
      id: Math.floor(Math.random() * 16),
      onClick: () =>
        dispatch(updateBulkActionData({ fieldName: 'isLoadTemplateDrawer', value: true })),
      loading: shiftBulkActionsActionLoader,
    },
    {
      label: 'Download Roster (CSV)',
      id: Math.floor(Math.random() * 16),
      onClick: () => donwloadScheduleCSV(),
      loading: downloadShecduleCSVActionLoader,
    },
    {
      label: 'Print by area (EXCEL)',
      id: Math.floor(Math.random() * 16),
      onClick: () => downloadScheduleExcel({ downloadBy: 'area' }),
      loading: downloadScheduleExcelByAreaActionLoader,
    },
    {
      label: 'Print by team member (EXCEL)',
      id: Math.floor(Math.random() * 16),
      onClick: () => downloadScheduleExcel({ downloadBy: 'teamMember' }),
      loading: downloadScheduleExcelByTeamMemberActionLoader,
    },
    {
      label: shiftBulkActions?.[0]?.label,
      id: shiftBulkActions?.[0]?.id,
      onClick: () => setMarkFilledEmptyAction(shiftBulkActions?.[0]?.id),
      loading: shiftBulkActionsActionLoader,
      disabled: eventIds?.length <= 0,
      access: 'EDITEMPSCHED',
    },
    {
      label: shiftBulkActions?.[1]?.label,
      id: shiftBulkActions?.[1]?.id,
      onClick: () => setRemoveEmptyAction(shiftBulkActions?.[1]?.id),
      loading: shiftBulkActionsActionLoader,
      disabled: emptyShifts <= 0,
      access: 'EDITEMPSCHED',
    },
    {
      label: shiftBulkActions?.[3]?.label,
      id: shiftBulkActions?.[3]?.id,
      onClick: () => setMarkEmptyAsOpenAction(shiftBulkActions?.[3]?.id),
      loading: shiftBulkActionsActionLoader,
      disabled: emptyShifts <= 0,
      access: 'EDITEMPSCHED',
    },
    {
      label: shiftBulkActions?.[2]?.label,
      id: shiftBulkActions?.[2]?.id,
      onClick: () => {
        setDeleteAllAction(shiftBulkActions?.[2]?.id);
      },
      loading: shiftBulkActionsActionLoader,
      disabled:
        unpublishedShifts <= 0 && emptyShifts <= 0 && openShifts <= 0 && publishedShifts <= 0,
      access: 'EDITEMPSCHED',
    },
  ];

  return (
    <>
      {loggedInRole !== 5 && (
        <EzyMenu iconName="more_vert" menuItems={bulkActions} color="primary" />
      )}
      <ConfirmationModal
        title="Delete Shifts"
        isOpen={!isEmpty(deleteAllAction) || !isNull(deleteAllAction)}
        handleClose={() => {
          setDeleteAllAction(null);
        }}
        handleSubmit={() => handleBulkSubmit(deleteAllAction)}
        primaryLabel="Delete"
        loading={shiftBulkActionsActionLoader}
      >
        <div className="d-flex-column gap-10">
          <div>Are you sure you want to delete all shifts?</div>
          <div className="secondary-caption-text">
            Action will be applied to filtered shifts only.
          </div>
        </div>
      </ConfirmationModal>
      <ConfirmationModal
        title="Empty Shifts"
        isOpen={!isEmpty(markFilledEmptyAction) || !isNull(markFilledEmptyAction)}
        handleClose={() => {
          setMarkFilledEmptyAction(null);
        }}
        handleSubmit={() => handleBulkSubmit(markFilledEmptyAction)}
        primaryLabel="Empty all"
        primaryColor="primary"
        loading={shiftBulkActionsActionLoader}
      >
        <div className="d-flex-column gap-10">
          <div>Are you sure you want to empty all filled shifts?</div>
          <div className="secondary-caption-text">
            Action will be applied to filtered shifts only.
          </div>
        </div>
      </ConfirmationModal>
      <ConfirmationModal
        title="Remove Empty Shifts"
        isOpen={!isEmpty(removeEmptyAction) || !isNull(removeEmptyAction)}
        handleClose={() => {
          setRemoveEmptyAction(null);
        }}
        handleSubmit={() => handleBulkSubmit(removeEmptyAction)}
        primaryLabel="Remove"
        loading={shiftBulkActionsActionLoader}
      >
        <div className="d-flex-column gap-10">
          <div>Are you sure you want to remove all empty shifts?</div>
          <div className="secondary-caption-text">
            Action will be applied to filtered shifts only.
          </div>
        </div>
      </ConfirmationModal>
      <ConfirmationModal
        title="Open Empty Shifts"
        isOpen={!isEmpty(markEmptyAsOpenAction) || !isNull(markEmptyAsOpenAction)}
        handleClose={() => {
          setMarkEmptyAsOpenAction(null);
        }}
        handleSubmit={() => handleBulkSubmit(markEmptyAsOpenAction)}
        primaryLabel="Open"
        primaryColor="primary"
        loading={shiftBulkActionsActionLoader}
      >
        <div className="d-flex-column gap-10">
          <div>Are you sure you want to open all empty shifts?</div>
          <div className="secondary-caption-text">
            Action will be applied to filtered shifts only.
          </div>
        </div>
      </ConfirmationModal>
      <SaveTemplateDrawer eventIds={eventIds} />
      <LoadTemplateDrawer eventIds={eventIds} reFetchEvents={reFetchEvents} />
      <TemplateErrorModal reFetchEvents={reFetchEvents} />
    </>
  );
}

export default ScheduleBulkActions;

ScheduleBulkActions.propTypes = {
  reFetchEvents: PropTypes.func.isRequired,
  eventIds: PropTypes.array.isRequired,
};
