import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import EzyMenu from '../../../components/common/menu/EzyMenu';
import { getStatsPanelDataAction } from '../redux/scheduleActions';
import { displayDate } from '../../../utils/timeHelper';
import EditInsightGraphMenu from './EditInsightGraphMenu';
import InsightGraph from './InsightGraph';
import Loader from '../../../components/common/loader/Loader';
import EzyIconTooltip from '../../../components/common/tooltip/EzyIconTooltip';

function InsightStatsPanel() {
  const dispatch = useDispatch();
  const { businessId } = useSelector(({ user }) => user?.profileData || {});

  const { getStatsPanelDataActionLoader, getChartDataActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const { sidePanelData, statsPanelData } = useSelector(({ schedule }) => schedule ?? {});

  const { weekDatesValue, selectedLocation, duration } = useMemo(
    () => sidePanelData,
    [sidePanelData]
  );
  const { enums } = useSelector(({ common }) => common ?? {});
  const [selectedItem, setSelectedItem] = useState({ id: 6, label: 'weekly summary (graph)' });

  const handleMenuItemClick = (item) => {
    setSelectedItem(item);
    const params = {
      startDate: moment(weekDatesValue?.startDate).toISOString(),
      endDate: moment(weekDatesValue?.endDate).toISOString(),
      businessId: selectedLocation?.id === 'all' ? businessId : null,
      locationId:
        selectedLocation?.id !== 'all' && !selectedLocation?.locationId
          ? selectedLocation?.id
          : selectedLocation?.locationId,
      areaIds: selectedLocation?.locationId ? selectedLocation?.id : null,
      statsPanelOption: item?.id,
    };
    if (params?.statsPanelOption !== 6) dispatch(getStatsPanelDataAction(params));
  };

  const summaryBasedOnOption = () => {
    switch (selectedItem?.id) {
      case 1:
        return (
          <div className="summary">
            <div>
              <span>Budgeted</span>
              <span className="text-overflow-ellipsis">
                {statsPanelData?.statsData?.hoursTotal?.budgeted || '-'}
              </span>
            </div>
            <div>
              <span>Req. staff</span>
              <span className="text-overflow-ellipsis">
                {statsPanelData?.statsData?.hoursTotal?.requiredStaff || '-'}
              </span>
            </div>
            <div>
              <span>Filled shift</span>
              <span className="text-overflow-ellipsis">
                {statsPanelData?.statsData?.hoursTotal?.filledShift || '-'}
              </span>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="summary">
            <div>
              <span>Budgeted</span>
              <span className="text-overflow-ellipsis">
                {statsPanelData?.statsData?.wagesTotal?.budgeted || '-'}
              </span>
            </div>
            <div>
              <span>Scheduled</span>
              <span className="text-overflow-ellipsis">
                {statsPanelData?.statsData?.wagesTotal?.scheduled || '-'}
              </span>
            </div>
            <div>
              <span>Actual</span>
              <span className="text-overflow-ellipsis">
                {statsPanelData?.statsData?.wagesTotal?.actuals || '-'}
              </span>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="summary">
            <div>
              <span>PayrollEzy forecast</span>
              <span className="text-overflow-ellipsis">
                {statsPanelData?.statsData?.salesTotal?.payrollEzyForecast || '-'}
              </span>
            </div>
            <div>
              <span>Manager forecast</span>
              <span className="text-overflow-ellipsis">
                {statsPanelData?.statsData?.salesTotal?.managerForecast || '-'}
              </span>
            </div>
            <div>
              <span>Actual</span>
              <span className="text-overflow-ellipsis">
                {statsPanelData?.statsData?.salesTotal?.actuals || '-'}
              </span>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="summary">
            <div>
              <span>Scheduled</span>
              <span className="text-overflow-ellipsis">
                {statsPanelData?.statsData?.laborTotal?.payrollEzyForecast || '-'}
              </span>
            </div>
            <div>
              <span>Actual</span>
              <span className="text-overflow-ellipsis">
                {statsPanelData?.statsData?.laborTotal?.actuals || '-'}
              </span>
            </div>
          </div>
        );
      case 5:
        return (
          <div className="summary">
            <div>
              <span>Forecast</span>
              <span className="text-overflow-ellipsis">
                {statsPanelData?.statsData?.salesPerHourTotal?.forecast || '-'}
              </span>
            </div>
            <div>
              <span>Scheduled</span>
              <span className="text-overflow-ellipsis">
                {statsPanelData?.statsData?.salesPerHourTotal?.scheduled || '-'}
              </span>
            </div>
            <div>
              <span>Actual</span>
              <span className="text-overflow-ellipsis">
                {statsPanelData?.statsData?.salesPerHourTotal?.actuals || '-'}
              </span>
            </div>
          </div>
        );

      default:
        return (
          <div className="summary weekly-summary">
            <div>
              <span>Sch. hours</span>
              <span className="empty-span"> </span>
              <span className="text-overflow-ellipsis">
                {statsPanelData?.graphData?.totalScheduledHours || '-'}
              </span>
            </div>
            <div>
              <span>Sch. wages</span>
              <span className="empty-span"> </span>
              <span className="text-overflow-ellipsis">
                {statsPanelData?.graphData?.totalScheduledWages || '-'}
              </span>
            </div>
            <div>
              <span>Forecast sales</span>
              <span className="empty-span"> </span>
              <span className="text-overflow-ellipsis">
                {statsPanelData?.graphData?.totalForecastSales || '-'}
              </span>
            </div>
            <div>
              <span>Sales per hr</span>
              <span className="empty-span"> </span>
              <span className="text-overflow-ellipsis">
                {statsPanelData?.graphData?.totalSalesPerHour || '-'}
              </span>
            </div>
            <div>
              <span>Labor %</span>
              <span className="empty-span"> </span>
              <span className="text-overflow-ellipsis">
                {statsPanelData?.graphData?.totalLabor || '-'}
              </span>
            </div>
            <EditInsightGraphMenu />
          </div>
        );
    }
  };

  const dataBasedOnFilter = (data) => {
    switch (selectedItem?.id) {
      case 1:
        return (
          <div className="summary" key={data?.date}>
            <div className="flex-center">{data?.hours?.budgeted || '-'}</div>
            <div className="flex-center">{data?.hours?.requiredStaff || '-'}</div>
            <div className="flex-center">{data?.hours?.filledShift || '-'}</div>
          </div>
        );
      case 2:
        return (
          <div className="summary" key={data?.date}>
            <div className="flex-center">{data?.wages?.budgeted || '-'}</div>
            <div className="flex-center">{data?.wages?.scheduled || '-'}</div>
            <div className="flex-center">{data?.wages?.actuals || '-'}</div>
          </div>
        );
      case 3:
        return (
          <div className="summary" key={data?.date}>
            <div className="flex-center">{data?.sales?.payrollEzyForecast || '-'}</div>
            <div className="flex-center">{data?.sales?.managerForecast || '-'}</div>
            <div className="flex-center">{data?.sales?.actuals || '-'}</div>
          </div>
        );
      case 4:
        return (
          <div className="summary" key={data?.date}>
            <div className="flex-center">{data?.labor?.payrollEzyForecast || '-'}</div>
            <div className="flex-center">{data?.labor?.actuals || '-'}</div>
          </div>
        );
      case 5:
        return (
          <div className="summary" key={data?.date}>
            <div className="flex-center">{data?.salesPerHour?.forecast || '-'}</div>
            <div className="flex-center">{data?.salesPerHour?.scheduled || '-'}</div>
            <div className="flex-center">{data?.salesPerHour?.actuals || '-'}</div>
          </div>
        );

      default:
        return '';
    }
  };

  const statsPanelTooltip = (item) => {
    if (item?.id === 4) {
      return (
        <div className="d-flex-column gap-10">
          <div>Labor % shows the wage costs divided by your sales.</div>
          <div>Forecast shows budgeted wages vs. PayrollEzy forecast sales.</div>
          <div>
            Scheduled shows scheduled wages vs. Manager forecast sales. If there is no manager
            forecast, the PayrollEzy forecast sales will be used.
          </div>
          <div>Actual shows actual wages vs actual sales.</div>
        </div>
      );
    }
    return (
      <div className="d-flex-column gap-10">
        <div>Sales per hour shows your sales divided by employee hours.</div>
        <div>Forecast shows PayrollEzy forecast sales vs budgeted hours.</div>
        <div>
          Scheduled shows manager forecast sales vs scheduled hours. If there is no manager
          forecast, the PayrollEzy forecast sales will be used.
        </div>
        <div>Actual shows actual sales vs actual hours.</div>
      </div>
    );
  };

  return (
    <>
      <td>
        <div className="insight-stats-panel-summary">
          <div className="summary-header subtitle-text">
            {selectedItem?.id === 6 ? (
              'Weekly summary'
            ) : (
              <div className="d-flex">
                {selectedItem?.label}
                {[4, 5]?.includes(selectedItem?.id) ? (
                  <EzyIconTooltip tooltip={statsPanelTooltip(selectedItem)} />
                ) : (
                  ''
                )}
              </div>
            )}
            {duration?.id === 1 && (
              <EzyMenu
                iconName="edit"
                menuItems={enums?.statsPanelOption}
                onItemClick={(_, item) => handleMenuItemClick(item)}
                selectedItem={selectedItem}
              />
            )}
          </div>
          {summaryBasedOnOption()}
        </div>
      </td>
      <td aria-labelledby="empty-cell"> </td>
      <td>
        {selectedItem?.id === 6 ? (
          <div className="stats-panel-graph-container">
            {getChartDataActionLoader && <Loader />}
            <div className="graph-summary">
              {[1, 2]?.includes(duration?.id) &&
                statsPanelData?.graphData?.graphDataList?.map((x) => (
                  <Tooltip
                    title={<SummaryTooltip data={x} selectedLocation={selectedLocation} />}
                    arrow
                  >
                    <div>
                      <div>{x?.graphWagesData?.scheduleWagesTotal}</div>
                      <div className="d-flex gap-5">
                        <span>{x?.graphLaborData?.scheduledLabor}</span>
                        <span>{x?.graphStaffData?.filledShiftTotal}</span>
                        {x?.graphStaffData?.differenceTotal && (
                          <span className="primary-color">
                            ({x.graphStaffData.differenceTotal})
                          </span>
                        )}
                      </div>
                    </div>
                  </Tooltip>
                ))}
            </div>
            <InsightGraph />
          </div>
        ) : (
          <div className="stats-panel-details">
            {getStatsPanelDataActionLoader && <Loader />}
            {statsPanelData?.statsData?.statsDataByDates?.map((x) => dataBasedOnFilter(x))}
          </div>
        )}
      </td>
    </>
  );
}

InsightStatsPanel.propTypes = {};

export default InsightStatsPanel;

function SummaryTooltip({ data, selectedLocation }) {
  return (
    <div className="summary-tooltip d-flex-column gap-12">
      <div className="subtitle-text white-color">
        {displayDate(data?.specificDay, selectedLocation?.timeZoneId?.label)}
      </div>

      <div className="d-flex-column">
        <span className="subtitle-text white-color">Staff</span>
        <div className="d-flex just-bet">
          <div className="flex-center gap-5">
            <div className="description-color required-staff" /> Required staff
          </div>
          {data?.graphStaffData?.requiredStaffTotal}
        </div>

        <div className="d-flex just-bet">
          <div className="flex-center gap-5">
            <div className="description-color filled-shift" /> Filled shifts
          </div>
          {data?.graphStaffData?.filledShiftTotal}
        </div>

        <div className="d-flex just-bet">
          <span>Budgeted hours </span> {data?.graphStaffData?.requiredStaffTotal}
        </div>

        <div className="d-flex just-bet">
          <div className="flex-center gap-5">
            <div className="description-color all-shift" /> All shifts
          </div>
          {data?.graphStaffData?.allShiftTotal}
        </div>
      </div>

      <div className="d-flex-column">
        <span className="subtitle-text white-color">Wages</span>
        <div className="d-flex just-bet">
          <div className="flex-center gap-5">
            <div className="description-color wages" /> Scheduled wages
          </div>
          {data?.graphWagesData?.scheduleWagesTotal}
        </div>
        <div className="d-flex just-bet">
          <div className="flex-center gap-5">
            <div className="description-color wages-dashed" /> Actual wages
          </div>
          {data?.graphWagesData?.scheduleWagesTotal}
        </div>

        <div className="d-flex just-bet">
          <div className="flex-center gap-5">
            <div className="description-color wages-dashed" /> Budgeted wages
          </div>
          {data?.graphWagesData?.budgetedWagesTotal}
        </div>
      </div>

      <div className="d-flex-column">
        <span className="subtitle-text white-color">Sales</span>
        <div className="d-flex just-bet">
          <div className="flex-center gap-5">
            <div className="description-color sales" /> Forecast
          </div>
          {data?.graphMetricData?.payrollEzyForecastTotal}
        </div>
        <div className="d-flex just-bet">
          <div className="flex-center gap-5">
            <div className="description-color sales-dashed" /> Manager forecast
          </div>
          {data?.graphMetricData?.managerForecastTotal}
        </div>

        <div className="d-flex just-bet">
          <div className="flex-center gap-5">
            <div className="description-color sales-dashed" /> Actual
          </div>
          {data?.graphMetricData?.actualTotal}
        </div>
      </div>

      <div className="d-flex-column">
        <span className="subtitle-text white-color">Sales per hour</span>
        <div className="d-flex just-bet">
          <span>Forecast</span>
          {data?.graphSalesPerHourData?.forecastSalesPerHour}
        </div>

        <div className="d-flex just-bet">
          <span>Scheduled</span>
          {data?.graphSalesPerHourData?.scheduleSalesPerHour}
        </div>

        <div className="d-flex just-bet">
          <span>Actual</span>
          {data?.graphSalesPerHourData?.actualSalesPerHour}
        </div>
      </div>

      <div className="d-flex-column">
        <span className="subtitle-text white-color">Labor % </span>
        <div className="d-flex just-bet">
          <span>Scheduled</span>
          {data?.graphLaborData?.scheduledLabor}
        </div>
        <div className="d-flex just-bet">
          <span>Actual</span>
          {data?.graphLaborData?.actualLabor}
        </div>
      </div>
    </div>
  );
}

SummaryTooltip.propTypes = {
  data: PropTypes.object.isRequired,
  selectedLocation: PropTypes.object.isRequired,
};
