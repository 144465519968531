import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InfoModal from '../../components/infoModal/InfoModal';
import useKeyQueryParams from '../../hooks/useKeyQueryParams';
import checkEmailImage from '../../assets/images/Check your email.png';
import linkNotFound from '../../assets/images/Link not found.png';
import ROUTE_CONSTANTS from '../../utils/constants';
import { verifyEmailFromLinkAction } from './redux/userAction';
import {
  clearAuthTokenFromLocalStorage,
  clearFCMTokenFromLocalStorage,
  getFCMTokenFromLocalStorage,
} from '../../utils/localStorageHelper';
import authServices from './authServices';
import { errorNotification } from '../../utils/notifyHelper';

function VerifyEmail() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [isExpireModal, setIsExpireModal] = useState(false);

  const { verifyEmailFromLinkActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const { key } = useKeyQueryParams();

  useEffect(() => {
    if (key) {
      (async () => {
        const res = await dispatch(verifyEmailFromLinkAction({ key }));
        if (res) {
          setIsSuccessModal(true);
        } else {
          setIsExpireModal(true);
        }
      })();
    }
  }, [key]);
  const logout = async () => {
    try {
      const clientToken = getFCMTokenFromLocalStorage();

      const res = await authServices.logout({ clientToken });
      if (res.data.success) {
        clearAuthTokenFromLocalStorage();
        clearFCMTokenFromLocalStorage();
        dispatch({ type: 'USER_LOGOUT' });
        history.push(ROUTE_CONSTANTS.LOGIN);
      }
    } catch (e) {
      errorNotification(e);
    }
  };
  return (
    <>
      <InfoModal
        img={checkEmailImage}
        title="Email updated successfully !"
        open={isSuccessModal && !verifyEmailFromLinkActionLoader}
        action={
          <div className="subtitle-text ">
            To login with your new email,
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span onClick={logout} className="secondary-action-text">
              click here.
            </span>
          </div>
        }
        onClose={() => {
          setIsSuccessModal(false);
          history.push(ROUTE_CONSTANTS.ACCOUNT_OVERVIEW);
        }}
      />
      <InfoModal
        img={linkNotFound}
        subTitle="Sorry!, Your link for change email is no longer valid. Request new one."
        title="Invalid link!"
        open={isExpireModal && !verifyEmailFromLinkActionLoader}
        onClose={() => {
          setIsExpireModal(false);
          history.push(ROUTE_CONSTANTS.ACCOUNT_OVERVIEW);
        }}
      />
    </>
  );
}

export default VerifyEmail;
