import {
  startGeneralLoader,
  stopGeneralLoader,
} from '../../../utils/backOfficeLists/commonDataSlice';
import { displayErrors } from '../../../utils/errorNotifyHelper';
import { successNotification } from '../../../utils/notifyHelper';
import { newsFeedServices } from '../newsFeedServices';
import {
  addNewsFeedMedia,
  setNewsFeedList,
  setReceiverList,
  updateNewsFeedDocs,
  updateNewsFeedListDetails,
} from './newsFeedSlice';

export const getNewsFeedListAction = (params) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const finalParams = {
        ...params,
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getNewsFeedListActionLoader' }));
      const res = await newsFeedServices.getNewsFeedList(finalParams);

      if (res?.data?.success) {
        const data = res?.data?.data?.map((e) => {
          if (e?.isConfirmationRequired) {
            if (e?.confirmedList?.length) {
              const isConfirmed =
                e?.confirmedList?.filter(
                  (x) =>
                    x?.personDetailEntity?.personDetailId === state?.user?.profileData?.personId
                )?.length === 1;
              return { ...e, isConfirmed };
            }
            return { ...e, isConfirmed: false };
          }
          return e;
        });
        if (params?.page < 2) dispatch(setNewsFeedList({ ...res?.data, data }));
        else dispatch(updateNewsFeedDocs({ ...res?.data, data }));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getNewsFeedListActionLoader' }));
    }
  };
};

export const commentOnPostAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'commentOnPostActionLoader' }));
      const res = await newsFeedServices.commentOnPost(data);
      if (res?.data?.success) {
        cb(res?.data?.data[0]);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'commentOnPostActionLoader' }));
    }
  };
};

export const deleteNewsFeedPostAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'deleteNewsFeedPostActionLoader' }));
      const res = await newsFeedServices.deleteNewsFeedPost(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deleteNewsFeedPostActionLoader' }));
    }
  };
};

export const deletePostCommentAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'deletePostCommentActionLoader' }));
      const res = await newsFeedServices.deletePostComment(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deletePostCommentActionLoader' }));
    }
  };
};

export const confirmNewsFeedPostAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'confirmNewsFeedPostActionLoader' }));
      const res = await newsFeedServices.confirmNewsFeedPost(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'confirmNewsFeedPostActionLoader' }));
    }
  };
};

export const addNewsFeedPostAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'addNewsFeedPostActionLoader' }));
      const res = await newsFeedServices.sendNewsFeedDrawer(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb(res?.data);
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'addNewsFeedPostActionLoader' }));
    }
  };
};

export const uploadMediaAction = (data, config) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'uploadMediaActionLoader' }));
      const res = await newsFeedServices.uploadMedia(data, config);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        dispatch(addNewsFeedMedia(res?.data?.data));
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'uploadMediaActionLoader' }));
    }
  };
};

export const deleteNewsFeedFileAction = (data, config) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'deleteNewsFeedFileActionLoader' }));
      const res = await newsFeedServices.deleteNewsFeedFile(data, config);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deleteNewsFeedFileActionLoader' }));
    }
  };
};

export const getNewsFeedReceiverListAction = (params, cb) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const finalParams = {
        ...params,
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getNewsFeedReceiverListActionLoader' }));
      const res = await newsFeedServices.getNewsFeedReceiverList(finalParams);
      if (res?.data?.success) {
        dispatch(setReceiverList(res?.data?.data));
        if (cb && typeof cb === 'function') {
          cb(res?.data?.data);
        }
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getNewsFeedReceiverListActionLoader' }));
    }
  };
};

export const sharePostWithOthersAction = (data, cb) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(startGeneralLoader({ loaderFor: 'sharePostWithOthersActionLoader' }));
      const res = await newsFeedServices.sharePostWithOthers(data);
      if (res?.data?.success) {
        dispatch(
          updateNewsFeedListDetails({
            fieldName: 'docs',
            value: state?.newsFeed?.newsFeedList?.docs?.map((e) => {
              if (e?.id === data?.id) {
                return {
                  ...e,
                  assignToPerson: [...e.assignToPerson, ...data.assignToPerson],
                  assignToLocation: [...e.assignToLocation, ...data.assignToLocation],
                };
              }
              return e;
            }),
          })
        );
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'sharePostWithOthersActionLoader' }));
    }
  };
};
