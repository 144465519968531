import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  getBillingAndAccountDetailsAction,
  getPaymentMethodsAction,
} from '../../redux/globalProfileActions';
import Loader from '../../../../components/common/loader/Loader';
import { displayDate } from '../../../../utils/timeHelper';
import ROUTE_CONSTANTS from '../../../../utils/constants';
import EzyButton from '../../../../components/common/button/EzyButton';
import AddonUsers from './AddonUsers';
import { updateBillingDetails } from '../../redux/globalProfileSlice';

function ViewBillingDetails() {
  const { paramId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { billingDetails, paymentMethods } = useSelector(
    ({ globalProfile }) => globalProfile || {}
  );
  const { timeZone } = useSelector(({ user }) => user?.profileData ?? {});

  const { getBillingAndAccountDetailsActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const {
    currentPlan,
    addressDetails,
    daysRemaining,
    email,
    name,
    nextBillingDate,
    planDuration,
    totalEmployee,
    totalUserOnPayRollEZY,
    prePaidUser,
    subscriptionEndDate,
    renewalDate,
    previousPaln,
    validtill,
    planId,
  } = useMemo(() => billingDetails ?? {}, [billingDetails]);

  const onAddOnUSerClick = () => {
    dispatch(
      updateBillingDetails({
        fieldName: 'isAddOnUserDrawer',
        value: true,
      })
    );
  };

  const isRenewelOccuring = moment(new Date()).isAfter(
    moment(subscriptionEndDate).subtract(15, 'day')
  );

  const handleRenew = () => {
    history.push(`${ROUTE_CONSTANTS.ACCOUNT_OVERVIEW}/billing/${paramId}?planId=${planId}`);
  };

  useEffect(() => {
    (async () => {
      await dispatch(getBillingAndAccountDetailsAction({ businessId: paramId }));
      await dispatch(getPaymentMethodsAction({ businessId: paramId }));
    })();
  }, [paramId]);

  if (getBillingAndAccountDetailsActionLoader) {
    return <Loader />;
  }

  if (currentPlan === 'Trial') {
    return (
      <div className="view-bill-wrapper">
        <div className="view-bill-field-wrapper">
          <div className="value">
            You are currently on the Trial Plan ({daysRemaining} days remaining -&nbsp;
            <span
              className="href-link"
              onClick={() => {
                history.push(`${ROUTE_CONSTANTS.ACCOUNT_OVERVIEW}/plans/${paramId}`);
              }}
            >
              Upgrade now
            </span>
            )
          </div>
        </div>
      </div>
    );
  }

  if (!currentPlan && previousPaln && validtill) {
    return (
      <div className="view-bill-wrapper">
        <div className="view-bill-field-wrapper">
          <div className="value">
            {`You have cancelled the Payroll Ezy "Premium" plan, your current plan is valid till ${displayDate(
              validtill,
              timeZone?.label
            )} (`}
            <span
              className="href-link"
              onClick={() => {
                history.push(`${ROUTE_CONSTANTS.ACCOUNT_OVERVIEW}/plans/${paramId}`);
              }}
            >
              Upgrade now
            </span>
            )
          </div>
        </div>
      </div>
    );
  }

  if (!currentPlan && !previousPaln) {
    return (
      <div className="view-bill-wrapper">
        <div className="view-bill-field-wrapper">
          <div className="value">
            Currently you are not a part of any Payroll Ezy plans, you can purchase a new plan from
            - &nbsp;
            <span
              className="href-link"
              onClick={() => {
                history.push(`${ROUTE_CONSTANTS.ACCOUNT_OVERVIEW}/plans/${paramId}`);
              }}
            >
              Here
            </span>
            )
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="view-bill-wrapper">
        {planDuration === 'Monthly' && (
          <div className="view-bill-field-wrapper three-grid">
            <div className="d-flex-column">
              <div className="text-secondary-color body-text">Current plan</div>
              <div className="value">{currentPlan}</div>
            </div>
            <div className="d-flex-column">
              <div className="text-secondary-color body-text">Next billing date</div>
              <div className="value">
                {displayDate(nextBillingDate, timeZone?.label)}&nbsp;
                {totalEmployee >= 0 ? `| ${totalEmployee} Employees used this month` : ''}
              </div>
            </div>
            <div className="d-flex-column">
              <div className="text-secondary-color body-text">Invoice will be emailed to</div>
              <div className="value">{email}</div>
            </div>
          </div>
        )}
        {planDuration === 'Yearly' && (
          <div className="view-bill-field-wrapper three-grid">
            <div className="d-flex-column">
              <div className="text-secondary-color body-text">Current plan</div>
              <div className="value">{currentPlan}</div>
            </div>
            <div className="d-flex-column">
              <div className="text-secondary-color body-text">
                Pre-Paid User (Additional user are charged at the monthly rate.)
              </div>
              <div className="value">{prePaidUser}</div>
            </div>
            <div className="d-flex-column">
              <div className="text-secondary-color body-text">Total User on PayrollEzy</div>
              <div className="value">{totalUserOnPayRollEZY}</div>
            </div>
          </div>
        )}
        {planDuration === 'Yearly' && (
          <div className="view-bill-field-wrapper two-grid">
            <div className="d-flex-column">
              <div className="text-secondary-color body-text">
                Subscription ends (Your subscription will switch to monthly billing after your
                annual subscription ends.)
              </div>
              <div className="value">{displayDate(subscriptionEndDate, timeZone?.label)}</div>
            </div>
            <div className="d-flex-column">
              <div className="text-secondary-color body-text">Renewal annual subscription</div>
              <div className="value">{displayDate(renewalDate, timeZone?.label)}</div>
            </div>
          </div>
        )}
        <div className="view-bill-field-wrapper two-grid">
          <div className="d-flex-column">
            <div className="text-secondary-color body-text">Name</div>
            <div className="value">{name}</div>
          </div>
          <div className="d-flex-column">
            <div className="text-secondary-color body-text">Address</div>
            <div className="value">{addressDetails}</div>
          </div>
        </div>
        {paymentMethods?.length > 0 &&
          currentPlan &&
          currentPlan !== 'Trial' &&
          paymentMethods?.map(({ cardNumber, expYear, expMonth }, index) => (
            <div className="view-bill-field-wrapper two-grid">
              <div className="d-flex-column" key={index}>
                <div className="text-secondary-color body-text">Card details</div>
                <div className="value">{cardNumber ? `**** **** **** ${cardNumber}` : '-'}</div>
              </div>
              <div className="d-flex-column">
                <div className="text-secondary-color body-text">Valid thru</div>
                <div className="value">
                  {expMonth || '-'} / {expYear || '-'}
                </div>
              </div>
            </div>
          ))}
        {planDuration === 'Yearly' && (
          <div className="d-flex gap-10 just-right">
            {isRenewelOccuring && <EzyButton label="Renew" onClick={handleRenew} />}
            <EzyButton label="Purchase Additional Users" onClick={onAddOnUSerClick} />
          </div>
        )}
      </div>
      <AddonUsers />
    </div>
  );
}

export default ViewBillingDetails;
