import { useLocation } from 'react-router-dom';
import { compact } from 'lodash';

const useKeyQueryParams = () => {
  const queries = compact(useLocation().search?.split('?'));
  const params = {};
  queries.forEach((e) => {
    const query = e?.split(/=(.*)/s);
    params[`${query[0]}`] = query[1];
  });
  return params;
};

export default useKeyQueryParams;
