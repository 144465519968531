import { useEffect, useState } from 'react';
import { FormHelperText } from '@mui/material';
import { isEmpty } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import EzyDialog from '../../components/common/dialog/EzyDialog';
import blackLogo from '../../assets/images/logo/PayrollEzy_BlackLogo.png';
import EzyInputField from '../../components/common/inputField/EzyInputField';
import EzyButton from '../../components/common/button/EzyButton';
import validateSignup from './validations/validateSignup';
import EzySwitch from '../../components/common/switch/EzySwitch';
import authServices from './authServices';
import InfoModal from '../../components/infoModal/InfoModal';
import thankYouImage from '../../assets/images/Thank you.png';
import ROUTE_CONSTANTS from '../../utils/constants';
import { displayErrors } from '../../utils/errorNotifyHelper';
import { getAllTimezonesAction } from '../../utils/backOfficeLists/backOfficeListActions';
import { mapLabelFromList } from '../../utils/enumMapping';
import useKeyPress from '../../hooks/useKeyPress';

function Signup() {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [isSignUpModal, setIsSignUpModal] = useState(true);
  const dispatch = useDispatch();
  const isEnterPressed = useKeyPress('Enter');

  const { timeZonesList } = useSelector(({ common }) => common ?? {});

  const history = useHistory();

  const updateFormData = (e) => {
    const { name, value } = e.target;
    setFormData((data) => ({ ...data, [name]: value }));
  };

  const handleTncChange = (e) => {
    const { name } = e.target;
    setFormData((data) => ({ ...data, [name]: !data[name] }));
  };

  const handleSubmit = async () => {
    const errorsData = validateSignup(formData);
    setErrors(errorsData);

    if (isEmpty(errorsData)) {
      try {
        setLoading(true);

        const timezone = mapLabelFromList(moment.tz.guess(), timeZonesList);

        const res = await authServices.businessSignUp({
          name: formData.username.toString().trim(),
          email: formData.email.toString().trim(),
          timeZoneId: timezone?.id,
        });
        if (res?.data?.success) {
          setIsSuccessModal(true);
          setIsSignUpModal(false);
        }
      } catch (e) {
        displayErrors(e);
        /**/
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    dispatch(getAllTimezonesAction());
  }, []);

  useEffect(() => {
    if (isEnterPressed) handleSubmit();
  }, [isEnterPressed]);

  return (
    <div>
      <EzyDialog handleClose={() => {}} open={isSignUpModal} className="auth-dialog">
        <div className="auth-wrapper">
          <img src={blackLogo} alt="logo" width={200} />
          <div className="info-text">
            <div className="primary-large-title">Create an account</div>
            <div className="subtitle-text">Start your 14 days free trial</div>
          </div>
          <div className="auth-form">
            <div className="field-wrapper">
              <EzyInputField
                label="Username"
                placeholder="Enter your username"
                name="username"
                onChange={updateFormData}
                value={formData.username}
                error={errors?.username}
                required
              />
              <EzyInputField
                label="User email"
                placeholder="Enter your email address"
                name="email"
                onChange={updateFormData}
                value={formData.email}
                error={errors?.email}
                required
              />
            </div>
            <div className="tnc-wrapper">
              <div className="tnc-section">
                <EzySwitch
                  color="secondary"
                  name="tnc"
                  checked={formData.tnc}
                  handleChange={handleTncChange}
                />
                <div className="body-text">
                  I agree to the{' '}
                  <span
                    className="secondary-action-text"
                    onClick={() => window.open(ROUTE_CONSTANTS.TERMS_AND_CONDITION, '_blank')}
                  >
                    terms and conditions
                  </span>{' '}
                  &{' '}
                  <span
                    className="secondary-action-text"
                    onClick={() => window.open(ROUTE_CONSTANTS.PRIVACY_POLICY, '_blank')}
                  >
                    privacy policy
                  </span>
                  .
                </div>
              </div>
              {errors?.tnc && (
                <FormHelperText className="error-helper">{errors?.tnc}</FormHelperText>
              )}
            </div>
            <div className="action-button">
              <EzyButton label="Register" onClick={handleSubmit} loading={loading} />
            </div>
            <div className="auth-redirect-text">
              Already have an account?{' '}
              <Link to="/login" className="secondary-action-text">
                Login
              </Link>
            </div>
          </div>
        </div>
      </EzyDialog>

      <InfoModal
        img={thankYouImage}
        subTitle="We just sent you an email with your login details. Please check your email to see your credentials."
        title="Thank you for registering!"
        open={isSuccessModal}
        onClose={() => history.push(ROUTE_CONSTANTS.LOGIN)}
      />
    </div>
  );
}

export default Signup;
