import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty, isNil, omitBy } from 'lodash';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import {
  updateAppIntegrationData,
  updateConfigurationDrawerDetails,
} from '../../redux/globalProfileSlice';
import EzySwitch from '../../../../components/common/switch/EzySwitch';
import IconByName from '../../../../components/common/iconByName/IconByName';
import logo from '../../../../assets/images/logo/Logo.svg';
import {
  activateConfigurationAction,
  commonIntegrationAPIAction,
  disconnectConfigurationAction,
  editConfigurationSettingsAction,
  getConfigurationInfoAction,
  getLocationSpecificConfigurationAction,
  getQboInvoiceApiConfigsAction,
  syncMyobLeaveBalanceAction,
} from '../../redux/globalProfileActions';
import EzyButton from '../../../../components/common/button/EzyButton';
import EzyAutoComplete from '../../../../components/common/autoComplete/EzyAutoComplete';
import { requireValidate } from '../../../../utils/validationHelper';

function ConfigurationActionDrawer() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { configurationDrawer, specificIntegrationDetails, selectedLocation } = useSelector(
    ({ globalProfile }) => globalProfile?.appIntegration || {}
  );

  const { businessId } = useSelector(({ user }) => user?.profileData || {});

  const handleClose = () => {
    dispatch(updateAppIntegrationData({ fieldName: 'configurationDrawer', value: {} }));
  };

  const {
    getConfigurationInfoActionLoader,
    syncMyobLeaveBalanceActionLoader,
    commonIntegrationAPIActionLoader,
    disconnectConfigurationActionLoader,
    editConfigurationSettingsActionLoader,
    activateConfigurationActionLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});

  const handleFieldChange = (fieldName, value) => {
    dispatch(updateConfigurationDrawerDetails({ fieldName, value }));
  };

  const handleConnect = (label) => {
    let error = {};

    if (configurationDrawer?.payRollEzyAPIName === 'Create Sale') {
      error.payrollArea = requireValidate(
        'Payroll Ezy area',
        configurationDrawer?.areaId?.id || configurationDrawer?.qboInvoiceConfig?.enumId
      );

      error = omitBy(error, isNil);
    }

    if (isEmpty(error)) {
      const data = {
        businessId,
        locationId: selectedLocation,
        integrationApiId: configurationDrawer?.apiIntegrationId,
        isActivateForAllLocations: configurationDrawer?.isActivateForAllLocations,
        qboInvoiceConfig: configurationDrawer?.qboInvoiceConfig?.enumId,
        areaId: configurationDrawer?.areaId?.id,
      };

      if (label === 'Connect') {
        dispatch(
          activateConfigurationAction(data, () => {
            handleClose();
            dispatch(
              getLocationSpecificConfigurationAction({
                integrationId: id,
                locationId: selectedLocation,
              })
            );
          })
        );
      } else if (label === 'Delete') {
        dispatch(
          disconnectConfigurationAction(data, () => {
            handleClose();
            dispatch(
              getLocationSpecificConfigurationAction({
                integrationId: id,
                locationId: selectedLocation,
              })
            );
          })
        );
      } else {
        dispatch(
          editConfigurationSettingsAction(data, () => {
            handleClose();
            dispatch(
              getLocationSpecificConfigurationAction({
                integrationId: id,
                locationId: selectedLocation,
              })
            );
          })
        );
      }
    } else {
      handleFieldChange('errors', error);
    }
  };

  const activateConfigurationActionButton = [
    {
      label: 'Connect',
      onClick: () => handleConnect('Connect'),
      loading: activateConfigurationActionLoader,
    },
  ];

  const editConfigurationActionButtons = [
    {
      label: 'Delete',
      color: 'error',
      onClick: () => handleConnect('Delete'),
      loading: disconnectConfigurationActionLoader,
    },
    {
      label: 'Save',
      onClick: () => handleConnect('Save'),
      loading: editConfigurationSettingsActionLoader,
    },
  ];

  const handleRunNow = () => {
    if (specificIntegrationDetails?.integrationEntity?.integrationName === 'Myob Business') {
      dispatch(
        syncMyobLeaveBalanceAction(
          {
            locationId: selectedLocation,
            integrationId: specificIntegrationDetails?.integrationEntity?.integrationId,
          },
          () => {
            handleClose();
          }
        )
      );
    } else {
      dispatch(
        commonIntegrationAPIAction(
          {
            locationId: selectedLocation,
            integrationId: specificIntegrationDetails?.integrationEntity?.integrationId,
            integrationApiId: configurationDrawer?.apiIntegrationId,
            businessId,
            isActivateForAllLocations: configurationDrawer?.isActivateForAllLocations,
            qboInvoiceConfig: configurationDrawer?.qboInvoiceConfig?.enumId,
            areaId: configurationDrawer?.areaId?.id,
          },
          () => {
            handleClose();
          }
        )
      );
    }
  };

  const action = (
    <EzyButton
      label="Run now"
      color="secondary"
      onClick={() => handleRunNow()}
      loading={syncMyobLeaveBalanceActionLoader || commonIntegrationAPIActionLoader}
    />
  );

  useEffect(() => {
    if (configurationDrawer?.apiIntegrationId) {
      dispatch(
        getConfigurationInfoAction({
          integrationApiId: configurationDrawer?.apiIntegrationId,
          locationId: selectedLocation,
        })
      );
    }
  }, [configurationDrawer?.apiIntegrationId]);

  useEffect(() => {
    if (configurationDrawer?.payRollEzyAPIName === 'Create Sale') {
      dispatch(getQboInvoiceApiConfigsAction({ locationId: selectedLocation }));
    }
  }, [configurationDrawer?.payRollEzyAPIName]);

  return (
    <EzyDrawer
      isOpen={!!configurationDrawer?.apiIntegrationId}
      onClose={handleClose}
      heading={configurationDrawer?.integrationAPIName}
      actionButtons={
        configurationDrawer?.isAlreadyActivated
          ? editConfigurationActionButtons
          : activateConfigurationActionButton
      }
      isLoading={getConfigurationInfoActionLoader}
      leftAction={configurationDrawer?.isAlreadyActivated && action}
    >
      <div className="configuration-action-drawer">
        <div className="app-configuration-details">
          {configurationDrawer?.isSwapAPIValues ? (
            <div className="configuration">
              <img src={logo} alt="logo" />
              <span>{configurationDrawer?.payRollEzyAPIName}</span>
              <IconByName name="play_arrow" className="primary-color icon-small" />
              <img
                src={specificIntegrationDetails?.integrationEntity?.logoPath}
                alt={specificIntegrationDetails?.integrationEntity?.integrationName}
              />
              <span>{configurationDrawer?.integrationAPIName}</span>
            </div>
          ) : (
            <div className="configuration">
              <img
                src={specificIntegrationDetails?.integrationEntity?.logoPath}
                alt={specificIntegrationDetails?.integrationEntity?.integrationName}
              />
              <span>{configurationDrawer?.integrationAPIName}</span>
              <IconByName name="play_arrow" className="primary-color icon-small" />
              <img src={logo} alt="logo" />
              <span>{configurationDrawer?.payRollEzyAPIName}</span>
            </div>
          )}
        </div>

        {configurationDrawer?.apiWarning && (
          <div className="info-div info">
            <IconByName name="info" />
            {configurationDrawer?.apiWarning}
          </div>
        )}

        {configurationDrawer?.payRollEzyAPIName === 'Create Sale' && (
          <>
            <EzyAutoComplete
              label="Invoice type"
              placeholder="Select invoice type"
              value={{ id: 1, label: 'Sales invoice' }}
              disabled
            />
            <EzyAutoComplete
              label="Payroll Ezy area"
              placeholder="Select area"
              name="payrollArea"
              options={configurationDrawer?.invoiceApiConfigDropdown}
              value={
                configurationDrawer?.areaId || configurationDrawer?.qboInvoiceConfig
                // configurationDrawer?.invoiceApiConfigDropdown?.find((e) => e?.isSelected)
              }
              onChange={(e, value) => {
                if (value?.id) {
                  handleFieldChange('qboInvoiceConfig', null);
                  handleFieldChange('areaId', value);
                } else {
                  handleFieldChange('qboInvoiceConfig', value);
                  handleFieldChange('areaId', null);
                }
              }}
              required
              error={configurationDrawer?.errors?.payrollArea}
            />
          </>
        )}

        <div className="configuration-info">{configurationDrawer?.apiInformation}</div>

        <EzySwitch
          label="Turn it all for all locations."
          checked={configurationDrawer?.isActivateForAllLocations}
          onChange={() =>
            dispatch(
              updateConfigurationDrawerDetails({
                fieldName: 'isActivateForAllLocations',
                value: !configurationDrawer?.isActivateForAllLocations,
              })
            )
          }
        />
      </div>
    </EzyDrawer>
  );
}

export default ConfigurationActionDrawer;
