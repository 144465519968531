import { Fade, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

function EzyTooltip({ placement, title, children, className, ...restOther }) {
  return (
    <Tooltip
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 300 }}
      title={title}
      placement={placement}
      {...restOther}
      className={`ezy-tooltip ${className}`}
    >
      <span>{children}</span>
    </Tooltip>
  );
}

export default EzyTooltip;
EzyTooltip.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.element,
  className: PropTypes.string,
};
EzyTooltip.defaultProps = {
  placement: 'top',
  children: null,
  className: null,
};
