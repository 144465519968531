import apiService from '../../utils/apiService';
import API_URL from '../../utils/urlConstants';

export const tasksServices = {
  getTasks: (params) => apiService.getData(API_URL.TASKS.tasks, { params }),
  getTasksByArea: (params) => apiService.getData(API_URL.TASKS.tasksByArea, { params }),
  addTasksForPerson: (data) => apiService.postData(API_URL.TASKS.tasksForPerson, data),
  addTaskForWholeArea: (data) => apiService.postData(API_URL.TASKS.taskForWholeArea, data),
  getTaskDetailsById: (params) => apiService.getData(API_URL.TASKS.taskDetailsById, { params }),
  editTask: (data) => apiService.putData(API_URL.TASKS.editTask, data),
  changeSubTaskStatus: (data) => apiService.putData(API_URL.TASKS.changeSubTaskStatus, [data]),
  changeTaskStatus: (data) => apiService.putData(API_URL.TASKS.changeTaskStatus, data),
  deleteTask: (params) => apiService.deleteData(API_URL.TASKS.deleteTask, { params }),
  getTaskForWholeAreaById: (params) =>
    apiService.getData(API_URL.TASKS.taskForWholeAreaById, { params }),
  editTaskForWholeArea: (data) => apiService.putData(API_URL.TASKS.editTaskForWholeArea, data),
};
