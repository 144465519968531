import { omitBy, isNil } from 'lodash';
import { requireValidate } from '../../../utils/validationHelper';
// import { checkIsEmpty, requireValidate } from '../../../utils/validationHelper';

const validateAddNewsFeed = (data) => {
  const { assignToLocation, assignToPerson, newsContent } = data ?? {};

  const error = {};
  const arrayOfSelectedPeopleAndSelectedLocation = [...assignToLocation, ...assignToPerson];
  error.newsContent = requireValidate('message', newsContent);
  if (arrayOfSelectedPeopleAndSelectedLocation.length === 0) {
    error.assignToLocation = 'Please select people or location';
    error.assignToPerson = 'Please select people or location';
  }
  return omitBy(error, isNil);
};

export default validateAddNewsFeed;
