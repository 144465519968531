import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import EzyButton from '../../../../components/common/button/EzyButton';
import { updateAppIntegrationData } from '../../redux/globalProfileSlice';
import { getLocationSpecificConfigurationAction } from '../../redux/globalProfileActions';
import Loader from '../../../../components/common/loader/Loader';

function AppIntegrationSideBar() {
  const dispatch = useDispatch();
  const { specificIntegrationDetails, selectedLocation } = useSelector(
    ({ globalProfile }) => globalProfile?.appIntegration || {}
  );
  const { getIntegrationDetailsActionLoader, connectIntegrationWithLocationActionLoader } =
    useSelector(({ common }) => common.generalLoader ?? {});

  const { id } = useParams();

  const { integrationEntity, locationList } = useMemo(
    () => specificIntegrationDetails,
    [specificIntegrationDetails]
  );

  const fetchConfigurationInfo = (locationId) => {
    if (locationId) {
      dispatch(getLocationSpecificConfigurationAction({ locationId, integrationId: id }));

      dispatch(updateAppIntegrationData({ fieldName: 'selectedLocation', value: locationId }));
    }
  };

  return (
    <div className="app-integration-sidebar">
      {getIntegrationDetailsActionLoader || connectIntegrationWithLocationActionLoader ? (
        <Loader />
      ) : (
        <>
          <img src={integrationEntity?.logoPath} alt={integrationEntity?.integrationName} />
          <div className="icon-small">{integrationEntity?.integrationName}</div>
          <div className="text-secondary-color text-center body-text">
            {integrationEntity?.tagLine}
          </div>

          <EzyButton
            label={locationList?.length > 0 ? 'Connect new location' : 'Connect add-on'}
            className="no-pad-button"
            onClick={() =>
              dispatch(updateAppIntegrationData({ fieldName: 'newLocationDrawer', value: true }))
            }
          />

          <div className="app-integration-location-list">
            {locationList &&
              locationList.map((location) => {
                return (
                  <div
                    className={`app-integration-location-details ${
                      location?.locationId === selectedLocation && 'selected-details-field'
                    }`}
                    key={location?.locationId}
                    onClick={() => {
                      fetchConfigurationInfo(location?.locationId);
                    }}
                  >
                    <div>{location?.locationName}</div>
                    <div className="text-secondary-color body-text">
                      {location?.locationAddress}
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
}

export default AppIntegrationSideBar;
