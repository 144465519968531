import { Divider, IconButton, Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import EzyButton from '../button/EzyButton';
import EzyIconButton from '../iconButton/EzyIconButton';
import IconByName from '../iconByName/IconByName';
import AccessWrapper from '../accessWrapper/AccessWrapper';
import useAccess from '../../../hooks/useAccess';

function EzyMenu({
  label,
  variant,
  color,
  iconName,
  menuItems,
  menuItemsIcon,
  className,
  disabled,
  onItemClick,
  selectedItem,
  loading,
  access,
  isOnlyIcon,
  ...restProps
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuWidth, setMenuWidth] = useState(140);
  const menuRef = useRef();
  const open = Boolean(anchorEl);

  const checkedAccess = useAccess(access);

  // Checking for access arrays and bool
  const hasAccess = useMemo(() => {
    if (Array.isArray(checkedAccess) && checkedAccess?.length <= 0) return false;
    return checkedAccess;
  }, [checkedAccess]);

  const handleClick = (event) => {
    event?.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useLayoutEffect(() => {
    setMenuWidth(menuRef?.current?.offsetWidth);
  }, [menuRef?.current?.offsetWidth]);

  return (
    // <AccessWrapper access={access} byPass={!access}>
    <div className={`ezy-menu ${className}`}>
      {label && (
        <EzyButton
          ref={menuRef}
          disabled={disabled || !hasAccess}
          variant={variant}
          label={label}
          color={color}
          startIcon={<IconByName name={iconName} />}
          endIcon={<IconByName name="expand_more" />}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          loading={loading}
        />
      )}
      {iconName && !isOnlyIcon && !label && (
        <EzyIconButton
          disabled={disabled || !hasAccess}
          variant={variant}
          color={color}
          iconName={iconName}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          loading={loading}
          tooltip={restProps?.tooltip}
        />
      )}
      {iconName && isOnlyIcon && (
        <IconButton
          disabled={disabled || !hasAccess}
          className="material-icons pointer-cursor menu-icon"
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <IconByName name={iconName} />
        </IconButton>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        className={`ezy-menu-list ${className}`}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          style: {
            width: restProps?.autoWidth ? 'auto' : menuWidth,
          },
        }}
        {...restProps}
      >
        {menuItems?.length > 0 ? (
          menuItems?.map((item) => {
            return (
              <AccessWrapper access={item?.access} byPass={!item?.access}>
                <MenuItem
                  onClick={(e) => {
                    handleClose();
                    onItemClick(e, item);
                    if (item?.onClick) {
                      item?.onClick(e, item);
                    }
                  }}
                  key={item?.label}
                  selected={item?.id === selectedItem?.id}
                  disabled={item.disabled}
                  className={
                    (className === 'time-sheets-location-menu' ||
                      className === 'schedule-location-menu') &&
                    (item?.locationId ? 'area-menu-item' : 'location-menu-list')
                  }
                >
                  <div className="manu-list-value">
                    {menuItemsIcon && <span className="material-icons">{iconName}</span>}
                    {item?.label}
                  </div>
                </MenuItem>
                {item?.isDividerAfter && <Divider />}
              </AccessWrapper>
            );
          })
        ) : (
          <MenuItem disabled>
            <div className="manu-list-value w-full text-center">No Records</div>
          </MenuItem>
        )}
      </Menu>
    </div>
    // </AccessWrapper>
  );
}

export default EzyMenu;

EzyMenu.propTypes = {
  label: PropTypes.string,
  menuItems: PropTypes.array.isRequired,
  color: PropTypes.string,
  variant: PropTypes.string,
  iconName: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  menuItemsIcon: PropTypes.bool,
  onItemClick: PropTypes.func,
  loading: PropTypes.bool,
  selectedItem: PropTypes.bool,
  access: PropTypes.string,
  isOnlyIcon: PropTypes.bool,
};

EzyMenu.defaultProps = {
  color: 'primary',
  variant: 'contained',
  iconName: '',
  label: '',
  className: '',
  disabled: false,
  menuItemsIcon: false,
  selectedItem: false,
  loading: false,
  onItemClick: () => {},
  access: null,
  isOnlyIcon: false,
};
