import {
  startGeneralLoader,
  stopGeneralLoader,
} from '../../../utils/backOfficeLists/commonDataSlice';
import { downloadAll } from '../../../utils/downloadHelper';
import { displayErrors } from '../../../utils/errorNotifyHelper';
import { successNotification } from '../../../utils/notifyHelper';
import { exportTimeSheetServices } from '../exportTimesheetsServices';
import {
  setPayRateSummaryList,
  setPersonWiseTimeSheetsList,
  updateExportTimeSheetData,
  updateExportTimesheetDrawerDetails,
  updateSidePanelData,
} from './exportTimesheetSlice';

export const getExportTimeSheetDetailsAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getExportTimeSheetDetailsActionLoader' }));
      const res = await exportTimeSheetServices.getExportTimeSheetDetails(params);
      if (res?.data?.success) {
        dispatch(updateSidePanelData({ fieldName: 'personsList', value: res?.data?.data }));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getExportTimeSheetDetailsActionLoader' }));
    }
  };
};

export const exportTimeSheetSummaryAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'exportTimeSheetSummaryActionLoader' }));
      const res = await exportTimeSheetServices.exportTimeSheetSummary(params);
      if (res?.data?.success) {
        dispatch(
          updateExportTimeSheetData({
            fieldName: 'selectedExportItemsSummary',
            value: res?.data?.data,
          })
        );
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'exportTimeSheetSummaryActionLoader' }));
    }
  };
};

export const exportTimesheetDetailsByPersonAction = (params, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'exportTimesheetDetailsByPersonActionLoader' }));
      const res = await exportTimeSheetServices.exportTimesheetDetailsByPerson(params);
      if (res?.data?.success) {
        dispatch(setPersonWiseTimeSheetsList(res?.data?.data?.listofPerson));
        cb(res?.data?.data?.listofPerson);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'exportTimesheetDetailsByPersonActionLoader' }));
    }
  };
};

export const markTimesheetAsPaidOrUnpaidAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'markTimesheetAsPaidOrUnpaidActionLoader' }));
      const res = await exportTimeSheetServices.markTimesheetAsPaidOrUnpaid(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'markTimesheetAsPaidOrUnpaidActionLoader' }));
    }
  };
};

export const getExportTypeDropdownListAction = () => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const params = {
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getExportTypeDropdownListActionLoader' }));
      const res = await exportTimeSheetServices.getExportTypeDropdownList(params);
      if (res?.data?.success) {
        dispatch(
          updateExportTimesheetDrawerDetails({
            fieldName: 'exportTypeList',
            value: res?.data?.data,
          })
        );
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getExportTypeDropdownListActionLoader' }));
    }
  };
};

export const exportTimesheetsAction = ({ exportTypeName, ...data }, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'exportTimesheetsActionLoader' }));
      const res = await exportTimeSheetServices.exportTimesheets(data);
      if (res) {
        if (exportTypeName === 'CSV/Excel - All Fields' || exportTypeName === 'Reckon Payroll')
          downloadAll(res);
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'exportTimesheetsActionLoader' }));
    }
  };
};

export const exportTimesheetDetailsByPayRatesAction = (data) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'exportTimesheetDetailsByPayRatesActionLoader' }));
      const res = await exportTimeSheetServices.exportTimesheetDetailsByPayRates(data);
      if (res?.data?.success) {
        dispatch(setPayRateSummaryList(res?.data?.data));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'exportTimesheetDetailsByPayRatesActionLoader' }));
    }
  };
};
