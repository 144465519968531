import apiService from '../../utils/apiService';
import API_URL from '../../utils/urlConstants';

const globalProfileServices = {
  getBusinessList: () => apiService.getData(API_URL.BUSINESS.getBusinessList),
  switchBusiness: (data) => apiService.postData(API_URL.BUSINESS.switchBusiness, data),
  saveClientToken: (data) => apiService.postData(API_URL.PEOPLE.saveClientToken, data),
  getNotifications: (params) => apiService.getData(API_URL.PEOPLE.notification, { params }),
  getAllInvoices: (params) => apiService.getData(API_URL.BUSINESS.BILL.getAllInvoices, { params }),
  getAllReceipts: (params) => apiService.getData(API_URL.BUSINESS.BILL.getAllReceipts, { params }),
  getInvoiceDetails: (params) =>
    apiService.getData(API_URL.BUSINESS.BILL.getInvoiceDetails, { params }),
  getPlans: (params) => apiService.getData(API_URL.BUSINESS.BILL.getPlans, { params }),
  getBillingAndAccountDetails: (params) =>
    apiService.getData(API_URL.BUSINESS.BILL.getBillingAndAccountDetails, { params }),
  getPaymentMethods: (params) =>
    apiService.getData(API_URL.BUSINESS.BILL.getPaymentMethods, { params }),
  getCheckoutSessionDetails: (params) =>
    apiService.getData(API_URL.BUSINESS.BILL.getCheckoutSessionDetails, { params }),
  getBillingAndPaymentDetails: (params) =>
    apiService.getData(API_URL.BUSINESS.BILL.getBillingAndPaymentDetails, { params }),
  createStripeCustomer: (data) =>
    apiService.postData(API_URL.BUSINESS.BILL.createStripeCustomer, data),
  createCheckOutSession: (data) =>
    apiService.postData(API_URL.BUSINESS.BILL.createCheckOutSession, data),
  cancelSubscription: (data) => apiService.postData(API_URL.BUSINESS.BILL.cancelSubscription, data),
  getOrderSummary: (params) =>
    apiService.getData(API_URL.BUSINESS.BILL.getOrderSummary, { params }),
  getGlobalProfileDetails: () => apiService.getData(API_URL.GLOBALPROFILE.getGlobalProfileDetails),
  editGlobalProfileDetails: (data) =>
    apiService.postData(API_URL.GLOBALPROFILE.editGlobalProfileDetails, data),
  uploadGlobalProfilePicture: (data, config) =>
    apiService.postData(API_URL.GLOBALPROFILE.uploadGlobalProfilePicture, data, {
      ...config,
      timeout: 2 * 60 * 1000,
    }),
  deleteGlobalProfilePicture: () =>
    apiService.deleteData(API_URL.GLOBALPROFILE.deleteGlobalProfilePicture),
  deleteNotifications: () => apiService.deleteData(API_URL.PEOPLE.deleteNotifications),
  deleteAccount: () => apiService.postData(API_URL.GLOBALPROFILE.deleteAccount),
  businessInvitationAction: (data) =>
    apiService.postData(API_URL.BUSINESS.businessInvitationAction, data),
  updateBusinessName: (data) => apiService.putData(API_URL.BUSINESS.updateBusinessName, data),
  getAllIntegrationsList: (params) =>
    apiService.getData(API_URL.GLOBALPROFILE.getAllIntegrationsList, { params }),
  getIntegrationDetails: (params) =>
    apiService.getData(API_URL.GLOBALPROFILE.getIntegrationDetails, { params }),
  getLocationList: (params) =>
    apiService.getData(API_URL.GLOBALPROFILE.getLocationList, { params }),
  getLocationSpecificConfiguration: (params) =>
    apiService.getData(API_URL.GLOBALPROFILE.getLocationSpecificConfiguration, { params }),
  getConfigurationInfo: (params) =>
    apiService.getData(API_URL.GLOBALPROFILE.getConfigurationInfo, { params }),
  activateConfiguration: (data) =>
    apiService.postData(API_URL.GLOBALPROFILE.activateConfiguration, data),
  disconnectConfiguration: (params) =>
    apiService.deleteData(API_URL.GLOBALPROFILE.disconnectConfiguration, { params }),
  disconnectLocationConfiguration: (params) =>
    apiService.deleteData(API_URL.GLOBALPROFILE.disconnectLocationConfiguration, { params }),
  connectIntegration: (params) =>
    apiService.getData(API_URL.GLOBALPROFILE.connectIntegration, { params }),
  editConfigurationSettings: (data) =>
    apiService.putData(API_URL.GLOBALPROFILE.editConfigurationSettings, data),
  connectIntegrationWithLocation: (data) =>
    apiService.postData(API_URL.GLOBALPROFILE.connectIntegrationWithLocation, data),
  getMyObFileDetails: (params) =>
    apiService.getData(API_URL.GLOBALPROFILE.getMyObFileDetails, { params }),
  saveMyObFileDetails: (data) =>
    apiService.postData(API_URL.GLOBALPROFILE.saveMyObFileDetails, data),
  syncMyobLeaveBalance: (data) =>
    apiService.postData(API_URL.GLOBALPROFILE.syncMyobLeaveBalance, data),
  commonIntegrationAPI: (data) =>
    apiService.postData(API_URL.GLOBALPROFILE.commonIntegrationAPI, data),
  autoSyncEmployees: (data) => apiService.putData(API_URL.GLOBALPROFILE.autoSyncEmployees, data),
  getQboInvoiceApiConfigs: (params) =>
    apiService.getData(API_URL.GLOBALPROFILE.getQboInvoiceApiConfigs, { params }),
};

export default globalProfileServices;
