import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormHelperText, InputAdornment } from '@mui/material';
import { isEmpty } from 'lodash';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import {
  addUpdateRangeRuleFields,
  resetAddRuleDrawer,
  updateAddRuleDrawer,
} from '../../redux/insightSlice';
import EzyToggleButtonGroup from '../../../../components/common/toggleButton/EzyToggleButtonGroup';
import EzyAutoComplete from '../../../../components/common/autoComplete/EzyAutoComplete';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import EzySwitch from '../../../../components/common/switch/EzySwitch';
import {
  addRuleAction,
  deleteRuleAction,
  getMetricListForRuleAction,
  getRuleDataByIdAction,
  getRuleListAction,
  updateRuleDataAction,
} from '../../redux/insightAction';
import EzyIconButton from '../../../../components/common/iconButton/EzyIconButton';
import validateAddRule from '../../validations/validateAddRule';
import EzyButton from '../../../../components/common/button/EzyButton';
import { displayTime } from '../../../../utils/timeHelper';

function AddRuleDrawer() {
  const dispatch = useDispatch();

  const { filters, staff } = useSelector(({ insight }) => insight ?? {});
  const { selectedLocation } = useMemo(() => filters, [filters]);
  const {
    isOpen,
    coverageType,
    isAnyTime,
    areaId,
    metricId,
    linearRule,
    ruleBuffer,
    rangeRule,
    addBuffer,
    errors,
  } = useMemo(() => staff?.addRuleDrawer, [staff?.addRuleDrawer]);

  const { coverageType: coverageTypeEnum } = useSelector(({ common }) => common?.enums ?? {});

  const {
    addRuleActionLoader,
    getRuleDataByIdActionLoader,
    getMetricListForRuleActionLoader,
    updateRuleDataActionLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});

  const handleClose = () => {
    dispatch(resetAddRuleDrawer());
  };

  const handleFieldChange = (fieldName, value, coverageField, index) => {
    if (index || index === 0) {
      dispatch(updateAddRuleDrawer({ fieldName, value, index }));
    } else {
      dispatch(updateAddRuleDrawer({ fieldName, value, coverageField }));
    }
  };

  const handleDeleteRule = () => {
    dispatch(
      deleteRuleAction({ ruleId: isOpen }, () => {
        handleClose();
        dispatch(
          getRuleListAction({
            locationId: selectedLocation?.id,
          })
        );
      })
    );
  };

  const handleSubmit = () => {
    const data = {
      ruleId: isOpen !== 'addRule' ? isOpen : null,
      locationId: selectedLocation?.id,
      areaId: areaId?.id,
      metricId: metricId?.id,
      metricName: metricId?.label,
      coverageType,
      isAnyTime,
      linearRule: coverageType === 1 ? linearRule : null,
      rangeRule: coverageType === 2 ? rangeRule : null,
      ruleBuffer: addBuffer ? ruleBuffer : null,
      isActuals: metricId?.label?.includes('Actual'),
    };
    const error = validateAddRule({ ...data, addBuffer });
    handleFieldChange('errors', error);
    if (isEmpty(error)) {
      if (isOpen === 'addRule')
        dispatch(
          addRuleAction(data, () => {
            handleClose();
            dispatch(
              getRuleListAction({
                locationId: selectedLocation?.id,
              })
            );
          })
        );
      else
        dispatch(
          updateRuleDataAction(data, () => {
            handleClose();
            dispatch(
              getRuleListAction({
                locationId: selectedLocation?.id,
              })
            );
          })
        );
    }
  };

  const actionButton = [
    {
      label: 'Save',
      onClick: handleSubmit,
      loading: addRuleActionLoader || updateRuleDataActionLoader,
    },
  ];

  useEffect(() => {
    (async () => {
      if (isOpen) await dispatch(getMetricListForRuleAction());
      if (isOpen && isOpen !== 'addRule') await dispatch(getRuleDataByIdAction({ ruleId: isOpen }));
    })();
  }, [isOpen]);

  return (
    <EzyDrawer
      isOpen={isOpen}
      onClose={handleClose}
      heading={isOpen === 'addRule' ? 'New Labor Rule' : 'Edit Rule'}
      actionButtons={actionButton}
      leftAction={
        isOpen !== 'addRule' ? (
          <EzyButton label="Delete" color="error" loading={false} onClick={handleDeleteRule} />
        ) : null
      }
      isLoading={getRuleDataByIdActionLoader || getMetricListForRuleActionLoader}
    >
      <div className="add-rule-drawer">
        <EzyToggleButtonGroup
          label="Coverage"
          list={coverageTypeEnum?.map((e) => e?.label)}
          selected="Linear"
          name="coverageType"
          handleChange={(event) => {
            const value = coverageTypeEnum?.find((e) => e?.label === event.target.value)?.id;
            handleFieldChange('coverageType', value);
          }}
          value={coverageTypeEnum?.find((e) => e?.id === coverageType)?.label}
          className="small"
        />

        <div className="two-grid">
          <EzyAutoComplete
            name="areaId"
            label="Area"
            placeholder="Select area"
            options={selectedLocation?.areaList}
            onChange={(e, value) => handleFieldChange('areaId', value)}
            value={areaId}
            required
            error={errors?.areaId}
          />
          <EzyAutoComplete
            name="metricId"
            label="Metric"
            placeholder="Select metric"
            options={staff?.listOfMetrics}
            onChange={(e, value) => handleFieldChange('metricId', value)}
            value={metricId}
            required
            error={errors?.metricId}
            loading={getMetricListForRuleActionLoader}
          />
        </div>

        {coverageType === 1 && (
          <div className="linear-rule-wrapper d-flex-column ">
            <div className="primary-bg ">
              <span>Minimum</span>
              <div className="flex-center gap-10">
                <EzyInputField
                  name="minimumLabor"
                  placeholder="Enter value"
                  type="number"
                  onChange={(e) =>
                    handleFieldChange('minimumLabor', e?.target?.value, 'linearRule')
                  }
                  value={linearRule?.minimumLabor}
                  required
                  error={errors?.linearRuleMinimumLabor}
                />
                <span>{areaId?.label}</span>
              </div>
            </div>
            <div className="primary-bg ">
              <span>For every </span>
              <div className="d-flex flex-align-center gap-10">
                <EzyInputField
                  name="laborCost"
                  type="number"
                  onChange={(e) => handleFieldChange('laborCost', e?.target?.value, 'linearRule')}
                  value={linearRule?.laborCost}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  required
                  error={errors?.linearRuleLaborCost}
                />
                <span className="flex-center gap-5">
                  in a {metricId?.label} per hour <span className="material-icons">east</span> add
                </span>
                <EzyInputField
                  name="requiredLabor"
                  type="number"
                  onChange={(e) =>
                    handleFieldChange('requiredLabor', e?.target?.value, 'linearRule')
                  }
                  value={linearRule?.requiredLabor}
                  required
                  error={errors?.linearRuleRequiredLabor}
                />
                <span>{areaId?.label}</span>
              </div>
            </div>
            <div className="primary-bg ">
              <span>Maximum </span>
              <div className="flex-center gap-10">
                <EzyInputField
                  name="maximumLabor"
                  type="number"
                  placeholder="Enter value"
                  onChange={(e) =>
                    handleFieldChange('maximumLabor', e?.target?.value, 'linearRule')
                  }
                  value={linearRule?.maximumLabor}
                  required
                  error={errors?.linearRuleMaximumLabor}
                />
                <span>{areaId?.label}</span>
              </div>
            </div>
          </div>
        )}

        {coverageType === 2 && (
          <div className="range-rule-wrapper primary-bg">
            {rangeRule?.map((res, index) => (
              <>
                {res?.maximumCost !== null && (
                  <div className="d-flex-column gap-10">
                    <div className="d-flex flex-align-center just-bet gap-10">
                      <div className="d-flex flex-align-center gap-10">
                        <EzyInputField
                          value={res?.minimumCost}
                          disabled
                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        />{' '}
                        to{' '}
                        <EzyInputField
                          type="number"
                          name="maximumCost"
                          value={res?.maximumCost}
                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                          onChange={(e) => {
                            handleFieldChange('maximumCost', e?.target?.value, 'rangeRule', index);
                            handleFieldChange(
                              'minimumCost',
                              +e.target.value + 1,
                              'rangeRule',
                              index + 1
                            );
                          }}
                        />{' '}
                        in{' '}
                      </div>
                      {index === 0 ? (
                        <EzyIconButton
                          iconName="add"
                          onClick={() =>
                            dispatch(
                              addUpdateRangeRuleFields({ type: 'ADD', index: rangeRule?.length })
                            )
                          }
                        />
                      ) : (
                        <EzyIconButton
                          iconName="delete"
                          onClick={() =>
                            dispatch(addUpdateRangeRuleFields({ type: 'DELETE', index }))
                          }
                        />
                      )}
                    </div>
                    <div className="d-flex flex-align-center gap-10">
                      <div className="text-wrapper">{metricId?.label} per hour</div>
                      <span className="material-icons">east</span>
                      <EzyInputField
                        type="number"
                        name="requiredLabor"
                        value={res?.requiredLabor}
                        onChange={(e) =>
                          handleFieldChange('requiredLabor', e?.target?.value, 'rangeRule', index)
                        }
                      />
                      <div className="text-wrapper">{areaId?.label}</div>
                    </div>
                  </div>
                )}

                {res?.maximumCost === null && (
                  <div className="d-flex-column gap-10">
                    <div className="d-flex flex-align-center gap-10">
                      <EzyInputField
                        value={res?.minimumCost}
                        disabled
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      />{' '}
                      and higher in
                    </div>
                    <div className="d-flex flex-align-center gap-10">
                      <div className="text-wrapper">{metricId?.label} per hour</div>
                      <span className="material-icons">east</span>
                      <EzyInputField
                        type="number"
                        name="requiredLabor"
                        value={res?.requiredLabor}
                        onChange={(e) =>
                          handleFieldChange('requiredLabor', e?.target?.value, 'rangeRule', index)
                        }
                      />
                      <div className="text-wrapper">{areaId?.label}</div>
                    </div>
                  </div>
                )}
              </>
            ))}
            {errors?.rangeRuleCost && (
              <FormHelperText className="error-helper">{errors?.rangeRuleCost}</FormHelperText>
            )}
          </div>
        )}

        <EzyToggleButtonGroup
          label="When"
          list={['Within operating hours', 'Anytime']}
          selected="Within operating hours"
          name="isAnyTime"
          handleChange={(event) => {
            handleFieldChange('isAnyTime', event.target.value === 'Anytime');
          }}
          value={isAnyTime ? 'Anytime' : 'Within operating hours'}
          className="small"
          tooltip={
            <div className="d-flex-column">
              {selectedLocation?.operatingHours?.map((e) => (
                <div className="d-flex gap-5 just-bet">
                  <span>{e?.weekDay?.label}</span>{' '}
                  <span>
                    {displayTime(e?.operatingHourStartTime, 'UTC')} -{' '}
                    {displayTime(e?.operatingHourEndTime, 'UTC')}
                  </span>
                </div>
              ))}
            </div>
          }
        />

        {!isAnyTime && (
          <div className="buffer-wrapper primary-bg">
            <EzySwitch
              label="Add buffers"
              subLabel="You can add buffers to your operating hours to better match your open or staffed hours"
              onChange={(e) => handleFieldChange('addBuffer', e?.target?.checked)}
              checked={addBuffer}
            />
            {addBuffer && (
              <>
                <div className="d-flex-column gap-5">
                  <span>From</span>
                  <div className="flex-center gap-10">
                    <EzyInputField
                      type="number"
                      name="fromHour"
                      onChange={(e) =>
                        handleFieldChange('fromHour', e?.target?.value, 'ruleBuffer')
                      }
                      value={ruleBuffer?.fromHour}
                      endAdornment={<InputAdornment position="end">hr</InputAdornment>}
                      required
                      error={errors?.fromHour}
                    />
                    <EzyAutoComplete
                      name="FromIsAfter"
                      options={['Before', 'After']}
                      onChange={(e, value) =>
                        handleFieldChange('FromIsAfter', value === 'After', 'ruleBuffer')
                      }
                      value={ruleBuffer?.fromIsAfter ? 'After' : 'Before'}
                    />
                    <span className="text-overflow-ellipsis"> Operating hours start</span>
                  </div>
                </div>

                <div className="d-flex-column gap-5">
                  <span>Until</span>
                  <div className="flex-center gap-10">
                    <EzyInputField
                      type="number"
                      name="untilHour"
                      onChange={(e) =>
                        handleFieldChange('untilHour', e?.target?.value, 'ruleBuffer')
                      }
                      value={ruleBuffer?.untilHour}
                      endAdornment={<InputAdornment position="end">hr</InputAdornment>}
                      required
                      error={errors?.untilHour}
                    />
                    <EzyAutoComplete
                      name="untilIsAfter"
                      onChange={(e, value) =>
                        handleFieldChange('untilIsAfter', value === 'After', 'ruleBuffer')
                      }
                      value={ruleBuffer?.untilIsAfter ? 'After' : 'Before'}
                      options={['Before', 'After']}
                    />
                    <span className="text-overflow-ellipsis">Operating hours ends</span>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </EzyDrawer>
  );
}

AddRuleDrawer.propTypes = {};

export default AddRuleDrawer;
