import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { cloneDeep } from 'lodash';
import EzyButton from '../../../../components/common/button/EzyButton';
import ROUTE_CONSTANTS, { DurationFilter } from '../../../../utils/constants';
import WeekPicker from '../../../../components/common/dateAndTimePicker/WeekPicker';
import {
  displayDate,
  displayTime,
  weekDatesValueBasedOnDuration,
} from '../../../../utils/timeHelper';
import { updateStaffFilters } from '../../redux/insightSlice';
import EzyMenu from '../../../../components/common/menu/EzyMenu';
import {
  getStaffStatisticDataForEditAction,
  updateStaffStatisticDataAction,
} from '../../redux/insightAction';
import HandsonTable from '../../../../components/common/table/HandsonTable';
import EzyDatePicker from '../../../../components/common/dateAndTimePicker/EzyDatePicker';
// import PropTypes from 'prop-types';

function EditStaff() {
  const history = useHistory();

  const dispatch = useDispatch();
  const { filters, staff } = useSelector(({ insight }) => insight ?? {});
  const { selectedLocation } = useMemo(() => filters, [filters]);
  const { weekDatesValue, areaId, duration } = useMemo(
    () => staff?.staffFilters,
    [staff?.staffFilters]
  );

  const hotRef = useRef();

  const rows = cloneDeep(staff.staffStatisticsList.newMetricTimeListEntities);

  const columns = new Array(
    duration?.label === 'Month'
      ? moment.tz(weekDatesValue?.startDate, 'UTC').daysInMonth()
      : duration?.dayCount
  ).fill(null);

  const sumRenderer = useCallback((instance, td, row, col) => {
    if (row === 0 && hotRef.current) {
      const sum = hotRef?.current?.hotInstance
        ?.getDataAtCol(col)
        .slice(1)
        .reduce((acc, curr) => acc + curr, 0);

      td.className = 'ezy-handson htDimmed';

      if (
        hotRef.current.hotInstance?.getSourceDataAtRow(row)?.dateValues[col]?.value ||
        hotRef.current.hotInstance?.getSourceDataAtRow(row)?.dateValues[col]?.value === null
      ) {
        if (sum > 0) {
          hotRef.current.hotInstance.getSourceDataAtRow(row).dateValues[col].value =
            sum?.toFixed(2);

          td.innerHTML = sum?.toFixed(2);
        } else {
          hotRef.current.hotInstance.getSourceDataAtRow(row).dateValues[col].value = null;

          td.innerHTML = null;
        }
      }
    }
  }, []);

  const handleFilterChange = (fieldName, value) => {
    dispatch(updateStaffFilters({ fieldName, value }));
  };

  const handleSubmit = () => {
    const hot = hotRef?.current?.hotInstance;
    const editedData = hot?.getSourceData();
    editedData?.shift();

    const data = {
      locationId: selectedLocation?.id,
      areaId: areaId?.id,
      newRequiredStaffDataEntities: editedData,
    };

    dispatch(updateStaffStatisticDataAction(data));
  };

  const getStaffStatisticsByFilter = (param) => {
    const params = {
      startDate: moment.tz(weekDatesValue?.startDate, 'UTC').toISOString(),
      endDate: moment.tz(weekDatesValue?.endDate, 'UTC').toISOString(),
      areaId: areaId?.id,
      ...param,
    };
    dispatch(getStaffStatisticDataForEditAction(params));
  };

  useEffect(() => {
    if (selectedLocation?.id && selectedLocation?.areaList?.length) {
      handleFilterChange('areaId', selectedLocation?.areaList?.[0]);
      getStaffStatisticsByFilter({ areaId: selectedLocation?.areaList?.[0]?.id });
    } else {
      history.push(`${ROUTE_CONSTANTS.SCHEDULE}/insights/staff?location="all"`);
    }
  }, []);

  return (
    <div className="edit-staff-wrapper">
      <div className="edit-staff-header">
        <div>
          {duration?.id === 5 ? (
            <EzyDatePicker
              value={moment.tz(weekDatesValue?.startDate, 'UTC')}
              onChange={async (e) => {
                const data = weekDatesValueBasedOnDuration({ startDate: e }, duration);
                handleFilterChange('weekDatesValue', data);
                await getStaffStatisticsByFilter({
                  startDate: moment.tz(data?.startDate, 'UTC')?.toISOString(),
                  endDate: moment.tz(data?.endDate, 'UTC').toISOString(),
                });
              }}
              timeZone="UTC"
            />
          ) : (
            <WeekPicker
              variant="outlined"
              value={weekDatesValue}
              onChange={async (e) => {
                const data = weekDatesValueBasedOnDuration(e, duration);
                handleFilterChange('weekDatesValue', data);
                await getStaffStatisticsByFilter({
                  startDate: moment.tz(data?.startDate, 'UTC')?.toISOString(),
                  endDate: moment.tz(data?.endDate, 'UTC').toISOString(),
                });
              }}
              onNextClick={async () => {
                const dates = {
                  startDate: moment
                    .tz(weekDatesValue?.startDate, 'UTC')
                    .add(duration.dayCount, 'days'),
                  endDate: moment.tz(weekDatesValue?.endDate, 'UTC').add(duration.dayCount, 'days'),
                };

                if (duration?.label === 'Month') {
                  dates.startDate = moment
                    .tz(weekDatesValue?.endDate, 'UTC')
                    .add(1, 'days')
                    .startOf('month');
                  dates.endDate = moment
                    .tz(weekDatesValue?.endDate, 'UTC')
                    .add(1, 'days')
                    .endOf('month');
                }
                handleFilterChange('weekDatesValue', dates);

                await getStaffStatisticsByFilter({
                  startDate: moment.tz(dates?.startDate, 'UTC')?.toISOString(),
                  endDate: moment.tz(dates?.endDate, 'UTC').toISOString(),
                });
              }}
              onPrevClick={async () => {
                const dates = {
                  startDate: moment
                    .tz(weekDatesValue?.startDate, 'UTC')
                    .subtract(duration.dayCount, 'days'),
                  endDate: moment
                    .tz(weekDatesValue?.endDate, 'UTC')
                    .subtract(duration.dayCount, 'days'),
                };

                if (duration?.label === 'Month') {
                  dates.startDate = moment
                    .tz(weekDatesValue?.startDate, 'UTC')
                    .subtract(1, 'days')
                    .startOf('month');
                  dates.endDate = moment
                    .tz(weekDatesValue?.startDate, 'UTC')
                    .subtract(1, 'days')
                    .endOf('month');
                }
                handleFilterChange('weekDatesValue', dates);
                await getStaffStatisticsByFilter({
                  startDate: moment.tz(dates?.startDate, 'UTC')?.toISOString(),
                  endDate: moment.tz(dates?.endDate, 'UTC').toISOString(),
                });
              }}
              timeZone="UTC"
              startWeekFrom={
                selectedLocation?.weekStartsOn === 7 ? 0 : selectedLocation?.weekStartsOn
              }
            />
          )}

          <EzyMenu
            className="insight-area-menu"
            menuItems={selectedLocation?.areaList}
            label={areaId?.label || 'Area'}
            onItemClick={(e, item) => {
              handleFilterChange('areaId', item);
              getStaffStatisticsByFilter({ areaId: item?.id });
            }}
            selectedItem={areaId}
          />

          <EzyMenu
            className="insight-duration-menu"
            menuItems={DurationFilter}
            label={duration?.label || 'Duration'}
            onItemClick={async (e, item) => {
              handleFilterChange('duration', item);

              const data = weekDatesValueBasedOnDuration(weekDatesValue, item);

              handleFilterChange('weekDatesValue', data);
              await getStaffStatisticsByFilter({
                startDate: moment.tz(data?.startDate, 'UTC')?.toISOString(),
                endDate: moment.tz(data?.endDate, 'UTC').toISOString(),
              });
            }}
            selectedItem={duration}
          />
        </div>

        <div>
          <EzyButton
            label="Save"
            onClick={() => handleSubmit()}
            // loading={updateMetricDataActionLoader}
          />

          <EzyButton
            color="secondary"
            label="Back"
            onClick={() => {
              history.push(
                `${ROUTE_CONSTANTS.SCHEDULE}/insights/staff?location=${selectedLocation?.id}`
              );
            }}
          />
        </div>
      </div>

      <div className="edit-staff-component-wrapper">
        <HandsonTable
          myRef={hotRef}
          rows={[
            {
              time: null,
              dateValues: rows[0]?.dateValues?.map((e) => ({ date: e?.date, value: null })),
            },
            ...rows,
          ]}
          dataSchema={{
            time: null,
            dateValues: [
              {
                date: null,
                value: null,
              },
            ],
          }}
          colHeaders={staff.staffStatisticsList.newMetricTimeListEntities?.[0]?.dateValues?.map(
            (e) => displayDate(e?.date, 'UTC')
          )}
          rowHeaders={[
            'Total',
            ...staff.staffStatisticsList.newMetricTimeListEntities.map((e) =>
              displayTime(e?.time, 'UTC')
            ),
          ]}
          columns={columns.map((e, i) => {
            return {
              data: `dateValues.${i}.value`,
              type: 'numeric',
              numericFormat: {
                pattern: '0.00',
              },
              // readOnly: metricFor === 'payrollForecast',
            };
          })}
          cells={(row) => {
            const cellProperties = {};
            if (row === 0) {
              cellProperties.renderer = sumRenderer;
              cellProperties.readOnly = true;
            }

            return cellProperties;
          }}
          // afterChange={onSumCellChange}
        />
      </div>
    </div>
  );
}

EditStaff.propTypes = {};

export default EditStaff;
