import { filter, isEmpty, isNil, omitBy } from 'lodash';
import moment from 'moment';
import { requireValidate } from '../../../utils/validationHelper';

const validateEndShift = (data) => {
  const { shiftBreakEntity, endTime, startTime } = data ?? {};
  const error = {};
  error.startTime = requireValidate('Start time', startTime);
  error.endTime = requireValidate('End time', endTime);
  if (startTime && endTime && !moment(endTime).isAfter(startTime)) {
    error.endTime = `End time can't be smaller then start time`;
  }
  error.breakDetails = shiftBreakEntity?.map((e) => {
    const errors = {};
    if (e?.duration && e?.duration >= 0)
      errors.breakType = requireValidate('Break type', e?.breakType);
    const ShiftBreakStartTime = moment(e?.startTime);
    const ShiftBreakEndTime = moment(e?.endTime);
    const scheduleStartTime = moment(startTime);
    const scheduleEndTime = moment(endTime);

    if (
      moment(ShiftBreakStartTime).isValid() &&
      !(
        moment(ShiftBreakStartTime).isBetween(scheduleStartTime, scheduleEndTime) ||
        moment(ShiftBreakStartTime).isSame(scheduleStartTime, scheduleEndTime)
      )
    )
      errors.startTime = `Break start time is out of shift range.`;

    if (moment(ShiftBreakEndTime).isValid() || moment(ShiftBreakStartTime).isValid()) {
      if (!moment(ShiftBreakEndTime).isValid()) {
        errors.endTime = `Break end time is required.`;
      }
      if (!moment(ShiftBreakStartTime).isValid()) {
        errors.startTime = `Break start time is required.`;
      }
    }

    if (
      moment(ShiftBreakEndTime).isValid() &&
      moment(ShiftBreakStartTime).isValid() &&
      moment(ShiftBreakEndTime).isBefore(ShiftBreakStartTime)
    )
      errors.endTime = `End time can't be smaller then start time`;

    if (
      moment(ShiftBreakEndTime).isValid() &&
      !(
        moment(ShiftBreakEndTime).isBetween(scheduleStartTime, scheduleEndTime) ||
        moment(ShiftBreakEndTime).isSame(scheduleStartTime, scheduleEndTime) ||
        moment(ShiftBreakEndTime).diff(scheduleEndTime, 'second') === 0
      )
    ) {
      errors.endTime = `Break end time is out of shift range.`;
    }

    return omitBy(errors, isNil);
  });

  error.breakDetails = filter(error.breakDetails, (e) => !isEmpty(e))?.length
    ? error.breakDetails
    : null;

  return omitBy(error, isNil);
};

export default validateEndShift;
