import React, { useEffect, useMemo } from 'react';
import { Chip, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import EzyDrawer from '../../../components/common/drawer/Drawer';
import EzyAutoComplete from '../../../components/common/autoComplete/EzyAutoComplete';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import EzySwitch from '../../../components/common/switch/EzySwitch';
import { updateNewsFeedDrawer, resetNewsFeedDrawer } from '../redux/newsFeedSlice';
import {
  addNewsFeedPostAction,
  deleteNewsFeedFileAction,
  getNewsFeedListAction,
  getNewsFeedReceiverListAction,
  uploadMediaAction,
} from '../redux/newsFeedActions';
import FileUpload from '../../../components/common/fileUpload/fileUpload';
import { errorNotification } from '../../../utils/notifyHelper';
import EzyIconButton from '../../../components/common/iconButton/EzyIconButton';
import validateAddNewsFeed from '../validations/validateAddNewsFeed';

function AddNewsFeedDrawer() {
  const dispatch = useDispatch();
  const { newsFeedDrawerDetails, receiverList } = useSelector(({ newsFeed }) => newsFeed ?? {});
  const { businessId, roleId } = useSelector(({ user }) => user?.profileData || {});

  const { addNewsFeedPostActionLoader } = useSelector(({ common }) => common.generalLoader ?? {});

  const {
    selectedLocation,
    selectedPeople,
    isOpenNewsFeedDrawer,
    message,
    isRequiredConfirmation,
    isCommentAllowed,
    errors,
    uploadedMedia,
  } = useMemo(() => newsFeedDrawerDetails, [newsFeedDrawerDetails]);

  const handleFieldChange = (fieldName, value) => {
    dispatch(updateNewsFeedDrawer({ fieldName, value }));
  };
  const handleClose = () => {
    dispatch(resetNewsFeedDrawer());
  };

  const handleSubmit = async () => {
    try {
      const data = {
        assignToLocation: receiverList?.receiverLocationList
          .filter((item) => selectedLocation.includes(item.label))
          .map((item) => {
            return { id: item.id, label: item.label };
          }),
        assignToPerson: receiverList?.receiverPersonList
          .filter((item) => selectedPeople.includes(item.label))
          .map((item) => {
            return { id: item.id, label: item.label };
          }),
        newsContent: message,
        isConfirmationRequired: isRequiredConfirmation,
        isCommentAllowed,
        businessId,
        mediaStoragePathList: uploadedMedia,
      };

      const error = validateAddNewsFeed(data);

      handleFieldChange('errors', error);
      if (isEmpty(error)) {
        dispatch(
          addNewsFeedPostAction(data, () => {
            handleClose();
            dispatch(
              getNewsFeedListAction({
                page: 1,
                limit: 5,
                locationId: selectedLocation,
                isYourPost: false,
                isImportantPost: false,
              })
            );
          })
        );
      }
    } catch (error) {
      /**/
    }
  };

  const actionButton = [
    {
      label: 'Save',
      onClick: handleSubmit,
      loading: addNewsFeedPostActionLoader,
    },
  ];

  const handleChange = async (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files.length > 4 || uploadedMedia.length + e.target.files.length > 4) {
        errorNotification('You can only upload four files.');
      } else {
        const fileExtension = ['jpeg', 'jpg', 'png', 'mp4', 'pdf', 'gif', 'MOV'];
        const mimeType = [
          'image/jpeg',
          'image/jpg',
          'image/png',
          'image/gif',
          'video/mp4',
          'application/pdf',
          'video/quicktime',
        ];

        const files = Array.from(e.target.files);
        const formData = new FormData();

        const data = files.filter((file) => {
          const checkExtension = fileExtension.indexOf(file.name.split('.').splice(-1)[0]) !== -1;
          const checkMimeTypes = mimeType.indexOf(files.type) !== -1;

          if (!(checkExtension || checkMimeTypes)) {
            return false;
          }
          formData.append(`mediaEntity`, file);
          return formData;
        });

        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };

        try {
          if (data?.length) await dispatch(uploadMediaAction(formData, config));
          else errorNotification('Only image, video and pdf file allowed');
        } catch (error) {
          /**/
        }
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (isOpenNewsFeedDrawer) {
        await dispatch(getNewsFeedReceiverListAction());
      }
    })();
  }, [isOpenNewsFeedDrawer]);

  return (
    <EzyDrawer
      heading="Add News Feed"
      isOpen={isOpenNewsFeedDrawer}
      actionButtons={actionButton}
      onClose={handleClose}
    >
      <div className="add-newsfeed-drawer">
        <EzyAutoComplete
          id="tags-outlined"
          multiple
          placeholder="Select locations"
          options={receiverList?.receiverLocationList?.map((e) => e.label)}
          value={selectedLocation}
          className={`${selectedLocation.length ? 'auto-complete-tags' : ''}`}
          label="Location"
          name="selectedLocation"
          limitTags={1}
          renderTags={(value, getTagProps) =>
            value.map((option, chipIndex) => (
              <Chip
                variant="outlined"
                color="primary"
                label={option}
                deleteIcon={<span className="material-icons">highlight_off</span>}
                {...getTagProps({ chipIndex })}
              />
            ))
          }
          onChange={(e, value) => {
            handleFieldChange('selectedLocation', value);
          }}
          error={errors?.assignToLocation}
          required
        />
        <EzyAutoComplete
          id="tags-outlined"
          multiple
          placeholder="Select People"
          options={receiverList?.receiverPersonList?.map((e) => e.label)}
          value={selectedPeople}
          className={`${selectedPeople.length ? 'auto-complete-tags ' : ''}`}
          label="People"
          name="selectedPeople"
          limitTags={1}
          renderTags={(value, getTagProps) =>
            value.map((option, chipIndex) => (
              <Chip
                variant="outlined"
                color="primary"
                label={option}
                deleteIcon={<span className="material-icons">highlight_off</span>}
                {...getTagProps({ chipIndex })}
              />
            ))
          }
          onChange={(e, value) => {
            handleFieldChange('selectedPeople', value);
          }}
          error={errors?.assignToPerson}
          required
        />

        <EzyInputField
          label="Write a message"
          name="message"
          placeholder="What's happening..."
          multiline
          rows={3}
          max-rows={5}
          value={message}
          onChange={(event) => handleFieldChange('message', event.target.value)}
          error={errors?.newsContent}
          required
        />

        <FileUpload
          label="Add media"
          subtitle="You can upload image, video and pdf."
          handleChange={handleChange}
          multiple
        />

        <List>
          {uploadedMedia?.map((media) => {
            const mediaIcon = () => {
              switch (media?.mediaType) {
                case 'application/pdf':
                  return <EzyIconButton iconName="picture_as_pdf" />;
                case 'video/mp4':
                case 'video/quicktime':
                  return <EzyIconButton iconName="play_circle" />;
                default:
                  return (
                    <img src={media?.storagePath} alt={media.fileName} className="media-icon-img" />
                  );
              }
            };

            return (
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>{mediaIcon()}</ListItemIcon>

                  <ListItemText>
                    <span>{media?.fileName}</span>
                    <span
                      className="material-icons error-color"
                      onClick={() => {
                        dispatch(deleteNewsFeedFileAction({ filePath: media?.storagePath }));
                        handleFieldChange(
                          'uploadedMedia',
                          uploadedMedia.filter((e) => e.fileName !== media.fileName)
                        );
                      }}
                    >
                      delete
                    </span>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>

        {roleId !== 5 && (
          <EzySwitch
            name="isRequiredComfirmation"
            label="Requires confirmation"
            checked={isRequiredConfirmation}
            handleChange={(e) => handleFieldChange('isRequiredConfirmation', e?.target?.checked)}
          />
        )}
        <EzySwitch
          name="isCommentAllowed"
          label="Allow comments"
          checked={isCommentAllowed}
          handleChange={(e) => handleFieldChange('isCommentAllowed', e?.target?.checked)}
        />
      </div>
    </EzyDrawer>
  );
}

export default AddNewsFeedDrawer;
