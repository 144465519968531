import { Chip } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import EzyToggleButtonGroup from '../../components/common/toggleButton/EzyToggleButtonGroup';
import EzyInputField from '../../components/common/inputField/EzyInputField';
import EzyPhoneNumber from '../../components/common/phoneNumberInput/ezyPhoneNumber';
import EzyAutoComplete from '../../components/common/autoComplete/EzyAutoComplete';
import EzyButton from '../../components/common/button/EzyButton';
import {
  addDeleteLocation,
  resetLocation,
  setInviteToBusinessField,
  setNewBusinessField,
  updateLocation,
} from './redux/businessSlice';
import ROUTE_CONSTANTS, { numberOfPeopleArray } from '../../utils/constants';
import EzyIconButton from '../../components/common/iconButton/EzyIconButton';
import IconByName from '../../components/common/iconByName/IconByName';

const joinTypeList = ['New To PayrollEzy', 'Join Your Team'];
const locationTypeList = ['Just One', 'Multiple'];

function RegisterForm({ addBusiness, businessInvite }) {
  const history = useHistory();
  const newBusinessData = useSelector(({ business }) => business ?? {});
  const { personName, businessId } = useSelector(({ user }) => user?.profileData || '');
  const { countryList, industryList } = useSelector(({ common }) => common ?? {});
  const {
    businessIndustryTypeId,
    businessName,
    countryId,
    // isLoading,
    joinType,
    errors,
    locations,
    // email,
    locationType,
    numberOfPeople,
    phoneNumber,
    inviteToBusiness,
  } = useMemo(() => newBusinessData, [newBusinessData]);

  const dispatch = useDispatch();

  const { registerNewBusinessLoader, joinYourTeamLoader, getCurrentUserDataLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );
  const handleFieldChange = (name, value) => {
    dispatch(setNewBusinessField({ fieldName: name, value }));
  };
  const handleInviteToBusinessFieldChange = (name, value) => {
    dispatch(setInviteToBusinessField({ fieldName: name, value }));
  };

  const updateLocationFields = (name, value, index) => {
    dispatch(updateLocation({ fieldName: name, value, index }));
  };

  const handelCancelClick = () => {
    history.push(ROUTE_CONSTANTS.ACCOUNT_OVERVIEW);
  };
  return (
    <div className="register-form-wrapper">
      {businessId && (
        <div className="form-title">
          <IconByName name="business_center" />
          <span>Add Business</span>
        </div>
      )}
      <div className="left-wrapper">
        {joinType === 'New To PayrollEzy' ? (
          <div className="d-flex-column gap-10">
            {!businessId && (
              <EzyToggleButtonGroup
                label={`Dear ${personName}, Let's get started !`}
                list={joinTypeList}
                selected="New To PayrollEzy"
                selectedToggleButton="new"
                name="joinType"
                handleChange={(e) => handleFieldChange('joinType', e.target.value)}
                value={joinType}
              />
            )}
            <EzyInputField
              nama="businessName"
              placeholder="Enter business name"
              label="What is your business name?"
              value={businessName}
              onChange={(e) => handleFieldChange('businessName', e.target.value)}
              error={errors?.businessName}
              required
            />
            <div className="two-grid">
              <EzyAutoComplete
                placeholder="Select country"
                label="Country"
                options={countryList}
                value={countryId?.label}
                name="countryId"
                onChange={(_, value) => {
                  handleFieldChange('countryId', value);
                }}
                error={errors?.countryId}
                required
              />
              <EzyPhoneNumber
                country={countryId?.code}
                placeholder="4 123 456 789"
                label="What is your mobile number?"
                name="phoneNumber"
                value={phoneNumber}
                onChange={(value, data) => {
                  handleFieldChange('phoneNumber', value);
                  handleFieldChange('countryCode', data?.dialCode);
                  handleFieldChange('phoneNumberData', data);
                }}
                error={errors?.phoneNumber}
              />
            </div>
            <div className="two-grid">
              <EzyAutoComplete
                placeholder="Select your business industry"
                label="What is your business industry?"
                options={industryList}
                value={businessIndustryTypeId?.label}
                name="businessIndustryTypeId"
                onChange={(_, value) => {
                  handleFieldChange('businessIndustryTypeId', value);
                }}
                error={errors?.businessIndustryTypeId}
                required
              />
              <EzyAutoComplete
                placeholder="Select number of employees"
                label="How many employees?"
                value={numberOfPeople?.label}
                name="numberOfPeople"
                options={numberOfPeopleArray}
                onChange={(e, value) => {
                  handleFieldChange('numberOfPeople', value);
                }}
                error={errors?.numberOfPeople}
                required
              />
            </div>
            <EzyToggleButtonGroup
              className="p-top-15"
              label="How many physical locations do you have?"
              list={locationTypeList}
              selected="Just One"
              name="locationType"
              handleChange={(e) => {
                handleFieldChange('locationType', e.target.value);
                dispatch(resetLocation());
              }}
              value={locationType}
              required
            />
            {locations?.map((e, index) => (
              <div key={index}>
                {/* {index > 0 && index !== locations?.length && <Divider className="secondary-border" />} */}
                <div
                  className={`two-grid ${
                    index > 0 && index !== locations?.length ? 'p-top-15' : ''
                  }`}
                >
                  <EzyInputField
                    placeholder="Enter location name"
                    label="Location name"
                    name="locationName"
                    value={e?.locationName}
                    onChange={(value) =>
                      updateLocationFields('locationName', value?.target?.value, index)
                    }
                    error={errors?.locations?.[index]?.locationName}
                    required
                  />
                  <div className="d-flex flex-end gap-10">
                    <EzyInputField
                      placeholder="Enter location address"
                      label="Location address"
                      name="address"
                      value={e?.address}
                      inputProps={{
                        maxLength: 300,
                      }}
                      multiline
                      maxRows={2}
                      onChange={(value) =>
                        updateLocationFields('address', value?.target?.value, index)
                      }
                      error={errors?.locations?.[index]?.address}
                      required
                    />
                    {index >= 1 ? (
                      <EzyIconButton
                        className={
                          errors?.locations?.[index]?.address
                            ? 'align-self-center'
                            : 'align-self-end'
                        }
                        iconName="delete"
                        onClick={() => dispatch(addDeleteLocation({ type: 'DELETE', index }))}
                      />
                    ) : (
                      locationType === 'Multiple' && (
                        <EzyIconButton
                          className={
                            errors?.locations?.[index]?.address
                              ? 'align-self-center'
                              : 'align-self-end'
                          }
                          iconName="add"
                          onClick={() => dispatch(addDeleteLocation({ type: 'ADD' }))}
                        />
                      )
                    )}
                  </div>
                </div>
                <div className="two-grid p-top-10">
                  <EzyInputField
                    label="City"
                    placeholder="Enter city name"
                    name="city"
                    value={e?.city}
                    onChange={(event) => updateLocationFields('city', event?.target?.value, index)}
                    error={errors?.locations?.[index]?.city}
                    required
                  />
                  <EzyAutoComplete
                    id="tags-outlined"
                    multiple
                    freeSolo
                    className={`${e?.areas?.length ? 'auto-complete-tags' : ''} `}
                    placeholder="Add area and press Enter key"
                    label="Location areas"
                    name="areas"
                    value={e?.areas}
                    options={[...new Set([...e.areas])]}
                    renderTags={(value, getTagProps) =>
                      value.map((option, chipIndex) => (
                        <Chip
                          variant="outlined"
                          color="primary"
                          label={option}
                          deleteIcon={<span className="material-icons">highlight_off</span>}
                          {...getTagProps({ chipIndex })}
                        />
                      ))
                    }
                    onChange={(_, value) => updateLocationFields('areas', value, index)}
                    error={errors?.locations?.[index]?.areas}
                    required
                  />
                </div>
              </div>
            ))}
            <div className="d-flex align-self-end p-top-15 gap-12">
              {businessId && <EzyButton label="Cancel" color="error" onClick={handelCancelClick} />}
              <EzyButton
                label="Submit"
                onClick={addBusiness}
                loading={registerNewBusinessLoader || getCurrentUserDataLoader}
              />
            </div>
          </div>
        ) : (
          <div className="d-flex-column gap-10">
            <EzyToggleButtonGroup
              label={`Dear ${personName}, Let's get started !`}
              list={joinTypeList}
              selected="New To PayrollEzy"
              selectedToggleButton="new"
              name="joinType"
              handleChange={(e) => handleFieldChange('joinType', e.target.value)}
              value={joinType}
            />
            <EzyInputField
              nama="businessName"
              placeholder="Enter your business name to join"
              label="What is the business name that you want to join?"
              value={inviteToBusiness?.name}
              onChange={(e) => handleInviteToBusinessFieldChange('name', e.target.value)}
              error={inviteToBusiness?.errors?.name}
              required
            />
            <EzyInputField
              nama="email"
              placeholder="Enter manager email address"
              label="Manager's email address?"
              value={inviteToBusiness?.email}
              onChange={(e) => handleInviteToBusinessFieldChange('email', e.target.value)}
              error={inviteToBusiness?.errors?.email}
              required
            />
            <div className="d-flex align-self-end p-top-15 gap-12">
              {businessId && <EzyButton label="Cancel" color="error" onClick={handelCancelClick} />}
              <EzyButton label="Submit" onClick={businessInvite} loading={joinYourTeamLoader} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RegisterForm;

RegisterForm.propTypes = {
  addBusiness: PropTypes.func.isRequired,
  businessInvite: PropTypes.func.isRequired,
};
