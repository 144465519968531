import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import moment from 'moment-timezone';
import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import IconByName from '../../../../components/common/iconByName/IconByName';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import Loader from '../../../../components/common/loader/Loader';
import useQueryParams from '../../../../hooks/useQueryParams';
import {
  cancelShiftOfferInvitationAction,
  getPeopleForOfferShiftAction,
  sendShiftOfferInvitationAction,
} from '../../../people/redux/peopleActions';
import { setPeopleForOfferShift } from '../../../people/redux/peopleSlice';
import { getDashboardShiftsAction } from '../../redux/dashboardActions';
import { updateOfferShiftDrawerDetails } from '../../redux/dashboardSlice';
import CurrentShiftDetailsAccordion from './CurrentShiftDetailsAccordian';

function OfferShiftDrawer() {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState(null);
  const [checked, setChecked] = useState([]);
  const { date: dateParam } = useQueryParams();

  const { peopleForOfferShift } = useSelector(({ people }) => people?.shiftDetails ?? {});
  const { personId, businessId, timeZone } = useSelector(({ user }) => user?.profileData ?? {});

  const { offerShiftDrawerDetails } = useSelector(({ dashboard }) => dashboard?.calenderData ?? {});

  const {
    getPeopleForOfferShiftActionLoader,
    sendShiftOfferInvitationActionLoader,
    getPeopleForOfferShiftWithSearchTextActionLoader,
    cancelShiftOfferInvitationActionLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});

  const { currentShift, note } = useMemo(() => offerShiftDrawerDetails, [offerShiftDrawerDetails]);

  const handleClose = () => {
    dispatch(updateOfferShiftDrawerDetails({ fieldName: 'currentShift', value: {} }));
    dispatch(updateOfferShiftDrawerDetails({ fieldName: 'note', value: '' }));
    dispatch(setPeopleForOfferShift([]));
    setChecked([]);
  };

  const fetchPeoples = async (params) => {
    await dispatch(
      getPeopleForOfferShiftAction({ shiftId: currentShift?.shiftId, searchText, ...params })
    );
    setChecked([]);
  };

  const handleSearch = async (e) => {
    if (e.target.value) setSearchText(e.target.value);
    else {
      setSearchText(null);
      await fetchPeoples({ searchText: null });
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleSubmit = () => {
    const data = {
      isFromDashboard: true,
      shiftId: currentShift?.shiftId,
      offerToPersonDetailId: checked,
      note,
    };

    if (checked?.length > 0) {
      dispatch(
        sendShiftOfferInvitationAction(data, () => {
          handleClose();
          dispatch(
            getDashboardShiftsAction({
              personDetailId: personId,
              businessId,
              filterStartDate: moment
                .tz(dateParam, timeZone?.label ?? moment.tz?.guess())
                .startOf('week')
                ?.toISOString(),
              filterEndDate: moment
                .tz(dateParam, timeZone?.label ?? moment.tz?.guess())
                .endOf('week')
                ?.toISOString(),
            })
          );
        })
      );
    }
  };
  const handleCancelInvitation = () => {
    const data = {
      shiftId: currentShift?.shiftId,
    };

    dispatch(
      cancelShiftOfferInvitationAction(data, () => {
        handleClose();
        dispatch(
          getDashboardShiftsAction({
            personDetailId: personId,
            businessId,
            filterStartDate: moment
              .tz(dateParam, timeZone?.label ?? moment.tz?.guess())
              .startOf('week')
              ?.toISOString(),
            filterEndDate: moment
              .tz(dateParam, timeZone?.label ?? moment.tz?.guess())
              .endOf('week')
              ?.toISOString(),
          })
        );
      })
    );
  };

  const actionButton = useMemo(() => {
    const actions = [];
    if (peopleForOfferShift?.filter((e) => e?.isAlreadyInvited)?.length > 0) {
      actions.push({
        label: 'Cancel offer shift',
        onClick: handleCancelInvitation,
        color: 'error',
        loading: cancelShiftOfferInvitationActionLoader,
      });
    }
    actions.push({
      label: 'Request for offer shift',
      onClick: handleSubmit,
      loading: sendShiftOfferInvitationActionLoader,
      disabled: checked?.length === 0,
    });
    return actions;
  }, [
    peopleForOfferShift,
    sendShiftOfferInvitationActionLoader,
    cancelShiftOfferInvitationActionLoader,
    handleSubmit,
    handleCancelInvitation,
    checked,
  ]);

  useEffect(() => {
    (async () => {
      if (currentShift?.shiftId) await fetchPeoples();
    })();
  }, [currentShift]);

  return (
    <EzyDrawer
      onClose={handleClose}
      isOpen={currentShift.shiftId}
      heading="Offer Shift"
      actionButtons={actionButton}
      isLoading={getPeopleForOfferShiftActionLoader}
    >
      <div className="shift-replace-drawer">
        {getPeopleForOfferShiftWithSearchTextActionLoader && <Loader />}

        <CurrentShiftDetailsAccordion
          shift={currentShift}
          updateNoteAction={updateOfferShiftDrawerDetails}
          note={note}
        />

        <div className="shift-replace-field-grid">
          <EzyInputField
            label="Search"
            placeholder="Search by people"
            className="search-box"
            value={searchText}
            onChange={handleSearch}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                await fetchPeoples();
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconByName name="search" />
              </InputAdornment>
            }
          />
        </div>

        {peopleForOfferShift?.length > 0 ? (
          <div className="shift-replace-field-grid">
            <FormControlLabel
              label="Select All"
              className="just-right"
              control={
                <Checkbox
                  edge="start"
                  checked={
                    peopleForOfferShift?.filter((e) => !e?.isAlreadyInvited)?.length ===
                    checked?.length
                  }
                  tabIndex={-1}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setChecked(
                        peopleForOfferShift
                          ?.filter((val) => !val?.isAlreadyInvited)
                          ?.map((val) => val?.id)
                      );
                    } else {
                      setChecked([]);
                    }
                  }}
                  disableRipple
                  inputProps={{ 'aria-labelledby': 'select All' }}
                  icon={<IconByName name="check_circle_outline" />}
                  checkedIcon={<IconByName name="check_circle" className="primary-color" />}
                />
              }
            />

            <List>
              {peopleForOfferShift?.map((value) => {
                const labelId = `checkbox-list-label-${value?.id}`;
                return (
                  <ListItem
                    disablePadding
                    selected={checked?.indexOf(value?.id) !== -1 || value?.isAlreadyInvited}
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(value?.id)}
                      dense
                      disabled={value?.isAlreadyInvited}
                      disableRipple
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked?.indexOf(value?.id) !== -1 || value?.isAlreadyInvited}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                          icon={<IconByName name="check_circle_outline" />}
                          checkedIcon={<IconByName name="check_circle" className="primary-color" />}
                        />
                      </ListItemIcon>
                      <ListItemText id={value?.id} className="people-invite-list-view">
                        <span>{value?.preferredFullName}</span>
                        {value?.isAlreadyInvited && (
                          <span className="primary-color">Already Invited</span>
                        )}
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </div>
        ) : (
          <div className="full-relative-wrapper">
            <div className="no-data-found">No data available.</div>
          </div>
        )}
      </div>
    </EzyDrawer>
  );
}

export default OfferShiftDrawer;
