import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import IconByName from '../../../../components/common/iconByName/IconByName';
import EzyButton from '../../../../components/common/button/EzyButton';
import EzySwitch from '../../../../components/common/switch/EzySwitch';
import { updateInsightFilters } from '../../redux/insightSlice';
import { getSummarySettingsAction, updateSummarySettingsAction } from '../../redux/insightAction';

function InsightMetricMenu({ refetchEvent }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuWidth, setMenuWidth] = useState(140);
  const dispatch = useDispatch();
  const menuRef = useRef();
  const open = Boolean(anchorEl);
  const { filters } = useSelector(({ insight }) => insight ?? {});

  const { displayLabor, displaySalesPerHour } = useMemo(() => filters, [filters]);

  const handleClick = (event) => {
    event?.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMetricChange = (fieldName, value) => {
    dispatch(updateInsightFilters({ fieldName, value }));
    dispatch(
      updateSummarySettingsAction({ [fieldName]: value }, () => {
        dispatch(getSummarySettingsAction());
        refetchEvent();
      })
    );
  };

  const menuItems = [
    {
      id: 1,
      label: (
        <div className="d-flex gap-10 flex-align-center w-full just-bet">
          labor
          <EzySwitch
            checked={displayLabor}
            onChange={(e) => handleMetricChange('displayLabor', e?.target?.checked)}
          />
        </div>
      ),
    },
    {
      id: 2,
      label: (
        <div className="d-flex gap-10 flex-align-center w-full">
          Sales per hour
          <EzySwitch
            checked={displaySalesPerHour}
            onChange={(e) => handleMetricChange('displaySalesPerHour', e?.target?.checked)}
          />
        </div>
      ),
    },
  ];

  useLayoutEffect(() => {
    setMenuWidth(menuRef?.current?.offsetWidth);
  }, [menuRef?.current?.offsetWidth]);

  return (
    <div className="ezy-menu metrics-menu">
      <EzyButton
        ref={menuRef}
        label="Metrics"
        endIcon={<IconByName name="expand_more" />}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        className="ezy-menu-list metrics-menu"
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          style: {
            width: menuWidth,
          },
        }}
      >
        {menuItems?.length > 0 ? (
          menuItems?.map((item) => {
            return (
              <MenuItem
                onClick={(e) => {
                  if (item?.onClick) {
                    item?.onClick(e, item);
                  }
                }}
                key={item?.label}
                disabled={item.disabled}
              >
                <div className="manu-list-value">{item?.label}</div>
              </MenuItem>
            );
          })
        ) : (
          <MenuItem disabled>
            <div className="manu-list-value w-full text-center">No Records</div>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

InsightMetricMenu.propTypes = {
  refetchEvent: PropTypes.func.isRequired,
};

export default InsightMetricMenu;
