import { AccordionSummary } from '@mui/material';
import PropTypes from 'prop-types';
import IconByName from '../iconByName/IconByName';

function EzyAccordionSummary({ children, className, ...restOther }) {
  return (
    <AccordionSummary
      expandIcon={<IconByName name="expand_more" />}
      aria-controls="panel1a-content"
      id="panel1a-header"
      className={`ezy-accordion-summary ${className}`}
      {...restOther}
    >
      {children}
    </AccordionSummary>
  );
}

export default EzyAccordionSummary;
EzyAccordionSummary.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
};

EzyAccordionSummary.defaultProps = {
  className: '',
};
