import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { kebabCase, isNull, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import {
  getAllScheduleShiftAction,
  getCoverageAreaDataAction,
  getGraphDataAction,
  getHolidaySettingsAction,
} from '../redux/scheduleActions';
import {
  resetSchedulerData,
  setScheduleCoverageData,
  setScheduleWeatherData,
  setStatsPanelList,
  updateScheduleSidePanelData,
} from '../redux/scheduleSlice';
import Scheduler from '../components/Scheduler';
import AddEditScheduleDrawer from '../components/AddEditScheduleDrawer';
import PublishShiftDrawer from '../components/PublishShiftDrawer';
import ReplaceShiftDrawer from '../../people/components/shiftDetails/ReplaceShiftDrawer';
import SwapShiftDrawer from '../components/SwapShiftDrawer';
import OpenShiftRequestDrawer from '../components/OpenShiftRequestDrawer';
import useReplaceState from '../../../hooks/useReplaceState';
import useQueryParams from '../../../hooks/useQueryParams';
import { getLocationAreaDropdownAction } from '../../timeSheets/redux/timeSheetsAction';
import WeatherModal from '../components/WeatherModal';

function ScheduleList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { shiftIdToHighlight = null } = history?.location?.state ?? {};
  const { location: locationParam, date: dateParam } = useQueryParams();
  const ref = useRef();
  const schedulerRef = useRef(null);
  const { businessId, roleId: loggedInRole } = useSelector(({ user }) => user?.profileData ?? {});
  const [weatherModal, setOpenWeatherModal] = useState(null);

  const {
    weekDatesValue,
    selectedLocation,
    selectedAreaPerson,
    // areaIds,
    filterByPeople,
    specialShift,
    duration,
  } = useSelector(({ schedule }) => schedule?.sidePanelData ?? {});

  const scheduleList = useSelector(({ schedule }) => schedule ?? {});
  const { scheduleData, calendarFooterData, timeOffEntity, coverageData } = useMemo(
    () => scheduleList,
    [scheduleList]
  );
  const { shiftType } = useSelector(({ common }) => common?.enums ?? {});
  const {
    getAllScheduleShiftActionLoader,
    downloadShecduleCSVActionLoader,
    getCoverageAreaDataActionLoader,
    getHolidaySettingsActionLoader,
    getStatsPanelDataActionLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});

  const replaceState = useReplaceState();

  const dataForScheduler = useMemo(() => {
    const resourceList = [];
    const shiftList = [];
    if (scheduleData?.length > 0) {
      if (!filterByPeople) {
        resourceList.push({
          id: 'time_off',
          title: '',
          groupId: 'time_off',
          groupTitle: 'Time off',
        });
        if (coverageData?.length > 0 && ![4, 5]?.includes(loggedInRole)) {
          coverageData?.forEach((e) => {
            e?.coverageData?.forEach(({ date, requiredStaff, scheduledStaff }, index) => {
              shiftList?.push({
                resourceId: `${e?.areaId}`,
                id: index,
                start: moment(date)?.toISOString(),
                end: moment(date)?.endOf('day').toISOString(),
                requiredStaff,
                scheduledStaff,
                title: 'coverage',
                className:
                  +scheduledStaff < +requiredStaff ? 'coverage-area error-color' : 'coverage-area',
              });
            });
          });
        }
        scheduleData?.forEach(
          ({ areaId, areaName, personDetailEntity, emptyShiftEntity, openShiftEntity }) => {
            resourceList.push({
              id: `${areaId}`,
              title: '',
              groupId: areaId,
              groupTitle: areaName,
            });

            if (personDetailEntity?.length > 0) {
              personDetailEntity.forEach(({ shiftEntity, preferredFullName, ...rest }) => {
                if (shiftEntity.length > 0) {
                  shiftEntity.forEach((e) => {
                    const status = kebabCase(
                      shiftType?.find((shift) => shift.id === e.shiftType)?.label
                    );
                    shiftList.push({
                      extendedProps: {
                        ...e,
                        ...rest,
                        areaName,
                        preferredFullName,
                        status,
                      },
                      id: e.shiftId,
                      start: e.startTime,
                      end: e.endTime,
                      title: e.shiftId,
                      resourceId: `${areaId}`,
                      className: status,
                      editable: e?.shiftType !== 4,
                    });
                  });
                }
              });
            }
            if (emptyShiftEntity.length > 0) {
              emptyShiftEntity.forEach((e) => {
                const status = kebabCase(
                  shiftType?.find((shift) => shift.id === e.shiftType)?.label
                );
                shiftList.push({
                  extendedProps: {
                    ...e,
                    areaName,
                    status,
                  },
                  id: e.shiftId,
                  start: e.startTime,
                  end: e.endTime,
                  title: e.shiftId,
                  resourceId: `${areaId}`,
                  className: status,
                });
              });
            }
            if (openShiftEntity.length > 0) {
              openShiftEntity.forEach((e) => {
                const status = kebabCase(
                  shiftType?.find((shift) => shift.id === e.shiftType)?.label
                );
                shiftList.push({
                  extendedProps: {
                    ...e,
                    areaName,
                    status,
                  },
                  id: e.shiftId,
                  start: e.startTime,
                  end: e.endTime,
                  title: e.shiftId,
                  resourceId: `${areaId}`,
                  className: status,
                });
              });
            }
          }
        );
        if (timeOffEntity?.leaves?.length > 0) {
          timeOffEntity?.leaves?.forEach((e) => {
            shiftList.push({
              extendedProps: {
                ...e,
                isTimeOffLeave: true,
              },
              id: Math.random().toFixed(5),
              start: e.startTime,
              end: e.endTime,
              title: e.personName,
              resourceId: 'time_off',
              editable: false,
              className: 'leave_timeoff',
            });
          });
        }
        if (timeOffEntity?.unavailabilities?.length > 0) {
          timeOffEntity?.unavailabilities?.forEach((e) => {
            shiftList.push({
              extendedProps: {
                ...e,
                isUnAvailability: true,
              },
              id: Math.random().toFixed(5),
              start: e.startTime,
              end: e.endTime,
              title: e.personName,
              resourceId: 'time_off',
              editable: false,
              className: 'unavailable_timeoff',
            });
          });
        }
      } else {
        const tempId = Math.random().toFixed(2);
        resourceList.push({
          id: tempId,
          title: 'Un Assigned(Empty/Open)',
          extendedProps: {
            emptyShiftTotalHours: calendarFooterData?.emptyShiftTotalHours,
            openShiftTotalHours: calendarFooterData?.openShiftTotalHours,
          },
          // groupId: 1,
          // groupTitle: 'temp',
        });
        scheduleData?.forEach(
          ({ /* areaId, */ areaName, personDetailEntity, emptyShiftEntity, openShiftEntity }) => {
            if (personDetailEntity?.length > 0) {
              personDetailEntity.forEach(
                ({ shiftEntity, preferredFullName, personDetailId, payRate, ...rest }) => {
                  resourceList.push({
                    id: personDetailId,
                    title: preferredFullName,
                    personDetailId,
                    extendedProps: {
                      preferredFullName,
                      personDetailId,
                      payRate,
                      ...rest,
                    },
                    // groupId: areaId,
                    // groupTitle: areaName,
                  });
                  if (shiftEntity.length > 0) {
                    shiftEntity.forEach((e) => {
                      const status = kebabCase(
                        shiftType?.find((shift) => shift.id === e.shiftType)?.label
                      );
                      shiftList.push({
                        extendedProps: {
                          ...e,
                          ...rest,
                          areaName,
                          preferredFullName,
                          status,
                        },
                        id: e.shiftId,
                        start: e.startTime,
                        end: e.endTime,
                        title: e.shiftId,
                        resourceId: personDetailId,
                        className: status,
                        borderColor: '#34a853',
                      });
                    });
                  }
                }
              );
            }
            if (emptyShiftEntity.length > 0) {
              emptyShiftEntity.forEach((e) => {
                const status = kebabCase(
                  shiftType?.find((shift) => shift.id === e.shiftType)?.label
                );
                shiftList.push({
                  extendedProps: {
                    ...e,
                    areaName,
                    status,
                  },
                  id: e.shiftId,
                  start: e.startTime,
                  end: e.endTime,
                  title: e.shiftId,
                  resourceId: tempId,
                  className: status,
                  borderColor: '#7f7f7f',
                });
              });
            }
            if (openShiftEntity.length > 0) {
              openShiftEntity.forEach((e) => {
                const status = kebabCase(
                  shiftType?.find((shift) => shift.id === e.shiftType)?.label
                );
                shiftList.push({
                  extendedProps: {
                    ...e,
                    areaName,
                    status,
                  },
                  id: e.shiftId,
                  start: e.startTime,
                  end: e.endTime,
                  title: e.shiftId,
                  resourceId: tempId,
                  className: status,
                  borderColor: '#fbbc05',
                });
              });
            }
          }
        );
      }
    }

    if (specialShift) {
      const indexToUpdate = shiftList?.findIndex((e) => e?.id === specialShift);
      if (indexToUpdate !== -1) {
        shiftList[indexToUpdate].className = `${shiftList[indexToUpdate].className} blink-me`;
      }
    }

    return { resourceList, events: shiftList };
  }, [scheduleData, timeOffEntity, filterByPeople, specialShift, coverageData]);

  const getIconName = (icon) => {
    switch (icon) {
      case 'partly-cloudy-day':
      case 'cloudy':
        return 'cloud';
      case 'rain':
        return 'water_drop';
      case 'snow':
        return 'ac_unit';
      default:
        return 'sunny';
    }
  };

  const setHeaderData = (data, durationFilter) => {
    const headerElement = document.querySelectorAll('.fc-timeline-header-row > .fc-timeline-slot '); // Get the header element

    for (let i = 0; i < headerElement?.length; i += 1) {
      const node = data?.find(
        (x) =>
          moment.tz(x?.date, selectedLocation?.timeZoneId?.label)?.format('YYYY-MM-DD') ===
          headerElement?.[i]?.getAttribute('data-date')
      );

      if (durationFilter?.id === 1 && data !== null) {
        headerElement[i].querySelector(
          '.fc-timeline-slot-cushion'
        ).innerHTML = `<span class="custom-header-text" id=${headerElement?.[i]?.getAttribute(
          'data-date'
        )}>
            <span>${moment
              .tz(
                headerElement?.[i]?.getAttribute('data-date'),
                selectedLocation?.timeZoneId?.label
              )
              .format('DD ddd')}</span>
            ${node?.isPublicHoliday ? `<span class="material-icons">beach_access</span>` : ''}
            ${node?.isTimesOffRequest ? `<span class="material-icons">block</span>` : ''}
            ${
              node?.weatherInfo?.icon
                ? `<span class="material-icons">${getIconName(node?.weatherInfo?.icon)}</span>`
                : ''
            }
          </span>`;
      } else if (durationFilter?.id !== 5 && durationFilter?.id !== 10) {
        headerElement[i].querySelector('.fc-timeline-slot-cushion').innerHTML = `${moment
          .tz(headerElement?.[i]?.getAttribute('data-date'), selectedLocation?.timeZoneId?.label)
          .format('DD ddd')}`;
      }

      headerElement?.[i]?.addEventListener('click', (event) => {
        if ([1, 2, 3, 4]?.includes(durationFilter?.id)) {
          event.stopPropagation();
          setOpenWeatherModal({
            ...event,
            headerText: headerElement?.[i]?.getAttribute('data-date'),
          });
        } else {
          setOpenWeatherModal(null);
        }
      });
    }
  };

  const changeBackground = (data) => {
    const elements = document.querySelectorAll('.fc-timeline-slot-lane');

    for (let i = 0; i < elements?.length; i += 1) {
      const node = data?.find(
        (x) =>
          moment.tz(x?.date, selectedLocation?.timeZoneId?.label)?.format('YYYY-MM-DD') ===
          elements?.[i]?.getAttribute('data-date')
      );

      if (node?.isPublicHoliday) {
        elements?.[i]?.classList?.add('public-holiday');
      } else {
        elements?.[i]?.classList?.remove('public-holiday');
      }
    }
  };

  const getSchedulesByFilter = async (params = {}) => {
    const paramsData = {
      personDetailId: selectedAreaPerson,
      startDate: moment(weekDatesValue?.startDate).toISOString(),
      endDate: moment(weekDatesValue?.endDate).toISOString(),
      businessId: selectedLocation?.id === 'all' ? businessId : null,
      locationId:
        selectedLocation?.id !== 'all' && !selectedLocation?.locationId
          ? selectedLocation?.id
          : selectedLocation?.locationId,
      areaIds: selectedLocation?.locationId ? selectedLocation?.id : null,
      ...params,
    };

    // action to remove graph data
    dispatch(setStatsPanelList({ listName: 'graphData', value: [] }));

    await dispatch(
      getAllScheduleShiftAction(paramsData, ({ showCoverageArea, showStatsPanel }) => {
        // API call for display coverage area
        if (showCoverageArea) dispatch(getCoverageAreaDataAction(paramsData));
        else dispatch(setScheduleCoverageData([]));

        // API call for display weather data & holiday settings
        if (paramsData?.locationId && [1, 2, 3, 4]?.includes(duration?.id)) {
          dispatch(
            getHolidaySettingsAction(
              {
                locationId: paramsData?.locationId,
                startDate: paramsData?.startDate,
                endDate: paramsData?.endDate,
              },
              (data) => {
                setHeaderData(data, duration);
                changeBackground(data);
              }
            )
          );
        } else {
          changeBackground(null);
          setHeaderData(null, duration);
          dispatch(setScheduleWeatherData([]));
        }

        // API call display graph

        if (showStatsPanel) {
          const paramsForStatsPanel = {
            locationId: paramsData?.locationId,
            startDate: paramsData?.startDate,
            endDate: paramsData?.endDate,
            businessId: paramsData?.businessId,
            areaId: paramsData?.areaIds,
          };
          dispatch(getGraphDataAction(paramsForStatsPanel));
        }
      })
    );
  };

  replaceState({
    location: selectedLocation?.id,
    date: moment(weekDatesValue?.startDate).toISOString(),
  });

  const handleCalendarEvents = async (e) => {
    dispatch(
      updateScheduleSidePanelData({
        fieldName: 'weekDatesValue',
        value: {
          startDate: moment(e?.start),
          endDate: moment(e?.end).subtract(1, 'minute'),
        },
      })
    );

    if (
      !moment(e?.start).isSame(weekDatesValue?.startDate, 'day') ||
      !moment(e?.end).isSame(weekDatesValue?.endDate, 'day')
    )
      await getSchedulesByFilter({
        startDate: moment(e?.start).toISOString(),
        endDate: moment(e?.end).subtract(1, 'minute').toISOString(),
      });
  };

  const reFetchEvents = async (params) => {
    await getSchedulesByFilter(params);
  };

  const handleEventBlinking = () => {
    dispatch(updateScheduleSidePanelData({ fieldName: 'specialShift', value: null }));
  };

  useEffect(() => {
    if (shiftIdToHighlight) {
      dispatch(
        updateScheduleSidePanelData({ fieldName: 'specialShift', value: shiftIdToHighlight })
      );
      ref?.current?.addEventListener('click', handleEventBlinking, { once: true });
    }

    return () => {
      dispatch(resetSchedulerData());
    };
  }, []);

  const fetchTimeSheet = async ({ locationId, date }) => {
    await dispatch(
      getLocationAreaDropdownAction(
        { locationId },
        async (response) => {
          if (response?.length) {
            const paramLocation = response?.find((location) => location?.id === locationId);

            const weekStartsOn = paramLocation?.weekStartsOn
              ? paramLocation?.weekStartsOn
              : response?.[0]?.weekStartsOn;
            moment.updateLocale('en', {
              week: {
                dow: weekStartsOn === 7 ? 0 : weekStartsOn,
              },
            });

            const params = {
              startDate: moment
                .tz(date, paramLocation?.timeZoneId?.label || response?.[0]?.timeZoneId?.label)
                .startOf('week')
                .toISOString(),

              endDate: moment
                .tz(date, paramLocation?.timeZoneId?.label || response?.[0]?.timeZoneId?.label)
                .endOf('week')
                .toISOString(),
            };

            if (locationId && locationId !== 'all' && !paramLocation?.locationId) {
              params.locationId = locationId;
            } else if (paramLocation?.locationId) {
              params.areaIds = paramLocation?.id;
              params.locationId = paramLocation?.locationId;
            } else {
              params.businessId = businessId;
            }

            schedulerRef?.current?.handleDateChange({
              startDate: moment
                .tz(date, paramLocation?.timeZoneId?.label || response?.[0]?.timeZoneId?.label)
                .startOf('week'),
              endDate: moment
                .tz(date, paramLocation?.timeZoneId?.label || response?.[0]?.timeZoneId?.label)
                .endOf('week'),
            });

            dispatch(
              updateScheduleSidePanelData({
                fieldName: 'weekDatesValue',
                value: {
                  startDate: moment
                    .tz(date, paramLocation?.timeZoneId?.label || response?.[0]?.timeZoneId?.label)
                    .startOf('week'),
                  endDate: moment
                    .tz(date, paramLocation?.timeZoneId?.label || response?.[0]?.timeZoneId?.label)
                    .endOf('week'),
                },
              })
            );

            await getSchedulesByFilter(params);
          }
        },
        true
      )
    );
  };

  useEffect(() => {
    (async () => {
      await fetchTimeSheet({
        locationId: !locationParam || locationParam === 'undefined' ? undefined : locationParam,
        date: dateParam || new Date(),
      });
    })();
  }, []);

  return (
    <div className="schedule-wrapper" ref={ref}>
      <div className="schedule-list-wrapper">
        {selectedLocation?.timeZoneId?.label && (
          <Scheduler
            data={{
              startDate: new Date(weekDatesValue?.startDate),
              endDate: new Date(weekDatesValue?.endDate),
              filterByPeople,
              locationId: selectedLocation?.id,
              calendarFooterData,
              ...dataForScheduler,
            }}
            loader={
              getAllScheduleShiftActionLoader ||
              downloadShecduleCSVActionLoader ||
              getCoverageAreaDataActionLoader ||
              getHolidaySettingsActionLoader ||
              getStatsPanelDataActionLoader
            }
            eventHandler={handleCalendarEvents}
            reFetchEvents={reFetchEvents}
            ref={schedulerRef}
          />
        )}
      </div>
      <AddEditScheduleDrawer reFetchEvents={reFetchEvents} />
      <PublishShiftDrawer reFetchEvents={reFetchEvents} />
      <ReplaceShiftDrawer />
      <SwapShiftDrawer reFetchEvents={reFetchEvents} />
      <OpenShiftRequestDrawer reFetchEvents={reFetchEvents} />
      <WeatherModal
        open={!isNull(weatherModal) || !isEmpty(weatherModal)}
        handleClose={() => setOpenWeatherModal(null)}
        selectedDate={weatherModal?.headerText}
        reFetchEvents={reFetchEvents}
      />
    </div>
  );
}

export default ScheduleList;
