import {
  saveEnumListData,
  setCountryList,
  setIndustryList,
  setTimeZonesList,
} from './commonDataSlice';
import { displayErrors } from '../errorNotifyHelper';
import ROUTE_CONSTANTS, {
  BUSINESS_TYPE_ENUM,
  COUNTRY_LIST,
  ENUMS_LIST,
  TIMEZONE_LIST,
} from '../constants';

export const getEnumsList = () => {
  return async (dispatch) => {
    try {
      // const res = await commonServices.enumsLists();
      // if (res?.data?.success) {
      dispatch(saveEnumListData(ENUMS_LIST));
      // }
    } catch (e) {
      /**/
    }
  };
};

export const getIndustryTypeList = () => {
  return async (dispatch) => {
    try {
      // const res = await commonServices.getIndustryList();
      // if (res?.data?.success) {
      dispatch(setIndustryList(BUSINESS_TYPE_ENUM?.map((e) => ({ label: e?.name, value: e?.id }))));
      // }
    } catch (e) {
      /**/
    }
  };
};

export const getCountryList = () => {
  return async (dispatch) => {
    try {
      // const res = await commonServices.getCountryList();
      // if (res?.data?.success) {
      dispatch(
        setCountryList(COUNTRY_LIST?.map((e) => ({ label: e?.name, value: e?.id, code: e?.code })))
      );
      // }
    } catch (e) {
      /**/
    }
  };
};

export const getAllTimezonesAction = () => {
  return async (dispatch) => {
    try {
      // const res = await commonServices.getAllTimeZones();
      // if (res?.data?.success) {
      dispatch(setTimeZonesList(TIMEZONE_LIST));
      // }
    } catch (e) {
      /**/
    }
  };
};

export const handlePaymentRequired = () => {
  return async (dispatch, getState) => {
    try {
      const profileData = getState().user?.profileData;
      if (profileData.roleId === 1) {
        window.location.href = `${ROUTE_CONSTANTS.ACCOUNT_OVERVIEW}/plans/${profileData.businessId}?planExpired=true`;
      } else {
        window.location.href = `${ROUTE_CONSTANTS.ACCOUNT_OVERVIEW}?planExpired=true`;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
