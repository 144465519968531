import { omitBy, isNil } from 'lodash';
import { requireValidate } from '../../../utils/validationHelper';

const validateMetricSettings = (data) => {
  const {
    metricName,
    metricType,
    levelOfDetail,
    isForecastActivated,
    metricForecastBasedOnEntity,
  } = data ?? {};

  const error = {};

  error.metricName = requireValidate('Metric Name', metricName);
  error.metricType = requireValidate('Metric type', metricType);
  error.levelOfDetail = requireValidate('Level of detail', levelOfDetail);

  if (isForecastActivated)
    error.metricForecastBasedOnEntity = requireValidate(
      'Metric forecast based on',
      metricForecastBasedOnEntity
    );

  return omitBy(error, isNil);
};

export default validateMetricSettings;
