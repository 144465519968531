import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import logo from '../assets/images/logo/favicon.ico';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FCM_apiKey,
  authDomain: process.env.REACT_APP_FCM_authDomain,
  projectId: process.env.REACT_APP_FCM_projectId,
  storageBucket: process.env.REACT_APP_FCM_storageBucket,
  messagingSenderId: process.env.REACT_APP_FCM_messagingSenderId,
  appId: process.env.REACT_APP_FCM_appId,
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestForToken = (cb) => {
  return getToken(messaging, {
    vapidKey: process.env.REACT_APP_FCM_vapidKey,
  })
    .then((currentToken) => {
      if (currentToken) {
        cb(currentToken);
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const requestPermission = (cb) => {
  if (!('Notification' in window)) {
    alert('This browser does not support desktop notification');
  } else if (Notification.permission === 'granted') {
    cb(requestForToken);
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        cb(requestForToken);
      }
    });
  }

  // At last, if the user has denied notifications, and you
  // want to be respectful there is no need to bother them anymore.
};

export const onMessageListener = (cb) =>
  onMessage(messaging, (payload) => {
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
      icon: logo,
    };

    if (!('Notification' in window)) {
      console.log('This browser does not support system notifications');
    }
    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      const notification = new Notification(notificationTitle, notificationOptions);
      notification.onclick = function () {
        notification.close();
      };
    }
    cb(payload);
  });
