import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Checkbox, FormHelperText } from '@mui/material';
import moment from 'moment-timezone';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import EzyIconButton from '../../../components/common/iconButton/EzyIconButton';
import {
  cleanAddressLocationAreas,
  editDeleteGeneralLocationAreas,
  resetEditLocationData,
  setEditGeneralLocationList,
  updateEditGeneralLocationAreas,
  updateEditGeneralLocationDays,
} from '../redux/locationSlice';
import EzyAutoComplete from '../../../components/common/autoComplete/EzyAutoComplete';
import { dayList } from '../../../utils/constants';
import { getLocationByLocationID } from '../redux/locationActions';
import EzyIconTooltip from '../../../components/common/tooltip/EzyIconTooltip';
import Loader from '../../../components/common/loader/Loader';
import EzySwitchButton from '../../../components/common/switch/EzySwitchButton';
import IconByName from '../../../components/common/iconByName/IconByName';
import EzySwitch from '../../../components/common/switch/EzySwitch';
import EzyTime from '../../../components/common/dateAndTimePicker/EzyTime';
import EzyButton from '../../../components/common/button/EzyButton';
import { errorNotification } from '../../../utils/notifyHelper';

function EditLocationGeneral() {
  const dispatch = useDispatch();
  const { locationId } = useParams();

  const editLocationData = useSelector(({ location }) => location.editLocationData || {});

  const { timeZonesList } = useSelector(({ common }) => common ?? {});

  const { getLocationByLocationIDLoader } = useSelector(({ common }) => common.generalLoader ?? {});
  const {
    locationName,
    locationAddress,
    locationCode,
    timeZoneId,
    errors,
    addAreaAddress,
    note,
    areas,
    city,
  } = useMemo(() => editLocationData, [editLocationData]);

  const { weekDay } = editLocationData ?? {};

  const handleFieldChange = (name, value) => {
    dispatch(setEditGeneralLocationList({ fieldName: name, value }));
  };

  const updateAreaFields = (name, value, index) => {
    dispatch(updateEditGeneralLocationAreas({ fieldName: name, value, index }));
  };
  const updateWeekDayFields = (name, value, index) => {
    dispatch(updateEditGeneralLocationDays({ fieldName: name, value, index }));
  };

  useEffect(() => {
    dispatch(getLocationByLocationID({ locationId }));
    return () => {
      dispatch(resetEditLocationData());
    };
  }, []);

  return (
    <div className="add-location-root-container">
      {getLocationByLocationIDLoader ? (
        <Loader />
      ) : (
        <>
          <div className="add-location-title-header">Location</div>
          <div className="two-grid p-x-24">
            <EzyInputField
              name="locationName"
              placeholder="Enter location name"
              value={locationName}
              label="Location name"
              onChange={(e) => {
                if (e.target.value.length <= 50) handleFieldChange('locationName', e.target.value);
              }}
              error={errors?.locationName}
              required
            />
            <EzyInputField
              name="locationAddress"
              placeholder="Enter location address"
              label="Location address"
              inputProps={{
                maxLength: 300,
              }}
              multiline
              maxRows={2}
              value={locationAddress}
              onChange={(e) => handleFieldChange('locationAddress', e.target.value)}
              error={errors?.locationAddress}
              required
            />
          </div>
          <div className="three-grid p-x-24">
            <EzyInputField
              label="Location city"
              placeholder="Enter city name"
              name="city"
              value={city}
              onChange={(e) => handleFieldChange('city', e?.target?.value)}
              error={errors?.city}
              required
            />
            <EzyInputField
              name="locationCode"
              placeholder="Enter location code"
              label="Location code"
              value={locationCode}
              onChange={(e) => {
                if (e.target.value.length <= 6) handleFieldChange('locationCode', e.target.value);
              }}
              error={errors?.locationCode}
              required
            />
            <EzyAutoComplete
              name="timeZoneId"
              placeholder="Enter location address"
              label="Location time zone"
              value={timeZoneId}
              options={timeZonesList}
              onChange={(_, value) => {
                const updatedWeekDay = weekDay.map((res) => {
                  return {
                    ...res,
                    startTime: moment
                      .tz(res?.startTime, value?.label ?? moment.tz.guess())
                      .set({ hours: 9, minutes: 0 })
                      ?.toISOString(),
                    endTime: moment
                      .tz(res?.endTime, value?.label ?? moment.tz.guess())
                      .set({ hours: 17, minutes: 0 })
                      ?.toISOString(),
                  };
                });

                handleFieldChange('timeZoneId', value);
                handleFieldChange('weekDay', updatedWeekDay);
              }}
              error={errors?.timeZoneId}
              required
            />
          </div>
          <div className="add-location-title-header">
            <span>
              Working Hours
              <EzyIconTooltip tooltip="Time here is based on location time zone setting." />
            </span>
          </div>
          <div className="two-grid-working-hours">
            {weekDay.map((res, index) => (
              <div
                className={`working-hours-card ${res.isActive ? 'border-active' : 'disabled-card'}`}
                key={index}
              >
                <div className="title">
                  <span className="day-label">{dayList?.[index]}</span>
                  <Checkbox
                    edge="start"
                    checked={res?.isActive}
                    tabIndex={-1}
                    disableRipple
                    icon={<IconByName name="check_circle_outline" />}
                    checkedIcon={<IconByName name="check_circle" className="primary-color" />}
                    onChange={(e) => {
                      updateWeekDayFields('isActive', e.target.checked, index);
                      if (!e.target.checked && res?.is24Hours) {
                        updateWeekDayFields('is24Hours', false, index);
                      }
                    }}
                  />
                </div>
                <EzySwitch
                  label="24 Hours"
                  checked={res?.is24Hours}
                  handleChange={() => updateWeekDayFields('is24Hours', !res.is24Hours, index)}
                  disabled={!res?.isActive}
                />
                <div className="time">
                  <EzyTime
                    name="startTime"
                    placeholder="09:00 AM"
                    value={res?.startTime}
                    onChange={(value) => {
                      updateWeekDayFields('startTime', value, index);
                    }}
                    timeZone={timeZoneId?.label}
                    disabled={!res?.isActive || res?.is24Hours}
                  />
                  <span>to</span>
                  <EzyTime
                    name="endTime"
                    placeholder="05:00 PM"
                    value={res?.endTime}
                    onChange={(value) => {
                      updateWeekDayFields('endTime', value, index);
                    }}
                    timeZone={timeZoneId?.label}
                    disabled={!res?.isActive || res?.is24Hours}
                  />
                </div>
                {errors?.weekDay?.[index]?.startTime ||
                  (errors?.weekDay?.[index]?.endTime && (
                    <FormHelperText className="error-helper">
                      {errors?.weekDay?.[index]?.startTime || errors?.weekDay?.[index]?.endTime}
                    </FormHelperText>
                  ))}
              </div>
            ))}
          </div>
          <div className="p-x-24">
            <EzyInputField
              placeholder="Enter note...."
              label="Note"
              multiline
              minRows={3}
              className="edit-general-location-text-area"
              value={note}
              onChange={(e) => handleFieldChange('note', e.target.value)}
            />
          </div>
          <div className="add-location-title-header">
            <span className="add-area-header">
              <span>
                Areas
                <EzyIconTooltip tooltip="In payrollEZY, people are scheduled to Areas of work. This can be roles, departments, physical areas." />
              </span>

              <span>
                <EzyButton
                  label="Add"
                  className="no-pad-button"
                  startIcon={<IconByName name="add" />}
                  onClick={() => dispatch(editDeleteGeneralLocationAreas({ type: 'ADD' }))}
                />
              </span>
            </span>
            <div className="location-sub-header-button-container">
              <span>Add Area Address</span>
              <EzySwitchButton
                name="addAreaAddress"
                checked={addAreaAddress}
                onChange={() => {
                  handleFieldChange('addAreaAddress', !addAreaAddress);
                  if (addAreaAddress) {
                    dispatch(cleanAddressLocationAreas());
                  }
                }}
                className="small"
              />
            </div>
          </div>
          {areas?.map((res, index) => (
            <div className="two-grid p-x-24" key={index}>
              <EzyInputField
                placeholder="Enter area name"
                name="areaOfWork"
                label="Area name"
                value={res.areaName}
                onChange={(value) => {
                  if (value.target.value.length <= 50)
                    updateAreaFields('areaName', value?.target?.value, index);
                }}
                error={errors?.areas?.[index]?.areaName}
                required
              />
              <div className="d-flex gap-10">
                {addAreaAddress && (
                  <EzyInputField
                    name="address"
                    placeholder="Enter area address"
                    label="Area address"
                    value={res.areaAddress}
                    inputProps={{
                      maxLength: 300,
                    }}
                    multiline
                    maxRows={2}
                    onChange={(value) =>
                      updateAreaFields('areaAddress', value?.target?.value, index)
                    }
                    error={errors?.areas?.[index]?.areaAddress}
                    required
                  />
                )}

                <EzyIconButton
                  iconName="delete"
                  onClick={() => {
                    if (areas?.length === 1) {
                      errorNotification(
                        'Sorry, you cannot delete this. You must have one Area of Work at least.'
                      );
                    } else {
                      dispatch(editDeleteGeneralLocationAreas({ type: 'DELETE', index }));
                    }
                  }}
                  className={
                    errors?.areas?.[index]?.areaName || errors?.areas?.[index]?.areaAddress
                      ? 'align-self-center'
                      : 'align-self-end'
                  }
                />
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export default EditLocationGeneral;
