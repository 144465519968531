import html2pdf from 'html2pdf.js';
import logo from '../assets/images/logo/PayrollEzy_WhiteLogo.png';
import { displayDate, displayDateTime, formatInvoiceDate } from './timeHelper';

export const generateAndDownloadPDF = async (data, timeZone) => {
  const promise = new Promise((resolve, reject) => {
    try {
      const {
        billDetails,
        invoiceDetails,
        payments,
        refund,
        balance,
        amountDue,
        description,
        servicePeriodStartDate,
        servicePeriodEndDate,
        quantity,
        unitPrice,
        amount,
        subTotal,
        discount,
        taxList,
        invoiceTotal,
        userName,
      } = data ?? {};
      billDetails.address = `${billDetails?.address?.line1}, ${billDetails?.address?.line2}, ${billDetails?.address?.city}.</br> ${billDetails?.address?.state}, ${billDetails?.address?.country} - ${billDetails?.address?.postalCode}`;
      const invoicePDF = document.getElementById('invoice-pdf');
      const pdfData = `<div id="invoice-wrapper"> 
        <div class="invoice-wrapper">
          <div class="header"> 
            <img src={${logo}} alt="payrollEzy" />
          </div>
          <div class="main"> 
            <div class="details">
              <div class="bill-details">
                <table>
                  <thead>
                    <tr>
                      <th>Bill Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>${billDetails?.name}</td>
                    </tr>
                    <tr>
                      <td>${billDetails?.email}</td>
                    </tr>
                    <tr>
                      <td>
                        ${billDetails?.address}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="bill-details">
                <table>
                  <thead>
                    <tr>
                      <th>Invoice Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>${invoiceDetails?.invoiceNumber}</td>
                    </tr>
                    <tr>
                      <td>${displayDateTime(invoiceDetails?.date, timeZone)}</td>
                    </tr>
                    <tr>
                      <td>Currency : ${invoiceDetails?.currency}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="amounts">
              <div class="devider"></div>
              <div class="amount-table">
                <table>
                  <thead>
                    <tr>
                      <th>PAYMENTS</th>
                      <th>REFUNDS & ADJUSTMENTS</th>
                      <th>BALANCE</th>
                      <th>AMOUNT DUE</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>${payments}</td>
                      <td>${refund}</td>
                      <td>${balance}</td>
                      <td>${amountDue}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="devider"></div>
              <div class="amount-table">
                <table>
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Service Period</th>
                      <th>Quantity</th>
                      <th>Unit Price (Excl. GST)</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>${description}</td>
                      <td>
                        ${displayDate(servicePeriodStartDate, timeZone)} <br />
                        ${displayDate(servicePeriodEndDate, timeZone)} 
                      </td>
                      <td>${quantity}</td>
                      <td>${unitPrice}</td>
                      <td>${amount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="devider"></div>
            <div class="total">
              <div class="total-wrapper">
                <div class="amount-wrapper">
                  <div class="label">SUB TOTAL (Excl. GST)</div>
                  <div class="value">${subTotal}</div>
                </div>
                <div class="amount-wrapper">
                  <div class="label">DISCOUNT</div>
                  <div class="value">${discount}</div>
                </div>
                <div class="amount-wrapper">
                  ${taxList?.map(
                    (e) =>
                      `<div>
                      <div class="label">
                        TAX (${e?.taxRate}% ${e?.taxType})
                      </div> 
                      <div class="value">${e?.taxAmount}</div>
                    </div>`
                  )}
                </div>
                <div class="amount-wrapper">
                  <div class="label">INVOICE TOTAL</div>
                  <div class="value">${invoiceTotal}</div>
                </div>
              </div>
            </div> 
            <div class="note">
              <div class="dot"></div>
              <div class="text">
                No action is necessary. The amount due will be debited from your
                nominated Credit Card or PayPal account
              </div>
            </div>
          </div>
        </div>
        <div class="break-here"></div>
        <div class="invoice-wrapper">
          <div class="header">
            <img src={logo} alt="payrollEzy" />
          </div>
          <div class="main">
            <div class="second-details-wrapper">
              <div class="head-with-dot">
                <div class="dot"></div>
                <div class="head-wrapper">
                  <div class="heading">Usage details</div>
                  <div class="sub-heading">
                    Employee Onboarding Credits Remaining
                  </div>
                </div>
              </div>
              <div class="usage-details">
                <div class="usage-wrapper">
                  <div class="usage-light-head">Charge Info</div>
                  <div>${description}</div>
                </div>
                <div class="usage-wrapper">
                  <div class="usage-light-head">Charge Quantity</div>
                  <div>${quantity}</div>
                </div>
              </div>
            </div>
            <div class="devider"></div>
            <div class="second-details-wrapper">
              <div class="head-with-dot">
                <div class="dot"></div>
                <div class="heading">Users</div>
              </div>
          <div class="users-grid" id="users"></div>
            </div> 
          </div> 
        </div>
      </div>`;
      invoicePDF.innerHTML = pdfData;

      const userElement = document.getElementById('users');

      userName.forEach((e) => {
        const user = `<div class="user">${e}</div>`;
        userElement.innerHTML += user;
      });

      const generatePDF = async () => {
        const element = document.getElementById('invoice-wrapper');
        const opt = {
          margin: 0,
          filename: `PayrollEzy-${invoiceDetails?.invoiceNumber}_${formatInvoiceDate(
            servicePeriodStartDate,
            timeZone
          )}_${formatInvoiceDate(servicePeriodEndDate, timeZone)}.pdf`,
          image: { type: 'jpeg', quality: 100 },
          html2canvas: { scale: 3 },
          jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
          pagebreak: { before: '.break-here' },
        };
        const html = element.outerHTML;
        await html2pdf().set(opt).from(html).save();
        invoicePDF.innerHTML = null;
        resolve();
      };
      generatePDF();
    } catch (e) {
      reject(e);
    }
  });
  return promise;
};
