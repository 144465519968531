import { filter, isEmpty, isNil, omitBy, toLower } from 'lodash';
import {
  findDuplicates,
  isEmail,
  requireValidate,
  validatePhoneNumberLength,
} from '../../../utils/validationHelper';

const validateAddLocation = (data) => {
  const {
    locationName,
    locationAddress,
    weekStartOn,
    timeZoneId,
    areas,
    people,
    addAreaAddress,
    city,
  } = data ?? {};
  const error = {};
  error.locationName = requireValidate('Location Name', locationName);
  error.locationAddress = requireValidate('Location Address', locationAddress);
  error.weekStartOn = requireValidate('Week Starts on', weekStartOn);
  error.timeZoneId = requireValidate('Timezone', timeZoneId);
  error.city = requireValidate('City', city);
  error.areas = areas?.map((e) =>
    omitBy(
      {
        areaOfWork: requireValidate('Area Name', e?.areaOfWork),
        address: addAreaAddress ? requireValidate('Area address', e?.address) : null,
      },
      isNil
    )
  );

  error.people = people?.map((e) =>
    omitBy(
      {
        name:
          e?.email || e?.name || e?.phoneNumber ? requireValidate('People Name', e?.name) : null,
        email: e?.email || e?.name || e?.phoneNumber ? isEmail('Email', e?.email) : null,
        phoneNumber:
          e?.phoneNumberData &&
          e?.phoneNumber &&
          !validatePhoneNumberLength(e?.phoneNumber, e?.phoneNumberData?.format)
            ? 'Invalid phone number'
            : null,
      },
      isNil
    )
  );

  const duplicates = findDuplicates(people?.map((e) => toLower(e?.email)));
  if (duplicates?.length > 0) {
    duplicates?.forEach((e) => {
      error.people[e].email = 'Email already exists!';
    });
  }
  const duplicateAras = findDuplicates(areas?.map((e) => toLower(e?.areaOfWork)));
  if (duplicateAras?.length > 0) {
    if (!error.areas || error.areas.length <= 0) {
      error.areas = [];
    }
    duplicateAras?.forEach((e) => {
      error.areas[e].areaOfWork = 'Area name must be unique!';
    });
  }
  error.people = filter(error.people, (e) => !isEmpty(e))?.length ? error.people : null;
  error.areas = filter(error.areas, (e) => !isEmpty(e))?.length ? error.areas : null;
  return omitBy(error, isNil);
};

export default validateAddLocation;
