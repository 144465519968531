import apiService from '../../utils/apiService';
import API_URL from '../../utils/urlConstants';

const authServices = {
  businessSignUp: (data) => apiService.postData(API_URL.AUTH.businessSignUp, data),
  userSignUp: (data) => apiService.postData(API_URL.AUTH.userSignUp, data),
  login: (data) => apiService.postData(API_URL.AUTH.login, data),
  logout: (data) => apiService.postData(API_URL.PEOPLE.logout, data),
  forgotPassword: (data) => apiService.postData(API_URL.AUTH.forgotPassword, data),
  resetPassword: (data) => apiService.postData(API_URL.AUTH.resetPassword, data),
  validateResetLink: (data) => apiService.postData(API_URL.AUTH.validateLink, data),
  getUserSignUpDetails: (params) =>
    apiService.getData(API_URL.AUTH.getUserSignUpDetails, { params }),
  getCurrentUserData: () => apiService.getData(API_URL.PEOPLE.getCurrentUserData),
  verifyEmailFromLink: (params) =>
    apiService.putData(API_URL.AUTH.verifyEmailFromLink, null, { params }),
};

export default authServices;
