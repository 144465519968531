import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { InputAdornment } from '@mui/material';
import { useDispatch } from 'react-redux';
import EzyDialog from '../../components/common/dialog/EzyDialog';
import blackLogo from '../../assets/images/logo/PayrollEzy_BlackLogo.png';
import EzyInputField from '../../components/common/inputField/EzyInputField';
import EzyButton from '../../components/common/button/EzyButton';
import authServices from './authServices';
import validateResetPassword from './validations/validateResetPassword';
import InfoModal from '../../components/infoModal/InfoModal';
import linkNotFound from '../../assets/images/Link not found.png';
import ROUTE_CONSTANTS from '../../utils/constants';
import { errorNotification, successNotification } from '../../utils/notifyHelper';
import useKeyQueryParams from '../../hooks/useKeyQueryParams';
import checkEmailImage from '../../assets/images/Check your email.png';
import {
  clearAuthTokenFromLocalStorage,
  clearFCMTokenFromLocalStorage,
} from '../../utils/localStorageHelper';
import useKeyPress from '../../hooks/useKeyPress';

function ResetPassword() {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState({
    pass: false,
    cPass: false,
  });
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [resetPassModal, setResetPassModal] = useState(false);
  const [isExpireModal, setIsExpireModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSetPasswordModal, setIsSetPasswordModal] = useState(false);
  const history = useHistory();
  const { key } = useKeyQueryParams();
  const dispatch = useDispatch();
  const isEnterPressed = useKeyPress('Enter');

  const updateFormData = (e) => {
    const { name, value } = e.target;
    setFormData((data) => ({ ...data, [name]: value }));
  };

  const handlePasswordShow = (index) => {
    setShowPassword((e) => ({ ...e, [index]: !e[index] }));
  };

  const handleSubmit = async () => {
    const errorsData = validateResetPassword(formData);
    setErrors(errorsData);
    if (isEmpty(errorsData)) {
      try {
        setLoading(true);
        const res = await authServices.resetPassword({
          id: userId,
          password: formData.password.toString().trim(),
        });
        if (res?.data?.success) {
          successNotification(res?.data?.message);
          setIsSetPasswordModal(true);
          if (localStorage.getItem('ezyAuthToken')) {
            clearAuthTokenFromLocalStorage();
            clearFCMTokenFromLocalStorage();
            dispatch({ type: 'USER_LOGOUT' });
          }
          // history.push(ROUTE_CONSTANTS.LOGIN);
        }
      } catch (e) {
        errorNotification(e?.res?.data?.message);
      } finally {
        setLoading(false);
      }
    }
  };
  const handelInfoModalClose = () => {
    history.push(ROUTE_CONSTANTS.LOGIN);
  };
  const validateLink = async () => {
    try {
      setIsLoading(true);
      const res = await authServices.validateResetLink({ key });
      if (res?.data?.success && res.data?.data !== null) {
        setUserId(res?.data?.data);
        setResetPassModal(true);
      } else {
        setIsExpireModal(true);
      }
    } catch (e) {
      setIsExpireModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (key) validateLink();
  }, [key]);

  useEffect(() => {
    if (isEnterPressed) handleSubmit();
  }, [isEnterPressed]);

  return (
    <div>
      <EzyDialog
        handleClose={() => {}}
        open={resetPassModal && userId && !isLoading}
        className="auth-dialog"
      >
        <div className="auth-wrapper">
          <img src={blackLogo} alt="logo" width={200} />
          <div className="info-text">
            <div className="primary-large-title">Set New Password</div>
            {/* <div className="subtitle-text"> */}
            {/*  Your new password must be different to previously used Password */}
            {/* </div> */}
          </div>
          <div className="auth-form">
            <div className="field-wrapper">
              <EzyInputField
                type={showPassword.pass ? 'text' : 'password'}
                label="Password"
                placeholder="Enter your password"
                tooltip="Password should contain atleast 8 characters, including atleast 1 capital and 1 special character."
                name="password"
                onChange={updateFormData}
                value={formData.password}
                error={errors?.password}
                required
                endAdornment={
                  <InputAdornment position="end">
                    <span
                      className="material-icons icon-small endAdornment-icon"
                      onClick={() => handlePasswordShow('pass')}
                      role="presentation"
                    >
                      {showPassword.pass ? 'visibility_off' : 'visibility'}
                    </span>
                  </InputAdornment>
                }
              />
              <EzyInputField
                type={showPassword.cPass ? 'text' : 'password'}
                label="Confirm password"
                placeholder="Confirm your password"
                name="confirmPassword"
                onChange={updateFormData}
                value={formData.confirmPassword}
                error={errors?.confirmPassword}
                required
                endAdornment={
                  <InputAdornment position="end">
                    <span
                      className="material-icons icon-small endAdornment-icon"
                      onClick={() => handlePasswordShow('cPass')}
                      role="presentation"
                    >
                      {showPassword.cPass ? 'visibility_off' : 'visibility'}
                    </span>
                  </InputAdornment>
                }
              />
            </div>
            <div className="action-button">
              <EzyButton label="Set password" onClick={handleSubmit} loading={loading} />
            </div>
            <div className="auth-redirect-text">
              {`Back to `}
              <Link to="/login" className="secondary-action-text">
                Login
              </Link>
            </div>
          </div>
        </div>
      </EzyDialog>
      <InfoModal
        img={linkNotFound}
        subTitle="Sorry!, Your link for reset password is no longer valid. Request new one."
        title="Invalid Link!"
        action={
          <div className="subtitle-text ">
            {`Link expired? `}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span
              onClick={() => history.push(ROUTE_CONSTANTS.LOGIN)}
              className="secondary-action-text"
            >
              Request new
            </span>
          </div>
        }
        open={isExpireModal && !isLoading && !userId}
        onClose={() => history.push(ROUTE_CONSTANTS.LOGIN)}
      />
      <InfoModal
        img={checkEmailImage}
        // subTitle="Password set successfully"
        title="Password set successfully"
        action={
          <div className="subtitle-text ">
            You can login with your password from&nbsp;
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span onClick={handelInfoModalClose} className="secondary-action-text">
              here.
            </span>
          </div>
        }
        open={isSetPasswordModal}
        onClose={handelInfoModalClose}
      />
    </div>
  );
}

export default ResetPassword;
