import { Accordion } from '@mui/material';
import PropTypes from 'prop-types';

function EzyAccordion({ children, onChange, ...restOther }) {
  return (
    <Accordion className="accordion-root" {...restOther} onChange={onChange}>
      {children}
    </Accordion>
  );
}

export default EzyAccordion;
EzyAccordion.propTypes = {
  children: PropTypes.element.isRequired,
  onChange: PropTypes.func,
};

EzyAccordion.defaultProps = {
  onChange: () => {},
};
