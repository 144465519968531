import apiService from '../../utils/apiService';
import { commonServices } from '../../utils/backOfficeLists/commonServices';
import API_URL from '../../utils/urlConstants';

export const scheduleServices = {
  getAreaListByLocationId: (params) =>
    apiService.getData(API_URL.SCHEDULE.getAreaListByLocationId, { params }),
  getShiftDropdownEntities: (params) =>
    apiService.getData(API_URL.SCHEDULE.getShiftDropdownEntities, { params }),
  getShiftByShiftId: (params) => apiService.getData(API_URL.SCHEDULE.getShiftByShiftId, { params }),
  getAllScheduleShift: (params) =>
    apiService.getData(API_URL.SCHEDULE.getAllScheduleShift, { params }),
  getAllTemplatesByLocationId: (params) =>
    apiService.getData(API_URL.SCHEDULE.getAllTemplatesByLocationId, { params }),
  getLocationSettingsForShift: (params) =>
    apiService.getData(API_URL.SCHEDULE.getLocationSettingsForShift, { params }),
  getShiftPanelDetails: (data) => apiService.postData(API_URL.SCHEDULE.getShiftPanelDetails, data),
  addShift: (data) => apiService.postData(API_URL.SCHEDULE.addShift, data),
  repeatShifts: (data) => apiService.postData(API_URL.SCHEDULE.repeatShifts, data),
  editShift: (data) => apiService.postData(API_URL.SCHEDULE.editShift, data),
  publishShift: (data) => apiService.postData(API_URL.SCHEDULE.publishShift, data),
  shiftBulkActions: (data) => apiService.postData(API_URL.SCHEDULE.shiftBulkActions, data),
  saveTemplate: (data) => apiService.postData(API_URL.SCHEDULE.saveTemplate, data),
  loadTemplate: (data) => apiService.postData(API_URL.SCHEDULE.loadTemplate, data),
  swapRequestAdmin: (data) => apiService.postData(API_URL.SCHEDULE.swapRequestAdmin, data),
  confirmRequestForOpenShift: (data) =>
    apiService.postData(API_URL.SCHEDULE.confirmRequestForOpenShift, data),
  dragAndDropShift: (data) => apiService.postData(API_URL.SCHEDULE.dragAndDropShift, data),
  deleteShift: (params) => apiService.deleteData(API_URL.SCHEDULE.deleteShift, { params }),
  deleteTemplate: (params) => apiService.deleteData(API_URL.SCHEDULE.deleteTemplate, { params }),
  getSwapShiftDetails: (params) =>
    apiService.getData(API_URL.SCHEDULE.getSwapShiftDetails, { params }),
  getOpenShiftDetails: (params) =>
    apiService.getData(API_URL.SCHEDULE.getOpenShiftDetails, { params }),
  cancelRequestedOpenShift: (params) =>
    apiService.deleteData(API_URL.SCHEDULE.cancelRequestedOpenShift, { params }),
  getPossibleSwapShift: (params) =>
    apiService.getData(API_URL.SCHEDULE.getPossibleSwapShift, { params }),
  sendSwapRequest: (data) => apiService.postData(API_URL.SCHEDULE.sendSwapRequest, data),
  addRequestForOpenShift: (data) =>
    apiService.postData(API_URL.SCHEDULE.addRequestForOpenShift, data),
  getAllSwapRequests: (params) =>
    apiService.getData(API_URL.SCHEDULE.getAllSwapRequests, { params }),
  swapRequestUserAction: (data) =>
    apiService.postData(API_URL.SCHEDULE.swapRequestUserAction, data),
  withdrawRequestForOpenShift: (params) =>
    apiService.deleteData(API_URL.SCHEDULE.withdrawRequestForOpenShift, { params }),
  getShiftHistoryByShiftId: (params) =>
    apiService.getData(API_URL.SCHEDULE.getShiftHistoryByShiftId, { params }),
  downloadShecduleCSV: (params) =>
    apiService.getData(API_URL.SCHEDULE.downloadShecduleCSV, { params }),
  downloadScheduleExcelByArea: (params) =>
    commonServices.downloadSample(API_URL.SCHEDULE.downloadScheduleExcelByArea, { params }),
  downloadScheduleExcelByTeamMember: (params) =>
    commonServices.downloadSample(API_URL.SCHEDULE.downloadScheduleExcelByTeamMember, { params }),
  getCoverageAreaData: (params) =>
    apiService.getData(API_URL.INSIGHT.getCoverageAreaData, { params }),
  updateInsightPanelDisplaySettings: (data) =>
    apiService.postData(API_URL.SCHEDULE.updateInsightPanelDisplaySettings, data),
  updateRequiredStaffData: (data) =>
    apiService.postData(API_URL.INSIGHT.updateRequiredStaffData, data),
  getHolidaySettings: (params) => apiService.getData(API_URL.SCHEDULE.holidaySettings, { params }),
  updateHolidaySettings: (data) => apiService.postData(API_URL.SCHEDULE.holidaySettings, data),
  getGraphData: (params, config) =>
    apiService.getData(API_URL.INSIGHT.graphData, { params, ...config, timeout: 2 * 60 * 1000 }),
  getStatsPanelData: (params) => apiService.getData(API_URL.INSIGHT.getStatsPanelData, { params }),
  updateGraphSettings: (data) => apiService.postData(API_URL.INSIGHT.graphSetting, data),
};
