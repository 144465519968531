import { Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import IconByName from '../../../../components/common/iconByName/IconByName';
import EzyButton from '../../../../components/common/button/EzyButton';
import {
  accountOverviewSwitchBusinessAction,
  getBusinessListForAccountOverview,
  setBusinessInvitation,
  switchBusinessAction,
} from '../../redux/globalProfileActions';
import EzyIconbutton from '../../../../components/common/iconButton/EzyIconButton';
import EzyTooltip from '../../../../components/common/tooltip/EzyTooltip';
import {
  updateGeneralSettingsFields,
  updateGlobalProfileData,
} from '../../redux/globalProfileSlice';
import { getCurrentUserDataAction } from '../../../auth/redux/userAction';
import ROUTE_CONSTANTS from '../../../../utils/constants';
import Loader from '../../../../components/common/loader/Loader';

function AccountOverviewCardView() {
  const cardData = useSelector(({ globalProfile }) => globalProfile || {});
  const { profileData } = useSelector(({ user }) => user || {});
  const { businessName, businessId } = useMemo(() => profileData, [profileData]);
  const { accountOverviewCard } = useMemo(() => cardData ?? {}, [cardData]);
  const { enums, generalLoader } = useSelector(({ common }) => common || {});
  const { roles } = useMemo(() => enums, [enums]);
  const {
    BusinessInvitationLoader,
    switchBusinessActionLoader,
    accountOverviewSwitchBusinessActionLoader,
    getBusinessListForAccountOverviewLoader,
    getCurrentUserDataLoader,
  } = useMemo(() => generalLoader, [generalLoader]);
  const dispatch = useDispatch();
  const history = useHistory();
  const handelHistoryPush = (path) => {
    history.push(path);
  };
  const redirectToNewBusiness = () => {
    handelHistoryPush(ROUTE_CONSTANTS.REGISTER_BUSINESS);
  };

  const handelSwitchBusiness = (data) => {
    const body = { businessId: data };
    dispatch(updateGlobalProfileData({ fieldName: 'isEditProfile', value: false }));
    dispatch(
      accountOverviewSwitchBusinessAction(body, () => {
        dispatch(
          getCurrentUserDataAction(
            () => {
              handelHistoryPush(ROUTE_CONSTANTS.HOME);
            },
            { isFromAccountOverview: true }
          )
        );
      })
    );
  };

  const handelBusinessInvitation = (invitationAction, newBusinessId) => {
    const data = { invitationAction, businessId: newBusinessId };
    dispatch(setBusinessInvitation(data, () => dispatch(getBusinessListForAccountOverview())));
  };

  const handleSettingClick = async (newBusinessId, nameOfBusiness) => {
    dispatch(updateGeneralSettingsFields({ fieldName: 'businessId', value: newBusinessId }));
    dispatch(updateGeneralSettingsFields({ fieldName: 'businessName', value: nameOfBusiness }));
    if (newBusinessId !== businessId) {
      const isSuccess = await dispatch(switchBusinessAction({ businessId: newBusinessId }));
      if (isSuccess) {
        dispatch(
          getCurrentUserDataAction(
            () => {
              history.push(`${ROUTE_CONSTANTS.ACCOUNT_OVERVIEW}/general/${newBusinessId}`);
            },
            { isFromSwitchBusiness: true }
          )
        );
      }
    } else history.push(`${ROUTE_CONSTANTS.ACCOUNT_OVERVIEW}/general/${newBusinessId}`);
  };

  if (
    BusinessInvitationLoader ||
    switchBusinessActionLoader ||
    accountOverviewSwitchBusinessActionLoader ||
    getBusinessListForAccountOverviewLoader ||
    getCurrentUserDataLoader
  ) {
    return <Loader />;
  }
  return (
    <div className="account-overview-card-wrapper">
      {accountOverviewCard.length > 0 ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {accountOverviewCard?.map((res) => (
            <div key={res?.businessId} className={`account-overview-card ${res.status}`}>
              <div className="card-details">
                <div>
                  <div className="card-title-wrapper">
                    <div className="card-title">
                      {!res?.isInvitationActionRequired && businessName === res?.businessName && (
                        <div>
                          <div className="circle" />
                        </div>
                      )}
                      <EzyTooltip
                        placement="top-start"
                        className={
                          businessName === res?.businessName
                            ? 'current-selected-business'
                            : 'business-name'
                        }
                        title={res?.businessName}
                      >
                        {res?.businessName}
                      </EzyTooltip>
                    </div>
                    {!res?.isInvitationActionRequired && (
                      <div className="card-title-button">
                        <EzyTooltip title="Go to business">
                          <EzyIconbutton
                            className="switch-business-button"
                            iconName="login"
                            onClick={() => handelSwitchBusiness(res?.businessId)}
                          />
                        </EzyTooltip>
                        {res?.roleId?.label === roles?.[0]?.label && (
                          <EzyTooltip title="Business settings">
                            <EzyIconbutton
                              className="switch-business-button"
                              iconName="settings"
                              onClick={() => handleSettingClick(res?.businessId, res?.businessName)}
                            />
                          </EzyTooltip>
                        )}
                      </div>
                    )}
                  </div>
                  <Divider />
                </div>
                {res?.isInvitationActionRequired ? (
                  <div className="request-card-info">
                    {` You have been invited to join ${res?.businessName}.`}
                  </div>
                ) : (
                  <div className="card-info">
                    <div>
                      <IconByName name="vpn_key" />
                      <span>{res?.role}</span>
                    </div>
                    <div>
                      <IconByName name="location_on" />
                      <span>{res?.numberOfLocations} Locations</span>
                    </div>
                    <div>
                      <IconByName name="groups" />
                      <span>{res?.numberOfPeople} People</span>
                    </div>
                  </div>
                )}

                {res?.isInvitationActionRequired ? (
                  <div className="card-dual-button-wrapper">
                    <EzyButton
                      label="Decline"
                      className="Decline"
                      color="error"
                      onClick={() => handelBusinessInvitation(false, res?.businessId)}
                    />
                    <EzyButton
                      label="Accept"
                      onClick={() => handelBusinessInvitation(true, res?.businessId)}
                    />
                  </div>
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {res?.expireDaysLeft === 0 ? (
                      <div className="card-button-wrapper">
                        <EzyButton
                          label="Upgrade Now"
                          color="error"
                          className={res?.status}
                          onClick={() => {
                            if (res?.roleId?.label === roles?.[0]?.label)
                              history.push(
                                `${ROUTE_CONSTANTS.ACCOUNT_OVERVIEW}/plans/${res?.businessId}`
                              );
                          }}
                        />
                      </div>
                    ) : (
                      <div className="card-button-wrapper">
                        <EzyButton
                          label={
                            res?.planName === 'Trial'
                              ? `${res?.expireDaysLeft} ${
                                  res?.status === 'warning' ? 'Days left' : 'Days'
                                }`
                              : `${res?.planName}(${res?.planDuration})`
                          }
                          className={res?.status}
                          color={res?.status === 'warning' ? 'secondary' : 'primary'}
                          onClick={() => {
                            if (res?.roleId?.label === roles?.[0]?.label)
                              history.push(
                                `${ROUTE_CONSTANTS.ACCOUNT_OVERVIEW}/billing/${res?.businessId}`
                              );
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="account-overview-card">
          <div className="card-details">
            <div className="create-new-business">
              <div>
                {`You aren't currently a member of any business in Payroll Ezy. or wait to be invited by
              your manager.`}
              </div>
              <EzyButton
                className="create-new-business-button"
                label="create new business"
                startIcon={<IconByName name="add" />}
                onClick={redirectToNewBusiness}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(AccountOverviewCardView);
