import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import EzyTooltip from '../tooltip/EzyTooltip';

function EzyAvatar({ imageLink, firstName, lastName, ...restProps }) {
  function getDarkColor() {
    let color = '#';
    for (let i = 0; i < 6; i += 1) {
      color += Math.floor(Math.random() * 10);
    }
    return color;
  }

  const stringAvatar = (first, last) => {
    if (first && last) {
      return {
        sx: {
          bgcolor: getDarkColor(),
        },
        children: `${first[0]?.toUpperCase()}${last[0]?.toUpperCase()}`,
      };
    }
    if (first?.split(' ')[0] && first?.split(' ')[1]) {
      return {
        sx: {
          bgcolor: getDarkColor(),
        },
        children: `${first.split(' ')[0][0]?.toUpperCase()}${first
          .split(' ')[1][0]
          ?.toUpperCase()}`,
      };
    }
    if (first === '' && last === '') {
      return {
        children: null,
      };
    }
    return {
      sx: {
        bgcolor: getDarkColor(),
      },
      children: `${first?.[0]?.toUpperCase()}`,
    };
  };

  return (
    <EzyTooltip title={`${firstName}${lastName}`}>
      {imageLink ? (
        <Avatar src={imageLink} {...restProps} />
      ) : (
        <Avatar {...stringAvatar(firstName, lastName)} {...restProps} />
      )}
    </EzyTooltip>
  );
}

export default React.memo(EzyAvatar);

EzyAvatar.propTypes = {
  imageLink: PropTypes?.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

EzyAvatar.defaultProps = {
  imageLink: '',
  firstName: '',
  lastName: '',
};
