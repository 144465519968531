import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EzyButton from '../../../../components/common/button/EzyButton';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import IconByName from '../../../../components/common/iconByName/IconByName';
import EzyToggleButtonGroup from '../../../../components/common/toggleButton/EzyToggleButtonGroup';
import useQueryParams from '../../../../hooks/useQueryParams';
import { displayDate, displayTime } from '../../../../utils/timeHelper';
import {
  getAllSwapRequestsAction,
  getDashboardShiftsAction,
  sendSwapRequestAction,
  swapRequestUserAction,
} from '../../redux/dashboardActions';
import { updateSwapShiftRequestsDetails } from '../../redux/dashboardSlice';
import CurrentShiftDetailsAccordion from './CurrentShiftDetailsAccordian';

function SwapShiftRequestsDrawer() {
  const dispatch = useDispatch();
  const { personId, businessId, timeZone } = useSelector(({ user }) => user?.profileData ?? {});
  const { swapShiftRequestsDetails } = useSelector(
    ({ dashboard }) => dashboard?.calenderData ?? {}
  );

  const { date: dateParam } = useQueryParams();

  const {
    getAllSwapRequestsActionLoader,
    sendSwapRequestActionLoader,
    swapRequestUserActionLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});

  const swapOfferedShifts = ['Swap Shift Received', 'Swap Shift Sent'];

  const { currentShift, note, swapRequests, swapRequestsDisplay, checkedSwapRequests } = useMemo(
    () => swapShiftRequestsDetails,
    [swapShiftRequestsDetails]
  );

  const handleClose = () => {
    dispatch(updateSwapShiftRequestsDetails({ fieldName: 'currentShift', value: {} }));
  };

  const handleSubmit = () => {
    const data = {
      shiftRequestId: currentShift?.shiftId,
      receiverShiftIds: checkedSwapRequests,
      note,
    };

    if (checkedSwapRequests?.length > 0) {
      dispatch(
        sendSwapRequestAction(data, () => {
          handleClose();
          dispatch(
            getDashboardShiftsAction({
              personDetailId: personId,
              businessId,
              filterStartDate: moment
                .tz(dateParam, timeZone?.label ?? moment.tz?.guess())
                .startOf('week')
                ?.toISOString(),
              filterEndDate: moment
                .tz(dateParam, timeZone?.label ?? moment.tz?.guess())
                .endOf('week')
                ?.toISOString(),
            })
          );
        })
      );
    }
  };
  const handleSwapRequestAction = async (params) => {
    const data = {
      isAccepted: false,
      isApproved: false,
      isCancelled: false,
      ...params,
    };

    await dispatch(
      swapRequestUserAction(data, () => {
        handleClose();
        dispatch(
          getDashboardShiftsAction({
            personDetailId: personId,
            businessId,
            filterStartDate: moment
              .tz(dateParam, timeZone?.label ?? moment.tz?.guess())
              .startOf('week')
              ?.toISOString(),
            filterEndDate: moment
              .tz(dateParam, timeZone?.label ?? moment.tz?.guess())
              .endOf('week')
              ?.toISOString(),
          })
        );
      })
    );
  };

  const deleteSwap = async () => {
    handleSwapRequestAction({ isCancelled: true, shiftRequestId: currentShift?.shiftId });
  };

  const actionButton = [
    {
      label: 'Delete swap',
      color: 'error',
      onClick: deleteSwap,
      loading: swapRequestUserActionLoader,
    },
    {
      label: 'Request for swap shift',
      onClick: handleSubmit,
      disabled: checkedSwapRequests?.length === 0,
      loading: sendSwapRequestActionLoader,
    },
  ];

  const handleToggle = (value) => () => {
    const currentIndex = checkedSwapRequests.indexOf(value);
    const newChecked = [...checkedSwapRequests];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    dispatch(
      updateSwapShiftRequestsDetails({ fieldName: 'checkedSwapRequests', value: newChecked })
    );
  };

  useEffect(() => {
    if (currentShift?.shiftId) {
      dispatch(getAllSwapRequestsAction({ shiftId: currentShift?.shiftId }));
    }
  }, [currentShift]);

  return (
    <EzyDrawer
      isOpen={currentShift?.shiftId}
      onClose={handleClose}
      heading="Swap Shift"
      actionButtons={swapRequestsDisplay?.listType === 'Swap Shift Sent' ? actionButton : []}
      isLoading={getAllSwapRequestsActionLoader}
    >
      <div className="shift-drawer swap-requests-drawer ">
        <CurrentShiftDetailsAccordion
          shift={currentShift}
          // note={note}
          // updateNoteAction={updateSwapShiftRequestsDetails}
        />

        <div className="save-template-fields-grid">
          {swapRequestsDisplay?.isToggle && (
            <EzyToggleButtonGroup
              list={swapOfferedShifts}
              handleChange={(e) =>
                dispatch(
                  updateSwapShiftRequestsDetails({
                    fieldName: 'swapRequestsDisplay',
                    value: { isToggle: true, listType: e.target.value },
                  })
                )
              }
              value={swapRequestsDisplay?.listType}
            />
          )}

          {!swapRequestsDisplay?.isToggle && <div>{swapRequestsDisplay?.listType}</div>}

          {swapRequestsDisplay?.listType === 'Swap Shift Received'
            ? swapRequests?.swapOffersReceived?.length > 0 && (
                <List className="load-template-lists">
                  {swapRequests?.swapOffersReceived?.map(
                    ({
                      fromShiftId,
                      fromShiftStartTime,
                      fromShiftEndTime,
                      fromBreakDetails,
                      fromAreaName,
                      fromLocationName,
                      fromPersonPreferredName,
                    }) => {
                      const totalBreakDuration = fromBreakDetails?.length
                        ? fromBreakDetails
                            ?.map((prev) => prev.duration)
                            .reduce((prev, next) => prev + next)
                        : 0;

                      return (
                        <ListItem key={fromShiftId} disablePadding>
                          <ListItemButton dense disableRipple>
                            <div className="d-flex-column gap-10 w-full">
                              <ListItemText>
                                <div className="d-flex flex-align-center gap-10 subtitle-text">
                                  <IconByName name="person" />
                                  {fromPersonPreferredName}
                                </div>
                              </ListItemText>

                              <div className="d-flex-column gap-5 template-sub-details">
                                <div className="d-flex flex-align-center text-secondary-color gap-10">
                                  <IconByName name="event" />
                                  <span>{displayDate(fromShiftStartTime)}</span>
                                </div>

                                <div className="d-flex flex-align-center text-secondary-color gap-10">
                                  <IconByName name="location_on" />
                                  <span>
                                    {fromAreaName} - {fromLocationName}
                                  </span>
                                </div>

                                <div className="d-flex flex-align-center just-bet gap-10">
                                  <div className="d-flex flex-align-center gap-10 text-secondary-color">
                                    <IconByName name="access_time_filled" />

                                    <span>
                                      {displayTime(fromShiftStartTime)} -{' '}
                                      {displayTime(fromShiftEndTime)}
                                    </span>
                                  </div>

                                  <div className="d-flex flex-align-center gap-10 text-secondary-color">
                                    <IconByName name="emoji_food_beverage" />
                                    <span>{totalBreakDuration} mins</span>
                                  </div>
                                </div>
                                <div className="d-flex flex-align-center just-bet gap-10 p-top-10 p-bottom-10">
                                  <EzyButton
                                    className="swap-requests-button"
                                    label="Decline"
                                    color="error"
                                    onClick={() =>
                                      handleSwapRequestAction({
                                        shiftRequestId: fromShiftId,
                                        shiftReceiverId: currentShift?.shiftId,
                                      })
                                    }
                                  />
                                  <EzyButton
                                    className="swap-requests-button"
                                    label="Accept"
                                    onClick={() =>
                                      handleSwapRequestAction({
                                        shiftRequestId: fromShiftId,
                                        shiftReceiverId: currentShift?.shiftId,
                                        isAccepted: true,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </ListItemButton>
                        </ListItem>
                      );
                    }
                  )}
                </List>
              )
            : swapRequests?.swapOffersSent?.length > 0 &&
              swapRequests?.swapOffersSent?.filter((request) => request?.isSwapAlreadySent)
                ?.length > 0 && (
                <List className="load-template-lists">
                  {swapRequests?.swapOffersSent?.map(
                    ({
                      toShiftId,
                      toShiftStartTime,
                      toShiftEndTime,
                      toBreakDetails,
                      toAreaName,
                      toLocationName,
                      toPersonPreferredName,
                      isSwapAlreadySent,
                    }) => {
                      const labelId = `checkbox-list-label-${toShiftId}`;

                      const totalBreakDuration = toBreakDetails?.length
                        ? toBreakDetails
                            ?.map((prev) => prev.duration)
                            .reduce((prev, next) => prev + next)
                        : 0;

                      return (
                        <ListItem
                          key={toShiftId}
                          disablePadding
                          selected={checkedSwapRequests?.indexOf(toShiftId) !== -1}
                        >
                          <ListItemButton dense onClick={handleToggle(toShiftId)} disableRipple>
                            <div className="d-flex-column gap-10 w-full">
                              <div className="d-flex flex-align-center just-bet gap-10">
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    checked={checkedSwapRequests?.indexOf(toShiftId) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    icon={<IconByName name="check_circle_outline" />}
                                    checkedIcon={
                                      <IconByName name="check_circle" className="primary-color" />
                                    }
                                  />
                                </ListItemIcon>
                                <ListItemText className="people-invite-list-view">
                                  <span className="subtitle-text">{toPersonPreferredName}</span>
                                  {isSwapAlreadySent && (
                                    <span className="primary-color">Already Requested</span>
                                  )}
                                </ListItemText>
                              </div>

                              <div className="d-flex-column gap-5 template-sub-details">
                                <div className="d-flex flex-align-center text-secondary-color gap-10">
                                  <IconByName name="event" />
                                  <span>{displayDate(toShiftStartTime)}</span>
                                </div>

                                <div className="d-flex flex-align-center text-secondary-color gap-10">
                                  <IconByName name="location_on" />
                                  <span>
                                    {toAreaName} - {toLocationName}
                                  </span>
                                </div>

                                <div className="d-flex flex-align-center just-bet gap-10">
                                  <div className="d-flex flex-align-center gap-10 text-secondary-color">
                                    <IconByName name="access_time_filled" />

                                    <span>
                                      {displayTime(toShiftStartTime)} -{' '}
                                      {displayTime(toShiftEndTime)}
                                    </span>
                                  </div>

                                  <div className="d-flex flex-align-center gap-10 text-secondary-color">
                                    <IconByName name="emoji_food_beverage" />
                                    <span>{totalBreakDuration} mins</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ListItemButton>
                        </ListItem>
                      );
                    }
                  )}
                </List>
              )}
        </div>
      </div>
    </EzyDrawer>
  );
}

export default SwapShiftRequestsDrawer;
