import apiService from '../../utils/apiService';
import API_URL from '../../utils/urlConstants';

const businessServices = {
  registerBusiness: (data) => apiService.postData(API_URL.BUSINESS.registerBusiness, data),
  fetchBusinessLeaveRules: (params) =>
    apiService.getData(API_URL.BUSINESS.fetchBusinessLeaveRules, { params }),
  saveBusinessGeneralDetails: (data) =>
    apiService.postData(API_URL.BUSINESS.saveBusinessGeneralDetails, data),
  saveEditedLeaveSetting: (data) =>
    apiService.postData(API_URL.BUSINESS.saveEditedLeaveSetting, data),
  getBusinessGeneralDetails: (params) =>
    apiService.getData(API_URL.BUSINESS.getBusinessGeneralDetails, { params }),
  getBusinessLeaveSetting: (params) =>
    apiService.getData(API_URL.BUSINESS.getBusinessLeaveSetting, { params }),
  inviteToBusiness: (data) => apiService.postData(API_URL.BUSINESS.inviteToBusiness, data),
  addEditLeaveRules: (data) => apiService.postData(API_URL.BUSINESS.addEditLeaveRules, data),
  getBusinessLeaveRuleDetails: (params) =>
    apiService.getData(API_URL.BUSINESS.getLeaveRuleDetails, { params }),
  personWhoCanApproveLeaveEnum: (params) =>
    apiService.getData(API_URL.BUSINESS.personWhoCanApproveLeaveEnum, { params }),
  deleteBusinessLeaveRuleByLeaveRuleId: (params) =>
    apiService.deleteData(API_URL.BUSINESS.deleteBusinessLeaveRule, { params }),
  deleteBusinessByBusinessId: (params) =>
    apiService.deleteData(API_URL.BUSINESS.deleteBusiness, { params }),
};

export default businessServices;
