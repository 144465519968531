import PropTypes from 'prop-types';
import { useRef } from 'react';
import IconByName from '../iconByName/IconByName';
import { downloadAll } from '../../../utils/downloadHelper';
import { commonServices } from '../../../utils/backOfficeLists/commonServices';

function FileUpload({ label, handleChange, fileName, downloadPath, subtitle, multiple }) {
  const hiddenFileInput = useRef(null);
  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const downloadSampleFile = async () => {
    const res = await commonServices.downloadSample(downloadPath);
    if (res) downloadAll(res);
  };

  return (
    <>
      {downloadPath && (
        <div className="primary-bg d-flex flex-align-center just-bet">
          <span>Download sample file</span>
          <IconByName
            name="download_for_offline"
            className="pointer-cursor"
            onClick={downloadSampleFile}
          />
        </div>
      )}
      <div className="uploader" onClick={handleClick}>
        <IconByName name="cloud_upload" className="primary-large-title" />
        <span className="title-text">{fileName || label}</span>
        {subtitle && <span className="text-secondary-color">{subtitle}</span>}
      </div>
      <input
        type="file"
        className="input-file-type"
        ref={hiddenFileInput}
        onChange={handleChange}
        multiple={multiple}
      />
    </>
  );
}

export default FileUpload;

FileUpload.propTypes = {
  label: PropTypes.string,
  handleChange: PropTypes.func,
  fileName: PropTypes.string,
  downloadPath: PropTypes.string,
  subtitle: PropTypes.string,
  multiple: PropTypes.bool,
};

FileUpload.defaultProps = {
  label: '',
  handleChange: () => {},
  fileName: '',
  downloadPath: '',
  subtitle: '',
  multiple: false,
};
