import { isNil, omitBy } from 'lodash';
import { requireValidate } from '../../../utils/validationHelper';

const validateDetailsEditLocation = (data) => {
  const {
    basics,
    breaks,
    UnscheduledShifts,
    creatingAndPublishingShifts,
    swapsAndOffers,
    clockInAndOut,
    teamMemberPermissions,
    timesheetApproving,
  } = data ?? {};
  const error = {};
  // ------------- Validation of basics
  error.weekStartsOn = requireValidate('Week Starts on', basics.weekStartsOn);
  error.defaultShiftDuration = requireValidate(
    'Default shift duration',
    basics?.defaultShiftDuration
  );
  error.defaultBreakDuration = requireValidate(
    'Default break duration',
    basics?.defaultBreakDuration
  );
  // ------------- Validation of creatingAndPublishingShifts
  error.preventShiftChange = requireValidate(
    'Prevent changes to shifts',
    creatingAndPublishingShifts.preventShiftChange
  );
  error.shiftNotificationRecipient = requireValidate(
    'Shift notifications recipient',
    creatingAndPublishingShifts.shiftNotificationRecipient
  );
  error.shiftRemovedNotification = requireValidate(
    'Send notification to team member when shifts are removed',
    creatingAndPublishingShifts.shiftRemovedNotification
  );
  // ------------- Validation of swapsAndOffers
  error.coWorkerScheduleVisibility = requireValidate(
    'Co-worker schedule visibility',
    swapsAndOffers.coWorkerScheduleVisibility
  );
  //--------------------------------------------------------------------
  // ------------- Validation of clockInAndOut
  error.minimumTimeSheetLengthInMinutes = requireValidate(
    'Minimum timesheet length',
    clockInAndOut.minimumTimeSheetLengthInMinutes
  );
  //------------------------------------------------------------------
  // ------------- Validation of teamMemberPermissions
  error.earlyStartShiftBufferTime = teamMemberPermissions?.allowToStartShiftBeforeScheduledTime
    ? requireValidate(
        'How early team members can start shifts',
        teamMemberPermissions.earlyStartShiftBufferTime
      )
    : null;
  error.earlyEndShiftBufferTime = requireValidate(
    'Ending shifts earlier than scheduled',
    teamMemberPermissions.earlyEndShiftBufferTime
  );
  //------------------------------------------------------------------
  // ------------- Validation of timesheetApproving
  error.autoApproveTimeSheetVariance = requireValidate(
    'Enter shifts earlier than scheduled',
    timesheetApproving.autoApproveTimeSheetVariance
  );
  error.startTimeRoundingType = requireValidate(
    'Start time rounding',
    timesheetApproving.startTimeRoundingType
  );
  error.endTimeRoundingType = requireValidate(
    'End time rounding',
    timesheetApproving.endTimeRoundingType
  );
  error.breakTimeRoundingType = requireValidate(
    'Break time rounding',
    timesheetApproving.breakTimeRoundingType
  );
  //------------------------------------------------------------------
  // ------------- Validation of UnscheduledShifts
  error.linkUnscheduledTimeSheetType = requireValidate(
    'Link unscheduled timesheet with a scheduled shift',
    UnscheduledShifts.linkUnscheduledTimeSheetType
  );
  error.unscheduledShiftTimeVariance =
    UnscheduledShifts?.linkUnscheduledTimeSheetType?.id === 4
      ? requireValidate('Time variance', UnscheduledShifts.unscheduledShiftTimeVariance)
      : null;
  //------------------------------------------------------------------
  // ------------- Validation of breaks
  error.isBreakPaid = requireValidate('Paid meal breaks', breaks.isBreakPaid);

  //------------------------------------------------------------------

  return omitBy(error, isNil);
};

export default validateDetailsEditLocation;
