import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import Loader from '../../../../components/common/loader/Loader';
import { updateGeneralSettingsFields } from '../../redux/globalProfileSlice';

function AOGeneralSettings() {
  const dispatch = useDispatch();
  const { generalSettings } = useSelector(({ globalProfile }) => globalProfile || {});
  const { businessName, errors } = useMemo(() => generalSettings ?? {}, [generalSettings]);
  const { getGeneralLocationSettingsActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const handleFieldChange = (fieldName, value) => {
    dispatch(updateGeneralSettingsFields({ fieldName, value }));
  };

  return (
    <div className="ao-business-root-container">
      {getGeneralLocationSettingsActionLoader && <Loader />}
      <div className="p-x-24 two-grid">
        <EzyInputField
          label="Business name"
          value={businessName}
          onChange={(e) => handleFieldChange('businessName', e?.target?.value)}
          error={errors?.businessName}
        />
      </div>
    </div>
  );
}

export default AOGeneralSettings;
