import React, { useEffect, useState } from 'react';
// import { InputAdornment } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InputAdornment } from '@mui/material';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import ROUTE_CONSTANTS from '../../../../utils/constants';
import {
  getAllIntegrationsListAction,
  getIntegrationDetailsAction,
  getLocationSpecificConfigurationAction,
} from '../../redux/globalProfileActions';
import Loader from '../../../../components/common/loader/Loader';
import EzyButton from '../../../../components/common/button/EzyButton';
import { updateAppIntegrationData } from '../../redux/globalProfileSlice';
import IconByName from '../../../../components/common/iconByName/IconByName';
// import IconByName from '../../../../components/common/iconByName/IconByName';

function Integrations() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState(null);
  const { businessId } = useSelector(({ user }) => user?.profileData || {});
  const { getAllIntegrationsListActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const { isAddIntegrationsScreen, integrationList } = useSelector(
    ({ globalProfile }) => globalProfile?.appIntegration || {}
  );

  const fetchIntegration = (params) => {
    dispatch(getAllIntegrationsListAction({ ...params, searchText }));
  };

  const handleSearch = async (e) => {
    if (e.target.value) setSearchText(e.target.value);
    else {
      setSearchText(null);
      await fetchIntegration({ searchText: null });
    }
  };

  useEffect(() => {
    if (businessId) fetchIntegration({ businessId: isAddIntegrationsScreen ? null : businessId });
  }, [businessId]);

  return (
    <div className="integration-root-container">
      <div className="d-flex just-bet flex-align-center ">
        <EzyInputField
          placeholder="Search "
          className="search-box"
          value={searchText}
          onChange={handleSearch}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              await fetchIntegration({ businessId: isAddIntegrationsScreen ? null : businessId });
            }
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconByName name="search" />
            </InputAdornment>
          }
        />

        {isAddIntegrationsScreen && (
          <EzyButton
            label="Back"
            color="secondary"
            onClick={() => {
              (async () => {
                dispatch(
                  updateAppIntegrationData({ fieldName: 'isAddIntegrationsScreen', value: false })
                );
                dispatch(updateAppIntegrationData({ fieldName: 'integrationList', value: [] }));

                await fetchIntegration({ businessId });
              })();
            }}
          />
        )}
      </div>

      {getAllIntegrationsListActionLoader ? (
        <Loader />
      ) : (
        <div className="integration-grid-container">
          {integrationList?.length ? (
            integrationList?.map((integration) => {
              return (
                <div
                  className="integration-info"
                  key={integration?.integrationId}
                  onClick={() => {
                    if (integration?.integrationId && businessId)
                      dispatch(
                        getIntegrationDetailsAction(
                          { integrationId: integration?.integrationId, businessId },
                          (location) => {
                            if (location?.locationId) {
                              dispatch(
                                getLocationSpecificConfigurationAction({
                                  integrationId: integration?.integrationId,
                                  locationId: location?.locationId,
                                })
                              );
                              dispatch(
                                updateAppIntegrationData({
                                  fieldName: 'selectedLocation',
                                  value: location?.locationId,
                                })
                              );
                              history.push(
                                `${ROUTE_CONSTANTS.APP_INTEGRATION}/configuration/${integration?.integrationId}`
                              );
                            } else {
                              history.push(
                                `${ROUTE_CONSTANTS.APP_INTEGRATION}/appInfo/${integration?.integrationId}`
                              );
                            }
                          }
                        )
                      );
                  }}
                >
                  <img src={integration?.logoPath} alt={integration?.integrationName} />
                  <div>
                    <div className="icon-small">{integration?.integrationName}</div>
                    <div className="text-secondary-color">{integration?.tagLine}</div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="no-data-found">You have not connected any integrations yet</div>
          )}
        </div>
      )}
    </div>
  );
}

export default Integrations;
