import React from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import IconByName from '../../../../components/common/iconByName/IconByName';
import EzyNavBar from '../../../../components/common/navBar/EzyNavBar';
import ROUTE_CONSTANTS from '../../../../utils/constants';
import EzyButton from '../../../../components/common/button/EzyButton';
import Integrations from './Integrations';
import { updateAppIntegrationData } from '../../redux/globalProfileSlice';
import { getAllIntegrationsListAction } from '../../redux/globalProfileActions';

function AppIntegration() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isAddIntegrationsScreen } = useSelector(
    ({ globalProfile }) => globalProfile?.appIntegration || {}
  );
  return (
    <div className="h-full w-full d-flex-column profile-setting-fullScreen-wrapper">
      <EzyNavBar isFormProfile>
        <div className="account-overview-navbar">
          {!isAddIntegrationsScreen && (
            <EzyButton
              label="Add a new integration"
              color="secondary"
              startIcon={<IconByName name="add" />}
              onClick={() => {
                (async () => {
                  dispatch(
                    updateAppIntegrationData({ fieldName: 'isAddIntegrationsScreen', value: true })
                  );
                  dispatch(updateAppIntegrationData({ fieldName: 'integrationList', value: [] }));
                  dispatch(getAllIntegrationsListAction({ businessId: null }));
                })();
              }}
            />
          )}
          <IconButton
            className="navBar-icon-buttons"
            onClick={() => history.push(ROUTE_CONSTANTS.HOME)}
          >
            <IconByName name="close" className="pointer-cursor white-color" />
          </IconButton>
        </div>
      </EzyNavBar>
      <div className="panel-child-wrapper">
        <Integrations />
      </div>
    </div>
  );
}

export default AppIntegration;
