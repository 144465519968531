import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import EzyAccordionDetails from '../../../../components/common/accordion/EzyAccordinDetails';
import EzyAccordion from '../../../../components/common/accordion/EzyAccordion';
import EzyAccordionSummary from '../../../../components/common/accordion/EzyAccordionSummary';
import EzyAccordionWrapper from '../../../../components/common/accordion/EzyAccordionWrapper';
import IconByName from '../../../../components/common/iconByName/IconByName';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import { displayTime } from '../../../../utils/timeHelper';

function CurrentShiftDetailsAccordion({ shift, updateNoteAction, note }) {
  const dispatch = useDispatch();
  const totalBreakDuration = shift?.shiftBreakDetailsList?.length
    ? shift?.shiftBreakDetailsList?.map((prev) => prev.duration).reduce((prev, next) => prev + next)
    : 0;

  return (
    <EzyAccordionWrapper className="shift-details-accordion">
      <EzyAccordion>
        <EzyAccordionSummary className="accordion-summary">Shift details</EzyAccordionSummary>
        <EzyAccordionDetails>
          <div className="shift-details-drawer-field">
            <div className=" d-flex just-bet">
              <span className="flex-center gap-5">
                <IconByName name="access_time_filled" />
                <span>
                  {displayTime(shift?.startTime, shift?.timeZoneId?.label)} -{' '}
                  {displayTime(shift?.endTime, shift?.timeZoneId?.label)}
                </span>
              </span>
              <span className="flex-center gap-5">
                <IconByName name="emoji_food_beverage" />
                <span> {totalBreakDuration} mins</span>
              </span>
            </div>

            <div className="d-flex">
              <span className="flex-center gap-5">
                <IconByName name="location_on" />
                <span>
                  {shift?.areaName} - {shift?.locationName}
                </span>
              </span>
            </div>
          </div>

          {updateNoteAction && (
            <EzyInputField
              label="Note"
              placeholder="Enter notes"
              onChange={(e) =>
                dispatch(updateNoteAction({ fieldName: 'note', value: e.target.value }))
              }
              value={note}
              multiline
              minRows={3}
              maxRows={5}
            />
          )}
        </EzyAccordionDetails>
      </EzyAccordion>
    </EzyAccordionWrapper>
  );
}

export default CurrentShiftDetailsAccordion;

CurrentShiftDetailsAccordion.propTypes = {
  shift: PropTypes.object.isRequired,
  updateNoteAction: PropTypes.func.isRequired,
  note: PropTypes.string.isRequired,
};
