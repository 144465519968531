import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import HandsonTable from '../../../../components/common/table/HandsonTable';
import {
  getMetricStatisticsAction,
  getPayrollForecastStatisticsAction,
  updateMetricDataAction,
} from '../../redux/insightAction';
import ROUTE_CONSTANTS, { DATE_FORMAT } from '../../../../utils/constants';
import { displayDate, displayTime } from '../../../../utils/timeHelper';

const EditMetricTable = forwardRef(({ metricFor }, ref) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useParams();
  const hotRef = useRef();

  const { filters, metric } = useSelector(({ insight }) => insight ?? {});
  const { selectedLocation, selectedModule } = useMemo(() => filters, [filters]);
  const { weekDatesValue, duration, areaId } = useMemo(() => metric?.filters, [metric?.filters]);

  const rows = cloneDeep(metric.metricStatisticsList.newMetricTimeListEntities);

  const columns = new Array(
    duration?.label === 'Month'
      ? moment.tz(weekDatesValue?.startDate, 'UTC').daysInMonth()
      : duration?.dayCount
  ).fill(null);

  const handleSubmit = () => {
    const hot = hotRef?.current?.hotInstance;
    const editedData = hot?.getSourceData();
    editedData?.shift();

    const data = {
      metricId: selectedModule?.id,
      locationId: selectedLocation?.id,
      areaId: selectedModule?.showArea ? areaId?.id : null,
      isActual: metricFor === 'actuals',
      metricData: editedData,
    };

    dispatch(updateMetricDataAction(data));
  };

  const handleAdjustClick = ({ value = 0 }) => {
    const hot = hotRef?.current?.hotInstance;
    const selected = hot.getSelected() || [];

    for (let index = 0; index < selected.length; index += 1) {
      const [row1, column1, row2, column2] = selected[index];
      const startRow = Math.max(Math.min(row1, row2), 0);
      const endRow = Math.max(row1, row2);
      const startCol = Math.max(Math.min(column1, column2), 0);
      const endCol = Math.max(column1, column2);

      const item = selected[index];

      const rowData = hot
        .getData(...item)
        ?.flat()
        ?.every((val, i, arr) => val === arr[0])
        ? hot.getData(...item)?.flat()?.[0]
        : null;

      const adjustedValue = (parseFloat(rowData) / 100) * value + parseFloat(rowData);

      if (rowData) {
        for (let rowIndex = startRow; rowIndex <= endRow; rowIndex += 1) {
          for (let columnIndex = startCol; columnIndex <= endCol; columnIndex += 1) {
            hot.setDataAtCell(rowIndex, columnIndex, adjustedValue?.toFixed(2));
          }
        }
        hot.render();
        hot.resumeRender();
      }
    }
  };

  useImperativeHandle(ref, () => ({
    handleSubmit,
    handleAdjustClick,
  }));

  const sumRenderer = useCallback((instance, td, row, col) => {
    if (row === 0 && hotRef.current) {
      const sum = hotRef?.current?.hotInstance
        ?.getDataAtCol(col)
        .slice(1)
        .reduce((acc, curr) => acc + curr, 0);

      td.className = `ezy-handson ${metricFor === 'payrollForecast' ? 'htDimmed' : ''}`;

      if (
        hotRef.current.hotInstance?.getSourceDataAtRow(row)?.dateValues[col]?.value ||
        hotRef.current.hotInstance?.getSourceDataAtRow(row)?.dateValues[col]?.value === null
      ) {
        if (sum > 0) {
          hotRef.current.hotInstance.getSourceDataAtRow(row).dateValues[col].value =
            sum?.toFixed(2);

          td.innerHTML = sum?.toFixed(2);
        } else {
          hotRef.current.hotInstance.getSourceDataAtRow(row).dateValues[col].value = null;

          td.innerHTML = null;
        }
      }
    }
  }, []);

  const onSumCellChange = (changes, source) => {
    if (source === 'edit' && changes && changes.length > 0) {
      const columnIndex = changes[0][1]?.split('.')?.[1]; // Column index where the change occurred
      const newValue = parseFloat(changes[0][3]); // New value entered

      if (newValue && newValue !== 0 && changes[0][0] === 0) {
        const hot = hotRef.current.hotInstance;

        const colHeader = hot.getColHeader(columnIndex);
        const rowDayNumber =
          moment.tz(colHeader, DATE_FORMAT, 'UTC').day() === 0
            ? 7
            : moment.tz(colHeader, DATE_FORMAT, 'UTC').day();

        const workingHoursOfRowDay = selectedLocation?.operatingHours?.find(
          (e) => e?.weekDay?.id === rowDayNumber
        );

        const indexes = []; // indexes from rows to update data for particular day working hours

        hot
          .getSourceData()
          .forEach((e, i) =>
            moment.tz(e?.time, 'UTC').hours() >=
              moment.tz(workingHoursOfRowDay?.operatingHourStartTime, 'UTC').hours() &&
            moment.tz(e?.time, 'UTC').hours() <
              moment.tz(workingHoursOfRowDay?.operatingHourEndTime, 'UTC').hour()
              ? indexes.push(i)
              : null
          );

        hot.setDataAtCell(
          indexes?.map((e) => {
            return [e, +columnIndex, newValue / indexes.length];
          })
        );
      }
    }
  };

  useEffect(() => {
    const params = {
      metricId: selectedModule?.id,
      locationId: selectedLocation?.id,
      areaId: selectedModule?.showArea ? areaId?.id : null,
      startDate: moment.tz(weekDatesValue?.startDate, 'UTC').toISOString(),
      endDate: moment.tz(weekDatesValue?.endDate, 'UTC').toISOString(),
    };
    if (selectedModule?.id && selectedLocation?.id) {
      if (metricFor === 'payrollForecast') {
        dispatch(getPayrollForecastStatisticsAction(params));
      } else {
        params.isActuals = metricFor === 'actuals';
        dispatch(getMetricStatisticsAction(params));
      }
    } else {
      history.push(
        `${ROUTE_CONSTANTS.SCHEDULE}/insights/${pathname}?location=${
          selectedLocation?.id
        }&date=${moment.tz(weekDatesValue?.startDate, 'UTC')?.toISOString()}`
      );
    }
  }, [metricFor, metric?.filters]);

  return (
    <HandsonTable
      myRef={hotRef}
      rows={[
        {
          time: null,
          dateValues: rows[0]?.dateValues?.map((e) => ({ date: e?.date, value: null })),
        },
        ...rows,
      ]}
      dataSchema={{
        time: null,
        dateValues: [
          {
            date: null,
            value: null,
          },
        ],
      }}
      colHeaders={metric?.metricStatisticsList?.newMetricTimeListEntities?.[0]?.dateValues?.map(
        (e) => displayDate(e?.date, 'UTC')
      )}
      rowHeaders={[
        'Total',
        ...metric.metricStatisticsList.newMetricTimeListEntities.map((e) =>
          displayTime(e?.time, 'UTC')
        ),
      ]}
      columns={columns.map((e, i) => {
        return {
          data: `dateValues.${i}.value`,
          type: 'numeric',
          numericFormat: {
            pattern: '0.00',
          },
          readOnly: metricFor === 'payrollForecast',
        };
      })}
      cells={(row) => {
        const cellProperties = {};
        if (row === 0) cellProperties.renderer = sumRenderer;

        return cellProperties;
      }}
      afterChange={onSumCellChange}
    />
  );
});

EditMetricTable.propTypes = {
  metricFor: PropTypes.string.isRequired,
};

export default React.memo(EditMetricTable, () => false);
