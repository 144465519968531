import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../components/common/loader/Loader';
import { subHeaders } from '../exportTimesheetsHeaders';
import IconByName from '../../../components/common/iconByName/IconByName';
import AccessWrapper from '../../../components/common/accessWrapper/AccessWrapper';

function PayRateTable({ className, headers, actions, rows, loading }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);

  const handleClick = (event, row, index) => {
    setOpenMenu(index);
    setSelectedRowData(row);
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    setSelectedRowData(null);
    setOpenMenu(null);
    event.stopPropagation();
  };

  const handleAction = (event, cb) => {
    handleClose(event);
    if (typeof cb === 'function') {
      cb(selectedRowData);
    }
  };

  const hasAction = () => {
    if (actions?.length > 0) return true;
    const actionInRow = rows?.some((e) => e?.action?.length > 0);
    return !!actionInRow;
  };
  return (
    <Paper className="table-paper">
      <TableContainer className={`${className} payRate-table`}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headers
                ?.filter((e) => !e?.hidden)
                ?.map((column, index) => (
                  <TableCell
                    key={index}
                    align={column?.align || 'left'}
                    style={{ minWidth: column?.minWidth }}
                    size={column?.size || 'medium'}
                  >
                    {column?.label}
                  </TableCell>
                ))}
              {hasAction() && <TableCell align="right">Actions</TableCell>}
            </TableRow>
          </TableHead>
          {loading ? (
            <Loader />
          ) : (
            <TableBody>
              {rows?.length > 0 ? (
                rows?.map((r) => {
                  const payRateType = r?.payRateType;

                  return r?.payRateSubTypeList?.map((e, index1) => {
                    return e?.payrates?.map((payrate, i) => {
                      return (
                        <TableRow>
                          {index1 === 0 && i === 0 && (
                            <TableCell
                              rowSpan={r?.payRateSubTypeList?.reduce(
                                (prev, curr) => prev + curr.payrates.length,
                                0
                              )}
                            >
                              {payRateType}
                            </TableCell>
                          )}
                          {i === 0 && (
                            <TableCell rowSpan={e?.payrates?.length}>{e?.subType}</TableCell>
                          )}

                          {subHeaders
                            ?.filter((q) => !q?.hidden)
                            ?.map((y) => {
                              const value = payrate?.[y?.name];

                              return <TableCell>{value}</TableCell>;
                            })}

                          {actions?.length > 0 && (
                            <TableCell align="right">
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={openMenu ? 'long-menu' : undefined}
                                aria-expanded={openMenu ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={(k) => handleClick(k, payrate, index1)}
                                disabled={actions?.[0] === 'DISABLED'}
                              >
                                <IconByName name="more_vert" />
                              </IconButton>
                              <Menu
                                id="long-menu"
                                MenuListProps={{
                                  'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={openMenu === i}
                                onClose={handleClose}
                              >
                                {actions?.[0] !== 'DISABLED' &&
                                  actions?.map(({ label, access, onClick }, iindex) => (
                                    <AccessWrapper access={access} byPass={!access}>
                                      <MenuItem
                                        key={iindex}
                                        onClick={(v) => {
                                          handleAction(v, onClick);
                                        }}
                                      >
                                        {label}
                                      </MenuItem>
                                    </AccessWrapper>
                                  ))}
                              </Menu>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    });
                  });
                })
              ) : (
                <Box className="no-data-found error-color">No records found</Box>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Paper>
  );
}

PayRateTable.propTypes = {
  headers: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  className: PropTypes.string,
  actions: PropTypes.array,
  loading: PropTypes.bool,
};

PayRateTable.defaultProps = {
  className: '',
  actions: [],
  loading: false,
};

export default PayRateTable;
