import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import EzyDialog from '../../../../components/common/dialog/EzyDialog';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import EzyButton from '../../../../components/common/button/EzyButton';
import oldAndNewEmailValidation from '../../validations/oldAndNewEmailValidation';
import { getPeopleDetailsAction, resetLoginDetailsAction } from '../../redux/peopleActions';
import IconByName from '../../../../components/common/iconByName/IconByName';

function ResetLoginDetails({ email, model, setModel }) {
  const [newEmail, setNewEmail] = useState('');
  const [errors, setErrors] = useState(null);
  const { peopleId } = useParams();
  const dispatch = useDispatch();
  const handelResetLogin = () => {
    const error = oldAndNewEmailValidation({ currentEmail: email, newEmail });
    setErrors(error);
    if (isEmpty(error)) {
      const data = {
        personId: peopleId,
        newEmail,
      };
      dispatch(
        resetLoginDetailsAction(data, () => {
          setModel(null);
          dispatch(getPeopleDetailsAction(peopleId));
        })
      );
    }
  };

  return (
    <EzyDialog
      handleClose={() => setModel(null)}
      open={model === 'ResetLoginDetails'}
      className="common-dialog-with-body bulk-action-modal"
    >
      <div className="dialog-body people-reset-login-modal">
        <IconByName name="close" className="close-icon" onClick={() => setModel(null)} />
        <div className="reset-login-title-wrapper">
          <div className="reset-login-main-title">Reset login details</div>
          <div className="reset-login-title-info">
            Enter the information you entered while registering.
          </div>
        </div>
        <EzyInputField
          disabled
          label="Current email"
          placeholder="Enter current email"
          value={email}
        />
        <EzyInputField
          label="New email"
          placeholder="Enter new email"
          onChange={(e) => setNewEmail(e.target.value)}
          value={newEmail}
          error={errors?.newEmail}
        />
        <div className="action-button">
          <EzyButton label="Submit" onClick={() => handelResetLogin()} />
        </div>
      </div>
    </EzyDialog>
  );
}

export default ResetLoginDetails;

ResetLoginDetails.propTypes = {
  email: PropTypes.string,
  model: PropTypes.string.isRequired,
  setModel: PropTypes.func.isRequired,
};
ResetLoginDetails.defaultProps = {
  email: '',
};
