import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';
import EzyTabDefault from '../../../components/common/tab/EzyTabDefault';
import TimesheetDetails from './TimesheetDetails';
import EzyTable from '../../../components/common/table/EzyTable';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import EzyAutoComplete from '../../../components/common/autoComplete/EzyAutoComplete';
import EzyButton from '../../../components/common/button/EzyButton';
import { timesheetActivityHeaders } from '../timeSheetsHeaders';
import TimeSheetDetailsAction from './TimeSheetDetailsAction';
import {
  getJournalDetailsByDateAction,
  getTimeSheetHistoryAction,
} from '../redux/timeSheetsAction';
import { displayDate } from '../../../utils/timeHelper';
import useQueryParams from '../../../hooks/useQueryParams';
import { updateTimeSheetData } from '../redux/timeSheetsSlice';
import { addJournalAction } from '../../people/redux/peopleActions';

function EditViewTimesheet() {
  const params = useQueryParams();
  const { selectedTimesheet } = useSelector(({ timeSheets }) => timeSheets?.sidePanelData);

  const { personId: loggedInPersonId } = useSelector(({ user }) => user?.profileData ?? {});

  const tabs = [
    { label: 'Time Sheet', component: <TimesheetDetails />, access: 'default' },
    { label: 'Activity', component: <TimesheetActivity />, access: 'default' },
    {
      label: 'Journal',
      component: <TimesheetJournal />,
      access: params?.person !== loggedInPersonId ? 'default' : '',
    },
  ];
  return (
    <div className="tab-container timesheet-detail-wrapper ">
      {selectedTimesheet?.id && <EzyTabDefault list={tabs} onChange={() => {}} variant="" />}
    </div>
  );
}

export default React.memo(EditViewTimesheet);

function TimesheetActivity() {
  const dispatch = useDispatch();

  const { selectedTimesheet, selectedLocation } = useSelector(
    ({ timeSheets }) => timeSheets?.sidePanelData
  );

  const { historyData } = useSelector(({ timeSheets }) => timeSheets?.timeSheetData);

  useEffect(() => {
    if (selectedTimesheet?.id)
      dispatch(getTimeSheetHistoryAction({ timesheetId: selectedTimesheet?.id }));
  }, [selectedTimesheet]);

  return (
    <div className="timesheet-activity-wrapper">
      <div className="created-text">
        Time sheet created by {historyData?.createdBy} ,{' '}
        {displayDate(historyData?.createdDate, selectedLocation?.timeZoneId?.label)}
      </div>
      <EzyTable
        headers={timesheetActivityHeaders}
        rows={historyData?.timeSheetHistoryData}
        timeZone={historyData?.timeZoneId?.label}
      />
    </div>
  );
}

function TimesheetJournal() {
  const dispatch = useDispatch();
  const params = useQueryParams();
  const { personName: loggedInPersonName, timeZone: loggedInPersonTimeZone } = useSelector(
    ({ user }) => user?.profileData ?? {}
  );
  const { journalDetails, journalEntry, ratePerformance, errors, areaId, startTime } = useSelector(
    ({ timeSheets }) => timeSheets?.timeSheetData
  );
  const { selectedTimesheet } = useSelector(({ timeSheets }) => timeSheets?.sidePanelData);
  const { ratePerformance: ratePerformanceEnum } = useSelector(({ common }) => common?.enums ?? {});

  const onChangeFields = (fieldName, value) => {
    dispatch(updateTimeSheetData({ fieldName, value }));
  };

  const handleSubmit = () => {
    const data = {
      ratePerformance: ratePerformance?.id ?? null,
      journalEntry,
      personDetailId: params?.person,
      createdDate:
        journalEntry && moment.tz(new Date(), loggedInPersonTimeZone?.label)?.toISOString(),
      createdByName: loggedInPersonName,
      attachedTimeSheetId: selectedTimesheet?.id,
    };

    if (!isEmpty(journalEntry)) {
      dispatch(
        addJournalAction(data, () => {
          onChangeFields('journalEntry', '');
          onChangeFields('ratePerformance', null);
          dispatch(
            getJournalDetailsByDateAction({
              date: moment.tz(startTime, areaId?.timeZoneId?.label)?.toISOString(),
              personDetailId: params?.person,
              areaId: areaId?.id,
              timesheetId: selectedTimesheet?.id,
            })
          );
        })
      );
    }
  };

  useEffect(() => {
    if (params?.person && startTime && areaId) {
      dispatch(
        getJournalDetailsByDateAction({
          date: moment.tz(startTime, areaId?.timeZoneId?.label)?.toISOString(),
          personDetailId: params?.person,
          areaId: areaId?.id,
          timesheetId: selectedTimesheet?.id,
        })
      );
    }
  }, [params?.person, startTime, areaId]);

  return (
    <div className="edit-timesheet-wrapper">
      <div className="journal-wrapper details">
        <div className="no-journal-text word-break">
          {!journalDetails ? (
            <div>There are no journal entries for this team member on this day.</div>
          ) : (
            <div className="w-full">
              <div>
                {journalDetails?.createdByName} |{' '}
                {displayDate(journalDetails?.createdDate, loggedInPersonTimeZone?.label)}
              </div>
              <div className="d-flex just-bet">
                <span className="text-secondary-color body-text">
                  {journalDetails?.journalEntry}
                </span>
                {journalDetails?.ratePerformance && (
                  <span
                    className={`body-text ${
                      journalDetails?.ratePerformance?.id === 1 ? 'primary-color' : 'error-color'
                    }`}
                  >
                    #{journalDetails?.ratePerformance?.label}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
        <EzyInputField
          label="Journal"
          placeholder="Enter journal"
          rows={2}
          multiline
          minRows={2}
          value={journalEntry}
          onChange={(e) => onChangeFields('journalEntry', e?.target?.value)}
          error={errors?.journalEntry}
        />
        <EzyAutoComplete
          label="Category"
          placeholder="Select category"
          value={ratePerformance}
          options={ratePerformanceEnum}
          onChange={(_, value) => onChangeFields('ratePerformance', value)}
        />
        <EzyButton label="Save journal" disabled={!journalEntry} onClick={handleSubmit} />
      </div>
      <TimeSheetDetailsAction />
    </div>
  );
}
