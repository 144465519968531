import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import EzyDrawer from '../../../components/common/drawer/Drawer';
import { updateAddEditShiftFields } from '../redux/scheduleSlice';
import IconByName from '../../../components/common/iconByName/IconByName';

function RepeatShiftForDaysDrawer({ handleSubmit }) {
  const { weekDays: weekDaysEnum } = useSelector(({ common }) => common?.enums ?? {});

  const shiftData = useSelector(({ schedule }) => schedule?.addEditShift ?? {});

  const { weekDays = [], repeatForDays, errors } = useMemo(() => shiftData, [shiftData]);

  const { editShiftActionLoader, addShiftActionLoader } = useSelector(
    ({ common }) => common?.generalLoader ?? {}
  );

  const dispatch = useDispatch();
  const handleFieldChange = (name, value) => {
    dispatch(updateAddEditShiftFields({ fieldName: name, value }));
  };

  const handleClose = () => {
    handleFieldChange('repeatForDays', null);
    handleFieldChange('weekDays', null);
    handleFieldChange('errors', null);
  };

  const handleToggle = (value) => () => {
    const currentIndex = weekDays?.indexOf(value);
    const newChecked = [...weekDays];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    handleFieldChange('weekDays', newChecked);
  };

  const actionButton = useMemo(
    () => [
      {
        label: 'Save',
        onClick: handleSubmit,
        loading: editShiftActionLoader || addShiftActionLoader,
      },
    ],
    [handleSubmit, editShiftActionLoader, addShiftActionLoader]
  );

  return (
    <EzyDrawer
      onClose={handleClose}
      isOpen={repeatForDays}
      actionButtons={actionButton}
      heading="Repeat Shift"
    >
      <div className="shift-drawer">
        <div className="repeat-shift-drawer-field-gird">
          <FormControlLabel
            label="Select All"
            className="just-right"
            control={
              <Checkbox
                edge="start"
                checked={weekDaysEnum?.length === weekDays?.length}
                tabIndex={-1}
                onChange={(e) => {
                  if (e.target.checked) {
                    handleFieldChange(
                      'weekDays',
                      weekDaysEnum?.map((val) => val?.id)
                    );
                  } else {
                    handleFieldChange('weekDays', []);
                  }
                }}
                disableRipple
                inputProps={{ 'aria-labelledby': 'select All' }}
                icon={<IconByName name="check_circle_outline" />}
                checkedIcon={<IconByName name="check_circle" className="primary-color" />}
              />
            }
          />
          <List>
            {weekDaysEnum?.map((value) => {
              const labelId = `checkbox-list-label-${value?.id}`;

              return (
                <ListItem key={value?.id} selected={weekDays?.includes(value?.id)} disablePadding>
                  <ListItemButton
                    role={undefined}
                    onClick={handleToggle(value?.id)}
                    dense
                    disableRipple
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={weekDays?.includes(value?.id)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                        icon={<IconByName name="check_circle_outline" />}
                        checkedIcon={<IconByName name="check_circle" className="primary-color" />}
                      />
                    </ListItemIcon>
                    <ListItemText id={value?.id} className="list-view">
                      <span>{value?.label}</span>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          {errors?.weekDays && (
            <FormHelperText className="error-helper">{errors?.weekDays}</FormHelperText>
          )}
        </div>
      </div>
    </EzyDrawer>
  );
}

export default RepeatShiftForDaysDrawer;

RepeatShiftForDaysDrawer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};
