import { displayDate, displayTime } from '../../utils/timeHelper';

export const exportTimeSheetHeaders = [
  {
    name: 'id',
    label: 'Id',
    hidden: true,
  },
  {
    name: 'name',
    label: 'Name',
    format: (value, row, isOpen) => {
      return (
        <div className="d-flex-column">
          <div>{value}</div>
          {!isOpen && (
            <div className="text-secondary-color d-flex justify-content-end">previous</div>
          )}
        </div>
      );
    },
    innerFormat: (value, row, timeZone) => {
      return (
        <div className="d-flex just-bet">
          <span>
            {displayDate(row?.timeSheetDate, timeZone)} | {displayTime(row?.startTime, timeZone)} -{' '}
            {displayTime(row?.endTime, timeZone)} | {row?.totalMealBreak} | {row?.areaName}
          </span>
          {row?.payRates?.length > 0 && (
            <span className="secondary-action-text">Pay breakdown</span>
          )}
        </div>
      );
    },
    mostInnerFormat: (value, row) => {
      return <div className="d-flex justify-content-end">{row?.payRatesString}</div>;
    },
  },
  {
    name: 'totalWorkingHour',
    label: 'Total hours',
    size: 'small',
    format: (value, row, isOpen) => {
      return (
        <div className="d-flex-column">
          <div>{value}</div>
          {!isOpen && (
            <div className="text-secondary-color d-flex justify-content-end">
              {row?.previousTotalWorkingHour}
            </div>
          )}
        </div>
      );
    },
    mostInnerFormat: (value, row) => {
      return row?.workingTime;
    },
    align: 'right',
  },
  {
    name: 'totalCost',
    label: 'Total Cost',
    size: 'small',
    format: (value, row, isOpen) => {
      return (
        <div className="d-flex-column">
          <div>{value}</div>
          {!isOpen && (
            <div className="text-secondary-color d-flex justify-content-end">
              {row?.previousTotalCost}
            </div>
          )}
        </div>
      );
    },
    mostInnerFormat: (value, row) => {
      return row?.cost;
    },
    align: 'right',
  },
];

export const exportTimeSheetPayRateHeaders = [
  {
    name: 'id',
    label: 'Id',
    hidden: true,
  },
  {
    name: 'payRateType',
    label: 'Pay Rate Type',
  },
  {
    name: 'subType',
    label: ' ',
  },
  {
    name: 'payRate',
    label: 'Pay Rate',
  },
  {
    name: 'exportCode',
    label: 'Export Code',
  },
  {
    name: 'hours',
    label: 'Hours / Units',
  },
  {
    name: 'cost',
    label: 'Cost',
  },
];

export const subHeaders = [
  {
    name: 'id',
    label: 'Id',
    hidden: true,
  },
  {
    name: 'payrate',
    label: 'Pay Rate',
  },
  {
    name: 'exportCode',
    label: 'Export Code',
  },
  {
    name: 'workingHours',
    label: 'Hours / Units',
  },
  {
    name: 'cost',
    label: 'Cost',
  },
];
