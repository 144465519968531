import { InputAdornment } from '@mui/material';
import { isEmpty, isNil, omitBy } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import { requireValidate } from '../../../../utils/validationHelper';
import { checkPasswordAction } from '../../redux/globalProfileActions';
import { resetChangeEmailData, updateGlobalProfileData } from '../../redux/globalProfileSlice';

function CheckPasswordDrawer() {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const { checkPasswordDrawer, password, profileDetails, errors } = useSelector(
    ({ globalProfile }) => globalProfile || {}
  );
  const { checkPasswordActionLoader } = useSelector(({ common }) => common.generalLoader ?? {});

  const handleClose = () => {
    dispatch(updateGlobalProfileData({ fieldName: 'checkPasswordDrawer', value: false }));
    dispatch(resetChangeEmailData());
  };

  const handleSubmit = async () => {
    const data = {
      email: profileDetails?.emailAddress,
      password,
    };
    let error = {};
    error.password = requireValidate('password', data.password);
    error = omitBy(error, isNil);
    if (isEmpty(error)) {
      dispatch(updateGlobalProfileData({ fieldName: 'errors', value: {} }));
      await dispatch(
        checkPasswordAction(data, () => {
          dispatch(updateGlobalProfileData({ fieldName: 'changeEmailDrawer', value: true }));
          handleClose();
        })
      );
    } else {
      dispatch(updateGlobalProfileData({ fieldName: 'errors', value: error }));
    }
  };

  const actionButton = [
    {
      label: 'Next',
      onClick: handleSubmit,
      loading: checkPasswordActionLoader,
    },
  ];

  return (
    <EzyDrawer
      onClose={handleClose}
      isOpen={checkPasswordDrawer}
      actionButtons={actionButton}
      heading="Password"
    >
      <div className="p-24">
        <EzyInputField
          type={showPassword ? 'text' : 'password'}
          label="Current password"
          placeholder="Enter current password"
          onChange={(e) =>
            dispatch(updateGlobalProfileData({ fieldName: 'password', value: e?.target?.value }))
          }
          value={password}
          error={errors?.password}
          endAdornment={
            <InputAdornment position="end">
              <span
                className="material-icons icon-small endAdornment-icon"
                onClick={() => setShowPassword(!showPassword)}
                role="presentation"
              >
                {showPassword ? 'visibility_off' : 'visibility'}
              </span>
            </InputAdornment>
          }
          required
        />
      </div>
    </EzyDrawer>
  );
}

export default CheckPasswordDrawer;
