import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EzyTabDefault from '../../../components/common/tab/EzyTabDefault';
import DashboardCards from './DashboardCards';
import { updateDashboardData } from '../redux/dashboardSlice';

function DashboardAdmin() {
  const { dashboardPanelDisplay } = useSelector(({ common }) => common?.enums ?? {});
  const dispatch = useDispatch();
  const handleTabChange = (e) => {
    dispatch(updateDashboardData({ fieldName: 'cardFor', value: e + 1 }));
  };
  const tabs = dashboardPanelDisplay?.map((e) => ({
    label: e?.label,
    component: <DashboardCards cardsFor={e?.id} />,
    access: 'default',
  }));

  return <EzyTabDefault list={tabs} onChange={handleTabChange} />;
}

export default DashboardAdmin;
