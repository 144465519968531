import { FormControl, FormHelperText, InputBase, InputLabel } from '@mui/material';
import PropTypes from 'prop-types';
import { toLower, upperFirst } from 'lodash';
import EzyIconTooltip from '../tooltip/EzyIconTooltip';

function EzyInputField({
  value,
  label,
  placeholder,
  onChange,
  className,
  error,
  tooltip,
  ...restProps
}) {
  return (
    <FormControl variant="standard" className={`inputField ${className}`}>
      {label && (
        <InputLabel shrink htmlFor="bootstrap-input">
          {label}
          {restProps?.required && <div className="required">*</div>}
          {tooltip && <EzyIconTooltip tooltip={tooltip} className="input-field-tooltip" />}
        </InputLabel>
      )}
      <InputBase
        className={error ? 'field-error' : ''}
        value={value}
        onChange={onChange}
        placeholder={upperFirst(toLower(placeholder))}
        inputRef={restProps?.innerRef}
        {...restProps}
      />
      {error && <FormHelperText className="error-helper">{error}</FormHelperText>}
    </FormControl>
  );
}

export default EzyInputField;

EzyInputField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

EzyInputField.defaultProps = {
  label: '',
  value: '',
  onChange: () => {},
  className: '',
  placeholder: '',
  error: false,
  tooltip: null,
};
