import { createSlice } from '@reduxjs/toolkit';
import { businessLeaveRulesListHeaders } from '../businessHeaders';

const businessSlice = createSlice({
  name: 'business',
  initialState: {
    joinType: 'New To PayrollEzy',
    locationType: 'Just One',
    businessName: '',
    numberOfPeople: '',
    uniqueURL: '',
    countryId: '',
    email: '',
    phoneNumber: '',
    businessIndustryTypeId: null,
    locations: [{ locationName: '', address: '', areas: [], city: '' }],
    errors: {},
    inviteToBusiness: {
      name: '',
      email: '',
    },
    businessSettings: {
      businessName: '',
      countryId: '',
      generalSetting: {},
      people: {},
      timesheets: {},
    },
    leaveSettings: {
      allowTeamMembersToViewTheirBalance: false,
      allowTeamMembersToAmendOrCancelTheirApprovedLeaveRequest: false,
      whoCanApproveLeave: '',
    },
    businessLeaveRulesList: {
      docs: [],
      headers: businessLeaveRulesListHeaders,
      total: 1,
      limit: 15,
      page: 1,
      pages: 1,
    },
    leaveRuleData: {
      whoCanApproveLeaveEnum: [],
      leaveRuleDetails: {
        id: null,
        name: '',
        isPaid: false,
        isVisibleToTeamMember: false,
        isDefault: false,
        leaveType: '',
        resetLeaveAccrual: '',
        trackLeaveIn: '',
      },
      leaveRulesDrawer: false,
      isEditLeaveRules: false,
    },
  },
  reducers: {
    setNewBusinessField: (state, action) => {
      const { fieldName, value } = action.payload;
      state[fieldName] = value;
    },
    setInviteToBusinessField: (state, action) => {
      const { fieldName, value } = action.payload;
      state.inviteToBusiness[fieldName] = value;
    },
    addDeleteLocation: (state, action) => {
      const { type, index } = action.payload;
      if (type === 'ADD') {
        state.locations.push({
          locationName: '',
          address: '',
          areas: [],
        });
      }
      if (type === 'DELETE') {
        state.locations = state.locations.filter((e, i) => i !== index);
      }
    },
    updateLocation: (state, action) => {
      const { index, fieldName, value } = action.payload;
      state.locations = state.locations.map((e, i) =>
        i === index ? { ...e, [fieldName]: value } : e
      );
    },
    setBusinessSettingDetails: (state, action) => {
      state.businessSettings = action.payload;
    },
    updateBusinessSettingDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.businessSettings[fieldName] = value;
    },
    updateTimeSheetBusinessSettingDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.businessSettings.timesheets[fieldName] = value;
    },
    updateGeneralBusinessSettingDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.businessSettings.generalSetting[fieldName] = value;
    },
    updatePeopleBusinessSettingDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.businessSettings.people[fieldName] = value;
    },
    setLeaveSettingDetails: (state, action) => {
      state.leaveSettings = { ...action.payload.leave };
    },
    updateLeaveSettingDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.leaveSettings[fieldName] = value;
    },
    setBusinessLeaveRules: (state, action) => {
      state.businessLeaveRulesList.docs = action.payload.data;
      state.businessLeaveRulesList.total = action.payload.totalData;
      state.businessLeaveRulesList.limit = action.payload.limit;
      state.businessLeaveRulesList.page = action.payload.page;
      state.businessLeaveRulesList.pages = action.payload.totalPages;
    },
    updateLeaveRuleData: (state, action) => {
      const { fieldName, value } = action.payload;
      state.leaveRuleData[fieldName] = value;
    },
    setLeaveRuleDetails: (state, action) => {
      state.leaveRuleData.leaveRuleDetails = action.payload;
    },
    resetLeaveRuleDetails: (state) => {
      state.leaveRuleData = {};
      state.leaveRuleData.leaveRuleDetails = {};
    },
    updateLeaveRuleDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.leaveRuleData.leaveRuleDetails[fieldName] = value;
    },
    resetLocation: (state) => {
      state.locations = [{ locationName: '', address: '', areas: [] }];
    },
    resetBusinessSettingDetails: (state) => {
      state.businessSettings.businessName = '';
      state.businessSettings.countryId = '';
      state.businessSettings.timesheets = {};
      state.businessSettings.generalSetting = {};
      state.businessSettings.people = {};
    },
    resetLeaveSettingDetails: (state) => {
      state.leaveSettings.allowTeamMembersToAmendOrCancelTheirApprovedLeaveRequest = false;
      state.leaveSettings.whoCanApproveLeave = {};
      state.leaveSettings.allowTeamMembersToViewTheirBalance = false;
    },
    resetInviteUserData: (state) => {
      state.inviteToBusiness.name = '';
      state.inviteToBusiness.email = '';
    },
    resetAddNewBusinessData: (state) => {
      state.businessIndustryTypeId = null;
      state.businessName = '';
      state.numberOfPeople = '';
      state.phoneNumber = '';
      state.countryId = '';
      state.locations = [{ locationName: '', address: '', areas: [] }];
    },
  },
});

const { reducer, actions } = businessSlice;

export const {
  setNewBusinessField,
  addDeleteLocation,
  updateLocation,
  updateBusinessSettingDetails,
  updateTimeSheetBusinessSettingDetails,
  updateGeneralBusinessSettingDetails,
  updatePeopleBusinessSettingDetails,
  setBusinessSettingDetails,
  setBusinessLeaveRules,
  setLeaveSettingDetails,
  setInviteToBusinessField,
  updateLeaveSettingDetails,
  updateLeaveRuleData,
  setLeaveRuleDetails,
  resetLeaveRuleDetails,
  updateLeaveRuleDetails,
  resetLocation,
  resetBusinessSettingDetails,
  resetLeaveSettingDetails,
  resetInviteUserData,
  resetAddNewBusinessData,
} = actions;

export default reducer;
