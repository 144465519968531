import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputAdornment } from '@mui/material';
import moment from 'moment';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import IconByName from '../../../components/common/iconByName/IconByName';
import EzyMenu from '../../../components/common/menu/EzyMenu';

import TasksSidebar from '../components/TasksSidebar';
import Task from '../components/Task';
import AddTaskForWholeAreaDrawer from '../components/AddTaskForWholeAreaDrawer';
import TaskByArea from '../components/TaskByArea';
import { getTasksAction } from '../redux/tasksActions';
import AddTaskDrawer from '../components/AddTaskDrawer';
import {
  updateAddTaskDrawerDetails,
  updateAddTaskForAreaDrawerDetails,
  updateTaskListDetails,
} from '../redux/tasksSlice';
import Loader from '../../../components/common/loader/Loader';
import EzyButton from '../../../components/common/button/EzyButton';
import useUrlParamsUpdate from '../../../hooks/useURLParamsUpdate';

function TasksList() {
  const { docs, hasMoreRecords, page, limit } = useSelector(({ tasks }) => tasks?.tasksList ?? {});
  const { taskType, selectedLocation, date } = useSelector(({ tasks }) => tasks?.filters);
  const { areaDocs } = useSelector(({ tasks }) => tasks?.areaTasksList ?? {});
  const { getTasksActionLoader, getTasksByAreaActionLoader, deleteTaskActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );
  const { roleId } = useSelector(({ user }) => user?.profileData ?? {});

  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  const param = {
    locationId: selectedLocation,
    date: moment(date).toISOString(),
  };

  useUrlParamsUpdate(param);

  const addTaskActions = [
    {
      label: 'Tasks for a whole area',
      id: 1,
      onClick: () => {
        dispatch(
          updateAddTaskForAreaDrawerDetails({
            fieldName: 'drawerTitle',
            value: 'Tasks For Whole Area',
          })
        );
      },
    },
    {
      label: 'A task for me',
      id: 2,
      onClick: () => {
        dispatch(
          updateAddTaskDrawerDetails({
            fieldName: 'drawerTitle',
            value: 'A Task For Me',
          })
        );
      },
    },
    {
      label: 'A Task For Someone Else',
      id: 3,
      onClick: () => {
        dispatch(
          updateAddTaskDrawerDetails({
            fieldName: 'drawerTitle',
            value: 'A Task For Someone Else',
          })
        );
      },
    },
  ];
  const getTasksByFilter = (params) => {
    dispatch(
      getTasksAction({
        page: 1,
        limit: 15,
        searchText: null,
        myTask: taskType === 'My tasks',
        ...params,
      })
    );
  };
  const handleSearch = async (e) => {
    if (e.target.value) setSearchText(e.target.value);
    else {
      setSearchText(null);
      await getTasksByFilter({
        searchText: null,
        myTask: taskType === 'My tasks',
      });
    }
  };
  const handleScroll = useCallback(
    (e) => {
      if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight && hasMoreRecords) {
        dispatch(updateTaskListDetails({ fieldName: 'page', value: page + 1 }));
        getTasksByFilter({
          page: page + 1,
          limit,
        });
      }
    },
    [docs?.length, hasMoreRecords, taskType]
  );

  return (
    <div className="task-root-container">
      <TasksSidebar />
      <div className="task-list-wrapper">
        <div className="task-list-header">
          {taskType !== 'Tasks By Area' && roleId < 4 && (
            <EzyInputField
              placeholder="Search "
              className="search-box"
              value={searchText}
              onChange={handleSearch}
              onKeyDown={async (e) => {
                if (e.key === 'Enter') {
                  await getTasksByFilter({
                    searchText,
                    myTask: taskType === 'My tasks',
                  });
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconByName name="search" />
                </InputAdornment>
              }
            />
          )}
          <span className="menu-button">
            {roleId > 3 ? (
              <EzyButton
                label="Add"
                onClick={() => {
                  dispatch(
                    updateAddTaskDrawerDetails({
                      fieldName: 'drawerTitle',
                      value: 'Add Task',
                    })
                  );
                }}
              />
            ) : (
              <EzyMenu
                className="add-task-menu"
                iconName="add"
                menuItems={addTaskActions}
                color="primary"
                label="Add"
              />
            )}
          </span>
        </div>

        <div className="task-grid" onScroll={handleScroll}>
          {(getTasksActionLoader || getTasksByAreaActionLoader || deleteTaskActionLoader) && (
            <Loader />
          )}
          {taskType !== 'Tasks By Area' &&
            (docs?.length > 0 ? (
              docs.map((item) => {
                return <Task key={item.taskId} task={item} taskType={taskType} />;
              })
            ) : (
              <div className="no-data-found no-data-found-container">No Data Found</div>
            ))}

          {taskType === 'Tasks By Area' &&
            (areaDocs?.length > 0 ? (
              areaDocs.map((item) => {
                return <TaskByArea key={item?.id} task={item} />;
              })
            ) : (
              <div className="no-data-found no-data-found-container">No Data Found</div>
            ))}
        </div>
      </div>
      <AddTaskDrawer />
      <AddTaskForWholeAreaDrawer />
    </div>
  );
}

export default TasksList;
