import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import {
  updateBillingDetails,
  updateGlobalProfileData,
  updateSummaryDetails,
} from '../../redux/globalProfileSlice';
import { getOrderSummaryAction, getPlansAction } from '../../redux/globalProfileActions';
import AddonUserOrderConfirmation from './AddonUserOrderConfirmation';
import Loader from '../../../../components/common/loader/Loader';

function AddonUsers() {
  const { paramId } = useParams();
  const dispatch = useDispatch();

  const { billingDetails, billingAndPaymentDetails } = useSelector(
    ({ globalProfile }) => globalProfile || {}
  );

  const { getPlansActionLoader, getOrderSummaryActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const { addOnUsers, isAddOnUserDrawer, planId, errors } = useMemo(
    () => billingDetails ?? {},
    [billingDetails]
  );

  const {
    numberOfUser,
    perUserCost = 0,
    totalAmount = 0,
    totalDiscount = 0,
    totalUserCost = 0,
    taxDetails,
  } = useMemo(() => billingAndPaymentDetails ?? {}, [billingAndPaymentDetails]);

  const handleClose = () => {
    dispatch(
      updateGlobalProfileData({
        fieldName: 'billingAndPaymentDetails',
        value: {},
      })
    );
    dispatch(
      updateBillingDetails({
        fieldName: 'addOnUsers',
        value: '',
      })
    );
    dispatch(
      updateBillingDetails({
        fieldName: 'isAddOnUserDrawer',
        value: false,
      })
    );
  };

  const handleSubmit = () => {
    const error = {};
    // eslint-disable-next-line no-restricted-globals
    if (!numberOfUser || numberOfUser <= 0 || isNaN(numberOfUser)) {
      error.addOnUsers = 'Enter valid number of users!';
    } else {
      dispatch(
        updateBillingDetails({
          fieldName: 'isAddOnUserModal',
          value: true,
        })
      );
    }
    dispatch(
      updateBillingDetails({
        fieldName: 'errors',
        value: error,
      })
    );
  };

  const actionButton = useMemo(
    () => [
      {
        label: 'Continue',
        onClick: handleSubmit,
      },
    ],
    [handleSubmit]
  );

  const getSummaryDetails = async (
    paramParamId,
    paramPlanId,
    paramPlanDuration = 2,
    paramNumberOfUser = 0,
    isAdditionalUser = false
  ) => {
    const data = {
      businessId: paramParamId,
      planId: Number(paramPlanId),
      planDuration: paramPlanDuration,
      numberOfUser: paramNumberOfUser ? Number(paramNumberOfUser) : 0,
      isAdditionalUser,
    };
    await dispatch(getOrderSummaryAction(data));
  };

  useEffect(() => {
    if (planId && isAddOnUserDrawer) {
      (async () => {
        await dispatch(
          getPlansAction({ businessId: paramId }, async (plans) => {
            if (plans?.length > 0 && planId) {
              const selectedPlan = plans?.find((e) => e?.planId?.toString() === planId);
              const planDuration = 2;
              dispatch(updateSummaryDetails({ ...selectedPlan, planDuration }));
              if (planDuration) {
                await getSummaryDetails(paramId, planId, planDuration, numberOfUser);
              }
            }
          })
        );
      })();
    }
  }, [planId, isAddOnUserDrawer]);

  return (
    <div>
      <EzyDrawer
        onClose={handleClose}
        isOpen={isAddOnUserDrawer}
        actionButtons={actionButton}
        heading="Purchase Additional Users"
        isLoading={getPlansActionLoader}
      >
        <div className="addon-user-drawer">
          <div className="field-grid">
            <EzyInputField
              name="addOnUsers"
              placeholder="Enter number of users"
              label="Number of users"
              value={addOnUsers}
              type="number"
              onChange={(e) => {
                dispatch(
                  updateBillingDetails({
                    fieldName: 'addOnUsers',
                    value: e.target.value,
                  })
                );
                (async () => {
                  await getSummaryDetails(paramId, planId, 2, e.target.value, true);
                })();
              }}
              error={errors?.addOnUsers}
            />
            <div className="amount-details">
              {getOrderSummaryActionLoader && <Loader />}
              <div className="w-full flex-align-center d-flex just-bet gap-12">
                <div className="label">
                  {`${perUserCost} / User / Year`}
                  <span className="primary-color"> &nbsp;x&nbsp; </span>
                  {`${numberOfUser || 0} users`}
                </div>
                <div className="value">{`${totalUserCost}`}</div>
              </div>
              <div className="w-full flex-align-center d-flex just-bet gap-12">
                <div className="label">Annual discount</div>
                <div className="value">{totalDiscount}</div>
              </div>
              {taxDetails?.length > 0 &&
                taxDetails.map((e, index) => (
                  <div className="w-full flex-align-center d-flex just-bet gap-12" key={index}>
                    <div className="label">
                      {e?.taxType}({e?.taxRate || 0}%)
                    </div>
                    <div className="value">{e?.taxAmount}</div>
                  </div>
                ))}
              <div className="w-full flex-align-center d-flex just-bet gap-12">
                <div className="label">Total</div>
                <div className="value">{totalAmount}</div>
              </div>
            </div>
          </div>
        </div>
      </EzyDrawer>
      <AddonUserOrderConfirmation />
    </div>
  );
}

export default AddonUsers;
