import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Checkbox,
  FormHelperText,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import EzyDrawer from '../../../components/common/drawer/Drawer';
import { resetAddEditShiftFields } from '../redux/scheduleSlice';
import IconByName from '../../../components/common/iconByName/IconByName';
import { publishShiftAction } from '../redux/scheduleActions';

const publishData = [
  {
    title: 'Publish Updates',
    subTitle: 'Only Team Members whose shifts have changed will be notified.',
    value: false,
    id: 1,
  },
  {
    title: 'Publish all',
    subTitle: 'All Team Members with shifts will be notified.',
    value: true,
    id: 2,
  },
];

function PublishShiftDrawer({ reFetchEvents }) {
  const dispatch = useDispatch();
  const [publishType, setPublishType] = useState(false);
  const [notifyType, setNotifyType] = useState(2);
  const [error, setError] = useState(null);

  const shiftData = useSelector(({ schedule }) => schedule?.addEditShift ?? {});
  const { notifyType: notifyEnum } = useSelector(({ common }) => common?.enums ?? {});
  const { shiftsToPublish, publishShiftDrawer } = useMemo(() => shiftData, [shiftData]);

  const { publishShiftActionLoader } = useSelector(({ common }) => common.generalLoader ?? {});

  const notifyListData = useMemo(
    () =>
      notifyEnum?.map((e) => {
        const data = e?.label?.split('(');
        const title = data?.[0];
        const subTitle = capitalize(data?.[1].split(')')?.[0]);

        return { id: e?.id, title, subTitle };
      }),
    [notifyEnum]
  );

  const handlePublishToggle = (value) => {
    setPublishType(value);
  };
  const handleNotifyToggle = (value) => {
    setNotifyType(value);
  };

  const handleClose = () => {
    dispatch(resetAddEditShiftFields());
    setPublishType(false);
    setError(null);
    setNotifyType(null);
  };

  const handleSubmit = () => {
    if (!notifyType) {
      setError('Please select valid options to continue.');
    } else {
      setError(null);
      const data = {
        shiftIds: shiftsToPublish,
        notifyStaff: notifyType,
        isPublishAll: publishType,
      };
      dispatch(
        publishShiftAction(data, () => {
          handleClose();
          reFetchEvents();
        })
      );
    }
  };

  const actionButton = [
    {
      label: 'Publish',
      onClick: handleSubmit,
      loading: publishShiftActionLoader,
    },
  ];

  return (
    <EzyDrawer
      onClose={handleClose}
      isOpen={publishShiftDrawer}
      actionButtons={actionButton}
      heading="Publish Shift"
    >
      <div className="shift-drawer">
        <div className="title-with-primary-bg area-title">
          <div className="d-flex gap-5">
            Type <div className="required">*</div>
          </div>
        </div>
        <div className="p-x-24">
          <div className="info-div info body-text">
            <IconByName name="error" />
            <div>
              {`Team Member's Can Only See Shifts You Publish. You Can Send Employees A List Of
                  Their Published Shifts Via Email And/Or Smartphone Apps Or Choose Not To Notify
                  Them`}
            </div>
          </div>
        </div>
        <List className="publish-checks-lists">
          {publishData?.map((value) => {
            const labelId = `checkbox-list-label-${value?.id}`;

            return (
              <ListItem key={value?.id} selected={publishType === value?.value} disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={() => handlePublishToggle(value?.value)}
                  dense
                  disableRipple
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={publishType === value?.value}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                      icon={<IconByName name="check_circle_outline" />}
                      checkedIcon={<IconByName name="check_circle" className="primary-color" />}
                    />
                  </ListItemIcon>
                  <ListItemText id={value?.id} className="publish-checks-view">
                    <span>{value?.title}</span>
                    <span>{value?.subTitle}</span>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        <div className="title-with-primary-bg area-title">
          <div className="d-flex gap-5">
            Notify staff <div className="required">*</div>
          </div>
        </div>
        <List className="publish-checks-lists">
          {notifyListData?.map((value) => {
            const labelId = `checkbox-list-label-${value?.id}`;

            return (
              <ListItem key={value?.id} selected={notifyType === value?.id} disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={() => handleNotifyToggle(value?.id)}
                  dense
                  disableRipple
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={notifyType === value?.id}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                      icon={<IconByName name="check_circle_outline" />}
                      checkedIcon={<IconByName name="check_circle" className="primary-color" />}
                    />
                  </ListItemIcon>
                  <ListItemText id={value?.id} className="publish-checks-view">
                    <span>{value?.title}</span>
                    <span>{value?.subTitle}</span>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        {error && <FormHelperText className="error-helper p-x-24">{error}</FormHelperText>}
      </div>
    </EzyDrawer>
  );
}

export default PublishShiftDrawer;

PublishShiftDrawer.propTypes = {
  reFetchEvents: PropTypes.func.isRequired,
};
