import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetLeaveRuleDetails,
  updateLeaveRuleData,
  updateLeaveRuleDetails,
} from '../redux/businessSlice';
import EzyDrawer from '../../../components/common/drawer/Drawer';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import EzyAutoComplete from '../../../components/common/autoComplete/EzyAutoComplete';
import EzySwitch from '../../../components/common/switch/EzySwitch';
import validateLeaveRule from '../validations/validateLeaveRule';
import {
  addEditLeaveRulesAction,
  fetchBusinessLeaveRulesAction,
  getBusinessLeaveRuleDetailsAction,
} from '../redux/businessAction';
import Loader from '../../../components/common/loader/Loader';

function LeaveRulesDrawer() {
  const dispatch = useDispatch();

  const { leaveRulesDrawer, leaveRuleDetails, isEditLeaveRules } = useSelector(
    ({ business }) => business?.leaveRuleData ?? {}
  );

  const { addEditLeaveRulesActionLoader, getBusinessLeaveRuleDetailsActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );
  const { enums } = useSelector(({ common }) => common ?? {});

  const handleClose = () => {
    dispatch(updateLeaveRuleData({ fieldName: 'leaveRulesDrawer', value: false }));
    dispatch(resetLeaveRuleDetails());
  };
  const onChangeInputField = (fieldName, value) => {
    dispatch(updateLeaveRuleDetails({ fieldName, value }));
  };

  const handleSubmit = () => {
    const data = {
      id: leaveRuleDetails?.id,
      name: leaveRuleDetails?.name,
      isPaid: leaveRuleDetails?.isPaid,
      isDefault: leaveRuleDetails?.isDefault,
      isVisibleToTeamMember: leaveRuleDetails?.isVisibleToTeamMember,
      leaveType: leaveRuleDetails?.leaveType?.id,
      resetLeaveAccrual: leaveRuleDetails?.resetLeaveAccrual?.id,
      trackLeaveIn: leaveRuleDetails?.trackLeaveIn?.id,
    };

    const errors = validateLeaveRule(data);

    onChangeInputField('errors', errors);
    if (isEmpty(errors))
      dispatch(
        addEditLeaveRulesAction(data, () => {
          dispatch(
            fetchBusinessLeaveRulesAction({
              page: 1,
              limit: 15,
            })
          );
          handleClose();
        })
      );
  };

  const actionButton = [
    {
      label: 'Save',
      onClick: handleSubmit,
      loading: addEditLeaveRulesActionLoader,
    },
  ];

  useEffect(() => {
    if (leaveRuleDetails?.id)
      dispatch(getBusinessLeaveRuleDetailsAction({ leaveRuleId: leaveRuleDetails?.id }));
  }, [isEditLeaveRules]);

  return (
    <EzyDrawer
      onClose={handleClose}
      isOpen={leaveRulesDrawer}
      actionButtons={actionButton}
      heading={isEditLeaveRules ? 'Edit Leave Rules' : 'Add Leave Rules'}
    >
      <div className="leave-request-drawer">
        {getBusinessLeaveRuleDetailsActionLoader ? (
          <Loader />
        ) : (
          <div className="leave-request-field-grid">
            <EzyInputField
              name="name"
              label="Name"
              placeholder="Enter name"
              value={leaveRuleDetails?.name}
              onChange={(e) => onChangeInputField('name', e?.target?.value)}
              error={leaveRuleDetails?.errors?.name}
              required
            />
            <EzyAutoComplete
              name="leaveType"
              label="Type"
              placeholder="Select type"
              options={enums?.leaveType}
              value={leaveRuleDetails?.leaveType}
              onChange={(_, value) => onChangeInputField('leaveType', value)}
              error={leaveRuleDetails?.errors?.leaveType}
              required
            />
            <div className="two-grid">
              <EzySwitch
                label="Paid leave"
                checked={leaveRuleDetails?.isPaid}
                onChange={(e) => onChangeInputField('isPaid', e?.target?.checked)}
              />
              <EzySwitch
                label="Visible to team member"
                checked={leaveRuleDetails?.isVisibleToTeamMember}
                onChange={(e) => onChangeInputField('isVisibleToTeamMember', e?.target?.checked)}
              />
            </div>
            <div className="two-grid">
              <EzySwitch
                label="Is default leave"
                checked={leaveRuleDetails?.isDefault}
                onChange={(e) => onChangeInputField('isDefault', e?.target?.checked)}
              />
            </div>
            <EzyAutoComplete
              name="trackLeaveIn"
              label="Track"
              placeholder="Select track"
              options={enums?.trackLeaveIn}
              value={leaveRuleDetails?.trackLeaveIn}
              onChange={(_, value) => onChangeInputField('trackLeaveIn', value)}
              error={leaveRuleDetails?.errors?.trackLeaveIn}
              required
            />
            <EzyAutoComplete
              name="resetLeaveAccrual"
              label="Reset leave accrual"
              placeholder="Select leave accrual"
              options={enums?.resetLeaveAccrual}
              value={leaveRuleDetails?.resetLeaveAccrual}
              onChange={(_, value) => onChangeInputField('resetLeaveAccrual', value)}
              error={leaveRuleDetails?.errors?.resetLeaveAccrual}
              required
            />
          </div>
        )}
      </div>
    </EzyDrawer>
  );
}

export default LeaveRulesDrawer;
