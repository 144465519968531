export const saveAuthTokenToLocalStorage = (authToken) => {
  localStorage.setItem('ezyAuthToken', authToken);
};

export const getAuthTokenFromLocalStorage = () => {
  return localStorage.getItem('ezyAuthToken');
};

export const clearAuthTokenFromLocalStorage = () => {
  localStorage.removeItem('ezyAuthToken');
};
export const saveFCMTokenToLocalStorage = (authToken) => {
  localStorage.setItem('ezyFCMToken', authToken);
};

export const getFCMTokenFromLocalStorage = () => {
  return localStorage.getItem('ezyFCMToken');
};

export const clearFCMTokenFromLocalStorage = () => {
  localStorage.removeItem('ezyFCMToken');
};
