import { Chip } from '@mui/material';
import { displayDate, displayDateTime, displayTime } from '../../utils/timeHelper';
import EzyIconTooltip from '../../components/common/tooltip/EzyIconTooltip';

export const timeSheetsHeaders = [
  {
    name: 'id',
    label: 'Id',
    hidden: true,
  },
  {
    name: 'date',
    label: 'Date',
    format: (value, rowData) => displayDate(value, rowData?.timeZoneId?.label),
  },
  {
    name: 'areaName',
    label: 'Area',
    hidden: false,
  },
  {
    name: 'timeSheetStatus',
    label: 'Status',
    hidden: false,
    format: (value) => {
      return <Chip variant="outlined" className={value} label={value} />;
    },
  },
  {
    name: 'timeSheetProgressStaus',
    label: 'Progress',
    hidden: false,
  },
  {
    name: 'time',
    label: 'Time',
    format: (_, rowData) =>
      `${displayTime(rowData?.startTime, rowData?.timeZoneId?.label)} - ${displayTime(
        rowData?.endTime,
        rowData?.timeZoneId?.label
      )}`,
  },
  {
    name: 'hours',
    label: 'Hours',
    hidden: false,
  },
  {
    name: 'automaticallyApproved',
    label: null,
    format: (_, rowData) => {
      if (rowData) {
        return (
          <div className="d-flex gap-5">
            {rowData?.isTimeSheetAutoRounded ? (
              <EzyIconTooltip
                iconName="trending_down"
                className="primary-color"
                tooltip="Auto Rounded"
              />
            ) : (
              ''
            )}
            {rowData?.isTimeSheetAutoApproved ? (
              <EzyIconTooltip
                iconName="verified"
                className="primary-color"
                tooltip="Auto Time Approved"
              />
            ) : (
              ' '
            )}
          </div>
        );
      }

      return ' ';
    },
    hidden: false,
  },
];

export const timesheetActivityHeaders = [
  {
    name: 'id',
    label: 'Id',
    hidden: true,
  },
  {
    name: 'user',
    label: 'User',
  },
  {
    name: 'field',
    label: 'Field',
  },
  {
    name: 'oldValue',
    label: 'Old value',
    format: (value, row, timeZone) => {
      if (
        (row?.field === 'Break Details' || row?.field === 'Auto-rounded break details') &&
        row?.oldBreakValue
      ) {
        return (
          <div className="d-flex-column ">
            {row?.oldBreakValue?.breakDetails?.length > 0 &&
              row?.oldBreakValue?.breakDetails?.map((breakDetails, i) => {
                return (
                  <>
                    <div>
                      Break state :<span> {row?.oldBreakValue?.breakState?.label || '-'}</span>
                    </div>
                    <div>Break type : {breakDetails?.breakType?.label}</div>
                    <div
                      className="text-overflow-ellipsis"
                      title={`Break duration : ${breakDetails?.duration} mins |
                      ${displayTime(breakDetails?.startTime, timeZone)} - ${displayTime(
                        breakDetails?.endTime,
                        timeZone
                      )}`}
                    >
                      Break duration : {breakDetails?.duration} mins{' '}
                      {(breakDetails?.startTime || breakDetails?.endTime) && (
                        <span>
                          &nbsp;| &nbsp;
                          {displayTime(breakDetails?.startTime, timeZone)}
                          &nbsp;-&nbsp;
                          {displayTime(breakDetails?.endTime, timeZone)}
                        </span>
                      )}
                    </div>
                    {i + 1 !== row?.newBreakValue?.breakDetails?.length && (
                      <div className="p-10"> </div>
                    )}
                  </>
                );
              })}
          </div>
        );
      }
      if (row?.field === 'Pay rates') {
        return value?.length > 1 ? (
          <div className="d-flex-column">
            {value?.map((e) => (
              <div>{e}</div>
            ))}
          </div>
        ) : (
          value
        );
      }
      if (row?.oldDateTimeValue) {
        return displayDateTime(row?.oldDateTimeValue, timeZone);
      }
      if (row?.field === 'Photo' && value?.[0]) {
        return <img src={value?.[0]} alt="" />;
      }
      if (value?.length) {
        return value;
      }
      return '-';
    },
  },
  {
    name: 'newValue',
    label: 'New value',
    format: (value, row, timeZone) => {
      if (
        (row?.field === 'Break Details' || row?.field === 'Auto-rounded break details') &&
        row?.newBreakValue
      ) {
        return (
          <div className="d-flex-column">
            {row?.newBreakValue?.breakDetails?.length > 0 &&
              row?.newBreakValue?.breakDetails?.map((breakDetails, i) => {
                return (
                  <>
                    <div>
                      Break state :<span> {row?.newBreakValue?.breakState?.label || '-'}</span>
                    </div>
                    <div>Break type : {breakDetails?.breakType?.label}</div>
                    <div
                      className="text-overflow-ellipsis"
                      title={`Break duration : ${breakDetails?.duration} mins |
                      ${displayTime(breakDetails?.startTime, timeZone)} - ${displayTime(
                        breakDetails?.endTime,
                        timeZone
                      )}`}
                    >
                      Break duration : {breakDetails?.duration} mins{' '}
                      {(breakDetails?.startTime || breakDetails?.endTime) && (
                        <span>
                          &nbsp;| &nbsp;
                          {displayTime(breakDetails?.startTime, timeZone)}
                          &nbsp;-&nbsp;
                          {displayTime(breakDetails?.endTime, timeZone)}
                        </span>
                      )}
                    </div>
                    {i + 1 !== row?.newBreakValue?.breakDetails?.length && (
                      <div className="p-10"> </div>
                    )}
                  </>
                );
              })}
          </div>
        );
      }
      if (row?.newDateTimeValue) {
        return displayDateTime(row?.newDateTimeValue, timeZone);
      }
      if (row?.field === 'Photo' && value?.[0]) {
        return <img src={value?.[0]} alt="" />;
      }
      if (value?.length) {
        return value;
      }
      return '-';
    },
  },
  {
    name: 'date',
    label: 'Date',
    format: (value, row, timeZone) => displayDateTime(value, timeZone),
    align: 'right',
  },
];
