import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import EzyDialog from '../../../../components/common/dialog/EzyDialog';
import EzyDialogTitle from '../../../../components/common/dialog/EzyDialogTitle';
import EzyDialogAction from '../../../../components/common/dialog/EzyDialogAction';
import { updateBillingDetails } from '../../redux/globalProfileSlice';
import { createCheckOutSessionAction } from '../../redux/globalProfileActions';
import { displayErrors } from '../../../../utils/errorNotifyHelper';

function AddonUserOrderConfirmation() {
  const { paramId } = useParams();

  const { billingDetails, billingAndPaymentDetails } = useSelector(
    ({ globalProfile }) => globalProfile || {}
  );

  const { createCheckOutSessionActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const {
    isAddOnUserModal,
    prePaidUser = 0,
    planId,
  } = useMemo(() => billingDetails ?? {}, [billingDetails]);
  const { numberOfUser = 0 } = useMemo(
    () => billingAndPaymentDetails ?? {},
    [billingAndPaymentDetails]
  );

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(
      updateBillingDetails({
        fieldName: 'isAddOnUserModal',
        value: false,
      })
    );
  };

  const handleCheckout = async () => {
    try {
      const sessionData = {
        planId,
        planDuration: 2,
        quantity: numberOfUser,
        isAdditionalUser: true,
        businessId: paramId,
      };
      await dispatch(
        createCheckOutSessionAction(sessionData, (session) => {
          if (session) window.location.href = session;
        })
      );
    } catch (e) {
      displayErrors(e);
    }
  };

  const actionButtons = [
    {
      label: 'Cancel',
      color: 'error',
      onClick: handleClose,
    },
    {
      label: 'Purchase',
      onClick: handleCheckout,
      loading: createCheckOutSessionActionLoader,
    },
  ];

  return (
    <EzyDialog
      handleClose={handleClose}
      open={isAddOnUserModal}
      className="common-dialog-with-body addon-confirm-dialog"
    >
      <EzyDialogTitle title="Review Your Order" onClose={handleClose} />
      <div className="dialog-body">
        <div className="primary-bg d-flex flex-align-center just-bet">
          <span>Existing licences on this plan</span>
          <span>{Number(prePaidUser)} Users</span>
        </div>
        <div className="primary-bg d-flex flex-align-center just-bet">
          <span>{`Additional license I'd like to purchase now`}</span>
          <span>{Number(numberOfUser)} Users</span>
        </div>
        <div className="primary-bg d-flex flex-align-center just-bet">
          <span>Total licences after purchase will be</span>
          <span>{Number(prePaidUser) + Number(numberOfUser)} Users</span>
        </div>
      </div>
      <EzyDialogAction actionButtons={actionButtons} />
    </EzyDialog>
  );
}

export default AddonUserOrderConfirmation;
