import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import EzyButton from '../../../components/common/button/EzyButton';
import IconByName from '../../../components/common/iconByName/IconByName';
import { importScheduledShift } from '../redux/timeSheetsAction';
import { displayDate, displayTime } from '../../../utils/timeHelper';

function ImportScheduledDetails() {
  const {
    attachedShift: viewTimeSheetData,
    editTimeSheetDrawer,
    timeZoneId,
  } = useSelector(({ timeSheets }) => timeSheets?.timeSheetData);

  const isTabletScreen = useMediaQuery('(max-width : 1024px)');

  const dispatch = useDispatch();
  const getBreakDataString = (data, breakType) => {
    const output = data?.[`${breakType}`]?.map((e) => {
      if ((e?.duration || e?.duration >= 0) && e?.breakStartTime && e?.breakEndTime) {
        return `${displayTime(e?.breakStartTime)} - ${displayTime(e?.breakEndTime)} (${
          e?.duration
        } mins)`;
      }
      return `(${e?.duration} mins)`;
    });
    if (output?.length > 0) {
      return output?.join(', ');
    }
    return '(0 mins)';
  };

  const breakSummaryString = () => {
    if (isTabletScreen) {
      return (
        <div className="d-flex-column">
          <div>Meal break : {viewTimeSheetData?.totalMealBreak || '0'} mins</div>
          <div>Rest break : {viewTimeSheetData?.totalRestBreak || '0'} mins</div>
        </div>
      );
    }
    return `Meal break : ${viewTimeSheetData?.totalMealBreak || '0'} mins 
    Rest break : ${viewTimeSheetData?.totalRestBreak || '0'} mins`;
  };
  const viewData = useMemo(() => {
    const output = viewTimeSheetData?.shiftBreakDetails?.reduce((obj, el) => {
      const property = el?.breakType;
      if (!obj?.[property]) {
        obj[property] = [];
      }
      obj[property].push(el);
      return obj;
    }, {});

    return [
      {
        title: 'Date',
        value: displayDate(viewTimeSheetData?.date, timeZoneId?.label),
      },
      {
        title: 'Area',
        value: viewTimeSheetData?.areaName || '-',
      },
      {
        title: 'Time',
        value: `${displayTime(viewTimeSheetData?.startTime, timeZoneId?.label)} - ${displayTime(
          viewTimeSheetData?.endTime,
          timeZoneId?.label
        )}`,
      },
      {
        title: 'Break summary',
        value: breakSummaryString(),
      },
      {
        title: 'Meal break',
        value: getBreakDataString(output, 1),
      },
      {
        title: 'Rest break',
        value: getBreakDataString(output, 2),
      },
      {
        title: 'Pay rates',
        value: viewTimeSheetData?.payRates || '-',
      },
    ];
  }, [viewTimeSheetData]);
  return (
    <div className="schedule-details-wrapper">
      <div className="d-flex-column gap-5">
        {viewData?.map(({ title, value }, index) => (
          <div className="data-wrapper" key={index}>
            <div className="title">{title} :&nbsp;</div>
            <div className="value">{value || ' - '}</div>
          </div>
        ))}
      </div>
      <EzyButton
        label="Import"
        startIcon={<IconByName name="download" />}
        onClick={() => {
          dispatch(
            importScheduledShift({
              id: editTimeSheetDrawer,
              ...viewTimeSheetData,
              breakSummary: viewTimeSheetData?.shiftBreakDetails,
              shiftBreakDetails: null,
              timeZoneId,
            })
          );
        }}
      />
    </div>
  );
}

export default ImportScheduledDetails;
