import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import IconByName from '../../../../components/common/iconByName/IconByName';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import Loader from '../../../../components/common/loader/Loader';
import useQueryParams from '../../../../hooks/useQueryParams';
import { displayDate, displayTime } from '../../../../utils/timeHelper';
import {
  getDashboardShiftsAction,
  getPossibleSwapShiftAction,
  sendSwapRequestAction,
} from '../../redux/dashboardActions';
import { updateSwapShiftDrawerDetails } from '../../redux/dashboardSlice';
import CurrentShiftDetailsAccordion from './CurrentShiftDetailsAccordian';

function SwapShiftDrawer() {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const { swapShiftDrawerDetails } = useSelector(({ dashboard }) => dashboard?.calenderData ?? {});
  const { personId, businessId, timeZone } = useSelector(({ user }) => user?.profileData ?? {});

  const { date: dateParam } = useQueryParams();

  const { currentShift, possibleShifts, note } = useMemo(
    () => swapShiftDrawerDetails,
    [swapShiftDrawerDetails]
  );

  const {
    sendSwapRequestActionLoader,
    getPossibleSwapShiftActionLoader,
    getPossibleSwapShiftWithSearchTextActionLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});

  const handleClose = () => {
    dispatch(updateSwapShiftDrawerDetails({ fieldName: 'currentShift', value: {} }));
    dispatch(updateSwapShiftDrawerDetails({ fieldName: 'possibleShifts', value: {} }));
    dispatch(updateSwapShiftDrawerDetails({ fieldName: 'note', value: '' }));
    setChecked([]);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const fetchShifts = async (params) => {
    await dispatch(
      dispatch(getPossibleSwapShiftAction({ areaId: currentShift?.areaId, searchText, ...params }))
    );

    setChecked([]);
  };

  const handleSearch = async (e) => {
    if (e.target.value) setSearchText(e.target.value);
    else {
      setSearchText(null);
      await fetchShifts({ searchText: null });
    }
  };

  const handleSubmit = () => {
    const data = {
      shiftRequestId: currentShift?.shiftId,
      receiverShiftIds: checked,
      note,
    };
    if (checked?.length > 0) {
      dispatch(
        sendSwapRequestAction(data, () => {
          handleClose();
          dispatch(
            getDashboardShiftsAction({
              personDetailId: personId,
              businessId,
              filterStartDate: moment
                .tz(dateParam, timeZone?.label ?? moment.tz?.guess())
                .startOf('week')
                ?.toISOString(),
              filterEndDate: moment
                .tz(dateParam, timeZone?.label ?? moment.tz?.guess())
                .endOf('week')
                ?.toISOString(),
            })
          );
        })
      );
    }
  };

  const actionButton = [
    {
      label: 'Request for swap shift',
      onClick: handleSubmit,
      disabled: checked?.length === 0,
      loading: sendSwapRequestActionLoader,
    },
  ];

  useEffect(() => {
    (async () => {
      if (currentShift?.areaId) await fetchShifts();
    })();
  }, [currentShift]);

  return (
    <EzyDrawer
      onClose={handleClose}
      isOpen={currentShift.shiftId}
      heading="Swap Shift"
      actionButtons={actionButton}
      isLoading={getPossibleSwapShiftActionLoader}
    >
      <div className="shift-drawer">
        {getPossibleSwapShiftWithSearchTextActionLoader && <Loader />}
        <CurrentShiftDetailsAccordion
          shift={currentShift}
          updateNoteAction={updateSwapShiftDrawerDetails}
          note={note}
        />

        <div className="save-template-fields-grid">
          <EzyInputField
            label="Search"
            placeholder="Search by people"
            className="search-box"
            value={searchText}
            onChange={handleSearch}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                await fetchShifts();
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconByName name="search" />
              </InputAdornment>
            }
          />
        </div>
        <FormControlLabel
          label="Select All"
          className="just-right"
          control={
            <Checkbox
              edge="start"
              checked={possibleShifts?.length === checked?.length}
              tabIndex={-1}
              onChange={(e) => {
                if (e.target.checked) {
                  setChecked(possibleShifts?.map((val) => val?.shiftId));
                } else {
                  setChecked([]);
                }
              }}
              disableRipple
              inputProps={{ 'aria-labelledby': 'select All' }}
              icon={<IconByName name="check_circle_outline" />}
              checkedIcon={<IconByName name="check_circle" className="primary-color" />}
            />
          }
        />

        {possibleShifts?.length > 0 ? (
          <div className="save-template-fields-grid">
            <List className="load-template-lists">
              {possibleShifts?.map(
                ({
                  shiftId,
                  startTime,
                  endTime,
                  shiftBreakDetailsList,
                  areaName,
                  locationName,
                  personDetails,
                }) => {
                  const labelId = `checkbox-list-label-${shiftId}`;
                  const totalBreakDuration = shiftBreakDetailsList?.length
                    ? shiftBreakDetailsList
                        ?.map((prev) => prev.duration)
                        .reduce((prev, next) => prev + next)
                    : 0;

                  return (
                    <ListItem
                      key={shiftId}
                      disablePadding
                      selected={checked?.indexOf(shiftId) !== -1}
                    >
                      <ListItemButton dense onClick={handleToggle(shiftId)} disableRipple>
                        <div className="d-flex-column gap-10 w-full">
                          <div className="d-flex flex-align-center just-bet gap-10">
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={checked?.indexOf(shiftId) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                                icon={<IconByName name="check_circle_outline" />}
                                checkedIcon={
                                  <IconByName name="check_circle" className="primary-color" />
                                }
                              />
                            </ListItemIcon>
                            <ListItemText>
                              <span className="subtitle-text">
                                {personDetails?.preferredFullName}
                              </span>
                            </ListItemText>
                          </div>

                          <div className="d-flex-column gap-5 template-sub-details">
                            <div className="d-flex flex-align-center text-secondary-color gap-10">
                              <IconByName name="event" />
                              <span>{displayDate(startTime)}</span>
                            </div>

                            <div className="d-flex flex-align-center just-bet gap-10">
                              <div className="d-flex flex-align-center gap-10 text-secondary-color">
                                <IconByName name="access_time_filled" />

                                <span>
                                  {displayTime(startTime)} - {displayTime(endTime)}
                                </span>
                              </div>

                              <div className="d-flex flex-align-center gap-10 text-secondary-color">
                                <IconByName name="emoji_food_beverage" />
                                <span>{totalBreakDuration} mins</span>
                              </div>
                            </div>
                            <div className="d-flex flex-align-center text-secondary-color gap-10">
                              <IconByName name="location_on" />
                              <span>
                                {areaName} - {locationName}
                              </span>
                            </div>
                          </div>
                        </div>
                      </ListItemButton>
                    </ListItem>
                  );
                }
              )}
            </List>
          </div>
        ) : (
          <div className="full-relative-wrapper">
            <div className="no-data-found">No shift available for swap.</div>
          </div>
        )}
      </div>
    </EzyDrawer>
  );
}

export default SwapShiftDrawer;
