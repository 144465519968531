import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InputAdornment } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import NewsFeedSidebar from '../components/NewsFeedSidebar';
import NewsPost from '../components/NewsPost';
import IconByName from '../../../components/common/iconByName/IconByName';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import EzyButton from '../../../components/common/button/EzyButton';
import { getNewsFeedListAction } from '../redux/newsFeedActions';
import Loader from '../../../components/common/loader/Loader';
import { updateNewsFeedListDetails, updateNewsFeedDrawer } from '../redux/newsFeedSlice';
import AddNewsFeedDrawer from '../components/AddNewsFeedDrawer';
import NewsFeedConfirmationDrawer from '../components/NewsFeedConfirmationDrawer';

function NewsFeedList() {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState(null);
  const { newsFeedList } = useSelector(({ newsFeed }) => newsFeed ?? {});

  const { docs, page, hasMoreRecords, limit, selectedLocation, postType } = useMemo(
    () => newsFeedList,
    [newsFeedList]
  );

  const { getNewsFeedListActionLoader } = useSelector(({ common }) => common.generalLoader ?? {});

  const getNewsFeedListByFilter = (params) => {
    dispatch(
      getNewsFeedListAction({
        page: 1,
        limit: 7,
        locationId: selectedLocation,
        isYourPost: postType === 'Your posts',
        isImportantPost: postType === 'Important posts',
        searchText,
        ...params,
      })
    );
  };

  const handleSearch = async (e) => {
    if (e.target.value) setSearchText(e.target.value);
    else {
      setSearchText(null);
      await getNewsFeedListByFilter({ searchText: null });
    }
  };

  const handleScroll = useCallback(
    (e) => {
      if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight && hasMoreRecords) {
        // setIsFetching(true);
        dispatch(updateNewsFeedListDetails({ fieldName: 'page', value: page + 1 }));
        getNewsFeedListByFilter({ page: page + 1, limit });
      }
    },

    [docs?.length, hasMoreRecords]
  );

  useEffect(() => {
    getNewsFeedListByFilter({ page, limit });
  }, []);

  return (
    <div className="newsFeed-root-container">
      <NewsFeedSidebar getNewsFeedList={getNewsFeedListByFilter} />

      <div className="newsFeed-list-wrapper">
        {getNewsFeedListActionLoader && <Loader />}
        <div className="newsFeed-list-header">
          <EzyInputField
            placeholder="Search "
            className="search-box"
            value={searchText}
            onChange={handleSearch}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                await getNewsFeedListByFilter();
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconByName name="search" />
              </InputAdornment>
            }
          />

          <EzyButton
            startIcon={<IconByName name="add" />}
            label="Add"
            className="no-pad-button"
            access="CREATENWF"
            onClick={() => {
              dispatch(updateNewsFeedDrawer({ fieldName: 'isOpenNewsFeedDrawer', value: true }));
            }}
          />
        </div>

        {docs?.length > 0 ? (
          <div className="news-post-grid" onScroll={handleScroll}>
            {docs.map((newsPost) => (
              <NewsPost data={newsPost} />
            ))}
          </div>
        ) : (
          <div className="full-relative-wrapper">
            {!getNewsFeedListActionLoader && (
              <div className="no-data-found no-data-found-container">No post found.</div>
            )}
          </div>
        )}
      </div>
      <AddNewsFeedDrawer />
      <NewsFeedConfirmationDrawer />
    </div>
  );
}

export default NewsFeedList;
