import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { FormControl, FormHelperText, InputLabel, TextField } from '@mui/material';
import { useState } from 'react';
import EzyInputField from '../inputField/EzyInputField';
import { DATE_TIME_FORMAT } from '../../../utils/constants';

function EzyTime({
  label,
  error,
  className,
  value,
  onChange,
  timeZone = moment.tz.guess(),
  ...restProps
}) {
  const [open, setOpen] = useState(false);

  const handleOnChange = (e) => {
    const dateString = moment(e).format(DATE_TIME_FORMAT);
    const parseMoment = moment.tz(dateString, DATE_TIME_FORMAT, timeZone).format();
    onChange(moment(new Date(parseMoment)).toISOString(), e);
  };

  return (
    <FormControl
      variant="standard"
      className={`date-picker ${className} ${restProps.disabled ? 'disabled' : ''}`}
    >
      {label && (
        <InputLabel shrink htmlFor="bootstrap-input">
          {label}
          {restProps?.required && <div className="required">*</div>}
        </InputLabel>
      )}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <TimePicker
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          variant="inline"
          format="hh:mm a"
          value={moment.tz(value, timeZone)}
          onChange={(e) => handleOnChange(e)}
          slotProps={{
            textField: {
              placeholder: restProps?.placeholder || 'Select time',
              value: value ? moment.tz(value, timeZone) : null,
            },
          }}
          timezone={timeZone}
          defaultValue={null}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: restProps?.placeholder || 'Select time',
              }}
              notched={false}
              className={error ? 'field-error' : ''}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              disabled={restProps.disabled}
              onClick={() => setOpen(true)}
              renderInput={(params2) => (
                <EzyInputField
                  ref={params2.InputProps.ref}
                  inputProps={params2.inputProps}
                  disabled={restProps.disabled}
                />
              )}
            />
          )}
          {...restProps}
        />
      </LocalizationProvider>
      {error && <FormHelperText className="error-helper">{error}</FormHelperText>}
    </FormControl>
  );
}

export default EzyTime;

EzyTime.propTypes = {
  label: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.string,
  timeZone: PropTypes.string.isRequired,
};
EzyTime.defaultProps = {
  label: null,
  error: false,
  onChange: () => {},
  className: '',
  value: '',
};
