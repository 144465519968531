import { createSlice, current } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import {
  headers,
  leaveBalanceListHeaders,
  leaveRequestListHeaders,
  previousShiftHeaders,
  profileTimeSheetHeaders,
  upComingShiftHeaders,
} from '../PeopleHeaders';
import { formatPhoneWithCode } from '../../../utils/phoneNumberHelper';
import { mapIdsFromList } from '../../../utils/enumMapping';
import { getDurationInMinutes, getHoursAndMinutesFromHours } from '../../../utils/timeHelper';

const peopleSlice = createSlice({
  name: 'people',
  initialState: {
    peopleList: { docs: [], headers, total: 1, limit: 15, page: 1, pages: 1 },
    addPeople: {
      location: '',
      people: [
        {
          name: '',
          email: '',
          phoneNumber: null,
          countryCode: null,
          accessLevel: { id: 5, name: 'Employee' },
        },
      ],
      importedPeople: [],
      addPeopleLoading: false,
      sendInvitationEmail: false,
      warningMessage: '',
    },
    editPeople: {
      locationsList: [],
      details: {
        personDetailId: '',
        roleId: '',
        primaryLocationId: '',
        workLocations: [],
        joiningDate: '',
        payRateType: '',
        payRollId: '',
        employmentType: '',
        workPeriodDetail: '',
        profilePhotoURL: '',
        preferredFullName: '',
        dateOfBirth: '',
        pronoun: '',
        address: '',
        postCode: '',
        city: '',
        state: '',
        countryId: '',
        countyCode: '',
        mobile: '',
        email: '',
        emergencyContactName: '',
        emergencyContactNumber: '',
        emergencyCountryCode: '',
        shiftData: {
          isEditShiftDrawerEnable: false,
          isSelectAreaDrawerEnable: false,
          isSelectBreakDrawerEnable: false,
          profilePhotoUrl: null,
          preferdFullName: '',
          shiftId: '',
          isLateForShift: null,
          areaId: null,
          areaName: null,
          shiftStartTime: null,
          shiftEndTime: null,
          breakDetails: null,
          isShiftScheduled: '',
          isShiftStarted: '',
          totalWorkedTime: 0,
          totalBreakTime: 0,
          isBreakStartted: '',
          timeSheetId: null,
          selectedShiftArea: '',
          endShiftDetails: {
            allowTimeSheet: false,
            note: '',
          },
        },
      },
      payDetails: {
        hourlyPayDetails: [
          {
            areaIds: [],
            weekDayRate: '',
            saturdayRate: '',
            sundayRate: '',
            publicHolidayRate: '',
          },
        ],
        salaryPayDetail: {},
        libraryRateDetailEntity: [
          {
            awardCode: '',
            areaIds: [],
            baseRate: 0,
          },
        ],
        awardsList: [],
      },
      areaListByPerson: [],
    },
    leaveDetails: {
      leaveRequestDrawer: false,
      viewLeaveRequestDrawer: false,
      leaveEntitlementDrawer: false,
      isEditLeaveEntitlement: false,
      isEditLeaveRequest: false,
      leaveRequestId: null,
      leaveEnums: {
        leaveEntitlementEnumByPersonId: [],
        leaveEntitlementEnumByBusinessId: [],
      },
      leaveBalance: {
        docs: [],
        headers: leaveBalanceListHeaders,
        total: 1,
        limit: 15,
        page: 1,
        pages: 1,
      },
      leaveRequest: {
        docs: [],
        headers: leaveRequestListHeaders,
        total: 1,
        limit: 15,
        page: 1,
        pages: 1,
      },
      addLeaveDetails: {
        isAllDay: false,
        fromDate: '',
        fromTime: '',
        toDate: '',
        toTime: '',
        shiftType: null,
        leaveType: null,
        reason: '',
        managerComment: '',
      },
      leaveEntitlementDetail: {
        leave: null,
        balance: '',
      },
      extraLeaveDetails: {
        name: '',
        shifts: [],
        personOnLeave: [],
      },
    },
    shiftDetails: {
      upComingShifts: {
        docs: [],
        headers: upComingShiftHeaders,
        total: 1,
        limit: 15,
        page: 1,
        pages: 1,
      },
      previousShifts: {
        docs: [],
        headers: previousShiftHeaders,
        profileHeaders: profileTimeSheetHeaders,
        total: 1,
        limit: 15,
        page: 1,
        pages: 1,
      },
    },
    journalDetails: {
      addJournalDrawer: {
        isOpen: false,
        ratePerformance: null,
        journalEntry: '',
      },
      journalList: {
        docs: [],
        total: 1,
        limit: 10,
        page: 1,
        pages: 1,
        hasMoreRecords: false,
      },
    },
    unavailabilityDetails: {
      unavailability: {
        docs: [],
        total: 1,
        limit: 15,
        page: 1,
        pages: 1,
        hasMoreRecords: false,
      },
      addUnavailabilityDetails: {
        isDrawerOpen: false,
        isAllDay: false,
        fromDate: '',
        fromTime: '',
        toDate: '',
        toTime: '',
        isRepeat: false,
        repeatitionFrequency: '',
        repeatWeekWise: [],
        repeatMontly: '',
        endOn: '',
        endOnDate: '',
        note: null,
      },
    },
  },
  reducers: {
    setPeopleList: (state, action) => {
      state.peopleList = {
        ...state.peopleList,
        docs: action.payload.data,
        total: action.payload.totalData,
        limit: action.payload.limit,
        page: action.payload.page,
        pages: action.payload.totalPages,
      };
    },
    setPeopleListFiltersByBusiness: (state, action) => {
      state.peopleListFiltersByBusiness = action.payload;
    },
    updateAddPeopleFields: (state, action) => {
      const { fieldName, value } = action.payload;
      state.addPeople[fieldName] = value;
    },
    addUpdatePeopleFields: (state, action) => {
      const { type, index } = action.payload;
      if (type === 'ADD') {
        state.addPeople.people.push({
          name: '',
          email: '',
          phoneNumber: null,
          accessLevel: { id: 5, name: 'Employee' },
        });
      }
      if (type === 'DELETE') {
        state.addPeople.people = state.addPeople.people.filter((e, i) => i !== index);
      }
    },
    importPeopleFromIntegrationFrPeopleModule: (state, action) => {
      state.addPeople.people = action.payload.map((e) => {
        return {
          accessLevel: { id: 5, name: 'Employee' },
          email: e?.email,
          phoneNumber: e?.phone,
          name: e?.name,
          xeroEmployeeId: e?.id,
          myobEmployeeId: e?.myobEmployeeId,
          quickBookEmployeeId: e?.quickBookEmployeeId,
        };
      });
    },
    resetAddPeopleFields: (state) => {
      state.addPeople.people = [
        {
          name: '',
          email: '',
          phoneNumber: null,
          accessLevel: '',
        },
      ];
      state.addPeople.importedPeople = [];
      state.addPeople.location = '';
      state.addPeople.addPeopleLoading = false;
      state.addPeople.errors = {};
    },
    updatePeopleData: (state, action) => {
      const { index, fieldName, value } = action.payload;
      state.addPeople.people = state.addPeople.people.map((e, i) =>
        i === index ? { ...e, [fieldName]: value } : e
      );
    },
    setImportedPeopleData: (state, action) => {
      state.addPeople.importedPeople = action.payload;
      state.addPeople.totalNoOfRecords = action.payload.length;
      state.addPeople.successCount = action.payload.filter((e) => !e.errorMessage).length;
      state.addPeople.failedCount = action.payload.filter((e) => e.errorMessage).length;
      state.addPeople.failedRecords = action.payload.filter((e) => e.errorMessage);
      state.addPeople.uploaderIndex = 1;
    },
    resetImportPeopleData: (state) => {
      state.addPeople.importedPeople = [];
      state.addPeople.totalNoOfRecords = 0;
      state.addPeople.successCount = 0;
      state.addPeople.failedCount = 0;
      state.addPeople.failedRecords = [];
      state.addPeople.uploaderIndex = 0;
      state.addPeople.selectedUploader = '';
      state.addPeople.fileName = '';
    },
    editPeopleDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.editPeople.details[fieldName] = value;
    },
    updatePeoplePayDetailsFields: (state, action) => {
      const { fieldName, value, payTypeField, index } = action.payload;
      if (payTypeField) {
        if (payTypeField === 'hourlyPayDetails') {
          state.editPeople.payDetails.hourlyPayDetails[index][fieldName] = value;
        } else if (payTypeField === 'libraryRateDetailEntity') {
          state.editPeople.payDetails.libraryRateDetailEntity[index][fieldName] = value;
        } else {
          state.editPeople.payDetails[payTypeField][fieldName] = value;
        }
      } else {
        state.editPeople.payDetails[fieldName] = value;
      }
    },
    addDeletePeopleHourlyPayGrade: (state, action) => {
      const { type, index } = action.payload;
      if (type === 'ADD') {
        state.editPeople.payDetails.hourlyPayDetails.push({
          areaIds: [],
          weekDayRate: 0,
          saturdayRate: 0,
          sundayRate: 0,
          publicHolidayRate: 0,
        });
      }
      if (type === 'DELETE') {
        state.editPeople.payDetails.hourlyPayDetails =
          state.editPeople.payDetails.hourlyPayDetails.filter((e, i) => i !== index);
      }
    },
    addDeleteAreaLibraryRate: (state, action) => {
      const { type, index } = action.payload;
      if (type === 'ADD') {
        state.editPeople.payDetails.libraryRateDetailEntity.push({
          awardCode: '',
          areaIds: [],
          baseRate: 0,
        });
      }
      if (type === 'DELETE') {
        state.editPeople.payDetails.libraryRateDetailEntity =
          state.editPeople.payDetails.libraryRateDetailEntity.filter((e, i) => i !== index);
      }
    },
    setLocationsListForPeople: (state, action) => {
      state.editPeople.locationsList = action.payload;
    },
    setWorkPeriodsByBusinessId: (state, action) => {
      state.editPeople.workPeriodList = action.payload;
    },
    setPeopleDetails: (state, action) => {
      state.editPeople.details = action.payload;
      const primaryLocationId = mapIdsFromList(
        action?.payload?.primaryLocationId,
        current(state)?.editPeople?.locationsList
      );
      state.editPeople.details.primaryLocationId = primaryLocationId;
      const workLocations = mapIdsFromList(
        action?.payload?.workLocations,
        current(state)?.editPeople?.locationsList
      )?.map((e) => e?.label);
      state.editPeople.details.workLocations = [primaryLocationId?.label, ...workLocations];
      state.editPeople.details.workLocationsToDisabled = [
        primaryLocationId?.label,
        ...workLocations,
      ];
      state.editPeople.details.phoneNumber = formatPhoneWithCode(
        action.payload.phoneNumber,
        action.payload.countryCode
      );
      state.editPeople.details.emergencyContactNumber = formatPhoneWithCode(
        action.payload.emergencyContactNumber,
        action.payload.emergencyContactCountryCode
      );
      state.editPeople.details.workPeriodId = mapIdsFromList(
        action.payload?.workPeriodDetail?.workPeriodId,
        current(state)?.editPeople?.workPeriodList
      );
      const { hours, minutes } = getHoursAndMinutesFromHours(
        action.payload.workPeriodDetail?.totalHours
      );
      state.editPeople.details.hours = hours;
      state.editPeople.details.minutes = minutes;
    },
    setPayRateData: (state, action) => {
      state.editPeople.payDetails.payRateType = action.payload.payRateType;
      if (action.payload.payRateType.id === 1) {
        state.editPeople.payDetails.hourlyPayDetails = !isEmpty(action.payload.hourlyPayDetails)
          ? action.payload.hourlyPayDetails?.map((e) => ({
              ...e,
              areaIds: current(state)
                ?.editPeople?.areaListByPerson?.filter((area) => e.areaIds?.includes(area?.id))
                ?.map((area) => area?.label),
            }))
          : [
              {
                areaIds: [],
                weekDayRate: 0,
                saturdayRate: 0,
                sundayRate: 0,
                publicHolidayRate: 0,
              },
            ];
      } else if (action.payload.payRateType.id === 2) {
        state.editPeople.payDetails.salaryPayDetail = !isEmpty(action.payload.salaryPayDetail)
          ? action.payload.salaryPayDetail
          : {
              salaryPeriod: '',
              salaryAmount: 0,
            };
      } else {
        state.editPeople.payDetails.payRateType =
          action.payload.libraryRateDetailEntity?.[0]?.awardCode;
        state.editPeople.payDetails.libraryRateDetailEntity = !isEmpty(
          action.payload.libraryRateDetailEntity
        )
          ? action.payload.libraryRateDetailEntity?.map((e) => ({
              ...e,
              areaIds: current(state)
                ?.editPeople?.areaListByPerson?.filter((area) => e.areaIds?.includes(area?.id))
                ?.map((area) => area?.label),
            }))
          : [
              {
                awardCode: '',
                areaIds: [],
                baseRate: 0,
              },
            ];
      }
    },
    setAreaListByPerson: (state, action) => {
      state.editPeople.areaListByPerson = action.payload;
    },
    setLeaveBalanceList: (state, action) => {
      state.leaveDetails.leaveBalance = {
        ...state.leaveDetails.leaveBalance,
        docs: action.payload.data,
        total: action.payload.totalData,
        limit: action.payload.limit,
        page: action.payload.page,
        pages: action.payload.totalPages,
      };
    },
    setLeaveRequestList: (state, action) => {
      state.leaveDetails.leaveRequest = {
        ...state.leaveDetails.leaveRequest,
        docs: action.payload.data,
        total: action.payload.totalData,
        limit: action.payload.limit,
        page: action.payload.page,
        pages: action.payload.totalPages,
      };
    },
    setUpComingShiftsList: (state, action) => {
      state.shiftDetails.upComingShifts.docs = action.payload.data;
      state.shiftDetails.upComingShifts.total = action.payload.totalData;
      state.shiftDetails.upComingShifts.limit = action.payload.limit;
      state.shiftDetails.upComingShifts.page = action.payload.page;
      state.shiftDetails.upComingShifts.pages = action.payload.totalPages;
    },
    setPreviousShiftsList: (state, action) => {
      state.shiftDetails.previousShifts.docs = action.payload.data;
      state.shiftDetails.previousShifts.total = action.payload.totalData;
      state.shiftDetails.previousShifts.limit = action.payload.limit;
      state.shiftDetails.previousShifts.page = action.payload.page;
      state.shiftDetails.previousShifts.pages = action.payload.totalPages;
    },
    editLeaveDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.leaveDetails[fieldName] = value;
    },
    updateAddLeaveDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.leaveDetails.addLeaveDetails[fieldName] = value;
    },
    setLeaveEntitlementEnum: (state, action) => {
      const { enumFor, data } = action.payload;
      if (enumFor === 'leaveEntitlementEnumByBusinessId') {
        state.leaveDetails.leaveEnums.leaveEntitlementEnumByBusinessId = data || [];
      } else {
        state.leaveDetails.leaveEnums.leaveEntitlementEnumByPersonId =
          data?.leaveEntitlementList || [];
        state.leaveDetails.addLeaveDetails.whoCanApproveLeave = data?.whoCanApproveLeave;
      }
    },
    setPeopleForOfferShift: (state, action) => {
      state.shiftDetails.peopleForOfferShift = action.payload;
    },
    editShiftDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.shiftDetails[fieldName] = value;
    },
    updateLeaveEntitlementDetail: (state, action) => {
      const { fieldName, value } = action.payload;
      state.leaveDetails.leaveEntitlementDetail[fieldName] = value;
    },
    resetLeaveRequestDetails: (state) => {
      state.leaveDetails.addLeaveDetails = {};
      state.leaveDetails.isEditLeaveRequest = false;
      state.leaveDetails.leaveRequestId = null;
      state.leaveDetails.addLeaveDetails.leaveType = null;
      state.leaveDetails.addLeaveDetails.shiftType = null;
      state.leaveDetails.extraLeaveDetails = null;
      state.leaveDetails.leaveEnums.leaveEntitlementEnumByPersonId = [];
    },
    resetLeaveEntitlementDrawerDetails: (state) => {
      state.leaveDetails.leaveEntitlementDetail = {};
      state.leaveDetails.isEditLeaveEntitlement = false;
      state.leaveDetails.leaveBalanceId = null;
    },
    setExtraLeaveDetails: (state, action) => {
      state.leaveDetails.extraLeaveDetails = {
        ...state.leaveDetails.extraLeaveDetails,
        name: action.payload.name,
        shifts: action.payload.shifts,
        personOnLeave: action.payload.personOnLeave,
      };
      if (action.payload.shifts.length > 0) {
        state.leaveDetails.addLeaveDetails.shiftType = { id: 3, label: 'Keep it as it is' };
      }
    },
    setAddLeaveDetails: (state, action) => {
      state.leaveDetails.addLeaveDetails = {
        ...state.leaveDetails.addLeaveDetails,
        reason: action?.payload.reason,
        fromTime: action?.payload.fromDate,
        toTime: action?.payload.toDate,
        fromDate: action?.payload.fromDate,
        toDate: action?.payload.toDate,
        isAllDay: action?.payload.isAllDay,
        leaveType: {
          id: action.payload.businessLeaveRuleId,
          label: action.payload.businessLeaveRuleName,
        },

        createdBy: action?.payload.createdBy,
        modifiedBy: action?.payload.modifiedBy,
        createdDate: action?.payload.createdDate,
        modifiedDate: action?.payload.modifiedDate,
        leaveStatus: action?.payload.leaveStatus,
        managerComment: action?.payload?.managerComment,
        extraLeaveDetails: action?.payload?.leaveExtraDetailsEntity,
        actionButtons: {
          canApprove: action?.payload.canApprove,
          canDecline: action?.payload.canDecline,
          canUnApprove: action?.payload.canUnApprove,
          canUnArchive: action?.payload.canUnArchive,
          canCancel: action?.payload.canCancel,
        },
      };
    },
    resetEditPeopleDetails: (state) => {
      state.editPeople.locationsList = [];
      state.editPeople.details = {
        personDetailId: '',
        roleId: '',
        primaryLocationId: '',
        workLocations: [],
        joiningDate: '',
        payRateType: '',
        payRollId: '',
        employmentType: '',
        workPeriodDetail: '',
        profilePhotoURL: '',
        preferredFullName: '',
        dateOfBirth: '',
        pronoun: '',
        address: '',
        postCode: '',
        city: '',
        state: '',
        countryId: '',
        countyCode: '',
        mobile: '',
        email: '',
        emergencyContactName: '',
        emergencyContactNumber: '',
        emergencyCountryCode: '',
      };
      state.editPeople.payDetails = {
        hourlyPayDetails: [
          {
            areaIds: [],
            weekDayRate: '',
            saturdayRate: '',
            sundayRate: '',
            publicHolidayRate: '',
          },
        ],
        salaryPayDetail: {},
        libraryRateDetailEntity: [
          {
            awardCode: '',
            areaIds: [],
            baseRate: 0,
          },
        ],
        awardsList: [],
      };
      state.editPeople.areaListByPerson = [];
    },
    updateEditPeopleShiftDetails: (state, action) => {
      const payload = action.payload;
      state.editPeople.details.shiftData = { ...state.editPeople.details.shiftData, ...payload };
    },
    updateEditPeopleShiftDetailsField: (state, action) => {
      const { fieldName, value } = action.payload;
      state.editPeople.details.shiftData[fieldName] = value;
    },
    addDeleteEndShiftBreakDetails: (state, action) => {
      const { type, index } = action.payload;
      if (type === 'ADD') {
        state.editPeople.details.shiftData.endShiftDetails.breakDetails.push({
          isCustom: false,
          breakType: null,
          breakStartTime: null,
          breakEndTime: null,
          duration: null,
        });
      }
      if (type === 'DELETE') {
        state.editPeople.details.shiftData.endShiftDetails.breakDetails =
          state.editPeople.details.shiftData.endShiftDetails.breakDetails.filter(
            (e, i) => i !== index
          );
      }
    },
    updateEndShiftBreakDetails: (state, action) => {
      const { fieldName, value, index } = action.payload;
      if (index || index === 0) {
        state.editPeople.details.shiftData.endShiftDetails.breakDetails[index][fieldName] = value;
        if (fieldName === 'startTime') {
          state.editPeople.details.shiftData.endShiftDetails.breakDetails[index].duration =
            getDurationInMinutes(
              value,
              state?.editPeople?.details.shiftData?.endShiftDetails?.breakDetails?.[index]?.endTime
            );
        }
        if (fieldName === 'endTime') {
          state.editPeople.details.shiftData.endShiftDetails.breakDetails[index].duration =
            getDurationInMinutes(
              state?.editPeople?.details?.shiftData?.endShiftDetails?.breakDetails?.[index]
                ?.startTime,
              value
            );
        }
        if (fieldName === 'isCustom' && value === true) {
          state.editPeople.details.shiftData.endShiftDetails.breakDetails[index].duration =
            getDurationInMinutes(
              state?.editPeople?.details?.shiftData?.endShiftDetails?.breakDetails?.[index]
                ?.breakStartTime,
              state?.editPeople?.details?.shiftData?.endShiftDetails?.breakDetails?.[index]
                ?.breakEndTime
            );
        }
        if (fieldName === 'isCustom' && value === false) {
          state.editPeople.details.shiftData.endShiftDetails.breakDetails[index].duration = null;
        }
      } else state.editPeople.details.shiftData.endShiftDetails[fieldName] = value;
    },
    resetEditPeopleShiftDetailsField: (state) => {
      state.editPeople.details.shiftData = {};
    },
    resetEditPeopleEndShiftDetails: (state) => {
      state.editPeople.details.shiftData.endShiftDetails = {};
    },
    resetPayRateData: (state) => {
      state.editPeople.payDetails = {
        hourlyPayDetails: [
          {
            areaIds: [],
            weekDayRate: '',
            saturdayRate: '',
            sundayRate: '',
            publicHolidayRate: '',
          },
        ],
        salaryPayDetail: {},
        libraryRateDetailEntity: [
          {
            awardCode: '',
            areaIds: [],
            baseRate: 0,
          },
        ],
        awardsList: [],
      };
    },
    updateJournalDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.journalDetails[fieldName] = value;
    },
    updateAddJournalDrawerDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.journalDetails.addJournalDrawer[fieldName] = value;
    },
    setJournalList: (state, action) => {
      state.journalDetails.journalList = {
        ...state.journalDetails.journalList,
        docs: action.payload.data,
        total: action.payload.totalData,
        limit: action.payload.limit,
        page: action.payload.page,
        pages: action.payload.totalPages,
        hasMoreRecords: action.payload.totalPages > action.payload.page,
      };
    },
    updateJournalDocs: (state, action) => {
      state.journalDetails.journalList = {
        ...state.journalDetails.journalList,
        docs: [...state.journalDetails.journalList.docs, ...action.payload.data],
        total: action.payload.totalData,
        limit: action.payload.limit,
        page: action.payload.page,
        pages: action.payload.totalPages,
        hasMoreRecords: action.payload.totalPages > action.payload.page,
      };
    },
    clearJournalDetails: (state) => {
      state.journalDetails.journalList = {
        docs: [],
        total: 1,
        limit: 10,
        page: 1,
        pages: 1,
        hasMoreRecords: false,
      };
    },
    updateAddUnavailabilityDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.unavailabilityDetails.addUnavailabilityDetails[fieldName] = value;
    },
    updateUnavailabilityDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.unavailabilityDetails[fieldName] = value;
    },
    updateUnavailability: (state, action) => {
      const { fieldName, value } = action.payload;
      state.unavailabilityDetails.unavailability[fieldName] = value;
    },
    resetAddUnavailabilityDetails: (state) => {
      state.unavailabilityDetails.addUnavailabilityDetails = {
        isDrawerOpen: false,
        isAllDay: false,
        fromDate: '',
        fromTime: '',
        toDate: '',
        toTime: '',
        isRepeat: false,
        repeatitionFrequency: '',
        repeatWeekWise: [],
        repeatMontly: '',
        endOn: '',
        endOnDate: '',
        note: null,
      };
    },
    setUnavailabilityList: (state, action) => {
      state.unavailabilityDetails.unavailability = {
        ...state.unavailabilityDetails.unavailability,
        docs: action.payload.data,
        total: action.payload.totalData,
        limit: action.payload.limit,
        page: action.payload.page,
        pages: action.payload.totalPages,
        hasMoreRecords: action.payload.totalPages > action.payload.page,
      };
    },
    updateUnavailabilityDocs: (state, action) => {
      state.unavailabilityDetails.unavailability = {
        ...state.unavailabilityDetails.unavailability,
        docs: [...state.unavailabilityDetails.unavailability.docs, ...action.payload.data],
        total: action.payload.totalData,
        limit: action.payload.limit,
        page: action.payload.page,
        pages: action.payload.totalPages,
        hasMoreRecords: action.payload.totalPages > action.payload.page,
      };
    },
  },
});

const { reducer, actions } = peopleSlice;

export const {
  setPeopleList,
  updatePeopleData,
  addUpdatePeopleFields,
  resetAddPeopleFields,
  updateAddPeopleFields,
  setImportedPeopleData,
  resetImportPeopleData,
  editPeopleDetails,
  setLocationsListForPeople,
  setPeopleDetails,
  updatePeoplePayDetailsFields,
  addDeletePeopleHourlyPayGrade,
  setPayRateData,
  setAreaListByPerson,
  setWorkPeriodsByBusinessId,
  setLeaveBalanceList,
  setLeaveRequestList,
  setPreviousShiftsList,
  setUpComingShiftsList,
  editLeaveDetails,
  updateAddLeaveDetails,
  setLeaveEntitlementEnum,
  updateLeaveEntitlementDetail,
  editShiftDetails,
  setPeopleForOfferShift,
  resetLeaveRequestDetails,
  resetLeaveEntitlementDrawerDetails,
  setExtraLeaveDetails,
  setAddLeaveDetails,
  resetEditPeopleDetails,
  setPeopleListFiltersByBusiness,
  updateEditPeopleShiftDetails,
  updateEditPeopleShiftDetailsField,
  addDeleteEndShiftBreakDetails,
  updateEndShiftBreakDetails,
  resetEditPeopleShiftDetailsField,
  resetEditPeopleEndShiftDetails,
  importPeopleFromIntegrationFrPeopleModule,
  resetPayRateData,
  updateJournalDetails,
  updateAddJournalDrawerDetails,
  setJournalList,
  updateJournalDocs,
  updateAddUnavailabilityDetails,
  resetAddUnavailabilityDetails,
  setUnavailabilityList,
  updateUnavailabilityDetails,
  updateUnavailabilityDocs,
  updateUnavailability,
  clearJournalDetails,
  addDeleteAreaLibraryRate,
} = actions;

export default reducer;
