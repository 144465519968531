import { displayDate, displayTime } from '../../../utils/timeHelper';

// const reasons = [
//   {
//     id: 1,
//     label: 'Overlaping',
//   },
//   {
//     id: 2,
//     label: 'Swap Pending',
//   },
//   {
//     id: 3,
//     label: 'On leave',
//   },
// ];

const getReason = ({
  reasonType,
  employeeName,
  date,
  startDate,
  endDate,
  areaName,
  timeZoneId,
}) => {
  if (reasonType === 1)
    return `Overlap detected! ${employeeName} is already working on ${displayDate(
      date
    )} ${displayTime(startDate, timeZoneId)} to ${displayTime(endDate, timeZoneId)} at ${areaName}`;
  if (reasonType === 2) return `There is a pending shift swap.`;
  if (reasonType === 3)
    return `On leave from ${displayDate(date, timeZoneId)} ${displayTime(
      startDate,
      timeZoneId
    )} to ${displayDate(date, timeZoneId)} ${displayTime(endDate, timeZoneId)}`;
  if (reasonType === 4) {
    return 'The person you selected was no longer active';
  }
  return '-';
};
export const templateErrorByAreaHeaders = [
  {
    name: 'reason',
    label: 'Incorrect Areas',
  },
];
export const templateErrorByPeopleHeaders = [
  {
    label: 'Employee name',
    name: 'employeeName',
  },
  {
    label: 'Date',
    name: 'date',
    format: (e) => displayDate(e),
  },
  {
    label: 'Reason',
    name: 'reasonType',
    format: (_, rowData) => getReason(rowData),
  },
];
