import PropTypes from 'prop-types';
import EzyDialog from '../dialog/EzyDialog';
import EzyDialogTitle from '../dialog/EzyDialogTitle';
import EzyAutoComplete from '../autoComplete/EzyAutoComplete';
import EzyDialogAction from '../dialog/EzyDialogAction';
import EzyInputField from '../inputField/EzyInputField';
import EzySwitch from '../switch/EzySwitch';

function FilterModal({
  open,
  filters,
  setFilters,
  handleClose,
  filterFields,
  onApplyFilters,
  className,
}) {
  const filterActionButton = [
    {
      label: 'Reset',
      color: 'secondary',
      onClick: () => {
        setFilters({});
        onApplyFilters({});
      },
    },
    {
      label: 'Apply',
      onClick: () => onApplyFilters(filters),
    },
  ];

  const onAutoCompleteChange = (name, event, value) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const onInputChange = (name, event) => {
    if (event?.target?.value) {
      setFilters({
        ...filters,
        [name]: event.target.value,
      });
    }
  };
  const onSwitchChange = (name, event) => {
    setFilters({
      ...filters,
      [name]: event.target.checked,
    });
  };

  const getComponentFromType = (input) => {
    let component = null;

    switch (input.type) {
      case 'AutoComplete':
        component = (
          <EzyAutoComplete
            label={input.label}
            key={input?.name}
            value={filters?.[input?.name]}
            options={input?.options ?? []}
            placeholder={input?.placeholder}
            onChange={(event, value) => onAutoCompleteChange(input?.name, event, value)}
          />
        );
        break;

      case 'switch':
        component = (
          <EzySwitch
            label={input?.label}
            checked={filters?.[input?.name]}
            onChange={(event) => onSwitchChange(input?.name, event)}
          />
        );
        break;

      default:
        component = (
          <EzyInputField
            label={input.label}
            placeholder={input?.placeholder}
            value={filters?.[input?.name]}
            key={input?.name}
            onChange={(event) => onInputChange(input?.name, event)}
          />
        );
    }
    return component;
  };

  return (
    <EzyDialog
      open={open}
      handleClose={handleClose}
      className={` common-dialog-with-body copy-dialog ${className}`}
    >
      <EzyDialogTitle title="Filters" onClose={handleClose} />
      <div className="dialog-body two-grid">
        {filterFields?.length > 0 && filterFields.map((field) => getComponentFromType(field))}
      </div>
      <EzyDialogAction actionButtons={filterActionButton} />
    </EzyDialog>
  );
}

export default FilterModal;

FilterModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  filterFields: PropTypes.array.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  className: PropTypes.string,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
};

FilterModal.defaultProps = {
  className: '',
  filters: {},
  setFilters: () => {},
};
