import { Fade, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import IconByName from '../iconByName/IconByName';

function EzyIconTooltip({ placement, tooltip, iconName, iconClass, className, ...restOther }) {
  return (
    <Tooltip
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 300 }}
      title={tooltip}
      arrow
      placement={placement}
      {...restOther}
      className={`ezy-icon-tooltip ${className}`}
    >
      <div>
        <IconByName name={iconName} className={`ezy-tooltip-icon ${iconClass}`} />
      </div>
    </Tooltip>
  );
}

export default EzyIconTooltip;
EzyIconTooltip.propTypes = {
  placement: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  iconName: PropTypes.string,
  className: PropTypes.string,
  iconClass: PropTypes.string,
};
EzyIconTooltip.defaultProps = {
  placement: 'top',
  iconName: 'error',
  className: '',
  iconClass: '',
};
