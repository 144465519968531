import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, InputAdornment, Menu } from '@mui/material';
import EzyButton from '../../../../components/common/button/EzyButton';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';

function AdjustPopup({ handleAdjustClick }) {
  const [adjustValue, setAdjustValue] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="adjust-popup">
      <EzyButton label="Adjust" variant="outlined" onClick={handleClick} />
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            p: 1,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 280,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        <Box sx={{ paddingX: 1, bgcolor: 'white' }}>
          <div className="d-flex-column gap-10">
            <EzyInputField
              placeholder="Enter value"
              onChange={(e) => {
                setAdjustValue(e?.target?.value);
              }}
              value={adjustValue}
              endAdornment={
                <InputAdornment position="end">
                  <span className="material-icons icon-small endAdornment-icon" role="presentation">
                    percent
                  </span>
                </InputAdornment>
              }
            />
            <EzyButton
              className="align-self-end"
              label="Adjust"
              onClick={() => handleAdjustClick({ value: adjustValue })}
            />
          </div>
        </Box>
      </Menu>
    </div>
  );
}

AdjustPopup.propTypes = {
  handleAdjustClick: PropTypes.func.isRequired,
};

export default AdjustPopup;
