import React from 'react';
import { Paper } from '@mui/material';
import BorderedHeader from '../../components/common/headers/BorderedHeader';

function TermsAndConditions() {
  return (
    <div className="h-full w-full terms-and-condition-wrapper">
      <BorderedHeader isLogin />
      <div className="r-form-wrapper overflow-auto">
        <div className="header-wrapper">
          <div className="main-title-text">
            <b>
              <u>Terms of Service and Terms of Use</u>
            </b>
          </div>
          <div className="subtitle">
            This Policy was last reviewed and updated on: [26 January 2023]
          </div>
        </div>
        <Paper className="content-wrapper">
          <div className="title">
            1. Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust TERMS OF
            SERVICE – TERMS APPLICABLE TO BUSINESSES
          </div>
          <p>
            These Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust Terms of
            Service (these “Terms of Service”) apply to businesses’ access to and use of the Payroll
            Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services. These
            Terms of Service, together with the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees
            For Yogi Unit Trust API Terms of Service and the order or online registration and
            subscription selection form referencing these Terms of Service (the “Order Form”)
            (collectively, the “Agreement”), form a binding legal agreement between the corporate
            entity you represent in agreeing to the Agreement (“Customer”) and (i) Payroll Ezy -
            Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust. if Customer is located in
            Australia; or (ii) Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
            Trust (US) Corp if Customer is located in the Australia (together, “Payroll Ezy - Yogi
            Innovations Pty Ltd As Trustees For Yogi Unit Trust”, “us”, “we” or “our”). Any terms
            not defined in these Terms of Service have the definition assigned to them in the Order
            Form.
          </p>

          <div className="title">PLEASE READ THE FOLLOWING TERMS CAREFULLY:</div>
          <p>
            <b>
              BY CLICKING “I ACCEPT,” “SIGN UP FOR FREE,” (OR OTHER SIMILAR LANGUAGE) OR BY
              DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING OR USING THE Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust SAAS SERVICES,
            </b>
            CUSTOMER AGREES THAT CUSTOMER HAS READ AND UNDERSTOOD, AND, AS A CONDITION TO CUSTOMER’S
            USE OF AND ACCESS TO THE Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
            Unit Trust SAAS SERVICES, IS BOUND BY, THE AGREEMENT, INCLUDING Payroll Ezy - Yogi
            Innovations Pty Ltd As Trustees For Yogi Unit Trust’ PRIVACY AS AMENDED FROM TIME TO
            TIME. If Customer is not eligible, or does not agree to the Terms, then Customer does
            not have our permission to access or use the Payroll Ezy - Yogi Innovations Pty Ltd As
            Trustees For Yogi Unit Trust SaaS Services. CUSTOMER’S USE OF THE Payroll Ezy - Yogi
            Innovations Pty Ltd As Trustees For Yogi Unit Trust SAAS SERVICES, AND Payroll Ezy -
            Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust’ PROVISION OF THE Payroll Ezy -
            Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SAAS SERVICES TO CUSTOMER,
            CONSTITUTES AN AGREEMENT BY Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
            Unit Trust AND BY CUSTOMER TO BE BOUND BY THE AGREEMENT.
          </p>
          <p>
            ARBITRATION NOTICE. Except for certain kinds of disputes described in Section 16,
            Customer agrees that disputes arising under the Agreement will be resolved by binding,
            individual arbitration, and BY ACCEPTING THE AGREEMENT, CUSTOMER AND Payroll Ezy - Yogi
            Innovations Pty Ltd As Trustees For Yogi Unit Trust ARE EACH WAIVING THE RIGHT TO A
            TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS ACTION OR REPRESENTATIVE PROCEEDING.
          </p>
          <p>
            Capitalized terms used but not defined in these Terms of Service have the meanings set
            forth elsewhere in the Agreement.
          </p>
          <p>
            <b>1. Definitions</b>
          </p>
          <ol>
            <li>
              <b>
                “Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS
                Services”
              </b>
              means the services through which Payroll Ezy - Yogi Innovations Pty Ltd As Trustees
              For Yogi Unit Trust hosts and makes available the Payroll Ezy - Yogi Innovations Pty
              Ltd As Trustees For Yogi Unit Trust solution for employee scheduling, communications,
              and management to the extent set forth on the applicable Order Form. The term{' '}
              <b>
                “Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS
                Services”
              </b>
              includes, to the extent set forth on the applicable Order Form, the Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust Software and Support Services.
            </li>
            <li>
              <b>
                “Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust Software”
              </b>
              means Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust’ mobile
              application products made available under the names Payroll Ezy - Yogi Innovations Pty
              Ltd As Trustees For Yogi Unit Trust, and any updates provided as part of the Payroll
              Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services.
            </li>
            <li>
              <b>“Customer Data”</b> means any data, information, content, records, and files that
              Customer or any of its Organizational Users load, transmit to or enter into the
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services,
              including Personal Data. <b>“Customer Data”</b> includes any data, information,
              content, records, or files that Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For
              Yogi Unit Trust is provided access to through an integration with a third party
              authorized by Organizational Users.
            </li>
            <li>
              <b>“Employment Information”</b> means Customer Data that is employment related data,
              information, content, records and files including employee roles, employment status,
              employment duration, POS proficiency, time & attendance, any data used to compute
              employee engagement scores, and other information specified by Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust in the Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services from time to time
              that is loaded, entered into, transmitted to, or made available to the Payroll Ezy -
              Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services.
            </li>
            <li>
              <b>“Modifications”</b> means modifications, improvements, customizations, patches, bug
              fixes, updates, enhancements, aggregations, compilations, derivative works,
              translations, and adaptations, and <b>“Modify”</b> has a corresponding meaning.
            </li>
            <li>
              <b>“Organizational Users”</b> mean Customer personnel that Customer wishes to have
              access to and use of the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
              Unit Trust SaaS Services.
            </li>
            <li>
              <b>“Parties”</b> refer to Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
              Unit Trust and Customer and <b>“Party”</b> refers to each of Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust and Customer.
            </li>
            <li>
              <b>“Personal Data” </b> means information about an identifiable individual
            </li>
            <li>
              <b>“Website”</b> means any websites used by Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust to provide the Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust SaaS Services, including the websites
            </li>
          </ol>

          <p>
            <b>
              2. Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS
              Services
            </b>
          </p>
          <ol>
            <li>
              General. The Agreement sets forth the terms and conditions under which Payroll Ezy -
              Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust makes available the Payroll
              Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services. These
              Terms of Service do not, absent the execution of an Order Form, create any business
              relationship or impose any obligation on Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust to provide any license, access, product, or service.
            </li>

            <li>
              Provisioning of the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
              Trust SaaS Services. Subject to Customer’s compliance with the terms and conditions of
              the Agreement, Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
              will make the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
              SaaS Services available to Customer on the terms and conditions set out in the
              Agreement during the Term (defined below). Customer is responsible for identifying and
              authenticating all Organizational Users, for ensuring only Organizational Users access
              and use the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
              SaaS Services, and for Organizational Users’ compliance with the Agreement.
            </li>

            <li>
              License to the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
              Software. Subject to Customer’s and its Organizational Users’ compliance with the
              terms and conditions of the Agreement, Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust grants to Customer a non-exclusive, non-transferable, and
              limited license to install, operate and use the Payroll Ezy - Yogi Innovations Pty Ltd
              As Trustees For Yogi Unit Trust Software in accordance with the Agreement during the
              Term. Customer’s use of the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For
              Yogi Unit Trust Software will be subject to any limitations described in the
              Agreement, in the documentation accompanying the Payroll Ezy - Yogi Innovations Pty
              Ltd As Trustees For Yogi Unit Trust Software, or as otherwise agreed in writing by the
              Parties.
            </li>

            <li>
              Limits. Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
              reserves the right to impose reasonable limits on bandwidth and SMS usage as part of
              the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS
              Services. Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust will
              determine these limits based on usage volumes for normal use of the Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services for their intended
              purposes. If Customer exceeds such limits, Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust may charge Customer reasonable additional fees, suspend
              access to the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
              SaaS Services, and throttle Customer’s and Organizational Users’ account until
              bandwidth consumption is reduced to the reasonable limits.
            </li>

            <li>
              Restrictions of Use. Customer is responsible for the compliance by all Organizational
              Users with the Agreement, any guidelines and policies published by Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust from time to time, and the
              activities of all Organizational Users on the Payroll Ezy - Yogi Innovations Pty Ltd
              As Trustees For Yogi Unit Trust SaaS Services. Without limiting the generality of any
              of the foregoing, Customer will not itself, and will not permit others to:
              <ol>
                <li>
                  reproduce, publicly display, publicly perform, create derivative works of, make
                  modifications to, sublicense, sell, rent, lend, lease, or distribute the Payroll
                  Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services or
                  any intellectual property rights therein or otherwise make the Payroll Ezy - Yogi
                  Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services available to
                  others;
                </li>

                <li>
                  use the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
                  SaaS Services to permit timesharing, service bureau use or commercially exploit
                  the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS
                  Services;
                </li>

                <li>
                  use or access the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
                  Trust SaaS Services (A) in violation of any applicable law or intellectual
                  property right, (B) in a manner that threatens the security or functionality of
                  the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS
                  Services, or for any purpose or (C) in any manner not expressly permitted in the
                  Agreement;
                </li>

                <li>
                  use the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
                  SaaS Services to create, collect, transmit, store, use or process any Customer
                  Data that:
                  <ol>
                    <li>
                      contains any computer viruses, worms, malicious code, or any software intended
                      to damage or alter a computer system or data;
                    </li>

                    <li>
                      Customer does not have the lawful right to create, collect, transmit, store,
                      use, or process;
                    </li>

                    <li>
                      violates any applicable laws, or infringes, violates, or otherwise
                      misappropriates the intellectual property or other rights of any third party
                      (including any moral right, privacy right, or right of publicity);
                    </li>

                    <li>
                      is harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar,
                      obscene, pornographic, libelous, invasive to another’s privacy, hateful,
                      racially, or ethnically objectionable, encourages criminal behaviour, gives
                      rise to civil liability, or is otherwise objectionable;
                    </li>

                    <li>
                      contains unsolicited or unauthorized advertising, solicitations for business,
                      promotional materials, “junk mail,” “spam”, “chain letters,” “pyramid
                      schemes,” or any other form of solicitation;
                    </li>

                    <li>
                      contains any falsehoods, misrepresentations, creates an impression that
                      Customer knows is incorrect, or any material that could damage or harm minors
                      in any way;
                    </li>
                  </ol>
                </li>

                <li>
                  modify the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
                  SaaS Services;
                </li>

                <li>
                  reverse engineer, de-compile, or disassemble the Payroll Ezy - Yogi Innovations
                  Pty Ltd As Trustees For Yogi Unit Trust SaaS Services;
                </li>

                <li>
                  remove or obscure any proprietary notices or labels on the Payroll Ezy - Yogi
                  Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services, including
                  brand, copyright, trademark, and patent or patent pending notices;
                </li>

                <li>
                  access or use any Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
                  Trust SaaS Services for the purpose of building a similar or competitive product
                  or service;
                </li>

                <li>
                  perform any vulnerability, penetration, or similar testing of the Payroll Ezy -
                  Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services; or
                </li>

                <li>
                  collect or store Personal Data about any individual not subject to the Agreement
                  or Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust Terms of
                  Use.
                </li>
              </ol>
            </li>

            <li>
              Suspension of Access; Scheduled Downtime; Modifications. Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust may from time to time and in its
              discretion and without notice, without limiting any of its other rights or remedies at
              law or in equity, under the Agreement:
              <ol>
                <li>
                  suspend Customer’s or Organizational Users’ access to or use of the Payroll Ezy -
                  Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services:
                  <ol>
                    <li>for scheduled maintenance;</li>
                    <li>due to a Force Majeure (as defined in Section 16(j))</li>
                    <li>
                      if Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
                      believes in good faith that Customer or any Organizational User has violated
                      any provision of the Agreement;
                    </li>
                    <li>
                      to prevent interference with the availability of the Payroll Ezy - Yogi
                      Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services;
                    </li>
                    <li> to address any emergency security concerns; or</li>
                    <li>
                      if required to do so by a regulatory body or as a result of a change in
                      applicable law.
                    </li>
                  </ol>
                </li>
                <li>
                  Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust reserves
                  the right to make any Modifications or discontinue all or any portion of the
                  Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS
                  Services at any time (including by limiting or discontinuing certain features of
                  the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS
                  Service), temporarily or permanently, without notice to Customer. Payroll Ezy -
                  Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust will have no liability
                  for any change to the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
                  Unit Trust SaaS Services, including any paid-for functionalities of the Payroll
                  Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services, or
                  any suspension or termination of Customer’s access to or use of the Payroll Ezy -
                  Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services.
                </li>
              </ol>
            </li>

            <li>
              Subcontracting. Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
              may engage third parties to provide the Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust SaaS Services
            </li>
          </ol>

          <p>
            <b>3. Data; Intellectual Property</b>
          </p>
          <ol>
            <li>
              Except as expressly set forth in the Agreement, nothing in the Agreement assigns or
              grants to Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust or
              any third party any right, title or interest including any intellectual property
              rights in or to Customer Data. Customer grants to Payroll Ezy - Yogi Innovations Pty
              Ltd As Trustees For Yogi Unit Trust a nonexclusive, worldwide, royalty-free,
              irrevocable, fully paid-up right to access, use, process, store, collect, disclose,
              and transmit Customer Data during the Term to: (i) provide the Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services, including sharing
              Customer Data with its third-party sub-contractors in connection with such third-party
              subcontractors providing the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For
              Yogi Unit Trust SaaS Services or other services to Payroll Ezy - Yogi Innovations Pty
              Ltd As Trustees For Yogi Unit Trust; (ii) improve and enhance the Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services and for other
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust offerings; and
              (iii) produce data, information or other materials that are not identified as relating
              to a particular individual or Customer (such data, information and materials, the
              <b>“Aggregated Data”</b>). Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
              Unit Trust and/or its third party service providers may use Aggregated Data for any
              purpose and without restriction or obligation to Customer or any third party.
            </li>
            <li>
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust may facilitate
              and Organizational Users may create and maintain individual accounts to use the
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services
              apart from Customer User Accounts (defined below) (each an <b>“Individual Account”</b>
              ). Customer hereby grants Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
              Unit Trust a nonexclusive, worldwide, royalty-free, sublicensable (to Organizational
              Users), irrevocable, fully paid-up right to access, use, process, store, collect,
              disclose, and transmit Customer Data that constitutes Employment Information to
              maintain such Individual Accounts.
            </li>
            <li>
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust or its
              licensors retain all ownership and intellectual property rights in and to: (i) the
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services;
              (ii) anything developed or delivered by or on behalf of Payroll Ezy - Yogi Innovations
              Pty Ltd As Trustees For Yogi Unit Trust under the Agreement; and (iii) any
              Modifications to the foregoing (i) and (ii).
            </li>
            <li>
              All rights not expressly granted by Payroll Ezy - Yogi Innovations Pty Ltd As Trustees
              For Yogi Unit Trust to Customer under the Agreement are reserved
            </li>
          </ol>

          <p>
            <b>4. Trademark License</b>
          </p>
          <p>
            Subject to the terms and conditions of the Agreement, each Party hereby grants to the
            other Party a non-exclusive, royalty-free, fully paid-up, non-transferable,
            non-sublicensable, and revocable license to use and display its trademarks, service
            marks, tradenames, or logos, in accordance with such Party’s branding guidelines, in the
            other Party’s advertising, marketing, and promotional materials solely to identify the
            other Party as a client or vendor.
          </p>

          <p>
            <b>5. Feedback</b>
          </p>
          <p>
            Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust may use any
            suggestions, comments or other feedback relating to any aspect of the Websites, Payroll
            Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services, or the
            Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust Software (
            <b>“Feedback”</b>), in or to improve the Website, the Payroll Ezy - Yogi Innovations Pty
            Ltd As Trustees For Yogi Unit Trust SaaS Services or in any other Payroll Ezy - Yogi
            Innovations Pty Ltd As Trustees For Yogi Unit Trust products or services (collectively,
            <b>
              “Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust Offerings”
            </b>
            ). Accordingly, Customer agrees that
          </p>
          <ol>
            <li>
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust is not subject
              to any confidentiality obligations in respect to Feedback;
            </li>
            <li>
              Feedback is not confidential or proprietary information of Customer or any third party
              and Customer has all of the necessary rights to disclose the Feedback to Payroll Ezy -
              Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust;
            </li>
            <li>
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust (including all
              of its successors and assigns and any successors and assigns of any of the Payroll Ezy
              - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust Offerings) may freely use,
              reproduce, publicize, license, distribute, sublicense, and otherwise commercialize
              Feedback in any Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
              Offerings; and
            </li>
            <li>
              Customer is not entitled to receive any compensation or reimbursement of any kind from
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust or any of the
              other users of the Website or Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For
              Yogi Unit Trust SaaS Services in respect of the Feedback.
            </li>
          </ol>

          <p>
            <b>6. Privacy</b>
          </p>
          <p>
            Customer understands that Personal Data, including the Personal Data of Organizational
            Users, will be treated in accordance with Payroll Ezy - Yogi Innovations Pty Ltd As
            Trustees For Yogi Unit Trust’ privacy policy located at our website. If Customer Data
            will include any personal data of individuals located in the European Economic Area or
            the United Kingdom, Customer is responsible for notifying Payroll Ezy - Yogi Innovations
            Pty Ltd As Trustees For Yogi Unit Trust and Customer hereby agrees to Payroll Ezy - Yogi
            Innovations Pty Ltd As Trustees For Yogi Unit Trust Data Processing Agreement (located
            on our website).
          </p>

          <p>
            <b>7. Customer User Account; Responsibility for Organizational Users</b>
          </p>
          <ol>
            <li>
              Accounts. Upon Customer’s request, Payroll Ezy - Yogi Innovations Pty Ltd As Trustees
              For Yogi Unit Trust will issue one or more accounts (each, a“Customer User Account”)
              to Customer for use by one or more Organizational Users and designate one or more
              Customer User Accounts as administrator accounts that provides Customer with the
              capability to administer, maintain, and manage certain features of the Payroll Ezy -
              Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services (such account,
              an “Administrator Account”). Customer must provide Payroll Ezy - Yogi Innovations Pty
              Ltd As Trustees For Yogi Unit Trust with the identity of the individual who will act
              as the administrator of the Customer User Account. Customer will ensure that
              Organizational Users only use the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees
              For Yogi Unit Trust SaaS Services through a Customer User Account. Customer will not
              allow any Organizational Users to share a Customer User Account with any other person.
            </li>
            <li>
              Authentication. Customer is responsible for identifying and authenticating all
              Organizational Users and for Organizational Users’ use of the Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services in compliance with
              the Agreement.
            </li>
            <li>
              Unauthorized Use. Customer will promptly notify Payroll Ezy - Yogi Innovations Pty Ltd
              As Trustees For Yogi Unit Trust of any actual or suspected unauthorized use of the
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services.
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust reserves the
              right to suspend, deactivate, or replace a Customer User Account if it determines that
              such Customer User Account may have been used for an unauthorized purpose. Customer
              will ensure that all individual users of the Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust SaaS Services, including Organizational Users, are
              contractually bound to Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
              Unit Trust’ end user Terms of Use.
            </li>
            <li>
              User Content Generally. Certain features of the Payroll Ezy - Yogi Innovations Pty Ltd
              As Trustees For Yogi Unit Trust SaaS Services may permit users to submit, upload,
              publish, broadcast, or otherwise transmit (“Post”) content to the Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services, including messages,
              reviews, photos, video, or audio (including sound or voice recordings and musical
              recordings embodied in the video or audio), images, folders, data, text, “User
              Content”may hold in the User Content that Customer Posts to the Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services, subject to the
              licenses granted in these Terms of Service.
            </li>
            <li>
              Limited License Grant to Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
              Unit Trust. By Posting User Content to or via the Payroll Ezy - Yogi Innovations Pty
              Ltd As Trustees For Yogi Unit Trust SaaS Services, Customer hereby grants Payroll Ezy
              - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust a worldwide, non-exclusive,
              irrevocable, royalty-free, fully paid right and license (with the right to sublicense
              through multiple tiers) to host, store, transfer, publicly display, publicly perform
              (including by means of a digital audio transmission), communicate to the public,
              reproduce, modify for the purpose of formatting for display, create derivative works
              as authorized in these Terms of Service, and distribute User Content, in whole or in
              part, in any media formats and through any media channels, in each instance whether
              now known or hereafter developed.
            </li>
            <li>
              Customer Must Have Rights to the Content Customer Posts; User Content Representations
              and Warranties. Customer must not Post User Content if Customer is not the owner of or
              not fully authorized to grant rights in all of the elements of that User Content.
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust disclaims any
              and all liability in connection with User Content. Customer is solely responsible for
              User Content and the consequences of providing User Content via the Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services. By providing User
              Content via the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
              SaaS Services, Customer affirm, represent, and warrant to us that:
              <ol>
                <li>
                  Customer is the creator and owner of the User Content, or have the necessary
                  licenses, rights, consents, and permissions to authorize Payroll Ezy - Yogi
                  Innovations Pty Ltd As Trustees For Yogi Unit Trust and users of the Payroll Ezy -
                  Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services to use and
                  distribute User Content as necessary to exercise the licenses granted by Customer
                  in this Section, in the manner contemplated by Payroll Ezy - Yogi Innovations Pty
                  Ltd As Trustees For Yogi Unit Trust, the Payroll Ezy - Yogi Innovations Pty Ltd As
                  Trustees For Yogi Unit Trust SaaS Services, and these Terms of Service;
                </li>
                <li>
                  User Content, and the Posting or other use of User Content as contemplated by
                  these Terms of Service, does not and will not: (i) infringe, violate,
                  misappropriate, or otherwise breach any third-party right, including any
                  copyright, trademark, patent, trade secret, moral right, privacy right, right of
                  publicity, or any other intellectual property, contract, or proprietary right;
                  (ii) slander, defame, libel, or invade the right of privacy, publicity, or other
                  property rights of any other person; or (iii) cause Payroll Ezy - Yogi Innovations
                  Pty Ltd As Trustees For Yogi Unit Trust to violate any law or regulation or
                  require Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust to
                  obtain any further licenses from or pay any royalties, fees, compensation, or
                  other amounts or provide any attribution to any third parties; and
                </li>
                <li>
                  User Content could not be deemed by a reasonable person to be objectionable,
                  profane, indecent, pornographic, harassing, threatening, embarrassing, hateful, or
                  otherwise inappropriate.
                </li>
              </ol>
            </li>

            <li>
              User Content Disclaimer. We are under no obligation to edit or control User Content
              that Customer or other users Post and will not be in any wayresponsible or liable for
              User Content. Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
              may, however, at any time and without prior notice, screen, remove, edit, or block any
              User Content that in our sole judgment violates these Terms of Service, is alleged to
              violate the rights of third parties, or is otherwise objectionable. If notified by a
              user or content owner that User Content allegedly does not conform to these Terms of
              Service, we may investigate the allegation and determine in our sole discretion
              whether to remove the User Content, which we reserve the right to do at any time and
              without notice. For clarity, Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For
              Yogi Unit Trust does not permit infringing activities on the Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services.
            </li>
            <li>
              Monitoring Content. Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
              Trust reserves the right to, and may from time to time, monitor any and all
              information transmitted or received through the Payroll Ezy - Yogi Innovations Pty Ltd
              As Trustees For Yogi Unit Trust SaaS Services for operational and other purposes. If
              at any time Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
              chooses to monitor the content, then Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust still assumes no responsibility or liability for content
              or any loss or damage incurred as a result of the use of content. During monitoring,
              information may be examined, recorded, copied, and used in accordance with our Privacy
              Policy. Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust may
              block, filter, mute, remove or disable access to any User Content uploaded to or
              transmitted through the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
              Unit Trust SaaS Services without any liability to the user who Posted such User
              Content to the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
              SaaS Services or to any other users of the Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust SaaS Services.
            </li>
          </ol>

          <p>
            <b>8. Support</b>
          </p>
          <p>
            Customer will generally have access to Payroll Ezy - Yogi Innovations Pty Ltd As
            Trustees For Yogi Unit Trust’ technical support services (“Support Services”) from 9:00
            AM to 5:00 PM on Monday – Friday Australian Eastern Standard Time through the Website.
            Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust reserves the
            right to modify the availability of Support Services from time-to-time in the regular
            course of business.
          </p>

          <p>
            <b>
              9. Updates to the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
              Trust Software; Third Party Content
            </b>
          </p>
          <ol>
            <li>
              General Updates to Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
              Trust Software. Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
              may, in its sole discretion, include the provision of updates, upgrades, bug fixes,
              patches, and other error corrections as Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust makes generally available to other licensees of the
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust Software
              (collectively, the “Updates”). All Updates will be deemed to be “Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust Software” and subject to the terms
              and conditions of the Agreement. Customer is required to accept all Updates made by or
              on behalf of Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust to
              the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust Software.
              If Customer does not wish to install the Updates, it should not license the Payroll
              Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust Software. Payroll Ezy -
              Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust may require that Customer
              accept and install Updates to the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees
              For Yogi Unit Trust Software as a condition to the licenses granted in the Agreement.
              Except for any automatic Updates provided by Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust pursuant to Section 9(c) below, Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust will provide Customer with prior
              notice of such Updates. Upon such notice, Customer will, through its Administrator
              Accounts, install such Updates promptly following receipt of such Update from Payroll
              Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust.
            </li>
            <li>
              Automatic Updates to Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
              Trust Software. The Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
              Trust Software may automatically communicate with Payroll Ezy - Yogi Innovations Pty
              Ltd As Trustees For Yogi Unit Trust’ servers or the Payroll Ezy - Yogi Innovations Pty
              Ltd As Trustees For Yogi Unit Trust SaaS Services to permit the Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust Software to perform in accordance
              with its specifications, to record and collect Customer Data and to receive Updates.
              The Updates may be automatically installed without providing any additional notice or
              receiving any additional consent. Customer consents to these automatic Updates.
            </li>
            <li>
              Third-Party Material. The Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
              Unit Trust Software may provide links or access to third-party content, websites,
              services, or systems. Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
              Trust does not endorse any third-party content, websites, services, or systems, or
              guarantee their quality, accuracy, reliability, completeness, currency, timeliness,
              non-infringement, merchantability, or fitness for any purpose. Third-party content,
              websites, services, or systems are not under the control of Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust, and if Customer chooses to access
              any such content, websites, services, or systems Customer does so entirely at its own
              risk. Customer acknowledges that it may be required by to accept terms and conditions
              applicable to third-party content, websites, services, or systems, that such terms may
              supersede the terms in the Agreement with respect to the use of such third-party
              content, websites, services or systems, and Customer agrees to accept and comply with
              any such terms and conditions.
            </li>
          </ol>

          <p>
            <b>10. Fees and Payment</b>
          </p>
          <ol>
            <li>
              Fees. Customer will pay to Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
              Unit Trust the applicable fees described in the applicable Order Form (the“Fees”)
              during the Term in accordance with the payment terms set out herein and in the
              applicable Order Form.
            </li>
            <li>
              Change to Fees. Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
              reserves the right to change the Fees and institute new charges upon providing not
              less than 30 days prior notice to Customer. Such notice may be provided at any time by
              posting the changes to our website or through the Payroll Ezy - Yogi Innovations Pty
              Ltd As Trustees For Yogi Unit Trust SaaS Services. Without limiting the foregoing,
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust may increase
              the Fees annually, without notice, for inflation based on changes to the CPI Index as
              compared to CPI Index for immediately preceding calendar year. The term “CPI Index”
              means the Consumer Price Index for the Australia as published in the AUSTRALIA Bureau
              of Statistics’ CPI Inflation Calculator or a similar replacement price index should
              that Inflation Calculator be discontinued in its present form.
            </li>
            <li>
              Invoicing. All Fees are due and payable in advance. Payroll Ezy - Yogi Innovations Pty
              Ltd As Trustees For Yogi Unit Trust will prepare and send to Customer, at the
              then-current contact information on file with Payroll Ezy - Yogi Innovations Pty Ltd
              As Trustees For Yogi Unit Trust, an invoice for any Fees that are due. Unless
              otherwise expressly stipulated in an invoice, Customer will pay all invoiced amounts
              in advance on either a monthly or annual basis and Fees are non-refundable. For
              greater certainty, if Customer has subscribed to an ongoing subscription, then such
              subscription will automatically renew at its expiry for the same period of time, at
              the then-current subscription rate described on the Website, and Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust will invoice or charge Customer on
              a recurring basis.
            </li>
            <li>
              Disputed Invoices or Charges. If Customer believes Payroll Ezy - Yogi Innovations Pty
              Ltd As Trustees For Yogi Unit Trust has charged or invoiced Customer incorrectly,
              Customer must contact Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
              Trust no later than 45 days after having been charged by Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust or receiving such invoice in which
              the error or problem appeared in order to request an adjustment or credit. In the
              event of a dispute, Customer will pay any undisputed amounts in accordance with the
              payment terms herein, and the parties will discuss the disputed amounts in good faith
              in order to resolve the dispute.
            </li>
            <li>
              Late Payment. Customer may not withhold or setoff any amounts due under the Agreement.
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust reserves the
              right to suspend Customer’s access to the Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust SaaS Services until all due and undisputed amounts are
              paid in full. Any late payment will be increased by the costs of collection (if any)
              and will incur interest at the rate of 1.5% compounded monthly (19.56% annually), or
              the maximum legal rate (if less), plus all expenses of collection, until fully paid.
            </li>
            <li>
              Taxes. The Fees do not include applicable sales, use, gross receipts, value-added,
              GST, personal property or other taxes. Customer will be responsible for and pay all
              applicable taxes, duties, tariffs, assessments, export and import fees, and similar
              charges (including interest and penalties imposed thereon) on the transactions
              contemplated in connection with the Agreement other than taxes based on the net income
              or profits of Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust.
              If Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust has the
              legal obligation to pay or collect taxes for which Customer is responsible, Payroll
              Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust will invoice Customer
              and Customer will pay that amount unless Customer provide Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust with a valid tax exemption
              certificate authorized by the appropriate taxing authority.
            </li>
            <li>
              Suspension. Any suspension of the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees
              For Yogi Unit Trust SaaS Services by Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust pursuant to the terms of the Agreement will not excuse
              Customer from its obligation to make payments under the Agreement.
            </li>
            <li>
              Third-party Marketplaces. Customer agrees and acknowledges that in the event Customer
              pays for Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS
              Services through third-party (i) Customer shall also be subject to the terms and
              conditions set forth by those parties; (ii) Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust will not provide refunds or credits in any circumstances;
              and (iii) Customer is solely responsible for managing its payments to Payroll Ezy -
              Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust through the third-party
              marketplace and Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
              will not be liable for Customer’s use of those marketplaces or the enforcement of such
              third parties’ policies.
            </li>
          </ol>

          <p>
            <b>11. Digital Tip Disbursement</b>
          </p>
          <ol>
            <li>Digital Tip Reimbursement is currently not available.</li>
          </ol>

          <p>
            <b>12. Payroll Processing Services</b>
          </p>
          <ol>
            <li>
              Payroll Processing Services. If payroll processing is selected as a feature in the
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services,
              Customer hereby authorizes Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
              Unit Trust to provide Customer and Organizational Users with payroll processing
              services set out in an Order Form (“Payroll Processing Services”).
            </li>
            <li>
              Customer Obligations: Customer hereby agrees to:{' '}
              <ol>
                <li>
                  provide information as reasonably requested by Payroll Ezy - Yogi Innovations Pty
                  Ltd As Trustees For Yogi Unit Trust in relation to its provision of Payroll
                  Processing Services, including without limitation, all information required by
                  Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust and its
                  service providers and bank to facilitate payroll payments to taxing authorities
                  and documents relating to prior payroll payments. Customer shall ensure all
                  information provided to Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For
                  Yogi Unit Trust is accurate, timely and complete. For the purposes of these Terms
                  of Service, such information provided by Customer shall be considered Customer
                  Data (as defined above);
                </li>
                <li>
                  authorize Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
                  and its authorized third party service providers and bank to use Customer Data,
                  including Employment Information, and any other data required for Payroll
                  Processing Services;
                </li>
                <li>
                  immediately provide to Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
                  Unit Trust the revised Customer Data in the event of any changes or updates.
                  Customer hereby waives and releases any claim against Payroll Ezy - Yogi
                  Innovations Pty Ltd As Trustees For Yogi Unit Trust and shall be liable for any
                  complaints from Organizational Users arising out of or relating to any errors or
                  omissions in the Customer Data, including without limitation, payroll information
                  which Customer has not corrected or has not requested Payroll Ezy - Yogi
                  Innovations Pty Ltd As Trustees For Yogi Unit Trust to correct in an appropriate
                  and timely manner;
                </li>
                <li>
                  provide all information to Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For
                  Yogi Unit Trust and its service providers to satisfy due diligence obligations as
                  required by applicable laws, in accordance with Payroll Ezy - Yogi Innovations Pty
                  Ltd As Trustees For Yogi Unit Trust’, applicable service provider’s or bank’s
                  policies and procedures;
                </li>
                <li>
                  cooperate with Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
                  Trust in investigating any fraudulent or illegal transactions;
                </li>
                <li>
                  remit any federal, state, and local liabilities incurred prior to enrolling in the
                  Payroll Processing Services and to submit any payroll returns to tax agencies that
                  were due for payroll tax liabilities incurred before using the Payroll Processing
                  Services.
                </li>
              </ol>
            </li>

            <li>
              Suspension of Payroll Processing Services: Customer agrees and acknowledges that
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust may, in its
              sole discretion, suspend or terminate provision of Payroll Processing Services when
              doing so would reasonably be expected to mitigate an actual or suspected security
              breach or threat, or in the event Payroll Ezy - Yogi Innovations Pty Ltd As Trustees
              For Yogi Unit Trust or its service providers determine that the provision of Payroll
              Processing Services would violate applicable laws or create undue risk for Payroll Ezy
              - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust or its service providers.
            </li>
            <li>
              Errors in Customer Data: Customer agrees and acknowledges Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust and its service providers rely on
              information furnished by Customer to perform the Payroll Processing Services. Payroll
              Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust is not responsible or
              liable for any errors or inaccuracies in information furnished by Customer (including
              its employees and/or independent contractors) and/or Organizational Users, or
              Customer’s failure to maintain original documents as legally required.
            </li>
            <li>
              Expedited Payroll: Customer hereby agrees and acknowledges that in the event Payroll
              Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust or its service
              providers determine that Customer or any payroll transaction is a potential credit
              risk, Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust may (i)
              withdraw the offering of expedited payroll services; (ii) require Customer to transmit
              funds via wire transfer or other methods; (iii) require Customer to provide current
              bank statements; (iv) require Customer to provide additional payment(s) to be held in
              escrow; and/or (v) require Customer to undertake any other action reasonably
              determined by Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
              to mitigate such credit risk.
            </li>
            <li>
              Availability of Funds:
              <ol>
                <li>
                  Customer hereby agrees to maintain in the Bank Account (as defined below)
                  immediately available funds sufficient to cover all disbursements, fees, payroll
                  taxes or any other amounts due (collectively, the “Amounts Due”) under these
                  Terms.
                </li>
                <li>
                  Customer agrees and acknowledges that Payroll Ezy - Yogi Innovations Pty Ltd As
                  Trustees For Yogi Unit Trust or its service provider shall not be liable for any
                  claims directly or indirectly arising from a failure to pay Amounts Due to the
                  applicable parties resulting from Customer’s failure to make sufficient funds
                  available to Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
                  Trust via the Bank Account. If there are not sufficient funds in the Bank Account,
                  Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust or its
                  service provider may (i) require Customer to pay all outstanding disbursements to
                  employees and independent contractors directly; (ii) refuse to pay any unremitted
                  payroll taxes to the applicable tax agencies, in which case Customer shall be
                  solely responsible for the payroll tax liability; (iii) pay some or all of the
                  Amounts Due as requested, then take all appropriate actions as are necessary to
                  recoup the funds from Customer, including via third-party collection agency or
                  legal action; (iv) repeat the debit attempt up to two additional times; (v)
                  require current and/or future payments to be remitted through an alternative
                  payment method; and/or (vi) refuse to perform further Payroll Processing Services.
                  In the event that Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
                  Trust incurs any financial obligations or liabilities resulting from Customer’s
                  failure to make sufficient funds available to Payroll Ezy - Yogi Innovations Pty
                  Ltd As Trustees For Yogi Unit Trust, including any legal or collection fees
                  incurred in the course of collecting Amounts Due from Customer, Customer hereby
                  agrees to reimburse Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
                  Unit Trust for all such amounts.
                </li>
              </ol>
            </li>
            <li>
              {' '}
              Disclaimer; Representations and Warranties; Consents:{' '}
              <ol>
                {' '}
                <li>
                  Customer acknowledges and agrees that (1) in performing the Payroll Processing
                  Services, Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
                  is not in a fiduciary capacity for the Customer; (2) using the Payroll Processing
                  Services does not relieve Customer of its obligations under federal, state,
                  provincial or local laws or regulations; (3) Customer should not construe any
                  information provided directly or indirectly by Payroll Ezy - Yogi Innovations Pty
                  Ltd As Trustees For Yogi Unit Trust through provision of Payroll Processing
                  Services to be legal, tax, or accounting advice; and (4) the Payroll Processing
                  Services may not include all functions necessary for Customer’s operations or for
                  Customer to meet all federal, state,provincial and local payroll reporting
                  obligations applicable to the Customer.
                </li>
                <li>
                  Customer represents and warrants that it has provided all notices and obtained all
                  consents necessary to provide Payroll Ezy - Yogi Innovations Pty Ltd As Trustees
                  For Yogi Unit Trust with the information and permissions required to provide the
                  Payroll Processing Services, including information from Customer’s employees
                  and/or independent contractors. Customer acknowledges and understands that in
                  providing the Payroll Processing Services, Payroll Ezy - Yogi Innovations Pty Ltd
                  As Trustees For Yogi Unit Trust acts as an intermediary between Customer and its
                  employees and/or independent contractors specifically and solely with respect to
                  the delivery of payroll funds owed.
                </li>
                <li>
                  Although Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust or
                  its service provider may be authorized by Customer to act as Customer’s reporting
                  agent with applicable tax authorities, Customer acknowledges and agrees that such
                  authorization does not relieve Customer of its responsibility to (or from
                  liability for failing to) ensure that all tax returns, tax deposits and payments
                  are filed and made on a timely basis.
                </li>
                <li>
                  Customer represents and warrants the following: (1) it does not currently conduct
                  business with any individual or entity that is subject to sanctions by the Office
                  of Foreign Assets Control of the AUSTRALIA Department of the Treasury, businesses
                  and individuals on other government-maintained sanctions lists, or businesses or
                  individuals in comprehensively sanctioned jurisdictions, including Cuba, Iran,
                  North Korea, Syria, the Crimea region of Ukraine, and the so-called Donetsk
                  People’s Republic and the so-called Luhansk People’s Republic of Ukraine; (2) no
                  payroll information submitted by Customer will result in entries that would
                  violate the sanctions program of any AUSTRALIA government agency or any other
                  applicable laws, rules, or regulations.
                </li>
                <li>
                  Customer represents and warrants that it will comply with all applicable laws and
                  provide Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
                  with documentation demonstrating its compliance upon request.
                </li>
              </ol>
            </li>
          </ol>

          <p>
            <b>13. Pass-Through Fees; Bank Accounts and Authorizations</b>
          </p>
          <ol>
            <li>
              Customer hereby agrees and acknowledges that Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust may charge certain pass-through fees and expenses from
              time to time with an effective date of no less than fifteen (15) days after such
              updates are posted on our website located at our website.
            </li>
            <li>
              Customer authorizes Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
              Trust and its authorized third parties to initiate debit or credit entries to
              Customer’s bank account (“Bank Account”) at the depository financial institution
              Customer provides to Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
              Trust and its service providers (the“Bank”) and Customer agrees to provide Payroll Ezy
              - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust, its service providers
              and/or the Bank, as applicable, with any additional authorizations or information such
              party may require in relation to the provision of Payroll Ezy - Yogi Innovations Pty
              Ltd As Trustees For Yogi Unit Trust SaaS Services. These authorizations will remain in
              full force and effect until Customer provides Payroll Ezy - Yogi Innovations Pty Ltd
              As Trustees For Yogi Unit Trust with written notice of termination of any such
              authorizations in such time and such manner as to afford Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust, its service providers and the
              Bank a reasonable opportunity to act upon such notice. Customer acknowledges that the
              failure to provide these authorizations or the termination of the authorizations for
              any reason may result in Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
              Unit Trust’ immediate termination of your access to the impacted Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services. Customer authorizes
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust and its
              authorized third party service providers to use Customer Data, including Employment
              Information, and any other data required for the Services.
            </li>
            <li>
              To the extent Customer provides its employees’ or contractors’ bank account
              information to Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
              or its authorized service providers, Customer hereby represents and warrants that it
              has obtained consents and authorizations from such individual as required by law to
              allow Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust or its
              service provider to credit and, as legally necessary to correct an error or
              overpayment, debit funds from those employees’ or contractors’ accounts. Customer
              hereby agrees to provide proof of such consent to Payroll Ezy - Yogi Innovations Pty
              Ltd As Trustees For Yogi Unit Trust upon request.
            </li>
            <li>
              Customer hereby agrees that Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For
              Yogi Unit Trust shall not be responsible for determining whether the bank accounts of
              any payors or payees have deposit or withdrawal restrictions.
            </li>
            <li>
              Amounts Due will be held for Customer’s benefit in accounts at financial institutions
              until such time as those payments are due to Customer’s employees and/or independent
              contractors (and in the case of Payroll Processing Services, the appropriate taxing
              agencies), and no interest will be paid to Customers on these amounts. Customer
              acknowledges that Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
              Trust and/or its service providers, as applicable, are acting only as Customer’s
              authorized ‘Third-Party Sender’ (as defined in the NACHA Rules) with respect to funds
              held in such bank account, and that Payroll Ezy - Yogi Innovations Pty Ltd As Trustees
              For Yogi Unit Trust and its service providers have no access to or control over such
              funds.
            </li>
            <li>
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services
              will enable Customer to enter, approve, and submit Customer information for creation,
              formatting, and transmission of entries in accordance with the NACHA Rules and Article
              4A of the Uniform Commercial Code as amended from time to time (“UCC”). Payroll Ezy -
              Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust or its service providers may,
              upon notice to Customer, reject information or entries that do not comply with the
              requirements in these Terms, NACHA Rules, or the UCC.
            </li>
            <li>
              Customer acknowledges it is the Originator (as defined in the NACHA Rules) of each
              entry or transaction, and assumes the responsibilities and liabilities of an
              Originator under the NACHA Rules. Customer further acknowledges that under the NACHA
              Rules and the UCC, Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
              Trust or its service provider (as applicable) as a Third-Party Sender (as defined in
              the NACHA Rules), are required to make certain warranties on behalf of the Originator
              with respect to each entry or transaction. Customer agrees to indemnify Payroll Ezy -
              Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust and its service providers
              against any claims which results, directly or indirectly, from a breach of such a
              warranty made by Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
              Trust or its service providers on Customer’s behalf, unless such breach results solely
              from Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust or its
              service provider’s (as applicable) own gross negligence or intentional misconduct.
            </li>
          </ol>

          <p>
            <b>14. Confidential Information</b>
          </p>
          <ol>
            <li>
              Definitions. For the purposes of the Agreement, a Party receiving Confidential
              Information (as defined below) will be the“Recipient”, the Party disclosing such
              information will be the “Discloser.” “Confidential Information” of Discloser means any
              and all information of Discloser or any of its licensors that has or will come into
              the possession or knowledge of the Recipient in connection with or as a result of
              entering into the Agreement, including information concerning the Discloser’s past,
              present or future customers, suppliers, technology or business, and where Discloser is
              Customer includes Customer Data; provided that Discloser’s Confidential Information
              does not include, except with respect to Personal Data: (i) information already known
              or independently developed by Recipient without access to Discloser’s Confidential
              Information; (ii) information that is publicly available through no wrongful act of
              Recipient; or (iii) information received by Recipient from a third party who was free
              to disclose it without confidentiality obligations.
            </li>
            <li>
              Confidentiality Covenants. Recipient hereby agrees that during the Term and at all
              times thereafter it will not, except to exercise its license rights or perform its
              obligations under the Agreement: (i) disclose Confidential Information of the
              Discloser to any person, except to its own personnel or affiliates having a “need to
              know” and that have entered into written agreements no less protective of such
              Confidential Information than the Agreement, and to such other Recipients as the
              Discloser may approve in writing; (ii) use Confidential Information of the Discloser;
              or (iii) alter or remove from any Confidential Information of the Discloser any
              proprietary legend. Each Party will take industry standard precautions to safeguard
              the other Party’s Confidential Information, which will in any event be at least as
              stringent as the precautions that the Recipient takes to protect its own Confidential
              Information of a similar type.
            </li>
            <li>
              Exceptions to Confidentiality. Notwithstanding Section 12(b), Recipient may disclose
              Discloser’s Confidential Information: (i) to the extent that such disclosure is
              required by applicable law or by the order of a court or similar judicial or
              administrative body, provided that, except to the extent prohibited by law, the
              Recipient promptly notifies the Discloser in writing of such required disclosure and
              cooperates with the Discloser to seek an appropriate protective order; (ii) to its
              legal counsel and other professional advisors if and to the extent such persons need
              to know such Confidential Information in order to provide applicable professional
              advisory services in connection with the Party’s business; or (iii) in the case of
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust as Recipient,
              to potential assignees, acquirers or successors of Payroll Ezy - Yogi Innovations Pty
              Ltd As Trustees For Yogi Unit Trust if and to the extent such persons need to know
              such Confidential Information in connection with a potential sale, merger,
              amalgamation or other corporate transaction involving the business or assets of
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust.
            </li>
          </ol>

          <p>
            <b>15. Warranty; Disclaimer; Indemnity</b>
          </p>
          <ol>
            <li>
              Customer Warranty. Customer represents and warrants to, and covenants with Payroll Ezy
              - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust that, Customer Data will
              only contain Personal Data in respect of which Customer has provided all notices and
              disclosures (including to each Organizational User), obtained all applicable
              third-party consents and permissions and otherwise has all authority, in each case as
              required by applicable laws, to enable Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust to provide the Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust SaaS Services, including with respect to the collection,
              storage, access, use, disclosure, processing and transmission of Personal Data,
              including by or to Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
              Trust and to or from all applicable third parties.
            </li>
            <li>
              No Advice. As part of the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
              Unit Trust SaaS Services, Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
              Unit Trust may offer notifications to Organizational Users related to compliance with
              labour laws (for example, calculations of overtime, violating split shift). This tool
              is for information purposes and does not constitute legal or professional advice.
              Customer expressly agrees that Customer is solely liable for compliance with all
              applicable labour laws and regulations.
            </li>
            <li>
              GENERAL DISCLAIMER. Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
              Trust DOES NOT WARRANT THAT THE Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For
              Yogi Unit Trust SAAS SERVICES WILL BE UNINTERRUPTED OR ERROR FREE OR THAT ALL ERRORS
              CAN OR WILL BE CORRECTED NOR DOES IT MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE
              OBTAINED FROM USE OF THE Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
              Unit Trust SAAS SERVICES. EXCEPT AS SPECIFICALLY PROVIDED IN THE AGREEMENT, THE
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SAAS SERVICES
              (OR ANY PART THEREOF), AND ANY OTHER PRODUCTS AND SERVICES PROVIDED BY Payroll Ezy -
              Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust TO CUSTOMER ARE PROVIDED “AS
              IS” AND “AS AVAILABLE.” NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY
              CUSTOMER FROM THE Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
              Trust SAAS SERVICES OR Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
              Unit Trust OR ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust SAAS SERVICES WILL CREATE ANY
              WARRANTY REGARDING Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
              Trust OR THE Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
              SAAS SERVICES THAT IS NOT EXPRESSLY STATED IN THE AGREEMENT. Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust IS NOT RESPONSIBLE FOR ANY DAMAGE
              THAT MAY RESULT FROM THE Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
              Unit Trust SAAS SERVICES AND CUSTOMER DEALING WITH ANY OTHER USER OF ANY Payroll Ezy -
              Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SAAS SERVICES. CUSTOMER USES
              ANY PORTION OF THE SERVICE AT CUSTOMER’S OWN DISCRETION AND RISK AND Payroll Ezy -
              Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust IS NOT RESPONSIBLE FOR ANY
              DAMAGE TO CUSTOMER’S PROPERTY (INCLUDING CUSTOMER’S COMPUTER SYSTEM OR MOBILE DEVICE
              USED IN CONNECTION WITH THE Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For
              Yogi Unit Trust SAAS SERVICE) OR ANY LOSS OF DATA. ANY REPRESENTATION OR WARRANTY OF
              OR CONCERNING ANY LICENSED THIRD-PARTY TECHNOLOGY IS STRICTLY BETWEEN CUSTOMER AND THE
              THIRD PARTY. TO THE EXTENT PERMITTED BY APPLICABLE LAW, Payroll Ezy - Yogi Innovations
              Pty Ltd As Trustees For Yogi Unit Trust HEREBY DISCLAIMS ALL EXPRESS, IMPLIED,
              COLLATERAL OR STATUTORY WARRANTIES, REPRESENTATIONS AND CONDITIONS, WHETHER WRITTEN OR
              ORAL, INCLUDING ANY IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, MERCHANTABLE
              QUALITY, COMPATIBILITY, TITLE, NON-INFRINGEMENT, SECURITY, RELIABILITY, COMPLETENESS,
              QUIET ENJOYMENT, ACCURACY, QUALITY, INTEGRATION OR FITNESS FOR A PARTICULAR PURPOSE OR
              USE, OR ANY WARRANTIES OR CONDITIONS ARISING OUT OF COURSE OF DEALING OR USAGE OF
              TRADE. WITHOUT LIMITING THE GENERALITY OF ANY OF THE FOREGOING, Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust EXPRESSLY DISCLAIMS ANY
              REPRESENTATION, CONDITION OR WARRANTY THAT ANY DATA OR INFORMATION PROVIDED TO
              CUSTOMER IN CONNECTION WITH CUSTOMER’S USE OF THE Payroll Ezy - Yogi Innovations Pty
              Ltd As Trustees For Yogi Unit Trust SAAS SERVICES (OR ANY PART THEREOF) IS ACCURATE,
              OR CAN OR SHOULD BE RELIED UPON BY CUSTOMER FOR ANY PURPOSE WHATSOEVER.
            </li>
            <li>
              Indemnity. Customer will defend, indemnify and hold harmless Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust, its employees, officers,
              directors, affiliates, agents, contractors, successors, and assigns against any and
              all third-party (including Organizational Users) claims (including damages,
              recoveries, deficiencies, interest, penalties, and legal fees), directly or indirectly
              arising from or in connection with: (i) Customer Data; (ii) Customer’s breach of any
              of Customer’s obligations, representations, warranties or covenants under the
              Agreement; (iii) use of the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For
              Yogi Unit Trust SaaS Services (or any part thereof) by Customer or any Organizational
              User in combination with any third-party software, application or service; or (iv) use
              of the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For aS Services in relation
              to compliance with applicable labour laws. Customer will fully cooperate with Payroll
              Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust in the defense of any
              claim defended by Customer pursuant to its indemnification obligations under the
              Agreement and will not settle any such claim without the prior written consent of
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust.
            </li>
          </ol>

          <p>
            <b>16. Limitation of Liabilities</b>
          </p>
          <p>
            The parties acknowledge that the following provisions have been negotiated by them and
            reflect a fair allocation of risk and form an essential basis of the bargain and will
            survive and continue in full force and effect despite any failure of consideration or of
            an exclusive remedy:
          </p>
          <ol>
            <li>
              AMOUNT. IN NO EVENT WILL THE TOTAL AGGREGATE LIABILITY OF Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust IN CONNECTION WITH OR UNDER THE
              AGREEMENT, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE OR GROSS NEGLIGENCE), OR
              OTHERWISE, EXCEED THE AMOUNT OF FEES PAID BY CUSTOMER FOR THE Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust SERVICES IN THE TWELVE-MONTH
              PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM. THE EXISTENCE OF ONE
              OR MORE CLAIMS UNDER THE AGREEMENT WILL NOT INCREASE THIS MAXIMUM LIABILITY AMOUNT. IN
              NO EVENT WILL Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust’
              THIRD-PARTY SUPPLIERS HAVE ANY LIABILITY ARISING OUT OF OR IN ANY WAY CONNECTED TO THE
              AGREEMENT.
            </li>
            <li>
              TYPE. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO EVENT WILL Payroll
              Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust BE LIABLE TO CUSTOMER
              OR ANY USER FOR ANY: (i) SPECIAL, EXEMPLARY, PUNITIVE, INDIRECT, INCIDENTAL OR
              CONSEQUENTIAL DAMAGES; (ii) LOST OR LOSS OF (A) SAVINGS, (B) PROFIT, (C) DATA, (D)
              USE, OR (E) GOODWILL; (iii) BUSINESS INTERRUPTION; (iv) COSTS FOR THE PROCUREMENT OF
              SUBSTITUTE PRODUCTS OR SERVICES; (v) PERSONAL INJURY OR DEATH; OR (vi) PERSONAL OR
              PROPERTY DAMAGE ARISING OUT OF OR IN ANY WAY CONNECTED TO THE AGREEMENT, REGARDLESS OF
              CAUSE OF ACTION OR THE THEORY OF LIABILITY, WHETHER IN CONTRACT, TORT (INCLUDING
              NEGLIGENCE OR GROSS NEGLIGENCE), OR OTHERWISE, AND EVEN IF NOTIFIED IN ADVANCE OF THE
              POSSIBILITIES OF SUCH DAMAGES.
            </li>
            <li>
              EACH PROVISION OF THE AGREEMENT THAT PROVIDES FOR A LIMITATION OF LIABILITY,
              DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS INTENDED TO AND DOES ALLOCATE THE
              RISKS BETWEEN THE PARTIES UNDER THE AGREEMENT. THIS ALLOCATION IS AN ESSENTIAL ELEMENT
              OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS SEVERABLE
              AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS OF SERVICE. THE LIMITATIONS IN
              THIS SECTION 14 WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
            </li>
          </ol>

          <p>
            <b>17. Term and Termination</b>
          </p>
          <ol>
            <li>
              Term. Unless terminated earlier in accordance with the Agreement, the Agreement is
              effective as of the effective date specified in the Order Form and will remain in
              effect for the Initial Term and any Renewal Terms specified in the Order Form
              (collectively, the “Term”).
            </li>
            <li>
              Termination for Cause. Either Party may, in addition to other relief, terminate the
              Agreement if the other Party commits a material breach of the Agreement and fails
              within 15 calendar days after receipt of notice of such breach to correct such
              material breach. In addition, Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For
              Yogi Unit Trust may terminate the Agreement immediately upon notice to Customer in the
              event Customer is in violation of Section 2 of the Agreement.
            </li>
            <li>
              Effect of Termination. Upon termination of the Agreement, Customer will immediately
              cease accessing or using the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For
              Yogi Unit Trust SaaS Services.
            </li>
            <li>
              Survival. The following Sections, together with any other provision of the Agreement
              which expressly or by its nature survives termination or expiration, or which
              contemplates performance or observance subsequent to termination or expiration of the
              Agreement, will survive expiration or termination of the Agreement for any reason:
              Section 3 (Data; Intellectual Property), Section 4 (Trademark License), Section 6
              (Privacy), Section 10 (Fees and Payment), Section 12 (Confidential Information),
              Section 13 (Warranty; Disclaimer; Indemnity), Section 14 (Limitation of Liabilities),
              Section 15(d) (Survival), and Section 16 (General Provisions).
            </li>
          </ol>

          <p>
            <b>18. General Provisions</b>
          </p>
          <ol>
            <li>
              Notices. Notices sent to either Party will be effective when delivered in writing and
              by email. Notices must be sent:
              <ol>
                <li>
                  if to Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust, to
                  the following address: Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
                  Unit, Australia Email:
                  <a href="mailto:info@yogiinnovation.com">info@yogiinnovation.com</a>
                </li>
                <li>
                  if to Customer, to the current email address that Payroll Ezy - Yogi Innovations
                  Pty Ltd As Trustees For Yogi Unit Trust has on file with respect to Customer.
                  Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust may change
                  its contact information by posting the new contact information on the Website or
                  by giving notice thereof to Customer. Customer is solely responsible for keeping
                  its contact information on file with Payroll Ezy - Yogi Innovations Pty Ltd As
                  Trustees For Yogi Unit Trust current at all times during the Term.
                </li>
              </ol>
            </li>

            <li>
              Assignment. Customer will not assign the Agreement to any third party without Payroll
              Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust’ prior written consent.
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust may assign the
              Agreement or any rights under the Agreement to any third party without Customer’s
              consent. The Agreement will inure to the benefit of and be binding upon the parties,
              their permitted successors and permitted assignees.
            </li>

            <li>
              {' '}
              Governing Law And Jurisdiction{' '}
              <ol>
                <li>
                  This Agreement and any action related thereto will be governed by and construed in
                  accordance with the laws of the Queensland, Australia and the federal laws of
                  Australia applicable therein, without regard to conflicts of law principles. The
                  U.N. Convention on Contracts for the International Sale of Goods will not apply to
                  the Agreement.
                </li>
              </ol>
            </li>

            <li>
              Dispute Resolution
              <ol>
                <li>
                  Subject to the Section 16(d)(iii), if any dispute arises between the parties
                  relating to the application, interpretation, implementation or validity of the
                  Agreement, the parties agree to resolve the dispute by arbitration using the
                  Australian Arbitration Association Expedited Arbitration Rules. The parties agree
                  that the Australian Arbitration Association Expedited Arbitration Rules give the
                  parties a fair opportunity to present their case and respond to the case of the
                  other side. The arbitration shall be held in Queensland and shall proceed in
                  accordance with the provisions of the Arbitration Act (Queensland). Judgement upon
                  the award rendered by the arbitrator may be entered in any court having
                  jurisdiction.
                </li>
                <li>
                  Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust may bring
                  the following actions in a court of competent jurisdiction in the Queensland or,
                  at Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust’
                  discretion, in the jurisdiction of incorporation of Customer: (A) any Fee
                  collection disputes arising out of the failure to pay by Customer; or (B) an
                  action seeking injunctive relief with respect to a violation of intellectual
                  property rights or confidentiality obligations.
                </li>
                <li>
                  Each of the parties to the Agreement: (A) irrevocably and unconditionally consents
                  and submits to the jurisdiction of such courts in any such action; (B) consents to
                  service of process in accordance with the rules governing proceedings in any such
                  court; and (C) irrevocably waives and covenants not to assert any objection to the
                  laying of venue in any such court in any such action.
                </li>
              </ol>
            </li>

            <li>
              Export Restrictions. Customer will comply with all export laws and regulations that
              may apply to its access to or use of the Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust SaaS Services.
            </li>
            <li>
              Consent to Electronic Communications. By using the Payroll Ezy - Yogi Innovations Pty
              Ltd As Trustees For Yogi Unit Trust SaaS Services, Customer consents to receiving
              certain electronic communications from Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust as further described in our Privacy Policy. Please read
              our Privacy Policy to learn more about our electronic communications practices.
              Customer agrees that any notices, agreements, disclosures, or other communications
              that Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust sends to
              you electronically will satisfy any legal communication requirements, including that
              those communications be in writing.
            </li>
            <li>
              No Support. Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust is
              under no obligation to provide support for the Payroll Ezy - Yogi Innovations Pty Ltd
              As Trustees For Yogi Unit Trust SaaS Services. In instances where we may offer
              support, the support will be subject to published policies.
            </li>
            <li>
              Construction. Except as otherwise provided in the Agreement, the parties’ rights and
              remedies under the Agreement are cumulative and are in addition to, and not in
              substitution for, any other rights and remedies available at law or in equity or
              otherwise. The terms “include” and “including” mean, respectively, “include without
              limitation” and “including without limitation.” The headings of sections of the
              Agreement are for reference purposes only and have no substantive effect. The terms
              “consent” or “discretion” mean the right of a Party to withhold such consent or
              exercise such discretion, as applicable, arbitrarily and without any implied
              obligation to act reasonably or explain its decision to the other Party.
            </li>
            <li>
              Force Majeure. Neither Party will be liable for delays caused by any event or
              circumstances beyond that Party’s reasonable control, including acts of God, acts of
              government, flood, fire, earthquakes, civil unrest, acts of terror, strikes or other
              labour problems (other than those involving that Party’s employees), Internet service
              failures or delays, or the unavailability or Modification by third parties of
              telecommunications or hosting infrastructure or third-party websites (“Force
              Majeure”).
            </li>
            <li>
              Severability. Any provision of the Agreement found by a tribunal or court of competent
              jurisdiction to be invalid, illegal, or unenforceable will be severed from the
              Agreement and all other provisions of the Agreement will remain in full force and
              effect.
            </li>
            <li>
              Waiver. A waiver of any provision of the Agreement must be in writing and a waiver in
              one instance will not preclude enforcement of such provision on other occasions.
            </li>
            <li>
              Additional Terms. Customer’s use of the Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust SaaS Services is subject to all additional terms,
              policies, rules, or guidelines applicable to the Payroll Ezy - Yogi Innovations Pty
              Ltd As Trustees For Yogi Unit Trust SaaS Services or certain features of the Payroll
              Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services that
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust may post on or
              link to from the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
              Trust SaaS Services (the “Additional Terms”). All Additional Terms are incorporated by
              this reference into, and made a part of, the Agreement.
            </li>
            <li>
              Independent Contractors. Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
              Unit Trust’ relationship to Customer is that of an independent contractor, and neither
              Party is an agent or partner of the other. Neither Party will have, and neither Party
              will represent to any third party that it has, any authority to act on behalf of the
              other Party.
            </li>
            <li>
              Entire Agreement. The Agreement constitutes the entire agreement between the parties
              with respect to the subject matter of the Agreement and supersedes all prior or
              contemporaneous agreements, representations, or other communications, whether oral or
              written. If there is a conflict or inconsistency between any of the terms of the
              Agreement, including the Order Form and the Terms of Service, then the conflict or
              inconsistency will be resolved by giving those terms the following order of descending
              precedence: (a) the Order Form; and (b) the Terms of Service.
            </li>
            <li>
              Amendments. Subject to the following sentence, no amendment, supplement, modification,
              waiver, or termination of the Agreement and, unless otherwise expressly specified in
              the Agreement, no consent or approval by any Party, will be binding unless executed in
              writing by the Party or parties to be bound thereby. NOTWITHSTANDING THE PRECEDING
              SENTENCE, Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust MAY
              UNILATERALLY AMEND THESE TERMS OF SERVICE, IN WHOLE OR IN PART (EACH, AN“AMENDMENT”),
              BY: (i) GIVING CUSTOMER NOTICE OF SUCH AMENDMENT; OR (ii) POSTING NOTICE OF SUCH
              AMENDMENT ON THE WEBSITE. UNLESS OTHERWISE INDICATED BY Payroll Ezy - Yogi Innovations
              Pty Ltd As Trustees For Yogi Unit Trust, ANY SUCH AMENDMENT WILL BECOME EFFECTIVE AS
              OF THE DATE THE NOTICE OF SUCH AMENDMENT IS PROVIDED TO CUSTOMER OR IS POSTED ON THE
              WEBSITE (WHICHEVER IS THE EARLIER).
            </li>
            <li>
              English Language. It is the express wish of the parties that the Agreement and all
              related documents be drawn up in English. C’est la volonté expresse des parties que la
              présente convention ainsi que les documents qui s’y rattachent soient rédigés en
              anglais.
            </li>
            <li>
              International Use. The Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
              Unit Trust SaaS Services is intended for Customers located within the Australia and
              New Zealand. Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
              makes no representation that the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees
              For Yogi Unit Trust SaaS Services is appropriate or available for use outside of the
              Australia or New Zealand. Access to the Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust SaaS Services from countries or territories or by
              individuals where such access is illegal is prohibited.
            </li>
            <li>
              Notice Regarding Apple. This Section only applies to the extent Customer is using our
              mobile application on an iOS device. Customer acknowledges that these Terms of Service
              are between Customer and Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
              Unit Trust only, not with Apple. (“Apple”), and Apple is not responsible for the
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services
              or the content of it. Apple has no obligation to furnish any maintenance and support
              services with respect to the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For
              Yogi Unit Trust SaaS Services. If the Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust SaaS Services fails to conform to any applicable
              warranty, Customer may notify Apple, and Apple will refund any applicable purchase
              price for the mobile application to Customer. To the maximum extent permitted by
              applicable law, Apple has no other warranty obligation with respect to the Payroll Ezy
              - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services. Apple is not
              responsible for addressing any claims by Customer or any third party relating to the
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services
              or Customer’s possession and/or use of the Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust SaaS Services, including: (i) product liability claims;
              (ii) any claim that the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
              Unit Trust SaaS Services fails to conform to any applicable legal or regulatory
              requirement; or (iii) claims arising under consumer protection or similar legislation.
              Apple is not responsible for the investigation, defense, settlement, and discharge of
              any third-party claim that the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For
              Yogi Unit Trust SaaS Services and/or Customer’s possession and use of the Payroll Ezy
              - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services infringe a
              third party’s intellectual property rights. Customer agrees to comply with any
              applicable third-party terms when using the Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust SaaS Services. Apple and Apple’s subsidiaries are
              third-party beneficiaries of these Terms of Service, and upon Customer’s acceptance of
              these Terms of Service, Apple will have the right (and will be deemed to have accepted
              the right) to enforce these Terms of Service against Customer as a third-party
              beneficiary of these Terms of Service. Customer hereby represents and warrants that:
              (i) Customer is not located in a country that is subject to a AUSTRALIA Government
              embargo or that has been designated by the AUSTRALIA Government as a “terrorist
              supporting” country; and (ii) Customer is not listed on any AUSTRALIA Government list
              of prohibited or restricted parties
            </li>
          </ol>

          <p>End of Terms of Service</p>

          <div className="title">
            Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust TERMS OF USE –
            TERMS APPLICABLE TO USERS
          </div>

          <p>
            The following terms of use (the “Terms of Use”) govern your access to and use of the
            software and services we make available that reference these terms including our mobile
            applications and our websites along with our related websites, hosted applications,
            mobile or other downloadable applications, and other services provided by us
            (collectively, the “Services”). These Terms of Use form an agreement between you and
            Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust. (together,
            “Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust”. “us”, “we”,
            “our”). The term “you” refers to the person browsing, installing, downloading,
            accessing, or otherwise using the Services (“use” or“using” in these Terms of Use will
            mean any of the foregoing).
          </p>

          <p>
            You may be an individual user of the Services or may be using the services on behalf of
            an organization or other third party that we refer to in these Terms of Use
            as“Customer”. In the event you are using the Services on behalf of a Customer, you
            acknowledge that such Customer has separately agreed to Terms of Service or has entered
            into an agreement (each, the “Contract”) that permits Customer to access, and provide
            its personnel with access, to the Services (each individual granted access to the
            Services, including you, is an “Organizational User”). The Contract contains our
            commitment to deliver the Services to Customer, who may then obtain user accounts for
            each individual Organizational User.
          </p>

          <p>
            <b>
              BY USING THE SERVICES, YOU: (A) REPRESENT AND WARRANT THAT (I) YOU HAVE THE CAPACITY
              TO ENTER INTO BINDING OBLIGATIONS OR IF YOU ARE UNDER THE AGE OF MAJORITY HAVE THE
              AGREEMENT FROM YOUR LEGAL GUARDIAN TO ENTER INTO THIS AGREEMENT AND (II) ALL
              INFORMATION SUPPLIED BY YOU TO US THROUGH THE SERVICES IS TRUE, ACCURATE, CURRENT, AND
              COMPLETE AND (B) AGREE TO BE BOUND BY AND COMPLY WITH THESE TERMS OF USE, AS UPDATED
              FROM TIME TO TIME IN ACCORDANCE WITH SECTION 1.
            </b>
          </p>

          <p>
            <b>1. Changes to these Terms of Use and the Services</b>
          </p>
          <ol>
            <li>
              Except where prohibited by applicable law, we reserve the right to change these Terms
              of Use at any time by posting a new version in the Services. It is your obligation to
              monitor the Services for any such changes. Your continued access to or use of the
              Services after any changes to these Terms of Use indicates your acceptance of such
              changes. It is your responsibility to review these Terms of Use regularly.
            </li>
            <li>
              We reserve the right to change the Services at any time, without notice. We may, at
              our discretion, suspend your access to or use of the Services or any component
              thereof: (i) for scheduled maintenance; (ii) if you violate any provision of these
              Terms of Use; (iii) to prevent interference with the availability of the Services; or
              (iv) to address any emergency security concerns. We reserve the right to remove, edit,
              limit, or block access to any data, information, records, and files that you load,
              transmit to, or enter into, or that we collect from, the Services (collectively, “User
              Data”) at any time, and we have no obligation to display or review your User Data.
            </li>
          </ol>

          <p>
            <b>2. Other Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust</b>
          </p>
          <p>
            <b>Agreements; Additional Terms</b>
          </p>
          <p>
            These Terms of Use do not alter in any way the terms or conditions of any other
            agreement that may apply to your use of the Services, including the Contract (the“Other
            Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust Agreements”). If
            there is a conflict or inconsistency between the Other Payroll Ezy - Yogi Innovations
            Pty Ltd As Trustees For Yogi Unit Trust Agreements and these Terms of Use, then the
            provisions of the Other Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
            Trust Agreements will govern to the extent of such conflict or inconsistency.
          </p>

          <p>
            <b>3. Privacy</b>
          </p>
          <ol>
            <li>
              Please review our current Privacy Policy, available at our website, which contains
              important information about our practices in collecting, storing, using, and
              disclosing information about identifiable individuals (<b>“Personal Information”</b>),
              and which is hereby incorporated into and forms a part of these Terms of Use.
            </li>
            <li>
              You represent and warrant to us that any data, information, records, and files that
              you load, transmit to, or enter into the Services will only contain Personal
              Information in respect of which you have provided all necessary notices and
              disclosures, obtained all applicable third-party consents and permissions and
              otherwise have all authority, in each case as required by applicable laws, to enable
              us to make available the Services.
            </li>
          </ol>

          <p>
            <b>4. Customer Data</b>
          </p>
          <p>
            When you submit content or information to the Services on behalf of a Customer (
            <b>“Customer Data”</b>), you acknowledge and agree that Customer retains all of its
            rights, title, and interest, including all intellectual property rights, in and to
            Customer Data, and the Contract provides Customer with many choices and control over
            that Customer Data. You also acknowledge, agree and consent to us using Customer Data in
            order to provide the Services, including sharing Customer Data with our third-party
            sub-contractors.
          </p>

          <p>
            <b>5. Feedback</b>
          </p>
          <p>
            Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust may use any
            suggestions, comments or other feedback relating to any aspect of the Services (
            <b>“Feedback”</b>), in or to improve Payroll Ezy - Yogi Innovations Pty Ltd As Trustees
            For Yogi Unit Trust Offerings. Accordingly, you agree that:
          </p>
          <ol>
            <li>
              Feedback is not your confidential or proprietary information or that of any third
              party and you have all of the necessary rights to disclose the Feedback to Payroll Ezy
              - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust;
            </li>
            <li>
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust (including all
              of its successors and assigns and any successors and assigns of any of the Payroll Ezy
              - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust Offerings) may freely use,
              reproduce, publicize, license, distribute, and otherwise commercialize Feedback in any
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust Offerings; and
            </li>
            <li>
              you are not entitled to receive any compensation or reimbursement of any kind from
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust or any of the
              other users of the Services in respect of the Feedback.
            </li>
          </ol>

          <p>
            <b>6. Ownership of the Services</b>
          </p>
          <p>
            All rights, title, and interest, including intellectual property rights, in the Services
            and all other materials provided by us hereunder, and any update, adaptation,
            translation, customization or derivative work thereof, will remain with us (or our
            third-party suppliers, if applicable). The Services and all materials provided by us
            hereunder are made available or licensed and not “sold” to you. All rights not expressly
            granted to you in these Terms of Use are reserved.
          </p>

          <p>
            <b>7. User Account for the Services</b>
          </p>
          <p>
            To access certain features of the Services, you may be required to successfully sign up
            for a user account using the available interfaces of the Services or third-party single
            sign on services (the “User ID”). You will keep your User ID secure and will not share
            or grant access to your User ID with anyone else. We reserve the right to disable any
            User ID issued to you at any time in our sole discretion. If we disable access to a User
            ID issued to you, you may be prevented from accessing the Services. Certain information
            will be shared with Customer and Customer may be able to change and update your user
            account settings.
          </p>

          <p>
            <b>8. Digital Tip Disbursement</b>
          </p>
          <ol>
            <li> Digital Tip Reimbursement is currently not available.</li>
          </ol>

          <p>
            <b>9. Payroll Processing Services</b>
          </p>
          <ol>
            <li>
              Payroll Processing Services. You hereby authorize Payroll Ezy - Yogi Innovations Pty
              Ltd As Trustees For Yogi Unit Trust to provide you with payroll processing services
              subject to an agreement between Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For
              Yogi Unit Trust and the Customer with whom you are associated (“Payroll Processing
              Services”).
            </li>
            <li>
              Customer Obligations: You hereby agree to:
              <ol>
                <li>
                  provide information as reasonably requested by Payroll Ezy - Yogi Innovations Pty
                  Ltd As Trustees For Yogi Unit Trust in relation to its provision of Payroll
                  Processing Services, including without limitation, all information required by
                  Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust and its
                  service providers and bank to facilitate payroll payments to taxing authorities
                  and documents relating to prior payroll payments. You agree to ensure all
                  information provided to Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For
                  Yogi Unit Trust is accurate, timely and complete. For the purposes of these Terms
                  of Use, such information provided by you shall be considered Customer Data (as
                  defined above);
                </li>
                <li>
                  immediately provide to Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
                  Unit Trust the revised Customer Data in the event of any changes or updates. You
                  hereby waive and release any claim against Payroll Ezy - Yogi Innovations Pty Ltd
                  As Trustees For Yogi Unit Trust arising out of or relating to any errors or
                  omissions in the Customer Data, including without limitation, payroll information
                  which you have not corrected or have not requested Payroll Ezy - Yogi Innovations
                  Pty Ltd As Trustees For Yogi Unit Trust to correct in an appropriate and timely
                  manner;
                </li>
                <li>
                  provide all information to Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For
                  Yogi Unit Trust and its service providers to satisfy due diligence obligations as
                  required by applicable laws, in accordance with Payroll Ezy - Yogi Innovations Pty
                  Ltd As Trustees For Yogi Unit Trust’, applicable service provider’s or bank’s
                  policies and procedures;
                </li>
                <li>
                  cooperate with Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
                  Trust in investigating any fraudulent or illegal transactions;
                </li>
                <li>
                  remit any federal, state, and local liabilities incurred prior to enrolling in the
                  Payroll Processing Services and to submit any payroll returns to tax agencies that
                  were due for payroll tax liabilities incurred before using the Payroll Processing
                  Services.
                </li>
              </ol>
            </li>

            <li>
              Suspension of Payroll Processing Services: You agree and acknowledge that Payroll Ezy
              - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust may, in its sole
              discretion, suspend or terminate provision of Payroll Processing Services when doing
              so would reasonably be expected to mitigate an actual or suspected security breach or
              threat, or in the event Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
              Unit Trust or its service providers determine that the provision of Payroll Processing
              Services would violate applicable laws or create undue risk for Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust or its service providers.
            </li>
            <li>
              Errors in Customer Data: You agree and acknowledge Payroll Ezy - Yogi Innovations Pty
              Ltd As Trustees For Yogi Unit Trust and its service providers rely on information
              furnished by Customer and you to perform the Payroll Processing Services. Payroll Ezy
              - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust is not responsible or
              liable for any errors or inaccuracies in information furnished by you and/or Customer
              (including its employees and/or independent contractors), or Customer’s failure to
              maintain original documents as legally required.
            </li>
            <li>
              Disclaimer; Representations and Warranties; Consents:
              <ol>
                <li>
                  You acknowledge and agree that (1) in performing the Payroll Processing Services,
                  Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust is not in a
                  fiduciary capacity for the Customer; (2) you should not construe any information
                  provided directly or indirectly by Payroll Ezy - Yogi Innovations Pty Ltd As
                  Trustees For Yogi Unit Trust through provision of Payroll Processing Services to
                  be legal, tax, or accounting advice.
                </li>
                <li>
                  You represent and warrant that: (1) you are not subject to sanctions by the Office
                  of Foreign Assets Control of the AUSTRALIA Department of the Treasury, businesses
                  and individuals on other government-maintained sanctions lists, or businesses or
                  individuals in Cuba, Iran, North Korea, the Crimea Region, or Syria; (2) no
                  payroll information submitted by you will result in entries that would violate the
                  sanctions program of any AUSTRALIA or Australian government agency or any other
                  applicable laws, rules, or regulations.
                </li>
              </ol>
            </li>
          </ol>

          <p>
            <b>10. Bank Accounts and Authorizations</b>
          </p>
          <ol>
            <li>
              You hereby authorize Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
              Trust and its authorized third parties to initiate debit or credit entries to your
              bank account (“Bank Account”) at the depository financial institution you provide to
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust and its service
              providers (the “Bank”). You agree to provide Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust, its service providers and/or the Bank, as applicable,
              with any additional authorizations or information such party may require in relation
              to the provision of Services. These authorizations will remain in full force and
              effect until you provide Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
              Unit Trust with written notice of termination of any such authorizations in such time
              and such manner as to afford Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For
              Yogi Unit Trust, its service providers and the Bank a reasonable opportunity to act
              upon such notice. You acknowledge that the failure to provide these authorizations or
              the termination of the authorizations for any reason may result in Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust’ immediate termination of your
              access to the impacted Services. You authorize Payroll Ezy - Yogi Innovations Pty Ltd
              As Trustees For Yogi Unit Trust and its authorized third party service providers to
              use Customer Data related to the provision of Services.
            </li>
            <li>
              You hereby agree that Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
              Trust shall not be responsible for determining whether your bank accounts have deposit
              or withdrawal restrictions
            </li>
          </ol>

          <p>
            <b>11. Your Responsibilities</b>
          </p>
          <p>You agree to:</p>
          <ol>
            <li>
              comply with all applicable laws and regulations, including, but not limited to, all
              intellectual property, data, privacy any export control laws;
            </li>
            <li>
              upload and disseminate only Customer Data which Customer owns all required rights
              under law and do so consistent with applicable law;
            </li>
            <li>
              use reasonable efforts to prevent unauthorized access to or use of the Services;
            </li>
            <li>keep User IDs and all other login information confidential;</li>
            <li>
              monitor and control all activity conducted through your account in connection with the
              Services; and
            </li>
            <li>
              promptly notify us and Customer if you become aware or reasonably suspect any illegal
              or unauthorized activity or a security breach involving your account, including any
              loss, theft, or unauthorized disclosure or use of a User ID or account.
            </li>
          </ol>

          <p>
            <b>12. No Unlawful or Prohibited Use</b>
          </p>
          <p>
            You will not use the Services in violation of these Terms of Use or of any applicable
            law. You will not, without our prior written permission, use the Services for any
            purpose other than to access and use the software and services we make available through
            the Services. Without limiting the generality of the foregoing, you will not (and will
            not attempt to) directly or indirectly:
          </p>
          <ol>
            <li>
              send, upload, collect, transmit, store, use, post, publish, or otherwise communicate
              on the Services any data, information, pictures, videos, audio or other materials or
              content that: (i) contains any computer viruses, worms, malicious code, or any
              software intended to damage or alter a computer system or data; (ii) you do not have
              the lawful right to send, upload, collect, transmit, store, use, post, publish, or
              otherwise communicate; (iii) is false, intentionally misleading, or impersonates any
              other person; (iv) contains unsolicited or unauthorized advertising, solicitations for
              business, promotional materials, “junk mail,” “spam”, “chain letters,” “pyramid
              schemes,” or any other form of solicitation; (v) is harmful, threatening, abusive,
              harassing, tortuous, defamatory, vulgar, obscene, pornographic, libelous, invasive to
              another’s privacy, hateful, racially or ethnically objectionable, encourages criminal
              behaviour, gives rise to civil liability, or is otherwise objectionable; (vi) is
              harmful to minors in any way or targeted at minors; (vii) infringes, violates or
              otherwise misappropriates the intellectual property or other rights of any third party
              (including any moral right, privacy right or right of publicity); or (viii) encourages
              any conduct that may violate any applicable laws or would give rise to civil or
              criminal liability;
            </li>
            <li>
              share, transfer or otherwise provide access to an account designated for you to
              another person;
            </li>
            <li>
              disable, overly burden, impair, or otherwise interfere with servers or networks
              connected to the Services (e.g., a denial-of-service attack);
            </li>
            <li>attempt to gain unauthorized access to the Services;</li>
            <li>
              use any data mining, robots, or similar data gathering or extraction methods, or copy,
              modify, reverse engineer, reverse assemble, disassemble, or decompile the Services or
              any part thereof or otherwise attempt to discover any source code;
            </li>
            <li>
              use the Services for the purpose of building a similar or competitive product or
              service;
            </li>
            <li>
              use the Services other than for the benefit of Customer that has entered into an
              agreement to access the Services, and who has granted you a right of access; or
            </li>
            <li>
              authorize, permit, enable, induce, or encourage any third party to do the above.
            </li>
          </ol>

          <p>
            <b>13. Third Party Content, Websites or Services</b>
          </p>
          <p>
            The Services may provide links or access to third party content, websites, or services.
            Likewise, we may allow you to access the Services from third party systems. Payroll Ezy
            - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust does not endorse any
            third-party content, websites, services, or systems, or guarantee their quality,
            accuracy, reliability, completeness, currency, timeliness, non-infringement,
            merchantability, or fitness for any purpose. Third party content, websites, services, or
            systems are not under Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
            Trust’ control, and if you choose to access any such content, websites, or services, or
            to access the Services from such systems, you do so entirely at your own risk. You
            acknowledge that you may be required to accept terms of use applicable to third party
            content, websites, services, or systems and agree to accept and comply with any such
            terms of use. You further acknowledge that by providing access to third party systems
            that Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust may import
            data from those systems into Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
            Unit Trust and such data will be considered to be part of Customer Data and third-party
            systems may have access and copy Customer Data and the use of such data is outside the
            control of Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust.
          </p>

          <p>
            <b>14. Malicious Code and Security</b>
          </p>
          <p>
            The downloading and viewing of content are done at your own risk. We do not guarantee or
            warrant that the Services is compatible with your computer system or that the Services,
            or any links from the Services, will be free of viruses, worms, trojan horses or
            disabling devices or other code that manifests contaminating or destructive properties.
            You are responsible for implementing safeguards to protect the security and integrity of
            your computer system, and you are responsible for the entire cost of any service,
            repairs, or connections of and to your computer system that may be necessary as a result
            of your use of the Services.
          </p>
          <p>
            You are prohibited from attempting to circumvent and from violating the security of the
            Services including without limitation: (a) accessing content that is not intended for
            you; (b) attempting to breach or breaching the Services security or authentication
            measures; (c) restricting, disrupting or disabling service to the Services users, hosts,
            servers or networks by any means, or (d) otherwise attempting to interfere with the
            proper working of the Services, including but not limited to by introducing any material
            that is malicious or technologically harmful.
          </p>

          <p>
            <b>15. Communications Not Confidential</b>
          </p>
          <p>
            We do not guarantee the confidentiality of any communications made by you through the
            Services. We do not guarantee the security of data transmitted over the Internet or
            public networks in connection with your use of the Services.
          </p>

          <p>
            <b>16. Disclaimer</b>
          </p>
          <p>
            THE LAWS OF CERTAIN JURISDICTIONS, INCLUDING QUEBEC, DO NOT ALLOW THE EXCLUSION OR
            LIMITATION OF LEGAL WARRANTIES, CONDITIONS OR REPRESENTATIONS. IF THESE LAWS APPLY TO
            YOU, SOME OR ALL OF THE EXCLUSIONS OR LIMITATIONS IN THESE TERMS OF USE (INCLUDING THE
            FOLLOWING DISCLAIMERS) MAY NOT APPLY AND YOU MAY HAVE ADDITIONAL RIGHTS.
          </p>
          <p>
            YOU ACKNOWLEDGE, UNDERSTAND, AND AGREE THAT THE SERVICES IS PROVIDED “AS IS” AND “AS
            AVAILABLE”, WITH ALL FAULTS AND WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND. TO THE
            FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, REPRESENTATIONS
            AND CONDITIONS OF ANY KIND WITH RESPECT TO THE SERVICES WHETHER EXPRESS, IMPLIED,
            STATUTORY OR COLLATERAL, INCLUDING, WITHOUT LIMITATION, THE WARRANTIES AND CONDITIONS OF
            MERCHANTABILITY, MERCHANTABLE QUALITY, COMPATIBILITY, TITLE, SECURITY, RELIABILITY,
            COMPLETENESS, QUIET ENJOYMENT, ACCURACY, RELIABILITY, CURRENCY, TIMELINESS, QUALITY,
            INTEGRATION, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT, OR ANY WARRANTIES OR
            CONDITIONS ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE, OR THAT THE SERVICES IS
            OR WILL BE ERROR-FREE OR WILL OPERATE WITHOUT INTERRUPTION.
          </p>

          <p>
            <b>17. Limitation of Liability</b>
          </p>
          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL WE BE LIABLE,
            WHETHER BASED ON WARRANTY, CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR ANY OTHER
            LEGAL THEORY, FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR
            PUNITIVE DAMAGES; OR LOST PROFITS, LOSS OF USE, LOSS OF DATA, PERSONAL INJURY, FINES,
            FEES, PENALTIES OR OTHER LIABILITIES, IN EACH CASE, WHETHER OR NOT WE WERE ADVISED OF
            THE POSSIBILITY OF SUCH DAMAGES, RESULTING FROM OR RELATED TO THE SERVICES OR THESE
            TERMS OF USE.
          </p>
          <p>
            FOR ANY OTHER DAMAGES, OR TO THE EXTENT THAT THE FOREGOING LIMITATION IS NOT PERMITTED
            BY APPLICABLE LAW, IN NO EVENT WILL OUR TOTAL AGGREGATE LIABILITY IN CONNECTION WITH OR
            UNDER THESE TERMS OF USE, OR YOUR USE OF, OR INABILITY TO MAKE USE OF, THE SERVICES
            EXCEED $100 AUD. FOR GREATER CERTAINTY, THE EXISTENCE OF ONE OR MORE CLAIMS UNDER THESE
            TERMS OF USE WILL NOT INCREASE THIS MAXIMUM LIABILITY AMOUNT.
          </p>
          <p>
            ANY REMEDIES AVAILABLE WITH REGARD TO THE SERVICES ARE AS SET OUT IN THE CONTRACT WITH
            CUSTOMER.
          </p>

          <p>
            <b>18. Indemnification</b>
          </p>
          <p>
            You will defend, indemnify, and hold harmless us, our affiliates and service providers,
            and each of their and our respective officers, directors, employees, agents, and any
            licensees, successors and assigns from and against any claims, causes of action,
            demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any
            kind or nature including reasonable legal and accounting fees, arising out of or in
            connection with:
          </p>
          <ol>
            <li>
              your breach of any provision of these Terms of Use or any documents referenced herein;
            </li>
            <li>
              your violation of any law or the rights of a third party (including intellectual
              property rights); or
            </li>
            <li> your use or the use by any third party using your User ID of the Services.</li>
          </ol>

          <p>
            <b>19. Geographical Restrictions</b>
          </p>
          <p>
            Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust makes no
            representation that the Services is available for use in locations outside Australia and
            the New Zealand or all locations within Australia and the New Zealand. This site is not
            intended for use in any jurisdiction where its use is not permitted. If you access the
            site from outside Australia or the New Zealand, you do so at your own risk and you are
            responsible for compliance with local laws of your jurisdiction.
          </p>

          <p>
            <b>20. General Provisions</b>
          </p>
          <ol>
            <li>
              Notices. Notices sent to either party will be effective when delivered in writing and
              by email. Notices must be sent to
              <a href="mailto:info@yogiinnovation.com">info@yogiinnovation.com</a>
            </li>
            <li>
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust may change its
              contact information by posting the new contact information on the Website or by giving
              notice thereof to you. You are solely responsible for keeping your contact information
              on file with Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
              current at all times during the Term.
            </li>
            <li>
              Choice of Law:
              <ol>
                <li>
                  If you are a resident of any country outside of the Australia, then except as
                  restricted by applicable law, these Terms of Use will be governed by the laws of
                  the Queensland and the federal laws of Australia applicable therein and such laws
                  apply to your access to or use of the Services, notwithstanding your domicile,
                  residency, or physical location. You will only use the Services in jurisdictions
                  where the Services may lawfully be used. The U.N. Convention on Contracts for the
                  International Sale of Goods will not apply to these Terms of Use. This choice of
                  jurisdiction does not prevent us from seeking injunctive relief with respect to a
                  violation of intellectual property rights or confidentiality obligations in any
                  appropriate jurisdiction.
                </li>
              </ol>
            </li>

            <li>
              Dispute Resolution:
              <ol>
                <li>
                  Collection Disputes. Any Fee collection disputes arising out of the failure to pay
                  by Customer will be commenced in and determined by a court of competent
                  jurisdiction in the Queensland or, at Payroll Ezy - Yogi Innovations Pty Ltd As
                  Trustees For Yogi Unit Trust’ discretion, in the jurisdiction of incorporation of
                  Customer. Each of the parties to the Agreement: (A) irrevocably and
                  unconditionally consents and submits to the jurisdiction of such courts in any
                  such action; (B) consents to service of process in accordance with the rules
                  governing proceedings in any such court; and (C) irrevocably waives and covenants
                  not to assert any objection to the laying of venue in any such court in any such
                  action.
                </li>
                <li>
                  Arbitration. If any dispute arises between the parties relating to the
                  application, interpretation, implementation or validity of the Agreement, the
                  parties agree to resolve the dispute by arbitration using the Australian
                  Arbitration Association Expedited Arbitration Rules. The parties agree that the
                  Australian Arbitration Association Expedited Arbitration Rules give the parties a
                  fair opportunity to present their case and respond to the case of the other side.
                  The arbitration shall be held in Queensland and shall proceed in accordance with
                  the provisions of the Arbitration Act (Queensland). Judgement upon the award
                  rendered by the arbitrator may be entered in any court having jurisdiction.
                </li>
              </ol>
            </li>

            <li>
              No Support. Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust is
              under no obligation to provide support for the Service. In instances where we may
              offer support, the support will be subject to published policies.
            </li>
            <li>
              {' '}
              Force Majeure. Neither party will be liable for delays caused by any event or
              circumstances beyond that party’s reasonable control, including acts of God, acts of
              government, flood, fire, earthquakes, civil unrest, acts of terror, strikes or other
              labour problems (other than those involving that party’s employees), Internet service
              failures or delays, or the unavailability or modification by third parties of
              telecommunications or hosting infrastructure or third-party websites (“Force
              Majeure”).
            </li>
            <li>
              Additional Terms. Your use of the Service is subject to all additional terms,
              policies, rules, or guidelines applicable to the Service or certain features of the
              Service that Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
              may post on or link to from the Service (the“Additional Terms”). All Additional Terms
              are incorporated by this reference into, and made a part of, these Terms of Use.
            </li>
            <li>
              Entire Agreement. These Terms of Use constitute the entire agreement between you and
              us pertaining to the subject matter hereof and supersede all prior or contemporaneous
              communications and proposals, whether electronic, oral, or written, between you and us
              with respect to the Services. A printed version of these Terms of Use and of any
              notice given in electronic form will be admissible in judicial or administrative
              proceedings based upon or relating to these Terms of Use to the same extent and
              subject to the same conditions as other business documents and records originally
              generated and maintained in printed form.
            </li>
            <li>
              Waiver. Our failure to insist upon or enforce strict performance of any provision of
              these Terms of Use will not be construed as a waiver of any provision or right. A
              waiver of any provision of these Terms of Use must be in writing and a waiver in one
              instance will not preclude enforcement of such provision on other occasions.
            </li>
            <li>
              Severable. If any of the provisions contained in these Terms of Use are determined to
              be void, invalid or otherwise unenforceable by a court of competent jurisdiction, such
              provision will be severed from these Terms of Use and all other provisions of these
              Terms of Use will remain in full force and effect.
            </li>
            <li>
              {' '}
              Assignment. You will not assign these Terms of Use to any third party without our
              prior written consent. We may assign these Terms of Use or any rights under these
              Terms of Use to any third party without your consent. Any assignment in violation of
              this Section will be void. The terms of these Terms of Use will be binding upon
              permitted assignees. These Terms of Use will inure to the benefit of and be binding
              upon the parties, their permitted successors and permitted assignees. Payroll Ezy -
              Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust may engage third parties to
              provide the Services.
            </li>
            <li>
              English Language. It is the express wish of the parties that these Terms of Use and
              all related documents be drawn up in English. C’est la volonté expresse des parties
              que la présente convention ainsi que les documents qui s’y rattachent soient rédigés
              en anglais.
            </li>
            <li>
              {' '}
              International Use. The Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
              Unit Trust SaaS Services is intended for Customers located within the Australia and
              New Zealand. Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
              makes no representation that the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees
              For Yogi Unit Trust SaaS Services is appropriate or available for use outside of the
              Australia or New Zealand. Access to the Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust SaaS Services from countries or territories or by
              individuals where such access is illegal is prohibited.
            </li>
            <li>
              Notice Regarding Apple. This Section only applies to the extent you are using our
              mobile application on an iOS device. You acknowledge that these Terms of Use are
              between you and Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
              only, not with Apple. (“Apple”), and Apple is not responsible for the Payroll Ezy -
              Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services or the content
              of it. Apple has no obligation to furnish any maintenance and support services with
              respect to the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust
              SaaS Services. If the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
              Trust SaaS Services fails to conform to any applicable warranty, you may notify Apple,
              and Apple will refund any applicable purchase price for the mobile application to you.
              To the maximum extent permitted by applicable law, Apple has no other warranty
              obligation with respect to the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For
              Yogi Unit Trust SaaS Services. Apple is not responsible for addressing any claims by
              you or any third party relating to the Payroll Ezy - Yogi Innovations Pty Ltd As
              Trustees For Yogi Unit Trust SaaS Services or your possession and/or use of the
              Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services,
              including: (i) product liability claims; (ii) any claim that the Payroll Ezy - Yogi
              Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services fails to conform to
              any applicable legal or regulatory requirement; or (iii) claims arising under consumer
              protection or similar legislation. Apple is not responsible for the investigation,
              defense, settlement, and discharge of any third-party claim that the Payroll Ezy -
              Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services and/or your
              possession and use of the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
              Unit Trust SaaS Services infringe a third party’s intellectual property rights. You
              agree to comply with any applicable third-party terms when using the Payroll Ezy -
              Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust SaaS Services. Apple and
              Apple’s subsidiaries are third-party beneficiaries of these Terms of Use, and upon
              your acceptance of these Terms of Use, Apple will have the right (and will be deemed
              to have accepted the right) to enforce these Terms of Use against you as a third-party
              beneficiary of these Terms of Use. You hereby represent and warrant that: (i) you are
              not located in a country that is subject to a AUSTRALIA Government embargo or that has
              been designated by the AUSTRALIA Government as a “terrorist supporting” country; and
              (ii) you are not listed on any AUSTRALIA Government list of prohibited or restricted
              parties.
            </li>
          </ol>

          <p>
            <b>21. Contact</b>
          </p>
          <p>
            If you have any questions or comments regarding these Terms of Use, please contact us
            through the email <a href="mailto:info@yogiinnovation.com">info@yogiinnovation.com</a>.
          </p>
        </Paper>
      </div>
    </div>
  );
}

TermsAndConditions.propTypes = {};

export default TermsAndConditions;
