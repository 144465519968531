import { useSelector } from 'react-redux';
import { intersection } from 'lodash';

const useAccess = (access) => {
  const accessList = useSelector(({ user }) => user?.profileData?.resourceCode ?? []);
  if (!access) return true;
  return Array.isArray(access)
    ? intersection([...accessList, 'default'], access)
    : accessList?.includes(access);
};

export default useAccess;
