import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { isEmpty } from 'lodash';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import { editShiftDetails } from '../../redux/peopleSlice';
import {
  cancelShiftOfferInvitationAction,
  getPeopleForOfferShiftAction,
  sendShiftOfferInvitationAction,
} from '../../redux/peopleActions';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import IconByName from '../../../../components/common/iconByName/IconByName';
import {
  resetAddEditShiftFields,
  updateAddEditShiftFields,
} from '../../../schedule/redux/scheduleSlice';
import Loader from '../../../../components/common/loader/Loader';

function ReplaceShiftDrawer() {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState(null);
  const [checked, setChecked] = React.useState([]);

  const {
    replaceShiftDrawer,
    shiftId: replaceShiftId,
    peopleForOfferShift = [],
    replaceShiftError,
  } = useSelector(({ people }) => people?.shiftDetails ?? {});

  const shiftData = useSelector(({ schedule }) => schedule?.addEditShift ?? {});

  const { offerShiftDrawer, shiftReplacementDrawer } = useMemo(() => shiftData, [shiftData]);

  const {
    getPeopleForOfferShiftActionLoader,
    sendShiftOfferInvitationActionLoader,
    getPeopleForOfferShiftWithSearchTextActionLoader,
    cancelShiftOfferInvitationActionLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});

  const shiftId = useMemo(() => {
    if (offerShiftDrawer || shiftReplacementDrawer)
      return offerShiftDrawer || shiftReplacementDrawer;
    return replaceShiftId;
  }, [replaceShiftId, offerShiftDrawer, shiftReplacementDrawer]);

  const fetchPeoples = async (params) => {
    await dispatch(getPeopleForOfferShiftAction({ shiftId, searchText, ...params }));
    setChecked([]);
  };

  const handleSearch = async (e) => {
    if (e.target.value) setSearchText(e.target.value);
    else {
      setSearchText(null);
      await fetchPeoples({ searchText: null });
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleClose = () => {
    dispatch(editShiftDetails({ fieldName: 'replaceShiftDrawer', value: false }));
    dispatch(editShiftDetails({ fieldName: 'shiftId', value: null }));
    dispatch(editShiftDetails({ fieldName: 'replaceShiftError', value: null }));
    setChecked([]);
    if (shiftReplacementDrawer || offerShiftDrawer) {
      (async () => {
        await dispatch(updateAddEditShiftFields({ fieldName: 'isReFetchEvents', value: true }));
        dispatch(resetAddEditShiftFields());
      })();
    }
  };

  const handleSubmit = () => {
    const data = {
      shiftId,
      offerToPersonDetailId: checked,
    };

    dispatch(
      editShiftDetails({
        fieldName: 'replaceShiftError',
        value: isEmpty(checked) ? 'Please select replacements.' : null,
      })
    );
    if (checked?.length > 0) {
      dispatch(sendShiftOfferInvitationAction(data, handleClose));
    }
  };

  const handleCancelInvitation = () => {
    const data = {
      shiftId,
    };
    dispatch(cancelShiftOfferInvitationAction(data, handleClose));
  };

  const actionButton = useMemo(() => {
    const actions = [];
    if (peopleForOfferShift?.filter((e) => e?.isAlreadyInvited)?.length > 0) {
      actions.push({
        label: 'Cancel invitation',
        onClick: handleCancelInvitation,
        color: 'error',
        loading: cancelShiftOfferInvitationActionLoader,
      });
    }
    actions.push({
      label: 'Send invitation',
      onClick: handleSubmit,
      loading: sendShiftOfferInvitationActionLoader,
    });
    return actions;
  }, [
    peopleForOfferShift,
    sendShiftOfferInvitationActionLoader,
    cancelShiftOfferInvitationActionLoader,
    handleSubmit,
    handleCancelInvitation,
  ]);

  useEffect(() => {
    (async () => {
      if ((shiftId && replaceShiftDrawer) || offerShiftDrawer || shiftReplacementDrawer)
        await fetchPeoples();
    })();
  }, [replaceShiftDrawer, offerShiftDrawer, shiftReplacementDrawer]);

  return (
    <EzyDrawer
      onClose={handleClose}
      isOpen={replaceShiftDrawer || offerShiftDrawer || shiftReplacementDrawer}
      actionButtons={peopleForOfferShift?.length > 0 ? actionButton : []}
      heading={offerShiftDrawer ? 'Offer Shift' : 'Select Replacement'}
      isLoading={getPeopleForOfferShiftActionLoader}
    >
      <div className="shift-replace-drawer">
        {getPeopleForOfferShiftWithSearchTextActionLoader && <Loader />}
        <div className="shift-replace-field-grid">
          <EzyInputField
            placeholder="Search people"
            className="search-box"
            value={searchText}
            onChange={handleSearch}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                await fetchPeoples();
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconByName name="search" />
              </InputAdornment>
            }
          />
        </div>
        {peopleForOfferShift?.length > 0 ? (
          <div className="shift-replace-field-grid">
            <FormControlLabel
              label="Select All"
              className="just-right"
              control={
                <Checkbox
                  edge="start"
                  checked={
                    peopleForOfferShift?.filter((e) => !e?.isAlreadyInvited)?.length ===
                    checked?.length
                  }
                  tabIndex={-1}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setChecked(
                        peopleForOfferShift
                          ?.filter((val) => !val?.isAlreadyInvited)
                          ?.map((val) => val?.id)
                      );
                    } else {
                      setChecked([]);
                    }
                  }}
                  disableRipple
                  inputProps={{ 'aria-labelledby': 'select All' }}
                  icon={<IconByName name="check_circle_outline" />}
                  checkedIcon={<IconByName name="check_circle" className="primary-color" />}
                />
              }
            />
            <List>
              {peopleForOfferShift?.map((value) => {
                const labelId = `checkbox-list-label-${value?.id}`;

                return (
                  <ListItem
                    key={value?.id}
                    selected={checked?.indexOf(value?.id) !== -1 || value?.isAlreadyInvited}
                    disablePadding
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(value?.id)}
                      dense
                      disabled={value?.isAlreadyInvited}
                      disableRipple
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked?.indexOf(value?.id) !== -1 || value?.isAlreadyInvited}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                          icon={<IconByName name="check_circle_outline" />}
                          checkedIcon={<IconByName name="check_circle" className="primary-color" />}
                        />
                      </ListItemIcon>
                      <ListItemText id={value?.id} className="people-invite-list-view">
                        <span>{value?.preferredFullName}</span>
                        {value?.isAlreadyInvited && (
                          <span className="primary-color">Already Invited</span>
                        )}
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
            {replaceShiftError && (
              <FormHelperText className="error-helper">{replaceShiftError}</FormHelperText>
            )}
          </div>
        ) : (
          <div className="full-relative-wrapper">
            <div className="no-data-found">No data available.</div>
          </div>
        )}
      </div>
    </EzyDrawer>
  );
}

export default ReplaceShiftDrawer;
