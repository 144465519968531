import { createSlice } from '@reduxjs/toolkit';

const newsFeedSlice = createSlice({
  name: 'newsFeed',
  initialState: {
    newsFeedList: {
      docs: [],
      total: 1,
      limit: 7,
      page: 1,
      pages: 1,
      hasMoreRecords: false,
      selectedLocation: null,
      postType: 'All posts',
    },
    receiverList: {
      receiverLocationList: [],
      receiverPersonList: [],
    },
    newsFeedDrawerDetails: {
      isOpenNewsFeedDrawer: false,
      selectedLocation: [],
      selectedPeople: [],
      message: '',
      isRequiredConfirmation: false,
      isCommentAllowed: false,
      uploadedMedia: [],
    },
    newsFeedConfirmationDrawer: {
      isForAddMore: false,
      selectedLocation: [],
      selectedPeople: [],
      data: {
        confirmedList: [],
        unconfirmedList: [],
      },
    },
  },
  reducers: {
    setNewsFeedList: (state, action) => {
      state.newsFeedList = {
        ...state.newsFeedList,
        postType: state.newsFeedList.postType,
        docs: action.payload.data,
        total: action.payload.totalData,
        limit: action.payload.limit,
        page: action.payload.page,
        pages: action.payload.totalPages,
        hasMoreRecords: action.payload.totalPages > action.payload.page,
      };
    },
    updateNewsFeedDocs: (state, action) => {
      state.newsFeedList = {
        ...state.newsFeedList,
        docs: [...state.newsFeedList.docs, ...action.payload.data],
        total: action.payload.totalData,
        limit: action.payload.limit,
        page: action.payload.page,
        pages: action.payload.totalPages,
        hasMoreRecords: action.payload.totalPages > action.payload.page,
      };
    },
    updateNewsFeedListDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.newsFeedList[fieldName] = value;
    },
    clearNewsFeedList: (state) => {
      state.newsFeedList = {
        docs: [],
        total: 1,
        limit: 7,
        page: 1,
        pages: 1,
        hasMoreRecords: false,
        postType: state.newsFeedList.postType,
        selectedLocation: state.newsFeedList.selectedLocation,
      };
    },
    updateNewsFeedDrawer: (state, action) => {
      const { fieldName, value } = action.payload;
      state.newsFeedDrawerDetails[fieldName] = value;
    },
    addNewsFeedMedia: (state, action) => {
      state.newsFeedDrawerDetails.uploadedMedia = [
        ...state.newsFeedDrawerDetails.uploadedMedia,
        ...action.payload,
      ];
    },
    resetNewsFeedDrawer: (state) => {
      state.newsFeedDrawerDetails = {
        isOpenNewsFeedDrawer: false,
        selectedLocation: [],
        selectedPeople: [],
        message: '',
        isRequiredConfirmation: false,
        isCommentAllowed: false,
        uploadedMedia: [],
      };
      state.receiverList = {
        receiverLocationList: [],
        receiverPersonList: [],
      };
    },
    setReceiverList: (state, action) => {
      state.receiverList = action.payload;
    },
    updateNewsFeedConfirmationDrawer: (state, action) => {
      const { fieldName, value } = action.payload;
      state.newsFeedConfirmationDrawer[fieldName] = value;
    },
    clearNewsFeedConfirmationDrawer: (state) => {
      state.newsFeedConfirmationDrawer = {
        isForAddMore: false,
        selectedLocation: [],
        selectedPeople: [],
        data: {
          confirmedList: [],
          unconfirmedList: [],
        },
      };
    },
  },
});

const { reducer, actions } = newsFeedSlice;

export const {
  setNewsFeedList,
  clearNewsFeedList,
  updateNewsFeedDocs,
  updateNewsFeedListDetails,
  updateNewsFeedDrawer,
  addNewsFeedMedia,
  resetNewsFeedDrawer,
  setReceiverList,
  updateNewsFeedConfirmationDrawer,
  clearNewsFeedConfirmationDrawer,
} = actions;

export default reducer;
