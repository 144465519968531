import { useHistory } from 'react-router-dom';

const useReplaceState = () => {
  const historyHook = useHistory();
  return (params, removeParams = false) => {
    const url = Object.entries(params)
      .filter((arr) => arr[1] !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    if (removeParams) {
      // eslint-disable-next-line no-restricted-globals
      history.replaceState(null, '', `${historyHook?.location?.pathname}`);
    }
    // eslint-disable-next-line no-restricted-globals
    else history.replaceState(null, '', `${historyHook?.location?.pathname}?${url}`);
  };
};

export default useReplaceState;
