import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import ROUTE_CONSTANTS from '../utils/constants';
import { getAuthTokenFromLocalStorage } from '../utils/localStorageHelper';
import useAccess from '../hooks/useAccess';

function AuthRoutes({ component, path, ...options }) {
  const authStatus = getAuthTokenFromLocalStorage();
  const hasAccess = useAccess(options?.access);

  if (!authStatus) {
    return (
      <Route {...options} path={path}>
        <Redirect to={ROUTE_CONSTANTS.LOGIN} />
      </Route>
    );
  }

  if (path !== ROUTE_CONSTANTS.HOME && !component) {
    return (
      <Route {...options} path={path}>
        <Redirect to={ROUTE_CONSTANTS.HOME} />
      </Route>
    );
  }

  if (!hasAccess && path !== ROUTE_CONSTANTS.FORBIDDEN) {
    return (
      <Route {...options}>
        <Redirect to={ROUTE_CONSTANTS.FORBIDDEN} />
      </Route>
    );
  }

  return <Route {...options} component={component} path={path} />;
}

export default AuthRoutes;

AuthRoutes.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  path: PropTypes.string.isRequired,
  options: PropTypes.bool,
};

AuthRoutes.defaultProps = {
  component: null,
  options: false,
};
