import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import { updateStaffSettingsDrawer } from '../../redux/insightSlice';
import EzyToggleButtonGroup from '../../../../components/common/toggleButton/EzyToggleButtonGroup';
import { getStaffSettingsAction, updateStaffSettingsAction } from '../../redux/insightAction';

function StaffSettingDrawer() {
  const dispatch = useDispatch();
  const { staff } = useSelector(({ insight }) => insight ?? {});
  const { isOpen, levelOfDetail } = useMemo(
    () => staff?.staffSettingsDrawer,
    [staff?.staffSettingsDrawer]
  );
  const { businessId } = useSelector(({ user }) => user?.profileData ?? {});

  const { levelOfDetail: levelOfDetailEnum } = useSelector(({ common }) => common?.enums ?? {});

  const { getStaffSettingsActionLoader, updateStaffSettingsActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const handleClose = () => {
    dispatch(updateStaffSettingsDrawer({ fieldName: 'isOpen', value: false }));
  };

  const handleSubmit = () => {
    const data = {
      businessId,
      levelOfDetail,
    };
    dispatch(updateStaffSettingsAction(data, () => handleClose()));
  };

  useEffect(() => {
    if (isOpen) dispatch(getStaffSettingsAction());
  }, [isOpen]);

  const actionButton = [
    { label: 'Save', onClick: handleSubmit, loading: updateStaffSettingsActionLoader },
  ];
  return (
    <EzyDrawer
      isOpen={isOpen}
      onClose={handleClose}
      heading="Staff Settings"
      actionButtons={actionButton}
      isLoading={getStaffSettingsActionLoader}
    >
      <div className="staff-settings-drawer">
        <div className="title-with-primary-bg">General</div>
        <div className="staff-setting-fields">
          <div>Level of detail </div>
          <div className="secondary-caption-text ">The granularity of data for this metric</div>
          <EzyToggleButtonGroup
            name="levelOfDetail"
            list={levelOfDetailEnum?.map((e) => e?.label)}
            handleChange={(event) => {
              const value = levelOfDetailEnum?.find((e) => e?.label === event.target.value)?.id;
              dispatch(updateStaffSettingsDrawer({ fieldName: 'levelOfDetail', value }));
            }}
            value={levelOfDetailEnum?.find((e) => e?.id === levelOfDetail)?.label}
          />
        </div>
      </div>
    </EzyDrawer>
  );
}

StaffSettingDrawer.propTypes = {};

export default StaffSettingDrawer;
