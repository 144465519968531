import { isNil, omitBy } from 'lodash';
import { isEmail } from '../../../utils/validationHelper';

const oldAndNewEmailValidation = (data) => {
  const { currentEmail, newEmail } = data || {};
  const error = {};
  error.currentEmail = isEmail('Current email', currentEmail);
  error.newEmail = isEmail('New email', newEmail);
  if (currentEmail === newEmail) {
    error.newEmail = `Current and New email can't be identical`;
  }

  return omitBy(error, isNil);
};

export default oldAndNewEmailValidation;
