import { isNil, omitBy } from 'lodash';
import { isEmail, requireValidate } from '../../../utils/validationHelper';

const validateLogin = (data) => {
  const error = {};
  error.email = isEmail('email', data.email);
  error.password = requireValidate('password', data.password);
  return omitBy(error, isNil);
};

export default validateLogin;
