import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import EzyDialog from '../../../components/common/dialog/EzyDialog';
import EzyDialogTitle from '../../../components/common/dialog/EzyDialogTitle';
import EzyDialogAction from '../../../components/common/dialog/EzyDialogAction';
import { archivePeopleAction } from '../redux/peopleActions';
import IconByName from '../../../components/common/iconByName/IconByName';

function ArchivePeopleModal({ isOpen, handleClose, rowData }) {
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const data = {
      personDetailId: rowData?.id,
      isArchive: true,
    };

    dispatch(
      archivePeopleAction(data, () => {
        handleClose(true);
      })
    );
  };

  const archivePeopleButtons = [
    {
      label: 'Cancel',
      onClick: () => {
        handleClose();
      },
      color: 'secondary',
    },
    {
      label: 'Save',
      onClick: handleSubmit,
    },
  ];

  return (
    <EzyDialog
      open={isOpen}
      className="common-dialog-with-body archive-dialog"
      handleClose={() => handleClose()}
    >
      <EzyDialogTitle title="Archive" onClose={() => handleClose()} />

      <div className="dialog-body">
        <div className="info-div success">
          <IconByName name="error" />
          <div>
            Archiving these people will revoke their access to this organisation and will no longer
            be able to login to any device but historical records will be retained.
          </div>
        </div>
      </div>

      <EzyDialogAction actionButtons={archivePeopleButtons} />
    </EzyDialog>
  );
}

export default ArchivePeopleModal;

ArchivePeopleModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  rowData: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
};

ArchivePeopleModal.defaultProps = {
  handleClose: null,
};
