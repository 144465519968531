import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import EzySwitch from './EzySwitch';

function EzySwitchButton({ className, variant, onChange, checked, name, disabled, ...restOther }) {
  const classes = `ezySwitchButton ${className || ''}`;

  const { label, subLabel } = restOther ?? {};
  return (
    <div className="switch-container">
      {(label || subLabel) && (
        <div className="ezy-labels">
          {label && <span className="ezy-label">{label}</span>}
          {subLabel && <span className="ezy-subLabel">{subLabel}</span>}
        </div>
      )}
      <Button
        className={classes}
        variant={variant}
        disableRipple
        disabled={disabled}
        {...restOther}
      >
        <EzySwitch handleChange={onChange} checked={checked} name={name} disabled={disabled} />
      </Button>
    </div>
  );
}

export default EzySwitchButton;

EzySwitchButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

EzySwitchButton.defaultProps = {
  className: '',
  name: '',
  variant: 'contained',
  onChange: () => {},
  disabled: false,
};
