import { filter, isEmpty, isNil, omitBy } from 'lodash';
import moment from 'moment';
import { requireValidate } from '../../../utils/validationHelper';
import { getDurationInMinutes } from '../../../utils/timeHelper';

const validateShift = (data) => {
  const {
    shiftType,
    shiftBreakDetailsList,
    endTime,
    startTime,
    areaId,
    personDetailId,
    totalMealBreak = 0,
    totalRestBreak = 0,
    isCloneDrawer = false,
    noOfClone,
    repeatForDays = false,
    weekDays = [],
  } = data ?? {};
  const error = {};
  error.shiftType = requireValidate('Shift type', shiftType);
  if (![1, 2].includes(shiftType)) error.personDetailId = requireValidate('People', personDetailId);
  error.areaId = requireValidate('Area', areaId);
  error.startTime = requireValidate('Start time', startTime);
  error.endTime = requireValidate('End time', endTime);
  if (startTime && endTime && !moment(endTime).isAfter(startTime)) {
    error.endTime = `End time can't be smaller then start time`;
  } else if (startTime && endTime) {
    const availableDuration = getDurationInMinutes(startTime, endTime);

    if (availableDuration > 1380) {
      error.startTime = `Shift duration can't be greater than 23 hours.`;
      error.endTime = `Shift duration can't be greater than 23 hours.`;
    }

    if (totalMealBreak + totalRestBreak > availableDuration) {
      error.totalMealBreak = `Break time can't be greater then shift time`;
      error.totalRestBreak = true;
    }
  }

  error.shiftBreakDetailsList = shiftBreakDetailsList?.map((e, index) => {
    const errors = {};
    if (e?.duration && e?.duration >= 0)
      errors.breakType = requireValidate('Break type', e?.breakType);

    if (e?.isCustom) {
      if (!moment(e?.startTime).isValid() || !moment(e?.endTime).isValid()) {
        if (!moment(e?.startTime).isValid()) errors.startTime = `Break start time is required.`;

        if (!moment(e?.endTime).isValid()) errors.endTime = `Break end time is required.`;
      } else {
        if (moment(e?.startTime).isValid() && !moment(e?.startTime).isBetween(startTime, endTime))
          errors.startTime = `Break start time is out of shift range.`;

        if (moment(e?.endTime).isValid() && !moment(e?.endTime).isBetween(startTime, endTime))
          errors.endTime = `Break end time is out of shift range.`;

        if (
          moment(e?.endTime).isValid() &&
          moment(e?.startTime).isValid() &&
          moment(e?.endTime).isBefore(e?.startTime)
        )
          errors.endTime = `Invalid break end time.`;

        for (let j = 0; j < index; j += 1) {
          const previousStartTime = shiftBreakDetailsList[j].startTime;
          const previousEndTime = shiftBreakDetailsList[j].endTime;

          if (
            moment(e?.startTime).isBetween(previousStartTime, previousEndTime) ||
            moment(e?.startTime).isSame(previousStartTime)
          ) {
            errors.startTime = `Start time overlaps with another break.`;
          }
          if (
            moment(e?.endTime).isBetween(previousStartTime, previousEndTime) ||
            moment(e?.endTime).isSame(previousEndTime)
          ) {
            errors.endTime = `End time overlaps with another break.`;
          }
        }
      }
    }

    return omitBy(errors, isNil);
  });

  error.shiftBreakDetailsList = filter(error.shiftBreakDetailsList, (e) => !isEmpty(e))?.length
    ? error.shiftBreakDetailsList
    : null;

  if (isCloneDrawer) {
    error.noOfClone = requireValidate('No of clones', noOfClone);
  }

  if (repeatForDays && weekDays?.length <= 0) {
    error.weekDays = 'Select days to repeat shift for.';
  }

  return omitBy(error, isNil);
};

export default validateShift;
