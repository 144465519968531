import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const logbookSlice = createSlice({
  name: 'newsFeed',
  initialState: {
    logsList: {
      timeZoneId: null,
      categoryList: [],
      isAutoSendEmail: false,
      time: null,
      sendEmailSummaryDrawer: false,
      requiredLogCategory: [],
      categoryDropdownList: [],
    },
    filteredEntries: {
      docs: [],
      page: 1,
      limit: 10,
      total: 1,
      pages: 1,
      hasMoreRecords: false,
    },
    filters: {
      date: moment(new Date()),
      selectedLocation: null,
      viewType: 'View',
    },
    addCategoryDrawer: {
      isOpenDrawer: false,
      isEditCategory: null,
      name: '',
      categoryType: null,
      instantNotification: false,
      required: false,
    },
  },
  reducers: {
    updateAddCategoryDrawerDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.addCategoryDrawer[fieldName] = value;
    },
    updateLogListFilters: (state, action) => {
      const { fieldName, value } = action.payload;
      state.filters[fieldName] = value;
    },
    setLogsList: (state, action) => {
      state.logsList = { ...state.logsList, ...action.payload };
    },
    updateLogsList: (state, action) => {
      const { fieldName, value } = action.payload;
      state.logsList[fieldName] = value;
    },
    updateLogsEntryDetails: (state, action) => {
      const { id, value } = action.payload;
      state.logsList.categoryList = state.logsList.categoryList.map((entry) =>
        entry?.logBookCategoryId === id
          ? { ...entry, logBookEntries: value?.logBookEntries }
          : entry
      );
    },
    clearLogsList: (state) => {
      state.logsList = {
        timeZoneId: null,
        categoryList: [],
        isAutoSendEmail: false,
        time: null,
        sendEmailSummaryDrawer: false,
        requiredLogCategory: [],
        categoryDropdownList: state.logsList.categoryDropdownList,
      };
      state.filteredEntries = {
        docs: [],
        page: 1,
        limit: 10,
        total: 1,
        pages: 1,
      };
    },
    clearAddCategoryDrawerData: (state) => {
      state.addCategoryDrawer = {
        isOpenDrawer: false,
        isEditCategory: null,
        name: '',
        categoryType: null,
        instantNotification: false,
        required: false,
      };
    },
    setAddCategoryDetails: (state, action) => {
      state.addCategoryDrawer = {
        ...state.addCategoryDrawer,
        ...action.payload,
      };
    },
    setFilteredEntriesList: (state, action) => {
      state.filteredEntries = {
        ...state.filteredEntries,
        docs: [...state.filteredEntries.docs, ...action.payload.data],
        total: action.payload.totalData,
        limit: action.payload.limit,
        page: action.payload.page,
        pages: action.payload.totalPages,
        hasMoreRecords: action.payload.totalPages > action.payload.page,
      };
    },
    updateFilteredEntriesDetails: (state, action) => {
      const { fieldName, value, index } = action.payload;

      if (index || index === 0) {
        state.filteredEntries.docs[index] = {
          ...state.filteredEntries.docs[index],
          logBookComments: [...state.filteredEntries.docs[index].logBookComments, value],
        };
      } else {
        state.filteredEntries[fieldName] = value;
      }
    },
  },
});

const { reducer, actions } = logbookSlice;

export const {
  updateAddCategoryDrawerDetails,
  updateLogListFilters,
  setLogsList,
  clearAddCategoryDrawerData,
  updateLogsEntryDetails,
  setAddCategoryDetails,
  clearLogsList,
  updateLogsList,
  setFilteredEntriesList,
  updateFilteredEntriesDetails,
} = actions;

export default reducer;
