import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { isEmpty, isNull } from 'lodash';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import WeekPicker from '../../../../components/common/dateAndTimePicker/WeekPicker';
import EzyAutoComplete from '../../../../components/common/autoComplete/EzyAutoComplete';
import { displayDate, weekDatesValueBasedOnDuration } from '../../../../utils/timeHelper';
import ROUTE_CONSTANTS, { DurationFilter } from '../../../../utils/constants';
import { updateRunLaborModalDrawer, updateStaffData } from '../../redux/insightSlice';
import { runLaborModelAction } from '../../redux/insightAction';
import EzyDialog from '../../../../components/common/dialog/EzyDialog';
import EzyDialogTitle from '../../../../components/common/dialog/EzyDialogTitle';
import EzyDialogAction from '../../../../components/common/dialog/EzyDialogAction';
import EzyDatePicker from '../../../../components/common/dateAndTimePicker/EzyDatePicker';

function RunLaborModalDrawer({ isFromRequiredStaff }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { filters, staff } = useSelector(({ insight }) => insight ?? {});
  const { isOpen, duration, weekDatesValue } = useMemo(
    () => staff?.runLaborModalDrawer,
    [staff?.runLaborModalDrawer]
  );
  const [successModal, setSuccessModal] = useState(null);

  const { runLaborModalActionLoader } = useSelector(({ common }) => common.generalLoader ?? {});

  const handleFieldChange = (fieldName, value) => {
    dispatch(updateRunLaborModalDrawer({ fieldName, value }));
  };

  const handleClose = () => {
    dispatch(
      updateStaffData({
        fieldName: 'runLaborModalDrawer',
        value: {
          isOpen: false,
          weekDatesValue: {
            startDate: moment.tz(new Date(), 'UTC').startOf('week'),
            endDate: moment.tz(new Date(), 'UTC').endOf('week'),
          },
          duration: {
            id: 1,
            label: 'Week',
            dayCount: 7,
          },
        },
      })
    );
  };
  const handleSubmit = () => {
    const params = {
      locationId: filters?.selectedLocation?.id,
      rangeStartDate: moment(weekDatesValue?.startDate).toISOString(),
      rangeEndDate: moment(weekDatesValue?.endDate).toISOString(),
      repetitionFrequency: duration?.id,
    };
    dispatch(
      runLaborModelAction(params, (data) => {
        handleClose();
        setSuccessModal(data);
      })
    );
  };
  const actionButton = [
    { label: 'Run', onClick: handleSubmit, loading: runLaborModalActionLoader },
  ];

  const successModalActionButton = useMemo(() => {
    const actions = [];
    if (!isFromRequiredStaff) {
      actions.push({
        label: 'Go to required staff',
        variant: 'outlined',
        onClick: () =>
          history.push(
            `${ROUTE_CONSTANTS.SCHEDULE}/insights/staff?location=${filters?.selectedLocation?.id}`
          ),
      });
    }
    actions.push({ label: 'Ok', onClick: () => setSuccessModal(null) });
    return actions;
  }, [isFromRequiredStaff]);

  return (
    <>
      <EzyDrawer
        isOpen={isOpen}
        onClose={handleClose}
        heading="Run Labor Model"
        actionButtons={actionButton}
      >
        <div className="run-labor-modal-drawer">
          <div>
            This will calculate your required staff for the following period based on your labor
            model rules
          </div>

          <EzyAutoComplete
            options={DurationFilter}
            onChange={(e, value) => {
              if (value !== null) {
                handleFieldChange('duration', value);
                const data = weekDatesValueBasedOnDuration(weekDatesValue, value);
                handleFieldChange('weekDatesValue', data);
              }
            }}
            value={duration}
          />

          {duration?.id === 5 ? (
            <EzyDatePicker
              timeZone="UTC"
              value={moment.tz(weekDatesValue?.startDate, 'UTC')}
              onChange={async (e) => {
                const data = weekDatesValueBasedOnDuration({ startDate: e }, duration);
                handleFieldChange('weekDatesValue', data);
              }}
            />
          ) : (
            <WeekPicker
              variant="outlined"
              value={weekDatesValue}
              onChange={async (e) => {
                const data = weekDatesValueBasedOnDuration(e, duration);
                handleFieldChange('weekDatesValue', data);
              }}
              onNextClick={async () => {
                const dates = {
                  startDate: moment
                    .tz(weekDatesValue?.startDate, 'UTC')
                    .add(duration?.dayCount, 'days'),
                  endDate: moment
                    .tz(weekDatesValue?.endDate, 'UTC')
                    .add(duration?.dayCount, 'days'),
                };

                if (duration?.label === 'Month') {
                  dates.startDate = moment
                    .tz(weekDatesValue?.endDate, 'UTC')
                    .add(1, 'days')
                    .startOf('month');
                  dates.endDate = moment
                    .tz(weekDatesValue?.endDate, 'UTC')
                    .add(1, 'days')
                    .endOf('month');
                }
                handleFieldChange('weekDatesValue', dates);
              }}
              onPrevClick={async () => {
                const dates = {
                  startDate: moment
                    .tz(weekDatesValue?.startDate, 'UTC')
                    .subtract(duration?.dayCount, 'days'),
                  endDate: moment
                    .tz(weekDatesValue?.endDate, 'UTC')
                    .subtract(duration?.dayCount, 'days'),
                };

                if (duration?.label === 'Month') {
                  dates.startDate = moment
                    .tz(weekDatesValue?.startDate, 'UTC')
                    .subtract(1, 'days')
                    .startOf('month');
                  dates.endDate = moment
                    .tz(weekDatesValue?.startDate, 'UTC')
                    .subtract(1, 'days')
                    .endOf('month');
                }
                handleFieldChange('weekDatesValue', dates);
              }}
              timeZone="UTC"
              startWeekFrom={
                filters?.selectedLocation?.weekStartsOn === 7
                  ? 0
                  : filters?.selectedLocation?.weekStartsOn
              }
            />
          )}
        </div>
      </EzyDrawer>
      <EzyDialog
        handleClose={() => setSuccessModal(null)}
        className="common-dialog-with-body labor-modal-success-dialog"
        open={!isEmpty(successModal) || !isNull(successModal)}
      >
        <EzyDialogTitle
          title={`Labor Model applied for ${
            displayDate(successModal?.startDate, 'UTC') ===
            displayDate(successModal?.endDate, 'UTC')
              ? displayDate(successModal?.startDate, 'UTC')
              : `${displayDate(successModal?.startDate, 'UTC')} -
        ${displayDate(successModal?.endDate, 'UTC')}`
          }`}
          onClose={() => setSuccessModal(null)}
        />
        <div className="dialog-body title-small">
          <div>We have successfully created required staff based on Labor model.</div>
          <div className="primary-bg">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell size="small" className="empty-cell" />
                  {successModal?.data?.[0]?.laborEntities?.map((x, index) => {
                    return (
                      <TableCell key={index} align="right">
                        {displayDate(x?.startDate, 'UTC') === displayDate(x?.endDate, 'UTC')
                          ? moment.tz(x?.startDate, 'UTC').format('DD MMM')
                          : `${moment.tz(x?.startDate, 'UTC').format('DD MMM')} -
                        ${moment.tz(x?.endDate, 'UTC').format('DD MMM')}`}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {successModal?.data?.map((x) => {
                  return (
                    <TableRow>
                      <TableCell>{x?.areaName}</TableCell>
                      {x?.laborEntities?.map((y) => (
                        <TableCell align="right">{y?.hours} h </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </div>
        <EzyDialogAction actionButtons={successModalActionButton} />
      </EzyDialog>
    </>
  );
}

RunLaborModalDrawer.propTypes = {
  isFromRequiredStaff: PropTypes.bool,
};

RunLaborModalDrawer.defaultProps = {
  isFromRequiredStaff: false,
};

export default RunLaborModalDrawer;
