import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Menu } from '@mui/material';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import EzyIconButton from '../../../../components/common/iconButton/EzyIconButton';
import EzyButton from '../../../../components/common/button/EzyButton';

function InsightLocationMenu({ refetchEvent }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState([]);
  const { locationListDropdown } = useSelector(({ insight }) => insight ?? {});

  const [selectedLocation, setSelectedLocation] = useState([]);

  const menuRef = useRef();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event?.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheck = (id, areaId, arealist) => {
    // sub id checked
    setSelectedLocation((prev) => {
      const newSelectedLocation = _.cloneDeep(prev);
      // find index of main id
      const mainIdIndex = newSelectedLocation.findIndex((x) => x.locationId === id);
      // main id checked
      if (id && !areaId) {
        // if its found then remove it
        if (mainIdIndex >= 0) {
          newSelectedLocation.splice(mainIdIndex, 1);
          return newSelectedLocation;
          // else add data
        }
        newSelectedLocation.push({ locationId: id, areaIds: [...arealist] });
        return newSelectedLocation;
      }
      if (id && areaId) {
        if (mainIdIndex < 0) {
          newSelectedLocation.push({ locationId: id, areaIds: [areaId] });
          return newSelectedLocation;
        }
        const areaIndex = newSelectedLocation[mainIdIndex].areaIds.findIndex((x) => x === areaId);
        if (areaIndex >= 0) {
          newSelectedLocation[mainIdIndex].areaIds.splice(areaIndex, 1);
          if (newSelectedLocation[mainIdIndex].areaIds.length === 0)
            newSelectedLocation.splice(mainIdIndex, 1);
          return newSelectedLocation;
        }
        newSelectedLocation[mainIdIndex].areaIds.push(areaId);
        return newSelectedLocation;
      }
      return newSelectedLocation;
    });
  };

  useEffect(() => {
    setSelectedLocation([
      ...locationListDropdown.slice(1).map((x) => {
        return { locationId: x?.id, areaIds: x?.areaList?.map((y) => y?.id) };
      }),
    ]);
  }, [locationListDropdown]);

  return (
    <div className="ezy-menu ">
      <EzyIconButton
        ref={menuRef}
        iconName="location_on"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <div className="summary-location-menu">
          <div className="location-list " key={selectedLocation}>
            {locationListDropdown.slice(1)?.map((e) => {
              return (
                <div key={e?.id} className="d-flex-column gap-10 w-full">
                  <div className="location-label">
                    <span
                      className="material-icons pointer-cursor"
                      onClick={() => {
                        setIsOpen((arr) => {
                          const newArr = [...arr];
                          if (newArr?.includes(e?.id)) {
                            newArr.splice(newArr.indexOf(e?.id), 1);
                          } else {
                            newArr.push(e?.id);
                          }

                          return newArr;
                        });
                      }}
                    >
                      {isOpen?.length > 0 && isOpen?.includes(e?.id)
                        ? 'expand_more'
                        : 'keyboard_arrow_right'}
                    </span>

                    <div className="label">
                      {e?.label}
                      <Checkbox
                        onClick={() =>
                          handleCheck(
                            e.id,
                            '',
                            e.areaList.map((x) => x.id)
                          )
                        }
                        indeterminate={
                          selectedLocation.find((x) => x.locationId === e.id)?.areaIds?.length >
                            0 &&
                          selectedLocation.find((x) => x.locationId === e.id)?.areaIds?.length <
                            e.areaList.length
                        }
                        checked={
                          selectedLocation.find((x) => x.locationId === e.id)?.areaIds?.length ===
                          e.areaList.length
                        }
                      />
                    </div>
                  </div>

                  {isOpen?.length > 0 &&
                    isOpen?.includes(e?.id) &&
                    e?.areaList?.map((area) => (
                      <div className="area-label" key={area.id}>
                        {area?.label}
                        <Checkbox
                          onClick={() => handleCheck(e.id, area.id)}
                          checked={selectedLocation
                            .find((x) => x.locationId === e.id)
                            ?.areaIds?.includes(area.id)}
                        />
                      </div>
                    ))}
                </div>
              );
            })}
          </div>

          <EzyButton
            label="Done"
            onClick={() => {
              handleClose();
              refetchEvent({ locationAreaEntities: selectedLocation });
            }}
          />
        </div>
      </Menu>
    </div>
  );
}

InsightLocationMenu.propTypes = {
  refetchEvent: PropTypes.func.isRequired,
};

export default InsightLocationMenu;
