import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Checkbox,
  FormHelperText,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import EzyDrawer from '../../../components/common/drawer/Drawer';
import { resetBulkActionData, updateBulkActionData } from '../redux/scheduleSlice';
import IconByName from '../../../components/common/iconByName/IconByName';
import {
  deleteTemplateAction,
  getAllTemplatesByLocationIdAction,
  loadTemplateAction,
} from '../redux/scheduleActions';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import EzyIconButton from '../../../components/common/iconButton/EzyIconButton';
import { displayDate } from '../../../utils/timeHelper';
import Loader from '../../../components/common/loader/Loader';
import useAccess from '../../../hooks/useAccess';
import { DATE_FORMAT } from '../../../utils/constants';

function LoadTemplateDrawer({ reFetchEvents }) {
  const dispatch = useDispatch();
  const { isLoadTemplateDrawer, templateData, error } = useSelector(
    ({ schedule }) => schedule?.bulkActionData ?? {}
  );
  const { startDate, endDate } = useSelector(
    ({ schedule }) => schedule?.sidePanelData?.weekDatesValue ?? {}
  );
  const { selectedLocation } = useSelector(({ schedule }) => schedule?.sidePanelData ?? {});
  const { businessId } = useSelector(({ user }) => user?.profileData ?? {});

  const hasAccess = useAccess('EDITEMPSCHED');

  const {
    getAllTemplatesByLocationIdActionLoader,
    deleteTemplateActionLoader,
    getAllTemplatesSearchTextActionLoader,
    loadTemplateActionLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});

  const [searchText, setSearchText] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const handleTemplateSelect = (value) => {
    setSelectedTemplate((e) => (e === value ? null : value));
  };

  const getTemplates = async (params = {}) => {
    const data = {
      searchText,
      ...params,
    };
    if (selectedLocation?.id !== 'all' && !selectedLocation?.locationId) {
      data.locationId = selectedLocation?.id;
    } else if (selectedLocation?.id === 'all') {
      data.businessId = businessId;
    } else if (selectedLocation?.locationId) {
      data.areaId = selectedLocation?.id;
    }
    await dispatch(getAllTemplatesByLocationIdAction(data));
  };

  const handleSearch = async (e) => {
    if (e.target.value) setSearchText(e.target.value);
    else {
      setSearchText(null);
      await getAllTemplatesByLocationIdAction({ searchText: undefined });
    }
  };

  const handleClose = () => {
    setSearchText(null);
    setSelectedTemplate(null);
    dispatch(resetBulkActionData());
  };

  const handleSubmit = async () => {
    const data = {
      templateId: selectedTemplate,
      weekStartDate: moment
        .tz(displayDate(startDate, selectedLocation?.timeZoneId?.label), DATE_FORMAT, 'UTC')
        .set({ hours: 0, minutes: 0 })
        ?.toISOString(),
      weekEndDate: moment
        .tz(displayDate(endDate, selectedLocation?.timeZoneId?.label), DATE_FORMAT, 'UTC')
        .set({ hour: 0, minute: 0 })
        ?.toISOString(),
      IsFromLoadTemplate: true,
    };

    if (selectedTemplate) {
      await dispatch(
        loadTemplateAction(data, () => {
          reFetchEvents();
          handleClose();
        })
      );
    }
  };

  const handleTemplateDelete = async (e, templateId) => {
    e.stopPropagation();
    await dispatch(
      deleteTemplateAction({ templateId }, () => {
        dispatch(
          updateBulkActionData({
            fieldName: 'templateData',
            value: templateData?.filter(
              (templates) => templates?.scheduleTemplateId !== templateId
            ),
          })
        );
        setSelectedTemplate(null);
      })
    );
  };

  const actionButton = useMemo(
    () => [
      {
        label: 'Load template',
        onClick: handleSubmit,
        disabled: !selectedTemplate,
        loading: loadTemplateActionLoader,
      },
    ],
    [selectedTemplate, handleSubmit, loadTemplateActionLoader]
  );

  useEffect(() => {
    if (isLoadTemplateDrawer) {
      (async () => {
        await getTemplates();
      })();
    }
  }, [isLoadTemplateDrawer]);

  return (
    <EzyDrawer
      onClose={handleClose}
      isOpen={isLoadTemplateDrawer}
      actionButtons={templateData?.length > 0 && hasAccess ? actionButton : []}
      heading="Shift Template"
      isLoading={getAllTemplatesByLocationIdActionLoader}
    >
      <div className="shift-drawer">
        {(deleteTemplateActionLoader || getAllTemplatesSearchTextActionLoader) && <Loader />}
        <div className="save-template-fields-grid">
          <EzyInputField
            placeholder="Search by template name"
            className="search-box"
            value={searchText}
            onChange={handleSearch}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                await getTemplates();
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconByName name="search" />
              </InputAdornment>
            }
          />
        </div>
        {templateData?.length > 0 ? (
          <div className="save-template-fields-grid">
            <List className="load-template-lists">
              {templateData?.map(
                ({
                  scheduleTemplateId,
                  templateName,
                  startFrom,
                  endTo,
                  totalEstimatedCost,
                  totalShifts,
                  totalTeamMembers,
                }) => {
                  const labelId = `checkbox-list-label-${scheduleTemplateId}`;

                  return (
                    <ListItem
                      key={scheduleTemplateId}
                      selected={selectedTemplate === scheduleTemplateId}
                      disablePadding
                    >
                      <ListItemButton
                        role={undefined}
                        onClick={() => {
                          if (hasAccess) handleTemplateSelect(scheduleTemplateId);
                        }}
                        dense
                        disableRipple
                      >
                        <div className="d-flex-column gap-10 w-full">
                          <div className="d-flex flex-align-center just-bet gap-10">
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={selectedTemplate === scheduleTemplateId}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                                icon={<IconByName name="check_circle_outline" />}
                                checkedIcon={
                                  <IconByName name="check_circle" className="primary-color" />
                                }
                              />
                            </ListItemIcon>
                            <ListItemText id={scheduleTemplateId} className="template-details">
                              <div className="d-flex flex-align-center just-bet gap-10">
                                <span className="subtitle-text">{templateName}</span>

                                {hasAccess && (
                                  <EzyIconButton
                                    iconName="delete"
                                    className="small"
                                    onClick={(e) => handleTemplateDelete(e, scheduleTemplateId)}
                                  />
                                )}
                              </div>
                            </ListItemText>
                          </div>
                          <div className="d-flex-column gap-5 template-sub-details">
                            <div className="d-flex flex-align-center text-secondary-color gap-10">
                              <IconByName name="calendar_month" />
                              <span>
                                {displayDate(startFrom, selectedLocation?.timeZoneId?.label)} -{' '}
                                {displayDate(endTo, selectedLocation?.timeZoneId?.label)}
                              </span>
                            </div>
                            <div className="d-flex flex-align-center just-bet gap-10">
                              <div className="d-flex flex-align-center gap-10 text-secondary-color">
                                <IconByName name="pending_actions" />
                                <span>{totalShifts}</span>
                              </div>
                              <div className="d-flex flex-align-center gap-10 text-secondary-color">
                                <IconByName name="groups" />
                                <span>{totalTeamMembers}</span>
                              </div>
                              <div className="d-flex flex-align-center gap-10 text-secondary-color">
                                <IconByName name="payments" />
                                <span>{totalEstimatedCost}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ListItemButton>
                    </ListItem>
                  );
                }
              )}
            </List>
            {error && <FormHelperText className="error-helper">{error}</FormHelperText>}
          </div>
        ) : (
          <div className="full-relative-wrapper">
            <div className="no-data-found">No templates available.</div>
          </div>
        )}
      </div>
    </EzyDrawer>
  );
}

export default LoadTemplateDrawer;

LoadTemplateDrawer.propTypes = {
  reFetchEvents: PropTypes.func.isRequired,
};
