import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { onMessageListener, requestPermission } from '../../services/firebase';
import { saveClientTokenAction } from '../../pages/globalProfile/redux/globalProfileActions';
import {
  updateDashboardAdminViaFCM,
  updateDashboardClientViaFCM,
} from '../../pages/home/redux/dashboardSlice';
import { setNotificationsViaFCM } from '../../pages/globalProfile/redux/globalProfileSlice';
import audio from '../../assets/sounds/notification_high-intensity.wav';
import { getFCMTokenFromLocalStorage } from '../../utils/localStorageHelper';

function FCM({ children }) {
  const { businessId, personId } = useSelector(({ user }) => user?.profileData || {});
  const dispatch = useDispatch();

  const handleFCMUpdates = (data = {}) => {
    switch (data.type) {
      case 'DASHBOARD_ADMIN':
        dispatch(updateDashboardAdminViaFCM(JSON.parse(data.payload)));
        break;
      case 'DASHBOARD_USER':
        dispatch(updateDashboardClientViaFCM(JSON.parse(data.payload)));
        break;
      // case 'SCHEDULE':
      //   dispatch(updateShiftsViaFCM(JSON.parse(data.payload)));
      //   break;
      default: {
        let player = new Audio(audio);
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty('NotificationRedirection')) {
          const { Date, LeaveRequestId, PersonDetailId, RedirectionScreen, TimeSheetId } =
            JSON.parse(data.NotificationRedirection);
          data.notificationRedirection = {};
          data.notificationRedirection.personDetailId = PersonDetailId;
          data.notificationRedirection.leaveRequestId = LeaveRequestId;
          data.notificationRedirection.timeSheetId = TimeSheetId;
          data.notificationRedirection.redirectionScreen = RedirectionScreen;
          data.notificationRedirection.date = Date;

          delete data.NotificationRedirection;
        }
        dispatch(setNotificationsViaFCM(data));
        if (player) player?.play();
        player = null;
      }
    }
  };

  useEffect(() => {
    if (
      businessId &&
      personId &&
      !['/account-overview/plans'].includes(
        window.location.pathname?.substring(0, window.location.pathname.lastIndexOf('/'))
      )
    ) {
      const existingToken = getFCMTokenFromLocalStorage();

      if (existingToken) {
        onMessageListener((data) => {
          handleFCMUpdates(data.data);
        });
      } else {
        requestPermission((generateToken) =>
          generateToken((newToken) => {
            dispatch(
              saveClientTokenAction({ clientToken: newToken, personDetailId: personId }, () => {
                onMessageListener((data) => {
                  handleFCMUpdates(data.data);
                });
              })
            );
          })
        );
      }
    }
  }, [businessId, personId]);
  return children;
}

FCM.propTypes = {
  children: PropTypes.element.isRequired,
};

export default FCM;
