import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import EzyDrawer from '../../../components/common/drawer/Drawer';
import { resetAddEditShiftFields } from '../redux/scheduleSlice';
import {
  cancelRequestedOpenShiftAction,
  confirmRequestForOpenShiftAction,
  getOpenShiftDetailsAction,
} from '../redux/scheduleActions';
import EzyButton from '../../../components/common/button/EzyButton';
import IconByName from '../../../components/common/iconByName/IconByName';
import { displayDateTime } from '../../../utils/timeHelper';
import ConfirmationModal from '../../../components/common/modal/ConfirmationModal';

// {
//   "shiftId": "2ac331d4-aa63-4724-8353-3f78d2738065",
//     "startTime": "2023-02-01T09:00:00Z",
//     "endTime": "2023-02-01T17:00:00Z",
//     "locationName": null,
//     "areaName": "Godawn",
//     "requestedPersonList": [
//   {
//     "preferredFullName": "Manish",
//     "requestedAt": "2023-02-03T03:57:30.019604Z",
//     "cost": "AUD 0",
//     "hoursThisWeek": 185.5
//   }
// ],
//     "personCount": null
// }

function OpenShiftRequestDrawer({ reFetchEvents }) {
  const [deleteAction, setDeleteAction] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState(null);

  const dispatch = useDispatch();
  const shiftData = useSelector(({ schedule }) => schedule?.addEditShift ?? {});
  const { isOpenShiftRequest, openShiftRequestDetails } = useMemo(
    () => shiftData ?? {},
    [shiftData]
  );

  const { requestedPersonList, areaName, locationName, endTime, startTime, shiftId } = useMemo(
    () => openShiftRequestDetails ?? {},
    [openShiftRequestDetails]
  );

  const {
    confirmRequestForOpenShiftActionLoader,
    cancelRequestedOpenShiftActionLoader,
    getOpenShiftDetailsActionLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});

  const handleTemplateSelect = (value) => {
    setSelectedPerson((e) => (e === value ? null : value));
  };

  const handleClose = () => {
    dispatch(resetAddEditShiftFields());
    setSelectedPerson(null);
    setDeleteAction(null);
  };

  const handleSubmit = async () => {
    const data = {
      openShiftId: shiftId,
      personsId: selectedPerson,
    };
    await dispatch(
      confirmRequestForOpenShiftAction(data, () => {
        reFetchEvents();
        handleClose();
      })
    );
  };

  const actionButton = [
    {
      label: 'Confirm',
      onClick: () => handleSubmit(),
      disabled: !selectedPerson,
      loading: confirmRequestForOpenShiftActionLoader,
    },
  ];

  const handleShiftDelete = () => {
    dispatch(
      cancelRequestedOpenShiftAction({ shiftId: isOpenShiftRequest }, () => {
        setDeleteAction(false);
        reFetchEvents();
        handleClose();
      })
    );
  };

  useEffect(() => {
    if (isOpenShiftRequest) dispatch(getOpenShiftDetailsAction({ shiftId: isOpenShiftRequest }));
  }, [isOpenShiftRequest]);

  return (
    <EzyDrawer
      onClose={handleClose}
      isOpen={isOpenShiftRequest}
      actionButtons={actionButton}
      heading="Shift Requests"
      isLoading={getOpenShiftDetailsActionLoader}
    >
      <div className="shift-drawer">
        <div className="schedule-details-wrapper">
          <div className="d-flex-column gap-5">
            <div className="data-wrapper">
              <div className="title">Area :&nbsp;</div>
              <div className="value">
                {areaName || '-'}&nbsp;at&nbsp;{locationName || '-'}{' '}
              </div>
            </div>
            <div className="data-wrapper">
              <div className="title">Time :&nbsp;</div>
              <div className="value">
                {displayDateTime(startTime)} - {displayDateTime(endTime)}
              </div>
            </div>
          </div>
          <EzyButton
            label="Cancel shift"
            startIcon={<IconByName name="delete" />}
            onClick={() => setDeleteAction(true)}
            className="small"
            color="error"
            loading={cancelRequestedOpenShiftActionLoader}
          />
        </div>
        <div className="save-template-fields-grid">
          <List className="load-template-lists">
            {requestedPersonList?.map(
              ({ hoursThisWeek, cost, requestedAt, preferredFullName, personDetailId }) => {
                const labelId = `checkbox-list-label-${personDetailId}`;

                return (
                  <ListItem
                    key={personDetailId}
                    selected={selectedPerson === personDetailId}
                    disablePadding
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={() => handleTemplateSelect(personDetailId)}
                      dense
                      disableRipple
                    >
                      <div className="d-flex-column gap-10 w-full">
                        <div className="d-flex flex-align-center just-bet gap-10">
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={selectedPerson === personDetailId}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                              icon={<IconByName name="check_circle_outline" />}
                              checkedIcon={
                                <IconByName name="check_circle" className="primary-color" />
                              }
                            />
                          </ListItemIcon>
                          <ListItemText id={personDetailId} className="template-details">
                            <div className="d-flex flex-align-center just-bet gap-10">
                              <span className="subtitle-text">{preferredFullName}</span>
                            </div>
                          </ListItemText>
                        </div>
                        <div className="d-flex-column gap-5">
                          <div className="d-flex flex-align-center gap-10 body-text text-secondary-color">
                            <IconByName name="schedule" />
                            <span>Requested&nbsp;&nbsp;{displayDateTime(requestedAt)}</span>
                          </div>
                          <div className="d-flex flex-align-center body-text  just-bet gap-10">
                            <div className="d-flex flex-align-center gap-10 text-secondary-color">
                              <IconByName name="payments" />
                              <span>{cost}</span>
                            </div>
                            <div className="d-flex flex-align-center body-text gap-10 text-secondary-color">
                              <span>Hours this week :&nbsp;</span>
                              <span>{hoursThisWeek} h</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ListItemButton>
                  </ListItem>
                );
              }
            )}
          </List>
        </div>
      </div>
      <ConfirmationModal
        title="Delete Shifts"
        isOpen={deleteAction}
        handleClose={() => {
          setDeleteAction(null);
        }}
        handleSubmit={handleShiftDelete}
        primaryLabel="Remove"
        // loading={shiftBulkActionsActionLoader}
      >
        <div className="d-flex-column gap-10">
          <div>Are you sure you want to delete this open shift?</div>
        </div>
      </ConfirmationModal>
    </EzyDrawer>
  );
}

export default OpenShiftRequestDrawer;

OpenShiftRequestDrawer.propTypes = {
  reFetchEvents: PropTypes.func.isRequired,
};
