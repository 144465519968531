import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import EzyDialog from '../../../components/common/dialog/EzyDialog';
import EzyDialogTitle from '../../../components/common/dialog/EzyDialogTitle';
import EzyDialogAction from '../../../components/common/dialog/EzyDialogAction';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import ROUTE_CONSTANTS from '../../../utils/constants';
import { updateRequiredStaffDataAction } from '../redux/scheduleActions';

function EditCoverageDataModal({ open, handleClose, reFetchEvents }) {
  const { coverageData, sidePanelData } = useSelector(({ schedule }) => schedule ?? {});
  const { updateRequiredStaffDataActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const headers = coverageData?.[0]?.coverageData?.map((x) => x?.date);

  const handleFieldChange = (areaId, date, newValue) => {
    setData((prev) => {
      const prevData = cloneDeep(prev);

      return prevData?.map((x) => {
        if (x?.areaId === areaId) {
          return {
            ...x,
            coverageData: x?.coverageData?.map((y) => {
              if (y?.date === date) {
                return { date: y?.date, value: newValue };
              }
              return y;
            }),
          };
        }
        return x;
      });
    });
  };

  const handleSubmit = () => {
    dispatch(
      updateRequiredStaffDataAction(
        data?.map((x) => {
          return {
            areaId: x?.areaId,
            requiredStaffByDates: x?.coverageData?.map((y) => {
              return { ...y, value: y?.value || null };
            }),
          };
        }),
        () => {
          handleClose();
          reFetchEvents();
        }
      )
    );
  };

  const actionButton = [
    { label: 'Done', onClick: handleSubmit, loading: updateRequiredStaffDataActionLoader },
  ];

  useEffect(() => {
    setData(
      coverageData?.map((x) => {
        return {
          ...x,
          coverageData: x?.coverageData?.map((y) => {
            return {
              date: y?.date,
              value: y?.requiredStaff,
            };
          }),
        };
      })
    );
  }, [coverageData]);

  return (
    <EzyDialog
      open={open}
      handleClose={handleClose}
      className="common-dialog-with-body copy-dialog schedule-coverage-dialog"
    >
      <EzyDialogTitle title="Staff Coverage" onClose={handleClose} />
      <div className="dialog-body title-small">
        <div>Set your coverage requirements to be alerted when you are understaffed</div>
        <Paper className="table-paper">
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell size="small" className="empty-cell" />
                  {headers?.map((x) => (
                    <TableCell>
                      {moment
                        .tz(x, sidePanelData?.selectedLocation?.timeZoneId?.label)
                        .format('DD MMM')}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map(({ areaId, areaName, coverageData: dateWiseList }) => {
                  return (
                    <TableRow key={areaId}>
                      <TableCell>{areaName}</TableCell>
                      {dateWiseList?.map((x, index) => {
                        const value = dateWiseList?.find(
                          (y) => y?.date === headers?.[index]
                        )?.value;
                        return (
                          <TableCell>
                            <EzyInputField
                              value={value}
                              onChange={(e) =>
                                handleFieldChange(areaId, headers?.[index], e?.target?.value)
                              }
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <div>
          <span
            className="primary-color pointer-cursor"
            onClick={() => {
              history.push(
                `${ROUTE_CONSTANTS.SCHEDULE}/insights/staff?location=${sidePanelData?.selectedLocation?.id}`
              );
              handleClose();
            }}
          >
            Edit detailed coverage
          </span>{' '}
          in Business Insights
        </div>
      </div>
      <EzyDialogAction actionButtons={actionButton} />
    </EzyDialog>
  );
}

EditCoverageDataModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  reFetchEvents: PropTypes.func.isRequired,
};

export default EditCoverageDataModal;
