import { AccordionDetails } from '@mui/material';
import PropTypes from 'prop-types';

function EzyAccordionDetails({ children, className, ...restOther }) {
  return (
    <AccordionDetails className={`ezy-accordion-details ${className}`} {...restOther}>
      <div className="ezy-divider-line" />
      <div className="ezy-accordion-details-contain">{children}</div>
    </AccordionDetails>
  );
}

export default EzyAccordionDetails;
EzyAccordionDetails.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
};
EzyAccordionDetails.defaultProps = {
  className: '',
};
