import React from 'react';
import PropTypes from 'prop-types';

function ShowCount({ count, onClick }) {
  return (
    <>
      <div className="count">{count}</div>
      <div className="plus" onClick={onClick}>
        +
      </div>
    </>
  );
}

ShowCount.propTypes = {
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ShowCount;
