import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import AccessWrapper from '../accessWrapper/AccessWrapper';

function EzyIconButton({ iconName, className, variant, ...restProps }) {
  const classes = `iconButton ${className || ''}`;
  return (
    <AccessWrapper access={restProps?.access} byPass={!restProps?.access}>
      <Button variant={variant} className={classes} title={restProps?.tooltip} {...restProps}>
        <span className="material-icons">{iconName}</span>
      </Button>
    </AccessWrapper>
  );
}

export default EzyIconButton;

EzyIconButton.propTypes = {
  iconName: PropTypes.string.isRequired,
  className: PropTypes.string,
  variant: PropTypes.string,
};

EzyIconButton.defaultProps = {
  className: '',
  variant: 'contained',
};
