import {
  startGeneralLoader,
  stopGeneralLoader,
} from '../../../utils/backOfficeLists/commonDataSlice';
import { mapEnumToIds } from '../../../utils/enumMapping';
import { displayErrors } from '../../../utils/errorNotifyHelper';
import { successNotification } from '../../../utils/notifyHelper';
import { logbookServices } from '../logbookServices';
import {
  setAddCategoryDetails,
  setFilteredEntriesList,
  setLogsList,
  updateLogsList,
} from './logbookSlice';

export const getLogbookEntriesAction = (params) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const finalParam = {
        ...params,
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getLogbookEntriesActionLoader' }));
      const res = await logbookServices.getLogbookEntries(finalParam);
      if (res?.data?.success) {
        const data = mapEnumToIds(
          [
            { fieldName: 'categoryType', listName: 'categoryType', isFromEnum: true },
            { fieldName: 'timeZoneId', listName: 'timeZonesList', isFromEnum: false },
          ],
          res?.data?.data,
          state?.common
        );
        dispatch(setLogsList(data));
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getLogbookEntriesActionLoader' }));
    }
  };
};

export const addLogBookCategoryAction = (data, cb) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const finalData = {
        ...data,
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'addLogBookCategoryActionLoader' }));
      const res = await logbookServices.addLogBookCategory(finalData);
      if (res?.data?.success) {
        cb();
        successNotification(res?.data?.message);
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'addLogBookCategoryActionLoader' }));
    }
  };
};

export const addLogAction = (data, cb) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const finalData = {
        ...data,
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'addLogActionLoader' }));
      const res = await logbookServices.addLog(finalData);
      if (res?.data?.success) {
        cb({ data: res?.data?.data });
        successNotification(res?.data?.message);
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'addLogActionLoader' }));
    }
  };
};

export const uploadAttachmentAction = (data, config, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'uploadAttachmentActionLoader' }));
      const res = await logbookServices.uploadAttachment(data, config);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb(res?.data?.data);
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'uploadAttachmentActionLoader' }));
    }
  };
};

export const deleteUploadedAttachmentAction = (params, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'deleteUploadedAttachmentActionLoader' }));
      const res = await logbookServices.deleteUploadedAttachment(params);
      if (res?.data?.success) {
        cb();
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deleteUploadedAttachmentActionLoader' }));
    }
  };
};

export const deleteLogAction = (params, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'deleteLogActionLoader' }));
      const res = await logbookServices.deleteLog(params);
      if (res?.data?.success) {
        cb();
        successNotification(res?.data?.message);
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deleteLogActionLoader' }));
    }
  };
};

export const deleteLogBookCategoryAction = (params, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'deleteLogBookCategoryActionLoader' }));
      const res = await logbookServices.deleteLogBookCategory(params);
      if (res?.data?.success) {
        cb();
        successNotification(res?.data?.message);
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deleteLogBookCategoryActionLoader' }));
    }
  };
};

export const addLogCommentAction = (data, cb) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const finalData = {
        ...data,
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'addLogCommentActionLoader' }));
      const res = await logbookServices.addLogComment(finalData);
      if (res?.data?.success) {
        cb({ data: res?.data?.data });
        successNotification(res?.data?.message);
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'addLogCommentActionLoader' }));
    }
  };
};

export const deleteLogCommentAction = (params, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'deleteLogCommentActionLoader' }));
      const res = await logbookServices.deleteLogComment(params);
      if (res?.data?.success) {
        cb();
        successNotification(res?.data?.message);
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deleteLogCommentActionLoader' }));
    }
  };
};

export const getCategoryDetailsByIdAction = (params) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(startGeneralLoader({ loaderFor: 'getCategoryDetailsByIdActionLoader' }));
      const res = await logbookServices.getCategoryDetailsById(params);
      if (res?.data?.success) {
        const data = mapEnumToIds(
          [{ fieldName: 'categoryType', listName: 'categoryType', isFromEnum: true }],
          res?.data?.data,
          state?.common
        );
        dispatch(setAddCategoryDetails(data));
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getCategoryDetailsByIdActionLoader' }));
    }
  };
};

export const editLogBookCategoryAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'editLogBookCategoryActionLoader' }));
      const res = await logbookServices.editLogBookCategory(data);
      if (res?.data?.success) {
        cb();
        successNotification(res?.data?.message);
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'editLogBookCategoryActionLoader' }));
    }
  };
};

export const getLogsEntryByFiltersAction = (params) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(startGeneralLoader({ loaderFor: 'getLogsEntryByFiltersActionLoader' }));
      const res = await logbookServices.getLogsEntryByFilters(params);
      if (res?.data?.success) {
        const data = mapEnumToIds(
          [
            { fieldName: 'categoryType', listName: 'categoryType', isFromEnum: true },
            { fieldName: 'timeZoneId', listName: 'timeZonesList', isFromEnum: false },
          ],
          res?.data,
          state?.common
        );
        dispatch(setFilteredEntriesList(data));
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getLogsEntryByFiltersActionLoader' }));
    }
  };
};

export const saveAutoSendEmailTimeAction = (data, cb) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const finalData = {
        ...data,
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'saveAutoSendEmailTimeActionLoader' }));
      const res = await logbookServices.saveAutoSendEmailTime(finalData);
      if (res?.data?.success) {
        successNotification(res?.data?.data);
        cb();
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'saveAutoSendEmailTimeActionLoader' }));
    }
  };
};

export const sendLogBookSummaryAction = (data, cb) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const finalData = {
        ...data,
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'sendLogBookSummaryActionLoader' }));
      const res = await logbookServices.sendLogBookSummary(finalData);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      cb(e?.response?.data?.data);
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'sendLogBookSummaryActionLoader' }));
    }
  };
};

export const getLogBookCategoriesByBusinessIdAction = (cb) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const params = {
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getLogBookCategoriesByBusinessIdActionLoader' }));
      const res = await logbookServices.getLogBookCategoriesByBusinessId(params);
      if (res?.data?.success) {
        dispatch(updateLogsList({ fieldName: 'categoryDropdownList', value: res?.data?.data }));
        if (cb && typeof cb === 'function') {
          cb();
        }
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getLogBookCategoriesByBusinessIdActionLoader' }));
    }
  };
};
