import { isArray } from 'lodash';
import { errorNotification, warningNotification } from './notifyHelper';
// eslint-disable-next-line consistent-return
export const displayErrors = (e) => {
  if (e?.code === 'ECONNABORTED') {
    errorNotification('Request Timeout, Make sure you are connected to network.');
  } else if (e.message === 'Previous same call cancellation') {
    return false;
  } else if (isArray(e?.response?.data?.message)) {
    e?.response?.data?.message.map((res) => errorNotification(res));
    throw e;
  } else if (e?.response?.status === 406) {
    warningNotification(e?.response?.data?.message);
    throw e;
  } else {
    errorNotification(e?.response?.data?.message ?? 'Something went wrong, please try again later');
    // throw e;
  }
};
