import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import EzyMenu from '../../../components/common/menu/EzyMenu';
import { updateAddEditShiftFields } from '../redux/scheduleSlice';
import ROUTE_CONSTANTS from '../../../utils/constants';

function ShiftActions() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedLocation, weekDatesValue } = useSelector(
    ({ schedule }) => schedule?.sidePanelData ?? {}
  );
  const shiftData = useSelector(({ schedule }) => schedule?.addEditShift ?? {});

  const {
    addShiftDrawer,
    editShiftDrawer,
    shiftType,
    areaId,
    personDetailId,
    timeSheetId,
    isArchived,
  } = useMemo(() => shiftData, [shiftData]);

  const handleActionClick = (item) => {
    dispatch(
      updateAddEditShiftFields({
        fieldName: item?.value,
        value: item?.id,
      })
    );
  };

  const handleViewTimeSheet = () => {
    history.push(
      `${ROUTE_CONSTANTS.TIMESHEETS}?page=1&limit=15&location=${selectedLocation?.id}&area=${
        areaId?.id
      }&person=${personDetailId?.id}&date=${weekDatesValue?.startDate.toISOString()}`
    );
  };

  const shiftActions = useMemo(() => {
    const actions = [];
    if (!personDetailId && !isArchived)
      actions.push({
        label: 'Clone',
        id: 1,
        onClick: (_, item) => handleActionClick(item),
        value: 'isCloneDrawer',
      });

    if (!personDetailId) {
      actions.splice(1, 0, {
        label: 'Offer shift',
        id: 2,
        onClick: (_, item) => handleActionClick(item),
        value: 'isOfferShiftDrawer',
      });
    }
    if ([1, 3].includes(shiftType?.id)) {
      actions.splice(2, 0, {
        label: 'Make it empty',
        id: 6,
        onClick: (_, item) => handleActionClick(item),
        value: 'makeShiftEmpty',
      });
    }
    if (personDetailId) {
      actions.push({
        label: 'Find replacement',
        id: 7,
        onClick: (_, item) => handleActionClick(item),
        value: 'isReplacementDrawer',
      });
      // actions.push({
      //   label: 'View shift history',
      //   id: 8,
      //   onClick: (_, item) => handleActionClick(item),
      // });
    }
    if (timeSheetId) {
      actions.push({
        label: 'View timesheet',
        id: 9,
        onClick: () => handleViewTimeSheet(),
      });
    }
    actions.push({
      label: 'Repeat for tomorrow',
      id: 3,
      onClick: (_, item) => handleActionClick(item),
      value: 'repeatForTomorrow',
    });
    actions.push({
      label: 'Repeat for rest of week',
      id: 4,
      onClick: (_, item) => handleActionClick(item),
      value: 'repeatForWeek',
    });
    actions.push({
      label: 'Repeat for specific days',
      id: 5,
      onClick: (_, item) => handleActionClick(item),
      value: 'repeatForDays',
    });
    actions.push({
      label: 'View shift history',
      id: 6,
      onClick: (_, item) => handleActionClick(item),
      value: 'scheduleShiftHistoryDrawer',
    });
    return actions;
  }, [
    addShiftDrawer,
    editShiftDrawer,
    shiftType,
    areaId,
    personDetailId,
    handleViewTimeSheet,
    handleActionClick,
    timeSheetId,
    isArchived,
  ]);

  return <EzyMenu label="Actions" menuItems={shiftActions} color="secondary" autoWidth />;
}

export default ShiftActions;
