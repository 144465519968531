import {
  importPeopleFromIntegration,
  setAddLocationList,
  setArchiveLocationDetails,
  setEditDetailsLocation,
  setEditGeneralLocation,
  setImportedPeopleData,
  setLocationList,
  setLocationListEnum,
} from './locationSlice';
import { locationServices } from '../locationServices';
import { errorNotification, successNotification } from '../../../utils/notifyHelper';
import { peopleServices } from '../../people/peopleServices';
import { displayErrors } from '../../../utils/errorNotifyHelper';
import { mapEditDetailsLocationIds, mapEnumToIds } from '../../../utils/enumMapping';
import {
  startGeneralLoader,
  stopGeneralLoader,
} from '../../../utils/backOfficeLists/commonDataSlice';
import { setLocationsListForPeople } from '../../people/redux/peopleSlice';
import { timeSheetServices } from '../../timeSheets/timeSheetsServices';

export const getLocationList = (params = { page: 1, limit: 15 }) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const finalParams = {
        ...params,
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getLocationListLoader' }));
      const res = await locationServices.getLocationList(finalParams);
      if (res?.data?.success) {
        dispatch(setLocationList(res?.data));
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getLocationListLoader' }));
    }
  };
};

export const getLocationByLocationID = (param) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getLocationByLocationIDLoader' }));
      const res = await locationServices.getLocationByLocationID(param);

      if (res?.data?.success) {
        const data = mapEnumToIds(
          [{ fieldName: 'timeZoneId', listName: 'timeZonesList', isFromEnum: false }],
          res?.data?.data,
          state?.common
        );
        dispatch(setEditGeneralLocation(data));
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getLocationByLocationIDLoader' }));
    }
  };
};
export const getLocationDetailsByID = (param) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getLocationDetailsByIDLoader' }));
      const res = await locationServices.getLocationDetailsByID(param);
      if (res?.data?.success) {
        const data = mapEnumToIds(mapEditDetailsLocationIds, res?.data?.data, state?.common);
        dispatch(setEditDetailsLocation(data));
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getLocationDetailsByIDLoader' }));
    }
  };
};
export const sendAddLocation = (data) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'sendAddLocationLoader' }));

      const res = await locationServices.sendAddLocation(data);

      if (res?.data?.success) {
        successNotification(res?.data?.message);
        return true;
      }

      return false;
    } catch (e) {
      if (e?.response?.data?.data?.length > 0) {
        const errors = data?.people?.map((people) =>
          e?.response?.data?.data?.includes(people?.email)
            ? { email: 'Email already exists!' }
            : null
        );
        dispatch(setAddLocationList({ fieldName: 'errors', value: { people: errors } }));
      } else displayErrors(e);
      return false;

      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'sendAddLocationLoader' }));
    }
  };
};
export const sendEditGeneralLocation = (data) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'sendEditGeneralLocationLoader' }));
      const res = await locationServices.sendEditGeneralLocation(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        return true;
      }
      return false;
    } catch (e) {
      displayErrors(e);
      return false;
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'sendEditGeneralLocationLoader' }));
    }
  };
};
export const sendEditDetailsLocation = (data) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'sendEditDetailsLocationLoader' }));
      const res = await locationServices.sendEditDetailsLocation(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        return true;
      }
      return false;
    } catch (e) {
      errorNotification(e?.res?.data?.errors);
      return false;
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'sendEditDetailsLocationLoader' }));
    }
  };
};

export const copyLocationFromList = (data) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'copyLocationFromListLoader' }));
      const res = await locationServices.copyLocation(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        return true;
      }
      return false;
    } catch (e) {
      errorNotification(e?.res?.data?.errors);
      return false;
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'copyLocationFromListLoader' }));
    }
  };
};

export const duplicateLocationFromList = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const finalData = {
        ...data,
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'duplicateLocationFromListLoader' }));
      const res = await locationServices.duplicateLocation(finalData);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        return true;
      }
      return false;
    } catch (e) {
      displayErrors(e);
      return false;
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'duplicateLocationFromListLoader' }));
    }
  };
};

export const importPeopleCSVFileFromLocation = (data, config) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'importPeopleCSVFileFromLocationLoader' }));
      const response = await peopleServices.importPeopleCSVFile(data, config);
      if (response?.data?.success) {
        dispatch(setImportedPeopleData(response?.data?.data));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'importPeopleCSVFileFromLocationLoader' }));
    }
  };
};

export const getDetailsBeforeArchiveLocationAction = (params, cb) => {
  return async (dispatch, getState) => {
    const state = getState();
    const finalParams = {
      ...params,
      businessId: state?.user?.profileData?.businessId,
    };
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getDetailsBeforeArchiveLocationActionLoader' }));
      const response = await locationServices.getDetailsBeforeArchive(finalParams);
      if (response?.data?.success) {
        dispatch(setArchiveLocationDetails(response?.data?.data));
        if (cb && typeof cb === 'function') {
          cb();
        }
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getDetailsBeforeArchiveLocationActionLoader' }));
    }
  };
};

export const archiveLocationAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'archiveLocationActionLoader' }));
      const response = await locationServices.archiveLocation(data);
      if (response?.data?.success) {
        successNotification(response?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'archiveLocationActionLoader' }));
    }
  };
};

export const deleteLocationAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'deleteLocationActionLoader' }));
      const response = await locationServices.deleteLocation(data);
      if (response?.data?.success) {
        successNotification(response?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deleteLocationActionLoader' }));
    }
  };
};

export const getLocationsByBusinessId = (params) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const finalParams = {
        ...params,
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getLocationsByBusinessIdLoader' }));
      const res = await locationServices.getLocationsByBusinessId(finalParams);
      if (res?.data?.success) {
        dispatch(setLocationsListForPeople(res?.data?.data));
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getLocationsByBusinessIdLoader' }));
    }
  };
};
export const getLocationDropDownListByBusinessId = (cb) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const finalParams = {
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getLocationsByBusinessIdLoader' }));
      const res = await timeSheetServices.getLocationsDropdown(finalParams);
      if (res?.data?.success) {
        const data = mapEnumToIds(
          [{ fieldName: 'timeZoneId', listName: 'timeZonesList', isFromEnum: false }],
          res?.data?.data,
          state?.common
        );
        dispatch(setLocationListEnum(data));

        if (cb && typeof cb === 'function') {
          cb(data);
        }
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getLocationsByBusinessIdLoader' }));
    }
  };
};

export const integrationLoginUrlAction = (params, cb) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const finalParams = {
        ...params,
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'integrationLoginUrlActionLoader' }));
      const res = await locationServices.integrationLoginUrl(finalParams);
      if (res?.data?.success) {
        cb(res?.data?.data);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'integrationLoginUrlActionLoader' }));
    }
  };
};

export const peopleFromIntegrationAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'peopleFromIntegrationActionLoader' }));
      const res = await locationServices.peopleFromIntegration(params);
      if (res?.data?.success) {
        if (res?.data?.data?.length > 0) dispatch(importPeopleFromIntegration(res?.data?.data));
        else
          dispatch(
            importPeopleFromIntegration([
              {
                name: '',
                email: '',
                phoneNumber: null,
                countryCode: null,
              },
            ])
          );
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'peopleFromIntegrationActionLoader' }));
    }
  };
};
