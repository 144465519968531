import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import EzyDrawer from '../../../components/common/drawer/Drawer';
import { updateAddEditShiftFields } from '../redux/scheduleSlice';
import EzyInputField from '../../../components/common/inputField/EzyInputField';

function CloneShiftDrawer({ handleSubmit }) {
  const shiftData = useSelector(({ schedule }) => schedule?.addEditShift ?? {});

  const { isCloneDrawer, errors, noOfClone } = useMemo(() => shiftData, [shiftData]);

  const { editShiftActionLoader, addShiftActionLoader } = useSelector(
    ({ common }) => common?.generalLoader ?? {}
  );

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(
      updateAddEditShiftFields({
        fieldName: 'noOfClone',
        value: null,
      })
    );
    dispatch(
      updateAddEditShiftFields({
        fieldName: 'isCloneDrawer',
        value: false,
      })
    );
  };

  const actionButton = useMemo(
    () => [
      {
        label: 'Save',
        onClick: handleSubmit,
        loading: editShiftActionLoader || addShiftActionLoader,
      },
    ],
    [handleSubmit, editShiftActionLoader, addShiftActionLoader]
  );

  return (
    <EzyDrawer
      onClose={handleClose}
      isOpen={isCloneDrawer}
      actionButtons={actionButton}
      heading="Clone Shift"
    >
      <div className="shift-drawer">
        <div className="shift-drawer-field-grid">
          <EzyInputField
            name="noOfClone"
            placeholder="1"
            label="No of clone"
            value={noOfClone}
            onChange={(e) =>
              dispatch(
                updateAddEditShiftFields({
                  fieldName: 'noOfClone',
                  value: e.target.value,
                })
              )
            }
            error={errors?.noOfClone}
          />
        </div>
      </div>
    </EzyDrawer>
  );
}

export default CloneShiftDrawer;

CloneShiftDrawer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};
