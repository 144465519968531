import { Box, Menu } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EzyAvatar from '../../components/common/avatar/EzyAvatar';
import IconByName from '../../components/common/iconByName/IconByName';
import { errorNotification, successNotification } from '../../utils/notifyHelper';
import {
  clearAuthTokenFromLocalStorage,
  clearFCMTokenFromLocalStorage,
  getFCMTokenFromLocalStorage,
} from '../../utils/localStorageHelper';
import authServices from '../auth/authServices';
import ROUTE_CONSTANTS from '../../utils/constants';
import { updateBusinessData } from './redux/globalProfileSlice';
import SwitchBusinessDrawer from './components/SwitchBusinessDrawer';
import { showTimeSheetingAction } from '../people/redux/peopleActions';

function GlobalProfile() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { profilePictureUrl, personName, roleId } = useSelector(
    ({ user }) => user?.profileData || {}
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = async () => {
    try {
      const clientToken = getFCMTokenFromLocalStorage();
      const res = await authServices.logout({ clientToken });
      if (res.data.success) {
        successNotification(res.data.message);
        clearAuthTokenFromLocalStorage();
        clearFCMTokenFromLocalStorage();
        dispatch({ type: 'USER_LOGOUT' });
        history.push(ROUTE_CONSTANTS.LOGIN);
      }
    } catch (e) {
      errorNotification(e);
    }
  };

  const handleProfileClick = async () => {
    await dispatch(showTimeSheetingAction());
    handleClose();
    history.push(ROUTE_CONSTANTS.GLOBAL_PROFILE);
  };

  return (
    <div className="navBarUserinfoSectionAvatar">
      <EzyAvatar imageLink={profilePictureUrl} firstName={personName} onClick={handleClick} />
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            p: 1,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 20,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        <Box sx={{ paddingX: 1, bgcolor: 'white' }}>
          <div className="global-profile-wrapper">
            <div
              // className=" profile-settings profile-settings-rolewise"
              className={`profile-settings ${
                ![1, 2, 3].includes(roleId) && 'profile-settings-rolewise'
              }
              `}
            >
              <div
                className="pointer-cursor"
                onClick={() => {
                  handleClose();
                  history.push(ROUTE_CONSTANTS.ACCOUNT_OVERVIEW);
                }}
              >
                <IconByName name="badge" />
                <span>Account Overview</span>
              </div>
              <div onClick={() => handleProfileClick()} className="pointer-cursor">
                <IconByName name="person" />
                <span>Profile Settings</span>
              </div>

              {roleId && [1, 2, 3]?.includes(roleId) && (
                <div
                  className="pointer-cursor"
                  onClick={() => {
                    handleClose();
                    history.push(ROUTE_CONSTANTS.APP_INTEGRATION);
                  }}
                >
                  <IconByName name="settings_ethernet" />
                  <span> App Integration</span>
                </div>
              )}

              <div
                className="pointer-cursor"
                onClick={() => {
                  handleClose();
                  dispatch(updateBusinessData({ fieldName: 'switchBusinessDrawer', value: true }));
                }}
              >
                <IconByName name="swap_horiz" />
                <span>Switch Business</span>
              </div>
            </div>
            <div className="pointer-cursor logOut-button" onClick={logout}>
              <IconByName name="logout" /> Logout
            </div>
          </div>
        </Box>
      </Menu>
      <SwitchBusinessDrawer />
    </div>
  );
}

export default GlobalProfile;
