import { isNil, omitBy } from 'lodash';
import {
  passwordValidate,
  requireValidate,
  validatePhoneNumberLength,
} from '../../../utils/validationHelper';

const validateInvite = (data) => {
  const error = {};
  error.username = requireValidate('username', data.username);
  error.password = passwordValidate('password', data.password, true);
  error.confirmPassword = passwordValidate(
    'confirmPassword',
    data.password,
    true,
    true,
    data.confirmPassword
  );
  if (
    data?.phoneNumber &&
    data?.phoneNumberData &&
    !validatePhoneNumberLength(data?.phoneNumber, data?.phoneNumberData?.format)
  ) {
    error.phoneNumber = 'Invalid phone number!';
  }
  return omitBy(error, isNil);
};

export default validateInvite;
