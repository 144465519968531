import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { formatPhoneWithCode } from '../../../utils/phoneNumberHelper';

const globalProfileSlice = createSlice({
  name: 'globalProfile',
  initialState: {
    businessData: {
      switchBusinessDrawer: false,
      businessList: [],
      newBusinessId: '',
    },
    isEditProfile: false,
    profileDetails: {
      profilePhotoURL: '',
      preferredFullName: '',
      emailAddress: '',
      countryCode: '',
      phoneNumber: '',
      kioskPin: '',
      timeZoneId: '',
      dateOfBirth: '',
      gender: '',
      pronoun: '',
      countryId: '',
      city: '',
      address: '',
      postCode: '',
      emergencyPhone: '',
      emergencyContactCountryCode: '',
    },
    checkPasswordDrawer: false,
    password: '',
    changeEmailDrawer: false,
    email: '',
    accountOverviewCard: [],
    generalSettings: {},
    invoice: {
      invoiceList: {},
      invoiceData: {},
    },
    receipts: {
      receiptList: {},
    },
    notifications: [],
    appIntegration: {
      integrationList: [],
      isAddIntegrationsScreen: false,
      newLocationDrawer: false,
      configurationDrawer: {
        apiIntegrationId: '',
        integrationAPIName: '',
      },
      specificIntegrationDetails: {
        isActivateForAllLocations: false,
        apiInformation: '',
      },
      locationList: [],
      selectedLocation: '',
      locationSpecificConfigurations: {
        apiList: [],
        autoSyncEmployees: false,
      },
      myObFilesList: [],
      myObLoginDetails: {
        fileName: '',
        userName: '',
        password: '',
      },
    },
  },
  reducers: {
    updateBusinessData: (state, action) => {
      const { fieldName, value } = action.payload;
      state.businessData[fieldName] = value;
    },
    setGlobalProfileDetails: (state, action) => {
      state.profileDetails.profilePhotoURL = action.payload.profilePhotoURL;
      state.profileDetails.preferredFullName = action.payload.preferredFullName;
      state.profileDetails.emailAddress = action.payload.emailAddress;
      state.profileDetails.emergencyPhone = formatPhoneWithCode(
        action.payload.emergencyPhone,
        action.payload.emergencyContactCountryCode
      );
      state.profileDetails.phoneNumber = formatPhoneWithCode(
        action.payload.phoneNumber,
        action.payload.countryCode
      );
      state.profileDetails.countryCode = action.payload.countryCode;
      state.profileDetails.emergencyContactCountryCode = action.payload.emergencyContactCountryCode;
      state.profileDetails.kioskPin = action.payload.kioskPin;
      state.profileDetails.timeZoneId = action.payload.timeZoneId;
      state.profileDetails.dateOfBirth = action.payload.dateOfBirth;
      state.profileDetails.gender = action.payload.gender;
      state.profileDetails.pronoun = action.payload.pronoun;
      state.profileDetails.countryId = action.payload.countryId;
      state.profileDetails.city = action.payload.city;
      state.profileDetails.address = action.payload.address;
      state.profileDetails.postCode = action.payload.postCode;
    },
    updateGlobalProfileDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.profileDetails[fieldName] = value;
    },
    updateGlobalProfileData: (state, action) => {
      const { fieldName, value } = action.payload;
      state[fieldName] = value;
    },
    resetChangeEmailData: (state) => {
      state.password = '';
      state.email = '';
    },
    updateAccountOverviewCard: (state, action) => {
      // state.accountOverviewCard = action.payload;
      state.accountOverviewCard = action.payload.map((res) => {
        if (res?.expireDaysLeft === 0 && res?.isInvitationActionRequired === false) {
          return { ...res, status: 'upgrade' };
        }
        if (res?.expireDaysLeft < 15 && res?.isInvitationActionRequired === false) {
          return { ...res, status: 'warning' };
        }
        if (res?.isInvitationActionRequired === true) {
          return { ...res };
        }

        return { ...res, status: 'accept' };
      });
    },
    updateGeneralSettingsFields: (state, action) => {
      const { fieldName, value } = action.payload;
      state.generalSettings[fieldName] = value;
    },
    setInvoiceData: (state, action) => {
      state.invoice.invoiceList = {
        ...state.invoice.invoiceList,
        data: action.payload.data,
        total: action.payload.totalData,
        limit: action.payload.limit,
        page: action.payload.page,
        pages: action.payload.totalPages,
      };
    },
    setReceiptData: (state, action) => {
      state.receipts.receiptList = {
        ...state.receipts.receiptList,
        data: action.payload.data,
        total: action.payload.totalData,
        limit: action.payload.limit,
        page: action.payload.page,
        pages: action.payload.totalPages,
      };
    },
    setPlanDetails: (state, action) => {
      state.planDetails = action.payload;
    },
    setBillingDetails: (state, action) => {
      state.billingDetails = action.payload;
    },
    setPaymentMethods: (state, action) => {
      state.paymentMethods = action.payload;
    },
    updateBillingDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.billingDetails[fieldName] = value;
    },
    setBillingAndPaymentDetails: (state, action) => {
      state.billingAndPaymentDetails = action.payload;
    },
    updateBillingAndPaymentDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      if (!state.billingAndPaymentDetails || isEmpty(state.billingAndPaymentDetails)) {
        state.billingAndPaymentDetails = {};
      }
      state.billingAndPaymentDetails[fieldName] = value;
    },
    updateSummaryDetails: (state, action) => {
      state.billingAndPaymentDetails = {
        ...state.billingAndPaymentDetails,
        ...action.payload,
      };
    },
    resetSettingsData: (state) => {
      state.generalSettings = {};
      state.invoice = {
        invoiceList: {},
        invoiceData: {},
      };
      state.paymentMethods = [];
      state.planDetails = [];
      state.billingDetails = {};
      state.billingAndPaymentDetails = {};
    },
    resetBillingDetails: (state) => {
      state.billingDetails = {};
      state.billingAndPaymentDetails = {};
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload ?? [];
    },
    setNotificationsViaFCM: (state, action) => {
      state.notifications.unshift(action.payload);
    },
    resetNotifications: (state) => {
      state.notifications = [];
    },
    updateAppIntegrationData: (state, action) => {
      const { fieldName, value } = action.payload;
      state.appIntegration[fieldName] = value;
    },
    updateConfigurationDrawerDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.appIntegration.configurationDrawer[fieldName] = value;
    },
    resetAppIntegrationData: (state) => {
      state.appIntegration = {
        ...state.appIntegration,
        integrationList: [],
        newLocationDrawer: false,
        configurationDrawer: {},
        specificIntegrationDetails: {},
        locationList: [],
        selectedLocation: '',
        locationSpecificConfigurations: {
          apiList: [],
          autoSyncEmployees: false,
        },
      };
    },
    updateMyObLoginDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.appIntegration.myObLoginDetails[fieldName] = value;
    },
  },
});

const { reducer, actions } = globalProfileSlice;

export const {
  updateBusinessData,
  setGlobalProfileDetails,
  updateGlobalProfileDetails,
  updateGlobalProfileData,
  resetChangeEmailData,
  updateAccountOverviewCard,
  updateGeneralSettingsFields,
  setNotifications,
  setNotificationsViaFCM,
  resetNotifications,
  resetSettingsData,
  setInvoiceData,
  setReceiptData,
  setPlanDetails,
  setBillingDetails,
  updateBillingDetails,
  setBillingAndPaymentDetails,
  updateBillingAndPaymentDetails,
  updateSummaryDetails,
  resetBillingDetails,
  setPaymentMethods,
  updateAppIntegrationData,
  updateConfigurationDrawerDetails,
  resetAppIntegrationData,
  updateMyObLoginDetails,
} = actions;

export default reducer;
