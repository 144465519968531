const entities = {
  amp: '&',
  apos: "'",
  '#x27': "'",
  '#x2F': '/',
  '#39': "'",
  '#47': '/',
  lt: '<',
  gt: '>',
  nbsp: ' ',
  quot: '"',
};

// For decoding HTML entities specially for auto complete
export const decodeHTMLEntities = (text) => {
  return text.replace(/&([^;]+);/gm, (match, entity) => entities[entity] || match);
};
