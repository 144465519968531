import PropTypes from 'prop-types';

function IconByName({ name, className, onClick, ...restProps }) {
  return (
    <span className={`material-icons ${className}`} onClick={onClick} {...restProps}>
      {name}
    </span>
  );
}

export default IconByName;

IconByName.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

IconByName.defaultProps = {
  className: '',
  onClick: null,
};
