import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { omitBy, isNil, isEmpty } from 'lodash';
import moment from 'moment';
import { requireValidate } from '../../../utils/validationHelper';
import EzyDrawer from '../../../components/common/drawer/Drawer';
import {
  updateAddTaskDrawerDetails,
  resetAddTaskDrawer,
  updateTaskListFilters,
} from '../redux/tasksSlice';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import EzyAutoComplete from '../../../components/common/autoComplete/EzyAutoComplete';
import EzyDate from '../../../components/common/dateAndTimePicker/EzyDate';
import { getNewsFeedReceiverListAction } from '../../newsFeed/redux/newsFeedActions';
import { addTasksForPersonAction, editTaskAction, getTasksAction } from '../redux/tasksActions';

function AddTaskDrawer() {
  const dispatch = useDispatch();
  const { addTasksForPersonActionLoader, editTaskActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );
  const { addTaskDrawer, filters } = useSelector(({ tasks }) => tasks ?? {});
  const { receiverList } = useSelector(({ newsFeed }) => newsFeed ?? {});
  const { personId, timeZone } = useSelector(({ user }) => user?.profileData ?? {});
  const { drawerTitle, taskTitle, dueDate, note, selectedPerson, errors, isEditCategory } = useMemo(
    () => addTaskDrawer,
    [addTaskDrawer]
  );
  const handleFieldChange = (name, value) => {
    dispatch(updateAddTaskDrawerDetails({ fieldName: name, value }));
  };
  const handleClose = () => {
    dispatch(resetAddTaskDrawer());
  };

  const handleSubmit = async () => {
    const data = {
      title: taskTitle,
      note,
      assignedToPerson: selectedPerson?.id,
      dueDate:
        drawerTitle !== 'Edit Task' ? moment.tz(dueDate, timeZone?.label).toISOString() : dueDate,
    };

    let error = {};
    error.title = requireValidate('Title ', taskTitle);
    error.assignedToPerson = requireValidate(
      'Person',

      selectedPerson?.id
    );
    error = omitBy(error, isNil);
    handleFieldChange('errors', error);
    if (isEmpty(error)) {
      if (drawerTitle !== 'Edit Task') {
        await dispatch(
          addTasksForPersonAction(data, () => {
            handleClose();
            dispatch(
              getTasksAction({
                myTask:
                  drawerTitle === 'A Task For Me' ||
                  (drawerTitle === 'Add Task' && selectedPerson.id === personId),
                page: 1,
                limit: 15,
                searchText: null,
              })
            );

            dispatch(
              updateTaskListFilters({
                fieldName: 'taskType',
                value:
                  drawerTitle === 'A Task For Me' ||
                  (drawerTitle === 'Add Task' && selectedPerson.id === personId)
                    ? 'My tasks'
                    : 'Assigned tasks',
              })
            );
          })
        );
      } else {
        await dispatch(
          editTaskAction({ ...data, id: isEditCategory }, () => {
            handleClose();
            dispatch(
              getTasksAction({
                myTask: filters?.taskType === 'My tasks',
                page: 1,
                limit: 15,
                searchText: null,
              })
            );
          })
        );
      }
    }
  };
  const actionButton = [
    {
      label: 'Save',
      onClick: handleSubmit,
      loading: addTasksForPersonActionLoader || editTaskActionLoader,
    },
  ];

  useEffect(() => {
    (async () => {
      if (drawerTitle) {
        await dispatch(
          getNewsFeedReceiverListAction({ isSentFromTask: true }, (data) => {
            if (drawerTitle === 'Add Task' || drawerTitle === 'A Task For Me') {
              dispatch(
                updateAddTaskDrawerDetails({
                  fieldName: 'selectedPerson',
                  value: data?.receiverPersonList.filter((e) => e.id === personId)[0],
                })
              );
            }
          })
        );
      }
    })();
  }, [drawerTitle, filters?.taskType]);

  return (
    <EzyDrawer
      heading={drawerTitle}
      isOpen={drawerTitle}
      actionButtons={drawerTitle !== 'View Task' ? actionButton : []}
      onClose={handleClose}
    >
      <div className="task-for-me-drawer">
        <EzyInputField
          label="Task Title"
          name="taskTitle"
          placeholder="Enter Task Title"
          value={taskTitle}
          onChange={(e) => handleFieldChange('taskTitle', e.target.value)}
          disabled={drawerTitle === 'View Task'}
          error={errors?.title}
          required={drawerTitle !== 'View Task'}
        />
        <EzyAutoComplete
          placeholder="Select Person"
          label="Assign to"
          options={receiverList?.receiverPersonList}
          value={selectedPerson}
          name="selectedPerson"
          onChange={(_, value) => {
            handleFieldChange('selectedPerson', value);
          }}
          disabled={drawerTitle !== 'A Task For Someone Else' && drawerTitle !== 'Add Task'}
          error={errors?.assignedToPerson}
          required={drawerTitle !== 'Task By Area'}
        />
        <EzyDate
          placeholder="Select date"
          label="Due Date"
          value={dueDate}
          timeZone={timeZone?.label}
          name="dueDate"
          onChange={(value) => handleFieldChange('dueDate', value)}
          disabled={drawerTitle === 'View Task'}
        />
        <EzyInputField
          label="Notes"
          name="note"
          placeholder="Write the notes..."
          multiline
          rows={3}
          max-rows={5}
          value={note}
          onChange={(e) => handleFieldChange('note', e.target.value)}
          disabled={drawerTitle === 'View Task'}
        />
      </div>
    </EzyDrawer>
  );
}

export default AddTaskDrawer;
