import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import { isEmpty, isNull } from 'lodash';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import { updateBudgetSettingsDrawer } from '../../redux/insightSlice';
import EzyToggleButtonGroup from '../../../../components/common/toggleButton/EzyToggleButtonGroup';
import {
  deleteBudgetAction,
  getBudgetSettingsAction,
  getBudgetStatisticAction,
  getInsightListAction,
  updateBudgetSettingDetailAction,
} from '../../redux/insightAction';
import ROUTE_CONSTANTS from '../../../../utils/constants';
import ConfirmationModal from '../../../../components/common/modal/ConfirmationModal';

function BudgetSettingsDrawer() {
  const { budget, filters } = useSelector(({ insight }) => insight ?? {});
  const { pathname } = useParams();
  const history = useHistory();
  const { businessId } = useSelector(({ user }) => user?.profileData ?? {});

  const [deleteModalData, setDeleteModalData] = useState(null);

  const { isOpen, budgetPeriod, budgetGroupingByEntity } = useMemo(
    () => budget?.budgetSettingsDrawer,
    [budget?.budgetSettingsDrawer]
  );

  const { updateBudgetSettingDetailActionLoader, deleteBudgetActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const { budgetPeriod: budgetPeriodEnum, budgetGroupingBy: budgetGroupingByEnum } = useSelector(
    ({ common }) => common?.enums ?? {}
  );

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(updateBudgetSettingsDrawer({ fieldName: 'isOpen', value: false }));
  };

  const handleDeleteBudget = () => {
    dispatch(
      deleteBudgetAction({ budgetId: filters?.selectedModule?.id }, () => {
        handleClose();
        dispatch(getInsightListAction('Summary'));
      })
    );
    history.push(
      `${ROUTE_CONSTANTS.SCHEDULE}/insights/Summary?location=${
        filters?.selectedLocation?.id
      }&date=${moment
        .tz(filters?.weekDatesValue?.startDate, filters?.selectedLocation?.timeZoneId?.label)
        .toISOString()}`
    );
  };

  const handleFieldChange = (fieldName, value) => {
    dispatch(updateBudgetSettingsDrawer({ fieldName, value }));
  };

  const handleSubmit = () => {
    const data = {
      budgetId: filters?.selectedModule?.id,
      businessId,
      period: budgetPeriod,
      groupingBy: budgetGroupingByEntity,
    };
    dispatch(
      updateBudgetSettingDetailAction(data, () => {
        handleClose();
        dispatch(
          getInsightListAction(pathname, () => {
            dispatch(
              getBudgetStatisticAction({
                id: filters?.selectedModule?.id,
                locationId:
                  filters?.selectedLocation?.id !== 'all' ? filters?.selectedLocation?.id : null,
                businessId: filters?.selectedLocation?.id === 'all' ? businessId : null,
                weekStartDate: moment(filters?.weekDatesValue?.startDate)?.toISOString(),
                weekEndDate: moment(filters?.weekDatesValue?.endDate)?.toISOString(),
                areaId: filters?.selectedModule?.showArea ? filters?.areaId : null,
              })
            );
          })
        );
      })
    );
  };

  const actionButtons = [
    {
      label: 'Delete',
      color: 'error',
      onClick: (e) => setDeleteModalData(e),
      loading: deleteBudgetActionLoader,
    },
    {
      label: 'Save',
      onClick: () => handleSubmit(),
      loading: updateBudgetSettingDetailActionLoader,
    },
  ];

  useEffect(() => {
    if (isOpen) dispatch(getBudgetSettingsAction({ budgetId: filters?.selectedModule?.id }));
  }, [isOpen]);

  return (
    <>
      <EzyDrawer
        heading="Budget Settings"
        isOpen={isOpen}
        onClose={handleClose}
        actionButtons={actionButtons}
      >
        <div className="budget-settings-drawer">
          <div className="d-flex-column gap-5">
            <div className="title-text">Budget period</div>
            <div className="body-text text-secondary-color">
              Set different budgets per day or a single budget for the whole week
            </div>

            <EzyToggleButtonGroup
              className="small"
              list={budgetPeriodEnum?.map((e) => e?.label)}
              name="budgetPeriod"
              handleChange={(event) => {
                const value = budgetPeriodEnum?.find((e) => e?.label === event.target.value)?.id;
                handleFieldChange('budgetPeriod', value);
              }}
              value={budgetPeriodEnum?.find((e) => e?.id === budgetPeriod)?.label}
              required
            />
          </div>
          <div className="d-flex-column gap-5">
            <div className="title-text">Budget by location or area</div>
            <div className="body-text text-secondary-color">
              Set different budgets for each area or a budget for the whole location
            </div>

            <EzyToggleButtonGroup
              className="small"
              list={budgetGroupingByEnum?.map((e) => e?.label)}
              name="budgetPeriod"
              handleChange={(event) => {
                const value = budgetGroupingByEnum?.find(
                  (e) => e?.label === event.target.value
                )?.id;
                handleFieldChange('budgetGroupingByEntity', value);
              }}
              value={budgetGroupingByEnum?.find((e) => e?.id === budgetGroupingByEntity)?.label}
              required
            />
          </div>
        </div>
      </EzyDrawer>
      <ConfirmationModal
        title="Delete Budget"
        isOpen={!isEmpty(deleteModalData) || !isNull(deleteModalData)}
        handleClose={() => {
          setDeleteModalData(null);
        }}
        handleSubmit={async () => {
          await handleDeleteBudget();
          setDeleteModalData(null);
        }}
        primaryLabel="Delete"
      >
        <div>
          <div className="subtitle-text">
            Are you sure you want to delete your{' '}
            {filters?.selectedModule?.label?.includes('Hour') ? 'hour' : 'wage'} budget ?
          </div>
          <div className="secondary-caption-text">
            This will delete the{' '}
            {filters?.selectedModule?.label?.includes('Hour') ? 'hour' : 'wage'} budget for all
            locations, along with all associated data.
          </div>
        </div>
      </ConfirmationModal>
    </>
  );
}

BudgetSettingsDrawer.propTypes = {};

export default BudgetSettingsDrawer;
