import { filter, isEmpty, isNil, omitBy, toLower } from 'lodash';
import {
  findDuplicates,
  isEmail,
  requireValidate,
  validatePhoneNumberLength,
} from '../../../utils/validationHelper';

const validateAddPeopleData = (data) => {
  const { locationId, people } = data ?? {};
  const error = {};
  error.location = requireValidate('Location', locationId);
  error.people = people?.map((e) => {
    const obj = {};

    obj.name = requireValidate('Name', e?.name);
    obj.email = isEmail('Email', e?.email);
    if (!e?.accessLevel?.id) {
      obj.accessLevel = requireValidate('Access Level', e?.accessLevel);
    } else if (data?.roleId !== 2 && e?.accessLevel?.id < data?.roleId) {
      obj.accessLevel = `You are not allowed to set this user as ${e?.accessLevel?.label} !`;
    }

    if (
      e?.phoneNumberData &&
      e?.phoneNumber &&
      !validatePhoneNumberLength(e?.phoneNumber, e?.phoneNumberData?.format)
    ) {
      obj.phoneNumber = 'Invalid phone number';
    }
    if (e?.phoneNumber === '') {
      obj.phoneNumber = 'Invalid phone number';
    }
    return omitBy(obj, isNil);
  });

  const duplicates = findDuplicates(people?.map((e) => toLower(e?.email)));
  if (duplicates?.length > 0) {
    duplicates?.forEach((e) => {
      error.people[e].email = 'Email already exists!';
    });
  }
  error.people = filter(error.people, (e) => !isEmpty(e))?.length ? error.people : null;
  return omitBy(error, isNil);
};

export default validateAddPeopleData;
