import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useQueryParams from '../../../../hooks/useQueryParams';
import { getCheckoutSessionDetailsAction } from '../../redux/globalProfileActions';
import success from '../../../../assets/images/paymentSuccess.svg';
import failed from '../../../../assets/images/paymentFailed.svg';
import ROUTE_CONSTANTS from '../../../../utils/constants';
import { displayDate } from '../../../../utils/timeHelper';
import EzyDialog from '../../../../components/common/dialog/EzyDialog';
import IconByName from '../../../../components/common/iconByName/IconByName';

function PaymentStatus() {
  const { paymentStatus } = useParams();
  const { session_id: sessionId } = useQueryParams();
  const [sessionData, setSessionData] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const { timeZone } = useSelector(({ user }) => user?.profileData ?? {});

  const { planDuration, planName, startDate } = useMemo(() => sessionData ?? {}, [sessionData]);

  const handleModalClose = () => {
    setSessionData(null);
    history.push(ROUTE_CONSTANTS.ACCOUNT_OVERVIEW);
  };

  useEffect(() => {
    if (paymentStatus === 'paymentSuccess' && sessionId) {
      dispatch(
        getCheckoutSessionDetailsAction({ sessionId }, (data) => {
          setSessionData(data);
        })
      );
    }
  }, [paymentStatus, sessionId]);

  return paymentStatus === 'paymentSuccess' ? (
    <EzyDialog
      handleClose={handleModalClose}
      open={sessionData}
      className="common-info-modal payment-status"
    >
      <IconByName name="close" className="pointer-cursor close-icon" onClick={handleModalClose} />
      <img src={success} alt="payrollEzy" className="info-modal-image" />
      <div className="title">{`You're all set!`}</div>
      <div className="subtitle-text">
        {planDuration === 'Yearly'
          ? `Your purchase for ${planName}(${planDuration}) plan  was successful. Now you can access all Premium features of the Payroll Ezy for a year starting from ${displayDate(
              startDate,
              timeZone?.label
            )}`
          : ` Your purchase for ${planName}(${planDuration}) plan was successful, The subscription amount will be debited monthly from the given card details.`}
      </div>
    </EzyDialog>
  ) : (
    <EzyDialog
      handleClose={handleModalClose}
      open={paymentStatus === 'paymentCancel'}
      className="common-info-modal payment-status"
    >
      <IconByName name="close" className="pointer-cursor close-icon" onClick={handleModalClose} />
      <img src={failed} alt="payrollEzy" className="info-modal-image" />
      <div className="title">Payment Failed!</div>
      <div className="subtitle-text">
        Oops! something went wrong! Please check your card details and try again.
      </div>
    </EzyDialog>
  );
}

export default PaymentStatus;
