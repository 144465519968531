import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import EzyDrawer from '../../../components/common/drawer/Drawer';
import {
  addDeleteShiftBreakDetails,
  resetAddEditShiftFields,
  updateAddEditShiftFields,
} from '../redux/scheduleSlice';
import EzyAutoComplete from '../../../components/common/autoComplete/EzyAutoComplete';
import EzyDateTime from '../../../components/common/dateAndTimePicker/EzyDateTime';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import EzyIconButton from '../../../components/common/iconButton/EzyIconButton';
import EzySwitch from '../../../components/common/switch/EzySwitch';
import ROUTE_CONSTANTS, { breakDurationList } from '../../../utils/constants';
import EzyTime from '../../../components/common/dateAndTimePicker/EzyTime';
import EzyAvatar from '../../../components/common/avatar/EzyAvatar';
import IconByName from '../../../components/common/iconByName/IconByName';
import {
  addShiftAction,
  deleteShiftAction,
  editShiftAction,
  getLocationSettingsForShiftAction,
  getShiftByShiftIdAction,
  getShiftDropdownEntitiesAction,
  getShiftPanelDetailsAction,
  shiftBulkActionsAction,
} from '../redux/scheduleActions';
import {
  concatDateAndTime,
  displayHoursAndMinutesFromHours,
  getDurationInHours,
} from '../../../utils/timeHelper';
import validateShift from '../validations/validateShift';
import ConfirmationModal from '../../../components/common/modal/ConfirmationModal';
import Loader from '../../../components/common/loader/Loader';
import ShiftActions from './ShiftActions';
import CloneShiftDrawer from './CloneShiftDrawer';
import RepeatShiftForDaysDrawer from './RepeatShiftForDaysDrawer';
import ShiftHistoryDrawer from './ShiftHistoryDrawer';

function GetPayData({ label, iconName, value }) {
  return (
    <div className="shift-info" key={Math.random}>
      <div className="round-icon">
        <IconByName name={iconName} />
      </div>

      <div>
        <div className="subtitle-text">{value}</div>
        <div className="label">{label}</div>
      </div>
    </div>
  );
}

GetPayData.propTypes = {
  label: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function AddEditScheduleDrawer({ reFetchEvents }) {
  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useDispatch();

  const { businessId } = useSelector(({ user }) => user?.profileData ?? {});

  const shiftData = useSelector(({ schedule }) => schedule?.addEditShift ?? {});
  const { selectedLocation: location, weekDatesValue } = useSelector(
    ({ schedule }) => schedule?.sidePanelData
  );

  const { shiftType: shiftTypeEnum, breakType: breakTypeEnum } = useSelector(
    ({ common }) => common?.enums ?? {}
  );
  const {
    getShiftByShiftIdActionLoader,
    getLocationSettingsForShiftActionLoader,
    getShiftDropdownEntitiesActionLoader,
    getShiftPanelDetailsActionLoader,
    editShiftActionLoader,
    addShiftActionLoader,
    deleteShiftActionLoader,
  } = useSelector(({ common }) => common?.generalLoader ?? {});

  const {
    addShiftDrawer,
    editShiftDrawer,
    shiftType,
    shiftBreakDetailsList,
    endTime,
    startTime,
    areaId,
    personDetailId,
    errors,
    totalMealBreak,
    totalRestBreak,
    note,
    areaList,
    peopleList,
    shiftPayDetails,
    isApprovalRequired,
    // edit shift fields
    shiftId,
    isLocked,
    isArchived,
    noOfClone,
    isCloneDrawer,
    repeatForWeek,
    repeatForTomorrow,
    repeatForDays,
    weekDays,
    makeShiftEmpty,
    isReplacementDrawer,
    isOfferShiftDrawer,
    isReFetchEvents = false,
  } = useMemo(() => shiftData, [shiftData]);

  const payData = useMemo(() => {
    const { weeklyHours = 0, weeklyCost = 0, shiftCost = '-' } = shiftPayDetails ?? {};

    const shiftMinutes = moment(endTime).diff(moment(startTime), 'minute', true);

    return [
      {
        label: 'Weekly Hours',
        value: displayHoursAndMinutesFromHours(weeklyHours),
        iconName: 'calendar_month',
      },
      { label: 'Weekly Cost', value: weeklyCost, iconName: 'payments' },
      {
        label: 'Shift Length',
        value:
          shiftData?.isMealBreakPaid === false
            ? displayHoursAndMinutesFromHours((shiftMinutes - (totalMealBreak ?? 0)) / 60)
            : displayHoursAndMinutesFromHours(getDurationInHours(startTime, endTime)),
        iconName: 'watch_later',
      },
      { label: 'Shift Cost', value: shiftCost, iconName: 'request_quote' },
    ];
  }, [
    shiftPayDetails,
    startTime,
    endTime,
    shiftData?.isMealBreakPaid,
    shiftBreakDetailsList,
    totalMealBreak,
  ]);

  const getPayData = async ({
    personDetailId: personId,
    areaId: area,
    startTime: start,
    endTime: end,
    shiftType: shift,
    totalMealBreak: mealBreakDuration,
  }) => {
    if (
      personId?.id &&
      area?.id &&
      start &&
      end &&
      ![0, 1].includes(shift?.id) &&
      mealBreakDuration >= 0
    ) {
      const UnpaidBreakShiftLength = (
        (moment(endTime).diff(moment(startTime), 'minute', true) - mealBreakDuration) /
        60
      ).toFixed(2);

      const data = {
        personDetailId: personId?.id,
        areaId: area?.id,
        shiftStartTime: startTime,
        shiftLength:
          shiftData?.isMealBreakPaid === false
            ? UnpaidBreakShiftLength
            : getDurationInHours(startTime, endTime),
        weekStartDate: moment(weekDatesValue?.startDate)?.toISOString(),
        weekEndDate: moment(weekDatesValue?.endDate)?.toISOString(),
      };
      await dispatch(getShiftPanelDetailsAction(data));
    } else {
      dispatch(
        updateAddEditShiftFields({
          fieldName: 'shiftPayDetails',
          value: {},
        })
      );
    }
  };

  const handleFieldChange = (name, value, index) => {
    if ((index || index === 0) && (name === 'startTime' || name === 'endTime')) {
      const timeZone = areaId?.timeZoneId?.label ?? moment.tz.guess();
      const scheduleStartTime = moment.tz(startTime, timeZone).format('HH');

      const scheduleEndTime = moment.tz(endTime, timeZone).format('HH');

      if (scheduleStartTime < scheduleEndTime) {
        // For single day timesheet
        const breakTime = concatDateAndTime(startTime, value, timeZone);

        dispatch(updateAddEditShiftFields({ fieldName: name, value: breakTime, index }));
      } else {
        const breakTime = moment.tz(value, timeZone).format('HH');

        if (breakTime <= scheduleEndTime) {
          dispatch(
            updateAddEditShiftFields({
              fieldName: name,
              value: concatDateAndTime(endTime, value, timeZone),
              index,
            })
          );
        } else if (breakTime === scheduleEndTime && name === 'startTime') {
          dispatch(
            updateAddEditShiftFields({
              fieldName: name,
              value: concatDateAndTime(endTime, value, timeZone),
              index,
            })
          );
          dispatch(
            updateAddEditShiftFields({
              fieldName: 'endTime',
              value: concatDateAndTime(endTime, shiftBreakDetailsList?.[index]?.endTime, timeZone),
              index,
            })
          );
        } else {
          dispatch(
            updateAddEditShiftFields({
              fieldName: name,
              value: concatDateAndTime(startTime, value, timeZone),
              index,
            })
          );
        }
      }
    } else {
      dispatch(updateAddEditShiftFields({ fieldName: name, value, index }));
    }

    if (
      ['personDetailId', 'areaId', 'startTime', 'endTime', 'breakType', 'totalMealBreak'].includes(
        name
      )
    ) {
      const data = { personDetailId, areaId, startTime, endTime, shiftType, totalMealBreak };

      (async () => {
        await getPayData({ ...data, [name]: value });
      })();
    }
  };

  const handleClose = () => {
    dispatch(resetAddEditShiftFields());
  };

  const submitCB = (id) => {
    if (isReplacementDrawer || isOfferShiftDrawer) {
      handleFieldChange(isReplacementDrawer ? 'shiftReplacementDrawer' : 'offerShiftDrawer', id);
      handleFieldChange('editShiftDrawer', false);
      handleFieldChange('addShiftDrawer', false);
    } else {
      handleClose();
      reFetchEvents();
    }
  };
  const handleSubmit = async () => {
    const data = {
      shiftType: shiftType?.id,
      shiftBreakDetailsList,
      endTime,
      startTime,
      areaId: areaId?.id,
      personDetailId: personDetailId?.id,
      note,
      isApprovalRequired: shiftType?.id === 1 ? isApprovalRequired : undefined,
    };

    const error = validateShift({
      ...data,
      totalMealBreak,
      totalRestBreak,
      noOfClone,
      isCloneDrawer,
      repeatForDays,
      weekDays,
    });
    dispatch(
      updateAddEditShiftFields({
        fieldName: 'errors',
        value: error,
      })
    );
    if (isEmpty(error)) {
      const finalData = cloneDeep(data);

      finalData.shiftBreakDetailsList = finalData.shiftBreakDetailsList
        ?.filter((e) => e?.breakType?.id)
        ?.map((e) => ({
          breakType: e?.breakType?.id,
          startTime: e?.startTime,
          endTime: e?.endTime,
          duration: Number(e?.duration),
          shiftBreakId: e?.shiftBreakId,
        }));
      if (isCloneDrawer) {
        finalData.noOfClone = noOfClone;
      }
      if (repeatForDays) {
        finalData.weekDays = weekDays;
      }
      if (repeatForTomorrow || repeatForWeek || repeatForDays) {
        let repeatShift;
        if (repeatForTomorrow) repeatShift = 1;
        if (repeatForWeek) repeatShift = 2;
        if (repeatForDays) repeatShift = 3;
        finalData.repeatShift = repeatShift;
      }
      if (addShiftDrawer) {
        dispatch(addShiftAction(finalData, (id) => submitCB(id)));
      } else {
        finalData.shiftId = shiftId;
        dispatch(editShiftAction(finalData, (id) => submitCB(id)));
      }
    }
  };

  const handleDelete = () => {
    dispatch(
      deleteShiftAction({ shiftId }, () => {
        setDeleteModal(false);
        handleClose();
        reFetchEvents();
      })
    );
  };

  const actionButton = useMemo(() => {
    const actions = [];
    if (editShiftDrawer) {
      actions.push({
        label: 'Delete',
        onClick: () => setDeleteModal(true),
        color: 'error',
      });
    }
    actions.push({
      label: 'Save',
      onClick: handleSubmit,
      loading: editShiftActionLoader || addShiftActionLoader,
    });
    return actions;
  }, [handleSubmit, editShiftActionLoader, addShiftActionLoader, editShiftDrawer]);

  const modalHeading = useMemo(() => {
    if (addShiftDrawer) {
      return 'Add Shift';
    }
    if (editShiftDrawer && isLocked) {
      return (
        <div className="flex-center gap-10">
          Edit Shift
          <IconByName name="lock" className="white-color" title="Shift is locked" />
        </div>
      );
    }
    if (editShiftDrawer && isArchived) {
      return (
        <div className="flex-center gap-10">
          Archived Shift
          <IconByName name="archive" className="white-color" title="Archived" />
        </div>
      );
    }
    return 'Edit Shift';
  }, [addShiftDrawer, editShiftDrawer, isArchived, isLocked]);

  useEffect(() => {
    const output = shiftBreakDetailsList?.reduce((obj, el) => {
      const property = el?.breakType?.id;
      obj[property] = (obj[property] || 0) + Number(el.duration);
      return obj;
    }, {});

    if (output?.['1'] !== totalMealBreak) {
      handleFieldChange('totalMealBreak', output?.['1'] ?? 0);
    }
    if (output?.['2'] !== totalRestBreak) {
      handleFieldChange('totalRestBreak', output?.['2'] ?? 0);
    }
  }, [shiftBreakDetailsList]);

  useEffect(() => {
    if (editShiftDrawer) {
      (async () => {
        await dispatch(
          getShiftDropdownEntitiesAction(
            {
              locationId:
                location?.id !== 'all' && !location?.locationId
                  ? location?.id
                  : location?.locationId,
              businessId: location?.id === 'all' ? businessId : null,
            },
            areaId
          )
        );
        if (editShiftDrawer && shiftId)
          await dispatch(getShiftByShiftIdAction({ shiftId }, areaId?.timeZoneId?.label));
      })();
    }
  }, [editShiftDrawer, shiftId, location]);

  useEffect(() => {
    if (repeatForWeek || repeatForTomorrow || isReplacementDrawer || isOfferShiftDrawer) {
      (async () => {
        await handleSubmit();
      })();
    }
  }, [repeatForWeek, repeatForTomorrow, isReplacementDrawer, isOfferShiftDrawer]);

  useEffect(() => {
    if (makeShiftEmpty) {
      if (addShiftDrawer) {
        handleFieldChange('shiftType', shiftTypeEnum?.[1]);
        handleFieldChange('makeShiftEmpty', null);
      }
      if (editShiftDrawer) {
        const data = {
          shiftIds: [shiftId],
          shiftBulkActions: 1,
        };
        dispatch(
          shiftBulkActionsAction(data, () => {
            handleClose();
            reFetchEvents();
          })
        );
      }
    }
  }, [makeShiftEmpty]);

  useEffect(() => {
    if (isReFetchEvents) reFetchEvents();
  }, [isReFetchEvents]);

  return (
    <EzyDrawer
      onClose={handleClose}
      isOpen={addShiftDrawer || editShiftDrawer}
      actionButtons={isLocked ? [] : actionButton}
      heading={modalHeading}
      isLoading={
        getShiftByShiftIdActionLoader ||
        getLocationSettingsForShiftActionLoader ||
        getShiftDropdownEntitiesActionLoader
      }
      leftAction={!isLocked && <ShiftActions />}
    >
      <div className="shift-drawer">
        <div className="shift-info-wrapper">
          {getShiftPanelDetailsActionLoader && <Loader />}
          <div className="shift-user-info">
            <EzyAvatar
              firstName={shiftPayDetails?.name}
              imageLink={shiftPayDetails?.profilePhotoURl}
            />
            <div>{shiftPayDetails?.name || 'Not Assigned'}</div>
            {personDetailId?.id && (
              <div className="flex-one d-flex flex-align-center just-right">
                <IconByName
                  className="pointer-cursor"
                  name="preview"
                  title="View person details"
                  onClick={() =>
                    window.open(`${ROUTE_CONSTANTS.PEOPLE}/edit/${personDetailId?.id}`, '_newtab')
                  }
                />
              </div>
            )}
          </div>
          {payData?.map((e) => (
            <GetPayData iconName={e.iconName} label={e.label} value={e.value} />
          ))}
        </div>
        <div className="shift-drawer-field-grid">
          <EzyAutoComplete
            name="shiftType"
            placeholder="Select shift type"
            label="Shift type"
            options={shiftTypeEnum}
            value={shiftType}
            onChange={(_, value) => handleFieldChange('shiftType', value)}
            getOptionDisabled={(option) => option.id === 4}
            error={errors?.shiftType}
            disabled={isLocked}
            required
          />
          {shiftType?.id === 1 && (
            <EzySwitch
              name="IsApprovalRequired"
              label="Require approval"
              checked={isApprovalRequired}
              handleChange={(e) => handleFieldChange('isApprovalRequired', e?.target?.checked)}
              tooltip="Allow team member to request this shift, pending your approval."
              disabled={isLocked}
            />
          )}
          <div className="two-grid">
            <EzyAutoComplete
              name="areaId"
              placeholder="Select area"
              label="Area"
              options={areaList}
              value={areaId}
              onChange={(_, value) => {
                handleFieldChange('areaId', value);
                if (startTime && endTime) {
                  dispatch(
                    getLocationSettingsForShiftAction({
                      start: moment.tz(startTime, value?.timeZoneId?.label)?.toISOString(),
                      end: moment.tz(endTime, value?.timeZoneId?.label)?.toISOString(),
                      areaId: value?.id,
                      personDetailId: personDetailId?.id,
                      currentView: addShiftDrawer,
                      timeZone: value?.timeZoneId?.label,
                    })
                  );
                }
              }}
              error={errors?.areaId}
              disabled={isLocked}
              required
            />
            <EzyAutoComplete
              name="people"
              placeholder="Select people"
              label="People"
              options={peopleList}
              value={personDetailId}
              onChange={(_, value) => handleFieldChange('personDetailId', value)}
              error={errors?.personDetailId}
              disabled={[1, 2]?.includes(shiftType?.id) || isLocked}
              required={shiftType?.id === 3 || shiftType?.id === 4}
            />
          </div>
          <div className="two-grid">
            <EzyDateTime
              name="startTime"
              placeholder="Select start time"
              label="Start time"
              value={startTime}
              onChange={(value) => handleFieldChange('startTime', value)}
              error={errors?.startTime}
              timeZone={areaId?.timeZoneId?.label}
              disabled={isLocked}
              required
            />
            <EzyDateTime
              name="endTime"
              placeholder="Select end time"
              label="End time"
              value={endTime}
              onChange={(value) => handleFieldChange('endTime', value)}
              error={errors?.endTime}
              timeZone={areaId?.timeZoneId?.label}
              disabled={isLocked}
              required
            />
          </div>
        </div>
        <div className="title-with-primary-bg ">Break Summary</div>
        <div className="shift-drawer-field-grid">
          <div className="two-grid">
            <EzyInputField
              name="totalMealBreak"
              placeholder="0"
              label="Total meal break"
              value={totalMealBreak}
              // onChange={(value) => onChangeFields('totalMealBreak', value)}
              error={errors?.totalMealBreak}
              disabled
            />
            <EzyInputField
              name="totalRestBreak"
              placeholder="0"
              label="Total rest break"
              value={totalRestBreak}
              // onChange={(value) => onChangeFields('totalMealBreak', value)}
              error={errors?.totalRestBreak}
              disabled
            />
          </div>
        </div>
        <div className="title-with-primary-bg area-title">
          <span>Break Details</span>
          <span>
            <EzyIconButton
              iconName="add"
              onClick={() => dispatch(addDeleteShiftBreakDetails({ type: 'ADD' }))}
              className="small"
              disabled={isLocked}
            />
          </span>
        </div>
        {shiftBreakDetailsList?.map((entity, index) => (
          <div className="add-timesheet-fields-grid" key={entity?.shiftBreakId}>
            <div className="d-flex flex-align-center just-bet">
              <EzySwitch
                name="isCustom"
                label="Set break start and end time"
                // subLabel="If turned on, gender will be displayed on the team member profile."
                checked={entity?.isCustom}
                handleChange={(e) => handleFieldChange('isCustom', e?.target?.checked, index)}
                disabled={isLocked}
              />
              <span>
                <EzyIconButton
                  iconName="delete"
                  onClick={() => dispatch(addDeleteShiftBreakDetails({ type: 'DELETE', index }))}
                  className="small"
                  disabled={isLocked}
                />
              </span>
            </div>
            <div className="two-grid">
              <EzyAutoComplete
                placeholder="Select type"
                label="Type"
                options={breakTypeEnum}
                value={entity?.breakType}
                name="breakType"
                onChange={(_, value) => handleFieldChange('breakType', value, index)}
                error={errors?.shiftBreakDetailsList?.[index]?.breakType}
                disabled={isLocked}
              />
              {!entity?.isCustom ? (
                <EzyAutoComplete
                  placeholder="Select duration"
                  label="Duration"
                  options={breakDurationList}
                  value={entity?.duration}
                  name="duration"
                  onChange={(_, value) => handleFieldChange('duration', value, index)}
                  disabled={isLocked}
                />
              ) : (
                <EzyInputField
                  placeholder="0"
                  label="Duration"
                  value={entity?.duration}
                  name="duration"
                  onChange={(_, value) => handleFieldChange('duration', value, index)}
                  disabled
                />
              )}
            </div>
            {entity?.isCustom && (
              <div className="two-grid">
                <EzyTime
                  name="startTime"
                  placeholder="Select time"
                  label="Start time"
                  value={entity?.startTime}
                  onChange={(value) => handleFieldChange('startTime', value, index)}
                  error={errors?.shiftBreakDetailsList?.[index]?.startTime}
                  timeZone={areaId?.timeZoneId?.label}
                  disabled={isLocked}
                />
                <EzyTime
                  name="endTime"
                  placeholder="Select time"
                  label="End time"
                  value={entity?.endTime}
                  onChange={(value) => handleFieldChange('endTime', value, index)}
                  error={errors?.shiftBreakDetailsList?.[index]?.endTime}
                  timeZone={areaId?.timeZoneId?.label}
                  disabled={isLocked}
                />
              </div>
            )}
          </div>
        ))}
        <div className="add-timesheet-fields-grid">
          <EzyInputField
            label="Note"
            placeholder="Enter note"
            multiline
            rows={3}
            max-rows={5}
            value={note}
            onChange={(event) => handleFieldChange('note', event.target.value)}
            error={errors?.note}
            disabled={isLocked}
          />
        </div>
      </div>
      <ConfirmationModal
        title="Delete Shift"
        isOpen={deleteModal}
        handleClose={() => {
          setDeleteModal(false);
        }}
        handleSubmit={() => {
          handleDelete();
        }}
        primaryLabel="Delete"
        loading={deleteShiftActionLoader}
      >
        <div>Are you sure you want to delete?</div>
      </ConfirmationModal>
      <CloneShiftDrawer handleSubmit={handleSubmit} />
      <RepeatShiftForDaysDrawer handleSubmit={handleSubmit} />
      <ShiftHistoryDrawer />
    </EzyDrawer>
  );
}

export default AddEditScheduleDrawer;

AddEditScheduleDrawer.propTypes = {
  reFetchEvents: PropTypes.func.isRequired,
};
