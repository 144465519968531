import React, { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import WeekPicker from '../../../../components/common/dateAndTimePicker/WeekPicker';
import ROUTE_CONSTANTS, { DurationFilter } from '../../../../utils/constants';
import EzyMenu from '../../../../components/common/menu/EzyMenu';
import { updateMetricFilters } from '../../redux/insightSlice';
import { weekDatesValueBasedOnDuration } from '../../../../utils/timeHelper';
import EzyButton from '../../../../components/common/button/EzyButton';
import EzyTab from '../../../../components/common/tab/EzyTab';
import EditMetricTable from './EditMetricTable';
import AdjustPopup from './AdjustPopup';
import EzyDatePicker from '../../../../components/common/dateAndTimePicker/EzyDatePicker';

function EditMetric() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { filters, metric } = useSelector(({ insight }) => insight ?? {});
  const { selectedLocation, selectedModule } = useMemo(() => filters, [filters]);
  const { weekDatesValue, duration, areaId } = useMemo(() => metric?.filters, [metric?.filters]);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const tableRef = useRef();

  const {
    updateMetricDataActionLoader,
    getPayrollForecastStatisticsActionLoader,
    getMetricStatisticsActionLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});

  const editMetricComponentList = useMemo(
    () => [
      {
        label: 'PayRoll Forecast',
        component: <EditMetricTable metricFor="payrollForecast" ref={tableRef} />,
        access: selectedModule?.isPayrollForecastEnable ? 'default' : '',
      },
      {
        label: 'Manager Forecast',
        component: <EditMetricTable metricFor="managerForecast" ref={tableRef} />,
        access: selectedModule?.isManagerForecastEnable ? 'default' : '',
      },
      {
        label: 'Actuals',
        component: <EditMetricTable metricFor="actuals" ref={tableRef} />,
        access: 'default',
      },
    ],
    []
  );

  const handleTabChange = (newTab, index) => {
    setCurrentTabIndex(index);
  };

  return (
    <div className="edit-metric-wrapper">
      <div className="edit-metric-header">
        {duration?.id === 5 ? (
          <EzyDatePicker
            value={moment.tz(weekDatesValue?.startDate, 'UTC')}
            timeZone="UTC"
            onChange={(e) => {
              const data = weekDatesValueBasedOnDuration({ startDate: e }, duration);
              dispatch(
                updateMetricFilters({
                  fieldName: 'weekDatesValue',
                  value: { startDate: data?.startDate, endDate: data?.endDate },
                })
              );
            }}
          />
        ) : (
          <WeekPicker
            variant="outlined"
            value={weekDatesValue}
            onChange={async (e) => {
              const data = weekDatesValueBasedOnDuration(e, duration);
              dispatch(
                updateMetricFilters({
                  fieldName: 'weekDatesValue',
                  value: { startDate: data?.startDate, endDate: data?.endDate },
                })
              );
            }}
            onNextClick={async () => {
              const dates = {
                startDate: moment
                  .tz(weekDatesValue?.startDate, 'UTC')
                  .add(duration.dayCount, 'days'),
                endDate: moment.tz(weekDatesValue?.endDate, 'UTC').add(duration.dayCount, 'days'),
              };

              if (duration?.label === 'Month') {
                dates.startDate = moment
                  .tz(weekDatesValue?.endDate, 'UTC')
                  .add(1, 'days')
                  .startOf('month');
                dates.endDate = moment
                  .tz(weekDatesValue?.endDate, 'UTC')
                  .add(1, 'days')
                  .endOf('month');
              }
              dispatch(
                updateMetricFilters({
                  fieldName: 'weekDatesValue',
                  value: { startDate: dates?.startDate, endDate: dates?.endDate },
                })
              );
            }}
            onPrevClick={async () => {
              const dates = {
                startDate: moment
                  .tz(weekDatesValue?.startDate, 'UTC')
                  .subtract(duration.dayCount, 'days'),
                endDate: moment
                  .tz(weekDatesValue?.endDate, 'UTC')
                  .subtract(duration.dayCount, 'days'),
              };

              if (duration?.label === 'Month') {
                dates.startDate = moment
                  .tz(weekDatesValue?.startDate, 'UTC')
                  .subtract(1, 'days')
                  .startOf('month');
                dates.endDate = moment
                  .tz(weekDatesValue?.startDate, 'UTC')
                  .subtract(1, 'days')
                  .endOf('month');
              }
              dispatch(
                updateMetricFilters({
                  fieldName: 'weekDatesValue',
                  value: { startDate: dates?.startDate, endDate: dates?.endDate },
                })
              );
            }}
            timeZone="UTC"
            startWeekFrom={
              selectedLocation?.weekStartsOn === 7 ? 0 : selectedLocation?.weekStartsOn
            }
          />
        )}

        <EzyMenu
          className="insight-duration-menu"
          menuItems={DurationFilter}
          label={duration?.label || 'Duration'}
          onItemClick={(e, item) => {
            dispatch(updateMetricFilters({ fieldName: 'duration', value: item }));

            const data = weekDatesValueBasedOnDuration(weekDatesValue, item);

            dispatch(
              updateMetricFilters({
                fieldName: 'weekDatesValue',
                value: { startDate: data?.startDate, endDate: data?.endDate },
              })
            );
          }}
          selectedItem={duration}
        />

        {selectedModule?.showArea && (
          <EzyMenu
            className="insight-area-menu"
            menuItems={selectedLocation?.areaList}
            label={areaId?.label || 'Area'}
            onItemClick={(e, item) => {
              dispatch(updateMetricFilters({ fieldName: 'areaId', value: item }));
            }}
            selectedItem={areaId}
          />
        )}
        {currentTabIndex !== 0 && (
          <>
            <AdjustPopup handleAdjustClick={(e) => tableRef?.current?.handleAdjustClick(e)} />

            <EzyButton
              label="Save"
              onClick={() => tableRef?.current?.handleSubmit()}
              loading={updateMetricDataActionLoader}
            />
          </>
        )}

        <EzyButton
          color="secondary"
          label="Back"
          onClick={() => {
            history.push(
              `${ROUTE_CONSTANTS.SCHEDULE}/insights/${selectedModule?.label}?location=${
                selectedLocation?.id
              }&date=${moment.tz(weekDatesValue?.startDate, 'UTC')?.toISOString()}`
            );
          }}
        />
      </div>

      <div className="edit-metric-component-wrapper">
        <EzyTab
          list={editMetricComponentList}
          onTabChange={handleTabChange}
          loading={getPayrollForecastStatisticsActionLoader || getMetricStatisticsActionLoader}
        />
      </div>
    </div>
  );
}

EditMetric.propTypes = {};

export default EditMetric;
