import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import FileUpload from '../../../components/common/fileUpload/fileUpload';
import API_URL from '../../../utils/urlConstants';
import {
  importPeopleToList,
  resetImportPeopleData,
  setAddLocationList,
} from '../redux/locationSlice';
import {
  resetImportPeopleData as resetImportPeopleDataForPeople,
  updateAddPeopleFields,
} from '../../people/redux/peopleSlice';
import { errorNotification } from '../../../utils/notifyHelper';
import { importPeopleCSVFile } from '../../people/redux/peopleActions';
import EzyDialogTitle from '../../../components/common/dialog/EzyDialogTitle';
import EzyDialog from '../../../components/common/dialog/EzyDialog';
import EzyDialogAction from '../../../components/common/dialog/EzyDialogAction';
import EzyTable from '../../../components/common/table/EzyTable';
import { peopleImportHeaders } from '../../people/ImportPeopleCSVHeaders';
import { importPeopleCSVFileFromLocation } from '../redux/locationActions';
import Loader from '../../../components/common/loader/Loader';
import ROUTE_CONSTANTS from '../../../utils/constants';

function PeopleImport({ fromModule }) {
  const addLocationData = useSelector(({ location }) => location.addLocationData || {});
  const addPeopleData = useSelector(({ people }) => people.addPeople || {});
  const businessId = useSelector(({ user }) => user?.profileData?.businessId || '');

  const { importPeopleCSVFileLoader, importPeopleCSVFileFromLocationLoader } = useSelector(
    ({ common }) => common?.generalLoader ?? {}
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const data = useMemo(() => {
    switch (fromModule) {
      case 'location':
        return addLocationData;
      case 'people':
        return addPeopleData;
      default:
        return addLocationData;
    }
  }, [fromModule, addLocationData, addPeopleData]);

  const downloadPath = useMemo(() => {
    switch (fromModule) {
      case 'location':
      case 'people':
      default:
        return API_URL.PEOPLE.downloadPeopleCSVSample;
    }
  }, [fromModule]);

  const importCSV = useCallback(
    async (formData, config) => {
      switch (fromModule) {
        case 'people':
          await dispatch(importPeopleCSVFile(formData, config));
          break;
        case 'location':
        default:
          await dispatch(importPeopleCSVFileFromLocation(formData, config));
      }
    },
    [fromModule]
  );

  const {
    selectedUploader,
    uploaderIndex,
    fileName,
    totalNoOfRecords = 0,
    successCount = 0,
    failedCount = 0,
    failedRecords = [],
  } = useMemo(() => data, [data]);

  const handleChange = useCallback(async (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      const fileExtension = ['csv'];
      const mimeType = ['text/csv'];
      const checkExtension =
        fileExtension.indexOf(e.target.files[0].name.split('.').splice(-1)[0]) !== -1;
      const checkMimeTypes = mimeType.indexOf(e.target.files[0].type) !== -1;

      if (!(checkExtension || checkMimeTypes)) {
        errorNotification('Only CSV file types are allowed');
      } else {
        dispatch(setAddLocationList({ fieldName: 'fileName', value: e.target.files[0].name }));
        const formData = new FormData();
        formData.append('peopleCSV', e.target.files[0]);
        formData.append('businessId', businessId);
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        e.target.value = null;
        try {
          await importCSV(formData, config);
        } catch (error) {
          /**/
        }
      }
    }
  }, []);

  const handleClose = () => {
    switch (fromModule) {
      case 'location':
        dispatch(resetImportPeopleData());
        break;
      case 'people':
        dispatch(resetImportPeopleDataForPeople());
        if (successCount > 0) history.push(ROUTE_CONSTANTS.PEOPLE);
        break;
      default:
        dispatch(resetImportPeopleData());
    }
  };

  const handlePreviousClick = () => {
    switch (fromModule) {
      case 'location':
        dispatch(setAddLocationList({ fieldName: 'uploaderIndex', value: 0 }));
        break;
      case 'people':
        dispatch(updateAddPeopleFields({ fieldName: 'uploaderIndex', value: 0 }));
        break;
      default:
        dispatch(setAddLocationList({ fieldName: 'uploaderIndex', value: 0 }));
    }
  };

  const handleImportClick = () => {
    switch (fromModule) {
      case 'location':
        dispatch(importPeopleToList());
        handleClose();
        break;
      case 'people':
        handleClose();
        break;
      default:
        dispatch(importPeopleToList());
        handleClose();
    }
  };

  const actionButtons = useMemo(() => {
    if (fromModule === 'people') {
      return [
        {
          label: 'Previous',
          onClick: handlePreviousClick,
          color: 'secondary',
        },
        {
          label: 'Close',
          onClick: handleClose,
        },
      ];
    }
    return [
      {
        label: 'Previous',
        onClick: handlePreviousClick,
        color: 'secondary',
      },
      {
        label: 'Import',
        onClick: handleImportClick,
        disabled: successCount <= 0,
      },
    ];
  }, [handlePreviousClick, handleImportClick, fromModule, successCount]);

  const uploaderComponents = [
    <>
      <EzyDialogTitle title="Import People" onClose={handleClose} />
      <div
        className={`dialog-body ${
          importPeopleCSVFileLoader || importPeopleCSVFileFromLocationLoader
            ? 'position-relative'
            : ''
        }`}
      >
        {importPeopleCSVFileLoader || importPeopleCSVFileFromLocationLoader ? (
          <Loader />
        ) : (
          <FileUpload
            fileName={fileName}
            label="Import CSV file"
            handleChange={handleChange}
            title="Import People"
            downloadPath={downloadPath}
          />
        )}
      </div>
    </>,
    <>
      <EzyDialogTitle title="Import people" onClose={handleClose} />
      <div className="dialog-body">
        <div className="primary-bg d-flex flex-align-center just-bet">
          <div>Total No Of Records {totalNoOfRecords}</div>
          <div>
            <span className="primary-color">Valid : {successCount}</span>
            <span className="error-color p-x-24">Invalid : {failedCount}</span>
          </div>
        </div>

        {failedRecords?.length > 0 && (
          <div className="table-wrapper">
            <Paper className="table-paper">
              <EzyTable
                headers={peopleImportHeaders}
                rows={failedRecords}
                className="people-import-table"
              />
            </Paper>
          </div>
        )}
      </div>

      <EzyDialogAction actionButtons={actionButtons} />
    </>,
  ];

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);

  return (
    <EzyDialog
      handleClose={handleClose}
      open={selectedUploader === 'CSV' && uploaderIndex >= 0}
      className="common-dialog-with-body file-upload"
    >
      {uploaderComponents[uploaderIndex]}
    </EzyDialog>
  );
}

export default PeopleImport;

PeopleImport.propTypes = {
  fromModule: PropTypes.string.isRequired,
};
