import React from 'react';
import { PropTypes } from 'prop-types';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import EzyAvatar from '../../../../components/common/avatar/EzyAvatar';

function ViewInvoiceDetails({ data, handleClose }) {
  const { planName, planDuration, quantity, unitAmount, personList } = data?.usageRecord ?? {};

  const actionButton = [
    {
      label: 'Cancel',
      color: 'secondary',
      onClick: handleClose,
    },
  ];

  return (
    <EzyDrawer
      onClose={handleClose}
      isOpen={data}
      actionButtons={actionButton}
      heading="Invoice Details"
    >
      <div className="view-invoice-drawer">
        <div className="view-invoide-details-grid">
          <div className="view-invoice-note">
            <div className="view-invoice-field">
              <span>Plan : </span>
              <div className="name-date-field">
                <span>
                  {planName}&nbsp;(${planDuration})
                </span>
              </div>
            </div>
            <div className="view-invoice-field">
              <span>Quantity : </span>
              <div className="name-date-field">
                <span>{quantity}</span>
              </div>
            </div>
            <div className="view-invoice-field">
              <span>Unit amount : </span>
              <div className="name-date-field">
                <span>{unitAmount}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="title-with-primary-bg">Users</div>
        <div className="view-invoide-details-grid">
          <div className="person-list">
            <List>
              {personList?.map((e, index) => (
                <ListItem key={index}>
                  <ListItemButton disableRipple>
                    <ListItemIcon>
                      <EzyAvatar imageLink={e?.profilePhotoURl} firstName={e?.name} />
                    </ListItemIcon>

                    <ListItemText
                      primary={`${e?.name} ( ${e?.role} )`}
                      secondary={e?.mainLocation}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      </div>
    </EzyDrawer>
  );
}

export default ViewInvoiceDetails;

ViewInvoiceDetails.propTypes = {
  data: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};
