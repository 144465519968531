import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import whileLogo from '../../../assets/images/logo/PayrollEzy_WhiteLogo.png';
import EzyTooltip from '../tooltip/EzyTooltip';
import IconByName from '../iconByName/IconByName';
import authServices from '../../../pages/auth/authServices';
import { errorNotification, successNotification } from '../../../utils/notifyHelper';
import {
  clearAuthTokenFromLocalStorage,
  clearFCMTokenFromLocalStorage,
  getFCMTokenFromLocalStorage,
} from '../../../utils/localStorageHelper';
import ROUTE_CONSTANTS from '../../../utils/constants';
import EzyButton from '../button/EzyButton';

function BorderedHeader({ isLogout, isLogin }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const logout = async () => {
    const clientToken = getFCMTokenFromLocalStorage();
    try {
      const res = await authServices.logout({ clientToken });
      if (res.data.success) {
        successNotification(res.data.message);
        clearAuthTokenFromLocalStorage();
        clearFCMTokenFromLocalStorage();
        dispatch({ type: 'USER_LOGOUT' });
        history.push(ROUTE_CONSTANTS.LOGIN);
      }
    } catch (e) {
      errorNotification(e);
    }
  };
  return (
    <div className="b-header">
      <img className="b-header-logo" src={whileLogo} alt="payrollEzy" />
      {isLogout && (
        <EzyTooltip title="Logout">
          <IconByName name="logout" onClick={logout} />
        </EzyTooltip>
      )}
      {isLogin && (
        <EzyButton
          label="Login"
          onClick={() => history.push(ROUTE_CONSTANTS.LOGIN)}
          color="secondary"
        />
      )}
    </div>
  );
}

export default BorderedHeader;

BorderedHeader.propTypes = {
  isLogout: PropTypes.bool,
  isLogin: PropTypes.bool,
};

BorderedHeader.defaultProps = {
  isLogout: false,
  isLogin: false,
};
