import { isNil, omitBy } from 'lodash';
import { requireValidate } from '../../../utils/validationHelper';

const validateLeaveRule = (data) => {
  const { name, resetLeaveAccrual, leaveType, trackLeaveIn } = data ?? {};

  const error = {};
  error.name = requireValidate('Name', name);
  error.resetLeaveAccrual = requireValidate('Reset leave accrual', resetLeaveAccrual);
  error.leaveType = requireValidate('Leave Type', leaveType);
  error.trackLeaveIn = requireValidate('Track', trackLeaveIn);

  return omitBy(error, isNil);
};

export default validateLeaveRule;
