const BASE_URL = process.env.REACT_APP_BASE_URL;

const ezyAPIUrl = (url) => {
  return BASE_URL + url;
};
const API_URL = {
  // COMMON: {
  //   getEnumsList: ezyAPIUrl('CommonApi/GetAllEnumValues'),
  //   industryList: ezyAPIUrl('CommonApi/GetAllBusinessIndustryTypes'),
  //   countryList: ezyAPIUrl('CommonApi/GetAllCountries'),
  //   getAllTimeZones: ezyAPIUrl('CommonApi/GetAllTimeZones'),
  // },
  AUTH: {
    businessSignUp: ezyAPIUrl('Auth/BusinessSignUp'),
    userSignUp: ezyAPIUrl('Auth/UserSignUp'),
    login: ezyAPIUrl('Auth/login'),
    forgotPassword: ezyAPIUrl('Auth/ForgotPassword'),
    resetPassword: ezyAPIUrl('Auth/ResetPassword'),
    validateLink: ezyAPIUrl('Auth/ValidateLink'),
    getUserSignUpDetails: ezyAPIUrl('Auth/GetUserSignUpDetails'),
    verifyEmailFromLink: ezyAPIUrl('Auth/VerifyEmailFromLink'),
  },
  GLOBALPROFILE: {
    getGlobalProfileDetails: ezyAPIUrl('GlobalProfile/GetGlobalProfile'),
    editGlobalProfileDetails: ezyAPIUrl('GlobalProfile/EditGlobalProfile'),
    uploadGlobalProfilePicture: ezyAPIUrl('GlobalProfile/EditGlobalProfilePhoto'),
    deleteGlobalProfilePicture: ezyAPIUrl('GlobalProfile/DeleteGlobalProfilePhoto'),
    deleteAccount: ezyAPIUrl('GlobalProfile/DeleteAccount'),
    getAllIntegrationsList: ezyAPIUrl('Xero/Integrations'),
    getIntegrationDetails: ezyAPIUrl('Xero/SpecificIntegration'),
    getLocationList: ezyAPIUrl('Xero/AllLocations'),
    getLocationSpecificConfiguration: ezyAPIUrl('Xero/ApiList'),
    getConfigurationInfo: ezyAPIUrl('Xero/SpecificApi'),
    activateConfiguration: ezyAPIUrl('Xero/ActivateIntegration'),
    disconnectConfiguration: ezyAPIUrl('Xero/DisconnectApi'),
    disconnectLocationConfiguration: ezyAPIUrl('Xero/Integration'),
    connectIntegration: ezyAPIUrl('Xero/ConnectIntegration'),
    editConfigurationSettings: ezyAPIUrl('Xero/ApiSetting'),
    connectIntegrationWithLocation: ezyAPIUrl('Xero/CreateIntegrationTokens'),
    importPeopleFromIntegration: ezyAPIUrl('Xero/EmployeesFromIntegration'),
    getMyObFileDetails: ezyAPIUrl('Xero/GetMyObFileDetails'),
    saveMyObFileDetails: ezyAPIUrl('Xero/SaveMyobFileDetails'),
    syncMyobLeaveBalance: ezyAPIUrl('Xero/SyncMyobLeaveBalance'),
    commonIntegrationAPI: ezyAPIUrl('Xero/CommonIntegrationApi'),
    autoSyncEmployees: ezyAPIUrl('Xero/AutoSync'),
    getQboInvoiceApiConfigs: ezyAPIUrl('Xero/GetQboInvoiceApiConfigs'),
    integrationLoginUrl: ezyAPIUrl('Xero/IntegrationLoginUrl'),
    peopleFromIntegration: ezyAPIUrl('Xero/PeopleFromIntegration'),
  },
  DASHBOARD: {
    getDashboardShifts: ezyAPIUrl('Dashboard/GetDashboardShiftsByPersonDetailIdAndBusinessId'),
    getDashboardDetailsByBusinessId: ezyAPIUrl('Dashboard/GetDashboardDetailsByBusinessId'),
    cancelShiftOfferInvitation: ezyAPIUrl('Dashboard/CancelShiftOfferInvitation'),
    getProfileDetailsByPersonID: ezyAPIUrl('Dashboard/GetPanelDetails'),
    startShiftByPersonID: ezyAPIUrl('Dashboard/StartShift'),
    startShiftBreak: ezyAPIUrl('Dashboard/StartBreak'),
    endShiftBreak: ezyAPIUrl('Dashboard/EndBreak'),
    getEndShiftDetails: ezyAPIUrl('Dashboard/GetEndShiftDetails'),
    endShift: ezyAPIUrl('Dashboard/EndShift'),
    endBreak: ezyAPIUrl('Dashboard/EndBreak'),
    acceptShiftOfferInvitation: ezyAPIUrl('Dashboard/AcceptShiftOfferInvitation'),
  },
  BUSINESS: {
    registerBusiness: ezyAPIUrl('Business/AddBusiness'),
    fetchBusinessLeaveRules: ezyAPIUrl('Business/GetBusinessLeaveRules'),
    getBusinessGeneralDetails: ezyAPIUrl('Business/GetBusinessSetting'),
    getBusinessLeaveSetting: ezyAPIUrl('Business/GetBusinessLeaveSetting'),
    saveBusinessGeneralDetails: ezyAPIUrl('Business/EditBusinessSetting'),
    saveEditedLeaveSetting: ezyAPIUrl('Business/EditLeaveSetting'),
    inviteToBusiness: ezyAPIUrl('People/JoinExistingTeam'),
    addEditLeaveRules: ezyAPIUrl('Business/AddBusinessLeaveRules'),
    getLeaveRuleDetails: ezyAPIUrl('Business/GetBusinessLeaveRuleByLeaveRuleId'),
    personWhoCanApproveLeaveEnum: ezyAPIUrl('Business/GetAllPersonWhoCanApproveLeave'),
    deleteBusinessLeaveRule: ezyAPIUrl('Business/DeleteBusinessLeaveRule'),
    getBusinessList: ezyAPIUrl('Business/GetBusinessList'),
    switchBusiness: ezyAPIUrl('Business/SwitchBusiness'),
    businessInvitationAction: ezyAPIUrl('Business/BusinessInvitationAction'),
    updateBusinessName: ezyAPIUrl('Business/EditBusiness'),
    deleteBusiness: ezyAPIUrl('Business/DeleteBusiness'),
    BILL: {
      getAllInvoices: ezyAPIUrl('Bill/GetAllInvoices'),
      getAllReceipts: ezyAPIUrl('Bill/GetReceiptByBusinessId'),
      getInvoiceDetails: ezyAPIUrl('Bill/GetInvoiceDetails'),
      getPlans: ezyAPIUrl('Bill/GetPlanDetails'),
      getBillingAndAccountDetails: ezyAPIUrl('Bill/GetBillingAndAccountDetails'),
      getBillingAndPaymentDetails: ezyAPIUrl('Bill/GetBillingAndPaymentDetails'),
      getPaymentMethods: ezyAPIUrl('Bill/GetPaymentMethods'),
      createStripeCustomer: ezyAPIUrl('Bill/CreateStripeCustomer'),
      createCheckOutSession: ezyAPIUrl('Bill/CreateCheckOutSession'),
      cancelSubscription: ezyAPIUrl('Bill/CancelSubscription'),
      getCheckoutSessionDetails: ezyAPIUrl('Bill/GetSessionDetails'),
      getOrderSummary: ezyAPIUrl('Bill/GetOrderSummary'),
    },
  },
  NEWSFEED: {
    newsFeed: ezyAPIUrl('NewsFeed/NewsFeed'),
    commentOnPost: ezyAPIUrl('NewsFeed/Comment'),
    confirmNewsFeedPost: ezyAPIUrl('NewsFeed/Confirmation'),
    uploadMedia: ezyAPIUrl('NewsFeed/NewsFeedMedia'),
    deleteNewsFeedFile: ezyAPIUrl('NewsFeed/DeleteNewsFeedFile'),
    newsFeedReceiverList: ezyAPIUrl('NewsFeed/ReceiverList'),
  },
  TASKS: {
    tasks: ezyAPIUrl('Tasks/GetTasks'),
    tasksByArea: ezyAPIUrl('Tasks/GetTasksByArea'),
    tasksForPerson: ezyAPIUrl('Tasks/Tasks'),
    taskForWholeArea: ezyAPIUrl('Tasks/AddTaskForWholeArea'),
    taskDetailsById: ezyAPIUrl('Tasks/Task/Id'),
    editTask: ezyAPIUrl('Tasks/Tasks'),
    changeSubTaskStatus: ezyAPIUrl('Tasks/ChangeSubTaskStatus'),
    changeTaskStatus: ezyAPIUrl('Tasks/ChangeTaskStatus'),
    deleteTask: ezyAPIUrl('Tasks/Tasks'),
    taskForWholeAreaById: ezyAPIUrl('Tasks/GetTaskForWholeAreaById'),
    editTaskForWholeArea: ezyAPIUrl('Tasks/EditTaskForWholeArea'),
  },
  LOCATION: {
    addLocation: ezyAPIUrl('Location/AddLocation'),
    copyLocation: ezyAPIUrl('Location/CopyLocationSetting'),
    duplicateLocation: ezyAPIUrl('Location/DuplicateLocation'),
    locationList: ezyAPIUrl('Location/GetAllLocationByBusinessId'),
    editGeneralLocation: ezyAPIUrl('Location/EditGeneralLocationSetting'),
    editDetailsLocation: ezyAPIUrl('Location/EditLocationDetailsSetting'),
    getGeneralLocationSetting: ezyAPIUrl('Location/GetGeneralLocationSetting'),
    getLocationDetailsSetting: ezyAPIUrl('Location/GetLocationDetailsSetting'),
    getDetailsBeforeArchive: ezyAPIUrl('Location/GetAllDetailsBeforeArchive'),
    archiveLocation: ezyAPIUrl('Location/ArchiveLocation'),
    deleteLocation: ezyAPIUrl('Location/DeleteLocation'),
    locationsByBusinessId: ezyAPIUrl('People/GetLocationListByBusinessId'),
  },
  PEOPLE: {
    peopleList: ezyAPIUrl('People/GetAllPeopleByFilter'),
    getPeopleFilterByBusinessIdAction: ezyAPIUrl('People/GetPeopleFilterByBusinessId'),
    peopleDetails: ezyAPIUrl('People/GetPeopleDetails'),
    getPeoplePayDetails: ezyAPIUrl('People/GetPeoplePayDetails'),
    getAreaListByPerson: ezyAPIUrl('People/GetAreaListByPerson'),
    downloadPeopleCSVSample: ezyAPIUrl('People/DownLoadAddPeopleTemplate'),
    importPeopleCSVSample: ezyAPIUrl('People/ImportCSV'),
    addPeople: ezyAPIUrl('People/AddPeople'),
    saveWorkPeriod: ezyAPIUrl('People/AddWorkPeriodDetails'),
    editPeopleDetails: ezyAPIUrl('People/EditPeopleDetails'),
    editPeoplePayDetails: ezyAPIUrl('People/EditPeoplePayDetails'),
    getWorkPeriodListByBusinessId: ezyAPIUrl('People/GetWorkPeriodListByBusinessId'),
    addBulkLocation: ezyAPIUrl('People/BulkAddLocation'),
    addBulkAccess: ezyAPIUrl('People/BulkAddAccess'),
    bulkSendInvitation: ezyAPIUrl('People/BulkSendInvitation'),
    leaveBalanceList: ezyAPIUrl('People/GetLeaveBalanceByPersonDetailId'),
    leaveRequestList: ezyAPIUrl('People/GetLeaveRequestByPersonDetailId'),
    getUpcomingShifts: ezyAPIUrl('People/GetUpcomingShifts'),
    getPreviousShifts: ezyAPIUrl('People/GetPreviousShifts'),
    leaveEntitlementEnumByPersonId: ezyAPIUrl('People/GetLeaveEntitlementByPersonId'),
    leaveEntitlementEnumByBusinessId: ezyAPIUrl('People/GetLeaveEntitlementByBusinessId'),
    getPeopleForOfferShift: ezyAPIUrl('Dashboard/GetPeopleForOfferShift'),
    sendShiftOfferInvitation: ezyAPIUrl('Dashboard/SendShiftOfferInvitation'),
    addEditLeaveBalance: ezyAPIUrl('People/AddEditLeaveBalance'),
    addLeaveRequest: ezyAPIUrl('People/AddLeaveRequest'),
    deletePeopleById: ezyAPIUrl('People/DeleteTeamMember'),
    deletePeopleLeave: ezyAPIUrl('People/DeleteLeaveEntitlement'),
    getExtraLeaveDetails: ezyAPIUrl('People/GetLeaveRequestExtraDetails'),
    leaveRequestByLeaveId: ezyAPIUrl('People/GetLeaveRequestByLeaveRequestId'),
    editLeaveRequest: ezyAPIUrl('People/EditLeaveRequest'),
    leaveRequestActions: ezyAPIUrl('People/LeaveRequestActions'),
    downloadSelectedPeopleCsv: ezyAPIUrl('People/DownloadSelectedPeopleCsv'),
    forgotPassword: ezyAPIUrl('Auth/ForgotPassword'),
    getCurrentUserData: ezyAPIUrl('People/GetCurrentUserData'),
    archivePeople: ezyAPIUrl('People/ArchiveTeamMember'),
    resetLoginDetails: ezyAPIUrl('People/ResetLoginDetails'),
    getNotInvitedPeopleList: ezyAPIUrl('People/GetNotInvitedPeopleList'),
    logout: ezyAPIUrl('People/Logout'),
    uploadProfilePicture: ezyAPIUrl('People/UploadProfilePicture'),
    deleteProfilePicture: ezyAPIUrl('People/DeleteProfilePicture'),
    sendVerificationMail: ezyAPIUrl('People/SendVerificationMail'),
    notification: ezyAPIUrl('People/Notifications'),
    deleteNotifications: ezyAPIUrl('People/deleteNotifications'),
    saveClientToken: ezyAPIUrl('People/SaveClientToken'),
    getAllFairWorkAwards: ezyAPIUrl('People/getAllFWCAwards'),
    getJournalListByPersonDetailId: ezyAPIUrl('People/Journal/personDetailId'),
    addJournal: ezyAPIUrl('People/Journal'),
    addUnavailability: ezyAPIUrl('People/Unavailability'),
    unavailabilityList: ezyAPIUrl('People/UnavailabilityList'),
    deleteUnavailabilityById: ezyAPIUrl('People/Unavailability'),
    syncWithXero: ezyAPIUrl('Xero/PayRates'),
    getAllWageAllowances: ezyAPIUrl('People/GetAllWageAllowances'),
    showTimeSheeting: ezyAPIUrl('People/ShowTimesheetSetting'),
  },
  TIME_SHEETS: {
    getAllTimesheetByPerson: ezyAPIUrl('TimeSheet/GetAllTimesheetByPerson'),
    getLocationsDropdown: ezyAPIUrl('TimeSheet/GetLocationListDropdownByBusinessId'),
    getPersonListByBusinessId: ezyAPIUrl('TimeSheet/GetPersonListByBusinessId'),
    getPersonList: ezyAPIUrl('TimeSheet/PersonList'),
    getTimeSheetById: ezyAPIUrl('TimeSheet/GetTimeSheetById'),
    getTimeSheetHistory: ezyAPIUrl('TimeSheet/GetTimeSheetHistory'),
    addTimeSheetDetails: ezyAPIUrl('TimeSheet/AddTimeSheetDetails'),
    editTimeSheetDetails: ezyAPIUrl('TimeSheet/EditTimeSheet'),
    changeTimeSheetStatus: ezyAPIUrl('TimeSheet/ChangeTimeSheetStatus'),
    getExportTimeSheetDetails: ezyAPIUrl('TimeSheet/GetExportTimeSheetDetails'),
    exportTimeSheetSummary: ezyAPIUrl('TimeSheet/ExportTimeSheetSummary'),
    exportTimesheetDetailsByPerson: ezyAPIUrl('TimeSheet/ExportTimesheetDetailsByPerson'),
    markTimesheetAsPaidOrUnpaid: ezyAPIUrl('TimeSheet/MarkTimeSheetAsPaid'),
    getExportTypeDropdownList: ezyAPIUrl('TimeSheet/GetExportTypeList'),
    exportTimesheets: ezyAPIUrl('TimeSheet/ExportTimesheets'),
    roundTimesheetTime: ezyAPIUrl('TimeSheet/RoundTimesheetTime'),
    getJournalDetailsByDate: ezyAPIUrl('TimeSheet/GetJournalByDate'),
    exportTimesheetDetailsByPayRates: ezyAPIUrl('TimeSheet/ExportTimesheetDetailsByPayRates'),
    getLocationAreaDropdown: ezyAPIUrl('TimeSheet/LocationAreaDropdown'),
    timesheetAllowances: ezyAPIUrl('TimeSheet/TimesheetAllowances'),
  },
  SCHEDULE: {
    getAreaListByLocationId: ezyAPIUrl('Schedule/GetAreaListByLocationId'),
    getShiftDropdownEntities: ezyAPIUrl('Schedule/GetShiftDropdownEntities'),
    getShiftByShiftId: ezyAPIUrl('Schedule/GetShiftByShiftId'),
    getAllScheduleShift: ezyAPIUrl('Schedule/GetAllScheduleShift'),
    getAllTemplatesByLocationId: ezyAPIUrl('Schedule/GetAllTemplatesByLocationId'),
    getShiftPanelDetails: ezyAPIUrl('Schedule/GetShiftPanelDetails'),
    addShift: ezyAPIUrl('Schedule/AddShift'),
    repeatShifts: ezyAPIUrl('Schedule/RepeatShifts'),
    editShift: ezyAPIUrl('Schedule/EditShift'),
    publishShift: ezyAPIUrl('Schedule/PublishShift'),
    shiftBulkActions: ezyAPIUrl('Schedule/ShiftBulkActions'),
    saveTemplate: ezyAPIUrl('Schedule/SaveTemplate'),
    loadTemplate: ezyAPIUrl('Schedule/LoadTemplate'),
    swapRequestAdmin: ezyAPIUrl('Schedule/SwapRequestAdminAction'),
    confirmRequestForOpenShift: ezyAPIUrl('Schedule/ConfirmRequestForOpenShift'),
    dragAndDropShift: ezyAPIUrl('Schedule/DragAndDropShift'),
    getLocationSettingsForShift: ezyAPIUrl('Schedule/GetLocationSettingsForShift'),
    deleteShift: ezyAPIUrl('Schedule/DeleteShift'),
    deleteTemplate: ezyAPIUrl('Schedule/DeleteTemplate'),
    getSwapShiftDetails: ezyAPIUrl('Schedule/GetSwapShiftDetails'),
    getOpenShiftDetails: ezyAPIUrl('Schedule/GetRequestedOpenShiftByShiftId'),
    cancelRequestedOpenShift: ezyAPIUrl('Schedule/CancelRequestedOpenShift'),
    getPossibleSwapShift: ezyAPIUrl('Schedule/GetPossibleSwapShifts'),
    sendSwapRequest: ezyAPIUrl('Schedule/SendSwapRequest'),
    addRequestForOpenShift: ezyAPIUrl('Schedule/AddRequestForOpenShift'),
    getAllSwapRequests: ezyAPIUrl('Schedule/GetSwapShiftRequest'),
    swapRequestUserAction: ezyAPIUrl('Schedule/SwapRequestUserAction'),
    withdrawRequestForOpenShift: ezyAPIUrl('Schedule/WithdrawRequestForOpenShift'),
    getShiftHistoryByShiftId: ezyAPIUrl('Schedule/GetShiftHistoryByShiftId'),
    downloadShecduleCSV: ezyAPIUrl('Schedule/DownloadScheduleCSV'),
    downloadScheduleExcelByArea: ezyAPIUrl('Schedule/DownloadExcelByArea'),
    downloadScheduleExcelByTeamMember: ezyAPIUrl('Schedule/DownloadExcelByTeamMember'),
    updateInsightPanelDisplaySettings: ezyAPIUrl('Schedule/InsightPanelDisplaySettings'),
    holidaySettings: ezyAPIUrl('Schedule/HolidaySettings'),
  },
  LOGBOOK: {
    getLogbookEntries: ezyAPIUrl('LogBook/GetLogBookEntries'),
    addLogBookCategory: ezyAPIUrl('LogBook/AddLogBookCategory'),
    addLog: ezyAPIUrl('LogBook/AddLog'),
    uploadAttachment: ezyAPIUrl('LogBook/UploadLogBookFile'),
    deleteUploadedAttachment: ezyAPIUrl('LogBook/DeleteLogBookFile'),
    deleteLog: ezyAPIUrl('LogBook/DeleteLog'),
    deleteLogBookCategory: ezyAPIUrl('LogBook/DeleteLogBookCategory'),
    addLogComment: ezyAPIUrl('LogBook/AddComment'),
    deleteLogComment: ezyAPIUrl('LogBook/DeleteComment'),
    getCategoryDetailsById: ezyAPIUrl('LogBook/GetLogBookCategoryById'),
    editLogBookCategory: ezyAPIUrl('LogBook/EditLogBookCategory'),
    getLogsEntryByFilters: ezyAPIUrl('LogBook/GetLogBookEntryByFilter'),
    saveAutoSendEmailTime: ezyAPIUrl('LogBook/SaveAutoSendEmailTime'),
    sendLogBookSummary: ezyAPIUrl('LogBook/SendLogBookSummary'),
    getLogBookCategoriesByBusinessId: ezyAPIUrl('LogBook/GetLogBookCategoriesByBusinessId'),
  },
  INSIGHT: {
    getInsightList: ezyAPIUrl('Insight/InsightList'),
    budget: ezyAPIUrl('Insight/Budget'),
    budgetSetting: ezyAPIUrl('Insight/BudgetSetting'),
    budgetStatistics: ezyAPIUrl('Insight/BudgetStatistics'),
    metric: ezyAPIUrl('Insight/Metric'),
    metricSetting: ezyAPIUrl('Insight/MetricSetting'),
    exportMetricData: ezyAPIUrl('Insight/ExportMetricData'),
    getMetricInformation: ezyAPIUrl('Insight/MetricInformation'),
    updateMetricDataFromViewScreen: ezyAPIUrl('Insight/MetricDataFromViewScreen'),
    getPayrollForecastStatistics: ezyAPIUrl('Insight/PayrollForecastStatistics'),
    getMetricStatistics: ezyAPIUrl('Insight/MetricStatistics'),
    updateMetricData: ezyAPIUrl('Insight/MetricData'),
    getMetricListForRule: ezyAPIUrl('Insight/MetricListForRule'),
    rule: ezyAPIUrl('Insight/Rule'),
    rules: ezyAPIUrl('Insight/Rules'),
    deleteMultipleRules: ezyAPIUrl('Insight/BulkDeleteAction'),
    getRuleDataById: ezyAPIUrl('Insight/Rule/Id'),
    runLaborModel: ezyAPIUrl('Insight/RunLaborModel'),
    staffSettings: ezyAPIUrl('Insight/RequiredStaffSetting'),
    requiredStaff: ezyAPIUrl('Insight/RequiredStaff'),
    staffStatisticDataForEdit: ezyAPIUrl('Insight/RequiredStaffStatisticsForEdit'),
    updateStaffStatisticData: ezyAPIUrl('Insight/RequiredStaffStatistics'),
    locationListForCopyAction: ezyAPIUrl('Insight/LocationListForCopyAction'),
    copyLocationBulkAction: ezyAPIUrl('Insight/BulkCopyAction'),
    getSummaryData: ezyAPIUrl('Insight/Summary'),
    summarySettings: ezyAPIUrl('Insight/SummarySettings'),
    getCoverageAreaData: ezyAPIUrl('Insight/CoverageAreaData'),
    updateRequiredStaffData: ezyAPIUrl('Insight/RequiredStaffData'),
    graphData: ezyAPIUrl('Insight/GraphData'),
    getStatsPanelData: ezyAPIUrl('Insight/StatsPanelData'),
    graphSetting: ezyAPIUrl('Insight/GraphSetting'),
  },
};

export default API_URL;
