import { isNil, omitBy } from 'lodash';
import { requireValidate } from '../../../utils/validationHelper';

export const validateAddWorkPeriod = (data) => {
  const { startDate, day, workPeriodLength } = data ?? {};
  const errors = {};
  errors.workPeriodLength = requireValidate('Work Period Length', workPeriodLength);
  if (workPeriodLength === 1) {
    errors.day = requireValidate('Day', day);
  }
  if ([2, 3]?.includes(workPeriodLength)) {
    errors.startDate = requireValidate('Start Date', startDate);
  }

  return omitBy(errors, isNil);
};
