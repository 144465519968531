import apiService from '../../utils/apiService';
import API_URL from '../../utils/urlConstants';

export const insightServices = {
  getInsightList: (params) => apiService.getData(API_URL.INSIGHT.getInsightList, { params }),
  addBudget: (data) => apiService.postData(API_URL.INSIGHT.budget, data),
  deleteBudget: (params) => apiService.deleteData(API_URL.INSIGHT.budget, { params }),
  getBudgetSettings: (params) => apiService.getData(API_URL.INSIGHT.budgetSetting, { params }),
  updateBudgetSetting: (data) => apiService.patchData(API_URL.INSIGHT.budgetSetting, data),
  getBudgetStatistic: (params) => apiService.getData(API_URL.INSIGHT.budgetStatistics, { params }),
  addMetric: (data) => apiService.postData(API_URL.INSIGHT.metric, data),
  getMetricSettings: (params) => apiService.getData(API_URL.INSIGHT.metricSetting, { params }),
  updateMetricSetting: (data) => apiService.putData(API_URL.INSIGHT.metricSetting, data),
  deleteMetric: (params) => apiService.deleteData(API_URL.INSIGHT.metric, { params }),
  exportMetricData: (params) => apiService.getData(API_URL.INSIGHT.exportMetricData, { params }),
  getMetricInformation: (params) =>
    apiService.getData(API_URL.INSIGHT.getMetricInformation, { params }),
  updateMetricDataFromViewScreen: (data) =>
    apiService.postData(API_URL.INSIGHT.updateMetricDataFromViewScreen, data),
  getPayrollForecastStatistics: (params) =>
    apiService.getData(API_URL.INSIGHT.getPayrollForecastStatistics, { params }),
  getMetricStatistics: (params) =>
    apiService.getData(API_URL.INSIGHT.getMetricStatistics, { params }),
  updateMetricData: (data) => apiService.postData(API_URL.INSIGHT.updateMetricData, data),
  getMetricListForRule: (params) =>
    apiService.getData(API_URL.INSIGHT.getMetricListForRule, { params }),
  addRule: (data) => apiService.postData(API_URL.INSIGHT.rule, data),
  getRuleList: (params) => apiService.getData(API_URL.INSIGHT.rules, { params }),
  deleteMultipleRules: (params) =>
    apiService.deleteData(API_URL.INSIGHT.deleteMultipleRules, { params }),
  getRuleDataById: (params) => apiService.getData(API_URL.INSIGHT.getRuleDataById, { params }),
  updateRuleData: (data) => apiService.putData(API_URL.INSIGHT.rule, data),
  deleteRule: (params) => apiService.deleteData(API_URL.INSIGHT.rule, { params }),
  runLaborModel: (params) => apiService.getData(API_URL.INSIGHT.runLaborModel, { params }),
  getStaffSettings: (params) => apiService.getData(API_URL.INSIGHT.staffSettings, { params }),
  updateStaffSettings: (data) => apiService.putData(API_URL.INSIGHT.staffSettings, data),
  getStaffList: (data) => apiService.postData(API_URL.INSIGHT.requiredStaff, data),
  getStaffStatisticDataForEdit: (params) =>
    apiService.getData(API_URL.INSIGHT.staffStatisticDataForEdit, { params }),
  updateStaffStatisticData: (data) =>
    apiService.postData(API_URL.INSIGHT.updateStaffStatisticData, data),
  getLocationListForCopyAction: (data) =>
    apiService.postData(API_URL.INSIGHT.locationListForCopyAction, data),
  copyLocationBulkAction: (data) =>
    apiService.postData(API_URL.INSIGHT.copyLocationBulkAction, data),
  getSummaryData: (data) => apiService.postData(API_URL.INSIGHT.getSummaryData, data),
  getSummarySettings: (params) => apiService.getData(API_URL.INSIGHT.summarySettings, { params }),
  updateSummarySettings: (data, config) =>
    apiService.postData(API_URL.INSIGHT.summarySettings, data, {
      ...config,
      timeout: 3 * 60 * 1000,
    }),
  updateBudgetData: (data) => apiService.patchData(API_URL.INSIGHT.budgetStatistics, data),
};
