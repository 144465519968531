import PropTypes from 'prop-types';
import Loader from '../loader/Loader';

function EzyAccordionWrapper({ children, className, ...restOther }) {
  return (
    <div className={`ezy-accordion-wrapper ${className}`} {...restOther}>
      {restOther?.loading ? <Loader /> : children}
    </div>
  );
}

export default EzyAccordionWrapper;

EzyAccordionWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
};
EzyAccordionWrapper.defaultProps = {
  className: '',
};
