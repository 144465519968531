import {
  startGeneralLoader,
  stopGeneralLoader,
} from '../../../utils/backOfficeLists/commonDataSlice';
import { mapEnumToIds } from '../../../utils/enumMapping';
import { displayErrors } from '../../../utils/errorNotifyHelper';
import {
  clearAuthTokenFromLocalStorage,
  clearFCMTokenFromLocalStorage,
  getFCMTokenFromLocalStorage,
  saveAuthTokenToLocalStorage,
  saveFCMTokenToLocalStorage,
} from '../../../utils/localStorageHelper';
import { errorNotification, successNotification } from '../../../utils/notifyHelper';
import authServices from '../../auth/authServices';
import globalProfileServices from '../globalProfileServices';
import {
  resetNotifications,
  setBillingAndPaymentDetails,
  setBillingDetails,
  setGlobalProfileDetails,
  setInvoiceData,
  setNotifications,
  setPaymentMethods,
  setPlanDetails,
  setReceiptData,
  updateAccountOverviewCard,
  updateAppIntegrationData,
  updateBusinessData,
  updateConfigurationDrawerDetails,
  updateGeneralSettingsFields,
  updateGlobalProfileDetails,
  updateSummaryDetails,
} from './globalProfileSlice';
import businessServices from '../../business/businessServices';
import ROUTE_CONSTANTS from '../../../utils/constants';

export const getBusinessListAction = () => {
  // eslint-disable-next-line consistent-return
  return async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getBusinessListActionLoader' }));

      const res = await globalProfileServices.getBusinessList();
      if (res?.data?.success) {
        const businessList = res?.data?.data?.filter(
          (business) => !business?.isInvitationActionRequired
        );
        dispatch(updateBusinessData({ fieldName: 'businessList', value: businessList }));
        dispatch(
          updateBusinessData({
            fieldName: 'newBusinessId',
            value: state?.user?.profileData?.businessId,
          })
        );
      }
    } catch (e) {
      errorNotification(e?.res?.data?.message);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getBusinessListActionLoader' }));
    }
  };
};

export const switchBusinessAction = (data) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'switchBusinessActionLoader' }));
      const clientToken = getFCMTokenFromLocalStorage();
      const res = await globalProfileServices.switchBusiness({ ...data, clientToken });
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        saveAuthTokenToLocalStorage(res?.data?.data?.token);
        clearFCMTokenFromLocalStorage();
        return true;
      }
      return false;
    } catch (e) {
      displayErrors(e);
      return false;
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'switchBusinessActionLoader' }));
    }
  };
};

export const getGlobalProfileDetailsAction = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getGlobalProfileDetailsActionLoader' }));
      const state = getState();
      const response = await globalProfileServices.getGlobalProfileDetails();
      if (response?.data?.success) {
        const data = mapEnumToIds(
          [
            { fieldName: 'gender', listName: 'gender', isFromEnum: true },
            { fieldName: 'pronoun', listName: 'pronoun', isFromEnum: true },
            { fieldName: 'country', listName: 'country', isFromEnum: true },
            { fieldName: 'timeZoneId', listName: 'timeZonesList', isFromEnum: false },
          ],
          response?.data?.data,
          state?.common
        );
        dispatch(setGlobalProfileDetails(data));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getGlobalProfileDetailsActionLoader' }));
    }
  };
};

export const editGlobalProfileDetailsAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'editGlobalProfileDetailsActionLoader' }));
      const response = await globalProfileServices.editGlobalProfileDetails(data);
      if (response?.data?.success) {
        successNotification(response?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'editGlobalProfileDetailsActionLoader' }));
    }
  };
};

export const uploadGlobalProfilePictureAction = (data, config) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'uploadGlobalProfilePictureActionLoader' }));
      const response = await globalProfileServices.uploadGlobalProfilePicture(data, config);
      if (response?.data?.success) {
        dispatch(
          updateGlobalProfileDetails({ fieldName: 'profilePhotoURL', value: response.data.data })
        );
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'uploadGlobalProfilePictureActionLoader' }));
    }
  };
};

export const deleteGlobalProfilePictureAction = () => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'deleteGlobalProfilePictureActionLoader' }));
      const response = await globalProfileServices.deleteGlobalProfilePicture();
      if (response?.data?.success) {
        dispatch(updateGlobalProfileDetails({ fieldName: 'profilePhotoURL', value: null }));
        successNotification(response?.data?.message);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deleteGlobalProfilePictureActionLoader' }));
    }
  };
};

export const deleteNotificationsAction = () => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'deleteNotificationsActionLoader' }));
      const response = await globalProfileServices.deleteNotifications();
      if (response?.data?.success) {
        successNotification(response?.data?.message);
        dispatch(resetNotifications());
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deleteNotificationsActionLoader' }));
    }
  };
};

export const resetPasswordAction = (data) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'resetPasswordActionLoader' }));
      const response = await authServices.forgotPassword(data);
      if (response?.data?.success) {
        return true;
      }
      return false;
    } catch (e) {
      displayErrors(e);
      return false;
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'resetPasswordActionLoader' }));
    }
  };
};

export const checkPasswordAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'checkPasswordActionLoader' }));
      const response = await authServices.login(data);
      if (response?.data?.success) {
        cb();
      }
    } catch (e) {
      errorNotification('Password is wrong!');
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'checkPasswordActionLoader' }));
    }
  };
};

export const deleteAccountAction = (history) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'deleteAccountActionLoader' }));
      const response = await globalProfileServices.deleteAccount();
      if (response?.data?.success) {
        successNotification(response?.data?.message);
        clearAuthTokenFromLocalStorage();
        dispatch({ type: 'USER_LOGOUT' });
        history.push(ROUTE_CONSTANTS.LOGIN);
      }
      return null;
    } catch (e) {
      return e?.response?.data;
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deleteAccountActionLoader' }));
    }
  };
};

export const getBusinessListForAccountOverview = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getBusinessListForAccountOverviewLoader' }));
      const state = getState();
      const res = await globalProfileServices.getBusinessList();
      if (res?.data?.success) {
        const mapData = mapEnumToIds(
          [{ fieldName: 'roleId', listName: 'roles', isFromEnum: true }],
          res?.data?.data,
          state?.common
        );
        dispatch(updateAccountOverviewCard(mapData));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getBusinessListForAccountOverviewLoader' }));
    }
  };
};

export const setBusinessInvitation = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'BusinessInvitationLoader' }));
      const res = await globalProfileServices.businessInvitationAction(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'BusinessInvitationLoader' }));
    }
  };
};

export const updateBusinessName = (data, cb) => {
  const { businessId } = data;
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'businessNameUpdateLoader' }));
      const clientToken = getFCMTokenFromLocalStorage();
      const tokenRes = await globalProfileServices.switchBusiness({ businessId, clientToken });
      if (tokenRes?.data?.success) {
        clearFCMTokenFromLocalStorage();
        saveAuthTokenToLocalStorage(tokenRes?.data?.data?.token);
        const res = await globalProfileServices.updateBusinessName(data);
        if (res?.data?.success) {
          successNotification(res?.data?.message);
          cb();
        }
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'businessNameUpdateLoader' }));
    }
  };
};
export const accountOverviewSwitchBusinessAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'accountOverviewSwitchBusinessActionLoader' }));
      const clientToken = getFCMTokenFromLocalStorage();
      const res = await globalProfileServices.switchBusiness({ ...data, clientToken });
      if (res?.data?.success) {
        successNotification('Business change successfully');
        clearFCMTokenFromLocalStorage();
        saveAuthTokenToLocalStorage(res?.data?.data?.token);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'accountOverviewSwitchBusinessActionLoader' }));
    }
  };
};

export const deleteBusinessByBusinessId = (params, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'deleteBusinessLoader' }));
      const clientToken = getFCMTokenFromLocalStorage();
      const tokenRes = await globalProfileServices.switchBusiness({ ...params, clientToken });
      if (tokenRes?.data?.success) {
        clearFCMTokenFromLocalStorage();
        saveAuthTokenToLocalStorage(tokenRes?.data?.data?.token);
        const res = await businessServices.deleteBusinessByBusinessId(params);
        if (res?.data?.success) {
          successNotification('Business deleted successfully');
          cb();
        }
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deleteBusinessLoader' }));
    }
  };
};

export const getNotificationsAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'notificationLoader' }));
      const res = await globalProfileServices.getNotifications(params);
      if (res?.data?.success) {
        dispatch(setNotifications(res?.data?.data));
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'notificationLoader' }));
    }
  };
};

export const getAllInvoicesAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getAllInvoicesActionLoader' }));
      const res = await globalProfileServices.getAllInvoices(params);
      if (res?.data?.success) {
        dispatch(setInvoiceData(res?.data));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getAllInvoicesActionLoader' }));
    }
  };
};

export const getAllReceiptAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getAllReceiptsActionLoader' }));
      const res = await globalProfileServices.getAllReceipts(params);
      if (res?.data?.success) {
        dispatch(setReceiptData(res?.data));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getAllReceiptsActionLoader' }));
    }
  };
};

export const getBusinessGeneralInfoAction = (businessId) => {
  return async (dispatch) => {
    try {
      const params = {
        businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getGeneralLocationSettingsActionLoader' }));
      const res = await businessServices.getBusinessGeneralDetails(params);
      if (res?.data?.success) {
        dispatch(
          updateGeneralSettingsFields({
            fieldName: 'businessId',
            value: res?.data?.data?.businessId,
          })
        );
        dispatch(
          updateGeneralSettingsFields({
            fieldName: 'businessName',
            value: res?.data?.data?.businessName,
          })
        );
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getGeneralLocationSettingsActionLoader' }));
    }
  };
};

export const getInvoicesDataAction = (params, cb) => {
  return async () => {
    try {
      const res = await globalProfileServices.getInvoiceDetails(params);
      if (res?.data?.success) {
        if (typeof cb === 'function' && cb) {
          cb(res?.data?.data);
        }
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getPlansAction = (params, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getPlansActionLoader' }));
      const res = await globalProfileServices.getPlans(params);
      if (res?.data?.success) {
        dispatch(setPlanDetails(res.data?.data));
        if (cb && typeof cb === 'function') {
          cb(res.data?.data);
        }
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getPlansActionLoader' }));
    }
  };
};

export const getBillingAndAccountDetailsAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getBillingAndAccountDetailsActionLoader' }));
      const res = await globalProfileServices.getBillingAndAccountDetails(params);
      if (res?.data?.success) {
        dispatch(setBillingDetails(res.data?.data));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getBillingAndAccountDetailsActionLoader' }));
    }
  };
};

export const getPaymentMethodsAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getPaymentMethodsActionLoader' }));
      const res = await globalProfileServices.getPaymentMethods(params);
      if (res?.data?.success) {
        dispatch(setPaymentMethods(res.data?.data));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getPaymentMethodsActionLoader' }));
    }
  };
};

export const getCheckoutSessionDetailsAction = (params, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getCheckoutSessionDetailsActionLoader' }));
      const res = await globalProfileServices.getCheckoutSessionDetails(params);
      if (res?.data?.success && cb && typeof cb === 'function') {
        cb(res?.data?.data);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getCheckoutSessionDetailsActionLoader' }));
    }
  };
};

export const getBillingAndPaymentDetailsAction = (params) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(startGeneralLoader({ loaderFor: 'getBillingAndPaymentDetailsActionLoader' }));
      const res = await globalProfileServices.getBillingAndPaymentDetails(params);
      if (res?.data?.success) {
        const data = mapEnumToIds(
          [{ fieldName: 'country', listName: 'country', isFromEnum: true }],
          res?.data?.data,
          state?.common
        );
        dispatch(setBillingAndPaymentDetails(data));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getBillingAndPaymentDetailsActionLoader' }));
    }
  };
};

export const getOrderSummaryAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getOrderSummaryActionLoader' }));
      const res = await globalProfileServices.getOrderSummary(params);
      if (res?.data?.success) {
        dispatch(updateSummaryDetails(res.data?.data));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getOrderSummaryActionLoader' }));
    }
  };
};

export const saveClientTokenAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'saveClientTokenLoader' }));
      const res = await globalProfileServices.saveClientToken(data);
      if (res?.data?.success) {
        saveFCMTokenToLocalStorage(data.clientToken);
        cb();
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'saveClientTokenLoader' }));
    }
  };
};

export const createStripeCustomerAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'createStripeCustomerActionLoader' }));
      const res = await globalProfileServices.createStripeCustomer(data);
      if (res?.data?.success) {
        cb(res?.data?.data);
      }
    } catch (e) {
      errorNotification('Failed to create checkout customer!');
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'createStripeCustomerActionLoader' }));
    }
  };
};

export const createCheckOutSessionAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'createCheckOutSessionActionLoader' }));
      const res = await globalProfileServices.createCheckOutSession(data);
      if (res?.data?.success) {
        cb(res?.data?.data);
      }
    } catch (e) {
      errorNotification('Failed to create checkout session!');
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'createCheckOutSessionActionLoader' }));
    }
  };
};

export const getAllIntegrationsListAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getAllIntegrationsListActionLoader' }));
      const res = await globalProfileServices.getAllIntegrationsList(params);
      if (res?.data?.success) {
        dispatch(
          updateAppIntegrationData({
            fieldName: 'integrationList',
            value: res?.data?.data,
          })
        );
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getAllIntegrationsListActionLoader' }));
    }
  };
};

export const getIntegrationDetailsAction = (params, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getIntegrationDetailsActionLoader' }));
      const res = await globalProfileServices.getIntegrationDetails(params);
      if (res?.data?.success) {
        dispatch(
          updateAppIntegrationData({
            fieldName: 'specificIntegrationDetails',
            value: res?.data?.data,
          })
        );
        cb(res?.data?.data?.locationList?.[0]);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getIntegrationDetailsActionLoader' }));
    }
  };
};

export const getLocationListAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getLocationListActionLoader' }));
      const res = await globalProfileServices.getLocationList(params);
      if (res?.data?.success) {
        dispatch(
          updateAppIntegrationData({
            fieldName: 'locationList',
            value: res?.data?.data,
          })
        );
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getLocationListActionLoader' }));
    }
  };
};

export const getLocationSpecificConfigurationAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getLocationSpecificConfigurationActionLoader' }));
      const res = await globalProfileServices.getLocationSpecificConfiguration(params);
      if (res?.data?.success) {
        dispatch(
          updateAppIntegrationData({
            fieldName: 'locationSpecificConfigurations',
            value: res?.data?.data,
          })
        );
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getLocationSpecificConfigurationActionLoader' }));
    }
  };
};

export const getConfigurationInfoAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getConfigurationInfoActionLoader' }));
      const res = await globalProfileServices.getConfigurationInfo(params);
      if (res?.data?.success) {
        dispatch(
          updateConfigurationDrawerDetails({
            fieldName: 'isActivateForAllLocations',
            value: res?.data?.data?.appliedToAllLocation,
          })
        );
        dispatch(
          updateConfigurationDrawerDetails({
            fieldName: 'apiInformation',
            value: res?.data?.data?.apiInformation,
          })
        );
        dispatch(
          updateConfigurationDrawerDetails({
            fieldName: 'apiWarning',
            value: res?.data?.data?.apiWarning,
          })
        );
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getConfigurationInfoActionLoader' }));
    }
  };
};

export const activateConfigurationAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'activateConfigurationActionLoader' }));
      const res = await globalProfileServices.activateConfiguration(data);
      if (res?.data?.success) {
        successNotification(res?.data.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'activateConfigurationActionLoader' }));
    }
  };
};

export const disconnectConfigurationAction = (params, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'disconnectConfigurationActionLoader' }));
      const res = await globalProfileServices.disconnectConfiguration(params);
      if (res?.data?.success) {
        successNotification(res?.data.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'disconnectConfigurationActionLoader' }));
    }
  };
};

export const disconnectLocationConfigurationAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'disconnectLocationConfigurationActionLoader' }));
      const res = await globalProfileServices.disconnectLocationConfiguration(data);
      if (res?.data?.success) {
        successNotification(res?.data.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'disconnectLocationConfigurationActionLoader' }));
    }
  };
};

export const cancelSubscriptionAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'cancelSubscriptionActionLoader' }));
      const res = await globalProfileServices.cancelSubscription(data);
      if (res?.data?.success) {
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'cancelSubscriptionActionLoader' }));
    }
  };
};

export const connectIntegrationAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'connectIntegrationActionLoader' }));
      const res = await globalProfileServices.connectIntegration(params);
      if (res?.data?.success) {
        window.location.href = res?.data?.data;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'connectIntegrationActionLoader' }));
    }
  };
};

export const editConfigurationSettingsAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'editConfigurationSettingsActionLoader' }));
      const res = await globalProfileServices.editConfigurationSettings(data);
      if (res?.data?.success) {
        successNotification(res?.data.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'editConfigurationSettingsActionLoader' }));
    }
  };
};

export const connectIntegrationWithLocationAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'connectIntegrationWithLocationActionLoader' }));
      const res = await globalProfileServices.connectIntegrationWithLocation(data);
      if (res?.data?.success) {
        successNotification(res?.data.message);
        cb(res?.data?.data);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'connectIntegrationWithLocationActionLoader' }));
    }
  };
};

export const getMyObFileDetailsAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getMyObFileDetailsActionLoader' }));
      const res = await globalProfileServices.getMyObFileDetails(params);
      if (res?.data?.success) {
        dispatch(updateAppIntegrationData({ fieldName: 'myObFilesList', value: res?.data?.data }));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getMyObFileDetailsActionLoader' }));
    }
  };
};

export const saveMyObFileDetailsAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'saveMyObFileDetailsActionLoader' }));
      const res = await globalProfileServices.saveMyObFileDetails(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'saveMyObFileDetailsActionLoader' }));
    }
  };
};

export const syncMyobLeaveBalanceAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'syncMyobLeaveBalanceActionLoader' }));
      const res = await globalProfileServices.syncMyobLeaveBalance(data);
      if (res?.data?.success) {
        successNotification(res?.data.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'syncMyobLeaveBalanceActionLoader' }));
    }
  };
};

export const commonIntegrationAPIAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'commonIntegrationAPIActionLoader' }));
      const res = await globalProfileServices.commonIntegrationAPI(data);
      if (res?.data?.success) {
        successNotification(res?.data.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'commonIntegrationAPIActionLoader' }));
    }
  };
};

export const autoSyncEmployeesAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'autoSyncEmployeesActionLoader' }));
      const res = await globalProfileServices.autoSyncEmployees(data);
      if (res?.data?.success) {
        successNotification(res?.data.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'autoSyncEmployeesActionLoader' }));
    }
  };
};

export const getQboInvoiceApiConfigsAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getQboInvoiceApiConfigsActionLoader' }));
      const res = await globalProfileServices.getQboInvoiceApiConfigs(params);
      if (res?.data?.success) {
        dispatch(
          updateConfigurationDrawerDetails({
            fieldName: 'invoiceApiConfigDropdown',
            value: res?.data?.data,
          })
        );
        if (res?.data?.data?.find((e) => e?.isSelected)?.id) {
          dispatch(
            updateConfigurationDrawerDetails({
              fieldName: 'areaId',
              value: res?.data?.data?.find((e) => e?.isSelected),
            })
          );
        }
        if (res?.data?.data?.find((e) => e?.isSelected)?.enumId) {
          dispatch(
            updateConfigurationDrawerDetails({
              fieldName: 'qboInvoiceConfig',
              value: res?.data?.data?.find((e) => e?.isSelected),
            })
          );
        }
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getQboInvoiceApiConfigsActionLoader' }));
    }
  };
};
