import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { isEmpty, isNull } from 'lodash';
import { useHistory } from 'react-router-dom';
import EzyMenu from '../../../../components/common/menu/EzyMenu';
import EzyButton from '../../../../components/common/button/EzyButton';
import AddRuleDrawer from './AddRuleDrawer';
import { updateAddRuleDrawer, updateRunLaborModalDrawer } from '../../redux/insightSlice';
import AddTaskForWholeAreaDrawer from '../../../tasks/components/AddTaskForWholeAreaDrawer';
import { updateAddTaskForAreaDrawerDetails } from '../../../tasks/redux/tasksSlice';
import {
  copyLocationBulkAction,
  deleteMultipleRulesActions,
  getLocationListForCopyAction,
  getRuleListAction,
} from '../../redux/insightAction';
import { displayTime } from '../../../../utils/timeHelper';
import { getTaskForWholeAreaByIdAction } from '../../../tasks/redux/tasksActions';
import ConfirmationModal from '../../../../components/common/modal/ConfirmationModal';
import Loader from '../../../../components/common/loader/Loader';
import RunLaborModalDrawer from './RunLaborModalDrawer';
import EzyDialog from '../../../../components/common/dialog/EzyDialog';
import EzyDialogTitle from '../../../../components/common/dialog/EzyDialogTitle';
import EzyDialogAction from '../../../../components/common/dialog/EzyDialogAction';
import IconByName from '../../../../components/common/iconByName/IconByName';
import ROUTE_CONSTANTS from '../../../../utils/constants';

function LaborModal() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { filters, staff } = useSelector(({ insight }) => insight ?? {});
  const { businessId } = useSelector(({ user }) => user?.profileData ?? {});
  const { selectedLocation } = useMemo(() => filters, [filters]);
  const { locationListForCopy } = useMemo(() => staff, [staff]);
  const [selectedTasks, setSelectedTask] = useState([]);
  const [selectedRules, setSelectedRule] = useState([]);
  const [deleteModal, setDeleteModal] = useState(null);
  const [copyModal, setCopyModal] = useState(null);
  const [checked, setChecked] = useState([]);
  const [overRideExisting, setOverRideExisting] = useState(false);

  const {
    getRuleListActionLoader,
    copyLocationBulkActionLoader,
    getLocationListForCopyLocationActionLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});

  const addMenuItems = [
    {
      id: 1,
      label: 'New rule',
      onClick: () => dispatch(updateAddRuleDrawer({ fieldName: 'isOpen', value: 'addRule' })),
    },
    {
      id: 2,
      label: 'New task',
      onClick: () =>
        dispatch(
          updateAddTaskForAreaDrawerDetails({
            fieldName: 'drawerTitle',
            value: 'Add Task',
          })
        ),
    },
  ];

  const handleMultipleDelete = () => {
    const params = {
      businessId,
      listOfRuleId: selectedRules?.length ? selectedRules?.join(',') : null,
      listOfTaskId: selectedTasks?.length ? selectedTasks?.join(',') : null,
    };

    dispatch(
      deleteMultipleRulesActions(params, () => {
        setSelectedRule([]);
        setSelectedTask([]);
        dispatch(
          getRuleListAction({
            locationId: selectedLocation?.id,
          })
        );
      })
    );
  };

  const handleCopyBulkAction = () => {
    const data = {
      locationIds: checked,
      overRideExisting,
      ruleIds: selectedRules,
      taskIds: selectedTasks,
    };
    dispatch(
      copyLocationBulkAction(data, () => {
        setCopyModal(null);
        setOverRideExisting(false);
        setSelectedRule([]);
        setSelectedTask([]);
        setChecked([]);
        dispatch(
          getRuleListAction({
            locationId: selectedLocation?.id,
          })
        );
      })
    );
  };

  const bulkActionsMenu = [
    { id: 1, label: 'Delete', onClick: (e) => setDeleteModal(e) },
    { id: 2, label: 'Copy', onClick: (e) => setCopyModal(e) },
  ];

  const handleCheck = (event, id, selected, setSelected) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    if (selectedLocation?.id !== 'all')
      dispatch(
        getRuleListAction({
          locationId: selectedLocation?.id,
        })
      );
  }, [selectedLocation]);

  useEffect(() => {
    if (!isNull(copyModal)) {
      const data = {
        businessId,
        listOfRuleId: selectedRules,
        listOfTaskId: selectedTasks,
      };
      dispatch(getLocationListForCopyAction(data));
    }
  }, [copyModal]);

  return (
    <div className="labor-list-wrapper">
      {selectedLocation?.id !== 'all' && (
        <div className="labor-header">
          <EzyMenu label="Add" iconName="add" menuItems={addMenuItems} />
          <EzyMenu
            iconName="more_vert"
            menuItems={bulkActionsMenu}
            disabled={selectedTasks?.length === 0 && selectedRules?.length === 0}
          />
          <EzyButton
            color="secondary"
            label="Run labor model"
            onClick={() => {
              dispatch(updateRunLaborModalDrawer({ fieldName: 'isOpen', value: true }));
            }}
          />

          <EzyButton
            label="Back"
            color="secondary"
            onClick={() => history.push(ROUTE_CONSTANTS.SCHEDULE)}
          />
        </div>
      )}
      <div className="labor-list">
        {getRuleListActionLoader && <Loader />}
        {staff?.rulesList?.length ? (
          staff?.rulesList?.map((e) => (
            <div key={e?.areaId} className="d-flex-column gap-10">
              <div className="word-break">{e?.areaName}</div>
              <div className="list-wrapper">
                {e?.rules?.map((ruleData) => (
                  <div
                    className="rule-wrapper"
                    key={ruleData?.ruleId}
                    onClick={() => {
                      dispatch(
                        updateAddRuleDrawer({ fieldName: 'isOpen', value: ruleData?.ruleId })
                      );
                    }}
                  >
                    <div>
                      {ruleData?.coverageType === 1 && (
                        <>
                          <div>
                            For every
                            <span className="text-wrapper">
                              $ {ruleData?.linearRule?.laborCost} in {ruleData?.metricName} per hour{' '}
                            </span>
                            <span className="material-icons">east</span>
                            add{' '}
                            <span className="text-wrapper">
                              {ruleData?.linearRule?.requiredLabor} {e?.areaName} employee
                            </span>
                          </div>
                          <div>
                            Minimum{' '}
                            <span className="text-wrapper">
                              {ruleData?.linearRule?.minimumLabor} {e?.areaName} employee
                            </span>
                            Maximum
                            <span className="text-wrapper">
                              {ruleData?.linearRule?.maximumLabor} {e?.areaName} employee
                            </span>
                          </div>
                        </>
                      )}

                      {ruleData?.coverageType === 2 &&
                        ruleData?.rangeRule?.map((rangeRuleData) => (
                          <div>
                            <span className="text-wrapper">
                              {' '}
                              $ {rangeRuleData?.minimumCost} -
                              {rangeRuleData?.maximumCost !== null
                                ? `$${rangeRuleData?.maximumCost}`
                                : 'higher'}{' '}
                              in {ruleData?.metricName} per hour
                            </span>
                            <span className="material-icons">east</span>
                            <span className="text-wrapper">
                              {' '}
                              {rangeRuleData?.requiredLabor} {e?.areaName} employees
                            </span>
                          </div>
                        ))}

                      {ruleData?.ruleBuffer === null ? (
                        <div>While open</div>
                      ) : (
                        <div>
                          From {ruleData?.ruleBuffer?.fromHour}h{' '}
                          {ruleData?.ruleBuffer?.fromIsAfter ? 'after' : 'before'} open to{' '}
                          {ruleData?.ruleBuffer?.untilHour}h{' '}
                          {ruleData?.ruleBuffer?.untilIsAfter ? 'after' : 'before'} close
                        </div>
                      )}
                    </div>
                    <div>
                      <Checkbox
                        checked={selectedRules.indexOf(ruleData?.ruleId) !== -1}
                        onClick={(event) => {
                          handleCheck(event, ruleData?.ruleId, selectedRules, setSelectedRule);
                        }}
                      />
                    </div>
                  </div>
                ))}

                {e?.tasks?.map((taskData) => {
                  const totalHours =
                    taskData.subTasks.reduce(
                      (prev, curr) => +prev + +curr.totalHours * 60 + +curr.totalMinutes,
                      0
                    ) / 60;
                  return (
                    <div
                      className="rule-wrapper"
                      key={taskData?.id}
                      onClick={async () => {
                        await dispatch(getTaskForWholeAreaByIdAction({ taskId: taskData.id }));
                        dispatch(
                          updateAddTaskForAreaDrawerDetails({
                            fieldName: 'drawerTitle',
                            value: 'Edit Task',
                          })
                        );
                      }}
                    >
                      <div>
                        <div className="title-text">{taskData?.taskTitle}</div>
                        <div>Between {displayTime(taskData?.startTime)}</div>
                        <div>
                          Employee hours
                          <span className="text-wrapper">
                            {Math.floor(+totalHours)} hours{' '}
                            {Math.round((+totalHours - +Math.floor(+totalHours)) * 60)} minutes
                          </span>
                        </div>
                      </div>
                      <div>
                        <Checkbox
                          checked={selectedTasks.indexOf(taskData?.id) !== -1}
                          onClick={(event) => {
                            handleCheck(event, taskData?.id, selectedTasks, setSelectedTask);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))
        ) : (
          <Box className="no-data-found">No Rules Found</Box>
        )}
      </div>
      <AddRuleDrawer />
      <AddTaskForWholeAreaDrawer isFromLaborModal />
      <ConfirmationModal
        title="Delete Rule"
        isOpen={!isEmpty(deleteModal) || !isNull(deleteModal)}
        handleClose={() => {
          setDeleteModal(null);
        }}
        handleSubmit={async () => {
          handleMultipleDelete();
          setDeleteModal(null);
        }}
        primaryLabel="Delete"
      >
        <div className="d-flex-column gap-10">
          <div>Are you sure you want to delete selected items?</div>
          <div className=" primary-bg">
            These rules and tasks are used for labor demand. Deleting them will delete all future
            tasks and may affect the calculation of required staff.
          </div>
        </div>
      </ConfirmationModal>
      <EzyDialog
        handleClose={() => setCopyModal(null)}
        className="common-dialog-with-body labor-model-copy-dialog"
        open={!isEmpty(copyModal) || !isNull(copyModal)}
      >
        <EzyDialogTitle title="Copy Location" onClose={() => setCopyModal(null)} />
        <div className="dialog-body title-small">
          {getLocationListForCopyLocationActionLoader && <Loader />}
          <List className="area-check-list">
            {locationListForCopy?.length > 0 &&
              locationListForCopy?.map((e) => {
                const labelId = `checkbox-list-label-${e?.locationId}`;
                if (e?.locationId !== selectedLocation?.id)
                  return (
                    <ListItem disablePadding selected={checked?.indexOf(e?.locationId) !== -1}>
                      <ListItemButton
                        role={undefined}
                        onClick={handleToggle(e?.locationId)}
                        dense
                        disabled={!e?.isAreaMatched}
                        disableRipple
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checked?.indexOf(e?.locationId) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                            icon={<IconByName name="check_circle_outline" />}
                            checkedIcon={
                              <IconByName name="check_circle" className="primary-color" />
                            }
                          />
                        </ListItemIcon>
                        <ListItemText id={e?.locationId} className="people-invite-list-view">
                          {e?.locationName}
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  );
                return null;
              })}
          </List>

          <FormControlLabel
            control={
              <Checkbox
                checked={overRideExisting}
                onChange={(e) => setOverRideExisting(e?.target?.checked)}
              />
            }
            label="Overwrite existing rules and task"
          />
        </div>
        <EzyDialogAction
          actionButtons={[
            {
              label: 'Copy',
              onClick: handleCopyBulkAction,
              loading: copyLocationBulkActionLoader,
              disabled: checked?.length === 0,
            },
          ]}
        />
      </EzyDialog>
      <RunLaborModalDrawer />
    </div>
  );
}

LaborModal.propTypes = {};

export default LaborModal;
