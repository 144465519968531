import { BrowserRouter as Router, Switch } from 'react-router-dom';
import EZY_ROUTES from './ezyRoutes';
import AuthRoutes from './AuthRoutes';
import NonAuthRoutes from './NonAuthRoutes';
import Layout from '../components/layout';

function Routes() {
  return (
    <Router>
      <Layout>
        <Switch>
          {EZY_ROUTES.map(({ path, component, authenticated, isEscapeRedirect, access }, index) => {
            const Component = authenticated ? AuthRoutes : NonAuthRoutes;

            return (
              <Component
                key={`${path}-${index}`}
                exact
                path={path}
                component={component}
                isEscapeRedirect={isEscapeRedirect}
                access={access}
              />
            );
          })}
        </Switch>
      </Layout>
    </Router>
  );
}

export default Routes;
