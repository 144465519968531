import moment from 'moment';
import {
  startGeneralLoader,
  stopGeneralLoader,
} from '../../../utils/backOfficeLists/commonDataSlice';
import {
  resetBulkActionData,
  setBulkActionData,
  setScheduleCoverageData,
  setScheduleData,
  setScheduleWeatherData,
  setStatsPanelList,
  updateAddEditShiftBulkFields,
  updateAddEditShiftFields,
  updateBulkActionData,
  updateScheduleSidePanelData,
} from './scheduleSlice';
import { scheduleServices } from '../scheduleServices';
import { updateAddTaskForAreaDrawerDetails } from '../../tasks/redux/tasksSlice';
import { errorNotification, successNotification } from '../../../utils/notifyHelper';
import { displayErrors } from '../../../utils/errorNotifyHelper';
import { mapEnumToIds, mapIdsFromList } from '../../../utils/enumMapping';
import { concatDateAndTime, getDurationInHours } from '../../../utils/timeHelper';
import { updateTimeSheetData } from '../../timeSheets/redux/timeSheetsSlice';
import { downloadAll } from '../../../utils/downloadHelper';

export const getAreaListByLocationIdAction = (params, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getAreaListByLocationIdActionLoader' }));
      const res = await scheduleServices.getAreaListByLocationId(params);
      if (res?.data?.success) {
        dispatch(
          updateScheduleSidePanelData({
            fieldName: 'areaList',
            value: res?.data?.data?.map((e) => ({
              id: e?.areaId,
              label: e?.areaName,
            })),
          })
        );
        cb();
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getAreaListByLocationIdActionLoader' }));
    }
  };
};

export const getShiftDropdownEntitiesAction = (params, areaId, personDetailId, cb) => {
  return async (dispatch, getState) => {
    const { shiftType } = getState()?.common?.enums ?? {};
    const { drawerTitle } = getState()?.tasks?.addTaskForAreaDrawer ?? {};
    let shiftTypeToUpdate = shiftType[1];

    const { timeZonesList } = getState()?.common ?? {};

    try {
      dispatch(startGeneralLoader({ loaderFor: 'getShiftDropdownEntitiesActionLoader' }));
      const res = await scheduleServices.getShiftDropdownEntities(params);
      if (res?.data?.success) {
        const areaList = res?.data?.data?.areas?.map((e) => ({
          label: `${e?.areaName} - ${e?.locationCode}`,
          id: e?.areaId,
          timeZoneId: mapIdsFromList(e.timeZoneId, timeZonesList),
        }));
        const peopleList = res?.data?.data?.persons;

        if (drawerTitle) {
          dispatch(
            updateAddTaskForAreaDrawerDetails({
              fieldName: 'areaListWithCode',
              value: areaList,
            })
          );
        }

        dispatch(
          updateAddEditShiftFields({
            fieldName: 'areaList',
            value: areaList,
          })
        );
        dispatch(
          updateAddEditShiftFields({
            fieldName: 'peopleList',
            value: peopleList,
          })
        );
        if (areaId) {
          dispatch(
            updateAddEditShiftFields({
              fieldName: 'areaId',
              value: areaList?.find((e) => e?.id === areaId),
            })
          );
        }
        if (personDetailId) {
          dispatch(
            updateAddEditShiftFields({
              fieldName: 'personDetailId',
              value: peopleList?.find((e) => e?.id === personDetailId),
            })
          );
          shiftTypeToUpdate = shiftType[2];
        }
        dispatch(
          updateAddEditShiftFields({
            fieldName: 'shiftType',
            value: shiftTypeToUpdate,
          })
        );

        if (cb && typeof cb === 'function') {
          cb(areaList?.find((e) => e?.id === areaId));
        }
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getShiftDropdownEntitiesActionLoader' }));
    }
  };
};

export const getAllScheduleShiftAction = (params, cb) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const finalParams = {
        ...params,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getAllScheduleShiftActionLoader' }));
      const res = await scheduleServices.getAllScheduleShift(finalParams);
      if (res?.data?.success) {
        const data = mapEnumToIds(
          [
            {
              fieldName: 'leaveStatus',
              listName: 'leaveStatus',
              isFromEnum: true,
            },
            {
              fieldName: 'timeZoneId',
              listName: 'timeZonesList',
              isFromEnum: false,
            },
          ],
          res?.data?.data,
          state?.common
        );
        dispatch(setScheduleData(data));
        cb({ showCoverageArea: data?.showCoverageInAreas, showStatsPanel: data?.showStatsPanel });
      }
    } catch (e) {
      errorNotification(e?.res?.data?.message);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getAllScheduleShiftActionLoader' }));
    }
  };
};

export const getLocationSettingsForShiftAction = (params) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const {
        areaId,
        currentView,
        personDetailId,
        timeZone,
        isFromTimesheet,
        locationId,
        businessId,
        ...rest
      } = params;
      dispatch(startGeneralLoader({ loaderFor: 'getLocationSettingsForShiftActionLoader' }));
      const res = await scheduleServices.getLocationSettingsForShift({ ...rest, areaId });
      if (res?.data?.success) {
        const resData = res.data.data;
        let startTime = null;
        let endTime = null;
        // default start and date filling
        if (resData?.startTime && resData?.endTime) {
          startTime = ['resourceTimelineWeek', 'listWeek'].includes(currentView)
            ? concatDateAndTime(rest.start, resData?.startTime, timeZone)
            : rest.start;
          endTime = ['resourceTimelineWeek', 'listWeek'].includes(currentView)
            ? concatDateAndTime(rest.start, resData?.endTime, timeZone)
            : moment
                .tz(rest.start, timeZone)
                .add(resData?.defaultShiftDuration, 'hour')
                .toISOString();
        } else {
          startTime = ['resourceTimelineWeek', 'listWeek'].includes(currentView)
            ? moment(rest.start).set('minutes', 570).toISOString()
            : rest.start;
          endTime = moment
            .tz(rest.start, timeZone)
            .add(resData?.defaultShiftDuration, 'hour')
            .toISOString();
        }

        if (!isFromTimesheet) {
          const shiftBreakDetailsList = [
            {
              isCustom: false,
              breakType: {
                id: 1,
                label: 'Meal Break',
              },
              startTime: null,
              endTime: null,
              duration: resData.defaultBreakDuration,
            },
          ];
          const dataToUpdate = {
            startTime,
            endTime,
            shiftBreakDetailsList,
            isMealBreakPaid: resData?.isMealBreakPaid,
          };

          dispatch(updateAddEditShiftBulkFields(dataToUpdate));
        } else {
          const data = mapEnumToIds(
            [
              {
                fieldName: 'breakTypeRoundingType',
                listName: 'breakTimeRounding',
                isFromEnum: true,
              },
              { fieldName: 'startTimeRoundingType', listName: 'timeRounding', isFromEnum: true },
              { fieldName: 'endTimeRoundingType', listName: 'timeRounding', isFromEnum: true },
            ],
            res?.data?.data,
            state?.common
          );
          dispatch(
            updateTimeSheetData({
              fieldName: 'breakTypeRoundingType',
              value: data?.breakTypeRoundingType,
            })
          );
          dispatch(
            updateTimeSheetData({
              fieldName: 'startTimeRoundingType',
              value: data?.startTimeRoundingType,
            })
          );
          dispatch(
            updateTimeSheetData({
              fieldName: 'endTimeRoundingType',
              value: data?.endTimeRoundingType,
            })
          );
        }
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getLocationSettingsForShiftActionLoader' }));
    }
  };
};

export const getShiftPanelDetailsAction = (data) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getShiftPanelDetailsActionLoader' }));
      const res = await scheduleServices.getShiftPanelDetails(data);
      if (res?.data?.success) {
        dispatch(
          updateAddEditShiftFields({
            fieldName: 'shiftPayDetails',
            value: res?.data?.data,
          })
        );
      }
    } catch (e) {
      errorNotification(e?.res?.data?.message);
      dispatch(
        updateAddEditShiftFields({
          fieldName: 'shiftPayDetails',
          value: {},
        })
      );
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getShiftPanelDetailsActionLoader' }));
    }
  };
};

export const addShiftAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'addShiftActionLoader' }));
      let res = null;
      if (data?.repeatShift) {
        res = await scheduleServices.repeatShifts(data);
        if (res?.data?.success && res?.data?.data?.length > 0) {
          dispatch(setBulkActionData({ loadTemplateErrors: { errorReasons: res.data.data } }));
          dispatch(updateBulkActionData({ fieldName: 'isRepeatErrorModal', value: true }));
        }
      } else {
        res = await scheduleServices.addShift(data);
      }
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        if (cb && typeof cb === 'function') cb(res?.data?.data);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'addShiftActionLoader' }));
    }
  };
};

export const getShiftByShiftIdAction = (params, timeZone) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { peopleList } = state?.schedule?.addEditShift ?? {};
    const { shiftType: shiftTypeEnum, breakType } = state?.common?.enums ?? {};
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getShiftByShiftIdActionLoader' }));
      const res = await scheduleServices.getShiftByShiftId(params);
      const resData = res.data.data;
      if (res?.data?.success) {
        const data = {
          ...res.data.data,
          isLocked: resData.shiftType === 4,
          personDetailId: mapIdsFromList(resData.personDetailId, peopleList),
          shiftType: mapIdsFromList(
            resData.shiftType === 4 ? resData?.previousShiftType : resData.shiftType,
            shiftTypeEnum
          ),
          shiftBreakDetailsList:
            resData?.shiftBreakDetailsList?.length > 0
              ? resData?.shiftBreakDetailsList?.map((e) => ({
                  ...e,
                  breakType: breakType?.find((type) => type?.id === e?.breakType),
                  isCustom: e?.startTime && e?.endTime,
                }))
              : [
                  {
                    isCustom: false,
                    breakType: null,
                    startTime: null,
                    endTime: null,
                    duration: null,
                  },
                ],
        };
        delete data.areaId;

        if (
          resData.personDetailId &&
          resData?.shiftBreakDetailsList?.filter((e) => e?.breakType === 1)?.length === 0
        ) {
          const payDetailsParams = {
            personDetailId: resData.personDetailId,
            areaId: resData.areaId,
            shiftStartTime: resData.startTime,
            shiftLength: getDurationInHours(resData.startTime, resData.endTime),
            weekStartDate: moment.tz(resData.startTime, timeZone)?.startOf('week')?.toISOString(),
            weekEndDate: moment.tz(resData.startTime, timeZone)?.endOf('week')?.toISOString(),
          };
          await dispatch(getShiftPanelDetailsAction(payDetailsParams));
        }
        dispatch(updateAddEditShiftBulkFields(data));
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getShiftByShiftIdActionLoader' }));
    }
  };
};

export const editShiftAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'editShiftActionLoader' }));
      let res = null;
      if (data?.repeatShift) {
        res = await scheduleServices.repeatShifts(data);
        if (res?.data?.success && res?.data?.data?.length > 0) {
          dispatch(setBulkActionData({ loadTemplateErrors: { errorReasons: res.data.data } }));
          dispatch(updateBulkActionData({ fieldName: 'isRepeatErrorModal', value: true }));
        }
      } else {
        res = await scheduleServices.editShift(data);
      }
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        if (cb && typeof cb === 'function') cb(res?.data?.data);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'editShiftActionLoader' }));
    }
  };
};

export const publishShiftAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'publishShiftActionLoader' }));
      const res = await scheduleServices.publishShift(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        if (cb && typeof cb === 'function') cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'publishShiftActionLoader' }));
    }
  };
};

export const shiftBulkActionsAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'shiftBulkActionsActionLoader' }));
      const res = await scheduleServices.shiftBulkActions(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        if (cb && typeof cb === 'function') cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'shiftBulkActionsActionLoader' }));
    }
  };
};

export const saveTemplateAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'saveTemplateActionLoader' }));
      const res = await scheduleServices.saveTemplate(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        if (cb && typeof cb === 'function') cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'saveTemplateActionLoader' }));
    }
  };
};

export const loadTemplateAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'loadTemplateActionLoader' }));
      const res = await scheduleServices.loadTemplate(data);
      if (
        res?.data?.success &&
        res?.data?.data?.incorrectAreas?.length <= 0 &&
        res?.data?.data?.errorReasons?.length <= 0
      ) {
        successNotification(res?.data?.message);
        if (cb && typeof cb === 'function') cb();
      } else {
        dispatch(resetBulkActionData());
        dispatch(setBulkActionData({ loadTemplateErrors: res.data.data }));
        dispatch(updateBulkActionData({ fieldName: 'isLoadErrorModal', value: true }));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'loadTemplateActionLoader' }));
    }
  };
};

export const deleteShiftAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'deleteShiftActionLoader' }));
      const response = await scheduleServices.deleteShift(data);
      if (response?.data?.success) {
        successNotification(response?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deleteShiftActionLoader' }));
    }
  };
};

export const getAllTemplatesByLocationIdAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(
        startGeneralLoader({
          loaderFor: params?.searchText
            ? 'getAllTemplatesSearchTextActionLoader'
            : 'getAllTemplatesByLocationIdActionLoader',
        })
      );
      const res = await scheduleServices.getAllTemplatesByLocationId(params);
      if (res?.data?.success) {
        dispatch(setBulkActionData({ templateData: res.data.data }));
      }
    } catch (e) {
      errorNotification(e?.res?.data?.message);
    } finally {
      dispatch(
        stopGeneralLoader({
          loaderFor: params?.searchText
            ? 'getAllTemplatesSearchTextActionLoader'
            : 'getAllTemplatesByLocationIdActionLoader',
        })
      );
    }
  };
};

export const deleteTemplateAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'deleteTemplateActionLoader' }));
      const response = await scheduleServices.deleteTemplate(data);
      if (response?.data?.success) {
        successNotification(response?.data?.message);
        cb(response?.data?.data);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deleteTemplateActionLoader' }));
    }
  };
};

export const getSwapShiftDetailsAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(
        startGeneralLoader({
          loaderFor: 'getSwapShiftDetailsActionLoader',
        })
      );
      const res = await scheduleServices.getSwapShiftDetails(params);
      if (res?.data?.success) {
        dispatch(updateAddEditShiftFields({ fieldName: 'swapDetails', value: res.data.data }));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(
        stopGeneralLoader({
          loaderFor: 'getSwapShiftDetailsActionLoader',
        })
      );
    }
  };
};
export const getOpenShiftDetailsAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(
        startGeneralLoader({
          loaderFor: 'getOpenShiftDetailsActionLoader',
        })
      );
      const res = await scheduleServices.getOpenShiftDetails(params);
      if (res?.data?.success) {
        dispatch(
          updateAddEditShiftFields({ fieldName: 'openShiftRequestDetails', value: res.data.data })
        );
      }
    } catch (e) {
      errorNotification(e?.res?.data?.message);
    } finally {
      dispatch(
        stopGeneralLoader({
          loaderFor: 'getOpenShiftDetailsActionLoader',
        })
      );
    }
  };
};

export const cancelRequestedOpenShiftAction = (params, cb) => {
  return async (dispatch) => {
    try {
      dispatch(
        startGeneralLoader({
          loaderFor: 'cancelRequestedOpenShiftActionLoader',
        })
      );
      const res = await scheduleServices.cancelRequestedOpenShift(params);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      errorNotification(e?.res?.data?.message);
    } finally {
      dispatch(
        stopGeneralLoader({
          loaderFor: 'cancelRequestedOpenShiftActionLoader',
        })
      );
    }
  };
};

export const swapRequestAdminAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'swapRequestAdminActionLoader' }));
      const res = await scheduleServices.swapRequestAdmin(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        if (cb && typeof cb === 'function') cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'swapRequestAdminActionLoader' }));
    }
  };
};

export const confirmRequestForOpenShiftAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'confirmRequestForOpenShiftActionLoader' }));
      const res = await scheduleServices.confirmRequestForOpenShift(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        if (cb && typeof cb === 'function') cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'confirmRequestForOpenShiftActionLoader' }));
    }
  };
};

export const dragAndDropShiftAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getAllScheduleShiftActionLoader' }));
      const res = await scheduleServices.dragAndDropShift(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb(true);
      } else cb(false);
    } catch (e) {
      cb(false);
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getAllScheduleShiftActionLoader' }));
    }
  };
};

export const getShiftHistoryByShiftId = (params) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(startGeneralLoader({ loaderFor: 'getShiftHistoryLoader' }));
      const res = await scheduleServices.getShiftHistoryByShiftId(params);

      if (res?.data?.success) {
        const data = mapEnumToIds(
          [{ fieldName: 'breakType', listName: 'breakType', isFromEnum: true }],
          res?.data?.data,
          state?.common
        );

        dispatch(
          updateAddEditShiftFields({
            fieldName: 'shiftHistory',
            value: data,
          })
        );
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getShiftHistoryLoader' }));
    }
  };
};

export const downloadShecduleCSVAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'downloadShecduleCSVActionLoader' }));
      const res = await scheduleServices.downloadShecduleCSV(params);

      if (res?.data) {
        successNotification('File received successfully');
        downloadAll(res);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'downloadShecduleCSVActionLoader' }));
    }
  };
};

export const downloadScheduleExcelByAreaAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'downloadScheduleExcelByAreaActionLoader' }));
      const res = await scheduleServices.downloadScheduleExcelByArea(params);

      if (res?.data) {
        successNotification('File received successfully');
        downloadAll(res);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'downloadScheduleExcelByAreaActionLoader' }));
    }
  };
};

export const downloadScheduleExcelByTeamMemberAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'downloadScheduleExcelByTeamMemberActionLoader' }));
      const res = await scheduleServices.downloadScheduleExcelByTeamMember(params);

      if (res?.data) {
        successNotification('File received successfully');
        downloadAll(res);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'downloadScheduleExcelByTeamMemberActionLoader' }));
    }
  };
};

export const getCoverageAreaDataAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getCoverageAreaDataActionLoader' }));
      const res = await scheduleServices.getCoverageAreaData(params);
      if (res?.data?.success) {
        dispatch(setScheduleCoverageData(res?.data?.data));
      }
    } catch (e) {
      errorNotification(e?.res?.data?.message);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getCoverageAreaDataActionLoader' }));
    }
  };
};

export const updateInsightPanelDisplaySettingsAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'updateInsightPanelDisplaySettingsActionLoader' }));
      const res = await scheduleServices.updateInsightPanelDisplaySettings(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      errorNotification(e?.res?.data?.message);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'updateInsightPanelDisplaySettingsActionLoader' }));
    }
  };
};

export const updateRequiredStaffDataAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'updateRequiredStaffDataActionLoader' }));
      const res = await scheduleServices.updateRequiredStaffData(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'updateRequiredStaffDataActionLoader' }));
    }
  };
};

export const getHolidaySettingsAction = (params, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getHolidaySettingsActionLoader' }));
      const res = await scheduleServices.getHolidaySettings(params);
      if (res?.data?.success) {
        dispatch(setScheduleWeatherData(res?.data?.data));
        cb(res?.data?.data);
      }
    } catch (e) {
      errorNotification(e?.res?.data?.message);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getHolidaySettingsActionLoader' }));
    }
  };
};

export const updateHolidaySettingsAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'updateHolidaySettingsActionLoader' }));
      const res = await scheduleServices.updateHolidaySettings(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      errorNotification(e?.res?.data?.message);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'updateHolidaySettingsActionLoader' }));
    }
  };
};

export const getGraphDataAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getChartDataActionLoader' }));
      const res = await scheduleServices.getGraphData(params);
      if (res?.data?.success) {
        dispatch(setStatsPanelList({ listName: 'graphData', value: res?.data?.data }));
      }
    } catch (e) {
      errorNotification(e?.res?.data?.message);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getChartDataActionLoader' }));
    }
  };
};

export const getStatsPanelDataAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getStatsPanelDataActionLoader' }));
      const res = await scheduleServices.getStatsPanelData(params);
      if (res?.data?.success) {
        dispatch(setStatsPanelList({ listName: 'statsData', value: res?.data?.data }));
      }
    } catch (e) {
      errorNotification(e?.res?.data?.message);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getStatsPanelDataActionLoader' }));
    }
  };
};

export const updateGraphSettingsAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'updateGraphSettingsActionLoader' }));
      const res = await scheduleServices.updateGraphSettings(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      errorNotification(e?.res?.data?.message);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'updateGraphSettingsActionLoader' }));
    }
  };
};
