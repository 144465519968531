import {
  setBusinessLeaveRules,
  setBusinessSettingDetails,
  setLeaveRuleDetails,
  setLeaveSettingDetails,
  updateLeaveRuleData,
} from './businessSlice';
import businessServices from '../businessServices';
import { errorNotification, successNotification } from '../../../utils/notifyHelper';
import { mapBusinessLeaveRuleIds, mapEnumToIds } from '../../../utils/enumMapping';
import {
  startGeneralLoader,
  stopGeneralLoader,
} from '../../../utils/backOfficeLists/commonDataSlice';
import { displayErrors } from '../../../utils/errorNotifyHelper';
import {
  clearFCMTokenFromLocalStorage,
  getFCMTokenFromLocalStorage,
  saveAuthTokenToLocalStorage,
} from '../../../utils/localStorageHelper';

export const registerNewBusiness = (data) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'registerNewBusinessLoader' }));
      const clientToken = getFCMTokenFromLocalStorage();

      const res = await businessServices.registerBusiness({ ...data, clientToken });
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        saveAuthTokenToLocalStorage(res?.data?.data?.token);
        clearFCMTokenFromLocalStorage();
        return true;
      }
      return false;
    } catch (e) {
      displayErrors(e);
      return false;
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'registerNewBusinessLoader' }));
    }
  };
};
export const joinYourTeam = (data) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'joinYourTeamLoader' }));
      const res = await businessServices.inviteToBusiness(data);
      if (res?.data?.success) {
        return true;
      }
      return false;
    } catch (e) {
      errorNotification(e?.res?.data?.message);
      return false;
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'joinYourTeamLoader' }));
    }
  };
};

export const getGeneralLocationSettingsAction = () => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const params = {
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getGeneralLocationSettingsActionLoader' }));
      const res = await businessServices.getBusinessGeneralDetails(params);
      if (res?.data?.success) {
        const data = mapEnumToIds(
          [{ fieldName: 'countryId', listName: 'country', isFromEnum: true }],
          res?.data?.data,
          state?.common
        );
        dispatch(setBusinessSettingDetails(data));
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getGeneralLocationSettingsActionLoader' }));
    }
  };
};

export const fetchBusinessLeaveRulesAction = (params) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const finalParams = {
        ...params,
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'fetchBusinessLeaveRulesActionLoader' }));
      const res = await businessServices.fetchBusinessLeaveRules(finalParams);
      if (res?.data?.success) {
        dispatch(setBusinessLeaveRules(res?.data));
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'fetchBusinessLeaveRulesActionLoader' }));
    }
  };
};

export const saveRegisterGeneralSettings = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(startGeneralLoader({ loaderFor: 'saveRegisterGeneralSettingsLoader' }));
      const finalData = {
        ...data,
        businessId: state?.user?.profileData?.businessId,
      };
      const res = await businessServices.saveBusinessGeneralDetails(finalData);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
      }
    } catch (e) {
      errorNotification(e?.res?.data?.message);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'saveRegisterGeneralSettingsLoader' }));
    }
  };
};
export const saveEditedLeaveSettingAction = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(startGeneralLoader({ loaderFor: 'saveEditedLeaveSettingActionLoader' }));
      const finalData = {
        ...data,
        businessId: state?.user?.profileData?.businessId,
      };
      const res = await businessServices.saveEditedLeaveSetting(finalData);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
      }
    } catch (e) {
      errorNotification(e?.res?.data?.message);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'saveEditedLeaveSettingActionLoader' }));
    }
  };
};

export const getBusinessLeaveSettingsAction = () => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const params = {
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getBusinessLeaveSettingsActionLoader' }));
      const res = await businessServices.getBusinessLeaveSetting(params);
      if (res?.data?.success) {
        const data = mapEnumToIds(
          [
            {
              fieldName: 'whoCanApproveLeave',
              listName: 'whoCanApproveLeaveEnum',
              isFromEnum: false,
            },
          ],
          res?.data?.data,
          state?.business?.leaveRuleData
        );

        dispatch(setLeaveSettingDetails(data));
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getBusinessLeaveSettingsActionLoader' }));
    }
  };
};

export const addEditLeaveRulesAction = (data, cb) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const finalData = {
        ...data,
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'addEditLeaveRulesActionLoader' }));
      const res = await businessServices.addEditLeaveRules(finalData);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'addEditLeaveRulesActionLoader' }));
    }
  };
};

export const getBusinessLeaveRuleDetailsAction = (params, cb) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const finalParams = {
        ...params,
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getBusinessLeaveRuleDetailsActionLoader' }));
      const res = await businessServices.getBusinessLeaveRuleDetails(finalParams);
      if (res?.data?.success) {
        const data = mapEnumToIds(mapBusinessLeaveRuleIds, res?.data?.data, state?.common);
        dispatch(setLeaveRuleDetails(data));
        cb();
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getBusinessLeaveRuleDetailsActionLoader' }));
    }
  };
};

export const getAllPersonWhoCanApproveLeaveEnum = (cb) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const finalParams = {
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getAllPersonWhoCanApproveLeaveEnumLoader' }));
      const res = await businessServices.personWhoCanApproveLeaveEnum(finalParams);
      if (res?.data?.success) {
        dispatch(
          updateLeaveRuleData({ fieldName: 'whoCanApproveLeaveEnum', value: res?.data?.data })
        );
        cb();
      }
    } catch (e) {
      errorNotification(e?.res?.data?.message);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getAllPersonWhoCanApproveLeaveEnumLoader' }));
    }
  };
};

export const deleteBusinessLeaveRuleByLeaveRuleId = (leaveRuleId, cb) => {
  return async (dispatch) => {
    try {
      const finalParams = {
        leaveRuleId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'deleteBusinessLeaveRuleByLeaveRuleIdLoader' }));
      const res = await businessServices.deleteBusinessLeaveRuleByLeaveRuleId(finalParams);
      if (res?.data?.success) {
        cb();
      }
    } catch (e) {
      errorNotification(e?.res?.data?.message);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deleteBusinessLeaveRuleByLeaveRuleIdLoader' }));
    }
  };
};
