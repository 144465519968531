import { isNil, omitBy } from 'lodash';
import { passwordValidate } from '../../../utils/validationHelper';

const validateResetPassword = (data) => {
  const error = {};
  error.password = passwordValidate('password', data.password, true);
  error.confirmPassword = passwordValidate(
    'confirmPassword',
    data.password,
    true,
    true,
    data.confirmPassword
  );
  return omitBy(error, isNil);
};

export default validateResetPassword;
