import React, { useEffect, useMemo } from 'react';
import { displayDate, displayDateTime, displayTime } from '../../../utils/timeHelper';

function TimesheetsPrint() {
  const data = window.printData;
  const { docs, selectedLocation, weekDatesValue, selectedTimeSheets, printOptions } = useMemo(
    () => data,
    [data]
  );

  const printData = docs?.map((e) => {
    return {
      ...e,
      timeSheetsForExport: e?.timeSheetsForExport?.filter((x) =>
        selectedTimeSheets?.includes(x?.timeSheetId)
      ),
    };
  });

  useEffect(() => {
    const printContents = document.querySelector('.print-container').innerHTML;

    if (printContents && printData?.length > 0) {
      window.print();
    }
  }, []);

  return (
    <div className="print-container" style={{ padding: 10 }}>
      <div>
        <h3>
          {selectedLocation?.label} |{' '}
          {displayDate(weekDatesValue?.startDate, selectedLocation?.timeZoneId?.label)} -{' '}
          {displayDate(weekDatesValue?.endDate, selectedLocation?.timeZoneId?.label)} |{' '}
          {displayDateTime(new Date())}
        </h3>
      </div>
      <br />
      {printData?.length > 0 &&
        printData?.map(
          (details, i) =>
            details?.timeSheetsForExport?.length > 0 && (
              <>
                {printOptions?.inSeparatePage && i > 0 && (
                  <div style={{ pageBreakBefore: 'always' }}>
                    <h3>
                      {selectedLocation?.label} |{' '}
                      {displayDate(weekDatesValue?.startDate, selectedLocation?.timeZoneId?.label)}{' '}
                      - {displayDate(weekDatesValue?.endDate, selectedLocation?.timeZoneId?.label)}{' '}
                      | {displayDateTime(new Date())}
                    </h3>
                  </div>
                )}
                {printOptions?.format === 'List of time sheets' ? (
                  <table
                    border="1"
                    cellPadding="3"
                    cellSpacing="0"
                    className="tse-print"
                    style={{ width: '100%', fontSize: '14px' }}
                  >
                    <thead>
                      <tr>
                        <td colSpan="2" rowSpan="2" className="name">
                          {details?.name}
                        </td>
                        <td align="right" className="header-row">
                          Items
                        </td>
                        <td align="right" className="header-row">
                          Total Hours
                        </td>
                      </tr>
                      <tr>
                        <td align="right">{details?.timeSheetsForExport?.length}</td>
                        <td align="right">{details?.totalWorkingHour}</td>
                      </tr>
                      <tr className="header-row">
                        <td>Date</td>
                        <td>Area</td>
                        <td align="right">Time</td>
                        <td align="right">Hours</td>
                      </tr>
                    </thead>
                    <tbody>
                      {details?.timeSheetsForExport?.map((timesheetDetails) => (
                        <tr key={timesheetDetails?.timeSheetId}>
                          <td>
                            {displayDate(
                              timesheetDetails?.timeSheetDate,
                              selectedLocation?.timeZoneId?.label
                            )}
                          </td>
                          <td>
                            <strong>[{selectedLocation?.code}]</strong> {timesheetDetails?.areaName}
                          </td>
                          <td align="right">
                            {displayTime(
                              timesheetDetails?.startTime,
                              selectedLocation?.timeZoneId?.label
                            )}{' '}
                            -{' '}
                            {displayTime(
                              timesheetDetails?.endTime,
                              selectedLocation?.timeZoneId?.label
                            )}{' '}
                            | {timesheetDetails?.totalMealBreak}
                          </td>
                          <td align="right" className="last">
                            {timesheetDetails?.totalWorkingHour}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <table
                    border="1"
                    cellPadding="3"
                    cellSpacing="0"
                    style={{ width: '100%', fontSize: '14px' }}
                  >
                    <thead>
                      <tr>
                        <td colSpan="2" rowSpan="2" className="name">
                          {details?.name}
                        </td>
                        <td align="right" className="header-row">
                          Items
                        </td>
                        <td align="right" className="header-row">
                          Total Hours
                        </td>
                        <td align="right" className="header-row">
                          Total Cost
                        </td>
                      </tr>
                      <tr>
                        <td align="right">{details?.timeSheetsForExport?.length}</td>
                        <td align="right">{details?.totalWorkingHour}</td>
                        <td align="right">{details?.totalCost}</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="5" aria-labelledby="empty-cell">
                          &nbsp;
                        </td>
                      </tr>
                      {details?.timeSheetsForExport?.map((timesheetDetails) => (
                        <>
                          <tr>
                            <td colSpan="3">
                              {displayDate(
                                timesheetDetails?.timeSheetDate,
                                selectedLocation?.timeZoneId?.label
                              )}
                              | <strong>[{selectedLocation?.code}]</strong>{' '}
                              {timesheetDetails?.areaName} |{' '}
                              {displayTime(
                                timesheetDetails?.startTime,
                                selectedLocation?.timeZoneId?.label
                              )}{' '}
                              -{' '}
                              {displayTime(
                                timesheetDetails?.endTime,
                                selectedLocation?.timeZoneId?.label
                              )}{' '}
                              | {timesheetDetails?.totalMealBreak}
                            </td>
                            <td align="right">{timesheetDetails?.totalWorkingHour}</td>
                            <td align="right" className="last">
                              {timesheetDetails?.totalCost}
                            </td>
                          </tr>
                          {timesheetDetails?.payRates?.map((payrate) => (
                            <tr>
                              <td
                                colSpan="2"
                                rowSpan={
                                  timesheetDetails.payRates.length - 1
                                    ? timesheetDetails.payRates.length - 1
                                    : 1
                                }
                              >
                                {' '}
                              </td>
                              <td>{payrate?.payRatesString}</td>
                              <td align="right">{payrate?.workingTime} </td>
                              <td align="right" className="last">
                                {payrate?.cost}
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan="5" aria-labelledby="empty-cell">
                              &nbsp;
                            </td>
                          </tr>
                        </>
                      ))}

                      <tr>
                        <td colSpan="5" className="header-row">
                          Pay Breakdown Summary
                        </td>
                      </tr>
                      <tr className="header-row">
                        <td colSpan="2">Condition Type</td>
                        <td>Condition</td>
                        <td align="right">Hours / Units</td>
                        <td align="right" className="last">
                          Cost
                        </td>
                      </tr>
                      {details?.exportTimesheetByPayRates?.map((payRateSummary, index1) =>
                        payRateSummary?.payRateSubTypeList?.map((e, index2) =>
                          e?.payrates?.map((payrate, index3) => {
                            return (
                              <tr>
                                {index1 === 0 && index2 === 0 && index3 === 0 && (
                                  <td
                                    rowSpan={payRateSummary?.payRateSubTypeList?.reduce(
                                      (prev, curr) => prev + curr.payrates.length,
                                      0
                                    )}
                                  >
                                    {payRateSummary?.payRateType}
                                  </td>
                                )}
                                {index1 === 0 && index3 === 0 && (
                                  <td rowSpan={e?.payrates?.length}>{e?.subType}</td>
                                )}
                                <td>{payrate?.payrate}</td>
                                <td align="right">{payrate?.cost}</td>
                                <td align="right">{payrate?.workingHours}</td>
                              </tr>
                            );
                          })
                        )
                      )}
                    </tbody>
                  </table>
                )}
                <br />
              </>
            )
        )}
    </div>
  );
}

export default TimesheetsPrint;
