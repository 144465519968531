import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const tasksSlice = createSlice({
  name: 'tasks',
  initialState: {
    tasksList: {
      docs: [],
      total: 1,
      limit: 15,
      totalTask: 0,
      completedTask: 0,
      page: 1,
      pages: 1,
      hasMoreRecords: false,
    },
    areaTasksList: {
      areaDocs: [],
    },
    filters: {
      taskType: 'My tasks',
      date: moment(new Date()),
      selectedLocation: null,
    },
    addTaskDrawer: {
      drawerTitle: '',
      isEditCategory: null,
      taskTitle: '',
      selectedPerson: null,
      dueDate: '',
      note: '',
    },
    addTaskForAreaDrawer: {
      areaListWithCode: [],
      isEditCategory: null,
      drawerTitle: '',
      task: '',
      subTasks: [
        {
          subTask: '',
          isCustom: false,
          note: '',
          totalHour: 0,
          totalMinute: 0,
        },
      ],
      selectedArea: null,
      startTime: null,
      repeat: null,
      date: new Date(),
      weekDays: [],
      datesOfMonth: [],
    },
  },
  reducers: {
    updateTaskListDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.tasksList[fieldName] = value;
    },
    updateAreaTaskListDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.areaTasksList[fieldName] = value;
    },
    setAreaTasksList: (state, action) => {
      state.areaTasksList = {
        ...state.areaTasksList,
        areaDocs: action?.payload,
      };
    },
    setTasksList: (state, action) => {
      state.tasksList = {
        ...state.tasksList,
        docs: action.payload.data.taskList,
        limit: action.payload.limit,
        totalTask: action.payload.data.totalTask,
        completedTask: action.payload.data.completedTask,
        total: action.payload.totalData,
        page: action.payload.page,
        pages: action.payload.totalPages,
        hasMoreRecords: action.payload.totalPages > action.payload.page,
      };
    },
    updateTasksListDocs: (state, action) => {
      state.tasksList = {
        ...state.tasksList,
        docs: [...state.tasksList.docs, ...action.payload.data.taskList],
        limit: action.payload.limit,
        totalTask: action.payload.data.totalTask,
        completedTask: action.payload.data.completedTask,
        total: action.payload.totalData,
        page: action.payload.page,
        pages: action.payload.totalPages,
        hasMoreRecords: action.payload.totalPages > action.payload.page,
      };
    },
    updateTaskListFilters: (state, action) => {
      const { fieldName, value } = action.payload;
      state.filters[fieldName] = value;
    },

    setAddTaskDrawerDetails: (state, action) => {
      const { title, assignedTo, dueDate, note, assignedToPerson, id } = action.payload;
      state.addTaskDrawer = {
        ...state.addTaskDrawer,
        isEditCategory: id,
        taskTitle: title,
        selectedPerson: { id: assignedToPerson, label: assignedTo },
        dueDate,
        note,
      };
    },
    setAddTaskForWholeAreaDetails: (state, action) => {
      const {
        id,
        taskTitle,
        subTasks,
        startTime,
        taskDate,
        weekDays,
        dayOfMonth,
        taskRepeatationFrequency,
        assignedAreaId,
        assignedAreaName,
        isLaborModelAdded,
      } = action.payload;
      state.addTaskForAreaDrawer = {
        ...state.addTaskForAreaDrawer,
        isEditCategory: id,
        task: taskTitle,
        subTasks: subTasks.map((subTask) => {
          return {
            ...subTask,
            subTask: subTask?.subTaskTitle,
            isCustom: subTask?.canCompletedMultipleTimes,
            totalHour: subTask?.totalHours,
            totalMinute: subTask?.totalMinutes,
          };
        }),
        isAddToLaborModel: isLaborModelAdded,
        selectedArea: { id: assignedAreaId, label: assignedAreaName },
        startTime,
        repeat: taskRepeatationFrequency,
        date: taskDate,
        weekDays: weekDays?.map((e) => e?.label) ?? [],
        datesOfMonth: dayOfMonth?.map((e) => e?.label) ?? [],
      };
    },
    updateAddTaskDrawerDetails: (state, action) => {
      const { fieldName, value } = action.payload ?? {};
      state.addTaskDrawer[fieldName] = value;
    },
    updateAddTaskForAreaDrawerDetails: (state, action) => {
      const { fieldName, value, index } = action.payload ?? {};
      if (index || index === 0) {
        state.addTaskForAreaDrawer.subTasks[index][fieldName] = value;
      }
      state.addTaskForAreaDrawer[fieldName] = value;
    },

    resetAddTaskDrawer: (state) => {
      state.addTaskDrawer = {
        drawerTitle: '',
        isEditCategory: null,
        taskTitle: '',
        selectedPerson: null,
        dueDate: '',
        note: '',
      };
    },
    resetAddTaskForAreaDrawer: (state) => {
      state.addTaskForAreaDrawer = {
        areaListWithCode: [],
        drawerTitle: '',
        task: '',
        subTasks: [
          {
            subTask: '',
            isCustom: false,
            note: '',
            totalHour: 0,
            totalMinute: 0,
          },
        ],

        selectedArea: null,
        startTime: null,
        repeat: null,
        date: new Date(),
        weekDays: [],
        datesOfMonth: [],
      };
    },
    addDeleteSubTasks: (state, action) => {
      const { type, index } = action.payload;
      if (type === 'ADD') {
        state.addTaskForAreaDrawer.subTasks.push({
          subTask: '',
          isCustom: false,
          totalHour: 0,
          totalMinute: 0,
        });
      }
      if (type === 'DELETE') {
        state.addTaskForAreaDrawer.subTasks = state.addTaskForAreaDrawer.subTasks.filter(
          (e, i) => i !== index
        );
      }
    },
    resetAreaTaskList: (state) => {
      state.areaTasksList.areaDocs = [];
    },
  },
});

const { reducer, actions } = tasksSlice;

export const {
  updateTaskListDetails,
  setTasksList,
  updateTaskListFilters,
  updateAddTaskDrawerDetails,
  resetAddTaskDrawer,
  setAddTaskDrawerDetails,
  addDeleteSubTasks,
  updateAddTaskForAreaDrawerDetails,
  setAddTaskForWholeAreaDetails,
  resetAddTaskForAreaDrawer,
  setAreaTasksList,
  updateAreaTaskListDetails,
  updateTasksListDocs,
  resetAreaTaskList,
} = actions;

export default reducer;
