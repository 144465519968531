import { isEmpty, isNil, omitBy } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import InfoModal from '../../../../components/infoModal/InfoModal';
import { isEmail } from '../../../../utils/validationHelper';
import { sendVerificationEmailAction } from '../../../people/redux/peopleActions';
import { resetChangeEmailData, updateGlobalProfileData } from '../../redux/globalProfileSlice';
import checkEmailImage from '../../../../assets/images/Check your email.png';

function ChangeEmailDrawer() {
  const dispatch = useDispatch();
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const { changeEmailDrawer, email, errors } = useSelector(
    ({ globalProfile }) => globalProfile || {}
  );
  const { checkPasswordActionLoader } = useSelector(({ common }) => common.generalLoader ?? {});

  const handleClose = () => {
    dispatch(updateGlobalProfileData({ fieldName: 'changeEmailDrawer', value: false }));
    dispatch(resetChangeEmailData());
  };

  const handleSubmit = async () => {
    const data = {
      newEmailId: email,
    };
    let error = {};
    error.email = isEmail('email', data.newEmailId);
    error = omitBy(error, isNil);
    if (isEmpty(error)) {
      dispatch(updateGlobalProfileData({ fieldName: 'errors', value: {} }));
      await dispatch(
        sendVerificationEmailAction(data, () => {
          setIsSuccessModal(true);
          handleClose();
        })
      );
    } else {
      dispatch(updateGlobalProfileData({ fieldName: 'errors', value: error }));
    }
  };

  const actionButton = [
    {
      label: 'Save',
      onClick: handleSubmit,
      loading: checkPasswordActionLoader,
    },
  ];

  return (
    <div>
      <EzyDrawer
        onClose={handleClose}
        isOpen={changeEmailDrawer}
        actionButtons={actionButton}
        heading="Change Email"
      >
        <div className="p-24">
          <EzyInputField
            label="Email"
            placeholder="Enter email"
            onChange={(e) =>
              dispatch(updateGlobalProfileData({ fieldName: 'email', value: e?.target?.value }))
            }
            value={email}
            error={errors?.email}
            required
          />
        </div>
      </EzyDrawer>
      <InfoModal
        img={checkEmailImage}
        subTitle={`We sent a password reset link to ${email}`}
        title="Check your email"
        action={
          <div className="subtitle-text ">
            {`Didn't receive the email? `}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span onClick={handleSubmit} className="secondary-action-text">
              Click to resend
            </span>
          </div>
        }
        open={isSuccessModal}
        onClose={() => setIsSuccessModal(false)}
      />
    </div>
  );
}

export default ChangeEmailDrawer;
