import { Tab, Tabs } from '@mui/material';
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useAccess from '../../../hooks/useAccess';

function EzyTabDefault({ list, defaultIndex, onChange, variant }) {
  const [value, setValue] = useState(defaultIndex);

  const access = useAccess(list?.map((e) => e.access));

  const tabData = useMemo(() => list?.filter((e) => access?.includes(e?.access)), [access]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <div className="full-relative-wrapper">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="wrapped label tabs example"
        variant={variant}
        className="default-tabs"
      >
        {tabData?.map((res, index) => (
          <Tab key={index} label={res?.label} className="default-tab" />
        ))}
      </Tabs>
      {list?.[value]?.access ? (
        <div className="default-tabs-view"> {list?.[value]?.component}</div>
      ) : null}
    </div>
  );
}

export default EzyTabDefault;

EzyTabDefault.propTypes = {
  list: PropTypes.array.isRequired,
  defaultIndex: PropTypes.number,
  onChange: PropTypes.func,
  variant: PropTypes.string,
};

EzyTabDefault.defaultProps = {
  defaultIndex: 0,
  onChange: null,
  variant: 'fullWidth',
};
