import PropTypes from 'prop-types';
import EzyDialog from '../common/dialog/EzyDialog';
import IconByName from '../common/iconByName/IconByName';

function InfoModal({ img, title, subTitle, action, onClose, open }) {
  return (
    <EzyDialog handleClose={onClose} open={open} className="common-info-modal">
      <IconByName name="close" className="pointer-cursor close-icon" onClick={onClose} />
      <img src={img} alt="payrollEzy" className="info-modal-image" />
      <div className="title">{title}</div>
      <div className="subtitle-text">{subTitle}</div>
      <div className="action-text">{action}</div>
    </EzyDialog>
  );
}

export default InfoModal;

InfoModal.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  action: PropTypes.element,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

InfoModal.defaultProps = {
  action: null,
  onClose: () => {},
};
