import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';

import { useEffect } from 'react';
import Routes from './routes/Routes';
import store from './redux/store';
import muiTheme from './utils/muiTheme';
import { getAuthTokenFromLocalStorage } from './utils/localStorageHelper';
import ROUTE_CONSTANTS from './utils/constants';
import FCM from './components/firebase messaging/FCM';

function App() {
  useEffect(() => {
    window.addEventListener('storage', () => {
      const isToken = getAuthTokenFromLocalStorage();
      if (!isToken && !window.location.pathname?.includes('login')) {
        window.location.replace(ROUTE_CONSTANTS.LOGIN);
      }
    });

    return () => {
      window.removeEventListener('storage', () => {});
    };
  }, []);

  return (
    <Provider store={store}>
      <FCM>
        <ThemeProvider theme={muiTheme}>
          <Routes />
        </ThemeProvider>
      </FCM>
    </Provider>
  );
}

export default App;
