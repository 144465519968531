import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import EzyTable from '../../../../components/common/table/EzyTable';
import EzyPagination from '../../../../components/common/pagination/EzyPagination';
import { InvoiceHeaders } from '../../globalProfileHeaders';
import useUrlParamsUpdate from '../../../../hooks/useURLParamsUpdate';
import useQueryParams from '../../../../hooks/useQueryParams';
import { getAllInvoicesAction } from '../../redux/globalProfileActions';
import ViewInvoiceDetails from './ViewInvoiceDetails';

function Invoices() {
  const { invoiceList } = useSelector(({ globalProfile }) => globalProfile?.invoice || {});
  const [selectedInvoiceData, setSelectedInvoiceData] = useState(null);
  const { getAllInvoicesActionLoader, getInvoicesDataActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );
  const dispatch = useDispatch();
  const { paramId } = useParams();

  const { page: paramPage, limit: paramLimit } = useQueryParams();
  const { data: docs, total, pages, page, limit } = useMemo(() => invoiceList ?? {}, [invoiceList]);

  useUrlParamsUpdate({
    page: page ?? 1,
    limit: limit ?? 15,
  });

  const getInvoiceListByFilter = async (params = {}) => {
    const data = {
      page: page ?? 1,
      limit: limit ?? 15,
      businessId: paramId,
      ...params,
    };
    await dispatch(getAllInvoicesAction(data));
  };

  const pageActionClick = async (newPage) => {
    await getInvoiceListByFilter({ page: newPage, limit });
  };
  const onSelectLimit = async (newLimit) => {
    await getInvoiceListByFilter({ page: 1, limit: newLimit });
  };

  const tableActions = [
    {
      label: 'View',
      onClick: (data) => setSelectedInvoiceData(data),
    },
  ];

  useEffect(() => {
    (async () => {
      const params = {
        page: paramPage ?? page ?? 1,
        limit: paramLimit ?? limit ?? 15,
      };
      await getInvoiceListByFilter({ ...params });
    })();
    return () => {
      setSelectedInvoiceData(null);
    };
  }, []);

  return (
    <div className="invoice-root-container">
      <div className="p-x-24 invoice-table-wrapper">
        <Paper className="table-paper">
          <EzyTable
            headers={InvoiceHeaders}
            rows={docs}
            loading={getAllInvoicesActionLoader || getInvoicesDataActionLoader}
            onRowClick={(data) => setSelectedInvoiceData(data)}
            actions={tableActions}
            accessForRow={['default']}
            accessForAction={['default']}
          />
          <EzyPagination
            total={total}
            pages={pages}
            pageActionClick={pageActionClick}
            limit={limit}
            page={page}
            onChangeLimit={onSelectLimit}
          />
        </Paper>
      </div>
      <ViewInvoiceDetails
        data={selectedInvoiceData}
        handleClose={() => setSelectedInvoiceData(null)}
      />
    </div>
  );
}

export default Invoices;
