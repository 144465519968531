import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { isEmpty } from 'lodash';
import EzyDrawer from '../../../components/common/drawer/Drawer';
import { resetTimeSheetData } from '../redux/timeSheetsSlice';
import { getTimeSheetHistoryAction } from '../redux/timeSheetsAction';
import IconByName from '../../../components/common/iconByName/IconByName';
import { displayDateTime, displayTime } from '../../../utils/timeHelper';

function TimeSheetHistoryDrawer() {
  const dispatch = useDispatch();
  const [openIndex, setOpenIndex] = useState(null);
  const { timeSheetHistoryDrawer, historyData } = useSelector(
    ({ timeSheets }) => timeSheets?.timeSheetData
  );

  const { timeZoneId } = useSelector(
    ({ timeSheets }) => timeSheets?.sidePanelData?.selectedLocation ?? {}
  );

  const { getTimeSheetHistoryActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const { createdDate, createdBy, timeSheetHistoryData } = useMemo(
    () => historyData ?? {},
    [historyData]
  );

  const onClickDetail = (e) => {
    setOpenIndex((prev) => (prev === e ? null : e));
  };

  const handleClose = () => {
    dispatch(resetTimeSheetData());
    setOpenIndex(null);
  };

  const actionButton = [
    {
      label: 'Close',
      color: 'secondary',
      onClick: () => handleClose(),
    },
  ];

  useEffect(() => {
    if (timeSheetHistoryDrawer) {
      dispatch(getTimeSheetHistoryAction({ timesheetId: timeSheetHistoryDrawer }));
    }
  }, [timeSheetHistoryDrawer]);

  return (
    <EzyDrawer
      onClose={handleClose}
      isOpen={timeSheetHistoryDrawer}
      heading="Timesheet History"
      actionButtons={actionButton}
      isLoading={getTimeSheetHistoryActionLoader}
    >
      <div className="timesheet-history-drawer">
        <div className="timesheet-history-data-wrapper">
          <div className="data-wrapper">
            <div className="title">Created by &nbsp;</div>
            <div className="value">
              <span>{createdBy}</span>
              <span>{displayDateTime(createdDate, timeZoneId?.label)}</span>
            </div>
          </div>
        </div>
        <Timeline>
          {timeSheetHistoryData?.map((e, index) => (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className="timesheet-timeline-info" onClick={() => onClickDetail(index)}>
                  <div>{`${displayDateTime(e?.date, timeZoneId?.label)} | ${e?.user}`}</div>
                  <IconByName name={openIndex === index ? 'visibility_off' : 'visibility'} />
                </div>
                <div
                  className={
                    openIndex === index ? 'timesheet-history-data-wrapper' : 'wrap-timesheet'
                  }
                >
                  <div className="data-wrapper">
                    <div className="title">User &nbsp;</div>
                    <div className="value">{e?.user}</div>
                  </div>
                  <div className="data-wrapper">
                    <div className="title">Field &nbsp;</div>
                    <div className="value">{e?.field}</div>
                  </div>
                  <div className="data-wrapper">
                    <div className="title">Old value &nbsp;</div>
                    <div className="value">
                      {e?.oldValue &&
                        e?.oldValue?.map((val) => {
                          if (e?.field === 'Photo' && val) {
                            return <img src={val} alt="timesheetPhoto" />;
                          }
                          return <span>{val}</span>;
                        })}
                      {e?.oldDateTimeValue && (
                        <span>{displayDateTime(e?.oldDateTimeValue, timeZoneId?.label)}</span>
                      )}
                      {e?.oldBreakValue?.breakDetails?.length > 0 &&
                        e?.oldBreakValue?.breakDetails?.map((breakDetails) => {
                          return (
                            <>
                              <span>
                                {breakDetails?.breakType?.label}{' '}
                                {e?.oldBreakValue?.breakState && (
                                  <span> {`(${e?.oldBreakValue?.breakState?.label})`}</span>
                                )}
                              </span>
                              <span>
                                {breakDetails?.duration} mins
                                {(breakDetails?.startTime || breakDetails?.endTime) && (
                                  <span>
                                    &nbsp;| &nbsp;
                                    {displayTime(breakDetails?.startTime, timeZoneId?.label)}
                                    &nbsp;-&nbsp;
                                    {displayTime(breakDetails?.endTime, timeZoneId?.label)}
                                  </span>
                                )}
                              </span>
                            </>
                          );
                        })}
                      {!e?.oldBreakValue > 0 && !e?.oldDateTimeValue && isEmpty(e?.oldValue) && (
                        <span>-</span>
                      )}
                    </div>
                  </div>
                  <div className="data-wrapper">
                    <div className="title">New value &nbsp;</div>
                    <div className="value">
                      {e?.newValue &&
                        e?.newValue?.map((val) => {
                          if (e?.field === 'Photo') {
                            return <img src={val} alt="timesheetPhoto" />;
                          }
                          return <span>{val}</span>;
                        })}
                      {e?.newDateTimeValue && (
                        <span>{displayDateTime(e?.newDateTimeValue, timeZoneId?.label)}</span>
                      )}
                      {e?.newBreakValue?.breakDetails?.length > 0 &&
                        e?.newBreakValue?.breakDetails?.map((breakDetails) => {
                          return (
                            <>
                              <span>
                                {breakDetails?.breakType?.label}{' '}
                                {e?.newBreakValue?.breakState && (
                                  <span> {`(${e?.newBreakValue?.breakState?.label})`}</span>
                                )}
                              </span>
                              <span>
                                {breakDetails?.duration} mins
                                {(breakDetails?.startTime || breakDetails?.endTime) && (
                                  <span>
                                    &nbsp;| &nbsp;
                                    {displayTime(breakDetails?.startTime, timeZoneId?.label)}
                                    &nbsp;-&nbsp;
                                    {displayTime(breakDetails?.endTime, timeZoneId?.label)}
                                  </span>
                                )}
                              </span>
                            </>
                          );
                        })}
                      {!e?.newBreakValue > 0 && !e?.newDateTimeValue && isEmpty(e?.newValue) && (
                        <span>-</span>
                      )}
                    </div>
                  </div>
                  <div className="data-wrapper">
                    <div className="title">Date &nbsp;</div>
                    <div className="value">{displayDateTime(e?.date, timeZoneId?.label)}</div>
                  </div>
                </div>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </div>
    </EzyDrawer>
  );
}

export default TimeSheetHistoryDrawer;
