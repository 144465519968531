import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../components/common/loader/Loader';

function TimesheetSelectableTable({
  headers,
  rows,
  className,
  selectedRow,
  loading,
  onRowClick,
  timeZone,
  setTopHeight,
  topHeight,
}) {
  const topRef = createRef();

  React.useEffect(() => {
    if (!topHeight) {
      setTopHeight(topRef.current.clientHeight);
      return;
    }

    topRef.current.style.height = `${topHeight}px`;
  }, [topRef]);

  return (
    <TableContainer className={`selectable-table ${className}`} ref={topRef}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {headers
              ?.filter((e) => !e?.hidden)
              ?.map((column, index) => (
                <TableCell
                  key={index}
                  align={column?.align || 'left'}
                  style={{ minWidth: column?.minWidth }}
                >
                  {column?.label}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        {loading ? (
          <Loader />
        ) : (
          <TableBody>
            {' '}
            {rows?.length > 0 ? (
              rows?.map((row, index) => {
                return (
                  <TableRow
                    selected={selectedRow?.id === row?.id}
                    tabIndex={-1}
                    key={row?.id || index}
                    onClick={() => {
                      if (typeof onRowClick === 'function' && onRowClick) onRowClick(row);
                    }}
                    className={
                      onRowClick && typeof onRowClick === 'function' ? 'pointer-cursor' : ''
                    }
                  >
                    {headers
                      ?.filter((e) => !e?.hidden)
                      ?.map((column) => {
                        const value = row[column?.name];
                        return (
                          <TableCell
                            key={column?.name}
                            align={column?.align || 'left'}
                            title={column?.tooltip ? value : undefined}
                            className={
                              ['Unsubmitted', 'Auto-Closed'].includes(value)
                                ? 'error-color'
                                : undefined
                            }
                          >
                            {(column?.format
                              ? column?.format(value, row, timeZone || timeZone?.label)
                              : value) || '-'}
                          </TableCell>
                        );
                      })}
                  </TableRow>
                );
              })
            ) : (
              <Box className="no-data-found error-color">No records found</Box>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}

TimesheetSelectableTable.propTypes = {
  headers: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  onRowClick: PropTypes.func,
  selectedRow: PropTypes.object,
  timeZone: PropTypes.string,
  topHeight: PropTypes.any.isRequired,
  setTopHeight: PropTypes.func.isRequired,
};

TimesheetSelectableTable.defaultProps = {
  className: '',
  loading: false,
  onRowClick: null,
  selectedRow: {},
  timeZone: null,
};

export default React.memo(TimesheetSelectableTable);
