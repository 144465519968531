import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';

function EzySelect({ value, label, onChange, options, className, error, ...restProps }) {
  return (
    <FormControl variant="standard" className={`custom-select ${className}`}>
      {label && (
        <InputLabel shrink htmlFor="bootstrap-input">
          {label}
        </InputLabel>
      )}
      <Select
        displayEmpty
        inputProps={{
          'aria-label': 'Without label',
        }}
        className={error ? 'field-error' : ''}
        value={value}
        onChange={onChange}
        renderValue={(selected) => {
          if (typeof selected === 'object' && selected?.length > 0) {
            return selected?.join(', ');
          }
          return selected;
        }}
        {...restProps}
      >
        {options?.length &&
          options?.map((option) => (
            <MenuItem key={option?.label} value={option?.value}>
              {option?.label}
            </MenuItem>
          ))}
      </Select>
      {error && <FormHelperText className="error-helper">{error}</FormHelperText>}
    </FormControl>
  );
}

export default EzySelect;

EzySelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  error: PropTypes.bool,
  options: PropTypes.array,
};

EzySelect.defaultProps = {
  label: '',
  value: '',
  onChange: () => {},
  className: '',
  error: false,
  options: [],
};
