import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import EzyAutoComplete from '../../../../components/common/autoComplete/EzyAutoComplete';
import {
  getBillingAndPaymentDetailsAction,
  getOrderSummaryAction,
  getPaymentMethodsAction,
  getPlansAction,
} from '../../redux/globalProfileActions';
import {
  updateBillingAndPaymentDetails,
  updateSummaryDetails,
} from '../../redux/globalProfileSlice';
import useQueryParams from '../../../../hooks/useQueryParams';
import Loader from '../../../../components/common/loader/Loader';

function EditBillingDetails() {
  const { paramId } = useParams();
  const { planId } = useQueryParams();

  const dispatch = useDispatch();

  const { billingAndPaymentDetails, paymentMethods } = useSelector(
    ({ globalProfile }) => globalProfile || {}
  );
  const { country: countryList } = useSelector(({ common }) => common?.enums ?? {});

  const { getBillingAndPaymentDetailsActionLoader, getPaymentMethodsActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const { name, email, country, state, city, postCode, addressLine1, addressLine2, errors } =
    useMemo(() => billingAndPaymentDetails ?? {}, [billingAndPaymentDetails]);

  const handleFieldChange = (fieldName, value) => {
    dispatch(updateBillingAndPaymentDetails({ fieldName, value }));
  };

  const handleTextChange = (e) => {
    handleFieldChange(e?.target?.name, e?.target?.value);
  };

  useEffect(() => {
    (async () => {
      await dispatch(getBillingAndPaymentDetailsAction({ businessId: paramId }));
      await dispatch(getPaymentMethodsAction({ businessId: paramId }));

      if (planId) {
        await dispatch(
          getPlansAction({ businessId: paramId }, async (plans) => {
            if (plans?.length > 0 && planId) {
              const selectedPlan = plans?.find((e) => e?.planId?.toString() === planId);
              const planDuration = 1;
              dispatch(updateSummaryDetails({ ...selectedPlan, planDuration }));
              if (planDuration) {
                const data = {
                  businessId: paramId,
                  planId: Number(planId),
                  planDuration: Number(planDuration),
                  numberOfUser: planDuration === 2 ? 1 : undefined,
                };
                await dispatch(getOrderSummaryAction(data));
              }
            }
          })
        );
      }
    })();
  }, [paramId, planId]);

  return (
    <div className="bill-details-wrapper">
      {(getBillingAndPaymentDetailsActionLoader || getPaymentMethodsActionLoader) && <Loader />}
      <div className="title-with-primary-bg">Billing Details</div>
      <div className="billing-details-container">
        <EzyInputField
          name="name"
          placeholder="Enter name"
          label="Name"
          className="billing-name"
          value={name}
          onChange={handleTextChange}
          error={errors?.name}
          // disabled={isDisabled}
          required
        />
        <EzyInputField
          name="email"
          placeholder="Enter email address"
          label="Email"
          type="email"
          value={email}
          error={errors?.email}
          onChange={handleTextChange}
          // disabled={invitationStatus === 1 || isDisabled}
          required
        />
        <EzyInputField
          name="addressLine1"
          placeholder="Enter address"
          label="Address line 1"
          value={addressLine1}
          onChange={handleTextChange}
          error={errors?.addressLine1}
          required
        />
        <EzyInputField
          name="addressLine2"
          placeholder="Enter address"
          label="Address line 2"
          value={addressLine2}
          onChange={handleTextChange}
        />
        <EzyInputField
          name="postCode"
          placeholder="Enter post code"
          label="Post code"
          value={postCode}
          error={errors?.postCode}
          onChange={handleTextChange}
          inputProps={{
            maxLength: 10,
          }}
          required
          // disabled={isDisabled}
        />
        <EzyInputField
          name="city"
          placeholder="Enter city"
          label="City"
          value={city}
          onChange={handleTextChange}
          error={errors?.city}
          required
        />
        <EzyAutoComplete
          placeholder="Select country"
          label="Country"
          name="country"
          options={countryList}
          value={country}
          onChange={(_, value) => handleFieldChange('country', value)}
          error={errors?.country}
          required
        />
        <EzyInputField
          name="state"
          placeholder="Enter state"
          label="State"
          value={state}
          onChange={handleTextChange}
          error={errors?.state}
          required
        />
      </div>
      <div className="title-with-primary-bg">Payment Details</div>
      <div className="card-details-container">
        {paymentMethods?.length > 0 ? (
          paymentMethods?.map(({ cardNumber, expYear, expMonth }, index) => (
            <div className="current-card" key={index}>
              <div>Card Number :&nbsp;{cardNumber ? `**** **** **** ${cardNumber}` : '-'}</div>
              <div>
                Expiry Date :&nbsp;{expMonth || '-'} / {expYear || '-'}
              </div>
            </div>
          ))
        ) : (
          <div className="current-card">No payment details available.</div>
        )}
      </div>
    </div>
  );
}

export default EditBillingDetails;
