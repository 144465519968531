import { Paper } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import BorderedHeader from '../../components/common/headers/BorderedHeader';
import whiteImage from '../../assets/images/New_to_PayrollEzy_background.png';
import RegisterForm from './RegisterForm';
import { joinYourTeam, registerNewBusiness } from './redux/businessAction';
import { formatPhoneToSend } from '../../utils/phoneNumberHelper';
import validateNewBusinessData from './validations/validateNewBusiness';
import {
  resetAddNewBusinessData,
  resetInviteUserData,
  setInviteToBusinessField,
  setNewBusinessField,
} from './redux/businessSlice';
// import ROUTE_CONSTANTS from '../../utils/constants';
import validateInviteToBusiness from './validations/validateInviteToBusiness';
// import Loader from '../../components/common/loader/Loader';
import { getCurrentUserDataAction } from '../auth/redux/userAction';
import InfoModal from '../../components/infoModal/InfoModal';
import checkYourEmail from '../../assets/images/Check your email.png';
import { mapLabelFromList } from '../../utils/enumMapping';

function RegisterNewBusiness() {
  const newBusinessData = useSelector(({ business }) => business ?? {});
  const { personId } = useSelector(({ user }) => user?.profileData ?? {});
  const [openSuccessInvitationModal, setOpenSuccessInvitationModal] = useState(false);

  const { timeZonesList } = useSelector(({ common }) => common ?? {});

  const {
    businessIndustryTypeId,
    businessName,
    countryId,
    countryCode,
    locations,
    // email,
    numberOfPeople,
    phoneNumber,
    inviteToBusiness,
    phoneNumberData,
  } = useMemo(() => newBusinessData, [newBusinessData]);

  // const { getCurrentUserDataLoader } = useSelector(({ common }) => common.generalLoader ?? {});

  const dispatch = useDispatch();
  const history = useHistory();

  const addNewBusiness = async () => {
    const timezone = mapLabelFromList(moment.tz.guess(), timeZonesList);
    const data = {
      businessName,
      countryId: countryId?.value,
      businessIndustryTypeId: businessIndustryTypeId?.value,
      numberOfPeople: numberOfPeople?.value,
      phoneNumber: formatPhoneToSend(phoneNumber, countryCode),
      countryCode: phoneNumber ? countryCode : undefined,
      startTime: moment
        .tz(moment(new Date())?.toISOString(), moment.tz.guess())
        .set({ hours: 9, minutes: 0 })
        ?.toISOString(),
      endTime: moment
        .tz(moment(new Date())?.toISOString(), moment.tz.guess())
        .set({ hours: 17, minutes: 0 })
        ?.toISOString(),
      timeZoneId: timezone?.id,
      locations,
    };
    const errors = validateNewBusinessData({ ...data, phoneNumberData, phoneNumber });

    dispatch(setNewBusinessField({ fieldName: 'errors', value: errors }));
    if (isEmpty(errors)) {
      const isSuccess = await dispatch(registerNewBusiness(data));
      if (isSuccess) {
        dispatch(
          getCurrentUserDataAction(
            () => {
              dispatch(resetAddNewBusinessData());
              history.push('/');
            },
            { isFromSwitchBusiness: true }
          )
        );
      }
    }
  };

  const inviteToBusinessSubmit = async () => {
    const data = {
      name: inviteToBusiness?.name,
      email: inviteToBusiness?.email,
    };
    const errors = validateInviteToBusiness(data);
    dispatch(setInviteToBusinessField({ fieldName: 'errors', value: errors }));
    if (isEmpty(errors)) {
      const isSuccess = await dispatch(joinYourTeam(data));
      if (isSuccess) {
        setOpenSuccessInvitationModal(true);
      }
    }
  };

  useEffect(() => {
    if (!personId) {
      (async () => {
        await dispatch(getCurrentUserDataAction());
      })();
    }
  }, [personId]);

  return (
    <div className="h-full w-full">
      <BorderedHeader isLogout />
      {/* {getCurrentUserDataLoader ? (
        <Loader />
      ) : ( */}
      <div className="r-form-wrapper">
        <Paper>
          <RegisterForm businessInvite={inviteToBusinessSubmit} addBusiness={addNewBusiness} />
          <img src={whiteImage} className="right-wrapper" alt="payrollEzy" />
        </Paper>
      </div>

      <InfoModal
        img={checkYourEmail}
        subTitle={` We have notified your organization manager to add you in their business. `}
        title="Request sent successfully!"
        open={openSuccessInvitationModal}
        onClose={() => {
          dispatch(resetInviteUserData());
          setOpenSuccessInvitationModal(false);
        }}
      />
    </div>
  );
}

export default RegisterNewBusiness;
