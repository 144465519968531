import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment } from '@mui/material';
import { useSelector } from 'react-redux';
import EzyAvatar from '../../../components/common/avatar/EzyAvatar';
import { displayDate, displayDateTime } from '../../../utils/timeHelper';
import EzyIconButton from '../../../components/common/iconButton/EzyIconButton';
import EzyInputField from '../../../components/common/inputField/EzyInputField';

function LogEntry({
  data,
  logCategory,
  handleDeleteLog,
  handleAddLogComment,
  handleDeleteComment,
}) {
  const [comment, setComment] = useState('');

  const { roleId, personId, timeZone } = useSelector(({ user }) => user?.profileData || {});

  return (
    <div className="entry-container" key={data?.logBookEntryId}>
      <EzyAvatar firstName={data?.createdByName} imageLink={data?.profilePhotoURl} />
      <div className="entry-comment-container">
        <div className="entry">
          <div className="d-flex-column w-full">
            <div className="d-flex just-bet">
              <div className="d-flex flex-align-center gap-5">
                {data?.createdByName}
                <div className="separator-circle"> </div>
                <span className="text-secondary-color body-text">
                  {displayDateTime(data?.createdDate, timeZone?.label)}
                </span>
              </div>

              {data?.categoryName && (
                <div className="entryList-date-title">
                  {data?.categoryName} of {displayDate(data?.logDate)}
                </div>
              )}
            </div>

            {data?.fileName && (
              <span
                className="see-more-text"
                onClick={() => window.open(data?.filePathUrl, '_blank')}
              >
                {data?.fileName}
              </span>
            )}

            <span className="text-secondary-color body-text word-break">
              {(data?.categoryType?.id === 3 || logCategory === 3) && '$'}
              {data?.logBookEntryData}
              {(data?.categoryType?.id === 4 || logCategory === 4) && '%'}
            </span>
          </div>

          {handleDeleteLog &&
            (roleId === 1
              ? true
              : roleId < data?.createdByRoleId || personId === data?.createdBy) && (
              <span
                className="material-icons pointer-cursor error-color"
                onClick={() => handleDeleteLog(data?.logBookEntryId)}
              >
                delete
              </span>
            )}
        </div>
        {data?.logBookComments?.length > 0 &&
          data?.logBookComments?.map((cmt) => (
            <div key={cmt?.commentId} className="flex-center gap-10">
              <EzyAvatar firstName={cmt?.createdByName} imageLink={cmt?.profilePhotoURl} />
              <div className="entry">
                <div className="d-flex-column ">
                  <div className="d-flex flex-align-center gap-5">
                    {cmt?.createdByName}
                    <div className="separator-circle"> </div>
                    <span className="text-secondary-color body-text">
                      {displayDateTime(cmt?.createdDate, timeZone?.label)}
                    </span>
                  </div>
                  <span className="text-secondary-color body-text word-break">
                    {cmt?.commentData}
                  </span>
                </div>

                {handleDeleteComment &&
                  (roleId === 1
                    ? true
                    : roleId < cmt?.createdByRoleId || personId === cmt?.createdBy) && (
                    <span
                      className="material-icons pointer-cursor error-color"
                      onClick={() => {
                        handleDeleteComment(data?.logBookEntryId, cmt?.commentId);
                      }}
                    >
                      delete
                    </span>
                  )}
              </div>
            </div>
          ))}

        <EzyInputField
          key={data?.logBookEntryId}
          placeholder="Write a comment..."
          value={comment}
          onChange={(e) => setComment(e?.target?.value)}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              await handleAddLogComment(data?.logBookEntryId, comment, setComment);
            }
          }}
          endAdornment={
            <InputAdornment position="end">
              <EzyIconButton
                iconName="send"
                disabled={!comment}
                onClick={() => handleAddLogComment(data?.logBookEntryId, comment, setComment)}
              />
            </InputAdornment>
          }
        />
      </div>
    </div>
  );
}

LogEntry.propTypes = {
  data: PropTypes.object.isRequired,
  logCategory: PropTypes.number,
  handleDeleteLog: PropTypes.func,
  handleAddLogComment: PropTypes.func,
  handleDeleteComment: PropTypes.func,
};

LogEntry.defaultProps = {
  logCategory: 1,
  handleDeleteLog: null,
  handleAddLogComment: null,
  handleDeleteComment: null,
};

export default LogEntry;
