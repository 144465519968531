import { filter, isEmpty, isNil, omitBy } from 'lodash';
import moment from 'moment-timezone';
import { requireValidate } from '../../../utils/validationHelper';
import { getDurationInMinutes } from '../../../utils/timeHelper';
// import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from '../../../utils/constants';

const validateAddTimeSheet = (data, timeZone, timesheetStatus, allowancesData) => {
  const {
    personDetailId,
    areaId,
    startTime,
    endTime,
    totalMealBreak,
    totalRestBreak,
    timeSheetBreakDetailEntities,
    journalEntry,
  } = data ?? {};
  const error = {};
  error.personDetailId = requireValidate('Person details', personDetailId);
  error.areaId = timesheetStatus === 'edit' ? requireValidate('Area', areaId) : null;
  error.startTime = requireValidate('Start time', startTime);
  error.endTime = requireValidate('End time', endTime);
  error.totalRestBreak = requireValidate('Rest break', totalRestBreak);
  error.totalMealBreak = requireValidate('Meal break', totalMealBreak);
  if (startTime && endTime && !moment(endTime).isAfter(startTime)) {
    error.endTime = `End time can't be smaller then start time`;
  } else if (startTime && endTime && moment(startTime).isAfter(moment())) {
    error.startTime = `Start time can't be future time`;
  } else if (startTime && endTime && moment(endTime).isAfter(moment())) {
    error.endTime = `End time can't be future time`;
  } else if (startTime && endTime) {
    const availableDuration = getDurationInMinutes(startTime, endTime);

    if (availableDuration > 1380) {
      error.startTime = `Timesheet duration can't be greater than 23 hours.`;
      error.endTime = `Timesheet duration can't be greater than 23 hours.`;
    }

    if (totalMealBreak + totalRestBreak > availableDuration) {
      error.totalMealBreak = `Break time can't be greater then shift time`;
      error.totalRestBreak = true;
    }
  }

  error.timeSheetBreakDetailEntities = timeSheetBreakDetailEntities?.map((e, index) => {
    const errors = {};

    if (e?.duration && e?.duration >= 0)
      errors.breakType = requireValidate('Break type', e?.breakType);

    if (e?.isCustom) {
      if (!moment(e?.breakStartTime).isValid() || !moment(e?.breakEndTime).isValid()) {
        if (!moment(e?.breakStartTime).isValid())
          errors.breakStartTime = `Break start time is required.`;

        if (!moment(e?.breakEndTime).isValid()) errors.breakEndTime = `Break end time is required.`;
      } else {
        if (
          moment(e?.breakStartTime).isValid() &&
          !moment(e?.breakStartTime).isBetween(startTime, endTime)
        )
          errors.breakStartTime = `Break start time is out of shift range.`;

        if (
          moment(e?.breakEndTime).isValid() &&
          !moment(e?.breakEndTime).isBetween(startTime, endTime)
        )
          errors.breakEndTime = `Break end time is out of shift range.`;

        if (
          moment(e?.breakEndTime).isValid() &&
          moment(e?.breakStartTime).isValid() &&
          moment(e?.breakEndTime).isBefore(e?.breakStartTime)
        )
          errors.breakEndTime = `Invalid break end time.`;
      }

      for (let j = 0; j < index; j += 1) {
        const previousStartTime = timeSheetBreakDetailEntities[j].breakStartTime;
        const previousEndTime = timeSheetBreakDetailEntities[j].breakEndTime;

        if (
          moment(e?.breakStartTime).isBetween(previousStartTime, previousEndTime) ||
          moment(e?.breakStartTime).isSame(previousStartTime)
        ) {
          errors.breakStartTime = `Start time overlaps with another break.`;
        }
        if (
          moment(e?.breakEndTime).isBetween(previousStartTime, previousEndTime) ||
          moment(e?.breakEndTime).isSame(previousEndTime)
        ) {
          errors.breakEndTime = `End time overlaps with another break.`;
        }
      }
    }

    return omitBy(errors, isNil);
  });

  if (data?.ratePerformance) {
    error.journalEntry = requireValidate('Journal Entry', journalEntry);
  }
  error.timeSheetBreakDetailEntities = filter(
    error.timeSheetBreakDetailEntities,
    (e) => !isEmpty(e)
  )?.length
    ? error.timeSheetBreakDetailEntities
    : null;

  error.timeSheetAllowances = allowancesData?.map((e) =>
    omitBy(
      {
        baseRatePayString: e?.isBaseRate ? requireValidate('Base rate', e?.payrateString) : null,
        shiftLoadingPayString: !e?.isBaseRate
          ? requireValidate('Shift loadings', e?.payrateString)
          : null,
        baseRateWorkingHour: e?.isBaseRate && e?.workingHour <= 0 ? 'Enter valid hour!' : null,
        shiftLoadingWorkingHour: !e?.isBaseRate && e?.workingHour <= 0 ? 'Enter valid hour!' : null,
      },
      isNil
    )
  );
  error.timeSheetAllowances = filter(error.timeSheetAllowances, (e) => !isEmpty(e))?.length
    ? error.timeSheetAllowances
    : null;

  return omitBy(error, isNil);
};

export default validateAddTimeSheet;
