import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLocationDropDownListByBusinessId } from '../../location/redux/locationActions';
import IconByName from '../../../components/common/iconByName/IconByName';
import Loader from '../../../components/common/loader/Loader';
import { clearNewsFeedList, updateNewsFeedListDetails } from '../redux/newsFeedSlice';

function NewsFeedSidebar({ getNewsFeedList }) {
  const dispatch = useDispatch();
  const { locationListEnum } = useSelector(({ location }) => location ?? []);
  const { selectedLocation, postType } = useSelector(
    ({ newsFeed }) => newsFeed?.newsFeedList ?? {}
  );

  const { getLocationsByBusinessIdLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const postFilters = [
    {
      id: 1,
      icon: 'view_day',
      label: 'All posts',
      onClick: async () => {
        dispatch(updateNewsFeedListDetails({ fieldName: 'postType', value: 'All posts' }));
        dispatch(clearNewsFeedList());
        await getNewsFeedList({
          isYourPost: false,
          isImportantPost: false,
        });
      },
    },
    {
      id: 2,
      icon: 'add_comment',
      label: 'Important posts',
      onClick: async () => {
        dispatch(updateNewsFeedListDetails({ fieldName: 'postType', value: 'Important posts' }));
        dispatch(clearNewsFeedList());
        await getNewsFeedList({
          isImportantPost: true,
          isYourPost: false,
        });
      },
    },
    {
      id: 3,
      icon: 'contacts',
      label: 'Your posts',
      onClick: async () => {
        dispatch(updateNewsFeedListDetails({ fieldName: 'postType', value: 'Your posts' }));
        dispatch(clearNewsFeedList());
        await getNewsFeedList({
          isYourPost: true,
          isImportantPost: false,
        });
      },
    },
  ];

  const handleLocationClick = (locationId) => {
    if (locationId !== selectedLocation) {
      dispatch(updateNewsFeedListDetails({ fieldName: 'selectedLocation', value: locationId }));
      dispatch(clearNewsFeedList());
      getNewsFeedList({ locationId });
    } else {
      dispatch(updateNewsFeedListDetails({ fieldName: 'selectedLocation', value: '' }));
      dispatch(clearNewsFeedList());
      getNewsFeedList({ locationId: null });
    }
  };

  useEffect(() => {
    dispatch(getLocationDropDownListByBusinessId());
  }, []);

  return (
    <div className="newsFeed-list-sidebar">
      <div className="newsFeed-filter-container">
        {postFilters.map((filter) => (
          <div
            key={filter?.id}
            onClick={filter?.onClick}
            className={`filters ${filter?.label === postType ? 'selected-filter' : ''}`}
          >
            <span className="material-icons">{filter?.icon}</span>
            <span className="text-overflow-ellipsis">{filter?.label}</span>
          </div>
        ))}
      </div>

      <Paper className="newsFeed-side-card-wrapper">
        <div className="title-text">Locations</div>
        {getLocationsByBusinessIdLoader && <Loader />}
        <List className="area-check-list">
          {locationListEnum?.map((value) => {
            const labelId = `checkbox-list-label-${value?.id}`;

            return (
              <ListItem key={value?.id} selected={selectedLocation === value?.id} disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={() => handleLocationClick(value?.id)}
                  dense
                  disableRipple
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selectedLocation === value?.id}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                      icon={<IconByName name="check_circle_outline" />}
                      checkedIcon={<IconByName name="check_circle" className="primary-color" />}
                    />
                  </ListItemIcon>
                  <ListItemText id={value?.id} className="publish-checks-view" title={value?.label}>
                    <span>{value?.label}</span>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Paper>
    </div>
  );
}

NewsFeedSidebar.propTypes = {
  getNewsFeedList: PropTypes.func.isRequired,
};

export default NewsFeedSidebar;
