import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  registerables,
} from 'chart.js';
import { useSelector } from 'react-redux';
// import _ from 'lodash';
import { displayDateTime } from '../../../utils/timeHelper';
import { graphDataList } from './EditInsightGraphMenu';
// import PropTypes from 'prop-types'

function InsightGraph() {
  const { graphData } = useSelector(({ schedule }) => schedule?.statsPanelData ?? {});

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    ...registerables
  );

  const list = graphDataList();

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    let tooltipEl = document.getElementById('chartjs-tooltip');

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      tooltipEl.innerHTML = '<div class="d-flex-column gap-10 main-div"></div>';
      document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    function getBody(bodyItem) {
      return bodyItem.lines;
    }

    // Set Text
    if (tooltipModel.body) {
      const titleLines = tooltipModel.title || [];
      const bodyLines = tooltipModel.body.map(getBody);

      let innerHtml = '';

      titleLines.forEach((title) => {
        innerHtml += `<div class="subtitle-text white-color">${title}</div>`;
      });

      list.forEach((item) => {
        if (item?.showStatistic) {
          const mainDiv = ` <div class="d-flex-column">
          <span class="subtitle-text white-color">${item?.statisticName}</span>`;

          innerHtml += mainDiv;

          item?.subList?.forEach((subItem) => {
            const subDivValue =
              bodyLines?.find(
                (e) =>
                  e[0]?.label?.includes(item?.statisticName) &&
                  e?.[0]?.label?.includes(subItem?.label)
              )?.[0]?.value || 0;

            const subDiv = `<div class="d-flex just-bet caption-text">
            <div class="flex-center gap-5 ">
              <div class="description-color ${subItem?.className}"   style="
                border-color: ${item?.metricColour};
                background-color: ${subItem?.label === 'Actual' && item?.metricColour};
              "> </div> ${subItem?.label}
            </div>
            ${
              ['Staff']?.includes(item?.statisticName)
                ? subDivValue
                : `$ ${subDivValue?.toFixed(2)}`
            }
            </div>`;

            innerHtml += subDiv;
          });

          innerHtml += `</div>`;
        }
      });

      const root = tooltipEl.querySelector('.main-div');
      root.innerHTML = innerHtml;
    }

    const position = context.chart.canvas.getBoundingClientRect();

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`;
    tooltipEl.style.top = `${position.top + window.pageYOffset}px`;
    tooltipEl.style.padding = `${tooltipModel.padding}px ${tooltipModel.padding}px`;
    tooltipEl.style.pointerEvents = 'none';
  };

  Tooltip.positioners.myCustomPositioner = (elements, position) => {
    if (!elements.length) {
      return false;
    }
    let offset = 0;

    // adjust the offset left or right depending on the event position
    // eslint-disable-next-line no-underscore-dangle
    if (elements[0].index > 140) {
      offset = -165;
    } else {
      offset = 5;
    }
    return {
      x: position.x + offset,
      y: position.y,
    };
  };

  const graphOptions = {
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    skipNull: true,
    scales: {
      x: {
        display: false,
        beginAtZero: true,
      },
      y: {
        display: false,
        beginAtZero: true,
      },
      'y-axis-1': {
        // offset: true,
        position: 'left',
        display: false,
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },

      tooltip: {
        intersect: false,
        enabled: false,
        external: externalTooltipHandler,
        callbacks: {
          label: (tooltipItem) => {
            return {
              label: tooltipItem?.dataset?.label,
              value: tooltipItem?.raw,
            };
          },
        },
        position: 'myCustomPositioner',
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    spanGaps: false,
    interaction: {
      intersect: false,
      mode: 'index',
    },
  };

  const requiredStaffData = graphData?.graphDataList
    ?.map((x) => x?.graphStaffData?.staffHourlyStatistics?.map((y) => y?.requiredStaff || null))
    .flat();

  const dataSets = [
    // graphs for staff data
    {
      type: 'line',
      label: 'Staff - Required staff',
      data: requiredStaffData,
      backgroundColor: '#1f77bc',
      borderColor: '#1f77bc',
      borderWidth: 2,
      pointRadius: 2,
      pointBorderColor: requiredStaffData?.map((dataPoint, i) =>
        requiredStaffData?.[i + 1] || requiredStaffData?.[i - 1] ? 'transparent' : '#1f77bc'
      ),
      pointBackgroundColor: requiredStaffData?.map((dataPoint, i) =>
        requiredStaffData?.[i + 1] || requiredStaffData?.[i - 1] ? 'transparent' : '#fff'
      ),
      pointBorderWidth: 1,
      yAxisID: 'y-axis-1',
      order: 1,
    },
    {
      type: 'line',
      label: 'Staff - All shift',
      data: graphData?.graphDataList
        ?.map((x) => x?.graphStaffData?.staffHourlyStatistics?.map((y) => y?.allShift || null))
        .flat(),
      pointRadius: 0,
      borderWidth: 2,
      backgroundColor: '#d4f0fb',
      borderColor: '#d4f0fb',
      fill: true,
      yAxisID: 'y-axis-1',
      order: 6,
    },
    {
      type: 'line',
      label: 'Staff - Filled shift',
      data: graphData?.graphDataList
        ?.map((x) => x?.graphStaffData?.staffHourlyStatistics?.map((y) => y?.filledShift || null))
        .flat(),
      pointRadius: 0,
      borderWidth: 2,
      backgroundColor: '#eaeced',
      borderColor: '#eaeced',
      fill: true,
      yAxisID: 'y-axis-1',
      order: 7,
    },
    // graph for wages data
    {
      type: 'line',
      label: 'Wages - Scheduled wages',
      data: graphData?.graphDataList
        ?.map((x) =>
          x?.graphWagesData?.wagesHourlyStatistics?.map((y) => y?.scheduledWages || null)
        )
        .flat(),
      pointRadius: 0,
      borderWidth: 2,
      backgroundColor: '#079e88',
      borderColor: '#079e88',
      order: 2,
    },
  ];

  graphData?.graphDataList?.[0]?.graphMetricData?.map((e) => {
    const metricGraphColor =
      e?.metricName === 'Sales'
        ? '#bc5090'
        : graphData?.graphSettingList?.find((x) => x?.statisticName === e?.metricName)
            ?.metricColour;

    const graphActualData = graphData?.graphDataList
      ?.map((x) =>
        x?.graphMetricData
          ?.find((y) => e?.metricName === y?.metricName)
          ?.metricHourlyStatistics?.map((x2) => x2?.actual || null)
      )
      .flat();

    const graphForecastData = graphData?.graphDataList
      ?.map((x) =>
        x?.graphMetricData
          ?.find((y) => e?.metricName === y?.metricName)
          ?.metricHourlyStatistics?.map((x2) => x2?.payrollEzyForecast || null)
      )
      .flat();

    const graphManagerForecastData = graphData?.graphDataList
      ?.map((x) =>
        x?.graphMetricData
          ?.find((y) => e?.metricName === y?.metricName)
          ?.metricHourlyStatistics?.map((x2) => x2?.managerForecast || null)
      )
      .flat();

    dataSets.push(
      // for metric actuals
      {
        type: 'line',
        label: `${e?.metricName} - Actual`,
        borderWidth: 2,
        pointBorderColor: graphActualData?.map((dataPoint, i) =>
          graphActualData?.[i + 1] || graphActualData?.[i - 1] ? 'transparent' : metricGraphColor
        ),
        pointBackgroundColor: graphActualData?.map((dataPoint, i) =>
          graphActualData?.[i + 1] || graphActualData?.[i - 1] ? 'transparent' : '#fff'
        ),
        pointRadius: 2,
        backgroundColor: metricGraphColor,
        borderColor: metricGraphColor,
        data: graphActualData,
        pointBorderWidth: 1,
        order: 3,
      },
      // for metric forecast
      {
        type: 'line',
        label: `${e?.metricName} - Forecast`,
        borderWidth: 2,
        pointBorderColor: graphForecastData?.map((dataPoint, i) =>
          graphForecastData?.[i + 1] || graphForecastData?.[i - 1]
            ? 'transparent'
            : metricGraphColor
        ),
        pointBackgroundColor: graphForecastData?.map((dataPoint, i) =>
          graphForecastData?.[i + 1] || graphForecastData?.[i - 1] ? 'transparent' : '#fff'
        ),
        pointRadius: 2,
        backgroundColor: metricGraphColor,
        borderColor: metricGraphColor,
        borderDash: [7, 5],
        data: graphForecastData,
        pointBorderWidth: 1,
        order: 4,
      },
      // for metric manager forecast
      {
        type: 'line',
        label: `${e?.metricName} - Manager forecast`,
        borderWidth: 2,
        pointBorderColor: graphManagerForecastData?.map((dataPoint, i) =>
          graphManagerForecastData?.[i + 1] || graphManagerForecastData?.[i - 1]
            ? 'transparent'
            : metricGraphColor
        ),
        pointBackgroundColor: graphManagerForecastData?.map((dataPoint, i) =>
          graphManagerForecastData?.[i + 1] || graphManagerForecastData?.[i - 1]
            ? 'transparent'
            : '#fff'
        ),
        pointRadius: 2,
        backgroundColor: metricGraphColor,
        borderColor: metricGraphColor,
        borderDash: [3, 5],
        data: graphManagerForecastData,
        pointBorderWidth: 1,
        order: 5,
      }
    );
    return e;
  });

  const data = {
    datasets: dataSets,
    labels: graphData?.graphDataList
      ?.map((x) =>
        x?.graphMetricData?.[0]?.metricHourlyStatistics?.map((y) =>
          displayDateTime(y?.dateTime, 'UTC')
        )
      )
      .flat(),
  };

  return (
    <div>
      <Line
        data={data}
        options={graphOptions}
        plugins={[
          {
            id: 'cursorLine',
            afterDraw: (chart) => {
              // eslint-disable-next-line no-underscore-dangle
              if (chart.tooltip?._active?.length) {
                // eslint-disable-next-line no-underscore-dangle
                const x = chart.tooltip._active[0].element.x;
                const yAxis = chart.scales.y;
                const ctx = chart.ctx;
                ctx.save();
                ctx.beginPath();
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 0.5;
                ctx.strokeStyle = 'black';
                ctx.stroke();
                ctx.restore();
              }
            },
          },
        ]}
      />
    </div>
  );
}

InsightGraph.propTypes = {};

export default InsightGraph;
