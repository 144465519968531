import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Chip } from '@mui/material';
import moment from 'moment';
import { isEmpty } from 'lodash';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import EzySwitch from '../../../../components/common/switch/EzySwitch';
import EzyTime from '../../../../components/common/dateAndTimePicker/EzyTime';
import EzyDate from '../../../../components/common/dateAndTimePicker/EzyDate';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import EzyAutoComplete from '../../../../components/common/autoComplete/EzyAutoComplete';
import { concatDateAndTime } from '../../../../utils/timeHelper';
import {
  resetAddUnavailabilityDetails,
  updateAddUnavailabilityDetails,
} from '../../redux/peopleSlice';
import { addUnavailabilityAction } from '../../redux/peopleActions';
import addUnavailabilityValidation from '../../validations/addUnavailabilityValidations';

function AddUnavailabilityDrawer({ isFromProfile, listCallback }) {
  const { addUnavailabilityDetails } = useSelector(
    ({ people }) => people?.unavailabilityDetails ?? {}
  );
  const { peopleId } = useParams();
  const { timeZoneId: timeZoneNotFromProfile } = useSelector(
    ({ people }) => people?.leaveDetails ?? {}
  );

  const {
    unavailabilityFrequency: unavailabilityFrequencyEnum,
    weekDays: weekDaysEnum,
    datesOfMonthList: datesOfMonthListEnum,
  } = useSelector(({ common }) => common?.enums ?? {});
  const { addUnavailabilityActionLoader } = useSelector(({ common }) => common.generalLoader ?? {});

  const dispatch = useDispatch();
  const {
    isDrawerOpen,
    isAllDay,
    isRepeat,
    repeatitionFrequency,
    repeatWeekWise,
    repeatMonthly,
    endOn,
    fromDate,
    fromTime,
    toDate,
    toTime,
    endOnDate,
    note,
    errors,
  } = useMemo(() => addUnavailabilityDetails, [addUnavailabilityDetails]);
  const { personId: loggedInPersonId, timeZone: timeZoneFromProfile } = useSelector(
    ({ user }) => user?.profileData ?? {}
  );
  const timeZone = isFromProfile ? timeZoneFromProfile : timeZoneNotFromProfile;

  const onChangeFields = (fieldName, value) => {
    dispatch(updateAddUnavailabilityDetails({ fieldName, value }));
  };

  function weekOfMonth(m) {
    const dayOfMonthStart = moment(m).startOf('month').day();
    const dayOfFromDate = moment(m).day();
    if (dayOfMonthStart > dayOfFromDate) {
      return m.week() - moment(m).startOf('month').week();
    }
    return m.week() - moment(m).startOf('month').week() + 1;
  }

  // Function for option list for Month
  const onList = () => {
    const monthDateNumber = datesOfMonthListEnum
      .filter((e) => e.id === moment.tz(fromDate, timeZone?.label).date())
      .map((e) => e.label)
      .join();
    const monthlyOptionList = [
      {
        id: 1,
        label: `On the ${monthDateNumber}`,
      },
    ];
    if (repeatitionFrequency && repeatitionFrequency.label === 'Monthly') {
      let monthDayNumber = weekOfMonth(moment.tz(fromDate, timeZone?.label));

      switch (monthDayNumber) {
        case 1:
          monthDayNumber = `${monthDayNumber}st`;
          break;
        case 2:
          monthDayNumber = `${monthDayNumber}nd`;
          break;
        case 3:
          monthDayNumber = `${monthDayNumber}rd`;
          break;
        case 4:
          monthDayNumber = `${monthDayNumber}th`;
          break;
        default:
          break;
      }
      if (monthDayNumber !== 5) {
        monthlyOptionList.push({
          id: 2,
          label: `On the ${monthDayNumber}  ${moment.tz(fromDate, timeZone?.label).format('dddd')}`,
        });
      }
    }
    return monthlyOptionList.map((e) => e.label);
  };

  const handleClose = () => {
    dispatch(resetAddUnavailabilityDetails());
  };

  const endFilter = [
    {
      id: 1,
      label: 'Never',
    },
    {
      id: 1,
      label: 'On a Date',
    },
  ];

  const handleSubmit = async () => {
    const data = {
      personDetailId: isFromProfile ? loggedInPersonId : peopleId,
      fromDate: fromDate && moment.tz(fromDate, timeZone?.label).toISOString(),
      toDate: toDate && moment.tz(toDate, timeZone?.label).endOf('day').toISOString(),
      fromTime:
        !isAllDay && fromTime ? concatDateAndTime(fromDate, fromTime, timeZone?.label) : null,
      toTime:
        !isAllDay && toTime && toDate ? concatDateAndTime(toDate, toTime, timeZone?.label) : null,
      note,
      isAllDay,
      timeZoneId: timeZone?.id ?? null,
      isFromProfile,
    };

    if (moment(fromDate).isSame(toDate) && isRepeat) {
      data.repeatitionFrequency = repeatitionFrequency.id;
      data.repeatOnDays = repeatitionFrequency.id !== 4 ? repeatWeekWise : [repeatMonthly];
      data.endOn = endOn !== 'Never' ?? false;
      if (endOn === 'On a Date') {
        data.toDate = endOnDate
          ? moment.tz(endOnDate, timeZone?.label).endOf('day').toISOString()
          : null;
        data.toTime =
          !isAllDay && endOnDate && toTime
            ? concatDateAndTime(endOnDate, toTime, timeZone?.label)
            : null;
      }
    }

    const error = addUnavailabilityValidation({
      ...data,
      toDate,
      endOnDate,
      isRepeat,
      endOn,
    });

    onChangeFields('errors', error);
    if (isEmpty(error)) {
      await dispatch(
        addUnavailabilityAction(data, () => {
          handleClose();
          listCallback();
        })
      );
    }
  };
  const actionButton = [
    {
      label: 'Save',
      onClick: handleSubmit,
      loading: addUnavailabilityActionLoader,
    },
  ];

  return (
    <EzyDrawer
      heading="Add Unavailability"
      isOpen={isDrawerOpen}
      actionButtons={actionButton}
      onClose={handleClose}
    >
      <div className="add-unavailability-drawer">
        <EzySwitch
          name="isAllDay"
          label="All day"
          checked={isAllDay}
          handleChange={(_, checked) => {
            onChangeFields('isAllDay', checked);
          }}
        />
        <div className={!isAllDay && 'two-grid'}>
          <EzyDate
            name="fromDate"
            placeholder="Select date"
            label="From"
            value={fromDate}
            timeZone={timeZone?.label}
            onChange={(value) => {
              onChangeFields('fromDate', value);
            }}
            minDate={moment(new Date())}
            error={errors?.fromDate}
            required
          />
          {!isAllDay && (
            <EzyTime
              name="fromTime"
              placeholder="Select time"
              label="Time"
              value={fromTime}
              timeZone={timeZone?.label}
              onChange={(value) => {
                onChangeFields('fromTime', value);
              }}
              error={errors?.fromTime}
              required={!isAllDay}
            />
          )}
        </div>

        <div className={!isAllDay && 'two-grid'}>
          <EzyDate
            name="toDate"
            placeholder="Select date"
            label="To"
            value={toDate}
            timeZone={timeZone?.label}
            onChange={(value) => {
              onChangeFields('toDate', value);
            }}
            error={errors?.toDate}
            minDate={moment.tz(fromDate, timeZone?.label)}
            required
          />
          {!isAllDay && (
            <EzyTime
              name="toTime"
              placeholder="Select time"
              label="Time"
              timeZone={timeZone?.label}
              value={toTime}
              onChange={(value) => {
                onChangeFields('toTime', value);
              }}
              error={errors?.toTime}
              required={!isAllDay}
            />
          )}
        </div>
        {fromDate && toDate && moment(fromDate).isSame(toDate) && (
          <EzySwitch
            name="isRepeat"
            label="Repeat"
            checked={isRepeat}
            handleChange={(_, checked) => {
              onChangeFields('isRepeat', checked);
            }}
          />
        )}
        {isRepeat && fromDate && toDate && moment(fromDate).isSame(toDate) && (
          <>
            <EzyAutoComplete
              placeholder="Select"
              label="Every"
              options={unavailabilityFrequencyEnum}
              value={repeatitionFrequency}
              onChange={(_, value) => {
                onChangeFields('repeatitionFrequency', value);
              }}
              error={errors?.repeatitionFrequency}
              required={isRepeat}
            />
            {repeatitionFrequency && repeatitionFrequency.label !== 'Monthly' ? (
              <EzyAutoComplete
                id="tags-outlined"
                multiple
                placeholder="Select"
                options={weekDaysEnum.map((e) => e.label)}
                value={repeatWeekWise}
                className={` ${repeatWeekWise?.length ? 'auto-complete-tags' : ''}  `}
                label="On"
                name="repeatOnDays"
                renderTags={(value, getTagProps) =>
                  value.map((option, chipIndex) => (
                    <Chip
                      variant="outlined"
                      color="primary"
                      label={option}
                      deleteIcon={<span className="material-icons">highlight_off</span>}
                      {...getTagProps({ chipIndex })}
                    />
                  ))
                }
                onChange={(_, value) => {
                  onChangeFields('repeatWeekWise', value);
                }}
                error={errors?.repeatWeekWise}
                required={isRepeat}
              />
            ) : (
              <EzyAutoComplete
                placeholder="Select"
                label="On"
                options={onList()}
                value={repeatMonthly}
                onChange={(_, value) => {
                  onChangeFields('repeatMonthly', value);
                }}
                error={errors?.repeatMonthly}
                required={isRepeat}
              />
            )}

            <EzyAutoComplete
              placeholder="Select"
              label="End"
              options={endFilter.map((e) => e.label)}
              value={endOn}
              onChange={(_, value) => {
                onChangeFields('endOn', value);
              }}
              error={errors?.endOn}
              required={isRepeat}
            />
          </>
        )}

        {moment(fromDate).isSame(toDate) && endOn && endOn !== 'Never' && (
          <EzyDate
            name="endOnDate"
            placeholder="Select date"
            label="Ends on date"
            value={endOnDate}
            timeZone={timeZone?.label}
            onChange={(value) => {
              onChangeFields('endOnDate', value);
            }}
            minDate={moment.tz(fromDate, timeZone?.label)}
            error={errors?.endOnDate}
            required={isRepeat && endOn !== 'Never'}
          />
        )}

        <EzyInputField
          label="Note"
          name="note"
          placeholder="Enter Leave notes..."
          multiline
          rows={3}
          max-rows={5}
          value={note}
          onChange={(e) => onChangeFields('note', e.target.value)}
        />
      </div>
    </EzyDrawer>
  );
}

export default AddUnavailabilityDrawer;

AddUnavailabilityDrawer.propTypes = {
  isFromProfile: PropTypes.bool,
  listCallback: PropTypes.func,
};

AddUnavailabilityDrawer.defaultProps = {
  isFromProfile: false,
  listCallback: () => {},
};
