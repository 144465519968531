import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import EzyTable from '../../../../components/common/table/EzyTable';
import EzyPagination from '../../../../components/common/pagination/EzyPagination';
import { getLeaveRequestByPersonId } from '../../redux/peopleActions';
import useQueryParams from '../../../../hooks/useQueryParams';
import useUrlParamsUpdate from '../../../../hooks/useURLParamsUpdate';
import LeaveRequestDrawer from './LeaveRequestDrawer';
import { editLeaveDetails } from '../../redux/peopleSlice';
import ViewLeaveRequestDrawer from './ViewLeaveRequestDrawer';

function LeaveRequestList({ isFromProfile }) {
  const { leaveRequest, timeZoneId } = useSelector(({ people }) => people?.leaveDetails ?? {});
  const { docs, headers, total, pages, page, limit } = useMemo(
    () => leaveRequest ?? {},
    [leaveRequest]
  );

  const { personId: loggedInPersonId } = useSelector(({ user }) => user?.profileData ?? {});

  const { getLeaveRequestByPersonIdLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const { peopleId } = useParams();

  const { page: paramPage, limit: paramLimit } = useQueryParams();
  const dispatch = useDispatch();

  useUrlParamsUpdate({
    page: page ?? 1,
    limit: limit ?? 15,
  });

  const getLeaveListByFilter = async (params = {}) => {
    const data = {
      page: page ?? 1,
      limit: limit ?? 15,
      personDetailId: isFromProfile ? loggedInPersonId : peopleId,
      ...params,
    };
    await dispatch(getLeaveRequestByPersonId(data));
  };

  const pageActionClick = async (newPage) => {
    await getLeaveListByFilter({ page: newPage, limit });
  };
  const onSelectLimit = async (newLimit) => {
    await getLeaveListByFilter({ page: 1, limit: newLimit });
  };

  const onViewClick = (e) => {
    dispatch(editLeaveDetails({ fieldName: 'viewLeaveRequestDrawer', value: true }));
    dispatch(editLeaveDetails({ fieldName: 'leaveRequestId', value: e?.id }));
  };

  const getActions = ({ leaveStatus, canEditWhenApproved }) => {
    const actions = [];
    actions.push({
      label: 'View',
      onClick: onViewClick,
      access: 'VIEWLVMGMT',
    });
    if ([2, 3].includes(leaveStatus) || canEditWhenApproved) {
      actions.push({
        label: 'Edit',
        onClick: (e) => {
          dispatch(editLeaveDetails({ fieldName: 'leaveRequestDrawer', value: true }));
          dispatch(editLeaveDetails({ fieldName: 'isEditLeaveRequest', value: true }));
          dispatch(editLeaveDetails({ fieldName: 'leaveRequestId', value: e?.id }));
        },
        access: 'EDITLVMGMT',
      });
    }
    return actions;
  };

  const getRowData = () =>
    docs?.map((dt) => ({
      ...dt,
      action: getActions(dt),
    }));

  useEffect(() => {
    const params = {
      page: paramPage ?? page ?? 1,
      limit: paramLimit ?? limit ?? 15,
    };
    getLeaveListByFilter({ ...params });
  }, []);

  return (
    <Paper className="table-paper">
      <EzyTable
        headers={headers}
        rows={getRowData()}
        loading={getLeaveRequestByPersonIdLoader}
        onRowClick={onViewClick}
        accessForRow="VIEWLVMGMT"
        accessForAction={['VIEWLVMGMT', 'EDITLVMGMT']}
        timeZone={timeZoneId?.label}
      />
      <EzyPagination
        total={total}
        pages={pages}
        pageActionClick={pageActionClick}
        limit={limit}
        page={page}
        onChangeLimit={onSelectLimit}
      />
      <LeaveRequestDrawer isFromProfile={isFromProfile} listCallback={getLeaveListByFilter} />
      <ViewLeaveRequestDrawer isFromProfile={isFromProfile} />
    </Paper>
  );
}

export default LeaveRequestList;

LeaveRequestList.propTypes = {
  isFromProfile: PropTypes.bool,
};

LeaveRequestList.defaultProps = {
  isFromProfile: false,
};
