import { createSlice } from '@reduxjs/toolkit';

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    calenderData: {
      weekDatesValue: {
        startDate: null,
        endDate: null,
      },
      calenderShiftDetails: {
        weeklyTotal: 0,
        dashboardShiftResponseEntitiesList: [],
      },
      swapShiftDrawerDetails: {
        currentShift: {},
        possibleShifts: [],
        note: '',
      },
      offerShiftDrawerDetails: {
        currentShift: {},
        note: '',
      },
      swapShiftRequestsDetails: {
        currentShift: {},
        swapRequests: {},
        note: '',
        swapRequestsDisplay: {
          isToggle: false,
          listType: 'Swap Shift Received',
        },
        checkedSwapRequests: [],
      },
      displayShiftDrawer: {},
      isPendingApprovalDrawer: null,
    },
    dashboardData: {},
  },
  reducers: {
    updateCalenderData: (state, action) => {
      const { fieldName, value } = action.payload;
      state.calenderData[fieldName] = value;
    },
    updateDashboardData: (state, action) => {
      const { fieldName, value } = action.payload;
      state[fieldName] = value;
    },
    setCalenderShiftDetails: (state, action) => {
      state.calenderData.calenderShiftDetails = action.payload;
    },
    updateSwapShiftDrawerDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.calenderData.swapShiftDrawerDetails[fieldName] = value;
    },
    updateOfferShiftDrawerDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.calenderData.offerShiftDrawerDetails[fieldName] = value;
    },
    updateSwapShiftRequestsDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.calenderData.swapShiftRequestsDetails[fieldName] = value;
    },
    setDashboardData: (state, action) => {
      state.dashboardData = action.payload;
    },
    resetDashboardData: (state) => {
      state.calenderData = {
        weekDatesValue: {
          startDate: null,
          endDate: null,
        },
        calenderShiftDetails: {
          weeklyTotal: 0,
          dashboardShiftResponseEntitiesList: [],
        },
        swapShiftDrawerDetails: {
          currentShift: {},
          possibleShifts: [],
          note: '',
        },
        offerShiftDrawerDetails: {
          currentShift: {},
          note: '',
        },
        swapShiftRequestsDetails: {
          currentShift: {},
          swapRequests: {},
          note: '',
        },
      };
      state.dashboardData = {};
    },
    resetDashboardCalender: (state) => {
      state.calenderData = {
        weekDatesValue: {
          startDate: null,
          endDate: null,
        },
        calenderShiftDetails: {
          weeklyTotal: 0,
          dashboardShiftResponseEntitiesList: [],
        },
        swapShiftDrawerDetails: {
          currentShift: {},
          possibleShifts: [],
          note: '',
        },
        offerShiftDrawerDetails: {
          currentShift: {},
          note: '',
        },
        swapShiftRequestsDetails: {
          currentShift: {},
          swapRequests: {},
          note: '',
        },
      };
    },
    updateDashboardAdminViaFCM: (state, action) => {
      const { forEntity, dataToUpdate } = action.payload ?? {};
      const existingData = state.dashboardData[forEntity] || [];
      state.dashboardData[forEntity] = [...existingData, dataToUpdate];
    },
    updateDashboardClientViaFCM: (state, action) => {
      const { forEntity, dataToUpdate, filterDates } = action.payload ?? {};
      const indexToUpdate =
        state?.calenderData?.calenderShiftDetails?.dashboardShiftResponseEntitiesList.findIndex(
          (e) => e.filterDates === filterDates
        );
      if (indexToUpdate > -1) {
        if (forEntity === 'requestLeave') {
          state.calenderData.calenderShiftDetails.dashboardShiftResponseEntitiesList[indexToUpdate][
            forEntity
          ] = dataToUpdate;
        } else {
          const existingData =
            state.calenderData?.calenderShiftDetails?.dashboardShiftResponseEntitiesList?.[
              indexToUpdate
            ]?.[forEntity] || [];
          state.calenderData.calenderShiftDetails.dashboardShiftResponseEntitiesList[indexToUpdate][
            forEntity
          ] = [...existingData, dataToUpdate];
        }
      }
    },
  },
});

const { reducer, actions } = dashboardSlice;

export const {
  updateCalenderData,
  setDashboardData,
  updateDashboardData,
  setCalenderShiftDetails,
  updateSwapShiftDrawerDetails,
  updateOfferShiftDrawerDetails,
  updateSwapShiftRequestsDetails,
  resetDashboardData,
  resetDashboardCalender,
  updateDashboardAdminViaFCM,
  updateDashboardClientViaFCM,
} = actions;

export default reducer;
