import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { Chip } from '@mui/material';
import EzyDialog from '../../../components/common/dialog/EzyDialog';
import IconByName from '../../../components/common/iconByName/IconByName';
import EzyAutoComplete from '../../../components/common/autoComplete/EzyAutoComplete';
import EzySwitch from '../../../components/common/switch/EzySwitch';
import EzyDialogTitle from '../../../components/common/dialog/EzyDialogTitle';
import EzyDialogAction from '../../../components/common/dialog/EzyDialogAction';
import { updateCopyLocationFields } from '../redux/locationSlice';
import {
  copyLocationFromList,
  getLocationDropDownListByBusinessId,
} from '../redux/locationActions';

function CopyLocation({ isOpen, handleClose, rowData }) {
  const { locationListEnum, copyLocation } = useSelector(({ location }) => location ?? []);

  const { copyLocationFromListLoader, getLocationsByBusinessIdLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const dispatch = useDispatch();
  const {
    copyTimeSheetSettings,
    copySchedulingSettings,
    copyOperatingHours,
    toLocationIds,
    fromLocationId,
    errors,
  } = useMemo(() => copyLocation, [copyLocation]);

  const finalList = useMemo(
    () =>
      locationListEnum?.map((e) => ({
        id: e?.id,
        label: `${e?.label} - ${e?.code}`,
      })),
    [locationListEnum]
  );

  const handleFieldChange = (name, value) => {
    dispatch(updateCopyLocationFields({ fieldName: name, value }));
  };

  const handleSubmit = async () => {
    try {
      const finalData = {
        fromLocationId: rowData.locationId,
        toLocationIds: finalList
          ?.filter((e) => toLocationIds?.includes(e?.label))
          ?.map((e) => e?.id),
        copyTimeSheetSettings,
        copySchedulingSettings,
        copyOperatingHours,
      };

      if (finalData?.toLocationIds?.length > 0) {
        handleFieldChange('errors', {});
        const isSuccess = await dispatch(copyLocationFromList(finalData));
        if (isSuccess) handleClose(true);
      } else {
        handleFieldChange('errors', {
          toLocationIds: 'Please select locations to copy',
        });
      }
    } catch (e) {
      /**/
    }
  };

  const copyActionButtons = [
    {
      label: 'Copy settings',
      onClick: handleSubmit,
      loading: copyLocationFromListLoader,
    },
  ];

  useEffect(() => {
    if (rowData?.locationId) {
      const location = finalList?.find((e) => e.id === rowData?.locationId);
      handleFieldChange('fromLocationId', location);
    }
  }, [rowData, finalList]);

  useEffect(() => {
    if (isOpen) {
      dispatch(getLocationDropDownListByBusinessId());
    }
  }, [isOpen]);

  return (
    <EzyDialog
      handleClose={handleClose}
      open={isOpen}
      className="common-dialog-with-body copy-dialog"
    >
      <EzyDialogTitle title="Copy Location Settings" onClose={handleClose} />
      <div className="dialog-body">
        <div className="location-select-row">
          <EzyAutoComplete
            placeholder="Select location"
            label="From"
            options={finalList}
            value={fromLocationId}
            name="fromLocationId"
            disabled
          />
          <IconByName name="arrow_circle_right" className="primary-color align-self-end p-10" />
          <EzyAutoComplete
            id="tags-outlined"
            placeholder={!toLocationIds.length ? 'Select locations' : ''}
            multiple
            limitTags={1}
            className={`${toLocationIds.length ? 'auto-complete-tags' : ''}`}
            label="To"
            options={finalList?.map((e) => e?.label)}
            getOptionDisabled={(option) => {
              return option === `${rowData.locationName} - ${rowData.locationCode}`;
            }}
            loading={getLocationsByBusinessIdLoader}
            value={toLocationIds}
            name="toLocationIds"
            renderTags={(value, getTagProps) =>
              value.map((option, chipIndex) => (
                <Chip
                  variant="outlined"
                  color="primary"
                  label={option}
                  deleteIcon={<span className="material-icons">highlight_off</span>}
                  {...getTagProps({ chipIndex })}
                />
              ))
            }
            onChange={(e, value) => {
              handleFieldChange('toLocationIds', value);
            }}
            error={errors?.toLocationIds}
            required
          />
        </div>
        <div className="primary-bg">Settings</div>
        <div className="setting-rows">
          <div className="two-grid p-bottom-10">
            <EzySwitch
              name={copyOperatingHours}
              checked={copyOperatingHours}
              handleChange={(e) => handleFieldChange('copyOperatingHours', e.target.checked)}
              label="Operating hours"
            />

            <EzySwitch
              name={copySchedulingSettings}
              checked={copySchedulingSettings}
              handleChange={(e) => handleFieldChange('copySchedulingSettings', e.target.checked)}
              label="Scheduling"
            />
          </div>
          <div className="two-grid">
            <EzySwitch
              name={copyTimeSheetSettings}
              checked={copyTimeSheetSettings}
              handleChange={(e) => handleFieldChange('copyTimeSheetSettings', e.target.checked)}
              label="Time sheets"
            />
          </div>
        </div>
      </div>
      <EzyDialogAction actionButtons={copyActionButtons} />
    </EzyDialog>
  );
}

export default CopyLocation;

CopyLocation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  rowData: PropTypes.object,
};

CopyLocation.defaultProps = {
  handleClose: null,
  rowData: {},
};
