import React, { useState } from 'react';
import { InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNull } from 'lodash';
import EzyAvatar from '../../../components/common/avatar/EzyAvatar';
import EzyButton from '../../../components/common/button/EzyButton';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import EzyIconButton from '../../../components/common/iconButton/EzyIconButton';
import {
  commentOnPostAction,
  confirmNewsFeedPostAction,
  deleteNewsFeedPostAction,
  deletePostCommentAction,
} from '../redux/newsFeedActions';
import {
  updateNewsFeedConfirmationDrawer,
  updateNewsFeedListDetails,
} from '../redux/newsFeedSlice';
import Loader from '../../../components/common/loader/Loader';
import EzyDialog from '../../../components/common/dialog/EzyDialog';
import IconByName from '../../../components/common/iconByName/IconByName';
import AccessWrapper from '../../../components/common/accessWrapper/AccessWrapper';
import useAccess from '../../../hooks/useAccess';
import ConfirmationModal from '../../../components/common/modal/ConfirmationModal';

function NewsPost({ data }) {
  const dispatch = useDispatch();
  const { docs } = useSelector(({ newsFeed }) => newsFeed?.newsFeedList ?? {});
  const [openPictureModal, setOpenPictureModal] = useState(null);

  const [deleteModalData, setDeleteModalData] = useState(null);

  const { roleId } = useSelector(({ user }) => user?.profileData || {});

  const { personId } = useSelector(({ user }) => user?.profileData ?? {});
  const hasAccessOfComment = useAccess('EDITNWF');

  const { deleteNewsFeedPostActionLoader, deletePostCommentActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const [seeAllComments, setSeeAllComments] = useState(false);

  const [comment, setComment] = useState('');

  const locationPersonString = (locationList, personList) => {
    const list = [...locationList, ...personList];

    switch (list?.length) {
      case 1:
        return list[0]?.label;
      case 2:
        return `${list[0]?.label} and ${list[1]?.label} `;
      case 3:
        return `${list[0]?.label} , ${list[1]?.label} and ${list[2]?.label}`;
      default:
        return `${list[0]?.label} , ${list[1]?.label} and ${list.length - 2} others`;
    }
  };

  const onChangeNewsFeedListDetails = (fieldName, value) => {
    dispatch(
      updateNewsFeedListDetails({
        fieldName,
        value,
      })
    );
  };

  const handleDeleteNewsPost = () => {
    dispatch(
      deleteNewsFeedPostAction({ newsFeedId: data?.id }, () =>
        onChangeNewsFeedListDetails(
          'docs',
          docs.filter((e) => e?.id !== data?.id)
        )
      )
    );
  };

  const handleCommentOnPostAction = () => {
    dispatch(
      commentOnPostAction({ newsFeedId: data?.id, comment }, (cmt) => {
        setComment('');
        dispatch(
          updateNewsFeedListDetails({
            fieldName: 'docs',
            value: docs.map((doc) => {
              if (doc?.id === cmt?.newsFeedId) {
                return {
                  ...doc,
                  comments: [...doc.comments, cmt],
                };
              }
              return doc;
            }),
          })
        );
      })
    );
  };

  return (
    <div
      className={`post-wrapper ${data?.isConfirmationRequired && 'important-post'}`}
      key={data?.id}
    >
      {(deleteNewsFeedPostActionLoader || deletePostCommentActionLoader) && <Loader />}
      <div className="post-header">
        <div className="d-flex gap-10">
          <EzyAvatar firstName={data?.createdByName} imageLink={data?.createdPersonProfilePhoto} />

          <div className="d-flex-column">
            <span className="title-text">{data?.createdByName}</span>

            <span className="secondary-caption-text">
              {moment.tz(data?.createdDate, data?.timeZone).fromNow()} |{' '}
              <span
                className={
                  (roleId === 1 || data?.createdBy === personId) && 'location-person-string'
                }
                onClick={() => {
                  if (roleId === 1 || data?.createdBy === personId) {
                    dispatch(
                      updateNewsFeedConfirmationDrawer({ fieldName: 'isForAddMore', value: true })
                    );
                    dispatch(
                      updateNewsFeedConfirmationDrawer({
                        fieldName: 'data',
                        value: {
                          newsFeedId: data?.id,
                          assignToLocation: data?.assignToLocation,
                          assignToPerson: data?.assignToPerson,
                          totalPeopleCount: data?.totalPeopleCount,
                        },
                      })
                    );
                  }
                }}
              >
                {locationPersonString(data?.assignToLocation, data?.assignToPerson)}
              </span>
            </span>
          </div>
        </div>
        <div className="d-flex gap-10">
          <AccessWrapper access="EDITNWF">
            {data?.isConfirmationRequired &&
              data?.createdBy !== personId &&
              ([1, 3].includes(roleId) ? [1, 3].includes(data?.createdByRole) : true) &&
              (data?.isConfirmed ? (
                <div className="flex-center gap-10">
                  {roleId !== 1 && <span className="primary-color">Confirmed</span>}
                  {roleId !== 1 && (
                    <EzyButton
                      label="Undo"
                      className="no-pad-button"
                      color="secondary"
                      onClick={() => {
                        dispatch(
                          confirmNewsFeedPostAction(
                            {
                              newsFeedId: data?.id,
                              isConfirmed: false,
                              personDetailId: personId,
                            },
                            () =>
                              onChangeNewsFeedListDetails(
                                'docs',
                                docs.map((e) => {
                                  if (e?.id === data?.id)
                                    return {
                                      ...e,
                                      isConfirmed: false,
                                      unconfirmedList: [
                                        ...e.confirmedList.filter(
                                          (x) => x?.personDetailEntity?.personDetailId === personId
                                        ),
                                        ...e.unconfirmedList,
                                      ],
                                      confirmedList: e?.confirmedList.filter(
                                        (x) => x?.personDetailEntity?.personDetailId !== personId
                                      ),
                                    };
                                  return e;
                                })
                              )
                          )
                        );
                      }}
                    />
                  )}
                </div>
              ) : (
                <EzyButton
                  label="Confirm"
                  className="no-pad-button"
                  onClick={() => {
                    dispatch(
                      confirmNewsFeedPostAction(
                        { newsFeedId: data?.id, isConfirmed: true, personDetailId: personId },
                        () =>
                          onChangeNewsFeedListDetails(
                            'docs',
                            docs.map((e) => {
                              if (e?.id === data?.id)
                                return {
                                  ...e,
                                  isConfirmed: true,
                                  confirmedList: [
                                    ...e.unconfirmedList.filter(
                                      (x) => x?.personDetailEntity?.personDetailId === personId
                                    ),
                                    ...e.confirmedList,
                                  ],
                                  unconfirmedList: e?.unconfirmedList.filter(
                                    (x) => x?.personDetailEntity?.personDetailId !== personId
                                  ),
                                };
                              return e;
                            })
                          )
                      )
                    );
                  }}
                />
              ))}

            {data?.isConfirmationRequired &&
              (roleId === 1 ||
                (roleId === 3 && data?.createdByRole >= 3) ||
                data?.createdBy === personId) && (
                <div
                  className="primary-color flex-center pointer-cursor text-overflow-ellipsis"
                  onClick={() => {
                    dispatch(
                      updateNewsFeedConfirmationDrawer({
                        fieldName: 'data',
                        value: {
                          newsFeedId: data?.id,
                          confirmedList: data?.confirmedList,
                          unconfirmedList: data?.unconfirmedList,
                        },
                      })
                    );
                  }}
                >
                  {data?.confirmedList?.length} /{' '}
                  {data.confirmedList.length + data.unconfirmedList.length} have confirmed
                </div>
              )}
          </AccessWrapper>

          <AccessWrapper access="DELETENWF">
            {data?.createdByRole >= roleId &&
              (roleId === 1 ? true : data?.createdBy === personId) && (
                <EzyIconButton iconName="delete" onClick={(e) => setDeleteModalData(e)} />
              )}
          </AccessWrapper>
        </div>
      </div>

      <div className="d-flex-column gap-10 ">
        <div className="word-break">{data?.newsContent}</div>
        {data?.mediaStoragePathList?.length > 0 && (
          <div
            className={`post-media-container ${
              data?.mediaStoragePathList?.length > 1 && 'post-grid'
            }`}
          >
            {data?.mediaStoragePathList?.map((media) => {
              if (media?.mediaType === 'video') {
                return (
                  <video src={media?.storagePath} type="video/mp4" controls>
                    <track kind="captions" />
                  </video>
                );
              }
              if (media?.mediaType === 'pdf') {
                return (
                  <div
                    className="flex-center pdf-container"
                    onClick={() => window.open(media?.storagePath, '_blank')}
                  >
                    <span className="material-icons">picture_as_pdf</span>
                  </div>
                );
              }
              return (
                <img
                  src={media?.storagePath}
                  alt={media?.mediaType}
                  onClick={() => setOpenPictureModal(media?.storagePath)}
                />
              );
            })}
          </div>
        )}
      </div>

      {data?.isCommentAllowed && hasAccessOfComment && <hr className="w-full" />}

      {data?.comments?.length > 0 && (
        <div className="d-flex-column gap-10">
          {data?.comments?.map(
            (e, index) =>
              (!seeAllComments ? index < 3 : seeAllComments) && (
                <div className="post-comment-container" key={e?.id}>
                  <EzyAvatar firstName={e?.createdBy} imageLink={e?.profilePhoto} />
                  <div className="comment">
                    <div className="d-flex-column ">
                      <div className="d-flex flex-align-center gap-5">
                        {e?.createdBy} <div className="separator-circle"> </div>
                        <span className="text-secondary-color body-text">
                          {moment.tz(e?.createdDate, e?.timeZone).fromNow()}
                        </span>
                      </div>
                      <span className="text-secondary-color body-text">{e?.comment}</span>
                    </div>
                    {hasAccessOfComment &&
                      (roleId === 1 ||
                        e?.createdById === personId ||
                        (data?.createdBy === personId && e?.createdByRole >= roleId)) && (
                        <span
                          className="material-icons pointer-cursor error-color"
                          onClick={() => {
                            dispatch(
                              deletePostCommentAction({ commentId: e?.id }, () =>
                                onChangeNewsFeedListDetails(
                                  'docs',
                                  docs.map((doc) => {
                                    if (doc?.id === data?.id) {
                                      return {
                                        ...doc,
                                        comments: doc.comments.filter((cmt) => cmt.id !== e?.id),
                                      };
                                    }
                                    return doc;
                                  })
                                )
                              )
                            );
                          }}
                        >
                          delete
                        </span>
                      )}
                  </div>
                </div>
              )
          )}
          {data?.comments?.length > 3 && !seeAllComments && (
            <div className="see-more-text" onClick={() => setSeeAllComments(true)}>
              View all comments
            </div>
          )}

          {data?.comments?.length > 3 && seeAllComments && (
            <div className="see-more-text" onClick={() => setSeeAllComments(false)}>
              Hide comments
            </div>
          )}
        </div>
      )}
      {data?.isCommentAllowed && hasAccessOfComment && (
        <EzyInputField
          placeholder="Write a comment.. "
          value={comment}
          onChange={(e) => setComment(e?.target?.value)}
          onKeyDown={async (e) => {
            if (e.key === 'Enter' && comment) {
              await handleCommentOnPostAction();
            }
          }}
          endAdornment={
            <InputAdornment position="end">
              <EzyIconButton
                iconName="send"
                onClick={() => handleCommentOnPostAction()}
                disabled={!comment}
              />
            </InputAdornment>
          }
        />
      )}

      <EzyDialog
        open={openPictureModal}
        handleClose={() => setOpenPictureModal(null)}
        className="common-dialog-with-body profile-picture-dialog"
      >
        <IconByName
          name="close"
          className="pointer-cursor close-icon"
          onClick={() => setOpenPictureModal(null)}
        />
        <img src={openPictureModal} alt="" />
      </EzyDialog>

      <ConfirmationModal
        title="Delete Post"
        isOpen={!isEmpty(deleteModalData) || !isNull(deleteModalData)}
        handleClose={() => {
          setDeleteModalData(null);
        }}
        handleSubmit={async () => {
          await handleDeleteNewsPost();
          setDeleteModalData(null);
        }}
        primaryLabel="Delete"
      >
        <div>Are you sure you want to delete?</div>
      </ConfirmationModal>
    </div>
  );
}

export default NewsPost;

NewsPost.propTypes = {
  data: PropTypes.object.isRequired,
};
