import { omitBy, isNil, filter, isEmpty } from 'lodash';
import { requireValidate } from '../../../utils/validationHelper';

const validateAddRule = (data) => {
  const { metricId, areaId, linearRule, ruleBuffer, coverageType, rangeRule, addBuffer } =
    data ?? {};

  const error = {};

  error.metricId = requireValidate('Metric ', metricId);
  error.areaId = requireValidate('Area', areaId);
  if (coverageType === 1) {
    error.linearRuleMinimumLabor = requireValidate('Minimum labor cost', linearRule?.minimumLabor);
    error.linearRuleMaximumLabor = requireValidate('Maximum labor cost', linearRule?.maximumLabor);
    error.linearRuleRequiredLabor = requireValidate('Labor', linearRule?.requiredLabor);
    error.linearRuleLaborCost = requireValidate('Labor cost', linearRule?.laborCost);
  }
  if (coverageType === 2) {
    error.rangeRule = rangeRule?.map((e, i) =>
      omitBy(
        {
          minimumCost: requireValidate('Minimum cost', e?.minimumCost),
          maximumCost:
            i + 1 !== rangeRule?.length ? requireValidate('Maximum cost', e?.maximumCost) : null,
          requiredLabor: requireValidate('Labor', e?.requiredLabor),
        },
        isNil
      )
    );
    error.rangeRuleCost = rangeRule
      ?.map((e, i) => (i + 1 !== rangeRule?.length ? +e.maximumCost < +e.minimumCost : false))
      ?.some((e) => e)
      ? 'Each step must have a valid minimum and maximum value'
      : null;
  }
  if (addBuffer) {
    error.fromHour = requireValidate('Hour', ruleBuffer?.fromHour);
    error.untilHour = requireValidate('Hour', ruleBuffer?.untilHour);
  }
  error.rangeRule = filter(error.rangeRule, (e) => !isEmpty(e))?.length ? error.rangeRule : null;
  return omitBy(error, isNil);
};

export default validateAddRule;
