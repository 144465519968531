import React from 'react';
import { registerAllModules } from 'handsontable/registry';
import { HotTable } from '@handsontable/react';
import PropTypes from 'prop-types';
import 'handsontable/dist/handsontable.full.min.css';

registerAllModules();

function HandsonTable({ rows, columns, colHeaders, rowHeaders, dataSchema, ...rest }) {
  return (
    <div className="handsontable-container w-full h-full">
      <HotTable
        ref={rest?.myRef}
        height="calc(100% - 10px)"
        width="100%"
        data={rows}
        dataSchema={dataSchema}
        colHeaders={colHeaders}
        rowHeaders={rowHeaders}
        columns={columns}
        rowHeights={40}
        manualRowResize
        columnHeaderHeight={40}
        rowHeaderWidth={100}
        stretchH="all"
        licenseKey="non-commercial-and-evaluation"
        className="ezy-handson"
        outsideClickDeselects={false}
        fixedRowsTop={1}
        {...rest}
      />
    </div>
  );
}

HandsonTable.propTypes = {
  rows: PropTypes.array.isRequired,
  colHeaders: PropTypes.array.isRequired,
  rowHeaders: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  dataSchema: PropTypes.object.isRequired,
};

export default HandsonTable;
