import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Menu } from '@mui/material';
import { isEmpty, isNull } from 'lodash';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import { resetMetricSettingsDrawer, updateMetricSettingsDrawer } from '../../redux/insightSlice';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import EzyAutoComplete from '../../../../components/common/autoComplete/EzyAutoComplete';
import EzyButton from '../../../../components/common/button/EzyButton';
import {
  deleteMetricAction,
  getInsightListAction,
  getMetricSettingsAction,
  updateMetricSettingAction,
} from '../../redux/insightAction';
import validateMetricSettings from '../../validations/validateMetricSettings';
import ROUTE_CONSTANTS from '../../../../utils/constants';
import ConfirmationModal from '../../../../components/common/modal/ConfirmationModal';

function MetricSettingsDrawer() {
  const { filters, metric } = useSelector(({ insight }) => insight ?? {});
  const history = useHistory();
  const [deleteModalData, setDeleteModalData] = useState(null);

  const {
    isOpen,
    metricName,
    chartColour = '#e53935',
    metricForecastBasedOnEntity,
    metricType,
    levelOfDetail,
    isDefault,
    isManagerForecastActivated,
    breakDownByArea,
    isForecastActivated,
    errors,
  } = useMemo(() => metric?.metricSettingsDrawer, [metric?.metricSettingsDrawer]);

  const {
    metricType: metricTypeEnum,
    metricForecastBasedOnEntity: metricForecastBasedOnEntityEnum,
    levelOfDetail: levelOfDetailEnum,
  } = useSelector(({ common }) => common?.enums ?? {});

  const {
    deleteMetricActionLoader,
    getMetricSettingsActionLoader,
    updateMetricSettingActionLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(resetMetricSettingsDrawer());
  };

  const colorpalette = [
    { id: 1, color: '#e53935' },
    { id: 2, color: '#f56a00' },
    { id: 3, color: '#c78500' },
    { id: 4, color: '#109e7a' },
    { id: 5, color: '#2962ff' },
    { id: 6, color: '#0091ea' },
    { id: 7, color: '#6200ea' },
    { id: 8, color: '#d500f9' },
    { id: 9, color: '#bf360c' },
    { id: 10, color: '#6d4c41' },
    { id: 11, color: '#988027' },
    { id: 12, color: '#0095a8' },
    { id: 13, color: '#1f77bc' },
    { id: 14, color: '#0d47a1' },
    { id: 15, color: '#673ab7' },
    { id: 16, color: '#bc5090' },
  ];

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleFieldChange = (fieldName, value) => {
    dispatch(updateMetricSettingsDrawer({ fieldName, value }));
  };

  const handleDeleteMetric = () => {
    dispatch(
      deleteMetricAction({ metricId: filters?.selectedModule?.id }, () => {
        handleClose();
        dispatch(
          getInsightListAction('Sales', () =>
            history.push(
              `${ROUTE_CONSTANTS.SCHEDULE}/insights/Sales?location=${
                filters?.selectedLocation?.id
              }&date=${moment
                .tz(
                  filters?.weekDatesValue?.startDate,
                  filters?.selectedLocation?.timeZoneId?.label
                )
                .toISOString()}`
            )
          )
        );
      })
    );
  };

  const handleSubmit = () => {
    const data = {
      metricId: filters?.selectedModule?.id,
      metricName,
      metricType: metricType?.id,
      levelOfDetail: levelOfDetail?.id,
      breakDownByArea: isDefault ? breakDownByArea : false,
      isForecastActivated,
      isManagerForecastActivated,
    };

    if (isForecastActivated) data.metricForecastBasedOnEntity = metricForecastBasedOnEntity?.id;

    if (!isDefault) data.chartColour = chartColour;

    const error = validateMetricSettings(data);

    if (isEmpty(error)) {
      dispatch(
        updateMetricSettingAction(data, () => {
          handleClose();
          dispatch(
            getInsightListAction(filters?.selectedModule?.label, () =>
              history.push(
                `${ROUTE_CONSTANTS.SCHEDULE}/insights/${filters?.selectedModule?.label}?location=${
                  filters?.selectedLocation?.id
                }&date=${moment
                  .tz(
                    filters?.weekDatesValue?.startDate,
                    filters?.selectedLocation?.timeZoneId?.label
                  )
                  .toISOString()}`
              )
            )
          );
        })
      );
    } else {
      handleFieldChange('errors', error);
    }
  };

  useEffect(() => {
    if (isOpen) dispatch(getMetricSettingsAction({ metricId: filters?.selectedModule?.id }));
  }, [isOpen]);

  return (
    <>
      <EzyDrawer
        isOpen={isOpen}
        onClose={handleClose}
        heading={`${filters?.selectedModule?.label} Settings`}
        isLoading={getMetricSettingsActionLoader}
        actionButtons={[
          {
            label: 'Delete',
            onClick: (e) => setDeleteModalData(e),
            loading: deleteMetricActionLoader,
            color: 'error',
            access: isDefault,
          },
          { label: 'Save', onClick: handleSubmit, loading: updateMetricSettingActionLoader },
        ]}
      >
        <div className="metric-settings-drawer">
          <div className="title-with-primary-bg">General</div>
          <div className="p-x-24 d-flex-column gap-10">
            <EzyInputField
              label="Metric name"
              placeholder="Enter metric name"
              name="metricName"
              onChange={(e) => handleFieldChange('metricName', e?.target?.value)}
              value={metricName}
              error={errors?.metricName}
              disabled={isDefault}
              required
            />
            {!isDefault && (
              <div className="d-flex gap-10">
                Chart color{' '}
                <div
                  className="chart-color"
                  onClick={handleClick}
                  style={{ borderColor: chartColour }}
                >
                  <div style={{ background: chartColour }}> </div>
                </div>
                <Menu
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleCloseMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      p: 1,
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 110,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                >
                  <Box sx={{ paddingX: 1, bgcolor: 'white' }}>
                    <div className="color-palette-wrapper">
                      <div className="color-palette">
                        {colorpalette?.map((e) => {
                          return (
                            <div
                              key={e?.id}
                              style={{ background: e?.color }}
                              className="color"
                              onClick={() => handleFieldChange('chartColour', e?.color)}
                            >
                              {e?.color === chartColour ? (
                                <span className="material-icons">check</span>
                              ) : (
                                ''
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <EzyButton label="Done" onClick={() => handleCloseMenu()} />
                    </div>
                  </Box>
                </Menu>
              </div>
            )}
            <EzyAutoComplete
              label="Metric type"
              placeholder="Select metric type"
              options={metricTypeEnum}
              name="metricType"
              onChange={(e, value) => {
                handleFieldChange('metricType', value);
              }}
              value={metricType}
              error={errors?.metricType}
              required
            />
            <EzyAutoComplete
              name="levelOfDetail"
              label="Level of detail"
              placeholder="Select level of detail"
              subtitle="The granularity of the data for this metric"
              options={levelOfDetailEnum}
              onChange={(e, value) => {
                handleFieldChange('levelOfDetail', value);
              }}
              value={levelOfDetail}
              error={errors?.levelOfDetail}
              required
            />
            {isDefault && (
              <EzyAutoComplete
                name="breakDownByArea"
                label="Break down by area"
                placeholder="Select break down by area"
                subtitle="Enter and display data for each individual area separately"
                options={['On', 'Off']}
                onChange={(e, value) => handleFieldChange('breakDownByArea', value === 'On')}
                value={breakDownByArea ? 'On' : 'Off'}
                required
              />
            )}
          </div>
          <div className="title-with-primary-bg">Forecasting</div>
          <div className="p-x-24 d-flex-column gap-10">
            <EzyAutoComplete
              name="isManagerForecastActivated "
              label="Manager forecast"
              placeholder="Select manager forecast"
              subtitle="Managers can enter and display their own forecast for the future"
              options={['On', 'Off']}
              onChange={(e, value) =>
                handleFieldChange('isManagerForecastActivated', value === 'On')
              }
              value={isManagerForecastActivated ? 'On' : 'Off'}
              required
            />
            <EzyAutoComplete
              label="Forecast"
              placeholder="Select forecast"
              name="isForecastActivated"
              options={['On', 'Off']}
              onChange={(e, value) => handleFieldChange('isForecastActivated', value === 'On')}
              value={isForecastActivated ? 'On' : 'Off'}
              required
            />
            {isForecastActivated && (
              <>
                <div className="secondary-caption-text">
                  Forecasts are generated on past values. Adjust the forecasting periods to better
                  match the seasonality of your business. A forecasting period is based on the
                  schedule view when a forecast is generated (i.e. day, week, month)
                </div>
                <EzyAutoComplete
                  label="Forecast based on"
                  placeholder="Select forecast"
                  name="metricForecastBasedOnEntity"
                  options={metricForecastBasedOnEntityEnum}
                  onChange={(e, value) => {
                    handleFieldChange('metricForecastBasedOnEntity', value);
                  }}
                  value={metricForecastBasedOnEntity}
                  error={errors?.metricForecastBasedOnEntity}
                  required
                />
              </>
            )}
          </div>
        </div>
      </EzyDrawer>
      <ConfirmationModal
        title="Delete Metric"
        isOpen={!isEmpty(deleteModalData) || !isNull(deleteModalData)}
        handleClose={() => {
          setDeleteModalData(null);
        }}
        handleSubmit={async () => {
          await handleDeleteMetric();
          setDeleteModalData(null);
        }}
        primaryLabel="Delete"
      >
        <div>
          <div className="subtitle-text">
            Are you sure you want to delete {filters?.selectedModule?.label} ?
          </div>
          <div className="secondary-caption-text">
            This will delete the {filters?.selectedModule?.label} metric for all locations, along
            with all associated data.
          </div>
        </div>
      </ConfirmationModal>
    </>
  );
}

MetricSettingsDrawer.propTypes = {};

export default MetricSettingsDrawer;
