import React, { useState } from 'react';
import EzyToggleButtonGroup from '../../../../components/common/toggleButton/EzyToggleButtonGroup';
import UpComingShifts from './UpComingShifts';
import PreviouslyWorkedShifts from './PreviouslyWorkedShifts';

const shiftTypes = ['Upcoming Shifts', 'Previously Worked Shifts'];

function ShiftDetails() {
  const [shiftType, setShiftType] = useState(shiftTypes[0]);

  return (
    <div className="shift-detail-root-container">
      <div className="title-with-primary-bg">
        <span>{shiftType}</span>
        <div className="d-flex gap-10">
          <EzyToggleButtonGroup
            list={shiftTypes}
            selected={shiftType[0]}
            selectedToggleButton="new"
            name="LeaveDetailsType"
            handleChange={(e) => setShiftType(e.target.value)}
            value={shiftType}
          />
        </div>
      </div>
      <div className="p-24 table-wrapper">
        {shiftType === shiftTypes[0] ? <UpComingShifts /> : <PreviouslyWorkedShifts />}
      </div>
    </div>
  );
}

export default ShiftDetails;
