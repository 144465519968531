import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import EzyDialog from '../../components/common/dialog/EzyDialog';
import blackLogo from '../../assets/images/logo/PayrollEzy_BlackLogo.png';
import EzyInputField from '../../components/common/inputField/EzyInputField';
import EzyButton from '../../components/common/button/EzyButton';
import authServices from './authServices';
import ValidateForgotPassword from './validations/validateForgotPassword';
import InfoModal from '../../components/infoModal/InfoModal';
import ROUTE_CONSTANTS from '../../utils/constants';
import checkEmailImage from '../../assets/images/Check your email.png';
import useKeyPress from '../../hooks/useKeyPress';
import { displayErrors } from '../../utils/errorNotifyHelper';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [isForgotModal, setIsForgotModal] = useState(true);
  const isEnterPressed = useKeyPress('Enter');

  const history = useHistory();

  const updateFormData = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    const errorsData = ValidateForgotPassword({ email });
    setErrors(errorsData);
    if (isEmpty(errorsData)) {
      try {
        setLoading(true);
        // const res = await authServices.forgotPassword({ email });
        await authServices.forgotPassword({ email });
        // if (res?.data?.success) {
        setIsForgotModal(false);
        setIsSuccessModal(true);
        // }
      } catch (e) {
        displayErrors(e);
        setIsForgotModal(false);
        setIsSuccessModal(true);
        /**/
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isEnterPressed) handleSubmit();
  }, [isEnterPressed]);

  return (
    <div>
      <EzyDialog handleClose={() => {}} open={isForgotModal} className="auth-dialog">
        <div className="auth-wrapper">
          <img src={blackLogo} alt="logo" width={200} />
          <div className="info-text">
            <div className="primary-large-title">Forgot Password</div>
            <div className="subtitle-text">No worries, will send you reset instructions.</div>
          </div>
          <div className="auth-form">
            <div className="field-wrapper">
              <EzyInputField
                label="Email"
                placeholder="Enter your email"
                name="email"
                onChange={updateFormData}
                value={email}
                error={errors?.email}
                required
              />
            </div>
            <div className="action-button">
              <EzyButton label="Reset password" onClick={handleSubmit} loading={loading} />
            </div>
            <div className="auth-redirect-text">
              {`Back to `}
              <Link to="/login" className="secondary-action-text">
                login
              </Link>
            </div>
          </div>
        </div>
      </EzyDialog>
      <InfoModal
        img={checkEmailImage}
        subTitle={`We sent a password reset link to ${email}`}
        title="Check your email"
        action={
          <div className="subtitle-text ">
            {`Didn't receive the email? `}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span onClick={handleSubmit} className="secondary-action-text">
              Click to resend
            </span>
          </div>
        }
        open={isSuccessModal}
        onClose={() => history.push(ROUTE_CONSTANTS.LOGIN)}
      />
    </div>
  );
}

export default ForgotPassword;
