import { forOwn, isArray, isObject } from 'lodash';

export const mapEnumToIds = (mappings, res, commonList) => {
  const fields = mappings?.map((e) => e?.fieldName);
  forOwn(res, (val, key) => {
    if (isArray(val)) {
      val.forEach((el) => {
        mapEnumToIds(mappings, el, commonList);
      });
    } else if (isObject(val)) {
      mapEnumToIds(mappings, val, commonList);
    } else if (fields.includes(key)) {
      const mapping = mappings?.find((e) => e?.fieldName === key);
      res[key] = mapping?.isFromEnum
        ? commonList?.enums?.[mapping?.listName]?.find((e) => e?.id === val)
        : commonList?.[mapping?.listName]?.find((e) => e?.id === val);
    }
  });
  return res;
};

export const mapIdsFromList = (ids, list) => {
  if (isArray(ids)) {
    return list?.filter((e) => ids?.includes(e?.id));
  }
  return list?.find((e) => e?.id === ids);
};

export const mapLabelFromList = (label, list) => {
  return list?.find((e) => e?.label === label);
};

export const mapPeopleDetailIds = [
  {
    fieldName: 'employmentType',
    listName: 'employmentType',
    isFromEnum: true,
  },
  {
    fieldName: 'countryId',
    listName: 'country',
    isFromEnum: true,
  },
  {
    fieldName: 'payRateType',
    listName: 'payRateType',
    isFromEnum: true,
  },
  {
    fieldName: 'pronoun',
    listName: 'pronoun',
    isFromEnum: true,
  },
  {
    fieldName: 'roleId',
    listName: 'roles',
    isFromEnum: true,
  },
];

export const mapPayRateId = [
  {
    fieldName: 'payRateType',
    listName: 'payRateType',
    isFromEnum: true,
  },
  {
    fieldName: 'salaryPeriod',
    listName: 'salaryPeriod',
    isFromEnum: true,
  },
];

export const mapEditDetailsLocationIds = [
  {
    fieldName: 'weekStartsOn',
    listName: 'weekDays',
    isFromEnum: true,
  },
  {
    fieldName: 'preventShiftChange',
    listName: 'preventShiftChanges',
    isFromEnum: true,
  },
  {
    fieldName: 'shiftNotificationRecipient',
    listName: 'shiftNotificationRecipient',
    isFromEnum: true,
  },
  {
    fieldName: 'shiftRemovedNotification',
    listName: 'shiftRemovedNotification',
    isFromEnum: true,
  },
  {
    fieldName: 'coWorkerScheduleVisibility',
    listName: 'coWorkerScheduleVisibility',
    isFromEnum: true,
  },
  {
    fieldName: 'minimumTimeSheetLengthInMinutes',
    listName: 'minimumTimeSheetLengthInMinutes',
    isFromEnum: true,
  },
  {
    fieldName: 'autoApproveTimeSheetVariance',
    listName: 'autoApproveTimeSheetVariance',
    isFromEnum: true,
  },
  {
    fieldName: 'startTimeRoundingType',
    listName: 'timeRounding',
    isFromEnum: true,
  },
  {
    fieldName: 'endTimeRoundingType',
    listName: 'timeRounding',
    isFromEnum: true,
  },
  {
    fieldName: 'breakTimeRoundingType',
    listName: 'breakTimeRounding',
    isFromEnum: true,
  },
  {
    fieldName: 'linkUnscheduledTimeSheetType',
    listName: 'linkUnscheduledTimeSheetType',
    isFromEnum: true,
  },
  {
    fieldName: 'unscheduledShiftTimeVariance',
    listName: 'unscheduledShiftTimeVariance',
    isFromEnum: true,
  },
  {
    fieldName: 'isBreakPaid',
    listName: 'paidMealBreaksList',
    isFromEnum: false,
  },
];

export const mapBusinessLeaveRuleIds = [
  {
    fieldName: 'leaveType',
    listName: 'leaveType',
    isFromEnum: true,
  },
  {
    fieldName: 'resetLeaveAccrual',
    listName: 'resetLeaveAccrual',
    isFromEnum: true,
  },
  {
    fieldName: 'trackLeaveIn',
    listName: 'trackLeaveIn',
    isFromEnum: true,
  },
];
