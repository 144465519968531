import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import EzyDrawer from '../../../components/common/drawer/Drawer';
import {
  confirmNewsFeedPostAction,
  getNewsFeedReceiverListAction,
  sharePostWithOthersAction,
} from '../redux/newsFeedActions';
import EzyAutoComplete from '../../../components/common/autoComplete/EzyAutoComplete';
import {
  clearNewsFeedConfirmationDrawer,
  updateNewsFeedConfirmationDrawer,
  updateNewsFeedListDetails,
} from '../redux/newsFeedSlice';
import EzyButton from '../../../components/common/button/EzyButton';
import EzyAvatar from '../../../components/common/avatar/EzyAvatar';
import EzyIconButton from '../../../components/common/iconButton/EzyIconButton';

function NewsFeedConfirmationDrawer() {
  const dispatch = useDispatch();
  const { newsFeedConfirmationDrawer, receiverList, newsFeedList } = useSelector(
    ({ newsFeed }) => newsFeed ?? {}
  );

  const { personId, roleId } = useSelector(({ user }) => user?.profileData ?? {});

  const { data, selectedLocation, selectedPeople, isForAddMore } = useMemo(
    () => newsFeedConfirmationDrawer,
    [newsFeedConfirmationDrawer]
  );

  const { sharePostWithOthersActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const handleClose = () => {
    dispatch(clearNewsFeedConfirmationDrawer());
  };

  const handleFieldChange = (fieldName, value) => {
    dispatch(updateNewsFeedConfirmationDrawer({ fieldName, value }));
  };

  const handleShareWithOthers = () => {
    const shareData = {
      id: data?.newsFeedId,
      assignToPerson: receiverList?.receiverPersonList.filter(
        (e) => selectedPeople.includes(e?.label) && e
      ),
      assignToLocation: receiverList?.receiverLocationList.filter((e) =>
        selectedLocation.includes(e?.label)
      ),
    };

    dispatch(
      sharePostWithOthersAction(shareData, () => {
        handleFieldChange('data', {
          ...data,
          assignToLocation: [...shareData.assignToLocation, ...data.assignToLocation],
          assignToPerson: [...shareData.assignToPerson, ...data.assignToPerson],
        });
        handleFieldChange('selectedPeople', []);
        handleFieldChange('selectedLocation', []);
        dispatch(getNewsFeedReceiverListAction({ newsFeedId: data?.newsFeedId }));
      })
    );
  };

  const onClickUndoConfirmation = (peopleDetail) => {
    dispatch(
      confirmNewsFeedPostAction(
        {
          newsFeedId: data?.newsFeedId,
          isConfirmed: false,
          personDetailId: peopleDetail?.personDetailEntity?.personDetailId,
        },
        () => {
          dispatch(
            updateNewsFeedListDetails({
              fieldName: 'docs',
              value: newsFeedList?.docs.map((y) => {
                if (y?.id === data?.newsFeedId)
                  return {
                    ...y,
                    isConfirmed: false,
                    unconfirmedList: [
                      ...y.confirmedList.filter(
                        (x) =>
                          x?.personDetailEntity?.personDetailId ===
                          peopleDetail?.personDetailEntity?.personDetailId
                      ),
                      ...y.unconfirmedList,
                    ],
                    confirmedList: y?.confirmedList.filter(
                      (x) =>
                        x?.personDetailEntity?.personDetailId !==
                        peopleDetail?.personDetailEntity?.personDetailId
                    ),
                  };
                return y;
              }),
            })
          );
          handleFieldChange('data', {
            ...data,
            confirmedList: data?.confirmedList.filter(
              (x) =>
                x?.personDetailEntity?.personDetailId !==
                peopleDetail?.personDetailEntity?.personDetailId
            ),
            unconfirmedList: [
              ...data.confirmedList.filter(
                (x) =>
                  x?.personDetailEntity?.personDetailId ===
                  peopleDetail?.personDetailEntity?.personDetailId
              ),
              ...data.unconfirmedList,
            ],
          });
        }
      )
    );
  };

  useEffect(() => {
    if (data?.newsFeedId && isForAddMore)
      dispatch(getNewsFeedReceiverListAction({ newsFeedId: data?.newsFeedId }));
  }, [data, isForAddMore]);

  return (
    <EzyDrawer
      isOpen={data?.newsFeedId}
      onClose={handleClose}
      heading={`Shared with ${
        data?.confirmedList
          ? data.confirmedList.length + data.unconfirmedList.length
          : data?.totalPeopleCount
      } people`}
    >
      <div className="newsFeed-confirmation-drawer">
        {isForAddMore && (
          <div className="d-flex-column gap-10">
            <EzyAutoComplete
              id="tags-outlined"
              multiple
              placeholder="Select locations"
              options={receiverList?.receiverLocationList?.map((e) => e.label)}
              value={selectedLocation}
              className={`${selectedLocation?.length ? 'auto-complete-tags' : ''}`}
              label="Location"
              name="selectedLocation"
              limitTags={1}
              renderTags={(value, getTagProps) =>
                value.map((option, chipIndex) => (
                  <Chip
                    variant="outlined"
                    color="primary"
                    label={option}
                    deleteIcon={<span className="material-icons">highlight_off</span>}
                    {...getTagProps({ chipIndex })}
                  />
                ))
              }
              onChange={(e, value) => {
                handleFieldChange('selectedLocation', value);
              }}
            />
            <EzyAutoComplete
              id="tags-outlined"
              multiple
              placeholder="Select People"
              options={receiverList?.receiverPersonList?.map((e) => e.label)}
              value={selectedPeople}
              className={`${selectedPeople?.length ? 'auto-complete-tags ' : ''}`}
              label="People"
              name="selectedPeople"
              limitTags={1}
              renderTags={(value, getTagProps) =>
                value.map((option, chipIndex) => (
                  <Chip
                    variant="outlined"
                    color="primary"
                    label={option}
                    deleteIcon={<span className="material-icons">highlight_off</span>}
                    {...getTagProps({ chipIndex })}
                  />
                ))
              }
              onChange={(e, value) => {
                handleFieldChange('selectedPeople', value);
              }}
            />
            <div className="d-flex gap-10 justify-content-end">
              <EzyButton
                label="Cancel"
                className="no-pad-button "
                color="error"
                onClick={() => {
                  handleFieldChange('selectedPeople', []);
                  handleFieldChange('selectedLocation', []);
                }}
                disabled={selectedLocation?.length === 0 && selectedPeople?.length === 0}
              />

              <EzyButton
                label="Share"
                className="no-pad-button"
                loading={sharePostWithOthersActionLoader}
                onClick={() => handleShareWithOthers()}
                disabled={selectedLocation?.length === 0 && selectedPeople?.length === 0}
              />
            </div>
          </div>
        )}

        <List>
          {data?.confirmedList?.length > 0 &&
            !isForAddMore &&
            data?.confirmedList?.map((e) => {
              return (
                <ListItem key={e?.personDetailEntity?.personDetailId} disablePadding>
                  <ListItemButton disableRipple>
                    <ListItemIcon>
                      <EzyAvatar
                        imageLink={e?.personDetailEntity?.profilePhotoUrl}
                        firstName={e?.personDetailEntity?.preferredFullName}
                      />
                    </ListItemIcon>

                    <ListItemText className="unconfirmed-text">
                      <div className="d-flex  flex-align-center just-bet">
                        <div className="d-flex-column">
                          <span> {e?.personDetailEntity?.preferredFullName}</span>
                          <span className="primary-color">Confirmed</span>
                        </div>
                        {(roleId === 1 || e?.personDetailEntity?.personDetailId === personId) && (
                          <EzyIconButton
                            color="error"
                            onClick={() => onClickUndoConfirmation(e)}
                            iconName="restart_alt"
                            title={
                              e?.personDetailEntity?.personDetailId === personId
                                ? 'Undo'
                                : 'Ask for reconfirmation'
                            }
                          />
                        )}
                      </div>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              );
            })}

          {data?.unconfirmedList?.length > 0 &&
            !isForAddMore &&
            data?.unconfirmedList?.map((e) => (
              <ListItem key={e?.personDetailEntity?.personDetailId} disablePadding>
                <ListItemButton disableRipple>
                  <ListItemIcon>
                    <EzyAvatar
                      imageLink={e?.personDetailEntity?.profilePhotoUrl}
                      firstName={e?.personDetailEntity?.preferredFullName}
                    />
                  </ListItemIcon>

                  <ListItemText
                    className="unconfirmed-text"
                    primary={e?.personDetailEntity?.preferredFullName}
                    secondary="Unconfirmed"
                  />
                </ListItemButton>
              </ListItem>
            ))}

          {data?.assignToPerson?.length > 0 &&
            data?.assignToPerson?.map((e) => (
              <ListItem key={e?.id} disablePadding>
                <ListItemButton disableRipple>
                  <ListItemIcon>
                    <EzyAvatar imageLink={e?.profilePhoto} firstName={e?.label} />
                  </ListItemIcon>

                  <ListItemText primary={e?.label} />
                </ListItemButton>
              </ListItem>
            ))}

          {data?.assignToLocation?.length > 0 &&
            data?.assignToLocation?.map((e) => (
              <ListItem key={e?.id} disablePadding>
                <ListItemButton disableRipple>
                  <ListItemIcon>
                    <Avatar>
                      <span className="material-icons primary-color">pin_drop</span>
                    </Avatar>
                  </ListItemIcon>

                  <ListItemText
                    primary={e?.label}
                    secondary={`${e?.locationPeopleCount ? e?.locationPeopleCount : 0} people`}
                  />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </div>
    </EzyDrawer>
  );
}

NewsFeedConfirmationDrawer.propTypes = {};

export default NewsFeedConfirmationDrawer;
