import { isNil, omitBy } from 'lodash';
import { isEmail, requireValidate } from '../../../utils/validationHelper';

const validateInviteToBusiness = (data) => {
  const { name, email } = data ?? {};
  const error = {};
  error.name = requireValidate('business name', name);
  error.email = isEmail('email address', email);
  return omitBy(error, isNil);
};
export default validateInviteToBusiness;
