import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import EzyIconButton from '../../../../components/common/iconButton/EzyIconButton';
import EzyToggleButtonGroup from '../../../../components/common/toggleButton/EzyToggleButtonGroup';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import { updateBillingAndPaymentDetails } from '../../redux/globalProfileSlice';
import { getOrderSummaryAction } from '../../redux/globalProfileActions';
import useQueryParams from '../../../../hooks/useQueryParams';
import ROUTE_CONSTANTS from '../../../../utils/constants';
import Loader from '../../../../components/common/loader/Loader';
import { displayDate } from '../../../../utils/timeHelper';

function PlanSummary() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { paramId } = useParams();
  const { planId } = useQueryParams();
  const { timeZone } = useSelector(({ user }) => user?.profileData ?? {});

  const { billingAndPaymentDetails } = useSelector(({ globalProfile }) => globalProfile || {});
  const {
    getOrderSummaryActionLoader,
    getBillingAndPaymentDetailsActionLoader,
    getPaymentMethodsActionLoader,
    getPlansActionLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});

  const {
    planDuration = 1,
    numberOfUser,
    perUserCost = 0,
    totalAmount = 0,
    totalDiscount = 0,
    totalUserCost = 0,
    planName,
    dueDate,
    planPrice = 0,
    taxDetails,
    errors,
  } = useMemo(() => billingAndPaymentDetails ?? {}, [billingAndPaymentDetails]);
  const { planDuration: planDurationEnum } = useSelector(({ common }) => common?.enums ?? {});

  const duration = 'month';

  const getSummaryDetails = async (
    paramParamId,
    paramPlanId,
    paramPlanDuration,
    paramNumberOfUser
  ) => {
    const data = {
      businessId: paramParamId,
      planId: Number(paramPlanId),
      planDuration: paramPlanDuration,
      numberOfUser: paramNumberOfUser ? Number(paramNumberOfUser) : 0,
    };
    await dispatch(getOrderSummaryAction(data));
  };

  const handleFieldChange = async (fieldName, value) => {
    dispatch(updateBillingAndPaymentDetails({ fieldName, value }));
    await getSummaryDetails(
      paramId,
      planId,
      fieldName === 'planDuration' ? value : planDuration,
      fieldName === 'numberOfUser' ? value : numberOfUser
    );
  };

  return (
    <div className="plan-summary-wrapper">
      {(getOrderSummaryActionLoader ||
        getBillingAndPaymentDetailsActionLoader ||
        getPaymentMethodsActionLoader ||
        getPlansActionLoader) && <Loader />}
      <div className="plan-card-header">Order summary</div>
      <div className="w-full h-full d-flex-column just-bet gap-12 overflow-auto">
        <div className="summary-info-wrapper">
          <div className="w-full  flex-align-center d-flex just-bet gap-12">
            <div className="label">{planName}</div>
            <div className="d-flex flex-align-center gap-10">
              <div className="value">{`${planPrice} / ${duration}`}</div>
              <EzyIconButton
                iconName="edit"
                className="small"
                onClick={() => {
                  history.push(`${ROUTE_CONSTANTS.ACCOUNT_OVERVIEW}/plans/${paramId}`);
                }}
              />
            </div>
          </div>
          <EzyToggleButtonGroup
            label="Billing Schedule"
            list={planDurationEnum?.map((e) => e?.label)}
            selected="Monthly"
            name="planDuration"
            handleChange={(event) => {
              const value = planDurationEnum?.find((e) => e?.label === event.target.value)?.id;
              handleFieldChange('planDuration', value);
            }}
            value={planDurationEnum?.find((e) => e?.id === planDuration)?.label}
            className="small"
          />
          <EzyInputField
            name="numberOfUser"
            placeholder="Enter total users"
            label="Number of users"
            value={numberOfUser}
            onChange={(e) => handleFieldChange('numberOfUser', e.target.value)}
            disabled={planDuration === 1}
            error={errors?.numberOfUser}
          />
        </div>
        {!getOrderSummaryActionLoader && !getBillingAndPaymentDetailsActionLoader && (
          <div className="amount-details">
            <div className="w-full flex-align-center d-flex just-bet gap-12">
              <div className="label">{`${perUserCost}/${duration} x ${numberOfUser} users`}</div>
              <div className="value">{`${totalUserCost}`}</div>
            </div>
            {planDuration === 2 && (
              <div className="w-full flex-align-center d-flex just-bet gap-12">
                <div className="label">Annual discount</div>
                <div className="value">{totalDiscount}</div>
              </div>
            )}
            {taxDetails?.length > 0 &&
              taxDetails.map((e, index) => (
                <div className="w-full flex-align-center d-flex just-bet gap-12" key={index}>
                  <div className="label">
                    {e?.taxType}({e?.taxRate || 0}%)
                  </div>
                  <div className="value">{e?.taxAmount}</div>
                </div>
              ))}
            {planDuration === 2 && (
              <div className="w-full flex-align-center d-flex just-bet gap-12">
                <div className="label">Total</div>
                <div className="value">{totalAmount}</div>
              </div>
            )}
            {planDuration === 1 && (
              <div className="w-full flex-align-center d-flex just-bet gap-12">
                <div className="label">Due on {displayDate(dueDate, timeZone?.label)}</div>
                <div className="value">{totalAmount}</div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default PlanSummary;
