import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Box,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
} from '@mui/material';
import EzyMenu from '../../../components/common/menu/EzyMenu';
import { getLocationAreaDropdownAction } from '../redux/timeSheetsAction';
import { updateSidePanelData } from '../redux/timeSheetsSlice';
import useQueryParams from '../../../hooks/useQueryParams';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import IconByName from '../../../components/common/iconByName/IconByName';
import Loader from '../../../components/common/loader/Loader';

function TimeSheetSideBar({ updateLocationList, fetchTimeSheetByFilters }) {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const {
    locationAreaDropdownList,
    selectedLocation,
    weekDatesValue,
    personList,
    selectedPerson,
    // selectedAreaId,
  } = useSelector(({ timeSheets }) => timeSheets?.sidePanelData);

  const { getLocationAreaDropdownActionLoader, getPersonListBasedOnLocationActionLoader } =
    useSelector(({ common }) => common.generalLoader ?? {});

  const {
    location: locationParam,
    area: areaParam,
    date: dateParam,
    person: personParam,
  } = useQueryParams();

  const handleSearch = async (e) => {
    if (e.target.value) setSearchText(e.target.value);
    else {
      setSearchText(null);
      await updateLocationList({ searchText: null });
    }
  };

  const onChangeDetails = (fieldName, value) => {
    dispatch(updateSidePanelData({ fieldName, value }));
  };

  const fetchTimeSheet = async ({ locationId, areaId, date, person }) => {
    dispatch(
      getLocationAreaDropdownAction({ locationId: areaId || locationId }, async (response) => {
        if (response?.length) {
          await moment.updateLocale('en', {
            week: {
              dow: response?.[0]?.weekStartsOn === 7 ? 0 : response?.[0]?.weekStartsOn,
            },
          });

          dispatch(
            updateSidePanelData({
              fieldName: 'weekDatesValue',
              value: {
                startDate: moment.tz(date, response?.[0]?.timeZoneId?.label).startOf('week'),
                endDate: moment.tz(date, response?.[0]?.timeZoneId?.label).endOf('week'),
              },
            })
          );

          const params = {
            startDate: moment
              .tz(date, response?.[0]?.timeZoneId?.label || selectedLocation?.timeZoneId?.label)
              .startOf('week')
              .toISOString(),
            endDate: moment
              .tz(date, response?.[0]?.timeZoneId?.label || selectedLocation?.timeZoneId?.label)
              .endOf('week')
              .toISOString(),
            searchText,
          };

          if (areaId) {
            params.areaId = areaId;
          }
          if (locationId && locationId !== 'all') {
            params.locationId = locationId;
          }

          await updateLocationList(params, person);
        }
      })
    );
  };

  useEffect(() => {
    (async () => {
      await fetchTimeSheet({
        locationId: locationParam,
        areaId: areaParam,
        date: dateParam ?? new Date(),
        person: personParam,
      });
    })();
  }, []);

  useEffect(() => {
    setSearchText('');
  }, [weekDatesValue]);

  return (
    <div className="time-sheets-side-menu">
      <EzyMenu
        className="time-sheets-location-menu"
        menuItems={locationAreaDropdownList}
        label={selectedLocation?.label || 'Location'}
        iconName="location_on"
        onItemClick={(_, value) => {
          onChangeDetails('selectedLocation', value);
          setSearchText('');
          moment.updateLocale('en', {
            week: {
              dow: value?.weekStartsOn === 7 ? 0 : value?.weekStartsOn,
            },
          });

          dispatch(
            updateSidePanelData({
              fieldName: 'weekDatesValue',
              value: {
                startDate: moment
                  .tz(weekDatesValue?.startDate, value?.timeZoneId?.label)
                  .startOf('week'),
                endDate: moment
                  .tz(weekDatesValue?.startDate, value?.timeZoneId?.label)
                  .endOf('week'),
              },
            })
          );

          const params = {
            startDate: moment
              .tz(weekDatesValue?.startDate, value?.timeZoneId?.label)
              .startOf('week')
              .toISOString(),
            endDate: moment
              .tz(weekDatesValue?.startDate, value?.timeZoneId?.label)
              .endOf('week')
              .toISOString(),
            searchText: '',
          };

          if (value?.id !== 'all') {
            if (value?.locationId) {
              params.locationId = value?.locationId;
              params.areaId = value?.id;
            } else {
              params.locationId = value?.id;
              params.areaId = null;
            }
          } else {
            params.locationId = null;
            params.areaId = null;
          }

          updateLocationList(params);
        }}
        selectedItem={selectedLocation}
        loading={getLocationAreaDropdownActionLoader}
      />

      <EzyInputField
        placeholder="Search by people"
        className="search-box"
        value={searchText}
        onChange={handleSearch}
        onKeyDown={async (e) => {
          if (e.key === 'Enter') {
            await updateLocationList({ searchText: e?.target?.value });
          }
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconByName name="search" />
          </InputAdornment>
        }
      />

      <Paper className="timesheet-person-list-wrapper">
        {getPersonListBasedOnLocationActionLoader && <Loader />}
        {personList?.length > 0 ? (
          <List className="area-check-list">
            {personList?.map((value) => {
              return (
                <ListItem
                  key={value?.id}
                  disablePadding
                  className={value?.personBackgroundColor}
                  selected={selectedPerson?.id === value?.id}
                >
                  <ListItemButton
                    role={undefined}
                    onClick={() => {
                      dispatch(updateSidePanelData({ fieldName: 'selectedPerson', value }));

                      fetchTimeSheetByFilters({ personDetailId: value?.id });
                    }}
                    dense
                    disableRipple
                  >
                    <ListItemText className="publish-checks-view">
                      <div className="person-details">
                        <div className="name">{value?.name}</div>
                        <div className="details">
                          {value?.totalTimesheet > 1
                            ? `${value?.totalTimesheet} timesheets`
                            : `${value?.totalTimesheet} timesheet`}{' '}
                          | {value?.workingHours} hrs
                        </div>
                      </div>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        ) : (
          <Box className="no-data-found">No team members to display</Box>
        )}
      </Paper>
    </div>
  );
}

export default TimeSheetSideBar;

TimeSheetSideBar.propTypes = {
  updateLocationList: PropTypes.func.isRequired,
  fetchTimeSheetByFilters: PropTypes.func.isRequired,
};
