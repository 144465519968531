import { filter, isEmpty, isNil, omitBy } from 'lodash';
import { requireValidate } from '../../../utils/validationHelper';

const validateAddTaskForArea = (data) => {
  const {
    taskTitle,
    assignedArea,
    weekDays,
    datesOfMonth,
    taskRepeatationFrequency,
    subTasks,
    date,
    startTime,
    isAddToLaborModel,
  } = data ?? {};
  const error = {};
  error.taskTitle = requireValidate('Task title', taskTitle);
  error.assignedArea = requireValidate('Area ', assignedArea);
  error.repeat = requireValidate('Repeatation frequency', taskRepeatationFrequency);
  error.startTime = requireValidate('Start time', startTime);
  error.date = taskRepeatationFrequency === 1 ? requireValidate('Date', date) : null;
  error.weekDays = taskRepeatationFrequency === 3 ? requireValidate('WeekDays', weekDays) : null;
  error.datesOfMonth =
    taskRepeatationFrequency === 4 ? requireValidate('At least one date', datesOfMonth) : null;

  error.subTasks = subTasks?.map((e) => {
    const obj = {};
    obj.subTaskTitle = requireValidate('SubTaskTitle ', e?.subTaskTitle);
    if (isAddToLaborModel) {
      obj.totalHour = requireValidate('Hour', e?.totalHour);
      obj.totalMinute = requireValidate('Minute', e?.totalMinute);
    }
    return omitBy(obj, isNil);
  });

  error.subTasks = filter(error.subTasks, (e) => !isEmpty(e))?.length ? error.subTasks : null;
  return omitBy(error, isNil);
};

export default validateAddTaskForArea;
