import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'userData',
  initialState: {
    profileData: {},
  },
  reducers: {
    setUserData: (state, action) => {
      const { accessRoleResponse, ...rest } = action.payload;
      state.profileData = {
        showTimesheetToEmployee: state.profileData.showTimesheetToEmployee,
        ...rest,
        ...accessRoleResponse,
      };
    },
    updateUserData: (state, action) => {
      const { fieldName, value } = action.payload;
      state.profileData[fieldName] = value;
    },
  },
});

const { reducer, actions } = userSlice;

export const { setUserData, updateUserData } = actions;

export default reducer;
