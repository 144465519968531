import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import EzyMenu from '../../../components/common/menu/EzyMenu';
import IconByName from '../../../components/common/iconByName/IconByName';
import {
  updateAddBudgetDrawer,
  updateAddMetricDrawer,
  updateInsightFilters,
} from '../redux/insightSlice';
import ROUTE_CONSTANTS from '../../../utils/constants';
import EzyIconButton from '../../../components/common/iconButton/EzyIconButton';
import { getInsightListAction } from '../redux/insightAction';
import { getLocationAreaDropdownAction } from '../../timeSheets/redux/timeSheetsAction';
import useQueryParams from '../../../hooks/useQueryParams';
import Loader from '../../../components/common/loader/Loader';

function ListOfPages({ list, onItemClick, selectedLocation }) {
  const { selectedModule } = useSelector(({ insight }) => insight?.filters ?? {});

  return list?.map((item) => (
    <>
      {item?.title && (
        <div className="text-secondary-color body-text d-flex flex-align-center gap-5 just-bet">
          <div>{item?.title}</div>
          {item?.button && (
            <EzyIconButton
              iconName="add"
              onClick={() => item?.onButtonClick()}
              className="small"
              disabled={item?.disableAddButton}
            />
          )}
        </div>
      )}
      {item?.label && (
        <ListItem disablePadding selected={item?.label === selectedModule?.label}>
          <ListItemButton
            role={undefined}
            onClick={() => onItemClick(item)}
            dense
            disableRipple
            disabled={
              selectedLocation === 'all' &&
              (item?.label === 'Labor model' || item?.label === 'Required staff')
            }
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                tabIndex={-1}
                checked={item?.label === selectedModule?.label}
                disableRipple
                inputProps={{ 'aria-labelledby': `checkbox-list-label-${item?.id}` }}
                icon={<IconByName name="check_circle_outline" />}
                checkedIcon={<IconByName name="check_circle" className="primary-color" />}
              />
            </ListItemIcon>
            <ListItemText id={item?.id} className="publish-checks-view">
              <span>{item?.label}</span>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      )}
      {/* {item?.button && <EzyButton label={item?.button} onClick={() => item?.onButtonClick()} />} */}
    </>
  ));
}

ListOfPages.propTypes = {
  list: PropTypes.array.isRequired,
  onItemClick: PropTypes.func.isRequired,
  selectedLocation: PropTypes.string.isRequired,
};
function InsightSidebar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { sidePanelList, locationListDropdown, filters } = useSelector(
    ({ insight }) => insight ?? {}
  );
  const { pathname } = useParams();

  const { location: locationParam } = useQueryParams();

  const { summaryList, budgetList, metricList, staffList } = useMemo(
    () => sidePanelList,
    [sidePanelList]
  );
  const { getLocationAreaDropdownActionLoader, getInsightListActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const onListItemClick = (item) => {
    history.push(
      `${ROUTE_CONSTANTS.SCHEDULE}/insights/${item?.pathName}?location=${
        filters?.selectedLocation?.id
      }&date=${moment
        .tz(filters?.weekDatesValue?.startDate, filters?.selectedLocation?.timeZoneId?.label)
        .toISOString()}`
    );
    dispatch(updateInsightFilters({ fieldName: 'selectedModule', value: item }));
  };

  const list = [
    ...summaryList,
    ...budgetList.map((e) => {
      return {
        ...e,
        onButtonClick: () => {
          if (e?.button) dispatch(updateAddBudgetDrawer({ fieldName: 'isOpen', value: true }));
        },
        disableAddButton: filters?.selectedLocation?.id === 'all' || !filters?.selectedLocation?.id,
      };
    }),
    ...metricList.map((e) => {
      return {
        ...e,
        onButtonClick: () => {
          if (e?.button) dispatch(updateAddMetricDrawer({ fieldName: 'isOpen', value: true }));
        },
        disableAddButton: filters?.selectedLocation?.id === 'all',
      };
    }),
    ...staffList,
  ];

  useEffect(() => {
    (async () => {
      await dispatch(
        getLocationAreaDropdownAction(
          { locationId: locationParam },
          async (response) => {
            await moment.updateLocale('en', {
              week: {
                dow: response?.[0]?.weekStartsOn === 7 ? 0 : response?.[0]?.weekStartsOn,
              },
            });
          },
          false,
          true
        )
      );
      await dispatch(getInsightListAction(pathname));
    })();
  }, []);

  return (
    <div className="insight-side-menu">
      <EzyMenu
        className="insight-list-location-menu"
        menuItems={locationListDropdown}
        label={filters?.selectedLocation?.label || 'Location'}
        iconName="location_on"
        menuIcon="location_on"
        onItemClick={(e, value) => {
          dispatch(updateInsightFilters({ fieldName: 'selectedLocation', value }));
        }}
        selectedItem={filters?.selectedLocation}
        loading={getLocationAreaDropdownActionLoader}
      />
      <Paper>
        <List className="area-check-list">
          {getInsightListActionLoader ? (
            <Loader />
          ) : (
            <ListOfPages
              list={list}
              onItemClick={(e) => onListItemClick(e)}
              selectedLocation={filters?.selectedLocation?.id}
            />
          )}
        </List>
      </Paper>
    </div>
  );
}

InsightSidebar.propTypes = {};

export default InsightSidebar;
