import { Box } from '@mui/material';
import { DataGrid, GridCellModes, useGridApiContext } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import EzyInputField from '../inputField/EzyInputField';
import IconByName from '../iconByName/IconByName';

function EditableInputField(props) {
  const { id, value, field, onFieldChange, formatValue, row } = props;
  const apiRef = useGridApiContext();

  const handleChange = (event) => {
    apiRef.current.setEditCellValue({ id, field, value: formatValue(event?.target?.value) });
  };

  const handleRef = (element) => {
    if (element) {
      const input = element.querySelector(`input[value="${value}"]`);
      input?.focus();
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <div className="editable-input-field">
        <EzyInputField ref={handleRef} value={value} onChange={handleChange} type="number" />
        <div
          className="save-icon"
          onClick={(e) => {
            e.stopPropagation();
            apiRef.current.stopCellEditMode({ id, field });
            onFieldChange({ id, field, value, date: row?.date || row?.dayOfStatistics });
          }}
        >
          <IconByName name="check" />
        </div>
      </div>
    </Box>
  );
}

EditableInputField.propTypes = {
  id: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  field: PropTypes.any.isRequired,
  onFieldChange: PropTypes.func,
  formatValue: PropTypes.func,
  row: PropTypes.object,
};

EditableInputField.defaultProps = {
  onFieldChange: () => {},
  formatValue: () => {},
  row: {},
};

function EzyEditableTable({ rows, columns, ...rest }) {
  const [cellModesModel, setCellModesModel] = useState({});
  const [selectedCellParams, setSelectedCellParams] = React.useState(null);

  const cellMode = React.useMemo(() => {
    if (!selectedCellParams) {
      return 'view';
    }
    const { id, field } = selectedCellParams;
    return cellModesModel?.[id]?.[field]?.mode || 'view';
  }, [cellModesModel, selectedCellParams]);

  const handleCellKeyDown = React.useCallback(
    (params, event) => {
      if (cellMode === 'edit') {
        // Prevents calling event.preventDefault() if Tab is pressed on a cell in edit mode
        event.defaultMuiPrevented = true;
      }
    },
    [cellMode]
  );

  const handleCellEditStop = React.useCallback((params, event) => {
    event.defaultMuiPrevented = true;
  }, []);

  const handleCellFocus = React.useCallback((event) => {
    const row = event.currentTarget.parentElement;
    const id = row.dataset.id;
    const field = event.currentTarget.dataset.field;
    setSelectedCellParams({ id, field });
  }, []);

  const handleCellClick = React.useCallback((params) => {
    setCellModesModel((prevModel) => {
      if (params?.isEditable)
        return {
          ...prevModel,
          [params.id]: {
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      return { prevModel };
    });
  }, []);

  const valueForEdit = (value) => {
    if (typeof value === 'string') {
      if (value?.includes('h')) {
        const split = value?.split(' ');
        const hour = split[0]?.replace(/\D/g, '');
        const minute = split?.[1]?.replace(/\D/g, '') || 0;

        const val = +hour * 60 + +minute;
        return val / 60 || null;
      }
      if (value?.includes('-')) return value?.replace(/\D/g, '');

      if (value?.includes('$')) return value?.substring(1);
    }
    return +value;
  };

  const renderEditCell = (params) => {
    return (
      <EditableInputField {...params} value={valueForEdit(params?.value)} key={params?.row?.id} />
    );
  };

  const updatedColumns = columns?.map((e) => {
    if (e?.editable) {
      return {
        ...e,
        flex: 1,
        sortable: false,
        // minWidth: 140,
        // maxWidth: 300,
        renderEditCell: (e2) =>
          renderEditCell({ ...e2, onFieldChange: e?.onFieldChange, formatValue: e?.formatValue }),
      };
    }
    return {
      ...e,
      editable: false,
      sortable: false,
      flex: 1,
      // minWidth: e?.field === 'date' ? 200 : 140,
      // maxWidth: 300,
    };
  });

  return (
    <DataGrid
      rows={rows}
      columns={updatedColumns}
      onCellKeyDown={handleCellKeyDown}
      cellModesModel={cellModesModel}
      onCellEditStop={handleCellEditStop}
      onCellModesModelChange={(model) => setCellModesModel(model)}
      onCellClick={handleCellClick}
      hideFooter
      disableColumnMenu
      slotProps={{
        cell: {
          onFocus: handleCellFocus,
        },
      }}
      isCellEditable={(e) => {
        if (e?.row?.isTotalEditable !== null && e?.row?.isTotalEditable) {
          if (typeof e?.id === 'string' && e?.id?.includes('weeklyTotal')) return true;
          return false;
        }
        if (typeof e?.id === 'string' && (e?.id === 'grandTotal' || e?.id?.includes('weeklyTotal')))
          return false;
        return true;
      }}
      getRowClassName={(params) => params?.row?.id}
      {...rest}
    />
  );
}

EzyEditableTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};

export default EzyEditableTable;
