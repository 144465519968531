// import API_URL from '../urlConstants';
// eslint-disable-next-line import/no-cycle
import apiService from '../apiService';

export const commonServices = {
  downloadSample: (URL, { params } = {}) =>
    apiService.request({
      url: URL,
      method: 'GET',
      responseType: 'blob',
      timeout: 60000,
      params,
    }),

  // enumsLists: () => apiService.getData(API_URL.COMMON.getEnumsList),

  // getIndustryList: () => apiService.getData(API_URL.COMMON.industryList),
  // getCountryList: () => apiService.getData(API_URL.COMMON.countryList),
  // getAllTimeZones: () => apiService.getData(API_URL.COMMON.getAllTimeZones),
};
