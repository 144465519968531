import { isNil, omitBy } from 'lodash';
import moment from 'moment';
import {
  isEmail,
  requireValidate,
  validatePhoneNumberLength,
  validatePostCode,
} from '../../../utils/validationHelper';

const validateEditPeopleDetails = (data) => {
  const {
    preferredFullName,
    roleId,
    employmentType,
    primaryLocationId,
    workPeriodDetail,
    email,
    dateOfBirth,
    joiningDate,
    emergencyContactNumberData,
    emergencyContactNumber,
    phoneNumber,
    phoneNumberData,
  } = data ?? {};
  const error = {};
  error.preferredFullName = requireValidate('Name', preferredFullName);
  error.roleId = requireValidate('Access Level', roleId);
  error.email = isEmail('Email', email);
  error.employmentType = requireValidate('Employment Type', employmentType);
  error.primaryLocationId = requireValidate('Primary Location', primaryLocationId);
  if (
    joiningDate &&
    dateOfBirth &&
    (moment(joiningDate).isBefore(dateOfBirth) || moment(joiningDate).isSame(dateOfBirth))
  ) {
    error.joiningDate = `Joining date can't be same or smaller then birth date`;
  }
  if (workPeriodDetail?.workPeriodId && workPeriodDetail?.totalHours <= 0) {
    error.hours = '\xa0';
    error.minutes = 'Hours per work period is required!';
  }
  if (data.postCode && !validatePostCode(data.postCode)) {
    error.postCode = 'Invalid postal code!';
  }

  if (
    emergencyContactNumberData &&
    emergencyContactNumber &&
    !validatePhoneNumberLength(emergencyContactNumber, emergencyContactNumberData?.format)
  ) {
    error.emergencyContactNumber = 'Invalid phone number!';
  }

  if (
    phoneNumberData &&
    phoneNumber &&
    !validatePhoneNumberLength(phoneNumber, phoneNumberData?.format)
  ) {
    error.phoneNumber = 'Invalid phone number!';
  }
  return omitBy(error, isNil);
};

export default validateEditPeopleDetails;
