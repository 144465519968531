import apiService from '../../utils/apiService';
import API_URL from '../../utils/urlConstants';

export const peopleServices = {
  getPeopleList: (params) => apiService.getData(API_URL.PEOPLE.peopleList, { params }),
  getPeopleFilterByBusinessId: (params) =>
    apiService.getData(API_URL.PEOPLE.getPeopleFilterByBusinessIdAction, { params }),
  getPeopleDetails: (params) => apiService.getData(API_URL.PEOPLE.peopleDetails, { params }),
  GetPeoplePayDetails: (params) =>
    apiService.getData(API_URL.PEOPLE.getPeoplePayDetails, { params }),
  getAreaListByPerson: (params) =>
    apiService.getData(API_URL.PEOPLE.getAreaListByPerson, { params }),
  getWorkPeriodsByBusinessId: (params) =>
    apiService.getData(API_URL.PEOPLE.getWorkPeriodListByBusinessId, { params }),
  addPeopleData: (data) => apiService.postData(API_URL.PEOPLE.addPeople, data),
  addWorkPeriod: (data) => apiService.postData(API_URL.PEOPLE.saveWorkPeriod, data),
  editPeopleDetails: (data) => apiService.postData(API_URL.PEOPLE.editPeopleDetails, data),
  editPeoplePayDetails: (data) => apiService.postData(API_URL.PEOPLE.editPeoplePayDetails, data),
  importPeopleCSVFile: (data, config) =>
    apiService.postData(API_URL.PEOPLE.importPeopleCSVSample, data, {
      ...config,
      timeout: 2 * 60 * 1000,
    }),
  addBulkLocation: (data) => apiService.postData(API_URL.PEOPLE.addBulkLocation, data),
  addBulkAccess: (data) => apiService.postData(API_URL.PEOPLE.addBulkAccess, data),
  bulkSendInvitation: (data) => apiService.postData(API_URL.PEOPLE.bulkSendInvitation, data),
  sendShiftOfferInvitation: (data) =>
    apiService.postData(API_URL.PEOPLE.sendShiftOfferInvitation, data),
  addEditLeaveBalance: (data) => apiService.postData(API_URL.PEOPLE.addEditLeaveBalance, data),
  addLeaveRequest: (data) => apiService.postData(API_URL.PEOPLE.addLeaveRequest, data),
  getLeaveBalanceList: (params) => apiService.getData(API_URL.PEOPLE.leaveBalanceList, { params }),
  getLeaveRequestList: (params) => apiService.getData(API_URL.PEOPLE.leaveRequestList, { params }),
  getUpcomingShifts: (params) => apiService.getData(API_URL.PEOPLE.getUpcomingShifts, { params }),
  getPreviousShifts: (params) => apiService.getData(API_URL.PEOPLE.getPreviousShifts, { params }),
  getLeaveEntitlementEnumByPersonId: (params) =>
    apiService.getData(API_URL.PEOPLE.leaveEntitlementEnumByPersonId, { params }),
  getLeaveEntitlementEnumByBusinessId: (params) =>
    apiService.getData(API_URL.PEOPLE.leaveEntitlementEnumByBusinessId, { params }),
  getPeopleForOfferShift: (params) =>
    apiService.getData(API_URL.PEOPLE.getPeopleForOfferShift, { params }),
  deletePeopleById: (params) => apiService.deleteData(API_URL.PEOPLE.deletePeopleById, { params }),
  deletePeopleLeave: (params) =>
    apiService.deleteData(API_URL.PEOPLE.deletePeopleLeave, { params }),
  getExtraDetailsOnChangeLeaveDate: (params) =>
    apiService.getData(API_URL.PEOPLE.getExtraLeaveDetails, { params }),
  getLeaveDetailsByLeaveRequestId: (params) =>
    apiService.getData(API_URL.PEOPLE.leaveRequestByLeaveId, { params }),
  getNotInvitedPeopleList: (params) =>
    apiService.getData(API_URL.PEOPLE.getNotInvitedPeopleList, { params }),
  editLeaveRequest: (data) => apiService.postData(API_URL.PEOPLE.editLeaveRequest, data),
  leaveRequestActions: (data) => apiService.postData(API_URL.PEOPLE.leaveRequestActions, data),
  downloadBlukCsv: (data) => apiService.postData(API_URL.PEOPLE.downloadSelectedPeopleCsv, data),
  // resetLoginDetails: (data) => apiService.postData(),
  sendPasswordToMail: (email) => apiService.postData(API_URL.PEOPLE.forgotPassword, { email }),
  archivePeople: (data) => apiService.putData(API_URL.PEOPLE.archivePeople, data),
  resetLoginDetails: (data) => apiService.postData(API_URL.PEOPLE.resetLoginDetails, data),
  uploadProfilePicture: (data, config) =>
    apiService.postData(API_URL.PEOPLE.uploadProfilePicture, data, {
      ...config,
      timeout: 2 * 60 * 1000,
    }),
  deleteProfilePicture: (params) =>
    apiService.deleteData(API_URL.PEOPLE.deleteProfilePicture, { params }),
  sendVerificationMail: (data) => apiService.postData(API_URL.PEOPLE.sendVerificationMail, data),
  getShiftDetails: (params) =>
    apiService.getData(API_URL.DASHBOARD.getProfileDetailsByPersonID, { params }),
  startShift: (data) => apiService.postData(API_URL.DASHBOARD.startShiftByPersonID, data),
  getEndShiftDetails: (params) =>
    apiService.getData(API_URL.DASHBOARD.getEndShiftDetails, { params }),
  startShiftBreak: (data) => apiService.postData(API_URL.DASHBOARD.startShiftBreak, data),
  endShiftBreak: (data) => apiService.postData(API_URL.DASHBOARD.endBreak, data),
  endShift: (data) => apiService.postData(API_URL.DASHBOARD.endShift, data),
  importPeopleFromIntegration: (params) =>
    apiService.getData(API_URL.GLOBALPROFILE.importPeopleFromIntegration, { params }),
  getJournalListByPersonDetailId: (params) =>
    apiService.getData(API_URL.PEOPLE.getJournalListByPersonDetailId, { params }),
  addJournal: (data) => apiService.postData(API_URL.PEOPLE.addJournal, data),
  editJournal: (data) => apiService.putData(API_URL.PEOPLE.addJournal, data),
  deleteJournal: (params) => apiService.deleteData(API_URL.PEOPLE.addJournal, { params }),
  addUnavailability: (data) => apiService.postData(API_URL.PEOPLE.addUnavailability, data),
  getUnavailabilityList: (params) =>
    apiService.getData(API_URL.PEOPLE.unavailabilityList, { params }),
  deleteUnavailabilityById: (params) =>
    apiService.deleteData(API_URL.PEOPLE.deleteUnavailabilityById, { params }),
  syncWithXero: (data) => apiService.putData(API_URL.PEOPLE.syncWithXero, data),
  getAllFairWorkAwards: (params) =>
    apiService.getData(API_URL.PEOPLE.getAllFairWorkAwards, { params }),
  getAllWageAllowances: (params) =>
    apiService.getData(API_URL.PEOPLE.getAllWageAllowances, { params }),
  showTimeSheeting: () => apiService.getData(API_URL.PEOPLE.showTimeSheeting),
};
