import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import EzyDialog from '../../../components/common/dialog/EzyDialog';
import EzyDialogTitle from '../../../components/common/dialog/EzyDialogTitle';
import EzyDialogAction from '../../../components/common/dialog/EzyDialogAction';
import { archiveLocationAction } from '../redux/locationActions';
import IconByName from '../../../components/common/iconByName/IconByName';
import EzyAutoComplete from '../../../components/common/autoComplete/EzyAutoComplete';
import EzyAvatar from '../../../components/common/avatar/EzyAvatar';
import { updateArchiveLocationDetails } from '../redux/locationSlice';
import Loader from '../../../components/common/loader/Loader';
import ROUTE_CONSTANTS from '../../../utils/constants';
import { updateTimeSheetData } from '../../timeSheets/redux/timeSheetsSlice';

function ArchiveLocation({ isOpen, handleClose, rowData }) {
  const history = useHistory();
  const { archiveDetails, newArchiveLocation = null } = useSelector(
    ({ location }) => location?.archiveLocation ?? {}
  );

  const { archiveLocationActionLoader, getDetailsBeforeArchiveLocationActionLoader } = useSelector(
    ({ common }) => common?.generalLoader ?? {}
  );
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const data = {
      locationId: rowData?.locationId,
      isArchive: true,
      newLocationId: newArchiveLocation?.id,
    };

    dispatch(
      archiveLocationAction(data, () => {
        handleClose(true);
      })
    );
  };

  const archiveLocationButtons = [
    {
      label: 'Cancel',
      onClick: () => {
        handleClose();
      },
      color: 'secondary',
    },
    {
      label: 'Archive',
      onClick: handleSubmit,
      loading: archiveLocationActionLoader,
      disabled:
        archiveDetails?.numberOfTimeSheets > 0 ||
        archiveDetails?.totalPeopleWithUpcomingShift > 0 ||
        (archiveDetails?.people?.length > 0
          ? !newArchiveLocation?.id
          : !archiveDetails?.people?.length < 0),
    },
  ];

  return (
    <EzyDialog
      open={isOpen}
      className="common-dialog-with-body archive-dialog"
      handleClose={handleClose}
    >
      <EzyDialogTitle title="Archive" onClose={handleClose} />

      {getDetailsBeforeArchiveLocationActionLoader ? (
        <Loader />
      ) : (
        <>
          <div className="dialog-body">
            <span className="icon-small">Are you sure you want to archive this location?</span>

            {archiveDetails?.numberOfTimeSheets > 0 && (
              <div className="error-div">
                <IconByName name="error" />

                <div>
                  Please approve or discard {archiveDetails?.numberOfTimeSheets} timesheets before
                  proceeding.{' '}
                  <span
                    onClick={() => {
                      history.push(
                        `${ROUTE_CONSTANTS.TIMESHEETS}?location=${rowData?.locationId}&date=${archiveDetails?.timeSheetDate}&area=${archiveDetails?.areaId}`
                      );
                      dispatch(
                        updateTimeSheetData({
                          fieldName: 'viewTimeSheetDrawer',
                          value: archiveDetails?.timeSheetId,
                        })
                      );
                    }}
                  >
                    {' '}
                    Click Here{' '}
                  </span>
                  to view the first one.
                </div>
              </div>
            )}

            {archiveDetails?.totalPeopleWithUpcomingShift > 0 && (
              <div className="error-div">
                <IconByName name="error" />

                <div>
                  {archiveDetails?.totalPeopleWithUpcomingShift} team members have upcoming shifts
                  at this location. Remove these shifts or reassign the location before archiving.
                </div>
              </div>
            )}

            {archiveDetails?.people?.length > 0 && (
              <div className="icon-small">
                {archiveDetails?.people?.length} Employees must be migrated to another location
                before you can proceed.
              </div>
            )}

            {archiveDetails?.people?.length > 0 && (
              <EzyAutoComplete
                name="location"
                label="Choose a location to migrate employees:"
                options={archiveDetails?.location}
                placeholder="Select location"
                getOptionDisabled={(option) => {
                  return option.id === rowData.locationId;
                }}
                onChange={(_, value) => {
                  dispatch(
                    updateArchiveLocationDetails({ fieldName: 'newArchiveLocation', value })
                  );
                }}
                value={newArchiveLocation}
                required
              />
            )}

            {archiveDetails?.people?.length > 0 && (
              <Stack direction="row" spacing={2}>
                {archiveDetails?.people?.map((people) => {
                  return (
                    <EzyAvatar firstName={people?.name} imageLink={people?.profilePictureUrl} />
                  );
                })}
              </Stack>
            )}
          </div>
          <EzyDialogAction actionButtons={archiveLocationButtons} />
        </>
      )}
    </EzyDialog>
  );
}

export default ArchiveLocation;

ArchiveLocation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  rowData: PropTypes.object,
  handleClose: PropTypes.func,
};

ArchiveLocation.defaultProps = {
  handleClose: null,
  rowData: {},
};
