import { Checkbox, List, ListItem, ListItemButton } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import IconByName from '../../../../components/common/iconByName/IconByName';
import {
  resetEditPeopleShiftDetailsField,
  updateEditPeopleShiftDetailsField,
} from '../../redux/peopleSlice';
import { getShiftDetailsArea, getShiftDetailsByPersonID } from '../../redux/peopleActions';

function SelectAreaDrawer({ isFromDashboard }) {
  const dispatch = useDispatch();
  const peopleData = useSelector(({ people }) => people ?? {});
  const param = useParams();
  const { shiftData } = useMemo(() => peopleData?.editPeople?.details, [peopleData]);
  const { getShiftDetailsAreaLoader } = useSelector(({ common }) => common.generalLoader ?? {});
  const { profileData } = useSelector(({ user }) => user || {});
  const userData = useMemo(() => profileData, [profileData]);
  const peopleId = isFromDashboard ? userData?.personId : param?.peopleId;
  const isFromProfile = !isFromDashboard ?? false;
  const startUnScheduledShiftData = {
    personDetailId: peopleId,
    areaId: shiftData?.selectedShiftArea?.areaId,
    isFromDashboard,
    isFromProfile,
  };

  const handelSelectArea = (value) => {
    dispatch(updateEditPeopleShiftDetailsField({ fieldName: 'selectedShiftArea', value }));
  };

  const handelDrawerClose = () => {
    dispatch(resetEditPeopleShiftDetailsField());
  };

  const handelStartShift = () => {
    if (shiftData?.selectedShiftArea?.isAllowToClockInFromWeb) {
      dispatch(
        getShiftDetailsArea(startUnScheduledShiftData, () => {
          dispatch(getShiftDetailsByPersonID(peopleId));
          handelDrawerClose();
        })
      );
    } else {
      handelDrawerClose();
      dispatch(
        updateEditPeopleShiftDetailsField({ fieldName: 'shiftStartStopWarningModal', value: true })
      );
    }
  };

  const actionButton = [
    {
      label: 'Start Shift',
      onClick: handelStartShift,
      disabled: !shiftData?.selectedShiftArea,
      loading: getShiftDetailsAreaLoader,
    },
  ];

  useEffect(() => {
    if (shiftData?.isSelectAreaDrawerEnable)
      dispatch(getShiftDetailsArea({ personDetailId: peopleId }));
  }, [shiftData?.isSelectAreaDrawerEnable]);

  return (
    <EzyDrawer
      onClose={handelDrawerClose}
      isOpen={shiftData?.isSelectAreaDrawerEnable}
      heading="Select Area"
      actionButtons={actionButton}
      isLoading={getShiftDetailsAreaLoader}
    >
      <div className="edit-people-area-list-wrapper">
        <List className="edit-people-area-list">
          {shiftData?.areaList?.map((list) => (
            <ListItem
              key={list?.areaId}
              selected={list?.areaId === shiftData?.selectedShiftArea?.areaId}
              disablePadding
            >
              <ListItemButton dense disableRipple onClick={() => handelSelectArea(list)}>
                <Checkbox
                  edge="start"
                  checked={list?.areaId === shiftData?.selectedShiftArea?.areaId}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': 'labelId' }}
                  icon={<IconByName name="check_circle_outline" />}
                  checkedIcon={<IconByName name="check_circle" className="primary-color" />}
                />
                <span>{`${list?.label} - [${list?.locationCode}]`}</span>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
    </EzyDrawer>
  );
}
export default SelectAreaDrawer;
SelectAreaDrawer.propTypes = {
  isFromDashboard: PropTypes.bool,
};

SelectAreaDrawer.defaultProps = {
  isFromDashboard: false,
};
