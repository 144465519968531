import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { Chip, Paper, useMediaQuery } from '@mui/material';
import { useHistory } from 'react-router-dom';
import WeekPicker from '../../../../components/common/dateAndTimePicker/WeekPicker';
import EzyAutoComplete from '../../../../components/common/autoComplete/EzyAutoComplete';
import EzyMenu from '../../../../components/common/menu/EzyMenu';
import EzyIconButton from '../../../../components/common/iconButton/EzyIconButton';
import EzyButton from '../../../../components/common/button/EzyButton';
import EzyTable from '../../../../components/common/table/EzyTable';
import {
  updateRunLaborModalDrawer,
  updateStaffFilters,
  updateStaffSettingsDrawer,
} from '../../redux/insightSlice';
import StaffSettingDrawer from './StaffSettingDrawer';
import {
  displayDate,
  displayDateTime,
  weekDatesValueBasedOnDuration,
} from '../../../../utils/timeHelper';
import ROUTE_CONSTANTS, { DurationFilter } from '../../../../utils/constants';
import { getStaffListAction } from '../../redux/insightAction';
import RunLaborModalDrawer from './RunLaborModalDrawer';
import EzyDatePicker from '../../../../components/common/dateAndTimePicker/EzyDatePicker';

function Staff() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { filters, staff } = useSelector(({ insight }) => insight ?? {});
  const { selectedLocation } = useMemo(() => filters, [filters]);
  const { weekDatesValue, areaIdList, duration } = useMemo(
    () => staff?.staffFilters,
    [staff?.staffFilters]
  );
  const isTabletScreen = useMediaQuery('(max-width: 1024px)');

  const { getStaffListActionLoader } = useSelector(({ common }) => common.generalLoader ?? {});

  const handleFilterChange = (fieldName, value) => {
    dispatch(updateStaffFilters({ fieldName, value }));
  };

  const staffListHeaders = useMemo(() => {
    const headers = [];

    headers.push({
      name: 'day',
      label: 'Date',
      format: (value) => {
        if (duration?.label === 'Day') {
          return displayDateTime(value, 'UTC');
        }
        return displayDate(value, 'UTC');
      },
    });

    if (areaIdList?.length === 0) {
      headers.push({
        name: 'locationName',
        label: staff?.staffList?.[0]?.locationName,
        format: (value, row) => row?.locationValue,
      });
    }
    if (areaIdList?.length > 0 && staff?.staffList?.length > 0) {
      headers?.push(
        ...staff.staffList[0].areaValueList.map((e) => {
          return {
            name: 'value',
            label: e?.areaId?.label,
            format: (value, rowData) => {
              const val = rowData?.areaValueList?.find(
                (area) => area?.areaId?.label === e?.areaId?.label
              )?.value;

              return val;
            },
          };
        })
      );
    }

    headers.push({
      name: 'total',
      label: 'Total',
    });

    return headers;
  }, [staff?.staffList, areaIdList]);

  const getStaffListByFilters = (param = {}) => {
    const data = {
      locationId: selectedLocation?.id,
      startDate: moment.tz(weekDatesValue?.startDate, 'UTC').toISOString(),
      endDate: moment.tz(weekDatesValue?.endDate, 'UTC').toISOString(),
      repetitionFrequency: duration?.id,
      areaIdList,
      ...param,
    };
    data.areaIdList = selectedLocation?.areaList
      ?.filter((e) => data?.areaIdList?.includes(e?.label))
      ?.map((e) => e?.id);
    if (selectedLocation?.id && selectedLocation?.id !== 'all') dispatch(getStaffListAction(data));
  };

  useEffect(() => {
    const dates = weekDatesValueBasedOnDuration(weekDatesValue, duration);
    handleFilterChange('areaIdList', selectedLocation?.areaList?.map((e) => e?.label) ?? []);
    handleFilterChange('weekDatesValue', dates);

    getStaffListByFilters({
      areaIdList: selectedLocation?.areaList?.map((e) => e?.label) ?? [],
      startDate: moment.tz(dates?.startDate, 'UTC')?.toISOString(),
      endDate: moment.tz(dates?.endDate, 'UTC').toISOString(),
    });
  }, [selectedLocation]);

  return (
    <div className="staff-list-wrapper">
      <div className="staff-header">
        {duration?.id === 5 ? (
          <EzyDatePicker
            timeZone="UTC"
            value={moment.tz(weekDatesValue?.startDate, 'UTC')}
            onChange={async (e) => {
              const data = weekDatesValueBasedOnDuration({ startDate: e }, duration);
              handleFilterChange('weekDatesValue', data);
              await getStaffListByFilters({
                startDate: moment.tz(data?.startDate, 'UTC')?.toISOString(),
                endDate: moment.tz(data?.endDate, 'UTC').toISOString(),
              });
            }}
          />
        ) : (
          <WeekPicker
            variant="outlined"
            value={weekDatesValue}
            onChange={async (e) => {
              const data = weekDatesValueBasedOnDuration(e, duration);
              handleFilterChange('weekDatesValue', data);
              await getStaffListByFilters({
                startDate: moment.tz(data?.startDate, 'UTC')?.toISOString(),
                endDate: moment.tz(data?.endDate, 'UTC').toISOString(),
              });
            }}
            onNextClick={async () => {
              const dates = {
                startDate: moment
                  .tz(weekDatesValue?.startDate, 'UTC')
                  .add(duration.dayCount, 'days'),
                endDate: moment.tz(weekDatesValue?.endDate, 'UTC').add(duration.dayCount, 'days'),
              };

              if (duration?.label === 'Month') {
                dates.startDate = moment
                  .tz(weekDatesValue?.endDate, 'UTC')
                  .add(1, 'days')
                  .startOf('month');
                dates.endDate = moment
                  .tz(weekDatesValue?.endDate, 'UTC')
                  .add(1, 'days')
                  .endOf('month');
              }
              handleFilterChange('weekDatesValue', dates);

              await getStaffListByFilters({
                startDate: moment.tz(dates?.startDate, 'UTC')?.toISOString(),
                endDate: moment.tz(dates?.endDate, 'UTC').toISOString(),
              });
            }}
            onPrevClick={async () => {
              const dates = {
                startDate: moment
                  .tz(weekDatesValue?.startDate, 'UTC')
                  .subtract(duration.dayCount, 'days'),
                endDate: moment
                  .tz(weekDatesValue?.endDate, 'UTC')
                  .subtract(duration.dayCount, 'days'),
              };

              if (duration?.label === 'Month') {
                dates.startDate = moment
                  .tz(weekDatesValue?.startDate, 'UTC')
                  .subtract(1, 'days')
                  .startOf('month');
                dates.endDate = moment
                  .tz(weekDatesValue?.startDate, 'UTC')
                  .subtract(1, 'days')
                  .endOf('month');
              }
              handleFilterChange('weekDatesValue', dates);
              await getStaffListByFilters({
                startDate: moment.tz(dates?.startDate, 'UTC')?.toISOString(),
                endDate: moment.tz(dates?.endDate, 'UTC').toISOString(),
              });
            }}
            timeZone="UTC"
            startWeekFrom={
              selectedLocation?.weekStartsOn === 7 ? 0 : selectedLocation?.weekStartsOn
            }
          />
        )}
        <EzyAutoComplete
          multiple
          limitTags={1}
          id="tags-outlined"
          className={`staff-area-select ${areaIdList?.length ? 'auto-complete-tags' : ''}`}
          placeholder="Add area"
          name="areaIdList"
          value={areaIdList}
          options={selectedLocation?.areaList?.map((area) => area?.label)}
          renderTags={(value, getTagProps) =>
            value.map((option, chipIndex) => (
              <Chip
                variant="outlined"
                color="primary"
                label={option}
                deleteIcon={<span className="material-icons">highlight_off</span>}
                {...getTagProps({ chipIndex })}
              />
            ))
          }
          onChange={(_, value) => {
            handleFilterChange('areaIdList', value);
            getStaffListByFilters({
              areaIdList: value,
            });
          }}
        />
        <EzyMenu
          menuItems={DurationFilter}
          label={duration?.label || 'Duration'}
          onItemClick={(e, item) => {
            handleFilterChange('duration', item);

            const data = weekDatesValueBasedOnDuration(weekDatesValue, item);

            handleFilterChange('weekDatesValue', data);

            getStaffListByFilters({
              repetitionFrequency: item?.id,
              startDate: moment.tz(data?.startDate, 'UTC')?.toISOString(),
              endDate: moment.tz(data?.endDate, 'UTC').toISOString(),
            });
          }}
          selectedItem={duration}
        />

        <EzyIconButton iconName="edit" onClick={() => history.push(ROUTE_CONSTANTS.STAFF_EDIT)} />
        <EzyIconButton
          iconName="settings"
          onClick={() => dispatch(updateStaffSettingsDrawer({ fieldName: 'isOpen', value: true }))}
        />
        <EzyButton
          label={isTabletScreen ? 'Run' : 'Run labor model'}
          color="secondary"
          onClick={() => {
            dispatch(updateRunLaborModalDrawer({ fieldName: 'isOpen', value: true }));
          }}
          className="labor-model-button"
        />

        <EzyButton
          className={isTabletScreen ? 'no-pad-button' : ''}
          label="Back"
          color="secondary"
          onClick={() => history.push(ROUTE_CONSTANTS.SCHEDULE)}
        />
      </div>

      <div className="staff-list">
        <Paper className="table-paper">
          <EzyTable
            headers={staffListHeaders}
            rows={staff?.staffList}
            loading={getStaffListActionLoader}
          />
        </Paper>
      </div>
      <StaffSettingDrawer />
      <RunLaborModalDrawer isFromRequiredStaff />
    </div>
  );
}

Staff.propTypes = {};

export default Staff;
