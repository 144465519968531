import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import EzyDrawer from '../../../components/common/drawer/Drawer';
import { resetAddEditShiftFields } from '../redux/scheduleSlice';
import { getSwapShiftDetailsAction, swapRequestAdminAction } from '../redux/scheduleActions';
import IconByName from '../../../components/common/iconByName/IconByName';
import { displayDateTime } from '../../../utils/timeHelper';

function GetCard(props) {
  const { preferredName, startTime, endTime, locationName, mealBreakCount, areaName } = props ?? {};
  return (
    <div className="swap-details-card">
      <div className="card-header">{preferredName}</div>
      <div className="card-body">
        <div className="d-flex flex-align-center gap-10 text-secondary-color">
          <IconByName name="timer" />
          <span>{displayDateTime(startTime)}</span>
        </div>
        <div className="d-flex flex-align-center gap-10 text-secondary-color">
          <IconByName name="timer_off" />
          <span>{displayDateTime(endTime)}</span>
        </div>
        <div className="d-flex flex-align-center gap-10 text-secondary-color">
          <IconByName name="fastfood" />
          <span>{mealBreakCount} x Meal Break</span>
        </div>
        <div className="d-flex flex-align-center gap-10 text-secondary-color">
          <IconByName name="location_on" />
          <span>
            {areaName} at {locationName}
          </span>
        </div>
      </div>
    </div>
  );
}

function SwapShiftDrawer({ reFetchEvents }) {
  const dispatch = useDispatch();
  const shiftData = useSelector(({ schedule }) => schedule?.addEditShift ?? {});
  const { isSwapShiftRequest, swapDetails } = useMemo(() => shiftData ?? {}, [shiftData]);
  const { costDifference, receiverShiftDetails, requestorShiftDetails } = useMemo(
    () => swapDetails ?? {},
    [swapDetails]
  );

  const { swapRequestAdminActionLoader, getSwapShiftDetailsActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const handleClose = () => {
    dispatch(resetAddEditShiftFields());
  };

  const handleSubmit = async (isApproved = false) => {
    const data = {
      shiftRequestId: requestorShiftDetails?.shiftId,
      shiftReceiverId: receiverShiftDetails?.shiftId,
      isApproved,
    };
    await dispatch(
      swapRequestAdminAction(data, () => {
        reFetchEvents();
        handleClose();
      })
    );
  };

  const actionButton = [
    {
      label: 'Decline',
      onClick: () => handleSubmit(),
      loading: swapRequestAdminActionLoader,
      color: 'error',
    },
    {
      label: 'Approve',
      onClick: () => handleSubmit(true),
      loading: swapRequestAdminActionLoader,
    },
  ];

  useEffect(() => {
    if (isSwapShiftRequest) dispatch(getSwapShiftDetailsAction({ shiftId: isSwapShiftRequest }));
  }, [isSwapShiftRequest]);

  return (
    <EzyDrawer
      onClose={handleClose}
      isOpen={isSwapShiftRequest}
      actionButtons={actionButton}
      heading="Swap Shift"
      isLoading={getSwapShiftDetailsActionLoader}
    >
      <div className="shift-drawer">
        <div className="swap-details-row">
          <GetCard {...requestorShiftDetails} />
          <IconByName name="swap_horizontal_circle" className="primary-color p-10" />
          <GetCard {...receiverShiftDetails} />
        </div>
        <div className="title-with-primary-bg">
          <div>
            Total saving for swapping {requestorShiftDetails?.preferredName}&nbsp;and &nbsp;
            {receiverShiftDetails?.preferredName}
          </div>
          <div>{costDifference}</div>
        </div>
      </div>
    </EzyDrawer>
  );
}

export default SwapShiftDrawer;

SwapShiftDrawer.propTypes = {
  reFetchEvents: PropTypes.func.isRequired,
};
