import { filter, isEmpty, isNil, omitBy, toLower } from 'lodash';
import moment from 'moment';
import { findDuplicates, isAlphanumeric, requireValidate } from '../../../utils/validationHelper';

const validateGeneralEditLocation = (data) => {
  const {
    locationName,
    locationAddress,
    locationCode,
    timeZoneId,
    addAreaAddress,
    areas,
    generalSettings,
    city,
  } = data ?? {};
  const error = {};
  error.locationName = requireValidate('Location Name', locationName);
  error.locationAddress = requireValidate('Location Address', locationAddress);
  error.locationCode = isAlphanumeric('Location Code', locationCode);
  error.city = requireValidate('City', city);
  if (locationCode !== locationCode?.toUpperCase()) {
    error.locationCode = 'Only uppercase alphanumeric value allowed.';
  }
  error.timeZoneId = requireValidate('Time Zone', timeZoneId);
  error.areas = areas?.map((e) =>
    omitBy(
      {
        areaName: requireValidate('Area Name', e?.areaName),
        areaAddress: addAreaAddress ? requireValidate('Area Address', e?.areaAddress) : null,
      },
      isNil
    )
  );
  error.weekDay = generalSettings?.operatingHours?.weekDay?.map((e) =>
    omitBy(
      {
        startTime:
          e?.isActive && !e?.is24Hours ? requireValidate('Start Time', e?.startTime) : null,
        endTime: e?.isActive && !e?.is24Hours ? requireValidate('End Time', e?.endTime) : null,
      },
      isNil
    )
  );

  generalSettings?.operatingHours?.weekDay?.forEach((e, index) => {
    if (e?.isActive && !e?.is24Hours && moment(e?.endTime).isBefore(moment(e?.startTime))) {
      if (!error.weekDay || error.weekDay.length <= 0) {
        error.weekDay = [];
      } else {
        error.weekDay[index].endTime = `End time can't be smaller then start time`;
      }
    }
  });

  const duplicateAras = findDuplicates(areas?.map((e) => toLower(e?.areaName)));
  if (duplicateAras?.length > 0) {
    if (!error.areas || error.areas.length <= 0) {
      error.areas = [];
    }
    duplicateAras?.forEach((e) => {
      error.areas[e].areaName = 'Area name must be unique!';
    });
  }
  error.areas = filter(error.areas, (e) => !isEmpty(e))?.length ? error.areas : null;
  error.weekDay = filter(error.weekDay, (e) => !isEmpty(e))?.length ? error.weekDay : null;

  return omitBy(error, isNil);
};

export default validateGeneralEditLocation;
