import _, { isEqual, sortBy, toLower, upperFirst } from 'lodash';

export const REGEX = {
  EMAIL: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  EMAIL_WITH_ONE: /^\w+([.-]?\w+)(\+[a-z0-9-]+)?@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  PHONE: /^\d{7,12}$/,
  ONLY_ALPHABETIC: /^[a-zA-Z]+$/,
  ONLY_ALPHABETIC_WITHSPACE: /^[a-zA-Z\s]+$/,
  ALPHA_NUMERIC: /^[0-9a-zA-Z]+$/,
  NUMBER_REGEX: /^\d+$/,
  DECIMAL_REGEX: /^\d*(\.\d{0,2})?$/,
  PASSWORD_REGEX: /^(?=.*\d)(?=.*[!@#$%^&*+-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
  POST_CODE: /^[0-9a-zA-Z]+(?:[-\s][0-9a-zA-Z]+)?$/,
};

export const validateEmail = (emailAddress) =>
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? REGEX.EMAIL_WITH_ONE.test(emailAddress?.toString()?.trim())
    : REGEX.EMAIL.test(emailAddress?.toString()?.trim());
export const validatePhone = (phoneNumber) => REGEX.PHONE.test(phoneNumber);
export const onlyAlphabets = (string) => REGEX.ONLY_ALPHABETIC.test(string);
export const validateAlphaNumeric = (string) => REGEX.ALPHA_NUMERIC.test(string);
export const validatePostCode = (string) => REGEX.POST_CODE.test(string);

export const validatePhoneNumberLength = (value, format) => {
  let isValidLength = true;
  if (format?.match(/\./g).length <= 15 && format?.match(/\./g).length !== value?.length) {
    isValidLength = false;
  } else if (format?.match(/\./g).length > 15 && value?.length < 15) {
    isValidLength = false;
  } else {
    isValidLength = true;
  }
  return isValidLength;
};

export const isEmpty = (val) => {
  let isValEmpty = true;
  if (!_.isNil(val) && _.trim(String(val)).length > 0) {
    isValEmpty = false;
  }
  return isValEmpty;
};

export const isEmail = (fieldName, val) => {
  if (isEmpty(val)) {
    return upperFirst(toLower(`${fieldName} is required!`));
  }
  if (!validateEmail(val)) {
    return upperFirst(toLower(`Invalid ${fieldName} address.`));
  }
  return null;
};
export const isAlphanumeric = (fieldName, val) => {
  if (isEmpty(val)) {
    return upperFirst(toLower(`${fieldName} is required!`));
  }
  if (!validateAlphaNumeric(val)) {
    return upperFirst(toLower(`Only Alphanumeric value allowed.`));
  }
  return null;
};
export const requireValidate = (fieldName, value) => {
  if (Array.isArray(value) && value?.length <= 0) {
    return upperFirst(toLower(`${fieldName} is required!`));
  }
  if (!value?.toString()?.trim()?.length || value === undefined || value === null) {
    return upperFirst(toLower(`${fieldName} is required!`));
  }
  return null;
};

export const checkIsEmpty = (fieldName, value) => {
  const isValue =
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0);
  if (isValue) {
    return upperFirst(toLower(`${fieldName} is required!`));
  }
  return null;
};

export const passwordValidate = (
  fieldName,
  password = '',
  isShowValidation = false,
  isConfirmPassword = false,
  confirmPassword = ''
) => {
  if (!password && !isConfirmPassword) {
    return upperFirst(toLower(`${fieldName} is required!`));
  }
  if (!REGEX.PASSWORD_REGEX.test(password) && isShowValidation && fieldName !== 'confirmPassword') {
    return upperFirst(
      toLower(
        `The ${fieldName} should contain at least 8 letters, one uppercase and one special character.`
      )
    );
  }
  if (isConfirmPassword && password !== confirmPassword) {
    return upperFirst(toLower(`Password and Confirm Password does not match.`));
  }

  return null;
};

export const findDuplicates = (array) => {
  const results = [];
  if (array?.length > 0) {
    const arr = array.filter((e) => !!e);
    const cache = {};
    for (let i = 0, len = arr?.length; i < len; i += 1) {
      if (cache[arr[i]] === true) {
        results.push(i);
      } else {
        cache[arr[i]] = true;
      }
    }
  }
  return results;
};

export const compareArrays = (a, b) => isEqual(sortBy(a), sortBy(b));
