import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import EzyDialog from '../../../../components/common/dialog/EzyDialog';
import IconByName from '../../../../components/common/iconByName/IconByName';
import img from '../../../../assets/images/Trial.svg';
import useQueryParams from '../../../../hooks/useQueryParams';
import ROUTE_CONSTANTS from '../../../../utils/constants';

function PlanExpiredUser() {
  const [open, setOpen] = useState(false);
  const { planExpired = false } = useQueryParams();
  const history = useHistory();

  const onClose = () => {
    setOpen(false);
    history.push(ROUTE_CONSTANTS.ACCOUNT_OVERVIEW);
  };

  useEffect(() => {
    setOpen(!!planExpired);
  }, [planExpired]);

  return (
    <EzyDialog handleClose={onClose} open={open} className="common-info-modal">
      <IconByName name="close" className="pointer-cursor close-icon" onClick={onClose} />
      <img src={img} alt="payrollEzy" className="info-modal-image" />
      <div className="subtitle-text">
        Sorry this account is locked due to plan expiry. Please contact your administrator to
        upgrade the account. Or head back to PayrollEzy.
      </div>
    </EzyDialog>
  );
}

export default PlanExpiredUser;
