import * as React from 'react';
import { useEffect, useMemo } from 'react';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useUrlParamsUpdate from '../../../../hooks/useURLParamsUpdate';
import useQueryParams from '../../../../hooks/useQueryParams';
import EzyTable from '../../../../components/common/table/EzyTable';
import EzyPagination from '../../../../components/common/pagination/EzyPagination';
import { getUpcomingShiftsAction } from '../../redux/peopleActions';
import { editShiftDetails } from '../../redux/peopleSlice';
import ReplaceShiftDrawer from './ReplaceShiftDrawer';

function UpComingShiftList() {
  const { upComingShifts } = useSelector(({ people }) => people?.shiftDetails ?? {});
  // const history = useHistory();

  const { docs, headers, total, pages, page, limit } = useMemo(
    () => upComingShifts ?? {},
    [upComingShifts]
  );

  const { getUpcomingShiftsActionLoader } = useSelector(({ common }) => common.generalLoader ?? {});
  const { peopleId } = useParams();

  const { page: paramPage, limit: paramLimit } = useQueryParams();
  const dispatch = useDispatch();

  useUrlParamsUpdate({
    page: page ?? 1,
    limit: limit ?? 15,
  });

  const getShiftListByFilter = async (params = {}) => {
    const data = {
      page: page ?? 1,
      limit: limit ?? 15,
      personDetailsId: peopleId,
      ...params,
    };
    await dispatch(getUpcomingShiftsAction(data));
  };

  const pageActionClick = async (newPage) => {
    await getShiftListByFilter({ page: newPage, limit });
  };
  const onSelectLimit = async (newLimit) => {
    await getShiftListByFilter({ page: 1, limit: newLimit });
  };

  const onReplaceClick = (e) => {
    dispatch(editShiftDetails({ fieldName: 'shiftId', value: e?.shiftId }));
    dispatch(editShiftDetails({ fieldName: 'replaceShiftDrawer', value: true }));
  };

  const getActions = ({ isPublished }) => {
    const actions = [];

    if (isPublished) {
      actions.push({
        label: 'Replace',
        onClick: onReplaceClick,
        access: 'EDITEMPSCHED',
      });
    } else {
      actions.push('DISABLED');
    }

    return actions;
  };

  const getRowData = () =>
    docs?.map((dt) => ({
      ...dt,
      action: getActions(dt),
    }));

  useEffect(() => {
    const params = {
      page: paramPage ?? page ?? 1,
      limit: paramLimit ?? limit ?? 15,
    };
    getShiftListByFilter({ ...params });
  }, []);

  return (
    <Paper className="table-paper">
      <EzyTable
        headers={headers}
        rows={getRowData()}
        loading={getUpcomingShiftsActionLoader}
        onRowClick={(e) => {
          if (e?.isPublished) onReplaceClick(e);
        }}
        accessForRow={['VIEWEMPSCHED', 'EDITEMPSCHED']}
        accessForAction="EDITEMPSCHED"
      />
      <EzyPagination
        total={total}
        pages={pages}
        pageActionClick={pageActionClick}
        limit={limit}
        page={page}
        onChangeLimit={onSelectLimit}
      />
      <ReplaceShiftDrawer />
    </Paper>
  );
}

export default UpComingShiftList;
