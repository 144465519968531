import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import EzyDialog from '../../../../components/common/dialog/EzyDialog';
import IconByName from '../../../../components/common/iconByName/IconByName';
import img from '../../../../assets/images/Trial.svg';
import useQueryParams from '../../../../hooks/useQueryParams';
import ROUTE_CONSTANTS from '../../../../utils/constants';
import EzyButton from '../../../../components/common/button/EzyButton';
import ConfirmationModal from '../../../../components/common/modal/ConfirmationModal';
import { deleteBusinessByBusinessId } from '../../redux/globalProfileActions';
import { resetSettingsData } from '../../redux/globalProfileSlice';

function PlanExpiredAdmin() {
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [open, setOpen] = useState(false);
  const { planExpired = false } = useQueryParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { businessId } = useSelector(({ user }) => user?.profileData || {});

  const onClose = () => {
    setOpen(false);
    history.push(`${ROUTE_CONSTANTS.ACCOUNT_OVERVIEW}/plans/${businessId}`);
  };

  const handelDeleteBusiness = () => {
    const data = { businessId };
    dispatch(
      deleteBusinessByBusinessId(data, () => {
        dispatch(resetSettingsData());
        setConfirmationModal(false);
        history.push(ROUTE_CONSTANTS.ACCOUNT_OVERVIEW);
      })
    );
  };

  useEffect(() => {
    setOpen(!!planExpired);
  }, [planExpired]);

  return (
    <div>
      <EzyDialog handleClose={onClose} open={open} className="common-info-modal">
        <IconByName name="close" className="pointer-cursor close-icon" onClick={onClose} />
        <img src={img} alt="payrollEzy" className="info-modal-image" />
        <div className="title">Your current plan is expired</div>
        <div className="subtitle-text">
          To retain all of your data, you need to choose a plan. For more information visit our
          pricing page.
        </div>
        <EzyButton
          color="error"
          label="Delete and Reset"
          onClick={() => {
            setOpen(false);
            setConfirmationModal(true);
          }}
        />
      </EzyDialog>
      <ConfirmationModal
        title="Delete business"
        isOpen={confirmationModal}
        handleClose={() => {
          setConfirmationModal(false);
        }}
        handleSubmit={handelDeleteBusiness}
        primaryLabel="Delete"
      >
        <div>Are you sure you want to delete this business?</div>
      </ConfirmationModal>
    </div>
  );
}

export default PlanExpiredAdmin;
