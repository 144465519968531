import axios from 'axios';
import { clearAuthTokenFromLocalStorage, getAuthTokenFromLocalStorage } from './localStorageHelper';
import ROUTE_CONSTANTS from './constants';
import store from '../redux/store';
// eslint-disable-next-line import/no-cycle
import { handlePaymentRequired } from './backOfficeLists/backOfficeListActions';

const instance = axios.create({
  timeout: 20 * 1000,
  params: {},
});

const sourceRequest = {};

instance.interceptors.request.use(
  async (request) => {
    const token = `bearer ${getAuthTokenFromLocalStorage()}`;

    if (token) {
      request.headers.authorization = token;
    }

    if (sourceRequest[request.url]) {
      sourceRequest[request.url].cancel('Previous same call cancellation');
    }

    const axiosSource = axios.CancelToken.source();
    sourceRequest[request.url] = { cancel: axiosSource.cancel };
    request.cancelToken = axiosSource.token;

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const apiService = {
  request(config = {}) {
    return instance.request(config);
  },
  getData(url, config = {}) {
    return instance.get(url, config);
  },
  postData(url, data, config) {
    return instance.post(url, data, config);
  },
  putData(url, data, config) {
    return instance.put(url, data, config);
  },
  patchData(url, data) {
    return instance.patch(url, data);
  },
  deleteData(url, config = {}) {
    return instance.delete(url, config);
  },
};

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const resType = error?.request?.responseType;
    const statusCode = error?.response?.status ?? 0;
    if (resType === 'blob') {
      const err = await error?.response?.data?.text();
      return Promise.reject(JSON.parse(err));
    }
    switch (statusCode) {
      case 401:
        clearAuthTokenFromLocalStorage();
        store.dispatch({ type: 'USER_LOGOUT' });
        // try {
        //     const response = await ApiService.deleteData(HEADER_URLS.LOGOUT_URL);
        //     if (response?.data?.status === 'SUCCESS') {
        //         store.dispatch({
        //             type: LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION,
        //         });
        //     }
        // } catch (e) {
        //     store.dispatch({
        //         type: LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION,
        //     });
        //     errorNotification('For security purposes you have been logged out, you need to re login', 5000);
        // }
        // errorNotification('For security purposes you have been logged out, you need to re login', 5000);
        return false;
      case 403:
        window.location.href = ROUTE_CONSTANTS.FORBIDDEN;
        return false;
      case 402:
        store.dispatch(handlePaymentRequired());
        return false;
      default:
        break;
    }
    return Promise.reject(error);
  }
);

export default apiService;
