import { createSlice } from '@reduxjs/toolkit';

const exportTimesheetSlice = createSlice({
  name: 'exportTimesheet',
  initialState: {
    sidePanelData: {
      selectedLocation: {},
      personsList: [],
      selectedPerson: {},
      weekDatesValue: {
        startDate: null,
        endDate: null,
      },
      selectedPersons: [],
      filterDuration: 'Week',
    },
    listOfPersonWiseTimeSheets: [],
    selectedExportItemsSummary: {},
    payRateSummaryList: [],
    exportItemsDrawer: {
      isOpen: false,
    },
    isOpenEditExportCodeDrawer: null,
  },
  reducers: {
    updateExportTimeSheetData: (state, action) => {
      const { fieldName, value } = action.payload;
      state[fieldName] = value;
    },
    updateExportTimesheetDrawerDetails: (state, action) => {
      const { fieldName, value } = action.payload;
      state.exportItemsDrawer[fieldName] = value;
    },
    updateSidePanelData: (state, action) => {
      const { fieldName, value } = action.payload;
      state.sidePanelData[fieldName] = value;
    },
    setPersonWiseTimeSheetsList: (state, action) => {
      state.listOfPersonWiseTimeSheets = action?.payload;
    },
    clearExportItemsDrawer: (state) => {
      state.exportItemsDrawer = {
        isOpen: false,
      };
    },
    setPayRateSummaryList: (state, action) => {
      state.payRateSummaryList = action?.payload;
    },
  },
});

const { reducer, actions } = exportTimesheetSlice;

export const {
  updateExportTimeSheetData,
  updateExportTimesheetDrawerDetails,
  updateSidePanelData,
  setPersonWiseTimeSheetsList,
  clearExportItemsDrawer,
  setPayRateSummaryList,
} = actions;

export default reducer;
