import React, { useEffect, useMemo, useState } from 'react';
import { Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty, isNull } from 'lodash';
import EzyTable from '../../../../components/common/table/EzyTable';
import EzyPagination from '../../../../components/common/pagination/EzyPagination';
import { deletePeopleLeave, getLeaveBalanceByPersonId } from '../../redux/peopleActions';
import LeaveEntitlementDrawer from './LeaveEntitlementDrawer';
import useQueryParams from '../../../../hooks/useQueryParams';
import useUrlParamsUpdate from '../../../../hooks/useURLParamsUpdate';
import { editLeaveDetails, updateLeaveEntitlementDetail } from '../../redux/peopleSlice';
import ConfirmationModal from '../../../../components/common/modal/ConfirmationModal';

function LeaveBalanceList() {
  const [deleteModalData, setDeleteModalData] = useState(null);

  const { roleId: loggedInRole } = useSelector(({ user }) => user?.profileData ?? {});

  const { leaveBalance } = useSelector(({ people }) => people?.leaveDetails ?? {});
  const { docs, headers, total, pages, page, limit } = useMemo(
    () => leaveBalance ?? {},
    [leaveBalance]
  );

  const { getLeaveBalanceByPersonIdLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const { peopleId } = useParams();

  const { page: paramPage, limit: paramLimit } = useQueryParams();
  const dispatch = useDispatch();

  useUrlParamsUpdate({
    page: page ?? 1,
    limit: limit ?? 15,
  });

  const getLeaveListByFilter = async (params = {}) => {
    const data = {
      page: page ?? 1,
      limit: limit ?? 15,
      personDetailId: peopleId,
      ...params,
    };
    await dispatch(getLeaveBalanceByPersonId(data));
  };

  const pageActionClick = async (newPage) => {
    await getLeaveListByFilter({ page: newPage, limit });
  };
  const onSelectLimit = async (newLimit) => {
    await getLeaveListByFilter({ page: 1, limit: newLimit });
  };

  const onClickDeleteLeave = (e) => setDeleteModalData(e);
  const handelDeletePeopleLeave = async (e) => {
    await dispatch(
      deletePeopleLeave(e, () => {
        const data = {
          page: page ?? 1,
          limit: limit ?? 15,
          personDetailId: peopleId,
        };
        dispatch(getLeaveBalanceByPersonId(data));
      })
    );
  };

  const onEditClick = (e) => {
    dispatch(editLeaveDetails({ fieldName: 'leaveEntitlementDrawer', value: true }));
    dispatch(editLeaveDetails({ fieldName: 'isEditLeaveEntitlement', value: true }));
    dispatch(editLeaveDetails({ fieldName: 'leaveBalanceId', value: e?.id }));

    dispatch(
      updateLeaveEntitlementDetail({
        fieldName: 'leave',
        value: {
          id: e?.businessLeaveRuleId,
          label: e?.businessLeaveRuleName,
          trackLeaveIn: e?.trackLeaveIn,
        },
      })
    );
    dispatch(
      updateLeaveEntitlementDetail({
        fieldName: 'balance',
        value: e?.totalBalance,
      })
    );
  };

  const getActions = () => {
    const actions = [];
    if ([1, 3]?.includes(loggedInRole)) {
      actions?.push(
        {
          label: 'Edit',
          onClick: onEditClick,
          access: 'EDITLVBAL',
        },
        {
          label: 'Delete',
          onClick: (e) => onClickDeleteLeave(e?.id),
          access: 'DELETELVBAL',
        }
      );
    } else {
      actions?.push('DISABLED');
    }
    return actions;
  };

  const getRowData = () =>
    docs?.map((dt) => ({
      ...dt,
      action: getActions(dt),
    }));

  useEffect(() => {
    const params = {
      page: paramPage ?? page ?? 1,
      limit: paramLimit ?? limit ?? 15,
    };
    getLeaveListByFilter({ ...params });
  }, []);

  return (
    <Paper className="table-paper">
      <EzyTable
        headers={headers}
        rows={getRowData()}
        loading={getLeaveBalanceByPersonIdLoader}
        onRowClick={onEditClick}
        accessForRow={['EDITLVBAL', 'VIEWLVBAL']}
        accessForAction={['EDITLVBAL', 'VIEWLVBAL', 'DELETELVBAL']}
      />
      <EzyPagination
        total={total}
        pages={pages}
        pageActionClick={pageActionClick}
        limit={limit}
        page={page}
        onChangeLimit={onSelectLimit}
      />
      <LeaveEntitlementDrawer />
      <ConfirmationModal
        title="Delete Leave Entitlement"
        isOpen={!isEmpty(deleteModalData) || !isNull(deleteModalData)}
        handleClose={() => {
          setDeleteModalData(null);
        }}
        handleSubmit={async () => {
          await handelDeletePeopleLeave(deleteModalData);
          setDeleteModalData(null);
        }}
        primaryLabel="Delete"
      >
        <div>Are you sure you want to delete?</div>
      </ConfirmationModal>
    </Paper>
  );
}

export default LeaveBalanceList;
