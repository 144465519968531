import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const insightSlice = createSlice({
  name: 'insight',
  initialState: {
    locationListDropdown: [],
    summaryStatisticData: [],
    sidePanelList: {
      summaryList: [{ id: 1, label: 'Summary', pathName: 'Summary' }],
      budgetList: [],
      metricList: [],
      staffList: [
        { id: 2, label: 'Labor model', pathName: 'Labor model', title: 'STAFF' },
        { id: 3, label: 'Required staff', pathName: 'staff' },
      ],
    },
    filters: {
      selectedLocation: {},
      weekDatesValue: {},
      selectedModule: { label: '' },
      viewType: 5,
    },
    budget: {
      filters: {
        weekDatesValue: {},
        areaId: null,
      },
      addBudgetDrawer: {
        isOpen: false,
        budgetStatisticsEntityList: [],
        setBudgetForArea: false,
        budgetPeriod: 1,
        // budgetEntity: null,
      },
      budgetSettingsDrawer: {
        isOpen: false,
      },
      budgetList: {
        budgetResponseValue: [],
      },
    },
    metric: {
      filters: {
        areaId: null,
        weekDatesValue: {},
        duration: {
          id: 1,
          label: 'Week',
          dayCount: 7,
        },
      },
      addMetricDrawer: {
        isOpen: false,
        metricName: '',
        metricType: 1,
        metricForecastingType: 1,
      },
      metricSettingsDrawer: {
        isOpen: false,
        metricName: '',
        metricType: {},
        levelOfDetail: {},
        isManagerForecastActivated: false,
        breakDownByArea: false,
        isForecastActivated: false,
        isDefault: false,
        chartColour: null,
        metricForecastBasedOnEntity: {},
      },
      exportMetricDrawer: {
        isOpen: false,
        statisticData: [],
      },
      metricList: { metricInformation: [] },
      metricStatisticsList: {
        newMetricTimeListEntities: [{ dateValues: [{ date: '', value: '' }] }],
        totalEntity: [],
      },
    },
    staff: {
      rulesList: [],
      listOfMetrics: [],
      runLaborModalDrawer: {
        isOpen: false,
        weekDatesValue: {
          startDate: moment.tz(new Date(), 'UTC').startOf('week'),
          endDate: moment.tz(new Date(), 'UTC').endOf('week'),
        },
        duration: {
          id: 1,
          label: 'Week',
          dayCount: 7,
        },
      },
      addRuleDrawer: {
        isOpen: false,
        coverageType: 1,
        isAnyTime: false,
        ruleBuffer: {},
        linearRule: {},
        rangeRule: [
          {
            minimumCost: 0,
            maximumCost: 10,
            requiredLabor: 0,
          },
          {
            minimumCost: 11,
            maximumCost: null,
            requiredLabor: 0,
          },
        ],
      },
      staffSettingsDrawer: {
        isOpen: false,
      },
      staffFilters: {
        weekDatesValue: {},
        duration: { id: 1, label: 'Week', dayCount: 7 },
        areaIdList: [],
      },
      staffStatisticsList: {
        newMetricTimeListEntities: [{ dateValues: [{ date: '', value: '' }] }],
        totalEntity: [],
      },
    },
  },
  reducers: {
    updateSidePanelList: (state, action) => {
      const { fieldName, value } = action.payload;
      state.sidePanelList[fieldName] = value;
    },
    updateInsightFilters: (state, action) => {
      const { fieldName, value } = action.payload;
      state.filters[fieldName] = value;
    },
    updateLocationListDropdown: (state, action) => {
      state.locationListDropdown = action.payload;
    },
    updateAddBudgetDrawer: (state, action) => {
      const { fieldName, value } = action.payload;
      state.budget.addBudgetDrawer[fieldName] = value;
    },
    updateBudgetSettingsDrawer: (state, action) => {
      const { fieldName, value } = action.payload;
      state.budget.budgetSettingsDrawer[fieldName] = value;
    },
    updateBudgetFilters: (state, action) => {
      const { fieldName, value } = action.payload;
      state.budget.filters[fieldName] = value;
    },
    updateBudgetStatisticsEntityList: (state, action) => {
      const { value, day, areaId } = action.payload;
      const existingIndex = state.budget.addBudgetDrawer.budgetStatisticsEntityList?.findIndex(
        (e) => e?.day === day && e?.areaId === areaId
      );
      if (existingIndex > -1)
        state.budget.addBudgetDrawer.budgetStatisticsEntityList[existingIndex].value = value;
      else state.budget.addBudgetDrawer.budgetStatisticsEntityList.push({ value, areaId, day });
    },
    resetAddBudgetDrawerData: (state) => {
      state.budget.addBudgetDrawer = {
        isOpen: false,
        budgetPeriod: 1,
        // budgetEntity: null,
        setBudgetForArea: false,
        budgetStatisticsEntityList: [],
      };
    },
    updateAddMetricDrawer: (state, action) => {
      const { fieldName, value } = action.payload;
      state.metric.addMetricDrawer[fieldName] = value;
    },
    resetAddMetricDrawerData: (state) => {
      state.metric.addMetricDrawer = {
        metricName: '',
        metricType: 1,
        metricForecastingType: 1,
      };
    },
    updateMetricSettingsDrawer: (state, action) => {
      const { fieldName, value } = action.payload;
      state.metric.metricSettingsDrawer[fieldName] = value;
    },
    setMetricData: (state, action) => {
      state.metric.metricSettingsDrawer = {
        isOpen: state.metric.metricSettingsDrawer.isOpen,
        ...action.payload,
      };
    },
    resetMetricSettingsDrawer: (state) => {
      state.metric.metricSettingsDrawer = {
        isOpen: false,
        metricName: '',
        metricType: {},
        levelOfDetail: {},
        isManagerForecastActivated: false,
        breakDownByArea: false,
        isForecastActivated: false,
        isDefault: false,
        chartColour: null,
        metricForecastBasedOnEntity: {},
      };
    },
    setBudgetList: (state, action) => {
      state.budget.budgetList = action.payload;
    },
    updateExportMetricDrawer: (state, action) => {
      const { fieldName, value } = action.payload;
      state.metric.exportMetricDrawer[fieldName] = value;
    },
    updateMetricFilters: (state, action) => {
      const { fieldName, value } = action.payload;
      state.metric.filters[fieldName] = value;
    },
    setMetricList: (state, action) => {
      const { listName, value } = action.payload;
      state.metric[listName] = value;
    },
    updateAddRuleDrawer: (state, action) => {
      const { fieldName, value, coverageField, index } = action.payload;
      if (index === 0 || index) {
        state.staff.addRuleDrawer.rangeRule = state.staff.addRuleDrawer.rangeRule.map((e, i) =>
          i === index ? { ...e, [fieldName]: value } : e
        );
      } else if (coverageField) {
        state.staff.addRuleDrawer[coverageField][fieldName] = value;
      } else state.staff.addRuleDrawer[fieldName] = value;
    },
    addUpdateRangeRuleFields: (state, action) => {
      const { type, index } = action.payload;
      if (type === 'ADD') {
        state.staff.addRuleDrawer.rangeRule.splice(
          state.staff.addRuleDrawer.rangeRule.length - 1,
          0,
          {
            minimumCost: +state.staff.addRuleDrawer.rangeRule[index - 2].maximumCost + 1,
            maximumCost: +state.staff.addRuleDrawer.rangeRule[index - 2].maximumCost + 10,
            requiredLabor: 0,
          }
        );
        state.staff.addRuleDrawer.rangeRule[
          state.staff.addRuleDrawer.rangeRule.length - 1
        ].minimumCost =
          +state.staff.addRuleDrawer.rangeRule[state.staff.addRuleDrawer.rangeRule.length - 1]
            .minimumCost + 10;
      }
      if (type === 'DELETE') {
        state.staff.addRuleDrawer.rangeRule = state.staff.addRuleDrawer.rangeRule.filter(
          (e, i) => i !== index
        );
      }
    },
    updateStaffData: (state, action) => {
      const { fieldName, value } = action.payload;
      state.staff[fieldName] = value;
    },
    resetAddRuleDrawer: (state) => {
      state.staff.addRuleDrawer = {
        isOpen: false,
        coverageType: 1,
        isAnyTime: false,
        ruleBuffer: {},
        linearRule: {},
        rangeRule: [
          {
            minimumCost: 0,
            maximumCost: 10,
            requiredLabor: 0,
          },
          {
            minimumCost: 11,
            maximumCost: null,
            requiredLabor: 0,
          },
        ],
      };
    },
    setRuleDrawerData: (state, action) => {
      state.staff.addRuleDrawer = {
        ...state.staff.addRuleDrawer,
        ...action.payload,
      };
    },
    updateRunLaborModalDrawer: (state, action) => {
      const { fieldName, value } = action.payload;
      state.staff.runLaborModalDrawer[fieldName] = value;
    },
    updateStaffSettingsDrawer: (state, action) => {
      const { fieldName, value } = action.payload;

      state.staff.staffSettingsDrawer[fieldName] = value;
    },
    updateStaffFilters: (state, action) => {
      const { fieldName, value } = action.payload;
      state.staff.staffFilters[fieldName] = value;
    },
    setStaffList: (state, action) => {
      const { listName, value } = action.payload;
      state.staff[listName] = value;
    },
    updateSummaryStatisticData: (state, action) => {
      state.summaryStatisticData = action.payload;
    },
    resetExportMetricDrawer: (state) => {
      state.metric.exportMetricDrawer = {
        isOpen: false,
        statisticData: [],
      };
    },
  },
});

const { reducer, actions } = insightSlice;

export const {
  updateAddBudgetDrawer,
  updateBudgetSettingsDrawer,
  updateSidePanelList,
  updateInsightFilters,
  updateLocationListDropdown,
  updateBudgetFilters,
  updateBudgetStatisticsEntityList,
  resetAddBudgetDrawerData,
  updateAddMetricDrawer,
  resetAddMetricDrawerData,
  updateMetricSettingsDrawer,
  setMetricData,
  resetMetricSettingsDrawer,
  setBudgetList,
  updateExportMetricDrawer,
  updateMetricFilters,
  setMetricList,
  updateAddRuleDrawer,
  updateStaffData,
  addUpdateRangeRuleFields,
  resetAddRuleDrawer,
  setRuleDrawerData,
  updateRunLaborModalDrawer,
  updateStaffSettingsDrawer,
  updateStaffFilters,
  setStaffList,
  updateSummaryStatisticData,
  resetExportMetricDrawer,
} = actions;

export default reducer;
