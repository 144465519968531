import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import {
  editLeaveDetails,
  resetLeaveEntitlementDrawerDetails,
  updateLeaveEntitlementDetail,
} from '../../redux/peopleSlice';
import EzyAutoComplete from '../../../../components/common/autoComplete/EzyAutoComplete';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import {
  addEditLeaveBalanceAction,
  getLeaveBalanceByPersonId,
  getLeaveEntitlementEnumByBusinessIdAction,
} from '../../redux/peopleActions';
import leaveEntitlementValidations from '../../validations/leaveEntitlementValidations';
import { REGEX } from '../../../../utils/validationHelper';

function LeaveEntitlementDrawer() {
  const dispatch = useDispatch();
  const { peopleId } = useParams();

  const {
    leaveEntitlementDrawer,
    isEditLeaveEntitlement,
    leaveEnums,
    leaveEntitlementDetail,
    leaveBalanceId,
  } = useSelector(({ people }) => people?.leaveDetails ?? {});

  const { addEditLeaveBalanceActionLoader, getLeaveEntitlementEnumByBusinessIdActionLoader } =
    useSelector(({ common }) => common.generalLoader ?? {});

  const onChangeInputField = (fieldName, value) => {
    dispatch(updateLeaveEntitlementDetail({ fieldName, value }));
  };

  const handleClose = () => {
    dispatch(editLeaveDetails({ fieldName: 'leaveEntitlementDrawer', value: false }));
    dispatch(resetLeaveEntitlementDrawerDetails());
  };
  const handleSubmit = () => {
    const data = {
      personDetailId: peopleId,
      businessLeaveRulesId: leaveEntitlementDetail?.leave?.id,
      totalBalance: leaveEntitlementDetail?.balance,
      id: leaveBalanceId,
    };
    const error = leaveEntitlementValidations(data);
    onChangeInputField('errors', error);
    if (isEmpty(error)) {
      dispatch(
        addEditLeaveBalanceAction({ ...data, totalBalance: Number(data.totalBalance) }, () => {
          dispatch(
            getLeaveBalanceByPersonId({
              page: 1,
              limit: 15,
              personDetailId: peopleId,
            })
          );
          handleClose();
        })
      );
    }
  };
  const actionButton = [
    {
      label: 'Save',
      onClick: handleSubmit,
      loading: addEditLeaveBalanceActionLoader,
    },
  ];

  useEffect(() => {
    if (leaveEntitlementDrawer) dispatch(getLeaveEntitlementEnumByBusinessIdAction());
  }, [leaveEntitlementDrawer]);

  return (
    <EzyDrawer
      onClose={handleClose}
      isOpen={leaveEntitlementDrawer}
      actionButtons={actionButton}
      heading={isEditLeaveEntitlement ? 'Edit Leave Entitlement' : 'Add Leave Entitlement'}
    >
      <div className="leave-entitlement-drawer">
        <div className="leave-entitlement-field-grid">
          <EzyAutoComplete
            name="leave"
            placeholder="Select leave"
            label="Select leave"
            options={leaveEnums?.leaveEntitlementEnumByBusinessId}
            value={leaveEntitlementDetail?.leave}
            onChange={(_, value) => onChangeInputField('leave', value)}
            error={leaveEntitlementDetail?.errors?.businessLeaveRulesId}
            disabled={isEditLeaveEntitlement}
            loading={getLeaveEntitlementEnumByBusinessIdActionLoader}
            required
          />
          <span
            className={`material-icons pointer-cursor p-10 ${
              isEmpty(leaveEntitlementDetail?.errors) ? 'align-self-end' : 'align-self-center'
            }`}
          >
            close
          </span>
          <EzyInputField
            label="Balance"
            placeholder={
              leaveEntitlementDetail?.leave?.trackLeaveIn
                ? `Enter ${leaveEntitlementDetail?.leave?.trackLeaveIn}`
                : 'Enter value'
            }
            value={leaveEntitlementDetail?.balance}
            onChange={(event) => {
              if (REGEX?.DECIMAL_REGEX.test(event.target.value)) {
                onChangeInputField('balance', event.target.value);
              }
            }}
            error={leaveEntitlementDetail?.errors?.totalBalance}
            required
          />
        </div>
      </div>
    </EzyDrawer>
  );
}

export default LeaveEntitlementDrawer;
