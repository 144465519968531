/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { IconButton, InputAdornment } from '@mui/material';
import { isEmpty, isNil, isNull, omitBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EzyAutoComplete from '../../../../components/common/autoComplete/EzyAutoComplete';
import EzyAvatar from '../../../../components/common/avatar/EzyAvatar';
import EzyButton from '../../../../components/common/button/EzyButton';
import EzyDate from '../../../../components/common/dateAndTimePicker/EzyDate';
import EzyDialog from '../../../../components/common/dialog/EzyDialog';
import EzyDialogTitle from '../../../../components/common/dialog/EzyDialogTitle';
import EzyDialogAction from '../../../../components/common/dialog/EzyDialogAction';
import IconByName from '../../../../components/common/iconByName/IconByName';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import Loader from '../../../../components/common/loader/Loader';
import ConfirmationModal from '../../../../components/common/modal/ConfirmationModal';
import EzyNavBar from '../../../../components/common/navBar/EzyNavBar';
import EzyPhoneNumber from '../../../../components/common/phoneNumberInput/ezyPhoneNumber';
import InfoModal from '../../../../components/infoModal/InfoModal';
import ROUTE_CONSTANTS from '../../../../utils/constants';
import { errorNotification, successNotification } from '../../../../utils/notifyHelper';
import { displayDate } from '../../../../utils/timeHelper';
import { requireValidate, validatePhoneNumberLength } from '../../../../utils/validationHelper';
import {
  deleteAccountAction,
  deleteGlobalProfilePictureAction,
  editGlobalProfileDetailsAction,
  getBusinessListForAccountOverview,
  getGlobalProfileDetailsAction,
  resetPasswordAction,
  uploadGlobalProfilePictureAction,
} from '../../redux/globalProfileActions';
import {
  updateGlobalProfileData,
  updateGlobalProfileDetails,
} from '../../redux/globalProfileSlice';
import checkEmailImage from '../../../../assets/images/Check your email.png';
import CheckPasswordDrawer from './CheckPasswordDrawer';
import ChangeEmailDrawer from './ChangeEmailDrawer';
import AccountOverviewCardView from './AccountOverviewCardView';
import authServices from '../../../auth/authServices';
import {
  clearAuthTokenFromLocalStorage,
  clearFCMTokenFromLocalStorage,
  getFCMTokenFromLocalStorage,
} from '../../../../utils/localStorageHelper';
import PlanExpiredUser from './PlanExpiredUser';
import { formatPhoneToSend } from '../../../../utils/phoneNumberHelper';

function AccountOverview() {
  const history = useHistory();
  const dispatch = useDispatch();

  const hiddenFileInput = useRef(null);
  const [hoverEffect, setHoverEffect] = useState('mouseout');
  const [profileModal, setOpenProfileModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(null);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [deleteAccountModal, setDeleteAccountModal] = useState(null);
  const [openWarningModal, setOpenWarningModal] = useState(null);

  const { enums, timeZonesList } = useSelector(({ common }) => common ?? {});
  const profileData = useSelector(({ user }) => user?.profileData ?? {});

  const { profileDetails, isEditProfile } = useSelector(({ globalProfile }) => globalProfile || {});

  const {
    profilePhotoURL,
    preferredFullName,
    emailAddress,
    phoneNumber,
    kioskPin,
    timeZoneId,
    dateOfBirth,
    gender,
    pronoun,
    countryId,
    city,
    address,
    postCode,
    emergencyPhone,
    countryCode,
    emergencyContactCountryCode,
    phoneNumberData,
    emergencyContactNumberData,
    errors,
  } = useMemo(() => profileDetails, [profileDetails]);

  const logout = async () => {
    try {
      const clientToken = getFCMTokenFromLocalStorage();
      const res = await authServices.logout({ clientToken });
      if (res.data.success) {
        successNotification(res.data.message);
        clearAuthTokenFromLocalStorage();
        clearFCMTokenFromLocalStorage();
        dispatch({ type: 'USER_LOGOUT' });
        history.push(ROUTE_CONSTANTS.LOGIN);
      }
    } catch (e) {
      errorNotification(e);
    }
  };
  const {
    getGlobalProfileDetailsActionLoader,
    getCurrentUserDataLoader,
    editGlobalProfileDetailsActionLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleChange = useCallback(async (e) => {
    e.preventDefault();

    if (e.target.files && e.target.files.length > 0) {
      const fileExtension = ['jpeg', 'jpg', 'png'];
      const mimeType = ['image/jpeg', 'image/jpg', 'image/png'];

      const checkExtension =
        fileExtension.indexOf(e.target.files[0].name.split('.').splice(-1)[0]) !== -1;

      const checkMimeTypes = mimeType.indexOf(e.target.files[0].type) !== -1;

      const checkFileSize = e.target.files[0].size > 4194304;

      if (!(checkExtension || checkMimeTypes)) {
        errorNotification('Only image file allowed');
      } else if (checkFileSize) {
        errorNotification('File size should be less than 4 mb');
      } else {
        const formData = new FormData();
        formData.append('image', e.target.files[0]);

        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };

        e.target.value = null;

        try {
          await dispatch(uploadGlobalProfilePictureAction(formData, config));
        } catch (error) {
          /**/
        }
      }
    }
  }, []);

  const deleteProfilePicture = async () => {
    await dispatch(deleteGlobalProfilePictureAction());
  };

  const handleDeleteAccount = async () => {
    const res = await dispatch(deleteAccountAction(history));
    if (res) {
      setOpenWarningModal(res);
    }
  };

  const handleFieldChange = (name, value) => {
    dispatch(updateGlobalProfileDetails({ fieldName: name, value }));
  };

  const onSaveDetails = () => {
    let error = {};
    error.preferredFullName = requireValidate('Name', preferredFullName);
    error.kioskPin = requireValidate('Kiosk PIN ', kioskPin);
    error.timeZoneId = requireValidate('TimeZone ', timeZoneId);
    if (
      phoneNumberData &&
      phoneNumber?.length &&
      !validatePhoneNumberLength(phoneNumber, phoneNumberData?.format)
    ) {
      error.phoneNumber = 'Invalid phone number!';
    }
    if (
      emergencyContactNumberData &&
      emergencyPhone?.length &&
      !validatePhoneNumberLength(emergencyPhone, emergencyContactNumberData?.format)
    ) {
      error.emergencyPhone = 'Invalid phone number!';
    }
    error = omitBy(error, isNil);

    if (isEmpty(error)) {
      dispatch(updateGlobalProfileDetails({ fieldName: 'errors', value: {} }));
      const data = {
        profilePhotoURL,
        preferredFullName,
        emailAddress,
        city,
        address,
        postCode,
        emergencyPhone: formatPhoneToSend(emergencyPhone, emergencyContactCountryCode),
        phoneNumber: formatPhoneToSend(phoneNumber, countryCode),
        kioskPin,
        dateOfBirth,
        timeZoneId: timeZoneId?.id,
        gender: gender?.id,
        pronoun: pronoun?.id,
        countryId,
        countryCode,
        emergencyContactCountryCode,
      };
      dispatch(
        editGlobalProfileDetailsAction(data, () => {
          dispatch(updateGlobalProfileData({ fieldName: 'isEditProfile', value: false }));
        })
      );
    } else {
      dispatch(updateGlobalProfileDetails({ fieldName: 'errors', value: error }));
    }
  };

  const resetAccountPassword = async () => {
    const res = await dispatch(resetPasswordAction({ email: emailAddress }));
    if (res) {
      setIsSuccessModal(true);
    }
  };

  useEffect(() => {
    if (!isEmpty(profileData)) {
      (async () => {
        await dispatch(getGlobalProfileDetailsAction());
      })();
    }
  }, [profileData]);

  useEffect(() => {
    dispatch(getBusinessListForAccountOverview());
  }, []);

  return (
    <div className="h-full w-full d-flex-column profile-setting-fullScreen-wrapper">
      <EzyNavBar isFormProfile>
        <div className="account-overview-navbar">
          <EzyButton
            label="Create new business"
            color="secondary"
            startIcon={<IconByName name="add" />}
            onClick={() => history.push(ROUTE_CONSTANTS.REGISTER_BUSINESS)}
          />
          <IconButton className="navBar-icon-buttons" onClick={logout}>
            <IconByName name="logout" />
          </IconButton>
        </div>
      </EzyNavBar>
      <div className="panel-child-wrapper">
        {getGlobalProfileDetailsActionLoader || getCurrentUserDataLoader ? (
          <Loader />
        ) : (
          <div className="profile-grid-fields">
            {!isEditProfile ? (
              <div className="profile-grid">
                <EzyAvatar
                  className="profile-picture default-cursor"
                  firstName={preferredFullName}
                  imageLink={profilePhotoURL}
                />
                <div className="large-title">{preferredFullName || '-'}</div>
                <EzyButton
                  label="Edit global profile"
                  onClick={() =>
                    dispatch(updateGlobalProfileData({ fieldName: 'isEditProfile', value: true }))
                  }
                />

                <div className="profile-details">
                  <div className="flex-center gap-10">
                    <IconByName name="email" />
                    <span className="word-break">{emailAddress || '-'}</span>
                  </div>
                  <div className="flex-center gap-10">
                    <IconByName name="call" />
                    <span className="word-break">
                      {formatPhoneToSend(phoneNumber, countryCode) || '-'}
                    </span>
                  </div>
                  <div className="flex-center gap-10">
                    <IconByName name="cake" />
                    <span className="word-break">{displayDate(dateOfBirth, 'UTC') || '-'}</span>
                  </div>
                </div>
                <EzyButton
                  label="Reset account password"
                  className="reset-button"
                  color="error"
                  onClick={resetAccountPassword}
                />
              </div>
            ) : (
              <div className="profile-grid">
                <IconByName
                  name="close"
                  className="pointer-cursor  profile-close-icon"
                  onClick={() =>
                    dispatch(updateGlobalProfileData({ fieldName: 'isEditProfile', value: false }))
                  }
                />

                <div
                  className="profile-picture-wrapper"
                  onMouseOver={(e) => setHoverEffect(e?.type)}
                  onMouseOut={(e) => setHoverEffect(e?.type)}
                >
                  <EzyAvatar
                    className="profile-picture"
                    firstName={preferredFullName}
                    imageLink={profilePhotoURL}
                  />
                  <input
                    type="file"
                    className="input-file-type"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    accept="image/png, image/jpg, image/jpeg"
                  />
                  {hoverEffect === 'mouseover' && profilePhotoURL && (
                    <div className="img__overlay">
                      <IconByName name="visibility" onClick={() => setOpenProfileModal(true)} />
                      <IconByName name="edit" onClick={handleClick} />
                      <IconByName
                        name="delete"
                        onClick={(e) => {
                          setDeleteModal(e);
                        }}
                      />
                    </div>
                  )}
                  {hoverEffect === 'mouseover' && profilePhotoURL === null && (
                    <div className="img__overlay">
                      <IconByName name="add_photo_alternate" onClick={handleClick} />
                    </div>
                  )}
                </div>

                <div className="global-profile-edit-fields">
                  <EzyInputField
                    label="Name"
                    placeholder="Enter name"
                    value={preferredFullName}
                    onChange={(e) => handleFieldChange('preferredFullName', e?.target?.value)}
                    error={errors?.preferredFullName}
                    required
                  />
                  <EzyInputField
                    label="Email"
                    placeholder="Enter email"
                    value={emailAddress}
                    disabled
                    endAdornment={
                      <InputAdornment position="end">
                        <span
                          className="material-icons icon-small endAdornment-icon"
                          onClick={() => {
                            dispatch(
                              updateGlobalProfileData({
                                fieldName: 'checkPasswordDrawer',
                                value: true,
                              })
                            );
                          }}
                          role="presentation"
                        >
                          edit
                        </span>
                      </InputAdornment>
                    }
                  />
                  <EzyPhoneNumber
                    label="Mobile number"
                    placeholder="Enter mobile number"
                    value={phoneNumber}
                    onChange={(value, data) => {
                      handleFieldChange('phoneNumber', value);
                      handleFieldChange('countryCode', data?.dialCode);
                      handleFieldChange('phoneNumberData', data);
                    }}
                    error={errors?.phoneNumber}
                  />
                  <EzyInputField
                    type={showPassword ? 'text' : 'password'}
                    label="Kiosk PIN"
                    placeholder="Enter kiosk PIN"
                    name="kioskPIN"
                    value={kioskPin}
                    required
                    inputProps={{
                      maxLength: 4,
                    }}
                    onChange={(e) => {
                      if (/^[0-9\b]+$/.test(e.target.value) || e.target.value === '') {
                        handleFieldChange('kioskPin', e?.target?.value);
                      }
                    }}
                    error={errors?.kioskPin}
                    endAdornment={
                      <InputAdornment position="end">
                        <span
                          className="material-icons icon-small endAdornment-icon"
                          onClick={() => setShowPassword(!showPassword)}
                          role="presentation"
                        >
                          {showPassword ? 'visibility_off' : 'visibility'}
                        </span>
                      </InputAdornment>
                    }
                  />
                  <EzyAutoComplete
                    label="Preferred time zone"
                    name="timeZone"
                    options={timeZonesList}
                    value={timeZoneId}
                    onChange={(_, value) => handleFieldChange('timeZoneId', value)}
                    error={errors?.timeZoneId}
                    required
                  />
                  <EzyDate
                    label="Birth date"
                    placeholder="Enter birth date"
                    value={dateOfBirth}
                    onChange={(value) => handleFieldChange('dateOfBirth', value)}
                    timeZone="UTC"
                    maxDate={moment.tz(new Date(), 'UTC').subtract(10, 'years')}
                  />
                  <EzyAutoComplete
                    label="Gender"
                    placeholder="Select gender"
                    value={gender}
                    options={enums?.gender}
                    onChange={(e, value) => {
                      handleFieldChange('gender', value);
                    }}
                  />
                  <EzyAutoComplete
                    label="Pronoun"
                    placeholder="Select pronoun"
                    value={pronoun}
                    options={enums?.pronoun}
                    onChange={(e, value) => {
                      handleFieldChange('pronoun', value);
                    }}
                  />
                  <EzyAutoComplete
                    label="Country"
                    placeholder="Select country"
                    value={enums?.country.find((obj) => obj.id === countryId)?.name}
                    options={enums?.country}
                    onChange={(e, value) => {
                      handleFieldChange('countryId', value?.id);
                    }}
                  />
                  <EzyInputField
                    label="City"
                    placeholder="Enter city"
                    value={city}
                    onChange={(e) => handleFieldChange('city', e?.target?.value)}
                  />
                  <EzyInputField
                    label="Address"
                    placeholder="Enter address"
                    value={address}
                    onChange={(e) => handleFieldChange('address', e?.target?.value)}
                  />
                  <EzyInputField
                    label="Post code"
                    placeholder="Enter post code"
                    value={postCode}
                    onChange={(e) => handleFieldChange('postCode', e?.target?.value)}
                  />
                  <EzyPhoneNumber
                    label="Emergency mobile number"
                    placeholder="Enter emergency mobile number"
                    value={emergencyPhone}
                    onChange={(value, data) => {
                      handleFieldChange('emergencyPhone', value);
                      handleFieldChange('emergencyContactCountryCode', data?.dialCode);
                      handleFieldChange('emergencyContactNumberData', data);
                    }}
                    error={errors?.emergencyPhone}
                  />
                </div>
                <div className="edit-buttons">
                  <EzyButton
                    label="Delete account"
                    className="no-pad-button"
                    color="error"
                    onClick={(e) => {
                      setDeleteAccountModal(e);
                    }}
                  />
                  <EzyButton
                    label="Save"
                    className="no-pad-button"
                    onClick={onSaveDetails}
                    loading={editGlobalProfileDetailsActionLoader}
                  />
                </div>
              </div>
            )}
            <div className="account-overviewCard-view">
              <AccountOverviewCardView />
            </div>
          </div>
        )}
        <EzyDialog
          open={profileModal && (profilePhotoURL !== null || undefined)}
          handleClose={() => setOpenProfileModal(false)}
          className="common-dialog-with-body profile-picture-dialog"
        >
          <IconByName
            name="close"
            className="pointer-cursor close-icon"
            onClick={() => setOpenProfileModal(false)}
          />
          <img src={profilePhotoURL} alt={preferredFullName} />
        </EzyDialog>
      </div>
      <ConfirmationModal
        title="Delete Profile Picture"
        isOpen={!isEmpty(deleteModal) || !isNull(deleteModal)}
        handleClose={() => {
          setDeleteModal(null);
        }}
        handleSubmit={async () => {
          await deleteProfilePicture();
          setDeleteModal(null);
        }}
        primaryLabel="Delete"
      >
        <div>Are you sure you want to delete?</div>
      </ConfirmationModal>

      <ConfirmationModal
        title="Delete Account"
        isOpen={!isEmpty(deleteAccountModal) || !isNull(deleteAccountModal)}
        handleClose={() => {
          setDeleteAccountModal(null);
        }}
        handleSubmit={async () => {
          await handleDeleteAccount();
          setDeleteAccountModal(null);
        }}
        primaryLabel="Delete"
      >
        <div>Are you sure you want to delete your account? This action cannot be undone!</div>
      </ConfirmationModal>

      <EzyDialog
        className="common-dialog-with-body delete-account-warning-modal"
        open={openWarningModal}
        handleClose={() => setOpenWarningModal(null)}
      >
        <EzyDialogTitle
          title="Delete Account"
          isCloseIcon
          onClose={() => setOpenWarningModal(null)}
        />
        <div className="dialog-body">{openWarningModal?.message}</div>
        <EzyDialogAction
          actionButtons={[{ label: 'Ok', onClick: () => setOpenWarningModal(null) }]}
        />
      </EzyDialog>

      <InfoModal
        img={checkEmailImage}
        subTitle={`We sent a password reset link to ${emailAddress}`}
        title="Check your email"
        action={
          <div className="subtitle-text ">
            {`Didn't receive the email? `}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span onClick={resetAccountPassword} className="secondary-action-text">
              Click to resend
            </span>
          </div>
        }
        open={isSuccessModal}
        onClose={() => setIsSuccessModal(false)}
      />
      <CheckPasswordDrawer />
      <ChangeEmailDrawer />
      <PlanExpiredUser />
    </div>
  );
}

export default AccountOverview;
