import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Chip } from '@mui/material';
import PropTypes from 'prop-types';
import { displayTime } from '../../../../utils/timeHelper';
import EzyButton from '../../../../components/common/button/EzyButton';
import EditShiftDrawer from './EditShiftDrawer';
import SelectBreakDrawer from './SelectBreakDrawer';
import SelectAreaDrawer from './SelectAreaDrawer';
import {
  editPeopleEndBreak,
  getShiftDetailsArea,
  getShiftDetailsByPersonID,
} from '../../redux/peopleActions';
import { updateEditPeopleShiftDetailsField } from '../../redux/peopleSlice';
import EzyWarningModal from '../../../../components/common/warningModal/EzyWarningModal';

function PeopleShift({ isFromDashboard }) {
  const dispatch = useDispatch();
  const param = useParams();
  const { getShiftDetails } = useSelector(({ common }) => common.generalLoader ?? {});
  const peopleData = useSelector(({ people }) => people ?? {});
  const { shiftData } = useMemo(() => peopleData?.editPeople?.details, [peopleData]);
  const { profileData } = useSelector(({ user }) => user || {});
  const userData = useMemo(() => profileData, [profileData]);
  const peopleId = isFromDashboard ? userData?.personId : param?.peopleId;

  const startScheduledShiftData = {
    personDetailId: peopleId,
    areaId: shiftData?.areaId,
    attachedShiftId: shiftData?.shiftId,
    timeSheetId: shiftData?.timeSheetId,
    isFromDashboard,
  };

  const warningMessage = useMemo(() => {
    if (shiftData?.shiftStartStopWarningModal) {
      return 'Sorry you are not allowed to start/stop shift via desktop computers. Please use PayrollEzy at your workplace.';
    }
    if (shiftData?.lessTimeBreakWarningModal) {
      return `You're not allowed to end break early.`;
    }

    return `You're not allowed to end shift early.`;
  }, [shiftData?.shiftStartStopWarningModal, shiftData?.lessTimeBreakWarningModal]);

  const handelFieldChange = (fieldName, value) => {
    dispatch(updateEditPeopleShiftDetailsField({ fieldName, value }));
  };

  const handelEndShift = () => {
    handelFieldChange('isEditShiftDrawerEnable', true);
  };
  const handelShiftStartStopWarningModal = () => {
    handelFieldChange('shiftStartStopWarningModal', false);
    handelFieldChange('lessTimeBreakWarningModal', false);
    handelFieldChange('earlyEndShiftWarningModal', false);
    handelFieldChange('earlyShiftStartWarning', false);
  };

  useEffect(() => {
    dispatch(getShiftDetailsByPersonID(peopleId));
  }, []);
  const handelStartShift = () => {
    if (shiftData?.isShiftScheduled) {
      if (shiftData?.isAllowToClockInFromWeb) {
        dispatch(
          getShiftDetailsArea(startScheduledShiftData, () => {
            dispatch(getShiftDetailsByPersonID(peopleId));
          })
        );
      } else {
        dispatch(
          updateEditPeopleShiftDetailsField({
            fieldName: 'shiftStartStopWarningModal',
            value: true,
          })
        );
      }
    } else {
      handelFieldChange('isSelectAreaDrawerEnable', true);
    }
  };
  const handelEndBreak = () => {
    const data = {
      timeSheetId: shiftData?.timeSheetId,
      isFromDashboard,
      isFromProfile: !isFromDashboard ?? false,
    };
    dispatch(
      editPeopleEndBreak(data, () => {
        dispatch(
          getShiftDetailsByPersonID(peopleId, (isShiftStarted) => {
            if (!isShiftStarted) {
              dispatch(getShiftDetailsArea({ personDetailId: peopleId }));
            }
          })
        );
      })
    );
  };

  return (
    <>
      <div className="chip shift-info">
        {shiftData?.shiftStartTime && shiftData?.shiftEndTime && (
          <div className="edit-people-profile-schedule-info">
            Schedule for {shiftData?.areaName} on{' '}
            {displayTime(shiftData?.shiftStartTime, shiftData?.locationTimeZoneName)} -
            {displayTime(shiftData?.shiftEndTime, shiftData?.locationTimeZoneName)}
          </div>
        )}
        {!shiftData?.isShiftScheduled && !shiftData?.isShiftStarted && (
          <div className="edit-people-profile-schedule-info">Start Unschedule shift</div>
        )}
        {shiftData?.isLateForShift && (
          <div className="edit-people-profile-schedule-status">Late for shift</div>
        )}
        {shiftData?.isShiftStarted && !shiftData?.isBreakStartted && (
          <>
            <div className="edit-people-profile-schedule-info">
              Shift started at{' '}
              {displayTime(shiftData?.shiftStartTime, shiftData?.locationTimeZoneName)} at [
              {shiftData?.locationCode}] {shiftData?.areaName}
            </div>
            <Chip variant="outlined" className="chip Approved" label="On shift" />
          </>
        )}
        {shiftData?.isBreakStartted && (
          <>
            <div className="edit-people-profile-schedule-info">
              Break started at{' '}
              {displayTime(shiftData?.breakStartTime, shiftData?.locationTimeZoneName)}
            </div>
            <Chip variant="outlined" className="chip Pending" label="On Break" />
          </>
        )}
      </div>

      {!shiftData?.isShiftStarted && (
        <EzyButton
          label="Start Shift"
          className="edit-people-profile-start-shift-button"
          onClick={handelStartShift}
          loading={getShiftDetails}
        />
      )}

      {shiftData?.isShiftStarted && (
        <div className="two-grid">
          {shiftData?.isBreakStartted ? (
            <EzyButton
              label="End break"
              color="error"
              className="shift-button"
              onClick={handelEndBreak}
              loading={getShiftDetails}
            />
          ) : (
            <EzyButton
              label="Start break"
              color="secondary"
              className="shift-button"
              loading={getShiftDetails}
              onClick={() => handelFieldChange('isSelectBreakDrawerEnable', true)}
            />
          )}
          <EzyButton
            label="End shift"
            color="error"
            className="shift-button"
            onClick={handelEndShift}
            loading={getShiftDetails}
          />
        </div>
      )}
      <EditShiftDrawer isFromDashboard={isFromDashboard} />
      <SelectBreakDrawer isFromDashboard={isFromDashboard} />
      <SelectAreaDrawer isFromDashboard={isFromDashboard} />
      <EzyWarningModal
        handleClose={handelShiftStartStopWarningModal}
        open={
          shiftData?.shiftStartStopWarningModal ||
          shiftData?.lessTimeBreakWarningModal ||
          shiftData?.earlyEndShiftWarningModal ||
          shiftData?.earlyShiftStartWarning
        }
        warningMessage={shiftData?.earlyShiftStartWarning || warningMessage}
      />
    </>
  );
}
export default PeopleShift;
PeopleShift.propTypes = {
  isFromDashboard: PropTypes.bool,
};

PeopleShift.defaultProps = {
  isFromDashboard: false,
};
