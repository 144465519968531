import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import WeekPicker from '../../../../components/common/dateAndTimePicker/WeekPicker';
import EzyMenu from '../../../../components/common/menu/EzyMenu';
import EzyButton from '../../../../components/common/button/EzyButton';
import EzyEditableTable from '../../../../components/common/table/EzyEditableTable';
import {
  updateExportMetricDrawer,
  updateMetricFilters,
  updateMetricSettingsDrawer,
} from '../../redux/insightSlice';
import ROUTE_CONSTANTS, { DurationFilter } from '../../../../utils/constants';
import MetricSettingsDrawer from './MetricSettingsDrawer';
import ExportMetricDrawer from './ExportMetricDrawer';
import {
  getMetricInformationAction,
  updateMetricDataFromViewScreenAction,
} from '../../redux/insightAction';
import {
  displayDate,
  displayDateTime,
  weekDatesValueBasedOnDuration,
} from '../../../../utils/timeHelper';
import EzyDatePicker from '../../../../components/common/dateAndTimePicker/EzyDatePicker';

function Metric() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useParams();

  const { filters, metric } = useSelector(({ insight }) => insight ?? {});
  const { selectedLocation, selectedModule } = useMemo(() => filters, [filters]);
  const { weekDatesValue, duration, areaId } = useMemo(() => metric?.filters, [metric?.filters]);
  const { businessId } = useSelector(({ user }) => user?.profileData ?? {});

  const getMetricInformationByFilters = (param) => {
    const params = {
      startDate: moment(param?.startDate || weekDatesValue?.startDate)?.toISOString(),
      endDate: moment(param?.endDate || weekDatesValue?.endDate).toISOString(),
      metricId: selectedModule?.id,
      locationId: selectedLocation?.id !== 'all' ? selectedLocation?.id : null,
      businessId: selectedLocation?.id === 'all' ? businessId : null,
      repetitionFrequency: param?.repetitionFrequency || duration?.id,
      areaId: selectedModule?.showArea ? param?.areaId || areaId?.id : null,
    };

    if (param?.startDate)
      dispatch(
        updateMetricFilters({
          fieldName: 'weekDatesValue',
          value: { startDate: param?.startDate, endDate: param?.endDate },
        })
      );

    dispatch(getMetricInformationAction(params));
  };

  const onFieldUpdate = (row) => {
    const data = {
      dayForStatistics: row?.date,
      value: row?.value || 0,
      locationId: selectedLocation?.id !== 'all' ? selectedLocation?.id : null,
      metricId: selectedModule?.id,
      isDailyView: duration?.id === 5,
      isActuals: row?.field === 'actuals',
      areaId: selectedModule?.showArea ? areaId?.id : null,
    };
    // if (data?.value)
    dispatch(
      updateMetricDataFromViewScreenAction(data, () => {
        getMetricInformationByFilters();
      })
    );
  };

  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      valueGetter: (e) => {
        if (e?.id === 'grandTotal') {
          return 'Grand total';
        }
        if (e?.id?.includes('weeklyTotal')) {
          return 'Weekly total';
        }
        if (duration?.label === 'Day') {
          return displayDateTime(e?.value, 'UTC');
        }
        return displayDate(e?.value, 'UTC');
      },
    },
    {
      field: 'lastYear',
      headerName: 'Last year',
      align: 'right',
      headerAlign: 'right',
      valueGetter: (e) => e?.value || '-',
    },
    {
      field: 'payrollForecast',
      headerName: 'Forecast',
      align: 'right',
      headerAlign: 'right',
      valueGetter: (e) => e?.value || '-',
    },
    {
      field: 'managerForecast',
      headerName: 'Manager forecast',
      align: 'right',
      headerAlign: 'right',
      valueGetter: (e) => e?.value || '-',
      editable: selectedLocation?.id !== 'all',
      onFieldChange: onFieldUpdate,
      formatValue: (value) => {
        return `$${value}`;
      },
    },
    {
      field: 'actuals',
      headerName: 'Actuals',
      type: 'decimal',
      editable: selectedLocation?.id !== 'all',
      align: 'right',
      headerAlign: 'right',
      valueGetter: (e) => e?.value || '-',
      onFieldChange: onFieldUpdate,
      formatValue: (value) => {
        return `$${value}`;
      },
    },
  ];

  const formatRow = () => {
    const weekStartsOn = selectedLocation?.weekStartsOn === 7 ? 0 : selectedLocation?.weekStartsOn;
    const rows = metric?.metricList?.metricInformation?.map((e, i) => {
      return { ...e, id: `${selectedModule?.id}-${i + 1}` };
    });
    const grandTotalRow = { id: 'grandTotal', ...metric?.metricList?.grandTotal };
    const weeklyTotalRows = metric?.metricList?.weeklyTotal
      ? metric?.metricList?.weeklyTotal?.map((e) => {
          return { id: `weeklyTotal ${e?.weekNumber}`, ...e };
        })
      : [];
    if (grandTotalRow?.actuals && weeklyTotalRows?.length) {
      let weekNumber = 0;
      const result = rows.reduce((prev, curr, index) => {
        if (index === 0) {
          const weeklyTotalRow = weeklyTotalRows[weekNumber];
          weekNumber += 1;
          return [grandTotalRow, weeklyTotalRow, ...prev, curr];
        }

        if (moment.tz(curr?.date, 'UTC').day() === weekStartsOn) {
          const weeklyTotalRow = weeklyTotalRows[weekNumber];
          weekNumber += 1;
          return [...prev, weeklyTotalRow, curr];
        }
        return [...prev, curr];
      }, []);

      return result;
    }
    if (grandTotalRow?.actuals) {
      return [grandTotalRow, ...rows];
    }
    return rows;
  };

  const formattedRow = formatRow();

  const metricActions = useMemo(() => {
    const actions = [
      {
        id: 2,
        label: 'Download metric data',
        onClick: () => dispatch(updateExportMetricDrawer({ fieldName: 'isOpen', value: true })),
      },
      {
        id: 3,
        label: 'Settings',
        onClick: () => dispatch(updateMetricSettingsDrawer({ fieldName: 'isOpen', value: true })),
      },
    ];
    if (selectedLocation?.id !== 'all') {
      actions.splice(0, 0, {
        id: 1,
        label: 'Add/Edit data',
        onClick: () => history.push(`${ROUTE_CONSTANTS.SCHEDULE}/insights/${pathname}/edit`),
      });
    }
    return actions;
  }, [selectedLocation]);

  useEffect(() => {
    if (selectedLocation?.id && selectedModule?.id) {
      const dates = weekDatesValueBasedOnDuration(weekDatesValue, duration);
      dispatch(
        updateMetricFilters({ fieldName: 'areaId', value: selectedLocation?.areaList?.[0] })
      );
      getMetricInformationByFilters({ ...dates, areaId: selectedLocation?.areaList?.[0]?.id });
    }
  }, [selectedModule?.id, selectedLocation?.id]);

  return (
    <div className="metric-list-wrapper">
      <div className="metric-header">
        <div>
          {duration?.id === 5 ? (
            <EzyDatePicker
              value={moment.tz(weekDatesValue?.startDate, 'UTC')}
              onChange={async (e) => {
                const data = weekDatesValueBasedOnDuration({ startDate: e }, duration);
                await getMetricInformationByFilters(data);
              }}
              timeZone="UTC"
            />
          ) : (
            <WeekPicker
              variant="outlined"
              value={weekDatesValue}
              onChange={async (e) => {
                const data = weekDatesValueBasedOnDuration(e, duration);
                await getMetricInformationByFilters(data);
              }}
              onNextClick={async () => {
                const dates = {
                  startDate: moment
                    .tz(weekDatesValue?.startDate, 'UTC')
                    .add(duration.dayCount, 'days'),
                  endDate: moment.tz(weekDatesValue?.endDate, 'UTC').add(duration.dayCount, 'days'),
                };

                if (duration?.label === 'Month') {
                  dates.startDate = moment
                    .tz(weekDatesValue?.endDate, 'UTC')
                    .add(1, 'days')
                    .startOf('month');
                  dates.endDate = moment
                    .tz(weekDatesValue?.endDate, 'UTC')
                    .add(1, 'days')
                    .endOf('month');
                }
                await getMetricInformationByFilters(dates);
              }}
              onPrevClick={async () => {
                const dates = {
                  startDate: moment
                    .tz(weekDatesValue?.startDate, 'UTC')
                    .subtract(duration.dayCount, 'days'),
                  endDate: moment
                    .tz(weekDatesValue?.endDate, 'UTC')
                    .subtract(duration.dayCount, 'days'),
                };

                if (duration?.label === 'Month') {
                  dates.startDate = moment
                    .tz(weekDatesValue?.startDate, 'UTC')
                    .subtract(1, 'days')
                    .startOf('month');
                  dates.endDate = moment
                    .tz(weekDatesValue?.startDate, 'UTC')
                    .subtract(1, 'days')
                    .endOf('month');
                }
                await getMetricInformationByFilters(dates);
              }}
              timeZone="UTC"
              startWeekFrom={
                selectedLocation?.weekStartsOn === 7 ? 0 : selectedLocation?.weekStartsOn
              }
            />
          )}

          <EzyMenu
            className="insight-duration-menu"
            menuItems={DurationFilter}
            label={duration?.label || 'Duration'}
            onItemClick={(e, item) => {
              dispatch(updateMetricFilters({ fieldName: 'duration', value: item }));

              const data = weekDatesValueBasedOnDuration(weekDatesValue, item);

              getMetricInformationByFilters({
                repetitionFrequency: item?.id,
                startDate: data?.startDate,
                endDate: data?.endDate,
              });
            }}
            selectedItem={duration}
          />

          {selectedModule?.showArea && selectedLocation?.id !== 'all' && (
            <EzyMenu
              className="insight-area-menu"
              menuItems={selectedLocation?.areaList}
              label={areaId?.label || 'Area'}
              onItemClick={(e, item) => {
                dispatch(updateMetricFilters({ fieldName: 'areaId', value: item }));
                getMetricInformationByFilters({ areaId: item?.id });
              }}
              selectedItem={areaId}
            />
          )}
        </div>

        <div>
          <EzyMenu iconName="more_vert" menuItems={metricActions} />

          <EzyButton
            label="Back"
            color="secondary"
            onClick={() => history.push(ROUTE_CONSTANTS.SCHEDULE)}
          />
        </div>
      </div>
      <EzyEditableTable
        rows={formattedRow}
        columns={columns}
        columnVisibilityModel={{
          payrollForecast: selectedModule?.isPayrollForecastEnable,
          managerForecast: selectedModule?.isManagerForecastEnable,
          lastYear: duration?.label !== 'Day',
        }}
      />
      <MetricSettingsDrawer />
      <ExportMetricDrawer />
    </div>
  );
}

export default Metric;
