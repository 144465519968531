import PropTypes from 'prop-types';
import useAccess from '../../../hooks/useAccess';

function AccessWrapper({ access, children, byPass }) {
  const hasAccess = useAccess(access);
  return hasAccess || byPass ? children : null;
}

export default AccessWrapper;

AccessWrapper.propTypes = {
  access: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  byPass: PropTypes.bool,
};

AccessWrapper.defaultProps = {
  byPass: false,
};
