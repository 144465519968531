import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty, isNull } from 'lodash';
import { InputAdornment } from '@mui/material';
import EzyTable from '../../../components/common/table/EzyTable';
import useQueryParams from '../../../hooks/useQueryParams';
import useUrlParamsUpdate from '../../../hooks/useURLParamsUpdate';
import EzyPagination from '../../../components/common/pagination/EzyPagination';
import {
  archiveLocationAction,
  getDetailsBeforeArchiveLocationAction,
  getLocationList,
} from '../redux/locationActions';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import EzyButton from '../../../components/common/button/EzyButton';
import IconByName from '../../../components/common/iconByName/IconByName';
import ROUTE_CONSTANTS from '../../../utils/constants';
import CopyLocation from '../components/CopyLocation';
import DuplicateLocation from '../components/duplicateLocation';
import {
  resetArchiveLocationDetails,
  resetCopyLocationDetails,
  resetDeleteLocationDetails,
  resetDuplicateLocation,
  updateArchiveLocationDetails,
} from '../redux/locationSlice';
import ArchiveLocation from '../components/ArchiveLocation';
import EzySwitchButton from '../../../components/common/switch/EzySwitchButton';
import DeleteLocation from '../components/DeleteLocation';

function LocationList() {
  const { locationList, archiveLocation } = useSelector(({ location }) => location ?? {});

  const { getLocationListLoader } = useSelector(({ common }) => common.generalLoader ?? {});

  const [searchText, setSearchText] = useState('');
  const [copyAction, setCopyAction] = useState(null);
  const [duplicateAction, setDuplicateAction] = useState(null);
  const [archiveLocationActionforModal, setArchiveLocationActionforModal] = useState(null);
  const [deleteLocationActionForModal, setDeleteLocationActionForModal] = useState(null);
  const history = useHistory();

  const { docs, headers, total, pages, page, limit } = useMemo(
    () => locationList ?? {},
    [locationList]
  );
  const { roleId: loggedInRole } = useSelector(({ user }) => user?.profileData ?? {});

  const { page: paramPage, limit: paramLimit, isArchive: paramIsArchive } = useQueryParams();
  const dispatch = useDispatch();

  useUrlParamsUpdate({
    page: page ?? 1,
    limit: limit ?? 15,
    isArchive: archiveLocation?.isArchive,
  });

  const getLocationListByFilter = async (params = {}) => {
    const data = {
      page: page ?? 1,
      limit: limit ?? 15,
      searchText,
      isArchive: archiveLocation?.isArchive,
      ...params,
    };
    await dispatch(getLocationList(data));
  };

  const pageActionClick = async (newPage) => {
    await getLocationListByFilter({ page: newPage, limit });
  };
  const onSelectLimit = async (newLimit) => {
    await getLocationListByFilter({ page: 1, limit: newLimit });
  };
  const handleSearch = async (e) => {
    if (e.target.value) setSearchText(e.target.value);
    else {
      setSearchText(null);
      await getLocationListByFilter({ searchText: null, page: 1, limit });
    }
  };

  const handleArchive = async (checked) => {
    dispatch(
      updateArchiveLocationDetails({
        fieldName: 'isArchive',
        value: checked,
      })
    );
    await getLocationListByFilter({ isArchive: checked, page: 1, limit });
  };

  const unArchiveLocationAction = async (e) => {
    const data = {
      locationId: e?.locationId,
      isArchive: false,
    };
    await dispatch(
      archiveLocationAction(data, () => {
        handleArchive(true);
      })
    );
  };

  const getActions = ({ canLocationManagerEdit }) => {
    const actions = [];
    if (archiveLocation?.isArchive) {
      actions?.push(
        { label: 'Unarchive', onClick: (e) => unArchiveLocationAction(e), access: 'EDITLOC' },
        { label: 'Delete', onClick: (e) => setDeleteLocationActionForModal(e), access: 'DELETELOC' }
      );
    } else if (loggedInRole < 3) {
      actions?.push(
        {
          label: 'Edit',
          onClick: (e) => history.push(`${ROUTE_CONSTANTS.LOCATIONS}/update/${e.locationId}`),
          access: 'EDITLOC',
        },
        {
          label: 'Archive',
          onClick: async (e) => {
            if (e?.locationId) {
              await dispatch(
                getDetailsBeforeArchiveLocationAction(
                  {
                    locationId: e?.locationId,
                  },
                  () => {
                    setArchiveLocationActionforModal(e);
                  }
                )
              );
            }
          },
          access: 'DELETELOC',
        },
        {
          label: 'Copy',
          onClick: (e) => setCopyAction(e),
          access: 'EDITLOC',
        },
        { label: 'Duplicate', onClick: (e) => setDuplicateAction(e), access: 'CREATELOC' },
        {
          label: 'Delete',
          onClick: async (e) => {
            if (e?.locationId) {
              await dispatch(
                getDetailsBeforeArchiveLocationAction(
                  {
                    locationId: e?.locationId,
                  },
                  () => {
                    setDeleteLocationActionForModal(e);
                  }
                )
              );
            }
          },
          access: 'DELETELOC',
        }
      );
    } else if (loggedInRole === 3 && canLocationManagerEdit) {
      actions?.push(
        {
          label: 'Edit',
          onClick: (e) => history.push(`${ROUTE_CONSTANTS.LOCATIONS}/update/${e.locationId}`),
          access: 'EDITLOC',
        },
        {
          label: 'Copy',
          onClick: (e) => setCopyAction(e),
          access: 'EDITLOC',
        }
      );
    } else {
      actions?.push('DISABLED');
    }
    return actions;
  };

  const getRowData = () =>
    docs?.map((dt) => ({
      ...dt,
      action: getActions(dt),
    }));

  useEffect(() => {
    (async () => {
      const isArchive = paramIsArchive === 'true' ? true : archiveLocation?.isArchive;
      const params = {
        page: paramPage ?? page ?? 1,
        limit: paramLimit ?? limit ?? 15,
        isArchive,
      };
      dispatch(
        updateArchiveLocationDetails({
          fieldName: 'isArchive',
          value: isArchive,
        })
      );
      await getLocationListByFilter({ ...params });
    })();
  }, []);

  return (
    <div className="location-wrapper">
      <div className="location-header">
        <EzyInputField
          placeholder="Search by name , address"
          className="search-box"
          value={searchText}
          onChange={handleSearch}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              await getLocationListByFilter({ page: 1, limit });
            }
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconByName name="search" />
            </InputAdornment>
          }
        />
        <div className="flex-center gap-10">
          {loggedInRole < 3 && (
            <>
              <span>Archived</span>

              <EzySwitchButton
                checked={archiveLocation?.isArchive}
                onChange={(e) => handleArchive(e?.target?.checked)}
              />
            </>
          )}
          <EzyButton
            label="Add"
            className="no-pad-button"
            startIcon={<IconByName name="add" />}
            onClick={() => history.push(`${ROUTE_CONSTANTS.LOCATIONS}/add`)}
            access="CREATELOC"
          />
        </div>
      </div>

      <div className="table-wrapper">
        <Paper className="table-paper">
          <EzyTable
            headers={headers}
            rows={getRowData()}
            loading={getLocationListLoader}
            onRowClick={(e) => {
              if (!archiveLocation?.isArchive) {
                history.push(`${ROUTE_CONSTANTS.LOCATIONS}/update/${e.locationId}`);
              }
            }}
            accessForRow={['EDITLOC']}
            accessForAction={['EDITLOC', 'CREATELOC', 'DELETELOC']}
          />
          <EzyPagination
            total={total}
            pages={pages}
            pageActionClick={pageActionClick}
            limit={limit}
            page={page}
            onChangeLimit={onSelectLimit}
          />
        </Paper>
      </div>

      <CopyLocation
        isOpen={!isEmpty(copyAction) || !isNull(copyAction)}
        handleClose={async (isSuccess = false) => {
          setCopyAction(null);
          dispatch(resetCopyLocationDetails());
          if (isSuccess === true) {
            await getLocationListByFilter();
          }
        }}
        rowData={copyAction}
      />
      <DuplicateLocation
        isOpen={!isEmpty(duplicateAction) || !isNull(duplicateAction)}
        handleClose={async (isSuccess = false) => {
          setDuplicateAction(null);
          dispatch(resetDuplicateLocation());
          if (isSuccess === true) {
            await getLocationListByFilter();
          }
        }}
        rowData={duplicateAction}
      />
      <ArchiveLocation
        isOpen={!isEmpty(archiveLocationActionforModal) || !isNull(archiveLocationActionforModal)}
        handleClose={async (isSuccess = false) => {
          setArchiveLocationActionforModal(null);
          dispatch(resetArchiveLocationDetails());
          if (isSuccess === true) {
            await getLocationListByFilter();
          }
        }}
        rowData={archiveLocationActionforModal}
      />
      <DeleteLocation
        isOpen={!isEmpty(deleteLocationActionForModal) || !isNull(deleteLocationActionForModal)}
        handleClose={async (isSuccess = false) => {
          setDeleteLocationActionForModal(null);
          dispatch(resetArchiveLocationDetails());
          dispatch(resetDeleteLocationDetails());
          if (isSuccess === true) {
            await getLocationListByFilter();
          }
        }}
        rowData={deleteLocationActionForModal}
      />
    </div>
  );
}

export default LocationList;
