import React, { useEffect, useMemo, useState } from 'react';
import { Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty, isNull } from 'lodash';
import useQueryParams from '../../../hooks/useQueryParams';
import useUrlParamsUpdate from '../../../hooks/useURLParamsUpdate';
import {
  deleteBusinessLeaveRuleByLeaveRuleId,
  fetchBusinessLeaveRulesAction,
} from '../redux/businessAction';
import EzyTable from '../../../components/common/table/EzyTable';
import EzyPagination from '../../../components/common/pagination/EzyPagination';
import { updateLeaveRuleData, updateLeaveRuleDetails } from '../redux/businessSlice';
import ConfirmationModal from '../../../components/common/modal/ConfirmationModal';

function BusinessLeaveRules() {
  const [deleteModalData, setDeleteModalData] = useState(null);
  const { businessLeaveRulesList } = useSelector(({ business }) => business ?? {});
  const { docs, headers, total, pages, page, limit } = useMemo(
    () => businessLeaveRulesList ?? {},
    [businessLeaveRulesList]
  );

  const { fetchBusinessLeaveRulesActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const { peopleId } = useParams();

  const { page: paramPage, limit: paramLimit } = useQueryParams();
  const dispatch = useDispatch();

  useUrlParamsUpdate({
    page: page ?? 1,
    limit: limit ?? 15,
  });

  const businessLeaveList = async (params = {}) => {
    const data = {
      page: page ?? 1,
      limit: limit ?? 15,
      personDetailId: peopleId,
      ...params,
    };
    await dispatch(fetchBusinessLeaveRulesAction(data));
  };

  const pageActionClick = async (newPage) => {
    await businessLeaveList({ page: newPage, limit });
  };
  const onSelectLimit = async (newLimit) => {
    await businessLeaveList({ page: 1, limit: newLimit });
  };

  const onClickDeleteRule = (e) => setDeleteModalData(e);
  const handelDelete = async (id) => {
    await dispatch(
      deleteBusinessLeaveRuleByLeaveRuleId(id, () => {
        businessLeaveList();
      })
    );
  };

  const onEditClick = (e) => {
    dispatch(updateLeaveRuleData({ fieldName: 'isEditLeaveRules', value: true }));
    dispatch(updateLeaveRuleData({ fieldName: 'leaveRulesDrawer', value: true }));
    dispatch(updateLeaveRuleDetails({ fieldName: 'id', value: e?.id }));
  };
  const tableActions = [
    {
      label: 'Edit',
      onClick: onEditClick,
    },
    {
      label: 'Delete',
      onClick: (e) => onClickDeleteRule(e.id),
    },
  ];

  useEffect(() => {
    const params = {
      page: paramPage ?? page ?? 1,
      limit: paramLimit ?? limit ?? 15,
    };
    businessLeaveList({ ...params });
  }, []);

  return (
    <Paper className="table-paper">
      <EzyTable
        headers={headers}
        rows={docs}
        actions={tableActions}
        loading={fetchBusinessLeaveRulesActionLoader}
        onRowClick={onEditClick}
      />
      <EzyPagination
        total={total}
        pages={pages}
        pageActionClick={pageActionClick}
        limit={limit}
        page={page}
        onChangeLimit={onSelectLimit}
      />
      <ConfirmationModal
        title="Delete Leave Rule"
        isOpen={!isEmpty(deleteModalData) || !isNull(deleteModalData)}
        handleClose={() => {
          setDeleteModalData(null);
        }}
        handleSubmit={async () => {
          await handelDelete(deleteModalData);
          setDeleteModalData(null);
        }}
        primaryLabel="Delete"
      >
        <div>Are you sure you want to delete?</div>
      </ConfirmationModal>
      {/* <LeaveEntitlementDrawer /> */}
    </Paper>
  );
}

export default BusinessLeaveRules;
