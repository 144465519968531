import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import { editPeopleDetails } from '../../redux/peopleSlice';
import EzyAutoComplete from '../../../../components/common/autoComplete/EzyAutoComplete';
import EzyDate from '../../../../components/common/dateAndTimePicker/EzyDate';
import { addNewWorkPeriodAction, getWorkPeriodsByBusinessId } from '../../redux/peopleActions';
import { validateAddWorkPeriod } from '../../validations/addWorkPeriodValidations';

function WorkPeriodDrawer() {
  const { details } = useSelector(({ people }) => people?.editPeople ?? {});
  const { addNewWorkPeriod, workPeriodLength, day, startDate, addWorkPeriodErrors } = useMemo(
    () => details ?? {},
    [details]
  );

  const { addNewWorkPeriodActionLoader } = useSelector(({ common }) => common.generalLoader ?? {});

  const { enums } = useSelector(({ common }) => common ?? {});

  const { workPeriodLength: periodLengthList, weekDays } = useMemo(() => enums ?? {}, [enums]);

  const dispatch = useDispatch();

  const resetWorkPeriodFields = () => {
    dispatch(editPeopleDetails({ fieldName: 'addNewWorkPeriod', value: false }));
    dispatch(editPeopleDetails({ fieldName: 'workPeriodLength', value: '' }));
    dispatch(editPeopleDetails({ fieldName: 'day', value: '' }));
    dispatch(editPeopleDetails({ fieldName: 'startDate', value: '' }));
  };

  const submitCB = async (data) => {
    await dispatch(getWorkPeriodsByBusinessId());
    dispatch(editPeopleDetails({ fieldName: 'workPeriodId', value: data }));
    resetWorkPeriodFields();
  };

  const handleClose = () => {
    resetWorkPeriodFields();
  };

  const actionButton = [
    {
      label: 'Save',
      onClick: () => {
        const data = {
          workPeriodLength: workPeriodLength?.id,
          day: workPeriodLength?.id === 1 ? day?.id : undefined,
          startDate: workPeriodLength?.id !== 1 ? startDate : undefined,
        };
        const errors = validateAddWorkPeriod(data);
        dispatch(editPeopleDetails({ fieldName: 'addWorkPeriodErrors', value: errors }));
        if (isEmpty(errors)) dispatch(addNewWorkPeriodAction(data, submitCB));
      },
      loading: addNewWorkPeriodActionLoader,
    },
  ];

  return (
    <EzyDrawer
      onClose={handleClose}
      isOpen={addNewWorkPeriod?.id === 'ADD_NEW'}
      actionButtons={actionButton}
      heading="Select Workperiod "
    >
      <div className="work-period-drawer">
        <div className="work-period-field-grid">
          <EzyAutoComplete
            placeholder="Select work period"
            label="Work period length"
            options={periodLengthList}
            value={workPeriodLength?.label}
            name="workPeriodLength"
            onChange={(_, value) =>
              dispatch(editPeopleDetails({ fieldName: 'workPeriodLength', value }))
            }
            error={addWorkPeriodErrors?.workPeriodLength}
            required
          />
          {workPeriodLength?.id > 0 && workPeriodLength?.id === 1 && (
            <EzyAutoComplete
              placeholder="Select day"
              label="Next work period start on"
              options={weekDays}
              value={day?.label}
              name="day"
              onChange={(_, value) => dispatch(editPeopleDetails({ fieldName: 'day', value }))}
              error={addWorkPeriodErrors?.day}
              required
            />
          )}
          {workPeriodLength?.id > 0 && workPeriodLength?.id !== 1 && (
            <EzyDate
              name="startDate"
              placeholder="Select date"
              label="Next work period start on"
              value={startDate}
              onChange={(value) => dispatch(editPeopleDetails({ fieldName: 'startDate', value }))}
              error={addWorkPeriodErrors?.startDate}
              required
            />
          )}
        </div>
      </div>
    </EzyDrawer>
  );
}

export default WorkPeriodDrawer;
