import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import EzyButton from '../../../components/common/button/EzyButton';
import validateAddTimeSheet from '../validations/validateAddTimeSheet';
import { updateSidePanelData, updateTimeSheetData } from '../redux/timeSheetsSlice';
import {
  changeTimeSheetStatusAction,
  editTimeSheetDetailsAction,
  editTimesheetAllowancesAction,
  getAllTimesheetByPerson,
  getPersonListBasedOnLocationAction,
  getTimeSheetViewDetailsAction,
} from '../redux/timeSheetsAction';
import { timeSheetActionButtons, timeSheetActions } from '../../../utils/constants';

function TimeSheetDetailsAction() {
  const dispatch = useDispatch();
  const { timeSheetData, sidePanelData } = useSelector(({ timeSheets }) => timeSheets ?? {});
  const { roleId: loggedInRole } = useSelector(({ user }) => user?.profileData ?? {});

  const data1 = timeSheetData?.viewTimeSheetData?.id
    ? timeSheetData?.viewTimeSheetData
    : timeSheetData;

  const {
    timeSheetStatusDISCARDLoader,
    // eslint-disable-next-line camelcase
    timeSheetStatusUN_APPROVE_TIMELoader,
    // eslint-disable-next-line camelcase
    timeSheetStatusUN_APPROVE_PAYLoader,
    // eslint-disable-next-line camelcase
    timeSheetStatusRE_INSTATELoader,
    timeSheetStatusAPPROVELoader,
    // getTimeSheetViewDetailsActionLoader,
    editTimeSheetDetailsActionLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});

  const { selectedTimesheet, selectedPerson, weekDatesValue, selectedLocation } = useMemo(
    () => sidePanelData,
    [sidePanelData]
  );

  const {
    areaId,
    startTime,
    endTime,
    totalMealBreak = 0,
    totalRestBreak = 0,
    timeSheetBreakDetailEntities,
    // timeSheetPhotoURL,
  } = useMemo(() => data1, [data1]);

  const getTimesheetsByFilter = async (moveNext = false) => {
    const finalParams = {
      startDate: moment
        .tz(weekDatesValue?.startDate, selectedLocation?.timeZoneId?.label)
        ?.toISOString(),
      endDate: moment
        .tz(weekDatesValue?.endDate, selectedLocation?.timeZoneId?.label)
        ?.toISOString(),
      locationId:
        selectedLocation?.id !== 'all' && !selectedLocation?.locationId
          ? selectedLocation?.id
          : null,
      areaId: selectedLocation?.locationId ? selectedLocation?.id : null,
    };
    dispatch(
      getPersonListBasedOnLocationAction(finalParams, async (listOfPerson) => {
        dispatch(
          updateSidePanelData({
            fieldName: 'selectedPerson',
            value: listOfPerson?.find((e) => e?.id === selectedPerson?.id) || listOfPerson?.[0],
          })
        );

        await dispatch(
          getAllTimesheetByPerson(
            { ...finalParams, personDetailId: selectedPerson?.id },
            (listOfTimesheet) => {
              const timesheet =
                listOfTimesheet?.[
                  listOfTimesheet.findIndex((e) => e?.id === selectedTimesheet?.id) + 1
                ]?.id && moveNext
                  ? listOfTimesheet?.[
                      listOfTimesheet.findIndex((e) => e?.id === selectedTimesheet?.id) + 1
                    ]
                  : listOfTimesheet?.find((e) => e?.id === selectedTimesheet?.id);

              dispatch(
                updateSidePanelData({
                  fieldName: 'selectedTimesheet',
                  value: timesheet,
                })
              );

              if (
                (timesheet?.timeSheetStatus === 'Approved' &&
                  timesheet?.timeSheetProgressStaus === 'Pay Approved') ||
                (timesheet?.timeSheetStatus === 'Pending' &&
                  timesheet?.timeSheetProgressStaus === 'Time Approved') ||
                timesheet?.timeSheetStatus === 'Discarded'
              ) {
                dispatch(getTimeSheetViewDetailsAction({ timesheetId: timesheet?.id }));
              } else {
                dispatch(getTimeSheetViewDetailsAction({ timesheetId: timesheet?.id }, true));
              }
            }
          )
        );
      })
    );
  };

  const allowancesData = [
    ...timeSheetData.timesheetAllowances.baseRate.map((e) => {
      return {
        ...e,
        isBaseRate: true,
        unitRate: timeSheetData.timesheetAllowances?.originalValue?.dropDownAllowances?.find(
          (dropdownValue) => dropdownValue?.payrateString === e?.payrateString
        )?.unitRate,
      };
    }),
    ...timeSheetData.timesheetAllowances.shiftLoadings.map((e) => {
      return {
        ...e,
        isBaseRate: false,
        unitRate: timeSheetData.timesheetAllowances?.originalValue?.dropDownAllowances?.find(
          (dropdownValue) => dropdownValue?.payrateString === e?.payrateString
        )?.unitRate,
      };
    }),
  ];

  const handleSubmit = () => {
    const data = {
      date: startTime,
      personDetailId: selectedPerson?.id,
      areaId: areaId?.id || areaId,
      startTime,
      endTime,
      totalMealBreak: Number(totalMealBreak),
      totalRestBreak: Number(totalRestBreak),
      timeSheetBreakDetailEntities,
      id: selectedTimesheet?.id ?? undefined,
    };

    const error = validateAddTimeSheet(
      data,
      areaId?.timeZoneId?.label,
      timeSheetData?.viewTimeSheetData?.id ? 'view' : 'edit',
      data1?.progressStatus === 5 ? allowancesData : []
    );

    dispatch(updateTimeSheetData({ fieldName: 'errors', value: error }));

    if (isEmpty(error)) {
      const finalData = cloneDeep(data);

      finalData.timeSheetBreakDetailEntities = finalData.timeSheetBreakDetailEntities
        ?.filter((e) => e?.breakType?.id)
        ?.map((e) => ({
          breakType: e?.breakType?.id,
          breakStartTime: moment.tz(e?.breakStartTime, areaId?.timeZoneId?.label)?.toISOString(),
          breakEndTime: moment.tz(e?.breakEndTime, areaId?.timeZoneId?.label)?.toISOString(),
          duration: Number(e?.duration),
        }));

      if (selectedTimesheet?.id) {
        dispatch(
          editTimeSheetDetailsAction(finalData, async () => {
            if (data1?.progressStatus === 5 && allowancesData?.length > 0) {
              dispatch(
                editTimesheetAllowancesAction(
                  {
                    timeSheetId: selectedTimesheet?.id,
                    timesheetAllowances: allowancesData,
                  },
                  () => getTimesheetsByFilter(true)
                )
              );
            } else {
              getTimesheetsByFilter(true);
            }
          })
        );
      }
    }
  };

  const handleStatusChange = (status) => {
    if (status === timeSheetActions.approve) {
      const data = {
        date: startTime,
        personDetailId: selectedPerson?.id,
        areaId: areaId?.id || areaId,
        startTime,
        endTime,
        totalMealBreak: Number(totalMealBreak),
        totalRestBreak: Number(totalRestBreak),
        timeSheetBreakDetailEntities,
        id: selectedTimesheet?.id ?? undefined,
      };

      const error = validateAddTimeSheet(
        data,
        areaId?.timeZoneId?.label,
        timeSheetData?.viewTimeSheetData?.id ? 'view' : 'edit',
        data1?.progressStatus === 5 ? allowancesData : []
      );
      dispatch(updateTimeSheetData({ fieldName: 'errors', value: error }));

      if (isEmpty(error)) {
        dispatch(
          changeTimeSheetStatusAction(
            { timeSheetAction: status, timeSheetIds: [data1?.id || selectedTimesheet?.id] },
            () => {
              dispatch(
                editTimesheetAllowancesAction(
                  {
                    timeSheetId: selectedTimesheet?.id,
                    timesheetAllowances: allowancesData,
                  },
                  () => getTimesheetsByFilter(true)
                )
              );
            }
          )
        );
      }
    } else {
      dispatch(
        changeTimeSheetStatusAction(
          { timeSheetAction: status, timeSheetIds: [data1?.id || selectedTimesheet?.id] },
          () => {
            getTimesheetsByFilter();
          }
        )
      );
    }
  };

  return (
    <div className="actions">
      {/* approve & next for status pending */}
      {[1, 3]?.includes(data1?.status) && (
        <EzyButton
          label="Approve & next"
          onClick={handleSubmit}
          loading={editTimeSheetDetailsActionLoader || timeSheetStatusAPPROVELoader}
        />
      )}

      {/* re-instate for status discarded */}
      {data1?.status === 4 && (
        <EzyButton
          label={timeSheetActionButtons.reInstate}
          onClick={() => handleStatusChange(timeSheetActions.reInstate)}
          // eslint-disable-next-line camelcase
          loading={timeSheetStatusRE_INSTATELoader}
        />
      )}

      {[1, 2]?.includes(data1?.status) && [3, 5, 8]?.includes(data1?.progressStatus) && (
        <div className="d-flex just-bet gap-5">
          Time Approval <span className="primary-color">Approved</span>
        </div>
      )}

      {[1, 2]?.includes(data1?.status) && [3, 5]?.includes(data1?.progressStatus) && (
        <EzyButton
          label={timeSheetActionButtons.unApproveTime}
          onClick={() => handleStatusChange(timeSheetActions.unApproveTime)}
          // eslint-disable-next-line camelcase
          loading={timeSheetStatusUN_APPROVE_TIMELoader}
          variant="outlined"
        />
      )}

      {/* pending time approval text for status PENDING & N/A and progress status SUBMITTED , STARTED , FUTURE , POSSIBLE ABSENTEE , UNSUBMITTED */}
      {[1, 3]?.includes(data1?.status) && ![3, 5]?.includes(data1?.progressStatus) && (
        <div className="d-flex just-bet gap-5">
          Time Approval <span className="secondary-color">Pending</span>
        </div>
      )}

      {/* time approve button for status PENDING and progress status SUBMITTED & UNSUBMITTED */}
      {data1?.status === 1 && [2, 4]?.includes(data1?.progressStatus) && (
        <EzyButton
          label={timeSheetActionButtons.approveTime}
          onClick={() => handleStatusChange(timeSheetActions.approve)}
          // eslint-disable-next-line camelcase
          loading={timeSheetStatusAPPROVELoader}
          variant="outlined"
        />
      )}

      {data1?.status === 2 && [3, 8]?.includes(data1?.progressStatus) && (
        <div className="d-flex just-bet gap-5">
          Pay Approval <span className="primary-color">Approved</span>
        </div>
      )}

      {/* unapprove pay button for status APPROVE and progress status PAY APPROVED */}
      {loggedInRole !== 4 && data1?.status === 2 && [3]?.includes(data1?.progressStatus) && (
        <EzyButton
          label={timeSheetActionButtons.unApprovePay}
          onClick={() => handleStatusChange(timeSheetActions.unApprovePay)}
          // eslint-disable-next-line camelcase
          loading={timeSheetStatusUN_APPROVE_PAYLoader}
          variant="outlined"
        />
      )}

      {/* approve pay button for status PENDING and progress status TIME APPROVED */}
      {loggedInRole !== 4 && data1?.status === 1 && [5]?.includes(data1?.progressStatus) && (
        <>
          <div className="d-flex just-bet gap-5">
            Pay Approval <span className="secondary-color">Pending</span>
          </div>
          <EzyButton
            label={timeSheetActionButtons.approvePay}
            onClick={() => handleStatusChange(timeSheetActions.approve)}
            // eslint-disable-next-line camelcase
            loading={timeSheetStatusAPPROVELoader}
            variant="outlined"
          />
        </>
      )}

      {/* discard timesheet for status other then discarded */}
      {data1?.status !== 4 && data1?.progressStatus !== 8 && (
        <EzyButton
          label="Discard timesheet"
          color="error"
          onClick={() => handleStatusChange(timeSheetActions.discard)}
          loading={timeSheetStatusDISCARDLoader}
        />
      )}

      {data1?.status !== 4 && data1?.timeSheetPhotoURL && (
        <img src={data1?.timeSheetPhotoURL} alt="" />
      )}
    </div>
  );
}

TimeSheetDetailsAction.propTypes = {};

export default TimeSheetDetailsAction;
