import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Checkbox,
  ListItemIcon,
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import moment from 'moment';
import { getLocationDropDownListByBusinessId } from '../../location/redux/locationActions';
import { getTasksAction, getTasksByAreaAction } from '../redux/tasksActions';
import IconByName from '../../../components/common/iconByName/IconByName';
import Loader from '../../../components/common/loader/Loader';
import { updateTaskListFilters, resetAreaTaskList } from '../redux/tasksSlice';
import EzyDatePicker from '../../../components/common/dateAndTimePicker/EzyDatePicker';
import useQueryParams from '../../../hooks/useQueryParams';

function TasksSidebar() {
  const { locationListEnum } = useSelector(({ location }) => location ?? []);
  const { filters, tasksList } = useSelector(({ tasks }) => tasks ?? {});
  const { roleId } = useSelector(({ user }) => user?.profileData ?? {});
  const dispatch = useDispatch();
  const { date, selectedLocation, taskType } = useMemo(() => filters, [filters]);
  const { getLocationsByBusinessIdLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );
  const { locationId: locationParam, date: dateParam } = useQueryParams();

  const taskFilters = [
    {
      id: 1,
      label: 'My tasks',
      onClick: async () => {
        await dispatch(
          getTasksAction({
            myTask: true,
            page: 1,
            limit: 15,
            searchText: null,
          })
        );
        dispatch(updateTaskListFilters({ fieldName: 'taskType', value: 'My tasks' }));
      },
    },
    {
      id: 2,
      label: 'Assigned tasks',
      onClick: async () => {
        await dispatch(
          getTasksAction({
            myTask: false,
            page: 1,
            limit: 15,
            searchText: null,
          })
        );
        dispatch(updateTaskListFilters({ fieldName: 'taskType', value: 'Assigned tasks' }));
      },
    },
    {
      id: 3,
      label: 'Tasks By Area',
      onClick: async () => {
        dispatch(updateTaskListFilters({ fieldName: 'taskType', value: 'Tasks By Area' }));
        const conditionalParams = roleId < 4 && {
          date: moment(date).toISOString(),
          locationId: selectedLocation,
        };
        await dispatch(getTasksByAreaAction(conditionalParams));
      },
    },
  ];

  const getFilteredTasksByArea = async (params) => {
    await dispatch(
      getTasksByAreaAction({
        date: dateParam ?? moment(date).toISOString(),
        locationId: locationParam ?? selectedLocation,
        ...params,
      })
    );
  };

  const getTasksByAreaHandler = async (value) => {
    dispatch(
      updateTaskListFilters({
        fieldName: 'selectedLocation',
        value,
      })
    );
    getFilteredTasksByArea({ locationId: value });
  };

  useEffect(() => {
    if (filters?.taskType === 'Tasks By Area') {
      (async () => {
        await getTasksByAreaHandler(locationParam);
      })();
    }
    return () => {
      dispatch(resetAreaTaskList());
    };
  }, []);

  useEffect(() => {
    dispatch(getTasksAction({ myTask: true, page: 1, limit: 15, searchText: null }));
    dispatch(
      getLocationDropDownListByBusinessId((data) => {
        dispatch(
          updateTaskListFilters({
            fieldName: 'selectedLocation',
            value: locationParam ?? data?.[0].id,
          })
        );
      })
    );

    return () => {
      dispatch(updateTaskListFilters({ fieldName: 'taskType', value: 'My tasks' }));
    };
  }, []);

  return (
    <div className="task-list-sidebar">
      <div className="task-filter-container">
        {taskFilters.map((filter) => (
          <div
            key={filter?.id}
            onClick={filter?.onClick}
            className={`filters d-flex gap-10 just-bet ${
              filter?.label === taskType ? 'selected-filter' : ''
            }`}
          >
            <div className="d-flex gap-10">
              {filter?.label === taskType ? (
                <span className="material-icons">check_circle</span>
              ) : (
                <span className="material-icons">check_circle_outline</span>
              )}

              <span>{filter?.label}</span>
            </div>

            {filter?.label === taskType && filter?.id !== 3 ? (
              <span className="complete-task">
                {tasksList?.completedTask}/{tasksList?.totalTask} Completed
              </span>
            ) : (
              ''
            )}
          </div>
        ))}
      </div>
      {taskType === 'Tasks By Area' && roleId < 4 && (
        <div className="location-by-area">
          <EzyDatePicker
            className="task-date-picker"
            value={date}
            onChange={async (selectedDate) => {
              dispatch(updateTaskListFilters({ fieldName: 'date', value: selectedDate }));
              await getFilteredTasksByArea({ date: moment(selectedDate)?.toISOString() });
            }}
          />
          <Paper className="task-side-card-wrapper">
            <div className="title-text">Locations</div>
            {getLocationsByBusinessIdLoader && <Loader />}
            <List className="area-check-list">
              {locationListEnum?.map((value) => {
                const labelId = `checkbox-list-label-${value?.id}`;
                return (
                  <ListItem
                    key={value?.id}
                    selected={value?.id === selectedLocation}
                    disablePadding
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={async () => {
                        await getTasksByAreaHandler(value?.id);
                      }}
                      dense
                      disableRipple
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={selectedLocation === value?.id}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                          icon={<IconByName name="check_circle_outline" />}
                          checkedIcon={<IconByName name="check_circle" className="primary-color" />}
                        />
                      </ListItemIcon>
                      <ListItemText id={value?.id} className="publish-checks-view">
                        <span className="location-name">{value?.label}</span>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </div>
      )}
    </div>
  );
}

export default TasksSidebar;
