import { filter, isEmpty, isNil, omitBy, toLower } from 'lodash';
import {
  checkIsEmpty,
  findDuplicates,
  requireValidate,
  validatePhoneNumberLength,
} from '../../../utils/validationHelper';

const validateNewBusinessData = (data) => {
  const {
    businessName,
    countryId,
    businessIndustryTypeId,
    numberOfPeople,
    locations,
    phoneNumber,
    phoneNumberData,
  } = data ?? {};
  const error = {};
  error.businessName = requireValidate('Business Name', businessName);
  error.countryId = requireValidate('Country', countryId);
  error.businessIndustryTypeId = requireValidate('Business Industry Type', businessIndustryTypeId);
  error.numberOfPeople = requireValidate('Number Of People', numberOfPeople);
  error.locations = locations.map((e) =>
    omitBy(
      {
        locationName: requireValidate('Location Name', e?.locationName),
        address: requireValidate('Location Address', e?.address),
        areas: checkIsEmpty('Location Areas', e?.areas),
        city: requireValidate('City', e?.city),
      },
      isNil
    )
  );
  if (
    phoneNumber &&
    phoneNumberData &&
    !validatePhoneNumberLength(phoneNumber, phoneNumberData?.format)
  ) {
    error.phoneNumber = 'Invalid phone number!';
  }
  const duplicates = findDuplicates(locations?.map((e) => toLower(e?.locationName)));
  if (duplicates?.length > 0) {
    duplicates?.forEach((e) => {
      error.locations[e].locationName = 'Location name already exists!';
    });
  }
  error.locations = filter(error.locations, (e) => !isEmpty(e))?.length ? error.locations : null;
  return omitBy(error, isNil);
};

export default validateNewBusinessData;
