import { Link } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import checkYourEmail from '../../../../assets/images/Check your email.png';
import InfoModal from '../../../../components/infoModal/InfoModal';
import { sendPasswordToMail } from '../../redux/peopleActions';

function SendPasswordIntoEmailModal({ email, model, setModel }) {
  const dispatch = useDispatch();
  return (
    <InfoModal
      img={checkYourEmail}
      subTitle={` We have sent the email to ${email}`}
      title={`We have sent the email `}
      onClose={() => setModel(null)}
      action={
        <div>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Don't receive the email?
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            className="people-details-email-resend"
            onClick={() => {
              dispatch(sendPasswordToMail(email));
            }}
          >
            Click to resend
          </Link>
        </div>
      }
      open={model === 'SendPasswordIntoEmail'}
    />
  );
}

export default SendPasswordIntoEmailModal;

SendPasswordIntoEmailModal.propTypes = {
  email: PropTypes.string,
  model: PropTypes.string.isRequired,
  setModel: PropTypes.func.isRequired,
};
SendPasswordIntoEmailModal.defaultProps = {
  email: '',
};
