// const getTimeZone = () => moment.tz.zonesForCountry('AU');

import moment from 'moment-timezone';
import {
  DATE_FORMAT,
  DATE_SIMPLE_FORMAT,
  DATE_TIME_FORMAT,
  TIME_FORMAT,
  TIME_FORMAT_24,
} from './constants';

const getTimeZone = () => 'Australia/Sydney';

export const startTime = (date) => {
  if (date && moment(date).isValid()) {
    return moment.utc(date).startOf('day').toISOString();
  }
  return null;
};

export const getHoursAndMinutesFromHours = (hours) => {
  if (hours && hours > 0) {
    const mins = hours * 60;
    const h = mins / 60 || 0;
    const m = mins % 60 || 0;
    return { hours: Math.floor(h), minutes: m };
  }
  return { hours: 0, minutes: 0 };
};
export const displayHoursAndMinutesFromHours = (hours) => {
  if (hours && hours > 0) {
    const mins = hours * 60;
    const h = mins / 60 || 0;
    const m = mins % 60 || 0;
    return `${Math.floor(h)}h ${Math.floor(m)}m`;
  }
  return `0h 0m`;
};

export const displayDateTime = (date = null, timeZone = moment.tz.guess()) => {
  if (moment(date).isValid()) {
    return moment.tz(date, timeZone).format(DATE_TIME_FORMAT);
  }

  return '-';
};

export const displayDate = (date = null, timeZone = null) => {
  if (moment(date).isValid()) {
    return moment
      .tz(moment(date)?.toISOString(), timeZone ?? moment.tz.guess())
      .format(DATE_FORMAT);
  }

  return '-';
};

export const displayTime = (date = null, timeZone = null, is24Hour = false) => {
  if (moment(date).isValid()) {
    return moment
      .tz(moment(date)?.toISOString(), timeZone ?? moment.tz.guess())
      .format(is24Hour ? TIME_FORMAT_24 : TIME_FORMAT);
  }

  return '-';
};

export const concatDateAndTime = (date, time, timeZone = moment.tz.guess()) => {
  return moment
    .tz(date, timeZone)
    .set({
      hour: moment.tz(time ?? 0, timeZone).format('HH'),
      minute: moment.tz(time ?? 0, timeZone).format('mm'),
    })
    .toISOString();
};

export const getDurationInMinutes = (start, end) => {
  if (moment(start).isValid() && moment(end).isValid())
    return moment(end).diff(moment(start), 'minute');
  return 0;
};
export const getDurationInHours = (start, end) => {
  if (start && end && moment(start).isValid() && moment(end).isValid())
    return moment(end).diff(moment(start), 'hour', true).toFixed(2);
  return 0;
};

export const dayCheck = (date) => {
  const thisYear = moment().year();
  const mom = moment(date).year(thisYear);
  return mom.calendar(null, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: DATE_FORMAT,
  });
};

export const weekRangeFromDate = (date) => {
  if (date && moment(date).isValid()) {
    return {
      startDate: moment(date).startOf('isoWeek'),
      endDate: moment(date).endOf('isoWeek'),
    };
  }
  return {
    startDate: moment(new Date()).startOf('isoWeek'),
    endDate: moment(new Date()).endOf('isoWeek'),
  };
};
export default getTimeZone;

export const formatInvoiceDate = (date = null, timeZone = null) => {
  if (moment(date).isValid()) {
    return moment
      .tz(moment(date)?.toISOString(), timeZone ?? moment.tz.guess())
      .format(DATE_SIMPLE_FORMAT);
  }

  return '-';
};

export const weekDatesValueBasedOnDuration = (data, duration) => {
  const params = {};
  if (duration?.label === 'Day') {
    params.startDate = moment.tz(data?.startDate, 'UTC').startOf('day');
    params.endDate = moment.tz(data?.startDate, 'UTC').endOf('day');
  } else if (duration?.label === 'Month') {
    params.startDate = moment.tz(data?.startDate, 'UTC').startOf('month');

    params.endDate = moment.tz(data?.startDate, 'UTC').endOf('month');
  } else if (duration?.label === 'Week') {
    params.startDate = moment.tz(data?.startDate, 'UTC').startOf('week');
    params.endDate = moment.tz(data?.startDate, 'UTC').endOf('week');
  } else {
    params.startDate = moment.tz(data?.startDate, 'UTC').startOf('week');
    params.endDate = moment
      .tz(data?.startDate, 'UTC')
      .startOf('week')
      .add(duration.dayCount - 1, 'days')
      .endOf('day');
  }

  return params;
};
