import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import EzyAutoComplete from '../../../components/common/autoComplete/EzyAutoComplete';
import {
  addUpdatePeopleFields,
  resetAddPeopleFields,
  updateAddPeopleFields,
  updatePeopleData,
} from '../redux/peopleSlice';
import EzyPhoneNumber from '../../../components/common/phoneNumberInput/ezyPhoneNumber';
import PeopleImport from '../../location/components/PeopleImport';
import { getLocationsByBusinessId } from '../../location/redux/locationActions';
import EzyIconButton from '../../../components/common/iconButton/EzyIconButton';
import EzySwitchButton from '../../../components/common/switch/EzySwitchButton';
import { getAllIntegrationsListAction } from '../../globalProfile/redux/globalProfileActions';
import { importPeopleFromIntegrationAction } from '../redux/peopleActions';
import ROUTE_CONSTANTS from '../../../utils/constants';
import EzyDialog from '../../../components/common/dialog/EzyDialog';
import EzyDialogTitle from '../../../components/common/dialog/EzyDialogTitle';
import EzyDialogAction from '../../../components/common/dialog/EzyDialogAction';
import Loader from '../../../components/common/loader/Loader';
import { updateAppIntegrationData } from '../../globalProfile/redux/globalProfileSlice';

function AddPeople() {
  const { addPeople } = useSelector(({ people }) => people ?? {});
  const { locationsList } = useSelector(({ people }) => people?.editPeople ?? {});
  const { enums } = useSelector(({ common }) => common ?? {});
  const dispatch = useDispatch();
  const history = useHistory();

  const { importPeopleFromIntegrationLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const { businessId } = useSelector(({ user }) => user?.profileData || {});

  const { integrationList } = useSelector(
    ({ globalProfile }) => globalProfile?.appIntegration || {}
  );

  const { location, sendInvitationEmail, people, errors, warningMessage, isWarningModal } = useMemo(
    () => addPeople ?? {},
    [addPeople]
  );

  const handleFieldChange = (name, value) => {
    dispatch(updateAddPeopleFields({ fieldName: name, value }));

    if (name === 'location') {
      dispatch(getAllIntegrationsListAction());
    }
  };

  const handlePeoplesArrayChange = (name, value, index) => {
    dispatch(updatePeopleData({ fieldName: name, value, index }));
  };

  useEffect(() => {
    dispatch(resetAddPeopleFields());
    dispatch(getLocationsByBusinessId());
  }, []);

  return (
    <div className="add-people-root-container">
      {importPeopleFromIntegrationLoader && <Loader />}
      <div className="two-grid p-x-24">
        <EzyAutoComplete
          options={locationsList ?? []}
          name="location"
          placeholder="Select location"
          value={location}
          label="Location"
          onChange={(_, value) => handleFieldChange('location', value)}
          error={errors?.location}
          required
        />
        {location && (
          <EzyAutoComplete
            placeholder="Import people from"
            label="Import people"
            options={[
              { id: 1, label: 'CSV' },
              ...integrationList.map((e) => {
                return { id: e?.integrationId, label: e?.integrationName };
              }),
            ]}
            onChange={(_, value) => {
              if (value?.label === 'CSV') {
                handleFieldChange('selectedUploader', value?.label);
                handleFieldChange('uploaderIndex', 0);
              } else {
                dispatch(
                  importPeopleFromIntegrationAction({
                    locationId: location?.id,
                    businessId,
                    integrationId: value?.id,
                  })
                );
              }
            }}
          />
        )}
      </div>

      <div className="add-people-title">
        <span>People</span>
        <div className="flex-center gap-10">
          <div className="flex-center gap-10">
            <span>Send Invite Email</span>
            <EzySwitchButton
              name="sendInvitationEmail"
              checked={sendInvitationEmail}
              onChange={(e) => handleFieldChange('sendInvitationEmail', e.target.checked)}
              disabled={!people?.some((e) => e?.email)}
              className="small"
            />
            <EzyIconButton
              iconName="add"
              onClick={() => dispatch(addUpdatePeopleFields({ type: 'ADD' }))}
              className="small"
            />
          </div>
        </div>
      </div>

      {people?.map((res, index) => (
        <div className="people-field-container">
          <div className="add-people-title">
            <span> </span>
            <div className="flex-center gap-10">
              <EzyIconButton
                iconName="delete"
                onClick={() => dispatch(addUpdatePeopleFields({ type: 'DELETE', index }))}
                className="small"
              />
            </div>
          </div>
          <div className="two-grid p-x-24">
            <EzyAutoComplete
              options={enums?.roles ?? []}
              name="accessLevel"
              placeholder="Select access level"
              value={res?.accessLevel}
              label="Access level"
              onChange={(_, value) => handlePeoplesArrayChange('accessLevel', value, index)}
              error={errors?.people?.[index]?.accessLevel}
              required
            />
            <EzyInputField
              name="name"
              placeholder="Enter name"
              label="Name"
              value={res?.name}
              error={errors?.people?.[index]?.name}
              onChange={(value) => handlePeoplesArrayChange('name', value?.target?.value, index)}
              required
            />
          </div>
          <div className="two-grid p-x-24">
            <EzyInputField
              placeholder="Enter email"
              name="email"
              label="Email"
              value={res?.email}
              error={errors?.people?.[index]?.email}
              onChange={(value) => {
                handlePeoplesArrayChange('email', value?.target?.value, index);
                if (res?.email === '' || value?.target?.value === '') {
                  handleFieldChange('sendInvitationEmail', !!value?.target?.value);
                }
              }}
              required
            />
            <EzyPhoneNumber
              country="au"
              name="phoneNumber"
              placeholder="4 123 456 789"
              value={res?.phoneNumber}
              error={errors?.people?.[index]?.phoneNumber}
              label="Mobile number"
              onChange={(value, data) => {
                handlePeoplesArrayChange('phoneNumber', value, index);
                handlePeoplesArrayChange('countryCode', data?.dialCode, index);
                handlePeoplesArrayChange('phoneNumberData', data, index);
              }}
            />
          </div>
        </div>
      ))}
      <PeopleImport fromModule="people" />

      <EzyDialog
        className="common-dialog-with-body delete-account-warning-modal"
        open={isWarningModal}
        handleClose={() =>
          dispatch(updateAddPeopleFields({ fieldName: 'isWarningModal', value: null }))
        }
      >
        <EzyDialogTitle
          title="Not Integrated"
          isCloseIcon
          onClose={() =>
            dispatch(updateAddPeopleFields({ fieldName: 'isWarningModal', value: null }))
          }
        />
        <div className="dialog-body">{warningMessage}</div>
        <EzyDialogAction
          actionButtons={[
            {
              label: 'Cancel',
              color: 'error',
              onClick: () => {
                dispatch(updateAddPeopleFields({ fieldName: 'isWarningModal', value: null }));
              },
            },
            {
              label: 'Connect',
              onClick: () => {
                history.push(`${ROUTE_CONSTANTS.APP_INTEGRATION}/configuration/${isWarningModal}`);
                dispatch(updateAddPeopleFields({ fieldName: 'isWarningModal', value: null }));
                dispatch(updateAppIntegrationData({ fieldName: 'newLocationDrawer', value: true }));
              },
            },
          ]}
        />
      </EzyDialog>
    </div>
  );
}

export default AddPeople;
