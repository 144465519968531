import {
  Autocomplete,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import { toLower, upperFirst } from 'lodash';
import EzyInputField from '../inputField/EzyInputField';
import EzyIconTooltip from '../tooltip/EzyIconTooltip';
import { decodeHTMLEntities } from '../../../utils/stringHelper';

function EzyAutoComplete({
  value,
  label,
  onChange,
  placeholder,
  options,
  className,
  error,
  tooltip,
  loading,
  ...restProps
}) {
  return (
    <FormControl variant="standard" className={`auto-complete ${className}`}>
      {label && (
        <>
          <InputLabel shrink htmlFor="bootstrap-input">
            {label}
            {restProps?.required && <div className="required">*</div>}
            {tooltip && <EzyIconTooltip tooltip={tooltip} className="input-field-tooltip" />}
          </InputLabel>
          {restProps?.subtitle && (
            <div className="secondary-caption-text">{restProps?.subtitle}</div>
          )}
        </>
      )}
      <Autocomplete
        key="invoice"
        options={options}
        limitTags={3}
        placeholder={upperFirst(toLower(placeholder))}
        getOptionLabel={(option) =>
          option?.label ?? option?.name ?? option?.locationName ?? option ?? ''
        }
        value={value}
        onChange={(event, values, reason) => {
          if (reason === 'removeOption' && restProps?.multiple) {
            const elements =
              event.currentTarget.parentElement.getElementsByClassName('MuiChip-label');
            let element = null;
            if (elements.length > 0 && elements.length === 1) {
              element = elements?.[0]?.innerHTML;
            } else {
              element = elements?.[value.length - 1]?.innerHTML;
            }
            const index = value.indexOf(decodeHTMLEntities(element));

            onChange(
              event,
              value.filter((e, i) => index !== i)
            );
          } else {
            onChange(event, values);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            notched={false}
            className={error ? 'field-error' : ''}
            placeholder={upperFirst(toLower(placeholder))}
            renderInput={(params2) => (
              <EzyInputField
                {...params2}
                placeholder={upperFirst(toLower(placeholder))}
                ref={params2.InputProps.ref}
                inputProps={params2.inputProps}
                disabled={restProps.disabled}
              />
            )}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        loading={loading}
        {...restProps}
      />
      {error && <FormHelperText className="error-helper">{error}</FormHelperText>}
    </FormControl>
  );
}

export default EzyAutoComplete;

EzyAutoComplete.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  error: PropTypes.bool,
  options: PropTypes.array,
  tooltip: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  loading: PropTypes.bool,
};

EzyAutoComplete.defaultProps = {
  label: null,
  value: '',
  onChange: () => {},
  className: '',
  error: false,
  options: [],
  tooltip: null,
  loading: false,
};
