import { useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import EzyTab from '../../../../components/common/tab/EzyTab';
import AOGeneralSettings from './AOGeneralSettings';
import {
  cancelSubscriptionAction,
  createCheckOutSessionAction,
  createStripeCustomerAction,
  deleteBusinessByBusinessId,
  getBusinessGeneralInfoAction,
  updateBusinessName,
} from '../../redux/globalProfileActions';
import {
  resetBillingDetails,
  resetSettingsData,
  updateBillingAndPaymentDetails,
  updateBillingDetails,
  updateGeneralSettingsFields,
} from '../../redux/globalProfileSlice';
import ROUTE_CONSTANTS from '../../../../utils/constants';
import ConfirmationModal from '../../../../components/common/modal/ConfirmationModal';
import EzyNavBar from '../../../../components/common/navBar/EzyNavBar';
import IconByName from '../../../../components/common/iconByName/IconByName';
import Invoices from './Invoices';
import Plans from './Plans';
import BillingAndAccount from './BillingAndAccount';
import useQueryParams from '../../../../hooks/useQueryParams';
import validateAccountAndBill from '../../validations/validateAccountAndBill';
import { requireValidate } from '../../../../utils/validationHelper';
import { displayErrors } from '../../../../utils/errorNotifyHelper';
import Receipts from './Receipts';

const tabsAndIndex = {
  general: 0,
  billing: 1,
  plans: 2,
  invoice: 3,
  receipt: 4,
};

function AOBusinessSettings() {
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [cancelSubsctiptionModal, setCancelSubsctiptionModal] = useState(false);
  const {
    generalSettings,
    billingDetails,
    billingAndPaymentDetails,
    planDetails = [],
  } = useSelector(({ globalProfile }) => globalProfile || {});

  const {
    saveBillingAndPaymentDetailsActionLoader,
    businessNameUpdateLoader,
    createCheckOutSessionActionLoader,
    createStripeCustomerActionLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});
  const { businessName, businessId } = useMemo(() => generalSettings ?? {}, [generalSettings]);
  const { isEditBilling, currentPlan } = useMemo(() => billingDetails ?? {}, [billingDetails]);

  const {
    name,
    email,
    country,
    state,
    city,
    postCode,
    addressLine1,
    addressLine2,
    planDuration,
    numberOfUser,
    minimumUser,
  } = useMemo(() => billingAndPaymentDetails ?? {}, [billingAndPaymentDetails]);

  const history = useHistory();
  const dispatch = useDispatch();
  const { action, paramId } = useParams();
  const { planId } = useQueryParams();

  if (!Object.keys(tabsAndIndex).includes(action)) return null;

  const handleClose = () => {
    dispatch(resetSettingsData());
    setConfirmationModal(false);
    history.push(ROUTE_CONSTANTS.ACCOUNT_OVERVIEW);
  };

  const handelDeleteBusiness = () => {
    const data = { businessId };
    dispatch(
      deleteBusinessByBusinessId(data, () => {
        handleClose();
      })
    );
  };

  const cancelSubsciption = () => {
    const data = { businessId };
    dispatch(
      cancelSubscriptionAction(data, () => {
        handleClose();
      })
    );
  };

  const handelSubmit = () => {
    const data = {
      businessId,
      businessName,
    };
    const error = requireValidate('Business Name', businessName);
    dispatch(updateGeneralSettingsFields({ fieldName: 'errors', value: { businessName: error } }));
    if (!error) {
      dispatch(
        updateBusinessName(data, () => {
          handleClose();
        })
      );
    }
  };

  const validateBillingDetails = (isFromSave = false) => {
    const data = {
      businessId,
      name,
      email,
      address: {
        city,
        countryId: country?.id,
        line1: addressLine1,
        line2: addressLine2,
        postalCode: postCode,
        state,
      },
      numberOfUser,
      minimumUser,
      planDuration,
      isFromSave,
    };
    return { data, errors: validateAccountAndBill(data) };
  };
  const saveBillingAndPaymentDetails = async () => {
    const { errors, data } = validateBillingDetails(true);
    dispatch(updateBillingAndPaymentDetails({ fieldName: 'errors', value: errors }));
    if (isEmpty(errors)) {
      await dispatch(
        createStripeCustomerAction(data, () => {
          dispatch(resetBillingDetails());
        })
      );
    }
  };

  const handleCheckout = async () => {
    try {
      const { errors, data } = validateBillingDetails();
      dispatch(updateBillingAndPaymentDetails({ fieldName: 'errors', value: errors }));
      if (isEmpty(errors)) {
        await dispatch(
          createStripeCustomerAction(data, async ({ customerId }) => {
            if (customerId) {
              const sessionData = {
                customerId,
                planId,
                planDuration,
              };
              if (planDuration === 2) {
                sessionData.quantity = numberOfUser;
              }
              await dispatch(
                createCheckOutSessionAction(sessionData, (session) => {
                  if (session) window.location.href = session;
                })
              );
            }
          })
        );
      }
    } catch (e) {
      displayErrors(e);
    }
  };

  const GeneralActionButton = [
    {
      label: 'Delete business',
      onClick: () => setConfirmationModal(true),
      loading: businessNameUpdateLoader,
      color: 'error',
    },
    {
      label: 'Save',
      onClick: handelSubmit,
      loading: businessNameUpdateLoader,
    },
  ];

  let planActionButton = [
    {
      label: 'Cancel Subscription',
      onClick: () => {
        setCancelSubsctiptionModal(true);
      },
      // loading: deleteBusinessLoader,
      color: 'error',
    },
  ];

  let billActionButton = [];

  if (currentPlan && currentPlan !== 'Trial') {
    billActionButton.push(...planActionButton);
  }

  billActionButton.push({
    label: 'Edit',
    onClick: () => {
      dispatch(updateBillingDetails({ fieldName: 'isEditBilling', value: true }));
    },
    // loading: deleteBusinessLoader,
    color: 'secondary',
  });

  if (isEditBilling) {
    billActionButton = [
      {
        label: 'Save',
        onClick: saveBillingAndPaymentDetails,
        loading: saveBillingAndPaymentDetailsActionLoader,
        color: 'primary',
      },
    ];
  }

  if (planId) {
    billActionButton = [
      {
        label: 'Cancel',
        onClick: () => {
          dispatch(resetBillingDetails());
          history.push(`${ROUTE_CONSTANTS.ACCOUNT_OVERVIEW}/billing/${paramId}`);
        },
        color: 'error',
        loading: createCheckOutSessionActionLoader || createStripeCustomerActionLoader,
      },
      {
        label: 'Checkout',
        onClick: handleCheckout,
        loading: createCheckOutSessionActionLoader || createStripeCustomerActionLoader,
        color: 'primary',
      },
    ];
  }

  if (planDetails.length > 0 && planDetails?.every((e) => !e?.isCurrentPlan)) {
    planActionButton = [];
  }

  const tabs = [
    {
      label: 'General',
      component: <AOGeneralSettings />,
      actionButtons: GeneralActionButton,
      access: 'default',
      name: 'general',
    },
    {
      label: 'Billing',
      component: <BillingAndAccount />,
      actionButtons: billActionButton,
      access: 'default',
      name: 'billing',
    },
    {
      label: 'Plans',
      component: <Plans />,
      access: 'default',
      name: 'plans',
    },
    {
      label: 'Invoice',
      component: <Invoices />,
      access: 'default',
      name: 'invoice',
    },
    {
      label: 'Receipts',
      component: <Receipts />,
      access: 'default',
      name: 'receipt',
    },
  ];

  const handleTabChange = (newTab) => {
    history.push(`${ROUTE_CONSTANTS.ACCOUNT_OVERVIEW}/${newTab}/${paramId}`);
  };

  useEffect(() => {
    if (paramId) dispatch(getBusinessGeneralInfoAction(paramId));
  }, [paramId]);

  return (
    <div className="h-full w-full d-flex-column profile-setting-fullScreen-wrapper">
      <EzyNavBar isFormProfile>
        <IconByName
          name="close"
          className="pointer-cursor white-color"
          onClick={() => history.push(ROUTE_CONSTANTS.ACCOUNT_OVERVIEW)}
        />
      </EzyNavBar>

      <div className="panel-child-wrapper">
        <EzyTab
          list={tabs}
          defaultIndex={tabsAndIndex[action] || 0}
          onTabChange={handleTabChange}
        />
        <ConfirmationModal
          title={cancelSubsctiptionModal ? 'Cancel Subscription' : 'Delete Business'}
          isOpen={confirmationModal || cancelSubsctiptionModal}
          handleClose={() => {
            setConfirmationModal(false);
            setCancelSubsctiptionModal(false);
          }}
          handleSubmit={cancelSubsctiptionModal ? cancelSubsciption : handelDeleteBusiness}
          primaryLabel={cancelSubsctiptionModal ? 'Yes' : 'Delete'}
          secondaryLabel={cancelSubsctiptionModal ? 'No' : 'Cancel'}
        >
          <div>
            {cancelSubsctiptionModal
              ? `Are you sure you want to cancel subscription for ${businessName}?`
              : `Are you sure you want to delete this business?`}
          </div>
        </ConfirmationModal>
      </div>
    </div>
  );
}

export default AOBusinessSettings;
