import { isNil, omitBy } from 'lodash';
import { requireValidate } from '../../../utils/validationHelper';

const validateLeaveEntitlement = (data) => {
  const { totalBalance, businessLeaveRulesId } = data ?? {};
  const error = {};
  error.businessLeaveRulesId = requireValidate('Leave type', businessLeaveRulesId);
  error.totalBalance = requireValidate('Leave balance', totalBalance);

  return omitBy(error, isNil);
};

export default validateLeaveEntitlement;
