import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { isEmpty, isNil, isNull, omitBy } from 'lodash';
import ExportTimesheetsSidebar from '../components/ExportTimesheetsSidebar';
import EzyMenu from '../../../components/common/menu/EzyMenu';
import EzyToggleButtonGroup from '../../../components/common/toggleButton/EzyToggleButtonGroup';
import EzyAccordion from '../../../components/common/accordion/EzyAccordion';
import EzyAccordionWrapper from '../../../components/common/accordion/EzyAccordionWrapper';
import EzyAccordionSummary from '../../../components/common/accordion/EzyAccordionSummary';
import EzyAccordionDetails from '../../../components/common/accordion/EzyAccordinDetails';
import EzyCollapsibleTable from '../components/EzyCollapsibleTable';
import EzyDialog from '../../../components/common/dialog/EzyDialog';
import EzyDialogTitle from '../../../components/common/dialog/EzyDialogTitle';
import EzyDialogAction from '../../../components/common/dialog/EzyDialogAction';
import {
  setPersonWiseTimeSheetsList,
  // updateExportTimeSheetData,
  updateExportTimesheetDrawerDetails,
  updateSidePanelData,
} from '../redux/exportTimesheetSlice';
import ExportItemsDrawer from '../components/ExportItemsDrawer';
import PayRateTable from '../components/PayRateTable';
import { exportTimeSheetHeaders, exportTimeSheetPayRateHeaders } from '../exportTimesheetsHeaders';
import EditExportCodeDrawer from '../components/EditExportCodeDrawer';
import {
  exportTimeSheetSummaryAction,
  exportTimesheetDetailsByPayRatesAction,
  exportTimesheetDetailsByPersonAction,
  getExportTimeSheetDetailsAction,
  markTimesheetAsPaidOrUnpaidAction,
} from '../redux/exportTimesheetsActions';
import EzyButton from '../../../components/common/button/EzyButton';
import ConfirmationModal from '../../../components/common/modal/ConfirmationModal';
import ROUTE_CONSTANTS from '../../../utils/constants';
import EzyAutoComplete from '../../../components/common/autoComplete/EzyAutoComplete';
import EzySwitch from '../../../components/common/switch/EzySwitch';
import { requireValidate } from '../../../utils/validationHelper';
import DateDurationPicker from '../../timeSheets/components/DateDurationPicker';

function ExportTimeSheetsList() {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);

  const viewTypeList = ['Group by person', 'Group by pay rate'];
  const [viewType, setViewType] = useState(viewTypeList[0]);

  const [expandSummaryAccordion, setExpandSummaryAccordion] = useState(true);
  const [openModal, setOpenModal] = useState(null);
  const [openPrintModal, setOpenPrintModal] = useState(null);
  const [printOptions, setPrintOptions] = useState({
    format: null,
    inSeparatePage: false,
  });
  const [errors, setErrors] = useState({});

  const [tableView, setTableView] = useState('Expand all');

  const {
    sidePanelData,
    listOfPersonWiseTimeSheets: docs,
    selectedExportItemsSummary,
    payRateSummaryList,
  } = useSelector(({ exportTimesheet }) => exportTimesheet ?? {});
  const [selectedTimeSheets, setSelectedTimeSheets] = useState([]);

  const { selectedLocation, weekDatesValue, selectedPersons, personsList, filterDuration } =
    useMemo(() => sidePanelData, [sidePanelData]);

  const { exportTimesheetDetailsByPersonActionLoader, markTimesheetAsPaidOrUnpaidActionLoader } =
    useSelector(({ common }) => common.generalLoader ?? {});

  const bulkActions = [
    {
      id: 1,
      label: 'Mark selected items as paid',
      onClick: () => {
        setOpenModal('paidModal');
      },
    },
    {
      id: 2,
      label: 'Export selected items',
      onClick: () => {
        dispatch(updateExportTimesheetDrawerDetails({ fieldName: 'isOpen', value: true }));
      },
    },
    {
      id: 3,
      label: 'Mark selected items as unpaid',
      onClick: () => {
        setOpenModal('unpaidModal');
      },
    },
    {
      id: 4,
      label: 'Print selected items',
      onClick: (e) => setOpenPrintModal(e),
    },
  ];

  const getExportTimeSheetDetailsByFilter = (params) => {
    dispatch(
      getExportTimeSheetDetailsAction({
        locationId: selectedLocation?.id,
        startDate: moment(weekDatesValue?.startDate)?.toISOString(),
        endDate: moment(weekDatesValue?.endDate)?.toISOString(),
        ...params,
      })
    );
  };

  const handleTimeSheetSelect = (value) => {
    setSelected(value);
    switch (value) {
      case 'Select all':
        setSelectedTimeSheets(
          docs?.reduce(
            (prev, curr) => [...prev, ...curr.timeSheetsForExport.map((e) => e?.timeSheetId)],
            []
          )
        );
        break;
      case 'Invert selected':
        setSelectedTimeSheets(
          docs
            ?.reduce(
              (prev, curr) => [...prev, ...curr.timeSheetsForExport.map((e) => e?.timeSheetId)],
              []
            )
            ?.filter((id) => !selectedTimeSheets?.includes(id))
        );
        break;
      default:
        setSelectedTimeSheets([]);
    }
  };

  const handleTimesheetPaidOrUnpaid = (isPaid) => {
    dispatch(
      markTimesheetAsPaidOrUnpaidAction({ timeSheetIds: selectedTimeSheets, isPaid }, () => {
        setOpenModal(null);
        if (selectedLocation?.id && selectedPersons)
          dispatch(
            exportTimesheetDetailsByPersonAction(
              {
                personIds: selectedPersons?.map((e) => e?.personDetailId),
                locationId: selectedLocation?.id,
                startDate: moment(weekDatesValue?.startDate)?.toISOString(),
                endDate: moment(weekDatesValue?.endDate)?.toISOString(),
                previousStartDate: moment(weekDatesValue?.startDate)
                  .subtract(7, 'days')
                  ?.toISOString(),
                previousEndDate: moment(weekDatesValue?.endDate).subtract(7, 'days')?.toISOString(),
              },
              (data) => {
                const timesheetList = data?.reduce(
                  (prev, e) => [...prev, ...e.timeSheetsForExport.map((x) => x?.timeSheetId)],
                  []
                );
                setSelectedTimeSheets(timesheetList);
              }
            )
          );
      })
    );
  };

  const handlePrintAction = () => {
    let error = {};
    error.printFormat = requireValidate('Print format', printOptions?.format);

    error = omitBy(error, isNil);

    setErrors(error);

    if (isEmpty(error)) {
      setErrors({});
      const newWindow = window.open(ROUTE_CONSTANTS.PRINT_EXPORT_TIMESHEETS, '_blank');
      newWindow.printData = {
        docs,
        selectedTimeSheets,
        selectedLocation,
        weekDatesValue,
        selectedPersons,
        printOptions,
      };
    }
  };

  useEffect(() => {
    if (selectedLocation?.id) {
      const params = {
        previousWeekStartDate: moment(weekDatesValue?.startDate).subtract(7, 'days')?.toISOString(),
        previousWeekEndDate: moment(weekDatesValue?.endDate).subtract(7, 'days')?.toISOString(),
        timesheetIds: selectedTimeSheets,
        locationId: selectedLocation?.id,
      };

      dispatch(exportTimeSheetSummaryAction(params));
    }
    if (selectedTimeSheets?.length === 0) {
      setSelected('Select none');
    } else if (
      selectedTimeSheets?.length ===
      docs?.reduce(
        (prev, curr) => [...prev, ...curr.timeSheetsForExport.map((e) => e?.timeSheetId)],
        []
      )?.length
    ) {
      setSelected('Select all');
    } else {
      setSelected();
    }
  }, [selectedLocation, weekDatesValue, selectedTimeSheets]);

  useEffect(() => {
    if (selectedLocation?.id)
      dispatch(
        exportTimesheetDetailsByPersonAction(
          {
            personIds: selectedPersons?.map((e) => e?.personDetailId),
            locationId: selectedLocation?.id,
            startDate: moment(weekDatesValue?.startDate)?.toISOString(),
            endDate: moment(weekDatesValue?.endDate)?.toISOString(),
            previousStartDate: moment(weekDatesValue?.startDate).subtract(7, 'days')?.toISOString(),
            previousEndDate: moment(weekDatesValue?.endDate).subtract(7, 'days')?.toISOString(),
          },
          (data) => {
            const timesheetList = data?.reduce(
              (prev, e) => [...prev, ...e.timeSheetsForExport.map((x) => x?.timeSheetId)],
              []
            );
            setSelectedTimeSheets(timesheetList);
          }
        )
      );
  }, [selectedPersons, selectedLocation, weekDatesValue]);

  useEffect(() => {
    dispatch(exportTimesheetDetailsByPayRatesAction({ timeSheetIds: selectedTimeSheets }));
  }, [selectedTimeSheets?.length]);

  return (
    <div className="export-timesheets-root-container">
      <ExportTimesheetsSidebar />
      <div className="export-timesheets-list-wrapper">
        <div className="export-timesheets-list-header">
          <DateDurationPicker
            value={{ ...weekDatesValue, filterDuration }}
            timeZone={selectedLocation?.timeZoneId?.label}
            onChange={(event) => {
              if (event?.filterDuration !== filterDuration) {
                dispatch(
                  updateSidePanelData({
                    fieldName: 'filterDuration',
                    value: event?.filterDuration,
                  })
                );
              }
              dispatch(
                updateSidePanelData({
                  fieldName: 'weekDatesValue',
                  value: {
                    startDate: moment.tz(
                      event.startDate.toISOString(),
                      selectedLocation?.timeZoneId?.label
                    ),
                    endDate: moment.tz(
                      event.endDate.toISOString(),
                      selectedLocation?.timeZoneId?.label
                    ),
                  },
                })
              );
              dispatch(setPersonWiseTimeSheetsList([]));
              setSelectedTimeSheets([]);
              dispatch(
                updateSidePanelData({
                  fieldName: 'selectedPersons',
                  value: [],
                })
              );

              getExportTimeSheetDetailsByFilter({
                startDate: moment
                  .tz(event.startDate.toISOString(), selectedLocation?.timeZoneId?.label)
                  ?.toISOString(),
                endDate: moment
                  .tz(event.endDate.toISOString(), selectedLocation?.timeZoneId?.label)
                  ?.toISOString(),
              });
            }}
          />
          <div>
            <EzyToggleButtonGroup
              list={viewTypeList}
              selected="Group by person"
              selectedToggleButton="new"
              name="viewType"
              handleChange={(e) => {
                setViewType(e?.target?.textContent);
              }}
              value={viewType}
              color="primary"
              // isIconLabel
            />
            <EzyMenu
              iconName="more_vert"
              menuItems={bulkActions}
              color="primary"
              disabled={selectedTimeSheets?.length === 0}
            />
          </div>
        </div>

        <EzyAccordionWrapper className="export-timesheet-detail-accordion">
          <EzyAccordion
            expanded={expandSummaryAccordion}
            onChange={() => setExpandSummaryAccordion((prev) => !prev)}
          >
            <EzyAccordionSummary className="accordion-summary icon-small">
              Selected item summary
            </EzyAccordionSummary>
            <EzyAccordionDetails>
              <div className="accordion-details">
                <div>
                  <div>
                    {selectedPersons?.length} / {personsList?.length} Team member selected
                  </div>
                  <div>
                    {selectedTimeSheets?.length} /{' '}
                    {personsList?.reduce((prev, curr) => prev + curr.timeSheetCount, 0)} Item
                    Selected
                  </div>
                </div>
                <div className="summary">
                  <div>
                    <div>Currently selected</div>
                    <div className="text-secondary-color">Previous</div>
                  </div>
                  <div>
                    <div>
                      {selectedExportItemsSummary?.currentWorkingHour !== null
                        ? selectedExportItemsSummary?.currentWorkingHour
                        : '-'}
                    </div>
                    <div className="text-secondary-color">
                      {selectedExportItemsSummary?.previousWorkingHour !== null
                        ? selectedExportItemsSummary?.previousWorkingHour
                        : '-'}
                    </div>
                  </div>
                  <div>
                    <div>{selectedExportItemsSummary?.workingHourBreakdown || '-'}</div>
                  </div>
                  <div>
                    <div>{selectedExportItemsSummary?.currentCost || '-'}</div>
                    <div className="text-secondary-color">
                      {selectedExportItemsSummary?.previousCost || '-'}
                    </div>
                  </div>
                  <div>
                    <div>{selectedExportItemsSummary?.costBreakdown || '-'}</div>
                  </div>
                </div>
              </div>
            </EzyAccordionDetails>
          </EzyAccordion>
        </EzyAccordionWrapper>

        {viewType === 'Group by person' && (
          <div className="filter-action-button">
            <div className="no-space-btn">
              <EzyButton
                label="Select all"
                title="Week"
                onClick={() => {
                  handleTimeSheetSelect('Select all');
                }}
                variant={selected === 'Select all' ? 'contained' : 'outlined'}
              />
              <EzyButton
                label="Select none"
                title="Day"
                onClick={() => {
                  handleTimeSheetSelect('Select none');
                }}
                variant={selected === 'Select none' ? 'contained' : 'outlined'}
              />
              <EzyButton
                label="Invert selected"
                title="List"
                onClick={() => {
                  handleTimeSheetSelect('Invert selected');
                }}
                variant={selected === 'Invert selected' ? 'contained' : 'outlined'}
                disabled={
                  selectedTimeSheets?.length === 0 ||
                  selectedTimeSheets?.length ===
                    docs?.reduce(
                      (prev, curr) => [
                        ...prev,
                        ...curr.timeSheetsForExport.map((e) => e?.timeSheetId),
                      ],
                      []
                    )?.length
                }
              />
            </div>

            <div className="no-space-btn">
              <EzyButton
                label="Expand all"
                title="Week"
                onClick={() => {
                  setTableView('Expand all');
                }}
                variant={tableView === 'Expand all' ? 'contained' : 'outlined'}
              />

              <EzyButton
                label="Detail all"
                title="Day"
                onClick={() => {
                  setTableView('Detail all');
                }}
                variant={tableView === 'Detail all' ? 'contained' : 'outlined'}
              />
              <EzyButton
                label="Collapse all"
                title="List"
                onClick={() => {
                  setTableView('Collapse all');
                }}
                variant={tableView === 'Collapse all' ? 'contained' : 'outlined'}
              />
            </div>
          </div>
        )}

        {viewType === 'Group by person' ? (
          <EzyCollapsibleTable
            headers={exportTimeSheetHeaders}
            rows={docs}
            selected={selectedTimeSheets}
            setSelected={setSelectedTimeSheets}
            tableView={tableView}
            setTableView={setTableView}
            loading={exportTimesheetDetailsByPersonActionLoader}
          />
        ) : (
          <PayRateTable
            headers={exportTimeSheetPayRateHeaders}
            rows={payRateSummaryList}
            actions={
              [
                // {
                //   id: 1,
                //   label: 'Edit',
                //   onClick: (e) => {
                //     dispatch(
                //       updateExportTimeSheetData({
                //         fieldName: 'isOpenEditExportCodeDrawer',
                //         value: e?.id,
                //       })
                //     );
                //   },
                // },
              ]
            }
          />
        )}
      </div>

      <EzyDialog
        className="common-dialog-with-body delete-confirmation-modal"
        open={openModal === 'paidModal'}
      >
        <EzyDialogTitle
          title="Mark Selected Items as Paid"
          onClose={() => {
            setOpenModal(false);
          }}
        />
        <div className="dialog-body title-small">
          <div className="info-div primary-bg word-break">
            Marking as paid will lock the timesheets from being edited. Are you sure you want to
            mark the selected items as paid?
          </div>
        </div>
        <EzyDialogAction
          actionButtons={[
            {
              label: 'Okay',
              onClick: () => {
                handleTimesheetPaidOrUnpaid(true);
              },
              loading: markTimesheetAsPaidOrUnpaidActionLoader,
            },
          ]}
        />
      </EzyDialog>

      <ConfirmationModal
        title="Mark Selected Items as Not Paid"
        isOpen={openModal === 'unpaidModal'}
        handleClose={() => {
          setOpenModal(null);
        }}
        handleSubmit={async () => {
          handleTimesheetPaidOrUnpaid(false);
        }}
        primaryLabel="Okay"
        loading={markTimesheetAsPaidOrUnpaidActionLoader}
        // primaryColor="primary"
      >
        <div>Are you sure you want to mark the selected items as not paid?</div>
      </ConfirmationModal>
      <ExportItemsDrawer selectedTimeSheets={selectedTimeSheets} />
      <EditExportCodeDrawer />
      <ConfirmationModal
        title="Print Selected Items"
        isOpen={!isEmpty(openPrintModal) || !isNull(openPrintModal)}
        handleClose={() => {
          setOpenPrintModal(null);
        }}
        handleSubmit={() => {
          handlePrintAction();
        }}
        primaryLabel="Print"
        primaryColor="primary"
      >
        <div className="d-flex-column gap-10">
          <EzyAutoComplete
            label="Please select the print format"
            placeholder="Select print format"
            options={['List of time sheets', 'Full summary with pay breakdown']}
            value={printOptions?.format}
            onChange={(_, value) =>
              setPrintOptions((e) => {
                return { ...e, format: value };
              })
            }
            required
            error={errors?.printFormat}
          />
          <EzySwitch
            label="Print each employee in separate page"
            checked={printOptions?.inSeparatePage}
            onChange={(e) =>
              setPrintOptions((x) => {
                return { ...x, inSeparatePage: e?.target?.checked };
              })
            }
          />
        </div>
      </ConfirmationModal>
    </div>
  );
}

export default ExportTimeSheetsList;
