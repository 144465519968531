import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';
import EzyIconTooltip from '../tooltip/EzyIconTooltip';

function EzySwitch({ checked, handleChange, label, subLabel, className, tooltip, ...restProps }) {
  return (
    <div className="switch-container">
      <Switch
        checked={checked}
        onChange={handleChange}
        className={`ezySwitch ${className}`}
        {...restProps}
      />
      {(label || subLabel) && (
        <div className="ezy-labels">
          {label && (
            <span className="ezy-label">
              {label}
              {tooltip && <EzyIconTooltip tooltip={tooltip} className="input-field-tooltip" />}
            </span>
          )}
          {subLabel && <span className="ezy-subLabel">{subLabel}</span>}
        </div>
      )}
    </div>
  );
}

export default EzySwitch;

EzySwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
  subLabel: PropTypes.string,
  tooltip: PropTypes.string,
};

EzySwitch.defaultProps = {
  handleChange: () => {},
  label: '',
  className: '',
  subLabel: '',
  tooltip: null,
};
