import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as React from 'react';
import EzyAutoComplete from '../../../components/common/autoComplete/EzyAutoComplete';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import EzySwitch from '../../../components/common/switch/EzySwitch';
import { resetEditDetailsLocationData, setEditDetailsLocationData } from '../redux/locationSlice';
import { breakDurationList, paidMealBreaksList } from '../../../utils/constants';
import EzyAccordionWrapper from '../../../components/common/accordion/EzyAccordionWrapper';
import EzyAccordion from '../../../components/common/accordion/EzyAccordion';
import EzyAccordionSummary from '../../../components/common/accordion/EzyAccordionSummary';
import EzyAccordionDetails from '../../../components/common/accordion/EzyAccordinDetails';
import { getLocationDetailsByID } from '../redux/locationActions';
import Loader from '../../../components/common/loader/Loader';
import EzyWarningModal from '../../../components/common/warningModal/EzyWarningModal';
import { updateUserData } from '../../auth/redux/userSlice';

function EditLocationDetails() {
  const dispatch = useDispatch();
  const { locationId } = useParams();
  const editDetailsLocationData = useSelector(({ location }) => location.editDetailsLocationData);
  const optionList = useSelector(({ common }) => common.enums);
  const { coWorkerScheduleVisibility } = useMemo(() => optionList, [optionList]);
  const [warningModal, setWarningModal] = useState(false);
  const [swapShift, setSwapShift] = useState(true);
  const [managerApprovalForShiftSwaps, setManagerApprovalForShiftSwaps] = useState(true);
  const { getLocationDetailsByIDLoader } = useSelector(({ common }) => common.generalLoader ?? {});

  const { primaryLocationId } = useSelector(({ user }) => user?.profileData ?? {});

  const {
    basics,
    creatingAndPublishingShifts,
    swapsAndOffers,
    clockInAndOut,
    teamMemberPermissions,
    timesheetApproving,
    UnscheduledShifts,
    breaks,
    errors,
  } = useMemo(() => editDetailsLocationData, [editDetailsLocationData]);

  const handleFieldChange = (sectionName, fieldName, fieldValue) => {
    dispatch(setEditDetailsLocationData({ sectionName, fieldName, fieldValue }));
    if (sectionName === 'swapsAndOffers' && fieldName === 'allowSwapShifts') {
      dispatch(
        setEditDetailsLocationData({
          sectionName: 'swapsAndOffers',
          fieldName: 'isManagerApprovalRequiredToSwap',
          fieldValue,
        })
      );
      if (fieldValue === true) {
        setManagerApprovalForShiftSwaps(true);
      } else {
        setManagerApprovalForShiftSwaps(false);
      }
    }
  };

  const [expanded, setExpanded] = useState('basics');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    dispatch(getLocationDetailsByID({ locationId }));
    return () => {
      dispatch(resetEditDetailsLocationData());
    };
  }, []);

  const handelCloseWarningModal = () => {
    setWarningModal(false);
  };

  const handelCoWorkerScheduleVisibility = (value) => {
    handleFieldChange('swapsAndOffers', 'coWorkerScheduleVisibility', value);
    if (value === coWorkerScheduleVisibility[0] || value === coWorkerScheduleVisibility[2]) {
      setWarningModal(true);
      setSwapShift(false);
      setManagerApprovalForShiftSwaps(false);
      handleFieldChange('swapsAndOffers', 'allowSwapShifts', false);
      handleFieldChange('swapsAndOffers', 'isManagerApprovalRequiredToSwap', false);
    } else {
      setSwapShift(true);
      setManagerApprovalForShiftSwaps(true);
    }
  };

  return (
    <EzyAccordionWrapper className="accordion-wrapper">
      {getLocationDetailsByIDLoader ? (
        <Loader />
      ) : (
        <>
          <EzyAccordion expanded={expanded === 'basics'} onChange={handleChange('basics')}>
            <EzyAccordionSummary className="accordion-summary">Basics</EzyAccordionSummary>
            <EzyAccordionDetails className="accordion-details">
              <div className="accordion-details-grid">
                <EzyAutoComplete
                  tooltip="This determines the start day of the schedule."
                  name="weekStartOn"
                  placeholder="Select day"
                  label="Week starts on"
                  options={optionList?.weekDays}
                  value={basics?.weekStartsOn}
                  onChange={(e, OnChangeValue) => {
                    handleFieldChange('basics', 'weekStartsOn', OnChangeValue);
                    if (primaryLocationId === locationId) {
                      dispatch(
                        updateUserData({ fieldName: 'weekStartsOn', value: OnChangeValue?.id })
                      );
                    }
                  }}
                  error={errors?.weekStartsOn}
                  required
                />
                <EzyInputField
                  tooltip="Default shift length when creating shifts."
                  name="defaultShiftDuration"
                  placeholder="Enter shift duration in hours"
                  label="Default shift duration"
                  type="number"
                  value={basics?.defaultShiftDuration}
                  onChange={(e) =>
                    handleFieldChange('basics', 'defaultShiftDuration', e.target.value)
                  }
                  error={errors?.defaultShiftDuration}
                  required
                />
                <EzyAutoComplete
                  tooltip="Default break length when creating shifts."
                  name="breakDurationList"
                  placeholder="Enter break duration in minutes"
                  label="Default break duration"
                  options={breakDurationList}
                  value={basics?.defaultBreakDuration}
                  onChange={(e, value) =>
                    handleFieldChange('basics', 'defaultBreakDuration', value)
                  }
                  error={errors?.defaultBreakDuration}
                  required
                />
              </div>
            </EzyAccordionDetails>
          </EzyAccordion>
          <EzyAccordion
            expanded={expanded === 'creatingAndPublishingShifts'}
            onChange={handleChange('creatingAndPublishingShifts')}
          >
            <EzyAccordionSummary className="accordion-summary">
              Creating and publishing shifts
            </EzyAccordionSummary>
            <EzyAccordionDetails className="accordion-details">
              <div className="accordion-details-grid">
                <EzyAutoComplete
                  tooltip="Shifts will lock after a set time period."
                  name="preventChangesToShifts"
                  placeholder="Select shifts"
                  label="Prevent changes to shifts"
                  options={optionList?.preventShiftChanges}
                  value={creatingAndPublishingShifts?.preventShiftChange}
                  onChange={(e, value) =>
                    handleFieldChange('creatingAndPublishingShifts', 'preventShiftChange', value)
                  }
                  error={errors?.preventShiftChange}
                  required
                />
                <EzyAutoComplete
                  tooltip="Shift notifications will be sent for late team members."
                  name="shiftNotificationsRecipient"
                  placeholder="Select notifications recipient"
                  label="Late arrival notification"
                  options={optionList?.shiftNotificationRecipient}
                  value={creatingAndPublishingShifts?.shiftNotificationRecipient}
                  onChange={(e, value) =>
                    handleFieldChange(
                      'creatingAndPublishingShifts',
                      'shiftNotificationRecipient',
                      value
                    )
                  }
                  error={errors?.shiftNotificationRecipient}
                  required
                />
                <EzyAutoComplete
                  tooltip="Notify team members when they are removed from a published shift."
                  name="sendNotificationToTeamMemberWhenShiftsAreRemoved"
                  placeholder="Select notification"
                  label="Send notification to team member when shifts are removed"
                  options={optionList?.shiftRemovedNotification}
                  value={creatingAndPublishingShifts?.shiftRemovedNotification}
                  onChange={(e, value) =>
                    handleFieldChange(
                      'creatingAndPublishingShifts',
                      'shiftRemovedNotification',
                      value
                    )
                  }
                  error={errors?.shiftRemovedNotification}
                  required
                />
              </div>
            </EzyAccordionDetails>
          </EzyAccordion>
          <EzyAccordion
            expanded={expanded === 'swapsAndOffers'}
            onChange={handleChange('swapsAndOffers')}
          >
            <EzyAccordionSummary className="accordion-summary">
              Swaps and offers
            </EzyAccordionSummary>
            <EzyAccordionDetails className="accordion-details">
              <div className="accordion-details-grid">
                <EzyAutoComplete
                  tooltip={
                    " If team members can view each other's schedule, you can enable shift swaps between team members."
                  }
                  name="coWorkerScheduleVisibility"
                  placeholder="Select co-worker schedule visibility"
                  label="Co-worker schedule visibility"
                  options={optionList?.coWorkerScheduleVisibility}
                  value={swapsAndOffers?.coWorkerScheduleVisibility}
                  onChange={(e, value) => handelCoWorkerScheduleVisibility(value)}
                  error={errors?.coWorkerScheduleVisibility}
                  required
                />
                <div className="accordion-Details-switch-wrapper">
                  <div className="accordion-Details-switch-container">
                    <EzySwitch
                      name="swapShifts"
                      checked={swapsAndOffers?.allowSwapShifts}
                      disabled={!swapShift}
                      handleChange={() =>
                        handleFieldChange(
                          'swapsAndOffers',
                          'allowSwapShifts',
                          !swapsAndOffers?.allowSwapShifts
                        )
                      }
                    />
                    <div className="accordion-Details-switch-label">Swap shifts</div>
                  </div>
                  <div className="accordion-Details-switch-description">
                    Team members can swap shifts with each other.
                  </div>
                </div>
                <div className="accordion-Details-switch-wrapper">
                  <div className="accordion-Details-switch-container">
                    <EzySwitch
                      name="managerApprovalForShiftSwaps"
                      checked={swapsAndOffers?.isManagerApprovalRequiredToSwap}
                      disabled={!managerApprovalForShiftSwaps}
                      handleChange={() =>
                        handleFieldChange(
                          'swapsAndOffers',
                          'isManagerApprovalRequiredToSwap',
                          !swapsAndOffers?.isManagerApprovalRequiredToSwap
                        )
                      }
                    />
                    <div className="accordion-Details-switch-label">
                      Manager approval for shift swaps
                    </div>
                  </div>
                  <div className="accordion-Details-switch-description">
                    Option for manager to approve shift swaps.
                  </div>
                </div>
                <div className="accordion-Details-switch-wrapper">
                  <div className="accordion-Details-switch-container">
                    <EzySwitch
                      name="offerShifts"
                      checked={swapsAndOffers?.allowOfferShifts}
                      handleChange={() =>
                        handleFieldChange(
                          'swapsAndOffers',
                          'allowOfferShifts',
                          !swapsAndOffers?.allowOfferShifts
                        )
                      }
                    />
                    <div className="accordion-Details-switch-label">Offer shifts</div>
                  </div>
                  <div className="accordion-Details-switch-description">
                    Team members can offer their shift to co-workers that are qualified and
                    available. Manager approval is not required but will be notified when the shift
                    has been accepted.
                  </div>
                </div>
              </div>
            </EzyAccordionDetails>
          </EzyAccordion>
          <EzyAccordion
            expanded={expanded === 'clockInAndOut'}
            onChange={handleChange('clockInAndOut')}
          >
            <EzyAccordionSummary className="accordion-summary">
              Clock in and out
            </EzyAccordionSummary>
            <EzyAccordionDetails className="accordion-details">
              <div className="accordion-details-grid">
                <div className="accordion-Details-switch-wrapper">
                  <div className="accordion-Details-switch-container">
                    <EzySwitch
                      name="clockInAndOutViaWebBrowser"
                      checked={clockInAndOut?.isClockInOutViaWeb}
                      handleChange={() =>
                        handleFieldChange(
                          'clockInAndOut',
                          'isClockInOutViaWeb',
                          !clockInAndOut?.isClockInOutViaWeb
                        )
                      }
                    />
                    <div className="accordion-Details-switch-label">
                      Clock in and out via web browser
                    </div>
                  </div>
                </div>
                <div className="accordion-Details-switch-wrapper">
                  <div className="accordion-Details-switch-container">
                    <EzySwitch
                      name="clockInAndOut"
                      checked={clockInAndOut?.isClockInOutViaMobile}
                      handleChange={() =>
                        handleFieldChange(
                          'clockInAndOut',
                          'isClockInOutViaMobile',
                          !clockInAndOut?.isClockInOutViaMobile
                        )
                      }
                    />
                    <div className="accordion-Details-switch-label">
                      Clock in and out via mobile apps
                    </div>
                  </div>
                </div>
                {/* <div className="accordion-Details-switch-wrapper"> */}
                {/*  <div className="accordion-Details-switch-container"> */}
                {/*    <EzySwitch */}
                {/*      name="blockClockingFromOutsideASetDistance" */}
                {/*      checked={clockInAndOut?.allowGeoFencing} */}
                {/*      handleChange={() => */}
                {/*        handleFieldChange( */}
                {/*          'clockInAndOut', */}
                {/*          'allowGeoFencing', */}
                {/*          !clockInAndOut?.allowGeoFencing */}
                {/*        ) */}
                {/*      } */}
                {/*    /> */}
                {/*    <div className="accordion-Details-switch-label"> */}
                {/*      Block clocking from outside a set distance */}
                {/*    </div> */}
                {/*  </div> */}
                {/*  <div className="accordion-Details-switch-description"> */}
                {/*    GPS tracking is used to estimate where team members are clocking in/out from. */}
                {/*  </div> */}
                {/* </div> */}
                <div className="accordion-Details-switch-wrapper">
                  <div className="accordion-Details-switch-container">
                    <EzySwitch
                      name="requireAPhotoWhenStartingAndEndingShiftsInKiosk"
                      checked={clockInAndOut?.isPhotoRequiredInKiosk}
                      handleChange={() =>
                        handleFieldChange(
                          'clockInAndOut',
                          'isPhotoRequiredInKiosk',
                          !clockInAndOut?.isPhotoRequiredInKiosk
                        )
                      }
                    />
                    <div className="accordion-Details-switch-label">
                      Require a photo when starting and ending shifts in Kiosk
                    </div>
                  </div>
                  <div className="accordion-Details-switch-description">
                    Team members must take a photo in the Kiosk when starting and ending their
                    shifts.
                  </div>
                </div>
                <EzyAutoComplete
                  tooltip="Timesheets shorter than the minimum length will be deleted."
                  name="minimumTimesheetLength"
                  placeholder="Select minimum timesheet length"
                  label="Minimum timesheet length (minutes)"
                  options={optionList?.minimumTimeSheetLengthInMinutes}
                  value={clockInAndOut?.minimumTimeSheetLengthInMinutes}
                  onChange={(e, value) =>
                    handleFieldChange('clockInAndOut', 'minimumTimeSheetLengthInMinutes', value)
                  }
                  error={errors?.minimumTimeSheetLengthInMinutes}
                  required
                />
              </div>
            </EzyAccordionDetails>
          </EzyAccordion>
          <EzyAccordion
            expanded={expanded === 'teamMemberPermissions'}
            onChange={handleChange('teamMemberPermissions')}
          >
            <EzyAccordionSummary className="accordion-summary">
              Team member permissions
            </EzyAccordionSummary>
            <EzyAccordionDetails className="accordion-details">
              <div className="accordion-details-grid">
                <div className="accordion-Details-switch-wrapper">
                  <div className="accordion-Details-switch-container">
                    <EzySwitch
                      name="addAndEditTimeSheets"
                      checked={teamMemberPermissions?.isTeamMemberAllowedToEditTimeSheet}
                      handleChange={() =>
                        handleFieldChange(
                          'teamMemberPermissions',
                          'isTeamMemberAllowedToEditTimeSheet',
                          !teamMemberPermissions?.isTeamMemberAllowedToEditTimeSheet
                        )
                      }
                    />
                    <div className="accordion-Details-switch-label">Add and edit timesheets</div>
                  </div>
                  <div className="accordion-Details-switch-description">
                    Team members can create or amend existing timesheets via the web browser or
                    mobile app.
                  </div>
                </div>
                <div className="accordion-Details-switch-wrapper">
                  <div className="accordion-Details-switch-container">
                    <EzySwitch
                      name="modifyTimesheetsDuringShifts"
                      checked={teamMemberPermissions?.editTimeSheetDuringShifts}
                      handleChange={() =>
                        handleFieldChange(
                          'teamMemberPermissions',
                          'editTimeSheetDuringShifts',
                          !teamMemberPermissions?.editTimeSheetDuringShifts
                        )
                      }
                    />
                    <div className="accordion-Details-switch-label">
                      Modify timesheets during shifts
                    </div>
                  </div>
                  <div className="accordion-Details-switch-description">
                    Team members can modify their timesheets before their shift ends.
                  </div>
                </div>
                <div className="accordion-Details-switch-wrapper">
                  <div className="accordion-Details-switch-container">
                    <EzySwitch
                      name="startShiftsAnytimeBeforeScheduledTime"
                      checked={teamMemberPermissions?.allowToStartShiftBeforeScheduledTime}
                      handleChange={() => {
                        handleFieldChange(
                          'teamMemberPermissions',
                          'allowToStartShiftBeforeScheduledTime',
                          !teamMemberPermissions?.allowToStartShiftBeforeScheduledTime
                        );
                        handleFieldChange('teamMemberPermissions', 'earlyStartShiftBufferTime', 0);
                      }}
                    />
                    <div className="accordion-Details-switch-label">
                      Start shifts anytime before scheduled time
                    </div>
                  </div>
                  <div className="accordion-Details-switch-description">
                    If &apos;on&apos;&sbquo; team members can start at anytime earlier than their
                    scheduled start time.
                  </div>
                </div>
                <EzyInputField
                  tooltip="The number of minutes team members can start before their scheduled shift time."
                  name="howEarlyTeamMembersCanStartShifts"
                  placeholder="Select team members can start shifts"
                  label="How early team members can start shifts (minutes)"
                  value={teamMemberPermissions?.earlyStartShiftBufferTime}
                  disabled={teamMemberPermissions?.allowToStartShiftBeforeScheduledTime}
                  type="number"
                  onChange={(e) =>
                    handleFieldChange(
                      'teamMemberPermissions',
                      'earlyStartShiftBufferTime',
                      e.target.value
                    )
                  }
                  error={errors?.earlyStartShiftBufferTime}
                />
                <EzyInputField
                  tooltip="The number of minutes team members can end earlier than their scheduled shift time."
                  name="endingShiftsEarlierThanScheduled"
                  placeholder="Enter shifts earlier than scheduled"
                  label="Ending shifts earlier than scheduled (minutes)"
                  value={teamMemberPermissions?.earlyEndShiftBufferTime}
                  type="number"
                  onChange={(e) =>
                    handleFieldChange(
                      'teamMemberPermissions',
                      'earlyEndShiftBufferTime',
                      e.target.value
                    )
                  }
                  error={errors?.earlyEndShiftBufferTime}
                  required
                />
                <div className="accordion-Details-switch-wrapper">
                  <div className="accordion-Details-switch-container">
                    <EzySwitch
                      name="endBreaksEarlier"
                      checked={teamMemberPermissions?.allowToEndBreaksEarlier}
                      handleChange={() =>
                        handleFieldChange(
                          'teamMemberPermissions',
                          'allowToEndBreaksEarlier',
                          !teamMemberPermissions?.allowToEndBreaksEarlier
                        )
                      }
                    />
                    <div className="accordion-Details-switch-label">End breaks earlier</div>
                  </div>
                  <div className="accordion-Details-switch-description">
                    Team members can end their breaks earlier than the scheduled duration or end
                    time.
                  </div>
                </div>
              </div>
            </EzyAccordionDetails>
          </EzyAccordion>
          <EzyAccordion
            expanded={expanded === 'timesheetApproving'}
            onChange={handleChange('timesheetApproving')}
          >
            <EzyAccordionSummary className="accordion-summary">
              Timesheet approving
            </EzyAccordionSummary>
            <EzyAccordionDetails className="accordion-details">
              <div className="accordion-details-grid">
                <EzyAutoComplete
                  tooltip={
                    "Timesheets can automatically be time-approved when it matches the scheduled time or if it's within the selected time variance."
                  }
                  name="autoTimeApproveTimesheets"
                  placeholder="Enter shifts earlier than scheduled"
                  label="Auto time-approve timesheets"
                  options={optionList?.autoApproveTimeSheetVariance}
                  value={timesheetApproving?.autoApproveTimeSheetVariance}
                  onChange={(e, value) =>
                    handleFieldChange('timesheetApproving', 'autoApproveTimeSheetVariance', value)
                  }
                  error={errors?.autoApproveTimeSheetVariance}
                  required
                />
                <div className="accordion-Details-switch-wrapper">
                  <div className="accordion-Details-switch-container">
                    <EzySwitch
                      name="automaticallyRoundTimesheets"
                      checked={timesheetApproving?.roundTimeSheets}
                      handleChange={() =>
                        handleFieldChange(
                          'timesheetApproving',
                          'roundTimeSheets',
                          !timesheetApproving?.roundTimeSheets
                        )
                      }
                    />
                    <div className="accordion-Details-switch-label">
                      Automatically round timesheets
                    </div>
                  </div>
                  <div className="accordion-Details-switch-description">
                    Timesheets can be auto-rounded based on the below settings.
                  </div>
                </div>
                <div className="accordion-Details-switch-wrapper">
                  <div className="accordion-Details-switch-container">
                    <EzySwitch
                      name="roundToScheduledStartTimeIfEarlier"
                      checked={timesheetApproving?.isRoundToScheduledStartTime}
                      handleChange={() =>
                        handleFieldChange(
                          'timesheetApproving',
                          'isRoundToScheduledStartTime',
                          !timesheetApproving?.isRoundToScheduledStartTime
                        )
                      }
                    />
                    <div className="accordion-Details-switch-label">
                      Round to scheduled start time
                    </div>
                  </div>
                  <div className="accordion-Details-switch-description">
                    If &apos;on&apos;, the timesheet will be rounded to the scheduled start time.
                  </div>
                </div>
                <EzyAutoComplete
                  tooltip="When approving timesheets, the start time can be rounded."
                  name="startTimeRounding"
                  placeholder="Enter shifts earlier than scheduled"
                  label="Start time rounding"
                  options={optionList?.timeRounding}
                  value={timesheetApproving?.startTimeRoundingType}
                  onChange={(e, value) =>
                    handleFieldChange('timesheetApproving', 'startTimeRoundingType', value)
                  }
                  error={errors?.startTimeRoundingType}
                  required
                />
                <div className="accordion-Details-switch-wrapper">
                  <div className="accordion-Details-switch-container">
                    <EzySwitch
                      name="roundToScheduledEndTimeIfLater"
                      checked={timesheetApproving?.isRoundToScheduledEndTime}
                      handleChange={() =>
                        handleFieldChange(
                          'timesheetApproving',
                          'isRoundToScheduledEndTime',
                          !timesheetApproving?.isRoundToScheduledEndTime
                        )
                      }
                    />
                    <div className="accordion-Details-switch-label">
                      Round to scheduled end time
                    </div>
                  </div>
                  <div className="accordion-Details-switch-description">
                    If &apos;on&apos;, the timesheet will be rounded to the scheduled end time.
                  </div>
                </div>
                <EzyAutoComplete
                  tooltip="When approving timesheets, the end time can be rounded."
                  name="endTimeRounding"
                  placeholder="Enter shifts earlier than scheduled"
                  label="End time rounding"
                  options={optionList?.timeRounding}
                  value={timesheetApproving?.endTimeRoundingType}
                  onChange={(e, value) =>
                    handleFieldChange('timesheetApproving', 'endTimeRoundingType', value)
                  }
                  error={errors?.endTimeRoundingType}
                  required
                />
                <div className="accordion-Details-switch-wrapper">
                  <div className="accordion-Details-switch-container">
                    <EzySwitch
                      name="roundToScheduledBreakIfBreakDurationIsShorter"
                      checked={timesheetApproving?.isRoundToScheduledBreakForShorterDuration}
                      handleChange={() =>
                        handleFieldChange(
                          'timesheetApproving',
                          'isRoundToScheduledBreakForShorterDuration',
                          !timesheetApproving?.isRoundToScheduledBreakForShorterDuration
                        )
                      }
                    />
                    <div className="accordion-Details-switch-label">
                      Round to scheduled break if break duration
                    </div>
                  </div>
                  <div className="accordion-Details-switch-description">
                    If &apos;on&apos;, the timesheet will be rounded to the scheduled duration.
                  </div>
                </div>
                <EzyAutoComplete
                  tooltip="When approving timesheets, the break time can be rounded"
                  name="breakTimeRounding"
                  placeholder="Enter shifts earlier than scheduled"
                  label="Break time rounding"
                  options={optionList?.breakTimeRounding}
                  value={timesheetApproving?.breakTimeRoundingType}
                  onChange={(e, value) =>
                    handleFieldChange('timesheetApproving', 'breakTimeRoundingType', value)
                  }
                  error={errors?.breakTimeRoundingType}
                  required
                />
              </div>
            </EzyAccordionDetails>
          </EzyAccordion>
          <EzyAccordion
            expanded={expanded === 'UnscheduledShifts'}
            onChange={handleChange('UnscheduledShifts')}
          >
            <EzyAccordionSummary className="accordion-summary">
              Unscheduled shifts
            </EzyAccordionSummary>
            <EzyAccordionDetails className="accordion-details">
              <div className="accordion-details-grid">
                <EzyAutoComplete
                  tooltip="Unscheduled timesheets can be linked to a scheduled shift if the area or location matches. The default option (Best Fit) will link when an unscheduled timesheet starts 8 hours before or after a scheduled shift."
                  name="linkUnscheduledTimesheetWithAScheduledShift"
                  placeholder="Select unscheduled timesheet with a scheduled shift"
                  label="Link unscheduled timesheet with a scheduled shift"
                  options={optionList?.linkUnscheduledTimeSheetType}
                  value={UnscheduledShifts?.linkUnscheduledTimeSheetType}
                  onChange={(e, value) => {
                    handleFieldChange('UnscheduledShifts', 'linkUnscheduledTimeSheetType', value);
                    if (value?.id !== 4) {
                      handleFieldChange('UnscheduledShifts', 'unscheduledShiftTimeVariance', '');
                    }
                  }}
                  error={errors?.linkUnscheduledTimeSheetType}
                  required
                />
                <EzyAutoComplete
                  tooltip="Link unscheduled timesheet with a scheduled shift determined by a time variance."
                  name="timeVariance"
                  placeholder="Select time variance"
                  label="Time variance"
                  disabled={UnscheduledShifts?.linkUnscheduledTimeSheetType?.id !== 4}
                  options={optionList?.unscheduledShiftTimeVariance}
                  value={UnscheduledShifts?.unscheduledShiftTimeVariance}
                  onChange={(e, value) =>
                    handleFieldChange('UnscheduledShifts', 'unscheduledShiftTimeVariance', value)
                  }
                  error={errors?.unscheduledShiftTimeVariance}
                />
              </div>
            </EzyAccordionDetails>
          </EzyAccordion>
          <EzyAccordion expanded={expanded === 'breaks'} onChange={handleChange('breaks')}>
            <EzyAccordionSummary className="accordion-summary">Breaks</EzyAccordionSummary>
            <EzyAccordionDetails className="accordion-details">
              <div className="accordion-details-grid">
                <div className="accordion-Details-switch-wrapper">
                  <div className="accordion-Details-switch-container">
                    <EzySwitch
                      name="suggestABreakWhenClockingOut"
                      checked={breaks?.isBreakSuggestionOn}
                      handleChange={() =>
                        handleFieldChange(
                          'breaks',
                          'isBreakSuggestionOn',
                          !breaks?.isBreakSuggestionOn
                        )
                      }
                    />
                    <div className="accordion-Details-switch-label">
                      Suggest a break when clocking out
                    </div>
                  </div>
                  <div className="accordion-Details-switch-description">
                    When clocking out, if no breaks were taken during the shift, suggest a break
                    duration.
                  </div>
                </div>
                {/* <div className="accordion-Details-switch-wrapper">
                  <div className="accordion-Details-switch-container">
                    <EzySwitch
                      name="displayWarningForShorterOrMissedBreaks"
                      checked={breaks?.isDisplayWarningForShorterBreaks}
                      handleChange={() =>
                        handleFieldChange(
                          'breaks',
                          'isDisplayWarningForShorterBreaks',
                          !breaks?.isDisplayWarningForShorterBreaks
                        )
                      }
                    />
                    <div className="accordion-Details-switch-label">
                      Display warning for shorter or missed breaks
                    </div>
                  </div>
                  <div className="accordion-Details-switch-description">
                    Display a warning in the timesheet if a scheduled break is shorter or was
                    missed.
                  </div>
                </div> */}
                <EzyAutoComplete
                  tooltip={
                    "Meal breaks can be paid or unpaid. We do not recommend using this setting - use 'Rest breaks' as a paid break."
                  }
                  name="paidMealBreaks"
                  placeholder="Select paid meal breaks"
                  label="Paid meal breaks (legacy)"
                  options={paidMealBreaksList}
                  value={breaks?.isBreakPaid}
                  onChange={(e, value) => handleFieldChange('breaks', 'isBreakPaid', value)}
                  error={errors?.isBreakPaid}
                  required
                />
              </div>
            </EzyAccordionDetails>
          </EzyAccordion>
        </>
      )}
      <EzyWarningModal
        handleClose={handelCloseWarningModal}
        open={warningModal}
        warningMessage="Shift swap feature will be disabled. In order to allow employee to swap shift, 'Allow employee to view each other shift' needs to be set allow as 'Allow all'
        "
      />
    </EzyAccordionWrapper>
  );
}

export default EditLocationDetails;
