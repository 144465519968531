import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { isEmpty, isNil, omitBy } from 'lodash';
import moment from 'moment-timezone';
import EzyDialog from '../../../components/common/dialog/EzyDialog';
import EzySwitch from '../../../components/common/switch/EzySwitch';
import EzyDialogTitle from '../../../components/common/dialog/EzyDialogTitle';
import EzyDialogAction from '../../../components/common/dialog/EzyDialogAction';
import { updateDuplicateLocationField } from '../redux/locationSlice';
import { duplicateLocationFromList } from '../redux/locationActions';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import { requireValidate } from '../../../utils/validationHelper';
import { mapLabelFromList } from '../../../utils/enumMapping';

function DuplicateLocation({ isOpen, handleClose, rowData }) {
  const dispatch = useDispatch();

  const { duplicateLocation } = useSelector(({ location }) => location ?? []);
  const { duplicateLocationFromListLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );
  const { timeZonesList } = useSelector(({ common }) => common ?? {});

  const {
    parentLocationId,
    locationName,
    locationAddress,
    copyEmployees,
    copySettings,
    copyPayroll,
    copyShift,
    errors,
  } = useMemo(() => duplicateLocation, [duplicateLocation]);

  const handleFieldChange = (name, value) => {
    dispatch(updateDuplicateLocationField({ fieldName: name, value }));
  };

  const handleSubmit = async () => {
    const timezone = mapLabelFromList(moment.tz.guess(), timeZonesList);
    try {
      const finalData = {
        parentLocationId,
        locationName,
        locationAddress,
        copyEmployees,
        copySettings,
        copyPayroll,
        copyShift,
        startTime: !copySettings
          ? moment
              .tz(moment(new Date())?.toISOString(), moment.tz.guess())
              .set({ hours: 9, minutes: 0 })
              ?.toISOString()
          : null,
        endTime: !copySettings
          ? moment
              .tz(moment(new Date())?.toISOString(), moment.tz.guess())
              .set({ hours: 17, minutes: 0 })
              ?.toISOString()
          : null,
        timeZoneId: timezone?.id,
      };

      let error = {};
      error.locationName = requireValidate('Location name', finalData.locationName);
      error.locationAddress = requireValidate('Location address', finalData.locationAddress);
      error = omitBy(error, isNil);
      if (isEmpty(error)) {
        handleFieldChange('errors', {});
        const isSuccess = await dispatch(duplicateLocationFromList(finalData));
        if (isSuccess) {
          handleClose(true);
        }
      } else {
        handleFieldChange('errors', error);
      }
    } catch (e) {
      /**/
    }
  };

  const duplicateActionButtons = [
    {
      label: 'Cancel',
      onClick: () => {
        handleClose();
      },
      color: 'secondary',
    },
    {
      label: 'Duplicate',
      onClick: handleSubmit,
      loading: duplicateLocationFromListLoader,
    },
  ];

  useEffect(() => {
    if (rowData?.locationId && !parentLocationId) {
      handleFieldChange('parentLocationId', rowData?.locationId);
    }
  }, [rowData]);

  return (
    <EzyDialog
      handleClose={handleClose}
      open={isOpen}
      className="common-dialog-with-body copy-dialog"
    >
      <EzyDialogTitle title="Duplicate Location" onClose={handleClose} />
      <div className="dialog-body">
        <div className="duplicate-location-select-row">
          <EzyInputField
            placeholder="Enter location Name"
            label="New location name"
            value={locationName}
            onChange={(e) => {
              handleFieldChange('locationName', e?.target?.value);
            }}
            error={errors?.locationName}
            required
          />
          <EzyInputField
            placeholder="Enter address"
            label="Address"
            value={locationAddress}
            onChange={(e) => {
              handleFieldChange('locationAddress', e?.target?.value);
            }}
            error={errors?.locationAddress}
            required
          />
        </div>
        <div className="primary-bg">Settings</div>
        <div className="setting-rows">
          <div className="two-grid p-bottom-10">
            <EzySwitch
              name={copyEmployees}
              checked={copyEmployees}
              handleChange={(e) => handleFieldChange('copyEmployees', e.target.checked)}
              label="Copy employees"
            />
            <EzySwitch
              name={copySettings}
              checked={copySettings}
              handleChange={(e) => handleFieldChange('copySettings', e.target.checked)}
              label="Copy settings"
            />
          </div>
          {/* <div className="two-grid"> */}
          {/*  <EzySwitch */}
          {/*    name={copyPayroll} */}
          {/*    checked={copyPayroll} */}
          {/*    handleChange={(e) => handleFieldChange('copyPayroll', e.target.checked)} */}
          {/*    label="Copy payroll connections" */}
          {/*  /> */}
          {/*  <EzySwitch */}
          {/*    name={copyShift} */}
          {/*    checked={copyShift} */}
          {/*    handleChange={(e) => handleFieldChange('copyShift', e.target.checked)} */}
          {/*    label="Copy shift templates" */}
          {/*  /> */}
          {/* </div> */}
        </div>
      </div>
      <EzyDialogAction actionButtons={duplicateActionButtons} />
    </EzyDialog>
  );
}

export default DuplicateLocation;

DuplicateLocation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  rowData: PropTypes.object,
};

DuplicateLocation.defaultProps = {
  handleClose: null,
  rowData: {},
};
