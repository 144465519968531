import { useHistory, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EzyButton from '../../../../components/common/button/EzyButton';
import { getPlansAction } from '../../redux/globalProfileActions';
import Loader from '../../../../components/common/loader/Loader';
import IconByName from '../../../../components/common/iconByName/IconByName';
import ROUTE_CONSTANTS from '../../../../utils/constants';
import PlanExpiredAdmin from '../accountOverview/PlanExpiredAdmin';

function Plans() {
  const { paramId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const planDetails = useSelector(({ globalProfile }) => globalProfile?.planDetails ?? []);

  const { getPlansActionLoader } = useSelector(({ common }) => common.generalLoader ?? {});

  useEffect(() => {
    (async () => {
      await dispatch(getPlansAction({ businessId: paramId }));
    })();
  }, [paramId]);

  const getPlanLabelClassAndVariant = (isCurrentPlan, isPlanMonthly) => {
    const data = {
      classes: 'small',
    };
    if (isCurrentPlan) {
      data.classes = 'small outlined-primary';
      data.label = 'Activated';
      if (isPlanMonthly) {
        data.label = 'Switch to annual billing';
        data.variant = 'outlined';
      }
    } else {
      data.label = 'Select plan';
      data.variant = 'contained';
    }
    return data;
  };

  const onPlanSelect = (planId) => {
    history.push(`${ROUTE_CONSTANTS.ACCOUNT_OVERVIEW}/billing/${paramId}?planId=${planId}`);
  };

  return (
    <div className="plan-wrapper">
      {getPlansActionLoader ? (
        <Loader />
      ) : (
        planDetails?.map(
          (
            {
              planId,
              planPrice,
              planName,
              description,
              planFeatureMapping,
              isPlanMonthly,
              isCurrentPlan,
            },
            index
          ) => {
            const { variant, label, classes } = getPlanLabelClassAndVariant(
              isCurrentPlan,
              isPlanMonthly
            );
            return (
              <div className={`plan-card ${isCurrentPlan ? 'selected' : ''}`} key={index}>
                <div className="plan-card-header">
                  {planName} {planPrice} / month
                </div>
                <div className="plan-card-sub-header">{description}</div>
                <EzyButton
                  label={label}
                  className={classes}
                  variant={variant}
                  onClick={() => {
                    if (label !== 'Activated') {
                      onPlanSelect(planId, isPlanMonthly);
                    }
                  }}
                />
                <div className="plan-card-info">
                  {planFeatureMapping?.map(({ featureName, isActive }, index2) => (
                    <div className="d-flex flex-align-center gap-12" key={index2}>
                      <IconByName
                        className={isActive ? 'primary-color' : 'error-color'}
                        name={isActive ? 'check_circle' : 'cancel'}
                      />
                      <div className="feature-name">{featureName}</div>
                    </div>
                  ))}
                </div>
              </div>
            );
          }
        )
      )}
      <PlanExpiredAdmin />
    </div>
  );
}

export default Plans;
