import apiService from '../../utils/apiService';
import API_URL from '../../utils/urlConstants';

export const locationServices = {
  getLocationList: (params) => apiService.getData(API_URL.LOCATION.locationList, { params }),
  sendAddLocation: (data) => apiService.postData(API_URL.LOCATION.addLocation, data),
  sendEditGeneralLocation: (data) =>
    apiService.postData(API_URL.LOCATION.editGeneralLocation, data),
  sendEditDetailsLocation: (data) =>
    apiService.postData(API_URL.LOCATION.editDetailsLocation, data),
  copyLocation: (data) => apiService.postData(API_URL.LOCATION.copyLocation, data),
  duplicateLocation: (data) => apiService.postData(API_URL.LOCATION.duplicateLocation, data),
  getLocationByLocationID: (params) =>
    apiService.getData(API_URL.LOCATION.getGeneralLocationSetting, { params }),
  getLocationDetailsByID: (params) =>
    apiService.getData(API_URL.LOCATION.getLocationDetailsSetting, { params }),
  getDetailsBeforeArchive: (params) =>
    apiService.getData(API_URL.LOCATION.getDetailsBeforeArchive, { params }),
  archiveLocation: (data) => apiService.postData(API_URL.LOCATION.archiveLocation, data),
  deleteLocation: (params) => apiService.deleteData(API_URL.LOCATION.deleteLocation, { params }),
  getLocationsByBusinessId: (params) =>
    apiService.getData(API_URL.LOCATION.locationsByBusinessId, { params }),
  integrationLoginUrl: (params) =>
    apiService.getData(API_URL.GLOBALPROFILE.integrationLoginUrl, { params }),
  peopleFromIntegration: (params) =>
    apiService.getData(API_URL.GLOBALPROFILE.peopleFromIntegration, { params }),
};
