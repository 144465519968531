import React from 'react';
import { Paper } from '@mui/material';
import BorderedHeader from '../../components/common/headers/BorderedHeader';

function PrivacyPolicy() {
  return (
    <div className="h-full w-full privacy-policy-wrapper">
      <BorderedHeader isLogin />
      <div className="r-form-wrapper overflow-auto ">
        <div className="header-wrapper">
          <div className="main-title-text">
            <b>
              <u>Privacy Policy</u>
            </b>
          </div>
          <div className="subtitle">
            This Policy was last reviewed and updated on: [26 January 2023]
          </div>
        </div>
        <Paper className="content-wrapper">
          <p>
            Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust values and
            respects the privacy of the people we deal with. Payroll Ezy - Yogi Innovations Pty Ltd
            As Trustees For Yogi Unit Trust is committed to protecting your privacy and complying
            with the Privacy Act 1988 (Cth) (Privacy Act) and other applicable privacy laws and
            regulations. This Privacy Policy (Policy) describes how we collect, hold, use and
            disclose your personal information, and how we maintain the quality and security of your
            personal information.
          </p>

          <div className="title">What is personal information?</div>
          <p>
            “Personal information” means any information or opinion, whether true or not, and
            whether recorded in a material form or not, about an identified individual or an
            individual who is reasonably identifiable. In general terms, this includes information
            or an opinion that personally identifies you either directly (e.g. your name) or
            indirectly.
          </p>

          <div className="title">What personal information do we collect?</div>
          <p>
            The personal information we collect about you depends on the nature of your dealings
            with us or what you choose to share with us.
          </p>
          <p>The personal information we collect about you may include:</p>

          <ul>
            <li>name;</li>
            <li>mailing or street address;</li>
            <li>date of birth;</li>
            <li>email address;</li>
            <li> phone number;</li>
            <li>profile photo;</li>
            <li>gender number;</li>
            <li>employer information;</li>
            <li>working hours employer;</li>
            <li>face recognition;</li>
            <li>geo location and time clock in and out;</li>
            <li>record of communication;</li>
            <li>business name;</li>
            <li>trade name, and addresses;</li>
            <li>Identification numbers;</li>
            <li>credit card details or other billing information;</li>
            <li>next of kin, emergency contact;</li>
            <li>work location;</li>
          </ul>

          <p>
            Under certain circumstances, Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
            Unit Trust may need to collect sensitive information about you. This might include any
            information or opinion about your racial or ethnic origin, political opinions, political
            association, religious or philosophical beliefs, membership of a trade union or other
            professional body, sexual preferences, criminal record, or health information.
          </p>

          <p>
            If we collect your sensitive information, we will do so only with your consent, if it is
            necessary to prevent a serious and imminent threat to life or health, or as otherwise
            required or authorised by law, and we take appropriate measures to protect the security
            of this information.
          </p>
          <p>
            You do not have to provide us with your personal information. Where possible, we will
            give you the option to interact with us anonymously or by using a pseudonym. However, if
            you choose to deal with us in this way or choose not to provide us with your personal
            information, we may not be able to provide you with our services or otherwise interact
            with you.
          </p>

          <div className="title">How do we collect your personal information?</div>
          <p>We collect your personal information directly from you when you:</p>
          <ul>
            <li>interact with us over the phone;</li>
            <li>interact with us in person;</li>
            <li> interact with us online;</li>
            <li>participate in surveys or questionnaires;</li>
            <li>
              attend a Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust event;
            </li>
            <li>subscribe to our mailing list;</li>
            <li>
              data from 3rd party integrations or usages of our API which have been authorized by
              your organization;
            </li>
            <li>apply for a position with us as an employee, contractor or volunteer;</li>
            <li>
              employment Information that is inputted by you or your organization (our Customer);
            </li>
            <li>other information generated from your use of the Services; and</li>
            <li>
              personal Information collected automatically from your Device or through our Website.
            </li>
          </ul>

          <div className="title">Collecting personal information from third parties</div>
          <p>
            We may also collect your personal information from third parties or through publicly
            available sources. We collect your personal information from these third parties so that
            our business functions.
          </p>
          <div className="title">How do we use your personal information?</div>
          <p>
            We use personal information for many purposes in connection with our functions and
            activities, including the following purposes:
          </p>
          <ul>
            <li>provide you with information or services that you request from us;</li>
            <li>deliver to you a more personalised experience and service offering;</li>
            <li>improve the quality of the services we offer;</li>
            <li>internal administrative purposes;</li>
            <li>marketing and research purposes;</li>
          </ul>

          <div className="title">Disclosure of personal information to third parties</div>
          <p>
            We may disclose your personal information to third parties in accordance with this
            Policy in circumstances where you would reasonably expect us to disclose your
            information. For example, we may disclose your personal information to:
          </p>
          <ul>
            <li>our third party service providers (for example, our IT providers);</li>
            <li>our marketing providers;</li>
            <li>our professional services advisors;</li>
          </ul>
          <div className="title">Transfer of personal information overseas</div>
          <p>
            Some of the third-party service providers we disclose personal information to may be
            based in or have servers located outside of Australia, including in overseas countries
            where third parties are located / have servers.
          </p>
          <p>
            Where we disclose your personal information to third parties overseas, we will take
            reasonable steps to ensure that data security and appropriate privacy practices are
            maintained. We will only disclose to overseas third parties if:
          </p>
          <p>We reasonably believe that:</p>
          <ul>
            <li>
              the overseas recipient is subject to a law or binding scheme that is, overall,
              substantially similar to the APPs; and
            </li>
            <li>the law or binding scheme can be enforced; or</li>
          </ul>
          <div className="title">How do we protect your personal information?</div>
          <p>
            Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust will take
            reasonable steps to ensure that the personal information that we hold about you is kept
            confidential and secure, including by:
          </p>
          <ul>
            <li>
              having a robust physical security of our premises/ third parties and databases /
              records;
            </li>
            <li>
              taking measures to restrict access to only personnel who need that personal
              information to effectively provide services to you;
            </li>
            <li>
              having technological measures in place (for example, anti-virus software, fire walls).
            </li>
          </ul>

          <div className="title">Online activity</div>
          <i>Cookies</i>
          <p>
            The Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust website uses
            cookies. A cookie is a small file of letters and numbers the website puts on your device
            if you allow it. These cookies recognise when your device has visited our website(s)
            before, so we can distinguish you from other users of the website. This improves your
            experience and the Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit
            Trust website(s).
          </p>
          <p>
            We do not use cookies to identify you, just to improve your experience on our
            website(s). If you do not wish to use the cookies, you can amend the settings on your
            internet browser so it will not automatically download cookies. However, if you remove
            or block cookies on your computer, please be aware that your browsing experience and our
            website’s functionality may be affected.
          </p>
          <i>Website analytics</i>
          <p>
            Our website uses relevant analytics service to help us better understand visitor
            traffic, so we can improve our services. Although this data is mostly anonymous, it is
            possible that under certain circumstances, we may connect it to you.
          </p>
          <i>Direct marketing</i>
          <p>
            We may send you direct marketing communications and information about our services,
            opportunities, or events that we consider may be of interest to you if you have
            requested or consented to receive such communications. These communications may be sent
            in various forms, including mail, SMS, fax and email, in accordance with applicable
            marketing laws, such as the Australian Spam Act 2003 (Cth). You consent to us sending
            you those direct marketing communications by any of those methods. If you indicate a
            preference for a method of communication, we will endeavour to use that method whenever
            practical to do so.
          </p>
          <p>
            You may opt-out of receiving marketing communications from us at any time by following
            the instructions to &ldquo;unsubscribe&rdquo; set out in the relevant communication /
            contacting us using the details set out in the “How to contact us” section below.
          </p>
          <p>
            In addition, we may also use your personal information or disclose your personal
            information to third parties for the purposes of advertising, including online
            behavioural advertising, website personalisation, and to provide targeted or retargeted
            advertising content to you (including through third party websites).
          </p>
          <div className="title">Retention of personal information</div>
          <p>
            We will not keep your personal information for longer than we need to. In most cases,
            this means that we will only retain your personal information for the duration of your
            relationship with us unless we are required to retain your personal information to
            comply with applicable laws, for example record-keeping obligations.
          </p>
          <div className="title">How to access and correct your personal information</div>
          <p>
            Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust will endeavour to
            keep your personal information accurate, complete and up to date.
          </p>
          <p>
            If you wish to make a request to access and / or correct the personal information we
            hold about you, you should make a request by contacting us in writing to email (
            <a href="mailto:info@yogiinnovations.com">info@yogiinnovations.com</a>).
          </p>
          <div className="title">Links to third party sites</div>
          <p>
            Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi Unit Trust website(s) may
            contain links to websites operated by third parties. If you access a third party website
            through our website(s), personal information may be collected by that third party
            website. We make no representations or warranties in relation to the privacy practices
            of any third party provider or website and we are not responsible for the privacy
            policies or the content of any third party provider or website. Third party providers /
            websites are responsible for informing you about their own privacy practices and we
            encourage you to read their privacy policies.
          </p>
          <div className="title">Inquiries and complaints</div>
          <p>
            For complaints about how Payroll Ezy - Yogi Innovations Pty Ltd As Trustees For Yogi
            Unit Trust handles, processes or manages your personal information, please contact (
            <a href="mailto:info@yogiinnovations.com">info@yogiinnovations.com</a>). Note we may
            require proof of your identity and full details of your request before we can process
            your complaint. It will not always be possible to resolve a complaint to everyone’s
            satisfaction.
          </p>
          <div className="title">How to contact us</div>
          <p>
            If you have a question or concern in relation to our handling of your personal
            information or this Policy, you can contact us for assistance as follows:
          </p>
          <div>
            Email : <a href="mailto:info@yogiinnovations.com">info@yogiinnovations.com</a>
          </div>
        </Paper>
      </div>
    </div>
  );
}

PrivacyPolicy.propTypes = {};

export default PrivacyPolicy;
