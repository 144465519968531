import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  startGeneralLoader,
  stopGeneralLoader,
} from '../../../../utils/backOfficeLists/commonDataSlice';
import { getInvoicesDataAction } from '../../redux/globalProfileActions';
import { generateAndDownloadPDF } from '../../../../utils/invoicePDFDownload';
import { displayErrors } from '../../../../utils/errorNotifyHelper';
import Loader from '../../../../components/common/loader/Loader';
import ROUTE_CONSTANTS from '../../../../utils/constants';

function DownloadInvoice() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  const { getInvoicesDataActionLoader } = useSelector(({ common }) => common.generalLoader ?? {});
  useEffect(() => {
    if (id) {
      try {
        dispatch(startGeneralLoader({ loaderFor: 'getInvoicesDataActionLoader' }));
        dispatch(
          getInvoicesDataAction({ invoiceId: id }, async (data) => {
            await generateAndDownloadPDF(data);
            window.close();
            history.push(ROUTE_CONSTANTS.HOME);
          })
        );
      } catch (e) {
        displayErrors(e);
      } finally {
        dispatch(stopGeneralLoader({ loaderFor: 'getInvoicesDataActionLoader' }));
      }
    }
  }, [id]);
  return <div>{getInvoicesDataActionLoader && <Loader />}</div>;
}

export default DownloadInvoice;
