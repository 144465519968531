import { Box, FormControl, Menu, TextField } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import EzyIconButton from '../../../components/common/iconButton/EzyIconButton';
import { displayDate } from '../../../utils/timeHelper';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import EzyButton from '../../../components/common/button/EzyButton';

function DateDurationPicker({ value, onChange, className, timeZone, ...restProps }) {
  const listOfDurations = [
    {
      id: 1,
      label: 'Current',
      subOptions: [
        'Today',
        'Week',
        `Fortnight 1 ( ${displayDate(moment(new Date()).startOf('week'), timeZone)} - ${displayDate(
          moment(new Date()).startOf('week')?.add(13, 'days'),
          timeZone
        )} )`,
        `Fortnight 2 ( ${displayDate(
          moment(new Date()).startOf('week')?.subtract(7, 'days'),
          timeZone
        )} - ${displayDate(
          moment(new Date()).startOf('week')?.subtract(7, 'days').add(13, 'days'),
          timeZone
        )})`,
        'Month',
      ],
    },
    {
      id: 2,
      label: 'Last',
      subOptions: ['Yesterday', '7 days', '14 days', '30 days', '90 days'],
    },
    { id: 3, label: 'Select Date Range' },
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState(
    listOfDurations?.find((e) => e?.subOptions?.includes(value?.filterDuration))
  );
  const open = Boolean(anchorEl);
  const [weekRange, setWeekRange] = useState({
    startDate: moment(new Date()),
    endDate: moment(new Date()),
  });
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleOptionSelect = (e) => {
    const weekDatesValue = {};

    switch (e) {
      case 'Today':
        weekDatesValue.startDate = moment.tz(new Date(), timeZone).startOf('day');
        weekDatesValue.endDate = moment.tz(new Date(), timeZone).endOf('day');
        break;
      case 'Month':
        weekDatesValue.startDate = moment.tz(new Date(), timeZone).startOf('Month');
        weekDatesValue.endDate = moment.tz(new Date(), timeZone).endOf('Month');
        break;
      case 'Yesterday':
        weekDatesValue.startDate = moment
          .tz(new Date(), timeZone)
          .subtract(1, 'days')
          .startOf('day');
        weekDatesValue.endDate = moment.tz(new Date(), timeZone).subtract(1, 'days').endOf('day');
        break;
      case '7 days':
      case '14 days':
      case '30 days':
      case '90 days':
        weekDatesValue.startDate = moment
          .tz(new Date(), timeZone)
          .startOf('day')
          .subtract(e.split(' ')[0] - 1, 'days');
        weekDatesValue.endDate = moment.tz(new Date(), timeZone).startOf('day').endOf('day');
        break;

      case `Fortnight 1 ( ${displayDate(
        moment(new Date()).startOf('week'),
        timeZone
      )} - ${displayDate(moment(new Date()).startOf('week')?.add(13, 'days'), timeZone)} )`:
        weekDatesValue.startDate = moment.tz(new Date(), timeZone).startOf('week');
        weekDatesValue.endDate = moment
          .tz(new Date(), timeZone)
          .startOf('week')
          .add(13, 'days')
          .endOf('day');
        break;

      case `Fortnight 2 ( ${displayDate(
        moment(new Date()).startOf('week')?.subtract(7, 'days'),
        timeZone
      )} - ${displayDate(
        moment(new Date()).startOf('week')?.subtract(7, 'days').add(13, 'days'),
        timeZone
      )})`:
        weekDatesValue.startDate = moment
          .tz(new Date(), timeZone)
          .startOf('week')
          ?.subtract(7, 'days');
        weekDatesValue.endDate = moment
          .tz(new Date(), timeZone)
          .startOf('week')
          ?.subtract(7, 'days')
          .add(13, 'days')
          .endOf('day');
        break;

      default:
        weekDatesValue.startDate = moment.tz(new Date(), timeZone).startOf('week');
        weekDatesValue.endDate = moment.tz(new Date(), timeZone).endOf('week');
        break;
    }
    onChange({
      filterDuration: e,
      startDate: weekDatesValue?.startDate,
      endDate: weekDatesValue?.endDate,
    });
    handleClose();
  };

  return (
    <FormControl variant="standard" className={`week-picker ${className}`}>
      <EzyIconButton
        className="left-button"
        iconName="navigate_before"
        onClick={() => {
          if (value?.filterDuration === 'Month') {
            onChange({
              ...value,
              startDate: moment(value?.startDate).subtract(1, 'months').startOf('Month'),
              endDate: moment(value?.startDate).subtract(1, 'months').endOf('Month'),
            });
          } else
            onChange({
              ...value,
              startDate: moment
                .tz(value?.startDate, timeZone)
                .subtract(moment(value?.endDate).diff(value?.startDate, 'days') + 1, 'days'),
              endDate: moment
                .tz(value?.endDate, timeZone)
                .subtract(moment(value?.endDate).diff(value?.startDate, 'days') + 1, 'days'),
            });
        }}
        variant="outlined"
      />
      <TextField
        value={`${displayDate(value?.startDate, timeZone)} - ${displayDate(
          value?.endDate,
          timeZone
        )}`}
        notched={false}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        onClick={handleClick}
        renderInput={(params2) => (
          <EzyInputField
            placeholder="Select Date"
            ref={params2.InputProps.ref}
            inputProps={params2.inputProps}
            disabled={restProps.disabled}
          />
        )}
      />
      <EzyIconButton
        className="right-button"
        iconName="navigate_next"
        onClick={() => {
          if (value?.filterDuration === 'Month') {
            onChange({
              ...value,
              startDate: moment(value?.startDate).add(1, 'months').startOf('Month'),
              endDate: moment(value?.startDate).add(1, 'months').endOf('Month'),
            });
          } else {
            onChange({
              ...value,
              startDate: moment
                .tz(value?.startDate, timeZone)
                .add(moment(value?.endDate).diff(value?.startDate, 'days') + 1, 'days'),
              endDate: moment
                .tz(value?.endDate, timeZone)
                .add(moment(value?.endDate).diff(value?.startDate, 'days') + 1, 'days'),
            });
          }
        }}
        variant="outlined"
      />
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="duration-menu-wrapper"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ bgcolor: 'white' }}>
          <div className="date-duration-wrapper">
            <div className="options-list">
              {listOfDurations?.map((item) => (
                <span
                  key={item?.id}
                  onClick={() => setSelected(item)}
                  className={`pointer-cursor ${
                    selected?.label === item?.label ? 'selected-option' : ''
                  }`}
                >
                  {item?.label}
                  <span className="material-icons">navigate_next</span>
                </span>
              ))}
            </div>
            <div className="options-list">
              {listOfDurations
                ?.find((e) => e?.id === selected?.id)
                ?.subOptions?.map((e) => (
                  <span
                    key={e}
                    className={`pointer-cursor ${
                      value?.filterDuration === e ? 'selected-option' : ''
                    }`}
                    onClick={() => handleOptionSelect(e)}
                  >
                    {e}
                  </span>
                ))}
              {selected?.id === 3 && (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <div className="d-flex gap-5">
                    <div>
                      <span>Start date</span>
                      <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        onChange={(e) =>
                          setWeekRange((prev) => {
                            return { ...prev, startDate: moment.tz(e, timeZone).startOf('day') };
                          })
                        }
                        defaultValue={moment(new Date())}
                      />
                    </div>
                    <div>
                      <span>To date</span>
                      <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        onChange={(e) =>
                          setWeekRange((prev) => {
                            return { ...prev, endDate: moment.tz(e, timeZone).endOf('day') };
                          })
                        }
                        defaultValue={moment(new Date())}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <EzyButton
                      label="Done"
                      className="no-pad-button"
                      onClick={() => {
                        onChange(weekRange);
                        handleClose();
                      }}
                    />
                  </div>
                </LocalizationProvider>
              )}
            </div>
          </div>
        </Box>
      </Menu>
    </FormControl>
  );
}

export default DateDurationPicker;

DateDurationPicker.propTypes = {
  value: PropTypes.object.isRequired,
  className: PropTypes.string,
  timeZone: PropTypes.string,
  onChange: PropTypes.func,
};

DateDurationPicker.defaultProps = {
  className: '',
  timeZone: moment.tz.guess(),
  onChange: () => {},
};
