import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, List, ListItem, ListItemButton, ListItemText, Paper } from '@mui/material';
import moment from 'moment-timezone';
import EzyMenu from '../../../components/common/menu/EzyMenu';
import { getLocationDropDownListByBusinessId } from '../../location/redux/locationActions';
import Loader from '../../../components/common/loader/Loader';
import IconByName from '../../../components/common/iconByName/IconByName';
import { getExportTimeSheetDetailsAction } from '../redux/exportTimesheetsActions';
import { setPersonWiseTimeSheetsList, updateSidePanelData } from '../redux/exportTimesheetSlice';
// import PropTypes from 'prop-types';

function ExportTimesheetsSidebar() {
  const dispatch = useDispatch();
  const { locationListEnum } = useSelector(({ location }) => location ?? []);
  const { selectedLocation, weekDatesValue, personsList, selectedPersons } = useSelector(
    ({ exportTimesheet }) => exportTimesheet?.sidePanelData ?? []
  );

  const { getLocationsByBusinessIdLoader, getExportTimeSheetDetailsActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const handleToggle = (value) => () => {
    const currentIndex = selectedPersons.indexOf(value);
    const newChecked = [...selectedPersons];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    dispatch(
      updateSidePanelData({
        fieldName: 'selectedPersons',
        value: newChecked,
      })
    );
  };

  const getExportTimeSheetDetailsByFilter = async (params) => {
    await dispatch(
      getExportTimeSheetDetailsAction({
        locationId: selectedLocation?.id,
        startDate: moment(weekDatesValue?.startDate)?.toISOString(),
        endDate: moment(weekDatesValue?.endDate)?.toISOString(),
        ...params,
      })
    );
    dispatch(
      updateSidePanelData({
        fieldName: 'selectedPersons',
        value: [],
      })
    );
  };

  useEffect(() => {
    dispatch(
      getLocationDropDownListByBusinessId((data) => {
        dispatch(updateSidePanelData({ fieldName: 'selectedLocation', value: data?.[0] }));
        moment.updateLocale('en', {
          week: {
            dow: data?.[0]?.weekStartsOn === 7 ? 0 : data?.[0]?.weekStartsOn,
          },
        });
        dispatch(
          updateSidePanelData({
            fieldName: 'weekDatesValue',
            value: {
              startDate: moment.tz(new Date(), data?.[0]?.timeZoneId?.label).startOf('week'),
              endDate: moment.tz(new Date(), data?.[0]?.timeZoneId?.label).endOf('week'),
            },
          })
        );

        const params = {
          locationId: data?.[0]?.id,
          startDate: moment
            .tz(new Date(), data?.[0]?.timeZoneId?.label)
            .startOf('week')
            ?.toISOString(),
          endDate: moment.tz(new Date(), data?.[0]?.timeZoneId?.label).endOf('week')?.toISOString(),
        };

        getExportTimeSheetDetailsByFilter(params);
      })
    );

    return () => {
      dispatch(setPersonWiseTimeSheetsList([]));
      dispatch(
        updateSidePanelData({
          fieldName: 'selectedPersons',
          value: [],
        })
      );
    };
  }, []);

  return (
    <div className="export-timesheets-sidebar">
      <EzyMenu
        className="export-timesheets-location-menu"
        menuItems={locationListEnum}
        label={selectedLocation?.label || 'Location'}
        iconName="location_on"
        menuIcon="location_on"
        onItemClick={(_, value) => {
          dispatch(updateSidePanelData({ fieldName: 'selectedLocation', value }));
          dispatch(
            updateSidePanelData({
              fieldName: 'selectedPersons',
              value: [],
            })
          );
          moment.updateLocale('en', {
            week: {
              dow: value?.weekStartsOn === 7 ? 0 : value?.weekStartsOn,
            },
          });
          const params = {
            locationId: value?.id,
            startDate: moment
              .tz(weekDatesValue?.startDate, value?.timeZoneId?.label)
              .startOf('week')
              .toISOString(),
            endDate: moment
              .tz(weekDatesValue?.startDate, value?.timeZoneId?.label)
              .endOf('week')
              .toISOString(),
          };

          dispatch(
            updateSidePanelData({
              fieldName: 'weekDatesValue',
              value: {
                startDate: moment
                  .tz(weekDatesValue?.startDate, value?.timeZoneId?.label)
                  .startOf('week'),
                endDate: moment
                  .tz(weekDatesValue?.startDate, value?.timeZoneId?.label)
                  .endOf('week'),
              },
            })
          );
          dispatch(
            updateSidePanelData({
              fieldName: 'selectedPersons',
              value: [],
            })
          );

          getExportTimeSheetDetailsByFilter(params);
        }}
        selectedItem={selectedLocation}
        menuItemsIcon
        loading={getLocationsByBusinessIdLoader}
      />

      <Paper className="export-timesheet-side-card-wrapper">
        <div className="title-text">
          <div className="d-flex flex-align-center">
            <Checkbox
              edge="start"
              tabIndex={-1}
              disableRipple
              checked={personsList?.length === selectedPersons?.length}
              icon={<IconByName name="check_circle_outline" />}
              checkedIcon={<IconByName name="check_circle" />}
              onChange={(e) => {
                if (e.target.checked) {
                  dispatch(
                    updateSidePanelData({
                      fieldName: 'selectedPersons',
                      value: personsList,
                    })
                  );
                } else {
                  dispatch(
                    updateSidePanelData({
                      fieldName: 'selectedPersons',
                      value: [],
                    })
                  );
                }
              }}
            />
            Select all
          </div>
        </div>
        {getExportTimeSheetDetailsActionLoader && <Loader />}
        <List className="area-check-list">
          {personsList?.map((value) => {
            // const labelId = `checkbox-list-label-${value?.id}`;

            return (
              <ListItem
                key={value?.personDetailId}
                disablePadding
                selected={selectedPersons?.indexOf(value) !== -1}
              >
                <ListItemButton role={undefined} onClick={handleToggle(value)} dense disableRipple>
                  <ListItemText id={value?.personDetailId} className="publish-checks-view">
                    <div className="person-details">
                      <div className="name">{value?.name}</div>
                      <div className="details">
                        <span>{value?.timeSheetCount} items</span>
                        <span>{value?.totalWorkingHour}</span>
                        <span>{value?.totalCost}</span>
                      </div>
                    </div>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Paper>
    </div>
  );
}

// ExportTimesheetsSidebar.propTypes = {};

export default ExportTimesheetsSidebar;
