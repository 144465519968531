const ROUTE_CONSTANTS = {
  HOME: '/home',
  HOME_WITH_ACTION: '/home/:action',
  PAYMENT_STATUS: '/payment/:paymentStatus',
  LOGIN: '/login',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  SET_PASSWORD: '/set-password',
  INVITE: '/accept-invite',
  REGISTER_BUSINESS: '/register-business',
  BUSINESS_SETTINGS: '/business-setting',
  LOCATIONS: '/locations',
  ADD_LOCATION: '/locations/:action',
  Edit_LOCATION: '/locations/:action/:locationId',
  PEOPLE: '/people',
  ADD_PEOPLE: '/people/:action',
  Edit_PEOPLE: '/people/:action/:peopleId',
  SCHEDULE: '/schedule',
  TIMESHEETS: '/timesheet',
  REPORTS: '/reports',
  FORBIDDEN: '/forbidden-access',
  GLOBAL_PROFILE: '/profile',
  ACCOUNT_OVERVIEW: '/account-overview',
  ACCOUNT_OVERVIEW_BUSINESS_SETTINGS: '/account-overview/:action/:paramId',
  VERIFY_EMAIL: '/verify-email',
  DOWNLOAD_INVOICE: '/download-invoice/:id',
  APP_INTEGRATION: '/integrations',
  APP_INTEGRATION_SETTINGS: '/integrations/:action/:id',
  NEWSFEED: '/newsfeed',
  LOGBOOK: '/logbook',
  TASKS: '/tasks',
  EXPORT_TIMESHEETS: '/timesheet/export',
  PRINT_EXPORT_TIMESHEETS: '/timesheet/export/print_timesheet',
  INSIGHT: '/schedule/insights/:pathname',
  METRIC_EDIT: '/schedule/insights/:pathname/edit',
  STAFF_EDIT: '/schedule/insights/staff/edit',
  PRIVACY_POLICY: '/terms/privacy-policy',
  TERMS_AND_CONDITION: '/terms/terms&condition',
};

export default ROUTE_CONSTANTS;

export const DATE_FORMAT = 'ddd, Do MMM YYYY';
export const DATE_SIMPLE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'ddd, Do MMM YYYY, hh:mm a';
export const TIME_FORMAT = 'hh:mm a';
export const TIME_FORMAT_24 = 'HH:mm';
export const WINDOW_OPEN_MESSAGE = 'window for code, relmId and state';

export const NAVBAR_CONSTANTS = [
  {
    tabValueAndLinkUrl: ROUTE_CONSTANTS.HOME,
    iconName: 'dashboard',
    tabLabel: 'Dashboard',
    access: 'LISTDASHCAL',
  },
  {
    tabValueAndLinkUrl: ROUTE_CONSTANTS.NEWSFEED,
    iconName: 'switch_access_shortcut_add',
    tabLabel: 'News Feed',
    access: 'LISTNWF',
  },
  {
    tabValueAndLinkUrl: ROUTE_CONSTANTS.TASKS,
    iconName: 'task',
    tabLabel: 'Tasks',
    access: 'LISTTSK',
  },
  {
    tabValueAndLinkUrl: ROUTE_CONSTANTS.LOCATIONS,
    iconName: 'pin_drop',
    tabLabel: 'Locations',
    access: 'LISTLOC',
  },
  {
    tabValueAndLinkUrl: ROUTE_CONSTANTS.PEOPLE,
    iconName: 'groups',
    tabLabel: 'People',
    access: 'LISTPPL',
  },
  {
    tabValueAndLinkUrl: ROUTE_CONSTANTS.SCHEDULE,
    iconName: 'watch_later',
    tabLabel: 'Schedule',
    access: 'LISTEMPSCHED',
  },
  {
    tabValueAndLinkUrl: ROUTE_CONSTANTS.TIMESHEETS,
    iconName: 'pending_actions',
    tabLabel: 'Timesheets',
    access: 'LISTTS',
  },
  {
    tabValueAndLinkUrl: ROUTE_CONSTANTS.LOGBOOK,
    iconName: 'description',
    tabLabel: 'Logbook',
    access: 'LISTLGB',
  },
];

export const numberOfPeopleArray = [
  { label: '1-15', value: 0 },
  { label: '16-25', value: 1 },
  { label: '26-49', value: 2 },
  { label: '50-249', value: 3 },
  { label: '250-749', value: 4 },
  { label: '749+', value: 5 },
];

export const breakDurationList = [
  5, 15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180, 195, 210,
];

export const paidMealBreaksList = [
  {
    label: 'All Meal breaks are unpaid',
    id: false,
  },
  { label: 'All Meal breaks are paid', id: true },
];

export const dayList = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const timeSheetActions = {
  approve: 'APPROVE',
  approveAll: 'APPROVE_ALL',
  unApproveAll: 'UN_APPROVE_ALL',
  unApprovePay: 'UN_APPROVE_PAY',
  unApproveTime: 'UN_APPROVE_TIME',
  discard: 'DISCARD',
  reInstate: 'RE_INSTATE',
};

export const timeSheetActionButtons = {
  approveTime: 'Approve time',
  approvePay: 'Approve pay',
  approveAll: 'Approve all',
  unApproveAll: 'Un approve all',
  unApprovePay: 'Un approve pay',
  unApproveTime: 'Un approve time',
  discard: 'Discard',
  reInstate: 'Re-instate',
};

export const AOSettingRoutes = [
  '/account-overview/general',
  '/account-overview/billing',
  '/account-overview/plans',
  '/account-overview/invoice',
];

export const AppIntegrationRoutes = [
  '/integrations/configuration/:id',
  '/integrations/appInfo/:id',
];

export const DurationFilter = [
  {
    id: 5,
    label: 'Day',
    dayCount: 1,
  },
  {
    id: 1,
    label: 'Week',
    dayCount: 7,
  },
  {
    id: 2,
    label: '2 Weeks',
    dayCount: 14,
  },
  {
    id: 3,
    label: '4 Weeks',
    dayCount: 28,
  },
  {
    id: 4,
    label: 'Month',
    dayCount: 30,
  },
];

export const DurationFilterMenu = [
  { id: 'area', label: 'VIEW BY AREA', disabled: true },
  ...DurationFilter.map((e) => (e?.id === 4 ? { ...e, isDividerAfter: true } : e)),
  { id: 'person', label: 'VIEW BY PERSON', disabled: true },
  ...DurationFilter.map((e) => {
    return { ...e, id: e.id + 5 };
  }),
];

export const ENUMS_LIST = {
  datesOfMonthList: [
    { id: 1, label: '1st' },
    { id: 2, label: '2nd' },
    { id: 3, label: '3rd' },
    { id: 4, label: '4th' },
    { id: 5, label: '5th' },
    { id: 6, label: '6th' },
    { id: 7, label: '7th' },
    { id: 8, label: '8th' },
    { id: 9, label: '9th' },
    { id: 10, label: '10th' },
    { id: 11, label: '11th' },
    { id: 12, label: '12th' },
    { id: 13, label: '13th' },
    { id: 14, label: '14th' },
    { id: 15, label: '15th' },
    { id: 16, label: '16th' },
    { id: 17, label: '17th' },
    { id: 18, label: '18th' },
    { id: 19, label: '19th' },
    { id: 20, label: '20th' },
    { id: 21, label: '21st' },
    { id: 22, label: '22nd' },
    { id: 23, label: '23rd' },
    { id: 24, label: '24th' },
    { id: 25, label: '25th' },
    { id: 26, label: '26th' },
    { id: 27, label: '27th' },
    { id: 28, label: '28th' },
    { id: 29, label: '29th' },
    { id: 30, label: '30th' },
    { id: 31, label: '31st' },
  ],
  taskCompletionStatus: [
    {
      id: 1,
      label: 'Pending',
    },
    {
      id: 2,
      label: 'Completion',
    },
  ],
  description: [
    {
      id: 1,
      label: "Don't repeat",
    },
    {
      id: 2,
      label: 'Daily',
    },
    {
      id: 3,
      label: 'Weekly',
    },
    {
      id: 4,
      label: 'Monthly',
    },
  ],
  unavailabilityFrequency: [
    {
      id: 1,
      label: 'Weekly',
    },
    {
      id: 2,
      label: '2 Weekly',
    },
    {
      id: 3,
      label: '4 Weekly',
    },
    {
      id: 4,
      label: 'Monthly',
    },
  ],
  accrualAndDeductLeave: [
    {
      id: 1,
      label: 'Accrual and deduct leave',
    },
    {
      id: 2,
      label: 'Deduct leave only',
    },
  ],
  allocateSalaryTo: [
    {
      id: 1,
      label: 'Sceduled Areas',
    },
    {
      id: 2,
      label: 'Specify Areas',
    },
  ],
  autoApproveTimeSheetVariance: [
    {
      id: 1,
      label: 'never',
    },
    {
      id: 2,
      label: 'when variance from schedule is up to 5 minutes',
    },
    {
      id: 3,
      label: 'when variance from schedule is up to 10 minutes',
    },
    {
      id: 4,
      label: 'when variance from schedule is up to 15 minutes',
    },
    {
      id: 5,
      label: 'when variance from schedule is up to 20 minutes',
    },
    {
      id: 6,
      label: 'when variance from schedule is up to 30 minutes',
    },
    {
      id: 7,
      label: 'when variance from schedule is up to 60 minutes',
    },
  ],
  breakTimeRounding: [
    {
      id: 1,
      label: 'No rounding',
    },
    {
      id: 2,
      label: 'closer 05',
    },
    {
      id: 3,
      label: 'closer 10',
    },
    {
      id: 4,
      label: 'closer 15',
    },
    {
      id: 5,
      label: 'closer 20',
    },
    {
      id: 6,
      label: 'closer 30',
    },
    {
      id: 7,
      label: 'closer 60',
    },
    {
      id: 8,
      label: 'shorter 05',
    },
    {
      id: 9,
      label: 'shorter 10',
    },
    {
      id: 10,
      label: 'shorter 15',
    },
    {
      id: 11,
      label: 'shorter 20',
    },
    {
      id: 12,
      label: 'shorter 30',
    },
    {
      id: 13,
      label: 'shorter 60',
    },
    {
      id: 14,
      label: 'longer 05',
    },
    {
      id: 15,
      label: 'longer 10',
    },
    {
      id: 16,
      label: 'longer 15',
    },
    {
      id: 17,
      label: 'longer 20',
    },
    {
      id: 18,
      label: 'longer 30',
    },
    {
      id: 19,
      label: 'longer 60',
    },
  ],
  breakType: [
    {
      id: 1,
      label: 'Meal Break',
    },
    {
      id: 2,
      label: 'Rest Break',
    },
  ],
  budgetPeriod: [
    {
      id: 1,
      label: 'Daily',
    },
    {
      id: 2,
      label: 'Weekly',
    },
  ],
  budgetedEntity: [
    {
      id: 1,
      label: 'Hour',
    },
    {
      id: 2,
      label: 'Wages',
    },
  ],
  budgetGroupingBy: [
    {
      id: 1,
      label: 'Area',
    },
    {
      id: 2,
      label: 'Location',
    },
  ],
  categoryType: [
    {
      id: 1,
      label: 'Text Box',
    },
    {
      id: 2,
      label: 'Number Box',
    },
    {
      id: 3,
      label: 'Dollar Box',
    },
    {
      id: 4,
      label: 'Percentage Box',
    },
  ],
  coWorkerScheduleVisibility: [
    {
      id: 1,
      label: "Only allow today's schedules",
    },
    {
      id: 2,
      label: 'Allow all',
    },
    {
      id: 3,
      label: 'Allow none. Completely block all visibility',
    },
  ],
  dateFormat: [
    {
      id: 1,
      label: 'dd/mm/yy',
    },
    {
      id: 2,
      label: 'mm/dd/yy',
    },
    {
      id: 3,
      label: 'dd/mmm/yy',
    },
    {
      id: 4,
      label: 'ISO 8601 (yyyy-mm-dd)',
    },
  ],
  employmentType: [
    {
      id: 1,
      label: 'Full time',
    },
    {
      id: 2,
      label: 'Part time',
    },
    {
      id: 3,
      label: 'Casual',
    },
  ],
  exportRuleType: [
    {
      id: 1,
      label: 'Do not export for payroll',
    },
    {
      id: 2,
      label: 'Export using associated Pay Condition',
    },
    {
      id: 3,
      label: 'Export using Export Code',
    },
  ],
  gender: [
    {
      id: 1,
      label: 'Not Specified',
    },
    {
      id: 2,
      label: 'Male',
    },
    {
      id: 3,
      label: 'Female',
    },
    {
      id: 4,
      label: 'Non-binary',
    },
  ],
  hoursPerWorkPeriod: [
    {
      id: 1,
      label: 'Set regular working hours',
    },
    {
      id: 2,
      label: 'Set total hours per period',
    },
  ],
  invitationStatus: [
    {
      id: 1,
      label: 'Accepted',
    },
    {
      id: 2,
      label: 'Declined',
    },
    {
      id: 3,
      label: 'Not Invited',
    },
    {
      id: 4,
      label: 'Waiting Reply',
    },
  ],
  leaveStatus: [
    {
      id: 1,
      label: 'Approved',
    },
    {
      id: 2,
      label: 'Date Approved',
    },
    {
      id: 3,
      label: 'Awaiting',
    },
    {
      id: 4,
      label: 'Pending',
    },
    {
      id: 5,
      label: 'Declined',
    },
    {
      id: 6,
      label: 'Cancelled',
    },
  ],
  leaveType: [
    {
      id: 1,
      label: 'Sick Leave',
    },
    {
      id: 2,
      label: 'HolidayLeave',
    },
    {
      id: 3,
      label: 'Long Service Leave',
    },
    {
      id: 4,
      label: 'Parental Leave',
    },
    {
      id: 5,
      label: 'Bereavement Leave',
    },
    {
      id: 6,
      label: 'Carers Leave',
    },
    {
      id: 7,
      label: 'Unpaid Leave - Sick',
    },
    {
      id: 8,
      label: 'Unpaid Leave - No Show',
    },
    {
      id: 9,
      label: 'Unpaid Leave - Leave',
    },
    {
      id: 10,
      label: 'Time In Lieu',
    },
    {
      id: 11,
      label: 'Training',
    },
    {
      id: 12,
      label: 'Svc Leave',
    },
  ],
  linkUnscheduledTimeSheetType: [
    {
      id: 1,
      label: 'Best Fit (default)',
    },
    {
      id: 2,
      label: 'If timesheet Area matches with scheduled shift Area',
    },
    {
      id: 3,
      label: 'If timesheet Location matches with scheduled shift Location',
    },
    {
      id: 4,
      label: 'If timesheet is within a time variance of a scheduled shift',
    },
  ],
  minimumTimeSheetLengthInMinutes: [
    {
      id: 1,
      label: '1',
    },
    {
      id: 2,
      label: '5',
    },
    {
      id: 3,
      label: '10',
    },
    {
      id: 4,
      label: '15',
    },
  ],
  notifyTo: [
    {
      id: 1,
      label: 'Employee',
    },
    {
      id: 2,
      label: 'Manager',
    },
    {
      id: 3,
      label: 'Both',
    },
  ],
  numberOfPeople: [
    {
      id: 1,
      label: '1 - 15',
    },
    {
      id: 2,
      label: '16 - 25',
    },
    {
      id: 3,
      label: '26 - 49',
    },
    {
      id: 4,
      label: '50 - 249',
    },
    {
      id: 5,
      label: '250 - 749',
    },
    {
      id: 6,
      label: '749+',
    },
  ],
  payRateType: [
    {
      id: 1,
      label: 'Hourly',
    },
    {
      id: 2,
      label: 'Salary',
    },
    {
      id: 3,
      label: 'Library rates',
    },
  ],
  preventShiftChanges: [
    {
      id: 0,
      label: 'never',
    },
    {
      id: 1,
      label: 'after 1 days',
    },
    {
      id: 3,
      label: 'after 3 days',
    },
    {
      id: 7,
      label: 'after 7 days',
    },
  ],
  pronoun: [
    {
      id: 1,
      label: 'He/Him',
    },
    {
      id: 2,
      label: 'She/Her',
    },
    {
      id: 3,
      label: 'They/Them',
    },
    {
      id: 4,
      label: 'Not Specified',
    },
  ],
  ratePerformance: [
    {
      id: 1,
      label: 'Positive',
    },
    {
      id: 2,
      label: 'Negative',
    },
  ],
  resetLeaveAccrual: [
    {
      id: 1,
      label: 'Never Reset',
    },
    {
      id: 2,
      label: 'Reset Annually',
    },
    {
      id: 3,
      label: 'Reset On Work Anniversary',
    },
  ],
  roles: [
    {
      id: 1,
      label: 'System Administrator',
    },
    {
      id: 2,
      label: 'Advisor',
    },
    {
      id: 3,
      label: 'Location Manager',
    },
    {
      id: 4,
      label: 'Supervisor',
    },
    {
      id: 5,
      label: 'Employee',
    },
  ],
  salaryCostAllocation: [
    {
      id: 1,
      label: 'No Allocation',
    },
    {
      id: 2,
      label: 'Days of the Week - fixed days',
    },
    {
      id: 3,
      label: 'Scheduled Hours',
    },
    {
      id: 4,
      label: 'Scheduled Days',
    },
  ],
  salaryPeriod: [
    {
      id: 1,
      label: 'Annual',
    },
    {
      id: 2,
      label: 'Weekly',
    },
  ],
  shiftNotificationRecipient: [
    {
      id: 1,
      label: 'Schedule Creator',
    },
    {
      id: 2,
      label: 'Scheduling Managers of Location',
    },
    {
      id: 3,
      label: 'Scheduling Managers of Location and Working',
    },
    {
      id: 4,
      label: 'Best Fit',
    },
  ],
  shiftRemovedNotification: [
    {
      id: 1,
      label: 'No notifications for shift removal',
    },
    {
      id: 2,
      label: 'Email and smartphones push notifications',
    },
  ],
  shiftType: [
    {
      id: 1,
      label: 'Open Shift',
    },
    {
      id: 2,
      label: 'Empty Shift',
    },
    {
      id: 3,
      label: 'Assigned',
    },
    {
      id: 4,
      label: 'Locked',
    },
  ],
  status: [
    {
      id: 1,
      label: 'Archived',
    },
    {
      id: 2,
      label: 'Employed',
    },
  ],
  timeFormat: [
    {
      id: 1,
      label: 'am/pm',
    },
    {
      id: 2,
      label: '24-Hour',
    },
  ],
  timeRounding: [
    {
      id: 1,
      label: 'No rounding',
    },
    {
      id: 2,
      label: 'closer 5',
    },
    {
      id: 3,
      label: 'closer 10',
    },
    {
      id: 4,
      label: 'closer 15',
    },
    {
      id: 5,
      label: 'closer 20',
    },
    {
      id: 6,
      label: 'closer 30',
    },
    {
      id: 7,
      label: 'closer 60',
    },
    {
      id: 8,
      label: 'earlier 5',
    },
    {
      id: 9,
      label: 'earlier 10',
    },
    {
      id: 10,
      label: 'earlier 15',
    },
    {
      id: 11,
      label: 'earlier 20',
    },
    {
      id: 12,
      label: 'earlier 30',
    },
    {
      id: 13,
      label: 'earlier 60',
    },
    {
      id: 14,
      label: 'later 5',
    },
    {
      id: 15,
      label: 'later 10',
    },
    {
      id: 16,
      label: 'later 15',
    },
    {
      id: 17,
      label: 'later 20',
    },
    {
      id: 18,
      label: 'later 30',
    },
    {
      id: 19,
      label: 'later 60',
    },
  ],
  timeSheetProgressStatus: [
    {
      id: 1,
      label: 'Started',
    },
    {
      id: 2,
      label: 'Submitted',
    },
    {
      id: 3,
      label: 'Pay Approved',
    },
    {
      id: 4,
      label: 'Unsubmitted',
    },
    {
      id: 5,
      label: 'Time Approved',
    },
    {
      id: 6,
      label: 'Possible Absentee',
    },
    {
      id: 7,
      label: 'Future',
    },
    {
      id: 8,
      label: 'Paid',
    },
  ],
  timeSheetStatus: [
    {
      id: 1,
      label: 'Pending',
    },
    {
      id: 2,
      label: 'Approved',
    },
    {
      id: 3,
      label: 'N/A',
    },
    {
      id: 4,
      label: 'Discarded',
    },
  ],
  trackLeaveIn: [
    {
      id: 1,
      label: 'Hours',
    },
    {
      id: 2,
      label: 'Days',
    },
  ],
  turnUnconfirmedOpenShiftsType: [
    {
      id: 1,
      label: 'Not Required',
    },
    {
      id: 2,
      label: '12 Hours before Start Time',
    },
    {
      id: 3,
      label: '24 Hours before Start Time',
    },
    {
      id: 4,
      label: '3 Days before Start Time',
    },
    {
      id: 5,
      label: '1 Week before Start Time',
    },
    {
      id: 6,
      label: '2 Weeks before Start Time',
    },
    {
      id: 7,
      label: '30 Days before Start Time',
    },
  ],
  unscheduledShiftTimeVariance: [
    {
      id: 1,
      label: 'No preference on time',
    },
    {
      id: 2,
      label: 'Match when timesheet is up to 60 minutes earlier than schedule',
    },
    {
      id: 3,
      label: 'Match when timesheet is up to 120 minutes earlier than schedule',
    },
    {
      id: 4,
      label: 'Match when timesheet is up to 240 minutes earlier than schedule',
    },
  ],
  weekDays: [
    {
      id: 1,
      label: 'Monday',
    },
    {
      id: 2,
      label: 'Tuesday',
    },
    {
      id: 3,
      label: 'Wednesday',
    },
    {
      id: 4,
      label: 'Thursday',
    },
    {
      id: 5,
      label: 'Friday',
    },
    {
      id: 6,
      label: 'Saturday',
    },
    {
      id: 7,
      label: 'Sunday',
    },
  ],
  workPeriodLength: [
    {
      id: 1,
      label: 'Weekly',
    },
    {
      id: 2,
      label: '2-weekly',
    },
    {
      id: 3,
      label: '4-weekly',
    },
  ],
  country: [
    {
      id: 1,
      name: 'Afghanistan',
    },
    {
      id: 2,
      name: 'Albania',
    },
    {
      id: 3,
      name: 'Algeria',
    },
    {
      id: 4,
      name: 'American Samoa',
    },
    {
      id: 5,
      name: 'Andorra',
    },
    {
      id: 6,
      name: 'Angola',
    },
    {
      id: 7,
      name: 'Anguilla',
    },
    {
      id: 8,
      name: 'Antarctica',
    },
    {
      id: 9,
      name: 'Antigua and Barbuda',
    },
    {
      id: 10,
      name: 'Argentina',
    },
    {
      id: 11,
      name: 'Armenia',
    },
    {
      id: 12,
      name: 'Aruba',
    },
    {
      id: 13,
      name: 'Australia',
    },
    {
      id: 14,
      name: 'Austria',
    },
    {
      id: 15,
      name: 'Azerbaijan',
    },
    {
      id: 16,
      name: 'Bahamas',
    },
    {
      id: 17,
      name: 'Bahrain',
    },
    {
      id: 18,
      name: 'Bangladesh',
    },
    {
      id: 19,
      name: 'Barbados',
    },
    {
      id: 20,
      name: 'Belarus',
    },
    {
      id: 21,
      name: 'Belgium',
    },
    {
      id: 22,
      name: 'Belize',
    },
    {
      id: 23,
      name: 'Benin',
    },
    {
      id: 24,
      name: 'Bermuda',
    },
    {
      id: 25,
      name: 'Bhutan',
    },
    {
      id: 26,
      name: 'Bosnia and Herzegovina',
    },
    {
      id: 27,
      name: 'Botswana',
    },
    {
      id: 28,
      name: 'Bouvet Island',
    },
    {
      id: 29,
      name: 'Brazil',
    },
    {
      id: 30,
      name: 'British Indian Ocean Territory',
    },
    {
      id: 31,
      name: 'Brunei Darussalam',
    },
    {
      id: 32,
      name: 'Bulgaria',
    },
    {
      id: 33,
      name: 'Burkina Faso',
    },
    {
      id: 34,
      name: 'Burundi',
    },
    {
      id: 35,
      name: 'Cambodia',
    },
    {
      id: 36,
      name: 'Cameroon',
    },
    {
      id: 37,
      name: 'Canada',
    },
    {
      id: 38,
      name: 'Cape Verde',
    },
    {
      id: 39,
      name: 'Cayman Islands',
    },
    {
      id: 40,
      name: 'Central African Republic',
    },
    {
      id: 41,
      name: 'Chad',
    },
    {
      id: 42,
      name: 'Chile',
    },
    {
      id: 43,
      name: 'China',
    },
    {
      id: 44,
      name: 'Christmas Island',
    },
    {
      id: 45,
      name: 'Cocos (Keeling) Islands',
    },
    {
      id: 46,
      name: 'Colombia',
    },
    {
      id: 47,
      name: 'Comoros',
    },
    {
      id: 48,
      name: 'Congo',
    },
    {
      id: 49,
      name: 'Cook Islands',
    },
    {
      id: 50,
      name: 'Costa Rica',
    },
    {
      id: 51,
      name: 'Croatia',
    },
    {
      id: 52,
      name: 'Cuba',
    },
    {
      id: 53,
      name: 'Cyprus',
    },
    {
      id: 54,
      name: 'Czech Republic',
    },
    {
      id: 55,
      name: 'Denmark',
    },
    {
      id: 56,
      name: 'Djibouti',
    },
    {
      id: 57,
      name: 'Dominica',
    },
    {
      id: 58,
      name: 'Dominican Republic',
    },
    {
      id: 59,
      name: 'Ecuador',
    },
    {
      id: 60,
      name: 'Egypt',
    },
    {
      id: 61,
      name: 'El Salvador',
    },
    {
      id: 62,
      name: 'Equatorial Guinea',
    },
    {
      id: 63,
      name: 'Eritrea',
    },
    {
      id: 64,
      name: 'Estonia',
    },
    {
      id: 65,
      name: 'Ethiopia',
    },
    {
      id: 66,
      name: 'Falkland Islands (Malvinas)',
    },
    {
      id: 67,
      name: 'Faroe Islands',
    },
    {
      id: 68,
      name: 'Fiji',
    },
    {
      id: 69,
      name: 'Finland',
    },
    {
      id: 70,
      name: 'France',
    },
    {
      id: 71,
      name: 'French Guiana',
    },
    {
      id: 72,
      name: 'French Polynesia',
    },
    {
      id: 73,
      name: 'French Southern Territories',
    },
    {
      id: 74,
      name: 'Gabon',
    },
    {
      id: 75,
      name: 'Gambia',
    },
    {
      id: 76,
      name: 'Georgia',
    },
    {
      id: 77,
      name: 'Germany',
    },
    {
      id: 78,
      name: 'Ghana',
    },
    {
      id: 79,
      name: 'Gibraltar',
    },
    {
      id: 80,
      name: 'Greece',
    },
    {
      id: 81,
      name: 'Greenland',
    },
    {
      id: 82,
      name: 'Grenada',
    },
    {
      id: 83,
      name: 'Guadeloupe',
    },
    {
      id: 84,
      name: 'Guam',
    },
    {
      id: 85,
      name: 'Guatemala',
    },
    {
      id: 86,
      name: 'Guernsey',
    },
    {
      id: 87,
      name: 'Guinea',
    },
    {
      id: 88,
      name: 'Guinea-Bissau',
    },
    {
      id: 89,
      name: 'Guyana',
    },
    {
      id: 90,
      name: 'Haiti',
    },
    {
      id: 91,
      name: 'Heard Island and McDonald Islands',
    },
    {
      id: 92,
      name: 'Holy See (Vatican City State)',
    },
    {
      id: 93,
      name: 'Honduras',
    },
    {
      id: 94,
      name: 'Hong Kong',
    },
    {
      id: 95,
      name: 'Hungary',
    },
    {
      id: 96,
      name: 'Iceland',
    },
    {
      id: 97,
      name: 'India',
    },
    {
      id: 98,
      name: 'Indonesia',
    },
    {
      id: 99,
      name: 'Iraq',
    },
    {
      id: 100,
      name: 'Ireland',
    },
    {
      id: 101,
      name: 'Isle of Man',
    },
    {
      id: 102,
      name: 'Israel',
    },
    {
      id: 103,
      name: 'Italy',
    },
    {
      id: 104,
      name: 'Jamaica',
    },
    {
      id: 105,
      name: 'Japan',
    },
    {
      id: 106,
      name: 'Jersey',
    },
    {
      id: 107,
      name: 'Jordan',
    },
    {
      id: 108,
      name: 'Kazakhstan',
    },
    {
      id: 109,
      name: 'Kenya',
    },
    {
      id: 110,
      name: 'Kiribati',
    },
    {
      id: 111,
      name: 'Kuwait',
    },
    {
      id: 112,
      name: 'Kyrgyzstan',
    },
    {
      id: 113,
      name: 'Lao Peoples Democratic Republic',
    },
    {
      id: 114,
      name: 'Latvia',
    },
    {
      id: 115,
      name: 'Lebanon',
    },
    {
      id: 116,
      name: 'Lesotho',
    },
    {
      id: 117,
      name: 'Liberia',
    },
    {
      id: 118,
      name: 'Libya',
    },
    {
      id: 119,
      name: 'Liechtenstein',
    },
    {
      id: 120,
      name: 'Lithuania',
    },
    {
      id: 121,
      name: 'Luxembourg',
    },
    {
      id: 122,
      name: 'Macao',
    },
    {
      id: 123,
      name: 'Madagascar',
    },
    {
      id: 124,
      name: 'Malawi',
    },
    {
      id: 125,
      name: 'Malaysia',
    },
    {
      id: 126,
      name: 'Maldives',
    },
    {
      id: 127,
      name: 'Mali',
    },
    {
      id: 128,
      name: 'Malta',
    },
    {
      id: 129,
      name: 'Marshall Islands',
    },
    {
      id: 130,
      name: 'Martinique',
    },
    {
      id: 131,
      name: 'Mauritania',
    },
    {
      id: 132,
      name: 'Mauritius',
    },
    {
      id: 133,
      name: 'Mayotte',
    },
    {
      id: 134,
      name: 'Mexico',
    },
    {
      id: 135,
      name: 'Monaco',
    },
    {
      id: 136,
      name: 'Mongolia',
    },
    {
      id: 137,
      name: 'Montenegro',
    },
    {
      id: 138,
      name: 'Montserrat',
    },
    {
      id: 139,
      name: 'Morocco',
    },
    {
      id: 140,
      name: 'Mozambique',
    },
    {
      id: 141,
      name: 'Myanmar',
    },
    {
      id: 142,
      name: 'Namibia',
    },
    {
      id: 143,
      name: 'Nauru',
    },
    {
      id: 144,
      name: 'Nepal',
    },
    {
      id: 145,
      name: 'Netherlands',
    },
    {
      id: 146,
      name: 'New Caledonia',
    },
    {
      id: 147,
      name: 'New Zealand',
    },
    {
      id: 148,
      name: 'Nicaragua',
    },
    {
      id: 149,
      name: 'Niger',
    },
    {
      id: 150,
      name: 'Nigeria',
    },
    {
      id: 151,
      name: 'Niue',
    },
    {
      id: 152,
      name: 'Norfolk Island',
    },
    {
      id: 153,
      name: 'Northern Mariana Islands',
    },
    {
      id: 154,
      name: 'Norway',
    },
    {
      id: 155,
      name: 'Oman',
    },
    {
      id: 156,
      name: 'Pakistan',
    },
    {
      id: 157,
      name: 'Palau',
    },
    {
      id: 158,
      name: 'Panama',
    },
    {
      id: 159,
      name: 'Papua New Guinea',
    },
    {
      id: 160,
      name: 'Paraguay',
    },
    {
      id: 161,
      name: 'Peru',
    },
    {
      id: 162,
      name: 'Philippines',
    },
    {
      id: 163,
      name: 'Pitcairn',
    },
    {
      id: 164,
      name: 'Poland',
    },
    {
      id: 165,
      name: 'Portugal',
    },
    {
      id: 166,
      name: 'Puerto Rico',
    },
    {
      id: 167,
      name: 'Qatar',
    },
    {
      id: 168,
      name: 'Romania',
    },
    {
      id: 169,
      name: 'Russian Federation',
    },
    {
      id: 170,
      name: 'Rwanda',
    },
    {
      id: 171,
      name: 'Saint Kitts and Nevis',
    },
    {
      id: 172,
      name: 'Saint Lucia',
    },
    {
      id: 173,
      name: 'Saint Martin (French part)',
    },
    {
      id: 174,
      name: 'Saint Pierre and Miquelon',
    },
    {
      id: 175,
      name: 'Saint Vincent and the Grenadines',
    },
    {
      id: 176,
      name: 'Samoa',
    },
    {
      id: 177,
      name: 'San Marino',
    },
    {
      id: 178,
      name: 'Sao Tome and Principe',
    },
    {
      id: 179,
      name: 'Saudi Arabia',
    },
    {
      id: 180,
      name: 'Senegal',
    },
    {
      id: 181,
      name: 'Serbia',
    },
    {
      id: 182,
      name: 'Seychelles',
    },
    {
      id: 183,
      name: 'Sierra Leone',
    },
    {
      id: 184,
      name: 'Singapore',
    },
    {
      id: 185,
      name: 'Sint Maarten (Dutch part)',
    },
    {
      id: 186,
      name: 'Slovakia',
    },
    {
      id: 187,
      name: 'Slovenia',
    },
    {
      id: 188,
      name: 'Solomon Islands',
    },
    {
      id: 189,
      name: 'Somalia',
    },
    {
      id: 190,
      name: 'South Africa',
    },
    {
      id: 191,
      name: 'South Georgia and the South Sandwich Islands',
    },
    {
      id: 192,
      name: 'South Sudan',
    },
    {
      id: 193,
      name: 'Spain',
    },
    {
      id: 194,
      name: 'Sri Lanka',
    },
    {
      id: 195,
      name: 'Sudan',
    },
    {
      id: 196,
      name: 'Suriname',
    },
    {
      id: 197,
      name: 'Svalbard and Jan Mayen',
    },
    {
      id: 198,
      name: 'Swaziland',
    },
    {
      id: 199,
      name: 'Sweden',
    },
    {
      id: 200,
      name: 'Switzerland',
    },
    {
      id: 201,
      name: 'Syrian Arab Republic',
    },
    {
      id: 202,
      name: 'Tajikistan',
    },
    {
      id: 203,
      name: 'Thailand',
    },
    {
      id: 204,
      name: 'Timor-Leste',
    },
    {
      id: 205,
      name: 'Togo',
    },
    {
      id: 206,
      name: 'Tokelau',
    },
    {
      id: 207,
      name: 'Tonga',
    },
    {
      id: 208,
      name: 'Trinidad and Tobago',
    },
    {
      id: 209,
      name: 'Tunisia',
    },
    {
      id: 210,
      name: 'Turkey',
    },
    {
      id: 211,
      name: 'Turkmenistan',
    },
    {
      id: 212,
      name: 'Turks and Caicos Islands',
    },
    {
      id: 213,
      name: 'Tuvalu',
    },
    {
      id: 214,
      name: 'Uganda',
    },
    {
      id: 215,
      name: 'Ukraine',
    },
    {
      id: 216,
      name: 'United Arab Emirates',
    },
    {
      id: 217,
      name: 'United Kingdom',
    },
    {
      id: 218,
      name: 'United States',
    },
    {
      id: 219,
      name: 'United States Minor Outlying Islands',
    },
    {
      id: 220,
      name: 'Uruguay',
    },
    {
      id: 221,
      name: 'Uzbekistan',
    },
    {
      id: 222,
      name: 'Vanuatu',
    },
    {
      id: 223,
      name: 'Viet Nam',
    },
    {
      id: 224,
      name: 'Wallis and Futuna',
    },
    {
      id: 225,
      name: 'Western Sahara',
    },
    {
      id: 226,
      name: 'Yemen',
    },
    {
      id: 227,
      name: 'Zambia',
    },
    {
      id: 228,
      name: 'Zimbabwe',
    },
  ],
  businessIndustryType: [
    {
      id: 1,
      label: 'Veterinary clinic',
    },
    {
      id: 2,
      label: 'Dental clinic',
    },
    {
      id: 3,
      label: 'Primary care physician',
    },
    {
      id: 4,
      label: 'Outpatient care centers',
    },
    {
      id: 5,
      label: 'Speciality clinics/Practitioners',
    },
    {
      id: 6,
      label: 'Care facility',
    },
    {
      id: 7,
      label: 'In-Home care',
    },
    {
      id: 8,
      label: 'Hospitals',
    },
    {
      id: 9,
      label: 'Pharmacies/Drug stores',
    },
    {
      id: 10,
      label: 'Fast food / Cashier restaurants',
    },
    {
      id: 11,
      label: 'Cafes / Coffee shops',
    },
    {
      id: 12,
      label: 'Sit down restaurants',
    },
    {
      id: 13,
      label: 'Pharmacies & drug stores',
    },
    {
      id: 14,
      label: 'Home, hardware & garden stores',
    },
    {
      id: 15,
      label: 'Clothing & personal care stores',
    },
    {
      id: 16,
      label: 'Bar / Club',
    },
    {
      id: 17,
      label: 'Food & beverage stores',
    },
    {
      id: 18,
      label: 'Auto, electronics & appliances stores',
    },
    {
      id: 19,
      label: 'Accomodation',
    },
    {
      id: 20,
      label: 'Catering',
    },
    {
      id: 21,
      label: 'Hospitality other',
    },
    {
      id: 22,
      label: 'Retail other',
    },
    {
      id: 23,
      label: 'Childcare centers',
    },
    {
      id: 24,
      label: 'Security services',
    },
    {
      id: 25,
      label: 'Cleaning services',
    },
    {
      id: 26,
      label: 'Call centers',
    },
    {
      id: 27,
      label: 'Delivery & postal services',
    },
    {
      id: 28,
      label: 'Critical & emergency services',
    },
    {
      id: 29,
      label: 'Professional services',
    },
    {
      id: 30,
      label: 'Personal & beauty services',
    },
    {
      id: 31,
      label: 'Employment services',
    },
    {
      id: 32,
      label: 'Services other',
    },
    {
      id: 33,
      label: 'Gyms',
    },
    {
      id: 34,
      label: 'Arts, entertainment & recreation',
    },
    {
      id: 35,
      label: 'Construction',
    },
    {
      id: 36,
      label: 'Education',
    },
    {
      id: 37,
      label: 'Manufacturing',
    },
    {
      id: 38,
      label: 'Transportation',
    },
    {
      id: 39,
      label: 'Government',
    },
    {
      id: 40,
      label: 'Warehousing & storage',
    },
    {
      id: 41,
      label: 'Logistics, distribution & freight',
    },
    {
      id: 42,
      label: 'All other',
    },
  ],
  dashboardShiftStatus: [
    {
      id: 1,
      label: 'Open',
    },
    {
      id: 2,
      label: 'Offer pending',
    },
    {
      id: 3,
      label: 'Pending approval',
    },
    {
      id: 4,
      label: 'Approved',
    },
    {
      id: 5,
      label: 'Swap Requested',
    },
    {
      id: 6,
      label: 'Swap Offers Received',
    },
  ],
  sortingRequestOpenShift: [
    {
      id: 1,
      label: 'First To Request',
    },
    {
      id: 2,
      label: 'A-Z',
    },
    {
      id: 3,
      label: 'Cost',
    },
  ],
  repeatShift: [
    {
      id: 1,
      label: 'Repeat For Tomorrow',
    },
    {
      id: 2,
      label: 'Repeat for rest of the week',
    },
    {
      id: 3,
      label: 'Repeat for specific days',
    },
  ],
  notifyType: [
    {
      id: 1,
      label: 'Marked as published (team members not notified)',
    },
    {
      id: 2,
      label: 'Notify (email and app only)',
    },
  ],
  shiftAction: [
    {
      id: 1,
      label: 'Make shift open',
    },
    {
      id: 2,
      label: 'Make shift empty',
    },
    {
      id: 3,
      label: 'Keep it as it is',
    },
  ],
  shiftErrorReasonType: [
    {
      id: 1,
      label: 'Overlaping',
    },
    {
      id: 2,
      label: 'Swap Pending',
    },
    {
      id: 3,
      label: 'On leave',
    },
    {
      id: 4,
      label: 'Person Inactive',
    },
  ],
  shiftBulkActions: [
    {
      id: 1,
      label: 'Mark filled shifts empty',
    },
    {
      id: 2,
      label: 'Remove empty shifts',
    },
    {
      id: 3,
      label: 'Delete all shifts',
    },
    {
      id: 4,
      label: 'Mark empty shifts as open',
    },
  ],
  dashboardPanelDisplay: [
    {
      id: 1,
      label: 'Needs Attention',
    },
    {
      id: 2,
      label: 'Needs Approval',
    },
    {
      id: 3,
      label: "What's Happening",
    },
  ],
  breakState: [
    {
      id: 1,
      label: 'Started',
    },
    {
      id: 2,
      label: 'Finished',
    },
    {
      id: 3,
      label: 'Scheduled',
    },
  ],
  planDuration: [
    {
      id: 1,
      label: 'Monthly',
    },
    {
      id: 2,
      label: 'Yearly',
    },
  ],
  redirectionScreen: [
    {
      id: 1,
      label: 'People',
    },
    {
      id: 2,
      label: 'GlobalProfile',
    },
    {
      id: 3,
      label: 'Deshboard',
    },
    {
      id: 4,
      label: 'Calendar',
    },
    {
      id: 5,
      label: 'Schedule',
    },
    {
      id: 6,
      label: 'TimeSheet',
    },
  ],
  metricType: [
    {
      id: 1,
      label: 'Currency',
    },
    {
      id: 2,
      label: 'Unit',
    },
  ],
  metricForecastingType: [
    {
      id: 1,
      label: 'Automatic forecast',
    },
    {
      id: 2,
      label: 'Manager forecast',
    },
    {
      id: 3,
      label: 'Both',
    },
  ],
  levelOfDetail: [
    {
      id: 1,
      label: 'Hourly',
    },
    {
      id: 2,
      label: '15-mins',
    },
  ],
  metricForecastBasedOnEntity: [
    {
      id: 1,
      label: 'Average of all time',
    },
    {
      id: 2,
      label: 'Average of last 2 period',
    },
    {
      id: 3,
      label: 'Average of last 4 period',
    },
    {
      id: 4,
      label: 'Average of last 6 period',
    },
    {
      id: 5,
      label: 'Average of last 12 period',
    },
    {
      id: 6,
      label: 'Same period last year',
    },
  ],
  coverageType: [
    {
      id: 1,
      label: 'Linear',
    },
    {
      id: 2,
      label: 'Range',
    },
  ],
  statsPanelOption: [
    {
      id: 1,
      label: 'Hours',
    },
    {
      id: 2,
      label: 'Wages',
    },
    {
      id: 3,
      label: 'Sales',
    },
    {
      id: 4,
      label: 'Labor',
    },
    {
      id: 5,
      label: 'Sales per hour',
    },
    {
      id: 6,
      label: 'Weekly summary (graph)',
    },
  ],
};

export const BUSINESS_TYPE_ENUM = [
  {
    id: 19,
    name: 'Accomodation',
  },
  {
    id: 42,
    name: 'All other',
  },
  {
    id: 34,
    name: 'Arts, entertainment & recreation',
  },
  {
    id: 18,
    name: 'Auto, electronics & appliances stores',
  },
  {
    id: 16,
    name: 'Bar / Club',
  },
  {
    id: 11,
    name: 'Cafes / Coffee shops',
  },
  {
    id: 26,
    name: 'Call centers',
  },
  {
    id: 6,
    name: 'Care facility',
  },
  {
    id: 20,
    name: 'Catering',
  },
  {
    id: 23,
    name: 'Childcare centers',
  },
  {
    id: 25,
    name: 'Cleaning services',
  },
  {
    id: 15,
    name: 'Clothing & personal care stores',
  },
  {
    id: 35,
    name: 'Construction',
  },
  {
    id: 28,
    name: 'Critical & emergency services',
  },
  {
    id: 27,
    name: 'Delivery & postal services',
  },
  {
    id: 2,
    name: 'Dental clinic',
  },
  {
    id: 36,
    name: 'Education',
  },
  {
    id: 31,
    name: 'Employment services',
  },
  {
    id: 10,
    name: 'Fast food / Cashier restaurants',
  },
  {
    id: 17,
    name: 'Food & beverage stores',
  },
  {
    id: 39,
    name: 'Government',
  },
  {
    id: 33,
    name: 'Gyms',
  },
  {
    id: 14,
    name: 'Home, hardware & garden stores',
  },
  {
    id: 21,
    name: 'Hospitality other',
  },
  {
    id: 8,
    name: 'Hospitals',
  },
  {
    id: 7,
    name: 'In-Home care',
  },
  {
    id: 41,
    name: 'Logistics, distribution & freight',
  },
  {
    id: 37,
    name: 'Manufacturing',
  },
  {
    id: 4,
    name: 'Outpatient care centers',
  },
  {
    id: 30,
    name: 'Personal & beauty services',
  },
  {
    id: 13,
    name: 'Pharmacies & drug stores',
  },
  {
    id: 9,
    name: 'Pharmacies/Drug stores',
  },
  {
    id: 3,
    name: 'Primary care physician',
  },
  {
    id: 29,
    name: 'Professional services',
  },
  {
    id: 22,
    name: 'Retail other',
  },
  {
    id: 24,
    name: 'Security services',
  },
  {
    id: 32,
    name: 'Services other',
  },
  {
    id: 12,
    name: 'Sit down restaurants',
  },
  {
    id: 5,
    name: 'Speciality clinics/Practitioners',
  },
  {
    id: 38,
    name: 'Transportation',
  },
  {
    id: 1,
    name: 'Veterinary clinic',
  },
  {
    id: 40,
    name: 'Warehousing & storage',
  },
];

export const COUNTRY_LIST = [
  {
    id: 1,
    name: 'Afghanistan',
    code: 'af',
  },
  {
    id: 2,
    name: 'Albania',
    code: 'al',
  },
  {
    id: 3,
    name: 'Algeria',
    code: 'dz',
  },
  {
    id: 4,
    name: 'American Samoa',
    code: 'as',
  },
  {
    id: 5,
    name: 'Andorra',
    code: 'ad',
  },
  {
    id: 6,
    name: 'Angola',
    code: 'ao',
  },
  {
    id: 7,
    name: 'Anguilla',
    code: 'ai',
  },
  {
    id: 8,
    name: 'Antarctica',
    code: 'aq',
  },
  {
    id: 9,
    name: 'Antigua and Barbuda',
    code: 'ag',
  },
  {
    id: 10,
    name: 'Argentina',
    code: 'ar',
  },
  {
    id: 11,
    name: 'Armenia',
    code: 'am',
  },
  {
    id: 12,
    name: 'Aruba',
    code: 'aw',
  },
  {
    id: 13,
    name: 'Australia',
    code: 'au',
  },
  {
    id: 14,
    name: 'Austria',
    code: 'at',
  },
  {
    id: 15,
    name: 'Azerbaijan',
    code: 'az',
  },
  {
    id: 16,
    name: 'Bahamas',
    code: 'bs',
  },
  {
    id: 17,
    name: 'Bahrain',
    code: 'bh',
  },
  {
    id: 18,
    name: 'Bangladesh',
    code: 'bd',
  },
  {
    id: 19,
    name: 'Barbados',
    code: 'bb',
  },
  {
    id: 20,
    name: 'Belarus',
    code: 'by',
  },
  {
    id: 21,
    name: 'Belgium',
    code: 'be',
  },
  {
    id: 22,
    name: 'Belize',
    code: 'bz',
  },
  {
    id: 23,
    name: 'Benin',
    code: 'bj',
  },
  {
    id: 24,
    name: 'Bermuda',
    code: 'bm',
  },
  {
    id: 25,
    name: 'Bhutan',
    code: 'bt',
  },
  {
    id: 26,
    name: 'Bosnia and Herzegovina',
    code: 'ba',
  },
  {
    id: 27,
    name: 'Botswana',
    code: 'bw',
  },
  {
    id: 28,
    name: 'Bouvet Island',
    code: 'bv',
  },
  {
    id: 29,
    name: 'Brazil',
    code: 'br',
  },
  {
    id: 30,
    name: 'British Indian Ocean Territory',
    code: 'io',
  },
  {
    id: 31,
    name: 'Brunei Darussalam',
    code: 'bn',
  },
  {
    id: 32,
    name: 'Bulgaria',
    code: 'bg',
  },
  {
    id: 33,
    name: 'Burkina Faso',
    code: 'bf',
  },
  {
    id: 34,
    name: 'Burundi',
    code: 'bi',
  },
  {
    id: 35,
    name: 'Cambodia',
    code: 'kh',
  },
  {
    id: 36,
    name: 'Cameroon',
    code: 'cm',
  },
  {
    id: 37,
    name: 'Canada',
    code: 'ca',
  },
  {
    id: 38,
    name: 'Cape Verde',
    code: 'cv',
  },
  {
    id: 39,
    name: 'Cayman Islands',
    code: 'ky',
  },
  {
    id: 40,
    name: 'Central African Republic',
    code: 'cf',
  },
  {
    id: 41,
    name: 'Chad',
    code: 'td',
  },
  {
    id: 42,
    name: 'Chile',
    code: 'cl',
  },
  {
    id: 43,
    name: 'China',
    code: 'cn',
  },
  {
    id: 44,
    name: 'Christmas Island',
    code: 'cx',
  },
  {
    id: 45,
    name: 'Cocos (Keeling) Islands',
    code: 'cc',
  },
  {
    id: 46,
    name: 'Colombia',
    code: 'co',
  },
  {
    id: 47,
    name: 'Comoros',
    code: 'km',
  },
  {
    id: 48,
    name: 'Congo',
    code: 'cg',
  },
  {
    id: 49,
    name: 'Cook Islands',
    code: 'ck',
  },
  {
    id: 50,
    name: 'Costa Rica',
    code: 'cr',
  },
  {
    id: 51,
    name: 'Croatia',
    code: 'hr',
  },
  {
    id: 52,
    name: 'Cuba',
    code: 'cu',
  },
  {
    id: 53,
    name: 'Cyprus',
    code: 'cy',
  },
  {
    id: 54,
    name: 'Czech Republic',
    code: 'cz',
  },
  {
    id: 55,
    name: 'Denmark',
    code: 'dk',
  },
  {
    id: 56,
    name: 'Djibouti',
    code: 'dj',
  },
  {
    id: 57,
    name: 'Dominica',
    code: 'dm',
  },
  {
    id: 58,
    name: 'Dominican Republic',
    code: 'do',
  },
  {
    id: 59,
    name: 'Ecuador',
    code: 'ec',
  },
  {
    id: 60,
    name: 'Egypt',
    code: 'eg',
  },
  {
    id: 61,
    name: 'El Salvador',
    code: 'sv',
  },
  {
    id: 62,
    name: 'Equatorial Guinea',
    code: 'gq',
  },
  {
    id: 63,
    name: 'Eritrea',
    code: 'er',
  },
  {
    id: 64,
    name: 'Estonia',
    code: 'ee',
  },
  {
    id: 65,
    name: 'Ethiopia',
    code: 'et',
  },
  {
    id: 66,
    name: 'Falkland Islands (Malvinas)',
    code: 'fk',
  },
  {
    id: 67,
    name: 'Faroe Islands',
    code: 'fo',
  },
  {
    id: 68,
    name: 'Fiji',
    code: 'fj',
  },
  {
    id: 69,
    name: 'Finland',
    code: 'fi',
  },
  {
    id: 70,
    name: 'France',
    code: 'fr',
  },
  {
    id: 71,
    name: 'French Guiana',
    code: 'gf',
  },
  {
    id: 72,
    name: 'French Polynesia',
    code: 'pf',
  },
  {
    id: 73,
    name: 'French Southern Territories',
    code: 'tf',
  },
  {
    id: 74,
    name: 'Gabon',
    code: 'ga',
  },
  {
    id: 75,
    name: 'Gambia',
    code: 'gm',
  },
  {
    id: 76,
    name: 'Georgia',
    code: 'ge',
  },
  {
    id: 77,
    name: 'Germany',
    code: 'de',
  },
  {
    id: 78,
    name: 'Ghana',
    code: 'gh',
  },
  {
    id: 79,
    name: 'Gibraltar',
    code: 'gi',
  },
  {
    id: 80,
    name: 'Greece',
    code: 'gr',
  },
  {
    id: 81,
    name: 'Greenland',
    code: 'gl',
  },
  {
    id: 82,
    name: 'Grenada',
    code: 'gd',
  },
  {
    id: 83,
    name: 'Guadeloupe',
    code: 'gp',
  },
  {
    id: 84,
    name: 'Guam',
    code: 'gu',
  },
  {
    id: 85,
    name: 'Guatemala',
    code: 'gt',
  },
  {
    id: 86,
    name: 'Guernsey',
    code: 'gg',
  },
  {
    id: 87,
    name: 'Guinea',
    code: 'gn',
  },
  {
    id: 88,
    name: 'Guinea-Bissau',
    code: 'gw',
  },
  {
    id: 89,
    name: 'Guyana',
    code: 'gy',
  },
  {
    id: 90,
    name: 'Haiti',
    code: 'ht',
  },
  {
    id: 91,
    name: 'Heard Island and McDonald Islands',
    code: 'hm',
  },
  {
    id: 92,
    name: 'Holy See (Vatican City State)',
    code: 'va',
  },
  {
    id: 93,
    name: 'Honduras',
    code: 'hn',
  },
  {
    id: 94,
    name: 'Hong Kong',
    code: 'hk',
  },
  {
    id: 95,
    name: 'Hungary',
    code: 'hu',
  },
  {
    id: 96,
    name: 'Iceland',
    code: 'is',
  },
  {
    id: 97,
    name: 'India',
    code: 'in',
  },
  {
    id: 98,
    name: 'Indonesia',
    code: 'id',
  },
  {
    id: 99,
    name: 'Iraq',
    code: 'iq',
  },
  {
    id: 100,
    name: 'Ireland',
    code: 'ie',
  },
  {
    id: 101,
    name: 'Isle of Man',
    code: 'im',
  },
  {
    id: 102,
    name: 'Israel',
    code: 'il',
  },
  {
    id: 103,
    name: 'Italy',
    code: 'it',
  },
  {
    id: 104,
    name: 'Jamaica',
    code: 'jm',
  },
  {
    id: 105,
    name: 'Japan',
    code: 'jp',
  },
  {
    id: 106,
    name: 'Jersey',
    code: 'je',
  },
  {
    id: 107,
    name: 'Jordan',
    code: 'jo',
  },
  {
    id: 108,
    name: 'Kazakhstan',
    code: 'kz',
  },
  {
    id: 109,
    name: 'Kenya',
    code: 'ke',
  },
  {
    id: 110,
    name: 'Kiribati',
    code: 'ki',
  },
  {
    id: 111,
    name: 'Kuwait',
    code: 'kw',
  },
  {
    id: 112,
    name: 'Kyrgyzstan',
    code: 'kg',
  },
  {
    id: 113,
    name: 'Lao Peoples Democratic Republic',
    code: 'la',
  },
  {
    id: 114,
    name: 'Latvia',
    code: 'lv',
  },
  {
    id: 115,
    name: 'Lebanon',
    code: 'lb',
  },
  {
    id: 116,
    name: 'Lesotho',
    code: 'ls',
  },
  {
    id: 117,
    name: 'Liberia',
    code: 'lr',
  },
  {
    id: 118,
    name: 'Libya',
    code: 'ly',
  },
  {
    id: 119,
    name: 'Liechtenstein',
    code: 'li',
  },
  {
    id: 120,
    name: 'Lithuania',
    code: 'lt',
  },
  {
    id: 121,
    name: 'Luxembourg',
    code: 'lu',
  },
  {
    id: 122,
    name: 'Macao',
    code: 'mo',
  },
  {
    id: 123,
    name: 'Madagascar',
    code: 'mg',
  },
  {
    id: 124,
    name: 'Malawi',
    code: 'mw',
  },
  {
    id: 125,
    name: 'Malaysia',
    code: 'my',
  },
  {
    id: 126,
    name: 'Maldives',
    code: 'mv',
  },
  {
    id: 127,
    name: 'Mali',
    code: 'ml',
  },
  {
    id: 128,
    name: 'Malta',
    code: 'mt',
  },
  {
    id: 129,
    name: 'Marshall Islands',
    code: 'mh',
  },
  {
    id: 130,
    name: 'Martinique',
    code: 'mq',
  },
  {
    id: 131,
    name: 'Mauritania',
    code: 'mr',
  },
  {
    id: 132,
    name: 'Mauritius',
    code: 'mu',
  },
  {
    id: 133,
    name: 'Mayotte',
    code: 'yt',
  },
  {
    id: 134,
    name: 'Mexico',
    code: 'mx',
  },
  {
    id: 135,
    name: 'Monaco',
    code: 'mc',
  },
  {
    id: 136,
    name: 'Mongolia',
    code: 'mn',
  },
  {
    id: 137,
    name: 'Montenegro',
    code: 'me',
  },
  {
    id: 138,
    name: 'Montserrat',
    code: 'me',
  },
  {
    id: 139,
    name: 'Morocco',
    code: 'ma',
  },
  {
    id: 140,
    name: 'Mozambique',
    code: 'mz',
  },
  {
    id: 141,
    name: 'Myanmar',
    code: 'mm',
  },
  {
    id: 142,
    name: 'Namibia',
    code: 'na',
  },
  {
    id: 143,
    name: 'Nauru',
    code: 'nr',
  },
  {
    id: 144,
    name: 'Nepal',
    code: 'np',
  },
  {
    id: 145,
    name: 'Netherlands',
    code: 'nl',
  },
  {
    id: 146,
    name: 'New Caledonia',
    code: 'nc',
  },
  {
    id: 147,
    name: 'New Zealand',
    code: 'nz',
  },
  {
    id: 148,
    name: 'Nicaragua',
    code: 'ni',
  },
  {
    id: 149,
    name: 'Niger',
    code: 'ne',
  },
  {
    id: 150,
    name: 'Nigeria',
    code: 'ng',
  },
  {
    id: 151,
    name: 'Niue',
    code: 'nu',
  },
  {
    id: 152,
    name: 'Norfolk Island',
    code: 'nf',
  },
  {
    id: 153,
    name: 'Northern Mariana Islands',
    code: 'mp',
  },
  {
    id: 154,
    name: 'Norway',
    code: 'no',
  },
  {
    id: 155,
    name: 'Oman',
    code: 'om',
  },
  {
    id: 156,
    name: 'Pakistan',
    code: 'pk',
  },
  {
    id: 157,
    name: 'Palau',
    code: 'pw',
  },
  {
    id: 158,
    name: 'Panama',
    code: 'pa',
  },
  {
    id: 159,
    name: 'Papua New Guinea',
    code: 'pg',
  },
  {
    id: 160,
    name: 'Paraguay',
    code: 'py',
  },
  {
    id: 161,
    name: 'Peru',
    code: 'pe',
  },
  {
    id: 162,
    name: 'Philippines',
    code: 'ph',
  },
  {
    id: 163,
    name: 'Pitcairn',
    code: 'pn',
  },
  {
    id: 164,
    name: 'Poland',
    code: 'pl',
  },
  {
    id: 165,
    name: 'Portugal',
    code: 'pt',
  },
  {
    id: 166,
    name: 'Puerto Rico',
    code: 'pr',
  },
  {
    id: 167,
    name: 'Qatar',
    code: 'qa',
  },
  {
    id: 168,
    name: 'Romania',
    code: 'ro',
  },
  {
    id: 169,
    name: 'Russian Federation',
    code: 'ru',
  },
  {
    id: 170,
    name: 'Rwanda',
    code: 'rw',
  },
  {
    id: 171,
    name: 'Saint Kitts and Nevis',
    code: 're',
  },
  {
    id: 172,
    name: 'Saint Lucia',
    code: 'bl',
  },
  {
    id: 173,
    name: 'Saint Martin (French part)',
    code: 'mf',
  },
  {
    id: 174,
    name: 'Saint Pierre and Miquelon',
    code: 'pm',
  },
  {
    id: 175,
    name: 'Saint Vincent and the Grenadines',
    code: 'vc',
  },
  {
    id: 176,
    name: 'Samoa',
    code: 'ws',
  },
  {
    id: 177,
    name: 'San Marino',
    code: 'sm',
  },
  {
    id: 178,
    name: 'Sao Tome and Principe',
    code: 'st',
  },
  {
    id: 179,
    name: 'Saudi Arabia',
    code: 'sa',
  },
  {
    id: 180,
    name: 'Senegal',
    code: 'sn',
  },
  {
    id: 181,
    name: 'Serbia',
    code: 'rs',
  },
  {
    id: 182,
    name: 'Seychelles',
    code: 'sc',
  },
  {
    id: 183,
    name: 'Sierra Leone',
    code: 'sl',
  },
  {
    id: 184,
    name: 'Singapore',
    code: 'sg',
  },
  {
    id: 185,
    name: 'Sint Maarten (Dutch part)',
    code: 'sx',
  },
  {
    id: 186,
    name: 'Slovakia',
    code: 'sk',
  },
  {
    id: 187,
    name: 'Slovenia',
    code: 'si',
  },
  {
    id: 188,
    name: 'Solomon Islands',
    code: 'sb',
  },
  {
    id: 189,
    name: 'Somalia',
    code: 'so',
  },
  {
    id: 190,
    name: 'South Africa',
    code: 'za',
  },
  {
    id: 191,
    name: 'South Georgia and the South Sandwich Islands',
    code: 'gs',
  },
  {
    id: 192,
    name: 'South Sudan',
    code: 'ss',
  },
  {
    id: 193,
    name: 'Spain',
    code: 'es',
  },
  {
    id: 194,
    name: 'Sri Lanka',
    code: 'lk',
  },
  {
    id: 195,
    name: 'Sudan',
    code: 'sd',
  },
  {
    id: 196,
    name: 'Suriname',
    code: 'sr',
  },
  {
    id: 197,
    name: 'Svalbard and Jan Mayen',
    code: 'sj',
  },
  {
    id: 198,
    name: 'Swaziland',
    code: 'sz',
  },
  {
    id: 199,
    name: 'Sweden',
    code: 'se',
  },
  {
    id: 200,
    name: 'Switzerland',
    code: 'ch',
  },
  {
    id: 201,
    name: 'Syrian Arab Republic',
    code: 'sy',
  },
  {
    id: 202,
    name: 'Tajikistan',
    code: 'tj',
  },
  {
    id: 203,
    name: 'Thailand',
    code: 'th',
  },
  {
    id: 204,
    name: 'Timor-Leste',
    code: 'tl',
  },
  {
    id: 205,
    name: 'Togo',
    code: 'tg',
  },
  {
    id: 206,
    name: 'Tokelau',
    code: 'tk',
  },
  {
    id: 207,
    name: 'Tonga',
    code: 'to',
  },
  {
    id: 208,
    name: 'Trinidad and Tobago',
    code: 'tt',
  },
  {
    id: 209,
    name: 'Tunisia',
    code: 'tn',
  },
  {
    id: 210,
    name: 'Turkey',
    code: 'tr',
  },
  {
    id: 211,
    name: 'Turkmenistan',
    code: 'tm',
  },
  {
    id: 212,
    name: 'Turks and Caicos Islands',
    code: 'tc',
  },
  {
    id: 213,
    name: 'Tuvalu',
    code: 'tv',
  },
  {
    id: 214,
    name: 'Uganda',
    code: 'ug',
  },
  {
    id: 215,
    name: 'Ukraine',
    code: 'ua',
  },
  {
    id: 216,
    name: 'United Arab Emirates',
    code: 'ae',
  },
  {
    id: 217,
    name: 'United Kingdom',
    code: 'gb',
  },
  {
    id: 218,
    name: 'United States',
    code: 'us',
  },
  {
    id: 219,
    name: 'United States Minor Outlying Islands',
    code: 'um',
  },
  {
    id: 220,
    name: 'Uruguay',
    code: 'uy',
  },
  {
    id: 221,
    name: 'Uzbekistan',
    code: 'uz',
  },
  {
    id: 222,
    name: 'Vanuatu',
    code: 'vu',
  },
  {
    id: 223,
    name: 'Viet Nam',
    code: 'vm',
  },
  {
    id: 224,
    name: 'Wallis and Futuna',
    code: 'wf',
  },
  {
    id: 225,
    name: 'Western Sahara',
    code: 'eh',
  },
  {
    id: 226,
    name: 'Yemen',
    code: 'ye',
  },
  {
    id: 227,
    name: 'Zambia',
    code: 'zm',
  },
  {
    id: 228,
    name: 'Zimbabwe',
    code: 'zw',
  },
];

export const TIMEZONE_LIST = [
  {
    id: 1,
    label: 'Africa/Abidjan',
  },
  {
    id: 2,
    label: 'Africa/Accra',
  },
  {
    id: 3,
    label: 'Africa/Addis_Ababa',
  },
  {
    id: 4,
    label: 'Africa/Algiers',
  },
  {
    id: 5,
    label: 'Africa/Asmara',
  },
  {
    id: 6,
    label: 'Africa/Asmera',
  },
  {
    id: 7,
    label: 'Africa/Bamako',
  },
  {
    id: 8,
    label: 'Africa/Bangui',
  },
  {
    id: 9,
    label: 'Africa/Banjul',
  },
  {
    id: 10,
    label: 'Africa/Bissau',
  },
  {
    id: 11,
    label: 'Africa/Blantyre',
  },
  {
    id: 12,
    label: 'Africa/Brazzaville',
  },
  {
    id: 13,
    label: 'Africa/Bujumbura',
  },
  {
    id: 14,
    label: 'Africa/Cairo',
  },
  {
    id: 15,
    label: 'Africa/Casablanca',
  },
  {
    id: 16,
    label: 'Africa/Ceuta',
  },
  {
    id: 17,
    label: 'Africa/Conakry',
  },
  {
    id: 18,
    label: 'Africa/Dakar',
  },
  {
    id: 19,
    label: 'Africa/Dar_es_Salaam',
  },
  {
    id: 20,
    label: 'Africa/Djibouti',
  },
  {
    id: 21,
    label: 'Africa/Douala',
  },
  {
    id: 22,
    label: 'Africa/El_Aaiun',
  },
  {
    id: 23,
    label: 'Africa/Freetown',
  },
  {
    id: 24,
    label: 'Africa/Gaborone',
  },
  {
    id: 25,
    label: 'Africa/Harare',
  },
  {
    id: 26,
    label: 'Africa/Johannesburg',
  },
  {
    id: 27,
    label: 'Africa/Juba',
  },
  {
    id: 28,
    label: 'Africa/Kampala',
  },
  {
    id: 29,
    label: 'Africa/Khartoum',
  },
  {
    id: 30,
    label: 'Africa/Kigali',
  },
  {
    id: 31,
    label: 'Africa/Kinshasa',
  },
  {
    id: 32,
    label: 'Africa/Lagos',
  },
  {
    id: 33,
    label: 'Africa/Libreville',
  },
  {
    id: 34,
    label: 'Africa/Lome',
  },
  {
    id: 35,
    label: 'Africa/Luanda',
  },
  {
    id: 36,
    label: 'Africa/Lubumbashi',
  },
  {
    id: 37,
    label: 'Africa/Lusaka',
  },
  {
    id: 38,
    label: 'Africa/Malabo',
  },
  {
    id: 39,
    label: 'Africa/Maputo',
  },
  {
    id: 40,
    label: 'Africa/Maseru',
  },
  {
    id: 41,
    label: 'Africa/Mbabane',
  },
  {
    id: 42,
    label: 'Africa/Mogadishu',
  },
  {
    id: 43,
    label: 'Africa/Monrovia',
  },
  {
    id: 44,
    label: 'Africa/Nairobi',
  },
  {
    id: 45,
    label: 'Africa/Ndjamena',
  },
  {
    id: 46,
    label: 'Africa/Niamey',
  },
  {
    id: 47,
    label: 'Africa/Nouakchott',
  },
  {
    id: 48,
    label: 'Africa/Ouagadougou',
  },
  {
    id: 49,
    label: 'Africa/Porto-Novo',
  },
  {
    id: 50,
    label: 'Africa/Sao_Tome',
  },
  {
    id: 51,
    label: 'Africa/Timbuktu',
  },
  {
    id: 52,
    label: 'Africa/Tripoli',
  },
  {
    id: 53,
    label: 'Africa/Tunis',
  },
  {
    id: 54,
    label: 'Africa/Windhoek',
  },
  {
    id: 55,
    label: 'America/Adak',
  },
  {
    id: 56,
    label: 'America/Anchorage',
  },
  {
    id: 57,
    label: 'America/Anguilla',
  },
  {
    id: 58,
    label: 'America/Antigua',
  },
  {
    id: 59,
    label: 'America/Araguaina',
  },
  {
    id: 60,
    label: 'America/Argentina/Buenos_Aires',
  },
  {
    id: 61,
    label: 'America/Argentina/Catamarca',
  },
  {
    id: 62,
    label: 'America/Argentina/ComodRivadavia',
  },
  {
    id: 63,
    label: 'America/Argentina/Cordoba',
  },
  {
    id: 64,
    label: 'America/Argentina/Jujuy',
  },
  {
    id: 65,
    label: 'America/Argentina/La_Rioja',
  },
  {
    id: 66,
    label: 'America/Argentina/Mendoza',
  },
  {
    id: 67,
    label: 'America/Argentina/Rio_Gallegos',
  },
  {
    id: 68,
    label: 'America/Argentina/Salta',
  },
  {
    id: 69,
    label: 'America/Argentina/San_Juan',
  },
  {
    id: 70,
    label: 'America/Argentina/San_Luis',
  },
  {
    id: 71,
    label: 'America/Argentina/Tucuman',
  },
  {
    id: 72,
    label: 'America/Argentina/Ushuaia',
  },
  {
    id: 73,
    label: 'America/Aruba',
  },
  {
    id: 74,
    label: 'America/Asuncion',
  },
  {
    id: 75,
    label: 'America/Atikokan',
  },
  {
    id: 76,
    label: 'America/Atka',
  },
  {
    id: 77,
    label: 'America/Bahia',
  },
  {
    id: 78,
    label: 'America/Bahia_Banderas',
  },
  {
    id: 79,
    label: 'America/Barbados',
  },
  {
    id: 80,
    label: 'America/Belem',
  },
  {
    id: 81,
    label: 'America/Belize',
  },
  {
    id: 82,
    label: 'America/Blanc-Sablon',
  },
  {
    id: 83,
    label: 'America/Boa_Vista',
  },
  {
    id: 84,
    label: 'America/Bogota',
  },
  {
    id: 85,
    label: 'America/Boise',
  },
  {
    id: 86,
    label: 'America/Buenos_Aires',
  },
  {
    id: 87,
    label: 'America/Cambridge_Bay',
  },
  {
    id: 88,
    label: 'America/Campo_Grande',
  },
  {
    id: 89,
    label: 'America/Cancun',
  },
  {
    id: 90,
    label: 'America/Caracas',
  },
  {
    id: 91,
    label: 'America/Catamarca',
  },
  {
    id: 92,
    label: 'America/Cayenne',
  },
  {
    id: 93,
    label: 'America/Cayman',
  },
  {
    id: 94,
    label: 'America/Chicago',
  },
  {
    id: 95,
    label: 'America/Chihuahua',
  },
  {
    id: 96,
    label: 'America/Coral_Harbour',
  },
  {
    id: 97,
    label: 'America/Cordoba',
  },
  {
    id: 98,
    label: 'America/Costa_Rica',
  },
  {
    id: 99,
    label: 'America/Creston',
  },
  {
    id: 100,
    label: 'America/Cuiaba',
  },
  {
    id: 101,
    label: 'America/Curacao',
  },
  {
    id: 102,
    label: 'America/Danmarkshavn',
  },
  {
    id: 103,
    label: 'America/Dawson',
  },
  {
    id: 104,
    label: 'America/Dawson_Creek',
  },
  {
    id: 105,
    label: 'America/Denver',
  },
  {
    id: 106,
    label: 'America/Detroit',
  },
  {
    id: 107,
    label: 'America/Dominica',
  },
  {
    id: 108,
    label: 'America/Edmonton',
  },
  {
    id: 109,
    label: 'America/Eirunepe',
  },
  {
    id: 110,
    label: 'America/El_Salvador',
  },
  {
    id: 111,
    label: 'America/Ensenada',
  },
  {
    id: 112,
    label: 'America/Fort_Nelson',
  },
  {
    id: 113,
    label: 'America/Fort_Wayne',
  },
  {
    id: 114,
    label: 'America/Fortaleza',
  },
  {
    id: 115,
    label: 'America/Glace_Bay',
  },
  {
    id: 116,
    label: 'America/Godthab',
  },
  {
    id: 117,
    label: 'America/Goose_Bay',
  },
  {
    id: 118,
    label: 'America/Grand_Turk',
  },
  {
    id: 119,
    label: 'America/Grenada',
  },
  {
    id: 120,
    label: 'America/Guadeloupe',
  },
  {
    id: 121,
    label: 'America/Guatemala',
  },
  {
    id: 122,
    label: 'America/Guayaquil',
  },
  {
    id: 123,
    label: 'America/Guyana',
  },
  {
    id: 124,
    label: 'America/Halifax',
  },
  {
    id: 125,
    label: 'America/Havana',
  },
  {
    id: 126,
    label: 'America/Hermosillo',
  },
  {
    id: 127,
    label: 'America/Indiana/Indianapolis',
  },
  {
    id: 128,
    label: 'America/Indiana/Knox',
  },
  {
    id: 129,
    label: 'America/Indiana/Marengo',
  },
  {
    id: 130,
    label: 'America/Indiana/Petersburg',
  },
  {
    id: 131,
    label: 'America/Indiana/Tell_City',
  },
  {
    id: 132,
    label: 'America/Indiana/Vevay',
  },
  {
    id: 133,
    label: 'America/Indiana/Vincennes',
  },
  {
    id: 134,
    label: 'America/Indiana/Winamac',
  },
  {
    id: 135,
    label: 'America/Indianapolis',
  },
  {
    id: 136,
    label: 'America/Inuvik',
  },
  {
    id: 137,
    label: 'America/Iqaluit',
  },
  {
    id: 138,
    label: 'America/Jamaica',
  },
  {
    id: 139,
    label: 'America/Jujuy',
  },
  {
    id: 140,
    label: 'America/Juneau',
  },
  {
    id: 141,
    label: 'America/Kentucky/Louisville',
  },
  {
    id: 142,
    label: 'America/Kentucky/Monticello',
  },
  {
    id: 143,
    label: 'America/Knox_IN',
  },
  {
    id: 144,
    label: 'America/Kralendijk',
  },
  {
    id: 145,
    label: 'America/La_Paz',
  },
  {
    id: 146,
    label: 'America/Lima',
  },
  {
    id: 147,
    label: 'America/Los_Angeles',
  },
  {
    id: 148,
    label: 'America/Louisville',
  },
  {
    id: 149,
    label: 'America/Lower_Princes',
  },
  {
    id: 150,
    label: 'America/Maceio',
  },
  {
    id: 151,
    label: 'America/Managua',
  },
  {
    id: 152,
    label: 'America/Manaus',
  },
  {
    id: 153,
    label: 'America/Marigot',
  },
  {
    id: 154,
    label: 'America/Martinique',
  },
  {
    id: 155,
    label: 'America/Matamoros',
  },
  {
    id: 156,
    label: 'America/Mazatlan',
  },
  {
    id: 157,
    label: 'America/Mendoza',
  },
  {
    id: 158,
    label: 'America/Menominee',
  },
  {
    id: 159,
    label: 'America/Merida',
  },
  {
    id: 160,
    label: 'America/Metlakatla',
  },
  {
    id: 161,
    label: 'America/Mexico_City',
  },
  {
    id: 162,
    label: 'America/Miquelon',
  },
  {
    id: 163,
    label: 'America/Moncton',
  },
  {
    id: 164,
    label: 'America/Monterrey',
  },
  {
    id: 165,
    label: 'America/Montevideo',
  },
  {
    id: 166,
    label: 'America/Montreal',
  },
  {
    id: 167,
    label: 'America/Montserrat',
  },
  {
    id: 168,
    label: 'America/Nassau',
  },
  {
    id: 169,
    label: 'America/New_York',
  },
  {
    id: 170,
    label: 'America/Nipigon',
  },
  {
    id: 171,
    label: 'America/Nome',
  },
  {
    id: 172,
    label: 'America/Noronha',
  },
  {
    id: 173,
    label: 'America/North_Dakota/Beulah',
  },
  {
    id: 174,
    label: 'America/North_Dakota/Center',
  },
  {
    id: 175,
    label: 'America/North_Dakota/New_Salem',
  },
  {
    id: 176,
    label: 'America/Ojinaga',
  },
  {
    id: 177,
    label: 'America/Panama',
  },
  {
    id: 178,
    label: 'America/Pangnirtung',
  },
  {
    id: 179,
    label: 'America/Paramaribo',
  },
  {
    id: 180,
    label: 'America/Phoenix',
  },
  {
    id: 182,
    label: 'America/Port_of_Spain',
  },
  {
    id: 181,
    label: 'America/Port-au-Prince',
  },
  {
    id: 183,
    label: 'America/Porto_Acre',
  },
  {
    id: 184,
    label: 'America/Porto_Velho',
  },
  {
    id: 185,
    label: 'America/Puerto_Rico',
  },
  {
    id: 186,
    label: 'America/Punta_Arenas',
  },
  {
    id: 187,
    label: 'America/Rainy_River',
  },
  {
    id: 188,
    label: 'America/Rankin_Inlet',
  },
  {
    id: 189,
    label: 'America/Recife',
  },
  {
    id: 190,
    label: 'America/Regina',
  },
  {
    id: 191,
    label: 'America/Resolute',
  },
  {
    id: 192,
    label: 'America/Rio_Branco',
  },
  {
    id: 193,
    label: 'America/Rosario',
  },
  {
    id: 194,
    label: 'America/Santa_Isabel',
  },
  {
    id: 195,
    label: 'America/Santarem',
  },
  {
    id: 196,
    label: 'America/Santiago',
  },
  {
    id: 197,
    label: 'America/Santo_Domingo',
  },
  {
    id: 198,
    label: 'America/Sao_Paulo',
  },
  {
    id: 199,
    label: 'America/Scoresbysund',
  },
  {
    id: 200,
    label: 'America/Shiprock',
  },
  {
    id: 201,
    label: 'America/Sitka',
  },
  {
    id: 202,
    label: 'America/St_Barthelemy',
  },
  {
    id: 203,
    label: 'America/St_Johns',
  },
  {
    id: 204,
    label: 'America/St_Kitts',
  },
  {
    id: 205,
    label: 'America/St_Lucia',
  },
  {
    id: 206,
    label: 'America/St_Thomas',
  },
  {
    id: 207,
    label: 'America/St_Vincent',
  },
  {
    id: 208,
    label: 'America/Swift_Current',
  },
  {
    id: 209,
    label: 'America/Tegucigalpa',
  },
  {
    id: 210,
    label: 'America/Thule',
  },
  {
    id: 211,
    label: 'America/Thunder_Bay',
  },
  {
    id: 212,
    label: 'America/Tijuana',
  },
  {
    id: 213,
    label: 'America/Toronto',
  },
  {
    id: 214,
    label: 'America/Tortola',
  },
  {
    id: 215,
    label: 'America/Vancouver',
  },
  {
    id: 216,
    label: 'America/Virgin',
  },
  {
    id: 217,
    label: 'America/Whitehorse',
  },
  {
    id: 218,
    label: 'America/Winnipeg',
  },
  {
    id: 219,
    label: 'America/Yakutat',
  },
  {
    id: 220,
    label: 'America/Yellowknife',
  },
  {
    id: 221,
    label: 'Antarctica/Casey',
  },
  {
    id: 222,
    label: 'Antarctica/Davis',
  },
  {
    id: 223,
    label: 'Antarctica/DumontDUrville',
  },
  {
    id: 224,
    label: 'Antarctica/Macquarie',
  },
  {
    id: 225,
    label: 'Antarctica/Mawson',
  },
  {
    id: 226,
    label: 'Antarctica/McMurdo',
  },
  {
    id: 227,
    label: 'Antarctica/Palmer',
  },
  {
    id: 228,
    label: 'Antarctica/Rothera',
  },
  {
    id: 229,
    label: 'Antarctica/South_Pole',
  },
  {
    id: 230,
    label: 'Antarctica/Syowa',
  },
  {
    id: 231,
    label: 'Antarctica/Troll',
  },
  {
    id: 232,
    label: 'Antarctica/Vostok',
  },
  {
    id: 233,
    label: 'Arctic/Longyearbyen',
  },
  {
    id: 234,
    label: 'Asia/Aden',
  },
  {
    id: 235,
    label: 'Asia/Almaty',
  },
  {
    id: 236,
    label: 'Asia/Amman',
  },
  {
    id: 237,
    label: 'Asia/Anadyr',
  },
  {
    id: 238,
    label: 'Asia/Aqtau',
  },
  {
    id: 239,
    label: 'Asia/Aqtobe',
  },
  {
    id: 240,
    label: 'Asia/Ashgabat',
  },
  {
    id: 241,
    label: 'Asia/Ashkhabad',
  },
  {
    id: 242,
    label: 'Asia/Atyrau',
  },
  {
    id: 243,
    label: 'Asia/Baghdad',
  },
  {
    id: 244,
    label: 'Asia/Bahrain',
  },
  {
    id: 245,
    label: 'Asia/Baku',
  },
  {
    id: 246,
    label: 'Asia/Bangkok',
  },
  {
    id: 247,
    label: 'Asia/Barnaul',
  },
  {
    id: 248,
    label: 'Asia/Beirut',
  },
  {
    id: 249,
    label: 'Asia/Bishkek',
  },
  {
    id: 250,
    label: 'Asia/Brunei',
  },
  {
    id: 251,
    label: 'Asia/Calcutta',
  },
  {
    id: 252,
    label: 'Asia/Chita',
  },
  {
    id: 253,
    label: 'Asia/Choibalsan',
  },
  {
    id: 254,
    label: 'Asia/Chongqing',
  },
  {
    id: 255,
    label: 'Asia/Chungking',
  },
  {
    id: 256,
    label: 'Asia/Colombo',
  },
  {
    id: 257,
    label: 'Asia/Dacca',
  },
  {
    id: 258,
    label: 'Asia/Damascus',
  },
  {
    id: 259,
    label: 'Asia/Dhaka',
  },
  {
    id: 260,
    label: 'Asia/Dili',
  },
  {
    id: 261,
    label: 'Asia/Dubai',
  },
  {
    id: 262,
    label: 'Asia/Dushanbe',
  },
  {
    id: 263,
    label: 'Asia/Famagusta',
  },
  {
    id: 264,
    label: 'Asia/Gaza',
  },
  {
    id: 265,
    label: 'Asia/Harbin',
  },
  {
    id: 266,
    label: 'Asia/Hebron',
  },
  {
    id: 267,
    label: 'Asia/Ho_Chi_Minh',
  },
  {
    id: 268,
    label: 'Asia/Hong_Kong',
  },
  {
    id: 269,
    label: 'Asia/Hovd',
  },
  {
    id: 270,
    label: 'Asia/Irkutsk',
  },
  {
    id: 271,
    label: 'Asia/Istanbul',
  },
  {
    id: 272,
    label: 'Asia/Jakarta',
  },
  {
    id: 273,
    label: 'Asia/Jayapura',
  },
  {
    id: 274,
    label: 'Asia/Jerusalem',
  },
  {
    id: 275,
    label: 'Asia/Kabul',
  },
  {
    id: 276,
    label: 'Asia/Kamchatka',
  },
  {
    id: 277,
    label: 'Asia/Karachi',
  },
  {
    id: 278,
    label: 'Asia/Kashgar',
  },
  {
    id: 279,
    label: 'Asia/Kathmandu',
  },
  {
    id: 280,
    label: 'Asia/Katmandu',
  },
  {
    id: 281,
    label: 'Asia/Khandyga',
  },
  {
    id: 282,
    label: 'Asia/Kolkata',
  },
  {
    id: 283,
    label: 'Asia/Krasnoyarsk',
  },
  {
    id: 284,
    label: 'Asia/Kuala_Lumpur',
  },
  {
    id: 285,
    label: 'Asia/Kuching',
  },
  {
    id: 286,
    label: 'Asia/Kuwait',
  },
  {
    id: 287,
    label: 'Asia/Macao',
  },
  {
    id: 288,
    label: 'Asia/Macau',
  },
  {
    id: 289,
    label: 'Asia/Magadan',
  },
  {
    id: 290,
    label: 'Asia/Makassar',
  },
  {
    id: 291,
    label: 'Asia/Manila',
  },
  {
    id: 292,
    label: 'Asia/Muscat',
  },
  {
    id: 293,
    label: 'Asia/Nicosia',
  },
  {
    id: 294,
    label: 'Asia/Novokuznetsk',
  },
  {
    id: 295,
    label: 'Asia/Novosibirsk',
  },
  {
    id: 296,
    label: 'Asia/Omsk',
  },
  {
    id: 297,
    label: 'Asia/Oral',
  },
  {
    id: 298,
    label: 'Asia/Phnom_Penh',
  },
  {
    id: 299,
    label: 'Asia/Pontianak',
  },
  {
    id: 300,
    label: 'Asia/Pyongyang',
  },
  {
    id: 301,
    label: 'Asia/Qatar',
  },
  {
    id: 302,
    label: 'Asia/Qyzylorda',
  },
  {
    id: 303,
    label: 'Asia/Rangoon',
  },
  {
    id: 304,
    label: 'Asia/Riyadh',
  },
  {
    id: 305,
    label: 'Asia/Saigon',
  },
  {
    id: 306,
    label: 'Asia/Sakhalin',
  },
  {
    id: 307,
    label: 'Asia/Samarkand',
  },
  {
    id: 308,
    label: 'Asia/Seoul',
  },
  {
    id: 309,
    label: 'Asia/Shanghai',
  },
  {
    id: 310,
    label: 'Asia/Singapore',
  },
  {
    id: 311,
    label: 'Asia/Srednekolymsk',
  },
  {
    id: 312,
    label: 'Asia/Taipei',
  },
  {
    id: 313,
    label: 'Asia/Tashkent',
  },
  {
    id: 314,
    label: 'Asia/Tbilisi',
  },
  {
    id: 315,
    label: 'Asia/Tehran',
  },
  {
    id: 316,
    label: 'Asia/Tel_Aviv',
  },
  {
    id: 317,
    label: 'Asia/Thimbu',
  },
  {
    id: 318,
    label: 'Asia/Thimphu',
  },
  {
    id: 319,
    label: 'Asia/Tokyo',
  },
  {
    id: 320,
    label: 'Asia/Tomsk',
  },
  {
    id: 321,
    label: 'Asia/Ujung_Pandang',
  },
  {
    id: 322,
    label: 'Asia/Ulaanbaatar',
  },
  {
    id: 323,
    label: 'Asia/Ulan_Bator',
  },
  {
    id: 324,
    label: 'Asia/Urumqi',
  },
  {
    id: 325,
    label: 'Asia/Ust-Nera',
  },
  {
    id: 326,
    label: 'Asia/Vientiane',
  },
  {
    id: 327,
    label: 'Asia/Vladivostok',
  },
  {
    id: 328,
    label: 'Asia/Yakutsk',
  },
  {
    id: 329,
    label: 'Asia/Yangon',
  },
  {
    id: 330,
    label: 'Asia/Yekaterinburg',
  },
  {
    id: 331,
    label: 'Asia/Yerevan',
  },
  {
    id: 332,
    label: 'Atlantic/Azores',
  },
  {
    id: 333,
    label: 'Atlantic/Bermuda',
  },
  {
    id: 334,
    label: 'Atlantic/Canary',
  },
  {
    id: 335,
    label: 'Atlantic/Cape_Verde',
  },
  {
    id: 336,
    label: 'Atlantic/Faeroe',
  },
  {
    id: 337,
    label: 'Atlantic/Faroe',
  },
  {
    id: 338,
    label: 'Atlantic/Jan_Mayen',
  },
  {
    id: 339,
    label: 'Atlantic/Madeira',
  },
  {
    id: 340,
    label: 'Atlantic/Reykjavik',
  },
  {
    id: 341,
    label: 'Atlantic/South_Georgia',
  },
  {
    id: 342,
    label: 'Atlantic/St_Helena',
  },
  {
    id: 343,
    label: 'Atlantic/Stanley',
  },
  {
    id: 344,
    label: 'Australia/ACT',
  },
  {
    id: 345,
    label: 'Australia/Adelaide',
  },
  {
    id: 346,
    label: 'Australia/Brisbane',
  },
  {
    id: 347,
    label: 'Australia/Broken_Hill',
  },
  {
    id: 348,
    label: 'Australia/Canberra',
  },
  {
    id: 349,
    label: 'Australia/Currie',
  },
  {
    id: 350,
    label: 'Australia/Darwin',
  },
  {
    id: 351,
    label: 'Australia/Eucla',
  },
  {
    id: 352,
    label: 'Australia/Hobart',
  },
  {
    id: 353,
    label: 'Australia/LHI',
  },
  {
    id: 354,
    label: 'Australia/Lindeman',
  },
  {
    id: 355,
    label: 'Australia/Lord_Howe',
  },
  {
    id: 356,
    label: 'Australia/Melbourne',
  },
  {
    id: 358,
    label: 'Australia/North',
  },
  {
    id: 357,
    label: 'Australia/NSW',
  },
  {
    id: 359,
    label: 'Australia/Perth',
  },
  {
    id: 360,
    label: 'Australia/Queensland',
  },
  {
    id: 361,
    label: 'Australia/South',
  },
  {
    id: 362,
    label: 'Australia/Sydney',
  },
  {
    id: 363,
    label: 'Australia/Tasmania',
  },
  {
    id: 364,
    label: 'Australia/Victoria',
  },
  {
    id: 365,
    label: 'Australia/West',
  },
  {
    id: 366,
    label: 'Australia/Yancowinna',
  },
  {
    id: 367,
    label: 'Brazil/Acre',
  },
  {
    id: 368,
    label: 'Brazil/DeNoronha',
  },
  {
    id: 369,
    label: 'Brazil/East',
  },
  {
    id: 370,
    label: 'Brazil/West',
  },
  {
    id: 373,
    label: 'Canada/Atlantic',
  },
  {
    id: 374,
    label: 'Canada/Central',
  },
  {
    id: 375,
    label: 'Canada/Eastern',
  },
  {
    id: 376,
    label: 'Canada/Mountain',
  },
  {
    id: 377,
    label: 'Canada/Newfoundland',
  },
  {
    id: 378,
    label: 'Canada/Pacific',
  },
  {
    id: 379,
    label: 'Canada/Saskatchewan',
  },
  {
    id: 380,
    label: 'Canada/Yukon',
  },
  {
    id: 371,
    label: 'CET',
  },
  {
    id: 381,
    label: 'Chile/Continental',
  },
  {
    id: 382,
    label: 'Chile/EasterIsland',
  },
  {
    id: 372,
    label: 'CST6CDT',
  },
  {
    id: 383,
    label: 'Cuba',
  },
  {
    id: 384,
    label: 'EET',
  },
  {
    id: 387,
    label: 'Egypt',
  },
  {
    id: 388,
    label: 'Eire',
  },
  {
    id: 385,
    label: 'EST',
  },
  {
    id: 386,
    label: 'EST5EDT',
  },
  {
    id: 389,
    label: 'Etc/GMT',
  },
  {
    id: 403,
    label: 'Etc/GMT-0',
  },
  {
    id: 404,
    label: 'Etc/GMT-1',
  },
  {
    id: 405,
    label: 'Etc/GMT-10',
  },
  {
    id: 406,
    label: 'Etc/GMT-11',
  },
  {
    id: 407,
    label: 'Etc/GMT-12',
  },
  {
    id: 408,
    label: 'Etc/GMT-13',
  },
  {
    id: 409,
    label: 'Etc/GMT-14',
  },
  {
    id: 410,
    label: 'Etc/GMT-2',
  },
  {
    id: 411,
    label: 'Etc/GMT-3',
  },
  {
    id: 412,
    label: 'Etc/GMT-4',
  },
  {
    id: 413,
    label: 'Etc/GMT-5',
  },
  {
    id: 414,
    label: 'Etc/GMT-6',
  },
  {
    id: 415,
    label: 'Etc/GMT-7',
  },
  {
    id: 416,
    label: 'Etc/GMT-8',
  },
  {
    id: 417,
    label: 'Etc/GMT-9',
  },
  {
    id: 390,
    label: 'Etc/GMT+0',
  },
  {
    id: 391,
    label: 'Etc/GMT+1',
  },
  {
    id: 392,
    label: 'Etc/GMT+10',
  },
  {
    id: 393,
    label: 'Etc/GMT+11',
  },
  {
    id: 394,
    label: 'Etc/GMT+12',
  },
  {
    id: 395,
    label: 'Etc/GMT+2',
  },
  {
    id: 396,
    label: 'Etc/GMT+3',
  },
  {
    id: 397,
    label: 'Etc/GMT+4',
  },
  {
    id: 398,
    label: 'Etc/GMT+5',
  },
  {
    id: 399,
    label: 'Etc/GMT+6',
  },
  {
    id: 400,
    label: 'Etc/GMT+7',
  },
  {
    id: 401,
    label: 'Etc/GMT+8',
  },
  {
    id: 402,
    label: 'Etc/GMT+9',
  },
  {
    id: 418,
    label: 'Etc/GMT0',
  },
  {
    id: 419,
    label: 'Etc/Greenwich',
  },
  {
    id: 420,
    label: 'Etc/UCT',
  },
  {
    id: 422,
    label: 'Etc/Universal',
  },
  {
    id: 421,
    label: 'Etc/UTC',
  },
  {
    id: 423,
    label: 'Etc/Zulu',
  },
  {
    id: 424,
    label: 'Europe/Amsterdam',
  },
  {
    id: 425,
    label: 'Europe/Andorra',
  },
  {
    id: 426,
    label: 'Europe/Astrakhan',
  },
  {
    id: 427,
    label: 'Europe/Athens',
  },
  {
    id: 428,
    label: 'Europe/Belfast',
  },
  {
    id: 429,
    label: 'Europe/Belgrade',
  },
  {
    id: 430,
    label: 'Europe/Berlin',
  },
  {
    id: 431,
    label: 'Europe/Bratislava',
  },
  {
    id: 432,
    label: 'Europe/Brussels',
  },
  {
    id: 433,
    label: 'Europe/Bucharest',
  },
  {
    id: 434,
    label: 'Europe/Budapest',
  },
  {
    id: 435,
    label: 'Europe/Busingen',
  },
  {
    id: 436,
    label: 'Europe/Chisinau',
  },
  {
    id: 437,
    label: 'Europe/Copenhagen',
  },
  {
    id: 438,
    label: 'Europe/Dublin',
  },
  {
    id: 439,
    label: 'Europe/Gibraltar',
  },
  {
    id: 440,
    label: 'Europe/Guernsey',
  },
  {
    id: 441,
    label: 'Europe/Helsinki',
  },
  {
    id: 442,
    label: 'Europe/Isle_of_Man',
  },
  {
    id: 443,
    label: 'Europe/Istanbul',
  },
  {
    id: 444,
    label: 'Europe/Jersey',
  },
  {
    id: 445,
    label: 'Europe/Kaliningrad',
  },
  {
    id: 446,
    label: 'Europe/Kiev',
  },
  {
    id: 447,
    label: 'Europe/Kirov',
  },
  {
    id: 448,
    label: 'Europe/Lisbon',
  },
  {
    id: 449,
    label: 'Europe/Ljubljana',
  },
  {
    id: 450,
    label: 'Europe/London',
  },
  {
    id: 451,
    label: 'Europe/Luxembourg',
  },
  {
    id: 452,
    label: 'Europe/Madrid',
  },
  {
    id: 453,
    label: 'Europe/Malta',
  },
  {
    id: 454,
    label: 'Europe/Mariehamn',
  },
  {
    id: 455,
    label: 'Europe/Minsk',
  },
  {
    id: 456,
    label: 'Europe/Monaco',
  },
  {
    id: 457,
    label: 'Europe/Moscow',
  },
  {
    id: 458,
    label: 'Europe/Nicosia',
  },
  {
    id: 459,
    label: 'Europe/Oslo',
  },
  {
    id: 460,
    label: 'Europe/Paris',
  },
  {
    id: 461,
    label: 'Europe/Podgorica',
  },
  {
    id: 462,
    label: 'Europe/Prague',
  },
  {
    id: 463,
    label: 'Europe/Riga',
  },
  {
    id: 464,
    label: 'Europe/Rome',
  },
  {
    id: 465,
    label: 'Europe/Samara',
  },
  {
    id: 466,
    label: 'Europe/San_Marino',
  },
  {
    id: 467,
    label: 'Europe/Sarajevo',
  },
  {
    id: 468,
    label: 'Europe/Saratov',
  },
  {
    id: 469,
    label: 'Europe/Simferopol',
  },
  {
    id: 470,
    label: 'Europe/Skopje',
  },
  {
    id: 471,
    label: 'Europe/Sofia',
  },
  {
    id: 472,
    label: 'Europe/Stockholm',
  },
  {
    id: 473,
    label: 'Europe/Tallinn',
  },
  {
    id: 474,
    label: 'Europe/Tirane',
  },
  {
    id: 475,
    label: 'Europe/Tiraspol',
  },
  {
    id: 476,
    label: 'Europe/Ulyanovsk',
  },
  {
    id: 477,
    label: 'Europe/Uzhgorod',
  },
  {
    id: 478,
    label: 'Europe/Vaduz',
  },
  {
    id: 479,
    label: 'Europe/Vatican',
  },
  {
    id: 480,
    label: 'Europe/Vienna',
  },
  {
    id: 481,
    label: 'Europe/Vilnius',
  },
  {
    id: 482,
    label: 'Europe/Volgograd',
  },
  {
    id: 483,
    label: 'Europe/Warsaw',
  },
  {
    id: 484,
    label: 'Europe/Zagreb',
  },
  {
    id: 485,
    label: 'Europe/Zaporozhye',
  },
  {
    id: 486,
    label: 'Europe/Zurich',
  },
  {
    id: 487,
    label: 'GB',
  },
  {
    id: 488,
    label: 'GB-Eire',
  },
  {
    id: 489,
    label: 'GMT',
  },
  {
    id: 491,
    label: 'GMT-0',
  },
  {
    id: 490,
    label: 'GMT+0',
  },
  {
    id: 492,
    label: 'GMT0',
  },
  {
    id: 493,
    label: 'Greenwich',
  },
  {
    id: 495,
    label: 'Hongkong',
  },
  {
    id: 494,
    label: 'HST',
  },
  {
    id: 496,
    label: 'Iceland',
  },
  {
    id: 497,
    label: 'Indian/Antananarivo',
  },
  {
    id: 498,
    label: 'Indian/Chagos',
  },
  {
    id: 499,
    label: 'Indian/Christmas',
  },
  {
    id: 500,
    label: 'Indian/Cocos',
  },
  {
    id: 501,
    label: 'Indian/Comoro',
  },
  {
    id: 502,
    label: 'Indian/Kerguelen',
  },
  {
    id: 503,
    label: 'Indian/Mahe',
  },
  {
    id: 504,
    label: 'Indian/Maldives',
  },
  {
    id: 505,
    label: 'Indian/Mauritius',
  },
  {
    id: 506,
    label: 'Indian/Mayotte',
  },
  {
    id: 507,
    label: 'Indian/Reunion',
  },
  {
    id: 508,
    label: 'Iran',
  },
  {
    id: 509,
    label: 'Israel',
  },
  {
    id: 510,
    label: 'Jamaica',
  },
  {
    id: 511,
    label: 'Japan',
  },
  {
    id: 512,
    label: 'Kwajalein',
  },
  {
    id: 513,
    label: 'Libya',
  },
  {
    id: 514,
    label: 'MET',
  },
  {
    id: 517,
    label: 'Mexico/BajaNorte',
  },
  {
    id: 518,
    label: 'Mexico/BajaSur',
  },
  {
    id: 519,
    label: 'Mexico/General',
  },
  {
    id: 515,
    label: 'MST',
  },
  {
    id: 516,
    label: 'MST7MDT',
  },
  {
    id: 522,
    label: 'Navajo',
  },
  {
    id: 520,
    label: 'NZ',
  },
  {
    id: 521,
    label: 'NZ-CHAT',
  },
  {
    id: 525,
    label: 'Pacific/Apia',
  },
  {
    id: 526,
    label: 'Pacific/Auckland',
  },
  {
    id: 527,
    label: 'Pacific/Bougainville',
  },
  {
    id: 528,
    label: 'Pacific/Chatham',
  },
  {
    id: 529,
    label: 'Pacific/Chuuk',
  },
  {
    id: 530,
    label: 'Pacific/Easter',
  },
  {
    id: 531,
    label: 'Pacific/Efate',
  },
  {
    id: 532,
    label: 'Pacific/Enderbury',
  },
  {
    id: 533,
    label: 'Pacific/Fakaofo',
  },
  {
    id: 534,
    label: 'Pacific/Fiji',
  },
  {
    id: 535,
    label: 'Pacific/Funafuti',
  },
  {
    id: 536,
    label: 'Pacific/Galapagos',
  },
  {
    id: 537,
    label: 'Pacific/Gambier',
  },
  {
    id: 538,
    label: 'Pacific/Guadalcanal',
  },
  {
    id: 539,
    label: 'Pacific/Guam',
  },
  {
    id: 540,
    label: 'Pacific/Honolulu',
  },
  {
    id: 541,
    label: 'Pacific/Johnston',
  },
  {
    id: 542,
    label: 'Pacific/Kiritimati',
  },
  {
    id: 543,
    label: 'Pacific/Kosrae',
  },
  {
    id: 544,
    label: 'Pacific/Kwajalein',
  },
  {
    id: 545,
    label: 'Pacific/Majuro',
  },
  {
    id: 546,
    label: 'Pacific/Marquesas',
  },
  {
    id: 547,
    label: 'Pacific/Midway',
  },
  {
    id: 548,
    label: 'Pacific/Nauru',
  },
  {
    id: 549,
    label: 'Pacific/Niue',
  },
  {
    id: 550,
    label: 'Pacific/Norfolk',
  },
  {
    id: 551,
    label: 'Pacific/Noumea',
  },
  {
    id: 552,
    label: 'Pacific/Pago_Pago',
  },
  {
    id: 553,
    label: 'Pacific/Palau',
  },
  {
    id: 554,
    label: 'Pacific/Pitcairn',
  },
  {
    id: 555,
    label: 'Pacific/Pohnpei',
  },
  {
    id: 556,
    label: 'Pacific/Ponape',
  },
  {
    id: 557,
    label: 'Pacific/Port_Moresby',
  },
  {
    id: 558,
    label: 'Pacific/Rarotonga',
  },
  {
    id: 559,
    label: 'Pacific/Saipan',
  },
  {
    id: 560,
    label: 'Pacific/Samoa',
  },
  {
    id: 561,
    label: 'Pacific/Tahiti',
  },
  {
    id: 562,
    label: 'Pacific/Tarawa',
  },
  {
    id: 563,
    label: 'Pacific/Tongatapu',
  },
  {
    id: 564,
    label: 'Pacific/Truk',
  },
  {
    id: 565,
    label: 'Pacific/Wake',
  },
  {
    id: 566,
    label: 'Pacific/Wallis',
  },
  {
    id: 567,
    label: 'Pacific/Yap',
  },
  {
    id: 568,
    label: 'Poland',
  },
  {
    id: 569,
    label: 'Portugal',
  },
  {
    id: 523,
    label: 'PRC',
  },
  {
    id: 524,
    label: 'PST8PDT',
  },
  {
    id: 570,
    label: 'ROC',
  },
  {
    id: 571,
    label: 'ROK',
  },
  {
    id: 572,
    label: 'Singapore',
  },
  {
    id: 573,
    label: 'Turkey',
  },
  {
    id: 574,
    label: 'UCT',
  },
  {
    id: 589,
    label: 'Universal',
  },
  {
    id: 575,
    label: 'US/Alaska',
  },
  {
    id: 576,
    label: 'US/Aleutian',
  },
  {
    id: 577,
    label: 'US/Arizona',
  },
  {
    id: 578,
    label: 'US/Central',
  },
  {
    id: 579,
    label: 'US/East-Indiana',
  },
  {
    id: 580,
    label: 'US/Eastern',
  },
  {
    id: 581,
    label: 'US/Hawaii',
  },
  {
    id: 582,
    label: 'US/Indiana-Starke',
  },
  {
    id: 583,
    label: 'US/Michigan',
  },
  {
    id: 584,
    label: 'US/Mountain',
  },
  {
    id: 585,
    label: 'US/Pacific',
  },
  {
    id: 586,
    label: 'US/Pacific-New',
  },
  {
    id: 587,
    label: 'US/Samoa',
  },
  {
    id: 588,
    label: 'UTC',
  },
  {
    id: 590,
    label: 'W-SU',
  },
  {
    id: 591,
    label: 'WET',
  },
  {
    id: 592,
    label: 'Zulu',
  },
];
