import { createSlice } from '@reduxjs/toolkit';

const commonDataSlice = createSlice({
  name: 'common',
  initialState: {
    generalLoader: {},
    enums: {},
    industryList: [],
    countryList: [],
    timeZonesList: [],
    paidMealBreaksList: [
      {
        label: 'All Meal breaks are unpaid',
        id: false,
      },
      { label: 'All Meal breaks are paid', id: true },
    ],
  },
  reducers: {
    startGeneralLoader: (state, action) => {
      const { loaderFor } = action.payload;
      state.generalLoader[loaderFor] = true;
    },
    stopGeneralLoader: (state, action) => {
      const { loaderFor } = action.payload;
      delete state.generalLoader[loaderFor];
    },
    saveEnumListData: (state, action) => {
      state.enums = action?.payload;
    },
    setIndustryList: (state, action) => {
      state.industryList = action.payload ?? [];
    },
    setCountryList: (state, action) => {
      state.countryList = action.payload ?? [];
    },
    setTimeZonesList: (state, action) => {
      state.timeZonesList = action.payload ?? [];
    },
  },
});

const { reducer, actions } = commonDataSlice;

export const {
  startGeneralLoader,
  stopGeneralLoader,
  saveEnumListData,
  setIndustryList,
  setCountryList,
  setTimeZonesList,
} = actions;

export default reducer;
