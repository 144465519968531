import { CircularProgress } from '@mui/material';
import React from 'react';

function Loader() {
  return (
    <div className="loader-container">
      <CircularProgress color="primary" thickness={4} size={50} />
    </div>
  );
}

Loader.propTypes = {};

export default Loader;
