import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import EzyTime from '../../../../components/common/dateAndTimePicker/EzyTime';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import {
  addDeleteEndShiftBreakDetails,
  resetEditPeopleEndShiftDetails,
  resetEditPeopleShiftDetailsField,
  updateEditPeopleShiftDetailsField,
  updateEndShiftBreakDetails,
} from '../../redux/peopleSlice';
import {
  editPeopleEndShift,
  getEndShiftDetailsByTimeSheetID,
  getShiftDetailsByPersonID,
} from '../../redux/peopleActions';
import EzyIconButton from '../../../../components/common/iconButton/EzyIconButton';
import EzySwitch from '../../../../components/common/switch/EzySwitch';
import EzyAutoComplete from '../../../../components/common/autoComplete/EzyAutoComplete';
import { breakDurationList } from '../../../../utils/constants';
import validateEndShift from '../../validations/validateEndShift';
import { getHoursAndMinutesFromHours } from '../../../../utils/timeHelper';
import IconByName from '../../../../components/common/iconByName/IconByName';
import EzyDateTime from '../../../../components/common/dateAndTimePicker/EzyDateTime';

function EditShiftDrawer({ isFromDashboard }) {
  const param = useParams();
  const dispatch = useDispatch();
  const peopleData = useSelector(({ people }) => people ?? {});
  const { shiftData } = useMemo(() => peopleData?.editPeople?.details, [peopleData]);
  const { breakType } = useSelector(({ common }) => common?.enums ?? {});
  const { profileData } = useSelector(({ user }) => user || {});
  const userData = useMemo(() => profileData, [profileData]);
  const peopleId = isFromDashboard ? userData?.personId : param?.peopleId;
  const isFromProfile = !isFromDashboard ?? false;
  const { getEndShiftDetails, editPeopleEndShiftLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );
  const handelBreakDetailsFields = (fieldName, value, index) => {
    dispatch(updateEndShiftBreakDetails({ fieldName, value, index }));
  };

  const handelDrawerClose = () => {
    dispatch(
      updateEditPeopleShiftDetailsField({ fieldName: 'isEditShiftDrawerEnable', value: false })
    );
    dispatch(resetEditPeopleEndShiftDetails());
    dispatch(updateEditPeopleShiftDetailsField({ fieldName: 'lessTimeWarning', value: false }));
  };
  const handelEndShift = () => {
    if (shiftData?.endShiftDetails?.isAllowToClockOutFromWeb) {
      const data = {
        timeSheetId: shiftData?.timeSheetId,
        startTime: shiftData?.endShiftDetails?.startTime,
        endTime: shiftData?.endShiftDetails?.endTime,
        ...(shiftData?.lessTimeWarning === false || shiftData?.lessTimeWarning === undefined
          ? { allowTimeSheet: false }
          : { allowTimeSheet: true }),
        shiftBreakEntity: shiftData?.endShiftDetails?.breakDetails?.map((res) => ({
          ...res,
          breakType: res?.breakType?.id,
        })),
        isFromDashboard,
        isFromProfile,
      };

      const error = validateEndShift(data);

      if (isEmpty(error)) {
        dispatch(
          editPeopleEndShift(data, () => {
            dispatch(getShiftDetailsByPersonID(peopleId));
            dispatch(resetEditPeopleShiftDetailsField());
          })
        );
      }
      handelBreakDetailsFields('errors', error);
    } else {
      dispatch(
        updateEditPeopleShiftDetailsField({ fieldName: 'shiftStartStopWarningModal', value: true })
      );
      handelDrawerClose();
    }
  };

  const actionButton = [
    {
      label: 'End Shift',
      color: 'error',
      onClick: handelEndShift,
      loading: editPeopleEndShiftLoader,
    },
  ];

  useEffect(() => {
    if (shiftData?.isEditShiftDrawerEnable) {
      dispatch(getEndShiftDetailsByTimeSheetID({ timeSheetId: shiftData?.timeSheetId }));
    }
  }, [shiftData?.isEditShiftDrawerEnable]);

  const shiftMinutes = moment(shiftData?.endShiftDetails?.endTime).diff(
    moment(shiftData?.endShiftDetails?.startTime),
    'minute',
    true
  );

  const TotalBreakDuration = shiftData?.endShiftDetails?.breakDetails?.reduce((prev, curr) => {
    return prev + +curr.duration;
  }, 0);

  const { hours, minutes } =
    shiftData?.endShiftDetails?.isMealBreakPaid === false
      ? getHoursAndMinutesFromHours((shiftMinutes - TotalBreakDuration) / 60)
      : getHoursAndMinutesFromHours(shiftMinutes / 60);

  return (
    <EzyDrawer
      onClose={handelDrawerClose}
      isOpen={shiftData?.isEditShiftDrawerEnable}
      heading="Edit shift"
      actionButtons={actionButton}
      isLoading={getEndShiftDetails}
    >
      <div className="edit-shift-drawer-wrapper">
        {shiftData?.lessTimeWarning && (
          <div className="info-div error body-text shift-warning">
            <IconByName name="error" />
            <div>
              This timesheet might be deleted because its length is less than minimum length
              requirement.
            </div>
          </div>
        )}
        {shiftData?.endShiftDetails?.suggestBreak && (
          <div className="info-div info body-text shift-warning">
            <IconByName name="info" />
            <div>{`You haven't consumed any of your scheduled break.`}</div>
          </div>
        )}
        {shiftData?.showTimesheetToEmployee && (
          <div className="edit-shift-drawer-time-header">
            <div className="edit-shift-drawer-time">{`${Math.floor(hours)} Hours ${Math.floor(
              minutes
            )} Minutes`}</div>
            <div className="edit-shift-drawer-sub-label">Total Paid Time</div>
          </div>
        )}
        <div className="edit-shift-drawer-time-selection">
          <EzyDateTime
            label="Start time"
            placeholder="Select time"
            value={shiftData?.endShiftDetails?.startTime}
            onChange={(value) => handelBreakDetailsFields('startTime', value)}
            error={shiftData?.endShiftDetails?.errors?.startTime}
            disabled={
              !shiftData?.endShiftDetails?.isAllowToEdittimeSheet ||
              !shiftData?.showTimesheetToEmployee
            }
            timeZone={shiftData?.endShiftDetails?.locationTimeZoneName}
            required
          />
          <EzyDateTime
            label="End Time"
            placeholder="Select time"
            value={shiftData?.endShiftDetails?.endTime}
            onChange={(value) => handelBreakDetailsFields('endTime', value)}
            error={shiftData?.endShiftDetails?.errors?.endTime}
            disabled={
              !shiftData?.endShiftDetails?.isAllowToEdittimeSheet ||
              !shiftData?.showTimesheetToEmployee
            }
            timeZone={shiftData?.endShiftDetails?.locationTimeZoneName}
            required
          />
        </div>
        <div className="edit-shift-drawer-note">
          <EzyInputField
            label="Note"
            placeholder="Enter notes"
            onChange={(e) => handelBreakDetailsFields('note', e.target.value)}
            disabled={!shiftData?.endShiftDetails?.isAllowToEdittimeSheet}
            value={shiftData?.endShiftDetails?.note}
            multiline
            minRows={3}
            maxRows={5}
          />
        </div>

        <div className="title-with-primary-bg area-title">
          <span>Break Details</span>
          <span>
            <EzyIconButton
              iconName="add"
              onClick={() => dispatch(addDeleteEndShiftBreakDetails({ type: 'ADD' }))}
              className="small"
              disabled={
                !shiftData?.endShiftDetails?.isAllowToEdittimeSheet ||
                !shiftData?.showTimesheetToEmployee
              }
            />
          </span>
        </div>
        {shiftData?.endShiftDetails?.breakDetails?.map((entity, index) => (
          <div className="add-timesheet-fields-grid" key={index}>
            <div className="d-flex flex-align-center just-bet">
              <EzySwitch
                name="isCustom"
                label="Set break start and end time"
                checked={entity?.isCustom}
                handleChange={(e) =>
                  handelBreakDetailsFields('isCustom', e?.target?.checked, index)
                }
                disabled={
                  !shiftData?.endShiftDetails?.isAllowToEdittimeSheet ||
                  !shiftData?.showTimesheetToEmployee
                }
              />
              <span>
                <EzyIconButton
                  iconName="delete"
                  onClick={() => dispatch(addDeleteEndShiftBreakDetails({ type: 'DELETE', index }))}
                  className="small"
                  disabled={
                    !shiftData?.endShiftDetails?.isAllowToEdittimeSheet ||
                    !shiftData?.showTimesheetToEmployee
                  }
                />
              </span>
            </div>
            <div className="two-grid">
              <EzyAutoComplete
                placeholder="Select type"
                label="Type"
                options={breakType}
                value={entity?.breakType}
                name="breakType"
                onChange={(_, value) => handelBreakDetailsFields('breakType', value, index)}
                error={shiftData?.endShiftDetails?.errors?.breakDetails?.[index]?.breakType}
                disabled={
                  !shiftData?.endShiftDetails?.isAllowToEdittimeSheet ||
                  !shiftData?.showTimesheetToEmployee
                }
                required
              />
              {!entity?.isCustom ? (
                <EzyAutoComplete
                  placeholder="Select duration"
                  label="Duration"
                  options={breakDurationList}
                  value={entity?.duration}
                  name="duration"
                  onChange={(_, value) => handelBreakDetailsFields('duration', value, index)}
                  error={shiftData?.endShiftDetails?.errors?.breakDetails?.[index]?.duration}
                  disabled={
                    !shiftData?.endShiftDetails?.isAllowToEdittimeSheet ||
                    !shiftData?.showTimesheetToEmployee
                  }
                  required
                />
              ) : (
                <EzyInputField
                  placeholder="0"
                  label="Duration"
                  value={entity?.duration}
                  name="duration"
                  onChange={(_, value) => handelBreakDetailsFields('duration', value, index)}
                  error={shiftData?.endShiftDetails?.errors?.breakDetails?.[index]?.duration}
                  disabled
                />
              )}
            </div>
            {entity?.isCustom && (
              <div className="two-grid">
                <EzyTime
                  name="startTime"
                  placeholder="Select time"
                  label="Start time"
                  value={entity?.startTime}
                  onChange={(value) => handelBreakDetailsFields('startTime', value, index)}
                  error={shiftData?.endShiftDetails?.errors?.breakDetails?.[index]?.startTime}
                  timeZone={shiftData?.endShiftDetails?.locationTimeZoneName}
                  disabled={
                    !shiftData?.endShiftDetails?.isAllowToEdittimeSheet ||
                    !shiftData?.showTimesheetToEmployee
                  }
                  required
                />
                <EzyTime
                  name="endTime"
                  placeholder="Select time"
                  label="End time"
                  value={entity?.endTime}
                  onChange={(value) => handelBreakDetailsFields('endTime', value, index)}
                  error={shiftData?.endShiftDetails?.errors?.breakDetails?.[index]?.endTime}
                  timeZone={shiftData?.endShiftDetails?.locationTimeZoneName}
                  disabled={
                    !shiftData?.endShiftDetails?.isAllowToEdittimeSheet ||
                    !shiftData?.showTimesheetToEmployee
                  }
                  required
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </EzyDrawer>
  );
}

export default EditShiftDrawer;
EditShiftDrawer.propTypes = {
  isFromDashboard: PropTypes.bool,
};

EditShiftDrawer.defaultProps = {
  isFromDashboard: false,
};
