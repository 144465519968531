import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { isEmpty, isNil, omitBy } from 'lodash';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import { updateAddJournalDrawerDetails, updateJournalDetails } from '../../redux/peopleSlice';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import EzyAutoComplete from '../../../../components/common/autoComplete/EzyAutoComplete';
import { requireValidate } from '../../../../utils/validationHelper';
import {
  addJournalAction,
  editJournalAction,
  getJournalListByPersonDetailIdAction,
} from '../../redux/peopleActions';

function AddJournalDrawer() {
  const dispatch = useDispatch();
  const { enums } = useSelector(({ common }) => common ?? {});
  const { personName: loggedInPersonName } = useSelector(({ user }) => user?.profileData || '');
  const params = useParams();

  const { addJournalDrawer, personDetail } = useSelector(
    ({ people }) => people?.journalDetails ?? {}
  );

  const { addJournalActionLoader, editJournalActionLoader } = useSelector(
    ({ common }) => common?.generalLoader ?? {}
  );

  const { isOpen, journalEntry, ratePerformance, errors, id } = useMemo(
    () => addJournalDrawer,
    [addJournalDrawer]
  );

  const handleClose = () => {
    dispatch(updateJournalDetails({ fieldName: 'addJournalDrawer', value: { isOpen: false } }));
  };

  const onFieldChange = (fieldName, value) => {
    dispatch(updateAddJournalDrawerDetails({ fieldName, value }));
  };

  const handleSubmit = () => {
    const data = {
      id,
      ratePerformance: ratePerformance?.id ?? null,
      journalEntry,
      personDetailId: params?.peopleId,
      createdDate: moment(new Date())?.toISOString(),
      createdByName: loggedInPersonName,
    };

    let error = {};
    error.journalEntry = requireValidate('Journal entry', data.journalEntry);
    error = omitBy(error, isNil);

    if (isEmpty(error)) {
      if (id) {
        dispatch(
          editJournalAction(data, () => {
            dispatch(
              getJournalListByPersonDetailIdAction({
                personDetailId: params?.peopleId,
                page: 1,
                limit: 10,
              })
            );
            handleClose();
          })
        );
      } else {
        dispatch(
          addJournalAction(data, () => {
            dispatch(
              getJournalListByPersonDetailIdAction({
                personDetailId: params?.peopleId,
                page: 1,
                limit: 10,
              })
            );
            handleClose();
          })
        );
      }
    } else {
      dispatch(updateAddJournalDrawerDetails({ fieldName: 'errors', value: error }));
    }
  };
  const actionButton = [
    {
      label: 'Save',
      onClick: handleSubmit,
      loading: addJournalActionLoader || editJournalActionLoader,
    },
  ];

  return (
    <EzyDrawer
      isOpen={isOpen}
      onClose={handleClose}
      heading={id ? 'Edit Journal' : 'Add Journal'}
      actionButtons={actionButton}
    >
      <div className="add-journal-drawer">
        <EzyInputField label="Person" value={personDetail?.personName} disabled />
        <EzyInputField
          label="Journal"
          placeholder="Enter message..."
          value={journalEntry}
          onChange={(e) => onFieldChange('journalEntry', e?.target?.value)}
          multiline
          required
          rows={3}
          max-rows={5}
          error={errors?.journalEntry}
        />
        <EzyAutoComplete
          label="Hashtag"
          placeholder="select hashtag"
          value={ratePerformance}
          options={enums?.ratePerformance}
          onChange={(_, value) => onFieldChange('ratePerformance', value)}
        />
      </div>
    </EzyDrawer>
  );
}

AddJournalDrawer.propTypes = {};

export default AddJournalDrawer;
