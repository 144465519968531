import React, { useState } from 'react';
import { Checkbox } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNull } from 'lodash';
import EzyAvatar from '../../../components/common/avatar/EzyAvatar';
import { updateAddTaskDrawerDetails } from '../redux/tasksSlice';
import {
  getTaskDetailsByIdAction,
  changeTaskStatusAction,
  deleteTaskAction,
  getTasksAction,
} from '../redux/tasksActions';
import ConfirmationModal from '../../../components/common/modal/ConfirmationModal';
import Loader from '../../../components/common/loader/Loader';
import { displayDate } from '../../../utils/timeHelper';

function Task({ task, taskType }) {
  const dispatch = useDispatch();
  const [deleteModalData, setDeleteModalData] = useState(null);
  const { personId } = useSelector(({ user }) => user?.profileData ?? {});
  const { taskCompletionStatus: taskCompletionStatusEnum } = useSelector(
    ({ common }) => common?.enums ?? {}
  );
  const { deleteTaskActionLoader } = useSelector(({ common }) => common.generalLoader ?? {});

  const ViewOrEditTask = async () => {
    dispatch(
      updateAddTaskDrawerDetails({
        fieldName: 'drawerTitle',
        value: taskType === 'My tasks' && task?.createdBy !== personId ? 'View Task' : 'Edit Task',
      })
    );
    dispatch(getTaskDetailsByIdAction({ taskId: task?.taskId, myTask: taskType === 'My tasks' }));
  };

  const handleDeleteTask = async () => {
    await dispatch(
      deleteTaskAction({ taskId: task?.taskId }, () => {
        dispatch(
          getTasksAction({
            myTask: taskType === 'My tasks',
            page: 1,
            limit: 15,
            searchText: null,
          })
        );
      })
    );
  };

  const handleStatusChange = async () => {
    await dispatch(
      changeTaskStatusAction(
        {
          taskId: task.taskId,
          status: taskCompletionStatusEnum
            .filter((e) => e.id !== task?.status)
            .map((e) => e.id)?.[0],
        },
        () => {
          dispatch(
            getTasksAction({
              myTask: taskType === 'My tasks',
              page: 1,
              limit: 15,
              searchText: null,
            })
          );
        }
      )
    );
  };

  return (
    <>
      <div className="task-wrapper">
        {deleteTaskActionLoader && <Loader />}
        <div className="flex-center gap-5">
          <Checkbox checked={task?.status === 2} onClick={handleStatusChange} />

          <div className={`text-secondary-color  ${task?.status === 2 ? 'text-line-through' : ''}`}>
            {task.title}
          </div>
        </div>

        <div className="action-group">
          {task?.dueDate && (
            <span className="text-secondary-color body-text">
              {displayDate(task?.dueDate, task?.timeZoneId?.label)}
            </span>
          )}
          {taskType && (
            <EzyAvatar
              imageLink={
                taskType === 'My tasks'
                  ? task?.createdByProfilePhotoUrl
                  : task?.assigendToProfilePhotoUrl
              }
              firstName={taskType === 'My tasks' ? task?.createdByName : task?.assigendToName}
              className="profile-picture"
            />
          )}
          <span
            className={`material-icons pointer-cursor ${
              taskType === 'My tasks' && task?.createdBy !== personId
                ? 'text-secondary-color'
                : 'secondary-color'
            }`}
            onClick={ViewOrEditTask}
          >
            {taskType === 'My tasks' && task?.createdBy !== personId ? 'info' : 'edit'}
          </span>

          <span
            className={`material-icons pointer-cursor error-color ${
              taskType === 'My tasks' && task?.createdBy !== personId ? 'no-delete' : ''
            }`}
            onClick={(e) => setDeleteModalData(e)}
          >
            delete
          </span>
        </div>
      </div>
      <ConfirmationModal
        title="Delete Task"
        isOpen={!isEmpty(deleteModalData) || !isNull(deleteModalData)}
        handleClose={() => {
          setDeleteModalData(null);
        }}
        handleSubmit={async () => {
          await handleDeleteTask();
          setDeleteModalData(null);
        }}
        primaryLabel="Delete"
      >
        <div>Are you sure you want to delete?</div>
      </ConfirmationModal>
    </>
  );
}

Task.propTypes = {
  task: PropTypes.object.isRequired,
  taskType: PropTypes.string.isRequired,
};

export default Task;
