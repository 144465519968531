import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Chip, InputAdornment } from '@mui/material';
import moment from 'moment';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import validateAddTaskForArea from '../validations/validateAddTaskForArea';
import EzyDrawer from '../../../components/common/drawer/Drawer';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import EzyIconButton from '../../../components/common/iconButton/EzyIconButton';
import EzySwitch from '../../../components/common/switch/EzySwitch';
import EzyAutoComplete from '../../../components/common/autoComplete/EzyAutoComplete';
import { getShiftDropdownEntitiesAction } from '../../schedule/redux/scheduleActions';
import {
  addDeleteSubTasks,
  resetAddTaskForAreaDrawer,
  updateAddTaskForAreaDrawerDetails,
  updateTaskListFilters,
} from '../redux/tasksSlice';
import {
  addTasksForWholeAreaAction,
  deleteTaskAction,
  editTaskForWholeAreaAction,
  getTasksByAreaAction,
} from '../redux/tasksActions';
import EzyDate from '../../../components/common/dateAndTimePicker/EzyDate';
import { TIME_FORMAT } from '../../../utils/constants';
import { getRuleListAction } from '../../insights/redux/insightAction';
import EzyButton from '../../../components/common/button/EzyButton';

function AddTaskForWholeAreaDrawer({ isFromLaborModal }) {
  const { filters, addTaskForAreaDrawer } = useSelector(({ tasks }) => tasks ?? {});

  const { filters: insightFilter } = useSelector(({ insight }) => insight ?? {});

  const {
    description: descriptionEnum,
    datesOfMonthList: datesOfMonthListEnum,
    weekDays: weekDaysEnum,
  } = useSelector(({ common }) => common?.enums ?? {});

  const { addTasksForWholeAreaActionLoader, editTaskForWholeAreaActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const {
    areaListWithCode,
    isEditCategory,
    drawerTitle,
    task,
    subTasks,
    selectedArea,
    startTime,
    repeat,
    date,
    weekDays,
    datesOfMonth,
    errors,
    isAddToLaborModel,
  } = useMemo(() => addTaskForAreaDrawer, [addTaskForAreaDrawer]);

  const dispatch = useDispatch();

  const handleFieldChange = (name, value, index) => {
    dispatch(updateAddTaskForAreaDrawerDetails({ fieldName: name, value, index }));
  };

  const handleClose = () => {
    dispatch(resetAddTaskForAreaDrawer());
  };

  const handleSubmit = async () => {
    const data = {
      taskTitle: task,
      isAddToLaborModel: isFromLaborModal || isAddToLaborModel,
      subTasks: subTasks.map((subTask) => {
        return {
          subTaskTitle: subTask.subTask,
          canCompletedMultipleTimes: subTask.isCustom,
          note: subTask.note,
          totalHour: subTask?.totalHour,
          totalMinute: subTask?.totalMinute,
        };
      }),
      assignedArea: selectedArea?.id,
      startTime,
      taskRepeatationFrequency: repeat?.id,
      date: date ?? null,
      weekDays: weekDays?.length
        ? weekDaysEnum?.filter((item) => weekDays?.includes(item.label))?.map((item) => item.id)
        : null,
      datesOfMonth: datesOfMonth?.length
        ? datesOfMonthListEnum
            ?.filter((item) => datesOfMonth?.includes(item.label))
            ?.map((item) => item.id)
        : null,
    };
    const error = validateAddTaskForArea(data);
    handleFieldChange('errors', error);
    if (isEmpty(error)) {
      if (drawerTitle !== 'Edit Task') {
        dispatch(
          addTasksForWholeAreaAction(data, () => {
            handleClose();
            if (!isFromLaborModal) {
              dispatch(
                getTasksByAreaAction({
                  locationId: filters?.selectedLocation,
                  date: moment(filters?.date).toISOString(),
                })
              );
            } else {
              dispatch(
                getRuleListAction({
                  locationId: insightFilter?.selectedLocation?.id,
                })
              );
            }
          })
        );
        dispatch(updateTaskListFilters({ fieldName: 'taskType', value: 'Tasks By Area' }));
        dispatch(updateTaskListFilters({ fieldName: 'date', value: filters?.date }));
        dispatch(
          updateTaskListFilters({ fieldName: 'selectedLocation', value: filters?.selectedLocation })
        );
      } else {
        const editData = {
          taskId: isEditCategory,
          taskTitle: task,
          isAddToLaborModel: isFromLaborModal || isAddToLaborModel,
          subTasks: subTasks.map((subTask) => {
            return {
              subTaskId: subTask.subTaskId,
              subTaskTitle: subTask.subTask,
              canCompletedMultipleTimes: subTask.isCustom,
              note: subTask.note,
              totalHour: subTask?.totalHour,
              totalMinute: subTask?.totalMinute,
            };
          }),
          startTime,
          taskRepeatationFrequency: repeat.id,
          date: date ?? null,
          weekDays: weekDays?.length
            ? weekDaysEnum?.filter((item) => weekDays?.includes(item.label))?.map((item) => item.id)
            : null,
          datesOfMonth: datesOfMonth?.length
            ? datesOfMonthListEnum
                ?.filter((item) => datesOfMonth?.includes(item.label))
                ?.map((item) => item.id)
            : null,
        };
        dispatch(
          editTaskForWholeAreaAction(editData, () => {
            handleClose();
            if (!isFromLaborModal) {
              dispatch(
                getTasksByAreaAction({
                  locationId: filters?.selectedLocation,
                  date: moment(filters?.date).toISOString(),
                })
              );
            } else {
              dispatch(
                getRuleListAction({
                  locationId: insightFilter?.selectedLocation?.id,
                })
              );
            }
          })
        );
        dispatch(updateTaskListFilters({ fieldName: 'taskType', value: 'Tasks By Area' }));
        dispatch(updateTaskListFilters({ fieldName: 'date', value: filters?.date }));
        dispatch(
          updateTaskListFilters({ fieldName: 'selectedLocation', value: filters?.selectedLocation })
        );
      }
    }

    ['taskTitle', 'assignedArea', 'repeat', 'startTime', 'date', 'weekDays', 'datesOfMonth']?.map(
      (e) => {
        if (error?.[e] !== null && error?.[e] !== undefined) {
          document.getElementById(e)?.scrollIntoView({ behavior: 'smooth' });
          return null;
        }
        return null;
      }
    );
    if (error?.subTasks) {
      error?.subTasks?.map((subtask, i) => {
        ['subTaskTitle', 'totalHour', 'totalMinute']?.map((field) => {
          if (subtask?.[field]) {
            document.getElementById(`${field}-${i}`)?.scrollIntoView({ behavior: 'smooth' });
            return null;
          }
          return null;
        });

        return null;
      });
    }
  };

  const actionButton = [
    {
      label: 'Save',
      onClick: handleSubmit,
      loading: addTasksForWholeAreaActionLoader || editTaskForWholeAreaActionLoader,
    },
  ];

  const timeIntervalFunction = () => {
    const x = {
      slotInterval: 30,
      openTime: moment(new Date()).startOf('day'),
      closeTime: moment(new Date()).endOf('day'),
    };

    const beginTime = moment(x.openTime);

    const endTime = moment(x.closeTime);

    const allTimes = [];

    while (beginTime < endTime) {
      allTimes.push(moment(beginTime).toISOString());
      beginTime.add(x.slotInterval, 'minutes');
    }

    return allTimes;
  };

  useEffect(() => {
    (async () => {
      if (drawerTitle) {
        await dispatch(
          getShiftDropdownEntitiesAction({
            locationId: isFromLaborModal
              ? insightFilter?.selectedLocation?.id
              : filters?.selectedLocation,
          })
        );

        if (drawerTitle === 'Tasks For Whole Area' || drawerTitle === 'Add Task') {
          handleFieldChange('startTime', timeIntervalFunction()[0]);
          handleFieldChange('repeat', descriptionEnum?.[0]);
          handleFieldChange('date', date);
        }
      }
    })();
  }, [drawerTitle]);

  return (
    <EzyDrawer
      heading={drawerTitle}
      isOpen={drawerTitle}
      actionButtons={actionButton}
      onClose={handleClose}
      className="task-for-whole-area-drawer-wrapper"
      leftAction={
        isFromLaborModal && drawerTitle === 'Edit Task' ? (
          <EzyButton
            label="Delete"
            color="error"
            onClick={() => {
              dispatch(
                deleteTaskAction({ taskId: isEditCategory }, () => {
                  handleClose();
                  dispatch(
                    getRuleListAction({
                      locationId: insightFilter?.selectedLocation?.id,
                    })
                  );
                })
              );
            }}
          />
        ) : null
      }
    >
      <div className="task-for-whole-area-drawer">
        <EzyInputField
          label="Task"
          name="title"
          placeholder="Enter Task"
          value={task}
          onChange={(e) => {
            handleFieldChange('task', e.target.value);

            if (subTasks[0].subTask === task || subTasks[0].subTask === null) {
              handleFieldChange('subTask', e.target.value, 0);
            }
          }}
          id="taskTitle"
          error={errors?.taskTitle}
          required
        />
        <EzySwitch
          label="Add to labor model"
          subLabel="Factor tasks into your required staff in Business insight"
          onChange={(e) => handleFieldChange('isAddToLaborModel', e?.target?.checked)}
          checked={isFromLaborModal || isAddToLaborModel}
          disabled={isFromLaborModal}
        />
      </div>

      {subTasks.map((entity, index) => {
        return (
          <div key="index" className="sub-task">
            <div className="title-with-primary-bg area-title">
              <span>Sub Tasks</span>
              <span>
                {index >= 1 ? (
                  <EzyIconButton
                    iconName="delete"
                    onClick={() => dispatch(addDeleteSubTasks({ type: 'DELETE', index }))}
                    className="small"
                  />
                ) : (
                  <EzyIconButton
                    iconName="add"
                    onClick={() => dispatch(addDeleteSubTasks({ type: 'ADD' }))}
                    className="small"
                  />
                )}
              </span>
            </div>
            <div className="d-flex-column gap-10 p-24">
              <EzyInputField
                label="Sub Tasks"
                name="title"
                placeholder="Enter Sub Task"
                value={entity?.subTask}
                onChange={(e) => handleFieldChange('subTask', e.target.value, index)}
                id={`subTaskTitle-${index}`}
                error={errors?.subTasks?.[index]?.subTaskTitle}
                required
              />
              {(isFromLaborModal || isAddToLaborModel) && (
                <>
                  <div>Time Required</div>
                  <div className="two-grid">
                    <EzyInputField
                      name="totalHour"
                      placeholder="Enter hour"
                      value={entity?.totalHour}
                      type="number"
                      onChange={(e) => handleFieldChange('totalHour', e?.target?.value, index)}
                      endAdornment={<InputAdornment position="end">hr</InputAdornment>}
                      id={`totalHour-${index}`}
                      error={errors?.subTasks?.[index]?.totalHour}
                    />
                    <EzyInputField
                      name="totalMinute"
                      placeholder="Enter minute"
                      value={entity?.totalMinute}
                      type="number"
                      onChange={(e) => handleFieldChange('totalMinute', e?.target?.value, index)}
                      endAdornment={<InputAdornment position="end">min</InputAdornment>}
                      id={`totalMinute-${index}`}
                      error={errors?.subTasks?.[index]?.totalMinute}
                    />
                  </div>
                </>
              )}
              <EzySwitch
                name="isCustom"
                label="Can be completed multiple times"
                checked={entity?.isCustom}
                handleChange={(e) => handleFieldChange('isCustom', e?.target?.checked, index)}
              />
              <EzyInputField
                label="Notes"
                placeholder="Write the notes..."
                multiline
                rows={3}
                max-rows={5}
                value={entity?.note}
                onChange={(e) => handleFieldChange('note', e.target.value, index)}
              />
            </div>
          </div>
        );
      })}

      <div className="task-for-whole-area-drawer">
        {(isFromLaborModal || isAddToLaborModel) && (
          <div>
            <div>Total</div>
            <div className="two-grid">
              <EzyInputField
                value={Math.floor(
                  subTasks.reduce(
                    (prev, curr) => +prev + +curr.totalHour * 60 + +curr.totalMinute,
                    0
                  ) / 60
                )}
                disabled
              />
              <EzyInputField
                value={Math.round(
                  (+subTasks.reduce(
                    (prev, curr) => +prev + +curr.totalHour * 60 + +curr.totalMinute,
                    0
                  ) /
                    60 -
                    +Math.floor(
                      subTasks.reduce(
                        (prev, curr) => +prev + +curr.totalHour * 60 + +curr.totalMinute,
                        0
                      ) / 60
                    )) *
                    60
                )}
                disabled
              />
            </div>
          </div>
        )}
        <EzyAutoComplete
          placeholder="Select Area"
          label="Assign to"
          options={areaListWithCode}
          value={selectedArea}
          onChange={(_, value) => {
            handleFieldChange('selectedArea', value);
          }}
          id="assignedArea"
          error={errors?.assignedArea}
          required
        />
        <div className="two-grid">
          <EzyAutoComplete
            placeholder="Select time"
            label="Start Time"
            options={timeIntervalFunction()}
            value={startTime}
            getOptionLabel={(option) => moment(option).format(TIME_FORMAT)}
            onChange={(_, value) => {
              handleFieldChange('startTime', value);
            }}
            id="startTime"
            error={errors?.startTime}
            required
          />
          <EzyAutoComplete
            placeholder="Select time"
            label="Repeat"
            options={descriptionEnum}
            value={repeat}
            onChange={(_, value) => {
              handleFieldChange('repeat', value);
            }}
            id="repeat"
            error={errors?.repeat}
            required
          />
        </div>
        {repeat?.label === "Don't repeat" && (
          <EzyDate
            label="Date"
            placeholder="Enter date"
            value={repeat?.label === "Don't repeat" && date}
            onChange={(value) => handleFieldChange('date', value)}
            id="date"
            error={errors?.date}
            required={repeat?.id === 1}
          />
        )}
        {repeat?.label === 'Weekly' && (
          <EzyAutoComplete
            multiple={repeat?.label === 'Weekly'}
            placeholder="Select days"
            options={weekDaysEnum?.map((e) => e.label)}
            value={repeat?.label === 'Weekly' && weekDays}
            className={`${weekDays?.length ? 'auto-complete-tags ' : ''}`}
            label="On day"
            renderTags={(value, getTagProps) =>
              value.map((option, chipIndex) => (
                <Chip
                  variant="outlined"
                  color="primary"
                  label={option}
                  deleteIcon={<span className="material-icons">highlight_off</span>}
                  {...getTagProps({ chipIndex })}
                />
              ))
            }
            onChange={(_, value) => {
              handleFieldChange('weekDays', value);
            }}
            id="weekDays"
            error={errors?.weekDays}
            required={repeat?.id === 3}
          />
        )}
        {repeat?.label === 'Monthly' && (
          <EzyAutoComplete
            multiple={repeat?.label === 'Monthly'}
            placeholder="Select dates"
            options={datesOfMonthListEnum?.map((e) => e.label)}
            value={repeat?.label === 'Monthly' && datesOfMonth}
            className={`${datesOfMonth?.length ? 'auto-complete-tags ' : ''}`}
            label="Dates"
            renderTags={(value, getTagProps) =>
              value.map((option, chipIndex) => (
                <Chip
                  variant="outlined"
                  color="primary"
                  label={option}
                  deleteIcon={<span className="material-icons">highlight_off</span>}
                  {...getTagProps({ chipIndex })}
                />
              ))
            }
            onChange={(e, value) => {
              handleFieldChange('datesOfMonth', value);
            }}
            id="datesOfMonth"
            error={errors?.datesOfMonth}
            required={repeat?.id === 4}
          />
        )}
      </div>
    </EzyDrawer>
  );
}

AddTaskForWholeAreaDrawer.propTypes = {
  isFromLaborModal: PropTypes.bool,
};

AddTaskForWholeAreaDrawer.defaultProps = {
  isFromLaborModal: false,
};

export default AddTaskForWholeAreaDrawer;
