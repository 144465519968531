import * as React from 'react';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useCallback } from 'react';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useUrlParamsUpdate from '../../../../hooks/useURLParamsUpdate';
import useQueryParams from '../../../../hooks/useQueryParams';
import EzyTable from '../../../../components/common/table/EzyTable';
import EzyPagination from '../../../../components/common/pagination/EzyPagination';
import { getPreviousShiftsAction } from '../../redux/peopleActions';
import AddTimeSheetDrawer from '../../../timeSheets/components/AddTimeSheetDrawer';
import { updateTimeSheetData } from '../../../timeSheets/redux/timeSheetsSlice';
import ViewTimeSheetDrawer from '../../../timeSheets/components/ViewTimeSheetDrawer';

function PreviouslyWorkedShifts({ isFromProfile }) {
  const { previousShifts } = useSelector(({ people }) => people.shiftDetails ?? {});
  // const history = useHistory();

  const { docs, headers, total, pages, page, limit, profileHeaders } = useMemo(
    () => previousShifts ?? {},
    [previousShifts]
  );

  const { getPreviousShiftsActionLoader, getTimeSheetViewDetailsActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const { peopleId } = useParams();

  const { personId: loggedInPersonId } = useSelector(({ user }) => user?.profileData ?? {});

  const { page: paramPage, limit: paramLimit } = useQueryParams();
  const dispatch = useDispatch();

  useUrlParamsUpdate({
    page: page ?? 1,
    limit: limit ?? 15,
  });

  const getShiftListByFilter = async (params = {}) => {
    const data = {
      page: page ?? 1,
      limit: limit ?? 15,
      personDetailsId: isFromProfile ? loggedInPersonId : peopleId,
      ...params,
    };
    await dispatch(getPreviousShiftsAction(data));
  };

  const pageActionClick = async (newPage) => {
    await getShiftListByFilter({ page: newPage, limit });
  };
  const onSelectLimit = async (newLimit) => {
    await getShiftListByFilter({ page: 1, limit: newLimit });
  };

  const onViewClick = (e) => {
    if (isFromProfile && e?.shiftId) {
      dispatch(updateTimeSheetData({ fieldName: 'viewTimeSheetDrawer', value: e?.shiftId }));
    }
  };

  const getActions = (row) => {
    const actions = [];
    actions.push({
      label: 'View',
      onClick: (e) => {
        onViewClick(e);
      },
      access: 'VIEWTSPRF',
    });
    if (![3, 5].includes(row?.progressStatus) && row?.isAllowToEditTimeSheet) {
      actions.push({
        label: 'Edit',
        onClick: (e) => {
          dispatch(updateTimeSheetData({ fieldName: 'editTimeSheetDrawer', value: e?.shiftId }));
        },
        access: 'EDITTSPRF',
      });
    }
    return actions;
  };

  const getRowData = useCallback(
    () =>
      docs?.map((dt) => ({
        ...dt,
        action: getActions(dt),
      })),
    [docs]
  );

  useEffect(() => {
    const params = {
      page: paramPage ?? page ?? 1,
      limit: paramLimit ?? limit ?? 15,
    };
    getShiftListByFilter({ ...params });
  }, []);

  return (
    <Paper className="table-paper time-sheets-wrapper">
      <EzyTable
        headers={isFromProfile ? profileHeaders : headers}
        rows={isFromProfile ? getRowData() : docs}
        loading={getTimeSheetViewDetailsActionLoader ? false : getPreviousShiftsActionLoader}
        className="table-wrapper"
        onRowClick={onViewClick}
      />
      <EzyPagination
        total={total}
        pages={pages}
        pageActionClick={pageActionClick}
        limit={limit}
        page={page}
        onChangeLimit={onSelectLimit}
      />
      <AddTimeSheetDrawer getTimeSheetsList={getShiftListByFilter} isFromProfile={isFromProfile} />
      <ViewTimeSheetDrawer getTimeSheetsList={getShiftListByFilter} isFromProfile={isFromProfile} />
    </Paper>
  );
}

export default PreviouslyWorkedShifts;

PreviouslyWorkedShifts.propTypes = {
  isFromProfile: PropTypes.bool,
};

PreviouslyWorkedShifts.defaultProps = {
  isFromProfile: false,
};
