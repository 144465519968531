import { styled } from '@mui/material/styles';
// import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { FormControl } from '@mui/material';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers';
import { useState } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';
// import EzyInputField from '../inputField/EzyInputField';
import EzyIconButton from '../iconButton/EzyIconButton';
import { displayDate } from '../../../utils/timeHelper';
import EzyButton from '../button/EzyButton';

function ButtonField(props) {
  const {
    setOpen,
    label,
    id,
    InputProps: { ref } = {},
    inputProps: { 'aria-label': ariaLabel } = {},
  } = props;

  return (
    <EzyButton
      variant="outlined"
      id={id}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
      label={label}
    />
  );
}

ButtonField.propTypes = {
  InputProps: PropTypes.object.isRequired,
  setOpen: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  inputProps: PropTypes.object.isRequired,
};

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
}));

function WeekPicker({
  value,
  onChange,
  className,
  variant,
  onPrevClick,
  onNextClick,
  customValueDisplay,
  OffWeekPicker,
  startWeekFrom,
  timeZone,
  ...restProps
}) {
  const [open, setOpen] = useState(false);
  moment.updateLocale('en', {
    week: {
      dow: startWeekFrom === 7 ? 0 : startWeekFrom,
    },
  });

  const renderWeekPickerDay = (prop) => {
    const { day, selectedDay, ...other } = prop;
    if (!value?.startDate) {
      return <PickersDay day={day} {...other} />;
    }
    if (selectedDay == null) {
      return <PickersDay day={day} {...other} />;
    }

    const start = moment(value?.startDate);
    const end = moment(value?.endDate);

    const dayIsBetween = moment(day).isBetween(start, end, null, '[]');

    const isFirstDay = moment(day).isSame(start, 'day');
    const isLastDay = moment(day).isSame(end, 'day');

    return (
      <CustomPickersDay
        {...other}
        day={day}
        sx={dayIsBetween ? { px: 2.5, mx: 0 } : {}}
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  return (
    <FormControl variant="standard" className={`week-picker ${className}`}>
      <EzyIconButton
        className="left-button"
        iconName="navigate_before"
        onClick={() => {
          if (onPrevClick) {
            onPrevClick({
              startDate: moment.tz(value?.startDate, timeZone).subtract(7, 'days'),
              endDate: moment.tz(value?.endDate, timeZone).subtract(7, 'days'),
            });
          } else
            onChange({
              startDate: moment.tz(value?.startDate, timeZone).subtract(7, 'days'),
              endDate: moment.tz(value?.endDate, timeZone).subtract(7, 'days'),
            });
        }}
        variant={variant}
      />
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en">
        <DatePicker
          className="MuiButtonBase-root"
          value={value?.startDate}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          slotProps={{
            textField: {
              onClick: () => setOpen(true),
            },
            day: {
              selectedDay: value,
            },
            field: { setOpen },
          }}
          slots={{
            openPickerButton: () => null,
            day: renderWeekPickerDay,
            field: ButtonField,
          }}
          label={`${displayDate(value?.startDate, timeZone)} - ${displayDate(
            value?.endDate,
            timeZone
          )}`}
          onChange={(newValue) => {
            if (OffWeekPicker) {
              onChange({
                startDate: moment.tz(newValue, timeZone).toISOString(),
              });
            } else
              onChange({
                startDate: moment.tz(newValue, timeZone).startOf('week'),
                endDate: moment.tz(newValue, timeZone).endOf('week'),
                selectedDate: newValue,
              });
          }}
          {...restProps}
        />
      </LocalizationProvider>
      <EzyIconButton
        className="right-button"
        iconName="navigate_next"
        onClick={() => {
          if (onNextClick) {
            onNextClick({
              startDate: moment.tz(value?.startDate, timeZone).add(7, 'days'),
              endDate: moment.tz(value?.endDate, timeZone).add(7, 'days'),
            });
          } else
            onChange({
              startDate: moment.tz(value?.startDate, timeZone).add(7, 'days'),
              endDate: moment.tz(value?.endDate, timeZone).add(7, 'days'),
            });
        }}
        variant={variant}
      />
    </FormControl>
  );
}

export default WeekPicker;
WeekPicker.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  className: PropTypes.string,
  variant: PropTypes.string,
  onPrevClick: PropTypes.func,
  onNextClick: PropTypes.func,
  customValueDisplay: PropTypes.string,
  OffWeekPicker: PropTypes.bool,
  startWeekFrom: PropTypes.string,
  timeZone: PropTypes.string,
};
WeekPicker.defaultProps = {
  value: '',
  onChange: () => {},
  className: '',
  variant: 'contained',
  onPrevClick: null,
  onNextClick: null,
  customValueDisplay: null,
  OffWeekPicker: false,
  startWeekFrom: 0,
  timeZone: moment.tz.guess(),
};
