import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router';
import {
  addDeleteLocationAreas,
  addDeleteLocationPeople,
  cleanAddressLocationAreas,
  resetAddLocationDetail,
  setAddLocationList,
  updateLocationAreas,
  updateLocationPeople,
} from '../redux/locationSlice';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import EzyAutoComplete from '../../../components/common/autoComplete/EzyAutoComplete';
import EzyIconButton from '../../../components/common/iconButton/EzyIconButton';
import EzyPhoneNumber from '../../../components/common/phoneNumberInput/ezyPhoneNumber';
import PeopleImport from './PeopleImport';
import EzySwitchButton from '../../../components/common/switch/EzySwitchButton';
import EzyIconTooltip from '../../../components/common/tooltip/EzyIconTooltip';
// import { importPeopleFromIntegrationAction } from '../../people/redux/peopleActions';
import { getAllIntegrationsListAction } from '../../globalProfile/redux/globalProfileActions';
import EzyDialogTitle from '../../../components/common/dialog/EzyDialogTitle';
import EzyDialog from '../../../components/common/dialog/EzyDialog';
import EzyDialogAction from '../../../components/common/dialog/EzyDialogAction';
import ROUTE_CONSTANTS, { WINDOW_OPEN_MESSAGE } from '../../../utils/constants';
import { integrationLoginUrlAction, peopleFromIntegrationAction } from '../redux/locationActions';
import EzyButton from '../../../components/common/button/EzyButton';
import IconByName from '../../../components/common/iconByName/IconByName';
// import Loader from '../../../components/common/loader/Loader';

function AddLocation() {
  const addLocationData = useSelector(({ location }) => location.addLocationData || {});

  const { integrationList } = useSelector(
    ({ globalProfile }) => globalProfile?.appIntegration || {}
  );

  const { integrationLoginUrlActionLoader, peopleFromIntegrationActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const openedWindow = useRef();
  // const { businessId } = useSelector(({ user }) => user?.profileData || {});

  const { timeZonesList } = useSelector(({ common }) => common ?? {});

  const optionList = useSelector(({ common }) => common.enums);

  const dispatch = useDispatch();

  const {
    locationName,
    locationAddress,
    weekStartOn,
    timeZoneId,
    addAreaAddress,
    sendInvitationEmail,
    areas,
    people,
    errors,
    warningMessage,
    isWarningModal,
    city,
  } = useMemo(() => addLocationData, [addLocationData]);

  const history = useHistory();

  const handleFieldChange = (name, value) => {
    dispatch(setAddLocationList({ fieldName: name, value }));
  };

  const updateAreaAndPeopleFields = (name, value, index, target) => {
    if (target === 'area') {
      dispatch(updateLocationAreas({ fieldName: name, value, index }));
    } else {
      dispatch(updateLocationPeople({ fieldName: name, value, index }));
    }
  };

  const handleGetPeople = (loginURL) => {
    if (loginURL) {
      openedWindow.current = window.open(loginURL, 'PopupWindow', 'height=570,width=520');
    }
  };

  const handleGetPeopleList = (event) => {
    if (event?.data?.message === WINDOW_OPEN_MESSAGE) {
      openedWindow?.current?.close();

      const search = new URLSearchParams(event?.data?.searchParams);

      const params = {};
      Array.from(search.entries()).forEach(([k, v]) => {
        params[k] = v;
      });

      dispatch(
        peopleFromIntegrationAction({
          integrationId: params?.state,
          code: params?.code,
          realmId: params?.realmId,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(getAllIntegrationsListAction());

    window?.opener?.postMessage(
      {
        href: window?.location?.href,
        searchParams: window?.location?.search,
        message: WINDOW_OPEN_MESSAGE,
      },
      '*'
    );

    window.addEventListener('message', (event) => {
      handleGetPeopleList(event);
    });

    return () => {
      dispatch(resetAddLocationDetail());

      window.removeEventListener('message', (event) => {
        handleGetPeopleList(event);
      });
    };
  }, []);

  return (
    <div className="add-location-root-container">
      <div className="add-location-title-header">Location</div>
      <div className="two-grid p-x-24">
        <EzyInputField
          name="locationName"
          placeholder="Enter location name"
          value={locationName}
          label="Location name"
          onChange={(e) => {
            if (e.target.value.length <= 50) handleFieldChange('locationName', e.target.value);
          }}
          error={errors?.locationName}
          required
        />
        <EzyInputField
          name="locationAddress"
          placeholder="Enter location address"
          label="Location address"
          value={locationAddress}
          inputProps={{
            maxLength: 300,
          }}
          multiline
          maxRows={2}
          onChange={(e) => handleFieldChange('locationAddress', e.target.value)}
          error={errors?.locationAddress}
          required
        />
      </div>
      <div className="three-grid p-x-24">
        <EzyInputField
          label="City"
          placeholder="Enter city name"
          name="city"
          value={city}
          onChange={(e) => handleFieldChange('city', e?.target?.value)}
          error={errors?.city}
          required
        />
        <EzyAutoComplete
          name="weekStartOn"
          placeholder="Select day"
          label="Week starts on"
          options={optionList?.weekDays}
          value={weekStartOn}
          onChange={(e, value) => {
            handleFieldChange('weekStartOn', value);
          }}
          error={errors?.weekStartOn}
          required
        />
        <EzyAutoComplete
          name="timeZoneId"
          placeholder="Enter location address"
          label="Location time zone"
          value={timeZoneId}
          options={timeZonesList}
          onChange={(e, value) => handleFieldChange('timeZoneId', value)}
          error={errors?.timeZoneId}
          required
        />
      </div>
      <div className="add-location-title-header">
        <span>
          Areas
          <EzyIconTooltip tooltip=" In payrollEZY, people are scheduled to Areas of work. This can be roles, departments, physical areas." />
        </span>

        <div className="location-sub-header-button-container">
          <span>Add Area Address</span>
          <EzySwitchButton
            name="addAreaAddress"
            checked={addAreaAddress}
            onChange={() => {
              handleFieldChange('addAreaAddress', !addAreaAddress);
              dispatch(cleanAddressLocationAreas());
            }}
            className="small"
          />
        </div>
      </div>
      {areas?.map((res, index) => (
        <div className="two-grid p-x-24">
          <EzyInputField
            placeholder="Enter area name"
            name="areaOfWork"
            label="Area name"
            value={res.areaOfWork}
            onChange={(value) => {
              if (value.target.value.length <= 50)
                updateAreaAndPeopleFields('areaOfWork', value?.target?.value, index, 'area');
            }}
            error={errors?.areas?.[index]?.areaOfWork}
          />
          <div className="d-flex gap-10">
            {addAreaAddress && (
              <EzyInputField
                name="address"
                placeholder="Enter area address"
                label="Area address"
                value={res.address}
                inputProps={{
                  maxLength: 300,
                }}
                multiline
                maxRows={2}
                onChange={(value) =>
                  updateAreaAndPeopleFields('address', value?.target?.value, index, 'area')
                }
                error={errors?.areas?.[index]?.address}
              />
            )}
            {index >= 1 ? (
              <EzyIconButton
                iconName="delete"
                onClick={() => dispatch(addDeleteLocationAreas({ type: 'DELETE', index }))}
                className={
                  errors?.areas?.[index]?.address || errors?.areas?.[index]?.areaOfWork
                    ? 'align-self-center'
                    : 'align-self-end'
                }
              />
            ) : (
              <EzyIconButton
                iconName="add"
                onClick={() => dispatch(addDeleteLocationAreas({ type: 'ADD' }))}
                className={
                  errors?.areas?.[index]?.address || errors?.areas?.[index]?.areaOfWork
                    ? 'align-self-center'
                    : 'align-self-end'
                }
              />
            )}
          </div>
        </div>
      ))}
      <div className="add-location-title-header">
        <span>
          People
          <EzyIconTooltip tooltip="Team members can be manually added or imported from a CSV file." />
          <EzyButton
            label="Add"
            onClick={() => dispatch(addDeleteLocationPeople({ type: 'ADD' }))}
            startIcon={<IconByName name="add" />}
            className="no-pad-button"
          />
        </span>
        <div className="location-sub-header-button-container">
          <span>Send Invite Email</span>
          <EzySwitchButton
            name="sendInvitationEmail"
            checked={sendInvitationEmail}
            disabled={!people?.some((e) => e?.email)}
            onChange={() => handleFieldChange('sendInvitationEmail', !sendInvitationEmail)}
            className="small"
          />
        </div>
      </div>
      <div className="three-grid p-x-24">
        <EzyAutoComplete
          placeholder="Import people from"
          label="Import people"
          loading={peopleFromIntegrationActionLoader || integrationLoginUrlActionLoader}
          options={[
            { id: 1, label: 'CSV' },
            ...integrationList.map((e) => {
              return { id: e?.integrationId, label: e?.integrationName };
            }),
          ]}
          onChange={(_, value) => {
            if (value?.label === 'CSV') {
              handleFieldChange('selectedUploader', value?.label);
              handleFieldChange('uploaderIndex', 0);
            } else {
              dispatch(
                integrationLoginUrlAction(
                  {
                    integrationId: value?.id,
                  },
                  (data) => {
                    handleGetPeople(data);
                  }
                )
              );
            }
          }}
        />
      </div>
      {people?.map((res, index) => (
        <div className="three-grid p-x-24" key={index}>
          <EzyInputField
            name="name"
            placeholder="Enter name"
            label="People name"
            value={res?.name}
            error={errors?.people?.[index]?.name}
            onChange={(value) =>
              updateAreaAndPeopleFields('name', value?.target?.value, index, 'people')
            }
          />
          <EzyInputField
            placeholder="Enter email"
            name="email"
            label="Email"
            value={res?.email}
            error={errors?.people?.[index]?.email}
            onChange={(value) => {
              updateAreaAndPeopleFields('email', value?.target?.value, index, 'people');
              if (res?.email === '' || value?.target?.value === '') {
                handleFieldChange('sendInvitationEmail', !!value?.target?.value);
              }
            }}
          />
          <div className="d-flex gap-10">
            <EzyPhoneNumber
              country="au"
              name="phoneNumber"
              placeholder="4 123 456 789"
              value={res?.phoneNumber}
              error={errors?.people?.[index]?.phoneNumber}
              label="Mobile number"
              onChange={(value, data) => {
                updateAreaAndPeopleFields('phoneNumber', value, index, 'people');
                updateAreaAndPeopleFields('countryCode', data?.dialCode, index, 'people');
                updateAreaAndPeopleFields('phoneNumberData', data, index, 'people');
              }}
            />

            <EzyIconButton
              iconName="delete"
              onClick={() => dispatch(addDeleteLocationPeople({ type: 'DELETE', index }))}
              className={
                errors?.people?.[index]?.phoneNumber ||
                errors?.people?.[index]?.email ||
                errors?.people?.[index]?.name
                  ? 'align-self-center'
                  : 'align-self-end'
              }
            />
          </div>
        </div>
      ))}
      <PeopleImport fromModule="location" />
      <EzyDialog
        className="common-dialog-with-body delete-account-warning-modal"
        open={isWarningModal}
        handleClose={() => dispatch(handleFieldChange('isWarningModal', null))}
      >
        <EzyDialogTitle
          title="Not Integrated"
          isCloseIcon
          onClose={() => dispatch(handleFieldChange('isWarningModal', null))}
        />
        <div className="dialog-body">{warningMessage}</div>
        <EzyDialogAction
          actionButtons={[
            {
              label: 'Cancel',
              color: 'error',
              onClick: () => {
                dispatch(handleFieldChange('isWarningModal', null));
              },
            },
            {
              label: 'Connect',
              onClick: () => {
                history.push(`${ROUTE_CONSTANTS.APP_INTEGRATION}/configuration/${isWarningModal}`);
                dispatch(handleFieldChange('isWarningModal', null));
              },
            },
          ]}
        />
      </EzyDialog>
    </div>
  );
}

export default AddLocation;
