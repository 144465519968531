import apiService from '../../utils/apiService';
import API_URL from '../../utils/urlConstants';

export const logbookServices = {
  getLogbookEntries: (params) => apiService.getData(API_URL.LOGBOOK.getLogbookEntries, { params }),
  addLogBookCategory: (data) => apiService.postData(API_URL.LOGBOOK.addLogBookCategory, data),
  addLog: (data) => apiService.postData(API_URL.LOGBOOK.addLog, data),
  uploadAttachment: (data, config) =>
    apiService.postData(API_URL.LOGBOOK.uploadAttachment, data, {
      ...config,
      timeout: 2 * 60 * 1000,
    }),
  deleteUploadedAttachment: (params) =>
    apiService.deleteData(API_URL.LOGBOOK.deleteUploadedAttachment, { params }),
  deleteLog: (params) => apiService.deleteData(API_URL.LOGBOOK.deleteLog, { params }),
  deleteLogBookCategory: (params) =>
    apiService.deleteData(API_URL.LOGBOOK.deleteLogBookCategory, { params }),
  addLogComment: (data) => apiService.postData(API_URL.LOGBOOK.addLogComment, data),
  deleteLogComment: (params) => apiService.deleteData(API_URL.LOGBOOK.deleteLogComment, { params }),
  getCategoryDetailsById: (params) =>
    apiService.getData(API_URL.LOGBOOK.getCategoryDetailsById, { params }),
  editLogBookCategory: (data) => apiService.putData(API_URL.LOGBOOK.editLogBookCategory, data),
  getLogsEntryByFilters: (params) =>
    apiService.getData(API_URL.LOGBOOK.getLogsEntryByFilters, { params }),
  saveAutoSendEmailTime: (data) => apiService.putData(API_URL.LOGBOOK.saveAutoSendEmailTime, data),
  sendLogBookSummary: (data) => apiService.postData(API_URL.LOGBOOK.sendLogBookSummary, data),
  getLogBookCategoriesByBusinessId: (params) =>
    apiService.getData(API_URL.LOGBOOK.getLogBookCategoriesByBusinessId, { params }),
};
