import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import { isEmpty, isNil, omitBy } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import EzyDrawer from '../../../components/common/drawer/Drawer';
import { resetBulkActionData, updateBulkActionData } from '../redux/scheduleSlice';
import IconByName from '../../../components/common/iconByName/IconByName';
import { displayDate } from '../../../utils/timeHelper';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import { requireValidate } from '../../../utils/validationHelper';
import { saveTemplateAction } from '../redux/scheduleActions';

// {
//   "shiftIds": [
//   139,140,141,142,143,144,145,146,147,148,149,150,151,152,153,154,155,156,157
// ],
//     "businessId": 19,
//     "templateName": "10 Jan - 15 Jan Template",
//     "description": "testing in dev",
//     "startFrom": "2023-01-09T06:13:49.390Z",
//     "endTo": "2023-01-15T06:13:49.390Z"
// }

function SaveTemplateDrawer({ eventIds }) {
  const dispatch = useDispatch();
  const { isSaveTemplateDrawer, templateName, description, errors } = useSelector(
    ({ schedule }) => schedule?.bulkActionData ?? {}
  );
  const { weekDatesValue, selectedLocation } = useSelector(
    ({ schedule }) => schedule?.sidePanelData ?? {}
  );
  const { businessId } = useSelector(({ user }) => user?.profileData ?? {});

  const { startDate, endDate } = useMemo(() => weekDatesValue, [weekDatesValue]);

  const { saveTemplateActionLoader } = useSelector(({ common }) => common.generalLoader ?? {});

  const handleClose = () => {
    dispatch(resetBulkActionData());
  };

  const handleFieldChange = (name, value) => {
    dispatch(updateBulkActionData({ fieldName: name, value }));
  };

  const handleSubmit = async () => {
    const data = {
      templateName,
      description,
      shiftIds: eventIds,
      startFrom: moment(startDate)?.toISOString(),
      endTo: moment(endDate)?.toISOString(),
    };

    if (selectedLocation?.id !== 'all' && !selectedLocation?.locationId) {
      data.locationId = selectedLocation?.id;
    } else if (selectedLocation?.id === 'all') {
      data.businessId = businessId;
    } else if (selectedLocation?.locationId) {
      data.areaId = selectedLocation?.id;
    }

    let error = {};
    error.templateName = requireValidate('Template name', data.templateName);
    // error.locationAddress = requireValidate('Location address', data.description);
    error = omitBy(error, isNil);
    if (isEmpty(error)) {
      handleFieldChange('errors', {});
      await dispatch(
        saveTemplateAction(data, () => {
          handleClose();
        })
      );
    } else {
      handleFieldChange('errors', error);
    }
  };

  const actionButton = [
    {
      label: 'Save',
      onClick: handleSubmit,
      loading: saveTemplateActionLoader,
    },
  ];

  return (
    <EzyDrawer
      onClose={handleClose}
      isOpen={isSaveTemplateDrawer}
      actionButtons={actionButton}
      heading="Save Shift Template"
    >
      <div className="shift-drawer">
        <div className="p-x-24">
          <div className="info-div success body-text">
            <IconByName name="error" />
            <div>{`You are about to save the shifts between ${displayDate(
              startDate
            )} - ${displayDate(endDate)} as template.`}</div>
          </div>
        </div>
        <div className="p-x-24">
          <div className="info-div success body-text">
            <IconByName name="error" />
            <div>Saving template with the same name will overwrite the previous template.</div>
          </div>
        </div>
        <div className="save-template-fields-grid">
          <EzyInputField
            label="Name"
            placeholder="Enter template name"
            value={templateName}
            onChange={(event) => handleFieldChange('templateName', event.target.value)}
            error={errors?.templateName}
            required
          />
          <EzyInputField
            label="Description"
            placeholder="Enter template description"
            multiline
            rows={3}
            max-rows={5}
            value={description}
            onChange={(event) => handleFieldChange('description', event.target.value)}
            error={errors?.description}
          />
        </div>
      </div>
    </EzyDrawer>
  );
}

export default SaveTemplateDrawer;

SaveTemplateDrawer.propTypes = {
  eventIds: PropTypes.array.isRequired,
};
