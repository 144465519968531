import { Drawer } from '@mui/material';
import PropTypes from 'prop-types';
import EzyButton from '../button/EzyButton';
import Loader from '../loader/Loader';

function EzyDrawer({ isOpen, onClose, children, actionButtons, heading, isLoading, leftAction }) {
  return (
    <Drawer className="ezy-drawer" anchor="right" open={isOpen} onClose={onClose}>
      <div className="drawer-header">
        <span>{heading}</span>
        <span className="material-icons pointer-cursor" onClick={onClose}>
          close
        </span>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="drawer-body">{children}</div>
          <div className="drawer-footer">
            <div className="left-action">{leftAction}</div>
            {actionButtons?.map(({ label, color, onClick, loading, ...rest }, index) => (
              <EzyButton
                key={index}
                label={label}
                onClick={onClick}
                className={rest?.className}
                color={color}
                loading={loading}
                {...rest}
              />
            ))}
          </div>
        </>
      )}
    </Drawer>
  );
}

export default EzyDrawer;

EzyDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  heading: PropTypes.element.isRequired,
  actionButtons: PropTypes.array,
  leftAction: PropTypes.element,
  isLoading: PropTypes.bool,
};

EzyDrawer.defaultProps = {
  actionButtons: [],
  isLoading: false,
  leftAction: null,
};
