import {
  startGeneralLoader,
  stopGeneralLoader,
} from '../../../utils/backOfficeLists/commonDataSlice';
import { downloadAll } from '../../../utils/downloadHelper';
import { mapEnumToIds } from '../../../utils/enumMapping';
import { displayErrors } from '../../../utils/errorNotifyHelper';
import { successNotification } from '../../../utils/notifyHelper';
import { insightServices } from '../insightServices';
import {
  setBudgetList,
  setMetricData,
  setMetricList,
  setRuleDrawerData,
  setStaffList,
  updateBudgetSettingsDrawer,
  updateInsightFilters,
  updateSidePanelList,
  updateStaffData,
  updateStaffSettingsDrawer,
  updateSummaryStatisticData,
} from './insightSlice';

export const getInsightListAction = (module, cb) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const params = {
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getInsightListActionLoader' }));
      const res = await insightServices.getInsightList(params);
      if (res?.data?.success) {
        const budgetList = res?.data?.data?.budgetList.map((e) => {
          return {
            id: e?.budgetId,
            label: e?.budgetName,
            pathName: e?.budgetName?.substr(e.budgetName.indexOf(' ') + 1),
            showArea: e?.showArea,
          };
        });

        const metricList = res?.data?.data?.metricList?.map((e) => {
          return {
            id: e?.metricId,
            label: e?.metricName,
            pathName: e?.metricName,
            ...e,
          };
        });

        dispatch(
          updateSidePanelList({
            fieldName: 'budgetList',
            value: [
              { title: 'BUDGETS', button: res?.data?.data?.budgetList?.length < 2 },
              ...budgetList,
            ],
          })
        );
        dispatch(
          updateSidePanelList({
            fieldName: 'metricList',
            value: [{ title: 'METRIC', button: true }, ...metricList],
          })
        );
        const selectedModule = [
          ...budgetList,
          ...metricList,
          ...state.insight.sidePanelList.staffList,
        ]?.find((e) => e?.label?.includes(module)) || {
          id: 1,
          label: 'Summary',
          pathName: 'Summary',
        };
        if (module) {
          dispatch(
            updateInsightFilters({
              fieldName: 'selectedModule',
              value: selectedModule,
            })
          );
        }
        if (cb && typeof cb === 'function') {
          cb(selectedModule);
        }
      }
    } catch (e) {
      displayErrors(e);
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getInsightListActionLoader' }));
    }
  };
};

export const addBudgetAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'addBudgetActionLoader' }));
      const res = await insightServices.addBudget(data);
      if (res?.data?.success) {
        cb({ data: res?.data?.data });
        successNotification(res?.data?.message);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'addBudgetActionLoader' }));
    }
  };
};

export const deleteBudgetAction = (params, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'deleteBudgetActionLoader' }));
      const res = await insightServices.deleteBudget(params);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deleteBudgetActionLoader' }));
    }
  };
};

export const getBudgetSettingsAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getBudgetSettingsActionLoader' }));
      const res = await insightServices.getBudgetSettings(params);
      if (res?.data?.success) {
        dispatch(
          updateBudgetSettingsDrawer({
            fieldName: 'budgetPeriod',
            value: res?.data?.data?.budgetPeriod,
          })
        );
        dispatch(
          updateBudgetSettingsDrawer({
            fieldName: 'budgetGroupingByEntity',
            value: res?.data?.data?.budgetGroupingByEntity,
          })
        );
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getBudgetSettingsActionLoader' }));
    }
  };
};

export const updateBudgetSettingDetailAction = (params, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'updateBudgetSettingDetailActionLoader' }));
      const res = await insightServices.updateBudgetSetting(params);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'updateBudgetSettingDetailActionLoader' }));
    }
  };
};

export const getBudgetStatisticAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getBudgetStatisticActionLoader' }));
      const res = await insightServices.getBudgetStatistic(params);
      if (res?.data?.success) {
        dispatch(setBudgetList(res?.data?.data));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getBudgetStatisticActionLoader' }));
    }
  };
};

export const addMetricAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'addMetricActionLoader' }));
      const res = await insightServices.addMetric(data);
      if (res?.data?.success) {
        cb({ data: res?.data?.data });
        successNotification(res?.data?.message);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'addMetricActionLoader' }));
    }
  };
};

export const getMetricSettingsAction = (params) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(startGeneralLoader({ loaderFor: 'getMetricSettingsActionLoader' }));
      const res = await insightServices.getMetricSettings(params);
      if (res?.data?.success) {
        const data = mapEnumToIds(
          [
            { fieldName: 'metricType', listName: 'metricType', isFromEnum: true },
            { fieldName: 'levelOfDetail', listName: 'levelOfDetail', isFromEnum: true },
            {
              fieldName: 'metricForecastBasedOnEntity',
              listName: 'metricForecastBasedOnEntity',
              isFromEnum: true,
            },
          ],
          res?.data?.data,
          state?.common
        );
        dispatch(setMetricData(data));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getMetricSettingsActionLoader' }));
    }
  };
};

export const updateMetricSettingAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'updateMetricSettingActionLoader' }));
      const res = await insightServices.updateMetricSetting(data);
      if (res?.data?.success) {
        cb();
        successNotification(res?.data?.message);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'updateMetricSettingActionLoader' }));
    }
  };
};

export const deleteMetricAction = (params, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'deleteMetricActionLoader' }));
      const res = await insightServices.deleteMetric(params);
      if (res?.data?.success) {
        cb();
        successNotification(res?.data?.message);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deleteMetricActionLoader' }));
    }
  };
};

export const exportMetricDataAction = (params, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'exportMetricDataActionLoader' }));
      const res = await insightServices.exportMetricData(params);
      if (res?.data) {
        downloadAll(res);
        cb();
        successNotification('File received successfully');
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'exportMetricDataActionLoader' }));
    }
  };
};

export const getMetricInformationAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getMetricInformationActionLoader' }));
      const res = await insightServices.getMetricInformation(params);
      if (res?.data?.success) {
        dispatch(setMetricList({ listName: 'metricList', value: res?.data?.data }));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getMetricInformationActionLoader' }));
    }
  };
};

export const updateMetricDataFromViewScreenAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'updateMetricDataFromViewScreenActionLoader' }));
      const res = await insightServices.updateMetricDataFromViewScreen(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'updateMetricDataFromViewScreenActionLoader' }));
    }
  };
};

export const getPayrollForecastStatisticsAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getPayrollForecastStatisticsActionLoader' }));
      const res = await insightServices.getPayrollForecastStatistics(params);
      if (res?.data?.success) {
        dispatch(setMetricList({ listName: 'metricStatisticsList', value: res?.data?.data }));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getPayrollForecastStatisticsActionLoader' }));
    }
  };
};

export const getMetricStatisticsAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getMetricStatisticsActionLoader' }));
      const res = await insightServices.getMetricStatistics(params);
      if (res?.data?.success) {
        dispatch(setMetricList({ listName: 'metricStatisticsList', value: res?.data?.data }));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getMetricStatisticsActionLoader' }));
    }
  };
};

export const updateMetricDataAction = (data) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'updateMetricDataActionLoader' }));
      const res = await insightServices.updateMetricData(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'updateMetricDataActionLoader' }));
    }
  };
};

export const getMetricListForRuleAction = () => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const params = {
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getMetricListForRuleActionLoader' }));
      const res = await insightServices.getMetricListForRule(params);
      if (res?.data?.success) {
        dispatch(updateStaffData({ fieldName: 'listOfMetrics', value: res?.data?.data }));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getMetricListForRuleActionLoader' }));
    }
  };
};

export const addRuleAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'addRuleActionLoader' }));
      const res = await insightServices.addRule(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'addRuleActionLoader' }));
    }
  };
};

export const getRuleListAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getRuleListActionLoader' }));
      const res = await insightServices.getRuleList(params);
      if (res?.data?.success) {
        dispatch(updateStaffData({ fieldName: 'rulesList', value: res?.data?.data }));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getRuleListActionLoader' }));
    }
  };
};

export const deleteMultipleRulesActions = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'deleteMultipleRulesActionsLoader' }));
      const res = await insightServices.deleteMultipleRules(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deleteMultipleRulesActionsLoader' }));
    }
  };
};

export const getRuleDataByIdAction = (params) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(startGeneralLoader({ loaderFor: 'getRuleDataByIdActionLoader' }));
      const res = await insightServices.getRuleDataById(params);
      if (res?.data?.success) {
        const data = mapEnumToIds(
          [{ fieldName: 'areaId', listName: 'areaList', isFromEnum: false }],
          res?.data?.data,
          { areaList: state?.insight?.filters?.selectedLocation?.areaList }
        );
        data.metricId = { id: data?.metricId, label: data?.metricName };
        dispatch(setRuleDrawerData(data));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getRuleDataByIdActionLoader' }));
    }
  };
};

export const updateRuleDataAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'updateRuleDataActionLoader' }));
      const res = await insightServices.updateRuleData(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'updateRuleDataActionLoader' }));
    }
  };
};

export const deleteRuleAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'deleteRuleActionLoader' }));
      const res = await insightServices.deleteRule(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'deleteRuleActionLoader' }));
    }
  };
};

export const runLaborModelAction = (params, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'runLaborModalActionLoader' }));
      const res = await insightServices.runLaborModel(params);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb({
          startDate: params?.rangeStartDate,
          endDate: params?.rangeEndDate,
          data: res?.data?.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'runLaborModalActionLoader' }));
    }
  };
};

export const getStaffSettingsAction = () => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const params = {
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getStaffSettingsActionLoader' }));
      const res = await insightServices.getStaffSettings(params);
      if (res?.data?.success) {
        dispatch(
          updateStaffSettingsDrawer({
            fieldName: 'levelOfDetail',
            value: res?.data?.data?.levelOfDetail,
          })
        );
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getStaffSettingsActionLoader' }));
    }
  };
};

export const updateStaffSettingsAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'updateStaffSettingsActionLoader' }));
      const res = await insightServices.updateStaffSettings(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'updateStaffSettingsActionLoader' }));
    }
  };
};

export const getStaffListAction = (params) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(startGeneralLoader({ loaderFor: 'getStaffListActionLoader' }));
      const res = await insightServices.getStaffList(params);
      if (res?.data?.success) {
        const data = mapEnumToIds(
          [{ fieldName: 'areaId', listName: 'areaList', isFromEnum: false }],
          res?.data?.data,
          { areaList: state?.insight?.filters?.selectedLocation?.areaList }
        );
        dispatch(setStaffList({ listName: 'staffList', value: data }));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getStaffListActionLoader' }));
    }
  };
};

export const getStaffStatisticDataForEditAction = (data) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getStaffStatisticDataForEditActionLoader' }));
      const res = await insightServices.getStaffStatisticDataForEdit(data);
      if (res?.data?.success) {
        dispatch(setStaffList({ listName: 'staffStatisticsList', value: res?.data?.data }));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getStaffStatisticDataForEditActionLoader' }));
    }
  };
};

export const updateStaffStatisticDataAction = (data) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'updateStaffStatisticDataActionLoader' }));
      const res = await insightServices.updateStaffStatisticData(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'updateStaffStatisticDataActionLoader' }));
    }
  };
};

export const getLocationListForCopyAction = (data) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getLocationListForCopyLocationActionLoader' }));
      const res = await insightServices.getLocationListForCopyAction(data);
      if (res?.data?.success) {
        dispatch(setStaffList({ listName: 'locationListForCopy', value: res?.data?.data }));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getLocationListForCopyLocationActionLoader' }));
    }
  };
};

export const copyLocationBulkAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'copyLocationBulkActionLoader' }));
      const res = await insightServices.copyLocationBulkAction(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'copyLocationBulkActionLoader' }));
    }
  };
};

export const getSummaryDataAction = (data) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getSummaryDataActionLoader' }));
      const res = await insightServices.getSummaryData(data);
      if (res?.data?.success) {
        dispatch(updateSummaryStatisticData(res?.data?.data));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getSummaryDataActionLoader' }));
    }
  };
};

export const getSummarySettingsAction = () => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const params = {
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getSummarySettingsActionLoader' }));
      const res = await insightServices.getSummarySettings(params);
      if (res?.data?.success) {
        dispatch(
          updateInsightFilters({
            fieldName: 'displaySalesPerHour',
            value: res?.data?.data?.displaySalesPerHour,
          })
        );
        dispatch(
          updateInsightFilters({
            fieldName: 'displayLabor',
            value: res?.data?.data?.displayLabor,
          })
        );
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getSummarySettingsActionLoader' }));
    }
  };
};

export const updateSummarySettingsAction = (data, cb) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const finalData = {
        businessId: state?.user?.profileData?.businessId,
        ...data,
      };
      dispatch(startGeneralLoader({ loaderFor: 'updateSummarySettingsActionLoader' }));
      const res = await insightServices.updateSummarySettings(finalData);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'updateSummarySettingsActionLoader' }));
    }
  };
};

export const updateBudgetStatisticAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'updateBudgetStatisticActionLoader' }));
      const res = await insightServices.updateBudgetData(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'updateBudgetStatisticActionLoader' }));
    }
  };
};
