import { combineReducers, configureStore } from '@reduxjs/toolkit';
import businessSlice from '../pages/business/redux/businessSlice';
import locationSlice from '../pages/location/redux/locationSlice';
import userSlice from '../pages/auth/redux/userSlice';
import peopleSlice from '../pages/people/redux/peopleSlice';
import commonDataSlice from '../utils/backOfficeLists/commonDataSlice';
import timeSheetsSlice from '../pages/timeSheets/redux/timeSheetsSlice';
import scheduleSlice from '../pages/schedule/redux/scheduleSlice';
import newsFeedSlice from '../pages/newsFeed/redux/newsFeedSlice';
import globalProfileSlice from '../pages/globalProfile/redux/globalProfileSlice';
import dashboardSlice from '../pages/home/redux/dashboardSlice';
import tasksSlice from '../pages/tasks/redux/tasksSlice';
import logbookSlice from '../pages/logBook/redux/logbookSlice';
import exportTimesheetSlice from '../pages/exportTimesheet/redux/exportTimesheetSlice';
import insightSlice from '../pages/insights/redux/insightSlice';

const devMode = process.env.NODE_ENV === 'development';
const appReducer = combineReducers({
  user: userSlice,
  dashboard: dashboardSlice,
  globalProfile: globalProfileSlice,
  business: businessSlice,
  newsFeed: newsFeedSlice,
  location: locationSlice,
  people: peopleSlice,
  common: commonDataSlice,
  timeSheets: timeSheetsSlice,
  schedule: scheduleSlice,
  insight: insightSlice,
  tasks: tasksSlice,
  logbook: logbookSlice,
  exportTimesheet: exportTimesheetSlice,
});
const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
export const store = configureStore({
  reducer: rootReducer,
  devTools: devMode,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});
export default store;
