import { isNil, omitBy } from 'lodash';
import moment from 'moment/moment';
import { requireValidate } from '../../../utils/validationHelper';

const addUnavailabilityValidation = (data) => {
  const {
    fromDate,
    toDate,
    fromTime,
    toTime,
    repeatitionFrequency,
    repeatOnDays,
    endOn,
    isAllDay,
    isRepeat,
    endOnDate,
  } = data ?? {};

  const error = {};

  error.fromDate = requireValidate('From date ', fromDate);

  if (!isRepeat) {
    error.toDate = requireValidate('To Date ', toDate);
  }

  if (toDate && fromDate && !moment(toDate).isSameOrAfter(fromDate) && !isRepeat) {
    error.toDate = `To date can't be smaller then from date`;
  }

  if (!isAllDay) {
    error.toTime = requireValidate('To time', toTime);
    error.fromTime = requireValidate('From time', fromTime);
  }

  if (fromTime && toTime && !moment(toTime).isSameOrAfter(fromTime)) {
    error.toTime = `To time can't be smaller then from time`;
  }

  if (isRepeat && moment(fromDate).isSame(toDate)) {
    error.repeatitionFrequency = requireValidate('Repeat Duration ', repeatitionFrequency);
    error.endOn = requireValidate('End Duration ', endOn);
    if (endOn === 'On a Date') {
      error.endOnDate = requireValidate('End Date ', endOnDate);
      if (endOnDate && fromDate && !moment(endOnDate).isSameOrAfter(fromDate)) {
        error.endOnDate = `Ends on date can't be smaller then from date`;
      }
    }

    if (repeatitionFrequency === 4) {
      error.repeatMonthly = requireValidate('Repeat Monthly', repeatOnDays);
    } else {
      error.repeatWeekWise = requireValidate('Repeat on Day', repeatOnDays);
    }
  }
  return omitBy(error, isNil);
};
export default addUnavailabilityValidation;
