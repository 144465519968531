import { createSlice, current } from '@reduxjs/toolkit';
import { timeSheetsHeaders } from '../timeSheetsHeaders';
import { getDurationInMinutes } from '../../../utils/timeHelper';

const timeSheetsSlice = createSlice({
  name: 'timeSheets',
  initialState: {
    sidePanelData: {
      locationAreaDropdownList: [],
      personList: [],
      selectedLocation: { timeZoneId: {} },
      selectedPerson: null,
      weekDatesValue: {
        startDate: null,
        endDate: null,
      },
      filterDuration: 'Week',
      selectedTimesheet: {},
    },
    timeSheetsList: {
      headers: timeSheetsHeaders,
      data: [],
      limit: 15,
      page: 1,
      totalData: 0,
      totalPages: 0,
    },
    timeSheetData: {
      addTimeSheetDrawer: false,
      timesheetAllowances: { baseRate: [], shiftLoadings: [] },
      timeSheetBreakDetailEntities: [
        {
          isCustom: false,
          breakType: null,
          breakStartTime: null,
          breakEndTime: null,
          duration: null,
        },
      ],
    },
  },
  reducers: {
    setTimeSheetsList: (state, action) => {
      state.timeSheetsList = {
        ...state.timeSheetsList,
        data: action.payload?.data || [],
        limit: action.payload.limit,
        page: action.payload.page,
        totalData: action.payload.totalData,
        totalPages: action.payload.totalPages,
      };
    },
    updateSidePanelData: (state, action) => {
      const { fieldName, value } = action.payload;
      state.sidePanelData[fieldName] = value;
    },
    updateTimeSheetData: (state, action) => {
      const { fieldName, value, index } = action.payload;
      if (index || index === 0) {
        state.timeSheetData.timeSheetBreakDetailEntities[index][fieldName] = value;
        if (fieldName === 'breakStartTime') {
          state.timeSheetData.timeSheetBreakDetailEntities[index].duration = getDurationInMinutes(
            value,
            state?.timeSheetData?.timeSheetBreakDetailEntities?.[index]?.breakEndTime
          );
        }
        if (fieldName === 'breakEndTime') {
          state.timeSheetData.timeSheetBreakDetailEntities[index].duration = getDurationInMinutes(
            state?.timeSheetData?.timeSheetBreakDetailEntities?.[index]?.breakStartTime,
            value
          );
        }
        if (fieldName === 'isCustom' && value === true) {
          state.timeSheetData.timeSheetBreakDetailEntities[index].duration = getDurationInMinutes(
            state?.timeSheetData?.timeSheetBreakDetailEntities?.[index]?.breakStartTime,
            state?.timeSheetData?.timeSheetBreakDetailEntities?.[index]?.breakEndTime
          );
        }
        if (fieldName === 'isCustom' && value === false) {
          state.timeSheetData.timeSheetBreakDetailEntities[index].duration = null;
        }
      } else state.timeSheetData[fieldName] = value;
    },
    fillTimeSheetData: (state, action) => {
      const { data: value, breakType } = action.payload ?? {};
      const shiftData = {
        ...value,
        date: value?.date,
        personDetailId: current(state)?.timeSheetData?.personList?.find(
          (e) => e?.id === value?.personDetailId
        ),
        areaId: current(state)?.timeSheetData?.areaList?.find((e) => e?.id === value?.areaId),
        startTime: value?.startTime,
        endTime: value?.endTime,
        totalMealBreak: value?.totalMealBreak,
        totalRestBreak: value?.totalRestBreak,
        breakSummary: null,
        timeZoneId: value?.timeZoneId,
        timeSheetBreakDetailEntities:
          value?.breakSummary?.length > 0
            ? value?.breakSummary?.map((e) => ({
                ...e,
                breakType: breakType?.find((type) => type?.id === e?.breakType),
                isCustom: e?.breakStartTime && e?.breakEndTime,
              }))
            : [
                {
                  isCustom: false,
                  breakType: null,
                  breakStartTime: null,
                  breakEndTime: null,
                  duration: null,
                },
              ],
      };

      state.timeSheetData = {
        ...state.timeSheetData,
        ...shiftData,
      };
    },
    addDeleteTimeSheetBreakDetails: (state, action) => {
      const { type, index } = action.payload;
      if (type === 'ADD') {
        state.timeSheetData.timeSheetBreakDetailEntities.push({
          isCustom: false,
          breakType: null,
          breakStartTime: null,
          breakEndTime: null,
          duration: null,
        });
      }
      if (type === 'DELETE') {
        state.timeSheetData.timeSheetBreakDetailEntities =
          state.timeSheetData.timeSheetBreakDetailEntities.filter((e, i) => i !== index);
        if (state.timeSheetData.errors?.timeSheetBreakDetailEntities) {
          state.timeSheetData.errors.timeSheetBreakDetailEntities =
            state.timeSheetData.errors.timeSheetBreakDetailEntities.filter((e, i) => i !== index);
        }
      }
    },
    resetTimeSheetData: (state) => {
      state.timeSheetData = {
        timeSheetBreakDetailEntities: [
          {
            isCustom: false,
            breakType: null,
            breakStartTime: null,
            breakEndTime: null,
            duration: null,
          },
        ],
      };
      state.sidePanelData = {
        ...state.sidePanelData,
        selectedTimesheet: {},
      };
    },
    resetTimeSheetListData: (state) => {
      state.timeSheetsList.data = [];
      state.timeSheetsList.totalData = 0;
      state.timeSheetsList.totalPages = 0;
    },
    updateAllowancesDetail: (state, action) => {
      state.timeSheetData.timesheetAllowances = {
        originalValue: action.payload,
        baseRateAllowancesDropDown: action.payload.dropDownAllowances
          ?.filter((e) => e?.isBaseRate)
          ?.map((e) => {
            return e?.payrateString;
          }),
        shiftLoadingAllowancesDropDown: action.payload.dropDownAllowances
          ?.filter((e) => !e?.isBaseRate)
          ?.map((e) => {
            return e?.payrateString;
          }),
        baseRate: action.payload.appliedAllowances.filter((e) => e?.isBaseRate),
        shiftLoadings: action.payload.appliedAllowances.filter((e) => !e?.isBaseRate),
        baseRateTotalWorkingHour: action.payload.appliedAllowances
          .filter((e) => e?.isBaseRate)
          ?.reduce((prev, curr) => prev + +curr.workingHour, 0),
      };
    },
    updateBaseRate: (state, action) => {
      const { index, fieldName, value } = action.payload;
      state.timeSheetData.timesheetAllowances.baseRate =
        state.timeSheetData.timesheetAllowances.baseRate.map((e, i) =>
          i === index ? { ...e, [fieldName]: value } : e
        );
    },
    updateShiftLoadings: (state, action) => {
      const { index, fieldName, value } = action.payload;
      state.timeSheetData.timesheetAllowances.shiftLoadings =
        state.timeSheetData.timesheetAllowances.shiftLoadings.map((e, i) =>
          i === index ? { ...e, [fieldName]: value } : e
        );
    },
    addDeleteBaseRate: (state, action) => {
      const { type, index } = action.payload;
      if (type === 'ADD') {
        state.timeSheetData.timesheetAllowances.baseRate.push({
          payrateString: '',
          workingHour: 0,
        });
      }
      if (type === 'DELETE') {
        state.timeSheetData.timesheetAllowances.baseRate =
          state.timeSheetData.timesheetAllowances.baseRate.filter((e, i) => i !== index);
      }
    },
    addDeleteShiftLoadings: (state, action) => {
      const { type, index } = action.payload;
      if (type === 'ADD') {
        state.timeSheetData.timesheetAllowances.shiftLoadings.push({
          payrateString: '',
          workingHour: 0,
        });
      }
      if (type === 'DELETE') {
        state.timeSheetData.timesheetAllowances.shiftLoadings =
          state.timeSheetData.timesheetAllowances.shiftLoadings.filter((e, i) => i !== index);
      }
    },
    resetTimesheetAllowancesData: (state) => {
      state.timeSheetData.timesheetAllowances = { baseRate: [], shiftLoadings: [] };
    },
  },
});

const { reducer, actions } = timeSheetsSlice;
export const {
  setTimeSheetsList,
  updateSidePanelData,
  resetTimeSheetListData,
  resetTimeSheetData,
  updateTimeSheetData,
  fillTimeSheetData,
  addDeleteTimeSheetBreakDetails,
  updateAllowancesDetail,
  updateShiftLoadings,
  updateBaseRate,
  addDeleteBaseRate,
  addDeleteShiftLoadings,
  resetTimesheetAllowancesData,
} = actions;
export default reducer;
