import moment from 'moment-timezone';
import { timeSheetServices } from '../timeSheetsServices';
import {
  fillTimeSheetData,
  setTimeSheetsList,
  updateAllowancesDetail,
  updateSidePanelData,
  updateTimeSheetData,
} from './timeSheetsSlice';
import { errorNotification, successNotification } from '../../../utils/notifyHelper';
import {
  startGeneralLoader,
  stopGeneralLoader,
} from '../../../utils/backOfficeLists/commonDataSlice';
import { getAreaListByPersonAction } from '../../people/redux/peopleActions';
import { updateScheduleSidePanelData } from '../../schedule/redux/scheduleSlice';
import { mapEnumToIds, mapIdsFromList } from '../../../utils/enumMapping';
import { displayErrors } from '../../../utils/errorNotifyHelper';
import {
  updateInsightFilters,
  updateLocationListDropdown,
} from '../../insights/redux/insightSlice';

export const getAllTimesheetByPerson = (params, cb) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(startGeneralLoader({ loaderFor: 'getAllTimesheetByPersonLoader' }));
      const res = await timeSheetServices.getAllTimesheetByPerson(params);
      if (res?.data?.success) {
        const data = mapEnumToIds(
          [{ fieldName: 'timeZoneId', listName: 'timeZonesList', isFromEnum: false }],
          res?.data,
          state?.common
        );
        dispatch(setTimeSheetsList(data));

        cb(data?.data);
      }
    } catch (e) {
      errorNotification(e?.res?.data?.message);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getAllTimesheetByPersonLoader' }));
    }
  };
};

export const getPersonListByBusinessIdAction = (cb) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const finalParams = {
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getPersonListByBusinessId' }));
      const res = await timeSheetServices.getPersonListByBusinessId(finalParams);
      if (res?.data?.success) {
        dispatch(updateTimeSheetData({ fieldName: 'personList', value: res?.data?.data }));
        if (cb && typeof cb === 'function') {
          cb();
        }
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getPersonListByBusinessId' }));
    }
  };
};

export const getPersonListBasedOnLocationAction = (params, cb) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const finalParams = {
        ...params,
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getPersonListBasedOnLocationActionLoader' }));
      const res = await timeSheetServices.getPersonList(finalParams);
      if (res?.data?.success) {
        dispatch(updateSidePanelData({ fieldName: 'personList', value: res?.data?.data }));

        cb(res?.data?.data);
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getPersonListBasedOnLocationActionLoader' }));
    }
  };
};

export const getTimeSheetViewDetailsAction = (params, isEdit = false) => {
  return async (dispatch, getState) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getTimeSheetViewDetailsActionLoader' }));
      const res = await timeSheetServices.getTimeSheetById(params);
      if (res?.data?.success) {
        const state = getState();

        const data = mapEnumToIds(
          [
            { fieldName: 'timeZoneId', listName: 'timeZonesList', isFromEnum: false },
            { fieldName: 'breakTypeRoundingType', listName: 'breakTimeRounding', isFromEnum: true },
            { fieldName: 'startTimeRoundingType', listName: 'timeRounding', isFromEnum: true },
            { fieldName: 'endTimeRoundingType', listName: 'timeRounding', isFromEnum: true },
          ],
          res?.data?.data,
          state?.common
        );
        if (isEdit) {
          const personId = data?.personDetailId;
          await dispatch(getAreaListByPersonAction(personId));

          dispatch(
            fillTimeSheetData({
              data,
              breakType: state?.common?.enums?.breakType,
            })
          );
          dispatch(
            updateTimeSheetData({
              fieldName: 'viewTimeSheetData',
              value: {},
            })
          );
        } else
          dispatch(
            updateTimeSheetData({
              fieldName: 'viewTimeSheetData',
              value: {
                ...data,
                timeSheetBreakDetailEntities: data?.breakSummary,
              },
            })
          );
      }
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getTimeSheetViewDetailsActionLoader' }));
    }
  };
};

export const getTimeSheetHistoryAction = (params) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(startGeneralLoader({ loaderFor: 'getTimeSheetHistoryActionLoader' }));
      const res = await timeSheetServices.getTimeSheetHistory(params);
      const data = mapEnumToIds(
        [
          { fieldName: 'breakType', listName: 'breakType', isFromEnum: true },
          { fieldName: 'breakState', listName: 'breakState', isFromEnum: true },
          { fieldName: 'timeZoneId', listName: 'timeZonesList', isFromEnum: false },
        ],
        res?.data?.data,
        state?.common
      );
      if (res?.data?.success) {
        dispatch(
          updateTimeSheetData({
            fieldName: 'historyData',
            value: data,
          })
        );
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getTimeSheetHistoryActionLoader' }));
    }
  };
};

export const importScheduledShift = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getTimeSheetViewDetailsActionLoader' }));
      const personId = data?.personDetailId;
      await dispatch(getAreaListByPersonAction(personId));
      const state = getState();
      dispatch(
        fillTimeSheetData({
          data,
          breakType: state?.common?.enums?.breakType,
        })
      );
    } catch (e) {
      /**/
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getTimeSheetViewDetailsActionLoader' }));
    }
  };
};
export const addTimeSheetDetailsAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'addTimeSheetDetailsActionLoader' }));
      const res = await timeSheetServices.addTimeSheetDetails(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        if (cb && typeof cb === 'function') cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'addTimeSheetDetailsActionLoader' }));
    }
  };
};
export const editTimeSheetDetailsAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'editTimeSheetDetailsActionLoader' }));
      const res = await timeSheetServices.editTimeSheetDetails(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        if (cb && typeof cb === 'function') cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'editTimeSheetDetailsActionLoader' }));
    }
  };
};
export const changeTimeSheetStatusAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: `timeSheetStatus${data?.timeSheetAction}Loader` }));
      const res = await timeSheetServices.changeTimeSheetStatus(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        if (cb && typeof cb === 'function') cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: `timeSheetStatus${data?.timeSheetAction}Loader` }));
    }
  };
};

export const roundTimesheetTimeAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: `roundTimesheetTimeActionLoader` }));
      const res = await timeSheetServices.roundTimesheetTime(data);
      if (res?.data?.success) {
        dispatch(
          updateTimeSheetData({ fieldName: 'startTime', value: res?.data?.data?.startTime })
        );
        dispatch(updateTimeSheetData({ fieldName: 'endTime', value: res?.data?.data?.endTime }));
        dispatch(
          updateTimeSheetData({
            fieldName: 'totalMealBreak',
            value: res?.data?.data?.mealBreakDuration,
          })
        );
        dispatch(
          updateTimeSheetData({
            fieldName: 'totalRestBreak',
            value: res?.data?.data?.restBreakDuration,
          })
        );
        if (cb && typeof cb === 'function') cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: `roundTimesheetTimeActionLoader` }));
    }
  };
};

export const getJournalDetailsByDateAction = (params) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(startGeneralLoader({ loaderFor: 'getJournalDetailsByDateActionLoader' }));
      const res = await timeSheetServices.getJournalDetailsByDate(params);
      if (res?.data?.success) {
        const data = mapEnumToIds(
          [
            { fieldName: 'ratePerformance', listName: 'ratePerformance', isFromEnum: true },
            { fieldName: 'timeZoneId', listName: 'timeZonesList', isFromEnum: false },
          ],
          res?.data?.data,
          state?.common
        );
        dispatch(updateTimeSheetData({ fieldName: 'journalDetails', value: data }));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getJournalDetailsByDateActionLoader' }));
    }
  };
};

export const getLocationAreaDropdownAction = (
  { locationId: locationParam },
  cb,
  isFromSchedule = false,
  isFromInsight = false
) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const params = {
        businessId: state?.user?.profileData?.businessId,
      };
      dispatch(startGeneralLoader({ loaderFor: 'getLocationAreaDropdownActionLoader' }));
      const res = await timeSheetServices.getLocationAreaDropdown(params);
      if (res?.data?.success) {
        const data = mapEnumToIds(
          [
            { fieldName: 'timeZoneId', listName: 'timeZonesList', isFromEnum: false },
            { fieldName: 'weekDay', listName: 'weekDays', isFromEnum: true },
          ],
          res?.data?.data,
          state?.common
        );

        // transform array as dropdown accept
        const transformedDropdownData = data.flatMap((location) => {
          const { locationId, locationName, areaList, timeZoneId, weekStartsOn } = location;
          const locationData = {
            id: locationId,
            label: locationName,
            timeZoneId,
            weekStartsOn,
          };

          const areaListData = areaList.map((area) => ({
            id: area.id,
            label: area.label,
            locationId,
            timeZoneId,
            weekStartsOn,
          }));
          return [locationData, ...areaListData];
        });
        const locationAreaDropDown = [
          {
            id: 'all',
            label: 'All Location',
            timeZoneId: {
              label: moment.tz.guess(),
            },
            weekStartsOn: 1,
          },
          ...transformedDropdownData,
        ];

        if (!isFromInsight && !isFromSchedule) {
          dispatch(
            updateSidePanelData({
              fieldName: 'locationAreaDropdownList',
              value: locationAreaDropDown,
            })
          );

          dispatch(
            updateSidePanelData({
              fieldName: 'selectedLocation',
              value:
                locationParam && locationParam !== 'all'
                  ? mapIdsFromList(locationParam, transformedDropdownData)
                  : locationAreaDropDown[0],
            })
          );
        }

        if (isFromSchedule) {
          dispatch(
            updateScheduleSidePanelData({
              fieldName: 'locationDropdownList',
              value: locationAreaDropDown,
            })
          );
          dispatch(
            updateScheduleSidePanelData({
              fieldName: 'selectedLocation',
              value:
                locationParam && locationParam !== 'all'
                  ? mapIdsFromList(locationParam, transformedDropdownData)
                  : locationAreaDropDown[0],
            })
          );
        }

        if (isFromInsight) {
          const finalData = [
            {
              id: 'all',
              label: 'All Location',
              timeZoneId: {
                label: moment.tz.guess(),
              },
              weekStartsOn: 1,
              areaList: [],
              operatingHours: [],
            },
            ...data.map((e) => {
              return {
                id: e?.locationId,
                label: e?.locationName,
                timeZoneId: e?.timeZoneId,
                weekStartsOn: e?.weekStartsOn,
                areaList: e?.areaList,
                operatingHours: e?.operatingHours,
              };
            }),
          ];
          dispatch(updateLocationListDropdown(finalData));
          dispatch(
            updateInsightFilters({
              fieldName: 'selectedLocation',
              value: locationParam ? mapIdsFromList(locationParam, finalData) : finalData?.[0],
            })
          );
        }

        cb(locationAreaDropDown);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getLocationAreaDropdownActionLoader' }));
    }
  };
};

export const getTimesheetAllowancesAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'getTimesheetAllowancesActionLoader' }));
      const res = await timeSheetServices.getTimesheetAllowances(params);
      if (res?.data?.success) {
        dispatch(updateAllowancesDetail(res?.data?.data));
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'getTimesheetAllowancesActionLoader' }));
    }
  };
};

export const editTimesheetAllowancesAction = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(startGeneralLoader({ loaderFor: 'editTimesheetAllowancesActionLoader' }));
      const res = await timeSheetServices.editTimesheetAllowances(data);
      if (res?.data?.success) {
        successNotification(res?.data?.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      dispatch(stopGeneralLoader({ loaderFor: 'editTimesheetAllowancesActionLoader' }));
    }
  };
};
