import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import ROUTE_CONSTANTS from '../../../../utils/constants';
import EzyNavBar from '../../../../components/common/navBar/EzyNavBar';
import IconByName from '../../../../components/common/iconByName/IconByName';
import EzyTab from '../../../../components/common/tab/EzyTab';
import AppIntegrationSideBar from './AppIntegrationSideBar';
import AddLocationDrawer from './AddLocationDrawer';
import IntegrationConfiguration from './IntegrationConfiguration';
import IntegrationAppInfo from './IntegrationAppInfo';
import ConfigurationActionDrawer from './ConfigurationActionDrawer';
import {
  connectIntegrationWithLocationAction,
  disconnectLocationConfigurationAction,
  getIntegrationDetailsAction,
  getLocationSpecificConfigurationAction,
  getMyObFileDetailsAction,
  saveMyObFileDetailsAction,
} from '../../redux/globalProfileActions';
import {
  resetAppIntegrationData,
  updateAppIntegrationData,
  updateMyObLoginDetails,
} from '../../redux/globalProfileSlice';
import useQueryParams from '../../../../hooks/useQueryParams';
import useReplaceState from '../../../../hooks/useReplaceState';
import EzyDialog from '../../../../components/common/dialog/EzyDialog';
import EzyAutoComplete from '../../../../components/common/autoComplete/EzyAutoComplete';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import EzyButton from '../../../../components/common/button/EzyButton';
import { errorNotification } from '../../../../utils/notifyHelper';

function AppIntegrationSettings() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { code, state, realmId } = useQueryParams();
  const [openLoginModal, setOpenLoginModal] = useState(false);

  const { businessId } = useSelector(({ user }) => user?.profileData ?? {});

  const { specificIntegrationDetails, selectedLocation, myObFilesList, myObLoginDetails } =
    useSelector(({ globalProfile }) => globalProfile?.appIntegration || {});

  const { getMyObFileDetailsActionLoader, saveMyObFileDetailsActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const handleTabChange = (newTab) => {
    history.push(`${ROUTE_CONSTANTS.APP_INTEGRATION}/${newTab}/${id}`);
  };

  const replaceState = useReplaceState();

  const getIntegrationDetails = async () => {
    if (id)
      await dispatch(
        getIntegrationDetailsAction({ integrationId: id, businessId }, (location) => {
          if (location?.locationId) {
            dispatch(
              getLocationSpecificConfigurationAction({
                integrationId: id,
                locationId: location?.locationId,
              })
            );
            dispatch(
              updateAppIntegrationData({
                fieldName: 'selectedLocation',
                value: location?.locationId,
              })
            );
          }
        })
      );
  };

  const actionButtons = useMemo(() => {
    const actions = [];
    actions?.push({
      label: 'Back',
      onClick: () => {
        history.push(ROUTE_CONSTANTS.APP_INTEGRATION);
        dispatch(resetAppIntegrationData());
      },
      color: 'secondary',
    });
    if (specificIntegrationDetails?.locationList?.length) {
      actions?.push({
        label: 'Disconnect',
        color: 'error',
        onClick: () => {
          dispatch(
            disconnectLocationConfigurationAction(
              {
                locationId: selectedLocation,
                integrationId: id,
                businessId,
              },
              async () => {
                await getIntegrationDetails();
              }
            )
          );
        },
      });
    }
    return actions;
  }, [specificIntegrationDetails?.locationList?.length, selectedLocation]);

  const tabs = useMemo(() => {
    const tabList = [];
    if (specificIntegrationDetails?.locationList?.length) {
      tabList.push({
        label: 'Configuration',
        component: <IntegrationConfiguration />,
        actionButtons,
        access: 'default',
        name: 'configuration',
      });
    }
    tabList.push({
      label: 'App Info',
      component: <IntegrationAppInfo />,
      actionButtons,
      access: 'default',
      name: 'appInfo',
    });

    return tabList;
  }, [specificIntegrationDetails, actionButtons]);

  const handleSubmitMyObLogin = () => {
    const data = {
      locationId: state,
      integrationId: id,
      id: myObLoginDetails?.fileName?.id,
      name: myObLoginDetails?.fileName?.name,
      filePathURl: myObLoginDetails?.fileName?.filePathURl,
    };
    if (
      myObLoginDetails?.userName?.toLowerCase() === 'administrator' &&
      myObLoginDetails?.password === '' &&
      !isEmpty(data)
    ) {
      dispatch(
        saveMyObFileDetailsAction(data, async () => {
          setOpenLoginModal(false);
          replaceState({}, true);
          await getIntegrationDetails();
        })
      );
    } else {
      errorNotification('Your credentials were incorrect, please double check that');
    }
  };

  useEffect(() => {
    if (code && businessId) {
      dispatch(
        connectIntegrationWithLocationAction(
          {
            businessId,
            code,
            integrationId: id,
            locationId: state,
            realmId,
          },
          (data) => {
            if (!data) {
              replaceState({}, true);
              setTimeout(() => {
                getIntegrationDetails();
              }, 1000);
            } else {
              replaceState({ state });
              dispatch(getMyObFileDetailsAction({ integrationId: id, locationId: state }));
              setTimeout(() => {
                setOpenLoginModal(true);
              }, 1000);
            }
          }
        )
      );
    } else if (businessId && !specificIntegrationDetails?.integrationEntity?.integrationName) {
      getIntegrationDetails();
    }
  }, [businessId]);

  return (
    <div className="h-full w-full d-flex-column profile-setting-fullScreen-wrapper">
      <EzyNavBar isFormProfile>
        <IconByName
          name="close"
          className="pointer-cursor white-color"
          onClick={() => history.push(ROUTE_CONSTANTS.APP_INTEGRATION)}
        />
      </EzyNavBar>

      <div className="panel-child-wrapper app-integration-settings-container">
        <AppIntegrationSideBar />
        <EzyTab list={tabs} onTabChange={handleTabChange} />
      </div>

      <AddLocationDrawer />
      <ConfigurationActionDrawer />
      <EzyDialog
        open={openLoginModal}
        handleClose={() => {}}
        className="common-info-modal myob-login-modal"
      >
        <div className="title">Connect to MYOB</div>
        <div className="login-dialog-body">
          <EzyAutoComplete
            label="MYOB file"
            placeholder="Select MYOB file"
            options={myObFilesList}
            value={myObLoginDetails?.fileName}
            onChange={(_, value) =>
              dispatch(updateMyObLoginDetails({ fieldName: 'fileName', value }))
            }
            loading={getMyObFileDetailsActionLoader}
          />
          <EzyInputField
            label="User name"
            placeholder="Enter name Eg.(Administrator)"
            value={myObLoginDetails?.userName}
            onChange={(e) =>
              dispatch(updateMyObLoginDetails({ fieldName: 'userName', value: e?.target?.value }))
            }
          />
          <EzyInputField
            label="Password"
            placeholder="Enter password"
            value={myObLoginDetails?.password}
            onChange={(e) =>
              dispatch(updateMyObLoginDetails({ fieldName: 'password', value: e?.target?.value }))
            }
          />
          <EzyButton
            label="Submit"
            onClick={handleSubmitMyObLogin}
            loading={saveMyObFileDetailsActionLoader}
          />
          <div className="subtitle-text">
            Authorise PayrollEzy to access your MYOB data. You only need to do this step once.
          </div>
        </div>
      </EzyDialog>
    </div>
  );
}

export default AppIntegrationSettings;
