import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState, createRef } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import EzyButton from '../button/EzyButton';
import EzyMenu from '../menu/EzyMenu';
import useAccess from '../../../hooks/useAccess';
import Loader from '../loader/Loader';

function EzyTab({ list, defaultIndex, onTabChange, loading }) {
  const [value, setValue] = useState(defaultIndex);
  const tabsRef = useRef([]);
  const [tabOffsetWidth, setTabOffsetWidth] = useState([]);
  const access = useAccess(list?.map((e) => e.access));

  const tabData = useMemo(() => list?.filter((e) => access?.includes(e?.access)), [access]);

  const handleChange = (event, newValue) => {
    if (typeof onTabChange === 'function' && onTabChange) {
      onTabChange(list[newValue]?.name, newValue);
    }
    setValue(newValue);
  };

  useEffect(() => {
    if (defaultIndex !== value && typeof onTabChange === 'function' && onTabChange) {
      setValue(defaultIndex);
    }
  }, [defaultIndex]);

  useEffect(() => {
    setTabOffsetWidth(tabsRef?.current?.map((e) => e?.current?.offsetWidth));
  }, [tabsRef]);

  return (
    <Box className="tab-container">
      <TabContext value={value}>
        <TabList
          onChange={handleChange}
          aria-label="basic tabs example"
          className="tabs"
          variant="fullWidth"
        >
          {tabData?.map((res, index) => {
            tabsRef.current[index] = createRef();
            return (
              <Tab
                ref={tabsRef.current[index]}
                key={index}
                label={
                  <>
                    <Box
                      sx={{
                        '::after': {
                          left: tabOffsetWidth[index] / 2,
                        },
                        '::before': {
                          right: tabOffsetWidth[index] / 2,
                        },
                      }}
                    />
                    {res?.label}
                  </>
                }
                className="tab"
                disableRipple
              />
            );
          })}
        </TabList>
        {tabData?.map((e, index) => (
          <TabPanel className="tab-panel" value={index}>
            {loading && <Loader />}
            {e.component}
          </TabPanel>
        ))}
      </TabContext>
      {tabData?.[value]?.actionButtons?.length > 0 && (
        <div className="action-wrapper">
          {tabData?.[value]?.actionButtons?.map(
            (
              {
                label,
                color,
                onClick,
                className,
                isLoading,
                isMenu,
                menuItems,
                iconName,
                disabled,
                ...rest
              },
              index
            ) =>
              isMenu ? (
                <EzyMenu
                  menuItems={menuItems}
                  onItemClick={onClick}
                  iconName={iconName}
                  label={label}
                  color={color}
                  {...rest}
                />
              ) : (
                <EzyButton
                  key={index}
                  label={label}
                  onClick={onClick}
                  className={className}
                  color={color}
                  loading={isLoading}
                  disabled={disabled}
                  {...rest}
                />
              )
          )}
        </div>
      )}
    </Box>
  );
}

export default EzyTab;

EzyTab.propTypes = {
  list: PropTypes.array.isRequired,
  defaultIndex: PropTypes.number,
  onTabChange: PropTypes.func,
  loading: PropTypes.bool,
};

EzyTab.defaultProps = {
  defaultIndex: 0,
  onTabChange: null,
  loading: false,
};
