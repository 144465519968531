import apiService from '../../utils/apiService';
import API_URL from '../../utils/urlConstants';

export const timeSheetServices = {
  getAllTimesheetByPerson: (params) =>
    apiService.getData(API_URL.TIME_SHEETS.getAllTimesheetByPerson, { params }),
  getLocationsDropdown: (params) =>
    apiService.getData(API_URL.TIME_SHEETS.getLocationsDropdown, { params }),
  getPersonListByBusinessId: (params) =>
    apiService.getData(API_URL.TIME_SHEETS.getPersonListByBusinessId, { params }),
  getPersonList: (params) => apiService.getData(API_URL.TIME_SHEETS.getPersonList, { params }),
  getTimeSheetById: (params) =>
    apiService.getData(API_URL.TIME_SHEETS.getTimeSheetById, { params }),
  getTimeSheetHistory: (params) =>
    apiService.getData(API_URL.TIME_SHEETS.getTimeSheetHistory, { params }),
  addTimeSheetDetails: (data) => apiService.postData(API_URL.TIME_SHEETS.addTimeSheetDetails, data),
  editTimeSheetDetails: (data) =>
    apiService.postData(API_URL.TIME_SHEETS.editTimeSheetDetails, data),
  changeTimeSheetStatus: (data) =>
    apiService.postData(API_URL.TIME_SHEETS.changeTimeSheetStatus, data),
  roundTimesheetTime: (data) => apiService.postData(API_URL.TIME_SHEETS.roundTimesheetTime, data),
  getJournalDetailsByDate: (params) =>
    apiService.getData(API_URL.TIME_SHEETS.getJournalDetailsByDate, { params }),
  getLocationAreaDropdown: (params) =>
    apiService.getData(API_URL.TIME_SHEETS.getLocationAreaDropdown, { params }),
  getTimesheetAllowances: (params) =>
    apiService.getData(API_URL.TIME_SHEETS.timesheetAllowances, { params }),
  editTimesheetAllowances: (data) =>
    apiService.postData(API_URL.TIME_SHEETS.timesheetAllowances, data),
};
