import React from 'react';
import { useParams } from 'react-router-dom';
// import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import InsightSidebar from './components/InsightSidebar';
import AddBudgetDrawer from './components/budget/AddBudgetDrawer';
import InsightSummary from './components/insightSummary/InsightSummary';
import Budget from './components/budget/Budget';
import useUrlParamsUpdate from '../../hooks/useURLParamsUpdate';
import Metric from './components/metric/Metric';
import AddMetricDrawer from './components/metric/AddMetricDrawer';
import LaborModal from './components/staff/LaborModal';
import Staff from './components/staff/Staff';

function Insight() {
  const { pathname } = useParams();
  const { filters } = useSelector(({ insight }) => insight ?? {});

  useUrlParamsUpdate({
    location: filters?.selectedLocation?.id,
  });

  return (
    <div className="d-flex gap-10">
      <InsightSidebar />
      {pathname === 'Summary' && <InsightSummary />}
      {pathname?.includes('budget') && <Budget />}
      {pathname === 'Labor model' && <LaborModal />}
      {pathname === 'staff' && <Staff />}
      {!pathname?.includes('budget') &&
        pathname !== 'Summary' &&
        pathname !== 'Labor model' &&
        pathname !== 'staff' && <Metric />}
      <AddBudgetDrawer />
      <AddMetricDrawer />
    </div>
  );
}

export default Insight;
