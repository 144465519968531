import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import EzyAvatar from '../../../components/common/avatar/EzyAvatar';
import EzyDialog from '../../../components/common/dialog/EzyDialog';
import EzyDialogAction from '../../../components/common/dialog/EzyDialogAction';
import EzyDialogTitle from '../../../components/common/dialog/EzyDialogTitle';
import IconByName from '../../../components/common/iconByName/IconByName';
import EzyAutoComplete from '../../../components/common/autoComplete/EzyAutoComplete';
import { updateDeleteLocationDetails } from '../redux/locationSlice';
import { deleteLocationAction } from '../redux/locationActions';
import Loader from '../../../components/common/loader/Loader';
import ROUTE_CONSTANTS from '../../../utils/constants';
import { updateTimeSheetData } from '../../timeSheets/redux/timeSheetsSlice';

function DeleteLocation({ isOpen, handleClose, rowData }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { archiveDetails } = useSelector(({ location }) => location?.archiveLocation ?? {});
  const { newLocation } = useSelector(({ location }) => location?.deleteLocation ?? {});

  const { deleteLocationActionLoader, getDetailsBeforeArchiveLocationActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const handleSubmit = () => {
    const data = {
      locationId: rowData?.locationId,
      newLocationId: newLocation?.id,
    };

    dispatch(
      deleteLocationAction(data, () => {
        handleClose(true);
      })
    );
  };

  const deleteLocationButtons = [
    {
      label: 'Cancel',
      onClick: () => {
        handleClose();
      },
      color: 'secondary',
    },
    {
      label: 'Delete',
      onClick: handleSubmit,
      disabled:
        archiveDetails?.numberOfTimeSheets > 0 ||
        archiveDetails?.totalPeopleWithUpcomingShift > 0 ||
        (archiveDetails?.people?.length > 0
          ? !newLocation?.id
          : !archiveDetails?.people?.length < 0),
      loading: deleteLocationActionLoader,
    },
  ];

  return (
    <EzyDialog
      open={isOpen}
      className="common-dialog-with-body archive-dialog"
      handleClose={handleClose}
    >
      <EzyDialogTitle title="Delete" onClose={handleClose} />

      {getDetailsBeforeArchiveLocationActionLoader ? (
        <Loader />
      ) : (
        <>
          <div className="dialog-body">
            <span className="icon-small">Are you sure you want to delete this location?</span>

            {archiveDetails?.numberOfTimeSheets > 0 && (
              <div className="error-div">
                <IconByName name="error" />

                <div>
                  Please approve or discard {archiveDetails?.numberOfTimeSheets} timesheets before
                  proceeding.{' '}
                  <span
                    onClick={() => {
                      history.push(
                        `${ROUTE_CONSTANTS.TIMESHEETS}?location=${rowData?.locationId}&date=${archiveDetails?.timeSheetDate}&area=${archiveDetails?.areaId}`
                      );
                      dispatch(
                        updateTimeSheetData({
                          fieldName: 'viewTimeSheetDrawer',
                          value: archiveDetails?.timeSheetId,
                        })
                      );
                    }}
                  >
                    {' '}
                    Click Here{' '}
                  </span>
                  to view the first one.
                </div>
              </div>
            )}

            {archiveDetails?.totalPeopleWithUpcomingShift > 0 && (
              <div className="error-div">
                <IconByName name="error" />

                <div>
                  {archiveDetails?.totalPeopleWithUpcomingShift} team members have upcoming shifts
                  at this location. Remove these shifts or reassign the location before archiving.
                </div>
              </div>
            )}

            {archiveDetails?.people?.length > 0 && (
              <div className="icon-small">
                {archiveDetails?.people?.length} Employees must be migrated to another location
                before you can proceed.
              </div>
            )}

            {archiveDetails?.people?.length > 0 && (
              <EzyAutoComplete
                name="location"
                label="Choose a location to migrate employees:"
                options={archiveDetails?.location}
                placeholder="Select location"
                getOptionDisabled={(option) => option.id === rowData?.locationId}
                onChange={(_, value) => {
                  dispatch(updateDeleteLocationDetails({ fieldName: 'newLocation', value }));
                }}
                value={newLocation}
                required
              />
            )}

            {archiveDetails?.people?.length > 0 && (
              <div className="avatar-row">
                {archiveDetails?.people?.map((people) => {
                  return (
                    <EzyAvatar
                      firstName={people?.firstName || people?.name}
                      lastName={people?.lastName}
                      imageLink={people?.profilePictureUrl}
                    />
                  );
                })}
              </div>
            )}
          </div>

          <EzyDialogAction actionButtons={deleteLocationButtons} />
        </>
      )}
    </EzyDialog>
  );
}

export default DeleteLocation;

DeleteLocation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  rowData: PropTypes.object,
  handleClose: PropTypes.func,
};

DeleteLocation.defaultProps = {
  handleClose: null,
  rowData: {},
};
