import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ViewBillingDetails from './ViewBillingDetails';
import EditBillingDetails from './EditBillingDetails';
import PlanSummary from './PlanSummary';
import useQueryParams from '../../../../hooks/useQueryParams';
import { resetBillingDetails } from '../../redux/globalProfileSlice';
import Loader from '../../../../components/common/loader/Loader';

function BillingAndAccount() {
  const { billingDetails } = useSelector(({ globalProfile }) => globalProfile || {});
  const { createCheckOutSessionActionLoader, createStripeCustomerActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );
  const { isEditBilling } = useMemo(() => billingDetails ?? {}, [billingDetails]);
  const { planId } = useQueryParams();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetBillingDetails());
    };
  }, []);

  if (isEditBilling || planId) {
    return (
      <div className="plan-and-summary-wrapper">
        {(createCheckOutSessionActionLoader || createStripeCustomerActionLoader) && <Loader />}
        <EditBillingDetails />
        {planId && <PlanSummary />}
      </div>
    );
  }

  return <ViewBillingDetails />;
}

export default BillingAndAccount;
