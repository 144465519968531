import React from 'react';
import PropTypes from 'prop-types';
import EzyDialog from '../../../components/common/dialog/EzyDialog';
import EzyDialogTitle from '../../../components/common/dialog/EzyDialogTitle';
import EzyDialogAction from '../../../components/common/dialog/EzyDialogAction';

function BulkAction({
  open,
  handleClose,
  onApplyClick,
  children,
  selectedRowNumber,
  title,
  key,
  isLoading,
  ...rest
}) {
  const actionButtons = [
    {
      label: rest?.actionTitle ? rest?.actionTitle : 'Apply',
      onClick: onApplyClick,
      loading: isLoading,
      disabled: rest?.disabledAction,
    },
  ];
  return (
    <EzyDialog
      handleClose={handleClose}
      open={open}
      className="common-dialog-with-body bulk-action-modal"
      key={key}
    >
      <EzyDialogTitle title={title} onClose={handleClose} />
      <div className="dialog-body">
        <div className="primary-bg">{selectedRowNumber} Team member selected</div>
        {children}
      </div>
      <EzyDialogAction actionButtons={actionButtons} />
    </EzyDialog>
  );
}

export default BulkAction;

BulkAction.propTypes = {
  open: PropTypes.bool.isRequired,
  onApplyClick: PropTypes.func.isRequired,
  children: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  selectedRowNumber: PropTypes.number,
  handleClose: PropTypes.func,
  key: PropTypes.string,
  isLoading: PropTypes.bool,
};

BulkAction.defaultProps = {
  handleClose: () => {},
  selectedRowNumber: 0,
  key: 'bulkAction',
  isLoading: false,
};
