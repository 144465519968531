import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { filter, isEmpty, isNil, omitBy } from 'lodash';
import moment from 'moment-timezone';
import EzyTab from '../../../components/common/tab/EzyTab';
import validateAddLocation from '../validations/validateAddLocation';
import {
  resetAddLocationDetail,
  setAddLocationList,
  setEditDetailsLocationError,
  setEditGeneralLocationList,
} from '../redux/locationSlice';
import {
  sendAddLocation,
  sendEditDetailsLocation,
  sendEditGeneralLocation,
} from '../redux/locationActions';
import ROUTE_CONSTANTS from '../../../utils/constants';
import { formatPhoneToSend } from '../../../utils/phoneNumberHelper';
import EditLocationGeneral from './EditLocationGeneral';
import AddLocation from './AddLocation';
import EditLocationDetails from './EditLocationDetails';
import validateGeneralEditLocation from '../validations/validateGeneralEditLocation';
import validateDetailsEditLocation from '../validations/validateDetailsEditLocation';

function AddEditLocation() {
  const { action, locationId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const addLocationData = useSelector(({ location }) => location.addLocationData || {});
  const businessId = useSelector(({ user }) => user?.profileData?.businessId || '');
  const editLocationData = useSelector(({ location }) => location.editLocationData || {});
  const editDetailsLocationData = useSelector(
    ({ location }) => location.editDetailsLocationData || {}
  );

  const { sendEditGeneralLocationLoader, sendAddLocationLoader, sendEditDetailsLocationLoader } =
    useSelector(({ common }) => common.generalLoader ?? {});

  const addLocation = async () => {
    const {
      locationName,
      locationAddress,
      weekStartOn,
      timeZoneId,
      addAreaAddress,
      sendInvitationEmail,
      areas,
      people,
      city,
    } = { ...addLocationData };

    const finalData = {
      addAreaAddress,
      locationName,
      businessId,
      city,
      locationAddress,
      weekStartOn: weekStartOn?.id,
      timeZoneId: timeZoneId?.id,
      startTime: moment
        .tz(moment(new Date())?.toISOString(), timeZoneId?.label)
        .set({ hours: 9, minutes: 0, second: 0, millisecond: 0 })
        ?.toISOString(),
      endTime: moment
        .tz(moment(new Date())?.toISOString(), timeZoneId?.label)
        .set({ hours: 17, minutes: 0, second: 0, millisecond: 0 })
        ?.toISOString(),
      sendInvitationEmail,
      areas: areas?.map((res) => ({
        areaOfWork: res.areaOfWork || null,
        address: res.address || null,
      })),
      people: people?.map((res) => ({
        ...res,
        name: res.name || null,
        email: res.email || null,
        phoneNumber: res.phoneNumber ? formatPhoneToSend(res.phoneNumber, res.countryCode) : null,
        countryCode: res.phoneNumber ? res.countryCode : null,
      })),
    };
    finalData.people = filter(
      finalData?.people?.map((e) => omitBy(e, isNil)),
      (e) => !isEmpty(e)
    )?.length
      ? finalData.people
      : undefined;
    finalData.areas = filter(
      finalData?.areas?.map((e) => omitBy(e, isNil)),
      (e) => !isEmpty(e)
    )?.length
      ? finalData.areas
      : undefined;
    const error = validateAddLocation({
      ...finalData,
      people: people.map((res) => ({
        ...res,
        phoneNumberData: res?.phoneNumberData,
      })),
    });
    dispatch(setAddLocationList({ fieldName: 'errors', value: error }));
    if (isEmpty(error)) {
      const isSuccess = await dispatch(sendAddLocation(finalData));
      if (isSuccess) {
        history.push(ROUTE_CONSTANTS.LOCATIONS);
        dispatch(resetAddLocationDetail());
      }
    }
  };
  const editActionGeneral = async () => {
    const {
      locationName,
      locationAddress,
      locationCode,
      timeZoneId,
      addAreaAddress,
      note,
      weekDay,
      areas,
      city,
    } = { ...editLocationData };

    const finalData = {
      locationName,
      locationAddress,
      locationCode,
      city,
      locationID: locationId,
      timeZoneId: timeZoneId?.id,
      addAreaAddress,
      generalSettings: {
        operatingHours: {
          weekDay: weekDay?.map((e) => ({
            ...e,
            startTime: !e?.is24Hours ? e.startTime : null,
            endTime: !e?.is24Hours ? e.endTime : null,
          })),
        },
        note,
      },
      areas: areas?.map((res) => ({
        areaName: res.areaName || null,
        areaAddress: res.areaAddress || null,
        areaId: res.areaId || null,
      })),
    };
    finalData.areas = filter(
      finalData?.areas?.map((e) => omitBy(e, isNil)),
      (e) => !isEmpty(e)
    )?.length
      ? finalData.areas
      : undefined;

    const error = validateGeneralEditLocation(finalData);
    dispatch(setEditGeneralLocationList({ fieldName: 'errors', value: error }));
    if (isEmpty(error)) {
      const isSuccess = await dispatch(sendEditGeneralLocation(finalData));
      if (isSuccess) history.push(ROUTE_CONSTANTS.LOCATIONS);
    }
  };
  const editActionDetails = async () => {
    const {
      breaks,
      UnscheduledShifts,
      timesheetApproving,
      teamMemberPermissions,
      clockInAndOut,
      swapsAndOffers,
      creatingAndPublishingShifts,
      basics,
    } = { ...editDetailsLocationData };

    const finalData = {
      locationID: locationId,
      timeSheetSettings: {
        isClockInOutViaWeb: clockInAndOut?.isClockInOutViaWeb,
        isClockInOutViaMobile: clockInAndOut?.isClockInOutViaMobile,
        allowGeoFencing: clockInAndOut?.allowGeoFencing,
        isPhotoRequiredInKiosk: clockInAndOut?.isPhotoRequiredInKiosk,
        minimumTimeSheetLengthInMinutes: clockInAndOut?.minimumTimeSheetLengthInMinutes?.id,
        isTeamMemberAllowedToEditTimeSheet:
          teamMemberPermissions?.isTeamMemberAllowedToEditTimeSheet,
        editTimeSheetDuringShifts: teamMemberPermissions?.editTimeSheetDuringShifts,
        allowToStartShiftBeforeScheduledTime:
          teamMemberPermissions?.allowToStartShiftBeforeScheduledTime,
        earlyStartShiftBufferTime: Number(teamMemberPermissions?.earlyStartShiftBufferTime),
        earlyEndShiftBufferTime: Number(teamMemberPermissions?.earlyEndShiftBufferTime),
        allowToEndBreaksEarlier: teamMemberPermissions?.allowToEndBreaksEarlier,
        autoApproveTimeSheetVariance: timesheetApproving?.autoApproveTimeSheetVariance?.id,
        roundTimeSheets: timesheetApproving?.roundTimeSheets,
        isRoundToScheduledStartTime: timesheetApproving?.isRoundToScheduledStartTime,
        startTimeRoundingType: timesheetApproving?.startTimeRoundingType?.id,
        isRoundToScheduledEndTime: timesheetApproving?.isRoundToScheduledEndTime,
        endTimeRoundingType: timesheetApproving?.endTimeRoundingType?.id,
        isRoundToScheduledBreakForShorterDuration:
          timesheetApproving?.isRoundToScheduledBreakForShorterDuration,
        breakTimeRoundingType: timesheetApproving?.breakTimeRoundingType?.id,
        linkUnscheduledTimeSheetType: UnscheduledShifts?.linkUnscheduledTimeSheetType?.id,
        unscheduledShiftTimeVariance: UnscheduledShifts?.unscheduledShiftTimeVariance?.id,
        isBreakSuggestionOn: breaks?.isBreakSuggestionOn,
        isDisplayWarningForShorterBreaks: breaks?.isDisplayWarningForShorterBreaks,
        isBreakPaid: breaks?.isBreakPaid?.id,
      },
      schedulingSettings: {
        weekStartsOn: basics?.weekStartsOn?.id,
        defaultShiftDuration: Number(basics?.defaultShiftDuration),
        defaultBreakDuration: basics?.defaultBreakDuration,
        preventShiftChange: creatingAndPublishingShifts?.preventShiftChange?.id,
        shiftNotificationRecipient: creatingAndPublishingShifts?.shiftNotificationRecipient?.id,
        shiftRemovedNotification: creatingAndPublishingShifts?.shiftRemovedNotification?.id,
        coWorkerScheduleVisibility: swapsAndOffers?.coWorkerScheduleVisibility?.id,
        allowSwapShifts: swapsAndOffers?.allowSwapShifts,
        isManagerApprovalRequiredToSwap: swapsAndOffers?.isManagerApprovalRequiredToSwap,
        allowOfferShifts: swapsAndOffers?.allowOfferShifts,
      },
    };
    const error = validateDetailsEditLocation({
      basics,
      breaks,
      UnscheduledShifts,
      creatingAndPublishingShifts,
      swapsAndOffers,
      clockInAndOut,
      teamMemberPermissions,
      timesheetApproving,
    });
    dispatch(setEditDetailsLocationError(error));

    if (isEmpty(error)) {
      const isSuccess = await dispatch(sendEditDetailsLocation(finalData));
      if (isSuccess) history.push(ROUTE_CONSTANTS.LOCATIONS);
    }
  };

  if (!['add', 'update'].includes(action)) return null;
  // const { action } = useParams();
  // if (!['add', 'edit'].includes(action)) return null;
  const addActionButtons = [
    {
      label: 'Back',
      onClick: () => history.push(ROUTE_CONSTANTS.LOCATIONS),
      color: 'secondary',
    },
    {
      label: 'Save',
      onClick: () => addLocation(),
      isLoading: sendAddLocationLoader,
    },
  ];
  const editActionGeneralButtons = [
    {
      label: 'Back',
      onClick: () => history.push(ROUTE_CONSTANTS.LOCATIONS),
      color: 'secondary',
    },
    {
      label: 'Save',
      onClick: () => editActionGeneral(),
      isLoading: sendEditGeneralLocationLoader,
    },
  ];
  const editActionDetailsButtons = [
    {
      label: 'Back',
      onClick: () => history.push(ROUTE_CONSTANTS.LOCATIONS),
      color: 'secondary',
    },
    {
      label: 'Save',
      onClick: () => editActionDetails(),
      isLoading: sendEditDetailsLocationLoader,
    },
  ];
  const addTabs = [
    {
      label: 'General',
      component: <AddLocation />,
      actionButtons: addActionButtons,
      access: 'CREATELOC',
    },
  ];
  const editTabs = [
    {
      label: 'General',
      component: <EditLocationGeneral />,
      actionButtons: editActionGeneralButtons,
      access: 'EDITLOC',
    },
    {
      label: 'Details',
      component: <EditLocationDetails />,
      actionButtons: editActionDetailsButtons,
      access: 'EDITLOC',
    },
  ];

  return <EzyTab list={action === 'add' ? addTabs : editTabs} />;
}

export default AddEditLocation;
