import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import PropTypes from 'prop-types';
import { toLower, upperFirst } from 'lodash';

function EzyPhoneNumber({
  value,
  label,
  placeholder,
  onChange,
  className,
  error,
  country,
  disabled,
  ...restProps
}) {
  return (
    <FormControl
      variant="standard"
      className={`phoneInput ${className}`}
      disabled={disabled}
      aria-disabled={disabled}
    >
      {label && (
        <InputLabel shrink htmlFor="bootstrap-input">
          {label}
          {restProps?.required && <div className="required">*</div>}
        </InputLabel>
      )}
      <PhoneInput
        className={`${error ? 'field-error' : ''} ${disabled ? 'disabled' : ''}`}
        placeholder={upperFirst(toLower(placeholder))}
        country={country}
        value={value}
        onChange={onChange}
        disabled={disabled}
        {...restProps}
      />
      {error && <FormHelperText className="error-helper">{error}</FormHelperText>}
    </FormControl>
  );
}

export default EzyPhoneNumber;

EzyPhoneNumber.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  country: PropTypes.string,
};

EzyPhoneNumber.defaultProps = {
  label: '',
  value: '',
  onChange: () => {},
  className: '',
  disabled: false,
  error: false,
  country: 'au',
};
