import React from 'react';
import { useSelector } from 'react-redux';

function IntegrationAppInfo() {
  const { integrationInformation, versionInformation } = useSelector(
    ({ globalProfile }) =>
      globalProfile?.appIntegration?.specificIntegrationDetails?.integrationEntity || {}
  );

  return (
    <div className="app-info-root-container">
      <div className="app-info-container">
        {integrationInformation?.length > 0
          ? integrationInformation?.map((e, index) => {
              const description = e?.description?.split('.');

              return (
                <>
                  <div>
                    <div className="text-secondary-color body-text ">{e?.heading}</div>
                    {description?.length > 0 ? (
                      description?.map((data) => data && <div className="p-bottom-10">{data}.</div>)
                    ) : (
                      <div>-</div>
                    )}
                  </div>
                  {index + 1 < integrationInformation?.length && <hr />}
                </>
              );
            })
          : 'No information found'}
      </div>
      <div className="app-info-sidebar">
        <div className="text-secondary-color body-text p-bottom-10">Version Info</div>
        {versionInformation?.length > 0 ? (
          versionInformation?.map((e) => (
            <div className="d-flex">
              <span className="text-secondary-color word-break">{e?.heading}: &nbsp;</span>
              <span className="word-break">{e?.description}</span>
            </div>
          ))
        ) : (
          <div>No information found</div>
        )}
      </div>
    </div>
  );
}

export default IntegrationAppInfo;
