import apiService from '../../utils/apiService';
import API_URL from '../../utils/urlConstants';

export const newsFeedServices = {
  getNewsFeedList: (params) => apiService.getData(API_URL.NEWSFEED.newsFeed, { params }),
  commentOnPost: (data) => apiService.postData(API_URL.NEWSFEED.commentOnPost, data),
  deletePostComment: (params) => apiService.deleteData(API_URL.NEWSFEED.commentOnPost, { params }),
  deleteNewsFeedPost: (params) => apiService.deleteData(API_URL.NEWSFEED.newsFeed, { params }),
  confirmNewsFeedPost: (data) => apiService.putData(API_URL.NEWSFEED.confirmNewsFeedPost, data),
  sendNewsFeedDrawer: (data) => apiService.postData(API_URL.NEWSFEED.newsFeed, data),
  uploadMedia: (data, config) =>
    apiService.postData(API_URL.NEWSFEED.uploadMedia, data, {
      ...config,
      timeout: 2 * 60 * 1000,
    }),
  deleteNewsFeedFile: (params) =>
    apiService.deleteData(API_URL.NEWSFEED.deleteNewsFeedFile, { params }),
  getNewsFeedReceiverList: (params) =>
    apiService.getData(API_URL.NEWSFEED.newsFeedReceiverList, { params }),
  sharePostWithOthers: (data) => apiService.putData(API_URL.NEWSFEED.newsFeed, data),
};
