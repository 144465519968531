import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EzyDrawer from '../../../components/common/drawer/Drawer';
import { updateLogsList } from '../redux/logbookSlice';
import EzyAutoComplete from '../../../components/common/autoComplete/EzyAutoComplete';
import EzyTime from '../../../components/common/dateAndTimePicker/EzyTime';
import { saveAutoSendEmailTimeAction, sendLogBookSummaryAction } from '../redux/logbookActions';

function SendEmailSummaryDrawer() {
  const dispatch = useDispatch();
  const { logsList, filters } = useSelector(({ logbook }) => logbook ?? {});

  const { sendEmailSummaryDrawer, isAutoSendEmail, time } = useMemo(() => logsList, [logsList]);

  const { saveAutoSendEmailTimeActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const handleClose = () => {
    dispatch(updateLogsList({ fieldName: 'sendEmailSummaryDrawer', value: false }));
  };

  const actionButton = useMemo(() => {
    const actions = [];
    if (!isAutoSendEmail) {
      actions?.push({
        label: 'Send now',
        onClick: () => {
          dispatch(
            sendLogBookSummaryAction(
              {
                locationId: filters?.selectedLocation?.id,
                date: filters?.date,
              },
              (data) => {
                if (!data) {
                  handleClose();
                } else {
                  dispatch(updateLogsList({ fieldName: 'requiredLogCategory', value: data }));
                  handleClose();
                }
              }
            )
          );
        },
      });
    }
    actions?.push({
      label: 'Save',
      onClick: () => {
        dispatch(
          saveAutoSendEmailTimeAction(
            { time: isAutoSendEmail ? time : null, isAutoSendEmail },
            () => {
              handleClose();
            }
          )
        );
      },
      loading: saveAutoSendEmailTimeActionLoader,
    });
    return actions;
  }, [isAutoSendEmail, time, filters]);

  return (
    <EzyDrawer
      isOpen={sendEmailSummaryDrawer}
      onClose={handleClose}
      heading="Send Email Summary"
      actionButtons={actionButton}
    >
      <div className="email-summary-drawer">
        <div className="summary-info">
          An email can be sent to managers manually or automatically at a specific time.
        </div>

        <EzyAutoComplete
          label="Email"
          placeholder="Select type"
          options={['Manually send email', 'Auto-send daily']}
          value={isAutoSendEmail ? 'Auto-send daily' : 'Manually send email'}
          onChange={(_, value) =>
            dispatch(
              updateLogsList({ fieldName: 'isAutoSendEmail', value: value === 'Auto-send daily' })
            )
          }
        />

        {isAutoSendEmail && (
          <EzyTime
            label="Time"
            placeholder="Select Time"
            onChange={(value) => dispatch(updateLogsList({ fieldName: 'time', value }))}
            value={time}
          />
        )}
      </div>
    </EzyDrawer>
  );
}

export default SendEmailSummaryDrawer;
