import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useMediaQuery } from '@mui/material';
import EzyTab from '../../../components/common/tab/EzyTab';
import ROUTE_CONSTANTS from '../../../utils/constants';
import AddPeople from './AddPeople';
import { formatPhoneToSend } from '../../../utils/phoneNumberHelper';
import validateAddPeopleData from '../validations/addPeopleValidations';
import {
  editPeopleDetails,
  resetAddPeopleFields,
  updateAddJournalDrawerDetails,
  updateAddPeopleFields,
  updateAddUnavailabilityDetails,
} from '../redux/peopleSlice';
import { addPeopleData, editPeopleDetailsAction, sendPasswordToMail } from '../redux/peopleActions';
import PeopleDetails from './editPeople/PeopleDetails';
import LeaveDetails from './leaveDetails/LeaveDetails';
import validateEditPeopleDetails from '../validations/editPeopleDetailsValidations';
import ShiftDetails from './shiftDetails/ShiftDetails';
import SendPasswordIntoEmailModal from './modals/SendPasswordIntoEmailModal';
import ResetLoginDetails from './modals/ResetLoginDetails';
import { startTime } from '../../../utils/timeHelper';
import useAccess from '../../../hooks/useAccess';
import JournalList from './journal/JournalList';
import UnavailabilityDetails from './UnavailabiltyDetails/UnavailabilityDetails';

function AddEditPeople() {
  const { addPeople, editPeople, isLoading, journalDetails } = useSelector(
    ({ people }) => people ?? {}
  );
  const businessId = useSelector(({ user }) => user?.profileData?.businessId || '');
  const dispatch = useDispatch();
  const history = useHistory();
  const { tab = 0 } = history?.location?.state ?? {};
  const { peopleId } = useParams();
  const isTabletScreen = useMediaQuery('(max-width: 1124px)');

  const { location, sendInvitationEmail, people } = useMemo(() => addPeople ?? {}, [addPeople]);

  const { details, locationsList } = useMemo(() => editPeople ?? {}, [editPeople]);

  const { addPeopleDataLoader, editPeopleDetailsActionLoader, getPeopleDetails } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const [model, setModel] = useState(null);

  const { roleId: loggedInRole, personId } = useSelector(({ user }) => user?.profileData ?? {});

  const hasAccessOfJournal = () => {
    if (
      peopleId === personId ||
      (loggedInRole === 3 && journalDetails?.personDetail?.roleId === 1) ||
      getPeopleDetails
    ) {
      return false;
    }
    return true;
  };

  const isDisabled = useMemo(
    () => details?.roleId?.id < loggedInRole ?? true,
    [details, loggedInRole]
  );
  const hasAccessToEdit = useAccess('EDITPPL');

  const onPeopleAdd = async () => {
    const finalData = {
      locationId: location?.id,
      businessId,
      sendInvitationEmail,
      people: people.map((res) => ({
        ...res,
        accessLevel: res.accessLevel.id,
        phoneNumber: formatPhoneToSend(res.phoneNumber, res.countryCode),
      })),
    };

    const error = validateAddPeopleData({
      ...finalData,
      people: people.map((res) => ({
        ...res,
        phoneNumberData: res?.phoneNumberData,
      })),
      roleId: loggedInRole,
    });
    dispatch(updateAddPeopleFields({ fieldName: 'errors', value: error }));
    if (isEmpty(error)) {
      const isSuccess = await dispatch(addPeopleData(finalData));
      if (isSuccess) {
        history.push(ROUTE_CONSTANTS.PEOPLE);
        dispatch(resetAddPeopleFields());
      }
    }
  };

  const onPeopleDetailsEdit = async () => {
    const data = {
      personDetailId: details?.personDetailId,
      roleId: details?.roleId?.id,
      primaryLocationId: details?.primaryLocationId?.id,
      workLocations: locationsList
        ?.filter((e) => details?.workLocations?.includes(e?.label))
        ?.map((e) => e?.id),
      joiningDate: startTime(details?.joiningDate),
      workPeriodDetail: details?.workPeriodId?.id
        ? {
            workPeriodId: details?.workPeriodId?.id,
            totalHours: moment.duration(`${details?.hours}:${details?.minutes}`, 'HH:mm').asHours(),
            // totalHours: 0,
          }
        : undefined,
      profilePhotoURL: details?.profilePhotoURL,
      preferredFullName: details?.preferredFullName,
      dateOfBirth: startTime(details?.dateOfBirth),
      pronoun: details?.pronoun?.id,
      address: details?.address,
      postCode: details?.postCode,
      city: details?.city,
      state: details?.state,
      countryId: details?.countryId?.id,
      phoneNumber: formatPhoneToSend(details?.phoneNumber, details?.countryCode),
      countryCode: details?.countryCode,
      email: details?.email,
      emergencyContactName: details?.emergencyContactName,
      emergencyContactCountryCode: details?.emergencyContactCountryCode,
      emergencyContactNumber: formatPhoneToSend(
        details?.emergencyContactNumber,
        details?.emergencyContactCountryCode
      ),
      payRateType: details?.payRateType?.id,
      payRollId: details?.payRollId,
      employmentType: details?.employmentType?.id,
    };

    const errors = validateEditPeopleDetails({
      ...data,
      emergencyContactNumber: details?.emergencyContactNumber,
      emergencyContactNumberData: details?.emergencyContactNumberData,
      phoneNumber: details?.phoneNumber,
      phoneNumberData: details?.phoneNumberData,
    });

    dispatch(editPeopleDetails({ fieldName: 'errors', value: errors }));

    if (isEmpty(errors)) {
      await dispatch(editPeopleDetailsAction(data, () => history.push(ROUTE_CONSTANTS.PEOPLE)));
    }
  };

  const { action } = useParams();

  if (!['add', 'edit'].includes(action)) return null;

  const addActionButtons = [
    {
      label: 'Back',
      onClick: () => {
        history.push(ROUTE_CONSTANTS.PEOPLE);
      },
      color: 'secondary',
    },
    {
      label: 'Save',
      onClick: () => onPeopleAdd(),
      isLoading: addPeopleDataLoader,
    },
  ];

  const editActionButtons = useMemo(() => {
    const actions = [];
    if (
      details?.invitationStatus === 1 &&
      !isDisabled &&
      personId !== peopleId &&
      hasAccessToEdit
    ) {
      actions.push({
        isMenu: true,
        label: !isTabletScreen && 'Change login credentials',
        iconName: 'login',
        color: 'secondary',
        tooltip: 'Change login credentials',
        menuItems: [
          {
            label: 'Send reset password link to email',
            id: 1,
          },
          {
            label: 'Reset login details',
            id: 2,
          },
        ],
        onClick: (_, value) => {
          if (value.id === 1) {
            setModel('SendPasswordIntoEmail');
            dispatch(sendPasswordToMail(details?.email));
          } else setModel('ResetLoginDetails');
        },
      });
    }
    actions.push({
      label: 'Back',
      onClick: () => {
        history.push(ROUTE_CONSTANTS.PEOPLE);
      },
      color: 'secondary',
    });
    if (hasAccessToEdit) {
      actions.push({
        label: 'Save',
        onClick: () => onPeopleDetailsEdit(),
        isLoading: editPeopleDetailsActionLoader,
      });
    }
    return actions;
  }, [details, editPeopleDetailsActionLoader, isTabletScreen]);

  const leavesActionButtons = [
    {
      label: 'Back',
      onClick: () => {
        history.push(ROUTE_CONSTANTS.PEOPLE);
      },
      color: 'secondary',
      isLoading,
    },
  ];
  const UnavailabilityActionButtons = [
    {
      label: 'Back',
      onClick: () => {
        history.push(ROUTE_CONSTANTS.PEOPLE);
      },
      color: 'secondary',
      isLoading,
    },
    {
      label: (
        <div className="flex-center gap-5">
          <span className="material-icons">add</span> Add
        </div>
      ),
      onClick: () => {
        dispatch(updateAddUnavailabilityDetails({ fieldName: 'isDrawerOpen', value: true }));
      },
      className: 'no-pad-button',
      access: 'CREATEUNAV',
    },
  ];

  const journalActionButtons = [
    {
      label: 'Back',
      onClick: () => {
        history.push(ROUTE_CONSTANTS.PEOPLE);
      },
      color: 'secondary',
    },
    {
      label: (
        <div className="flex-center gap-5">
          <span className="material-icons">add</span> Add
        </div>
      ),
      onClick: () => {
        dispatch(updateAddJournalDrawerDetails({ fieldName: 'isOpen', value: true }));
      },
      className: 'no-pad-button',
    },
  ];

  const addTabs = [
    {
      label: 'General',
      component: <AddPeople />,
      actionButtons: addActionButtons,
      access: 'default',
    },
  ];

  const editTabs = [
    {
      label: 'Details',
      component: <PeopleDetails />,
      actionButtons: editActionButtons,
      access: 'VIEWPPL',
    },
    {
      label: 'Shift',
      component: <ShiftDetails />,
      actionButtons: leavesActionButtons,
      access: 'LISTEMPSCHED',
    },
    {
      label: 'Leave',
      component: <LeaveDetails />,
      actionButtons: leavesActionButtons,
      access: 'LISTLVMGMT',
    },
    {
      label: 'Unavailability',
      component: <UnavailabilityDetails />,
      actionButtons: UnavailabilityActionButtons,
      access: 'LISTUNAV',
    },
    {
      label: 'Journal',
      component: <JournalList />,
      actionButtons: journalActionButtons,
      access: hasAccessOfJournal() ? 'LISTJRNL' : '',
    },
  ];

  // useEffect(() => {
  //   return () => {
  //     // window.history.replaceState({}, document.title);
  //   };
  // }, []);

  return (
    <>
      <EzyTab list={action === 'add' ? addTabs : editTabs} defaultIndex={tab} />
      <SendPasswordIntoEmailModal setModel={setModel} model={model} email={details?.email} />
      <ResetLoginDetails setModel={setModel} model={model} email={details?.email} />
    </>
  );
}

export default AddEditPeople;
