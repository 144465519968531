import { filter, isEmpty, isNil, omitBy } from 'lodash';
import { requireValidate } from '../../../utils/validationHelper';

export const validatePayRate = (data) => {
  const { salaryPayDetail, hourlyPayDetails, payRateType, libraryRateDetailEntity } = data ?? {};
  const errors = {};
  errors.payRateType = requireValidate('Pay Rate Type', payRateType);
  if (payRateType === 1) {
    errors.hourlyPayDetails = hourlyPayDetails?.map((e, index) =>
      omitBy(
        {
          areaIds: index > 0 ? requireValidate('Area', e?.areaIds) : null,
          weekDayRate: requireValidate('Week Day Rate', e?.weekDayRate),
          publicHolidayRate: requireValidate('Public Holiday Rate', e?.publicHolidayRate),
          saturdayRate: requireValidate('Saturday Rate', e?.saturdayRate),
          sundayRate: requireValidate('Sunday Rate', e?.sundayRate),
        },
        isNil
      )
    );
    errors.hourlyPayDetails = filter(errors.hourlyPayDetails, (e) => !isEmpty(e))?.length
      ? errors.hourlyPayDetails
      : null;
  }
  if (payRateType === 2) {
    errors.salaryPeriod = requireValidate('Salary Period', salaryPayDetail?.salaryPeriod);
    errors.salaryAmount = requireValidate('Salary Amount', salaryPayDetail?.salaryAmount);
  }
  if (![1, 2]?.includes(payRateType)) {
    errors.libraryRateDetailEntity = libraryRateDetailEntity?.map((e, index) =>
      omitBy(
        {
          areaIds: index > 0 ? requireValidate('Area', e?.areaIds) : null,
          awardCode: index > 0 ? requireValidate('Award code', e?.awardCode) : null,
          baseRate: requireValidate('Base rate', e?.baseRate),
        },
        isNil
      )
    );
    errors.libraryRateDetailEntity = filter(errors.libraryRateDetailEntity, (e) => !isEmpty(e))
      ?.length
      ? errors.libraryRateDetailEntity
      : null;
  }
  return omitBy(errors, isNil);
};
