import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { getDurationInMinutes } from '../../../utils/timeHelper';

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState: {
    sidePanelData: {
      locationDropdownList: [],
      areaPersonList: [],
      selectedLocation: {},
      selectedAreaPerson: null,
      weekDatesValue: {
        startDate: moment(new Date()).startOf('isoWeek'),
        endDate: moment(new Date()).endOf('isoWeek'),
      },
      timeZoneId: '',
      duration: { id: 1, label: 'Week', dayCount: 7 },
    },
    scheduleData: {},
    coverageData: [],
    weatherData: [],
    statsPanelData: {},
    addEditShift: {
      // personDetailId: 801,
      // areaId: 445,
      // startTime: '2023-01-23T10:30:30.756Z',
      // endTime: '2023-01-23T18:30:30.756Z',
      totalMealBreak: 0,
      totalRestBreak: 0,
      shiftBreakDetailsList: [
        {
          isCustom: false,
          breakType: null,
          startTime: null,
          endTime: null,
          duration: null,
        },
      ],
    },
    bulkActionData: {},
  },
  reducers: {
    updateScheduleSidePanelData: (state, action) => {
      const { fieldName, value } = action.payload;
      state.sidePanelData[fieldName] = value;
    },
    setScheduleData: (state, action) => {
      const { shifts, timeOffEntity, ...rest } = action.payload;
      state.scheduleData = shifts;
      state.calendarFooterData = rest;
      state.timeOffEntity = timeOffEntity;
    },
    resetSchedulerData: (state) => {
      state.sidePanelData = {
        locationDropdownList: [],
        areaPersonList: [],
        selectedLocation: {},
        selectedAreaPerson: null,
        weekDatesValue: {
          startDate: moment(new Date()).startOf('isoWeek'),
          endDate: moment(new Date()).endOf('isoWeek'),
        },
        timeZoneId: '',
        duration: { id: 1, label: 'Week', dayCount: 7 },
      };
      state.calendarFooterData = {};
      state.scheduleData = {};
    },
    updateAddEditShiftFields: (state, action) => {
      const { fieldName, value, index } = action.payload ?? {};
      if (index || index === 0) {
        state.addEditShift.shiftBreakDetailsList[index][fieldName] = value;
        if (fieldName === 'startTime') {
          state.addEditShift.shiftBreakDetailsList[index].duration = getDurationInMinutes(
            value,
            state?.addEditShift?.shiftBreakDetailsList?.[index]?.endTime
          );
        }
        if (fieldName === 'endTime') {
          state.addEditShift.shiftBreakDetailsList[index].duration = getDurationInMinutes(
            state?.addEditShift?.shiftBreakDetailsList?.[index]?.startTime,
            value
          );
        }
        if (fieldName === 'isCustom' && value === true) {
          state.addEditShift.shiftBreakDetailsList[index].duration = getDurationInMinutes(
            state?.addEditShift?.shiftBreakDetailsList?.[index]?.startTime,
            state?.addEditShift?.shiftBreakDetailsList?.[index]?.endTime
          );
        }
        if (fieldName === 'isCustom' && value === false) {
          state.addEditShift.shiftBreakDetailsList[index].duration = null;
        }
      } else state.addEditShift[fieldName] = value;

      if (fieldName === 'shiftType' && [1, 2]?.includes(value?.id)) {
        state.addEditShift.personDetailId = null;
      }
    },
    updateAddEditShiftBulkFields: (state, action) => {
      state.addEditShift = {
        ...state.addEditShift,
        ...action.payload,
      };
    },
    addDeleteShiftBreakDetails: (state, action) => {
      const { type, index } = action.payload;
      if (type === 'ADD') {
        state.addEditShift.shiftBreakDetailsList.push({
          isCustom: false,
          breakType: null,
          startTime: null,
          endTime: null,
          duration: null,
        });
      }
      if (type === 'DELETE') {
        state.addEditShift.shiftBreakDetailsList = state.addEditShift.shiftBreakDetailsList.filter(
          (e, i) => i !== index
        );
        if (state.addEditShift.errors?.shiftBreakDetailsList) {
          state.addEditShift.errors.shiftBreakDetailsList =
            state.addEditShift.errors.shiftBreakDetailsList.filter((e, i) => i !== index);
        }
      }
    },
    resetAddEditShiftFields: (state) => {
      state.addEditShift = {
        shiftBreakDetailsList: [
          {
            isCustom: false,
            breakType: null,
            startTime: null,
            endTime: null,
            duration: null,
          },
        ],
      };
    },
    updateBulkActionData: (state, action) => {
      const { fieldName, value } = action.payload ?? {};
      state.bulkActionData[fieldName] = value;
    },
    updateCalendarFooterData: (state, action) => {
      const { fieldName, value } = action.payload ?? {};
      state.calendarFooterData[fieldName] = value;
    },
    setBulkActionData: (state, action) => {
      state.bulkActionData = {
        ...state.bulkActionData,
        ...action.payload,
      };
    },
    resetBulkActionData: (state) => {
      state.bulkActionData = {};
    },
    updateShiftsViaFCM: (state, action) => {
      const { shiftEntity, areaId, dataToUpdate, personDetailId } = action.payload ?? {};
      const existingAreaIndex = state.scheduleData.findIndex((e) => e?.areaId === areaId);
      if (existingAreaIndex > -1) {
        if (shiftEntity === 'personDetailEntity') {
          const personIndex = state.scheduleData[existingAreaIndex][shiftEntity]?.findIndex(
            (e) => e?.personDetailId === personDetailId
          );
          if (personIndex > -1) {
            state.scheduleData[existingAreaIndex][shiftEntity][personIndex]?.shiftEntity.unshift(
              dataToUpdate
            );
          }
        } else {
          state.scheduleData[existingAreaIndex][shiftEntity].unshift(dataToUpdate);
        }
      }
    },
    setScheduleCoverageData: (state, action) => {
      state.coverageData = action.payload;
    },
    setScheduleWeatherData: (state, action) => {
      state.weatherData = action.payload;
    },
    setStatsPanelList: (state, action) => {
      const { listName, value } = action.payload;
      state.statsPanelData[listName] = value;
    },
  },
});

const { reducer, actions } = scheduleSlice;
export const {
  updateScheduleSidePanelData,
  setScheduleData,
  updateAddEditShiftFields,
  updateAddEditShiftBulkFields,
  resetAddEditShiftFields,
  addDeleteShiftBreakDetails,
  resetBulkActionData,
  updateBulkActionData,
  setBulkActionData,
  resetSchedulerData,
  updateCalendarFooterData,
  updateShiftsViaFCM,
  setScheduleCoverageData,
  setScheduleWeatherData,
  setStatsPanelList,
} = actions;
export default reducer;
