import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import EzyDrawer from '../../../../components/common/drawer/Drawer';
import {
  editLeaveDetails,
  resetLeaveRequestDetails,
  updateAddLeaveDetails,
} from '../../redux/peopleSlice';
import EzySwitch from '../../../../components/common/switch/EzySwitch';
import EzyDate from '../../../../components/common/dateAndTimePicker/EzyDate';
import EzyTime from '../../../../components/common/dateAndTimePicker/EzyTime';
import EzyAutoComplete from '../../../../components/common/autoComplete/EzyAutoComplete';
import EzyInputField from '../../../../components/common/inputField/EzyInputField';
import { concatDateAndTime, displayDate, displayTime } from '../../../../utils/timeHelper';
import validateLeaveRequest from '../../validations/leaveRequestValidations';
import {
  addLeaveRequestAction,
  editLeaveRequestAction,
  getDetailsOnChangeLeaveDate,
  getLeaveDetailsByLeaveRequestIdAction,
  getLeaveEntitlementEnumByPersonIdAction,
} from '../../redux/peopleActions';
import EzyAccordion from '../../../../components/common/accordion/EzyAccordion';
import EzyAccordionSummary from '../../../../components/common/accordion/EzyAccordionSummary';
import EzyAccordionDetails from '../../../../components/common/accordion/EzyAccordinDetails';
import EzyAccordionWrapper from '../../../../components/common/accordion/EzyAccordionWrapper';

function LeaveRequestDrawer({ isFromProfile, listCallback }) {
  const dispatch = useDispatch();
  const { peopleId } = useParams();
  const { enums } = useSelector(({ common }) => common ?? {});

  const { roleId } = useSelector(({ user }) => user?.profileData ?? {});
  const {
    leaveRequestDrawer,
    addLeaveDetails,
    leaveEnums,
    extraLeaveDetails,
    leaveRequestId,
    isEditLeaveRequest,
    timeZoneId,
  } = useSelector(({ people }) => people?.leaveDetails ?? {});

  const { personId: loggedInPersonId, timeZone: loggedInPersonTimeZone } = useSelector(
    ({ user }) => user?.profileData ?? {}
  );

  const timeZone = isFromProfile ? loggedInPersonTimeZone : timeZoneId;

  const {
    getLeaveEntitlementEnumByPersonIdActionLoader,
    getLeaveDetailsByLeaveRequestIdActionLoader,
    addLeaveRequestActionLoader,
    editLeaveRequestActionLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});

  const onChangeFields = (fieldName, value) => {
    dispatch(updateAddLeaveDetails({ fieldName, value }));
  };

  const handleClose = () => {
    dispatch(editLeaveDetails({ fieldName: 'leaveRequestDrawer', value: false }));
    dispatch(resetLeaveRequestDetails());
  };

  const handleSubmit = () => {
    const data = {
      isFromProfile,
      id: leaveRequestId,
      isAllDay: addLeaveDetails?.isAllDay,
      personDetailId: isFromProfile ? loggedInPersonId : peopleId,
      businessLeaveRuleId: addLeaveDetails?.leaveType?.id,
      shiftType: addLeaveDetails?.shiftType?.id,
      reason: addLeaveDetails?.reason,
      fromDate: addLeaveDetails?.fromDate,
      toDate: addLeaveDetails?.toDate,
      shiftIds: extraLeaveDetails?.shifts.map((shift) => shift?.shiftId),
      leaveStatus: addLeaveDetails?.leaveStatus,
      managerComment: addLeaveDetails?.managerComment,
    };

    if (!addLeaveDetails?.isAllDay) {
      data.fromDate = addLeaveDetails?.fromDate
        ? concatDateAndTime(addLeaveDetails?.fromDate, addLeaveDetails?.fromTime, timeZone?.label)
        : undefined;
      data.toDate = addLeaveDetails?.toDate
        ? concatDateAndTime(addLeaveDetails?.toDate, addLeaveDetails?.toTime, timeZone?.label)
        : undefined;
    }

    if (addLeaveDetails?.isAllDay) {
      data.toDate = addLeaveDetails?.toDate
        ? moment.tz(addLeaveDetails?.toDate, timeZone?.label).endOf('day').toISOString()
        : undefined;
    }

    const errors = validateLeaveRequest({
      ...data,
      fromTime: addLeaveDetails?.fromTime,
      toTime: addLeaveDetails?.toTime,
      shiftLength: extraLeaveDetails?.shifts?.length,
    });

    onChangeFields('errors', errors);

    if (isEmpty(errors))
      if (isEditLeaveRequest) {
        dispatch(
          editLeaveRequestAction(data, () => {
            listCallback();
            handleClose();
          })
        );
      } else {
        dispatch(
          addLeaveRequestAction(data, () => {
            listCallback();
            handleClose();
          })
        );
      }
  };

  const hasApproveAccess = useMemo(() => {
    return roleId <= addLeaveDetails?.whoCanApproveLeave;
  }, [roleId, addLeaveDetails?.whoCanApproveLeave]);

  const actionButton = useMemo(
    () => [
      {
        label: hasApproveAccess && !isFromProfile ? 'Request & Approve' : 'Save',
        onClick: handleSubmit,
        loading: editLeaveRequestActionLoader || addLeaveRequestActionLoader,
      },
    ],
    [editLeaveRequestActionLoader, addLeaveRequestActionLoader, hasApproveAccess, handleSubmit]
  );

  const getExtraLeaveDetails = (params) => {
    const data = {
      personId: isFromProfile ? loggedInPersonId : peopleId,
      endDate: moment
        .tz(addLeaveDetails?.toDate, timeZone?.label)
        .add(1439, 'minutes')
        .toISOString(),
      startDate: moment.tz(addLeaveDetails?.fromDate, timeZone?.label).toISOString(),
      ...params,
    };

    dispatch(getDetailsOnChangeLeaveDate(data));
  };
  useEffect(() => {
    (async () => {
      if (leaveRequestDrawer) {
        if (leaveRequestId && isEditLeaveRequest) {
          await dispatch(getLeaveDetailsByLeaveRequestIdAction({ leaveRequestId }));
        }
        await dispatch(
          getLeaveEntitlementEnumByPersonIdAction({
            personId: isFromProfile ? loggedInPersonId : peopleId,
            fromProfile: !!isFromProfile,
          })
        );
      }
    })();
  }, [leaveRequestId, isEditLeaveRequest, leaveRequestDrawer]);

  return (
    <EzyDrawer
      onClose={handleClose}
      isOpen={leaveRequestDrawer}
      actionButtons={actionButton}
      heading={isEditLeaveRequest ? 'Edit Leave Request' : 'Add Leave Request'}
      isLoading={getLeaveDetailsByLeaveRequestIdActionLoader}
    >
      <div className="leave-request-drawer">
        <div className="leave-request-field-grid">
          {extraLeaveDetails?.personOnLeave?.length > 0 && !isFromProfile && (
            <EzyAccordionWrapper>
              <EzyAccordion>
                <EzyAccordionSummary className="accordion-summary">
                  Already On Leave
                </EzyAccordionSummary>
                <EzyAccordionDetails>
                  {extraLeaveDetails?.personOnLeave?.map((data) => {
                    return (
                      <div className="leave-details-accordion-field">
                        <span>{data?.name}</span>
                        <span className="accordion-date">
                          {displayDate(data?.fromDate)} - {displayDate(data?.toDate)}
                        </span>
                      </div>
                    );
                  })}
                </EzyAccordionDetails>
              </EzyAccordion>
            </EzyAccordionWrapper>
          )}

          <EzySwitch
            label="All Day"
            checked={addLeaveDetails?.isAllDay}
            handleChange={(_, checked) => {
              onChangeFields('isAllDay', checked);
              if (
                checked &&
                addLeaveDetails?.toDate &&
                addLeaveDetails?.fromDate &&
                !isFromProfile
              ) {
                getExtraLeaveDetails();
              }
            }}
          />

          <div className={!addLeaveDetails?.isAllDay && 'two-grid'}>
            <EzyDate
              name="fromDate"
              placeholder="Select date"
              label="From"
              value={addLeaveDetails?.fromDate}
              timeZone={timeZone?.label}
              onChange={(value) => {
                onChangeFields('fromDate', value);
                if (addLeaveDetails?.toDate && !isFromProfile) {
                  getExtraLeaveDetails({
                    endDate: addLeaveDetails?.isAllDay
                      ? moment
                          .tz(addLeaveDetails?.toDate, timeZone?.label)
                          .add(1439, 'minutes')
                          .toISOString()
                      : concatDateAndTime(
                          addLeaveDetails?.toDate,
                          addLeaveDetails?.toTime,
                          timeZone?.label
                        ),
                    startDate: concatDateAndTime(value, addLeaveDetails?.fromTime, timeZone?.label),
                  });
                }
              }}
              error={addLeaveDetails?.errors?.fromDate}
              required
            />
            {!addLeaveDetails?.isAllDay && (
              <EzyTime
                name="fromTime"
                placeholder="Select time"
                label="Time"
                value={addLeaveDetails?.fromTime}
                timeZone={timeZone?.label}
                onChange={(value) => {
                  onChangeFields('fromTime', value);
                  if (
                    addLeaveDetails?.toDate &&
                    addLeaveDetails?.fromDate &&
                    addLeaveDetails?.toTime &&
                    !isFromProfile
                  ) {
                    getExtraLeaveDetails({
                      endDate: concatDateAndTime(
                        addLeaveDetails?.toDate,
                        addLeaveDetails?.toTime,
                        timeZone?.label
                      ),
                      startDate: concatDateAndTime(
                        addLeaveDetails?.fromDate,
                        value,
                        timeZone?.label
                      ),
                    });
                  }
                }}
                error={addLeaveDetails?.errors?.fromTime}
                required
              />
            )}
          </div>

          <div className={!addLeaveDetails?.isAllDay && 'two-grid'}>
            <EzyDate
              name="toDate"
              placeholder="Select date"
              label="To"
              value={addLeaveDetails?.toDate}
              timeZone={timeZone?.label}
              onChange={(value) => {
                onChangeFields('toDate', value);

                if (addLeaveDetails?.fromDate && !isFromProfile) {
                  getExtraLeaveDetails({
                    startDate: concatDateAndTime(
                      addLeaveDetails?.fromDate,
                      addLeaveDetails?.fromTime,
                      timeZone?.label
                    ),
                    endDate: addLeaveDetails?.isAllDay
                      ? moment.tz(value, timeZone?.label).add(1439, 'minutes').toISOString()
                      : concatDateAndTime(value, addLeaveDetails?.toTime, timeZone?.label),
                  });
                }
              }}
              error={addLeaveDetails?.errors?.toDate}
              minDate={moment.tz(addLeaveDetails?.fromDate, timeZone?.label)}
              disabled={!addLeaveDetails?.fromDate}
              required
            />
            {!addLeaveDetails?.isAllDay && (
              <EzyTime
                name="toTime"
                placeholder="Select time"
                label="Time"
                timeZone={timeZone?.label}
                value={addLeaveDetails?.toTime}
                onChange={(value) => {
                  onChangeFields('toTime', value);

                  if (
                    addLeaveDetails?.fromDate &&
                    addLeaveDetails?.toDate &&
                    addLeaveDetails?.fromTime &&
                    !isFromProfile
                  ) {
                    getExtraLeaveDetails({
                      startDate: concatDateAndTime(
                        addLeaveDetails?.fromDate,
                        addLeaveDetails?.fromTime,
                        timeZone?.label
                      ),
                      endDate: concatDateAndTime(addLeaveDetails?.toDate, value, timeZone?.label),
                    });
                  }
                }}
                // minTime={
                //   new Date(
                //     concatDateAndTime(addLeaveDetails?.fromDate, addLeaveDetails?.fromTime)
                //   )
                // }
                error={addLeaveDetails?.errors?.toTime}
                required
              />
            )}
          </div>

          {extraLeaveDetails?.shifts?.length > 0 && !isFromProfile && (
            <div className="leave-drawer-note">
              <span>
                {extraLeaveDetails?.name} is scheduled for shifts during this period. Choose what
                you would like to do with this shift. If no selection is made, Team member will be
                kept on shift.
              </span>
            </div>
          )}

          {extraLeaveDetails?.shifts?.length > 0 && !isFromProfile && (
            <EzyAccordionWrapper>
              <EzyAccordion>
                <EzyAccordionSummary className="accordion-summary">Shifts</EzyAccordionSummary>
                <EzyAccordionDetails>
                  {extraLeaveDetails?.shifts?.map((data) => {
                    return (
                      <div className="leave-details-accordion-field" key={data?.shiftId}>
                        <span>{displayDate(data?.startTime, data?.timeZoneId?.label)}</span>
                        <span className="accordion-date">
                          {displayTime(data?.startTime, data?.timeZoneId?.label)} -
                          {displayTime(data?.endTime, data?.timeZoneId?.label)} | {data?.areaName} |{' '}
                          {data?.locationName}
                        </span>
                      </div>
                    );
                  })}
                </EzyAccordionDetails>
              </EzyAccordion>
            </EzyAccordionWrapper>
          )}

          {extraLeaveDetails?.shifts?.length > 0 && !isFromProfile && (
            <EzyAutoComplete
              name="shiftType"
              placeholder="Select shift"
              label="What you would like to do with shift?"
              options={enums?.shiftAction}
              value={addLeaveDetails?.shiftType}
              onChange={(_, value) => onChangeFields('shiftType', value)}
              error={addLeaveDetails?.errors?.shiftType}
              required
            />
          )}

          <EzyAutoComplete
            name="leaveType"
            placeholder="Select leave type"
            label="Leave type"
            options={leaveEnums?.leaveEntitlementEnumByPersonId}
            value={addLeaveDetails?.leaveType}
            onChange={(_, value) => onChangeFields('leaveType', value)}
            error={addLeaveDetails?.errors?.businessLeaveRuleId}
            loading={getLeaveEntitlementEnumByPersonIdActionLoader}
            required
          />

          {isFromProfile && (
            <EzyInputField
              label="Reason"
              placeholder="Enter leave notes.."
              multiline
              rows={3}
              max-rows={5}
              value={addLeaveDetails?.reason}
              onChange={(event) => onChangeFields('reason', event.target.value)}
              error={addLeaveDetails?.errors?.reason}
              required
            />
          )}

          {!isFromProfile && (
            <EzyInputField
              label="Manger Comment"
              placeholder="Enter comment"
              multiline
              rows={3}
              max-rows={5}
              value={addLeaveDetails?.managerComment}
              onChange={(event) => onChangeFields('managerComment', event.target.value)}
              error={addLeaveDetails?.errors?.managerComment}
              required
            />
          )}
        </div>
      </div>
    </EzyDrawer>
  );
}

export default LeaveRequestDrawer;

LeaveRequestDrawer.propTypes = {
  isFromProfile: PropTypes.bool,
  listCallback: PropTypes.func,
};

LeaveRequestDrawer.defaultProps = {
  isFromProfile: false,
  listCallback: () => {},
};
