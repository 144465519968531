import React, { useEffect, useMemo, useState } from 'react';
// import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { useMediaQuery } from '@mui/material';
import EzyIconButton from '../../../components/common/iconButton/EzyIconButton';
// import EzyDate from '../../../components/common/dateAndTimePicker/EzyDate';
import EzyAutoComplete from '../../../components/common/autoComplete/EzyAutoComplete';
import EzyTime from '../../../components/common/dateAndTimePicker/EzyTime';
import EzySwitch from '../../../components/common/switch/EzySwitch';
import TimeSheetDetailsAction from './TimeSheetDetailsAction';
import EzyButton from '../../../components/common/button/EzyButton';
import EzyTooltip from '../../../components/common/tooltip/EzyTooltip';
import {
  getTimesheetAllowancesAction,
  importScheduledShift,
  roundTimesheetTimeAction,
} from '../redux/timeSheetsAction';
import IconByName from '../../../components/common/iconByName/IconByName';
import {
  concatDateAndTime,
  displayDate,
  displayDateTime,
  displayTime,
} from '../../../utils/timeHelper';
import { DATE_TIME_FORMAT, breakDurationList } from '../../../utils/constants';
import {
  addDeleteBaseRate,
  addDeleteShiftLoadings,
  addDeleteTimeSheetBreakDetails,
  resetTimesheetAllowancesData,
  updateAllowancesDetail,
  updateBaseRate,
  updateShiftLoadings,
  updateTimeSheetData,
} from '../redux/timeSheetsSlice';
import EzyDateTime from '../../../components/common/dateAndTimePicker/EzyDateTime';
import Loader from '../../../components/common/loader/Loader';
import EzyInputField from '../../../components/common/inputField/EzyInputField';
import { REGEX } from '../../../utils/validationHelper';
// import validateAddTimeSheet from '../validations/validateAddTimeSheet';

function TimesheetDetails() {
  const { timeSheetData, sidePanelData } = useSelector(({ timeSheets }) => timeSheets ?? {});
  const [isFromEdit, setIsFromEdit] = useState(false);
  const { breakType } = useSelector(({ common }) => common?.enums ?? {});
  const isTabletScreen = useMediaQuery('(max-width: 1024px)');
  const [isEditAllowances, setIsEditAllowances] = useState(false);

  const dispatch = useDispatch();

  const { getTimeSheetViewDetailsActionLoader, getTimesheetAllowancesActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );

  const {
    areaList,
    areaId,
    startTime,
    endTime,
    totalMealBreak = 0,
    totalRestBreak = 0,
    timeSheetBreakDetailEntities,
    isAutomaticallyClosed,
    errors,
    breakTypeRoundingType,
    startTimeRoundingType,
    endTimeRoundingType,
    timeZoneId,
    viewTimeSheetData,
    timesheetAllowances,
  } = useMemo(() => timeSheetData, [timeSheetData]);

  const handleRoundTime = () => {
    const data = {
      timesheetId: sidePanelData?.selectedTimesheet?.id ?? undefined,
      startTime,
      endTime,
      mealBreakDuration: Number(totalMealBreak),
      restBreakDuration: Number(totalRestBreak),
      areaId: areaId?.id,
    };

    dispatch(roundTimesheetTimeAction(data));
  };

  const label =
    breakTypeRoundingType?.label === startTimeRoundingType?.label &&
    breakTypeRoundingType?.label === endTimeRoundingType?.label ? (
      `Round time to ${breakTypeRoundingType?.label} minute block.`
    ) : (
      <div className="pay-rate-tooltip-data-wrapper">
        <div className="pay-rate-tooltip-data">
          <div className="data">
            Round start time to {startTimeRoundingType?.label} minute block.
          </div>
          <div className="data">Round end time to {endTimeRoundingType?.label} minute block.</div>
          <div className="data">Round break to {breakTypeRoundingType?.label} minute block.</div>
        </div>
      </div>
    );

  const onChangeFields = (fieldName, value, index) => {
    if (fieldName === 'breakStartTime' || fieldName === 'breakEndTime') {
      const timeZone = areaId?.timeZoneId?.label ?? moment.tz.guess();

      const scheduleStartTime = moment.tz(startTime, timeZone).format('HH');

      const scheduleEndTime = moment.tz(endTime, timeZone).format('HH');

      if (scheduleStartTime < scheduleEndTime) {
        // For single day timesheet
        const breakTime = concatDateAndTime(startTime, value, timeZone);

        dispatch(updateTimeSheetData({ fieldName, value: breakTime, index }));
      } else {
        const breakTime = moment.tz(value, timeZone).format('HH');

        if (breakTime <= scheduleEndTime) {
          dispatch(
            updateTimeSheetData({
              fieldName,
              value: concatDateAndTime(endTime, value, timeZone),
              index,
            })
          );
        } else if (breakTime === scheduleEndTime && fieldName === 'breakStartTime') {
          dispatch(
            updateTimeSheetData({
              fieldName,
              value: concatDateAndTime(endTime, value, timeZone),
              index,
            })
          );
          dispatch(
            updateTimeSheetData({
              fieldName: 'breakEndTime',
              value: concatDateAndTime(
                endTime,
                timeSheetBreakDetailEntities?.[index]?.breakEndTime,
                timeZone
              ),
              index,
            })
          );
        } else {
          dispatch(
            updateTimeSheetData({
              fieldName,
              value: concatDateAndTime(startTime, value, timeZone),
              index,
            })
          );
        }
      }
    } else dispatch(updateTimeSheetData({ fieldName, value, index }));
  };

  const output = timeSheetBreakDetailEntities?.reduce((obj, el) => {
    const property = el?.breakType?.id;
    obj[property] = (obj[property] || 0) + Number(el.duration);
    return obj;
  }, {});

  const getBreakDataString = (data, breakType1) => {
    const output1 = data?.[`${breakType1}`]?.map((e) => {
      if ((e?.duration || e?.duration >= 0) && e?.breakStartTime && e?.breakEndTime) {
        return `${displayTime(e?.breakStartTime, timeZoneId?.label)} - ${displayTime(
          e?.breakEndTime,
          timeZoneId?.label
        )} (${e?.duration} mins)`;
      }
      return `(${e?.duration} mins)`;
    });
    if (output1?.length > 0) {
      return output1?.join(', ');
    }
    return '(0 mins)';
  };

  const breakSummaryString = () => {
    if (isTabletScreen) {
      return (
        <div className="d-flex-column">
          <div>Meal break : {timeSheetData?.attachedShift?.totalMealBreak || '0'} mins</div>
          <div>Rest break : {timeSheetData?.attachedShift?.totalRestBreak || '0'} mins</div>
        </div>
      );
    }
    return `Meal break : ${timeSheetData?.attachedShift?.totalMealBreak || '0'} mins 
    Rest break : ${timeSheetData?.attachedShift?.totalRestBreak || '0'} mins`;
  };

  const attchedshiftDetails = useMemo(() => {
    const attchedShiftOutput = timeSheetData?.attachedShift?.shiftBreakDetails?.reduce(
      (obj, el) => {
        const property = el?.breakType;
        if (!obj?.[property]) {
          obj[property] = [];
        }
        obj[property].push(el);
        return obj;
      },
      {}
    );

    return [
      {
        title: 'Date',
        value: displayDate(timeSheetData?.attachedShift?.date, timeZoneId?.label),
      },
      {
        title: 'Area',
        value: timeSheetData?.attachedShift?.areaName || '-',
      },
      {
        title: 'Start time',
        value: displayTime(timeSheetData?.attachedShift?.startTime, timeZoneId?.label),
      },
      {
        title: 'End time',
        value: displayTime(timeSheetData?.attachedShift?.endTime, timeZoneId?.label),
      },
      {
        title: 'Break summary',
        value: breakSummaryString(),
      },
      {
        title: 'Meal break',
        value: getBreakDataString(attchedShiftOutput, 1),
      },
    ];
  }, [timeSheetData?.attachedShift, isTabletScreen, timeZoneId]);

  const viewData = useMemo(() => {
    const viewOutput = viewTimeSheetData?.timeSheetBreakDetailEntities?.reduce((obj, el) => {
      const property = el?.breakType;
      if (!obj?.[property]) {
        obj[property] = [];
      }
      obj[property].push(el);
      return obj;
    }, {});

    return {
      timeSheetPhotoURL: viewTimeSheetData?.timeSheetPhotoURL || null,
      value: [
        {
          title: 'Timesheet date',
          value: displayDate(viewTimeSheetData?.date, viewTimeSheetData?.timeZoneId?.label),
        },
        {
          title: 'Area',
          value: viewTimeSheetData?.areaName || '-',
        },
        {
          title: 'Timesheet time',
          value:
            `${displayTime(
              viewTimeSheetData?.startTime,
              viewTimeSheetData?.timeZoneId?.label
            )} - ${displayTime(
              viewTimeSheetData?.endTime,
              viewTimeSheetData?.timeZoneId?.label
            )}` || '-',
        },
        {
          title: 'Break(s) statistics ',
          value: `Meal break : ${viewTimeSheetData?.totalMealBreak || '0'} mins, Rest break : ${
            viewTimeSheetData?.totalRestBreak || '0'
          } mins`,
        },
        {
          title: 'Meal break',
          value: getBreakDataString(viewOutput, 1),
        },
        {
          title: 'Rest break',
          value: getBreakDataString(viewOutput, 2),
        },
        {
          title: 'Pay rates',
          value: viewTimeSheetData?.payRates || '-',
        },
      ],
    };
  }, [viewTimeSheetData]);

  useEffect(() => {
    if (isFromEdit) {
      if (output?.['1'] !== totalMealBreak) {
        onChangeFields('totalMealBreak', output?.['1']);
      }
      if (output?.['2'] !== totalRestBreak) {
        onChangeFields('totalRestBreak', output?.['2']);
      }
    }

    return () => setIsFromEdit(false);
  }, [timeSheetBreakDetailEntities, isFromEdit]);

  useEffect(() => {
    if (viewTimeSheetData?.progressStatus === 5 && sidePanelData?.selectedTimesheet?.id) {
      dispatch(getTimesheetAllowancesAction({ timesheetId: sidePanelData?.selectedTimesheet?.id }));
    } else {
      dispatch(resetTimesheetAllowancesData());
    }
  }, [viewTimeSheetData?.progressStatus]);

  // FAIR WORK

  const updateBaseRateandShiftLoadingsField = (name, value, index, target) => {
    setIsEditAllowances(true);
    if (target === 'baseRate') {
      dispatch(updateBaseRate({ fieldName: name, value, index }));
    } else {
      dispatch(updateShiftLoadings({ fieldName: name, value, index }));
    }
  };

  return getTimeSheetViewDetailsActionLoader || getTimesheetAllowancesActionLoader ? (
    <Loader />
  ) : (
    <div className="edit-timesheet-wrapper">
      {viewTimeSheetData?.status === 4 && (
        <div className="info-div info">
          <IconByName name="error" />
          <div>Timesheet has been discarded, you may reinstate it</div>
        </div>
      )}

      {viewTimeSheetData?.progressStatus &&
      [1, 2, 3, 5].includes(viewTimeSheetData?.progressStatus) ? (
        <div className="view-timesheet-details">
          <div className="view-timesheet-data-wrapper">
            {viewData?.value?.map(({ title, value }, index) => (
              <div className="data-wrapper" key={index}>
                <div className="title">{title} :&nbsp;</div>
                <div className="value">{value || ' - '}</div>
              </div>
            ))}
          </div>

          {viewTimeSheetData?.progressStatus === 5 && (
            <>
              {isEditAllowances && (
                <div className="info-div info body-text just-bet">
                  <div className="flex-center gap-10">
                    <IconByName name="error" />
                    <div>Shift pay conditions have been manually overridden.</div>
                  </div>

                  <EzyButton
                    label="Revert"
                    color="secondary"
                    className="no-pad-button"
                    onClick={() => {
                      dispatch(updateAllowancesDetail(timesheetAllowances?.originalValue));
                      dispatch(updateTimeSheetData({ fieldName: 'errors', value: {} }));
                      setIsEditAllowances(false);
                    }}
                  />
                </div>
              )}

              <div className="p-bottom-10">
                <div className="title-with-primary-bg">
                  Pay rates
                  <EzyIconButton
                    iconName="add"
                    className="small"
                    onClick={() => dispatch(addDeleteBaseRate({ type: 'ADD' }))}
                  />
                </div>

                {timesheetAllowances?.baseRate?.map((baseRate, index) => {
                  return (
                    <div className="details-fields">
                      <EzyAutoComplete
                        label="Base pay rates"
                        placeholder="Select base pay rate"
                        options={timesheetAllowances?.baseRateAllowancesDropDown}
                        disabled={timesheetAllowances?.baseRateAllowancesDropDown?.length === 0}
                        onChange={(e, value) => {
                          updateBaseRateandShiftLoadingsField(
                            'payrateString',
                            value,
                            index,
                            'baseRate'
                          );
                        }}
                        value={baseRate?.payrateString}
                        error={errors?.timeSheetAllowances?.[index]?.baseRatePayString}
                        required
                      />
                      <div className="hour-field">
                        <EzyInputField
                          label="Hours"
                          placeholder="Enter hours"
                          value={
                            timesheetAllowances?.baseRateAllowancesDropDown?.length === 0
                              ? 0
                              : baseRate?.workingHour
                          }
                          onChange={(e) => {
                            if (REGEX?.DECIMAL_REGEX.test(e.target.value))
                              updateBaseRateandShiftLoadingsField(
                                'workingHour',
                                e?.target?.value,
                                index,
                                'baseRate'
                              );
                          }}
                          disabled={timesheetAllowances?.baseRateAllowancesDropDown?.length === 0}
                          error={errors?.timeSheetAllowances?.[index]?.baseRateWorkingHour}
                          required
                        />
                        <div>
                          <EzyIconButton
                            iconName="delete"
                            color="error"
                            onClick={() => {
                              setIsEditAllowances(true);
                              dispatch(addDeleteBaseRate({ type: 'DELETE', index }));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}

                {timesheetAllowances?.baseRateTotalWorkingHour !==
                  timesheetAllowances?.baseRate?.reduce(
                    (prev, curr) => prev + +curr.workingHour,
                    0
                  ) && (
                  <div className="details-fields">
                    <div> </div>
                    <div className="hour-field">
                      <EzyInputField
                        disabled
                        value={(
                          timesheetAllowances.baseRate.reduce(
                            (prev, curr) => prev + +curr.workingHour,
                            0
                          ) - timesheetAllowances.baseRateTotalWorkingHour
                        ).toFixed(2)}
                      />
                      <div> </div>
                    </div>
                  </div>
                )}
              </div>

              <div>
                <div className="title-with-primary-bg">
                  Shift
                  <EzyIconButton
                    iconName="add"
                    className="small"
                    onClick={() => dispatch(addDeleteShiftLoadings({ type: 'ADD' }))}
                  />
                </div>

                {timesheetAllowances?.shiftLoadings?.map((shiftLoadings, index) => {
                  return (
                    <div className="details-fields">
                      <EzyAutoComplete
                        label="Shift loadings"
                        placeholder="Select shift loadings"
                        options={timesheetAllowances?.shiftLoadingAllowancesDropDown}
                        onChange={(e, value) =>
                          updateBaseRateandShiftLoadingsField(
                            'payrateString',
                            value,
                            index,
                            'shiftLoadings'
                          )
                        }
                        value={shiftLoadings?.payrateString}
                        disabled={timesheetAllowances?.shiftLoadingAllowancesDropDown?.length === 0}
                        error={
                          errors?.timeSheetAllowances?.[index + timesheetAllowances.baseRate.length]
                            ?.shiftLoadingPayString
                        }
                        required
                      />
                      <div className="hour-field">
                        <EzyInputField
                          label="Hours"
                          placeholder="Enter hours"
                          onChange={(e) => {
                            if (REGEX?.DECIMAL_REGEX.test(e.target.value))
                              updateBaseRateandShiftLoadingsField(
                                'workingHour',
                                e?.target?.value,
                                index,
                                'shiftLoadings'
                              );
                          }}
                          value={
                            timesheetAllowances?.shiftLoadingAllowancesDropDown?.length === 0
                              ? 0
                              : shiftLoadings?.workingHour
                          }
                          disabled={
                            timesheetAllowances?.shiftLoadingAllowancesDropDown?.length === 0
                          }
                          error={
                            errors?.timeSheetAllowances?.[
                              index + timesheetAllowances.baseRate.length
                            ]?.shiftLoadingWorkingHour
                          }
                          required
                        />
                        <div>
                          <EzyIconButton
                            iconName="delete"
                            color="error"
                            onClick={() => {
                              setIsEditAllowances(true);
                              dispatch(addDeleteShiftLoadings({ type: 'DELETE', index }));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      ) : (
        viewTimeSheetData?.status !== 4 && (
          <div className="details">
            <div className="title-with-primary-bg">Schedule Overview</div>
            {!isEmpty(timeSheetData?.attachedShift) && (
              <div className="primary-bg">
                <div>
                  {attchedshiftDetails?.map((e) => {
                    return (
                      <div>
                        <span>{e?.title} : </span>
                        <span className="text-blue-color body-text">{e?.value}</span>
                      </div>
                    );
                  })}
                </div>
                <EzyIconButton
                  iconName="download_for_offline"
                  onClick={() => {
                    dispatch(
                      importScheduledShift({
                        id: sidePanelData?.selectedTimesheet?.id,
                        ...timeSheetData?.attachedShift,
                        breakSummary: timeSheetData?.attachedShift?.shiftBreakDetails,
                        shiftBreakDetails: null,
                        timeZoneId,
                      })
                    );
                  }}
                />
              </div>
            )}

            {sidePanelData?.selectedTimesheet?.id && isAutomaticallyClosed && (
              <div className="info-div info body-text">
                <IconByName name="error" />
                <div>Automatically closed because employee did not finish their shift.</div>
              </div>
            )}

            <div className="title-with-primary-bg">
              Timesheet Details (actual time Clock-In, Clock-Out, and Break(s) )
            </div>

            <div className="details-fields">
              <EzyAutoComplete
                label="Work area"
                placeholder="Select work area"
                options={areaList}
                value={areaId}
                name="areaId"
                onChange={(_, value) => {
                  onChangeFields('areaId', value);
                  const currentTimeZone = areaId?.timeZoneId?.label ?? moment.tz.guess();
                  const updatedTimeZone = value?.timeZoneId?.label ?? moment.tz.guess();

                  if (startTime && endTime) {
                    const currentStartTime = displayDateTime(startTime, currentTimeZone);
                    const currentEndTime = displayDateTime(endTime, currentTimeZone);

                    onChangeFields(
                      'startTime',
                      moment.tz(currentStartTime, DATE_TIME_FORMAT, updatedTimeZone)?.toISOString()
                    );
                    onChangeFields(
                      'endTime',
                      moment.tz(currentEndTime, DATE_TIME_FORMAT, updatedTimeZone)?.toISOString()
                    );
                  }

                  const newBreakDetails = timeSheetBreakDetailEntities.map((e) => {
                    if (e?.isCustom) {
                      const currentBreakStartTime = displayDateTime(
                        e?.breakStartTime,
                        currentTimeZone
                      );
                      const currentBreakEndTime = displayDateTime(e?.breakEndTime, currentTimeZone);

                      return {
                        ...e,
                        breakStartTime: e?.breakStartTime
                          ? moment
                              .tz(currentBreakStartTime, DATE_TIME_FORMAT, updatedTimeZone)
                              ?.toISOString()
                          : null,
                        breakEndTime: e?.breakEndTime
                          ? moment
                              .tz(currentBreakEndTime, DATE_TIME_FORMAT, updatedTimeZone)
                              ?.toISOString()
                          : null,
                      };
                    }
                    return e;
                  });

                  onChangeFields('timeSheetBreakDetailEntities', newBreakDetails);
                }}
                error={errors?.areaId}
                // loading={getAreaListByPersonActionLoader}
                required
              />
              <div> </div>
              <EzyDateTime
                label="Clock-in time"
                name="startTime"
                placeholder="Select clock-in time"
                value={startTime}
                onChange={(value) => onChangeFields('startTime', value)}
                error={errors?.startTime}
                timeZone={areaId?.timeZoneId?.label}
                required
              />
              <div className="d-flex gap-10 w-full">
                <EzyDateTime
                  label="Clock-out time"
                  name="endTime"
                  placeholder="Select clock-out time"
                  value={endTime}
                  onChange={(value) => onChangeFields('endTime', value)}
                  error={errors?.endTime}
                  timeZone={areaId?.timeZoneId?.label}
                  className="w-full"
                  required
                />
                <EzyTooltip title={label} placement="bottom">
                  {!isTabletScreen ? (
                    <EzyButton
                      label="Round"
                      onClick={() => handleRoundTime()}
                      disabled={!(startTime && endTime) || !areaId?.id}
                    />
                  ) : (
                    <EzyIconButton
                      iconName="update"
                      onClick={() => handleRoundTime()}
                      disabled={!(startTime && endTime) || !areaId?.id}
                      // className="small"
                    />
                  )}
                </EzyTooltip>
              </div>
            </div>

            <div className="title-with-primary-bg">Break(s) statistic</div>

            <div className="details-fields">
              <EzyAutoComplete
                label="Total meal break"
                placeholder="Select time"
                name="totalMealBreak"
                value={totalMealBreak}
                // onChange={(value) => onChangeFields('totalMealBreak', value)}
                error={errors?.totalMealBreak}
                disabled
              />
              <EzyAutoComplete
                label="Total rest break"
                placeholder="Select time"
                name="totalRestBreak"
                value={totalRestBreak}
                // onChange={(value) => onChangeFields('totalMealBreak', value)}
                error={errors?.totalRestBreak}
                disabled
              />
            </div>

            <div className="title-with-primary-bg">
              Details of Break(s)
              <span>
                <EzyIconButton
                  iconName="add"
                  onClick={() => dispatch(addDeleteTimeSheetBreakDetails({ type: 'ADD' }))}
                  className="small"
                />
              </span>
            </div>

            {((output?.['1'] ? output?.['1'] !== totalMealBreak : false) ||
              (output?.['2'] ? output?.['2'] !== totalRestBreak : false)) && (
              <div className="info-div error ">
                <IconByName name="error" />
                Please note that total duration of break/s does not reconcile with break details.You
                may still approve the timesheet.
              </div>
            )}

            <div className="break-details-field">
              {timeSheetBreakDetailEntities?.map((entity, index) => (
                <>
                  <div className="d-flex flex-align-center just-bet">
                    <EzySwitch
                      name="isCustom"
                      label="Set break start and end time"
                      checked={entity?.isCustom}
                      handleChange={(e) => {
                        setIsFromEdit(true);
                        onChangeFields('isCustom', e?.target?.checked, index);
                      }}
                    />
                    <span>
                      <EzyIconButton
                        iconName="delete"
                        onClick={() =>
                          dispatch(addDeleteTimeSheetBreakDetails({ type: 'DELETE', index }))
                        }
                        className="small"
                      />
                    </span>
                  </div>
                  <div className={!entity?.isCustom ? 'd-flex gap-10' : 'break-details-with-time'}>
                    <EzyAutoComplete
                      label="Break type"
                      placeholder="Select break type"
                      options={breakType}
                      value={entity?.breakType}
                      name="breakType"
                      onChange={(_, value) => {
                        setIsFromEdit(true);
                        onChangeFields('breakType', value, index);
                      }}
                      error={errors?.timeSheetBreakDetailEntities?.[index]?.breakType}
                    />
                    <EzyAutoComplete
                      label="Break duration"
                      placeholder="Select break duration"
                      options={breakDurationList}
                      value={entity?.duration}
                      name="duration"
                      onChange={(_, value) => {
                        setIsFromEdit(true);
                        onChangeFields('duration', value, index);
                      }}
                      error={entity?.errors?.duration}
                      disabled={entity?.isCustom}
                    />
                    {entity?.isCustom && (
                      <>
                        <EzyTime
                          label="Break start time"
                          placeholder="Select break start time"
                          name="startTime"
                          value={entity?.breakStartTime}
                          onChange={(value) => {
                            setIsFromEdit(true);
                            onChangeFields('breakStartTime', value, index);
                          }}
                          error={errors?.timeSheetBreakDetailEntities?.[index]?.breakStartTime}
                          timeZone={areaId?.timeZoneId?.label}
                        />
                        <EzyTime
                          label="Break end time"
                          placeholder="Select break end time"
                          name="endTime"
                          value={entity?.breakEndTime}
                          onChange={(value) => {
                            setIsFromEdit(true);
                            onChangeFields('breakEndTime', value, index);
                          }}
                          error={errors?.timeSheetBreakDetailEntities?.[index]?.breakEndTime}
                          timeZone={areaId?.timeZoneId?.label}
                        />
                      </>
                    )}
                  </div>
                </>
              ))}
            </div>
          </div>
        )
      )}
      <TimeSheetDetailsAction />
    </div>
  );
}

TimesheetDetails.propTypes = {};

export default TimesheetDetails;
