import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  getAllPersonWhoCanApproveLeaveEnum,
  getBusinessLeaveSettingsAction,
} from '../redux/businessAction';
import EzySwitch from '../../../components/common/switch/EzySwitch';
import EzyAutoComplete from '../../../components/common/autoComplete/EzyAutoComplete';
import BusinessLeaveRules from './BusinessLeaveRules';
import {
  resetLeaveSettingDetails,
  updateLeaveRuleData,
  updateLeaveSettingDetails,
} from '../redux/businessSlice';
import EzyIconButton from '../../../components/common/iconButton/EzyIconButton';
import LeaveRulesDrawer from './LeaveRulesDrawer';
import Loader from '../../../components/common/loader/Loader';
import ROUTE_CONSTANTS from '../../../utils/constants';

function BusinessLeaveSettings() {
  const details = useSelector(({ business }) => business?.leaveSettings ?? {});
  const { whoCanApproveLeaveEnum } = useSelector(({ business }) => business?.leaveRuleData ?? {});

  const { getAllPersonWhoCanApproveLeaveEnumLoader, getBusinessLeaveSettingsActionLoader } =
    useSelector(({ common }) => common.generalLoader ?? {});

  const {
    allowTeamMembersToViewTheirBalance,
    allowTeamMembersToAmendOrCancelTheirApprovedLeaveRequest,
    whoCanApproveLeave,
    errors,
  } = useMemo(() => details, [details]);
  const dispatch = useDispatch();

  const handleFieldChange = (name, value) => {
    dispatch(updateLeaveSettingDetails({ fieldName: name, value }));
  };

  const onClickAddLeave = () => {
    dispatch(updateLeaveRuleData({ fieldName: 'leaveRulesDrawer', value: true }));
  };

  useEffect(() => {
    dispatch(
      getAllPersonWhoCanApproveLeaveEnum(() => {
        dispatch(getBusinessLeaveSettingsAction());
      })
    );
    return () => {
      dispatch(resetLeaveSettingDetails());
    };
  }, []);

  return (
    <div className="leave-settings-wrapper">
      {getAllPersonWhoCanApproveLeaveEnumLoader || getBusinessLeaveSettingsActionLoader ? (
        <Loader />
      ) : (
        <>
          <div className="two-grid p-x-24">
            <div className="d-flex-column gap-10">
              <EzySwitch
                name="allowTeamMembersToAmendOrCancelTheirApprovedLeaveRequest"
                label="Allow team members to amend or cancel their approved leave request"
                checked={allowTeamMembersToAmendOrCancelTheirApprovedLeaveRequest}
                handleChange={(e) =>
                  handleFieldChange(
                    'allowTeamMembersToAmendOrCancelTheirApprovedLeaveRequest',
                    e?.target?.checked
                  )
                }
              />
              <EzySwitch
                name="allowTeamMembersToViewTheirBalance"
                label="Allow team members to view their leave balance"
                checked={allowTeamMembersToViewTheirBalance}
                handleChange={(e) =>
                  handleFieldChange('allowTeamMembersToViewTheirBalance', e?.target?.checked)
                }
              />
            </div>
            <EzyAutoComplete
              placeholder="Select access level"
              label="Who can approve leaves ?"
              tooltip={
                <>
                  Here, list of access level available in your organization. &nbsp;
                  <Link to={`${ROUTE_CONSTANTS.PEOPLE}/add`} className="secondary-action-text">
                    Click here
                  </Link>
                </>
              }
              options={whoCanApproveLeaveEnum}
              value={whoCanApproveLeave}
              name="whoCanApproveLeave"
              onChange={(_, value) => {
                handleFieldChange('whoCanApproveLeave', value);
              }}
              error={errors?.whoCanApproveLeave}
              required
            />
          </div>
          <div className="title-with-primary-bg">
            <div>Leave Rules</div>
            <EzyIconButton iconName="add" onClick={onClickAddLeave} className="small" />
          </div>
          <div className="p-x-24 leaveRulesTableHeight">
            <BusinessLeaveRules />
          </div>
          <LeaveRulesDrawer />
        </>
      )}
    </div>
  );
}

export default BusinessLeaveSettings;
