import PropTypes from 'prop-types';
import EzyDialog from '../dialog/EzyDialog';
import EzyDialogTitle from '../dialog/EzyDialogTitle';
import EzyDialogAction from '../dialog/EzyDialogAction';

function ConfirmationModal({
  handleClose,
  isOpen,
  title,
  children,
  handleSubmit,
  primaryLabel,
  secondaryLabel,
  className,
  primaryColor,
  loading,
}) {
  const actionButton = [
    {
      label: secondaryLabel,
      color: 'secondary',
      onClick: handleClose,
    },
    {
      label: primaryLabel,
      onClick: handleSubmit,
      color: primaryColor,
      loading,
    },
  ];

  return (
    <EzyDialog
      handleClose={handleClose}
      className={`common-dialog-with-body ${className}`}
      open={isOpen}
    >
      <EzyDialogTitle title={title} onClose={handleClose} />
      <div className="dialog-body title-small">{children}</div>
      <EzyDialogAction actionButtons={actionButton} />
    </EzyDialog>
  );
}

export default ConfirmationModal;

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  primaryLabel: PropTypes.string.isRequired,
  secondaryLabel: PropTypes.string,
  primaryColor: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  primaryColor: 'error',
  secondaryLabel: 'Cancel',
  className: 'delete-confirmation-modal',
  loading: false,
};
