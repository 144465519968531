import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import EzyAvatar from '../../../../components/common/avatar/EzyAvatar';
import IconByName from '../../../../components/common/iconByName/IconByName';
import EzyNavBar from '../../../../components/common/navBar/EzyNavBar';
import EzyTab from '../../../../components/common/tab/EzyTab';
import ROUTE_CONSTANTS from '../../../../utils/constants';
import LeaveRequestList from '../../../people/components/leaveDetails/LeaveRequestList';
import EzyButton from '../../../../components/common/button/EzyButton';
import PreviouslyWorkedShifts from '../../../people/components/shiftDetails/PreviouslyWorkedShifts';
import {
  editLeaveDetails,
  updateAddUnavailabilityDetails,
} from '../../../people/redux/peopleSlice';
import Loader from '../../../../components/common/loader/Loader';
import AddTimeSheetDrawer from '../../../timeSheets/components/AddTimeSheetDrawer';
import { updateTimeSheetData } from '../../../timeSheets/redux/timeSheetsSlice';
import {
  getPreviousShiftsAction,
  showTimeSheetingAction,
} from '../../../people/redux/peopleActions';
import { displayDate } from '../../../../utils/timeHelper';
import useQueryParams from '../../../../hooks/useQueryParams';
import { updateGlobalProfileData } from '../../redux/globalProfileSlice';
import UnavailabilityDetails from '../../../people/components/UnavailabiltyDetails/UnavailabilityDetails';

function ProfileSettings() {
  const history = useHistory();
  const dispatch = useDispatch();
  const details = useSelector(({ user }) => user?.profileData ?? {});

  const { tab = 0 } = history?.location?.state ?? {};
  const { viewLeave = false, viewShift = null } = history?.location?.state ?? {};

  const { getCurrentUserDataLoader } = useSelector(({ common }) => common.generalLoader ?? {});

  const {
    personId,
    personName,
    profilePictureUrl,
    email,
    countryCode,
    phoneNumber,
    dateOfBirth,
    showTimesheetToEmployee,
  } = useMemo(() => details, [details]);

  const { page: paramPage, limit: paramLimit } = useQueryParams();

  const leaveRequestActions = [
    {
      label: (
        <span className="flex-center">
          <IconByName name="add" /> Add leave request
        </span>
      ),
      onClick: () => {
        dispatch(editLeaveDetails({ fieldName: 'leaveRequestDrawer', value: true }));
      },
      access: 'CREATELVMGMT',
    },
  ];

  const TimeSheetAction = [
    {
      label: (
        <span className="flex-center">
          <IconByName name="add" /> Add timesheets
        </span>
      ),
      onClick: () =>
        dispatch(updateTimeSheetData({ fieldName: 'addTimeSheetDrawer', value: true })),
    },
  ];

  const UnavailabilityActionButtons = [
    {
      label: (
        <div className="flex-center">
          <IconByName name="add" /> Add Unavailability
        </div>
      ),
      onClick: () => {
        dispatch(updateAddUnavailabilityDetails({ fieldName: 'isDrawerOpen', value: true }));
      },
      access: 'CREATEUNAV',
    },
  ];

  const getShiftListByFilter = async (params = {}) => {
    const data = {
      page: paramPage ?? 1,
      limit: paramLimit ?? 15,
      personDetailsId: personId,
      ...params,
    };
    await dispatch(getPreviousShiftsAction(data));
  };

  const profileTabs = [
    {
      label: 'Leave',
      component: <LeaveRequestList isFromProfile />,
      actionButtons: leaveRequestActions,
      access: 'LISTLVMGMT',
    },
    {
      label: 'Timesheets',
      component: <PreviouslyWorkedShifts isFromProfile />,
      actionButtons: TimeSheetAction,
      access: showTimesheetToEmployee ? 'LISTTSPRF' : '',
    },
    {
      label: 'Unavailablity',
      component: <UnavailabilityDetails isFromProfile />,
      actionButtons: UnavailabilityActionButtons,
      access: 'LISTUNAV',
    },
  ];

  useEffect(() => {
    if (viewLeave) {
      dispatch(editLeaveDetails({ fieldName: 'viewLeaveRequestDrawer', value: true }));
      dispatch(editLeaveDetails({ fieldName: 'leaveRequestId', value: viewLeave }));
    }
    if (viewShift) {
      dispatch(updateTimeSheetData({ fieldName: 'viewTimeSheetDrawer', value: viewShift }));
    }
  }, [viewLeave, viewShift]);

  useEffect(() => {
    dispatch(showTimeSheetingAction());
  }, []);

  return (
    <div className="h-full w-full d-flex-column profile-setting-fullScreen-wrapper">
      <EzyNavBar isFormProfile>
        <IconByName
          name="close"
          className="pointer-cursor white-color"
          onClick={() => history.push(ROUTE_CONSTANTS.HOME)}
        />
      </EzyNavBar>

      <div className="panel-child-wrapper">
        {getCurrentUserDataLoader ? (
          <Loader />
        ) : (
          <div className="profile-grid-fields">
            <div className="profile-grid">
              <EzyAvatar
                className="profile-picture"
                firstName={personName}
                imageLink={profilePictureUrl}
              />

              <div className="large-title">{personName || '-'}</div>

              <div className="profile-details">
                <div className="flex-center gap-10">
                  <IconByName name="email" />
                  <span>{email || '-'}</span>
                </div>
                <div className="flex-center gap-10">
                  <IconByName name="call" />
                  <span>{countryCode && phoneNumber ? `+${countryCode} ${phoneNumber}` : '-'}</span>
                </div>
                <div className="flex-center gap-10">
                  <IconByName name="cake" />
                  <span>{displayDate(dateOfBirth, 'UTC') || '-'}</span>
                </div>
              </div>

              <EzyButton
                label="Edit global profile"
                onClick={() => {
                  history.push(ROUTE_CONSTANTS.ACCOUNT_OVERVIEW);
                  dispatch(updateGlobalProfileData({ fieldName: 'isEditProfile', value: true }));
                }}
              />
            </div>

            <EzyTab list={profileTabs} defaultIndex={tab} />
          </div>
        )}
      </div>

      <AddTimeSheetDrawer getTimeSheetsList={getShiftListByFilter} isFromProfile />
    </div>
  );
}

export default ProfileSettings;
