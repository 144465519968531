import React, { useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty, isNull } from 'lodash';
import Loader from '../../../../components/common/loader/Loader';
import AddUnavailabilityDrawer from './AddUnavailabilityDrawer';
import {
  deleteUnavailabilityByIdAction,
  getUnavailabilityListAction,
} from '../../redux/peopleActions';
import ConfirmationModal from '../../../../components/common/modal/ConfirmationModal';
import { updateUnavailability, updateUnavailabilityDetails } from '../../redux/peopleSlice';
import Unavailability from './Unavailability';

function UnavailabilityDetails({ isFromProfile }) {
  const dispatch = useDispatch();
  const [deleteModalData, setDeleteModalData] = useState(null);
  const params = useParams();
  const { unavailability } = useSelector(({ people }) => people?.unavailabilityDetails ?? {});
  const { docs, page, limit, hasMoreRecords } = useMemo(
    () => unavailability ?? {},
    [unavailability]
  );
  const { personId: loggedInPersonId } = useSelector(({ user }) => user?.profileData ?? {});
  const { getUnavailabilityListActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );
  const getUnavailabilityListByFilter = async (param = {}) => {
    const data = {
      page: page ?? 1,
      limit: limit ?? 15,
      personDetailId: isFromProfile ? loggedInPersonId : params?.peopleId,
      ...param,
    };
    await dispatch(getUnavailabilityListAction(data));
  };
  const handleScroll = useCallback(
    (e) => {
      if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight && hasMoreRecords) {
        dispatch(
          updateUnavailabilityDetails({
            fieldName: 'unavailability',
            value: { ...unavailability, page: page + 1 },
          })
        );
        getUnavailabilityListByFilter({
          page: page + 1,
        });
      }
    },

    [docs?.length, hasMoreRecords]
  );
  useEffect(() => {
    if (params?.peopleId || loggedInPersonId) {
      getUnavailabilityListByFilter();
    }
  }, []);
  return (
    <div className="unavailability-wrapper" onScroll={handleScroll}>
      {getUnavailabilityListActionLoader && <Loader />}
      {docs?.length > 0 ? (
        docs?.map((item) => {
          return (
            <Unavailability
              isFromProfile={isFromProfile}
              key={item.id}
              unavailability={item}
              setDeleteModalData={setDeleteModalData}
            />
          );
        })
      ) : (
        <div className="no-data-found">No data found</div>
      )}

      <AddUnavailabilityDrawer
        isFromProfile={isFromProfile}
        listCallback={getUnavailabilityListByFilter}
      />
      <ConfirmationModal
        title="Delete Unavailability"
        isOpen={!isEmpty(deleteModalData) || !isNull(deleteModalData)}
        handleClose={() => {
          setDeleteModalData(null);
        }}
        handleSubmit={async () => {
          await dispatch(
            deleteUnavailabilityByIdAction({ unavailabilityId: deleteModalData }, () => {
              dispatch(
                updateUnavailability({
                  fieldName: 'docs',
                  value: docs.filter((e) => e.id !== deleteModalData),
                })
              );
            })
          );
          setDeleteModalData(null);
        }}
        primaryLabel="Delete"
      >
        Are you sure you want to delete?
      </ConfirmationModal>
    </div>
  );
}

export default UnavailabilityDetails;

UnavailabilityDetails.propTypes = {
  isFromProfile: PropTypes.bool,
};

UnavailabilityDetails.defaultProps = {
  isFromProfile: false,
};
