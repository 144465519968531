import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import React from 'react';
import AccessWrapper from '../accessWrapper/AccessWrapper';

const EzyButton = React.forwardRef(({ label, variant, className, ...restProps }, ref) => {
  const classes = `button ${className || ''}`;
  return (
    <AccessWrapper access={restProps?.access} byPass={!restProps?.access}>
      <LoadingButton variant={variant} className={classes} ref={ref} {...restProps}>
        <span className="button-label">{label}</span>
      </LoadingButton>
    </AccessWrapper>
  );
});

export default EzyButton;

EzyButton.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  variant: PropTypes.string,
};

EzyButton.defaultProps = {
  className: '',
  variant: 'contained',
};
