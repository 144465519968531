import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EzyDrawer from '../../../components/common/drawer/Drawer';
import IconByName from '../../../components/common/iconByName/IconByName';
import { getCurrentUserDataAction } from '../../auth/redux/userAction';
import { getBusinessListAction, switchBusinessAction } from '../redux/globalProfileActions';
import { updateBusinessData } from '../redux/globalProfileSlice';

function SwitchBusinessDrawer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { switchBusinessDrawer, businessList, newBusinessId } = useSelector(
    ({ globalProfile }) => globalProfile?.businessData || {}
  );
  const businessId = useSelector(({ user }) => user?.profileData?.businessId || '');

  const { getBusinessListActionLoader } = useSelector(({ common }) => common.generalLoader ?? {});

  const handleClose = () => {
    dispatch(updateBusinessData({ fieldName: 'switchBusinessDrawer', value: false }));
  };
  const onSwitchBusiness = async () => {
    if (newBusinessId !== businessId) {
      const isSuccess = await dispatch(switchBusinessAction({ businessId: newBusinessId }));
      if (isSuccess) {
        dispatch(
          getCurrentUserDataAction(
            () => {
              history.push('/');
            },
            { isFromSwitchBusiness: true }
          )
        );
        handleClose();
      }
    } else {
      handleClose();
    }
  };
  const switchBusinessActionButtons = [
    {
      label: 'Go to business',
      onClick: onSwitchBusiness,
      loading: false,
    },
  ];

  useEffect(() => {
    if (switchBusinessDrawer) {
      dispatch(getBusinessListAction());
    }
  }, [switchBusinessDrawer]);

  return (
    <EzyDrawer
      isOpen={switchBusinessDrawer}
      onClose={handleClose}
      heading="Switch Business"
      actionButtons={switchBusinessActionButtons}
      isLoading={getBusinessListActionLoader}
    >
      <div className="switch-business-drawer">
        <List>
          {businessList?.map((value) => {
            return (
              <ListItem
                key={value?.businessId}
                disablePadding
                selected={value?.businessId === newBusinessId}
                onClick={() =>
                  dispatch(
                    updateBusinessData({ fieldName: 'newBusinessId', value: value?.businessId })
                  )
                }
              >
                <ListItemButton disableRipple>
                  <ListItemIcon>
                    <IconByName name="business_center" />
                  </ListItemIcon>

                  <ListItemText>{value?.businessName}</ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </div>
    </EzyDrawer>
  );
}

export default SwitchBusinessDrawer;
