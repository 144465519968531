import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EzySwitch from '../../../../components/common/switch/EzySwitch';
import EzyButton from '../../../../components/common/button/EzyButton';
import { updateAppIntegrationData } from '../../redux/globalProfileSlice';
import Loader from '../../../../components/common/loader/Loader';
import IconByName from '../../../../components/common/iconByName/IconByName';
import logo from '../../../../assets/images/logo/Logo.svg';
import {
  autoSyncEmployeesAction,
  getLocationSpecificConfigurationAction,
} from '../../redux/globalProfileActions';

function IntegrationConfiguration() {
  const dispatch = useDispatch();

  const { locationSpecificConfigurations, specificIntegrationDetails, selectedLocation } =
    useSelector(({ globalProfile }) => globalProfile?.appIntegration || {});

  const { getLocationSpecificConfigurationActionLoader } = useSelector(
    ({ common }) => common.generalLoader ?? {}
  );
  return (
    <div className="configuration-root-container">
      {specificIntegrationDetails?.integrationEntity?.integrationName === 'Xero' && (
        <EzySwitch
          label="Auto sync employees"
          subLabel="This will update the employee profile in Xero with data from PayrollEzy. 
          The PayrollEzy employee profile will be the source of truth, any changes made in Xero will be overwritten."
          checked={locationSpecificConfigurations?.autoSyncEmployees}
          onClick={(e) => {
            dispatch(
              autoSyncEmployeesAction(
                {
                  isAutoSyncEmployee: e?.target?.checked,
                  locationId: selectedLocation,
                  integrationId: specificIntegrationDetails?.integrationEntity?.integrationId,
                },
                () => {
                  dispatch(
                    getLocationSpecificConfigurationAction({
                      locationId: selectedLocation,
                      integrationId: specificIntegrationDetails?.integrationEntity?.integrationId,
                    })
                  );
                }
              )
            );
          }}
        />
      )}

      {specificIntegrationDetails?.integrationEntity?.integrationName === 'Myob Business' && (
        <div className="info-div success">
          Connected Account: {locationSpecificConfigurations?.myobFileName}
        </div>
      )}

      {getLocationSpecificConfigurationActionLoader ? (
        <Loader />
      ) : (
        <div className="app-configuration-list">
          {locationSpecificConfigurations?.apiList?.length ? (
            locationSpecificConfigurations?.apiList?.map((value, i) => {
              const isSwapAPIValues =
                specificIntegrationDetails?.integrationEntity?.integrationName === 'QuickBooks' &&
                i === 0;
              return (
                <div className="app-configuration-details">
                  {isSwapAPIValues ? (
                    <div className="configuration">
                      <img src={logo} alt="logo" />
                      <span>{value?.payRollEzyAPIName}</span>
                      <IconByName name="play_arrow" className="primary-color icon-small" />
                      <img
                        src={specificIntegrationDetails?.integrationEntity?.logoPath}
                        alt={specificIntegrationDetails?.integrationEntity?.integrationName}
                      />
                      <span>{value?.integrationAPIName}</span>
                    </div>
                  ) : (
                    <div className="configuration">
                      <img
                        src={specificIntegrationDetails?.integrationEntity?.logoPath}
                        alt={specificIntegrationDetails?.integrationEntity?.integrationName}
                      />
                      <span>{value?.integrationAPIName}</span>
                      <IconByName name="play_arrow" className="primary-color icon-small" />
                      <img src={logo} alt="logo" />
                      <span>{value?.payRollEzyAPIName}</span>
                    </div>
                  )}

                  <EzyButton
                    label={value?.isAlreadyActivated ? 'Edit' : 'Activate'}
                    className="no-pad-button"
                    color={value?.isAlreadyActivated ? 'secondary' : 'primary'}
                    onClick={() =>
                      dispatch(
                        updateAppIntegrationData({
                          fieldName: 'configurationDrawer',
                          value: { ...value, isSwapAPIValues },
                        })
                      )
                    }
                  />
                </div>
              );
            })
          ) : (
            <div className="no-data-found">No configuration found</div>
          )}
        </div>
      )}
    </div>
  );
}

export default IntegrationConfiguration;
