import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { renderDashboardCardContent } from './renderDashboardCardContent';
import { getDashboardDetailsByBusinessIdAction } from '../redux/dashboardActions';
import Loader from '../../../components/common/loader/Loader';
import ROUTE_CONSTANTS from '../../../utils/constants';
import { updateTaskListFilters } from '../../tasks/redux/tasksSlice';

function DashboardCard({ cardFor, data, label, error, onClick }) {
  return (
    <div className="dashboard-card">
      <div className="card-header">{label}</div>
      <div className="card-body">
        {data?.length > 0 ? (
          renderDashboardCardContent(cardFor, data, onClick)
        ) : (
          <div className="no-data-found">{error}</div>
        )}
      </div>
    </div>
  );
}

DashboardCard.propTypes = {
  cardFor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  onClick: PropTypes.func,
};

DashboardCard.defaultProps = {
  onClick: () => {},
};

function DashboardCards({ cardsFor }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { timeZone, weekStartsOn } = useSelector(({ user }) => user?.profileData || {});
  const { dashboardData } = useSelector(({ dashboard }) => dashboard ?? {});
  const { getDashboardDetailsByBusinessIdActionLoader } = useSelector(
    ({ common }) => common?.generalLoader ?? {}
  );

  const {
    unfilledOpenShiftEntity = [],
    unpublishedShiftEntity = [],
    offerShiftEntity = [],
    allLeaveRequestsEntity = [],
    timesheetEntity = [],
    openShiftRequestEntity = [],
    swapShiftEntity = [],
    personWorkAnniversaries = [],
    personBirthdays = [],
    peopleOnLeaveEntity = [],
    runningLateShiftEntity = [],
    incompleteTasks = [],
    unavailability = [],
  } = useMemo(() => dashboardData ?? {}, [dashboardData]);

  const handleShiftClick = (data) => {
    history.push(`${ROUTE_CONSTANTS.SCHEDULE}?location=${data.locationId}&date=${data.startTime}`, {
      shiftIdToHighlight: data.shiftId,
    });
  };

  const handleSwapShiftClick = (data) => {
    history.push(
      `${ROUTE_CONSTANTS.SCHEDULE}?location=${data.fromPersonDetails.locationId}&date=${data.fromPersonDetails.shiftStartTime}`,
      {
        shiftIdToHighlight: data.fromPersonDetails.shiftId,
      }
    );
  };

  const handleLeaveClick = (data) => {
    history.push(`${ROUTE_CONSTANTS.PEOPLE}/edit/${data.personDetailId}`, {
      tab: 2,
      viewLeave: data.id,
    });
  };
  const handlePeopleClick = (data) => {
    history.push(`${ROUTE_CONSTANTS.PEOPLE}/edit/${data.personDetailId}`);
  };

  const handleTaskClick = (data) => {
    dispatch(updateTaskListFilters({ fieldName: 'taskType', value: 'Tasks By Area' }));
    history.push(
      `${ROUTE_CONSTANTS.TASKS}?locationId=${data?.locationId}&date=${moment(
        new Date()
      ).toISOString()}`
    );
  };

  const handleTimesheetClick = (data) => {
    history.push(
      `${ROUTE_CONSTANTS.TIMESHEETS}?page=1&limit=15&location=${data?.locationId}&area=${data?.areaId}&person=${data?.personDetailId}&date=${data?.startTime}`
    );
  };

  useEffect(() => {
    if (cardsFor) {
      moment.updateLocale('en', {
        week: {
          dow: weekStartsOn === 7 ? 0 : weekStartsOn,
        },
      });
      (async () => {
        await dispatch(
          getDashboardDetailsByBusinessIdAction({
            display: cardsFor,
            weekStartDate: moment.tz(new Date(), timeZone?.label).startOf('week').toISOString(),
            weekEndDate: moment.tz(new Date(), timeZone?.label).endOf('week').toISOString(),
          })
        );
      })();
    }
  }, [cardsFor]);

  if (getDashboardDetailsByBusinessIdActionLoader) {
    return <Loader />;
  }

  if (cardsFor === 1) {
    return (
      <div className="dashboard-card-wrapper">
        <div className="divide-cards">
          <DashboardCard
            cardFor="UNFILLED_OPEN_SHIFT"
            data={unfilledOpenShiftEntity}
            label="Unfilled open shifts"
            error="There is no unfilled open shifts!"
            onClick={handleShiftClick}
          />
          <DashboardCard
            cardFor="INCOMPLETE_TASK"
            data={incompleteTasks}
            label="Incomplete tasks"
            error="There is no incomplete tasks!"
            onClick={handleTaskClick}
          />
        </div>
        <div className="divide-cards">
          <DashboardCard
            cardFor="UNPUBLISHED_SHIFT"
            data={unpublishedShiftEntity}
            label="Unpublished shifts"
            error="There is no unpublished shifts!"
            onClick={handleShiftClick}
          />
          <DashboardCard
            cardFor="UNAVAILABILITY"
            data={unavailability}
            label="Unavailability"
            error="There is no unavailability!"
            onClick={handlePeopleClick}
          />
        </div>

        <DashboardCard
          cardFor="OFFER_SHIFT"
          data={offerShiftEntity}
          label="Offer shifts"
          error="There is no offered shifts!"
          onClick={handleShiftClick}
        />
      </div>
    );
  }

  if (cardsFor === 2) {
    return (
      <div className="dashboard-card-wrapper">
        <DashboardCard
          cardFor="LEAVE_REQUESTS"
          data={allLeaveRequestsEntity}
          label="Leave requests"
          error="There is no pending leave requests!"
          onClick={handleLeaveClick}
        />
        <DashboardCard
          cardFor="TIMESHEET"
          data={timesheetEntity}
          label="Timesheet"
          error="There is no pending timesheet!"
          onClick={handleTimesheetClick}
        />
        <div className="divide-cards">
          <DashboardCard
            cardFor="OPEN_SHIFTS"
            data={openShiftRequestEntity}
            label="Open shift requests"
            error="There is no open shift requests!"
            onClick={handleShiftClick}
          />
          <DashboardCard
            cardFor="SWAP_SHIFTS"
            data={swapShiftEntity}
            label="Swap shifts"
            error="There is no swap shift request!"
            onClick={handleSwapShiftClick}
          />
        </div>
      </div>
    );
  }
  if (cardsFor === 3) {
    return (
      <div className="dashboard-card-wrapper">
        <DashboardCard
          cardFor="RUNNING_LATE"
          data={runningLateShiftEntity}
          label="Running late"
          error="Everyone is on time!"
          onClick={handlePeopleClick}
        />
        <DashboardCard
          cardFor="PEOPLE_ON_LEAVE"
          data={peopleOnLeaveEntity}
          label="People on leave"
          error="Everyone working today!"
          onClick={handleLeaveClick}
        />
        <div className="divide-cards">
          <DashboardCard
            cardFor="BIRTHDAYS"
            data={personBirthdays}
            label="Birthdays"
            error="No one is having birthday today!"
          />
          <DashboardCard
            cardFor="WORK_ANNIVERSARIES"
            data={personWorkAnniversaries}
            label="Work anniversaries"
            error="No one is having work anniversary today!"
          />
        </div>
      </div>
    );
  }
}

export default DashboardCards;

DashboardCards.propTypes = {
  cardsFor: PropTypes.string.isRequired,
};
