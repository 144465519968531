import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import EzyButton from '../../../components/common/button/EzyButton';
import IconByName from '../../../components/common/iconByName/IconByName';
import EzyMenu from '../../../components/common/menu/EzyMenu';
import {
  changeTimeSheetStatusAction,
  getAllTimesheetByPerson,
  getPersonListBasedOnLocationAction,
  getTimeSheetViewDetailsAction,
} from '../redux/timeSheetsAction';
import TimeSheetSideBar from './TimeSheetSideBar';
import {
  resetTimeSheetData,
  resetTimeSheetListData,
  updateSidePanelData,
  updateTimeSheetData,
} from '../redux/timeSheetsSlice';
import ViewTimeSheetDrawer from '../components/ViewTimeSheetDrawer';
import AddTimeSheetDrawer from '../components/AddTimeSheetDrawer';
import useUrlParamsUpdate from '../../../hooks/useURLParamsUpdate';
import { timeSheetActionButtons, timeSheetActions } from '../../../utils/constants';
import TimeSheetHistoryDrawer from '../components/TimeSheetHistoryDrawer';
import DateDurationPicker from '../components/DateDurationPicker';
import TimesheetSelectableTable from '../components/TimesheetSelectableTable';
import EditViewTimesheet from '../components/EditViewTimesheet';

function TimeSheetsList() {
  const dispatch = useDispatch();
  const splitPaneRef = createRef();

  const timeSheetsData = useSelector(({ timeSheets }) => timeSheets?.timeSheetsList);
  const {
    weekDatesValue,
    selectedLocation,
    selectedPerson,
    filterDuration,
    selectedTimesheet,
    personList,
  } = useSelector(({ timeSheets }) => timeSheets?.sidePanelData);

  // const { businessId } = useSelector(({ user }) => user?.profileData ?? {});

  const {
    // getAreaPersonDataBasedOnLocationLoader,
    getAllTimesheetByPersonLoader,
    // eslint-disable-next-line camelcase
    timeSheetStatusAPPROVE_ALLLoader,
  } = useSelector(({ common }) => common.generalLoader ?? {});

  const { data, headers } = useMemo(() => timeSheetsData ?? {}, [timeSheetsData]);

  useUrlParamsUpdate({
    location: selectedLocation?.locationId ? selectedLocation?.locationId : selectedLocation?.id,
    area: selectedLocation?.locationId && selectedLocation?.id,
    person: selectedPerson?.id,
    date: moment.tz(weekDatesValue?.startDate, selectedLocation?.timeZoneId?.label).toISOString(),
  });

  const handleRowClick = (row) => {
    dispatch(updateSidePanelData({ fieldName: 'selectedTimesheet', value: row }));

    if (
      (row?.timeSheetStatus === 'Approved' && row?.timeSheetProgressStaus === 'Pay Approved') ||
      (row?.timeSheetStatus === 'Pending' && row?.timeSheetProgressStaus === 'Time Approved') ||
      row?.timeSheetStatus === 'Discarded'
    ) {
      dispatch(getTimeSheetViewDetailsAction({ timesheetId: row?.id }));
    } else {
      dispatch(getTimeSheetViewDetailsAction({ timesheetId: row?.id }, true));
    }
  };

  const getTimeSheetsListByFilter = async (params = {}) => {
    const paramsData = {
      personDetailId: selectedPerson?.id,
      startDate: moment(weekDatesValue?.startDate)?.toISOString(),
      endDate: moment(weekDatesValue?.endDate)?.toISOString(),
      locationId:
        selectedLocation?.id !== 'all' && !selectedLocation?.locationId
          ? selectedLocation?.id
          : null,
      areaId: selectedLocation?.locationId ? selectedLocation?.id : null,
      ...params,
    };

    await dispatch(
      getAllTimesheetByPerson(paramsData, (timesheetList) => {
        if (selectedTimesheet?.id && timesheetList?.find((e) => e?.id === selectedTimesheet?.id))
          handleRowClick(timesheetList?.find((e) => e?.id === selectedTimesheet?.id));
        else handleRowClick(timesheetList?.[0]);
      })
    );
  };

  const updateLocationList = (params, personParam = null) => {
    const finalParams = {
      startDate: moment
        .tz(weekDatesValue?.startDate, selectedLocation?.timeZoneId?.label)
        ?.toISOString(),
      endDate: moment
        .tz(weekDatesValue?.endDate, selectedLocation?.timeZoneId?.label)
        ?.toISOString(),
      locationId:
        selectedLocation?.id !== 'all' && !selectedLocation?.locationId
          ? selectedLocation?.id
          : null,
      areaId: selectedLocation?.locationId ? selectedLocation?.id : null,
      ...params,
    };

    dispatch(
      getPersonListBasedOnLocationAction(finalParams, async (listOfPerson) => {
        dispatch(
          updateSidePanelData({
            fieldName: 'selectedPerson',
            value: listOfPerson?.find((e) => e?.id === personParam) || listOfPerson?.[0],
          })
        );

        if (listOfPerson?.[0]?.id || personParam) {
          await getTimeSheetsListByFilter({
            personDetailId: personParam || finalParams?.personId || listOfPerson?.[0]?.id,
            areaId: finalParams?.areaId,
            locationId:
              finalParams?.locationId !== 'all' && !finalParams?.areaId
                ? finalParams?.locationId
                : null,
            startDate: params?.startDate || weekDatesValue?.startDate.toISOString(),
            endDate: params?.endDate || weekDatesValue?.endDate.toISOString(),
          });
        } else {
          dispatch(resetTimeSheetListData());
          dispatch(resetTimeSheetData());
        }
      })
    );
  };

  const handleStatusChange = (status, moveNext = false) => {
    dispatch(
      changeTimeSheetStatusAction(
        { timeSheetAction: status, timeSheetIds: data?.map((e) => e?.id) },
        async () => {
          if (moveNext) {
            const nextPerson =
              personList[personList.findIndex((e) => e?.id === selectedPerson?.id) + 1] ||
              personList?.[0];

            dispatch(
              updateSidePanelData({
                fieldName: 'selectedPerson',
                value: nextPerson,
              })
            );

            if (nextPerson?.id) {
              updateLocationList({}, nextPerson?.id);
            }
          } else await updateLocationList({}, selectedPerson?.id);
        }
      )
    );
  };

  const bulkActions = [
    {
      label: timeSheetActionButtons.approveAll,
      id: 1,
      onClick: () => handleStatusChange(timeSheetActions.approveAll),
      disabled:
        selectedPerson?.personBackgroundColor === 'Green' || selectedPerson?.workingHours === 0,
    },
    {
      label: timeSheetActionButtons.unApproveAll,
      id: 2,
      onClick: () => handleStatusChange(timeSheetActions.unApproveAll),
      disabled: data?.every((e) => e?.timeSheetStatus === 'Pending'),
    },
  ];

  // resize

  const [topHeight, setTopHeight] = useState(200);
  const separatorYPosition = useRef(null);

  const onStartResize = useCallback((e) => {
    separatorYPosition.current = e.touches ? e.touches[0].clientY : e.clientY;
  }, []);

  const onResize = useCallback(
    (e) => {
      if (!separatorYPosition.current) {
        return;
      }

      const clientY = e.touches ? e.touches[0].clientY : e.clientY;
      const newTopHeight = topHeight + clientY - separatorYPosition.current;
      separatorYPosition.current = clientY;

      setTopHeight(newTopHeight);
    },
    [topHeight]
  );

  const onStopResize = useCallback(() => {
    separatorYPosition.current = null;
  }, []);

  useEffect(() => {
    document.addEventListener('mousemove', onResize);
    document.addEventListener('touchmove', onResize, { passive: false });
    document.addEventListener('mouseup', onStopResize);
    document.addEventListener('touchend', onStopResize);

    return () => {
      document.removeEventListener('mousemove', onResize);
      document.removeEventListener('touchmove', onResize);
      document.removeEventListener('mouseup', onStopResize);
      document.removeEventListener('touchend', onStopResize);
    };
  }, [onResize, onStopResize]);

  return (
    <div className="time-sheets-list-wrapper">
      <TimeSheetSideBar
        updateLocationList={updateLocationList}
        fetchTimeSheetByFilters={getTimeSheetsListByFilter}
      />
      <div className="time-sheets-wrapper">
        <div className="time-sheets-header">
          <DateDurationPicker
            value={{ ...weekDatesValue, filterDuration }}
            timeZone={selectedLocation?.timeZoneId?.label}
            onChange={(event) => {
              if (event?.filterDuration !== filterDuration) {
                dispatch(
                  updateSidePanelData({
                    fieldName: 'filterDuration',
                    value: event?.filterDuration,
                  })
                );
              }
              dispatch(
                updateSidePanelData({
                  fieldName: 'weekDatesValue',
                  value: {
                    startDate: moment.tz(
                      event.startDate.toISOString(),
                      selectedLocation?.timeZoneId?.label
                    ),
                    endDate: moment.tz(
                      event.endDate.toISOString(),
                      selectedLocation?.timeZoneId?.label
                    ),
                  },
                })
              );
              updateLocationList({
                startDate: moment
                  .tz(event.startDate.toISOString(), selectedLocation?.timeZoneId?.label)
                  ?.toISOString(),
                endDate: moment
                  .tz(event.endDate.toISOString(), selectedLocation?.timeZoneId?.label)
                  ?.toISOString(),
                searchText: '',
              });
            }}
          />
          <div>
            <EzyMenu iconName="more_vert" menuItems={bulkActions} color="primary" access="EDITTS" />
            <EzyButton
              label="Add"
              className="no-pad-button"
              startIcon={<IconByName name="add" />}
              onClick={() =>
                dispatch(updateTimeSheetData({ fieldName: 'addTimeSheetDrawer', value: true }))
              }
              access="CREATETS"
            />
            <EzyButton
              label="Approve all & next"
              className="no-pad-button"
              onClick={() => handleStatusChange(timeSheetActions.approveAll, true)}
              disabled={
                selectedPerson?.personBackgroundColor === 'Green' ||
                selectedPerson?.workingHours === 0
              }
              // eslint-disable-next-line camelcase
              loading={timeSheetStatusAPPROVE_ALLLoader}
            />
          </div>
        </div>

        <div className="time-sheets-table-wrapper table-wrapper" ref={splitPaneRef}>
          <TimesheetSelectableTable
            headers={headers}
            rows={data}
            loading={getAllTimesheetByPersonLoader}
            onRowClick={(row) => handleRowClick(row)}
            selectedRow={selectedTimesheet}
            setTopHeight={setTopHeight}
            topHeight={topHeight}
          />
          <div
            className="separator"
            onMouseDown={onStartResize}
            onTouchStart={onStartResize}
            onClick={(e) => e?.preventDefault()}
          >
            <span
              className="material-icons"
              onMouseDown={onStartResize}
              onTouchStart={onStartResize}
            >
              drag_handle
            </span>
          </div>
          <EditViewTimesheet />
        </div>
      </div>

      <ViewTimeSheetDrawer getTimeSheetsList={getTimeSheetsListByFilter} />
      <AddTimeSheetDrawer getTimeSheetsList={updateLocationList} />
      <TimeSheetHistoryDrawer />
    </div>
  );
}

export default React.memo(TimeSheetsList);
